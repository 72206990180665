export const CONTRAENTE            = 'contraente';
export const CONTRAENTE_FISICO     = 'contraenteFisico';
export const CONTRAENTE_GIURIDICO  = 'contraenteGiuridico';
export const ASSICURATO_FISICO     = 'assicuratoFisico';
export const TITOLARE_EFFETTIVO    = 'titolareEff';
export const RAPPRESENTANTE_LEGALE = 'rappLegale';
export const ESECUTORE_FISICO      = 'esecutoreFisico';
export const BENEF_VITA_FISICO     = 'beneficiarioVitaFisico';
export const BENEF_MORTE_FISICO    = 'beneficiarioMorteFisico';
export const BENEF_VITA_GIURIDICO  = 'beneficiarioVitaGiuridico';
export const BENEF_MORTE_GIURIDICO = 'beneficiarioMorteGiuridico';
export const REFERENTE_TERZO	   = 'referenteTerzo';
export const CODICE_MEDESIMO_SOGGETTO = '09';

export const BENEFICIARIO_VITA                      = 'BENEFICIARIO_VITA';
export const BENEFICIARIO_MORTE                     = 'BENEFICIARIO_MORTE';
export const TITOLARE_EFF_BENEF_MORTE               = 'TITOLARE_EFF_BENEF_MORTE';
export const TITOLARE_EFF_BENEF_VITA                = 'TITOLARE_EFF_BENEF_VITA';

export const CODICE_QUALIFICA_SOCIO_PROPRIETARIO    = '05'; 
export const CODICE_PERC_QUALIFICA_ANTIRICICLAGGIO      = '05';
export const CODICE_QUALIFICA_FIDUCIANTE                = '01';

export const locale_it = 'it';

export const RUOLO_CONTRAENTE   = 'A';
export const RUOLO_ASSICURATO   = 'B';
export const RUOLO_BENEF_MORTE  = 'C';
export const RUOLO_BENEF_VITA   = 'V';
export const RUOLO_RAPP_LEGALE  = 'R';
export const RUOLO_REF_TERZO    = 'N';
export const RUOLO_TITOLARE_EFF = 'T';

export const PERSONA_FISICA    = 'F';
export const PERSONA_GIURIDICA = 'G';
export const PERSONA_GENERICA  = 'Q';

export const COD_NAZIONE_ITALIA	= '86';
export const PROVINCIA_ESTERA	= 'EE';

export const PRODOTTO_TIPOLOGIA_MULTINVEST = 'M';
export const PRODOTTO_TIPOLOGIA_UNIT_LINKED  = 'U';
export const PRODOTTO_TIPOLOGIA_ALTRO      = 'A';

export const PRODOTTO_ZURICH_TARGET_SOLUTION_045 = '045';
export const PRODOTTO_TABOO_601 = '601';
export const PRODOTTO_MULTINVEST_M111 = 'M111';
export const PRODOTTO_PORTFOLIO_EXTRA_UL04 = 'UL04';
export const PRODOTTO_Z_INTEGRA_981 = '981';
export const PRODOTTO_ZURICH_PROGETTO_170 = '170';
export const PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 = 'M118';

export const DURATA = 'DURATA';
export const DURPAG = 'DURPAG';
export const CAPASS = 'CAPASS';
export const PRSOTT = 'PRSOTT';
export const PANNUO = 'PANNUO';
export const PUNICO = 'PUNICO';
export const FRAZ   = 'FRAZ';

export const PREMIO_CAPITALE_ASSICURATO = 'PREMIO_CAPITALE_ASSICURATO';
export const PREMIO                     = 'PREMIO';
export const RIPARTIZIONE_PREMIO        = 'RIPARTIZIONE_PREMIO';
export const OPZIONI_CONTRATTUALI       = 'OPZIONI_CONTRATTUALI';
export const MODALITA_PAGAMENTO         = 'MODALITA_PAGAMENTO';

export const DATI_NESSUNO                  	  	= '0';
export const DATI_PIANO_EROGAZIONI_PERIODICHE 	= '1';
export const DATI_TAKE_PROFIT                 	= '2';
export const DATI_LIFE_CYCLE                  	= '3';
export const DATI_PIANO_SPOSTAMENTO_AUTOMATICO	= '4';

//2020-12-01 PFD: SAV 'TO BE'
//export const ASSEGNO_BANCARIO                 = '1';
//export const ASSEGNO_CIRCOLARE                = '6';
//export const BONIFICO_ITALIA                  = '2';
//export const BONIFICO_ESTERO                  = 'S';
//export const REIMPIEGO                        = '7';
//export const ALTRO_PAGAMENTO                  = '8';
//export const CARTA_DI_DEBITO                  = 'BC';
//export const CARTA_DI_CREDITO                 = 'CR';

//2020-12-01 PFD: SAV 'TO BE'
export const MODALITA_PAGAMENTO_TYPE = {
    ASSEGNO_BANCARIO: '1',
    ASSEGNO_CIRCOLARE: '6',
    BONIFICO_ITALIA: '2',
    BONIFICO_ESTERO: 'S',
    REIMPIEGO: '7',
    CARTA_DI_DEBITO: 'BC',
    CARTA_DI_CREDITO: 'CR',
    ALTRO_PAGAMENTO: '8',
}
 

export const INTERMEDIARIO = 'INTERMEDIARIO';
export const CONSENSO      = 'CONSENSO';

export const ADEGUATEZZA = 'ADEGUATEZZA';
export const ANAMNESTICO = 'ANAMNESTICO';
export const SEMPLIFICATO= 'SEMPLIFICATO';

export const QUESTIONARIO_ADEGUATEZZA_FISICO         = 'questionarioAdeguatezzaFisico';
export const QUESTIONARIO_ADEGUATEZZA_GIURIDICO      = 'questionarioAdeguatezzaGiuridico';
export const QUESTIONARIO_ANAMNESTICO                = 'questionarioAnamnestico';
export const QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE = 'questionarioAnamnesticoMalattiaGrave';
export const QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO   = 'questionarioAnamnesticoSemplificato';

//2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP export const COD_QUESTIONARIO_ADEGUATEZZA  				 = '021';
export const COD_QUESTIONARIO_ADEGUATEZZA  				 = '031';
export const COD_QUESTIONARIO_ANAMNESTICO  				 = '002';
export const COD_QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE = '007';
export const COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011  	 = 'ADEG_011';
export const COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002  	 = 'ANAM_002';

export const COD_BLOCCO_QUESTIONARIO_100 = '100';
export const COD_BLOCCO_QUESTIONARIO_101 = '101';               
export const COD_BLOCCO_QUESTIONARIO_102 = '102';                            
export const COD_BLOCCO_QUESTIONARIO_103 = '103';                       
export const COD_BLOCCO_QUESTIONARIO_104 = '104'; 
export const COD_BLOCCO_QUESTIONARIO_113 = '113';                  
export const COD_BLOCCO_QUESTIONARIO_212 = '212';
export const COD_BLOCCO_QUESTIONARIO_307 = '307';

export const STATO_UTENTE_PENDENTE = 'P';
export const STATO_UTENTE_ATTIVA   = 'A';
export const STATO_UTENTE_REVOCATA = 'R';
export const STATO_UTENTE_BLOCCATA = 'B';
export const STATO_UTENTE_SCADUTA  = 'S';

export const MASTER = 'MASTER';
export const ADMIN  = 'ADMIN';
export const USER   = 'USER';

export const MAX_DATE = '31/12/9999';

export const PWD_IN_SCADENZA            = 'INF_SCAD_PWD';
export const PWD_SCADUTA                = 'ERR_SCAD_PWD';
export const CAMBIO_PWD_IN_SCADENZA     = 'DA_SCADENZA_A_LOGIN';
export const NON_CAMBIO_PWD_IN_SCADENZA = 'DA_SCADENZA_A_CAMBIO_PASSWORD';

export const STEP_CONTRAENTE_ASSICURATO     = 'STEP_CONTRAENTE_ASSICURATO';
export const STEP_ALTRE_FIGURE              = 'STEP_ALTRE_FIGURE';
export const STEP_CARATTERISTICHE_CONTRATTO = 'STEP_CARATTERISTICHE_CONTRATTO';
export const STEP_CONSENSI                  = 'STEP_CONSENSI';
export const STEP_QUESTIONARI               = 'STEP_QUESTIONARI';
export const STEP_STAMPA_PROPOSTA           = 'STEP_STAMPA_PROPOSTA';

export const CODICE_ALTRA_RELAZIONE                     = '10';
export const CODICE_ALTRO_TITOLO_STUDIO                 = '04';
export const CODICE_ATRA_FONTE_REDDITO                  = '10';
export const CODICE_ALTRA_ORIGINE_FONDI                 = '14';
export const CODICE_ALTRI_SOGGETTI_DA_TUTELARE          = '03';
export const CODICE_ALTRA_RAPPRESENTANZA                = '10';
export const CODICE_ATT_PREV_ALTRO					    = '10';
export const CODICE_ATT_PREV_PENSIONATO                 = '16';
export const CODICE_ALTRA_QUALIFICA		                = '10';

export const CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE      = '273';
export const CODICE_SAE_FIDUCIARIA_GESTIONE             = '265';
