import * as actionType from '../actionType/datiTakeProfit';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiTakeProfitInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiTakeProfitReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiTakeProfitReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaTakeProfit(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };	  
    case actionType.SALVA_DATI_TAKE_PROFIT:
//      console.log('#RT2 Reducer areaDatiTakeProfitReducer SALVA_DATI_TAKE_PROFIT', actionType.SALVA_DATI_TAKE_PROFIT); 
//      console.log('#RT2 Reducer areaDatiTakeProfitReducer.action', action);    
      const caratteristicheContrattoDatiTakeProfitSalva = {...state}.caratteristicheContratto;
      const datiTakeProfitSalva = {...caratteristicheContrattoDatiTakeProfitSalva}.datiTakeProfit;
      datiTakeProfitSalva.controlloDati = action.unDato;
      caratteristicheContrattoDatiTakeProfitSalva.datiTakeProfit = {...datiTakeProfitSalva};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiTakeProfitSalva,
      };
    case actionType.UPDATE_DATI_TAKE_PROFIT_VALIDA:   
      const caratteristicheContrattoDatiTakeProfitUpdate = {...state}.caratteristicheContratto;
      const datiTakeProfitUpdate = {...caratteristicheContrattoDatiTakeProfitUpdate}.datiTakeProfit;
      datiTakeProfitUpdate.datiTakeProfitValida = action.unDato;
      caratteristicheContrattoDatiTakeProfitUpdate.datiTakeProfit = {...datiTakeProfitUpdate};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiTakeProfitUpdate,
      };      
    case actionType.UPDATE_PERCENTUALE_OBIETTIVO:
      const caratteristicheContrattoPercentualeObiettivo = {...state}.caratteristicheContratto;
      const datiTakeProfitPercentualeObiettivo = {...caratteristicheContrattoPercentualeObiettivo}.datiTakeProfit;
      datiTakeProfitPercentualeObiettivo.percentualeObiettivo = unValore;
      datiTakeProfitPercentualeObiettivo.descPercentualeObiettivo = unaLabel;
      caratteristicheContrattoPercentualeObiettivo.datiTakeProfit = {...datiTakeProfitPercentualeObiettivo};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoPercentualeObiettivo,
      }; 
    default:
      return state;
  }
}

export const inizializzaTakeProfit = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiTakeProfit = jsonCopy(datiTakeProfitInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiTakeProfitReducer;