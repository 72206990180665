import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ButtonComp from '../../common/ButtonComp';
import TextFieldComp from '../../common/TextFieldComp';
import TextFieldAdornPwdComp from '../../common/TextFieldAdornPwdComp';
import { textTrim } from '../../../utility/genericUtility';

import * as actions from './action/datiLoginCambioPassword';
import * as actionType from './actionType/datiLoginCambioPassword';

import { uris, fetchParam } from '../../../uris';
import { PWD_SCADUTA } from '../../../utility/jsConstants';

const styles = theme => ({
  root: {
    marginTop: 200,
  },
  button: theme.button,
  textField: theme.textField,
  appName: {
    fontSize: theme.typography.fontSize*3.3, 
	color: theme.palette.color.marine,
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*2, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*1.5, 
      },
    marginRight: theme.spacing(2),
  },
  appNameCorsivo: {
    fontSize: theme.typography.fontSize*3, 
	color: theme.palette.color.windowsBlue,
	fontStyle: 'italic',
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*1.7, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*1.3, 
      },
  },
  marginLeft: {
	marginLeft: theme.spacing(1.5),
	color: theme.palette.color.marine,
  },
  textFieldLogin: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1), 
	height: 48,	
	width: '97%',
	marginTop: theme.spacing(0),
  },
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	loginUser: state.areaLoginReducer.areaLogin,
	datiLoginCambioPassword: state.areaDatiLoginCambioPasswordReducer.gestioneUtenza.datiLoginCambioPassword,
  };
};
	
const mapDispatchToProps = dispatch => {
  return {
    updateDatiLoginCambioPassword: (unDato, unActionType) => dispatch(actions.updateDatiLoginCambioPassword(unDato, unActionType)),
	salvaDatiLoginCambioPassword: (controlloDati) => dispatch(actions.salvaDatiLoginCambioPassword(controlloDati)),
	modificaDatiLoginCambioPassword: (password, passwordNuova, passwordConferma) => dispatch(actions.modificaDatiLoginCambioPassword(password, passwordNuova, passwordConferma)),
  };
};

class DatiLoginCambioPassword extends React.Component {

  constructor(props) {
    super(props);
    const datiLoginCambioPassword = this.props.datiLoginCambioPassword;
    this.state = {
      utente: datiLoginCambioPassword.utente,
      password: datiLoginCambioPassword.password,
      passwordNuova: datiLoginCambioPassword.passwordNuova,
      passwordConferma: datiLoginCambioPassword.passwordConferma,
      codiceErrore: '',
    };    
  };
    
  componentDidMount() {
	const loginUser = this.props.loginUser;
	this.props.updateDatiLoginCambioPassword(loginUser.user, actionType.UPDATE_UTENTE_LOGIN_CAMBIO_PASSWORD);
    this.setState({
      utente: loginUser.user,
    });
  }
  
  handleChangePassword = event => {
    const nuovoPassword = textTrim(event.target.value, true);
	const vecchioPassword = textTrim(this.state.password, true);
	if (nuovoPassword !== vecchioPassword) {
	  this.props.updateDatiLoginCambioPassword(event.target.value, actionType.UPDATE_PASSWORD_LOGIN_CAMBIO_PASSWORD);   
	  this.setState({
        password: event.target.value, 
      });
	}
  };
	  
  handleChangePasswordNuova = event => {
    const nuovoPasswordNuova = textTrim(event.target.value, true);
	const vecchioPasswordNuova = textTrim(this.state.passwordNuova, true);
	if (nuovoPasswordNuova !== vecchioPasswordNuova) {
	  this.props.updateDatiLoginCambioPassword(event.target.value, actionType.UPDATE_PASSWORD_NUOVA_LOGIN_CAMBIO_PASSWORD); 
	  this.setState({
	    passwordNuova: event.target.value, 
      });
	}
  };
  
  handleChangePasswordConferma = event => {
    const nuovoPasswordConferma = textTrim(event.target.value, true);
	const vecchioPasswordConferma = textTrim(this.state.passwordConferma, true);
	if (nuovoPasswordConferma !== vecchioPasswordConferma) {
	  this.props.updateDatiLoginCambioPassword(event.target.value, actionType.UPDATE_PASSWORD_CONFERMA_LOGIN_CAMBIO_PASSWORD); 
	  this.setState({
	    passwordConferma: event.target.value, 
      });
	}
  };
	  
  handleChangeModificaDatiLoginCambioPassword = (utente, password, passwordNuova, passwordConferma) => event => {
    this.props.salvaDatiLoginCambioPassword(true, actionType.SALVA_DATI_LOGIN_CAMBIO_PASSWORD);
	if(this.state.password.trim() !== '' && this.state.passwordNuova.trim() !== '' && this.state.passwordConferma.trim() !== ''){
	  this.setModificaPasswordVecchia(utente, password, passwordNuova, passwordConferma);
	}
  }  
  
  setModificaPasswordVecchia = (utente, password, passwordNuova, passwordConferma) => {
    const opts = {
      user: utente.trim(),
      password: password.trim(),
      newPassword: passwordNuova.trim(),
      confirmedPassword: passwordConferma.trim(),
	};
	fetch(uris.cambioPasswordUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const codiceErrore = result.codiceErrore.trim();  
	  this.setState({
		  codiceErrore: codiceErrore,
		});
	  if(codiceErrore===''){
	    this.props.modificaDatiLoginCambioPassword(password.trim(),passwordNuova.trim(),passwordConferma.trim());
		this.setState({
	      password: password.trim(),
		  passwordNuova: passwordNuova.trim(),
		  passwordConferma: passwordConferma.trim(),
		  codiceErrore: codiceErrore,
		});
		if(this.props.rilanciaLogin) {
		  this.props.rilanciaLogin(utente.trim(), passwordNuova.trim());
		}
	  }
	});
  }
	  
  isRequiredPassword = () => {
    return true;
  }
  
  isRequiredPasswordNuova = () => {
    return true;
  }
  
  isRequiredPasswordConferma = () => {
    return true;
  }
  
  render() {
	const { classes, datiLoginCambioPassword } = this.props;	 
    const { t } = this.props; //hoc	
    const controlloDati = datiLoginCambioPassword.controlloDati;
    
    return (
      <Fragment>
        <div style={{height: '100px'}} />
	    <div align='center'>
	      <div align='center' style={{width: '50%'}}>
		    <Typography display='inline' className={classes.appName}>{'ZURICH'}</Typography>
			<Typography display='inline' className={classes.appNameCorsivo}>{'Semplifico'}</Typography>  
			<Typography variant="h6">
			{this.props.codiceErroreLogin!==PWD_SCADUTA && t('modificaPassword')}
			{this.props.codiceErroreLogin===PWD_SCADUTA && t(PWD_SCADUTA)}
			</Typography>
	      </div>
		</div>
		<Grid container direction='row' justify='flex-start' alignItems='flex-start'>
		  <Grid item xs={3}></Grid>
	      <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('tuoIdUtente')}</Typography></Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={6}>
	        <div className={classes.middle}><TextFieldComp aStyle={classes.textFieldLogin} id='utente' label='IdUtente' value={datiLoginCambioPassword.utente} readonly={true} /></div>      
	      </Grid>
	      <Grid item xs={3}></Grid>
	          
	      <Grid item xs={12}>&nbsp;</Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('tuaPassword')}</Typography></Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={6}>
	    	<TextFieldAdornPwdComp onChange={this.handleChangePassword} aStyle={classes.textFieldLogin} id='password' label='tuaPassword' value={datiLoginCambioPassword.password} 
	    	  required={this.isRequiredPassword()} controlloDati={controlloDati} adornPosit='end'/>  
	      </Grid>
	      <Grid item xs={3}></Grid>
	      
	      <Grid item xs={12}>&nbsp;</Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('tuaPasswordNuova')}</Typography></Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={6}>
	    	<TextFieldAdornPwdComp onChange={this.handleChangePasswordNuova} aStyle={classes.textFieldLogin} id='passwordNuova' label='tuaPasswordNuova' value={datiLoginCambioPassword.passwordNuova} 
	    	  required={this.isRequiredPasswordNuova()} controlloDati={controlloDati} adornPosit='end'/>  
	      </Grid>
	      <Grid item xs={3}></Grid>
	      
	      <Grid item xs={12}>&nbsp;</Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('confermaPassword')}</Typography></Grid>
	        
	      <Grid item xs={3}></Grid>
	      <Grid item xs={6}>
	    	<TextFieldAdornPwdComp onChange={this.handleChangePasswordConferma} aStyle={classes.textFieldLogin} id='passwordConferma' label='confermaPassword' value={datiLoginCambioPassword.passwordConferma} 
	    	  required={this.isRequiredPasswordConferma()} controlloDati={controlloDati} adornPosit='end'/>  
	      </Grid>
	      <Grid item xs={3}></Grid>
	    	  
	    </Grid>
	
	    <div align='center'>
	      <Grid container direction="row" justify="center" alignItems="center">	
	        <Grid item xs={12}>&nbsp;</Grid>    
	        <Grid item xs={12}>
	          {this.state.codiceErrore!=='' &&
	  	        <Typography className={classes.textErrorMessage}>{t(this.state.codiceErrore)}</Typography>
	          }
	        </Grid>
	        <Grid item xs={12}>
	          {this.state.codiceErrore!=='' && this.state.codiceErrore==='ERR_PWD_05' && 
	  	        <Typography className={classes.textErrorMessage}>{t('ERR_PWD_05_DET')}</Typography>
	          }
	        </Grid>
	      </Grid>
	      <Grid container direction='row' justify='center' alignItems='flex-start'>
	        <Grid item xs={12}>   
	          <ButtonComp 
	            aClassName={classes.button} 
	            buttonLabel={'salva'}
	            onClick={this.handleChangeModificaDatiLoginCambioPassword(datiLoginCambioPassword.utente, datiLoginCambioPassword.password, datiLoginCambioPassword.passwordNuova, datiLoginCambioPassword.passwordConferma)}/>
	        </Grid> 
	      </Grid>
	    </div>		  	 		  	 
      </Fragment>
    );
  }
}

DatiLoginCambioPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles, { withTheme: true })(DatiLoginCambioPassword)));