import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TextFieldComp from '../../../common/TextFieldComp';
import NumberFieldComp from '../../../common/NumberFieldComp';
import RadioButtonComp from '../../../common/RadioButtonComp';
import ButtonComp from '../../../common/ButtonComp';

import { radioButtonRibilanciamento } from '../../../store/radioButtonStore';
import { PRODOTTO_TIPOLOGIA_MULTINVEST, PRODOTTO_TIPOLOGIA_UNIT_LINKED, PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 } from '../../../../utility/jsConstants';

import DatiRipartizionePremioFondi from './DatiRipartizionePremioFondi';

import * as actions from './action/datiRipartizionePremio';
import * as actionType from './actionType/datiRipartizionePremio';
import { textTrim } from '../../../../utility/genericUtility';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.color.greyLight,
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginBottom: theme.spacing(3),
  },
  body: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginBottom: theme.spacing(3),
  },
}))(TableCell);

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  table: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
  },
  textField: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1), 
	marginBottom: theme.spacing(2),
	height: 48,
  },
  textFieldError: theme.textFieldError,
  marginGrid: {
    marginLeft: theme.spacing(1.5),
  },
  tableWrapper: {
	 width:'100%',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiRipartizionePremio.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiRipartizionePremio: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio,
    datiRipartizionePremioFondi: state.areaDatiRipartizionePremioFondiReducer.caratteristicheContratto.datiRipartizionePremioFondi,
    esitoPercentualeFondo: state.areaDatiRipartizionePremioFondiReducer.caratteristicheContratto.datiRipartizionePremioFondi.esitoPercentualeFondo,
    prodotto: state.areaProdottoReducer.prodotto
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiRipartizionePremio.mapDispatchToProps.dispatch', dispatch);
  return {
	updateDatiRipartizionePremio: (unDato, unActionType) => dispatch(actions.updateDatiRipartizionePremio(unDato, unActionType)),
//	2019-12-17 PFD: updateDatiRipartizionePremioLinea: (unDato, codiceLinea, unActionType) => dispatch(actions.updateDatiRipartizionePremioLinea(unDato, codiceLinea, unActionType)),	
	updateDatiRipartizionePremioLinea: (unDato, unActionType) => dispatch(actions.updateDatiRipartizionePremioLinea(unDato, unActionType)),
//	2019-12-17 PFD:	salvaEsitoPercentualeAllocazione: (esitoPercentualeAllocazione, esitoPercentualeFondo, unActionType) => dispatch(actions.salvaEsitoPercentualeAllocazione(esitoPercentualeAllocazione, esitoPercentualeFondo, unActionType)),
  };
};

class DatiRipartizionePremio extends React.Component {
	
  constructor(props) {
  super(props);
    const datiRipartizionePremio = this.props.datiRipartizionePremio;
    
    const lineeGuidateTutte = this.props.dominio.allocazioneItems.lineaGuidata.map((item, index) => {
    	return {codice: item.codice, descrizione: item.descrizione}
    });
     
    // salvo nello store la lista di tutte le linee guidate
    this.props.updateDatiRipartizionePremio(lineeGuidateTutte, actionType.UPDATE_LINEE_GUIDATE_TUTTE);
    
    this.state = {
      gestioneSeparata: datiRipartizionePremio.gestioneSeparata,
      lineaLibera: datiRipartizionePremio.lineaLibera,
      lineaGuidata: datiRipartizionePremio.lineaGuidata,
      ribilanciamento: datiRipartizionePremio.ribilanciamento,
      esitoPercentualeAllocazione: '',
      esitoPercentualeGestioneSeparata: '',
//2019-09-12 PFD:	  esitoPercentualeFondo: '',
      statoCampi: this.initStatoCampi(lineeGuidateTutte),
      isPresenteErrore: null,
      refreshErrors: true,  //2022-09-22 PFD: per permettere il controllo errori al caricamento del Componente
    };
  }; 
  
  handleChangeConfermaDatiRipartizionePremio = event => {	 
    this.props.updateDatiRipartizionePremio(true, actionType.SALVA_DATI_RIPARTIZIONE_PREMIO); 
//2019-12-17 PFD: const esitoPercentualeAllocazione = (this.checkTotalePercentualeAllocazione() === true) ? 'OK': 'KO';  
//2019-09-12 PFD: spostato in DatiRipartizionePremioFondi    const esitoPercentualeFondo = (this.checkTotalePercentualeFondi() === true) ? 'OK': 'KO';
    
    const lineeGuidateTutte = this.props.datiRipartizionePremio.lineeGuidateTutte;
    this.setState({
//2019-12-17 PFD:      esitoPercentualeAllocazione: esitoPercentualeAllocazione,
//2019-09-12 PFD: spostato in DatiRipartizionePremioFondi      esitoPercentualeFondo: esitoPercentualeFondo,
      refreshErrors: true,
    });
//2019-09-12 PFD: spostato in DatiRipartizionePremioFondi  this.props.salvaEsitoPercentualeAllocazione(esitoPercentualeAllocazione, esitoPercentualeFondo, actionType.SALVA_ESITO_PERCENTUALE_ALLOCAZIONE);
//2019-12-17 PFD: this.props.salvaEsitoPercentualeAllocazione(esitoPercentualeAllocazione, null, actionType.SALVA_ESITO_PERCENTUALE_ALLOCAZIONE);
  } 
  
  checkTotalePercentualeAllocazione() {
    let check = false;
	let tot = 0;
	const lineaCopia = {...this.props.datiRipartizionePremio}.lineaGuidata;
	lineaCopia.forEach(function(item, index, array) {
	  if(!isNaN(item.linea) && item.linea!==''){
	    tot = tot + parseInt(item.linea)
	  }
	});
	if(!isNaN(this.props.datiRipartizionePremio.gestioneSeparata) && this.props.datiRipartizionePremio.gestioneSeparata!==''){
	  tot = tot + parseInt(this.props.datiRipartizionePremio.gestioneSeparata)
	}
	if(!isNaN(this.props.datiRipartizionePremio.lineaLibera) && this.props.datiRipartizionePremio.lineaLibera!==''){
	  tot = tot + parseInt(this.props.datiRipartizionePremio.lineaLibera)
	}
	if(tot===100){
	  check = true;
	}
	return check;
  }

  getMassimaPercentualeGestioneSeparata (prodotto) {
	  let maxPerc = 100; // scelgo arbitrariamente il massimo
	  if (prodotto.tipo===PRODOTTO_TIPOLOGIA_MULTINVEST){
		  if (prodotto.codice===PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118) {
			  maxPerc = 80;
		  } else {
			  maxPerc = 50;
		  }
	  }
	  return maxPerc;
  }
  
  getMinimaPercentualeGestioneSeparata (prodotto) {
	  let minPerc = 0; // scelgo arbitrariamente il minimo
	  if (prodotto.tipo===PRODOTTO_TIPOLOGIA_MULTINVEST){
		  if (prodotto.codice===PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118) {
			  minPerc = 40;
		  } else {
			  minPerc = 0;
		  }
	  }
	  return minPerc;
  }
  
  checkTotalePercentualeGestioneSeparata(prodotto) {
    let check = false;
	let gestioneSeparataValue = 0;
	let maxPercGestSep = this.getMassimaPercentualeGestioneSeparata(prodotto);
	let minPercGestSep = this.getMinimaPercentualeGestioneSeparata(prodotto);
	
	const gestioneSeparata = this.props.datiRipartizionePremio.gestioneSeparata;
	if(textTrim(gestioneSeparata, true) !=='' && !isNaN(gestioneSeparata)) {
		gestioneSeparataValue = gestioneSeparataValue + parseInt(gestioneSeparata);
	}
	
	// verifico che il valore imputato sia compreso tra il valore massimo e minimo
	if (gestioneSeparataValue >= minPercGestSep && gestioneSeparataValue <= maxPercGestSep) {
		check = true;
	} 	
	
	return check;
  }
  
/*2019-09-12 PFD:   
  checkTotalePercentualeFondi() {
    let check = false;
    if(this.props.datiRipartizionePremio.lineaLibera === '') {
      check = true;
    } else {
	  let tot = 0;
//	  const fondoCopia = {...this.props.datiRipartizionePremioFondi}.fondo.filter(unItem => (!isNaN(unItem.fondo) && unItem.fondo !== ''));
	  const fondoCopia = {...this.props.datiRipartizionePremioFondi}.fondo.filter(unItem => (!isNaN(unItem.fondo) && (textTrim(unItem.fondo, true) !== '')));
	  fondoCopia.forEach(function(item, index, array) {
	    tot += parseInt(item.fondo);
      });
	  if(tot===100) {
	    check = true;
	  }
    }
	return check;
  }
  
  checkTotalePercentualeFondi_OLD() {
    let check = false;
    if(this.props.datiRipartizionePremio.lineaLibera === ''){
      check = true;
    }else{
	  let tot = 0;
	  const fondoCopia = {...this.props.datiRipartizionePremioFondi}.fondo;
	  fondoCopia.forEach(function(item, index, array) {
	    if(!isNaN(item.fondo) && item.fondo!==''){
	      tot += parseInt(item.fondo)
	    }
	  });
	  if(tot===100){
	    check = true;
	  }
    }
	return check;
  }  
*/
  
  handleChangeGestioneSeparata = event => {
    //console.log('#RTCFA DatiRipartizionePremio handleChangeGestioneSeparata new value:' + event.target.value);
    const nuovaGestioneSeparata = textTrim(event.target.value, true);
    //const vecchiaGestioneSeparata = textTrim(this.state.gestioneSeparata, true);
    const vecchiaGestioneSeparata = textTrim(this.props.datiRipartizionePremio.gestioneSeparata, true);
    if (nuovaGestioneSeparata !== vecchiaGestioneSeparata) {		  
      const nuovoCampoGestioneSeparata = { 
    		  codice: this.props.dominio.allocazioneItems.gestioneSeparata.codice, 
    		  descrizione: this.props.dominio.allocazioneItems.gestioneSeparata.descrizione, 
    		  valore: nuovaGestioneSeparata ,
      };
// 2019-12-17 PFD:      this.props.updateDatiRipartizionePremioLinea(nuovaGestioneSeparata, 0, actionType.UPDATE_GESTIONE_SEPARATA);
      this.props.updateDatiRipartizionePremioLinea(nuovoCampoGestioneSeparata, actionType.UPDATE_GESTIONE_SEPARATA);
      this.setState({ 
        gestioneSeparata: nuovaGestioneSeparata,
        refreshErrors: true,
      });
    }
  };
	  
  handleChangeLineaLibera = codiceLinea => event => {
    //console.log('#RTCFA DatiRipartizionePremio handleChangeLineaLibera new value:' + event.target.value);
    const nuovaLineaLibera = textTrim(event.target.value, true);
    //const vecchiaLineaLibera = textTrim(this.state.lineaLibera, true);
    const vecchiaLineaLibera = textTrim(this.props.datiRipartizionePremio.lineaLibera, true);
    if (nuovaLineaLibera !== vecchiaLineaLibera) {
    	const nuovoCampoLineaLibera = { 
    			codice: this.props.dominio.allocazioneItems.lineaLibera.codice, 
    			descrizione: this.props.dominio.allocazioneItems.lineaLibera.descrizione, 
    			valore: nuovaLineaLibera,
    	};
// 2019-12-17 PFD:      this.props.updateDatiRipartizionePremioLinea(nuovaLineaLibera, codiceLinea, actionType.UPDATE_LINEA_LIBERA);
        this.props.updateDatiRipartizionePremioLinea(nuovoCampoLineaLibera, actionType.UPDATE_LINEA_LIBERA);
        this.setState({ 
        lineaLibera: nuovaLineaLibera,
        refreshErrors: true,
      });
    }  
  };
    
  handleChangeLineaGuidata = codiceLinea => event => {	
    let vecchioCampoLineaGuidata = null;
    let vecchioValoreLineaGuidata = null;
    const nuovoValoreLineaGuidata = textTrim(event.target.value, true);
    let valoreLineaGuidata = this.state.lineaGuidata.filter(unItem => (textTrim(unItem.codiceLinea, true) === textTrim(codiceLinea, true)));
    if (valoreLineaGuidata.length > 0) {
      vecchioCampoLineaGuidata = valoreLineaGuidata[0];
      vecchioValoreLineaGuidata = textTrim(vecchioCampoLineaGuidata.linea, true);
    }
    if (nuovoValoreLineaGuidata !== vecchioValoreLineaGuidata) {
      let descrLinea = '';
    	if (nuovoValoreLineaGuidata!==''){
    	  const descrLineaGuidata = this.props.datiRipartizionePremio.lineeGuidateTutte.filter(unItem => (textTrim(unItem.codice, true) === textTrim(codiceLinea, true)));
    	  descrLinea = descrLineaGuidata[0].descrizione;
      }
      const nuovoCampoLineaGuidata = { 
    		  codice: codiceLinea, 
    		  descrizione: descrLinea, 
    		  valore: nuovoValoreLineaGuidata,
      };
// const nuovoCampoLineaGuidata = { codiceLinea: codiceLinea, linea: nuovoValoreLineaGuidata };       
// 2019-12-17 PFD:      this.props.updateDatiRipartizionePremioLinea(nuovoValoreLineaGuidata, codiceLinea, actionType.UPDATE_LINEA_GUIDATA);
      this.props.updateDatiRipartizionePremioLinea(nuovoCampoLineaGuidata, actionType.UPDATE_LINEA_GUIDATA);
      
/* non gestisco piu' lineaGuidata come array
  20191212 PFD:const lineaGuidataCopia = this.state.lineaGuidata.filter(unItem => (textTrim(unItem.codiceLinea, true) !== textTrim(codiceLinea, true)));
  20191212 PFD:this.setState({
  20191212 PFD:lineaGuidata: [...lineaGuidataCopia, nuovoCampoLineaGuidata],
	  });
*/            
      //20191212 PFD:
      this.setState({
    	lineaGuidata: [{ codiceLinea: codiceLinea, descrLinea: descrLinea, linea: nuovoValoreLineaGuidata, }],
    	refreshErrors: true,
       });
    }	
  };
    
  getLineaByCode = codiceLinea => {
    const lineaGuidata = this.state.lineaGuidata.filter(unItem => (textTrim(unItem.codiceLinea, true) === textTrim(codiceLinea, true)));
    const linea = (lineaGuidata.length > 0) ? lineaGuidata[0].linea : '';
    return linea;
  }
    
  handleChangeRibilanciamento = event => {
    //console.log('#RTCFA DatiRipartizionePremio handleChangeRibilanciamento new value:' + event.target.value);
    const nuovoRibilanciamento = textTrim(event.target.value, true);
    //const vecchioRibilanciamento = textTrim(this.state.ribilanciamento, true); 
    const vecchioRibilanciamento = textTrim(this.props.datiRipartizionePremio.ribilanciamento, true);
    if (nuovoRibilanciamento !== vecchioRibilanciamento) { 	  
    	const nuovoCampoRibilanciamento = { codice: null, 
   											descrizione: null, 
   											valore: nuovoRibilanciamento };
    	
// 2019-12-17 PFD:      this.props.updateDatiRipartizionePremioLinea(nuovoRibilanciamento, 0, actionType.UPDATE_RIBILANCIAMENTO);
    	this.props.updateDatiRipartizionePremioLinea(nuovoCampoRibilanciamento, actionType.UPDATE_RIBILANCIAMENTO);
      this.setState({ 
        ribilanciamento: nuovoRibilanciamento 
      });
    }  
  };

  isRequiredGestioneSeparata = () => {
    return true;
  }
   
  isRequiredLineaLibera = () => {
	const isEmptyLineaLibera = textTrim(this.props.datiRipartizionePremio.lineaLibera.linea, true) === '';
	const lineaGuidata = this.props.datiRipartizionePremio.lineaGuidata.filter(unItem => (textTrim(unItem.linea, true) !== ''));	
	return isEmptyLineaLibera && lineaGuidata.length === 0;
  }
    
  isRequiredLineaGuidata = () => {
	let isLineaLiberaValorizzata = this.props.datiRipartizionePremio.lineaLibera !== '';
	if (isLineaLiberaValorizzata === true) {
      return false;	
	} else {
      const lineaGuidataValorizzata = this.props.datiRipartizionePremio.lineaGuidata.filter(unItem => (textTrim(unItem.linea, true) !== ''));
      return (lineaGuidataValorizzata.length === 0);
	}
  }
  
  isRequiredRibilanciamento = () => {
  	return (this.props.datiRipartizionePremio.lineaLibera !== '');
  }
  
  isDisabledRibilanciamento = () => {
    return (this.props.datiRipartizionePremio.lineaLibera === '');
  }
  
  isDisabledLineaLibera = () => {
    const lineaGuidata = this.props.datiRipartizionePremio.lineaGuidata.filter(unItem => (textTrim(unItem.linea, true) !== ''));
	return (lineaGuidata.length > 0);	  
  }
    
  isDisabledLineaGuidata = codLinea => {
	const isLineaLiberaValorizzata = this.props.datiRipartizionePremio.lineaLibera !== '';
	if (isLineaLiberaValorizzata === true) {
      return true;	
	} else {
      const lineaGuidata = this.props.datiRipartizionePremio.lineaGuidata.filter(unItem => (textTrim(unItem.linea, true) !== ''));
      return ((lineaGuidata.length === 0) ? false
    		                             : ((lineaGuidata[0].codiceLinea !== codLinea) ? true : false));
	}
  }  
  
  initStatoCampi = (lineeGuidateTutte) => {   	
    /*
	return [ {id: 'gestioneSeparata', isError: false},
             {id: 'lineaLibera', isError: false}, 
             
             {id: 'lineaGuidata', isError: false}, 
             {id: 'ribilanciamento', isError: false},
             {id: 'ripartizionePremioFondi', isError: false},
    	   ];
    	   */
	
    const lineaGuidataItems = lineeGuidateTutte.map((elementoDetail, index) => {
	   return {id: 'lineaGuidata' + index, isError: false};
    });

    const statoCampiParz = [ 
	   {id: 'gestioneSeparata', isError: false},
       {id: 'lineaLibera', isError: false},         
       {id: 'ribilanciamento', isError: false},
       {id: 'ripartizionePremioFondi', isError: false},
    ];
	
	const statoCampi = statoCampiParz.concat(lineaGuidataItems);
	return statoCampi;
  }

  /* PFD 2019-09-12:
  handleError = (id, isError) => {   
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    let campiTemp = [...campoWithoutId, unErrore];   
    let prova = this.props.datiRipartizionePremio.lineaLibera;
    let provaState = this.state.lineaLibera;
//    if ((id === 'lineaLibera') && (this.props.datiRipartizionePremio.lineaLibera === '')) {
      campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== 'ripartizionePremioFondi');        
      let unErroreDip = { id: 'ripartizionePremioFondi', isError: false };
      campiTemp =  [...campoWithoutId, unErroreDip];
//    }
    this.setState({	
      statoCampi: [...campiTemp],
    });    
  }
    */ 
    
  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }
  
  handleError = (id, isError) => {   
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  render() {
    const { classes, dominio, datiRipartizionePremio, prodotto } = this.props;
    const { t } = this.props; //hoc  
    const controlloDati = datiRipartizionePremio.controlloDati;
    const disabledRibilanciamento = this.isDisabledRibilanciamento();
    const disabledLineaLibera = this.isDisabledLineaLibera();
    let erroreTotaleRipartizione = '';
    if(prodotto.tipo === PRODOTTO_TIPOLOGIA_MULTINVEST){
      erroreTotaleRipartizione = t('errorePercTotaleAllocazione');
    } else if(prodotto.tipo === PRODOTTO_TIPOLOGIA_UNIT_LINKED) {
      erroreTotaleRipartizione = t('errorePercTotaleAllocazioneUL');
    }
    
    return (
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
    	        <TableHead>
    	          <TableRow>
    	            <CustomTableCell width='50%' colSpan={2} align='center'><Typography variant='subtitle1'>{t('allocazione')}</Typography></CustomTableCell>
    	            <CustomTableCell width='10%' align='center' padding='none'><Typography variant='subtitle1'>%</Typography></CustomTableCell>
    	            <CustomTableCell width='40%'></CustomTableCell>
    	          </TableRow>
    	        </TableHead>
    	        {dominio.allocazioneItems &&
    	        <TableBody>  
                {/*20230313 PFD - MISTEP-IFA: prodotto.tipo === PRODOTTO_TIPOLOGIA_MULTINVEST &&*/
                  textTrim(dominio.allocazioneItems.gestioneSeparata.descrizione) !== '' &&
                  <TableRow>
    	            <CustomTableCell colSpan={2} align='center'><Typography variant='caption'>{dominio.allocazioneItems.gestioneSeparata.descrizione}</Typography></CustomTableCell>
  	                <CustomTableCell align='center' padding='none'>
  	                  <NumberFieldComp onBlur={this.handleChangeGestioneSeparata} aStyle={classes.textField} aStyleError={classes.textFieldError} id='gestioneSeparata' label='' format='###,###' 
   	                     value={datiRipartizionePremio.gestioneSeparata} maxLength='7' required={this.isRequiredGestioneSeparata()} controlloDati={controlloDati} 
  	                     onError={this.handleError} isControlError={this.getIsInError('gestioneSeparata')}/>
  	                </CustomTableCell>
  	                <CustomTableCell align='center'><Typography variant='caption'>{t('allocazioneMaxGestSep')}</Typography></CustomTableCell>
  	              </TableRow>
                }
                {textTrim(dominio.allocazioneItems.lineaLibera.descrizione) !== '' &&
	              <TableRow>
	                <CustomTableCell align='center' colSpan={2}>
	                  <Typography variant='caption'>{dominio.allocazioneItems.lineaLibera.descrizione}</Typography>
	                </CustomTableCell>
	                <CustomTableCell padding='none'>
	                  <NumberFieldComp onBlur={this.handleChangeLineaLibera(dominio.allocazioneItems.lineaLibera.codice)} aStyle={classes.textField} aStyleError={classes.textFieldError} id='lineaLibera' label='' format='###,###' 
	                     value={datiRipartizionePremio.lineaLibera} maxLength='7' required={this.isRequiredLineaLibera()} controlloDati={controlloDati} disabled={disabledLineaLibera} 
	                     onError={this.handleError} isControlError={this.getIsInError('lineaLibera')} />
	                </CustomTableCell>
	                <CustomTableCell rowSpan={datiRipartizionePremio.lineeGuidateTutte.length + 1}>
                    {prodotto.tipo === PRODOTTO_TIPOLOGIA_MULTINVEST &&
	                    <Typography variant='caption'>{t('infoLineeMultinvest')}</Typography>
                    }
                    {prodotto.tipo === PRODOTTO_TIPOLOGIA_UNIT_LINKED &&
                      <Typography variant='caption'>{t('infoLineeUnitLinked')}</Typography>
                    }
	                </CustomTableCell> 
	              </TableRow>
                }
	              {datiRipartizionePremio.lineeGuidateTutte.map((elementoDetail, index) => (
	      	        <TableRow key={index}>
	      	          {index === 0 &&
	      	            <CustomTableCell align='center' rowSpan={datiRipartizionePremio.lineeGuidateTutte.length}>
	      	              <Typography variant='caption'>{t('lineeGuidate')}</Typography>
	      	            </CustomTableCell>
	      	          }
	      	          <CustomTableCell align='center'>
	      	            <Typography variant='caption'>{elementoDetail.descrizione}</Typography>
	      	          </CustomTableCell>
	      	          <CustomTableCell padding='none'>
	      	            <NumberFieldComp onBlur={this.handleChangeLineaGuidata(elementoDetail.codice)} aStyle={classes.textField} aStyleError={classes.textFieldError} id={'lineaGuidata' + index} label=' ' format='###,###' 
	                      value={this.getLineaByCode(elementoDetail.codice)} maxLength='7' required={this.isRequiredLineaGuidata()} controlloDati={controlloDati} 
	      	              disabled={this.isDisabledLineaGuidata(elementoDetail.codice)} onError={this.handleError} isControlError={this.getIsInError('lineaGuidata'+index)}/>
	      	          </CustomTableCell>
	      	        </TableRow>
	      	      ))}
    	        </TableBody>
    	        }
    	      </Table>
    	     </div>
    	   </Paper>
    	 </Grid>
    	 {/*Soluzione con o senza Ribilanciamento in funzione dell'esistenza della linea libera.*/}
  	     {textTrim(dominio.allocazioneItems.lineaLibera.descrizione) !== '' &&
  	       <Grid item xs={12}>    	   
    	     <Grid container direction='row' justify='space-evenly' alignItems='center' className={classes.marginGrid}>
    	       <Grid item md={5} xs={12}>
	    	     <Typography variant='caption'>{t('infoRibalanciamento')}</Typography>
	    	   </Grid>
	    	   <Grid item md={3} xs={12}>
	    	     <RadioButtonComp onChange={this.handleChangeRibilanciamento} radioComp={radioButtonRibilanciamento} classes={{divider: classes.divider}} 
                                selectedValue={datiRipartizionePremio.ribilanciamento} required={this.isRequiredRibilanciamento()} controlloDati={controlloDati} 
                                disabled={disabledRibilanciamento} id='ribilanciamento' onError={this.handleError} />
	    	   </Grid>
	    	   <Grid item md={4} xs={12}></Grid>
	         </Grid>
		    </Grid>
    	 }
	     {this.props.datiRipartizionePremio.lineaLibera !== '' &&
	        <Grid item xs={12}>
	          <DatiRipartizionePremioFondi fondiItems={dominio.fondiItems}
                                           id='ripartizionePremioFondi' onError={this.handleError} /> 
	        </Grid>
	     }
	     <Grid item xs={12}>
	        <Grid container direction='row' justify='center' alignItems='center'>	
	          <Grid item>
	          	{(this.state.esitoPercentualeGestioneSeparata === 'KO') &&
	          		<Typography className={classes.textErrorMessage}>{t('errorePercTotaleGestSep')}</Typography>
	          	}
	            {(this.state.esitoPercentualeAllocazione === 'KO') &&
	    	      <Typography className={classes.textErrorMessage}>{erroreTotaleRipartizione}</Typography>
	            }
	            {(this.state.esitoPercentualeAllocazione === 'OK') && (this.props.esitoPercentualeFondo === 'KO') &&
		    	  <Typography className={classes.textErrorMessage}>{t('errorePercTotaleFondo')}</Typography>
		        }
	            {(this.state.esitoPercentualeAllocazione === 'OK') && 
	            	(this.props.esitoPercentualeFondo === 'OK') && 
	            	(this.props.esitoPercentualeGestioneSeparata === 'OK') &&
	            	(this.state.isPresenteErrore === true) &&	            
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(this.props.datiRipartizionePremio.controlloDati === true) && (this.state.isPresenteErrore === false) &&
/*                    (this.state.esitoPercentualeAllocazione === 'OK') && (this.props.esitoPercentualeFondo === 'OK') &&*/
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
	          </Grid>
	        </Grid>
	      </Grid>
	      <Grid item xs={12}>
	        <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>	
	          <Grid item>
	            <ButtonComp
	              aClassName={classes.button}
	              buttonLabel={t('conferma')}
	              onClick={this.handleChangeConfermaDatiRipartizionePremio}
	            />
	          </Grid>
	        </Grid>
	      </Grid>
      </Grid>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiRipartizionePremio componentDidUpdate this.state.statoCampi', this.state.statoCampi);
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiRipartizionePremio.controlloDati === true)) {
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      */
      this.props.updateDatiRipartizionePremio(!isPresenteCampiConErrore, actionType.UPDATE_DATI_RIPARTIZIONE_PREMIO_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }

// 2019-10-09 PFD  
  isInBusinessError(prevProps, prevState) {
	//if (this.state.esitoPercentualeAllocazione !== prevState.esitoPercentualeAllocazione) {
	if (this.props.datiRipartizionePremio.controlloDati === true && this.state.refreshErrors === true) {
		
		const lineaGuidata = this.props.datiRipartizionePremio.lineaGuidata[0];
		const lineeGuidateTutte = this.props.datiRipartizionePremio.lineeGuidateTutte;
		const gestioneSeparata = this.props.datiRipartizionePremio.gestioneSeparata;
		
		// calcolo  la percentuale
		const isTotaleErr = !this.checkTotalePercentualeAllocazione();
		const isGestioneSeparataErr = !this.checkTotalePercentualeGestioneSeparata(this.props.prodotto);
			
		/* 20191212 PFD: */
    	for (var index = 0; index < lineeGuidateTutte.length; index ++) {
    		const item = lineeGuidateTutte[index];    		
    		if (lineaGuidata!==undefined && textTrim(item.codice, true) === textTrim(lineaGuidata.codiceLinea, true)) {
    			this.handleError('lineaGuidata' + index, isTotaleErr); 
    		}
    	}
    	
    	if (textTrim(this.props.datiRipartizionePremio.gestioneSeparata, true) !== '') {
    		if (isTotaleErr) {
    			this.handleError('gestioneSeparata', isTotaleErr);
    		} else if(isGestioneSeparataErr) {
    			this.handleError('gestioneSeparata', isGestioneSeparataErr);
    		} else {
    			// nessun errore su gestioneSeparata
    			this.handleError('gestioneSeparata', false);
    		}
    		
    	}
    	
    	if (textTrim(this.props.datiRipartizionePremio.lineaLibera, true) !== '') {
    		this.handleError('lineaLibera', isTotaleErr); 
    	}
    	
    	this.setState({
			refreshErrors: false,
			esitoPercentualeAllocazione : (!isTotaleErr ? 'OK' : 'KO'),
			esitoPercentualeGestioneSeparata : (!isGestioneSeparataErr ? 'OK' : 'KO'),
		})
	}
        
    if (textTrim(this.state.lineaLibera, true) !== textTrim(prevState.lineaLibera, true) 
    		&& textTrim(this.state.lineaLibera, true) === '') {
    	// reset errore
    	this.handleError('ripartizionePremioFondi', false);  
	}
    console.log('########DatiRipartizionePremio isInBusinessError this.state.statoCampi', this.state.statoCampi);
  }
  
  componentDidUpdate(prevProps, prevState) {
	  this.isInBusinessError(prevProps, prevState); // 2019-10-09 PFD
	  this.isInError(prevProps, prevState);    
  }      
}

DatiRipartizionePremio.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiRipartizionePremio)));

