import * as actionType from '../actionType/datiInviaNuoveCredenziali';

export const updateDatiInviaNuoveCredenziali = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaDatiInviaNuoveCredenziali = controlloDati => {
  return {
    type: actionType.SALVA_DATI_INVIA_NUOVE_CREDENZIALI,
	controlloDati,
  }
};