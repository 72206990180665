import * as actionType from '../actionType/contraente';
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import { relazPaesiListInizializzato } from '../../../../session/info/componentiBase/sezioniAnagrafica';
import initialState from '../../../../session/InitialState';

import { jsonCopy, dataFormat } from '../../../../utility/genericUtility';
import { PROVINCIA_ESTERA, RAPPRESENTANTE_LEGALE, CONTRAENTE_FISICO, CONTRAENTE_GIURIDICO, CODICE_ALTRA_ORIGINE_FONDI } from '../../../../utility/jsConstants';
import { datiGeneraliInizializzato, datiResidenzaInizializzato, datiRecapitoInizializzato, datiFiscaliInizializzato, datiConsensoComElettrInizializzato,
         datiAttEconomicaInizializzato, datiAntiriciclaggioInizializzato, datiRappLegaleInizializzato, datiEsecutoreInizializzato, datiNaturaRapportoInizializzato,
         datiGeneraliGiuridicoInizializzato, datiAttEconomicaGiuridicoInizializzato, datiFinanziariInizializzato, datiSocietariGiuridicoInizializzato, datiFinanziariGiuridicoInizializzato, datiMandatoFiduciarioInizializzato, datiDomicilioInizializzato } from '../../../../session/info/componentiBase/sezioniAnagrafica';


const areaContraenteReducer = (state = initialState, action) => {
  //TODO ### DA RIVEDERE ###
  // FIGURE
  const contraenteFisico = { ...state }.contraenteFisico;
  const contraenteGiuridico = { ...state }.contraenteGiuridico;

  // DATI FIGURE
  const datiEsecutore = contraenteFisico.datiEsecutore;
  const datiRappLegale = contraenteGiuridico.datiRappLegale;
  const datiRecapito = contraenteFisico.datiRecapito;
  const datiRecapitoGiuridico = contraenteGiuridico.datiRecapito;
  

  // SEZIONE DATI GENERALI
  const datiGeneraliEsecutore = { ...datiEsecutore }.datiGenerali;

  // SEZIONE DATI RESIDENZA
  const datiResidenzaEsecut = datiEsecutore.datiResidenza;
  const datiResidenzaRappresLegale = datiRappLegale.datiResidenza;

  // COPY SEZIONE DATI RESIDENZA
  const datiResidenzaEsecutore = { ...datiResidenzaEsecut };
  const datiResidenzaRappLegale = { ...datiResidenzaRappresLegale };

  // SEZIONE DATI ANTIRICICLAGGIO
  const datiAntiriciclaggioEsecut = datiEsecutore.datiAntiriciclaggio;

  // COPY SEZIONE DATI RESIDENZA
  const datiAntiriciclaggioEsecutore = { ...datiAntiriciclaggioEsecut };

  // SEZIONE DATI DOMICILIO
  const datiDomicilioEsecutore = datiEsecutore.datiDomicilio;
  const datiDomicilioContrFis = contraenteFisico.datiDomicilio;
  const datiDomicilioRappLegale = datiRappLegale.datiDomicilio;

  // SEZIONE DATI CORRISPONDENZA 
  const datiCorrispondenzaContrFis = datiRecapito.datiCorrispondenza;
  const datiCorrispondenzaEsecutore = datiEsecutore.datiCorrispondenza;




  // DATI 
  let datiAttEconomica = null; // punta ai dati della figura selezionata
  let datiAntiriciclaggio = null; // punta ai dati della figura selezionata
  let datiConsensoComElettr = null; // punta ai dati della figura selezionata
  let datiGeneraliMinimi = null; // punta ai dati della figura selezionata 
  let datiGenerali = null; // punta ai dati della figura selezionata 
  let datiFiscali = null; // punta ai dati della figura selezionata
  let datiNaturaRapporto = null; // punta ai dati della figura selezionata
  let datiResidenza = null; // punta ai dati della figura selezionata
  let datiMandatoFiduciario = null; // punta ai dati della figura selezionata

  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;

  let figura = action.figura;
  if (figura === 'contraenteFisico') {
	const datiAntiriciclaggioContr = contraenteFisico.datiAntiriciclaggio;
	const datiConsensoComElettrContr = contraenteFisico.datiConsensoComElettr;
    const datiAttEconomicaContr = contraenteFisico.datiAttEconomica;
	const datiGeneraliContr = contraenteFisico.datiGenerali; 
	const datiFiscaliContr = contraenteFisico.datiFiscali;
	const datiNaturaRapportoContr = contraenteFisico.datiNaturaRapporto;
	  
	datiAntiriciclaggio = {...datiAntiriciclaggioContr};
	datiConsensoComElettr = {...datiConsensoComElettrContr};
	datiAttEconomica = {...datiAttEconomicaContr};
	datiGenerali = {...datiGeneraliContr};
	datiFiscali = {...datiFiscaliContr};
	datiNaturaRapporto = {...datiNaturaRapportoContr}
  } else if (figura === 'esecutoreFisico') {
    const datiAntiriciclaggioEsecut = datiEsecutore.datiAntiriciclaggio;
    const datiAttEconomicaEsecut = datiEsecutore.datiAttEconomica;

    datiAntiriciclaggio = { ...datiAntiriciclaggioEsecut };
    datiResidenza = { ...datiResidenzaEsecutore };
    datiAttEconomica = {...datiAttEconomicaEsecut};
  } else if (figura === 'contraenteGiuridico') {
    const datiConsensoComElettrGiuridico = contraenteGiuridico.datiConsensoComElettr;
    const datiAttEconomicaGiuridicoContr = contraenteGiuridico.datiAttEconomicaGiuridico;
    const datiFiscaliGiuridicoContr = contraenteGiuridico.datiFiscali;
    const datiNaturaRapportoGiuridicoContr = contraenteGiuridico.datiNaturaRapporto;
    const datiMandatoFiduciarioGiuridicoContr = contraenteGiuridico.datiMandatoFiduciario;

    datiConsensoComElettr = { ...datiConsensoComElettrGiuridico };
    datiAttEconomica = { ...datiAttEconomicaGiuridicoContr };
    datiMandatoFiduciario = {...datiMandatoFiduciarioGiuridicoContr};
    datiFiscali = { ...datiFiscaliGiuridicoContr };
    datiNaturaRapporto = { ...datiNaturaRapportoGiuridicoContr };
  } else if (figura === 'rappLegale') {
	  const datiRappLegale = contraenteGiuridico.datiRappLegale;
	  const datiGeneraliMinimiRappLegale = datiRappLegale.datiGeneraliMinimi;
    const datiAntiriciclaggioRappLegale = datiRappLegale.datiAntiriciclaggio;
    const datiAttEconomicaRappLegale = datiRappLegale.datiAttEconomica;
    
    datiAttEconomica = {...datiAttEconomicaRappLegale}
	  datiGeneraliMinimi = {...datiGeneraliMinimiRappLegale};	  
	  datiAntiriciclaggio = {...datiAntiriciclaggioRappLegale};
  } 
  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA:
      const contraenteFisicoInizializza = inizializzaContraenteFisico(state);
      const contraenteGiuridicoInizializza = inizializzaContraenteGiuridico(state);
      return {
        ...state,
        contraenteFisico: contraenteFisicoInizializza,
        contraenteGiuridico: contraenteGiuridicoInizializza,
      };
    case actionType.INIZIALIZZA_CONTRAENTE_FISICO:
      const contraenteFisicoInizializzaFisico = inizializzaContraenteFisico(state);
      return {
        ...state,
        contraenteFisico: contraenteFisicoInizializzaFisico,
      };
    case actionType.INIZIALIZZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoInizializzaGiuridico = inizializzaContraenteGiuridico(state);
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoInizializzaGiuridico,
      };
    /* CONTRAENTE FISICO */
    case actionType.SALVA_CONTRAENTE_FISICO:
      const contraenteFisicoSalva = { ...state }.contraenteFisico;
      contraenteFisicoSalva.controlloDati = action.unDato;
      //controlloDati = '' su contraenteGiuridico dopo salvataggio su contraenteFisico
      const contraenteGiuridicoSalvaControlloDati = { ...state }.contraenteGiuridico;
      contraenteGiuridicoSalvaControlloDati.controlloDati = '';
      return {
        ...state,
        contraenteFisico: contraenteFisicoSalva,
        contraenteGiuridico: contraenteGiuridicoSalvaControlloDati,
      };
    case actionType.UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_FISICO:
      //      console.log('#RT2 Reducer areaContraenteReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_FISICO); 
      //      console.log('#RT2 Reducer areaContraenteReducer.action', action);    
      const contraenteFisicoAnagraficaValida = { ...state }.contraenteFisico;
      contraenteFisicoAnagraficaValida.anagraficaValida = action.unDato;
      const contraenteGiuridicoAnagraficaValidaUpdate = { ...state }.contraenteGiuridico;
      contraenteGiuridicoAnagraficaValidaUpdate.anagraficaValida = null;
      return {
        ...state,
        contraenteFisico: contraenteFisicoAnagraficaValida,
        contraenteGiuridico: contraenteGiuridicoAnagraficaValidaUpdate,
      };
    /* DATI GENERALI CONTRAENTE FISICO */
    case actionType.UPDATE_CODICE_FISCALE_CONTRAENTE_FISICO:
      const contraenteFisicoFiscale = { ...state }.contraenteFisico;
      const datiGeneraliFiscale = { ...contraenteFisicoFiscale }.datiGenerali;
      datiGeneraliFiscale.codiceFiscale = action.unDato;
      contraenteFisicoFiscale.datiGenerali = { ...datiGeneraliFiscale };
      return {
        ...state,
        contraenteFisico: contraenteFisicoFiscale,
      };
    /*    case actionType.UPDATE_CONTROLLO_CODICE_FISCALE_CONTRAENTE_FISICO: 
            const contraenteFisicoFiscaleControllo = {...state}.contraenteFisico;
            const datiGeneraliFiscaleControllo = {...contraenteFisicoFiscaleControllo}.datiGenerali;
            datiGeneraliFiscaleControllo.controlloCodiceFiscale = action.unDato;
            contraenteFisicoFiscaleControllo.datiGenerali = {...datiGeneraliFiscaleControllo};
            return {
            ...state,
            contraenteFisico: contraenteFisicoFiscaleControllo,
            }; */
    case actionType.UPDATE_CODICE_FISCALE_VALIDO_CONTRAENTE_FISICO:
      const contraenteFisicoFiscaleControllo = { ...state }.contraenteFisico;
      const datiGeneraliFiscaleControllo = { ...contraenteFisicoFiscaleControllo }.datiGenerali;
      datiGeneraliFiscaleControllo.codiceFiscaleValido = action.unDato;
      contraenteFisicoFiscaleControllo.datiGenerali = { ...datiGeneraliFiscaleControllo };
      return {
        ...state,
        contraenteFisico: contraenteFisicoFiscaleControllo,
      };
    case actionType.UPDATE_NOME_CONTRAENTE_FISICO:
      const contraenteFisicoNome = { ...state }.contraenteFisico;
      const datiGeneraliNome = { ...contraenteFisicoNome }.datiGenerali;
      datiGeneraliNome.nome = action.unDato;
      contraenteFisicoNome.datiGenerali = { ...datiGeneraliNome };
      return {
        ...state,
        contraenteFisico: contraenteFisicoNome,
      };
    case actionType.UPDATE_COGNOME_CONTRAENTE_FISICO:
      const contraenteFisicoCognome = { ...state }.contraenteFisico;
      const datiGeneraliCognome = { ...contraenteFisicoCognome }.datiGenerali;
      datiGeneraliCognome.cognome = action.unDato;
      contraenteFisicoCognome.datiGenerali = { ...datiGeneraliCognome };
      return {
        ...state,
        contraenteFisico: contraenteFisicoCognome,
      };
    case actionType.UPDATE_SESSO_CONTRAENTE_FISICO:
      const contraenteFisicoSesso = { ...state }.contraenteFisico;
      const datiGeneraliSesso = { ...contraenteFisicoSesso }.datiGenerali;
      datiGeneraliSesso.sesso = action.unDato;
      contraenteFisicoSesso.datiGenerali = { ...datiGeneraliSesso };
      return {
        ...state,
        contraenteFisico: contraenteFisicoSesso,
      };
    case actionType.UPDATE_DATA_NASCITA_CONTRAENTE_FISICO:
      const unaData = action.unDato;
      const nuovaDataNascitaFormattata = dataFormat(unaData, 'DD/MM/YYYY');
      const contraenteFisicoDataNascita = { ...state }.contraenteFisico;
      const datiGeneraliDataNascita = { ...contraenteFisicoDataNascita }.datiGenerali;
      datiGeneraliDataNascita.dataNascita = unaData;
      datiGeneraliDataNascita.dataNascitaStampa = nuovaDataNascitaFormattata;
      datiGeneraliDataNascita.statoNascita = '';
      datiGeneraliDataNascita.descrStatoNascita = '';
      datiGeneraliDataNascita.provinciaNascita = '';
      datiGeneraliDataNascita.descrProvinciaNascita = '';
      datiGeneraliDataNascita.comuneNascita = '';
      datiGeneraliDataNascita.descrComuneNascita = '';
      //datiGeneraliDataNascita.cittadinanza1 = '';
      //datiGeneraliDataNascita.descrCittadinanza1 = '';
      //datiGeneraliDataNascita.cittadinanza2 = '';
      //datiGeneraliDataNascita.descrCittadinanza2 = '';
      contraenteFisicoDataNascita.datiGenerali = { ...datiGeneraliDataNascita };
      return {
        ...state,
        contraenteFisico: contraenteFisicoDataNascita,
      };
    case actionType.UPDATE_STATO_NASCITA_CONTRAENTE_FISICO:
      const contraenteFisicoStatoNascita = { ...state }.contraenteFisico;
      const datiGeneraliStatoNascita = { ...contraenteFisicoStatoNascita }.datiGenerali;
      datiGeneraliStatoNascita.statoNascita = unValore;
      datiGeneraliStatoNascita.descrStatoNascita = unaLabel;
      datiGeneraliStatoNascita.provinciaNascita = '';
      datiGeneraliStatoNascita.descrProvinciaNascita = '';
      datiGeneraliStatoNascita.comuneNascita = '';
      datiGeneraliStatoNascita.descrComuneNascita = '';
      contraenteFisicoStatoNascita.datiGenerali = { ...datiGeneraliStatoNascita };
      return {
        ...state,
        contraenteFisico: contraenteFisicoStatoNascita,
      };
    case actionType.UPDATE_PROVINCIA_NASCITA_CONTRAENTE_FISICO:
      const contraenteFisicoProvinciaNascita = { ...state }.contraenteFisico;
      const datiGeneraliProvinciaNascita = { ...contraenteFisicoProvinciaNascita }.datiGenerali;
      datiGeneraliProvinciaNascita.provinciaNascita = unValore;
      datiGeneraliProvinciaNascita.descrProvinciaNascita = unaLabel;
      datiGeneraliProvinciaNascita.comuneNascita = '';
      datiGeneraliProvinciaNascita.descrComuneNascita = '';
      contraenteFisicoProvinciaNascita.datiGenerali = { ...datiGeneraliProvinciaNascita };
      return {
        ...state,
        contraenteFisico: contraenteFisicoProvinciaNascita,
      };
    case actionType.UPDATE_COMUNE_NASCITA_CONTRAENTE_FISICO:
      const contraenteFisicoComuneNascita = { ...state }.contraenteFisico;
      const datiGeneraliComuneNascita = { ...contraenteFisicoComuneNascita }.datiGenerali;
      const contraenteFisicoProvNascita = datiGeneraliComuneNascita.provinciaNascita;
      if (contraenteFisicoProvNascita !== PROVINCIA_ESTERA) {
        datiGeneraliComuneNascita.comuneNascita = unValore;
        datiGeneraliComuneNascita.descrComuneNascita = unaLabel; // campo select
      } else {
        datiGeneraliComuneNascita.comuneNascita = '';
        datiGeneraliComuneNascita.descrComuneNascita = action.unDato;  // campo testo
      }
      contraenteFisicoComuneNascita.datiGenerali = { ...datiGeneraliComuneNascita };
      return {
        ...state,
        contraenteFisico: contraenteFisicoComuneNascita,
      };
    case actionType.UPDATE_CITTADINANZA_1_CONTRAENTE_FISICO:
      const contraenteFisicoCittadinanza1 = { ...state }.contraenteFisico;
      const datiGeneraliCittadinanza1 = { ...contraenteFisicoCittadinanza1 }.datiGenerali;
      datiGeneraliCittadinanza1.cittadinanza1 = unValore;
      datiGeneraliCittadinanza1.descrCittadinanza1 = unaLabel;
      contraenteFisicoCittadinanza1.datiGenerali = { ...datiGeneraliCittadinanza1 };
      return {
        ...state,
        contraenteFisico: contraenteFisicoCittadinanza1,
      };
    case actionType.UPDATE_CITTADINANZA_2_CONTRAENTE_FISICO:
      const contraenteFisicoCittadinanza2 = { ...state }.contraenteFisico;
      const datiGeneraliCittadinanza2 = { ...contraenteFisicoCittadinanza2 }.datiGenerali;
      datiGeneraliCittadinanza2.cittadinanza2 = unValore;
      datiGeneraliCittadinanza2.descrCittadinanza2 = unaLabel;
      contraenteFisicoCittadinanza2.datiGenerali = { ...datiGeneraliCittadinanza2 };
      return {
        ...state,
        contraenteFisico: contraenteFisicoCittadinanza2,
      };
    case actionType.UPDATE_NUM_TELEFONO_CONTRAENTE_FISICO:
      const contraenteFisicoNumTelefono = { ...state }.contraenteFisico;
      const datiGeneraliNumTelefono = { ...contraenteFisicoNumTelefono }.datiGenerali;
      datiGeneraliNumTelefono.numTelefono = action.unDato;
      contraenteFisicoNumTelefono.datiGenerali = { ...datiGeneraliNumTelefono };
      return {
        ...state,
        contraenteFisico: contraenteFisicoNumTelefono,
      };
    case actionType.UPDATE_TITOLO_STUDIO:
      const contraenteFisicoTitoloStudio = {...state}.contraenteFisico;
      const datiGeneraliTitoloStudio = {...contraenteFisicoTitoloStudio}.datiGenerali;
      datiGeneraliTitoloStudio.titoloStudio = unValore;
      datiGeneraliTitoloStudio.descrTitoloStudio = unaLabel;
      contraenteFisicoTitoloStudio.datiGenerali = {...datiGeneraliTitoloStudio};
      return {
        ...state,
        contraenteFisico: contraenteFisicoTitoloStudio,
      };
    case actionType.UPDATE_TITOLO_STUDIO_ALTRO:
        const contraenteFisicoAltro = {...state}.contraenteFisico;
        const datiGeneraliAltro = {...contraenteFisicoAltro}.datiGenerali;
        datiGeneraliAltro.altroTitoloStudio = action.unDato;
        contraenteFisicoAltro.datiGenerali = {...datiGeneraliAltro};
        return {
          ...state,
          contraenteFisico: contraenteFisicoAltro,
        }

    /* DATI RESIDENZA CONTRAENTE FISICO */
    case actionType.UPDATE_STATO_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoStatoResidenza = { ...state }.contraenteFisico;
      const datiResidenzaStatoResidenza = { ...contraenteFisicoStatoResidenza }.datiResidenza;
      datiResidenzaStatoResidenza.statoResidenza = unValore;
      datiResidenzaStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaStatoResidenza.provinciaResidenza = '';
      datiResidenzaStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaStatoResidenza.comuneResidenza = '';
      datiResidenzaStatoResidenza.descrComuneResidenza = '';
      datiResidenzaStatoResidenza.toponimoResidenza = '';
      datiResidenzaStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaStatoResidenza.descrIndirizzoResidenza = '';
      contraenteFisicoStatoResidenza.datiResidenza = { ...datiResidenzaStatoResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoStatoResidenza,
      };
    case actionType.UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoProvinciaResidenza = { ...state }.contraenteFisico;
      const datiResidenzaProvinciaResidenza = { ...contraenteFisicoProvinciaResidenza }.datiResidenza;
      datiResidenzaProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaProvinciaResidenza.comuneResidenza = '';
      datiResidenzaProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaProvinciaResidenza.descrIndirizzoResidenza = '';
      contraenteFisicoProvinciaResidenza.datiResidenza = { ...datiResidenzaProvinciaResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoProvinciaResidenza,
      };
    case actionType.UPDATE_COMUNE_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoComuneResidenza = { ...state }.contraenteFisico;
      const datiResidenzaComuneResidenza = { ...contraenteFisicoComuneResidenza }.datiResidenza;
      if (datiResidenzaComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaComuneResidenza.comuneResidenza = unValore;
        datiResidenzaComuneResidenza.descrComuneResidenza = unaLabel; // campo select
        datiResidenzaComuneResidenza.toponimoResidenza = '';
        datiResidenzaComuneResidenza.descrToponimoResidenza = '';
        //datiResidenzaComuneResidenza.indirizzoResidenza = '';
        //datiResidenzaComuneResidenza.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaComuneResidenza.comuneResidenza = '';
        datiResidenzaComuneResidenza.descrComuneResidenza = action.unDato; // campo testo
      }

      contraenteFisicoComuneResidenza.datiResidenza = { ...datiResidenzaComuneResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoComuneResidenza,
      };
    case actionType.UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoToponimoResidenza = { ...state }.contraenteFisico;
      const datiResidenzaToponimoResidenza = { ...contraenteFisicoToponimoResidenza }.datiResidenza;
      if (datiResidenzaToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaToponimoResidenza.toponimoResidenza = unValore;
        datiResidenzaToponimoResidenza.descrToponimoResidenza = unaLabel; // campo select
        //datiResidenzaToponimoResidenza.indirizzoResidenza = '';
        //datiResidenzaToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaToponimoResidenza.toponimoResidenza = '';
        datiResidenzaToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo
      }
      contraenteFisicoToponimoResidenza.datiResidenza = { ...datiResidenzaToponimoResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoToponimoResidenza,
      };
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoIndirizzoResidenza = { ...state }.contraenteFisico;
      const datiResidenzaIndirizzoResidenza = { ...contraenteFisicoIndirizzoResidenza }.datiResidenza;
      //datiResidenzaIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      contraenteFisicoIndirizzoResidenza.datiResidenza = { ...datiResidenzaIndirizzoResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoIndirizzoResidenza,
      };
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoNumeroCivicoResidenza = { ...state }.contraenteFisico;
      const datiResidenzaNumeroCivicoResidenza = { ...contraenteFisicoNumeroCivicoResidenza }.datiResidenza;
      datiResidenzaNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      contraenteFisicoNumeroCivicoResidenza.datiResidenza = { ...datiResidenzaNumeroCivicoResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoNumeroCivicoResidenza,
      };
    case actionType.UPDATE_CAP_RESIDENZA_CONTRAENTE_FISICO:
      const contraenteFisicoCapResidenza = { ...state }.contraenteFisico;
      const datiResidenzaCapResidenza = { ...contraenteFisicoCapResidenza }.datiResidenza;
      datiResidenzaCapResidenza.capResidenza = action.unDato;
      contraenteFisicoCapResidenza.datiResidenza = { ...datiResidenzaCapResidenza };
      return {
        ...state,
        contraenteFisico: contraenteFisicoCapResidenza,
      };
    /* DATI DOMICILIO CONTRAENTE FISICO */
    case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_CONTRAENTE_FISICO:
      datiDomicilioContrFis.domicilioDiversoResidenza = action.unDato;
      if(datiDomicilioContrFis.domicilioDiversoResidenza === 'N') {
        datiDomicilioContrFis.statoDomicilio = '';
        datiDomicilioContrFis.descrStatoDomicilio = '';
        datiDomicilioContrFis.provinciaDomicilio = '';
        datiDomicilioContrFis.descrProvinciaDomicilio = '';
        datiDomicilioContrFis.comuneDomicilio = '';
        datiDomicilioContrFis.descrComuneDomicilio = '';
        datiDomicilioContrFis.toponimoDomicilio = '';
        datiDomicilioContrFis.descrToponimoDomicilio = '';
        datiDomicilioContrFis.indirizzoDomicilio = '';
        datiDomicilioContrFis.numCivicoDomicilio = '';
        datiDomicilioContrFis.capDomicilio = '';
      }
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO:
      datiDomicilioContrFis.statoDomicilio = unValore;
      datiDomicilioContrFis.descrStatoDomicilio = unaLabel;
      datiDomicilioContrFis.provinciaDomicilio = '';
      datiDomicilioContrFis.descrProvinciaDomicilio = '';
      datiDomicilioContrFis.comuneDomicilio = '';
      datiDomicilioContrFis.descrComuneDomicilio = '';
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO:
      datiDomicilioContrFis.provinciaDomicilio = unValore;
      datiDomicilioContrFis.descrProvinciaDomicilio = unaLabel;
      datiDomicilioContrFis.comuneDomicilio = '';
      datiDomicilioContrFis.descrComuneDomicilio = '';
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO:
      if(datiDomicilioContrFis.provinciaDomicilio === PROVINCIA_ESTERA) {
        datiDomicilioContrFis.comuneDomicilio = '';
        datiDomicilioContrFis.descrComuneDomicilio = action.unDato;
      } else {
        datiDomicilioContrFis.comuneDomicilio = unValore;
        datiDomicilioContrFis.descrComuneDomicilio = unaLabel;
      }
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO:
      if(datiDomicilioContrFis.provinciaDomicilio === PROVINCIA_ESTERA) {
        datiDomicilioContrFis.toponimoDomicilio = '';
        datiDomicilioContrFis.descrToponimoDomicilio = action.unDato;
      } else { 
        datiDomicilioContrFis.toponimoDomicilio = unValore;
        datiDomicilioContrFis.descrToponimoDomicilio = unaLabel;
      }
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO:
      datiDomicilioContrFis.indirizzoDomicilio = action.unDato;
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO:
      datiDomicilioContrFis.numCivicoDomicilio = action.unDato;
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO:
      datiDomicilioContrFis.capDomicilio = action.unDato;
      contraenteFisico.datiDomicilio = {...datiDomicilioContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }

    /* DATI RESIDENZA ESECUTORE FISICO */
    case actionType.UPDATE_DATI_RESIDENZA_PER_ESECUTORE:
      datiResidenzaEsecut.statoResidenza = '';
      datiResidenzaEsecut.descrStatoResidenza = '';
      datiResidenzaEsecut.provinciaResidenza = '';
      datiResidenzaEsecut.descrProvinciaResidenza = '';
      datiResidenzaEsecut.comuneResidenza = '';
      datiResidenzaEsecut.descrComuneResidenza = '';
      datiResidenzaEsecut.toponimoResidenza = '';
      datiResidenzaEsecut.descrToponimoResidenza = '';
      datiResidenzaEsecut.indirizzoResidenza = '';
      datiResidenzaEsecut.descrIndirizzoResidenza = '';
      datiResidenzaEsecut.numCivicoResidenza = '';
      datiResidenzaEsecut.capResidenza = '';
      datiResidenzaEsecut.datiResidenza = { ...datiResidenzaEsecut };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };

    case actionType.UPDATE_STATO_RESIDENZA_ESECUTORE_FISICO:
      datiResidenzaEsecutore.statoResidenza = unValore;
      datiResidenzaEsecutore.descrStatoResidenza = unaLabel;
      datiResidenzaEsecutore.provinciaResidenza = '';
      datiResidenzaEsecutore.descrProvinciaResidenza = '';
      datiResidenzaEsecutore.comuneResidenza = '';
      datiResidenzaEsecutore.descrComuneResidenza = '';
      datiResidenzaEsecutore.toponimoResidenza = '';
      datiResidenzaEsecutore.descrToponimoResidenza = '';
      //datiResidenzaEsecutore.indirizzoResidenza = '';
      //datiResidenzaEsecutore.descrIndirizzoResidenza = '';
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_PROVINCIA_RESIDENZA_ESECUTORE_FISICO:
      datiResidenzaEsecutore.provinciaResidenza = unValore;
      datiResidenzaEsecutore.descrProvinciaResidenza = unaLabel;
      datiResidenzaEsecutore.comuneResidenza = '';
      datiResidenzaEsecutore.descrComuneResidenza = '';
      datiResidenzaEsecutore.toponimoResidenza = '';
      datiResidenzaEsecutore.descrToponimoResidenza = '';
      //datiResidenzaEsecutore.indirizzoResidenza = '';
      //datiResidenzaEsecutore.descrIndirizzoResidenza = '';
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_COMUNE_RESIDENZA_ESECUTORE_FISICO:
      if (datiResidenzaEsecutore.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaEsecutore.comuneResidenza = unValore;
        datiResidenzaEsecutore.descrComuneResidenza = unaLabel; // campo select
        datiResidenzaEsecutore.toponimoResidenza = '';
        datiResidenzaEsecutore.descrToponimoResidenza = '';
        //datiResidenzaEsecutore.indirizzoResidenza = '';
        //datiResidenzaEsecutore.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaEsecutore.comuneResidenza = '';
        datiResidenzaEsecutore.descrComuneResidenza = action.unDato; // campo testo
      }
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_TOPONIMO_RESIDENZA_ESECUTORE_FISICO:
      if (datiResidenzaEsecutore.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaEsecutore.toponimoResidenza = unValore;
        datiResidenzaEsecutore.descrToponimoResidenza = unaLabel; // campo select
        //datiResidenzaEsecutore.indirizzoResidenza = '';
        //datiResidenzaEsecutore.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaEsecutore.toponimoResidenza = '';
        datiResidenzaEsecutore.descrToponimoResidenza = action.unDato; // campo testo
      }
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_ESECUTORE_FISICO:
      //datiResidenzaEsecutore.indirizzoResidenza = unValore;
      //datiResidenzaEsecutore.descrIndirizzoResidenza = unaLabel;
      datiResidenzaEsecutore.descrIndirizzoResidenza = action.unDato;
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_ESECUTORE_FISICO:
      datiResidenzaEsecutore.numCivicoResidenza = action.unDato;
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_CAP_RESIDENZA_ESECUTORE_FISICO:
      datiResidenzaEsecutore.capResidenza = action.unDato;
      datiEsecutore.datiResidenza = { ...datiResidenzaEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
  
    /* DATI RECAPITO CONTRAENTE FISICO */
    case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_FISICO:
      const contraenteFisicoCorrispondenzaRes = { ...state }.contraenteFisico;
      const datiCorrispondenzaContrFis = { ...contraenteFisicoCorrispondenzaRes }.datiRecapito;
      datiCorrispondenzaContrFis.corrispondenzaDiversoResidenza = action.unDato;
      if(datiCorrispondenzaContrFis.corrispondenzaDiversoResidenza === 'N') {
        datiCorrispondenzaContrFis.corrispondenzaIntestatoContraente = '';
        datiCorrispondenzaContrFis.statoRecapito = '';
        datiCorrispondenzaContrFis.descrStatoRecapito = '';
        datiCorrispondenzaContrFis.provinciaRecapito = '';
        datiCorrispondenzaContrFis.descrProvinciaRecapito = '';
        datiCorrispondenzaContrFis.comuneRecapito = '';
        datiCorrispondenzaContrFis.descrComuneRecapito = '';
        datiCorrispondenzaContrFis.toponimoRecapito = '';
        datiCorrispondenzaContrFis.descrToponimoRecapito = '';
        datiCorrispondenzaContrFis.descrIndirizzoRecapito = '';
        datiCorrispondenzaContrFis.numCivicoRecapito = '';
        datiCorrispondenzaContrFis.capRecapito = '';
      }
      contraenteFisico.datiRecapito = {...datiCorrispondenzaContrFis};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_FISICO:  
    const contraenteFisicoCorrispondenzaIntestato = { ...state }.contraenteFisico;
    const datiCorrispondenzaIntestatoContrFis = { ...contraenteFisicoCorrispondenzaIntestato }.datiRecapito;
    datiCorrispondenzaIntestatoContrFis.corrispondenzaIntestatoContraente = action.unDato;
    datiCorrispondenzaIntestatoContrFis.presso = '';
    contraenteFisico.datiRecapito = {...datiCorrispondenzaIntestatoContrFis};
    return {
      ...state,
      contraenteFisico: contraenteFisico
    }
    case actionType.UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoStatoRecapito = { ...state }.contraenteFisico;
      const datiGeneraliStatoRecapito = { ...contraenteFisicoStatoRecapito }.datiRecapito;
      datiGeneraliStatoRecapito.statoRecapito = unValore;
      datiGeneraliStatoRecapito.descrStatoRecapito = unaLabel;
      datiGeneraliStatoRecapito.provinciaRecapito = '';
      datiGeneraliStatoRecapito.descrProvinciaRecapito = '';
      datiGeneraliStatoRecapito.comuneRecapito = '';
      datiGeneraliStatoRecapito.descrComuneRecapito = '';
      datiGeneraliStatoRecapito.toponimoRecapito = '';
      datiGeneraliStatoRecapito.descrToponimoRecapito = '';
      //datiGeneraliStatoRecapito.indirizzoRecapito = '';
      //datiGeneraliStatoRecapito.descrIndirizzoRecapito = '';
      contraenteFisicoStatoRecapito.datiRecapito = { ...datiGeneraliStatoRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoStatoRecapito,
      };
    case actionType.UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoProvinciaRecapito = { ...state }.contraenteFisico;
      const datiGeneraliProvinciaRecapito = { ...contraenteFisicoProvinciaRecapito }.datiRecapito;
      datiGeneraliProvinciaRecapito.provinciaRecapito = unValore;
      datiGeneraliProvinciaRecapito.descrProvinciaRecapito = unaLabel;
      datiGeneraliProvinciaRecapito.comuneRecapito = '';
      datiGeneraliProvinciaRecapito.descrComuneRecapito = '';
      datiGeneraliProvinciaRecapito.toponimoRecapito = '';
      datiGeneraliProvinciaRecapito.descrToponimoRecapito = '';
      //datiGeneraliProvinciaRecapito.indirizzoRecapito = '';
      //datiGeneraliProvinciaRecapito.descrIndirizzoRecapito = '';
      contraenteFisicoProvinciaRecapito.datiRecapito = { ...datiGeneraliProvinciaRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoProvinciaRecapito,
      };
    case actionType.UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoComuneRecapito = { ...state }.contraenteFisico;
      const datiGeneraliComuneRecapito = { ...contraenteFisicoComuneRecapito }.datiRecapito;
      if (datiGeneraliComuneRecapito.provinciaRecapito !== PROVINCIA_ESTERA) {
        datiGeneraliComuneRecapito.comuneRecapito = unValore;
        datiGeneraliComuneRecapito.descrComuneRecapito = unaLabel;
        datiGeneraliComuneRecapito.toponimoRecapito = '';
        datiGeneraliComuneRecapito.descrToponimoRecapito = '';
        //datiGeneraliComuneRecapito.indirizzoRecapito = '';
        //datiGeneraliComuneRecapito.descrIndirizzoRecapito = '';
      } else {
        datiGeneraliComuneRecapito.comuneRecapito = '';
        datiGeneraliComuneRecapito.descrComuneRecapito = action.unDato;
      }
      contraenteFisicoComuneRecapito.datiRecapito = { ...datiGeneraliComuneRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoComuneRecapito,
      };
    case actionType.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoToponimoRecapito = { ...state }.contraenteFisico;
      const datiGeneraliToponimoRecapito = { ...contraenteFisicoToponimoRecapito }.datiRecapito;
      if (datiGeneraliToponimoRecapito.provinciaRecapito !== PROVINCIA_ESTERA) {
        datiGeneraliToponimoRecapito.toponimoRecapito = unValore;
        datiGeneraliToponimoRecapito.descrToponimoRecapito = unaLabel;
        //datiGeneraliToponimoRecapito.indirizzoRecapito = '';
        //datiGeneraliToponimoRecapito.descrIndirizzoRecapito = '';
      } else {
        datiGeneraliToponimoRecapito.toponimoRecapito = '';
        datiGeneraliToponimoRecapito.descrToponimoRecapito = action.unDato;
      }
      contraenteFisicoToponimoRecapito.datiRecapito = { ...datiGeneraliToponimoRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoToponimoRecapito,
      };
    case actionType.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoIndirizzoRecapito = { ...state }.contraenteFisico;
      const datiGeneraliIndirizzoRecapito = { ...contraenteFisicoIndirizzoRecapito }.datiRecapito;
      //datiGeneraliIndirizzoRecapito.indirizzoRecapito = unValore;
      //datiGeneraliIndirizzoRecapito.descrIndirizzoRecapito = unaLabel;
      datiGeneraliIndirizzoRecapito.descrIndirizzoRecapito = action.unDato;
      contraenteFisicoIndirizzoRecapito.datiRecapito = { ...datiGeneraliIndirizzoRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoIndirizzoRecapito,
      };
    case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoNumeroCivicoRecapito = { ...state }.contraenteFisico;
      const datiGeneraliNumeroCivicoRecapito = { ...contraenteFisicoNumeroCivicoRecapito }.datiRecapito;
      datiGeneraliNumeroCivicoRecapito.numCivicoRecapito = action.unDato;
      contraenteFisicoNumeroCivicoRecapito.datiRecapito = { ...datiGeneraliNumeroCivicoRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoNumeroCivicoRecapito,
      };
    case actionType.UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO:
      const contraenteFisicoCapRecapito = { ...state }.contraenteFisico;
      const datiGeneraliCapRecapito = { ...contraenteFisicoCapRecapito }.datiRecapito;
      datiGeneraliCapRecapito.capRecapito = action.unDato;
      contraenteFisicoCapRecapito.datiRecapito = { ...datiGeneraliCapRecapito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoCapRecapito,
      };
    case actionType.UPDATE_PRESSO_CONTR_FISICO:  
      const contraenteFisicoPresso = {...state}.contraenteFisico;
      const datiGeneraliPresso     = {...contraenteFisicoPresso}.datiRecapito;
      datiGeneraliPresso.presso = action.unDato;
      contraenteFisicoPresso.datiRecapito = {...datiGeneraliPresso};
      return {
        ...state,
        contraenteFisico: contraenteFisicoPresso,
      };
    /* DATI FINANZIARI CONTRAENTE FISICO */
    case actionType.UPDATE_FONTE_REDDITO:
      const contraenteFisicoDatiFinanziariContr = { ...state }.contraenteFisico;
      const contraenteFisicoFonteReddito = { ...contraenteFisicoDatiFinanziariContr };
      const datiFinanziariFonteReddito = { ...contraenteFisicoFonteReddito }.datiFinanziari;
      datiFinanziariFonteReddito.codFonteReddito = unValore;
      datiFinanziariFonteReddito.descFonteReddito = unaLabel;
      datiFinanziariFonteReddito.altro = '';
      contraenteFisicoDatiFinanziariContr.datiFinanziari = { ...datiFinanziariFonteReddito };
      return {
        ...state,
        contraenteFisico: contraenteFisicoDatiFinanziariContr
      }
    case actionType.UPDATE_FONTE_REDDITO_ALTRO:
      const contraenteFisicoFonteAltroContr = { ...state }.contraenteFisico;
      const contraenteFisicoDatiFinanziariContrFonteAltro = { ...contraenteFisicoFonteAltroContr }.datiFinanziari;
      contraenteFisicoDatiFinanziariContrFonteAltro.altro = action.unDato;
      contraenteFisicoFonteAltroContr.datiFinanziari = { ...contraenteFisicoDatiFinanziariContrFonteAltro };
      return {
        ...state,
        contraenteFisico: contraenteFisicoFonteAltroContr
      }
    case actionType.UPDATE_REDDITO_ANNUO:
      const contraenteFisicoRedditoAnnuoContr = { ...state }.contraenteFisico;
      const contraenteFisicoRedditoAnnuoDati = { ...contraenteFisicoRedditoAnnuoContr }.datiFinanziari;
      contraenteFisicoRedditoAnnuoDati.codRedditoAnnuo = unValore;
      contraenteFisicoRedditoAnnuoDati.descRedditoAnnuo = unaLabel;
      contraenteFisicoRedditoAnnuoContr.datiFinanziari = { ...contraenteFisicoRedditoAnnuoDati };
      return {
        ...state,
        contraenteFisico: contraenteFisicoRedditoAnnuoContr
      }
    case actionType.UPDATE_PRIMA_CASA:
      const contraenteFisicoPrimaCasaContr = { ...state }.contraenteFisico;
      const contraenteFisicoPrimaCasaDati = { ...contraenteFisicoPrimaCasaContr }.datiFinanziari;
      contraenteFisicoPrimaCasaDati.primaCasa = action.unDato;
      contraenteFisicoPrimaCasaContr.datiFinanziari = { ...contraenteFisicoPrimaCasaDati };
      return {
        ...state,
        contraenteFisico: contraenteFisicoPrimaCasaContr
      }
    case actionType.UPDATE_ALTRI_IMMOBILI:
      const contraenteFisicoAltriImmobiliContr = { ...state }.contraenteFisico;
      const contraenteFisicoAltriImmobiliDati = { ...contraenteFisicoAltriImmobiliContr }.datiFinanziari;
      contraenteFisicoAltriImmobiliDati.altriImmobili = action.unDato;
      contraenteFisicoAltriImmobiliContr.datiFinanziari = { ...contraenteFisicoAltriImmobiliDati };
      return {
        ...state,
        contraenteFisico: contraenteFisicoAltriImmobiliContr
      }
    case actionType.UPDATE_PATRIMONIO_FINANZIARIO:
      const contraenteFisicoPatrFinContr = { ...state }.contraenteFisico;
      const contraenteFisicoPatrFinDati = { ...contraenteFisicoPatrFinContr }.datiFinanziari;
      contraenteFisicoPatrFinDati.codPatrimonioFinanziario = unValore;
      contraenteFisicoPatrFinDati.descPatrimonioFinanziario = unaLabel;
      contraenteFisicoPatrFinContr.datiFinanziari = { ...contraenteFisicoPatrFinDati };
      return {
        ...state,
        contraenteFisico: contraenteFisicoPatrFinContr
      };
    case actionType.UPDATE_IMPEGNO_FINANZIARIO:
      const contraenteFisicoImpFinContr = { ...state }.contraenteFisico;
      const contraenteFisicoImpFinDati = { ...contraenteFisicoImpFinContr }.datiFinanziari;
      contraenteFisicoImpFinDati.codImpegnoFinanziario = unValore;
      contraenteFisicoImpFinDati.descImpegnoFinanziario = unaLabel;
      contraenteFisicoImpFinContr.datiFinanziari = { ...contraenteFisicoImpFinDati };
      return {
        ...state,
        contraenteFisico: contraenteFisicoImpFinContr
      }
    case actionType.UPDATE_MERCATO_MONETARIO:
      const contraenteFisicoMercMonContr = { ...state }.contraenteFisico;
      const contraenteFisicoMercMonDati = { ...contraenteFisicoMercMonContr }.datiFinanziari;
      const contraenteFisicoMercMonRipa = { ...contraenteFisicoMercMonDati }.ripartizioneInvestimentiFinanziari;
      contraenteFisicoMercMonRipa.strumentiMercatoMonetario = action.unDato;
      contraenteFisicoMercMonDati.ripartizioneInvestimentiFinanziari = { ...contraenteFisicoMercMonRipa };
      contraenteFisicoMercMonContr.datiFinanziari = { ...contraenteFisicoMercMonDati };
      return {
        ...state,
        contraenteFisico: contraenteFisicoMercMonContr
      }
    case actionType.UPDATE_STRUMENTI_OBBLIGAZIONARI:
      const contraenteFisicoObbliContr = {...state}.contraenteFisico;
      const contraenteFisicoObbliDati = {...contraenteFisicoObbliContr}.datiFinanziari;
      const contraenteFisicoObbliRipa = {...contraenteFisicoObbliDati}.ripartizioneInvestimentiFinanziari;
      contraenteFisicoObbliRipa.strumentiObbligazionari = action.unDato;
      contraenteFisicoObbliDati.ripartizioneInvestimentiFinanziari = {...contraenteFisicoObbliRipa};
      contraenteFisicoObbliContr.datiFinanziari = {...contraenteFisicoObbliDati};
      return {
        ...state,
        contraenteFisico: contraenteFisicoObbliContr
      }
    case actionType.UPDATE_STRUMENTI_AZIONARI:
        const contraenteFisicoAzioContr = {...state}.contraenteFisico;
        const contraenteFisicoAzioDati = {...contraenteFisicoAzioContr}.datiFinanziari;
        const contraenteFisicoAzioRipa = {...contraenteFisicoAzioDati}.ripartizioneInvestimentiFinanziari;
        contraenteFisicoAzioRipa.strumentiAzionari = action.unDato;
        contraenteFisicoAzioDati.ripartizioneInvestimentiFinanziari = {...contraenteFisicoAzioRipa};
        contraenteFisicoAzioContr.datiFinanziari = {...contraenteFisicoAzioDati};
        return {
          ...state,
          contraenteFisico: contraenteFisicoAzioContr
        }
      case actionType.UPDATE_STRUMENTI_ASSICURATIVI:
        const contraenteFisicoAssContr = {...state}.contraenteFisico;
        const contraenteFisicoAssDati = {...contraenteFisicoAssContr}.datiFinanziari;
        const contraenteFisicoAssRipa = {...contraenteFisicoAssDati}.ripartizioneInvestimentiFinanziari;
        contraenteFisicoAssRipa.strumentiAssicurativi = action.unDato;
        contraenteFisicoAssDati.ripartizioneInvestimentiFinanziari = {...contraenteFisicoAssRipa};
        contraenteFisicoAssContr.datiFinanziari = {...contraenteFisicoAssDati};
        return {
          ...state,
          contraenteFisico: contraenteFisicoAssContr
        }
      case actionType.UPDATE_STRUMENTI_COMPLESSI:
          const contraenteFisicoComlContr = {...state}.contraenteFisico;
          const contraenteFisicoComplDati = {...contraenteFisicoComlContr}.datiFinanziari;
          const contraenteFisicoComplRipa = {...contraenteFisicoComplDati}.ripartizioneInvestimentiFinanziari;
          contraenteFisicoComplRipa.strumentiComplessi = action.unDato;
          contraenteFisicoComplDati.ripartizioneInvestimentiFinanziari = {...contraenteFisicoComplRipa};
          contraenteFisicoComlContr.datiFinanziari = {...contraenteFisicoComplDati};
          return {
            ...state,
            contraenteFisico: contraenteFisicoComlContr,
          };   
    /* DATI FISCALI CONTRAENTE FISICO */
    /*      
        case actionType.UPDATE_TASSE_STATI_UNITI_CONTRAENTE_FISICO:
          const contraenteFisicoTasse = {...state}.contraenteFisico;
          const datiFiscaliTasseFisico = {...contraenteFisicoTasse}.datiFiscali;
          datiFiscaliTasseFisico.tasseStatiUniti = action.unDato;
          contraenteFisicoTasse.datiFiscali = {...datiFiscaliTasseFisico};
          return {
            ...state,
            contraenteFisico: contraenteFisicoTasse,
          };  

        case actionType.UPDATE_TIN_GIIN_CONTRAENTE_FISICO:
          const contraenteFisicoTinGiin = {...state}.contraenteFisico;
          const datiFiscaliTinGiin = {...contraenteFisicoTinGiin}.datiFiscali;
          datiFiscaliTinGiin.tinGiin = action.unDato;
          contraenteFisicoTinGiin.datiFiscali = {...datiFiscaliTinGiin};
          return {
            ...state,
            contraenteFisico: contraenteFisicoTinGiin,
          };     
        case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_CONTRAENTE_FISICO:
          const contraenteFisicoStatoResFiscEstera = {...state}.contraenteFisico;
          const datiFiscaliStatoResFiscEstera = {...contraenteFisicoStatoResFiscEstera}.datiFiscali;
          datiFiscaliStatoResFiscEstera.statoResidenzaFiscaleEstera = action.unDato;
          contraenteFisicoStatoResFiscEstera.datiFiscali = {...datiFiscaliStatoResFiscEstera};
          return {
            ...state,
            contraenteFisico: contraenteFisicoStatoResFiscEstera,
          };          
        case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_CONTRAENTE_FISICO:
          const contraenteFisicoStatoResFiscale1 = {...state}.contraenteFisico;
          const datiFiscaliStatoResFiscale1 = {...contraenteFisicoStatoResFiscale1}.datiFiscali;
          datiFiscaliStatoResFiscale1.statoResFiscale1 = action.unDato.value;
          datiFiscaliStatoResFiscale1.descrStatoResFiscale1 = action.unDato.label;
          contraenteFisicoStatoResFiscale1.datiFiscali = {...datiFiscaliStatoResFiscale1};
          return {
            ...state,
            contraenteFisico: contraenteFisicoStatoResFiscale1,
          };   
        case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_CONTRAENTE_FISICO:
          const contraenteFisicoNifResFiscale1 = {...state}.contraenteFisico;
          const datiFiscaliNifResFiscale1 = {...contraenteFisicoNifResFiscale1}.datiFiscali;
          datiFiscaliNifResFiscale1.nifResFiscale1 = action.unDato;
          contraenteFisicoNifResFiscale1.datiFiscali = {...datiFiscaliNifResFiscale1};
          return {
            ...state,
            contraenteFisico: contraenteFisicoNifResFiscale1,
          };  
        case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_CONTRAENTE_FISICO:
          const contraenteFisicoStatoResFiscale2 = {...state}.contraenteFisico;
          const datiFiscaliStatoResFiscale2 = {...contraenteFisicoStatoResFiscale2}.datiFiscali;
          datiFiscaliStatoResFiscale2.statoResFiscale2 = action.unDato.value;
          datiFiscaliStatoResFiscale2.descrStatoResFiscale2 = action.unDato.label;
          contraenteFisicoStatoResFiscale2.datiFiscali = {...datiFiscaliStatoResFiscale2};
          return {
            ...state,
            contraenteFisico: contraenteFisicoStatoResFiscale2,
          };   
        case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_CONTRAENTE_FISICO:
          const contraenteFisicoNifResFiscale2 = {...state}.contraenteFisico;
          const datiFiscaliNifResFiscale2 = {...contraenteFisicoNifResFiscale2}.datiFiscali;
          datiFiscaliNifResFiscale2.nifResFiscale2 = action.unDato;
          contraenteFisicoNifResFiscale2.datiFiscali = {...datiFiscaliNifResFiscale2};
          return {
            ...state,
            contraenteFisico: contraenteFisicoNifResFiscale2,
          };    */ 

    /* 
        case actionType.UPDATE_FL_CONSENSO_COM_ELETTR_CONTRAENTE_FISICO: 
          const contraenteFisicoFlConsensoComElettr = {...state}.contraenteFisico;
          const contraenteFisicoDatiConsensoFlConsensoComElettr = {...contraenteFisicoFlConsensoComElettr}.datiConsensoComElettr;
          contraenteFisicoDatiConsensoFlConsensoComElettr.flConsensoComElettr = action.unDato;
          contraenteFisicoFlConsensoComElettr.datiConsensoComElettr = {...contraenteFisicoDatiConsensoFlConsensoComElettr};
          return {
            ...state,
            contraenteFisico: contraenteFisicoFlConsensoComElettr,
          };   
        case actionType.UPDATE_EMAIL_CONSENSO_COM_ELETTR_CONTRAENTE_FISICO:
          const contraenteFisicoEmailConsensoComElettr = {...state}.contraenteFisico;
          const contraenteFisicoDatiConsensoEmailConsensoComElettr = {...contraenteFisicoEmailConsensoComElettr}.datiConsensoComElettr;    	
          contraenteFisicoDatiConsensoEmailConsensoComElettr.emailConsensoComElettr = action.unDato;
        contraenteFisicoEmailConsensoComElettr.datiConsensoComElettr = {...contraenteFisicoDatiConsensoEmailConsensoComElettr};
          return {
            ...state,
            contraenteFisico: contraenteFisicoEmailConsensoComElettr,
          };
    */
    /* DATI CONSENSO COMUNICAZIONI ELETTRONICHE CONTRAENTE FISICO */
    case actionType.UPDATE_FL_CONSENSO_COM_ELETTR:
      datiConsensoComElettr.flConsensoComElettr = action.unDato;
      return resolveDatiConsensoComElettr(state, figura, datiConsensoComElettr);
    case actionType.UPDATE_EMAIL_CONSENSO_COM_ELETTR:
      datiConsensoComElettr.emailConsensoComElettr = action.unDato;
      return resolveDatiConsensoComElettr(state, figura, datiConsensoComElettr);

    /* CONTRAENTE GIURIDICO */
    case actionType.SALVA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoSalva = { ...state }.contraenteGiuridico;
      contraenteGiuridicoSalva.controlloDati = action.unDato;
      //controlloDati = '' su contraenteFisico dopo salvataggio su contraenteGiuridico
      const contraenteFisicoSalvaControlloDati = { ...state }.contraenteFisico;
      contraenteFisicoSalvaControlloDati.controlloDati = '';
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoSalva,
        contraenteFisico: contraenteFisicoSalvaControlloDati,
      };
    case actionType.UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_GIURIDICO:
      //        console.log('#RT2 Reducer areaContraenteReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_GIURIDICO); 
      //        console.log('#RT2 Reducer areaContraenteReducer.action', action);    
      const contraenteGiuridicoAnagraficaValida = { ...state }.contraenteGiuridico;
      contraenteGiuridicoAnagraficaValida.anagraficaValida = action.unDato;
      const contraenteFisicoAnagraficaValidaUpdate = { ...state }.contraenteFisico;
      contraenteFisicoAnagraficaValidaUpdate.anagraficaValida = null;
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoAnagraficaValida,
        contraenteFisico: contraenteFisicoAnagraficaValidaUpdate,
      };

    /* DATI GENERALI CONTRAENTE GIURIDICO */
    case actionType.UPDATE_RAGIONE_SOCIALE_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoRagioneSociale = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoRagioneSociale = { ...contraenteGiuridicoRagioneSociale }.datiGenerali;
      datiGeneraliGiuridicoRagioneSociale.ragioneSociale = action.unDato;
      contraenteGiuridicoRagioneSociale.datiGenerali = { ...datiGeneraliGiuridicoRagioneSociale };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoRagioneSociale,
      };
    case actionType.UPDATE_CODICE_FISCALE_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoFiscale = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoFiscale = { ...contraenteGiuridicoFiscale }.datiGenerali;
      datiGeneraliGiuridicoFiscale.codiceFiscale = action.unDato;
      contraenteGiuridicoFiscale.datiGenerali = { ...datiGeneraliGiuridicoFiscale };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoFiscale,
      };
    case actionType.UPDATE_DATA_COSTITUZIONE_CONTRAENTE_GIURIDICO:
      const unaDataNascitaGiuridica = action.unDato;
      const nuovaDataNascitaGiuridicaFormattata = dataFormat(unaDataNascitaGiuridica, 'DD/MM/YYYY');
      const contraenteGiuridicoDataCostituzione = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoDataCostituzione = { ...contraenteGiuridicoDataCostituzione }.datiGenerali;
      datiGeneraliGiuridicoDataCostituzione.dataNascita = unaDataNascitaGiuridica;
      datiGeneraliGiuridicoDataCostituzione.dataNascitaStampa = nuovaDataNascitaGiuridicaFormattata;
      contraenteGiuridicoDataCostituzione.datiGenerali = { ...datiGeneraliGiuridicoDataCostituzione };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoDataCostituzione,
      };
    /* DATI SOCIETARI CONTRAENTE GIURIDICO */
    case actionType.ISCRIZIONE_REGISTRO:
      const contraenteGiuridicoIscrizionePersGiu = { ...state }.contraenteGiuridico;
      const datiIscrizioneRegistroPersoneGiuridiche = { ...contraenteGiuridicoIscrizionePersGiu }.datiSocietari;
      datiIscrizioneRegistroPersoneGiuridiche.codTipoIscrRegistro = unValore;
      datiIscrizioneRegistroPersoneGiuridiche.descTipoIscrRegistro = unaLabel;
      contraenteGiuridicoIscrizionePersGiu.datiSocietari = { ...datiIscrizioneRegistroPersoneGiuridiche };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoIscrizionePersGiu,
      }

    case actionType.NUMERO_REGISTRO:
      const contraenteGiuridicoNumRegistro = { ...state }.contraenteGiuridico;
      const datiNumeroRegistro = { ...contraenteGiuridicoNumRegistro }.datiSocietari;
      datiNumeroRegistro.numeroRegistro = action.unDato;
      contraenteGiuridicoNumRegistro.datiSocietari = { ...datiNumeroRegistro };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoNumRegistro,
      }
    /*case actionType.NUMERO_REGISTRO_PERSONE_GIURIDICHE:
      const contraenteGiuridicoNumPersGiuridiche = { ...state }.contraenteGiuridico;
      const datiNumRegistroPersGiuridiche = { ...contraenteGiuridicoNumPersGiuridiche }.datiSocietari;
      datiNumRegistroPersGiuridiche.numeroRegistro = action.unDato;
      contraenteGiuridicoNumPersGiuridiche.datiSocietari = { ...datiNumRegistroPersGiuridiche };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoNumPersGiuridiche,
      }*/
    case actionType.UPDATE_NOME_ALBO:
      const contraenteGiuridicoUpdateNomeAlbo = { ...state }.contraenteGiuridico;
      const datiUpdateNomeAlbo = { ...contraenteGiuridicoUpdateNomeAlbo }.datiSocietari;
      datiUpdateNomeAlbo.nomeAlbo = action.unDato;
      contraenteGiuridicoUpdateNomeAlbo.datiSocietari = { ...datiUpdateNomeAlbo };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoUpdateNomeAlbo,
      }
    case actionType.ISCRIZIONE_ALBO:
      const contraenteGiuridicoIscrAlbo = {...state}.contraenteGiuridico;
      const datiUpdateTipoIscrAlbo = {...contraenteGiuridicoIscrAlbo}.datiSocietari;
      datiUpdateTipoIscrAlbo.codTipoIscrAlbo = unValore;
      datiUpdateTipoIscrAlbo.descTipoIscrAlbo= unaLabel;
      contraenteGiuridicoIscrAlbo.datiSocietari = {...datiUpdateTipoIscrAlbo};
      return{
        ...state,
        contraenteGiuridico: contraenteGiuridicoIscrAlbo,
      }

    case actionType.DATA_ISCRIZIONE_ALBO: 
        const dataIscrizioneAlboDato = action.unDato;
        const nuovaDataIscrizioneAlboFormattata = dataFormat(dataIscrizioneAlboDato, 'DD/MM/YYYY');
        const contraenteGiurdicoDataIscrzAlbo = {...state}.contraenteGiuridico;
        const datiDataIscrizioneAlbo = {...contraenteGiurdicoDataIscrzAlbo}.datiSocietari;
        datiDataIscrizioneAlbo.dataIscrizioneAlbo = dataIscrizioneAlboDato;
        datiDataIscrizioneAlbo.dataIscrizioneAlboStampa = nuovaDataIscrizioneAlboFormattata;
        contraenteGiurdicoDataIscrzAlbo.datiSocietari= {...datiDataIscrizioneAlbo};
        return{
          ...state,
          contraenteGiuridico: contraenteGiurdicoDataIscrzAlbo,
        }
    case  actionType.NUMERO_ISCRIZIONE_ALBO:    
          const contraenteGiuridicoNumIscrizioneAlbo = {...state}.contraenteGiuridico;
          const datiNumeroIscrizioneAlbo = {...contraenteGiuridicoNumIscrizioneAlbo}.datiSocietari;
          datiNumeroIscrizioneAlbo.numeroIscrizioneAlbo = action.unDato;
          contraenteGiuridicoNumIscrizioneAlbo.datiSocietari= {...datiNumeroIscrizioneAlbo};
          return{
            ...state,
            contraenteGiuridico: contraenteGiuridicoNumIscrizioneAlbo,
          }

    
    /* DATI RESIDENZA CONTRAENTE GIURIDICO */
    case actionType.UPDATE_STATO_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoStatoResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoStatoResidenza = { ...contraenteGiuridicoStatoResidenza }.datiResidenza;
      datiResidenzaGiuridicoStatoResidenza.statoResidenza = unValore;
      datiResidenzaGiuridicoStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaGiuridicoStatoResidenza.provinciaResidenza = '';
      datiResidenzaGiuridicoStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaGiuridicoStatoResidenza.comuneResidenza = '';
      datiResidenzaGiuridicoStatoResidenza.descrComuneResidenza = '';
      datiResidenzaGiuridicoStatoResidenza.toponimoResidenza = '';
      datiResidenzaGiuridicoStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaGiuridicoStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaGiuridicoStatoResidenza.descrIndirizzoResidenza = '';
      contraenteGiuridicoStatoResidenza.datiResidenza = { ...datiResidenzaGiuridicoStatoResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoStatoResidenza,
      };
    case actionType.UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoProvinciaResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoProvinciaResidenza = { ...contraenteGiuridicoProvinciaResidenza }.datiResidenza;
      datiResidenzaGiuridicoProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaGiuridicoProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaGiuridicoProvinciaResidenza.comuneResidenza = '';
      datiResidenzaGiuridicoProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaGiuridicoProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaGiuridicoProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaGiuridicoProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaGiuridicoProvinciaResidenza.descrIndirizzoResidenza = '';
      contraenteGiuridicoProvinciaResidenza.datiResidenza = { ...datiResidenzaGiuridicoProvinciaResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoProvinciaResidenza,
      };
    case actionType.UPDATE_COMUNE_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoComuneResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoComuneResidenza = { ...contraenteGiuridicoComuneResidenza }.datiResidenza;
      if (datiResidenzaGiuridicoComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaGiuridicoComuneResidenza.comuneResidenza = unValore;
        datiResidenzaGiuridicoComuneResidenza.descrComuneResidenza = unaLabel; // campo select
        datiResidenzaGiuridicoComuneResidenza.toponimoResidenza = '';
        datiResidenzaGiuridicoComuneResidenza.descrToponimoResidenza = '';
        //datiResidenzaGiuridicoComuneResidenza.indirizzoResidenza = '';
        //datiResidenzaGiuridicoComuneResidenza.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaGiuridicoComuneResidenza.comuneResidenza = '';
        datiResidenzaGiuridicoComuneResidenza.descrComuneResidenza = action.unDato // campo testo
      }
      contraenteGiuridicoComuneResidenza.datiResidenza = { ...datiResidenzaGiuridicoComuneResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoComuneResidenza,
      };
    case actionType.UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoToponimoResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoToponimoResidenza = { ...contraenteGiuridicoToponimoResidenza }.datiResidenza;
      if (datiResidenzaGiuridicoToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaGiuridicoToponimoResidenza.toponimoResidenza = unValore;
        datiResidenzaGiuridicoToponimoResidenza.descrToponimoResidenza = unaLabel; // campo select
        //datiResidenzaGiuridicoToponimoResidenza.indirizzoResidenza = '';
        //datiResidenzaGiuridicoToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaGiuridicoToponimoResidenza.toponimoResidenza = '';
        datiResidenzaGiuridicoToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo
      }
      contraenteGiuridicoToponimoResidenza.datiResidenza = { ...datiResidenzaGiuridicoToponimoResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoToponimoResidenza,
      };
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoIndirizzoResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoIndirizzoResidenza = { ...contraenteGiuridicoIndirizzoResidenza }.datiResidenza;
      //datiResidenzaGiuridicoIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaGiuridicoIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaGiuridicoIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      contraenteGiuridicoIndirizzoResidenza.datiResidenza = { ...datiResidenzaGiuridicoIndirizzoResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoIndirizzoResidenza,
      };
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoNumeroCivicoResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoNumeroCivicoResidenza = { ...contraenteGiuridicoNumeroCivicoResidenza }.datiResidenza;
      datiResidenzaGiuridicoNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      contraenteGiuridicoNumeroCivicoResidenza.datiResidenza = { ...datiResidenzaGiuridicoNumeroCivicoResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoNumeroCivicoResidenza,
      };
    case actionType.UPDATE_CAP_RESIDENZA_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoCapResidenza = { ...state }.contraenteGiuridico;
      const datiResidenzaGiuridicoCapResidenza = { ...contraenteGiuridicoCapResidenza }.datiResidenza;
      datiResidenzaGiuridicoCapResidenza.capResidenza = action.unDato;
      contraenteGiuridicoCapResidenza.datiResidenza = { ...datiResidenzaGiuridicoCapResidenza };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoCapResidenza,
      };
    /* DATI FISCALI CONTRAENTE GIURIDICO */
    /*      
        case actionType.UPDATE_TASSE_STATI_UNITI_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoTasse = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoTasseGiuridico = {...contraenteGiuridicoTasse}.datiFiscali;
          datiFiscaliGiuridicoTasseGiuridico.tasseStatiUniti = action.unDato;
          contraenteGiuridicoTasse.datiFiscali = {...datiFiscaliGiuridicoTasseGiuridico};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoTasse,
          };   
        case actionType.UPDATE_TIN_GIIN_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoTinGiin = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoTinGiin = {...contraenteGiuridicoTinGiin}.datiFiscali;
          datiFiscaliGiuridicoTinGiin.tinGiin = action.unDato;
          contraenteGiuridicoTinGiin.datiFiscali = {...datiFiscaliGiuridicoTinGiin};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoTinGiin,
          };     
        case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoStatoResFiscEstera = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoStatoResFiscEstera = {...contraenteGiuridicoStatoResFiscEstera}.datiFiscali;
          datiFiscaliGiuridicoStatoResFiscEstera.statoResidenzaFiscaleEstera = action.unDato;
          contraenteGiuridicoStatoResFiscEstera.datiFiscali = {...datiFiscaliGiuridicoStatoResFiscEstera};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoStatoResFiscEstera,
          };          
        case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoStatoResFiscale1 = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoStatoResFiscale1 = {...contraenteGiuridicoStatoResFiscale1}.datiFiscali;
          datiFiscaliGiuridicoStatoResFiscale1.statoResFiscale1 = action.unDato.value;
          datiFiscaliGiuridicoStatoResFiscale1.descrStatoResFiscale1 = action.unDato.label;
          contraenteGiuridicoStatoResFiscale1.datiFiscali = {...datiFiscaliGiuridicoStatoResFiscale1};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoStatoResFiscale1,
          };   
        case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoNifResFiscale1 = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoNifResFiscale1 = {...contraenteGiuridicoNifResFiscale1}.datiFiscali;
          datiFiscaliGiuridicoNifResFiscale1.nifResFiscale1 = action.unDato;
          contraenteGiuridicoNifResFiscale1.datiFiscali = {...datiFiscaliGiuridicoNifResFiscale1};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoNifResFiscale1,
          };    
        case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoStatoResFiscale2 = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoStatoResFiscale2 = {...contraenteGiuridicoStatoResFiscale2}.datiFiscali;
          datiFiscaliGiuridicoStatoResFiscale2.statoResFiscale2 = action.unDato.value;
          datiFiscaliGiuridicoStatoResFiscale2.descrStatoResFiscale2 = action.unDato.label;
          contraenteGiuridicoStatoResFiscale2.datiFiscali = {...datiFiscaliGiuridicoStatoResFiscale2};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoStatoResFiscale2,
          };   
        case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_CONTRAENTE_GIURIDICO:
          const contraenteGiuridicoNifResFiscale2 = {...state}.contraenteGiuridico;
          const datiFiscaliGiuridicoNifResFiscale2 = {...contraenteGiuridicoNifResFiscale2}.datiFiscali;
          datiFiscaliGiuridicoNifResFiscale2.nifResFiscale2 = action.unDato;
          contraenteGiuridicoNifResFiscale2.datiFiscali = {...datiFiscaliGiuridicoNifResFiscale2};
          return {
            ...state,
            contraenteGiuridico: contraenteGiuridicoNifResFiscale2,
          };
    */

    /* DATI RECAPITO CONTRAENTE GIURIDICO */
    case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_GIURIDICO:
      const contraenteGiuridicoCorrispondenzaRes = { ...state }.contraenteGiuridico;
      const datiCorrispondenzaContrGiur = { ...contraenteGiuridicoCorrispondenzaRes }.datiRecapito;
      datiCorrispondenzaContrGiur.corrispondenzaDiversoResidenza = action.unDato;
      if(datiCorrispondenzaContrGiur.corrispondenzaDiversoResidenza === 'N') {
        datiCorrispondenzaContrGiur.statoRecapito = '';
        datiCorrispondenzaContrGiur.descrStatoRecapito = '';
        datiCorrispondenzaContrGiur.provinciaRecapito = '';
        datiCorrispondenzaContrGiur.descrProvinciaRecapito = '';
        datiCorrispondenzaContrGiur.comuneRecapito = '';
        datiCorrispondenzaContrGiur.descrComuneRecapito = '';
        datiCorrispondenzaContrGiur.toponimoRecapito = '';
        datiCorrispondenzaContrGiur.descrToponimoRecapito = '';
        datiCorrispondenzaContrGiur.descrIndirizzoRecapito = '';
        datiCorrispondenzaContrGiur.numCivicoRecapito = '';
        datiCorrispondenzaContrGiur.capRecapito = '';
      }
      contraenteGiuridico.datiRecapito = {...datiCorrispondenzaContrGiur};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_GIURIDICO:
      const contraenteGiuridicoCorrispondenzaIntestato = { ...state }.contraenteGiuridico;
      const datiCorrispondenzaIntestatoContrGiur = { ...contraenteGiuridicoCorrispondenzaIntestato }.datiRecapito;
      datiCorrispondenzaIntestatoContrGiur.corrispondenzaIntestatoContraente = action.unDato;
      contraenteGiuridico.datiRecapito = { ...datiCorrispondenzaIntestatoContrGiur };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    
    case actionType.UPDATE_STATO_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoStatoRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoStatoRecapito = { ...contraenteGiuridicoStatoRecapito }.datiRecapito;
      datiGeneraliGiuridicoStatoRecapito.statoRecapito = unValore;
      datiGeneraliGiuridicoStatoRecapito.descrStatoRecapito = unaLabel;
      datiGeneraliGiuridicoStatoRecapito.provinciaRecapito = '';
      datiGeneraliGiuridicoStatoRecapito.descrProvinciaRecapito = '';
      datiGeneraliGiuridicoStatoRecapito.comuneRecapito = '';
      datiGeneraliGiuridicoStatoRecapito.descrComuneRecapito = '';
      datiGeneraliGiuridicoStatoRecapito.toponimoRecapito = '';
      datiGeneraliGiuridicoStatoRecapito.descrToponimoRecapito = '';
      //datiGeneraliGiuridicoStatoRecapito.indirizzoRecapito = '';
      //datiGeneraliGiuridicoStatoRecapito.descrIndirizzoRecapito = '';
      contraenteGiuridicoStatoRecapito.datiRecapito = { ...datiGeneraliGiuridicoStatoRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoStatoRecapito,
      };
    case actionType.UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoProvinciaRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoProvinciaRecapito = { ...contraenteGiuridicoProvinciaRecapito }.datiRecapito;
      datiGeneraliGiuridicoProvinciaRecapito.provinciaRecapito = unValore;
      datiGeneraliGiuridicoProvinciaRecapito.descrProvinciaRecapito = unaLabel;
      datiGeneraliGiuridicoProvinciaRecapito.comuneRecapito = '';
      datiGeneraliGiuridicoProvinciaRecapito.descrComuneRecapito = '';
      datiGeneraliGiuridicoProvinciaRecapito.toponimoRecapito = '';
      datiGeneraliGiuridicoProvinciaRecapito.descrToponimoRecapito = '';
      //datiGeneraliGiuridicoProvinciaRecapito.indirizzoRecapito = '';
      //datiGeneraliGiuridicoProvinciaRecapito.descrIndirizzoRecapito = '';
      contraenteGiuridicoProvinciaRecapito.datiRecapito = { ...datiGeneraliGiuridicoProvinciaRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoProvinciaRecapito,
      };
    case actionType.UPDATE_COMUNE_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoComuneRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoComuneRecapito = { ...contraenteGiuridicoComuneRecapito }.datiRecapito;
      if (datiGeneraliGiuridicoComuneRecapito.provinciaRecapito !== PROVINCIA_ESTERA) {
        datiGeneraliGiuridicoComuneRecapito.comuneRecapito = unValore;
        datiGeneraliGiuridicoComuneRecapito.descrComuneRecapito = unaLabel;
        datiGeneraliGiuridicoComuneRecapito.toponimoRecapito = '';
        datiGeneraliGiuridicoComuneRecapito.descrToponimoRecapito = '';
        //datiGeneraliGiuridicoComuneRecapito.indirizzoRecapito = '';
        //datiGeneraliGiuridicoComuneRecapito.descrIndirizzoRecapito = '';
      } else {
        datiGeneraliGiuridicoComuneRecapito.comuneRecapito = '';
        datiGeneraliGiuridicoComuneRecapito.descrComuneRecapito = action.unDato;
      }
      contraenteGiuridicoComuneRecapito.datiRecapito = { ...datiGeneraliGiuridicoComuneRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoComuneRecapito,
      };
    case actionType.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoToponimoRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoToponimoRecapito = { ...contraenteGiuridicoToponimoRecapito }.datiRecapito;
      if (datiGeneraliGiuridicoToponimoRecapito.provinciaRecapito !== PROVINCIA_ESTERA) {
        datiGeneraliGiuridicoToponimoRecapito.toponimoRecapito = unValore;
        datiGeneraliGiuridicoToponimoRecapito.descrToponimoRecapito = unaLabel;
        //datiGeneraliGiuridicoToponimoRecapito.indirizzoRecapito = '';
        //datiGeneraliGiuridicoToponimoRecapito.descrIndirizzoRecapito = '';
      } else {
        datiGeneraliGiuridicoToponimoRecapito.toponimoRecapito = '';
        datiGeneraliGiuridicoToponimoRecapito.descrToponimoRecapito = action.unDato;
      }
      contraenteGiuridicoToponimoRecapito.datiRecapito = { ...datiGeneraliGiuridicoToponimoRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoToponimoRecapito,
      };
    case actionType.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoIndirizzoRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoIndirizzoRecapito = { ...contraenteGiuridicoIndirizzoRecapito }.datiRecapito;
      //datiGeneraliGiuridicoIndirizzoRecapito.indirizzoRecapito = unValore;
      //datiGeneraliGiuridicoIndirizzoRecapito.descrIndirizzoRecapito = unaLabel;
      datiGeneraliGiuridicoIndirizzoRecapito.descrIndirizzoRecapito = action.unDato;
      contraenteGiuridicoIndirizzoRecapito.datiRecapito = { ...datiGeneraliGiuridicoIndirizzoRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoIndirizzoRecapito,
      };
    case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoNumeroCivicoRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoNumeroCivicoRecapito = { ...contraenteGiuridicoNumeroCivicoRecapito }.datiRecapito;
      datiGeneraliGiuridicoNumeroCivicoRecapito.numCivicoRecapito = action.unDato;
      contraenteGiuridicoNumeroCivicoRecapito.datiRecapito = { ...datiGeneraliGiuridicoNumeroCivicoRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoNumeroCivicoRecapito,
      };
    case actionType.UPDATE_CAP_RECAPITO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoCapRecapito = { ...state }.contraenteGiuridico;
      const datiGeneraliGiuridicoCapRecapito = { ...contraenteGiuridicoCapRecapito }.datiRecapito;
      datiGeneraliGiuridicoCapRecapito.capRecapito = action.unDato;
      contraenteGiuridicoCapRecapito.datiRecapito = { ...datiGeneraliGiuridicoCapRecapito };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoCapRecapito,
      };
    case actionType.UPDATE_PRESSO_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoPresso = {...state}.contraenteGiuridico;
      const datiGeneraliGiuridicoPresso = {...contraenteGiuridicoPresso}.datiRecapito;
      datiGeneraliGiuridicoPresso.presso = action.unDato;
      contraenteGiuridicoPresso.datiRecapito = {...datiGeneraliGiuridicoPresso};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoPresso,
      }

/* DATI FINANZIARI CONTRAENTE GIURIDICO */
    case actionType.UPDATE_FATTURATO_GIURIDICO:
      const contraenteGiuridicoFatturatoContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoFatturatoDati = {...contraenteGiuridicoFatturatoContr}.datiFinanziari;
      contraenteGiuridicoFatturatoDati.codFatturato = unValore;
      contraenteGiuridicoFatturatoDati.descFatturato = unaLabel;
      contraenteGiuridicoFatturatoContr.datiFinanziari = {...contraenteGiuridicoFatturatoDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoFatturatoContr
      }
    case actionType.UPDATE_SEDI_CONTRAENTE_GIURIDICO:
      const contraenteGiuridicoSediContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoSediDati = {...contraenteGiuridicoSediContr}.datiFinanziari;
      contraenteGiuridicoSediDati.sediSocieta = action.unDato;
      contraenteGiuridicoSediContr.datiFinanziari = {...contraenteGiuridicoSediDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoSediContr
      }
    case actionType.UPDATE_PATRIMONIO_FINANZIARIO_GIURIDICO:
      const contraenteGiuridicoPatrContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoPatrDati = {...contraenteGiuridicoPatrContr}.datiFinanziari;
      contraenteGiuridicoPatrDati.codPatrimonioFinanziario = unValore;
      contraenteGiuridicoPatrDati.descPatrimonioFinanziario = unaLabel;
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoPatrContr
      }
    case actionType.UPDATE_ALTRI_IMMOBILI_GIURIDICO:
      const contraenteGiuridicoAtriImmContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoAtriImmDati = {...contraenteGiuridicoAtriImmContr}.datiFinanziari;
      contraenteGiuridicoAtriImmDati.altriImmobili = action.unDato;
      contraenteGiuridicoAtriImmContr.datiFinanziari = {...contraenteGiuridicoAtriImmDati}
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoAtriImmContr
      }
    case actionType.UPDATE_MERCATO_MONETARIO_GIURIDICO:
      const contraenteGiuridicoMercMonContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoMercMonDati = {...contraenteGiuridicoMercMonContr}.datiFinanziari;
      const contraenteGiuridicoMercMonRipa = {...contraenteGiuridicoMercMonDati}.ripartizioneInvestimentiFinanziari;
      contraenteGiuridicoMercMonRipa.strumentiMercatoMonetario = action.unDato;
      contraenteGiuridicoMercMonDati.ripartizioneInvestimentiFinanziari = {...contraenteGiuridicoMercMonRipa};
      contraenteGiuridicoMercMonContr.datiFinanziari = {...contraenteGiuridicoMercMonDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoMercMonContr
      }
    case actionType.UPDATE_STRUMENTI_OBBLIGAZIONARI_GIURIDICO:
      const contraenteGiuridicoObbliContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoObbliDati = {...contraenteGiuridicoObbliContr}.datiFinanziari;
      const contraenteGiuridicoObbliRipa = {...contraenteGiuridicoObbliDati}.ripartizioneInvestimentiFinanziari;
      contraenteGiuridicoObbliRipa.strumentiObbligazionari = action.unDato;
      contraenteGiuridicoObbliDati.ripartizioneInvestimentiFinanziari = {...contraenteGiuridicoObbliRipa};
      contraenteGiuridicoObbliContr.datiFinanziari = {...contraenteGiuridicoObbliDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoObbliContr
      }
    case actionType.UPDATE_STRUMENTI_AZIONARI_GIURIDICO:
      const contraenteGiuridicoAzioContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoAzioDati = {...contraenteGiuridicoAzioContr}.datiFinanziari;
      const contraenteGiuridicoAzioRipa = {...contraenteGiuridicoAzioDati}.ripartizioneInvestimentiFinanziari;
      contraenteGiuridicoAzioRipa.strumentiAzionari = action.unDato;
      contraenteGiuridicoAzioDati.ripartizioneInvestimentiFinanziari = {...contraenteGiuridicoAzioRipa};
      contraenteGiuridicoAzioContr.datiFinanziari = {...contraenteGiuridicoAzioDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoAzioContr
      }
      case actionType.UPDATE_STRUMENTI_ASSICURATIVI_GIURIDICO:
      const contraenteGiuridicoAssContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoAssDati = {...contraenteGiuridicoAssContr}.datiFinanziari;
      const contraenteGiuridicoAssRipa = {...contraenteGiuridicoAssDati}.ripartizioneInvestimentiFinanziari;
      contraenteGiuridicoAssRipa.strumentiAssicurativi = action.unDato;
      contraenteGiuridicoAssDati.ripartizioneInvestimentiFinanziari = {...contraenteGiuridicoAssRipa};
      contraenteGiuridicoAssContr.datiFinanziari = {...contraenteGiuridicoAssDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoAssContr
      }
    case actionType.UPDATE_STRUMENTI_COMPLESSI_GIURIDICO:
      const contraenteGiuridicoComlContr = {...state}.contraenteGiuridico;
      const contraenteGiuridicoComplDati = {...contraenteGiuridicoComlContr}.datiFinanziari;
      const contraenteGiuridicoComplRipa = {...contraenteGiuridicoComplDati}.ripartizioneInvestimentiFinanziari;
      contraenteGiuridicoComplRipa.strumentiComplessi = action.unDato;
      contraenteGiuridicoComplDati.ripartizioneInvestimentiFinanziari = {...contraenteGiuridicoComplRipa};
      contraenteGiuridicoComlContr.datiFinanziari = {...contraenteGiuridicoComplDati};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridicoComlContr
      }
          
            
/* DATI GENERALI ESECUTORE FISICO */
    case actionType.UPDATE_DATI_GENERALI_PER_ESECUTORE:
      datiGeneraliEsecutore.sesso = '';
      datiGeneraliEsecutore.codiceFiscale = '';
      datiGeneraliEsecutore.cognome = '';
      datiGeneraliEsecutore.nome = '';
      datiGeneraliEsecutore.dataNascita = null;
      datiGeneraliEsecutore.statoNascita = '';
      datiGeneraliEsecutore.descrStatoNascita = '';
      datiGeneraliEsecutore.provinciaNascita = '';
      datiGeneraliEsecutore.descrProvinciaNascita = '';
      datiGeneraliEsecutore.comuneNascita = '';
      datiGeneraliEsecutore.descrComuneNascita = '';
      datiGeneraliEsecutore.cittadinanza1 = '';
      datiGeneraliEsecutore.descrCittadinanza1 = '';
      datiGeneraliEsecutore.cittadinanza2 = '';
      datiGeneraliEsecutore.descrCittadinanza2 = '';
      datiGeneraliEsecutore.numTelefono = '';
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_FL_ESECUTORE_FISICO:
      datiEsecutore.flEsecutore = action.unDato;
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_RELAZ_CONTR_ESECUTORE_FISICO:
      datiEsecutore.relazContrEsecut = unValore;
      datiEsecutore.descrRelazContrEsecut = unaLabel;
      datiEsecutore.altroRelazContr = '';
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_FL_DELEGA_ESECUTORE_FISICO:
      datiEsecutore.flDelega = action.unDato;
      if(action.unDato==='N'){
    	  datiEsecutore.tipoRappresentanza='';
    	  datiEsecutore.altraRappresentanza='';
      }
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_TIPO_RAPPRESENTANZA_ESECUTORE:
      datiEsecutore.tipoRappresentanza = unValore;
      datiEsecutore.descrTipoRappresentanza = unaLabel;
      datiEsecutore.altraRappresentanza = '';
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_ALTRA_RAPPRESENTANZA:
      datiEsecutore.altraRappresentanza = action.unDato;
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_ORIGINE_FONDI_ESECUTORE_FISICO:
      datiEsecutore.origineFondi = unValore;
      datiEsecutore.descrOrigineFondi = unaLabel;
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_ORIGINE_FONDI_ALTRO_ESECUTORE_FISICO:
      datiEsecutore.altro = action.unDato;
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_ALTRO_RELAZ_CONTR:
      datiEsecutore.altroRelazContr = action.unDato;
      contraenteFisico.datiEsecutore = datiEsecutore;
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_SESSO_ESECUTORE_FISICO:
      datiGeneraliEsecutore.sesso = action.unDato;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_CODICE_FISCALE_ESECUTORE_FISICO:
      datiGeneraliEsecutore.codiceFiscale = action.unDato;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    /*      case actionType.UPDATE_CONTROLLO_CODICE_FISCALE_ESECUTORE_FISICO:
            datiGeneraliEsecutore.controlloCodiceFiscale = action.unDato;
            datiEsecutore.datiGenerali = {...datiGeneraliEsecutore};
              contraenteFisico.datiEsecutore = {...datiEsecutore};
              return {
                ...state,
                contraenteFisico: contraenteFisico,
              };*/
    case actionType.UPDATE_CODICE_FISCALE_VALIDO_ESECUTORE_FISICO:
      datiGeneraliEsecutore.codiceFiscaleValido = action.unDato;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_COGNOME_ESECUTORE_FISICO:
      datiGeneraliEsecutore.cognome = action.unDato;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_NOME_ESECUTORE_FISICO:
      datiGeneraliEsecutore.nome = action.unDato;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_DATA_NASCITA_ESECUTORE_FISICO:
      const unaDataEsec = action.unDato;
      const nuovaDataNascitaEsecFormattata = dataFormat(unaDataEsec, 'DD/MM/YYYY');
      datiGeneraliEsecutore.dataNascita = unaDataEsec;
      datiGeneraliEsecutore.dataNascitaStampa = nuovaDataNascitaEsecFormattata;
      datiGeneraliEsecutore.statoNascita = '';
      datiGeneraliEsecutore.descrStatoNascita = '';
      datiGeneraliEsecutore.provinciaNascita = '';
      datiGeneraliEsecutore.descrProvinciaNascita = '';
      datiGeneraliEsecutore.comuneNascita = '';
      datiGeneraliEsecutore.descrComuneNascita = '';
      //datiGeneraliEsecutore.cittadinanza1 = '';
      //datiGeneraliEsecutore.descrCittadinanza1 = '';
      //datiGeneraliEsecutore.cittadinanza2 = '';
      //datiGeneraliEsecutore.descrCittadinanza2 = '';
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_STATO_NASCITA_ESECUTORE_FISICO:
      datiGeneraliEsecutore.statoNascita = unValore;
      datiGeneraliEsecutore.descrStatoNascita = unaLabel;
      datiGeneraliEsecutore.provinciaNascita = '';
      datiGeneraliEsecutore.descrProvinciaNascita = '';
      datiGeneraliEsecutore.comuneNascita = '';
      datiGeneraliEsecutore.descrComuneNascita = '';
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_PROVINCIA_NASCITA_ESECUTORE_FISICO:
      datiGeneraliEsecutore.provinciaNascita = unValore;
      datiGeneraliEsecutore.descrProvinciaNascita = unaLabel;
      datiGeneraliEsecutore.comuneNascita = '';
      datiGeneraliEsecutore.descrComuneNascita = '';
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_COMUNE_NASCITA_ESECUTORE_FISICO:
      if (datiGeneraliEsecutore.provinciaNascita !== PROVINCIA_ESTERA) {
        datiGeneraliEsecutore.comuneNascita = unValore;
        datiGeneraliEsecutore.descrComuneNascita = unaLabel;  // campo select 
      } else {
        datiGeneraliEsecutore.comuneNascita = '';
        datiGeneraliEsecutore.descrComuneNascita = action.unDato; // campo testo
      }
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_CITTADINANZA_1_ESECUTORE_FISICO:
      datiGeneraliEsecutore.cittadinanza1 = unValore;
      datiGeneraliEsecutore.descrCittadinanza1 = unaLabel;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_CITTADINANZA_2_ESECUTORE_FISICO:
      datiGeneraliEsecutore.cittadinanza2 = unValore;
      datiGeneraliEsecutore.descrCittadinanza2 = unaLabel;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    case actionType.UPDATE_NUM_TELEFONO_ESECUTORE_FISICO:
      datiGeneraliEsecutore.numTelefono = action.unDato;
      datiEsecutore.datiGenerali = { ...datiGeneraliEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };
    /* DATI RECAPITO ESECUTORE FISICO */
    case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_GIURIDICO:
      datiCorrispondenzaEsecutore.corrispondenzaDiversoResidenza = action.unDato;
      if(datiCorrispondenzaEsecutore.corrispondenzaDiversoResidenza === 'N') {
        datiCorrispondenzaEsecutore.corrispondenzaIntestatoContraente = '';
        datiCorrispondenzaEsecutore.statoRecapito = '';
        datiCorrispondenzaEsecutore.descrStatoRecapito = '';
        datiCorrispondenzaEsecutore.provinciaRecapito = '';
        datiCorrispondenzaEsecutore.descrProvinciaRecapito = '';
        datiCorrispondenzaEsecutore.comuneRecapito = '';
        datiCorrispondenzaEsecutore.descrComuneRecapito = '';
        datiCorrispondenzaEsecutore.toponimoRecapito = '';
        datiCorrispondenzaEsecutore.descrToponimoRecapito = '';
        datiCorrispondenzaEsecutore.descrIndirizzoRecapito = '';
        datiCorrispondenzaEsecutore.numCivicoRecapito = '';
        datiCorrispondenzaEsecutore.capRecapito = '';
      }
      datiEsecutore.datiRecapito = {...datiCorrispondenzaEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_ESECUTORE_FISICO:
      datiCorrispondenzaEsecutore.corrispondenzaIntestatoContraente = action.unDato;
      datiEsecutore.datiRecapito = {...datiCorrispondenzaEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_PRESSO_FISICO_ESECUTORE:
      datiCorrispondenzaEsecutore.presso = action.unDato;
      datiEsecutore.datiRecapito = {...datiCorrispondenzaEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_ESECUTORE:
      datiDomicilioEsecutore.domicilioDiversoResidenza = action.unDato;
      if(datiDomicilioEsecutore.domicilioDiversoResidenza === 'N') {
        datiDomicilioEsecutore.statoDomicilio = '';
        datiDomicilioEsecutore.descrStatoDomicilio = '';
        datiDomicilioEsecutore.provinciaDomicilio = '';
        datiDomicilioEsecutore.descrProvinciaDomicilio = '';
        datiDomicilioEsecutore.comuneDomicilio = '';
        datiDomicilioEsecutore.descrComuneDomicilio = '';
        datiDomicilioEsecutore.toponimoDomicilio = '';
        datiDomicilioEsecutore.descrToponimoDomicilio = '';
        datiDomicilioEsecutore.descrIndirizzoDomicilio = '';
        datiDomicilioEsecutore.numCivicoDomicilio = '';
        datiDomicilioEsecutore.capDomicilio = '';
      }
      datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
      datiDomicilioEsecutore.statoDomicilio = unValore;
      datiDomicilioEsecutore.descrStatoDomicilio = unaLabel;
      datiDomicilioEsecutore.provinciaDomicilio = '';
      datiDomicilioEsecutore.descrProvinciaDomicilio = '';
      datiDomicilioEsecutore.comuneDomicilio = '';
      datiDomicilioEsecutore.descrComuneDomicilio = '';
      datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
      datiDomicilioEsecutore.provinciaDomicilio = unValore;
      datiDomicilioEsecutore.descrProvinciaDomicilio = unaLabel;
      datiDomicilioEsecutore.comuneDomicilio = '';
      datiDomicilioEsecutore.descrComuneDomicilio = '';
      datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
      if(datiDomicilioEsecutore.provinciaDomicilio === PROVINCIA_ESTERA) {
        datiDomicilioEsecutore.comuneDomicilio = '';
        datiDomicilioEsecutore.descrComuneDomicilio = action.unDato;
        datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};  
        contraenteFisico.datiEsecutore = {...datiEsecutore};
      } else {
        datiDomicilioEsecutore.comuneDomicilio = unValore;
        datiDomicilioEsecutore.descrComuneDomicilio = unaLabel;
        datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
        contraenteFisico.datiEsecutore = {...datiEsecutore};
      }
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
      case actionType.UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
        if(datiDomicilioEsecutore.provinciaDomicilio === PROVINCIA_ESTERA) {
          datiDomicilioEsecutore.toponimoDomicilio = '';
          datiDomicilioEsecutore.descrToponimoDomicilio = action.unDato;
          datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
          contraenteFisico.datiEsecutore = {...datiEsecutore};
        } else { 
          datiDomicilioEsecutore.toponimoDomicilio = unValore;
          datiDomicilioEsecutore.descrToponimoDomicilio = unaLabel;
          datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
          contraenteFisico.datiEsecutore = {...datiEsecutore};
        }
        return {
          ...state,
          contraenteFisico: contraenteFisico,
        }
    case actionType.UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
      datiDomicilioEsecutore.indirizzoDomicilio = action.unDato;
      datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};    
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
      datiDomicilioEsecutore.numCivicoDomicilio = action.unDato;
      datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    case actionType.UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE:
      datiDomicilioEsecutore.capDomicilio = action.unDato;
      datiEsecutore.datiDomicilio = {...datiDomicilioEsecutore};  
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      }
    /* DATI ANTIRICICLAGGIO ESECUTORE FISICO */
    case actionType.UPDATE_DATI_ANTIRICICLAGGIO_PER_ESECUTORE:
      datiAntiriciclaggioEsecutore.tipoDocumento = '';
      datiAntiriciclaggioEsecutore.descrTipoDocumento = '';
      datiAntiriciclaggioEsecutore.numeroDocumento = '';
      datiAntiriciclaggioEsecutore.enteRilascio = '';
      datiAntiriciclaggioEsecutore.descrEnteRilascio = '';
      datiAntiriciclaggioEsecutore.dataRilascio = null;
      datiAntiriciclaggioEsecutore.dataScadenza = null;
      datiAntiriciclaggioEsecutore.statoRilascio = '';
      datiAntiriciclaggioEsecutore.descrStatoRilascio = '';
      datiAntiriciclaggioEsecutore.provinciaRilascio = '';
      datiAntiriciclaggioEsecutore.descrProvinciaRilascio = '';
      datiAntiriciclaggioEsecutore.comuneRilascio = '';
      datiAntiriciclaggioEsecutore.descrComuneRilascio = '';
      datiAntiriciclaggioEsecutore.espostaPoliticamente = '';

      datiEsecutore.datiAntiriciclaggio = { ...datiAntiriciclaggioEsecutore };
      contraenteFisico.datiEsecutore = { ...datiEsecutore };
      return {
        ...state,
        contraenteFisico: contraenteFisico,
      };     
    
/* DATI GENERALI MINIMI */     
        case actionType.UPDATE_SESSO: 
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.sesso = action.unDato;
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_CODICE_FISCALE:
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.codiceFiscale = action.unDato;
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_CODICE_FISCALE_VALIDO:
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.codiceFiscaleValido = action.unDato;
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_COGNOME: 
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.cognome = action.unDato;
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_NOME: 
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.nome = action.unDato;
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_DATA_NASCITA: 
        	if (datiGeneraliMinimi!==undefined) {
        		const unaDataNascitaMinimi = action.unDato; 
        		const nuovaDataNascitaMinimiFormattata = dataFormat(unaDataNascitaMinimi, 'DD/MM/YYYY');
	            datiGeneraliMinimi.dataNascita = unaDataNascitaMinimi;
	            datiGeneraliMinimi.dataNascitaStampa = nuovaDataNascitaMinimiFormattata;
	            datiGeneraliMinimi.statoNascita = '';
	            datiGeneraliMinimi.descrStatoNascita = '';
	            datiGeneraliMinimi.provinciaNascita = '';
	            datiGeneraliMinimi.descrProvinciaNascita = '';
	            datiGeneraliMinimi.comuneNascita = '';
	            datiGeneraliMinimi.descrComuneNascita = '';
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_STATO_NASCITA:
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.statoNascita = unValore;
	            datiGeneraliMinimi.descrStatoNascita = unaLabel;
	            datiGeneraliMinimi.provinciaNascita = '';
	            datiGeneraliMinimi.descrProvinciaNascita = '';
	            datiGeneraliMinimi.comuneNascita = '';
	            datiGeneraliMinimi.descrComuneNascita = '';
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_PROVINCIA_NASCITA: 
        	if (datiGeneraliMinimi!==undefined) {
	            datiGeneraliMinimi.provinciaNascita = unValore;
	            datiGeneraliMinimi.descrProvinciaNascita = unaLabel;
	            datiGeneraliMinimi.comuneNascita = '';
	            datiGeneraliMinimi.descrComuneNascita = '';
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
        case actionType.UPDATE_COMUNE_NASCITA: 
        	if (datiGeneraliMinimi!==undefined) {
        		if (datiGeneraliMinimi.provinciaNascita !== PROVINCIA_ESTERA) {
		            datiGeneraliMinimi.comuneNascita = unValore; // campo select
		            datiGeneraliMinimi.descrComuneNascita = unaLabel;
        		} else {
        			datiGeneraliMinimi.comuneNascita = '';
        			datiGeneraliMinimi.descrComuneNascita = action.unDato; // campo testo
        		}
	            return resolveDatiGeneraliMinimi(state, figura, datiGeneraliMinimi);
        	}
        	break;
/* DATI ANTIRICICLAGGIO - VALIDO PER CONTRAENTE E PER ESECUTORE */
      case actionType.UPDATE_TIPO_DOCUMENTO: 
          datiAntiriciclaggio.tipoDocumento = unValore;
          datiAntiriciclaggio.descrTipoDocumento = unaLabel;          
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_NUMERO_DOCUMENTO: 
          datiAntiriciclaggio.numeroDocumento = action.unDato;
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_ENTE_RILASCIO: 
          datiAntiriciclaggio.enteRilascio = unValore;
          datiAntiriciclaggio.descrEnteRilascio = unaLabel;
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_DATA_RILASCIO: 
          const unaDataRilascio = action.unDato;
          const nuovaDataRilascioFormattata = dataFormat(unaDataRilascio, 'DD/MM/YYYY');     	  
          datiAntiriciclaggio.dataRilascio = unaDataRilascio;
          datiAntiriciclaggio.dataRilascioStampa = nuovaDataRilascioFormattata;  
          datiAntiriciclaggio.statoRilascio = '';
          datiAntiriciclaggio.descrStatoRilascio = '';
          datiAntiriciclaggio.provinciRilascio = '';
          datiAntiriciclaggio.descrProvinciaRilascio = '';
          datiAntiriciclaggio.comuneRilascio = '';
          datiAntiriciclaggio.descrComuneRilascio = '';
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_DATA_SCADENZA: 
          const unaDataScadenza = action.unDato;
          const nuovaDataScadenzaFormattata = dataFormat(unaDataScadenza, 'DD/MM/YYYY');    	  
          datiAntiriciclaggio.dataScadenza = unaDataScadenza;
          datiAntiriciclaggio.dataScadenzaStampa = nuovaDataScadenzaFormattata;
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_STATO_RILASCIO: 
          datiAntiriciclaggio.statoRilascio = unValore;
          datiAntiriciclaggio.descrStatoRilascio = unaLabel;
          datiAntiriciclaggio.provinciaRilascio = '';
          datiAntiriciclaggio.descrProvinciaRilascio = '';
          datiAntiriciclaggio.comuneRilascio = '';
          datiAntiriciclaggio.descrComuneRilascio = '';
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_PROVINCIA_RILASCIO: 
          datiAntiriciclaggio.provinciaRilascio = unValore;
          datiAntiriciclaggio.descrProvinciaRilascio = unaLabel;
          datiAntiriciclaggio.comuneRilascio = '';
          datiAntiriciclaggio.descrComuneRilascio = '';
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_COMUNE_RILASCIO:
    	  if (datiAntiriciclaggio.provinciaRilascio !== PROVINCIA_ESTERA){
	          datiAntiriciclaggio.comuneRilascio = unValore;
	          datiAntiriciclaggio.descrComuneRilascio = unaLabel;
    	  } else {
	          datiAntiriciclaggio.comuneRilascio = '';
	          datiAntiriciclaggio.descrComuneRilascio = action.unDato;    		  
    	  }
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE:
          datiAntiriciclaggio.espostaPoliticamente = action.unDato;
          if(datiAntiriciclaggio.espostaPoliticamente === 'N') {
            datiAntiriciclaggio.isInCarica = '';
            datiAntiriciclaggio.caricaPersonaEsposta = '';
          }
          return resolveDatiAntiriciclaggio (state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_FL_PEP_IN_CARICA:
          datiAntiriciclaggio.isInCarica = action.unDato;
          return resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_CARICA_PEP:
          datiAntiriciclaggio.caricaPersonaEsposta = action.unDato;
          return resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_CARICA_PUBBLICA_NO_PEP:           // 2020-12-01 PFD: SAV 'TO BE' - START 
          datiAntiriciclaggio.caricaPubblicaNoPEP = action.unDato;
          return resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_ATTIVITA_PRECEDENTE:
          datiAntiriciclaggio.codAttivitaPrecedente = unValore;
          datiAntiriciclaggio.descrAttivitaPrecedente = unaLabel;
          return resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_AMBITO_ATTIVITA:
          datiAntiriciclaggio.codAmbitoAttivita = unValore;
          datiAntiriciclaggio.descrAmbitoAttivita = unaLabel;
          return resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);      
/* DATI ATTIVITA ECONOMICA */
      case actionType.UPDATE_TIPO_SOCIETA:
    	  datiAttEconomica.tipoSocieta = unValore;
        datiAttEconomica.descrTipoSocieta = unaLabel;
        datiAttEconomica.codSAE = '';
        datiAttEconomica.descrSAE = '';
        datiAttEconomica.codATECO = '';
        datiAttEconomica.descrATECO = '';    	  
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_ATT_PREVAL_SVOLTA:
    	  datiAttEconomica.codAttPrevalSvolta = unValore;
        datiAttEconomica.descrAttPrevalSvolta = unaLabel;  
        datiAttEconomica.altroAttPrevAtt = '';
        datiAttEconomica.codSAE = '';
        datiAttEconomica.descrSAE = '';
        if(figura === RAPPRESENTANTE_LEGALE || figura === CONTRAENTE_FISICO) {  
          datiAntiriciclaggio.codAttivitaPrecedente = '';
          datiAntiriciclaggio.descrAttivitaPrecedente = '';
        }
        return resolveDatiAttEconomica(state, figura, datiAttEconomica) && resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_STATO_ATT_PREVAL:
        datiAttEconomica.statoAttPrevalSvolta = unValore;
        datiAttEconomica.descrStatoAttPrevalSvolta = unaLabel;
        datiAttEconomica.descrProvinciaAttPrevalSvolta = '';
        datiAttEconomica.provinciaAttPrevalSvolta = '';
        datiAttEconomica.comuneAttPrevalSvolta = '';
        datiAttEconomica.descrComuneAttPrevalSvolta = '';
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_PROVINCIA_ATT_PREVAL:
    	  datiAttEconomica.provinciaAttPrevalSvolta = unValore;
    	  datiAttEconomica.descrProvinciaAttPrevalSvolta = unaLabel;
    	  datiAttEconomica.comuneAttPrevalSvolta = '';
    	  datiAttEconomica.descrComuneAttPrevalSvolta = '';
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_COMUNE_ATT_PREVAL:
        if(datiAttEconomica.provinciaAttPrevalSvolta !== PROVINCIA_ESTERA){
          datiAttEconomica.comuneAttPrevalSvolta = unValore;
          datiAttEconomica.descrComuneAttPrevalSvolta = unaLabel;
        } else {
          datiAttEconomica.comuneAttPrevalSvolta = '';
          datiAttEconomica.descrComuneAttPrevalSvolta = action.unDato;
        }
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_CAP_ATT_PREVAL:
    	  datiAttEconomica.capAttPrevalSvolta = action.unDato;
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_COD_SAE:
    	  datiAttEconomica.codSAE = unValore;
    	  datiAttEconomica.descrSAE = unaLabel;
    	  datiAttEconomica.codATECO = '';
        datiAttEconomica.descrATECO = '';
        if(figura === RAPPRESENTANTE_LEGALE || figura === CONTRAENTE_FISICO){
          datiAntiriciclaggio.codAmbitoAttivita = '';
          datiAntiriciclaggio.descrAmbitoAttivita = '';
        }
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica) && resolveDatiAntiriciclaggio(state, figura, datiAntiriciclaggio);
      case actionType.UPDATE_COD_ATECO_RAP_LEGALE:
        datiAttEconomica.codATECORapLegale = unValore;
    	  datiAttEconomica.descrATECORapLegale = unaLabel;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_COD_ATECO:
    	  datiAttEconomica.codATECO = unValore;
    	  datiAttEconomica.descrATECO = unaLabel;
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_FL_ALLEGATI: 
    	  datiAttEconomica.flAllegati = action.unDato;    	  
    	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_ALLEGATI_ALTRO: 
    	  datiAttEconomica.altro = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT:
        datiAttEconomica.altroAttPrevAtt = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_FL_PAESI_BLACK_LIST: 
        datiAttEconomica.flPaesiBlackList = action.unDato;
        if(datiAttEconomica.flPaesiBlackList === 'N'){
          datiAttEconomica.relazPaesiList = [...relazPaesiListInizializzato]; // shallow copy
        }
    	return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      //2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
      case actionType.UPDATE_RELAZ_PAESI_LIST:
          datiAttEconomica.relazPaesiList = action.unDato;
      	  return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_FL_ENT_FINANZ_PASSIVA: 
    	  datiAttEconomica.flEntitaFinanzPassiva = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_SOCIETA_AZ_FIDUCIARIO:
        datiAttEconomica.societaAzFiduciario = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_SOCIETA_QUOTATA:
        datiAttEconomica.societaQuotata = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_MOTIVO_RELAZIONE:
        datiAttEconomica.motivoRelazione = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_CLASSE_BENEFICIARI:
        datiAttEconomica.classeBeneficiari = action.unDato;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      case actionType.UPDATE_STAT_ATT_PREV_SVOLTA:
        datiAttEconomica.statoAttPrevalSvolta = unValore;
        datiAttEconomica.descrStatoAttPrevalSvolta = unaLabel;
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
      /*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
      case actionType.TOGLE_PAESE_BLACK_LIST:
        const nuovoPaese = action.unDato;
        // dopo aver controllato la proprietà checked dell'oggetto, non ne ho più bisogno e la rimuovo
        if(nuovoPaese.checked) {
          //delete nuovoPaese.checked;
          datiAttEconomica.listaPaesiBlackList.push(nuovoPaese);
        } else {
          ///delete nuovoPaese.checked;
          datiAttEconomica.listaPaesiBlackList.map((el, i) => {
            if(el.value === nuovoPaese.value) {
              datiAttEconomica.listaPaesiBlackList.splice(i, 1);
            }
          });
        }       
        return resolveDatiAttEconomica(state, figura, datiAttEconomica);
     */
/* DATI MANDATO FIDUCIARIO */
      case actionType.UPDATE_NUM_MANDATO_FIDUCIARIO:
        datiMandatoFiduciario.numeroMandatoFid = action.unDato;
        return resolveDatiMandatoFiduciario(state, figura, datiMandatoFiduciario);
      case actionType.UPDATE_INTESTATARIO_MANDATO:
        datiMandatoFiduciario.intestatarioMandato = action.unDato;
        return resolveDatiMandatoFiduciario(state, figura, datiMandatoFiduciario);
/* DATI SCOPO E NATURA RAPPORTO */
      case actionType.UPDATE_SCOPO_NATURA_RAPPORTO: 
    	  datiNaturaRapporto.scopoNaturaRapporto = unValore;
    	  datiNaturaRapporto.descrScopoNaturaRapporto = unaLabel;
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_INTERMEDIARIO_BANCARIO:
        datiNaturaRapporto.codTempoProvvista = unValore;
        datiNaturaRapporto.descTempoProvvista = unaLabel;
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_ORIGINE_FONDI: 
    	  datiNaturaRapporto.origineFondi = unValore;
    	  datiNaturaRapporto.descrOrigineFondi = unaLabel;
    	  return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_ALTRA_ORIGINE_FONDI: 
        datiNaturaRapporto.altro = action.unDato;
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_STIPULATO_PER_CONTO:
        datiNaturaRapporto.stipulatoPerConto = action.unDato;
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      /* case actionType.UPDATE_SCOPO_NATURA_CONTRATTO:
        datiNaturaRapporto.codScopoNaturaContratto = unValore;
        datiNaturaRapporto.descScopoNaturaContratto = unaLabel; */
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_SOGGETTO_DA_TUTELARE:
        datiNaturaRapporto.codSoggettoDaTutelare = unValore;
        datiNaturaRapporto.descSoggettoDaTutelare = unaLabel;
        datiNaturaRapporto.altriSoggetti = '';
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_ALTRI_SOGGETTI:
        datiNaturaRapporto.altriSoggetti = action.unDato;
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.UPDATE_STATO_PROVENIENZA_SOMME:
          datiNaturaRapporto.statoProvenienzaSomme = unValore;
          datiNaturaRapporto.descrStatoProvenienzaSomme = unaLabel;
          return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
      case actionType.TOGGLE_ORIGINE_FONDI_LIST:
        const nuovaOrigine = action.unDato;
        if(nuovaOrigine.checked && datiNaturaRapporto.origineFondiList.length < 2) {
          //add nuovaOrigine;
          datiNaturaRapporto.origineFondiList.push(action.unDato);
        } else if(!nuovaOrigine.checked) {
          //delete nuovaOrigine.checked;
          datiNaturaRapporto.origineFondiList.map((el, i) => {
            if(el.value === nuovaOrigine.value) {
              let index = datiNaturaRapporto.origineFondiList.indexOf(el);
              datiNaturaRapporto.origineFondiList.splice(index, 1);
              if(el.value === CODICE_ALTRA_ORIGINE_FONDI) {
                datiNaturaRapporto.altro = '';
              }
            }
          });
        }
        return resolveDatiNaturaRapporto(state, figura, datiNaturaRapporto);
/* DATI FISCALI FISICO GIURIDICO */
      case actionType.UPDATE_TASSE_STATI_UNITI: 
          datiFiscali.tasseStatiUniti = action.unDato;
          return resolveDatiFiscali (state, figura, datiFiscali);
      case actionType.UPDATE_TIN_GIIN: 
          datiFiscali.tinGiin = action.unDato;
          return resolveDatiFiscali (state, figura, datiFiscali);
      case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA: 
          datiFiscali.statoResidenzaFiscaleEstera = action.unDato;
          return resolveDatiFiscali (state, figura, datiFiscali);
      case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1: 
/*          datiFiscali.statoResFiscale1 = action.unDato.value;
          datiFiscali.descrStatoResFiscale1 = action.unDato.label;*/
          datiFiscali.statoResFiscale1 = unValore;
          datiFiscali.descrStatoResFiscale1 = unaLabel;
          return resolveDatiFiscali (state, figura, datiFiscali);
      case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1: 
          datiFiscali.nifResFiscale1 = action.unDato;
          return resolveDatiFiscali (state, figura, datiFiscali);
      case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2: 
          datiFiscali.statoResFiscale2 = unValore;
          datiFiscali.descrStatoResFiscale2 = unaLabel;
          return resolveDatiFiscali (state, figura, datiFiscali);
      case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2: 
          datiFiscali.nifResFiscale2 = action.unDato;
          return resolveDatiFiscali (state, figura, datiFiscali);
/* DATI RESIDENZA RAPPRESENTANTE LEGALE  */
      case actionType.UPDATE_STATO_RESIDENZA_RAPP_LEGALE:
        datiResidenzaRappLegale.statoResidenza = unValore;
        datiResidenzaRappLegale.descrStatoResidenza = unaLabel;
        datiResidenzaRappLegale.provinciaResidenza = '';
        datiResidenzaRappLegale.descrProvinciaResidenza = '';
        datiResidenzaRappLegale.comuneResidenza = '';
        datiResidenzaRappLegale.descrComuneResidenza = '';
        datiResidenzaRappLegale.toponimoResidenza = '';
        datiResidenzaRappLegale.descrToponimoResidenza = '';
        datiResidenzaRappLegale.indirizzoResidenza = '';
        datiResidenzaRappLegale.descrIndirizzoResidenza = '';
        datiRappLegale.datiResidenza = {...datiResidenzaRappLegale};
        contraenteGiuridico.datiRappLegale = {...datiRappLegale};
        return {
          ...state,
          contraenteGiuridico: contraenteGiuridico,
        }; 
      case actionType.UPDATE_PROVINCIA_RESIDENZA_RAPP_LEGALE:
        datiResidenzaRappLegale.provinciaResidenza = unValore;
        datiResidenzaRappLegale.descrProvinciaResidenza = unaLabel;
        datiResidenzaRappLegale.comuneResidenza = '';
        datiResidenzaRappLegale.descrComuneResidenza = '';
        datiResidenzaRappLegale.toponimoResidenza = '';
        datiResidenzaRappLegale.descrToponimoResidenza = '';
        //datiResidenzaRappLegale.indirizzoResidenza = '';
        //datiResidenzaRappLegale.descrIndirizzoResidenza = '';
        datiRappLegale.datiResidenza = {...datiResidenzaRappLegale};
        contraenteGiuridico.datiRappLegale = {...datiRappLegale};
        return {
          ...state,
          contraenteGiuridico: contraenteGiuridico,
        }; 
      case actionType.UPDATE_COMUNE_RESIDENZA_RAPP_LEGALE:
        if (datiResidenzaRappLegale.provinciaResidenza!==PROVINCIA_ESTERA) {
          datiResidenzaRappLegale.comuneResidenza = unValore;
          datiResidenzaRappLegale.descrComuneResidenza = unaLabel; // campo select
          datiResidenzaRappLegale.toponimoResidenza = '';
          datiResidenzaRappLegale.descrToponimoResidenza = '';
          //datiResidenzaRappLegale.indirizzoResidenza = '';
          //datiResidenzaRappLegale.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaRappLegale.comuneResidenza = '';
        datiResidenzaRappLegale.descrComuneResidenza = action.unDato; // campo testo
      }
        datiRappLegale.datiResidenza = {...datiResidenzaRappLegale};
        contraenteGiuridico.datiRappLegale = {...datiRappLegale};
        return {
          ...state,
          contraenteGiuridico: contraenteGiuridico,
        }; 
    case actionType.UPDATE_TOPONIMO_RESIDENZA_RAPP_LEGALE:
      if (datiResidenzaRappLegale.provinciaResidenza !== PROVINCIA_ESTERA) {
        datiResidenzaRappLegale.toponimoResidenza = unValore;
        datiResidenzaRappLegale.descrToponimoResidenza = unaLabel; // campo select
        //datiResidenzaRappLegale.indirizzoResidenza = '';
        //datiResidenzaRappLegale.descrIndirizzoResidenza = '';
      } else {
        datiResidenzaRappLegale.toponimoResidenza = '';
        datiResidenzaRappLegale.descrToponimoResidenza = action.unDato; // campo testo
      }
      datiRappLegale.datiResidenza = { ...datiResidenzaRappLegale };
      contraenteGiuridico.datiRappLegale = { ...datiRappLegale };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico,
      };
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_RAPP_LEGALE:
      //datiResidenzaRappLegale.indirizzoResidenza = unValore;
      //datiResidenzaRappLegale.descrIndirizzoResidenza = unaLabel;
      datiResidenzaRappLegale.descrIndirizzoResidenza = action.unDato;
      datiRappLegale.datiResidenza = { ...datiResidenzaRappLegale };
      contraenteGiuridico.datiRappLegale = { ...datiRappLegale };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico,
      };
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_RAPP_LEGALE:
      datiResidenzaRappLegale.numCivicoResidenza = action.unDato;
      datiRappLegale.datiResidenza = { ...datiResidenzaRappLegale };
      contraenteGiuridico.datiRappLegale = { ...datiRappLegale };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico,
      };
    case actionType.UPDATE_CAP_RESIDENZA_RAPP_LEGALE:
      datiResidenzaRappLegale.capResidenza = action.unDato;
      datiRappLegale.datiResidenza = { ...datiResidenzaRappLegale };
      contraenteGiuridico.datiRappLegale = { ...datiRappLegale };
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico,
      };
/* DATI DOMICILIO RAPPRESENTANTE LEGALE */
    case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_RAPP_LEGALE:
      datiDomicilioRappLegale.domicilioDiversoResidenza = action.unDato;
      if(datiDomicilioRappLegale.domicilioDiversoResidenza === 'N') {
        datiDomicilioRappLegale.statoDomicilio = '';
        datiDomicilioRappLegale.descrStatoDomicilio = '';
        datiDomicilioRappLegale.provinciaDomicilio = '';
        datiDomicilioRappLegale.descrProvinciaDomicilio = '';
        datiDomicilioRappLegale.comuneDomicilio = '';
        datiDomicilioRappLegale.descrComuneDomicilio = '';
        datiDomicilioRappLegale.toponimoDomicilio = '';
        datiDomicilioRappLegale.descrToponimoDomicilio = '';
        datiDomicilioRappLegale.indirizzoDomicilio = '';
        datiDomicilioRappLegale.numCivicoDomicilio = '';
        datiDomicilioRappLegale.capDomicilio = '';
      }
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_STATO_DOMICILIO_RAPP_LEGALE:
      datiDomicilioRappLegale.statoDomicilio = unValore;
      datiDomicilioRappLegale.descrStatoDomicilio = unaLabel;
      datiDomicilioRappLegale.provinciaDomicilio = '';
      datiDomicilioRappLegale.descrProvinciaDomicilio = '';
      datiDomicilioRappLegale.comuneDomicilio = '';
      datiDomicilioRappLegale.descrComuneDomicilio = '';
      datiDomicilioRappLegale.toponimoDomicilio = '';
      datiDomicilioRappLegale.descrToponimoDomicilio = '';
      datiDomicilioRappLegale.indirizzoDomicilio = '';
      datiDomicilioRappLegale.numCivicoDomicilio = '';
      datiDomicilioRappLegale.capDomicilio = '';
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_PROVINCIA_DOMICILIO_RAPP_LEGALE:
      datiDomicilioRappLegale.provinciaDomicilio = unValore;
      datiDomicilioRappLegale.descrProvinciaDomicilio = unaLabel;
      datiDomicilioRappLegale.comuneDomicilio = '';
      datiDomicilioRappLegale.descrComuneDomicilio = '';
      datiDomicilioRappLegale.toponimoDomicilio = '';
      datiDomicilioRappLegale.descrToponimoDomicilio = '';
      datiDomicilioRappLegale.indirizzoDomicilio = '';
      datiDomicilioRappLegale.numCivicoDomicilio = '';
      datiDomicilioRappLegale.capDomicilio = '';
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_COMUNE_DOMICILIO_RAPP_LEGALE:
      if(datiDomicilioRappLegale.provinciaDomicilio === PROVINCIA_ESTERA) {
        datiDomicilioRappLegale.comuneDomicilio = '';
        datiDomicilioRappLegale.descrComuneDomicilio = action.unDato;
        datiDomicilioRappLegale.toponimoDomicilio = '';
        datiDomicilioRappLegale.descrToponimoDomicilio = '';
        datiDomicilioRappLegale.indirizzoDomicilio = '';
        datiDomicilioRappLegale.numCivicoDomicilio = '';
        datiDomicilioRappLegale.capDomicilio = '';
      } else {
        datiDomicilioRappLegale.comuneDomicilio = unValore;
        datiDomicilioRappLegale.descrComuneDomicilio = unaLabel;
        datiDomicilioRappLegale.toponimoDomicilio = '';
        datiDomicilioRappLegale.descrToponimoDomicilio = '';
        datiDomicilioRappLegale.indirizzoDomicilio = '';
        datiDomicilioRappLegale.numCivicoDomicilio = '';
        datiDomicilioRappLegale.capDomicilio = '';
      }
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_TOPONIMO_DOMICILIO_RAPP_LEGALE:
      if(datiDomicilioRappLegale.provinciaDomicilio === PROVINCIA_ESTERA) {
        datiDomicilioRappLegale.toponimoDomicilio = '';
        datiDomicilioRappLegale.descrToponimoDomicilio = action.unDato;
        datiDomicilioRappLegale.indirizzoDomicilio = '';
        datiDomicilioRappLegale.numCivicoDomicilio = '';
        datiDomicilioRappLegale.capDomicilio = '';
      } else {
        datiDomicilioRappLegale.toponimoDomicilio = unValore;
        datiDomicilioRappLegale.descrToponimoDomicilio = unaLabel;
        datiDomicilioRappLegale.indirizzoDomicilio = '';
        datiDomicilioRappLegale.numCivicoDomicilio = '';
        datiDomicilioRappLegale.capDomicilio = '';
      }
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_INDIRIZZO_DOMICILIO_RAPP_LEGALE:
      datiDomicilioRappLegale.indirizzoDomicilio = action.unDato;
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_RAPP_LEGALE:
      datiDomicilioRappLegale.numCivicoDomicilio = action.unDato;
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_CAP_DOMICILIO_RAPP_LEGALE:
      datiDomicilioRappLegale.capDomicilio = action.unDato;
      datiRappLegale.datiDomicilio = {...datiDomicilioRappLegale};
      contraenteGiuridico.datiRappLegale = {...datiRappLegale};
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico
      }
    case actionType.UPDATE_PRESENTI_TITOLARE_EFF:
      contraenteGiuridico.flTitolariEff = action.unDato;
      return {
        ...state,
        contraenteGiuridico: contraenteGiuridico,
      };
    default:
      return state;
  }
};

export const resolveDatiAntiriciclaggio = (state, figura, datiAntiriciclaggio) => {

  const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;

  if (figura === 'contraenteFisico') {
    contrFisico.datiAntiriciclaggio = { ...datiAntiriciclaggio };
  } else if (figura === 'esecutoreFisico') {
    contrFisico.datiEsecutore.datiAntiriciclaggio = { ...datiAntiriciclaggio };
  } else if (figura === 'rappLegale') {
    contrGiuridico.datiRappLegale.datiAntiriciclaggio = { ...datiAntiriciclaggio };
  }


  return {
    ...state,
    contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
  };

};

export const resolveDatiConsensoComElettr = (state, figura, datiConsensoComElettr) => {
  const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;

  if (figura === 'contraenteFisico') {
    contrFisico.datiConsensoComElettr = { ...datiConsensoComElettr };
  } else if (figura === 'contraenteGiuridico') {
    contrGiuridico.datiConsensoComElettr = { ...datiConsensoComElettr };
  }

  return {
    ...state,
    contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
  };

};

export const resolveDatiAttEconomica = (state, figura, datiAttEconomica) => {
	 
	const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;	 
  const rappLegale = state.contraenteGiuridico.datiRappLegale;
  const esecFisic = state.contraenteFisico.datiEsecutore;
		  
	if (figura === 'contraenteFisico') { 
		contrFisico.datiAttEconomica = {...datiAttEconomica};
	} else if  (figura === 'contraenteGiuridico') { 
		contrGiuridico.datiAttEconomicaGiuridico = {...datiAttEconomica};
	} else if (figura === 'rappLegale') {
    rappLegale.datiAttEconomica = {...datiAttEconomica};
  } else if (figura === 'esecutoreFisico') {
    esecFisic.datiAttEconomica = {...datiAttEconomica};
  }
	  
	  return {
		...state,
		contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
    esecutoreFisico: esecFisic
	  };
	  
};

export const resolveDatiMandatoFiduciario = (state, figura, datiMandatoFiduciario) => {
  const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;

  if (figura === 'esecutoreFisico') {
    contrFisico.datiMandatoFiduciario = { ...datiMandatoFiduciario };
  } else if (figura === 'contraenteGiuridico') {
    contrGiuridico.datiMandatoFiduciario = {...datiMandatoFiduciario};
  }

  return {
    ...state,
    contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
  };
}

export const resolveDatiGeneraliMinimi = (state, figura, datiGeneraliMinimi) => {

  const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;


  if (figura === 'esecutoreFisico') {
    contrFisico.datiEsecutore.datiGeneraliMinimi = { ...datiGeneraliMinimi };
  } else if (figura === 'rappLegale') {
    contrGiuridico.datiRappLegale.datiGeneraliMinimi = { ...datiGeneraliMinimi };
  }

  return {
    ...state,
    contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
  };

};

export const resolveDatiNaturaRapporto = (state, figura, datiNaturaRapporto) => {

  const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;


  if (figura === 'contraenteFisico') {
    contrFisico.datiNaturaRapporto = { ...datiNaturaRapporto };
  } else if (figura === 'contraenteGiuridico') {
    contrGiuridico.datiNaturaRapporto = { ...datiNaturaRapporto };
  }

  return {
    ...state,
    contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
  };

};

export const resolveDatiFiscali = (state, figura, datiFiscali) => {

  const contrFisico = state.contraenteFisico;
  const contrGiuridico = state.contraenteGiuridico;


  if (figura === 'contraenteFisico') {
    contrFisico.datiFiscali = { ...datiFiscali };
  } else if (figura === 'contraenteGiuridico') {
    contrGiuridico.datiFiscali = { ...datiFiscali };
  }

  return {
    ...state,
    contraenteFisico: contrFisico,
    contraenteGiuridico: contrGiuridico,
  };

};

export const inizializzaContraenteFisico = (state) => {
  const contraenteFisico_INI = { ...state }.contraenteFisico;
  contraenteFisico_INI.anagraficaValida = null;
  contraenteFisico_INI.controlloDati = '';
  contraenteFisico_INI.datiGenerali = jsonCopy(datiGeneraliInizializzato);
  contraenteFisico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  contraenteFisico_INI.datiDomicilio = jsonCopy(datiDomicilioInizializzato);
  contraenteFisico_INI.datiRecapito = jsonCopy(datiRecapitoInizializzato);
  contraenteFisico_INI.datiFiscali = jsonCopy(datiFiscaliInizializzato);
  contraenteFisico_INI.datiConsensoComElettr = jsonCopy(datiConsensoComElettrInizializzato);
  contraenteFisico_INI.datiAttEconomica = jsonCopy(datiAttEconomicaInizializzato);
  contraenteFisico_INI.datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
  contraenteFisico_INI.datiEsecutore = jsonCopy(datiEsecutoreInizializzato);
  contraenteFisico_INI.datiNaturaRapporto = jsonCopy(datiNaturaRapportoInizializzato);
  contraenteFisico_INI.datiFinanziari = jsonCopy(datiFinanziariInizializzato);
  return contraenteFisico_INI;
}

export const inizializzaContraenteGiuridico = (state) => {
  const contraenteGiuridico_INI = { ...state }.contraenteGiuridico;
  contraenteGiuridico_INI.anagraficaValida = null;
  contraenteGiuridico_INI.controlloDati = '';
  contraenteGiuridico_INI.flTitolariEff = '';
  contraenteGiuridico_INI.datiGenerali = jsonCopy(datiGeneraliGiuridicoInizializzato);
  contraenteGiuridico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  contraenteGiuridico_INI.datiRecapito = jsonCopy(datiRecapitoInizializzato);
  contraenteGiuridico_INI.datiConsensoComElettr = jsonCopy(datiConsensoComElettrInizializzato);
  contraenteGiuridico_INI.datiAttEconomicaGiuridico = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  contraenteGiuridico_INI.datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
  contraenteGiuridico_INI.datiFiscali = jsonCopy(datiFiscaliInizializzato);
  contraenteGiuridico_INI.datiRappLegale = jsonCopy(datiRappLegaleInizializzato);
  contraenteGiuridico_INI.datiNaturaRapporto = jsonCopy(datiNaturaRapportoInizializzato);
  contraenteGiuridico_INI.datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
  contraenteGiuridico_INI.datiFinanziari = jsonCopy(datiFinanziariGiuridicoInizializzato);
    return contraenteGiuridico_INI;
}

export default areaContraenteReducer;