//TODO ### DA RIVEDERE ### 	
/* DATI GENERALI */
export const updateDatiGeneraliPerContraente = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RESIDENZA */
export const updateDatiResidenzaPerContraente = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI ANTIRICICLAGGIO */
export const updateDatiAntiriciclaggioPerContraente = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* CONTRAENTE FISICO */
export const updateContraenteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* CONTRAENTE GIURIDICO */
export const updateContraenteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI GENERALI CONTRAENTE FISICO */
export const updateDatiGeneraliContraenteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RESIDENZA CONTRAENTE FISICO */
export const updateDatiResidenzaContraenteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI DOMICILIO CONTRAENTE FISICO */
export const updateDatiDomicilioContraenteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

/* DATI RESIDENZA ESECUTORE FISICO */
export const updateDatiResidenzaEsecutoreFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RECAPITO CONTRAENTE FISICO */
export const updateDatiRecapitoContraenteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI FINANZIARI CONTRAENTE FISICO */
export const updateDatiFinanziariContraenteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

/* DATI FISCALI */
export const updateDatiFiscali = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }  
};

/* DATI CONSENSO COMUNICAZIONI ELETTRONICHE CONTRAENTE FISICO/GIURIDICO */
export const updateDatiConsensoComElettrContraente = (unDato, actionType, figura) => {
  return {
    type: actionType,
    unDato,
    figura,
  }  
};

/* DATI GENERALI CONTRAENTE GIURIDICO */
export const updateDatiGeneraliContraenteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RESIDENZA CONTRAENTE GIURIDICO */
export const updateDatiResidenzaContraenteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RECAPITO CONTRAENTE GIURIDICO */
export const updateDatiRecapitoContraenteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI FINANZIARI CONTRAENTE GIURIDICO */
export const updateDatiFinanziariContraenteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
}

/* DATI GENERALI ESECUTORE FISICO  */
export const updateDatiEsecutore = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RECAPITO ESECUTORE FISICO */
export const updateDatiRecapitoEsecutore = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI DOMICILIO ESECUTORE FISICO */
export const updateDatiDomicilioEsecutore = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA CONTRAENTE FISICO */
export const updateDatiCorrispondenzaContrFisico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA CONTRAENTE GIURIDICO */
export const updateDatiCorrispondenzaContrGiuridico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA ESECUTORE FISICO */
export const updateDatiCorrispondenzaEsecutoreFisico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA INTESTATARIO CONTRAENTE FISICO */
export const updateDatiCorrispondenzaIntestatoContrFisico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA INTESTATARIO CONTRAENTE GIURIDICO */
export const updateDatiCorrispondenzaIntestatarioContrGiuridico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA INTESTATARIO ESECUTORE FISICO */
export const updateDatiCorrispondenzaIntestatarioEsecutoreFisico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};





/* DATI ATTIVITA ECONOMICA ESECUTORE */
export const updateDatiAttEconomicaEsecutore = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI ATTIVITA ECONOMICA  */
export const updateDatiAttEconomica = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }
};

/* DATI MANDATO FIDUCIARIO  */
export const updateDatiMandatoFiduciario = (unDato, unActionType, figura) => {
  return{
    type: unActionType,
    unDato,
    figura,
  }
};

/* DATI ANTIRICICLAGGIO  */
export const updateDatiAntiriciclaggio = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }
};

/* DATI NATURA RAPPORTO  */
export const updateDatiNaturaRapporto = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }  
};

/* DATI ATTIVITA ECONOMICA GIURIDICO  */
export const updateDatiAttEconomicaGiuridico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }  
};

/* DATI RAPPRESENTANTE LEGALE  */
export const updateDatiRappLegale = (unDato, unActionType, figura) => {
 return {
    type: unActionType,
    unDato,
    figura,
  }  
};

export const updateDatiGeneraliMinimiRappLegale = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }  
};


export const updateDatiResidenzaRappLegale = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const updateDatiDomicilioRappLegale = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}


export const updateDatiGeneraliEsecutore = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const inizializzaContraente = (unActionType) => {
  return {
    type: unActionType,
  }
};

export const updateDatiGeneraliContraente = (unDato, unActionType) => {
  return{
    type: unActionType,
    unDato,
  }
};

export const updateDatiSocietari = ( unDato, unActionType ) => {
  return {
    type: unActionType,
    unDato,
  }
};