import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { uris, fetchParam } from '../../../../uris';

import TextFieldComp from '../../../common/TextFieldComp';
import NumberFieldComp from '../../../common/NumberFieldComp';
import SelectComp from '../../../common/SelectComp';
import CheckboxComp from '../../../common/CheckboxComp';
import RadioButtonComp from '../../../common/RadioButtonComp';
import ButtonComp from '../../../common/ButtonComp';

import { appendiceCostiScelta } from '../../../store/radioButtonStore';

import * as actions from './action/datiPremioCapitaleAssicurato';
import * as actionType from './actionType/datiPremioCapitaleAssicurato';
import * as actionGeneric from '../../../generic/action/generic';
import * as actionTypeGeneric from '../../../generic/actionType/generic';

import { DURATA, DURPAG, CAPASS, PRSOTT, PANNUO, PUNICO, FRAZ, PRODOTTO_TABOO_601, locale_it} from '../../../../utility/jsConstants';
import { textTrim, showSezione, checkBoxValue } from '../../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../../utility/tcmIndividualeUtility';


const styles = theme => ({	
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  textFieldWidthPerc_60: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1), 
		height: 48,	
		width: '60%',	
  },
  textFieldWidthPercError_60: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		height: 48,	
		width: '60%',	
  },
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
    datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,
    prodotto: state.areaProdottoReducer.prodotto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiPremioCapitaleAssicurato: (unDato, unActionType) => dispatch(actions.updateDatiPremioCapitaleAssicurato(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class DatiPremioCapitaleAssicurato extends React.Component {
	
  constructor(props) {
    super(props); 
    const datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
    
    this.state = {
	  tariffa: datiPremioCapitaleAssicurato.tariffa,
	  descTariffa: datiPremioCapitaleAssicurato.descTariffa,
// 2019-11-13 PFD:	  edizioneModello: datiPremioCapitaleAssicurato.edizioneModello,
	  durata: datiPremioCapitaleAssicurato.durata,
	  durataPagamentoPremi: datiPremioCapitaleAssicurato.durataPagamentoPremi,
	  capitaleAssicurato: datiPremioCapitaleAssicurato.capitaleAssicurato,
	  malattiaGrave: datiPremioCapitaleAssicurato.malattiaGrave,
	  capitaleAssicuratoMalattiaGrave: datiPremioCapitaleAssicurato.capitaleAssicuratoMalattiaGrave,
	  descCapitaleAssicuratoMalattiaGrave: datiPremioCapitaleAssicurato.descCapitaleAssicuratoMalattiaGrave,
	  complementareInfortuni: datiPremioCapitaleAssicurato.complementareInfortuni,
	  frazionamento: datiPremioCapitaleAssicurato.frazionamento,
	  descFrazionamento: datiPremioCapitaleAssicurato.descFrazionamento,
	  premioRata: datiPremioCapitaleAssicurato.premioRata,
	  premioAnnuo: datiPremioCapitaleAssicurato.premioAnnuo,
	  premioUnico: datiPremioCapitaleAssicurato.premioUnico,
	  appendiceCosti: datiPremioCapitaleAssicurato.appendiceCosti,
		  
	  durataShow : false,
	  durataPagamentoPremiShow : false,
	  capitaleAssicuratoShow : false,
	  premioRataShow : false,
	  premioAnnuoShow : false,
	  premioUnicoShow : false,
	  frazionamentoShow : false,
	  
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,	  
	};
  };
  
  componentDidMount() {	  
	const datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
    this.setCampiTariffa(datiPremioCapitaleAssicurato ? datiPremioCapitaleAssicurato.tariffa : '');
  }  
  
  handleChangeConfermaDatiPremioCapitaleAssicurato = event => {
    this.props.updateDatiPremioCapitaleAssicurato(true, actionType.SALVA_DATI_PREMIO_CAPITALE_ASSICURATO);	  
  } 
  
  handleChangeTariffa = event => {
    const nuovaTariffa = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaTariffa = textTrim(this.state.tariffa, true); 
    if (nuovaTariffa !== vecchiaTariffa) {   	  
      this.props.updateDatiPremioCapitaleAssicurato(event, actionType.UPDATE_TARIFFA); 
      this.setState({
        tariffa: (event === null) ? '' : event.value,
        descTariffa: (event === null) ? '' : event.label,
      });	
      this.setCampiTariffa((event === null) ? '' : event.value);
      
      // Se per la tariffa selezionata non e' obbligatoria allora resetto il frazionamento se precedentemnte selezionato.
      if(this.isTariffaDisabled && this.props.datiPremioCapitaleAssicurato.frazionamento!==''){
    	  this.props.updateDatiPremioCapitaleAssicurato({value: '', label: ''}, actionType.UPDATE_FRAZIONAMENTO); 	
          this.setState({
            frazionamento: '',
            descFrazionamento: '',
          });
      }
    }
  }  
  /* 2019-11-13 PFD:
  handleChangeEdizioneModello = event => {
    const nuovaEdizioneModello = textTrim(event.target.value, true);
    const vecchiaEdizioneModello = textTrim(this.state.edizioneModello, true); 
    if (nuovaEdizioneModello !== vecchiaEdizioneModello) { 	  
	  this.props.updateDatiPremioCapitaleAssicurato(nuovaEdizioneModello, actionType.UPDATE_EDIZIONE_MODELLO);   
	  this.setState({ 
	    edizioneModello: nuovaEdizioneModello, 
	  });
    }
  };
  */ 
  handleChangeDurata = event => {
    const nuovaDurata = textTrim(event.target.value, true);
    const vecchiaDurata = textTrim(this.state.durata, true); 
    if (nuovaDurata !== vecchiaDurata) { 	  	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovaDurata, actionType.UPDATE_DURATA); 	
      this.setState({
        durata: nuovaDurata,
      });
    }
  }  
  
  handleChangeDurataPagamentoPremi = event => {
    const nuovaDurataPagamentoPremi = textTrim(event.target.value, true);
    const vecchiaDurataPagamentoPremi = textTrim(this.state.durataPagamentoPremi, true); 
    if (nuovaDurataPagamentoPremi !== vecchiaDurataPagamentoPremi) {	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovaDurataPagamentoPremi, actionType.UPDATE_DURATA_PAGAMENTO_PREMI); 	
      this.setState({
        durataPagamentoPremi: nuovaDurataPagamentoPremi,
      });
    }
  }  
  
  handleChangeCapitaleAssicurato = event => {
    const nuovoCapitaleAssicurato = textTrim(event.target.value, true);
    const vecchioCapitaleAssicurato = textTrim(this.state.capitaleAssicurato, true); 
    if (nuovoCapitaleAssicurato !== vecchioCapitaleAssicurato) {	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovoCapitaleAssicurato, actionType.UPDATE_CAPITALE_ASSICURATO); 	
      this.setState({
        capitaleAssicurato: nuovoCapitaleAssicurato,
      });
    }
  }  
  
  handleChangeMalattiaGrave = event => {
    const nuovaMalattiaGrave = checkBoxValue(event.target.checked);
    const vecchiaMalattiaGrave = checkBoxValue(this.state.malattiaGrave); 
    if (nuovaMalattiaGrave !== vecchiaMalattiaGrave) {	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovaMalattiaGrave, actionType.UPDATE_MALATTIA_GRAVE);
      this.setState({ 
        malattiaGrave: nuovaMalattiaGrave,
      });
      if (nuovaMalattiaGrave === false) {
        this.props.updateDatiPremioCapitaleAssicurato(null, actionType.UPDATE_CAPITALE_ASSICURATO_MALATTIA_GRAVE); 
        this.setState({
          capitaleAssicuratoMalattiaGrave: '',
          descCapitaleAssicuratoMalattiaGrave: '',
        });
      }
    }
  }
  
  handleChangeCapitaleAssicuratoMalattiaGrave = event => { 
    const nuovoCapitaleAssicuratoMalattiaGrave = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchioCapitaleAssicuratoMalattiaGrave = textTrim(this.state.capitaleAssicuratoMalattiaGrave, true); 
    if (nuovoCapitaleAssicuratoMalattiaGrave !== vecchioCapitaleAssicuratoMalattiaGrave) {
      this.props.updateDatiPremioCapitaleAssicurato(event, actionType.UPDATE_CAPITALE_ASSICURATO_MALATTIA_GRAVE); 	
      this.setState({
        capitaleAssicuratoMalattiaGrave: (event === null) ? '' : event.value,
        descCapitaleAssicuratoMalattiaGrave: (event === null) ? '' : event.label,
      });	
    }
  }  
  
  handleChangeComplementareInfortuni = event => {
    const nuovaComplementareInfortuni = checkBoxValue(event.target.checked);
    const vecchiaComplementareInfortuni = checkBoxValue(this.state.complementareInfortuni); 
    if (nuovaComplementareInfortuni !== vecchiaComplementareInfortuni) {		  
      this.props.updateDatiPremioCapitaleAssicurato(nuovaComplementareInfortuni, actionType.UPDATE_COMPLEMENTARE_INFORTUNI);
      this.setState({ 
        complementareInfortuni: nuovaComplementareInfortuni,
      });
    }
  }
  
  handleChangeFrazionamento = event => { 
    const nuovoFrazionamento = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchioFrazionamento = textTrim(this.state.frazionamento, true); 
    if (nuovoFrazionamento !== vecchioFrazionamento) {
      this.props.updateDatiPremioCapitaleAssicurato(event, actionType.UPDATE_FRAZIONAMENTO); 	
      this.setState({
        frazionamento: (event === null) ? '' : event.value,
        descFrazionamento: (event === null) ? '' : event.label,
      });
    }
  }  
  
  handleChangePremioRata = event => {
    const nuovoPremioRata = textTrim(event.target.value, true);
    const vecchioPremioRata = textTrim(this.state.premioRata, true); 
    if (nuovoPremioRata !== vecchioPremioRata) {	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovoPremioRata, actionType.UPDATE_PREMIO_RATA); 	
      this.setState({
        premioRata: nuovoPremioRata,
      });
    }
    
    //2021-07-01 PFD: TCM_INDIV
    this.handleTcmIndividualeValida();
  } 
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  handleChangePremioAnnuo = event => {
    const nuovoPremioAnnuo = textTrim(event.target.value, true);
    const vecchioPremioAnnuo = textTrim(this.state.premioAnnuo, true); 
    if (nuovoPremioAnnuo !== vecchioPremioAnnuo) {	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovoPremioAnnuo, actionType.UPDATE_PREMIO_ANNUO); 	
      this.setState({
        premioAnnuo: nuovoPremioAnnuo,
      });
    }
    
    //2021-07-01 PFD: TCM_INDIV
    this.handleTcmIndividualeValida();
  } 
  
  handleChangePremioUnico = event => {
    const nuovoPremioUnico = textTrim(event.target.value, true);
    const vecchioPremioUnico = textTrim(this.state.premioUnico, true); 
    if (nuovoPremioUnico !== vecchioPremioUnico) {	  
      this.props.updateDatiPremioCapitaleAssicurato(nuovoPremioUnico, actionType.UPDATE_PREMIO_UNICO); 	
      this.setState({
        premioUnico: nuovoPremioUnico,
      });	
    }
    
    //2021-07-01 PFD: TCM_INDIV
    this.handleTcmIndividualeValida();
  } 
  
  handleChangeAppendiceCosti = event => {
    const scelta = event.target.value;
    this.props.updateDatiPremioCapitaleAssicurato(scelta, actionType.UPDATE_APPENDICE_COSTI_PREMIO_CAPITALE_ASSIC);
    this.setState({
      appendiceCosti: scelta,
    })
  }
  
  setCampiTariffa = (tariffa) => {
    if(textTrim(tariffa, true)!==''){
	    const opts = {
	      siglaVersione : "ZURI",
	      codiceTariffa: tariffa,
	    };
	    fetch(uris.campiTariffaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      // If request is good update state with fetched data
          const items = result.map((item) => {
		                            return (item.value)
                                  }); 	      
	      this.setState({
	        durataShow : showSezione(items, DURATA),
	    	durataPagamentoPremiShow : showSezione(items, DURPAG),
	    	capitaleAssicuratoShow : showSezione(items, CAPASS),
	    	premioRataShow : showSezione(items, PRSOTT),
	    	premioAnnuoShow : showSezione(items, PANNUO),
	    	premioUnicoShow : showSezione(items, PUNICO),
	    	frazionamentoShow : showSezione(items, FRAZ),
		  });
	    });
    }else{
      this.setState({
        durataShow : false,
    	durataPagamentoPremiShow : false,
    	capitaleAssicuratoShow : false,
    	premioRataShow : false,
    	premioAnnuoShow : false,
    	premioUnicoShow : false,
    	frazionamentoShow : false,
	  });
    }
  }
  
  isRequiredTariffa = () => {
	return true;
  }
  /* 2019-11-13 PFD:
  isRequiredEdizioneModello = () => {
	return true;
  }
  */
  isRequiredDurata = () => {
	return true;
  }
  
  isRequiredDurataPagamentoPremi = () => {
	return true;
  }
  
  isRequiredCapitaleAssicurato = () => {
	return true;
  }
  
  isRequiredMalattiaGrave = () => {
	return false;
  }
  
  isRequiredCapitaleAssicuratoMalattiaGrave = () => {
    return this.props.datiPremioCapitaleAssicurato.malattiaGrave;  
  }
	    
  isRequiredComplementareInfortuni = () => {
	return false;
  }
  
  isRequiredPremioRata = () => {
	return true;
  }
  
  isRequiredPremioAnnuo = () => {
    return (this.props.datiPremioCapitaleAssicurato.frazionamento === '1');
  }
  
  isRequiredPremioUnico = () => {
	return true;
  }
  
  isRequiredFrazionamento = () => {
	  //Per le tariffe '601' e '621' del prodotto TABOO_601 il frazionamento non e' obbligatorio 
	  return !this.isTariffaDisabled() ;
  }
 
  isDisabledFrazionamento = () => {
	  //Per le tariffe '601' e '621' del prodotto TABOO_601 il frazionamento e' disabled 
	  return this.isTariffaDisabled() ;
  }
  
  isDisabledCapitaleAssicuratoMalattiaGrave = () => {
	return (this.props.datiPremioCapitaleAssicurato.malattiaGrave !== true);  
  }
  
  isTariffaDisabled = () => {
	  var disabled = false;
	  const codiceProdotto = this.props.prodotto.codice;
	  //2020-12-01 PFD: SAV 'TO BE' - START
	  if (codiceProdotto === PRODOTTO_TABOO_601) {
		  if (this.props.datiPremioCapitaleAssicurato.tariffa === '601' || this.props.datiPremioCapitaleAssicurato.tariffa === '621') {
			  disabled = true;
		  }
	  }
	  return disabled;
  }
  
  isRequiredAppendiceCostiScelta = () => {
    return true;
  }

  
  initStatoCampi = () => {
    return [ {id: 'tariffa', isError: false},
// 2019-11-13 PFD:             {id: 'edizioneModello', isError: false}, 
             {id: 'durata', isError: false}, 
             {id: 'durataPagamentoPremi', isError: false},
             {id: 'capitaleAssicurato', isError: false},             
             {id: 'malattiaGrave', isError: false},
             {id: 'capitaleAssicuratoMalattiaGrave', isError: false},
             {id: 'complementareInfortuni', isError: false},
             {id: 'premioRata', isError: false},
             {id: 'premioAnnuo', isError: false},
             {id: 'premioUnico', isError: false},
             {id: 'frazionamento', isError: false},     
             {id: 'appendiceCostiScelta', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }  
  
  render() {
    const { classes, datiPremioCapitaleAssicurato, dominio } = this.props;
    const { t } = this.props; //hoc  
    const controlloDati = datiPremioCapitaleAssicurato.controlloDati;
    
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="subtitle1">{t('pianoErogazioniPeriodiche')}</Typography></legend>
        <DividerComp titolo='pianoErogazioniPeriodiche' variant="subtitle1" />*/}
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
          <Grid item md={5} xs={12}>
            <SelectComp onChange={this.handleChangeTariffa} options={dominio.tariffaItems} ricerca='comboRicerca' etichetta='tariffa'
                        value={{value: datiPremioCapitaleAssicurato.tariffa, label: datiPremioCapitaleAssicurato.descTariffa}} required={this.isRequiredTariffa()} 
                        controlloDati={controlloDati} id='tariffa' onError={this.handleError} />
		  </Grid>
		  <Grid item md={7} xs={12}>
		  {/*  2019-11-13 PFD:
		    <TextFieldComp onBlur={this.handleChangeEdizioneModello} aStyle={classes.textField} id='edizioneModello' label='edizioneModello' 
		                   value={datiPremioCapitaleAssicurato.edizioneModello} required={this.isRequiredEdizioneModello()} 
		                   controlloDati={controlloDati} onError={this.handleError} />
		  */} 
          </Grid>
          {this.state.durataShow === true && this.state.durataPagamentoPremiShow === true &&
          <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
              <Grid item md={4} xs={12}>
{/*                <TextFieldComp onBlur={this.handleChangeDurata} aStyle={classes.textField} id='durata' label='durata' value={datiPremioCapitaleAssicurato.durata} 
                               required={this.isRequiredDurata()} controlloDati={controlloDati} onError={this.handleError} />*/}
					<NumberFieldComp onBlur={this.handleChangeDurata} aStyle={classes.textField} id={'durata'} label='durata' value={datiPremioCapitaleAssicurato.durata}
						required={this.isRequiredDurata()} controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={0} />
             
			  </Grid>
              <Grid item md={8} xs={12}>
{/*                <TextFieldComp onBlur={this.handleChangeDurataPagamentoPremi} aStyle={classes.textField} id='durataPagamentoPremi' label='durataPagamentoPremi' 
		                       value={datiPremioCapitaleAssicurato.durataPagamentoPremi} required={this.isRequiredDurataPagamentoPremi()} 
                               controlloDati={controlloDati} onError={this.handleError} /> */}
              
					<NumberFieldComp onBlur={this.handleChangeDurataPagamentoPremi} aStyle={classes.textField} id={'durataPagamentoPremi'} label='durataPagamentoPremi' value={datiPremioCapitaleAssicurato.durataPagamentoPremi}
						required={this.isRequiredDurataPagamentoPremi()} controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={0} />
              </Grid>
		    </Grid>
		  </Grid>
		  }
		  {this.state.durataShow === true && this.state.durataPagamentoPremiShow === false && 
	      <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
	          <Grid item md={4} xs={12}>
{/*                <TextFieldComp onBlur={this.handleChangeDurata} aStyle={classes.textField} id='durata' label='durata' value={datiPremioCapitaleAssicurato.durata} 
                               required={this.isRequiredDurata()} controlloDati={controlloDati} onError={this.handleError} /> */}

                <NumberFieldComp onBlur={this.handleChangeDurata} aStyle={classes.textField} id={'durata'} label='durata' 
						value={datiPremioCapitaleAssicurato.durata} required={this.isRequiredDurata()} 
						controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={0} />
              </Grid>
              <Grid item md={8} xs={12}></Grid>
            </Grid>
		  </Grid>
  	      }
  	      {this.state.durataShow === false && this.state.durataPagamentoPremiShow === true &&
	      <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
	          <Grid item md={4} xs={12}>
{/*                <TextFieldComp onBlur={this.handleChangeDurataPagamentoPremi} aStyle={classes.textField} id='durataPagamentoPremi' label='durataPagamentoPremi' 
	                       	   value={datiPremioCapitaleAssicurato.durataPagamentoPremi} required={this.isRequiredDurataPagamentoPremi()} 
                               controlloDati={controlloDati} onError={this.handleError} /> */}
                
					<NumberFieldComp onBlur={this.handleChangeDurataPagamentoPremi} aStyle={classes.textField} id={'durataPagamentoPremi'} label='durataPagamentoPremi' 
						value={datiPremioCapitaleAssicurato.durataPagamentoPremi} required={this.isRequiredDurataPagamentoPremi()} 
						controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={0} />
              
			  </Grid>
              <Grid item md={8} xs={12}></Grid>
            </Grid>
          </Grid>
          }
          {this.state.capitaleAssicuratoShow === true &&
  	      <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
		      <Grid item md={12} xs={12}>
		      
{/*		        <TextFieldComp onBlur={this.handleChangeCapitaleAssicurato} aStyle={classes.textField} id='capitaleAssicurato' label='capitaleAssicurato' 
		        	           value={datiPremioCapitaleAssicurato.capitaleAssicurato} required={this.isRequiredCapitaleAssicurato()} 
		                       controlloDati={controlloDati} onError={this.handleError} /> */}
				
				<NumberFieldComp onBlur={this.handleChangeCapitaleAssicurato} aStyle={classes.textField} id={'capitaleAssicurato'} label='capitaleAssicurato' 
						value={datiPremioCapitaleAssicurato.capitaleAssicurato} required={this.isRequiredCapitaleAssicurato()} 
						controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} />
		      </Grid>
		      <Grid item md={2} xs={12}>
		        <CheckboxComp onChange={this.handleChangeMalattiaGrave} id='malattiaGrave' aLabel='malattiaGrave' checked={datiPremioCapitaleAssicurato.malattiaGrave} 
		                      required={this.isRequiredMalattiaGrave()} controlloDati={controlloDati} onError={this.handleError} />
		      </Grid>
		      <Grid item md={10} xs={12}>
		        <Grid container direction='row' justify='flex-start' alignItems='center'>  
		          <Grid item md={6} xs={12}>
				    <SelectComp onChange={this.handleChangeCapitaleAssicuratoMalattiaGrave} options={dominio.capitaleAssicuratoMalattiaGraveItems} ricerca='comboRicerca' 
					          etichetta='capitaleAssicuratoMalattiaGrave' value={{value: datiPremioCapitaleAssicurato.capitaleAssicuratoMalattiaGrave, label: datiPremioCapitaleAssicurato.descCapitaleAssicuratoMalattiaGrave}} 
				              required={this.isRequiredCapitaleAssicuratoMalattiaGrave()} controlloDati={controlloDati} id='capitaleAssicuratoMalattiaGrave' 
				              onError={this.handleError} disabled={this.isDisabledCapitaleAssicuratoMalattiaGrave()} />
				  </Grid>
				  <Grid item md={6} xs={12}>
				    <Typography variant='caption'>{t('fasceCapitaleAmmesse')}</Typography>
			      </Grid>
		        </Grid>
			  </Grid>
			  <Grid item md={12} xs={12}>
	            <Grid container direction='row' justify='flex-start' alignItems='center'>  
                  <Grid item md={3} xs={12}>
		            <CheckboxComp onChange={this.handleChangeComplementareInfortuni} id='complementareInfortuni' aLabel='complementareInfortuni' 
		            	          checked={datiPremioCapitaleAssicurato.complementareInfortuni} required={this.isRequiredComplementareInfortuni()} 
		                          controlloDati={controlloDati} onError={this.handleError} />
			      </Grid>
                  <Grid item md={9} xs={12}>
			        <Typography variant='caption'>{t('capitaleMassimoAssicurabile')}</Typography>
                  </Grid>
                </Grid>
		      </Grid>
		    </Grid>
		  </Grid>
   	      }
		  {this.state.premioRataShow === true && this.state.premioAnnuoShow === true &&
          <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
		      <Grid item md={5} xs={12}>
{/*		        <TextFieldComp onBlur={this.handleChangePremioRata} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='premioRata' 
		        	           label='premioRata' value={datiPremioCapitaleAssicurato.premioRata} required={this.isRequiredPremioRata()} 
		                       controlloDati={controlloDati} onError={this.handleError} /> */}
{/*20210615				<NumberFieldComp onBlur={this.handleChangePremioRata} aStyle={classes.textFieldWidthPerc_60} aStyleError={classes.textFieldWidthPercError_60} id={'premioRata'} label='premioRata' */} 
				<NumberFieldComp onBlur={this.handleChangePremioRata} aStyle={classes.textFieldWidthPerc_60} aStyleError={classes.textFieldWidthPercError_60} id={'premioRata'} label='premioRataVersSottoscrizione'
					value={datiPremioCapitaleAssicurato.premioRata} required={this.isRequiredPremioRata()} 
					controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} />
		      </Grid>
		      <Grid item md={7} xs={12}>
{/*		        <TextFieldComp onBlur={this.handleChangePremioAnnuo} aStyle={classes.textField} id='premioAnnuo' label='premioAnnuo' value={datiPremioCapitaleAssicurato.premioAnnuo} 
		                       required={this.isRequiredPremioAnnuo()} controlloDati={controlloDati} onError={this.handleError} /> */}
				<NumberFieldComp onBlur={this.handleChangePremioAnnuo} aStyle={classes.textField} id={'premioAnnuo'} label='premioAnnuo' value={datiPremioCapitaleAssicurato.premioAnnuo} 
					required={this.isRequiredPremioAnnuo()} controlloDati={controlloDati} onError={this.handleError} 
					maximumFractionDigits={2} />
		      </Grid>
            </Grid>
          </Grid>
		  }
		  {this.state.premioRataShow === true && this.state.premioAnnuoShow === false && 
          <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
		      <Grid item md={4} xs={12}>
{/*	            <TextFieldComp onBlur={this.handleChangePremioRata} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
	                           id='premioRata' label='premioRata' value={datiPremioCapitaleAssicurato.premioRata} required={this.isRequiredPremioRata()} 
	                           controlloDati={controlloDati} onError={this.handleError} /> */}
{/*20210615				<NumberFieldComp onBlur={this.handleChangePremioRata} aStyle={classes.textFieldWidthPerc_60} aStyleError={classes.textFieldWidthPercError_60} id={'premioRata'} label='premioRata' */}
				<NumberFieldComp onBlur={this.handleChangePremioRata} aStyle={classes.textFieldWidthPerc_60} aStyleError={classes.textFieldWidthPercError_60} id={'premioRata'} label='premioRataVersSottoscrizione'
					value={datiPremioCapitaleAssicurato.premioRata} required={this.isRequiredPremioRata()} controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} />
	          </Grid>
	          <Grid item md={8} xs={12}></Grid>
	        </Grid>
		  </Grid>
		  }
		  {this.state.premioRataShow === false && this.state.premioAnnuoShow === true &&
		  <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
		      <Grid item md={5} xs={12}>		      
{/*	            <TextFieldComp onBlur={this.handleChangePremioAnnuo} aStyle={classes.textField} id='premioAnnuo' label='premioAnnuo' value={datiPremioCapitaleAssicurato.premioAnnuo} 
	                           required={this.isRequiredPremioAnnuo()} controlloDati={controlloDati} onError={this.handleError} /> */}
				<NumberFieldComp onBlur={this.handleChangePremioAnnuo} aStyle={classes.textField} id={'premioAnnuo'} label='premioAnnuo' value={datiPremioCapitaleAssicurato.premioAnnuo} 
					required={this.isRequiredPremioAnnuo()} controlloDati={controlloDati} onError={this.handleError} 
					maximumFractionDigits={2} />
              </Grid>
              <Grid item md={7} xs={12}></Grid>
	        </Grid>
          </Grid>
		  }
		  {this.state.premioUnicoShow === true &&
		  <Grid item md={12} xs={12}> 
		  	<Grid container direction='row' justify='flex-start' alignItems='center'>  
		  	  <Grid item md={5} xs={12}>	
{/*		    <TextFieldComp onBlur={this.handleChangePremioUnico} aStyle={classes.textField} id='premioUnico' label='premioUnico' 
		                   value={datiPremioCapitaleAssicurato.premioUnico} required={this.isRequiredPremioUnico()} controlloDati={controlloDati} 
		                   onError={this.handleError} />*/}
{/*20210615			    <NumberFieldComp onBlur={this.handleChangePremioUnico} aStyle={classes.textFieldWidthPerc_60} aStyleError={classes.textFieldWidthPercError_60} id={'premioUnico'} label='premioUnico' */}
				<NumberFieldComp onBlur={this.handleChangePremioUnico} aStyle={classes.textFieldWidthPerc_60} aStyleError={classes.textFieldWidthPercError_60} id={'premioUnico'} label='premioRataVersSottoscrizione'
			    	value={datiPremioCapitaleAssicurato.premioUnico} required={this.isRequiredPremioUnico()} controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} />
			  </Grid>
	          <Grid item md={7} xs={12}></Grid>
	        </Grid>
		  </Grid>
		  }
		  {this.state.frazionamentoShow === true &&
		  <Grid item md={12} xs={12}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>  
		      <Grid item md={4} xs={12}>
		        <SelectComp onChange={this.handleChangeFrazionamento} options={dominio.frequenzaCapitaleAssicuatoItems} ricerca='comboRicerca' etichetta='frazionamento' 
		        	    value={{value: datiPremioCapitaleAssicurato.frazionamento, label: datiPremioCapitaleAssicurato.descFrazionamento}} 
		                required={this.isRequiredFrazionamento()} controlloDati={controlloDati} id='frazionamento' onError={this.handleError} disabled={this.isDisabledFrazionamento()}/>
		      </Grid>
		      <Grid item md={8} xs={12}></Grid>
		    </Grid>
		  </Grid>
		  }
		  
		  {/* 2020-12-01 PFD: SAV 'TO BE' - START */}
		  {this.state.tariffa && this.state.tariffa !== '' && 
			  <Grid container direction='row' justify='flex-start' alignItems='center'>  
			  	<Grid item xs={12}>
		        	<RadioButtonComp onChange={this.handleChangeAppendiceCosti} radioComp={appendiceCostiScelta} classes={{divider: classes.divider}} selectedValue={datiPremioCapitaleAssicurato.appendiceCosti} 
		               required={this.isRequiredAppendiceCostiScelta()} controlloDati={controlloDati} id='appendiceCostiScelta' onError={this.handleError} />
	        	</Grid>
		      </Grid>
		  }
		  
		  {this.props.datiPremioCapitaleAssicurato.appendiceCosti === 'S' &&
              <Grid item>
              	<Typography className={classes.textErrorMessage}>{t("infoPropostaAppendice")}</Typography>
              </Grid>
		  }
		{/* 2020-12-01 PFD: SAV 'TO BE' - END */}
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center'>   	
              <Grid item>
              {(this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(this.state.isPresenteErrore === false) && (this.props.datiPremioCapitaleAssicurato.controlloDati !== '') && 
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
              </Grid>
            </Grid>
          </Grid>		  		  
		  <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
	          <Grid item>
	                <ButtonComp
	                  aClassName={classes.button}
	                  buttonLabel={t('conferma')}
	                  onClick={this.handleChangeConfermaDatiPremioCapitaleAssicurato}
	                />
	          </Grid>
	        </Grid>
	      </Grid>
		</Grid>
      {/*</fieldset>*/}
	  </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiPremioCapitaleAssicurato componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########DatiPremioCapitaleAssicurato componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########DatiPremioCapitaleAssicurato componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
//	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiPremioCapitaleAssicurato.controlloDati === true)) {    
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiPremioCapitaleAssicurato(!isPresenteCampiConErrore, actionType.UPDATE_DATI_PREMIO_CAPITALE_ASSICURATO_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }  
}

DatiPremioCapitaleAssicurato.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiPremioCapitaleAssicurato)));

