/* BENEFICIARIO VITA FISICO */
export const SALVA_BENEF_VITA_FISICO    = 'SALVA_BENEF_VITA_FISICO';
export const SALVA_BENEF_VITA_FISICO_SUCCESS    = 'SALVA_BENEF_VITA_FISICO_SUCCESS';
export const UPDATE_BENEF_VITA_FISICO   = 'UPDATE_BENEF_VITA_FISICO';
export const UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_FISICO = 'UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_FISICO';
//export const AGGIUNGI_BENEF_VITA_FISICO = 'AGGIUNGI_BENEF_VITA_FISICO';
export const CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_FISICO = 'CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_FISICO';
export const NUOVO_BENEFICIARIO_VITA_FISICO = 'NUOVO_BENEFICIARIO_VITA_FISICO';


/* BENEFICIARIO VITA FISICO DATI GENERALI */
export const UPDATE_CODICE_FISCALE_BENEF_VITA_FISICO           = 'UPDATE_CODICE_FISCALE_BENEF_VITA_FISICO';
export const UPDATE_CODICE_FISCALE_VALIDO_BENEF_VITA_FISICO    = 'UPDATE_CODICE_FISCALE_VALIDO_BENEF_VITA_FISICO';
export const UPDATE_NOME_BENEF_VITA_FISICO                     = 'UPDATE_NOME_BENEF_VITA_FISICO';
export const UPDATE_COGNOME_BENEF_VITA_FISICO                  = 'UPDATE_COGNOME_BENEF_VITA_FISICO';
export const UPDATE_SESSO_BENEF_VITA_FISICO                    = 'UPDATE_SESSO_BENEF_VITA_FISICO';
export const UPDATE_DATA_NASCITA_BENEF_VITA_FISICO             = 'UPDATE_DATA_NASCITA_BENEF_VITA_FISICO';
export const UPDATE_STATO_NASCITA_BENEF_VITA_FISICO            = 'UPDATE_STATO_NASCITA_BENEF_VITA_FISICO';
export const UPDATE_PROVINCIA_NASCITA_BENEF_VITA_FISICO        = 'UPDATE_PROVINCIA_NASCITA_BENEF_VITA_FISICO';
export const UPDATE_COMUNE_NASCITA_BENEF_VITA_FISICO           = 'UPDATE_COMUNE_NASCITA_BENEF_VITA_FISICO';
export const UPDATE_CITTADINANZA_1_BENEF_VITA_FISICO           = 'UPDATE_CITTADINANZA_1_BENEF_VITA_FISICO';
export const UPDATE_CITTADINANZA_2_BENEF_VITA_FISICO           = 'UPDATE_CITTADINANZA_2_BENEF_VITA_FISICO';
export const UPDATE_NUM_TELEFONO_BENEF_VITA_FISICO             = 'UPDATE_NUM_TELEFONO_BENEF_VITA_FISICO';
export const UPDATE_PERCENTUALE_BENEF_VITA_FISICO              = 'UPDATE_PERCENTUALE_BENEF_VITA_FISICO';
export const UPDATE_BENE_DIVERSO_IMP_BENEF_VITA_FISICO         = 'UPDATE_BENE_DIVERSO_IMP_BENEF_VITA_FISICO';

/* BENEFICIARIO VITA FISICO DATI RESIDENZA */
export const UPDATE_STATO_RESIDENZA_BENEF_VITA_FISICO         = 'UPDATE_STATO_RESIDENZA_BENEF_VITA_FISICO';
export const UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_FISICO     = 'UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_FISICO';
export const UPDATE_COMUNE_RESIDENZA_BENEF_VITA_FISICO        = 'UPDATE_COMUNE_RESIDENZA_BENEF_VITA_FISICO';
export const UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_FISICO      = 'UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_FISICO';
export const UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_FISICO     = 'UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_FISICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_FISICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_FISICO';
export const UPDATE_CAP_RESIDENZA_BENEF_VITA_FISICO           = 'UPDATE_CAP_RESIDENZA_BENEF_VITA_FISICO';

/* BENEFICIARIO VITA FISICO DATI ANTIRICICLAGGIO */
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_BENEF_VITA_FISICO = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_BENEF_VITA_FISICO';
export const UPDATE_CARICA_PEP_BENEF_VITA_FISICO               = 'UPDATE_CARICA_PEP_BENEF_VITA_FISICO';
export const UPDATE_CARICA_PUBBLICA_NO_PEP_BENEF_VITA_FISICO   = 'UPDATE_CARICA_PUBBLICA_NO_PEP_BENEF_VITA_FISICO';

/* BENEFICIARIO VITA FISICO DATI RELAZ CONTRAENTE */
export const UPDATE_EMAIL_BENEF_VITA_FISICO                 = 'UPDATE_EMAIL_BENEF_VITA_FISICO';
export const UPDATE_RELAZ_CONTR_BENEF_VITA_FISICO           = 'UPDATE_RELAZ_CONTR_BENEF_VITA_FISICO';
export const UPDATE_ESCLUS_COMUNIC_BENEF_VITA_FISICO        = 'UPDATE_ESCLUS_COMUNIC_BENEF_VITA_FISICO';
export const UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_FISICO     = 'UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_FISICO';

/* BENEFICIARIO VITA FISICO COPIA DA FIGURA */
export const UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_FISICO   = 'UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_FISICO';
export const UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_FISICO = 'UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_FISICO';

/* BENEFICIARIO VITA GIURIDICO */
export const SALVA_BENEF_VITA_GIURIDICO    = 'SALVA_BENEF_VITA_GIURIDICO';
export const SALVA_BENEF_VITA_GIURIDICO_SUCCESS    = 'SALVA_BENEF_VITA_GIURIDICO_SUCCESS';
export const UPDATE_BENEF_VITA_GIURIDICO   = 'UPDATE_BENEF_VITA_GIURIDICO';
export const UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GIURIDICO = 'UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GIURIDICO';
export const UPDATE_ERRORE_FIDUCIANTE_TE_VITA                                   = 'UPDATE_ERRORE_FIDUCIANTE_TE_VITA';
//export const AGGIUNGI_BENEF_VITA_GIURIDICO = 'AGGIUNGI_BENEF_VITA_GIURIDICO';
export const CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GIURIDICO = 'CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GIURIDICO';
export const NUOVO_BENEFICIARIO_VITA_GIURIDICO = 'NUOVO_BENEFICIARIO_VITA_GIURIDICO';

/* BENEFICIARIO VITA GIURIDICO DATI GENERALI */
export const UPDATE_RAGIONE_SOCIALE_BENEF_VITA_GIURIDICO                = 'UPDATE_RAGIONE_SOCIALE_BENEF_VITA_GIURIDICO';
export const UPDATE_CODICE_FISCALE_BENEF_VITA_GIURIDICO                 = 'UPDATE_CODICE_FISCALE_BENEF_VITA_GIURIDICO';
export const UPDATE_DATA_NASCITA_BENEF_VITA_GIURIDICO                   = 'UPDATE_DATA_NASCITA_BENEF_VITA_GIURIDICO';
export const UPDATE_PERCENTUALE_BENEF_VITA_GIURIDICO                    = 'UPDATE_PERCENTUALE_BENEF_VITA_GIURIDICO';
export const UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_VITA_GIUR        = 'UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_VITA_GIUR';
export const TITOLARI_EFFETTIVI_BENEFICIARI_VITA                        = 'TITOLARI_EFFETTIVI_BENEFICIARI_VITA';

/* BENEFICIARIO VITA GIURIDICO DATI RESIDENZA */
export const UPDATE_STATO_RESIDENZA_BENEF_VITA_GIURIDICO         = 'UPDATE_STATO_RESIDENZA_BENEF_VITA_GIURIDICO';
export const UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_GIURIDICO     = 'UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_GIURIDICO';
export const UPDATE_COMUNE_RESIDENZA_BENEF_VITA_GIURIDICO        = 'UPDATE_COMUNE_RESIDENZA_BENEF_VITA_GIURIDICO';
export const UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_GIURIDICO      = 'UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_GIURIDICO';
export const UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_GIURIDICO     = 'UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_GIURIDICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_GIURIDICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_GIURIDICO';
export const UPDATE_CAP_RESIDENZA_BENEF_VITA_GIURIDICO           = 'UPDATE_CAP_RESIDENZA_BENEF_VITA_GIURIDICO';

/* BENEFICIARIO VITA GIURIDICO DATI RELAZ CONTRAENTE */
export const UPDATE_EMAIL_BENEF_VITA_GIURIDICO          = 'UPDATE_EMAIL_BENEF_VITA_GIURIDICO';
export const UPDATE_RELAZ_CONTR_BENEF_VITA_GIURIDICO    = 'UPDATE_RELAZ_CONTR_BENEF_VITA_GIURIDICO';
export const UPDATE_ESCLUS_COMUNIC_BENEF_VITA_GIURIDICO = 'UPDATE_ESCLUS_COMUNIC_BENEF_VITA_GIURIDICO';
export const UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_GIURIDICO = 'UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_GIURIDICO';

/* BENEFICIARIO VITA GIURIDICO COPIA DA FIGURA */
export const UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_GIURIDICO   = 'UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_GIURIDICO';
export const UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_GIURIDICO = 'UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_GIURIDICO';

/* DATI SOCIETARI BENEFICIARIO VITA GIURIDICO */
export const ISCRIZIONE_REGISTRO_BENEF_VITA_GIURIDICO = 'ISCRIZIONE_REGISTRO_BENEF_VITA_GIURIDICO';
export const NUMERO_REGISTRO_BENEF_VITA_GIURIDICO     = 'NUMERO_REGISTRO_BENEF_VITA_GIURIDICO';

/* DATI MANDATO FIDUCIARIO BENEFICIARIO VITA GIURIDICO */
export const UPDATE_INTESTATARIO_MANDATO_BENEF_VITA_GIURIDICO = 'UPDATE_INTESTATARIO_MANDATO_BENEF_VITA_GIURIDICO';
export const UPDATE_MANDATO_BENEF_VITA_GIURIDICO              = 'UPDATE_MANDATO_BENEF_VITA_GIURIDICO';
/* BENEFICIARIO VITA GENERICO */
export const SALVA_BENEF_VITA_GENERICO    = 'SALVA_BENEF_VITA_GENERICO';
export const SALVA_BENEF_VITA_GENERICO_SUCCESS    = 'SALVA_BENEF_VITA_GENERICO_SUCCESS';
export const UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GENERICO = 'UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GENERICO';
export const UPDATE_BENEFICIARIO_VITA_GENERICO = 'UPDATE_BENEFICIARIO_VITA_GENERICO';
//export const AGGIUNGI_BENEF_VITA_GENERICO = 'AGGIUNGI_BENEF_VITA_GENERICO';
export const CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GENERICO = 'CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GENERICO';
export const NUOVO_BENEFICIARIO_VITA_GENERICO = 'NUOVO_BENEFICIARIO_VITA_GENERICO';

/* BENEFICIARIO VITA GENERICO DATI GENERALI */
export const UPDATE_PERCENTUALE_BENEF_VITA_GENERICO = 'UPDATE_PERCENTUALE_BENEF_VITA_GENERICO';
export const UPDATE_BENEF_VITA_GENERICO             = 'UPDATE_BENEF_VITA_GENERICO';
export const UPDATE_ESTESO_BENEF_VITA_GENERICO      = 'UPDATE_ESTESO_BENEF_VITA_GENERICO';

//TABELLA DI RIEPILOGO TYPES
export const CARICA_BENEF_VITA = 'CARICA_BENEF_VITA';
export const DELETE_BENEF_VITA_FISICO = 'DELETE_BENEF_VITA_FISICO';
export const DELETE_BENEF_VITA_GIURIDICO = 'DELETE_BENEF_VITA_GIURIDICO';
export const DELETE_BENEF_VITA_GENERICO = 'DELETE_BENEF_VITA_GENERICO';
export const UPDATE_TAB_SELEZIONATO_BENEF_VITA = 'UPDATE_TAB_SELEZIONATO_BENEF_VITA';

export const UPDATE_BENEF_VITA_PERCENTUALE_TOTALE = 'UPDATE_BENEF_VITA_PERCENTUALE_TOTALE';


/* ATTIVITA ECONOMICA GIURIDICO VITA */
export const UPDATE_ATT_PREVAL_SVOLTA_VITA_GIUR                       = 'UPDATE_ATT_PREVAL_SVOLTA_VITA_GIUR';
export const UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT_VITA_GIURIDICO        = 'UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT_VITA_GIURIDICO';
export const UPDATE_STAT_ATT_PREV_SVOLTA_VITA_GIURIDICO               = 'UPDATE_STAT_ATT_PREV_SVOLTA_VITA_GIURIDICO';
export const UPDATE_PROVINCIA_ATT_PREV_SVOLTA_VITA_GIURIDICO          = 'UPDATE_PROVINCIA_ATT_PREV_SVOLTA_VITA_GIURIDICO'; 
export const UPDATE_COMUNE_ATT_PREV_SVOLTA_VITA_GIURIDICO             = 'UPDATE_COMUNE_ATT_PREV_SVOLTA_VITA_GIURIDICO';
export const UPDATE_TIPO_SOCIETA_VITA_GIURIDICO                       = 'UPDATE_TIPO_SOCIETA_VITA_GIURIDICO';
export const UPDATE_COD_SAE_VITA_GIURIDICO                            = 'UPDATE_COD_SAE_VITA_GIURIDICO';
export const UPDATE_COD_ATECO_VITA_GIURIDICO                          = 'UPDATE_COD_ATECO_VITA_GIURIDICO';
export const UPDATE_SOCIETA_AZ_FIDUCIARIO_BENEF_VITA_GIURIDICO        = 'UPDATE_SOCIETA_AZ_FIDUCIARIO_BENEF_VITA_GIURIDICO';

/* TITOLARE EFFETTIVO BENEFICIARIO VITA */
export const SALVA_TITOLARE_EFF_BENEF_VITA                                          = 'SALVA_TITOLARE_EFF_BENEF_VITA';
export const SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS                                  = 'SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS';
export const UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA                       = 'UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA';
export const CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA                              = 'CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA';
export const NUOVO_TITOLARE_EFF_BENEF_VITA                                          = 'NUOVO_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_TITOLARE_EFF_BENEF_VITA                                         = 'UPDATE_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA                             = 'UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA                    = 'UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA                           = 'UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA                              = 'UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA                      = 'UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA                           = 'UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA                       = 'UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA                          = 'UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA                   = 'UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA                             = 'UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA';
export const CARICA_TITOLARE_EFF_BENEF_VITA					                        = 'CARICA_TITOLARE_EFF_BENEF_VITA';
export const DELETE_TITOLARE_EFF_BENEF_VITA			                                = 'DELETE_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA                         = 'UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA                           = 'UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA                              = 'UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA                            = 'UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA                  = 'UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA                                 = 'UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA                           = 'UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA                           = 'UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA';
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA             = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                         = 'UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA                     = 'UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA                        = 'UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                      = 'UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                     = 'UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA                           = 'UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                 = 'UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA                          = 'UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA    = 'UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA           = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA';
export const UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA                      = 'UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA                         = 'UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA                       = 'UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA                      = 'UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA                  = 'UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA                            = 'UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA                                  = 'UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA                                = 'UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA';
export const UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA                              = 'UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA';
export const UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA                                 = 'UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA                                 = 'UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA                                 = 'UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA';
export const UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA                                = 'UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA                            = 'UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA                               = 'UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_TITOLARE_EFF_QUALIFICA_VITA                                     = 'UPDATE_TITOLARE_EFF_QUALIFICA_VITA';
export const UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA                                = 'UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA';
export const UPDATE_ALTRO_QUALIFICA_VITA                                            = 'UPDATE_ALTRO_QUALIFICA_VITA';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA                      = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA';
export const UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA                              = 'UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA';
export const UPDATE_CARICA_PEP_TITOLARE_EFF_VITA                                    = 'UPDATE_CARICA_PEP_TITOLARE_EFF_VITA';
export const UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA                            = 'UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA';
export const UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA                        = 'UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA';
export const UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA                           = 'UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA';

/*  DATI FISCALI TITOLARE EFF BENEF VITA  */
export const UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA                            = 'UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_VITA                   = 'UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA           = 'UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA             = 'UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA           = 'UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA             = 'UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_VITA      = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_VITA';


export const AGGIUNGI_TITOLARE_BENEF_VITA_GIURIDICO                             = 'AGGIUNGI_TITOLARE_BENEF_VITA_GIURIDICO';
export const UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA                   = 'UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA';


export const UPDATE_CITTADINANZA_1_TITOLARE_EFF_VITA    		                = 'UPDATE_CITTADINANZA_1_TITOLARE_EFF_VITA';
export const UPDATE_CITTADINANZA_2_TITOLARE_EFF_VITA	                        = 'UPDATE_CITTADINANZA_2_TITOLARE_EFF_VITA';

export const UPDATE_NUM_TELEFONO_TITOLARE_EFF_VITA      = 'UPDATE_NUM_TELEFONO_TITOLARE_EFF_VITA';