import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
//RT import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import rootReducer from './session/Reducer';
import logger from 'redux-logger';

//RT export const history = createHistory();
export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
//RT const middleware = [thunk, routerMiddleware(history)];
const middleware = [thunk, routerMiddleware(history), logger];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
                connectRouter(history)(rootReducer),
                initialState,
                composedEnhancers
              );

export default store;
/*
 export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
); */
