import * as actionType from '../actionType/datiModPagAssegno';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiModPagAssegnoInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';
import { CODICE_ALTRA_RELAZIONE } from '../../../../../utility/jsConstants';

const areaDatiModPagAssegnoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiModPagAssegnoReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaModPagAssegno(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
  	  
    case actionType.UPDATE_NUMERO_ASSEGNO_1:
      const caratteristicheContrattoNumeroAssegno1 = {...state}.caratteristicheContratto;
      const datiModPagAssegnoNumeroAssegno1 = {...caratteristicheContrattoNumeroAssegno1}.datiModPagAssegno;
      datiModPagAssegnoNumeroAssegno1.numeroAssegno1 = action.unDato;
      caratteristicheContrattoNumeroAssegno1.datiModPagAssegno = {...datiModPagAssegnoNumeroAssegno1};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoNumeroAssegno1,
      };   
    /*case actionType.UPDATE_NUMERO_ASSEGNO_2:
        const caratteristicheContrattoNumeroAssegno2 = {...state}.caratteristicheContratto;
        const datiModPagAssegnoNumeroAssegno2 = {...caratteristicheContrattoNumeroAssegno2}.datiModPagAssegno;
        datiModPagAssegnoNumeroAssegno2.numeroAssegno2 = action.unDato;
        caratteristicheContrattoNumeroAssegno2.datiModPagAssegno = {...datiModPagAssegnoNumeroAssegno2};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoNumeroAssegno2,
        }; */
    case actionType.UPDATE_BANCA_1:
        const caratteristicheContrattoBanca1 = {...state}.caratteristicheContratto;
        const datiModPagAssegnoBanca1 = {...caratteristicheContrattoBanca1}.datiModPagAssegno;
        datiModPagAssegnoBanca1.banca1 = unValore;
        datiModPagAssegnoBanca1.descBanca1 =  unaLabel;
        //datiModPagAssegnoBanca1.agenzia1 = '';
        //datiModPagAssegnoBanca1.descAgenzia1 = '';
        caratteristicheContrattoBanca1.datiModPagAssegno = {...datiModPagAssegnoBanca1};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoBanca1,
        };  
    /*case actionType.UPDATE_BANCA_2:
        const caratteristicheContrattoBanca2 = {...state}.caratteristicheContratto;
        const datiModPagAssegnoBanca2 = {...caratteristicheContrattoBanca2}.datiModPagAssegno;
        datiModPagAssegnoBanca2.banca2 = unValore;
        datiModPagAssegnoBanca2.descBanca2 = unaLabel;
        datiModPagAssegnoBanca2.agenzia2 = '';
        datiModPagAssegnoBanca2.descAgenzia2 = '';
        caratteristicheContrattoBanca2.datiModPagAssegno = {...datiModPagAssegnoBanca2};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoBanca2,
        };*/ 
    case actionType.UPDATE_AGENZIA_1:
        const caratteristicheContrattoAgenzia1 = {...state}.caratteristicheContratto;
        const datiModPagAssegnoAgenzia1 = {...caratteristicheContrattoAgenzia1}.datiModPagAssegno;
        //datiModPagAssegnoAgenzia1.agenzia1 = action.unDato.value;
        //datiModPagAssegnoAgenzia1.descAgenzia1 =  action.unDato.label;
        datiModPagAssegnoAgenzia1.descAgenzia1 = action.unDato;
        caratteristicheContrattoAgenzia1.datiModPagAssegno = {...datiModPagAssegnoAgenzia1};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoAgenzia1,
        };  
    /*case actionType.UPDATE_AGENZIA_2:
        const caratteristicheContrattoAgenzia2 = {...state}.caratteristicheContratto;
        const datiModPagAssegnoAgenzia2 = {...caratteristicheContrattoAgenzia2}.datiModPagAssegno;
        datiModPagAssegnoAgenzia2.agenzia2 = action.unDato.value;
        datiModPagAssegnoAgenzia2.descAgenzia2 =  action.unDato.label;
        caratteristicheContrattoAgenzia2.datiModPagAssegno = {...datiModPagAssegnoAgenzia2};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoAgenzia2,
        };*/  
    case actionType.UPDATE_COINTESTATO:
        const caratteristicheContrattoCointestato = {...state}.caratteristicheContratto;
        const datiModPagAssegnoCointestato = {...caratteristicheContrattoCointestato}.datiModPagAssegno;
        datiModPagAssegnoCointestato.contoCointestato = action.unDato;
        if(datiModPagAssegnoCointestato.contoCointestato === false) {
          datiModPagAssegnoCointestato.nomeCognomeCointestatario = '';
          datiModPagAssegnoCointestato.relazContrCoint = '';
          datiModPagAssegnoCointestato.descrRelazContrCoint = '';
          datiModPagAssegnoCointestato.altraRelazione = '';
        }
        caratteristicheContrattoCointestato.datiModPagAssegno = {...datiModPagAssegnoCointestato};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoCointestato
        }    
    case actionType.UPDATE_NOME_COGNOME_COINTESTATARIO:
        const caratteristicheContrattoDatiCoint = {...state}.caratteristicheContratto;
        const datiModPagAssegnoDatiCoint = {...caratteristicheContrattoDatiCoint}.datiModPagAssegno;
        datiModPagAssegnoDatiCoint.nomeCognomeCointestatario = action.unDato;
        caratteristicheContrattoDatiCoint.datiModPagAssegno = {...datiModPagAssegnoDatiCoint};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoDatiCoint
        }
    case actionType.UPDATE_RELAZ_PAGAT_CONTR:
        const caratteristicheContrattoRelaz = {...state}.caratteristicheContratto;
        const datiModPagAssegnoRelaz = {...caratteristicheContrattoRelaz}.datiModPagAssegno;
        datiModPagAssegnoRelaz.relazContrCoint = unValore;
        datiModPagAssegnoRelaz.descrRelazContrCoint = unaLabel;
        if(datiModPagAssegnoRelaz.altraRelazione !== CODICE_ALTRA_RELAZIONE) {
          datiModPagAssegnoRelaz.altraRelazione = '';
        }
        caratteristicheContrattoRelaz.datiModPagAssegno = {...datiModPagAssegnoRelaz};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoRelaz
        }
    case actionType.UPDATE_ALTRA_RELAZ:
        const caratteristicheContrattoAltraRelaz = {...state}.caratteristicheContratto;
        const datiModPagAssegnoAltraRelaz = {...caratteristicheContrattoAltraRelaz}.datiModPagAssegno;
        datiModPagAssegnoAltraRelaz.altraRelazione = action.unDato;
        caratteristicheContrattoAltraRelaz.datiModPagAssegno = {...datiModPagAssegnoAltraRelaz};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoAltraRelaz
        }
    default:
      return state;
  }
}

export const inizializzaModPagAssegno = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiModPagAssegno = jsonCopy(datiModPagAssegnoInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiModPagAssegnoReducer;