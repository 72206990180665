import * as actionType from '../actionType/referenteTerzo';
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import initialState from '../../../../session/InitialState';

import { jsonCopy, dataFormat } from '../../../../utility/genericUtility';
import { PROVINCIA_ESTERA } from '../../../../utility/jsConstants';
import { datiReferenteTerzoInizializzato } from '../../../../session/info/componentiBase/sezioniAnagrafica';
	
const areaReferenteTerzoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
  
  const referenteTerzoFisico = {...state}.referenteTerzoFisico;
  const datiReferenteTerzo = {...referenteTerzoFisico}.datiReferenteTerzo;
  const datiGeneraliReferenteTerzo = {...datiReferenteTerzo}.datiGenerali;
  const datiResidenzaReferenteTerzo = {...datiReferenteTerzo}.datiResidenza;
  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
    	const referenteTerzoFisicoInizializza = inizializzaReferenteTerzo(state);
    	return {
          ...state,
          referenteTerzoFisico: referenteTerzoFisicoInizializza,
        };  
    case actionType.SALVA_REFERENTE_TERZO_FISICO:
    	referenteTerzoFisico.controlloDati = action.unDato;
      return {
        ...state,
        referenteTerzoFisico: referenteTerzoFisico,
      };  
    case actionType.UPDATE_ANAGRAFICA_VALIDA_REFERENTE_TERZO_FISICO:
    	referenteTerzoFisico.anagraficaValida = action.unDato;
        return {
          ...state,
          referenteTerzoFisico: referenteTerzoFisico,
        };
/* REFERENTE TERZO DATI GENERALI */
    case actionType.UPDATE_FL_REFERENTE_TERZO_FISICO:
    	datiGeneraliReferenteTerzo.flReferenteTerzo = action.unDato;
    	datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
    	referenteTerzoFisico.controlloDati = '';
    	referenteTerzoFisico.anagraficaValida = null;    	
    	referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
        return {
          ...state,
          referenteTerzoFisico: referenteTerzoFisico,
        };
    case actionType.UPDATE_CODICE_FISCALE_REFERENTE_TERZO_FISICO:
      datiGeneraliReferenteTerzo.codiceFiscale = action.unDato;
      datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
      return {
        ...state,
        referenteTerzoFisico: referenteTerzoFisico,
      };
    case actionType.UPDATE_CODICE_FISCALE_VALIDO_REFERENTE_TERZO_FISICO:
        datiGeneraliReferenteTerzo.codiceFiscaleValido = action.unDato;
        datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
        referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
        return {
          ...state,
          referenteTerzoFisico: referenteTerzoFisico,
        };
    case actionType.UPDATE_NOME_REFERENTE_TERZO_FISICO:        
    	datiGeneraliReferenteTerzo.nome = action.unDato;
    	datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
        referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
        return {
          ...state,
          referenteTerzoFisico: referenteTerzoFisico,
        };
      case actionType.UPDATE_COGNOME_REFERENTE_TERZO_FISICO:
    	datiGeneraliReferenteTerzo.cognome = action.unDato;
    	datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
        referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
        return {
          ...state,
          referenteTerzoFisico: referenteTerzoFisico,
        }; 
      case actionType.UPDATE_SESSO_REFERENTE_TERZO_FISICO:
		  datiGeneraliReferenteTerzo.sesso = action.unDato;
		  datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
	      return {
	        ...state,
	        referenteTerzoFisico: referenteTerzoFisico,
	      };
      case actionType.UPDATE_DATA_NASCITA_REFERENTE_TERZO_FISICO:
          const unaDataNascita = action.unDato; 
          const nuovaDataNascitaFormattata = dataFormat(unaDataNascita, 'DD/MM/YYYY');    	  
    	  datiGeneraliReferenteTerzo.dataNascita = unaDataNascita;
    	  datiGeneraliReferenteTerzo.dataNascitaStampa = nuovaDataNascitaFormattata;
    	  datiGeneraliReferenteTerzo.statoNascita = '';
    	  datiGeneraliReferenteTerzo.descrStatoNascita = '';
    	  datiGeneraliReferenteTerzo.provinciaNascita = '';
    	  datiGeneraliReferenteTerzo.descrProvinciaNascita = '';
    	  datiGeneraliReferenteTerzo.comuneNascita = '';
    	  datiGeneraliReferenteTerzo.descrComuneNascita = '';
    	  datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
	      return {
	        ...state,
	        referenteTerzoFisico: referenteTerzoFisico,
	      };      
        case actionType.UPDATE_STATO_NASCITA_REFERENTE_TERZO_FISICO:
          datiGeneraliReferenteTerzo.statoNascita = unValore;
          datiGeneraliReferenteTerzo.descrStatoNascita = unaLabel;
          datiGeneraliReferenteTerzo.provinciaNascita = '';
          datiGeneraliReferenteTerzo.descrProvinciaNascita = '';
          datiGeneraliReferenteTerzo.comuneNascita = '';
          datiGeneraliReferenteTerzo.descrComuneNascita = '';
          datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
	      return {
	        ...state,
	        referenteTerzoFisico: referenteTerzoFisico,
	      }; 
        case actionType.UPDATE_PROVINCIA_NASCITA_REFERENTE_TERZO_FISICO:
          datiGeneraliReferenteTerzo.provinciaNascita = unValore;
          datiGeneraliReferenteTerzo.descrProvinciaNascita = unaLabel;
          datiGeneraliReferenteTerzo.comuneNascita = '';
          datiGeneraliReferenteTerzo.descrComuneNascita = '';
          datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
	      return {
	        ...state,
	        referenteTerzoFisico: referenteTerzoFisico,
	      };      
        case actionType.UPDATE_COMUNE_NASCITA_REFERENTE_TERZO_FISICO:
            if (datiGeneraliReferenteTerzo.provinciaNascita!==PROVINCIA_ESTERA) {
            	 datiGeneraliReferenteTerzo.comuneNascita = unValore;
                 datiGeneraliReferenteTerzo.descrComuneNascita = unaLabel; // campo select
            } else {
            	datiGeneraliReferenteTerzo.comuneNascita = '';
            	datiGeneraliReferenteTerzo.descrComuneNascita = action.unDato;  // campo testo
            }         
          datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
	      return {
	        ...state,
	        referenteTerzoFisico: referenteTerzoFisico,
	      };
/* REFERENTE TERZO DATI RESIDENZA */
        case actionType.UPDATE_STATO_RESIDENZA_REFERENTE_TERZO_FISICO:
        	datiResidenzaReferenteTerzo.statoResidenza = unValore;
        	datiResidenzaReferenteTerzo.descrStatoResidenza = unaLabel;
        	datiResidenzaReferenteTerzo.provinciaResidenza = '';
          datiResidenzaReferenteTerzo.descrProvinciaResidenza = '';
          datiResidenzaReferenteTerzo.comuneResidenza = '';
          datiResidenzaReferenteTerzo.descrComuneResidenza = '';
          datiResidenzaReferenteTerzo.toponimoResidenza = '';
          datiResidenzaReferenteTerzo.descrToponimoResidenza = '';
          //datiResidenzaReferenteTerzo.indirizzoResidenza = '';
          //datiResidenzaReferenteTerzo.descrIndirizzoResidenza = '';
          datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
  	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
  	      return {
  	        ...state,
  	        referenteTerzoFisico: referenteTerzoFisico,
  	      };
        case actionType.UPDATE_PROVINCIA_RESIDENZA_REFERENTE_TERZO_FISICO:
        	datiResidenzaReferenteTerzo.provinciaResidenza = unValore;
        	datiResidenzaReferenteTerzo.descrProvinciaResidenza = unaLabel;
        	datiResidenzaReferenteTerzo.comuneResidenza = '';
        	datiResidenzaReferenteTerzo.descrComuneResidenza = '';
        	datiResidenzaReferenteTerzo.toponimoResidenza = '';
        	datiResidenzaReferenteTerzo.descrToponimoResidenza = '';
          //datiResidenzaReferenteTerzo.indirizzoResidenza = '';
          //datiResidenzaReferenteTerzo.descrIndirizzoResidenza = '';            
            datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
    	    referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
    	    return {
    	        ...state,
    	        referenteTerzoFisico: referenteTerzoFisico,
    	    };    
        case actionType.UPDATE_COMUNE_RESIDENZA_REFERENTE_TERZO_FISICO:
        	if (datiResidenzaReferenteTerzo.provinciaResidenza !== PROVINCIA_ESTERA){
	        	datiResidenzaReferenteTerzo.comuneResidenza = unValore;
	        	datiResidenzaReferenteTerzo.descrComuneResidenza = unaLabel; // campo select
	        	datiResidenzaReferenteTerzo.toponimoResidenza = '';
	        	datiResidenzaReferenteTerzo.descrToponimoResidenza = '';        	
	          //datiResidenzaReferenteTerzo.indirizzoResidenza = '';
	          //datiResidenzaReferenteTerzo.descrIndirizzoResidenza = '';
        	} else {
        		datiResidenzaReferenteTerzo.comuneResidenza = '';
	        	datiResidenzaReferenteTerzo.descrComuneResidenza = action.unDato; // campo testo
        	}
        	datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
     	    referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
     	    return {
     	        ...state,
     	        referenteTerzoFisico: referenteTerzoFisico,
     	    };            
        case actionType.UPDATE_TOPONIMO_RESIDENZA_REFERENTE_TERZO_FISICO:
        	if (datiResidenzaReferenteTerzo.provinciaResidenza !== PROVINCIA_ESTERA){
	        	datiResidenzaReferenteTerzo.toponimoResidenza = unValore;
	        	datiResidenzaReferenteTerzo.descrToponimoResidenza = unaLabel; // campo select
	          //datiResidenzaReferenteTerzo.indirizzoResidenza = '';
	          //datiResidenzaReferenteTerzo.descrIndirizzoResidenza = '';
        	}else {
        		datiResidenzaReferenteTerzo.toponimoResidenza = '';
	        	datiResidenzaReferenteTerzo.descrToponimoResidenza = action.unDato; // campo testo
        	}
        	datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
     	    referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
     	    return {
     	        ...state,
     	        referenteTerzoFisico: referenteTerzoFisico,
     	    };   
        case actionType.UPDATE_INDIRIZZO_RESIDENZA_REFERENTE_TERZO_FISICO:
          //datiResidenzaReferenteTerzo.indirizzoResidenza = unValore;
          //datiResidenzaReferenteTerzo.descrIndirizzoResidenza = unaLabel;
        	datiResidenzaReferenteTerzo.descrIndirizzoResidenza = action.unDato;
        	datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
     	    referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
     	    return {
     	        ...state,
     	        referenteTerzoFisico: referenteTerzoFisico,
     	    };
       case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_REFERENTE_TERZO_FISICO:
          datiResidenzaReferenteTerzo.numCivicoResidenza = action.unDato;
          datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
   	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
   	      return {
   	        ...state,
   	        referenteTerzoFisico: referenteTerzoFisico,
   	      };     
        case actionType.UPDATE_CAP_RESIDENZA_REFERENTE_TERZO_FISICO:
          datiResidenzaReferenteTerzo.capResidenza = action.unDato;
          datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
   	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
   	      return {
   	        ...state,
   	        referenteTerzoFisico: referenteTerzoFisico,
   	      };
/* RESET DATI REFERENTE TERZO */
   	   /* BENEFICIARIO MORTE FISICO - AGGIORNAMENTO DATI REFERENTE TERZO*/
        case actionType.RESET_DATI_REFERENTE_TERZO:
        	datiGeneraliReferenteTerzo.sesso = '';
        	datiGeneraliReferenteTerzo.codiceFiscale = '';
        	datiGeneraliReferenteTerzo.cognome = '';
        	datiGeneraliReferenteTerzo.nome = '';
        	datiGeneraliReferenteTerzo.dataNascita = null;
        	datiGeneraliReferenteTerzo.statoNascita = '';
        	datiGeneraliReferenteTerzo.descrStatoNascita = '';
        	datiGeneraliReferenteTerzo.provinciaNascita = '';
            datiGeneraliReferenteTerzo.descrProvinciaNascita = '';
            datiGeneraliReferenteTerzo.comuneNascita = '';
            datiGeneraliReferenteTerzo.descrComuneNascita = '';
            datiReferenteTerzo.datiGenerali = datiGeneraliReferenteTerzo;
                       
            // dati residenza
            datiResidenzaReferenteTerzo.statoResidenza='';
            datiResidenzaReferenteTerzo.descrStatoResidenza= '';
            datiResidenzaReferenteTerzo.provinciaResidenza= '';
            datiResidenzaReferenteTerzo.descrProvinciaResidenza= '';
            datiResidenzaReferenteTerzo.comuneResidenza= '';
            datiResidenzaReferenteTerzo.descrComuneResidenza= '';
            datiResidenzaReferenteTerzo.toponimoResidenza= '';
            datiResidenzaReferenteTerzo.descrToponimoResidenza= '';
            datiResidenzaReferenteTerzo.indirizzoResidenza= '';
            datiResidenzaReferenteTerzo.descrIndirizzoResidenza= '';
            datiResidenzaReferenteTerzo.numCivicoResidenza= '';
            datiResidenzaReferenteTerzo.capResidenza= '';
            datiReferenteTerzo.datiResidenza = datiResidenzaReferenteTerzo;
           
            datiReferenteTerzo.email = '';
            referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
            return {
            	...state,
       	        referenteTerzoFisico: referenteTerzoFisico,
            };               	      
/* REFERENTE TERZO EMAIL */
        case actionType.UPDATE_EMAIL_REFERENTE_TERZO_FISICO:
          datiReferenteTerzo.email = action.unDato;
   	      referenteTerzoFisico.datiReferenteTerzo = datiReferenteTerzo;
   	      return {
   	        ...state,
   	        referenteTerzoFisico: referenteTerzoFisico,
   	      };

    default:
      return state;
  }
}

export const inizializzaReferenteTerzo = (state) => {
  const referenteTerzoFisico_INI = {...state}.referenteTerzoFisico;
  referenteTerzoFisico_INI.anagraficaValida= null;
  referenteTerzoFisico_INI.controlloDati= '';
  referenteTerzoFisico_INI.datiReferenteTerzo = jsonCopy(datiReferenteTerzoInizializzato);
  return referenteTerzoFisico_INI;
}

export default areaReferenteTerzoReducer;