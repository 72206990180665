import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';
import DatiGeneraliMinimi from './DatiGeneraliMinimi';

import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';
import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';

import { BENEF_VITA_FISICO, BENEF_MORTE_FISICO } from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';
import TextFieldComp from '../../common/TextFieldComp';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
//  console.log('#RTCFA DatiGeneraliBenefFisico.mapStateToProps.state', state);
  return {
    contraenteFisicoValida: state.areaContraenteReducer.contraenteFisico.anagraficaValida,
    datiGeneraliBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.datiGeneraliBenefVita,
    controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,
    datiGeneraliBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiGeneraliBenefMorte, 
    controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
//  console.log('#RTCFA DatiGeneraliBenefFisico.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiGeneraliBenefVitaFisico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiGeneraliBenefVitaFisico(unDato, unActionType)),
    updateDatiGeneraliBenefMorteFisico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiGeneraliBenefMorteFisico(unDato, unActionType)),
  };
};
	
class DatiGeneraliBenefFisico extends React.Component {
  constructor(props) {
    super(props);
//    console.log('#RTCFA DatiGeneraliBenefFisico props', props);	 
    const datiGeneraliFisico = this.datiGeneraliFrom(this.props.figura);
    this.state = {
      percBeneficiario: datiGeneraliFisico.percBeneficiario,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
    };
  };
  
  datiGeneraliFrom = figura => {
    let datiGeneraliFisico = null;
    if (this.props.figura === BENEF_VITA_FISICO) {
      datiGeneraliFisico = this.props.datiGeneraliBenefVitaFisico;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      datiGeneraliFisico = this.props.datiGeneraliBenefMorteFisico;
    } 
    return datiGeneraliFisico;
  }
  
  controlloDatiFrom = figura => {
    let controlloDati = false;
    if (this.props.figura === BENEF_VITA_FISICO) {
      controlloDati = this.props.controlloDatiBenefVitaFisico;
    } else if (this.props.figura === BENEF_MORTE_FISICO ) {
      controlloDati = this.props.controlloDatiBenefMorteFisico;
    }
    return controlloDati;
  }
  
  handlePercBeneficiario = event => {
//    console.log('#RTCFA DatiGeneraliBenefFisico percBeneficiario new value:' + event.target.value);	
    const nuovaPercentuale = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchiaPercentuale = textTrim(datiGenerali.percBeneficiario, true);     
    if (nuovaPercentuale !== vecchiaPercentuale) {	      
      let anActionType = null;
      if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_PERCENTUALE_BENEF_VITA_FISICO;
        this.props.updateDatiGeneraliBenefVitaFisico(nuovaPercentuale, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_PERCENTUALE_BENEF_MORTE_FISICO;
        this.props.updateDatiGeneraliBenefMorteFisico(nuovaPercentuale, anActionType); 	      
      }    	
      this.setState({
        percBeneficiario: nuovaPercentuale,
      });
    }  
  }

  handleChangeBenDiversoImpresaBenefFisico = (event) => {
    const nuovoBenDiversoImp = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioBenDiversoImp  = textTrim(datiGenerali.benDiversoImp, true);
    if(nuovoBenDiversoImp !== vecchioBenDiversoImp){
      let anActionType = null;
      if( this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_BENE_DIVERSO_IMP_BENEF_VITA_FISICO;
        this.props.updateDatiGeneraliBenefVitaFisico(nuovoBenDiversoImp, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_BENE_DIVERSO_IMP_BENEF_MORTE_FISICO;
        this.props.updateDatiGeneraliBenefMorteFisico(nuovoBenDiversoImp, anActionType); 	    
      }
      this.setState({
        benDiversoImp: nuovoBenDiversoImp,
      })
    }
  }


  isRequiredBenDiversoImp = () => {
    let required = false;
    const codFiscContrGiur = this.props.codFiscContrGiur;
    const dati = this.datiGeneraliFrom(this.props.figura);
    const currentCodFisc = dati.codiceFiscale;
    if (this.props.figura === BENEF_MORTE_FISICO && codFiscContrGiur === currentCodFisc) {
      required = true;
    }else if(this.props.figura === BENEF_VITA_FISICO && codFiscContrGiur === currentCodFisc){
      required = true;
    } 
    return required;
  }

  isDisabledBenDiversoImp = () => {
    let disabled = false;
    const codFiscContrGiur = this.props.codFiscContrGiur;
    const dati = this.datiGeneraliFrom(this.props.figura);
    const currentCodFisc = dati.codiceFiscale;
    if(codFiscContrGiur === currentCodFisc){
      disabled = true;
    }
    return disabled;
  }
  
  initStatoCampi = () => {
    return [ {id: 'percBeneficiario', isError: false},
             {id: 'datiGeneraliMinimi', isError: false},
           ];
  }
				  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  render() {
	console.log('#RTCFA DatiGeneraliBenefFisico.this.props', this.props);
    const { classes, figura, labelFieldSet } = this.props;
    const datiGeneraliFisico = this.datiGeneraliFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura);
    
    return (
      <Fragment>
      {/*
      <fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiGenerali')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
          <Grid item md={4} xs={12}>
            <NumberFieldComp onBlur={this.handlePercBeneficiario} aStyle={classes.textField} id='percBeneficiario' label='percBeneficiario' format='###,###' 
            	             value={datiGeneraliFisico.percBeneficiario} maxLength='7' required={true} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
        </Grid>
        {figura && (figura === BENEF_VITA_FISICO) &&
          <DatiGeneraliMinimi figura={figura} datiGeneraliBenefVitaFisico={datiGeneraliFisico.datiGeneraliMinimi} 
        	                  id='datiGeneraliMinimi' onError={this.handleError} /> 
        }
        {figura && (figura === BENEF_MORTE_FISICO) &&
          <DatiGeneraliMinimi figura={figura} datiGeneraliBenefMorteFisico={datiGeneraliFisico.datiGeneraliMinimi}
        	                  id='datiGeneraliMinimi' onError={this.handleError} />
        }
        {/* <Grid item xs={12}>
          <TextFieldComp onBlur={this.handleChangeBenDiversoImpresaBenefFisico} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
            id='benDiversoImp' label='benDiversoImp' value={datiGeneraliFisico.benDiversoImp} required={this.isRequiredBenDiversoImp()}
            controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledBenDiversoImp()} />
        </Grid> */}
      </Fragment>    
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiGeneraliBenefFisico componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//	    console.log('########DatiGeneraliBenefFisico componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	    console.log('########DatiGeneraliBenefFisico componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
		  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }  
}

DatiGeneraliBenefFisico.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiGeneraliBenefFisico));
