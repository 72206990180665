import { jsonCopy } from '../../../utility/genericUtility';

export const datiPremioInizializzato = {
  datiPremioValida: null,		
  controlloDati: '',
  premioUnico: '',
  classePremioPotenziale: '',
  // NUOVA UL
  appendiceCosti: '', 
  tariffa: '',
  descTariffa: '',
};

export const datiRipartizionePremioInizializzato = {
  datiRipartizionePremioValida: null,		
  controlloDati: '',
  esitoPercentualeAllocazione: '',
  gestioneSeparata: '',  
  lineaLibera: '',
  lineaLiberaCodice: '',
  lineaLiberaDescrizione: '',
  lineaGuidata: [],
  lineeGuidateTutte: [],
  ribilanciamento: '',
};

export const datiRipartizionePremioFondiInizializzato = {
  esitoPercentualeFondo: '',
  fondo: [],
  codiceIsin: '',
  nomeFondo: '',
};

export const datiPianoErogazioniPeriodicheInizializzato = {
  datiPianoErogazioniPeriodicheValida: null,			
  controlloDati: '',	
  freqPrestazione: '',
  descFreqPrestazione: '',
  durata: '',
  descDurata: '',
  percentuale: '',
  descPercentuale: '',
  iban: '',
  controlloIban: '',
  contoCointestato: '',
  nomeCognomeCointestatario: '',
  relazContrCoint: '',
  descrRelazContrCoint: '',
  altraRelazione: '',
};

export const datiTakeProfitInizializzato = {
  datiTakeProfitValida: null,		
  controlloDati: '',
  percentualeObiettivo: '',
  descPercentualeObiettivo: '',
};

export const datiLifeCycleInizializzato = {
  datiLifeCycleValida: null,
};

export const datiPianoSpostamentoAutomaticoInizializzato = {
  datiPianoSpostamentoAutomaticoValida: null,
  controlloDati: '',
  allocazioneTargetItems: null,  // valori del RadioButtonCom caricato dal servizio 
  allocazioneTarget: null,
  descAllocazioneTarget: null,
  
};

export const datiOpzioniContrattualiInizializzato = {
  opzioneContrattuale: '',
  datiPianoErogazioniPeriodiche: jsonCopy(datiPianoErogazioniPeriodicheInizializzato),
  datiTakeProfit: jsonCopy(datiTakeProfitInizializzato),
  datiLifeCycle: jsonCopy(datiLifeCycleInizializzato),
  datiPianoSpostamentoAutomatico: jsonCopy(datiPianoSpostamentoAutomaticoInizializzato),
};

export const datiModPagAssegnoInizializzato = {
  numeroAssegno1: '',
  numeroAssegno2: '',
  banca1: '',
  descBanca1: '',
  banca2: '',
  descBanca2: '',
  agenzia1: '',
  descAgenzia1: '',
  agenzia2: '',
  descAgenzia2: '',
  contoCointestato: '',
  nomeCognomeCointestatario: '',
  relazContrCoint: '',
  descrRelazContrCoint: ''
};

export const datiModPagBonificoInizializzato = {
  codiceIbanValido: null,
  controlloIban: '',
  iban: '',
  banca: '',
  descBanca: '',
  agenzia: '',
  descAgenzia: '',
  nSottorubrica: '',
  intestatario: '',
  contoCointestato: '',
  nomeCognomeCointestatario: '',
  relazContrCoint: '',
  descrRelazContrCoint: '',
  altraRelazione: '',
};

export const datiModPagBonificoEsteroInizializzato = {
  banca: '',
  descBanca: '',
  agenzia: '',
  descAgenzia: '',
  paese: '',
  descrPaese: '',
  filiale: '',
  nSottorubrica: '',
  intestatario: '',
  codiceSwift: '',
  motivoContoEstero: '',
  contoCointestato: '',
  nomeCognomeCointestatario: '',
  relazContrCoint: '',
  descrRelazContrCoint: '',
  altraRelazione: ''
}

export const datiModPagReimpiegoInizializzato = {
  polizza: '',
};

export const datiModPagAltroInizializzato = {
  altro: '',
}

export const datiModPagCartaInizializzato = {
  intestatario: '',
}

export const datiModalitaPagamentoInizializzato = {
  datiModalitaPagamentoValida: null,				
  controlloDati: '',
  pagatorePremio: '',
  modalitaPagamento: '',
  descModalitaPagamento: '',
  /* datiModPagBonifico: jsonCopy(datiModPagBonificoInizializzato),
  datiModPagBonificoEstero: jsonCopy(datiModPagBonificoEsteroInizializzato),
  datiModPagAssegno: jsonCopy(datiModPagAssegnoInizializzato),
  datiModPagReimpiego: jsonCopy(datiModPagReimpiegoInizializzato), */
};

export const datiPremioCapitaleAssicuratoInizializzato = {
  datiPremioCapitaleAssicuratoValida: null,		
  controlloDati: '',
  tariffa: '',
  descTariffa: '',
  edizioneModello: '',
  durata: '',
  durataPagamentoPremi: '',
  capitaleAssicurato: '',
  malattiaGrave: null,
  capitaleAssicuratoMalattiaGrave: '',
  descCapitaleAssicuratoMalattiaGrave: '',
  complementareInfortuni: null,
  frazionamento: '',
  descFrazionamento: '',
  premioRata: '',
  premioAnnuo: '',
  premioUnico: '',
};