import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/it';


import * as actions from '../common/action/dominio';
import { setGeneric, selectScopeAmleto} from '../generic/action/generic';
import * as actionTypeGeneric from '../generic/actionType/generic';

import { uris, fetchParam } from '../../uris';

import { PRODOTTO_TIPOLOGIA_MULTINVEST, PRODOTTO_TIPOLOGIA_UNIT_LINKED, 
	     PRODOTTO_ZURICH_TARGET_SOLUTION_045, PRODOTTO_TABOO_601 } from '../../utility/jsConstants';
import { textTrim } from '../../utility/genericUtility';



const moreStyles = theme => ({
  root: {
/*    display: 'flex', */
    marginTop: 100,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),    
  },
  card: {
	raised: true,
  },
  media: {
	height: 159,
    objectFit: 'inherit',
  },
});

const mapStateToProps = state => {
  return {
    prodotto: state.areaProdottoReducer.prodotto,
    loginUser: state.areaProdottoReducer.loginUser,
    dominio: state.areaDatiDominioReducer.dominio,
  };
};
	
const mapDispatchToProps = dispatch => {
  return {
    dominioNazioni: (nazioneItems) => dispatch(actions.dominioNazioni(nazioneItems)),
    dominioProvince: (provinciaItems) => dispatch(actions.dominioProvince(provinciaItems)),
    dominioTipoDocumento: (tipoDocumentoItems) => dispatch(actions.dominioTipoDocumento(tipoDocumentoItems)),    
    dominioEnteRilascio: (enteRilascioItems) => dispatch(actions.dominioEnteRilascio(enteRilascioItems)),    
    dominioScopoNaturaRapporto: (scopoNaturaRapportoItems) => dispatch(actions.dominioScopoNaturaRapporto(scopoNaturaRapportoItems)),  
    dominioTipoSocieta: (tipoSocietaItems) => dispatch(actions.dominioTipoSocieta(tipoSocietaItems)),
    dominioBeneficiarioGenericoVita: (beneficiarioGenericoVitaItems) => dispatch(actions.dominioBeneficiarioGenericoVita(beneficiarioGenericoVitaItems)),
    dominioBeneficiarioGenericoMorte: (beneficiarioGenericoMorteItems) => dispatch(actions.dominioBeneficiarioGenericoMorte(beneficiarioGenericoMorteItems)),
    dominioPrevalenteAttivitaSvolta: (prevalenteAttivitaSvoltaItems) => dispatch(actions.dominioPrevalenteAttivitaSvolta(prevalenteAttivitaSvoltaItems)),
    dominioPrevalenteAttivitaSvoltaGiuridico: (prevalenteAttivitaSvoltaGiuridicoItems) => dispatch(actions.dominioPrevalenteAttivitaSvoltaGiuridico(prevalenteAttivitaSvoltaGiuridicoItems)),
//    dominioOrigineFondi: (origineFondiItems) => dispatch(actions.dominioOrigineFondi(origineFondiItems)),
    dominioAllocazioni: (allocazioneItems) => dispatch(actions.dominioAllocazioni(allocazioneItems)),
    dominioFondi: (fondiItems) => dispatch(actions.dominioFondi(fondiItems)),
    dominioPercTakeProfit: (percTakeProfitItems) => dispatch(actions.dominioPercTakeProfit(percTakeProfitItems)),
    dominioFrequenzaErogazioniPeriodiche: (frequenzaErogazioniPeriodicheItems) => dispatch(actions.dominioFrequenzaErogazioniPeriodiche(frequenzaErogazioniPeriodicheItems)),
    dominioModalitaPagamento: (modalitaPagamentoItems) => dispatch(actions.dominioModalitaPagamento(modalitaPagamentoItems)),
    dominioBanca: (bancaItems) => dispatch(actions.dominioBanca(bancaItems)),
    dominioConsensi: (consensiItems) => dispatch(actions.dominioConsensi(consensiItems)),
//20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio. dominioDichiarazioniIntermediario: (dichiarazioniIntermediarioItems) => dispatch(actions.dominioDichiarazioniIntermediario(dichiarazioniIntermediarioItems)),
    dominioRegione: (regioneItems) => dispatch(actions.dominioRegione(regioneItems)),
    dominioDurataErogazioniPeriodiche: (durataItems) => dispatch(actions.dominioDurataErogazioniPeriodiche(durataItems)),
//20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio. dominioClassePremioPotenziale: (classePremioPotenzialeItems) => dispatch(actions.dominioClassePremioPotenziale(classePremioPotenzialeItems)),
    dominioTariffaPerProdotto: (tariffaItems) => dispatch(actions.dominioTariffaPerProdotto(tariffaItems)),
    dominioCapitaleAssicuratoPerProdotto: (capitaleAssicuratoMalattiaGraveItems) => dispatch(actions.dominioCapitaleAssicuratoPerProdotto(capitaleAssicuratoMalattiaGraveItems)),
    dominioFrequenzaCapitaleAssicurato: (frequenzaCapitaleAssicuatoItems) => dispatch(actions.dominioFrequenzaCapitaleAssicurato(frequenzaCapitaleAssicuatoItems)),
    dominioRuoloXProdotto: (ruoloXProdottoItems) => dispatch(actions.dominioRuoloXProdotto(ruoloXProdottoItems)),
    dominioNazioneItalia: (nazioneItaliaItems) => dispatch(actions.dominioNazioneItalia(nazioneItaliaItems)),
    dominioRelazione: (relazioneItems) => dispatch(actions.dominioRelazione(relazioneItems)),
    selectScopeAmleto: (unaData) => dispatch(selectScopeAmleto(unaData)),
    agenziaNonAdeguataOriginiFondi: (unDato, unaLabel, unActionType) => dispatch(setGeneric(unDato, unaLabel, unActionType)),
    scopeTcmEmissione: (unDato, unaLabel, unActionType) => dispatch(setGeneric(unDato, unaLabel, unActionType)),
    dominioPaesiBlackList: (paesiBlackListItems) => dispatch(actions.dominioPaesiBlackList(paesiBlackListItems)),
    dominioCittadinanzaPaesiBlackList: (cittadinanzaPaesiBlackListItems) => dispatch(actions.dominioCittadinanzaPaesiBlackList(cittadinanzaPaesiBlackListItems)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class InitProposal extends React.Component {
  constructor(props) {
    super(props);
    console.log('[ZSE] InitProposal.constructor props', props);
  };

  componentDidMount() {
	const codiceProdotto = this.props.prodotto.codice;
//	const dataOggiFormatta =  new Intl.DateTimeFormat('it-IT').format(new Date());
    const dataOggiFormatta = moment().format('DD/MM/YYYY');
    //domini dipendenti dal prodotto
    this.loadRuoloXProdotto(dataOggiFormatta, codiceProdotto);
    this.loadRelazioni(dataOggiFormatta)
    this.loadScopeAmleto(dataOggiFormatta, codiceProdotto);
    this.loadScopeTcmEmissione(dataOggiFormatta, codiceProdotto);
    this.loadAgenziaNonAdeguataOriginiFondi(dataOggiFormatta, this.props.loginUser.mandato);
    if(this.props.prodotto.tipo === PRODOTTO_TIPOLOGIA_MULTINVEST) {
      this.loadAllocazioni(dataOggiFormatta, codiceProdotto);
      this.loadPercTakeProfit(dataOggiFormatta, codiceProdotto);
      this.loadFrequenzaErogazioniPeriodiche(dataOggiFormatta, codiceProdotto);
      this.loadDurataErogazioniPeriodiche(dataOggiFormatta);
    //20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
     //this.loadClassePremioPotenziale(dataOggiFormatta);
    } else if (this.props.prodotto.tipo === PRODOTTO_TIPOLOGIA_UNIT_LINKED) {
      this.loadAllocazioni(dataOggiFormatta, codiceProdotto);
      this.loadTariffaPerProdotto(dataOggiFormatta, codiceProdotto);
      this.loadFrequenzaErogazioniPeriodiche(dataOggiFormatta, codiceProdotto);
      this.loadDurataErogazioniPeriodiche(dataOggiFormatta);
    } else {
      this.loadTariffaPerProdotto(dataOggiFormatta, codiceProdotto);
      this.loadCapitaleAssicuratoPerProdotto(dataOggiFormatta, codiceProdotto);
      this.loadFrequenzaCapitaleAssicurato(dataOggiFormatta);
    } 
    if(this.props.dominio.nazioneItems === null || this.props.dominio.nazioneItems === undefined) {
	  this.loadNazioni(dataOggiFormatta);
	  this.loadNazioneItalia(dataOggiFormatta);
      this.loadProvince(dataOggiFormatta);
      this.loadTipoDocumento(dataOggiFormatta);
      this.loadEnteRilascio(dataOggiFormatta);
      this.loadScopoNaturaRapporto(dataOggiFormatta);
      this.loadTipoSocieta(dataOggiFormatta);
      this.loadBeneficiarioGenericoVita(dataOggiFormatta);
      this.loadBeneficiarioGenericoMorte(dataOggiFormatta);
      this.loadPrevalenteAttivitaSvolta(dataOggiFormatta);
      this.loadPrevalenteAttivitaSvoltaGiuridico(dataOggiFormatta);
// 2020-12-01 PFD: SAV 'TO BE'      this.loadOrigineFondi(dataOggiFormatta);
      this.loadModalitaPagamento(dataOggiFormatta);
      this.loadBanca(dataOggiFormatta);
      this.loadConsensi(dataOggiFormatta);
      //20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
      //this.loadDichiarazioniIntermediario(dataOggiFormatta);
      this.loadRegione(dataOggiFormatta);
      //2021-07-01 PFD: TCM_INDIV Paesi Black List caricati solo per prodotto TABOO per la valutazione scope TCM_INDIVIDUALE
      if (PRODOTTO_TABOO_601 === codiceProdotto) {
    	  this.loadPaesiBlackList(dataOggiFormatta);
    	  this.loadCittadinanzaPaesiBlackList(dataOggiFormatta);
      }
    }
  }

  loadScopeAmleto = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      dataRichiesta: dataOggiFormatta,
      codiceProdotto: codiceProdotto
    }
    this.setState({
      loaded: false
    })
    fetch(uris.selectScopeAmleto, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      this.setState({
        loaded: true
      })
      console.log("SCOPE AMLETO FETCH, codiceProdotto",result, codiceProdotto);
      // WARNING: la regola sul DB è invertita!! 
      // risultano in scope tutti i prodotti per i quali NON esiste la regola 'AMLETO_X_PROD'
      if(result.value) {
        this.props.selectScopeAmleto(false);
      } else {
        this.props.selectScopeAmleto(true);
      }
    })
  }
  
  loadScopeTcmEmissione = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      dataRichiesta: dataOggiFormatta,
      codSocieta: '143',
      codiceProdotto: codiceProdotto,
    }
    this.setState({
      loaded: false
    })
    fetch(uris.selectScopeTcmEmissione, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      this.setState({
        loaded: true
      })
      console.log("loadScopeTcmEmissione FETCH, codiceProdotto", result, codiceProdotto);
      if(result!=null&&result.length>0) {
        this.props.scopeTcmEmissione(true, '', actionTypeGeneric.SCOPE_TCM_EMISSIONE);        
        this.props.setGeneric(true, '', actionTypeGeneric.TCM_INDIVIDUALE_VALIDA); // inInit--> true
      } else {
        this.props.scopeTcmEmissione(false, '', actionTypeGeneric.SCOPE_TCM_EMISSIONE);
      }      
    })
  }
  
  loadAgenziaNonAdeguataOriginiFondi = (dataOggiFormatta, codiceMandato) => {
    const opts = {
      dataRichiesta: dataOggiFormatta,
      siglaVersione: 'ZURI',
      codiceRegola: 'REFRESH',
      valore1: '143',
      valore3: codiceMandato,
    }
    this.setState({
      loaded: false
    })
    fetch(uris.agenziaNonAgeduataOriginiFondi, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      this.setState({
        loaded: true
      })
      console.log("loadAgenziaNonAdeguataOriginiFondi FETCH, codiceMandato", result, codiceMandato);
      let isAgenziaNonAdeguataOriginiFondi = null;
      if(result.length>0) {    	  
    	  isAgenziaNonAdeguataOriginiFondi = true;
      } else {
    	  isAgenziaNonAdeguataOriginiFondi = false;
      }
      
      this.props.agenziaNonAdeguataOriginiFondi(isAgenziaNonAdeguataOriginiFondi,'',actionTypeGeneric.AGENZIA_NON_ADEGUATA_ORIGINI_FONDI);
    })
  }
  
  loadNazioni = (dataOggiFormatta) => {
    const opts = {
      dataRiferimentoComune: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.nazioneItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//        nazioneItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioNazioni(result);
    });
  }
  
  loadProvince = (dataOggiFormatta) => {
    const opts = {
      dataRiferimentoProv: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.provinciaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	provinciaItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioProvince(result);
    });
  }
  
  loadTipoDocumento = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'A003',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.tipoDocumentoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//         tipoDocumentoItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioTipoDocumento(result);
    });
  }  
  
  loadEnteRilascio = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'A009',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.enteRilascioItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	 enteRilascioItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioEnteRilascio(result);
    });
  }  
  
  loadScopoNaturaRapporto = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'CLSP',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.scopoNaturaRapportoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	 scopoNaturaRapportoItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioScopoNaturaRapporto(result);
    });
  }  
  
  loadTipoSocieta = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'A020',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.tipoSocietaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	   tipoSocietaItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioTipoSocieta(result);
    });
  }  
  
  loadBeneficiarioGenericoVita = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'BENV',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.beneficiarioGenericoVitaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	   beneficiarioGenericoVitaItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioBeneficiarioGenericoVita(result);
    });
  }  
  
  loadBeneficiarioGenericoMorte = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'BENM',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.beneficiarioGenericoMorteItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	   beneficiarioGenericoMorteItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioBeneficiarioGenericoMorte(result);
    });
  }  
  loadPrevalenteAttivitaSvolta = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'ATPS',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.prevalenteAttivitaSvoltaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	   prevalenteAttivitaSvoltaItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioPrevalenteAttivitaSvolta(result);
    });
  }  
  loadPrevalenteAttivitaSvoltaGiuridico = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'A021',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.prevalenteAttivitaSvoltaGiuridicoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	   prevalenteAttivitaSvoltaGiuridicoItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioPrevalenteAttivitaSvoltaGiuridico(result);
    });
  }  
  
  /* 2020-12-01 PFD: SAV 'TO BE'
  loadOrigineFondi = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'OFON',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.origineFondiItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
     )
     .then((result) => {
       // If request is good update state with fetched data
       this.setState({
//    	   origineFondiItems: result,
         loaded: true
       });
       console.log('[ZSE] componentDidMount result ... ', result);
       this.props.dominioOrigineFondi(result);
    });
  }  
  */
  
  loadAllocazioni = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceProdotto: codiceProdotto,
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.allocazioneItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	allocazioneItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
      
	  this.props.dominioAllocazioni(result);
	  let lineaLibera = result.lineaLibera.codice;	
	  this.loadFondi(dataOggiFormatta, lineaLibera);
    });
  }
  
  loadFondi = (dataOggiFormatta, lineaLibera) => {
	if (textTrim(lineaLibera)!=='') {
	    const opts = {
	      siglaVersione: 'ZURI',
	      codiceProdottoInvestimento: lineaLibera,
	      dataRichiesta: dataOggiFormatta,
	    };
	    this.setState({
	      loaded: false
	    });
	    fetch(uris.fondiItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	    // If request is good update state with fetched data
	      this.setState({
	//    	  fondiItems: result,
	        loaded: true
	      });
	      console.log('[ZSE] componentDidMount result ... ', result);
		  this.props.dominioFondi(result);
	    });
	}
  }
  
  loadPercTakeProfit = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceProdotto: codiceProdotto,
      codiceOpzione: '2',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.percTakeProfitItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	percTakeProfitItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioPercTakeProfit(result);
    });
  }
  
  loadFrequenzaErogazioniPeriodiche = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceProdotto: codiceProdotto,
      codiceOpzione: '1',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.frequenzaErogazioniPeriodicheItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	  frequenzaErogazioniPeriodicheItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioFrequenzaErogazioniPeriodiche(result);
    });
  }
  
  loadModalitaPagamento = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'E035',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.modalitaPagamentoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	modalitaPagamentoItems: result,
        loaded: true
      });
      this.props.dominioModalitaPagamento(result);
    });
  }
  
  loadBanca = (dataOggiFormatta) => {
    const opts = {
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.bancaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	bancaItems: result,
        loaded: true
      });
      this.props.dominioBanca(result);
    });
  }
  
  loadConsensi = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'CONSENSI',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.consensiItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	consensiItems: result,
        loaded: true
      });
      this.props.dominioConsensi(result);
    });
  }
  
  /* //20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio
  loadDichiarazioniIntermediario = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'TCMP',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.dichiarazioniIntermediarioItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	dichiarazioniIntermediarioItems: result,
        loaded: true
      });
      this.props.dominioDichiarazioniIntermediario(result);
    });
  }
  */
  
  loadRegione = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'REGI',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.regioneItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	regioneItems: result,
        loaded: true
      });
      this.props.dominioRegione(result);
    });
  }
  
  loadDurataErogazioniPeriodiche = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'DRPP',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.durataItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	durataItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioDurataErogazioniPeriodiche(result);
    });
  }
  
  /*
  loadClassePremioPotenziale = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'CLPP',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.classePremioPotenzialeItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	classePremioPotenzialeItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioClassePremioPotenziale(result);
    });
  }
  */
  
  loadTariffaPerProdotto = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceRegola: 'PROD_X_TARIFFA',
      codiceProdotto: codiceProdotto,
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.tariffaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	tariffaItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
      // per Zurich Target Solution visualizzo nella descrizione anche il codice tariffa 
      // 2020-02-01 PFD: Mail Barbara Danova:  del 01/02/2021 12:05 per Target Solution eliminare il codice Tariffa dall descrizione 
      /*
      if (codiceProdotto===PRODOTTO_ZURICH_TARGET_SOLUTION_045) {
	      if (result!==null&&result!==undefined&&result.length>0) {
	    	  for(let i=0;i<result.length;i++) {
	    		  result[i].label = result[i].value + " " + result[i].label; 
	    	  }
	      }
      }
      */
	  this.props.dominioTariffaPerProdotto(result);
    });
  }
  
  loadCapitaleAssicuratoPerProdotto = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceRegola: 'CAPGAR_X_PROD',
      codiceProdotto: codiceProdotto,
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.capitaleAssicuratoMalattiaGraveItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//    	capitaleAssicuratoMalattiaGraveItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioCapitaleAssicuratoPerProdotto(result);
    });
  }
  
  loadFrequenzaCapitaleAssicurato = (dataOggiFormatta) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'FRPP',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.frequenzaCapitaleAssicuratoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	frequenzaCapitaleAssicuatoItems: result,
        loaded: true
      });
      this.props.dominioFrequenzaCapitaleAssicurato(result);
    });
  }
  
  loadRuoloXProdotto = (dataOggiFormatta, codiceProdotto) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceProdotto: codiceProdotto,
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.ruoloXProdottoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
//    	ruoloXProdottoItems: result,
        loaded: true
      });
      this.props.dominioRuoloXProdotto(result);
    });
  }
  /*
  loadNazioneItalia = (dataOggiFormatta) => {    
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'A018',
      dataRichiesta: dataOggiFormatta,
    };
    this.setState({
      loaded: false
    });
    fetch(uris.nazioneItaliaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//	        nazioneItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioNazioneItalia(result);
    });
  }
  */
  loadNazioneItalia = (dataOggiFormatta) => {
    const opts = {
      dataRiferimentoComune: dataOggiFormatta,
      flNazioneItalia: true,  // SOLO ITALIA
    };
    this.setState({
      loaded: false
    });
    fetch(uris.nazioneItaliaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
//	        nazioneItems: result,
        loaded: true
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioNazioneItalia(result);
    });
  }

  loadRelazioni = dataOggiFormatta => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceCampo: 'RELZ',
      dataRichiesta: dataOggiFormatta
    }
    this.setState({
      loaded: false
    })
    fetch(uris.relazioneItems, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      this.setState({
        loaded: true
      })
      console.log('[ZSE] loadRelazioni result: ', result)
      this.props.dominioRelazione(result)
    })
  }
  
  loadPaesiBlackList = dataOggiFormatta => {
	  const opts = {
      siglaVersione: 'ZURI',
      dataRichiesta: dataOggiFormatta,
    }
    fetch(uris.selectPaesiBlackList, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An error occured...", error)
    )
    .then((result) => {
	  this.setState({
        loaded: true
      });
//      result.sort((a,b) => {
//        if(a.label > b.label) {
//            return 1;
//        } else {
//            return -1;
//        }
//      });
	  result.forEach((item, index, array) => {
          item.value = parseInt(item.value).toString(); // elimino gli zeri iniziali perche' i codici nazioni sono anch'esse valori interi senza lo zero
        });
      
      console.log('[ZSE] loadPaesiBlackList result: ', result)
      this.props.dominioPaesiBlackList(result);
    })
  }
  
  loadCittadinanzaPaesiBlackList = dataOggiFormatta => {
	  const opts = {
      siglaVersione: 'ZURI',
      dataRichiesta: dataOggiFormatta,
    }
    fetch(uris.selectCittadinanzaPaesiBlackList, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An error occured...", error)
    )
    .then((result) => {
	  this.setState({
        loaded: true
      });
      result.sort((a,b) => {
        if(a.label > b.label) {
            return 1;
        } else {
            return -1;
        }
      });
      const filteredResult = result.filter((el) => {
          return textTrim(el.value, true)!=='';
        });
      console.log('[ZSE] loadCittadinanzaPaesiBlackList result: ', filteredResult);
      this.props.dominioCittadinanzaPaesiBlackList(result);
    })
  }
  
  render() {
    const { classes, initPropostaItems } = this.props;
    console.log('[ZSE] render InitProposal ... ', initPropostaItems);
    const { t } = this.props; //hoc 
    return (
      <div className={classes.root}>        
        <Grid container direction='row' justify='center' alignItems='baseline' spacing={3}>
          {initPropostaItems.map((initPropostaItem, index) => (
            <Grid item key={index}>        		
              <Card className={classes.card} elevation={0}>
                <CardActionArea to={initPropostaItem.to} component={Link}>
                  <CardActions>
                    {initPropostaItem.image &&
			    	  <CardMedia
                        className={classes.media}
                        component='img'
                        image={initPropostaItem.image}
                        alt={t(initPropostaItem.title)}
                      />
                    }
                  </CardActions>
                </CardActionArea>
                <CardContent>
                  {initPropostaItem.title &&
                    <Typography gutterBottom variant='h5' align='center'>
                      {t(initPropostaItem.title)}
                    </Typography>
                  }     
                </CardContent>        	        
              </Card>
            </Grid>        	  
          ))}
    	</Grid>
      </div>
    );
  }
}

InitProposal.propTypes = {
  classes: PropTypes.object.isRequired,
  initPropostaItems: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(moreStyles, { withTheme: true })(InitProposal)));
