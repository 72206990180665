import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ButtonComp from '../../common/ButtonComp';

import DatiReferenteTerzo from '../sezione/DatiReferenteTerzo';

import * as actions from '../figure/action/referenteTerzo';
import * as actionType from '../figure/actionType/referenteTerzo';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button, 
  textErrorMessage: theme.textErrorMessage,
});
	
const mapStateToProps = state => {
  console.log('#RTCFA ReferenteTerzoFisicoAss.mapStateToProps.state', state);
  return {
    referenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico,
    flReferenteTerzo: state.areaReferenteTerzoReducer.referenteTerzoFisico.datiReferenteTerzo.datiGenerali.flReferenteTerzo,
    controlloDatiReferenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA ReferenteTerzoFisicoAss.mapDispatchToProps.dispatch', dispatch);
  return {	  
    updateDatiReferenteTerzoFisico: (unDato, unActionType) => dispatch(actions.updateDatiReferenteTerzoFisico(unDato, unActionType)),  
  };
};
	
class ReferenteTerzoFisicoAss extends React.Component {
	
  constructor(props) {
    super(props);
//    console.log('#RTCFA ReferenteTerzoFisicoAss constructor props', this.props);
    this.state = {
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
      isMounting: false,
      isConferma: false,
    }   
  };
  
  handleChangeConfermaReferenteTerzo = event => {
	  console.log('#RTCFA ReferenteTerzoFisicoAss handleChangeConfermaContraente', event.target.value);
	  this.props.updateDatiReferenteTerzoFisico(true, actionType.SALVA_REFERENTE_TERZO_FISICO);
	  this.setState({
	        isConferma: true,
	    });	  
  }  

  initStatoCampi = () => {
      return [ {id: 'datReferenteTerzo', isError: false},
             ]; 
  }
	  
  handleError = (id, isError) => {
    console.log('########ReferenteTerzoFisicoAss verificaCodiceFiscale handleError id', id);	  
    console.log('########ReferenteTerzoFisicoAss verificaCodiceFiscale handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],
    });
  }
	  
  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }
  
  render() {
    const { classes, t } = this.props;
    const isMounting = this.state.isMounting;
    const controlloDati = this.props.controlloDatiReferenteTerzoFisico;
    
    return (
      <Fragment>
      	
      	<DatiReferenteTerzo labelFieldSet='referenteTerzo' id={'datiReferenteTerzo'} onError={this.handleError} />
      	
      	<Grid container direction="row" justify="center" alignItems="flex-start">
            <Grid item xs={12}>
              <Grid container direction='row' justify='center' alignItems='center'>            	
                <Grid item>
                  {/*{this.props.referenteTerzoFisico.controlloDati===true&&
                     <Typography className={classes.textErrorMessage}>{'CODICE FISCALE REFERENTE TERZO ERRATO'}</Typography>
                  }*/}
                  {(controlloDati === true) && (this.state.isPresenteErrore === true) && isMounting === false &&
                    <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                  }
                  {(controlloDati === true) && (this.state.isPresenteErrore === false) && isMounting === false &&
                    <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ButtonComp
                 aClassName={classes.button}
                 buttonLabel={'conferma'}
                 onClick={this.handleChangeConfermaReferenteTerzo}
              />
            </Grid>
        </Grid>	
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########ReferenteTerzoFisicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//		    console.log('########ReferenteTerzoFisicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//		    console.log('########ReferenteTerzoFisicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	
    // condizione per il reset del componente  
    if (this.props.flReferenteTerzo!==prevProps.flReferenteTerzo){
		if (this.state.isPresenteErrore!==null) {
			this.state.isPresenteErrore = null;
		}
	}
    
    if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDatiReferenteTerzoFisico === true)) {
      /*
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      } 
      */     
	  this.props.updateDatiReferenteTerzoFisico(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_REFERENTE_TERZO_FISICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });
	}
  }
  
  isMounting(prevProps, prevState) {
	if(this.state.isMounting===true){
		this.setState({
			isMounting: false,
		});
	}  
  }
  
  isConferma(prevProps, prevState) {
	if(this.state.isConferma===true){
		this.setState({
			isConferma: false, // reset click conferma
		});
	}  
  }
  
  componentDidUpdate(prevProps, prevState) {	
    this.isInError(prevProps, prevState);
    this.isConferma(prevProps, prevState);
    this.isMounting(prevProps, prevState);
  }
}

ReferenteTerzoFisicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ReferenteTerzoFisicoAss)));
