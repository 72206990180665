import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';


const styles = theme => ({
  titolo: {
	textAlign: 'center',
	marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
  
  titoloAllegato: {
	width:'100%',
    paddingTop: theme.spacing(3),
  },
  colMarine: {
	  color: theme.marine,
  },
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.color.greyLight,
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
  },
  body: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    textAlign: 'center',
  },
}))(TableCell);


const mapStateToProps = state => {
  //console.log('#RTCFA DatiQuestionarioAnamnestico.mapStateToProps.state', state);
  return {	   
  };
};
	
const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModalitaPagamento.mapDispatchToProps.dispatch', dispatch);
  return {
  };
};
		


class Allegato_1_QuestOncologico extends React.Component {
  
  constructor(props) {
	  super(props);
	  
	  let rows = 10; //
	  let cols = 3; // 
	  const datiTabAllegato = Array.from({ length: rows }, () => Array.from({ length: cols }, () => ''));
	  const { t } = this.props; //hoc catalog.js translation
	  //carico le righe della tabella dell'allegato oncologico da catalog.js
	  for(let i=0; i< rows; i++) {
	  	for(let j=0; j< cols; j++) {
	  		let key = 'allegato1_Tabella_R'+(i+1)+'_C'+(j+1); 
	  		let c = t(key);
	  		datiTabAllegato[i][j]=c;
	  	}
	  }
	  
	  this.state = {		  
		  datiTabAllegato: datiTabAllegato,
	  };
  }
    
  render() {
    const { classes } = this.props;   
    const { t } = this.props;
  
    return (
	    <div>
		    <Grid container direction="row" justify="center" alignItems="center">	
				<Grid item>
					<Typography  className={classes.colMarine}>{t('allegato1_ALLEGATO')}</Typography>
				</Grid>
				
				<Grid container direction="row">
					<Grid item md={1} xs={12}></Grid>    				
					<Grid item md={11} xs={12}>
						<Typography className={classes.colMarine}>{t('allegato1_DECRETO')}</Typography>
					</Grid>
					
					<Grid item md={1} xs={12}></Grid>
					<Grid item md={10} xs={12}>
						<Typography variant="caption" className={classes.colMarine}>{t('allegato1_Elenco')}</Typography>
					</Grid>
					<Grid item md={1} xs={12}></Grid>
		
					<Grid item md={1} xs={12}></Grid>
					<Grid item md={10} xs={12} className={classes.titoloAllegato}>
						<Typography variant="caption" className={classes.colMarine}>{t('allegato1_Tabella')}</Typography>
					</Grid>
					<Grid item md={1} xs={12}></Grid>
				</Grid>	
				
				<Grid item md={1} xs={12}></Grid>
				<Grid item md={10} xs={12} className={classes.titoloAllegato}>
					<Grid container direction="row">
						<Grid item md={12}>
							<Table className={classes.table}>
								<TableHead>
							        <TableRow key={0}>
							          <CustomTableCell width='25%' align='center'><Typography variant='subtitle1'>{t('allegato1_Tabella_Header_1')}</Typography></CustomTableCell>
							          <CustomTableCell width='50%' align='center'><Typography variant='subtitle1'>{t('allegato1_Tabella_Header_2')}</Typography></CustomTableCell>
							          <CustomTableCell width='25%' align='center'><Typography variant='subtitle1'>{t('allegato1_Tabella_Header_3')}</Typography></CustomTableCell>
							         </TableRow>
							    </TableHead>
							    <TableBody>								    	
							    	{this.state.datiTabAllegato.map((row, indRow) => (
							    			
					    					<TableRow key={indRow+1}>
					    					{
					    						
								    			row.map((col, indCol) => (
								    					
												    	<CustomTableCell><Typography variant='caption'>{col}</Typography></CustomTableCell>
								    			))
					    					}
									    	</TableRow>								    			
								    	))}
							    	
							    </TableBody> 
							</Table> 
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={1} xs={12}></Grid>
								
			</Grid>	
	    </div>
	  );
  }
}


Allegato_1_QuestOncologico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Allegato_1_QuestOncologico)));
