import * as actionType from '../actionType/datiModalitaPagamento';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiModalitaPagamentoInizializzato, datiModPagAssegnoInizializzato, datiModPagBonificoInizializzato, datiModPagBonificoEsteroInizializzato, datiModPagReimpiegoInizializzato, datiModPagCartaInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

//import { MODALITA_PAGAMENTO_TYPE } from '../../../../../utility/jsConstants';
import { datiModPagAltro } from '../../../../../session/info/sezioni/sezioniCaratteristicheContratto';
	
const areaDatiModalitaPagamentoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiModalitaPagamentoReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaModalitaPagamento(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };	  
    case actionType.SALVA_DATI_MODALITA_PAGAMENTO:
      console.log('#RT2 Reducer areaDatiModalitaPagamentoReducer SALVA_DATI_MODALITA_PAGAMENTO', actionType.SALVA_DATI_MODALITA_PAGAMENTO); 
      console.log('#RT2 Reducer areaDatiModalitaPagamentoReducer.action', action);    
      const caratteristicheContrattoDatiModalitaPagamentoSalva = {...state}.caratteristicheContratto;
      const datiModalitaPagamentoSalva = {...caratteristicheContrattoDatiModalitaPagamentoSalva}.datiModalitaPagamento;
      datiModalitaPagamentoSalva.controlloDati = action.unDato;
      caratteristicheContrattoDatiModalitaPagamentoSalva.datiModalitaPagamento = {...datiModalitaPagamentoSalva};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiModalitaPagamentoSalva,
      };
    case actionType.UPDATE_DATI_MODALITA_PAGAMENTO_VALIDA:
      console.log('#RT2 Reducer areaDatiModalitaPagamentoReducer UPDATE_DATI_MODALITA_PAGAMENTO_VALIDA', actionType.UPDATE_DATI_MODALITA_PAGAMENTO_VALIDA); 
      console.log('#RT2 Reducer areaDatiModalitaPagamentoReducer.action', action);    
      const caratteristicheContrattoDatiModalitaPagamentoUpdate = {...state}.caratteristicheContratto;
      const datiModalitaPagamentoUpdate = {...caratteristicheContrattoDatiModalitaPagamentoUpdate}.datiModalitaPagamento;
      datiModalitaPagamentoUpdate.datiModalitaPagamentoValida = action.unDato;
      caratteristicheContrattoDatiModalitaPagamentoUpdate.datiModalitaPagamento = {...datiModalitaPagamentoUpdate};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiModalitaPagamentoUpdate,
      };      
    case actionType.UPDATE_PAGATORE_PREMIO:
      const caratteristicheContrattoPagatorePremio = {...state}.caratteristicheContratto;
      const datiModalitaPagamentoPagatorePremio = {...caratteristicheContrattoPagatorePremio}.datiModalitaPagamento;
      datiModalitaPagamentoPagatorePremio.pagatorePremio = action.unDato;
      caratteristicheContrattoPagatorePremio.datiModalitaPagamento = {...datiModalitaPagamentoPagatorePremio};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoPagatorePremio,
      };   
    case actionType.UPDATE_MODALITA_PAGAMENTO:
      const caratteristicheContrattoModalitaPagamento = {...state}.caratteristicheContratto;
      const datiModalitaPagamentoModalitaPagamento = {...caratteristicheContrattoModalitaPagamento}.datiModalitaPagamento;
      datiModalitaPagamentoModalitaPagamento.modalitaPagamento = unValore;
      datiModalitaPagamentoModalitaPagamento.descModalitaPagamento = unaLabel;
      datiModalitaPagamentoModalitaPagamento.controlloDati = '';
      datiModalitaPagamentoModalitaPagamento.datiModalitaPagamentoValida = null;
      caratteristicheContrattoModalitaPagamento.datiModalitaPagamento = {...datiModalitaPagamentoModalitaPagamento};
      
      const datiModPagBonifico = {...caratteristicheContrattoModalitaPagamento}.datiModPagBonifico;
      datiModPagBonifico.codiceIbanValido = null;
      datiModPagBonifico.controlloIban = '';
      datiModPagBonifico.iban = '';      
      datiModPagBonifico.banca = '';
      datiModPagBonifico.descBanca = '';
      datiModPagBonifico.agenzia = '';
      datiModPagBonifico.descAgenzia = '';
      caratteristicheContrattoModalitaPagamento.datiModPagBonifico = datiModPagBonifico;
      
      const datiModPagAssegno = {...caratteristicheContrattoModalitaPagamento}.datiModPagAssegno;
      datiModPagAssegno.numeroAssegno1 = '';
      datiModPagAssegno.numeroAssegno2 = '';
      datiModPagAssegno.banca1 = '';
      datiModPagAssegno.descBanca1 = '';
      datiModPagAssegno.banca2 = '';
      datiModPagAssegno.descBanca2 = '';
      datiModPagAssegno.agenzia1 = '';
      datiModPagAssegno.descAgenzia1 = '';
      datiModPagAssegno.agenzia2 = '';
      datiModPagAssegno.descAgenzia2 = '';
      caratteristicheContrattoModalitaPagamento.datiModPagAssegno = datiModPagAssegno;
      
      const datiModPagReimpiego = {...caratteristicheContrattoModalitaPagamento}.datiModPagReimpiego;
      datiModPagReimpiego.polizza = '';
      caratteristicheContrattoModalitaPagamento.datiModPagReimpiego = datiModPagReimpiego;
      
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoModalitaPagamento,
      };
    case actionType.UPDATE_NEED_ESECUTORE:
      const contraenteFisico = {...state}.contraenteFisico;
      const datiEsecutore = {...contraenteFisico}.datiEsecutore;
      contraenteFisico.anagraficaValida = false;
      contraenteFisico.controlloDati = true;
      datiEsecutore.flEsecutore = 'S';
      contraenteFisico.datiEsecutore = {...datiEsecutore};
      return {
        ...state,
        contraenteFisico: contraenteFisico
      }
    case actionType.RESET_DATI_MOD_PAGAMENTO:
      const caratteristicheContratto = {...state}.caratteristicheContratto;
      caratteristicheContratto.datiModPagAssegno = jsonCopy(datiModPagAssegnoInizializzato);
      caratteristicheContratto.datiModPagBonifico = jsonCopy(datiModPagBonificoInizializzato);
      caratteristicheContratto.datiModPagBonificoEstero = jsonCopy(datiModPagBonificoEsteroInizializzato);
      caratteristicheContratto.datiModPagBonificoEstero.intestatario = action.unDato;
      caratteristicheContratto.datiModPagCarta = jsonCopy(datiModPagCartaInizializzato);
      caratteristicheContratto.datiModPagCarta.intestatario = action.unDato || '';
      caratteristicheContratto.datiModPagReimpiego = jsonCopy(datiModPagReimpiegoInizializzato);
      caratteristicheContratto.datiModPagAltro = jsonCopy(datiModPagAltro);
      return {
        ...state,
        caratteristicheContratto
      }
    default:
      return state;
  }
}

export const inizializzaModalitaPagamento = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiModalitaPagamento = jsonCopy(datiModalitaPagamentoInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiModalitaPagamentoReducer;