import * as actionType from '../actionType/datiModificaUtenza';

export const updateDatiModificaUtenza = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaDatiModificaUtenza = controlloDati => {
  return {
    type: actionType.SALVA_DATI_MODIFICA_UTENZA,
	controlloDati,
  }
};
	
export const riattivaDatiModificaUtenza = (dataRevoca, statoUtenza, dataUltimaVariazione) => {
  return {
    type: actionType.RIATTIVA_DATI_MODIFICA_UTENZA,
    dataRevoca,
    statoUtenza,
    dataUltimaVariazione,
  }
};

export const revocaDatiModificaUtenza = (dataRevoca, statoUtenza, dataUltimaVariazione) => {
  return {
    type: actionType.REVOCA_DATI_MODIFICA_UTENZA,
    dataRevoca,
    statoUtenza,
    dataUltimaVariazione,
  }
};
	
export const sbloccaDatiModificaUtenza = (dataSblocco, statoUtenza, dataUltimaVariazione) => {
  return {
    type: actionType.SBLOCCA_DATI_MODIFICA_UTENZA,
	dataSblocco,
    statoUtenza,
    dataUltimaVariazione,
  }
};
		
export const modificaDatiModificaUtenza = (email, dataUltimaVariazione) => {
  return {
    type: actionType.MODIFICA_DATI_MODIFICA_UTENZA,
	email,
    dataUltimaVariazione,
  }
};