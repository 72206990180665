export const datiNuovaUtenzaInizializzato = {
  controlloDati: '',
  mandato: '',
  descMandato: '',
  cognome: '',
  nome: '',
  email: '',
  codiceConsulente: '',
  utente: '',
  profilo: '',
  descProfilo: '',
  dataCreazione: null,
  dataScadenza: null,
};

export const datiRicercaUtenzaInizializzato = {
  controlloDati: '',
  mandato: '',
  descMandato: '',
  cognome: '',
  nome: '',
  utente: '',
  profilo: '',
  descProfilo: '',
  dataCreazione: null,
  dataScadenza: null,
  dataUltimoAccesso: null,
  dettaglioUtente: null,
};

export const datiModificaUtenzaInizializzato = {
  controlloDati: '',
  statoUtenza: '',
  mandato: '',
  descMandato: '',
  cognome: '',
  nome: '',
  utente: '',
  profilo: '',
  descProfilo: '',
  dataCreazione: null,
  dataScadenza: null,
  dataBlocco: null,
  dataRevoca: null,
  dataUltimaVariazione: null,
};

export const datiLoginCambioPasswordInizializzato = {
  controlloDati: '',
  utente: '',
  password: '',
  passwordNuova: '',
  passwordConferma: '',
};

export const datiPasswordDimenticataInizializzato = {
  controlloDati: '',
  email: '',
};

export const datiNuovaPasswordInizializzato = {
  controlloDati: '',
  utente: '',
};

export const datiInviaNuoveCredenzialiInizializzato = {
  controlloDati: '',
  mandato: '',
  descMandato: '',
};