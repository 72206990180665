import store from '../store';
import { showSezione } from './genericUtility';
import { getQuestionarioAnamnestico } from './questionarioUtility';

import { STEP_CONTRAENTE_ASSICURATO, STEP_ALTRE_FIGURE, STEP_CARATTERISTICHE_CONTRATTO, 
         STEP_CONSENSI, STEP_QUESTIONARI, STEP_STAMPA_PROPOSTA,
         RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, 
         PRODOTTO_TIPOLOGIA_ALTRO, 
         DATI_PIANO_EROGAZIONI_PERIODICHE, DATI_TAKE_PROFIT, DATI_LIFE_CYCLE, DATI_NESSUNO, DATI_PIANO_SPOSTAMENTO_AUTOMATICO,
         COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002, QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO,
         CONTRAENTE_FISICO, ESECUTORE_FISICO, CONTRAENTE, CODICE_QUALIFICA_FIDUCIANTE } from './jsConstants';
         
export const getIsValidaProposal = () => {
  const state = store.getState();
  const contraenteFisico = state.areaContraenteReducer.contraenteFisico;
  const contraenteGiuridico = state.areaContraenteReducer.contraenteGiuridico;
  const assicuratoFisico = state.areaAssicuratoReducer.assicuratoFisico;
  const titolareEffList = state.areaTitolareEffReducer.titolareEffList;
  const titolareEff = state.areaTitolareEffReducer.titolareEff;
  const isValidaStepContraenteAssicurato = getIsValidaStepContraenteAssicurato(contraenteFisico, contraenteGiuridico, assicuratoFisico, titolareEffList, titolareEff);    
  let isValida = isValidaStepContraenteAssicurato;
  if (isValida === true) {
	const dominio = state.areaDatiDominioReducer.dominio;	  
	const controlloDatiBenefVitaFisico = state.areaBenefVitaReducer.benefVitaFisico.controlloDati;
    const controlloDatiBenefVitaGiuridico = state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati;
    const controlloDatiBenefVitaGenerico = state.areaBenefVitaReducer.benefVitaGenerico.controlloDati;
    const percBenVitaTotale = state.areaBenefVitaReducer.percBenVitaTotale;
	const controlloDatiBenefMorteFisico = state.areaBenefMorteReducer.benefMorteFisico.controlloDati;
	const controlloDatiBenefMorteGiuridico = state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati;
	const controlloDatiBenefMorteGenerico = state.areaBenefMorteReducer.benefMorteGenerico.controlloDati;
	const percBenMorteTotale = state.areaBenefMorteReducer.percBenMorteTotale;
	const controlloDatiReferenteTerzoFisico = state.areaReferenteTerzoReducer.referenteTerzoFisico.controlloDati;
    const referenteTerzoFisicoValida = state.areaReferenteTerzoReducer.referenteTerzoFisico.anagraficaValida;	  
    const isValidaStepAltreFigure = getIsValidaStepAltreFigure(dominio, 
                                             controlloDatiBenefVitaFisico, controlloDatiBenefVitaGiuridico, controlloDatiBenefVitaGenerico, percBenVitaTotale,
                                             controlloDatiBenefMorteFisico, controlloDatiBenefMorteGiuridico, controlloDatiBenefMorteGenerico, percBenMorteTotale,
                                             controlloDatiReferenteTerzoFisico, referenteTerzoFisicoValida);
    isValida = isValida && isValidaStepAltreFigure;
    if (isValida === true) {
      const prodotto = state.areaProdottoReducer.prodotto;
      const datiModalitaPagamentoValida = state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento.datiModalitaPagamentoValida;      
      const datiPremioCapitaleAssicuratoValida = state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida;
      const datiPremioValida = state.areaDatiPremioReducer.caratteristicheContratto.datiPremio.datiPremioValida;
      const datiRipartizionePremioValida = state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio.datiRipartizionePremioValida;
      const datiOpzioniContrattuali = state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali;
      const datiPianoErogazioniPeriodicheValida = state.areaDatiPianoErogazioniPeriodicheReducer.caratteristicheContratto.datiPianoErogazioniPeriodiche.datiPianoErogazioniPeriodicheValida;
      const datiTakeProfitValida = state.areaDatiTakeProfitReducer.caratteristicheContratto.datiTakeProfit.datiTakeProfitValida;       
      const isValidaStepCaratteristicheContratto = getIsValidaStepCaratteristicheContratto(prodotto, datiModalitaPagamentoValida, datiPremioCapitaleAssicuratoValida,
                                                                                                datiPremioValida, datiRipartizionePremioValida,
                                                                                                datiOpzioniContrattuali, datiPianoErogazioniPeriodicheValida,
                                                                                                datiTakeProfitValida);
      isValida = isValida && isValidaStepCaratteristicheContratto;
      if (isValida === true) {   
        const datiIntermediarioValida = state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario.datiIntermediarioValida;
        //const controlloDatiIntermediario = state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario.controlloDati;
        const datiConsensoValida = state.areaDichConsensoReducer.dichiarazioni.datiConsenso.datiConsensoValida;
        //const controlloDatiConsenso = state.areaDichConsensoReducer.dichiarazioni.datiConsenso.controlloDati;  
        const isValidaStepConsensi = getIsValidaStepConsensi(datiIntermediarioValida, datiConsensoValida); 
        isValida = isValida && isValidaStepConsensi;
        if (isValida === true) {   
      	  const datiGeneraliContraenteGiuridico = state.areaContraenteReducer.contraenteGiuridico.datiGenerali;
          const datiQuestionarioAdeguatezzaFisicoValida = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico.datiQuestionarioValida;
          const datiQuestionarioAdeguatezzaGiuridicoValida = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico.datiQuestionarioValida;
          const datiPremioCapitaleAssicurato = state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato;
          
//2020-02-08 PFD - START
//          const datiQuestionarioAnamnesticoValida = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico.datiQuestionarioValida;
//          const datiQuestionarioAnamnesticoMalattiaGraveValida = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida;
//          const datiQuestionarioSemplificatoValida = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida;
//
//          const datiQuestionarioAdeguatezzaFisicoControlloDati = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico.controlloDati;
//          const datiQuestionarioAdeguatezzaGiuridicoControlloDati = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico.controlloDati;
//          const datiQuestionarioAnamnesticoControlloDati = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico.controlloDati;
//          const datiQuestionarioAnamnesticoMalattiaGraveControlloDati = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave.controlloDati;    
//          const datiQuestionarioSemplificatoControlloDati = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato.controlloDati;
//          const questionarioSemplificatoShow = state.areaDatiQuestionarioReducer.questionario.showSemplificato;
          const isChangedFromSemplificato = state.areaDatiQuestionarioReducer.questionario.isChangedFromSemplificato;
//          const showOnlySemplificato = state.areaDatiQuestionarioReducer.questionario.showOnlySemplificato;
//            
//          const isValidaStepQuestionari = getIsValidaStepQuestionari(prodotto.questionari, datiGeneraliContraenteGiuridico, datiQuestionarioAdeguatezzaFisicoValida, 
//                                                                     datiQuestionarioAdeguatezzaGiuridicoValida, datiPremioCapitaleAssicurato, 
//                                                                     datiQuestionarioAnamnesticoValida, datiQuestionarioAnamnesticoMalattiaGraveValida,
//                                                                     datiQuestionarioAdeguatezzaFisicoControlloDati, datiQuestionarioAdeguatezzaGiuridicoControlloDati,
//                                                                     datiQuestionarioAnamnesticoControlloDati, datiQuestionarioAnamnesticoMalattiaGraveControlloDati,
//                                                                     datiQuestionarioSemplificatoValida, datiQuestionarioSemplificatoControlloDati, questionarioSemplificatoShow,
//                                                                     isChangedFromSemplificato, showOnlySemplificato);
//
          const datiQuestionarioAnamnestico = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico;
          const datiQuestionarioAnamnesticoMalattiaGrave = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave;
          const datiQuestionarioAnamnesticoSemplificato = state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato;
          
          const isValidaStepQuestionari = getIsValidaStepQuestionari(prodotto, contraenteFisico, assicuratoFisico, contraenteGiuridico, 
        		  datiQuestionarioAdeguatezzaFisicoValida, datiQuestionarioAdeguatezzaGiuridicoValida, 
        		  datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoMalattiaGrave,
        		  datiQuestionarioAnamnesticoSemplificato, isChangedFromSemplificato);
//2020-02-08 PFD - END
          
          isValida = isValida && isValidaStepQuestionari;
        }
      }
    }
  }
  return isValida;
}

const getIsValidaStepContraenteAssicurato = (contraenteFisico, contraenteGiuridico, assicuratoFisico, titolareEffList, titolareEff) => {
  let isValidaContraente = null;
  let isValidaAssicurato = null;
  let isValidaTitolareEff = null;
  if (contraenteFisico.controlloDati !== '') {
    isValidaContraente = contraenteFisico.anagraficaValida;
    isValidaTitolareEff = true;    
  } else if (contraenteGiuridico.controlloDati !== '') {    	  
    isValidaContraente = contraenteGiuridico.anagraficaValida;    	  
  }
  if ((assicuratoFisico.controlloDati !== '') || (assicuratoFisico.flAssicuratoUgualeContraente === 'S')) {
    isValidaAssicurato = assicuratoFisico.anagraficaValida;
  }
/*  let titolariOK = titolareEffList.filter(unTitolare => ((unTitolare.controlloDati === true) && (unTitolare.anagraficaValida === true)));
  let titolariKO = titolareEffList.filter(unTitolare => ((unTitolare.controlloDati === true) && (unTitolare.anagraficaValida !== true)));
  let titolariWARN = titolareEffList.filter(unTitolare => (unTitolare.controlloDati === ''));
  if ((titolariOK.length >= 0) && (titolariKO.length === 0) && (titolariWARN.length === 0)) {
    isValidaTitolareEff = true;
  } else if (titolariKO.length > 0) {
	isValidaTitolareEff = false;
  } */
  
  if ((titolareEffList.length > 0) || ((contraenteGiuridico.controlloDati !== '') && (contraenteGiuridico.flTitolariEff === 'N'))) {	  
    isValidaTitolareEff = true;
    titolareEffList.map(tit => {
      if(tit.errorFiduciante) {
        isValidaTitolareEff = false;
      }
    })
  } else if (titolareEffList.length === 0 && titolareEff.anagraficaValida !== null) {
    isValidaTitolareEff = false;
  }    
  
  const isValida = ((isValidaContraente === null) && (isValidaAssicurato === null) && (isValidaTitolareEff === null)) 
                          ? null 
                          : ((isValidaContraente === false) || (isValidaAssicurato === false) || (isValidaTitolareEff === false))
                               ? false
                               : ((isValidaContraente === true) && (isValidaAssicurato === true) && (isValidaTitolareEff === true))
                                  ? true
                                  : null;
  return isValida;
}

const getIsValidaStepAltreFigure = (dominio, controlloDatiBenefVitaFisico, controlloDatiBenefVitaGiuridico, controlloDatiBenefVitaGenerico, percBenVitaTotale,
		                            controlloDatiBenefMorteFisico, controlloDatiBenefMorteGiuridico, controlloDatiBenefMorteGenerico, percBenMorteTotale,
                                    controlloDatiReferenteTerzoFisico, referenteTerzoFisicoValida) => {
  let isValidaBenefMorte = null;	 
  let isValidaBenefVita = null;
  let isValidaRefTerzo = null;
  let isValidaTitEffVita = null;
  let isValidaTitEffMorte = null;
  const items = dominio.ruoloXProdottoItems.map((item) => {
    												return (item.value)
    											  });
  if (showSezione(items, RUOLO_BENEF_VITA) !== true) {
  	isValidaBenefVita = true;
  } else { 
    const controlloDati = controlloDatiBenefVitaFisico !== '' || controlloDatiBenefVitaGiuridico !== '' || controlloDatiBenefVitaGenerico !== '';     
    if (controlloDati) {
//     const percBenVitaTotale = this.props.percBenVitaTotale;
      isValidaBenefVita = percBenVitaTotale === 100.0;
    }      	
  }    
  if (showSezione(items, RUOLO_BENEF_MORTE) !== true) {
    isValidaBenefMorte = true;
    isValidaRefTerzo = true;
  } else { 
    const controlloDati = controlloDatiBenefMorteFisico !== '' || controlloDatiBenefMorteGiuridico !== '' || controlloDatiBenefMorteGenerico !== '';    
    if (controlloDati) {
//        const percBenMorteTotale = this.props.percBenMorteTotale;
      isValidaBenefMorte = percBenMorteTotale === 100.0;
    }    	
    if (controlloDatiReferenteTerzoFisico !== '') {
      isValidaRefTerzo = referenteTerzoFisicoValida;
    }  
  }
  const state = store.getState();
  if(state.areaBenefMorteReducer.benefMorteGiuridicoList.length > 0 && state.areaBenefMorteReducer.benefMorteGiuridicoList[0].titolareEffBenefMorteListInBenef.length > 0) {
    state.areaBenefMorteReducer.benefMorteGiuridicoList.map(benef => {
      benef.titolareEffBenefMorteListInBenef.length > 0 && benef.titolareEffBenefMorteListInBenef.map(tit => {
        isValidaTitEffMorte = tit.anagraficaValida && tit.errorFiduciante === false;
      })
    })
  } else {
    isValidaTitEffMorte = true;
  }
  if(state.areaBenefVitaReducer.benefVitaGiuridicoList.length > 0 && state.areaBenefVitaReducer.benefVitaGiuridicoList[0].titolareEffBenefVitaListInBenef.length > 0) {
    state.areaBenefVitaReducer.benefVitaGiuridicoList.map(benef => {
      benef.titolareEffBenefVitaListInBenef.length > 0 && benef.titolareEffBenefVitaListInBenef.map(tit => {
        isValidaTitEffVita = tit.anagraficaValida && tit.errorFiduciante === false;
      })
    })
  } else {
    isValidaTitEffVita = true;
  }
  const isValida = ((isValidaBenefVita === null) && (isValidaBenefMorte === null) && (isValidaRefTerzo === null) && (isValidaTitEffVita === null) && (isValidaTitEffMorte === null)) 
                     ? null 
                     : ((isValidaBenefVita === false) || (isValidaBenefMorte === false) || (isValidaRefTerzo === false) && (isValidaTitEffVita === false) && (isValidaTitEffMorte === false))
                           ? false
                           : ((isValidaBenefVita === true) && (isValidaBenefMorte === true) && (isValidaRefTerzo === true) && (isValidaTitEffVita === true) && (isValidaTitEffMorte === true))
                                  ? true
                                  : null;      
  return isValida;
}  
  
const getIsValidaStepCaratteristicheContratto = (prodotto, datiModalitaPagamentoValida, datiPremioCapitaleAssicuratoValida,
		                                         datiPremioValida, datiRipartizionePremioValida,
		                                         datiOpzioniContrattuali, datiPianoErogazioniPeriodicheValida,
		                                         datiTakeProfitValida) => {
  let isValida = null;
  let isValidaModalitaPagamento = datiModalitaPagamentoValida;
  if (prodotto.tipo === PRODOTTO_TIPOLOGIA_ALTRO) {
    let isValidaCapitaleAssicurato = datiPremioCapitaleAssicuratoValida;	
    isValida = ((isValidaModalitaPagamento === null) && (isValidaCapitaleAssicurato === null)) 
                     ? null 
                     : ((isValidaModalitaPagamento === false) || (isValidaCapitaleAssicurato === false))
                           ? false
                           : ((isValidaModalitaPagamento === true) && (isValidaCapitaleAssicurato === true))
                                  ? true
                                  : null;      
  } else {
    let isValidaPremio = datiPremioValida;
    let isValidaRipartizionePremio = datiRipartizionePremioValida;	    
    let isValidaOpzioniContrattuali = null;
    const opzioneContrattuale = datiOpzioniContrattuali.opzioneContrattuale;
    if (opzioneContrattuale === DATI_PIANO_EROGAZIONI_PERIODICHE) {
      isValidaOpzioniContrattuali = datiPianoErogazioniPeriodicheValida;
    } else if (opzioneContrattuale === DATI_TAKE_PROFIT) {
      isValidaOpzioniContrattuali = datiTakeProfitValida;
    } else if (opzioneContrattuale === DATI_LIFE_CYCLE) {
      isValidaOpzioniContrattuali = datiOpzioniContrattuali.datiLifeCycle.datiLifeCycleValida;
    } else if (opzioneContrattuale === DATI_NESSUNO) {
      isValidaOpzioniContrattuali = true;
    }  else if (opzioneContrattuale === DATI_PIANO_SPOSTAMENTO_AUTOMATICO) {
      isValidaOpzioniContrattuali = datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.datiPianoSpostamentoAutomaticoValida;
    }
    isValida = ((isValidaModalitaPagamento === null) && (isValidaPremio === null) && (isValidaRipartizionePremio === null) && (isValidaOpzioniContrattuali === null)) 
                      ? null 
                      : ((isValidaModalitaPagamento === false) || (isValidaPremio === false) || (isValidaRipartizionePremio === false) || (isValidaOpzioniContrattuali === false))
                           ? false
                           : ((isValidaModalitaPagamento === true) && (isValidaPremio === true) && (isValidaRipartizionePremio === true) && (isValidaOpzioniContrattuali === true))
                                ? true
                                : null;        
  }
  return isValida;
}
	  
const getIsValidaStepConsensi = (datiIntermediarioValida, datiConsensoValida) => {
  const isValidaDatiIntermediario = datiIntermediarioValida;
  const isValidaDatiConsenso = datiConsensoValida;
  const isValida = ((isValidaDatiIntermediario === null) && (isValidaDatiConsenso === null)) 
                     ? null 
                     : ((isValidaDatiIntermediario === false) || (isValidaDatiConsenso === false))
                           ? false
                           : ((isValidaDatiIntermediario === true) && (isValidaDatiConsenso === true))
                                  ? true
                                  : null;      
  return isValida;
}

export const getIsValidaStepQuestionari = (prodotto, contraenteFisico, assicuratoFisico, contraenteGiuridico, 
		datiQuestionarioAdeguatezzaFisicoValida, datiQuestionarioAdeguatezzaGiuridicoValida,
        datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoMalattiaGrave,        
        datiQuestionarioAnamnesticoSemplificato, isChangedFromSemplificato) => {
	
        	
    const datiQuestionarioAnamnesticoValida = datiQuestionarioAnamnestico.datiQuestionarioValida;
    const datiQuestionarioAnamnesticoMalattiaGraveValida = datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida;
    const datiQuestionarioAnamnesticoSemplificatoValida = datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida;
    
    const datiGeneraliContraenteGiuridico = contraenteGiuridico.datiGenerali;
    
    let isValidaAdeguatezza = null;	 
    let isValidaAnamnestico = null;
    let isValidaSemplificato = null;
    
//    console.log(showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002));
    
    if (showSezione(prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011) !== true) {
      isValidaAdeguatezza = true;
    } else {
      isValidaAdeguatezza = (datiGeneraliContraenteGiuridico.codiceFiscale === '') 
                             	? datiQuestionarioAdeguatezzaFisicoValida : datiQuestionarioAdeguatezzaGiuridicoValida;
    }

    const dataNascita = (assicuratoFisico.flAssicuratoUgualeContraente === 'S') ? contraenteFisico.datiGenerali.dataNascita : assicuratoFisico.datiGenerali.dataNascita;    
	const questionarioAnamnestico = getQuestionarioAnamnestico (dataNascita, datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoSemplificato, datiQuestionarioAnamnesticoMalattiaGrave);
	const tipoQuestionarioAnam = questionarioAnamnestico.tipoQuestionario;
	  
    if (!showSezione(prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002) ||  
    	 (tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO && !isChangedFromSemplificato) ) {
    	isValidaAnamnestico = true;
    } else {
      const malattiaGrave = datiPremioCapitaleAssicurato.malattiaGrave;
      const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);    
      isValidaAnamnestico = (isQuestionarioAnamnestico) ? datiQuestionarioAnamnesticoValida : datiQuestionarioAnamnesticoMalattiaGraveValida;
    }
    
    if (tipoQuestionarioAnam !== QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO || !showSezione(prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002)) {    
      isValidaSemplificato = true;
    } else {
      isValidaSemplificato = (isChangedFromSemplificato) ? true : datiQuestionarioAnamnesticoSemplificatoValida;
    }
    const isValida = ((isValidaAdeguatezza === false) || (isValidaAnamnestico === false) || (isValidaSemplificato === false))
                           ? false
                           : ((isValidaAdeguatezza === true) && (isValidaAnamnestico === true) && (isValidaSemplificato === true))
                                  ? true
                                  : null;      
    return isValida;
}
        
const getIsValidaStepQuestionari_OLD = (questionari, datiGeneraliContraenteGiuridico, datiQuestionarioAdeguatezzaFisicoValida, datiQuestionarioAdeguatezzaGiuridicoValida,
                                    datiPremioCapitaleAssicurato, datiQuestionarioAnamnesticoValida, datiQuestionarioAnamnesticoMalattiaGraveValida,
                                    datiQuestionarioAdeguatezzaFisicoControlloDati, datiQuestionarioAdeguatezzaGiuridicoControlloDati,
                                    datiQuestionarioAnamnesticoControlloDati, datiQuestionarioAnamnesticoMalattiaGraveControlloDati,
                                    datiQuestionarioSemplificatoValida, datiQuestionarioSemplificatoControlloDati, questionarioSemplificatoShow,
                                    isChangedFromSemplificato, showOnlySemplificato) => {
  let isValidaAdeguatezza = null;	 
  let isValidaAnamnestico = null;
  let isValidaSemplificato = null;
  if (showSezione(questionari, COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011) !== true) {
    isValidaAdeguatezza = true;
  } else {
//RT semaforo questionari INIZIO
    const controlloDati = (datiGeneraliContraenteGiuridico.codiceFiscale === '') 
                                       ? datiQuestionarioAdeguatezzaFisicoControlloDati : datiQuestionarioAdeguatezzaGiuridicoControlloDati;
    isValidaAdeguatezza = (controlloDati === '') ? null : true;    	  
//RT semaforo questionari FINE	  
/*RT    isValidaAdeguatezza = (datiGeneraliContraenteGiuridico.codiceFiscale === '') 
                                 ? datiQuestionarioAdeguatezzaFisicoValida : datiQuestionarioAdeguatezzaGiuridicoValida;  */  	
  }
  if ((showSezione(questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002) !== true) || showOnlySemplificato) {
    isValidaAnamnestico = true;
  } else {
    const malattiaGrave = datiPremioCapitaleAssicurato.malattiaGrave;
    const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);
//RT semaforo questionari INIZIO
    const controlloDati = (isQuestionarioAnamnestico) ? datiQuestionarioAnamnesticoControlloDati : datiQuestionarioAnamnesticoMalattiaGraveControlloDati;
//20190906 PFD: 	isValidaAnamnestico = (controlloDati === '') ? null : true;  	  
    isValidaAnamnestico = (isQuestionarioAnamnestico) ? datiQuestionarioAnamnesticoValida : datiQuestionarioAnamnesticoMalattiaGraveValida;
//20190906 PFD: END  
  //RT semaforo questionari FINE
  }
  if(!questionarioSemplificatoShow) {
    isValidaSemplificato = true;
  } else {
    isValidaSemplificato = (isChangedFromSemplificato) ? true : datiQuestionarioSemplificatoValida;
  }
  const isValida = ((isValidaAdeguatezza === null) && (isValidaAnamnestico === null) && (isValidaSemplificato === null)) 
                     ? null 
                     : ((isValidaAdeguatezza === false) || (isValidaAnamnestico === false) || (isValidaSemplificato === false))
                           ? false
                           : ((isValidaAdeguatezza === true) && (isValidaAnamnestico === true) && (isValidaSemplificato === true))
                                  ? true
                                  : null;                    
  return isValida;
}

export const getNomeIntestatarioFromTipo = () => {
  const state = store.getState();
  let datiIntestatario = '';
  let nome = '';
  let cognome = '';
  const codIntestatario = state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento.pagatorePremio;
  let tipoIntestatario = '';
  if(codIntestatario === '2') {
    tipoIntestatario = ESECUTORE_FISICO;
  } else if(codIntestatario === '1') {
    tipoIntestatario = CONTRAENTE;
  } 
  const contraenteFisico = state.areaContraenteReducer.contraenteFisico;
  const contraenteGiuridico = state.areaContraenteReducer.contraenteGiuridico;
  const datiGeneraliContraenteFisico = contraenteFisico.datiGenerali;
  const datiGeneraliContraenteGiuridico = contraenteGiuridico.datiGenerali;
  const soggettoTerzo = contraenteFisico.datiEsecutore;
  const datiGeneraliSoggettoTerzo = soggettoTerzo.datiGenerali;
  if(tipoIntestatario === CONTRAENTE) {
    if(contraenteFisico.anagraficaValida !== null) {
    nome = datiGeneraliContraenteFisico.nome;
    cognome = datiGeneraliContraenteFisico.cognome;
    } else {
      nome = datiGeneraliContraenteGiuridico.ragioneSociale;
    }
  } else if(tipoIntestatario === ESECUTORE_FISICO) {
    nome = datiGeneraliSoggettoTerzo.nome;
    cognome = datiGeneraliSoggettoTerzo.cognome;
  } 
  if(nome || cognome) {
    datiIntestatario = cognome.concat(' ').concat(nome);
  }
  return datiIntestatario;
}

export const errorFiducianteMorte = (isFiduciaria) => {
  const state = store.getState();
  const scopeAmleto = state.genericReducer.generic.proposalInfo.scopeAmleto;
  const currentTit = state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte;
  const titList = state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorteList;
  let isFiducianteCurrent = false;
  let isPresenteFiducianteInList = false;
  if(currentTit.datiTitolareBenefMorteEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
    isFiducianteCurrent = true;
  }
  if(titList.length > 0 && titList[0].datiTitolareBenefMorteEff) {
    titList.map((el, i) => {
      if(el.datiTitolareBenefMorteEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
        isPresenteFiducianteInList = true;
      }
    });
  }
  let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
  if(!isFiduciaria || !scopeAmleto) {
    return false;
  }
  return !presente;
}

export const errorFiducianteVita = (isFiduciaria) => {
  const state = store.getState();
  const scopeAmleto = state.genericReducer.generic.proposalInfo.scopeAmleto;
  const currentTit = state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita;
  const titList = state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVitaList;
  let isFiducianteCurrent = false;
  let isPresenteFiducianteInList = false;
  if(currentTit.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
    isFiducianteCurrent = true;
  }
  if(titList.length > 0 && titList[0].datiTitolareBenefVitaEff) {
    titList.map((el, i) => {
      if(el.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
        isPresenteFiducianteInList = true;
      }
    });
  }
  let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
  
  if(!isFiduciaria || !scopeAmleto) {
    return false;
  }
  return !presente;
}

export const errorFiducianteContraente = isFiduciaria => {
  const state = store.getState();
  const scopeAmleto = state.genericReducer.generic.proposalInfo.scopeAmleto;
  const currentTit = state.areaTitolareEffReducer.titolareEff;
  const titList = state.areaTitolareEffReducer.titolareEffList;
  let isFiducianteCurrent = false;
  let isPresenteFiducianteInList = false;
  if(currentTit.datiTitolareEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
    isFiducianteCurrent = true;
  }
  if(titList.length > 0 && titList[0].datiTitolareEff) {
    titList.map((el, i) => {
      if(el.datiTitolareEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
        isPresenteFiducianteInList = true;
      }
    });
  }
  let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
  if(!isFiduciaria || !scopeAmleto) {
    return false;
  }
  return !presente;
}