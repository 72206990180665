import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import NavTabsComp from '../../common/NavTabsComp';
//import { benefVitaTabItems } from '../../store/navTabProposalItems';

import * as actions from '../../anagrafica/figure/action/beneficiarioVita'; 

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const mapStateToProps = state => {
  console.log('#RTCFA BenefMortePanel.mapStateToProps.state', state);
  return {	  
    tabSelezionato: state.areaBenefVitaReducer.tabVitaSelezionato,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA BenefMortePanel.mapDispatchToProps.dispatch', dispatch);
  return {
    updateTabSelezionatoBenVita: (tabIndex) => dispatch(actions.updateTabSelezionatoBenVita(tabIndex)),
  };
};


class BenefVitaPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state =  { 
      tabSelezionato: ((props.tabSelezionato !== undefined) && (props.tabSelezionato !== null)) ? props.tabMSelezionato : 0,
    }
  }; 

  // funzione callback esterna
  handleChangeUpdateTabSelezionato = (event, tabIndex) => {
    this.props.updateTabSelezionatoBenVita(tabIndex);
    this.setState({
      tabSelezionato: tabIndex,
    });
  };

  render () {
    // console.log('#RTCFA BenefVitaPanel this.props', this.props);
    const { classes, isValida, tabList } = this.props;
    console.log('####tabList: ', tabList);    
    const tabSelezionato = this.props.tabSelezionato
    return (
      <div className={classes.root}> 
        <ExpansionPanelComp titolo={'beneficiarioVita'} componente={NavTabsComp} tabSelezionato={tabSelezionato} tabList={tabList} 
                            handleChange={this.handleChangeUpdateTabSelezionato} isValida={isValida} />
      </div>
    );
  };
};

BenefVitaPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BenefVitaPanel));
