import * as actionType from '../actionType/datiPasswordDimenticata';
import initialState from '../../../../session/InitialState';

const areaDatiPasswordDimenticataReducer = (state = initialState, action) => {
	  
  switch (action.type) {
    case actionType.SALVA_DATI_PASSWORD_DIMENTICATA:
      const gestioneUtenzaDatiPasswordDimenticataSalva = {...state}.gestioneUtenza;
      const datiPasswordDimenticataSalva = {...gestioneUtenzaDatiPasswordDimenticataSalva}.datiPasswordDimenticata;
      datiPasswordDimenticataSalva.controlloDati = action.controlloDati;
      gestioneUtenzaDatiPasswordDimenticataSalva.datiPasswordDimenticata = {...datiPasswordDimenticataSalva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiPasswordDimenticataSalva,
      };
    case actionType.INVIA_DATI_PASSWORD_DIMENTICATA:
      const gestioneUtenzaDatiPasswordDimenticataInvia = {...state}.gestioneUtenza;
      const datiPasswordDimenticataInvia = {...gestioneUtenzaDatiPasswordDimenticataInvia}.datiPasswordDimenticata;
      datiPasswordDimenticataInvia.email = action.email;
      gestioneUtenzaDatiPasswordDimenticataInvia.datiPasswordDimenticata = {...datiPasswordDimenticataInvia};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDatiPasswordDimenticataInvia,
      };
    case actionType.UPDATE_EMAIL_PASSWORD_DIMENTICATA:
      const gestioneUtenzaDatiPasswordDimenticataEmail = {...state}.gestioneUtenza;
      const datiPasswordDimenticataEmail = {...gestioneUtenzaDatiPasswordDimenticataEmail}.datiPasswordDimenticata;
      datiPasswordDimenticataEmail.email = action.unDato;
      gestioneUtenzaDatiPasswordDimenticataEmail.datiPasswordDimenticata = {...datiPasswordDimenticataEmail};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaDatiPasswordDimenticataEmail,
      };
    default:
      return state;
  }
}

export default areaDatiPasswordDimenticataReducer;