import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonComp from '../../../common/ButtonComp';
import moment from 'moment';
import 'moment/locale/it';
import { uris, fetchParam } from '../../../../uris';

import * as actions from './action/datiQuestionario';
import * as actionType from './actionType/datiQuestionario';

import DomandeQuestionario from './DomandeQuestionario';

import { COD_QUESTIONARIO_ADEGUATEZZA, QUESTIONARIO_ADEGUATEZZA_FISICO, 
       QUESTIONARIO_ADEGUATEZZA_GIURIDICO } from '../../../../utility/jsConstants';
       
import { textTrim } from '../../../../utility/genericUtility';


const styles = theme => ({
  titolo: {
	textAlign: 'center',	  
	marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
  paddingArea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiQuestionarioAdeguatezzaFisico.mapStateToProps.state', state);
  return {
    datiQuestionarioAdeguatezzaFisico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico,
    datiQuestionarioAdeguatezzaGiuridico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico,
    prodotto: state.areaProdottoReducer.prodotto,
    datiGeneraliContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiGenerali,
    datiRipartizionePremio: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio,
  };
};
	
const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModalitaPagamento.mapDispatchToProps.dispatch', dispatch);
  return {
    updateQuestionario: (unDato, tipoQuestionario, unActionType) => dispatch(actions.updateQuestionario(unDato, tipoQuestionario, unActionType)),    
    salvaEsitoDatiQuestionario: (esito, esitoMessaggio, tipoQuestionario) => dispatch(actions.salvaEsitoDatiQuestionario(esito, esitoMessaggio, tipoQuestionario)),
  };
};

class DatiQuestionarioIvass extends React.Component {
	
  constructor(props) {
	super(props);
	const datiQuestionarioAdeguatezzaFisico = this.props.datiQuestionarioAdeguatezzaFisico;
	const datiQuestionarioAdeguatezzaGiuridico = this.props.datiQuestionarioAdeguatezzaGiuridico;
	const questionarioAdeg = (this.props.datiGeneraliContraenteGiuridico.codiceFiscale === '') 
	                                 ? datiQuestionarioAdeguatezzaFisico.questionarioAdeguatezzaFisico 
                                     : datiQuestionarioAdeguatezzaGiuridico.questionarioAdeguatezzaGiuridico;
	const tipoQuestionarioAdeg = (this.props.datiGeneraliContraenteGiuridico.codiceFiscale === '') 
                                     ? QUESTIONARIO_ADEGUATEZZA_FISICO
                                     : QUESTIONARIO_ADEGUATEZZA_GIURIDICO;
	this.state = {
	  questionarioAdeguatezza: questionarioAdeg,
	  tipoQuestionarioAdeguatezza: tipoQuestionarioAdeg,
	  esito: '',
	  esitoMessaggio: '',
	};
  };
	
  handleChangeConfermaDatiQuestionario = event => {
    this.props.updateQuestionario(true, this.state.tipoQuestionarioAdeguatezza, actionType.SALVA_DATI_QUESTIONARIO);    
    const questionario = (this.state.tipoQuestionarioAdeguatezza === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) ? this.props.datiQuestionarioAdeguatezzaGiuridico: this.props.datiQuestionarioAdeguatezzaFisico;  
    
    const codProdotto = this.props.prodotto.codice;
    const dataOggi = moment().format('DD/MM/YYYY');
    let profiloInvestimento = '';
    if(this.props.datiRipartizionePremio.lineaLibera !== '') {
      profiloInvestimento = this.props.datiRipartizionePremio.lineaLiberaCodice;
    } else if (this.props.datiRipartizionePremio.lineaGuidata[0] && this.props.datiRipartizionePremio.lineaGuidata[0].linea !== '') {
      profiloInvestimento = this.props.datiRipartizionePremio.lineaGuidata[0].codiceLinea;
    }
    const testataQuestionario = {
	  codSocieta: '143',
	  codiceProdotto: codProdotto,
	  profiloInvestimento: profiloInvestimento,
	  codiceQuestionario: COD_QUESTIONARIO_ADEGUATEZZA,
	  dataProposta: dataOggi,
	};
    questionario.testataQuestionario = testataQuestionario;
    
    let opts = null;
    if(this.state.tipoQuestionarioAdeguatezza === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
      opts = {
        testataQuestionario: questionario.testataQuestionario,
        questionarioAdeguatezzaGiuridico: questionario.questionarioAdeguatezzaGiuridico,
      };
    } else {
      opts = {
        testataQuestionario: questionario.testataQuestionario,
        questionarioAdeguatezzaFisico: questionario.questionarioAdeguatezzaFisico,
      };
    }
    fetch(uris.calcoloAdeguatezza, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
//2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  - START
    // const esito = (result === true) ? 'OK': 'KO';
    //   this.setState({
    //     esito: esito,
    //     esitoMessaggio: esito
    //   });
    //   this.props.salvaEsitoDatiQuestionario(esito, esito, this.state.tipoQuestionarioAdeguatezza, actionType.SALVA_ESITO_DATI_QUESTIONARIO);
    //   //Indipendentemente dal risultato questionario di adeguatezza valido
    //   //this.props.updateQuestionario(result, this.state.tipoQuestionarioAdeguatezza, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);    
    //   this.props.updateQuestionario(true, this.state.tipoQuestionarioAdeguatezza, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);    
      const esito = result[0].label;  
      const valore = result[0].value; 
      this.setState({
        esito: esito,
        esitoMessaggio: valore,
      });
      const isValido = esito==='OK' ? true : false;
      this.props.salvaEsitoDatiQuestionario(esito, esito, this.state.tipoQuestionarioAdeguatezza, actionType.SALVA_ESITO_DATI_QUESTIONARIO);
      this.props.updateQuestionario(isValido, this.state.tipoQuestionarioAdeguatezza, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);
    });
//2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  - END
  } 
  
  render() {
    const { classes } = this.props;  
    const { t } = this.props; //hoc
    
    return (
      <div className={classes.paddingArea}>
        {this.state.questionarioAdeguatezza.map((elemento, index) => (	
          index === 0 ?
            <div key={index} className={classes.titolo}>
			  <Typography variant="h6">{elemento.sezione.testo}</Typography>
			</div>
			:
			<DomandeQuestionario key={index} domande={elemento.domande} titoloSezione={elemento.sezione.testo} tipoQuestionario={this.state.tipoQuestionarioAdeguatezza} /> 
    	))}
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
	        <Grid container direction="row" justify="center" alignItems="center">	
	          <Grid item>
	            {this.state.esito==='OK' &&
	    	        <Typography className={classes.textErrorMessage}>{t('questionarioAdeguato')}</Typography>
	            }
              {this.state.esito==='KO' &&
                <Typography className={classes.textErrorMessage}>{t(this.state.esitoMessaggio)}</Typography>
	            }
	          </Grid>
	        </Grid>
	      </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
              <Grid item>
                <ButtonComp
                  aClassName={classes.button}
                  buttonLabel={'conferma'}
                  onClick={this.handleChangeConfermaDatiQuestionario}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
	  </div>
    );
  }
}

DatiQuestionarioIvass.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiQuestionarioIvass)));

