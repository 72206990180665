import * as actionType from '../actionType/intermediario';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy, dataFormat } from '../../../../../utility/genericUtility';
import { datiIntermediarioInizializzato } from '../../../../../session/info/componentiBase/sezioniDichiarazioni';

const areaDatiIntermediarioReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  let dichiarazioni = state.dichiarazioni;
  let datiIntermediario = dichiarazioni.datiIntermediario;
    
  switch (action.type) { 
    case actionTypeGeneric.INIZIALIZZA: 
	  const dichiarazioni_INI = inizializzaIntermediario(state);
      return {
        ...state,
        dichiarazioni: dichiarazioni_INI,
      };
    case actionType.SALVA_DATI_INTERMEDIARIO:  
      const dichiarazioniDatiIntermediarioSalva = {...state}.dichiarazioni;
      const datiIntermediarioSalva = {...dichiarazioniDatiIntermediarioSalva}.datiIntermediario;
      datiIntermediarioSalva.controlloDati = action.unDato;
      dichiarazioniDatiIntermediarioSalva.datiIntermediario = {...datiIntermediarioSalva};
      return {
    	...state,
    	dichiarazioni: dichiarazioniDatiIntermediarioSalva,
      };
    case actionType.UPDATE_DATI_INTERMEDIARIO_VALIDA:  
      const dichiarazioniDatiIntermediarioUpdate = {...state}.dichiarazioni;
      const datiIntermediarioUpdate = {...dichiarazioniDatiIntermediarioUpdate}.datiIntermediario;
      datiIntermediarioUpdate.datiIntermediarioValida = action.unDato;
      dichiarazioniDatiIntermediarioUpdate.datiIntermediario = {...datiIntermediarioUpdate};
      return {
    	...state,
    	dichiarazioni: dichiarazioniDatiIntermediarioUpdate,
      };    
    case actionType.UPDATE_COMPORTAMENTO_CLIENTE:   
      const dichiarazioniDatiIntermediarioComportamentoCliente = {...state}.dichiarazioni;
      const datiIntermediarioComportamentoCliente = {...dichiarazioniDatiIntermediarioComportamentoCliente}.datiIntermediario;
      datiIntermediarioComportamentoCliente.comportamentoCliente = action.unDato;
      dichiarazioniDatiIntermediarioComportamentoCliente.datiIntermediario = {...datiIntermediarioComportamentoCliente};      
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioComportamentoCliente,
      };
    case actionType.UPDATE_DURATA_RAPPORTO_CONTR_INT:
      const dichiarazioniIntermediarioDurataRapportoContrInt = {...state}.dichiarazioni;
      const datiIntermediarioDurataRapportoContrInt = {...dichiarazioniIntermediarioDurataRapportoContrInt}.datiIntermediario;
      datiIntermediarioDurataRapportoContrInt.durataRapportoContrInt = unValore;
      datiIntermediarioDurataRapportoContrInt.descrDurataRapportoContrInt = unaLabel;
      dichiarazioniIntermediarioDurataRapportoContrInt.datiIntermediario = {...datiIntermediarioDurataRapportoContrInt};
      return {
        ...state,
        dichiarazioni: dichiarazioniIntermediarioDurataRapportoContrInt,
      };    
    case actionType.UPDATE_OPERAZIONE_COERENTE:
      const dichiarazioniIntermediarioOperazioneCoerente = {...state}.dichiarazioni;
      const datiIntermediarioOperazioneCoerente = {...dichiarazioniIntermediarioOperazioneCoerente}.datiIntermediario;
      datiIntermediarioOperazioneCoerente.flOperazioneCoerente = action.unDato;
      dichiarazioniIntermediarioOperazioneCoerente.datiIntermediario = {...datiIntermediarioOperazioneCoerente};
      return {
        ...state,
        dichiarazoni: dichiarazioniIntermediarioOperazioneCoerente,
      };
    case actionType.UPDATE_PROFILO_RISCHIO:
      const dichiarazioneIntermediarioProfiloRischio= {...state}.dichiarazioni;
      const datiIntermediarioProfiloRischio = {...dichiarazioneIntermediarioProfiloRischio}.datiIntermediario;
      datiIntermediarioProfiloRischio.profiloRischio = unValore;
      datiIntermediarioProfiloRischio.descrProfiloRischio = unaLabel;
      dichiarazioneIntermediarioProfiloRischio.datiIntermediario = {...datiIntermediarioProfiloRischio}
      return {
        ...state,
        dichiarazioni: dichiarazioneIntermediarioProfiloRischio,
      };
    case actionType.UPDATE_COLLOCATORE:    
      const dichiarazioniDatiIntermediarioCollocatore = {...state}.dichiarazioni;
      const datiIntermediarioCollocatore = {...dichiarazioniDatiIntermediarioCollocatore}.datiIntermediario;
      datiIntermediarioCollocatore.collocatore = action.unDato;
      dichiarazioniDatiIntermediarioCollocatore.datiIntermediario = {...datiIntermediarioCollocatore};         
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioCollocatore,
      };
    /*2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario 
    case actionType.UPDATE_COGNOME_COLLOCATORE:    
      const dichiarazioniDatiIntermediarioCognomeCollocatore = {...state}.dichiarazioni;
      const datiIntermediarioCognomeCollocatore = {...dichiarazioniDatiIntermediarioCognomeCollocatore}.datiIntermediario;
      datiIntermediarioCognomeCollocatore.cognomeColloc = action.unDato;
      dichiarazioniDatiIntermediarioCognomeCollocatore.datiIntermediario = {...datiIntermediarioCognomeCollocatore}; 
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioCognomeCollocatore,
      };
    case actionType.UPDATE_NOME_COLLOCATORE:
      const dichiarazioniDatiIntermediarioNomeCollocatore = {...state}.dichiarazioni;
      const datiIntermediarioNomeCollocatore = {...dichiarazioniDatiIntermediarioNomeCollocatore}.datiIntermediario;
      datiIntermediarioNomeCollocatore.nomeColloc = action.unDato;
      dichiarazioniDatiIntermediarioNomeCollocatore.datiIntermediario = {...datiIntermediarioNomeCollocatore}; 
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioNomeCollocatore,
      };
    case actionType.UPDATE_REGIONE_ATT_COLLOCATORE:    
      const dichiarazioniDatiIntermediarioRegioneAttCollocatore = {...state}.dichiarazioni;
      const datiIntermediarioRegioneAttCollocatore = {...dichiarazioniDatiIntermediarioRegioneAttCollocatore}.datiIntermediario;
      datiIntermediarioRegioneAttCollocatore.regioneAttivita = unValore;
      datiIntermediarioRegioneAttCollocatore.descrRegioneAttivita = unaLabel;
      dichiarazioniDatiIntermediarioRegioneAttCollocatore.datiIntermediario = {...datiIntermediarioRegioneAttCollocatore}; 
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioRegioneAttCollocatore,
      };
    case actionType.UPDATE_COD_INTERMEDIARIO:
      const dichiarazioniDatiIntermediarioCodIntermediario = {...state}.dichiarazioni;
      const datiIntermediarioCodIntermediario = {...dichiarazioniDatiIntermediarioCodIntermediario}.datiIntermediario;
      datiIntermediarioCodIntermediario.codIntermediario = action.unDato;
      dichiarazioniDatiIntermediarioCodIntermediario.datiIntermediario = {...datiIntermediarioCodIntermediario};       
	  return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioCodIntermediario,
      };
    case actionType.UPDATE_COD_RUI:    
      const dichiarazioniDatiIntermediarioCodRui = {...state}.dichiarazioni;
      const datiIntermediarioCodRui = {...dichiarazioniDatiIntermediarioCodRui}.datiIntermediario;
      datiIntermediarioCodRui.codRUI = action.unDato;
      dichiarazioniDatiIntermediarioCodRui.datiIntermediario = {...datiIntermediarioCodRui}; 
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioCodRui,
      };
      */
    case actionType.UPDATE_COD_COGNOME_NOME_COLLOCATORE:    
        const dichiarazioniDatiIntermediarioCodCognomeNomeColloc = {...state}.dichiarazioni;
        const datiIntermediarioCodCognomeNomeColloc = {...dichiarazioniDatiIntermediarioCodCognomeNomeColloc}.datiIntermediario;
        datiIntermediarioCodCognomeNomeColloc.codCognomeNomeColloc = action.unDato;
        dichiarazioniDatiIntermediarioCodCognomeNomeColloc.datiIntermediario = {...datiIntermediarioCodCognomeNomeColloc}; 
        return {
          ...state,
          dichiarazioni: dichiarazioniDatiIntermediarioCodCognomeNomeColloc,
        };
    case actionType.UPDATE_LUOGO_COLLOCATORE:    
      const dichiarazioniDatiIntermediarioLuogoCollocatore = {...state}.dichiarazioni;
      const datiIntermediarioLuogoCollocatore = {...dichiarazioniDatiIntermediarioLuogoCollocatore}.datiIntermediario;
      datiIntermediarioLuogoCollocatore.luogoColloc = action.unDato;
      dichiarazioniDatiIntermediarioLuogoCollocatore.datiIntermediario = {...datiIntermediarioLuogoCollocatore};       
	  return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioLuogoCollocatore,
      };
    case actionType.UPDATE_DATA_COLLOCATORE:    
      const unaDataColloc = action.unDato; 
      const nuovaDataCollocFormattata = dataFormat(unaDataColloc, 'DD/MM/YYYY');    	
      const dichiarazioniDatiIntermediarioDataCollocatore = {...state}.dichiarazioni;
      const datiIntermediarioDataCollocatore = {...dichiarazioniDatiIntermediarioDataCollocatore}.datiIntermediario;
      datiIntermediarioDataCollocatore.dataColloc = unaDataColloc;
      datiIntermediarioDataCollocatore.dataCollocStampa = nuovaDataCollocFormattata;
      dichiarazioniDatiIntermediarioDataCollocatore.datiIntermediario = {...datiIntermediarioDataCollocatore};        
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiIntermediarioDataCollocatore,
      };
    default:
      return state;
  }
}

export const inizializzaIntermediario = (state) => {
  const dichiarazioni_INI = {...state}.dichiarazioni;
  dichiarazioni_INI.datiIntermediario = jsonCopy(datiIntermediarioInizializzato);
  return dichiarazioni_INI;
}

export default areaDatiIntermediarioReducer;