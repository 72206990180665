import * as actionType from '../actionType/beneficiarioMorte';

/* BENEFICIARIO MORTE FISICO */
export const updateBeneficiarioMorteFisico = (currBenefMorteFisico, unActionType) => {
  return {
    type: unActionType,
    currBenefMorteFisico,
  }
};

export const confermaAggiungiBeneficiarioMorteFisico = (beneficiarioMorteFisico) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_FISICO,
    beneficiarioMorteFisico,
  }
};	

export const nuovoBeneficiarioMorteFisico = (beneficiarioMorteFisico) => {
  return {
    type: actionType.NUOVO_BENEFICIARIO_MORTE_FISICO,
    beneficiarioMorteFisico,
  }
};	
	
export const deleteDettaglioBenefMorteFisico = (benefMorteFisicoDelete)  => {
  return {
    type: actionType.DELETE_BENEF_MORTE_FISICO,
    benefMorteFisicoDelete,
  }
};

export const caricaDettaglioBeneficiarioMorte = (benefMorteList, figuraSelezIndex)  => {
  return {
    type: actionType.CARICA_BENEF_MORTE,
    benefMorteList,
    figuraSelezIndex,
  }
};

/* UPDATE TAB SELEZIONATO BEN MORTE*/
export const updateTabSelezionatoBenMorte = (tabIndex) => {
  return {
    type: actionType.UPDATE_TAB_SELEZIONATO_BENEF_MORTE,
    tabIndex,
  }
};

/* BENEFICIARIO MORTE FISICO DATI GENERALI */
export const updateDatiGeneraliBenefMorteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE FISICO DATI RESIDENZA */
export const updateDatiResidenzaBenefMorteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA FISICO DATI ANTIRICICLAGGIO */
export const updateDatiAntiriciclaggioBenefMorteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE FISICO DATI RELAZ CONTRAENTE */
export const updateDatiRelazContrBenefMorteFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE GIURIDICO */
export const updateBeneficiarioMorteGiuridico = (currBenefMorteGiuridico, unActionType) => {
  return {
    type: unActionType,
    currBenefMorteGiuridico,
  }
};

export const confermaAggiungiBeneficiarioMorteGiuridico = (beneficiarioMorteGiuridico) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_GIURIDICO,
    beneficiarioMorteGiuridico,
  }
};

export const nuovoBeneficiarioMorteGiuridico = (beneficiarioMorteGiuridico) => {
 return {
    type: actionType.NUOVO_BENEFICIARIO_MORTE_GIURIDICO,
    beneficiarioMorteGiuridico,
  }
};	

/* DELETE BENEFICIARIO MORTE GIURIDICO */
export const deleteDettaglioBenefMorteGiuridico = (benefMorteGiuridicoDelete)  => {
  return {
    type: actionType.DELETE_BENEF_MORTE_GIURIDICO,
    benefMorteGiuridicoDelete,
  }
};

/* BENEFICIARIO MORTE GIURIDICO DATI GENERALI */
export const updateDatiGeneraliBenefMorteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE GIURIDICO DATI RESIDENZA */
export const updateDatiResidenzaBenefMorteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE GIURIDICO DATI RELAZ CONTR */
export const updateDatiRelazContrBenefMorteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE GENERICO */
/* AGGIUNGI BENEFICIARIO VITA GENERICO */
export const updateBeneficiarioMorteGenerico = (currBenefMorteGenerico, unActionType) => {
  return {
    type: unActionType,
    currBenefMorteGenerico,
  }
};

export const confermaAggiungiBeneficiarioMorteGenerico = (beneficiarioMorteGenerico) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_GENERICO,
    beneficiarioMorteGenerico,
  }
};

/* DELETE BENEFICIARIO MORTE GENERICO */
export const deleteDettaglioBenefMorteGenerico = (benefMorteGenericoDelete)  => {
  return {
    type: actionType.DELETE_BENEF_MORTE_GENERICO,
    benefMorteGenericoDelete,
  }
};

/* BENEFICIARIO MORTE GENERICO DATI GENERALI */
export const updateDatiGeneraliBenefMorteGenerico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO MORTE GENERICO DATI GENERALI MINIMI*/
export const updateDatiGeneraliMinimiBeneficiarioMorteFisico = (unDato, unActionType, unaFigura) => {
  return {
    type: unActionType,
    unDato,
    figura: unaFigura,
  }
};

/* UPDATE DATI PER BENEFICIARI MORTE */
export const updateDatiBeneficiarioMorte = (unDato, unActionType, isFiduciaria) => {
  return {
    type: unActionType,
    unDato,
    isFiduciaria
  }
};

/* DATI SOCIETARI BENEF MORTE GIURIDICO */
export const updateDatiSocietariBeneficiarioMorte = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

export const updateDatiMandatoFiduciarioBenefMorteGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

export const aggiungiTitolareAlProprioBenefMorteGiuridico = (currTitolareEff) => {
  return {
    type: actionType.AGGIUNGI_TITOLARE_BENEF_MORTE_GIURIDICO,
    currTitolareEff
  }
}
