import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import QuestionarioIvassPanel from './QuestionarioIvassPanel';
import QuestionarioSanitarioPanel from './QuestionarioSanitarioPanel';

import { COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002,
	     ADEGUATEZZA, ANAMNESTICO, SEMPLIFICATO, QUESTIONARIO_ANAMNESTICO, QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO, QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE } from '../../../utility/jsConstants';
import { showSezione } from '../../../utility/genericUtility';
import { getQuestionarioAnamnestico} from '../../../utility/questionarioUtility';

import QuestionarioSanitarioSemplificatoPanel from './QuestionarioSanitarioSemplificatoPanel';
	     
const styles = theme => ({
  root: {	  
/*	 marginTop: 200, 
	 marginLeft: 100,
	 marginRight: 100, */
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const mapStateToProps = state => {
  return {
    prodotto: state.areaProdottoReducer.prodotto,
    datiQuestionarioAdeguatezzaFisicoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico.datiQuestionarioValida,
    datiQuestionarioAdeguatezzaGiuridicoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico.datiQuestionarioValida,
    datiQuestionarioAnamnesticoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico.datiQuestionarioValida,
    datiQuestionarioAnamnesticoMalattiaGraveValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida,
//RT semaforo questionari INIZIO
//    datiQuestionarioAdeguatezzaFisicoControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico.controlloDati,
//    datiQuestionarioAdeguatezzaGiuridicoControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico.controlloDati,
//    datiQuestionarioAnamnesticoControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico.controlloDati,
//    datiQuestionarioAnamnesticoMalattiaGraveControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave.controlloDati,    
//RT semaforo questionari FINE
    datiQuestionarioAnamnesticoSemplificatoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida,
    datiGeneraliContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiGenerali,
    datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,
  //2020-02-08 PFD - START
    datiQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico,
    datiQuestionarioAnamnesticoMalattiaGrave: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave,
    datiQuestionarioAnamnesticoSemplificato: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato,
    assicurato: state.areaAssicuratoReducer.assicuratoFisico,
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    isChangedFromSemplificato: state.areaDatiQuestionarioReducer.questionario.isChangedFromSemplificato,
//    showSemplificato: state.areaDatiQuestionarioReducer.questionario.showSemplificato,
//    showOnlySemplificato: state.areaDatiQuestionarioReducer.questionario.showOnlySemplificato
  //2020-02-08 PFD - END
  };
};

class QuestionarioProposal extends React.Component {
  constructor(props) {
    super(props);
	this.state = {
	  adeguatezzaShow : showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011),
      anamnesticoShow : showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002),
      tipoQuestionarioAnam: this.getTipoQuestAnam(),
	};
  }
  
  getTipoQuestAnam(){
	  const dataNascita = (this.props.assicurato.flAssicuratoUgualeContraente === 'S') ? this.props.contraenteFisico.datiGenerali.dataNascita : this.props.assicurato.datiGenerali.dataNascita;
	  const datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
	  const datiQuestionarioAnamnestico = this.props.datiQuestionarioAnamnestico;
	  const datiQuestionarioAnamnesticoMalattiaGrave = this.props.datiQuestionarioAnamnesticoMalattiaGrave;
	  const datiQuestionarioAnamnesticoSemplificato = this.props.datiQuestionarioAnamnesticoSemplificato;
	  
	  const questionarioAnamnestico = getQuestionarioAnamnestico (dataNascita, datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoSemplificato, datiQuestionarioAnamnesticoMalattiaGrave);
	  const tipoQuestionarioAnam = questionarioAnamnestico.tipoQuestionario;
	  return tipoQuestionarioAnam;
  };
  

  getIsValida = (unQuestionario) => {
    let isValida = null;
    if (unQuestionario === ADEGUATEZZA) {
      if (this.state.adeguatezzaShow !== true) {
        isValida = true;
      } else {
        isValida = (this.props.datiGeneraliContraenteGiuridico.codiceFiscale === '') 
                          ? this.props.datiQuestionarioAdeguatezzaFisicoValida : this.props.datiQuestionarioAdeguatezzaGiuridicoValida;
      }
    } else if (unQuestionario === ANAMNESTICO) {
      if (this.state.anamnesticoShow !== true) {
        isValida = true;    	  
      } else {
        const malattiaGrave = this.props.datiPremioCapitaleAssicurato.malattiaGrave;
        const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);    
        isValida = (isQuestionarioAnamnestico) ? this.props.datiQuestionarioAnamnesticoValida : this.props.datiQuestionarioAnamnesticoMalattiaGraveValida;
      }  	
    } else if(unQuestionario === SEMPLIFICATO) {
    	//2020-02-08 PFD - START if(!this.props.showSemplificato) {
    	if (this.state.anamnesticoShow !== true) {
            isValida = true;
        } else {
        isValida = (this.props.isChangedFromSemplificato) ? true : this.props.datiQuestionarioAnamnesticoSemplificatoValida;
      }
    }
    return isValida;
  }
  


  render () {
    const { classes } = this.props;	 
    return (
      <div className={classes.root}>
        {this.state.adeguatezzaShow &&  
    	  <QuestionarioIvassPanel isValida={this.getIsValida(ADEGUATEZZA)} />
        }
        {/*2020-02-08 PFD - START
        {this.props.showSemplificato && 
        <QuestionarioSanitarioSemplificatoPanel isValida={this.getIsValida(SEMPLIFICATO)} />
        }
        {this.state.anamnesticoShow && !this.props.showOnlySemplificato &&
        <QuestionarioSanitarioPanel isValida={this.getIsValida(ANAMNESTICO)} />
        }
        */}		
	    {this.state.anamnesticoShow && 
	    	<>
	    	{ (this.state.tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO || 
	    	   this.props.datiQuestionarioAnamnesticoSemplificatoValida || this.props.isChangedFromSemplificato) &&  
	    	  !this.props.datiQuestionarioAnamnesticoValida && !this.props.datiQuestionarioAnamnesticoMalattiaGraveValida &&	
	    		<QuestionarioSanitarioSemplificatoPanel isValida={this.getIsValida(SEMPLIFICATO)} />
		    }	    	
	    	{(this.state.tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO || this.state.tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE ||
	    			this.props.isChangedFromSemplificato) && 
	    		<QuestionarioSanitarioPanel isValida={this.getIsValida(ANAMNESTICO)} />
	    	}
	    	</>
	    }        
      </div>
    );
  }
}

QuestionarioProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(QuestionarioProposal));