import store from '../store';
import { locale_it, MODALITA_PAGAMENTO_TYPE } from './jsConstants';
import { textTrim, getNumber, /*isContraenteGiuridico,*/ isTabSelezionatoContraenteGiuridico } from './genericUtility';


const TCM_INDIVIDUALE_PREMIO_ANNUO_MAX = 1000;
const TCM_INDIVIDUALE_PREMIO_UNICO_MAX = 2500;

// Per i Prodotti che sono in scope TCM_EMISSIONE verifica  che:
//-	il premio annuale eccede i 1.000 euro (se la proposta prevede il premio annuale) o il premio unico è di importo superiore a 2.500 euro (se la proposta prevede il premio unico) 
//-	almeno una delle figure anagrafiche di proposta è PEP 
//-	almeno una delle figure anagrafiche di proposta ha relazioni con Paesi ad alto rischio
export const isTcmIndividualeValida = (locale) => {
	const state = store.getState();

	let validScopeTcmEmissione = false;
	const datiPremioCapitaleAssicurato = state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato;
	// Controllo sugli importi premio
	const isPremioOk = checkPremioTcmIndividuale (datiPremioCapitaleAssicurato, locale!==undefined?locale:locale_it);
	if(isPremioOk){
		// Controllo sui PEP
		const existPep = existPepTcmIndividuale();
		if(!existPep ) {
			// Controllo coinvolgimento della Anagrafiche con i Paesi Black List
			const relazPaesiBlackList = existRelazionePaesiBlackListTcmIndividuale();
			if (!relazPaesiBlackList){
				validScopeTcmEmissione = true;
			}
		}
	}
	
	return validScopeTcmEmissione;
}

const checkPremioTcmIndividuale = (datiPremioCapitaleAssicurato, locale) => {
	let isPremioOk = true;
	const premioUnico = datiPremioCapitaleAssicurato.premioUnico;
	const premioUnicoNum =  getNumber(premioUnico, locale);
	
	if (premioUnicoNum!==''){
		if (premioUnicoNum>TCM_INDIVIDUALE_PREMIO_UNICO_MAX){
			isPremioOk=false;
		}
	} else{
		const premioAnnuo = datiPremioCapitaleAssicurato.premioAnnuo;
		const premioAnnuoNum =  getNumber(premioAnnuo, locale);
		// Se valorizzato
		if (premioAnnuoNum!==''){
			if (premioAnnuoNum>TCM_INDIVIDUALE_PREMIO_ANNUO_MAX){
				isPremioOk=false;
			}
		} else {			
			const premioRata = datiPremioCapitaleAssicurato.premioRata;
			const premioRataNum =  getNumber(premioRata, locale);
			const frazionamento = datiPremioCapitaleAssicurato.frazionamento;
						
			if (premioRataNum!==''){
				if (textTrim(frazionamento)!=='') {
					// calcolo i premio Annuo: premioRata * Frazionamento				 
					const premioAnnuoCalc = frazionamento * premioRataNum;
					if (premioAnnuoCalc>TCM_INDIVIDUALE_PREMIO_ANNUO_MAX){
						isPremioOk=false;
					}
				} else {
					const premioAnnuo = premioRataNum;
					if (premioAnnuo>TCM_INDIVIDUALE_PREMIO_ANNUO_MAX){
						isPremioOk=false;
					}

				}
			}
		}
	}
	return isPremioOk;
}

const existPepTcmIndividuale = () => {
	let existPep = false;
	const state = store.getState();
	const pepContraente = textTrim(state.areaContraenteReducer.contraenteFisico.datiAntiriciclaggio.espostaPoliticamente, true);
	
	if (pepContraente==='S'){
		existPep = true;
	} 
	
	if (existPep===false){// Controllo PEP sui Beneficiari Vita
		const benefVitaFisicoList = state.areaBenefVitaReducer.benefVitaFisicoList;
		const pepBenefVita = existPepBenefList(benefVitaFisicoList);
		if (pepBenefVita!==null){
			existPep = true;
		}
	}
	
	if (existPep===false){// Controllo PEP sui Beneficiari Morte
		const benefMorteFisicoList = state.areaBenefMorteReducer.benefMorteFisicoList;
		const pepBenefMorte = existPepBenefList(benefMorteFisicoList);
		if (pepBenefMorte!==null){
			existPep = true;
		} 
	}
		
	if (existPep===false){// Controllo PEP sul Rapp Legale
		const pepRappLegale = textTrim(state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiAntiriciclaggio.espostaPoliticamente, true);
		if (pepRappLegale==='S'){
			existPep = true;
		} 
	}
	
	if (existPep===false){// Controllo PEP sui Titolari Effettivi		
		const titolareEffList = state.areaTitolareEffReducer.titolareEffList;
		const pepTitEff = existPepTitEffList(titolareEffList);
		if (pepTitEff!==null){
			existPep = true;
		} 
	}
	return existPep;
}

const existPepBenefList = (benefList) => {
	let pepBenef = null;
	if (benefList.length>0){
		pepBenef = benefList.filter(item => {
			if(item.datiAntiriciclaggio.espostaPoliticamente === 'S') {
	            return item;
	        }
	    });
	}
	return pepBenef===null||pepBenef.length===0?null:pepBenef;
}

const existPepTitEffList = (titEffList) => {
	let pepTitEff = null;
	if (titEffList.length>0){
		pepTitEff = titEffList.filter(item => {
			if(item.datiTitolareEff.datiAntiriciclaggio.espostaPoliticamente === 'S') {
	            return item;
	        }
	    });
	}
	return pepTitEff===null||pepTitEff.length===0?null:pepTitEff ;
}


const existRelazionePaesiBlackListTcmIndividuale = () => {
	let existRelBlackList = false;
	const state = store.getState();
	const contraenteGiuridico = state.areaContraenteReducer.contraenteGiuridico;
	
	// Contraente
	//2021-10-25 PFD: sostituito  isContraenteGiuridico con isTabSelezionatoContraenteGiuridico
	//const isContrPG = isContraenteGiuridico(contraenteGiuridico);
	const isContrPG = isTabSelezionatoContraenteGiuridico();
	if (isContrPG){
		// Contraente Giuridico
		
		/* //2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List - 
		 * il flag flPaesiBlackList non indica + se si hanno rapporti con i paesi in Black List.
		 * Il valore di una delle tre combo relazPaesiList potrebbe contenere un paesi in black List 
		if(contraenteGiuridico.datiAttEconomicaGiuridico.flPaesiBlackList==='S'){
			existRelBlackList = true;
		}*/
		
		if (!existRelBlackList){
			// Dati del Contraente Giuridico
			const contraenteGiuridico = state.areaContraenteReducer.contraenteGiuridico;
			existRelBlackList = existRelazionePaesiBlackList(contraenteGiuridico);
		}
		if (!existRelBlackList){
			// Rappresentante Legale
			const rappLegale = state.areaContraenteReducer.contraenteGiuridico.datiRappLegale;
			existRelBlackList = existRelazionePaesiBlackList(rappLegale);
		}
		if (!existRelBlackList){
			// Titolare Effettivi del Contraente Giuridico
			const titolareEffList = state.areaTitolareEffReducer.titolareEffList;
			if(titolareEffList.length>0){
				for(let i=0; !existRelBlackList && i<titolareEffList.length;i++){
					existRelBlackList = existRelazionePaesiBlackList(titolareEffList[i].datiTitolareEff);
					if (!existRelBlackList){
						// Controllo Cittadinanza Titolare Effettivo
						existRelBlackList = existRelazioneCittadinanzaBlackListPersonaFisica(titolareEffList[i].datiTitolareEff);	
					}
				}
			}
		}
		
	} else {
		const contraenteFisico = state.areaContraenteReducer.contraenteFisico;
		// Contraente Fisico
		existRelBlackList = existRelazionePaesiBlackList(contraenteFisico);	
		if (!existRelBlackList){
			// Controllo Cittadinanza Contraente
			existRelBlackList = existRelazioneCittadinanzaBlackListPersonaFisica(contraenteFisico);	
		}
		
		if (!existRelBlackList){
			// Soggetto Terzo
			const esecutore = state.areaContraenteReducer.contraenteFisico.datiEsecutore;
			existRelBlackList = existRelazionePaesiBlackList(esecutore);
		}
		
		
	}
	
	if(!existRelBlackList) {
		// Assicurato 
		const assicuratoFisico = state.areaAssicuratoReducer.assicuratoFisico;
		existRelBlackList = existRelazionePaesiBlackList(assicuratoFisico);
		if (!existRelBlackList){
			// Controllo Cittadinanza Assicurato
			existRelBlackList = existRelazioneCittadinanzaBlackListPersonaFisica(assicuratoFisico);	
		}

	}
		
	if(!existRelBlackList) {
		// Beneficiario Vita Fisico
		const benefVitaFisicoList = state.areaBenefVitaReducer.benefVitaFisicoList;
		if(benefVitaFisicoList.length>0){
			for(let i=0; !existRelBlackList && i<benefVitaFisicoList.length;i++){
				existRelBlackList = existRelazionePaesiBlackList(benefVitaFisicoList[i]);	
			}			
		}		
		// Beneficiario Vita Giuridico
		const benefVitaGiuridicoList = state.areaBenefVitaReducer.benefVitaGiuridicoList;
		if(benefVitaGiuridicoList.length>0){
			for(let i=0; !existRelBlackList && i<benefVitaGiuridicoList.length;i++){
				existRelBlackList = existRelazionePaesiBlackList(benefVitaGiuridicoList[i]);
				if (!existRelBlackList) {
					// Titolare Eff del Beneficiario Vita Giuridico
					const titolareEffBenefVitaList = benefVitaGiuridicoList[i].titolareEffBenefVitaList;
					for(let j=0; !existRelBlackList && j<titolareEffBenefVitaList.length;j++){
						existRelBlackList = existRelazionePaesiBlackList(titolareEffBenefVitaList[j].datiTitolareBenefVitaEff);
					}
				}
			}			
		}
	}
	
	if(!existRelBlackList) {
		// Beneficiario Morte Fisico
		const benefMorteFisicoList = state.areaBenefMorteReducer.benefMorteFisicoList;
		if(benefMorteFisicoList.length>0){
			for(let i=0; !existRelBlackList && i<benefMorteFisicoList.length;i++){
				existRelBlackList = existRelazionePaesiBlackList(benefMorteFisicoList[i]);				
			}
		}
		
		// Beneficiario Morte Giuridico
		const benefMorteGiuridicoList = state.areaBenefMorteReducer.benefMorteGiuridicoList;
		if(benefMorteGiuridicoList.length>0){
			for(let i=0; !existRelBlackList && i<benefMorteGiuridicoList.length;i++){
				existRelBlackList = existRelazionePaesiBlackList(benefMorteGiuridicoList[i]);
				if (!existRelBlackList) {
					// Titolare Eff del Beneficiario Morte Giuridico
					const titolareEffBenefMorteList = benefMorteGiuridicoList[i].titolareEffBenefMorteList;
					for(let j=0; !existRelBlackList && j<titolareEffBenefMorteList.length;j++){
						existRelBlackList = existRelazionePaesiBlackList(titolareEffBenefMorteList[j].datiTitolareBenefMorteEff);
					}
				}
			}			
		}
	}
	
	if(!existRelBlackList) {
		// Referente Terzo
		const referenteTerzoFisico = state.areaReferenteTerzoReducer.referenteTerzoFisico;
		existRelBlackList = existRelazionePaesiBlackList(referenteTerzoFisico);
	}
	
	return existRelBlackList;
}

const existRelazionePaesiBlackList = (figura) => {
	const state = store.getState();
	const paesiBlackList = state.areaDatiDominioReducer.dominio.paesiBlackListItems;
	const NOT_FOUND = -1;
	
	let existRelBlackList = NOT_FOUND;
	if (figura!==null && paesiBlackList!==undefined){
		const datiResidenza = figura.datiResidenza;
		if (existRelBlackList===NOT_FOUND && datiResidenza!==undefined) {
			const statoResidenza = datiResidenza.statoResidenza;
			if(textTrim(statoResidenza, true)!==''){
				existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoResidenza);
			}
		}
		const datiRecapito = figura.datiRecapito;
		if (existRelBlackList===NOT_FOUND && datiRecapito!==undefined) {
			const statoRecapito = datiRecapito.statoRecapito;
			if(textTrim(statoRecapito, true)!==''){
				existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoRecapito);
			}
		}
		const datiDomicilio = figura.datiDomicilio;
		if (existRelBlackList===NOT_FOUND && datiDomicilio!==undefined) {
			const statoDomicilio = datiDomicilio.statoDomicilio;
			if(textTrim(statoDomicilio, true)!==''){
				existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoDomicilio);
			}
		}
		const datiAttEconomica = figura.datiAttEconomica;
		if (existRelBlackList===NOT_FOUND && datiAttEconomica!==undefined) {
			const statoAttPrevalSvolta = datiAttEconomica.statoAttPrevalSvolta;
			if(textTrim(statoAttPrevalSvolta, true)!==''){
				existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoAttPrevalSvolta);
			}
		}		
		const datiAttEconomicaGiuridico = figura.datiAttEconomicaGiuridico;
		if (existRelBlackList===NOT_FOUND && datiAttEconomicaGiuridico!==undefined) {
			const statoAttPrevalSvolta = datiAttEconomicaGiuridico.statoAttPrevalSvolta;
			if(textTrim(statoAttPrevalSvolta, true)!==''){
				existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoAttPrevalSvolta);
			}
			
			//2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List - check relazPaesiList solo per ContraenteGiuridico
			if (existRelBlackList===NOT_FOUND && datiAttEconomicaGiuridico!==undefined) {
				if(datiAttEconomicaGiuridico.flPaesiBlackList==='S'){
					// verifico se tra i paesi selezionati ve ne siano alcuni in black list
					datiAttEconomicaGiuridico.relazPaesiList.forEach((item, index) => {
						if(existRelBlackList===NOT_FOUND) {
							const statoRelazPaese = item.value;
							existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoRelazPaese);
						}
					})
					
				}
			}
		}		
		const datiFiscali = figura.datiFiscali;
		if (existRelBlackList===NOT_FOUND && datiFiscali!==undefined) {
			const statoResFiscale1 = datiFiscali.statoResFiscale1;
			if(textTrim(statoResFiscale1, true)!==''){
				existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoResFiscale1);
			}
			if (existRelBlackList===NOT_FOUND) {
				const statoResFiscale2= datiFiscali.statoResFiscale2;
				if(textTrim(statoResFiscale2, true)!==''){
					existRelBlackList = paesiBlackList.findIndex(paesiBlackList => paesiBlackList.value === statoResFiscale2);
				}
			}
		}
	}
	return existRelBlackList!==NOT_FOUND?true:false;
}

const existRelazioneCittadinanzaBlackListPersonaFisica = (figura) => {
	const state = store.getState();
	const cittadinanzaPaesiBlackListItems = state.areaDatiDominioReducer.dominio.cittadinanzaPaesiBlackListItems;
	const NOT_FOUND = -1;
	
	let existRelBlackList = NOT_FOUND;
	if (figura!==null && cittadinanzaPaesiBlackListItems!==undefined){
		const datiGenerali = figura.datiGenerali;
		if (datiGenerali!==undefined) {
			const cittadinanza1 = datiGenerali.cittadinanza1;
			if(textTrim(cittadinanza1, true)!==''){
				existRelBlackList = cittadinanzaPaesiBlackListItems.findIndex(cittadinanzaPaesiBlack => cittadinanzaPaesiBlack.value === cittadinanza1);
			}
			if (existRelBlackList===NOT_FOUND) {
				const cittadinanza2= datiGenerali.cittadinanza2;
				if(textTrim(cittadinanza2, true)!==''){
					existRelBlackList = cittadinanzaPaesiBlackListItems.findIndex(cittadinanzaPaesiBlack => cittadinanzaPaesiBlack.value === cittadinanza2);
				}
			}
		}
		
	
	}
	return existRelBlackList!==NOT_FOUND?true:false;
}


//2021-07-01 PFD: TCM_INDIV
//Per i Prodotti in scope TCM_EMISSIONE per i quali non sono soddisfatte le condizioni di validita'
//sul Pemio/PEP Anagrafiche/Relazioni Paesi Black List diventano obbligatori tutti i campi 
//controllati nella seguente funzione checkMandatoryFieldsTcmIndividuale.
export const checkMandatoryFieldsTcmIndividuale = () => {
	// check campi anagrafici
	let isOk = checkMandatoryFieldsTcmIndividualeAnagrafica();
	if (isOk) {
	  // check campi mod pagamento
	  isOk = checkMandatoryFieldsTcmIndividualeModPagam();
	} 
	return isOk;
}

const checkMandatoryFieldsTcmIndividualeAnagrafica = () => {
	let isOk = true;
	let datiNaturaRapporto = null;
	  
	const state = store.getState();
	//Contraente
	const contraenteGiuridico = state.areaContraenteReducer.contraenteGiuridico;
	const contraenteFisico = state.areaContraenteReducer.contraenteFisico;
	  
	//2021-10-25 PFD: sostituito  isContraenteGiuridico con isTabSelezionatoContraenteGiuridico
	//const isContrPG = isContraenteGiuridico(contraenteGiuridico);
	const isContrPG = isTabSelezionatoContraenteGiuridico();
	
	if (isContrPG) {
	  //Dati Finanziari
	  const datiFinanziari = contraenteGiuridico.datiFinanziari;
	  if (datiFinanziari.codFatturato==='' || datiFinanziari.codPatrimonioFinanziario==='') {
		  isOk = false;		
	  }
	  // Dati Rapp Legale	  
	  const datiRappLegale = contraenteGiuridico.datiRappLegale;
	  const datiAttEconomicaRappLegale = datiRappLegale.datiAttEconomica;
	  if(datiAttEconomicaRappLegale.codAttPrevalSvolta==='' || 
		 datiAttEconomicaRappLegale.statoAttPrevalSvolta==='' || 
		 datiAttEconomicaRappLegale.provinciaAttPrevalSvolta==='' ) {
		  isOk = false;
	  }
		  
	  datiNaturaRapporto = contraenteGiuridico.datiNaturaRapporto;		  
	} else {
	  //Dati Finanziari
	  const datiFinanziari = contraenteFisico.datiFinanziari;
	  if (datiFinanziari.codRedditoAnnuo==='' || datiFinanziari.codPatrimonioFinanziario==='') {
		isOk = false;		
	  }
		  
	  datiNaturaRapporto = contraenteFisico.datiNaturaRapporto;
	}
	
	// Dati Scopo e Natura Rapporto - Valido sia per Contraente PF sia PG
	const countChecked = datiNaturaRapporto.origineFondiList.filter((item) => item.checked);
	if (countChecked.length===0){
	  isOk = false;
	}
	if (datiNaturaRapporto.codTempoProvvista==='') {
	  isOk = false;
	}
	  
	return isOk;
}

const checkMandatoryFieldsTcmIndividualeModPagam = () => {
	let isOk = true;
	const state = store.getState();
	
	const datiModalitaPagamento = state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento;
	const modalitaPagamento = datiModalitaPagamento.modalitaPagamento;
	if (modalitaPagamento===MODALITA_PAGAMENTO_TYPE.ASSEGNO_BANCARIO ||
		modalitaPagamento===MODALITA_PAGAMENTO_TYPE.ASSEGNO_CIRCOLARE) {
		const datiModPagAssegno = state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModPagAssegno
		if (datiModPagAssegno.nomeCognomeCointestatario!==''){
			isOk=false;
		}
		if (datiModPagAssegno.relazContrCoint!==''){
			isOk=false;
		}
	} else if (modalitaPagamento===MODALITA_PAGAMENTO_TYPE.BONIFICO_ITALIA) {
		const datiModPagBonifico = state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonifico
		if (datiModPagBonifico.nomeCognomeCointestatario!==''){
			isOk=false;
		}
		if (datiModPagBonifico.relazContrCoint!==''){
			isOk=false;
		}
	} else if (modalitaPagamento===MODALITA_PAGAMENTO_TYPE.BONIFICO_ESTERO) {
		const datiModPagBonificoEstero = state.areaDatiModPagBonificoEsteroReducer.caratteristicheContratto.datiModPagBonificoEstero
		if (datiModPagBonificoEstero.nomeCognomeCointestatario!==''){
			isOk=false;
		}
		if (datiModPagBonificoEstero.relazContrCoint!==''){
			isOk=false;
		}
	}
	
	return isOk;
}