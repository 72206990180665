import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import ButtonComp from '../../common/ButtonComp';
import TextFieldAdornComp from '../../common/TextFieldAdornComp';
import TextFieldComp from '../../common/TextFieldComp';
import DatePickerComp from '../../common/DatePickerComp';
import Typography from '@material-ui/core/Typography';
import { uris, fetchParam } from '../../../uris';
import moment from 'moment';
import 'moment/locale/it';

import * as actions from './action/datiModificaUtenza';
import * as actionType from './actionType/datiModificaUtenza';

import { STATO_UTENTE_PENDENTE, STATO_UTENTE_ATTIVA, STATO_UTENTE_REVOCATA, STATO_UTENTE_BLOCCATA, STATO_UTENTE_SCADUTA } from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  button: theme.button,
  dateFieldWidth: {
    width: 270,
  },
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	loginUser: state.areaProdottoReducer.loginUser,
	datiModificaUtenza: state.areaDatiModificaUtenzaReducer.gestioneUtenza.datiModificaUtenza,
	datiRicercaUtenza: state.areaDatiRicercaUtenzaReducer.gestioneUtenza.datiRicercaUtenza,
  };
};
	
//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  return {
	updateDatiModificaUtenza: (unDato, unActionType) => dispatch(actions.updateDatiModificaUtenza(unDato, unActionType)),
	salvaDatiModificaUtenza: (controlloDati) => dispatch(actions.salvaDatiModificaUtenza(controlloDati)),
	riattivaDatiModificaUtenza: (dataRevoca, statoUtenza, dataUltimaVariazione) => dispatch(actions.riattivaDatiModificaUtenza(dataRevoca, statoUtenza, dataUltimaVariazione)),
	revocaDatiModificaUtenza: (dataRevoca, statoUtenza, dataUltimaVariazione) => dispatch(actions.revocaDatiModificaUtenza(dataRevoca, statoUtenza, dataUltimaVariazione)),
	sbloccaDatiModificaUtenza: (dataSblocco, statoUtenza, dataUltimaVariazione) => dispatch(actions.sbloccaDatiModificaUtenza(dataSblocco, statoUtenza, dataUltimaVariazione)),
	modificaDatiModificaUtenza: (email, dataUltimaVariazione) => dispatch(actions.modificaDatiModificaUtenza(email, dataUltimaVariazione)),
  };
};

class DatiModificaUtenza extends React.Component {
  constructor(props) {
    super(props);
    const datiModificaUtenza = this.props.datiModificaUtenza;
    this.state = {
      mandato: datiModificaUtenza.mandato,
      descMandato:  datiModificaUtenza.descMandato,
      cognome: datiModificaUtenza.cognome,
	  nome: datiModificaUtenza.nome,
	  utente: datiModificaUtenza.utente,
	  profilo: datiModificaUtenza.profilo,
      descProfilo: datiModificaUtenza.descProfilo,
      email: datiModificaUtenza.email,
      dataCreazione: datiModificaUtenza.dataCreazione, 
      dataScadenza: datiModificaUtenza.dataScadenza,
      dataBlocco: datiModificaUtenza.dataBlocco,
      dataRevoca: datiModificaUtenza.dataRevoca,
      statoUtenza: datiModificaUtenza.statoUtenza,
      dataUltimaVariazione: datiModificaUtenza.dataUltimaVariazione,
      esito: '',
      codiceErrore: '',
    };
  };
    
  componentDidMount() {
	const dettaglioUtente = this.props.datiRicercaUtenza.dettaglioUtente;
	this.props.updateDatiModificaUtenza(dettaglioUtente.utente, actionType.UPDATE_UTENTE_MODIFICA);
    this.props.updateDatiModificaUtenza(dettaglioUtente.email, actionType.UPDATE_EMAIL_MODIFICA);
    this.props.updateDatiModificaUtenza(dettaglioUtente.dataBlocco, actionType.UPDATE_DATA_BLOCCO_MODIFICA);
    this.props.updateDatiModificaUtenza(dettaglioUtente.dataRevoca, actionType.UPDATE_DATA_REVOCA_MODIFICA);
    this.props.updateDatiModificaUtenza(dettaglioUtente.dataUltimaVariazione, actionType.UPDATE_DATA_ULTIMA_VARIAZIONE_MODIFICA);
    this.props.updateDatiModificaUtenza(dettaglioUtente.statoUtenza, actionType.UPDATE_STATO_UTENZA_MODIFICA);
    this.setState({
      utente: dettaglioUtente.utente,
      email: dettaglioUtente.email,
      dataBlocco: dettaglioUtente.dataBlocco,
      dataRevoca: dettaglioUtente.dataRevoca,
      dataUltimaVariazione: dettaglioUtente.dataUltimaVariazione,
      statoUtenza: dettaglioUtente.statoUtenza,
    });
    
    let mand = {
	  value: this.props.loginUser.mandato.trim(),
	  label: this.props.loginUser.descrMandato.trim(),
	};
	this.props.updateDatiModificaUtenza(mand, actionType.UPDATE_MANDATO_MODIFICA);
	this.setState({
	  mandato: this.props.loginUser.mandato.trim(),
	  descMandato: this.props.loginUser.descrMandato.trim(),
    });
	
	let prof = {
	  value: '',
	  label: '',
	};
	this.props.dominio.profiloItems.forEach(function(item, index, array) {
	  if(item.value === textTrim(dettaglioUtente.profilo, true)){
		prof = {
		  value: item.value,
		  label: item.label,
		};
	  }
	});
	this.props.updateDatiModificaUtenza(prof, actionType.UPDATE_PROFILO_MODIFICA);
    this.setState({
	  profilo: prof.value,
	  descProfilo: prof.label,
	});
  }
  
  handleChangeConfermaDatiModificaUtenza = event => {
    this.props.salvaDatiModificaUtenza(true, actionType.SALVA_DATI_MODIFICA_UTENZA);
  }  
  
  handleChangeRiattivaDatiModificaUtenza = event => {
	this.setRiattivaUtente();
  }  
  
  setRiattivaUtente = () => {
    const opts = {
	  user: this.props.datiModificaUtenza.utente,
	};
	fetch(uris.riattivaSbloccaUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const esito = (result.user !== '') ? 'OK': 'KO';  
	  const dataUltimaVariazione = moment(result.dataUltimaVariazione, "YYYY-MM-DD");
	  this.props.riattivaDatiModificaUtenza(null, result.stato, dataUltimaVariazione);
	  this.setState({
		dataRevoca: null,
		dataUltimaVariazione: dataUltimaVariazione,
		statoUtenza: result.stato,
	    esito: esito,
	    codiceErrore: '',
	  });
	});
  }
  
  handleChangeRevocaDatiModificaUtenza = event => {
	this.setRevocaUtente();
  }  
  
  setRevocaUtente = () => {
    const opts = {
	  user: this.props.datiModificaUtenza.utente,
	};
	fetch(uris.revocaUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const esito = (result.user !== '') ? 'OK': 'KO';  
	  const dataUltimaVariazione = moment(result.dataUltimaVariazione, "YYYY-MM-DD");
	  const dataRevoca = moment(result.dataInizio, "YYYY-MM-DD");
	  this.props.revocaDatiModificaUtenza(dataRevoca, result.stato, dataUltimaVariazione);
	  this.setState({
		dataRevoca: dataRevoca,
		dataUltimaVariazione: dataUltimaVariazione,
		statoUtenza: result.stato,
	    esito: esito,
	    codiceErrore: '',
	  });
	});
  }
  
  handleChangeSbloccaDatiModificaUtenza = event => {
	this.setSbloccaUtente();
  }  
  
  setSbloccaUtente = () => {
    const opts = {
	  user: this.props.datiModificaUtenza.utente,
	};
	fetch(uris.riattivaSbloccaUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const esito = (result.user !== '') ? 'OK': 'KO';  
	  const dataUltimaVariazione = moment(result.dataUltimaVariazione, "YYYY-MM-DD");
	  this.props.sbloccaDatiModificaUtenza(null, result.stato, dataUltimaVariazione);
	  this.setState({
		dataBlocco: null,
		dataUltimaVariazione: dataUltimaVariazione,
		statoUtenza: result.stato,
	    esito: esito,
	    codiceErrore: '',
	  });
	});
  }
  
  handleChangeModificaDatiModificaUtenza = event => {
    this.props.salvaDatiModificaUtenza(true, actionType.SALVA_DATI_MODIFICA_UTENZA);
	if(this.state.email.trim() !== ''){
	  this.setModificaUtente();
	}
  }  
  
  setModificaUtente = () => {
    const opts = {
	  user: this.props.datiModificaUtenza.utente,
	  email: this.props.datiModificaUtenza.email.trim(),
	};
	fetch(uris.modificaUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  let esito = '';  
	  const codiceErrore = (result.codiceErrore===undefined || result.codiceErrore===null) ? 'OK' : result.codiceErrore.trim();  
	  if(codiceErrore==='OK'){
	    esito = 'OK';
	  }else{
		esito = 'KO';
	  }
	  if(codiceErrore==='OK'){
	    const dataUltimaVariazione = moment(result.dataUltimaVariazione, "YYYY-MM-DD");
		this.props.modificaDatiModificaUtenza(result.email, dataUltimaVariazione);
		this.setState({
		  email: result.email,
		  dataUltimaVariazione: dataUltimaVariazione,
		  esito: esito,
		  codiceErrore: codiceErrore,
		});
	  }else{
		this.setState({
	      esito: esito,
		  codiceErrore: codiceErrore,
		});
	  }
	});
  }
  
  handleChangeTornaRicercaDatiModificaUtenza = event => {
    if(this.props.mostraDettaglio) {
	  this.props.mostraDettaglio(event, "");
	}
  }  
  	  
  handleChangeEmail = event => {
	const nuovoEmail = textTrim(event.target.value, true);
	const vecchioEmail = textTrim(this.state.email, true);
	if (nuovoEmail !== vecchioEmail) {
      this.props.updateDatiModificaUtenza(event.target.value, actionType.UPDATE_EMAIL_MODIFICA);   
      this.setState({ 
        email: event.target.value, 
      });
	}
  };
	   
  isRequiredEmail = () => {
    return true;
  }
   
  render() {
    const { classes, dominio, datiModificaUtenza, datiRicercaUtenza } = this.props;  
    const controlloDati = datiModificaUtenza.controlloDati;
    const { t } = this.props; //hoc
    
    return (
     <Fragment>
       <Grid container direction='row' justify='center' alignItems='flex-start'>
         <Grid item xs={2}></Grid>
         <Grid item xs={8}>
	       <Grid container direction='row' justify='center' alignItems='flex-start'>
	         <Grid item xs={12}>
			   <TextFieldComp aStyle={classes.textFieldWidthPerc} id='mandato' label='mandato' value={datiModificaUtenza.descMandato} readonly={true} /> 
	         </Grid>  
	         <Grid item md={6} xs={12}>
	           <TextFieldComp aStyle={classes.textFieldWidthPerc} id='cognome' label='general.cognome' value={datiRicercaUtenza.dettaglioUtente.cognome} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp aStyle={classes.textFieldWidthPerc} id='nome' label='general.nome' value={datiRicercaUtenza.dettaglioUtente.nome} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp aStyle={classes.textFieldWidthPerc} id='utente' label='utente' value={datiModificaUtenza.utente} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp aStyle={classes.textFieldWidthPerc} id='profilo' label='profilo' value={datiModificaUtenza.descProfilo} readonly={true} /> 
	         </Grid>
	         <Grid item xs={12}>	
	           <TextFieldAdornComp onBlur={this.handleChangeEmail} id='email' label='indEmail' value={datiModificaUtenza.email} aStyle={classes.textFieldWidthPerc} margin='normal' variant='outlined' 
	             adornComp={ <AlternateEmail />} adornPosit='end' required={this.isRequiredEmail()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} id='dataCreazione' label='dataCreazione' 
	             value={datiRicercaUtenza.dettaglioUtente.dataCreazione} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           {datiModificaUtenza.statoUtenza !== STATO_UTENTE_PENDENTE &&
	             <DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} id='dataScadenza' label='dataScadenza' 
	               value={datiRicercaUtenza.dettaglioUtente.dataScadenza} readonly={true} />
	           }
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} id='dataBlocco' label='dataBlocco' 
	             value={datiModificaUtenza.dataBlocco} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} id='dataRevoca' label='dataRevoca' 
	             value={datiModificaUtenza.dataRevoca} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp aStyle={classes.textFieldWidthPerc} id='statoUtenza' label='statoUtenza' value={t('statoUtente'+datiModificaUtenza.statoUtenza)} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} 
	             id='dataUltimaVariazione' label='dataUltimaVariazione' value={datiModificaUtenza.dataUltimaVariazione} readonly={true} />
	         </Grid>
	         <Grid item xs={12}>
		       <Grid container direction="row" justify="center" alignItems="center">	
		         <Grid item>
		           {this.state.esito==='OK' &&
		    	     <Typography className={classes.textErrorMessage}>{t('salvaOk')}</Typography>
		           }
		           {(this.state.esito==='KO' && this.state.codiceErrore==='') &&
		    	     <Typography className={classes.textErrorMessage}>{t('salvaKo')}</Typography>
		           }
		           {(this.state.esito==='KO' && this.state.codiceErrore!=='') &&
		    	     <Typography className={classes.textErrorMessage}>{t(this.state.codiceErrore)}</Typography>
		           }
		         </Grid>
		       </Grid>
		     </Grid>
	         {datiModificaUtenza.statoUtenza === STATO_UTENTE_ATTIVA &&
	           <Grid item xs={12}>
		         <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
		           <Grid item xs={2}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'tornaRicerca'}
	                   onClick={this.handleChangeTornaRicercaDatiModificaUtenza}
	                  />
	               </Grid>
	               <Grid item xs={1}></Grid>
	               <Grid item xs={3}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'revoca'}
	                   onClick={this.handleChangeRevocaDatiModificaUtenza}
	                 />
	               </Grid>
	               <Grid item xs={3}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'modifica'}
	                   onClick={this.handleChangeModificaDatiModificaUtenza}
	                  />
	               </Grid>
	               <Grid item xs={3}></Grid>
	             </Grid>
	           </Grid>
	         }
	         {datiModificaUtenza.statoUtenza === STATO_UTENTE_BLOCCATA &&
	           <Grid item xs={12}>
	             <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
	               <Grid item xs={2}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'tornaRicerca'}
	                   onClick={this.handleChangeTornaRicercaDatiModificaUtenza}
	                  />
	               </Grid>
	               <Grid item xs={3}></Grid>
	               <Grid item xs={2}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'sblocca'}
	                   onClick={this.handleChangeSbloccaDatiModificaUtenza}
	                 />
	               </Grid>
	               <Grid item xs={5}></Grid>
	             </Grid>
	           </Grid>
	         }
	         {datiModificaUtenza.statoUtenza === STATO_UTENTE_REVOCATA &&
	           <Grid item xs={12}>
	             <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
		           <Grid item xs={2}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'tornaRicerca'}
	                   onClick={this.handleChangeTornaRicercaDatiModificaUtenza}
	                  />
	               </Grid>
	               <Grid item xs={3}></Grid>
	               <Grid item xs={2}>
	                 <ButtonComp
	                   aClassName={classes.button}
	                   buttonLabel={'riattiva'}
	                   onClick={this.handleChangeRiattivaDatiModificaUtenza}
	                 />
	               </Grid>
	               <Grid item xs={5}></Grid>
	             </Grid>
	           </Grid>
	         }
	         {datiModificaUtenza.statoUtenza === STATO_UTENTE_PENDENTE &&
	           <Grid item xs={12}>
                 <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
                   <Grid item xs={5}></Grid>
                   <Grid item xs={2}>
                     <ButtonComp
                       aClassName={classes.button}
                       buttonLabel={'tornaRicerca'}
	                   onClick={this.handleChangeTornaRicercaDatiModificaUtenza}
                     />
                   </Grid>
                   <Grid item xs={5}></Grid>
                 </Grid>
               </Grid>
	         }
	        </Grid>
	      </Grid>
		  <Grid item xs={2}></Grid>
		</Grid>
      </Fragment>	  
    );
  }
}

DatiModificaUtenza.propTypes = {
  classes: PropTypes.object.isRequired, 
  dominio: PropTypes.object.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiModificaUtenza)));

