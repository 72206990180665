import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteForever from '@material-ui/icons/DeleteForever';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import { TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA, TITOLARE_EFFETTIVO } from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableWrapper: {
    width: '90%',
    margin: 'auto',
    padding: 24, // aggiunto successivamente
  },
  textField: theme.textField,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  row: {
    cursor: 'pointer',
  }
});


const CustomTableCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(1.6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  head: {
    backgroundColor: theme.palette.color.greyLight,
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginBottom: theme.spacing(1),
  },
  body: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginBottom: theme.spacing(3),
    color: 'inherit',
  },

}))(TableCell);


function createDataRiepilogoList(riepilogoList, figura) {
  let riepilogoArray = [];
  for (let index = 0; index < riepilogoList.length; index++) {
    const item = riepilogoList[index];
    console.log('Item CreateDataRiepilogo', item);
    let datiResidenza = null;
    let datiGenerali = null;
    let percOwnerShip = null;
    if (figura === TITOLARE_EFFETTIVO) {
      datiResidenza = item.datiTitolareEff.datiResidenza;
      datiGenerali = item.datiTitolareEff.datiGenerali;
      percOwnerShip = item.datiTitolareEff.percOwnerShip;
      riepilogoArray[index] = creaDatiRiepilogo(index, datiGenerali, datiResidenza, percOwnerShip, figura);
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiGenerali = (item.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale) ? item.datiTitolareBenefVitaEff.datiGeneraliMinimi : item.datiTitolareBenefVitaEff.datiGenerali;
      riepilogoArray[index] = creaDatiRiepilogo(index, datiGenerali, null, null, figura);
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiGenerali = (item.datiTitolareBenefMorteEff.datiGeneraliMinimi.codiceFiscale) ? item.datiTitolareBenefMorteEff.datiGeneraliMinimi : item.datiTitolareBenefMorteEff.datiGenerali;
      riepilogoArray[index] = creaDatiRiepilogo(index, datiGenerali, null, null, figura);
    }
  }
  return riepilogoArray;
}


function  creaDatiRiepilogo (id, datiGenerali, datiResidenza, percOwnerShip, figura){
  let cognome = undefined;
  let nome = undefined;
  let codiceFiscale = undefined;
  if(figura === TITOLARE_EFF_BENEF_VITA){
  cognome = datiGenerali.cognome;
  nome = datiGenerali.nome;
  codiceFiscale = datiGenerali.codiceFiscale;
  }else if(figura === TITOLARE_EFF_BENEF_MORTE){
  cognome = datiGenerali.cognome;
  nome = datiGenerali.nome;
  codiceFiscale = datiGenerali.codiceFiscale;
  } else if(figura === TITOLARE_EFFETTIVO){
  cognome = datiGenerali.cognome;
  nome = datiGenerali.nome;
  codiceFiscale = datiGenerali.codiceFiscale;
  }
  let nominativo = (cognome !== undefined ? cognome + " " : "") + (nome !== undefined ? nome : "");
  return { id, nominativo, codiceFiscale, percOwnerShip };
}


const mapStateToProps = state => {
  console.log('#RTCFA TabDatiRiepilogoTitolareEff.mapStateToProps.state', state);
  return {
    titolareEffList: state.areaTitolareEffReducer.titolareEffList,
    titolareEffBenefMorteList: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorteList,
    titolareEffBenefVitaList: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVitaList,
  };
};
const mapDispatchToProps = dispatch => {
  //  console.log('#RTCFA TabDatiRiepilogoTitolareEff.mapDispatchToProps.dispatch', dispatch);
  return {
    caricaDettaglioTitolareEff: (selectedIndex) => dispatch(actionsTitolareEff.caricaDettaglioTitolareEff(selectedIndex)),
    updateDatiTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiTitolareEff(unDato, unActionType)),
    deleteTitolareEff: (titolareEff, figura) => dispatch(actionsTitolareEff.deleteTitolareEff(titolareEff, figura)),
    caricaDettaglioTitolareEffBenefMorte: (selectedIndex) => dispatch(actionsTitolareEff.caricaDettaglioTitolareEffBenefMorte(selectedIndex)),
    updateDatiTitolareEffBenefMorte: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiTitolareEffBenefMorte(unDato, unActionType)),
    deleteTitolareEffBenefMorte: (titolareEff, figura) => dispatch(actionsTitolareEff.deleteTitolareEffBenefMorte(titolareEff, figura)),
    caricaDettaglioTitolareEffBenefVita: (selectedIndex) => dispatch(actionsTitolareEff.caricaDettaglioTitolareEffBenefVita(selectedIndex)),
    updateDatiTitolareEffBenefVita: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiTitolareEffBenefVita(unDato, unActionType)),
    deleteTitolareEffBenefVita: (titolareEff, figura) => dispatch(actionsTitolareEff.deleteTitolareEffBenefVita(titolareEff, figura)),
  };
};

class TabDatiRiepilogoTitolareEff extends React.Component {
  constructor(props) {
    super(props);
  };

  caricaRiepilogoList = (figura) => {
    let riepilogoList = [];
    if (figura === TITOLARE_EFF_BENEF_MORTE) {
      const riepilogoBenefMorteList = this.props.titolareEffBenefMorteList;
      for (let index = 0; index < riepilogoBenefMorteList.length; index++) {
        riepilogoList.push(riepilogoBenefMorteList[index]);
      }
    }
    if (figura === TITOLARE_EFF_BENEF_VITA) {
      const riepilogoBenefVitaList = this.props.titolareEffBenefVitaList;
      for (let index = 0; index < riepilogoBenefVitaList.length; index++) {
        riepilogoList.push(riepilogoBenefVitaList[index]);
      }
      if (figura === TITOLARE_EFFETTIVO) {
        const riepilogoTitolareBenefList = this.props.titolareEffList;
        for (let index = 0; index < riepilogoTitolareBenefList.length; index++) {
          riepilogoList.push(riepilogoTitolareBenefList[index]);
        }
    }
    return riepilogoList;
  } 
}


  datiRiepilogoFrom = figura => {
    let datiRiepilogoList = null;
    if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      const titolareEffBenefMorteList = this.props.titolareEffBenefMorteList;
      let datiRiepilogoTitolareEffBenefMorteList = createDataRiepilogoList(titolareEffBenefMorteList, TITOLARE_EFF_BENEF_MORTE);
      datiRiepilogoList = datiRiepilogoTitolareEffBenefMorteList;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      const titolareEffBenefVitaList = this.props.titolareEffBenefVitaList;
      let datiRiepilogoTitolareEffBenefVitaList = createDataRiepilogoList(titolareEffBenefVitaList, TITOLARE_EFF_BENEF_VITA);
      datiRiepilogoList = datiRiepilogoTitolareEffBenefVitaList;
    } else if (figura === TITOLARE_EFFETTIVO){
      const datiTitolareEffList = this.props.titolareEffList;
      let datiRiepilogoTitolareEffList = createDataRiepilogoList(datiTitolareEffList, TITOLARE_EFFETTIVO);
      datiRiepilogoList = datiRiepilogoTitolareEffList;
    }
    for (let index = 0; datiRiepilogoList !== null && index < datiRiepilogoList.length; index++) {
      if (datiRiepilogoList[index].nominativo !== undefined && datiRiepilogoList[index].nominativo !== '') {
        datiRiepilogoList[index].id = index;
      }
    }
    return datiRiepilogoList;
  }

  handleClick = (event, id, figura) => {
    let riepilogoList= [];
    if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      riepilogoList = this.caricaRiepilogoList(figura);
      this.props.caricaDettaglioTitolareEffBenefMorte(id);
      this.props.updateDatiTitolareEffBenefMorte(false, actionTypeTitolareEff.SALVA_TITOLARE_EFF_BENEF_MORTE_SUCCESS);
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      riepilogoList = this.caricaRiepilogoList(figura);
      this.props.caricaDettaglioTitolareEffBenefVita(id);
      this.props.updateDatiTitolareEffBenefVita(false, actionTypeTitolareEff.SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS);
    } else {
      riepilogoList = this.caricaRiepilogoList(figura);
      this.props.caricaDettaglioTitolareEff(id);
      this.props.updateDatiTitolareEff(false, actionTypeTitolareEff.SALVA_TITOLARE_EFF_SUCCESS);
    }
  };

  handleClickDelete = (event, id, figura) => {
    if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      const titolareEffDeleteBenefMorte = this.props.titolareEffBenefMorteList[id];
      this.props.deleteTitolareEffBenefMorte(titolareEffDeleteBenefMorte, figura);
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      const titolareEffDeleteBenefVita = this.props.titolareEffBenefVitaList[id];
      this.props.deleteTitolareEffBenefVita(titolareEffDeleteBenefVita, figura);
    } else {
      const titolareEffDelete = this.props.titolareEffList[id];
      this.props.deleteTitolareEff(titolareEffDelete, figura);
    }
  }


  render() {
    //  console.log('#RTCFA TabDatiRiepilogoTitolareEff.this.props', this.props);
    const { classes, t, figura, tabHeader } = this.props;
    const riepilogoList = this.datiRiepilogoFrom(figura);
    //    const riepilogoList = this.createDataRiepilogoList(this.props.titolareEffList);
    let tableList = riepilogoList;
    return (
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item xs={12}>
            {tableList && tableList !== undefined &&
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell align='left' colSpan={5} ><Typography variant='subtitle1'>{t(tabHeader)}</Typography></CustomTableCell>
                    </TableRow>
                    <TableRow>
                      <CustomTableCell width='60%' align='left'><Typography variant='subtitle1'>{t('general.nominativo')}</Typography></CustomTableCell>
                      <CustomTableCell width='25%' align='left'><Typography variant='subtitle1'>{t('codiceFiscale')}</Typography></CustomTableCell>
                      <CustomTableCell width='7%' align='center'>
                        {figura !== TITOLARE_EFF_BENEF_MORTE && figura !== TITOLARE_EFF_BENEF_VITA &&
                          <Typography variant='subtitle1'>{'%'}</Typography>
                        }
                      </CustomTableCell>
                      <CustomTableCell width='3%' align='center'></CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableList.map(row => (
                      <TableRow hover className={classes.row} key={row.id}>
                        <CustomTableCell align='left' onClick={event => this.handleClick(event, row.id, figura)}  >{row.nominativo}</CustomTableCell>
                        <CustomTableCell align='left' onClick={event => this.handleClick(event, row.id, figura)}  >{row.codiceFiscale}</CustomTableCell>
                        <CustomTableCell align='center' onClick={event => this.handleClick(event, row.id, figura)}>{row.percOwnerShip}</CustomTableCell>
                        <CustomTableCell align='center'><DeleteForever onClick={event => this.handleClickDelete(event, row.id, figura)} /> </CustomTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            }
          </Grid>
        </Grid>
    );
  }
}
TabDatiRiepilogoTitolareEff.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
//  riepilogoList: PropTypes.string.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TabDatiRiepilogoTitolareEff)));
