import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DatiGeneraliGiuridico from '../sezione/DatiGeneraliGiuridico';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiRecapito from '../sezione/DatiRecapito';
import DatiConsensoComunicazioneElettronica from '../sezione/DatiConsensoComunicazioneElettronica';
import DatiAttivitaEconomicaGiuridico from '../sezione/DatiAttivitaEconomicaGiuridico';
import DatiFiscali from '../sezione/DatiFiscali';
import DatiRappLegale from '../sezione/DatiRappLegale';
//import DatiTitolareEff from '../sezione/DatiTitolareEff';
import DatiNaturaRapporto from '../sezione/DatiNaturaRapporto';

import { radioButtonTitolariEffettivi } from '../../store/radioButtonStore';

import DividerComp from '../../common/DividerComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import ButtonComp from '../../common/ButtonComp';

import * as actions from './action/contraente';
import * as actionType from './actionType/contraente';
import * as actionsTitolareEff from './action/titolareEff';
import DatiSocietari from '../sezione/DatiSocietari';
import DatiSituazioneFinanziariaGiuridico from '../sezione/DatiSituazioneFinanziariaGiuridico';
import DatiMandatoFiduciario from '../sezione/DatiMandatoFiduciario';
import { fetchParam, uris } from '../../../uris';
import moment from 'moment';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA ContraenteGiuridicoAss.mapStateToProps.state', state);
  return {
    contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    titolareEffList: state.areaTitolareEffReducer.titolareEffList,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA ContraenteGiuridicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
    updateContraenteGiuridico: (unDato, anActionType) => dispatch(actions.updateContraenteGiuridico(unDato, anActionType)),    	  
    updateDatiGeneraliContraenteGiuridico: (unDato, unActionType)  => dispatch(actions.updateDatiGeneraliContraenteGiuridico(unDato, unActionType) ),
	nuovoTitolareEff: () => dispatch(actionsTitolareEff.nuovoTitolareEff()),
    deleteTitolareEff: (titolareEff) => dispatch(actionsTitolareEff.deleteTitolareEff(titolareEff)),
    inizializzaContraente: (anActionType) => dispatch(actions.inizializzaContraente(anActionType)),   
  };
};
	
class ContraenteGiuridicoAss extends React.Component {
  
  constructor(props) {	
    super(props);
    console.log('#RTCFA ContraenteGiuridicoAss constructor props', this.props);
    if(this.props.contraenteGiuridico.flTitolariEff !== 'S') {
      this.props.updateDatiGeneraliContraenteGiuridico('S', actionType.UPDATE_PRESENTI_TITOLARE_EFF);
    }
    this.state = {
      contraenteGiuridico: this.props.contraenteGiuridico,	
      statoResidenza: this.props.contraenteGiuridico.datiResidenza.statoResidenza,
      flTitolariEff: 'S',
      isConferma: false,
      errorRipartizione: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
      isReset: false,
      isMounting: false,
    }   
  };
  
  handleChangeConfermaContraente = event => {
//    console.log('#RTCFA ContraenteGiuridicoAss handleChangeConfermaContraente', event.target.value);
    this.verificaPatrimonio()
    this.props.updateContraenteGiuridico(true, actionType.SALVA_CONTRAENTE_GIURIDICO);
    this.setState({
      isConferma: true,
    });    
  } 
  
  handleChangeInizializzaContraente = event => {
    this.props.inizializzaContraente(actionType.INIZIALIZZA_CONTRAENTE_GIURIDICO);  
    this.setState({
      contraenteGiuridico: this.props.contraenteGiuridico,	
      statoResidenza: this.props.contraenteGiuridico.datiResidenza.statoResidenza,
      flTitolariEff: '',
      isConferma: false,
      isReset: true,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,       
    });
  }  
  
  handleChangeStatoResidenzaPerSae = event => {
    this.setState({
      statoResidenza: (event === null) ? '' : event.value,
    });	
  }    
  
  handleChangeFlTitolareEff = (event) => {	  
    console.log('#RTCFA ContraenteGiuridicoAss handleChangeFlTitolareEff', event.target.value);
    const flTitolariEff = event.target.value
    this.props.updateDatiGeneraliContraenteGiuridico(flTitolariEff, actionType.UPDATE_PRESENTI_TITOLARE_EFF);
    if (flTitolariEff==='N' && this.props.titolareEffList.length>0) {
    	this.props.titolareEffList.forEach( (item, index, array) => {
        	const titolareEffDelete = item;
        	this.props.deleteTitolareEff(titolareEffDelete);	    		
    	});
    	this.props.nuovoTitolareEff();
    }
    if (this.props.handleChangeFlTitolareEff) {
      this.props.handleChangeFlTitolareEff(flTitolariEff);
      this.setState({
        flTitolariEff: flTitolariEff,
      });
    }
  }
  
  initStatoCampi = () => {
    return [ {id: 'datiGeneraliContraenteGiuridico', isError: false},
             {id: 'datiResidenzaContraenteGiuridico', isError: false},  
             {id: 'datiRecapitoContraenteGiuridico', isError: false},  
             {id: 'datiConsensoComunicazioneElettronicaContraenteGiuridico', isError: false},
             {id: 'datiAttivitaEconomicaContraenteGiuridico', isError: false},
             {id: 'datiFiscaliContraenteGiuridico', isError: false},  
             {id: 'datiRappLegaleContraenteGiuridico', isError: false},               
             {id: 'datiNaturaRapportoContraenteGiuridico', isError: false}, 
             {id: 'datiSocietariContraenteGiuridico', isError: false},
             {id: 'datiSituazioneFinanziariaContraenteGiuridico', isError: false},
             {id: 'flTitolareEff', isError: false},   
           ];
  }
	  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  verificaPatrimonio = () => {
    const datiFinanziari = this.props.contraenteGiuridico.datiFinanziari;
    const ripartizione = datiFinanziari.ripartizioneInvestimentiFinanziari;
    let tot = 0;
    // somma gli strumenti finanziari
    for(var key in ripartizione) {
      if(ripartizione.hasOwnProperty(key) && ripartizione[key]) {
          tot = tot + parseInt(ripartizione[key])
      }
    }
    // chiede al servizio qual è il patrimonio massimo possibile
    const opts = {
      siglaVersione: 'ZURI',
      codiceRegola: 'CONTR_X_PFIG',
      codPatrimonioFinanziario: datiFinanziari.codPatrimonioFinanziario,
      dataRichiesta: moment().format("DD/MM/YYYY")
    }
    fetch(uris.selectPatrimonioFinanziarioMassimo, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An error occured...", error)
    )
    .then((result) => {
        const patrimonioMassimo = result;
        if(patrimonioMassimo && tot <= patrimonioMassimo) {
          console.log("RIPARTIZIONE OK");
          this.setState({
            errorRipartizione: false,
          })
        } else {
          console.log("RIPARTIZIONE KO");
          this.setState({
            errorRipartizione: true,
            isPresenteErrore: true,
          })
        }
    });
  }

  componentDidMount = () => {
	if(this.props.handleChangeFlTitolareEff){
		this.props.handleChangeFlTitolareEff('S');
	}
  }

  componentWillUnmount = () => {
    if(this.state.flTitolariEff === 'S') {
      this.setState({
        flTitolariEff: 'N'
      })
      this.props.updateDatiGeneraliContraenteGiuridico('N', actionType.UPDATE_PRESENTI_TITOLARE_EFF);
      if(this.props.handleChangeFlTitolareEff){
    	  this.props.handleChangeFlTitolareEff('N');
      }
    }
  }
 
  render() {
    const { t, classes, contraenteGiuridico } = this.props;
	console.log('#RTCFA ContraenteGiuridicoAss this.props', this.props);
    const controlloDati = this.props.controlloDatiContraenteGiuridico;
    const isMounting = this.state.isMounting;
    
    return (
      <Fragment>
        <DatiGeneraliGiuridico figura={'contraenteGiuridico'} labelFieldSet='datiGenerali' id={'datiGeneraliContraenteGiuridico'} onError={this.handleError} /> 
        <DatiResidenza figura={'contraenteGiuridico'} datiResidenzaContraenteGiuridico={contraenteGiuridico.datiResidenza} labelFieldSet='datiDiResidenzaGiuridico' 
        	id={'datiResidenzaContraenteGiuridico'} onChangeStatoResidenza={this.handleChangeStatoResidenzaPerSae} onError={this.handleError} />
        <DatiRecapito figura={'contraenteGiuridico'} datiRecapitoContraenteGiuridico={contraenteGiuridico.datiRecapito} labelFieldSet='indirizzoDiCorrispondenza' 
            id={'datiRecapitoContraenteGiuridico'} onError={this.handleError} />
        <DatiConsensoComunicazioneElettronica figura={'contraenteGiuridico'} datiConsensoComElettr={contraenteGiuridico.datiConsensoComElettr} labelFieldSet='datiConsensoComunicazioneElettronica'
            id={'datiConsensoComunicazioneElettronicaContraenteGiuridico'} onError={this.handleError} />    
        <DatiSocietari figura={'contraenteGiuridico'} labelFieldSet='datiSocietari' datiSocietari={contraenteGiuridico.datiSocietari}
          id={'datiSocietariContraenteGiuridico'} />       
        <DatiAttivitaEconomicaGiuridico  figura={'contraenteGiuridico'} datiAttEconomicaGiuridico={contraenteGiuridico.datiAttEconomicaGiuridico} statoResidenza={this.state.statoResidenza} 
        	id={'datiAttivitaEconomicaContraenteGiuridico'} onError={this.handleError} /> 
        <DatiMandatoFiduciario figura={'contraenteGiuridico'}  onError={this.handleError} datiMandatoFiduciario={contraenteGiuridico.datiMandatoFiduciario} ù
                               labelFieldSet='datiMandatoFiduciario'/>
        <DatiFiscali figura={'contraenteGiuridico'} datiFiscali={contraenteGiuridico.datiFiscali} labelFieldSet='datiFiscali'
            id={'datiFiscaliContraenteGiuridico'} onError={this.handleError} />
        <DatiRappLegale id={'datiRappLegaleContraenteGiuridico'} datiRappLegale={contraenteGiuridico.datiRappLegale} onError={this.handleError} />    
        <DatiSituazioneFinanziariaGiuridico figura={'contraenteGiuridico'} datiFinanziari={contraenteGiuridico.datiFinanziari} labelFieldSet='datiSituazioneFinanziariaGiuridico'
          id={'datiSituazioneFinanziariaContraenteGiuridico'} onError={this.handleError} />
        <DatiNaturaRapporto id={'datiNaturaRapportoContraenteGiuridico'} figura={'contraenteGiuridico'} datiNaturaRapporto={contraenteGiuridico.datiNaturaRapporto} 
                            labelFieldSet='scopoNaturaRapportoOrigineFondi' onError={this.handleError} />
        <DividerComp titolo='titolareEffettivo' variant='subtitle1' />
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item md={4} xs={12}>
            <RadioButtonComp /* onChange={this.handleChangeFlTitolareEff} */ radioComp={radioButtonTitolariEffettivi} id={'flTitolareEff'} 
        	                 selectedValue={this.state.flTitolariEff} required={true} controlloDati={controlloDati} onError={this.handleError} readonly={true} />
          </Grid>
          <Grid item md={8} xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          	<Grid container direction='row' justify='center' alignItems='center'>   	
              <Grid item>
                {(controlloDati === true) && (this.state.isPresenteErrore === true) && isMounting === false &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(controlloDati === true) && (this.state.isPresenteErrore === false) && isMounting === false &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
                {this.state.errorRipartizione && 
                  <Typography className={classes.textErrorMessage}>{t('ripartizioneNonValida')}</Typography>
                }
              </Grid>
            </Grid>
            <Grid item>
              <ButtonComp
                 aClassName={classes.button}
                 buttonLabel={'conferma'}
                 onClick={this.handleChangeConfermaContraente}
              />
            </Grid>
            <Grid item>
	          <ButtonComp
	            aClassName={classes.button}
	            buttonLabel={'cancellaDati'}
	            onClick={this.handleChangeInizializzaContraente}
	          />
	        </Grid>
          </Grid>
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError || this.state.errorRipartizione
      );
	});
    console.log('########ContraenteGiuridicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########ContraenteGiuridicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########ContraenteGiuridicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	    
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDatiContraenteGiuridico !== '')) {    
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiGeneraliContraenteGiuridico(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_GIURIDICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,   
      });      
	}    
  }
              
  isConferma = (prevProps, prevState) => {   
	const currIsConferma = this.state.isConferma;	
	const prevIsConferma = prevState.isConferma;
	if (currIsConferma) {		
      // funzione callback
      if (this.props.handleChangeConfermaContraenteGiuridico) {
        this.props.handleChangeConfermaContraenteGiuridico();
      }
    }
	if (currIsConferma !== prevIsConferma) {
      this.setState({
        isConferma: false
      }); // reset
	}	
  }
  
  isMounting(prevProps, prevState) {
	if(this.state.isMounting===true){
		this.setState({
			isMounting: false,
		});
	}  
  }
  
  isConferma(prevProps, prevState) {
	if(this.state.isConferma===true){
		this.setState({
			isConferma: false, // reset click conferma
		});
	}  
  }
  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
    this.isConferma(prevProps, prevState);
    if (this.state.isReset === true) {
      this.setState({	
        isReset: false,   
      });
    }
	this.isMounting(prevProps, prevState)
  }
}

ContraenteGiuridicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  contraenteGiuridico: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ContraenteGiuridicoAss)));
