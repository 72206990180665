import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uris, fetchParam } from '../../../uris';

import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';
import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';
import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';
import * as actionsAssicurato from '../figure/action/assicurato';
import * as actionTypeAssicurato from '../figure/actionType/assicurato';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';


import { textTrim } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { CONTRAENTE_FISICO, ASSICURATO_FISICO, CONTRAENTE_GIURIDICO, BENEF_VITA_FISICO, BENEF_VITA_GIURIDICO,
         BENEF_MORTE_FISICO, BENEF_MORTE_GIURIDICO, ESECUTORE_FISICO, RAPPRESENTANTE_LEGALE, TITOLARE_EFFETTIVO,
         PROVINCIA_ESTERA, 
         TITOLARE_EFF_BENEF_MORTE,
         TITOLARE_EFF_BENEF_VITA, locale_it} from '../../../utility/jsConstants';	     
	     
import moment from 'moment';
import 'moment/locale/it';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
	datiResidenzaContraenteFisico: state.areaContraenteReducer.contraenteFisico.datiResidenza,
	controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
	datiResidenzaAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.datiResidenza,
	controlloDatiAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.controlloDati,	
	datiResidenzaContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiResidenza,
	controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
	datiResidenzaBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.datiResidenza,
	controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,	
	datiResidenzaBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiResidenza,
	controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
	datiResidenzaBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiResidenza,
	controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
	datiResidenzaBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiResidenza,
	controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
	datiEsecutoreFisico: state.areaContraenteReducer.contraenteFisico.datiEsecutore,	
	controlloDatiEsecutoreFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,	
	datiResidenzaRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiResidenza,
	controlloDatiRappLegale: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
	datiResidenzaTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiResidenza,
	controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
	datiResidenzaTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiResidenza,
	controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
	datiResidenzaTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiResidenza,
	controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
	
	tipologiaProdotto: state.areaProdottoReducer.prodotto.tipo,	
	scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiResidenzaContraenteFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiResidenzaContraenteFisico(unDato, unActionType)),
    updateDatiResidenzaAssicuratoFisico: (unDato, unActionType) => dispatch(actionsAssicurato.updateDatiResidenzaAssicuratoFisico(unDato, unActionType)),
    updateDatiResidenzaContraenteGiuridico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiResidenzaContraenteGiuridico(unDato, unActionType)),     
    updateDatiResidenzaBenefVitaFisico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiResidenzaBenefVitaFisico(unDato, unActionType)),	 
    updateDatiResidenzaBenefVitaGiuridico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiResidenzaBenefVitaGiuridico(unDato, unActionType)),    
    updateDatiResidenzaBenefMorteFisico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiResidenzaBenefMorteFisico(unDato, unActionType)),	 
    updateDatiResidenzaBenefMorteGiuridico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiResidenzaBenefMorteGiuridico(unDato, unActionType)),    
    updateDatiResidenzaEsecutoreFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiResidenzaEsecutoreFisico(unDato, unActionType)),
    updateDatiResidenzaRappLegale:(unDato, unActionType) => dispatch(actionsContraente.updateDatiResidenzaRappLegale(unDato, unActionType)),
    updateDatiResidenzaTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiResidenzaTitolareEff(unDato, unActionType)),
    updateDatiResidenzaPerContraente: (unDato, unActionType) => dispatch(actionsContraente.updateDatiResidenzaPerContraente(unDato, unActionType)), 
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};

class DatiResidenza extends React.Component {
	
  constructor(props) {
    super(props);
	const datiResidenza = this.datiResidenzaFrom(this.props.figura);  
    this.state = ({
      statoResidenza: datiResidenza.statoResidenza,
      descrStatoResidenza: datiResidenza.descrStatoResidenza,
      provinciaResidenza: datiResidenza.provinciaResidenza,
      descrProvinciaResidenza: datiResidenza.descrProvinciaResidenza,
      comuneResidenza: datiResidenza.comuneResidenza,
      descrComuneResidenza: datiResidenza.descrComuneResidenza,
      toponimoResidenza: datiResidenza.toponimoResidenza,
      descrToponimoResidenza: datiResidenza.descrToponimoResidenza,
      indirizzoResidenza: datiResidenza.indirizzoResidenza,
      descrIndirizzoResidenza: datiResidenza.descrIndirizzoResidenza,
      numCivicoResidenza: datiResidenza.numCivicoResidenza,
      capResidenza: datiResidenza.capResidenza, 
      nazioneItems: [],
      provinciaItems: [],
      comuneItems: [],
      toponimoItems: [],
      stradarioItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,      
	});
  };
     
  componentDidMount() {	  
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    // Lo stato di Residenza e' ITALIA per il contraente/assicurato
    if (this.props.figura === CONTRAENTE_FISICO ||
        this.props.figura === CONTRAENTE_GIURIDICO ||
    	this.props.figura === ASSICURATO_FISICO) {
      if ((this.props.dominio !== null) && (this.props.dominio.nazioneItaliaItems !== undefined) && (this.props.dominio.nazioneItaliaItems !== null) &&
          (this.props.dominio.nazioneItaliaItems.length > 0)) {
        this.setState({nazioneItems: this.props.dominio.nazioneItaliaItems});
      } else {
        const dataOggiFormatta = moment().format('DD/MM/YYYY');  
        this.setNazioneItalia(dataOggiFormatta);      	
      }    
    } else {
	    if ((this.props.dominio !== null) && (this.props.dominio.nazioneItems !== undefined) && (this.props.dominio.nazioneItems !== null) &&
            (this.props.dominio.nazioneItems.length > 1)) {
          this.setState({nazioneItems: this.props.dominio.nazioneItems});
	    } else {
          const dataOggiFormatta = moment().format('DD/MM/YYYY');  
          this.setNazioni(dataOggiFormatta);      	
	    }
    }
    this.setProvince(datiResidenza ? datiResidenza.statoResidenza : '');
    this.setComuni(datiResidenza ? datiResidenza.provinciaResidenza : '', moment().format('DD/MM/YYYY'));
    this.setToponimo(datiResidenza ? datiResidenza.comuneResidenza : '');
    //this.setStradario(datiResidenza ? datiResidenza.comuneResidenza : '', datiResidenza ? datiResidenza.toponimoResidenza : '', datiResidenza ? datiResidenza.descrIndirizzoResidenza : '');
  }  
  
  datiResidenzaFrom = figura => {
    let datiResidenza = null;
    if (figura === CONTRAENTE_FISICO) {
      datiResidenza = this.props.datiResidenzaContraenteFisico;
    } else if (figura === ASSICURATO_FISICO) {
      datiResidenza = this.props.datiResidenzaAssicuratoFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      datiResidenza = this.props.datiResidenzaContraenteGiuridico;    	
    } else if (figura === BENEF_VITA_FISICO) {
      datiResidenza = this.props.datiResidenzaBenefVitaFisico;
    } else if (figura === BENEF_VITA_GIURIDICO) {
      datiResidenza = this.props.datiResidenzaBenefVitaGiuridico;
    } else if (figura === BENEF_MORTE_FISICO) {
      datiResidenza = this.props.datiResidenzaBenefMorteFisico;
    } else if (figura === BENEF_MORTE_GIURIDICO) {
      datiResidenza = this.props.datiResidenzaBenefMorteGiuridico;
    } else if (figura === ESECUTORE_FISICO) {
      datiResidenza = this.props.datiEsecutoreFisico.datiResidenza;    	
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      datiResidenza = this.props.datiResidenzaRappLegale;
    } else if (figura === TITOLARE_EFFETTIVO) {
      datiResidenza = this.props.datiResidenzaTitolareEff;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiResidenza = this.props.datiResidenzaTitEffMorte;
    } else if(figura === TITOLARE_EFF_BENEF_VITA) {
      datiResidenza = this.props.datiResidenzaTitEffVita;
    }
    return datiResidenza;
  }
  
  handleChangeStatoResidenza = event => {
    const nuovoStatoResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioStatoResidenza = textTrim(datiResidenza.statoResidenza, true);    
    if (nuovoStatoResidenza !== vecchioStatoResidenza) { 	  
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(event, anActionType); 	
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_STATO_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(event, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_STATO_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(event, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_STATO_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_STATO_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_STATO_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(event, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(event, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_STATO_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      }
      this.setProvince(nuovoStatoResidenza);
      this.setComuni('', moment().format('DD/MM/YYYY'));
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        statoResidenza: nuovoStatoResidenza,
        descrStatoResidenza: (event === null) ? '' : event.label,
        provinciaResidenza: '',
        descrProvinciaResidenza: '',
      });	
      if(this.props.onChangeStatoResidenza) {
        this.props.onChangeStatoResidenza(event);
      }
    }  
    
    //2021-07-01 PFD: TCM_INDIV
    this.handleTcmIndividualeValida();
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  handleChangeProvinciaResidenza = event => {
    const nuovaProvinciaResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchiaProvinciaResidenza = textTrim(datiResidenza.provinciaResidenza, true);    
    if (nuovaProvinciaResidenza !== vecchiaProvinciaResidenza) { 	  	  
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(event, anActionType); 	
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_PROVINCIA_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(event, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(event, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_PROVINCIA_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_PROVINCIA_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(event, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(event, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      }
      this.setComuni(nuovaProvinciaResidenza, moment().format('DD/MM/YYYY'));
      this.setToponimo('');
      this.setStradario('', '', '');
	  this.setState({
	    provinciaResidenza: nuovaProvinciaResidenza,
        descrProvinciaResidenza: (event === null) ? '' : event.label,
        comuneResidenza: '',
        descrComuneResidenza: '',	
      });	    
    }
  }  
  
  handleChangeComuneResidenza = event => {
    const nuovoComuneResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioComuneResidenza = textTrim(datiResidenza.comuneResidenza, true);     
    if (nuovoComuneResidenza !== vecchioComuneResidenza) { 	  	  	  
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_COMUNE_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(event, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(event, anActionType);
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_COMUNE_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(event, anActionType);
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_COMUNE_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_COMUNE_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_COMUNE_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(event, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(event, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType)
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      }
      this.setToponimo(nuovoComuneResidenza);
      this.setStradario(nuovoComuneResidenza, '', '');
      this.setState({
        comuneResidenza: nuovoComuneResidenza,
        descrComuneResidenza: (event === null) ? '' : event.label,
		toponimoResidenza: '',
        descrToponimoResidenza: '', 
      });	
    }
  }
  
  // usato per PROVINCIA ESTERA
  handleChangeComuneResidenzaTextField = event => {
    const nuovoComuneResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioComuneResidenza = textTrim(datiResidenza.descrComuneResidenza, true);      
    if (nuovoComuneResidenza !== vecchioComuneResidenza) { 	  	  	  
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_COMUNE_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(nuovoComuneResidenza, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_COMUNE_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_COMUNE_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(nuovoComuneResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_COMUNE_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(nuovoComuneResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_COMUNE_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(nuovoComuneResidenza, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(nuovoComuneResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(nuovoComuneResidenza, anActionType);
      }
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        comuneResidenza: '' ,
        descrComuneResidenza: nuovoComuneResidenza,
      });
      
      // need error Reset if previously had an error
      this.handleError('toponimoResidenza', false);
    }
  }  
 
  handleChangeToponimoResidenza = event => {
    const nuovoToponimoResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioToponimoResidenza = textTrim(datiResidenza.toponimoResidenza, true);     
    if (nuovoToponimoResidenza !== vecchioToponimoResidenza) { 	  	  
  	  let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_TOPONIMO_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(event, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(event, anActionType);
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(event, anActionType);
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_TOPONIMO_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(event, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_TOPONIMO_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(event, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(event, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	    anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_RAPP_LEGALE;
	    this.props.updateDatiResidenzaRappLegale(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(event, anActionType);
      }
      //this.setStradario(this.state.comuneResidenza, (event === null) ? '' : event.value, this.state.descrIndirizzoResidenza);
	  this.setState({
        toponimoResidenza: nuovoToponimoResidenza,
        descrToponimoResidenza: (event === null) ? '' : event.label, 
      });
    }
  };

  // usato per PROVINCIA ESTERA
  handleChangeToponimoResidenzaTextField = event => {
    const nuovoToponimoResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioToponimoResidenza = textTrim(datiResidenza.descrToponimoResidenza, true);    
    if (nuovoToponimoResidenza !== vecchioToponimoResidenza) {
  	  let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_TOPONIMO_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(nuovoToponimoResidenza, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(nuovoToponimoResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_TOPONIMO_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_TOPONIMO_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(nuovoToponimoResidenza, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	    anActionType = actionTypeContraente.UPDATE_TOPONIMO_RESIDENZA_RAPP_LEGALE;
	    this.props.updateDatiResidenzaRappLegale(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(nuovoToponimoResidenza, anActionType);
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(nuovoToponimoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(nuovoToponimoResidenza, anActionType);
      }
      //this.setStradario(this.state.comuneResidenza, (event === null) ? '' : event.value, this.state.descrIndirizzoResidenza);
	  this.setState({
        toponimoResidenza: '',
        descrToponimoResidenza: nuovoToponimoResidenza, 
      });
    }
  };
	  
  /*
  handleChangeIndirizzoResidenza = event => {
    let anActionType = null;
	if (this.props.figura === CONTRAENTE_FISICO) {
	  anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_FISICO;
      this.props.updateDatiResidenzaContraenteFisico(event, anActionType); 
    } else if (this.props.figura === ASSICURATO_FISICO) {
      anActionType = actionTypeAssicurato.UPDATE_INDIRIZZO_RESIDENZA_ASSICURATO_FISICO;
      this.props.updateDatiResidenzaAssicuratoFisico(event, anActionType); 	
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_GIURIDICO;
      this.props.updateDatiResidenzaContraenteGiuridico(event, anActionType); 	
    } else if (this.props.figura === BENEF_VITA_FISICO) {
      anActionType = actionTypeBenefVita.UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_FISICO;
      this.props.updateDatiResidenzaBenefVitaFisico(event, anActionType); 	
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
      anActionType = actionTypeBenefVita.UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_GIURIDICO;
      this.props.updateDatiResidenzaBenefVitaGiuridico(event, anActionType); 	
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      anActionType = actionTypeBenefMorte.UPDATE_INDIRIZZO_RESIDENZA_BENEF_MORTE_FISICO;
      this.props.updateDatiResidenzaBenefMorteFisico(event, anActionType);
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
      anActionType = actionTypeBenefMorte.UPDATE_INDIRIZZO_RESIDENZA_BENEF_MORTE_GIURIDICO;
      this.props.updateDatiResidenzaBenefMorteGiuridico(event, anActionType); 	
    } else if (this.props.figura === ESECUTORE_FISICO) {
      anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_ESECUTORE_FISICO;
      this.props.updateDatiResidenzaEsecutoreFisico(event, anActionType);
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	  anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_RAPP_LEGALE;
	  this.props.updateDatiResidenzaRappLegale(event, anActionType);
	} else if (this.props.figura === TITOLARE_EFFETTIVO) {
	  anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF;
	  this.props.updateDatiResidenzaTitolareEff(event, anActionType);
	}
	this.setState({
      indirizzoResidenza: (event === null) ? '' : event.value,
      descrIndirizzoResidenza: (event === null) ? '' : event.label,       
	});
  };*/
  
  handleChangeIndirizzoResidenza = event => {
    const nuovoIndirizzoResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioIndirizzoResidenza = textTrim(datiResidenza.descrIndirizzoResidenza, true);     
    if (nuovoIndirizzoResidenza !== vecchioIndirizzoResidenza) {	  
      let anActionType = null;
	  if (this.props.figura === CONTRAENTE_FISICO) {
	    anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(nuovoIndirizzoResidenza, anActionType); 
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_INDIRIZZO_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(nuovoIndirizzoResidenza, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(nuovoIndirizzoResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(nuovoIndirizzoResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(nuovoIndirizzoResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_INDIRIZZO_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(nuovoIndirizzoResidenza, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_INDIRIZZO_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(nuovoIndirizzoResidenza, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(nuovoIndirizzoResidenza, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(nuovoIndirizzoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(nuovoIndirizzoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(nuovoIndirizzoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(nuovoIndirizzoResidenza, anActionType);
      }
      this.setState({
        descrIndirizzoResidenza: nuovoIndirizzoResidenza,    
     });
    }
  };
  
  handleChangeNumCivicoResidenza = event => {
    const nuovoNumCivicoResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioNumCivicoResidenza = textTrim(datiResidenza.numCivicoResidenza, true);        
    if (nuovoNumCivicoResidenza !== vecchioNumCivicoResidenza) {	  	  
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_NUMERO_CIVICO_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(nuovoNumCivicoResidenza, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(nuovoNumCivicoResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_MORTE_FISICO;
        this.props.updateDatiResidenzaBenefMorteFisico(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(nuovoNumCivicoResidenza, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiResidenzaTitolareEff(nuovoNumCivicoResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiResidenzaTitolareEff(nuovoNumCivicoResidenza, anActionType);
      }
      this.setState({
        numCivicoResidenza: nuovoNumCivicoResidenza, 
      });
    }
  };
  
  handleChangeCapResidenza = event => {
    const nuovoCapResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const vecchioCapResidenza = textTrim(datiResidenza.capResidenza, true);          
    if (nuovoCapResidenza !== vecchioCapResidenza) {	  	  	  
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CAP_RESIDENZA_CONTRAENTE_FISICO;
        this.props.updateDatiResidenzaContraenteFisico(nuovoCapResidenza, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_CAP_RESIDENZA_ASSICURATO_FISICO;
        this.props.updateDatiResidenzaAssicuratoFisico(nuovoCapResidenza, anActionType); 	
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_CAP_RESIDENZA_CONTRAENTE_GIURIDICO;
        this.props.updateDatiResidenzaContraenteGiuridico(nuovoCapResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_CAP_RESIDENZA_BENEF_VITA_FISICO;
        this.props.updateDatiResidenzaBenefVitaFisico(nuovoCapResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_CAP_RESIDENZA_BENEF_VITA_GIURIDICO;
        this.props.updateDatiResidenzaBenefVitaGiuridico(nuovoCapResidenza, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
         anActionType = actionTypeBenefMorte.UPDATE_CAP_RESIDENZA_BENEF_MORTE_FISICO;
         this.props.updateDatiResidenzaBenefMorteFisico(nuovoCapResidenza, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_CAP_RESIDENZA_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiResidenzaBenefMorteGiuridico(nuovoCapResidenza, anActionType); 	
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CAP_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiResidenzaEsecutoreFisico(nuovoCapResidenza, anActionType);
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        anActionType = actionTypeContraente.UPDATE_CAP_RESIDENZA_RAPP_LEGALE;
        this.props.updateDatiResidenzaRappLegale(nuovoCapResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CAP_RESIDENZA_TITOLARE_EFF;
        this.props.updateDatiResidenzaTitolareEff(nuovoCapResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_CAP_RESIDENZA_TITOLARE_EFF_MORTE;
        this.props.updateDatiResidenzaTitolareEff(nuovoCapResidenza, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA;
        this.props.updateDatiResidenzaTitolareEff(nuovoCapResidenza, anActionType);
      }
      this.setState({
        capResidenza: nuovoCapResidenza, 
      });
    }
  };
	  
  setNazioni = (aDate) => {
    const opts = {
      dataRiferimentoComune: aDate,
    };
    fetch(uris.nazioneItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
        nazioneItems: result,
      });
    });
  };
  
  setNazioneItalia = (aDate) => {
    const opts = {
      dataRiferimentoComune: aDate,
      flNazioneItalia: true,
    };
    fetch(uris.nazioneItaliaItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      this.setState({
        nazioneItems: result,
      });
    });
  };
	  
  setProvince = (nazione) => {
    if(textTrim(nazione, true)!==''){
	    const opts = {
	      nazione: nazione,
	    };
	    fetch(uris.provinciaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      // If request is good update state with fetched data
	      this.setState({
	    	provinciaItems: result,
	      });
	    });
    }else{
        this.setState({
        	provinciaItems: [],
        });
    }
  }
  
  setComuni = (codProvincia, dataNascita) => {
    if(textTrim(codProvincia, true)!=='' && textTrim(dataNascita, true)!==''){
	  const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          comuneItems: result,
        });
      });
    }else{
      this.setState({
        comuneItems: [],
      });
    }
  }
  
  setToponimo = (codiceIstat) => {
    if(textTrim(codiceIstat, true)!==''){
	  const opts = {
	    codiceIstat: codiceIstat,
	  };
	  fetch(uris.toponimoItems, fetchParam(opts))
	  .then (
	    response => response.json(),
	    error => console.error('An error occurred.', error),
	  )
	  .then((result) => {
	    // If request is good update state with fetched data
	    this.setState({
	      toponimoItems: result,
	    });
	  });
    }else{
      this.setState({
        toponimoItems: [],
      });
    }
  }
  
  setStradario = (codiceIstat, toponimo, descrStrada) => {
	if(textTrim(codiceIstat, true)!=='' && textTrim(toponimo, true)!==''){
	  const opts = {
        codiceIstat: codiceIstat,
        descrToponimo: toponimo,
        descrStrada: descrStrada,
      };
      fetch(uris.stradarioItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
    	  stradarioItems: result,
        });
      });
	}else{
      this.setState({
        stradarioItems: [],
       });
    }
  }

  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    } else if (figura === ASSICURATO_FISICO) {
      controlloDati = this.props.controlloDatiAssicuratoFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiContraenteGiuridico;    	
    } else if (figura === BENEF_VITA_FISICO) {
      controlloDati = this.props.controlloDatiBenefVitaFisico;
    } else if (figura === BENEF_VITA_GIURIDICO) {
      controlloDati = this.props.controlloDatiBenefVitaGiuridico;
    } else if (figura === BENEF_MORTE_FISICO) {
      controlloDati = this.props.controlloDatiBenefMorteFisico;
    } else if (figura === BENEF_MORTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiBenefMorteGiuridico;
    } else if (figura === ESECUTORE_FISICO) {
      controlloDati = this.props.controlloDatiEsecutoreFisico;
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      controlloDati = this.props.controlloDatiRappLegale;
    } else if (figura === TITOLARE_EFFETTIVO) {
      controlloDati = this.props.controlloDatiTitolareEff;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitEffVita;
    }
    return controlloDati;
  } 

  isRequiredPerBeneficiario = () => {
	// se e' escluso dalle Comunicazioni elettroniche allora diventa non e' obbligatorio  
	return (this.props.flEsclusioneComunicazBenef!=='S');
  }
  
  isRequiredPerEsecutore = () => {
    return (this.props.datiEsecutoreFisico.flEsecutore === 'S');
  }
  
  isRequiredStato = () => {
	let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO /*&& !this.isTipologiaProdottoMultinvest() */) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      required = true;    	
    } else if (this.props.figura === BENEF_VITA_FISICO) {
      required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
      required = this.isRequiredPerBeneficiario();    
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      required = this.isRequiredPerBeneficiario();    	
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
      required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }
  
  isRequiredProvincia = () => {
	let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO /*&& !this.isTipologiaProdottoMultinvest()*/ ) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      required = true;    	
    } else if (this.props.figura === BENEF_VITA_FISICO) {
    	required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
    	required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }
  
  isRequiredComune = () => {
	let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO /*&& !this.isTipologiaProdottoMultinvest()*/ ) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      required = true;    	
    } else if (this.props.figura === BENEF_VITA_FISICO) {
    	required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
    	required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }  
  
  isRequiredToponimo = () => {
	let required = false;
	const datiResidenza = this.datiResidenzaFrom(this.props.figura);
	if (datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
      if (this.props.figura === CONTRAENTE_FISICO) {
        required = true;
      } else if (this.props.figura === ASSICURATO_FISICO) {
       required = true;
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        required = true;    	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
      	required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === ESECUTORE_FISICO) {
        required = this.isRequiredPerEsecutore(); 
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
    }
    return required;
  }
  
  isRequiredIndirizzo = () => {
	let required = false;
	const datiResidenza = this.datiResidenzaFrom(this.props.figura);
// 2019-07-10 PFD: campo indirizzo sempre obbligatorio, mentre per l'estero non lo sono il toponimo, numCivico e cap
//	if (datiResidenza.provinciaResidenza!==PROVINCIA_ESTERA) {
      if (this.props.figura === CONTRAENTE_FISICO) {
        required = true;
      } else if (this.props.figura === ASSICURATO_FISICO) {
        required = true;
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        required = true;    	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === ESECUTORE_FISICO) {
        required = this.isRequiredPerEsecutore();
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
  //    }
    return required;
  }
  
  isRequiredNumCivico = () => {
	let required = false;
	const datiResidenza = this.datiResidenzaFrom(this.props.figura);
	if (datiResidenza.provinciaResidenza!==PROVINCIA_ESTERA) {
      if (this.props.figura === CONTRAENTE_FISICO) {
        required = true;
      } else if (this.props.figura === ASSICURATO_FISICO /*&& !this.isTipologiaProdottoMultinvest()*/ ) {
        required = true;
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        required = true;    	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === ESECUTORE_FISICO) {
        required = this.isRequiredPerEsecutore();
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
    }
    return required;
  }
 
  isRequiredCap = () => {
	let required = false;
	const datiResidenza = this.datiResidenzaFrom(this.props.figura);
	if (datiResidenza.provinciaResidenza!==PROVINCIA_ESTERA) {
      if (this.props.figura === CONTRAENTE_FISICO) {
        required = true;
      } else if (this.props.figura === ASSICURATO_FISICO /*&& !this.isTipologiaProdottoMultinvest()*/ ) {
        required = true;
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        required = true;    	
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = this.isRequiredPerBeneficiario();
      } else if (this.props.figura === ESECUTORE_FISICO) {
        required = this.isRequiredPerEsecutore();
      } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
      }
    return required;
  }
  
  isDisabled = () => {
    const disabled = (this.props.renderChildren !== undefined) && (this.props.renderChildren !== null) && (this.props.renderChildren.digitabile === false);
    return disabled;
  }
  
  isDisabledProvincia = () => {
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const statoResidenza = textTrim(datiResidenza.statoResidenza, true); 
	return (this.isDisabled() === true) || (statoResidenza === '');
  }
 
  isDisabledComune = () => {
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const provinciaResidenza = textTrim(datiResidenza.provinciaResidenza, true); 
	return (this.isDisabledProvincia() === true) || (provinciaResidenza === '');
  }
   
  isDisabledToponimo = () => {
    const datiResidenza = this.datiResidenzaFrom(this.props.figura);
    const comuneResidenza = textTrim(datiResidenza.comuneResidenza, true); 
	return (this.isDisabledComune() === true) || (comuneResidenza === '');
  }
    
  initStatoCampi = () => {
    return [ {id: 'statoResidenza', isError: false},
             {id: 'provinciaResidenza', isError: false}, 
             {id: 'comuneResidenza', isError: false}, 
             {id: 'toponimoResidenza', isError: false},
             {id: 'indirizzoResidenza', isError: false},             
             {id: 'numCivDiResidenza', isError: false},
             {id: 'capDiResidenza', isError: false},
           ];
  }
	  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
	    
  render() {
    const { classes, figura, labelFieldSet, dominio, renderChildren } = this.props; 
    const datiResidenza = this.datiResidenzaFrom(figura);  
//    const { t } = this.props; //hoc
    const controlloDati = this.controlloDatiFrom(figura);  
    const disabled = this.isDisabled();
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t(datiDiResidenza)}</Typography></legend>*/}
        {labelFieldSet && 
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        }
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeStatoResidenza} options={this.state.nazioneItems} ricerca='comboRicerca' etichetta='statoResidenza' 
            	        value={{value: datiResidenza.statoResidenza, label: datiResidenza.descrStatoResidenza}} required={this.isRequiredStato()} 
                        controlloDati={controlloDati} disabled={disabled} id='statoResidenza' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>	
            <SelectComp onChange={this.handleChangeProvinciaResidenza} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provinciaResidenza' 
            	        value={{value: datiResidenza.provinciaResidenza, label: datiResidenza.descrProvinciaResidenza}} required={this.isRequiredProvincia()} 
                        controlloDati={controlloDati} disabled={this.isDisabledProvincia()} id='provinciaResidenza' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
          	{datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA &&
          	  <SelectComp onChange={this.handleChangeComuneResidenza} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comuneResidenza' 
            	          value={{value: datiResidenza.comuneResidenza, label: datiResidenza.descrComuneResidenza}} required={this.isRequiredComune()}
                          controlloDati={controlloDati} disabled={this.isDisabledComune()} id='comuneResidenza' onError={this.handleError} />
          	}
          	{datiResidenza.provinciaResidenza === PROVINCIA_ESTERA &&
          	  <TextFieldComp onBlur={this.handleChangeComuneResidenzaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} label='comuneResidenza' 
                       value={datiResidenza.descrComuneResidenza} required={this.isRequiredComune()} controlloDati={controlloDati}
                       disabled={disabled} id='comuneResidenza' onError={this.handleError} />
          	}
          </Grid>	
          <Grid item md={3} xs={12}>
          {datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA &&          
            <SelectComp onChange={this.handleChangeToponimoResidenza} options={this.state.toponimoItems} ricerca='comboRicerca' etichetta='toponimo' 
            	        value={{value: datiResidenza.toponimoResidenza, label: datiResidenza.descrToponimoResidenza}} required={this.isRequiredToponimo()}
                        controlloDati={controlloDati} disabled={this.isDisabledToponimo()} id='toponimoResidenza' onError={this.handleError} />
          }
          {datiResidenza.provinciaResidenza === PROVINCIA_ESTERA &&
            <TextFieldComp onBlur={this.handleChangeToponimoResidenzaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} label='toponimo' 
                  value={datiResidenza.descrToponimoResidenza} required={this.isRequiredToponimo()} controlloDati={controlloDati}
                  disabled={disabled} id='toponimoResidenza' onError={this.handleError} />
          }
          </Grid>
          <Grid item md={5} xs={12}>
          {/*<SelectComp onChange={this.handleChangeIndirizzoResidenza} options={this.state.stradarioItems} ricerca='comboRicerca' etichetta='indirizzo' 
            	        value={{value: datiResidenza.indirizzoResidenza, label: datiResidenza.descrIndirizzoResidenza}} required={this.isRequiredIndirizzo()} 
                        controlloDati={controlloDati} disabled={disabled} id='indirizzoResidenza' onError={this.handleError} />*/}
            <TextFieldComp onBlur={this.handleChangeIndirizzoResidenza} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} label='indirizzo' 
                           value={datiResidenza.descrIndirizzoResidenza} required={this.isRequiredIndirizzo()} controlloDati={controlloDati}
                           disabled={disabled} id='indirizzoResidenza' onError={this.handleError} />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextFieldComp onBlur={this.handleChangeNumCivicoResidenza} aStyle={classes.textField} id='numCivDiResidenza' label='numCivico' 
            	           value={datiResidenza.numCivicoResidenza} required={this.isRequiredNumCivico()} controlloDati={controlloDati} 
                           disabled={disabled} onError={this.handleError} />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCapResidenza} aStyle={classes.textField} id='capDiResidenza' label='cap' value={datiResidenza.capResidenza}
                           required={this.isRequiredCap()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
          </Grid>	
        </Grid>
      {/*</fieldset>*/}
	  </Fragment>	  
    );
  }
  
  updatePerContraente(prevProps) {
    const prevRender = prevProps.renderChildren;
    const currRender = this.props.renderChildren;
    if (this.isDisabled() && (prevRender !== currRender)) { 	
      this.setState({
        provinciaItems: [],
        comuneItems: [],
        toponimoItems: [],
        stradarioItems: [],
      });
      const figura = this.props.figura;
      if (figura === ESECUTORE_FISICO) {
        this.props.updateDatiResidenzaPerContraente(null, actionTypeContraente.UPDATE_DATI_RESIDENZA_PER_ESECUTORE);    	  
      } else if (figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiResidenzaPerContraente(null, actionTypeTitolareEff.UPDATE_DATI_RESIDENZA_PER_TITOLARE_EFFETTIVO);    	  
      } /* else if (figura === CONTRAENTE_FISICO) {
        this.props.updateDatiGeneraliPerContraente(null, actionTypeContraente.UPDATE_DATI_GENERALI_PER_CONTRAENTE);    	  
      } else if (figura === ASSICURATO_FISICO) {
        this.props.updateDatiGeneraliPerAssicurato(null, actionTypeAssicurato.UPDATE_DATI_GENERALI_PER_ASSICURATO);       	  
      } */
    }
  }   

  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiResidenza componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiResidenza componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiResidenza componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }  
  
  componentDidUpdate(prevProps, prevState) {
    this.updatePerContraente(prevProps);
    this.isInError(prevProps, prevState);
  }  
}

DatiResidenza.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  dominio: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiResidenza));
