import moment from 'moment';
import { locale_it,  
	     RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, 
	     PERSONA_FISICA, PERSONA_GIURIDICA } from '../../../../utility/jsConstants';
import { toNumber, } from '../../../../utility/genericUtility';
	     

export const calcolaPercBenfTotale = (fisicoList, giuridicoList, genericoList, locale) => {
  let percFisicoTot = 0;
  fisicoList !== undefined && fisicoList.forEach(item => {
    const ruolo = item.datiFigureAnagrBase.ruolo;
    if (ruolo===RUOLO_BENEF_MORTE) {		  
      percFisicoTot = percFisicoTot+toNumber(item.datiGeneraliBenefMorte.percBeneficiario, locale);
    } else if (ruolo===RUOLO_BENEF_VITA) {
      percFisicoTot = percFisicoTot+toNumber(item.datiGeneraliBenefVita.percBeneficiario, locale);
    }
  });
  let percGiuridicoTot = 0;
  giuridicoList !== undefined && giuridicoList.forEach(item =>{		  
    percGiuridicoTot = percGiuridicoTot+toNumber(item.datiGeneraliBenefGiuridico.percBeneficiario, locale);
  });
  
  let percGenericoTot = 0;
  genericoList !== undefined && genericoList.forEach(item => {		  
    percGenericoTot = percGenericoTot+toNumber(item.datiGenerali.percBeneficiario, locale);
  });
  return percFisicoTot+percGiuridicoTot+percGenericoTot;
}

// Trova il benf in lista 
export const getPercBenefByCodFiscInList = (benefList, benef, locale) => {
  let percBenef = undefined;
//	const ruolo = benef.datiFigureAnagrBase.ruolo;
//	const tipoPers = benef.datiFigureAnagrBase.tipoPers;	
  const datiGeneraliBenef = trovaDatiGeneraliBenefInList(benefList, benef);
  if (datiGeneraliBenef!==undefined) {	
    percBenef = datiGeneraliBenef.percBeneficiario;
    if (percBenef!==undefined) {
      percBenef = toNumber(percBenef, locale);
    }
  }
  return percBenef;
};

export const trovaDatiGeneraliBenefInList = (benefList, benef) => {
	let datiGeneraliBenef = undefined;
	
	const ruolo = benef.datiFigureAnagrBase.ruolo;
	const tipoPers = benef.datiFigureAnagrBase.tipoPers;	
		
	benefList.forEach((item, index, array) => {
		if (tipoPers===PERSONA_FISICA) {
			if (ruolo===RUOLO_BENEF_MORTE) {
				const codiceFiscaleBenefMorte = benef.datiGeneraliBenefMorte.datiGeneraliMinimi.codiceFiscale
				if (item.datiGeneraliBenefMorte.datiGeneraliMinimi.codiceFiscale === codiceFiscaleBenefMorte) {
					datiGeneraliBenef = item.datiGeneraliBenefMorte;
				}
			} else if (ruolo===RUOLO_BENEF_VITA) {
				const codiceFiscaleBenefVita = benef.datiGeneraliBenefVita.datiGeneraliMinimi.codiceFiscale
				if (item.datiGeneraliBenefVita.datiGeneraliMinimi.codiceFiscale === codiceFiscaleBenefVita) {
					datiGeneraliBenef = item.datiGeneraliBenefVita;
				}
			}
		} else if (tipoPers===PERSONA_GIURIDICA) {
			const codiceFiscaleBenefGiur = benef.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale
			if (item.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === codiceFiscaleBenefGiur) {
				datiGeneraliBenef = item.datiGeneraliBenefGiuridico;
			}
		} else {
			datiGeneraliBenef = item.datiGenerali;			
		}
	});
	
	return datiGeneraliBenef;
};

export const trovaDatiTitolareEffInList = (titList, titEff) => {
	let datiTitolareEff = undefined;
		
	titList.forEach((item, index, array) => {
		const codiceFiscaleTitEff = titEff.datiTitolareEff.datiGenerali.codiceFiscale
		if (item.datiTitolareEff.datiGenerali.codiceFiscale === codiceFiscaleTitEff) {
			datiTitolareEff = item.datiTitolareEff;
		}	
	});	
	
	return datiTitolareEff;
};

export const trovaDatiGeneraliBenefInListByCognome = (benefList, benef) => {
	let datiGeneraliBenef = undefined;
	
	const ruolo = benef.datiFigureAnagrBase.ruolo;
	const tipoPers = benef.datiFigureAnagrBase.tipoPers;	
		
	benefList.forEach((item, index, array) => {
		let cognomeBenef = undefined;
		
		if (tipoPers===PERSONA_FISICA) {
			if (ruolo===RUOLO_BENEF_MORTE) {
				cognomeBenef = benef.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome
			} else if (ruolo===RUOLO_BENEF_VITA) {
				cognomeBenef = benef.datiGeneraliBenefVita.datiGeneraliMinimi.cognome
			}
		} else if (tipoPers===PERSONA_GIURIDICA) {
			cognomeBenef = benef.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale
		} 
		
		if (cognomeBenef!==undefined) {
			if (item.datiGeneraliBenefMorte!==undefined && 
					item.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome === cognomeBenef) {
				datiGeneraliBenef = item.datiGeneraliBenefMorte;
			} else if (item.datiGeneraliBenefVita!==undefined &&
					item.datiGeneraliBenefVita.datiGeneraliMinimi.cognome === cognomeBenef) {
				datiGeneraliBenef = item.datiGeneraliBenefVita;
			} else if (item.datiGeneraliBenefGiuridico!==undefined  &&
					item.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale === cognomeBenef) {
				datiGeneraliBenef = item.datiGeneraliBenefGiuridico;
			}
		}
	});
	
	return datiGeneraliBenef;
};

export const getPercBenefByCognomeInList = (benefList, benef) => {
	let percBenefInLista = undefined;	 
	let datiGeneraliBenefInLista = trovaDatiGeneraliBenefInListByCognome (benefList, benef);
	
	if (datiGeneraliBenefInLista!==undefined) {
		percBenefInLista = datiGeneraliBenefInLista.percBeneficiario;		
	}
	
	return percBenefInLista;
}