import React from 'react';
import { Fragment } from 'react';
//import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextFieldComp from '../../common/TextFieldComp';
import DatePickerComp from '../../common/DatePickerComp';
import DividerComp from '../../common/DividerComp';

import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';

import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';

//import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiGeneraliGiuridico.mapStateToProps.state', state);
  return {
    datiGeneraliContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiGenerali,
	controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiGeneraliGiuridico.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiGeneraliContraenteGiuridico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiGeneraliContraenteGiuridico(unDato, unActionType)),
  };
};
	
class DatiGeneraliGiuridico extends React.Component {
  constructor(props) {
    super(props);
    console.log('#RTCFA DatiGeneraliGiuridico props', props);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    this.state = {
      codiceFiscale: datiGenerali.codiceFiscale,
      ragioneSociale: datiGenerali.ragioneSociale,
      dataNascita: datiGenerali.dataNascita,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
    };
  };

  handleChangeRagioneSociale = event => {
    console.log('#RTCFA DatiGeneraliGiuridico ragioneSociale new value', event.target.value);
    const nuovaRagioneSociale = textTrim(event.target.value, true);
//    const vecchiaRagioneSociale = textTrim(this.state.ragioneSociale, true); 
    const vecchiaRagioneSociale = textTrim(this.props.datiGeneraliContraenteGiuridico.ragioneSociale, true);
    if (nuovaRagioneSociale !== vecchiaRagioneSociale) {     
      this.props.updateDatiGeneraliContraenteGiuridico(nuovaRagioneSociale, actionTypeContraente.UPDATE_RAGIONE_SOCIALE_CONTRAENTE_GIURIDICO);    
      this.setState({
        ragioneSociale: nuovaRagioneSociale, 
      });
    }
  };
	  
  handleChangeCodFisc = event => {
    console.log('#RTCFA DatiGeneraliGiuridico CodFisc new value', event.target.value);
    const nuovoCodiceFiscale = textTrim(event.target.value, true);
//    const vecchioCodiceFiscale = textTrim(this.state.codiceFiscale, true); 
    const vecchioCodiceFiscale = textTrim(this.props.datiGeneraliContraenteGiuridico.codiceFiscale, true);
    if (nuovoCodiceFiscale !== vecchioCodiceFiscale) {
      this.props.updateDatiGeneraliContraenteGiuridico(nuovoCodiceFiscale, actionTypeContraente.UPDATE_CODICE_FISCALE_CONTRAENTE_GIURIDICO);    
      this.setState({
        codiceFiscale: nuovoCodiceFiscale, 
      });
    }    
  };  

  handleChangeDataCostituzione = event => {
    if (isDataValid(event)) {
      const nuovaDataNascita = dataFormat(event, 'DD/MM/YYYY');
      const vecchiaDataNascita = dataFormat(this.props.datiGeneraliContraenteGiuridico.dataNascita, 'DD/MM/YYYY');    
      if (nuovaDataNascita !== vecchiaDataNascita) {
        this.props.updateDatiGeneraliContraenteGiuridico(event, actionTypeContraente.UPDATE_DATA_COSTITUZIONE_CONTRAENTE_GIURIDICO);    
        this.setState({
          dataNascita: event,    	
        });
      } else {
        console.log('###DATA NON VALIDA datiGeneraliContraenteGiuridico handleChangeDataCostituzione', event);
      }
    }
  }
  
  datiGeneraliFrom = figura => {
    let datiGenerali = null;
    if (this.props.figura === 'contraenteGiuridico') {
   	  datiGenerali = this.props.datiGeneraliContraenteGiuridico;
    }
    return  datiGenerali;
  };
    
  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (this.props.figura === 'contraenteGiuridico') {
      controlloDati = this.props.controlloDatiContraenteGiuridico;
    } 
    return controlloDati;
  }

  
  isRequiredRagioneSociale = () => {
	  return true;
  }

  isRequiredCodiceFiscale = () => {
	  return true;
  }

  isRequiredDataCostituzione = () => {
	  return true;
  }
  
  initStatoCampi = () => {
    return [ {id: 'ragioneSociale', isError: false},
	         {id: 'codFisc', isError: false}, 
             {id: 'dataCostituzione', isError: false}, 	             
           ];
  }
	 
  handleError = (id, isError) => {
    console.log('########DatiGeneraliGiuridico verificaCodiceFiscale handleError id', id);	  
    console.log('########DatiGeneraliGiuridico verificaCodiceFiscale handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  render() {
	console.log('#RT2 DatiGeneraliGiuridico.this.props', this.props);
    const { classes, figura, labelFieldSet } = this.props;
//    const { t } = this.props; //hoc
    const datiGenerali = this.datiGeneraliFrom(figura); 
    const controlloDati = this.controlloDatiFrom(figura); 
    
    return (
      <Fragment>
      {/*
      <fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiGenerali')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeRagioneSociale} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='ragioneSociale' label='ragioneSociale' 
            	           value={datiGenerali.ragioneSociale} required={this.isRequiredRagioneSociale()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>	
            <TextFieldComp onBlur={this.handleChangeCodFisc} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='codFisc' label='codiceFiscale' 
            	           value={datiGenerali.codiceFiscale} required={this.isRequiredCodiceFiscale()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}></Grid>	
          <Grid item xs={12}>
            <DatePickerComp onChange={this.handleChangeDataCostituzione} aStyle={classes.dateField}
                            id='dataCostituzione' label='dataCostituzione' value={datiGenerali.dataNascita} 
            	            required={this.isRequiredDataCostituzione()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>	
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########ContraenteGiuridicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########ContraenteGiuridicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########ContraenteGiuridicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }
}

DatiGeneraliGiuridico.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
};

//export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiGeneraliGiuridico)));
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiGeneraliGiuridico));
