import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { connect } from 'react-redux';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiConsensoComp from './consenso/DatiConsensoComp';

//import { buttonDichConsensoPanelItems } from '../../store/buttonItems';

const styles = theme => ({
  root: {
    width: '100%',
  },
/*  heading: {
	color: theme.palette.color.marine,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },*/
});
	
class DichConsensoPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  };

  render () {	
    const { classes, isValida } = this.props;
    return (
      <div className={classes.root}> 
        <ExpansionPanelComp titolo={'consenso'} componente={DatiConsensoComp} isValida={isValida} />
      </div>
    );
  }
}

DichConsensoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DichConsensoPanel);
