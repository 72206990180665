import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

//import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
//import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
//import SearchIcon from '@material-ui/icons/Search';
//import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import { Link as CoreLink } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
//import * as actions from '../prodotto/action/prodotto';
import * as actions from '../generic/action/generic';
import { USER, STATO_UTENTE_PENDENTE } from '../../utility/jsConstants';

const drawerWidth = 240; //FIXME

const moreStyles = theme => ({
  root: {
    display: 'flex',
  },
  appName: {
	lineHeight: 1,
    fontSize: theme.typography.fontSize*1.8, 
	color: theme.palette.color.marine,
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*1.4, 
      },
	[theme.breakpoints.down('xs')]: { 
		fontSize: theme.typography.fontSize*1, 
      
      },
    marginRight: theme.spacing(2),
  },
  appNameCorsivo: {
	lineHeight: 1,
    fontSize: theme.typography.fontSize*1.6, 
	color: theme.palette.color.windowsBlue,
	fontStyle: 'italic',
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*1.2, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*0.8,      
      },
  },
  prodottoName: {
	 lineHeight: 1,
	 color: theme.palette.color.windowsBlue,
	 fontSize: theme.typography.fontSize*1.2, 
  },
  appBar: {	
	background: theme.palette.color.white,	  
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    background: theme.palette.color.windowsBlue70,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuItem: {
    marginTop: theme.spacing(1),
	//  marginLeft: 12,
  },
  menuItemMarginLeftAuto: {
  //marginLeft: 'auto',
  },
  titleHeader: {
//	  marginTop: 'auto',
//	  marginBottom: 'auto',
    margin: 'auto',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconSize: {
    height: 24,
    width: 24,
  },
  iconStyle: {
    color: theme.palette.color.marine,
  },
  
  link: {
    margin: theme.spacing(1),
    textDecoration: 'none',
    color: theme.palette.color.marine,
    '&:hover': {  
      //backgroundColor: theme.palette.color.windowsBlue,
	  //color: theme.palette.color.white,
	  //textDecoration: 'underline',
      textDecoration: 'none',
    },
  }, 
});

const mapStateToProps = state => {
  return {
	loginUser: state.areaProdottoReducer.loginUser,
	prodotto: state.areaProdottoReducer.prodotto,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  	inizializza: () => dispatch(actions.inizializza()),
  };
};
	
class MenuAppBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

//  constructor(props) {
//    super(props);
//    console.log("[ZSE] MenuAppBar.constructor props", props);
//  };

  //profile menu
  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  
  handleAreaProdottiMenuClose = () => {
	this.props.inizializza();
    this.setState({ anchorEl: null });
  };

  handleMobileMenuOpen = event => {
    //this.setState({ mobileMoreAnchorEl: event.currentTarget });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    //this.setState({ mobileMoreAnchorEl: null });
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, open, shrinkAppBar } = this.props;
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { t } = this.props; //hoc	

    //console.log("[AER] shrinkAppBar ... " + shrinkAppBar);

    const renderMenu = (
      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
           vertical: 'top',
           horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleMenuClose}
      >
      {this.props.loginUser.profilo.trim()!=='' && this.props.loginUser.stato!==STATO_UTENTE_PENDENTE && this.props.loginUser.profilo.trim()!==USER &&
        <MenuItem onClick={this.handleMenuClose}>
          <Typography variant="caption">
            <Link to={'/gestioneUtenze'} className={classes.link}>{t('gestioneUtenze')}</Link>
          </Typography>
        </MenuItem>
      }
      <MenuItem onClick={this.handleAreaProdottiMenuClose}>
        <Typography variant="caption">
          <Link to={'/areaProdotti'} className={classes.link}>{t('areaProdotti')}</Link>
        </Typography>
      </MenuItem>
      {/*
      <MenuItem onClick={this.handleAreaProdottiMenuClose}>
        <Typography variant="caption">
          <a href='https://www.elisazurich.it' target="_blank" className={classes.link}>{t('elisaWeb')}</a>
        </Typography>
      </MenuItem>
      */}
      <MenuItem onClick={this.handleMenuClose}>
        <Typography variant="caption">
          <CoreLink href={'/'} className={classes.link}>{t('logout')}</CoreLink>
        </Typography>
      </MenuItem>
      </Menu>
    );
    
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          elevation={0}
        >
          {/*<Toolbar className={classes.toolBarBottomNone	} >*/}
            {/*<img src={logo}  alt="logo-Milano-header"/>*/}
          
            <Toolbar>
            	<Grid container justify='space-between'>
            	 {/*
            	  {this.props.menuItems.map((menuItem, index) => (
	            	<Grid item className={index === 1 ? classes.menuItemMarginLeftAuto : ''} key={index}>   	
	            	  {(this.props.loginUser.profilo.trim()==='' || this.props.loginUser.stato===STATO_UTENTE_PENDENTE) &&  
	            	    <img src={menuItem.image} alt={menuItem.title} className={classes.menuItem} />
	            	  }	
	            	  {this.props.loginUser.profilo.trim()!=='' && this.props.loginUser.stato!==STATO_UTENTE_PENDENTE && index===0 &&  
		            	<img src={menuItem.image} alt={menuItem.title} className={classes.menuItem} />
		              }	
		            </Grid>
            	  ))}
            	*/}
            	 
            	 {this.props.menuItems.map((menuItem, index) => (
            		<Fragment key={index}>
            		 {(this.props.loginUser.profilo.trim()==='' || this.props.loginUser.stato===STATO_UTENTE_PENDENTE) &&            			 
             			<Grid item className={index === 1 ? classes.menuItemMarginLeftAuto : ''} key={index}>
   	            	    	<img src={menuItem.image} alt={menuItem.title} className={classes.menuItem} />
   	            	    </Grid>    	
             		 }
 	            	 {this.props.loginUser.profilo.trim()!=='' && this.props.loginUser.stato!==STATO_UTENTE_PENDENTE && index===0 &&  
 	            		<Fragment>
 	            		 <Grid item key={index}>
   	            		 	<img src={menuItem.image} alt={menuItem.title} className={classes.menuItem} />
    	            	 </Grid>
   	            		 <Grid item className={classes.titleHeader}>
   	            		 	<Grid container direction='column' justify='center' alignItems='center'>
   	            		 		<Grid item>
		   	            		   <Typography display='inline' className={classes.appName}>{'ZURICH'}</Typography>
		   	            		   <Typography display='inline' className={classes.appNameCorsivo}>{'Semplifico'}</Typography>
   	            		 		</Grid>   	            		 		
   	            		 		{this.props.prodotto.codice!=='' &&
   	            		 		<Grid item>
   	            		 		  <Typography display='inline' className={classes.prodottoName}>{this.props.prodotto.title}</Typography>
   	            		 		</Grid> 
   	            		 		}
   	            		 	</Grid>   	            		 	
		        		 </Grid>
		        		 </Fragment>
   	            	 }  		            
  		            </Fragment>
              	  ))}            	
            	
  
            	{this.props.loginUser.profilo.trim()!=='' && this.props.loginUser.stato!==STATO_UTENTE_PENDENTE &&
            	  <Fragment>
            	  
                  <Grid item className={classes.menuItemMarginLeftAuto}>
	                <div className={classes.sectionDesktop}>
		              <IconButton
		                aria-haspopup="true"
		                onClick={this.handleProfileMenuOpen}
		                className={classes.iconStyle}>
		            	{/*<AccountCircle className={classNames({[classes.appBarShift]: open,})}/>*/}
		            	<MenuIcon />
		              </IconButton>
		            </div>
		            <div className={classes.sectionMobile}>
		              <IconButton
		                aria-haspopup="true"
		                onClick={this.handleMobileMenuOpen}
		                className={classes.iconStyle}>
		                <MoreIcon />
		              </IconButton>
		           </div>
		         </Grid>
		         </Fragment>
            	}
            	</Grid>
            </Toolbar>
          {/*                    <div className={classes.sectionDesktop}>
            <MenuComposition menuItems={this.props.menuItems}/>
          </div>*/}
        </AppBar>
        {renderMenu}
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
  //shrinkAppBar: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(moreStyles, { withTheme: true })(MenuAppBar)));