import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';

import RadioButtonComp from '../../../common/RadioButtonComp';
import SelectComp from '../../../common/SelectComp';

import { radioButtonPagatorePremio } from '../../../store/radioButtonStore';

import DatiModPagBonifico from './DatiModPagBonifico';
import DatiModPagAssegno from './DatiModPagAssegno';
import DatiModPagReimpiego from './DatiModPagReimpiego';
import DatiModPagBonificoEstero from './DatiModPagBonificoEstero';
import DatiModaPagCarta from './DatiModPagCarta';

import * as actions from './action/datiModalitaPagamento';
import * as actionType from './actionType/datiModalitaPagamento';
import * as actionsPagBonifico from './action/datiModPagBonifico';
import * as actionTypePagBonifico from './actionType/datiModPagBonifico';

import ButtonComp from '../../../common/ButtonComp';
import { textTrim } from '../../../../utility/genericUtility';
import { MODALITA_PAGAMENTO_TYPE, CONTRAENTE, ESECUTORE_FISICO, ALTRO_PAGAMENTO } from '../../../../utility/jsConstants';
import { getNomeIntestatarioFromTipo } from '../../../../utility/storeUtility';
import DatiModPagAltro from './DatiModPagAltro';

const styles = theme => ({
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiModalitaPagamento.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiModalitaPagamento: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento,
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    contraenteGiuridicoValida: state.areaContraenteReducer.contraenteGiuridico.anagraficaValida,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModalitaPagamento.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiModalitaPagamento: (unDato, unActionType) => dispatch(actions.updateDatiModalitaPagamento(unDato, unActionType)),
    updateDatiModPagBonifico: (unDato, unActionType) => dispatch(actionsPagBonifico.updateDatiModPagBonifico(unDato, unActionType)),
    updateNeedEsecutore: () => dispatch(actions.updateNeedEsecutore())
  };
};
	
class DatiModalitaPagamento extends React.Component {
	
  constructor(props) {
    super(props);
    const datiModalitaPagamento = this.props.datiModalitaPagamento;
    this.state = {
      pagatorePremio: datiModalitaPagamento.pagatorePremio,
      modalitaPagamento: datiModalitaPagamento.modalitaPagamento,
      descModalitaPagamento: datiModalitaPagamento.descModalitaPagamento,
      controlloIbanModalitaPagamento: '',
      mancaSoggettoTerzo: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
	};
  };

  componentDidMount = () => {
    if(this.props.contraenteGiuridicoValida !== null) {
      this.props.updateDatiModalitaPagamento('1', actionType.UPDATE_PAGATORE_PREMIO); 	
    }
  }
	
  handleChangeConfermaDatiModalitaPagamento = event => {
    this.props.updateDatiModalitaPagamento(true, actionType.SALVA_DATI_MODALITA_PAGAMENTO);	  
    this.props.updateDatiModPagBonifico('CHECK_IBAN', actionTypePagBonifico.UPDATE_CONTROLLO_IBAN_PAG_BONIFICO);
  } 
  
  handleChangePagatorePremio = event => {
	//console.log('#RTCFA DatiModalitaPagamento pagatorePremio new value:' + event.target.value);
    const nuovoPagatorePremio = textTrim(event.target.value, true);
    const vecchioPagatorePremio = textTrim(this.state.pagatorePremio, true); 
    if (nuovoPagatorePremio !== vecchioPagatorePremio) {	  
      this.props.updateDatiModalitaPagamento(nuovoPagatorePremio, actionType.UPDATE_PAGATORE_PREMIO); 	
      this.setState({
        pagatorePremio: nuovoPagatorePremio,
      });
    }
    this.mancaSoggettoTerzo();
  };
		  
  handleChangeModalitaPagamento = event => {
    //console.log('#RTCFA DatiModalitaPagamento modalitaPagamento new value:' + event); 
    const nuovaModalitaPagamento = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaModalitaPagamento = textTrim(this.state.modalitaPagamento, true); 
    if (nuovaModalitaPagamento !== vecchiaModalitaPagamento) {	  
      this.props.updateDatiModalitaPagamento(event, actionType.UPDATE_MODALITA_PAGAMENTO); 	
      this.props.updateDatiModalitaPagamento('', actionType.SALVA_DATI_MODALITA_PAGAMENTO); 	
      this.props.updateDatiModalitaPagamento(null, actionType.UPDATE_DATI_MODALITA_PAGAMENTO_VALIDA); 	
      this.setState({
        controlloIbanModalitaPagamento: '',
        modalitaPagamento: (event === null) ? '' : event.value,
        descModalitaPagamento: (event === null) ? '' : event.label,
        isPresenteErrore: null
      });
    }
  }  
	
  handleChangeControlloIban = (controlloIban) => {
    this.setState({
	  controlloIbanModalitaPagamento: controlloIban,
    });
  }
  
  isRequiredModalitaPagamento = () => {
    return true;
  }
  
  isRequiredPagatorePremio = () => {
	return true;
  }
  
  initStatoCampi = () => {
    return [ {id: 'pagatorePremio', isError: false},
             {id: 'modalitaPagamento', isError: false},  
             {id: 'modPagBonifico', isError: false},  
             {id: 'modPagAssegno', isError: false},
             {id: 'modPagReimpiego', isError: false},                 
             {id: 'modPagBonificoEstero', isError: false},                 
             {id: 'modPagAltro', isError: false},                 
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  mancaSoggettoTerzo = () => {
    let manca = false;
    if(this.props.datiModalitaPagamento.pagatorePremio === '2') {
      if(this.props.contraenteFisico.datiEsecutore.flEsecutore !== 'S') {
        manca = true;
        this.props.updateNeedEsecutore();
      } else {
        if(!this.props.contraenteFisico.anagraficaValida) {
          manca = true;
          this.props.updateNeedEsecutore();
        }
      }
    }
    this.setState({
      mancaSoggettoTerzo: manca
    })
  }

  render() {
    const { classes, datiModalitaPagamento, dominio } = this.props; 
    const { t } = this.props; //hoc 
    const controlloDati = datiModalitaPagamento.controlloDati;   
    const tipoCarta = (datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.CARTA_DI_CREDITO) ? MODALITA_PAGAMENTO_TYPE.CARTA_DI_CREDITO : MODALITA_PAGAMENTO_TYPE.CARTA_DI_DEBITO;
    const pagatorePremio = this.state.pagatorePremio;
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item md={6} xs={12}>
          <RadioButtonComp onChange={this.handleChangePagatorePremio} radioComp={radioButtonPagatorePremio} selectedValue={datiModalitaPagamento.pagatorePremio} classes={{divider: classes.divider}} 
                           required={this.isRequiredPagatorePremio()} controlloDati={controlloDati} id='pagatorePremio' onError={this.handleError} readonly={this.props.contraenteGiuridicoValida !== null ? true : false} />
        </Grid>    
        <Grid item md={6} xs={12}>      
          <SelectComp onChange={this.handleChangeModalitaPagamento} options={dominio.modalitaPagamentoItems} ricerca='comboRicerca' etichetta='modalitaPagamento' 
                      value={{value: datiModalitaPagamento.modalitaPagamento, label: datiModalitaPagamento.descModalitaPagamento}} 
                      required={this.isRequiredModalitaPagamento()} controlloDati={controlloDati} id='modalitaPagamento' onError={this.handleError} />
        </Grid>  
        {datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.BONIFICO_ITALIA &&
        <Grid item xs={12}>  
          <DatiModPagBonifico controlloDati={controlloDati} onChangeControlloIban={this.handleChangeControlloIban} 
                              id={'modPagBonifico'} onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
        {datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.BONIFICO_ESTERO &&
        <Grid item xs={12}>  
          <DatiModPagBonificoEstero controlloDati={controlloDati} onChangeControlloIban={this.handleChangeControlloIban} 
                              id={'modPagBonificoEstero'} onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
        {(datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.ASSEGNO_BANCARIO || 
          datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.ASSEGNO_CIRCOLARE) &&
        <Grid item xs={12}>  
      	  <DatiModPagAssegno controlloDati={controlloDati} id={'modPagAssegno'} onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
	      {datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.REIMPIEGO &&        
        <Grid item xs={12}>  
          <DatiModPagReimpiego controlloDati={controlloDati} id={'modPagReimpiego'} onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
        {datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.CARTA_DI_DEBITO &&
        <Grid item xs={12}>
          <DatiModaPagCarta tipoCarta={tipoCarta} controlloDati={controlloDati} id='modPagCarta' onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
        {datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.CARTA_DI_CREDITO &&
        <Grid item xs={12}>
          <DatiModaPagCarta tipoCarta={tipoCarta} controlloDati={controlloDati} id='modPagCarta' onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
        {datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.ALTRO_PAGAMENTO &&
        <Grid item xs={12}>
          <DatiModPagAltro controlloDati={controlloDati} id='modPagAltro' onError={this.handleError} pagatorePremio={pagatorePremio} />
        </Grid>
        }
        <Grid item xs={12}>
          <Grid container direction='row' justify='center' alignItems='center' >
            <Grid item>
            {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
              <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
            }
            {(controlloDati === true) && (this.state.isPresenteErrore === false) && 
            	((this.state.controlloIbanModalitaPagamento === 'OK' &&
                  datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.BONIFICO_ITALIA) || 
            	  datiModalitaPagamento.modalitaPagamento !== MODALITA_PAGAMENTO_TYPE.BONIFICO_ITALIA
                ) &&
              <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
            }            
            {controlloDati === true && this.state.controlloIbanModalitaPagamento === 'KO' &&
              <Typography className={classes.textErrorMessage}>{'CODICE IBAN ERRATO'}</Typography>
            }
            {this.state.mancaSoggettoTerzo &&
              <Typography className={classes.textErrorMessage}>{t('soggettoTerzoError')}</Typography>
            }
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
            <Grid item>
              <ButtonComp
                    aClassName={classes.button}
                    buttonLabel={t('conferma')}
                    onClick={this.handleChangeConfermaDatiModalitaPagamento}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiModalitaPagamento componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########DatiModalitaPagamento componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########DatiModalitaPagamento componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
//	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiModalitaPagamento.controlloDati === true)) {		
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiModalitaPagamento(!isPresenteCampiConErrore, actionType.UPDATE_DATI_MODALITA_PAGAMENTO_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
    if(prevState.modalitaPagamento !== this.state.modalitaPagamento) {
      this.props.updateDatiModalitaPagamento(getNomeIntestatarioFromTipo(), actionType.RESET_DATI_MOD_PAGAMENTO);
      this.setState({
        statoCampi: this.initStatoCampi()
      })
    }
  }    
}

DatiModalitaPagamento.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiModalitaPagamento)));

