import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { checkBoxValue, textTrim } from '../../utility/genericUtility';

const styles = theme => ({  
  label: {
    color: theme.palette.color.windowsBlue,
  },
});

class CheckboxComp extends React.Component {
		
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.checked,
      typing: false,
    }
  };
  
  handleClick = event => {
    console.log('CheckBoxComp handleClick this.state.id', this.state.id);
    console.log('CheckBoxComp handleClick event.target.checked', event.target.checked);
    this.setState({
      id: event.target.checked,
      typing: true,      
    });
    if(this.props.onClick) {
      this.props.onClick(event);
    }
  };

  handleChange = event => {
    console.log('CheckBoxComp handleChange this.state.id', this.state.id);
    console.log('CheckBoxComp handleChange event.target.checked', event.target.checked);
    this.setState({
      id: event.target.checked,
      typing: false,      
    });
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  };

  handleError = (isError, id) => {
    //    console.log('#######TextFieldComp handleError', id + ' ' + isError);
    this.setState({
      isError: isError,
    });
    if (this.props.onError) {
      this.props.onError(id, isError);
    }
  };  

  valueText = (trim) => {
    const aValue = (this.state.id === true) ? textTrim(this.state.value, trim) : textTrim(this.props.value, trim);
    return aValue;
    };
    
  handleOnBlur = event => {
    console.log('CheckBoxComp handleOnBlur this.state.id', this.state.id);
    console.log('CheckBoxComp handleOnBlur event.target.checked', event.target.checked);
    this.setState({
      id: event.target.checked,
      typing: false,    
    });
    if(this.props.onBlur) {
      this.props.onBlur(event);  
    }
  };
		  
  valueCheckBox = () => {
    const aValue = (this.state.typing === true) ? checkBoxValue(this.state.id) : checkBoxValue(this.props.checked);
    return aValue;
  };
	  
  render () {
    const { classes, onBlur, id, aLabel, checked, disabled, controlloDati, required, isControlError, checkError } = this.props;
    const { t } = this.props; //hoc
    const aValue = this.valueCheckBox();    
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aLabelTxt = t(aLabel);
    const style = checkError && controlloDati && isRequired && !isDisabled ? {color: 'red'} : {};
    return (
      <FormControlLabel
        control={<Checkbox
                   checked={aValue}
                   onClick={this.handleClick}
                   onChange={this.handleChange}
                   onBlur={this.handleOnBlur}
                   value={aLabelTxt}   
                   disabled={disabled}
                   style={style}
                   /*style={{color: '#4E8FC9'}} ######## Do an overrride of MuiThemes in index.js*/
                 />}
        label={<Typography className={classes.label}>{aLabelTxt}</Typography>}
      />
    )
  };



  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueCheckBox(controlloDati);
    const isError = (controlloDati === true) && isRequired && !isDisabled && (!aValue);
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
      //      console.log('#######prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
      //      console.log('#######prevIsError !== isError, isError = ' + isError + ', id: ' + id);
      //      console.log('#######prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);
    }
  };
};

CheckboxComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default withStyles(styles, { withTheme: true })(TextFieldComp);
export default withTranslation()(withStyles(styles, { withTheme: true })(CheckboxComp));
