import * as actionType from '../actionType/datiRipartizionePremio';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy, textTrim } from '../../../../../utility/genericUtility';
import { datiRipartizionePremioInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiRipartizionePremioReducer = (state = initialState, action) => {
	
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaRipartizionePremio(state);
	  return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.SALVA_DATI_RIPARTIZIONE_PREMIO:
      const caratteristicheContrattoDatiRipartizionePremioSalva = {...state}.caratteristicheContratto;
      const datiRipartizionePremioSalva = {...caratteristicheContrattoDatiRipartizionePremioSalva}.datiRipartizionePremio;
      datiRipartizionePremioSalva.controlloDati = action.unDato;
      caratteristicheContrattoDatiRipartizionePremioSalva.datiRipartizionePremio = {...datiRipartizionePremioSalva};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiRipartizionePremioSalva,
      };
    case actionType.UPDATE_DATI_RIPARTIZIONE_PREMIO_VALIDA:
      const caratteristicheContrattoDatiRipartizionePremioValida = {...state}.caratteristicheContratto;
      const datiRipartizionePremioValida = {...caratteristicheContrattoDatiRipartizionePremioValida}.datiRipartizionePremio;
      datiRipartizionePremioValida.datiRipartizionePremioValida = action.unDato;
      caratteristicheContrattoDatiRipartizionePremioValida.datiRipartizionePremio = {...datiRipartizionePremioValida};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiRipartizionePremioValida,
      };      
    case actionType.SALVA_ESITO_PERCENTUALE_ALLOCAZIONE:
      const caratteristicheContrattoDatiRipartizionePremioSalvaEsitoPercentuale = {...state}.caratteristicheContratto;
      const datiRipartizionePremioSalvaEsitoPercentuale = {...caratteristicheContrattoDatiRipartizionePremioSalvaEsitoPercentuale}.datiRipartizionePremio;
      datiRipartizionePremioSalvaEsitoPercentuale.esitoPercentualeAllocazione = action.esitoPercentualeAllocazione;
      caratteristicheContrattoDatiRipartizionePremioSalvaEsitoPercentuale.datiRipartizionePremio = {...datiRipartizionePremioSalvaEsitoPercentuale};
      
// 2019-12-09 PFD: spostato in nel reducer di 'datiRipartizionePremioFondi'
//      const datiRipartizionePremioSalvaEsitoPercentualeFondo = {...caratteristicheContrattoDatiRipartizionePremioSalvaEsitoPercentuale}.datiRipartizionePremioFondi
//      datiRipartizionePremioSalvaEsitoPercentualeFondo.esitoPercentualeFondo = action.esitoPercentualeFondo;
//      caratteristicheContrattoDatiRipartizionePremioSalvaEsitoPercentuale.datiRipartizionePremioFondi = datiRipartizionePremioSalvaEsitoPercentualeFondo;
      
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoDatiRipartizionePremioSalvaEsitoPercentuale,
      };
    case actionType.UPDATE_GESTIONE_SEPARATA:
      const caratteristicheContrattoGestioneSeparata = {...state}.caratteristicheContratto;
      const datiRipartizionePremioGestioneSeparata = {...caratteristicheContrattoGestioneSeparata}.datiRipartizionePremio;
//2019-12-17 PFD:      datiRipartizionePremioGestioneSeparata.gestioneSeparata = action.unDato;
      datiRipartizionePremioGestioneSeparata.gestioneSeparata = action.unDato.valore;
      caratteristicheContrattoGestioneSeparata.datiRipartizionePremio = {...datiRipartizionePremioGestioneSeparata};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoGestioneSeparata,
      };   
    case actionType.UPDATE_LINEA_LIBERA:
      const caratteristicheContrattoLineaLibera = {...state}.caratteristicheContratto;
      const datiRipartizionePremioLineaLibera = {...caratteristicheContrattoLineaLibera}.datiRipartizionePremio;
//2019-12-17 PFD:      const lineaLibera = action.unDato;
      const lineaLibera = action.unDato.valore; ;
      datiRipartizionePremioLineaLibera.lineaLibera = lineaLibera;
//2019-12-17 PFD:      datiRipartizionePremioLineaLibera.lineaLiberaCodice = action.codiceLinea;
      datiRipartizionePremioLineaLibera.lineaLiberaCodice = action.unDato.codice;
      datiRipartizionePremioLineaLibera.lineaLiberaDescrizione = action.unDato.descrizione;

      if (textTrim(lineaLibera, true) === '' ) {
    	  // reset del flag ribilanciamento
    	  datiRipartizionePremioLineaLibera.ribilanciamento = '';
      }
      caratteristicheContrattoLineaLibera.datiRipartizionePremio = {...datiRipartizionePremioLineaLibera};
      
//2022-04-06 PFD: non c'e' motivo di resettare la lista fondi gia inseriti.     const datiRipartizionePremioFondi = {...caratteristicheContrattoLineaLibera}.datiRipartizionePremioFondi
//2022-04-06 PFD: non c'e' motivo di resettare la lista fondi gia inseriti.     datiRipartizionePremioFondi.fondo = [];
//2022-04-06 PFD: non c'e' motivo di resettare la lista fondi gia inseriti.     caratteristicheContrattoLineaLibera.datiRipartizionePremioFondi = datiRipartizionePremioFondi;
      
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoLineaLibera,
      };
    case actionType.UPDATE_LINEA_GUIDATA:
        const caratteristicheContrattoLineaGuidata = {...state}.caratteristicheContratto;
        const datiRipartizionePremioLineaGuidata = {...caratteristicheContrattoLineaGuidata}.datiRipartizionePremio;
        // 2019-10-09 PFD
        const datiRipartizionePremioFondiLineaGuidata = {...caratteristicheContrattoLineaGuidata}.datiRipartizionePremioFondi;
        datiRipartizionePremioFondiLineaGuidata.esitoPercentualeFondo = 'OK';
        
        let linea = {
		  codiceLinea: '',
		  descrLinea: '',
		  linea: '',
		};
	    let found = false;
	    const codiceLinea = action.unDato.codice;
	    const descrLinea = action.unDato.descrizione;
	    const valore = action.unDato.valore;
	    datiRipartizionePremioLineaGuidata.lineaGuidata.forEach(function(item, index, array) {
//2019-12-17 PFD: if(item.codiceLinea === (action.codiceLinea) ){
	    if(item.codiceLinea === (codiceLinea) ){
//2019-12-17 PFD:     	    item.linea = action.unDato;
     	    item.codiceLinea = codiceLinea;
     	    item.descrLinea = descrLinea;
     	    item.linea = valore;
    	    found = true;
    	  }
    	});
	    if(!found){
	      linea = {
//2019-12-17 PFD:	        codiceLinea: action.codiceLinea,
//2019-12-17 PFD:			linea: action.unDato,
	  	    codiceLinea: codiceLinea,
	  	    descrLinea: descrLinea,
	  	    linea: valore,
	  	  };
	      datiRipartizionePremioLineaGuidata.lineaGuidata = new Array();
	      datiRipartizionePremioLineaGuidata.lineaGuidata.push(linea);
	    }
        caratteristicheContrattoLineaGuidata.datiRipartizionePremio = {...datiRipartizionePremioLineaGuidata};
        caratteristicheContrattoLineaGuidata.datiRipartizionePremioFondi = {...datiRipartizionePremioFondiLineaGuidata};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoLineaGuidata,
        };
    case actionType.UPDATE_LINEE_GUIDATE_TUTTE:
        const caratteristicheContrattoLineeGuidateTutte = {...state}.caratteristicheContratto;
        const datiRipartizionePremioLineeGuidateTutte = {...caratteristicheContrattoLineeGuidateTutte}.datiRipartizionePremio;
        
        datiRipartizionePremioLineeGuidateTutte.lineeGuidateTutte = action.unDato;
        caratteristicheContrattoLineeGuidateTutte.datiRipartizionePremio = {...datiRipartizionePremioLineeGuidateTutte};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoLineeGuidateTutte,
        };
    case actionType.UPDATE_RIBILANCIAMENTO:
        const caratteristicheContrattoRibilanciamento = {...state}.caratteristicheContratto;
        const datiRipartizionePremioRibilanciamento = {...caratteristicheContrattoRibilanciamento}.datiRipartizionePremio;
        datiRipartizionePremioRibilanciamento.ribilanciamento = action.unDato.valore;
        caratteristicheContrattoRibilanciamento.datiRipartizionePremio = {...datiRipartizionePremioRibilanciamento};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoRibilanciamento,
        }; 
    default:
      return state;
  }
}

export const inizializzaRipartizionePremio = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiRipartizionePremio = jsonCopy(datiRipartizionePremioInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiRipartizionePremioReducer;