import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonComp from '../../../common/ButtonComp';
import moment from 'moment';
import 'moment/locale/it';
import classNames from 'classnames';

import { uris, fetchParam } from '../../../../uris';

import * as actions from './action/datiQuestionario';
import * as actionType from './actionType/datiQuestionario';

import * as dominioActions from '../../../common/action/dominio';

import DomandeQuestionario from './DomandeQuestionario';
import Allegato_1_QuestOncologico from './Allegato_1_QuestOncologico';
import ModalBasicInfoComp from '../../../common/ModalBasicInfoComp';

import { COD_QUESTIONARIO_ANAMNESTICO, COD_BLOCCO_QUESTIONARIO_100, COD_BLOCCO_QUESTIONARIO_101, 
         COD_BLOCCO_QUESTIONARIO_102, COD_BLOCCO_QUESTIONARIO_103, COD_BLOCCO_QUESTIONARIO_104, COD_BLOCCO_QUESTIONARIO_113, 
         COD_BLOCCO_QUESTIONARIO_212, COD_BLOCCO_QUESTIONARIO_307, QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO } from '../../../../utility/jsConstants';
import { checkEtaMassima } from '../../../../utility/genericUtility';
import { getQuestionarioAnamnestico } from '../../../../utility/questionarioUtility';

const styles = theme => ({
    titolo: {
      textAlign: 'center',	  
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: theme.button,
    textErrorMessage: theme.textErrorMessage,
    divSezioneOblio: {
      width:'100%',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    txtSezioneOblio: {
      fontWeight: 'bold',
      fontSize: 20,
    },
    sezioneAss: {
      width:'100%',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },  
    sezioneDotted: {
      width:'100%',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(3),
    },
    sezioneDotted_2: {
      width:'100%',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
    colMarine: {
      color: theme.marine,
    },
 });

 const modalBoxStyleAllegato1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY:'scroll',
};

  const mapStateToProps = state => {
    return {
      datiQuestionarioAnamnesticoSemplificato: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato,
      datiQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico,
      datiQuestionarioAnamnesticoMalattiaGrave: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave,
      prodotto: state.areaProdottoReducer.prodotto,
      datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,
      assicurato: state.areaAssicuratoReducer.assicuratoFisico,
      isChangedFromSemplificato: state.areaDatiQuestionarioReducer.questionario.isChangedFromSemplificato,
      tipoCurrentQuestionario: state.areaDatiQuestionarioReducer.questionario.tipoCurrentQuestionario,
      dataNascitaContrFis: state.areaContraenteReducer.contraenteFisico.datiGenerali.dataNascita,
      professioniQas: state.areaDatiDominioReducer.dominio.professioniQas,
      sportQas: state.areaDatiDominioReducer.dominio.sportQas
    };
  };
      
  const mapDispatchToProps = dispatch => {
    return {
      updateQuestionario: (unDato, tipoQuestionario, unActionType) => dispatch(actions.updateQuestionario(unDato, tipoQuestionario, unActionType)),	  
      salvaEsitoDatiQuestionario: (esito, esitoMessaggio, tipoQuestionario) => dispatch(actions.salvaEsitoDatiQuestionario(esito, esitoMessaggio, tipoQuestionario)),
      updateIsChangedFromSemplificato: (unDato, unActionType) => dispatch(actions.updateIsChangedFromSemplificato(unDato, unActionType)),
      updateTipoCurrentQuestionario: (unDato, unActionType) => dispatch(actions.updateTipoCurrentQuestionario(unDato, unActionType)),
//      updateShowSemplificato: (showSemplificato, showOnlySemplificato, unActionType) => dispatch(actions.updateShowSemplificato(showSemplificato, showOnlySemplificato, unActionType)),
      dominioProfessioniQas: (unaLista) => dispatch(dominioActions.dominioProfessioniQas(unaLista)),
      dominioSportQas: (unaLista) => dispatch(dominioActions.dominioSportsQas(unaLista))
    };
  };
  
  
  class DatiQuestionarioSanitarioSemplificato extends React.Component {
    constructor(props) {
        super(props);        
//2020-02-08 PFD - START 
//        const dataNascita = (this.props.assicurato.flAssicuratoUgualeContraente === 'S') ? this.props.dataNascitaContrFis : this.props.assicurato.datiGenerali.dataNascita;
//        const isQuestionarioSemplificato = checkEtaMassima(moment(), dataNascita, 55, 6)
//                                  && this.props.datiPremioCapitaleAssicurato.capitaleAssicurato
//                                  && this.props.datiPremioCapitaleAssicurato.capitaleAssicurato <= 200000
//                                  && !this.props.isChangedFromSemplificato
//                                  && !this.props.datiQuestionarioAnamnestico.datiQuestionarioValida
//                                  && !this.props.datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida
//                                  && !this.props.datiPremioCapitaleAssicurato.malattiaGrave;
//		if(!isQuestionarioSemplificato) {
//            this.props.updateShowSemplificato(true, false, actionType.UPDATE_SHOW_SEMPLIFICATO);
//      }
//2020-02-08 PFD - END
        
        this.state = {
            questionarioAnamnestico: this.props.datiQuestionarioAnamnesticoSemplificato.questionarioAnamnesticoSemplificato,
            tipoQuestionarioAnamnestico: QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO,
            esito: '',
            esitoMessaggio: '',
            counterDomande: 0,
            counterRisposte: 0,
            counterRisposteSi: 0,
            mancaRisposta: 0,
            openModalAllegati: false,
        }
    }

      
    componentWillMount = () => {
      const dataOggiFormattata = moment().format('DD/MM/YYYY');
      const siglaVersione = 'ZURI';
      const opts = {
          siglaVersione: siglaVersione,
          dataRichiesta: dataOggiFormattata,
      }
      
      fetch(uris.selectProfessioniQas, fetchParam(opts))
      .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
      )
      .then((result) => {
          this.props.dominioProfessioniQas(result);
      })

      fetch(uris.selectSportsQas, fetchParam(opts))
      .then(
        response => response.json(),
        error => console.error('An error occurred.', error)
      )
      .then((result) => {
        this.props.dominioSportQas(result);
      })

  }

    handleChangeConfermaDatiQuestionario = event => {
        // 
    	this.checkRispostaSi();
        
        //2020-02-08 PFD - START
//        if(this.state.tipoQuestionarioAnamnestico !== QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO) {
//          this.props.updateQuestionario(true, this.state.tipoQuestionarioAnamnestico, actionType.SALVA_DATI_QUESTIONARIO);
//        }
//        if(this.props.tipoCurrentQuestionario !== QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE && this.props.tipoCurrentQuestionario !== QUESTIONARIO_ANAMNESTICO){
//           this.props.updateTipoCurrentQuestionario(QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO, actionType.UPDATE_TIPO_CURRENT_QUESTIONARIO);
//        }
        
    	if(this.props.tipoCurrentQuestionario !== QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO){
    		this.props.updateTipoCurrentQuestionario(QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO, actionType.UPDATE_TIPO_CURRENT_QUESTIONARIO);
    	}
        //2020-02-08 PFD - END
    } 
    
    
  checkRispostaSi = () => {
    // Controlla se esiste almeno una risposta == 'si'
    if(this.state.tipoQuestionarioAnamnestico === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO) {
      let counterRisposte = 0;
      let counterDomande = 0;
      let mancaRisposta = 0;
      this.state.questionarioAnamnestico.map((listaDomande) => {
        listaDomande.domande.map((domanda) => {
          if(domanda.numDomanda && domanda.numDomanda > 0) {
            counterDomande++;
          }
          domanda.risposte.map((risposta) => {
            if(risposta.selezionato) {
              counterRisposte++;
              if(risposta.testo === "SI") {
                this.setState({
                  counterRisposteSi: this.state.counterRisposteSi++
                })
              }
              if(risposta.testo === '3' && !risposta.testoSelezionato) {
                counterRisposte--;
              }
            }
            this.setState({
              counterDomande: counterDomande
            })
          });
          this.setState({
            counterRisposte: counterRisposte
          })
        });
      });
      mancaRisposta = (counterRisposte < counterDomande) ? 1 : 2;
      this.setState({
        mancaRisposta: (counterRisposte < counterDomande) ? 1 : 2
      })
      if(this.state.counterRisposteSi === 0) {
        // Nel caso non esista si aggiorna il tipoQuestionario a "semplificato" e la variabile 'isChangedFromSemplificato' a 
        // false, così da mostrare il questionario completo
        this.props.updateIsChangedFromSemplificato(false, actionType.UPDATE_CHANGED_FROM_SEMPLIFICATO);
        if(mancaRisposta === 2) {
          this.props.updateQuestionario(true, this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);
//          this.props.updateShowSemplificato(true, true, actionType.UPDATE_SHOW_SEMPLIFICATO);          
        } else {
          this.props.updateQuestionario(false, this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);
        }
      } else {
        // Nel caso ci sia almeno una risposta con 'si', la variabile 'isChangedFromSemplificato' si aggiorna a true, 
        // che serve a mostrare l'alert per ricompilare il questionario e il nuovo accordion
//        this.props.updateShowSemplificato(true, false, actionType.UPDATE_SHOW_SEMPLIFICATO);
        this.props.updateIsChangedFromSemplificato(true, actionType.UPDATE_CHANGED_FROM_SEMPLIFICATO);
        this.props.updateQuestionario(false, this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);
        
      }
    }
    this.setState({
      counterRisposteSi: 0
    })
  }
  
  handleOpenModalAllegati = event => {	 
	  var openModalAllegatiValue = !this.state.openModalAllegati;
	  this.setState({
		  openModalAllegati: openModalAllegatiValue,
	  })
  };
  
  render() {
    const { classes } = this.props;   
    const { t } = this.props; //hoc
    return (
	  <div>
	  
	  	<div className={classes.divSezioneOblio}>
	  		<Typography variant="caption" className={classNames(classes.colMarine, classes.txtSezioneOblio)}>{t('dirittoObblioOncologico_1')}</Typography>
	  	</div>
  
	  	<div className={classes.sezioneAss}>
	  		<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_2')}</Typography>	    	
	  		<a href="" onClick={(event) => {
	  			event.preventDefault();
	  			this.handleOpenModalAllegati(event);
	  		}}
	  		>
	  		<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_3')}</Typography>
	  		</a>
  	
	  		<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_4')}</Typography>
	  	</div>
		
	  	<div className={classes.sezioneAss}>
		  	<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_5')}</Typography>
		</div>	    
		<div className={classes.sezioneDotted}>
		  	<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_6')}</Typography>
		</div>
		<div className={classes.sezioneDotted_2}>
			<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_7')}</Typography>
		</div>
	
		<ModalBasicInfoComp componente={Allegato_1_QuestOncologico} boxStyle={modalBoxStyleAllegato1} open={this.state.openModalAllegati} handleClose={this.handleOpenModalAllegati} />
        
		{this.state.questionarioAnamnestico.map((elemento, index) => (	
          index === 0 ?
          <div key={index} className={classes.titolo}>
            <Typography variant="h6">{elemento.sezione.testo}</Typography>
          </div>
			:
			<DomandeQuestionario key={index} domande={elemento.domande} titoloSezione={elemento.sezione.testo} tipoQuestionario={this.state.tipoQuestionarioAnamnestico} /> 
    	))}
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
	        <Grid container direction="row" justify="center" alignItems="center">	
	          <Grid item>
	            {this.state.esito==='OK' &&
	      	      <Typography className={classes.textErrorMessage}>{t('questionaIdoneoSempl')}</Typography>
	            }
	            {this.state.esito==='KO' &&
	      	      <Typography className={classes.textErrorMessage}>{t(this.state.esitoMessaggio)}</Typography>
	            }
              {this.state.mancaRisposta === 1 &&
                <Typography className={classes.textErrorMessage}>{t('307')}</Typography>
              }
              {this.state.mancaRisposta === 2 && !this.props.isChangedFromSemplificato &&
                <Typography className={classes.textErrorMessage}>{t('questionaIdoneoSempl')}</Typography>
              }
	          </Grid>
	        </Grid>
	      </Grid>  
        <Grid item xs={12}>
          {this.props.isChangedFromSemplificato &&
            <Grid container direction="row" justify="center" alignItems="flex-start">
              <Grid item>  
                <Typography className={classes.textErrorMessage}>{t('isChangedFromSemplificatoAlert')}</Typography>
              </Grid>
            </Grid>
          }
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
            <Grid item>
              <Grid item>
                <ButtonComp
                  aClassName={classes.button}
                  buttonLabel={'conferma'}
                  onClick={this.handleChangeConfermaDatiQuestionario}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
	    </Grid>
	  </div>
    );
  }
}
 


DatiQuestionarioSanitarioSemplificato.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiQuestionarioSanitarioSemplificato)));
  