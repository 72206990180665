// GENERIC APPLICATION 
export const generic = {
	// Loading Overlay
	activeOverlay: false,	
	labelOverlay: '',
	tabSelezionatoContraente: 0, // 0 CONTRAENTE FISICO, 1 CONTRAENTE GIURIDICO
	
	proposalInfo: {
        scopeAmleto: '', // Regola AMLETO_X_PROD in TZSE0988 :  // WARNING: la regola sul DB è invertita!! risultano in scope tutti i prodotti per i quali NON esiste la regola 'AMLETO_X_PROD'
        agenziaNonAdeguataOrigineFondi: '',
        scopeTcmEmissione: '', //2021-07-01 PFD: TCM_INDIV indica se il prodotto è in scope alla Regola TCM_EMISSIONE in TZSE0988
        tcmIndividualeValida: true, //2021-07-01 PFD: TCM_INDIV per i prodotti in scopeTcmEmissione indica se sono validi i criteri sul Premio/PEP Anagrafiche/Relazioni Paesi Black List
    },
};
