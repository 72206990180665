import * as actionType from '../actionType/datiLoginCambioPassword';
import initialState from '../../../../session/InitialState';

const areaDatiLoginCambioPasswordReducer = (state = initialState, action) => {
	
  switch (action.type) {
    case actionType.SALVA_DATI_LOGIN_CAMBIO_PASSWORD:
      const gestioneUtenzaDatiLoginCambioPasswordSalva = {...state}.gestioneUtenza;
      const datiLoginCambioPasswordSalva = {...gestioneUtenzaDatiLoginCambioPasswordSalva}.datiLoginCambioPassword;
      datiLoginCambioPasswordSalva.controlloDati = action.controlloDati;
      gestioneUtenzaDatiLoginCambioPasswordSalva.datiLoginCambioPassword = {...datiLoginCambioPasswordSalva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiLoginCambioPasswordSalva,
      };
    case actionType.MODIFICA_DATI_LOGIN_CAMBIO_PASSWORD:
      const gestioneUtenzaDatiLoginCambioPasswordModifica = {...state}.gestioneUtenza;
      const datiLoginCambioPasswordModifica = {...gestioneUtenzaDatiLoginCambioPasswordModifica}.datiLoginCambioPassword;
      datiLoginCambioPasswordModifica.password = action.password;
      datiLoginCambioPasswordModifica.passwordNuova = action.passwordNuova;
      datiLoginCambioPasswordModifica.passwordConferma = action.passwordConferma;
      gestioneUtenzaDatiLoginCambioPasswordModifica.datiLoginCambioPassword = {...datiLoginCambioPasswordModifica};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDatiLoginCambioPasswordModifica,
      };
    case actionType.UPDATE_UTENTE_LOGIN_CAMBIO_PASSWORD:
        const gestioneUtenzaUtente = {...state}.gestioneUtenza;
        const datiLoginCambioPasswordUtente = {...gestioneUtenzaUtente}.datiLoginCambioPassword;
        datiLoginCambioPasswordUtente.utente = action.unDato;
        gestioneUtenzaUtente.datiLoginCambioPassword = {...datiLoginCambioPasswordUtente};
        return {
          ...state,
        	gestioneUtenza: gestioneUtenzaUtente,
        };
    case actionType.UPDATE_PASSWORD_LOGIN_CAMBIO_PASSWORD:
      const gestioneUtenzaPassword = {...state}.gestioneUtenza;
      const datiLoginCambioPasswordPassword = {...gestioneUtenzaPassword}.datiLoginCambioPassword;
      datiLoginCambioPasswordPassword.password = action.unDato;
      gestioneUtenzaPassword.datiLoginCambioPassword = {...datiLoginCambioPasswordPassword};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaPassword,
      };
    case actionType.UPDATE_PASSWORD_NUOVA_LOGIN_CAMBIO_PASSWORD:
        const gestioneUtenzaPasswordNuova = {...state}.gestioneUtenza;
        const datiLoginCambioPasswordPasswordNuova = {...gestioneUtenzaPasswordNuova}.datiLoginCambioPassword;
        datiLoginCambioPasswordPasswordNuova.passwordNuova = action.unDato;
        gestioneUtenzaPasswordNuova.datiLoginCambioPassword = {...datiLoginCambioPasswordPasswordNuova};
        return {
          ...state,
        	gestioneUtenza: gestioneUtenzaPasswordNuova,
        };
    case actionType.UPDATE_PASSWORD_CONFERMA_LOGIN_CAMBIO_PASSWORD:
        const gestioneUtenzaPasswordConferma = {...state}.gestioneUtenza;
        const datiLoginCambioPasswordPasswordConferma = {...gestioneUtenzaPasswordConferma}.datiLoginCambioPassword;
        datiLoginCambioPasswordPasswordConferma.passwordConferma = action.unDato;
        gestioneUtenzaPasswordConferma.datiLoginCambioPassword = {...datiLoginCambioPasswordPasswordConferma};
        return {
          ...state,
        	gestioneUtenza: gestioneUtenzaPasswordConferma,
        };
    default:
      return state;
  }
}

export default areaDatiLoginCambioPasswordReducer;