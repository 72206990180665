import { jsonCopy } from '../../../utility/genericUtility';
import { datiGeneraliBenefGiuridicoInizializzato, datiResidenzaInizializzato, datiRelazContrInizializzato, datiCopiaDaAltraFiguraInizializzato, datiSocietariGiuridicoInizializzato, datiMandatoFiduciarioInizializzato, datiAttEconomicaGiuridicoInizializzato, titEffBenefVitaGiuridicoInizializzato } 
	from '../componentiBase/sezioniAnagrafica';

export const datiGeneraliBenefGiuridico = jsonCopy(datiGeneraliBenefGiuridicoInizializzato);
export const datiResidenza = jsonCopy(datiResidenzaInizializzato);
export const datiRelazContr = jsonCopy(datiRelazContrInizializzato);
export const datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
export const datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
export const datiAttEconomica = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
export const titolareEffBenefVita = jsonCopy(titEffBenefVitaGiuridicoInizializzato);
export const datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
export const titolareEffBenefVitaList = [];
export const titolareEffBenefVitaListInBenef = [];
export const flTitolareEffBenef = '';
