import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

const styles = theme => ({

});

class ButtonComp extends React.Component {
  
  handleClick = event => {
	if(this.props.onClick) {
      console.log('#RT1 ButtonComp this.props.onClick', this.props.onClick);
      this.props.onClick(event);
//      this.props.onClick(this.props.obj1);
    }
  }; 

  render() {
    const { aKey, to, component, aClassName, buttonLabel, onClick, disabled, href, download } = this.props;
/*    console.log('#RTCFA Login ButtonComp props to', to);
    console.log('#RTCFA Login ButtonComp props aKey', aKey);
    console.log('#RTCFA Login ButtonComp props component', component);
    console.log('#RTCFA Login ButtonComp props aClassName', aClassName);
    console.log('#RTCFA Login ButtonComp props buttonLabel', buttonLabel);
    console.log('#RTCFA Login ButtonComp props onClick', onClick);*/  
    const { t } = this.props; //hoc    
    console.log('#RT ButtonComp.to: #' + to +'#');  
    return (
	  <div>	  
        <Button to={to} component={component} className={aClassName} onClick={this.handleClick} disabled={this.props.disabled} href={href} download={this.props.download?true:false}> 
    	  {t(buttonLabel)}
        </Button>      
      </div>  
    )
  }
}

ButtonComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(ButtonComp));
