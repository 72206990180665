import * as actionType from '../actionType/datiLoginCambioPassword';

export const updateDatiLoginCambioPassword = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaDatiLoginCambioPassword = controlloDati => {
  return {
    type: actionType.SALVA_DATI_LOGIN_CAMBIO_PASSWORD,
	controlloDati,
  }
};

export const modificaDatiLoginCambioPassword = (password, passwordNuova, passwordConferma) => {
  return {
    type: actionType.MODIFICA_DATI_LOGIN_CAMBIO_PASSWORD,
    password,
    passwordNuova,
    passwordConferma,
  }
};