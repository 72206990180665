import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import RisposteCondizionateQuestionario from './RisposteCondizionateQuestionario';
import TextTestoQuestionario from './TextFieldQuestionario';
import LegendaQuestionarioComponent from './LegendaQuestionarioComponent';

const styles = theme => ({
  root: {
    color: theme.palette.color.windowsBlue,
    '&$checked': {
      color: theme.palette.color.windowsBlue,
    },
  },
  checked: {
  },
  textField: theme.textField,
});
	
class RadioButtonQuestionario extends React.Component {
	
	constructor(props) {
		super(props);
		let initialSelectedValue = '';
		props.risposte.forEach(function(item, index, array) {
		  if(item.selezionato){
		    initialSelectedValue = item.id;
		  }
		});
		this.state = {
			selectedValue: initialSelectedValue,
		};
	};

	handleChange = event => {
		this.setState({ selectedValue: event.target.value });
		if(this.props.onChange){
			this.props.onChange(event);
		}
	};
	  
  render() {
    const { classes, risposte, numDomanda, idRisposta, tipoQuestionario, didascalia, tipoAttivita } = this.props;
    
	let risposteTesto = [];
    
    return (
     <Grid container direction='column' justify="flex-start" alignItems='flex-start'>
     {risposte.map((risposta, index) => {
       risposteTesto = []; ('T' === risposta.tipoCampoAggiuntivo || 'N' === risposta.tipoCampoAggiuntivo) && risposteTesto.push(risposta);
       return (
         <Grid container key={index}>
    	   <Grid style={{display:'flex'}} justify='center' alignItems='flex-start' key={index}>
    	     <FormControlLabel
			   key={index}
    	       control={<Radio
			     checked={this.state.selectedValue === risposta.id}
				 onChange={this.handleChange}
				 value={risposta.id}
				 name={risposta.id}
				 aria-label={risposta.id}
				 classes={{ root: classes.root, checked: classes.checked }}
			   />}
			   label={('T' === risposta.tipoCampoAggiuntivo || 'N' === risposta.tipoCampoAggiuntivo) ? <TextTestoQuestionario risposte={risposteTesto} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='horizontal' /> : <Typography variant="caption">{risposta.testo}</Typography>}
			   />
			 {didascalia && <div style={{alignSelf: 'center'}}><LegendaQuestionarioComponent numDidascalia={risposta.testo} tipoAttivita={tipoAttivita} style={{justify: 'flex-start'}} /></div>}
	         </Grid>
	         <RisposteCondizionateQuestionario key={risposta.id} risposte={risposta.risposteCondizionate} numDomanda={numDomanda} idRisposta={risposta.id} mostraRisposteCondizionate={risposta.mostraRisposteCondizionate} tipoQuestionario={tipoQuestionario} />
	       </Grid>
          )
         })} 
	   </Grid>
	);
  }
}

RadioButtonQuestionario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonQuestionario);


