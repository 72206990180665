import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import SelectComp from '../../../common/SelectComp';
import Paper from '@material-ui/core/Paper';

import * as actions from './action/datiTakeProfit';
import * as actionType from './actionType/datiTakeProfit';

import ButtonComp from '../../../common/ButtonComp';
import { textTrim } from '../../../../utility/genericUtility';

const styles = theme => ({
  paperStyle: theme.paperStyle,	
  textField: theme.textField,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiTakeProfit.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiTakeProfit: state.areaDatiTakeProfitReducer.caratteristicheContratto.datiTakeProfit,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiTakeProfit.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiTakeProfit: (unDato, unActionType) => dispatch(actions.updateDatiTakeProfit(unDato, unActionType)),
  };
};
	
class DatiTakeProfit extends React.Component {
	
  constructor(props) {
    //console.log('#RTCFA DatiTakeProfit constructor props', props);	 	  
    super(props);
	const datiTakeProfit = this.props.datiTakeProfit;
	this.state = {
	  percentualeObiettivo: datiTakeProfit.percentualeObiettivo,
	  descPercentualeObiettivo: datiTakeProfit.descPercentualeObiettivo,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,		  
	};
  };

  handleChangeConfermaDatiTakeProfit = event => {
    this.props.updateDatiTakeProfit(true, actionType.SALVA_DATI_TAKE_PROFIT);
  }  
  
  handleChangePercentualeObiettivo = event => {
    //console.log('#RTCFA DatiTakeProfit percentualeObiettivo new value:' + event); 
    const nuovaPercentualeObiettivo = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaPercentualeObiettivo = textTrim(this.state.percentualeObiettivo, true); 
    if (nuovaPercentualeObiettivo !== vecchiaPercentualeObiettivo) {	  
      this.props.updateDatiTakeProfit(event, actionType.UPDATE_PERCENTUALE_OBIETTIVO); 	
      this.setState({
        percentualeObiettivo: (event === null) ? '' : event.value,
        descPercentualeObiettivo: (event === null) ? '' : event.label,
      });
    }  
  }  
  
  isRequiredPercentualeObiettivo = () => {
    return true;
  }
  
  initStatoCampi = () => {
    return [ {id: 'percentualeObiettivo', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {
    console.log('########DatiPianoErogazioniPeriodiche verificaCodiceFiscale handleError id', id);	  
    console.log('########DatiPianoErogazioniPeriodiche verificaCodiceFiscale handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
    
  render() {
    const { classes, datiTakeProfit, dominio } = this.props;
    const { t } = this.props; //hoc    
    const controlloDati = datiTakeProfit.controlloDati;
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
      	<legend><Typography variant="subtitle1">{t('takeProfit')}</Typography></legend>
        <DividerComp titolo='takeProfit' variant="subtitle1" />*/}
		<Grid container direction="row" justify="center" alignItems="flex-start">
		  <Grid item md={4} xs={12}>
	    	<SelectComp onChange={this.handleChangePercentualeObiettivo} options={dominio.percTakeProfitItems} ricerca='comboRicerca' etichetta='percentualeObiettivo' 
	    		        value={{value: datiTakeProfit.percentualeObiettivo, label: datiTakeProfit.descPercentualeObiettivo}} 
	    	            required={this.isRequiredPercentualeObiettivo()} controlloDati={controlloDati} id='percentualeObiettivo' onError={this.handleError} />
	      </Grid>
	      <Grid item md={4} xs={12} />
	      <Grid item md={4} xs={12} />
	      <Grid item xs={12}>  
		    <Paper elevation={0} className={classes.paperStyle}>
		      <Typography variant="caption">{t('legendaTakeProfit')}</Typography>
		    </Paper>
	      </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center'>   	
              <Grid item>
                {(this.state.isPresenteErrore === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(this.props.datiTakeProfit.controlloDati === true) && (this.state.isPresenteErrore === false) &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
              </Grid>
            </Grid>
          </Grid> 	      
	      <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
              <Grid item>
                <ButtonComp
                  aClassName={classes.button}
                  buttonLabel={t('conferma')}
                  onClick={this.handleChangeConfermaDatiTakeProfit}
                />
              </Grid>
            </Grid>
          </Grid>
	    </Grid>
	  {/*</fieldset>*/}
	  </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiTakeProfit componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########DatiTakeProfit componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiTakeProfit.controlloDati === true)) {		
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiTakeProfit(!isPresenteCampiConErrore, actionType.UPDATE_DATI_TAKE_PROFIT_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }      
}

DatiTakeProfit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiTakeProfit)));
