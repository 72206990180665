import * as actionType from '../actionType/datiModPagBonificoEstero';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiModPagBonificoEsteroInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';
import { CODICE_ALTRA_RELAZIONE } from '../../../../../utility/jsConstants';

const areaDatiModPagBonificoEsteroReducer = (state = initialState, action) => {

    //DA UTILIZZARE SOLO PER LE COMBO
    const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
    const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;

    const caratteristicheContratto = {...state}.caratteristicheContratto;
    const datiModPagBonificoEstero = {...caratteristicheContratto}.datiModPagBonificoEstero;

    switch(action.type) {
        case actionTypeGeneric.INIZIALIZZA: 
            const caratteristicheContratto_INI = inizializzaModPagBonificoEstero(state);
            return {
            ...state,
            caratteristicheContratto: caratteristicheContratto_INI,
            };
        case actionType.UPDATE_BANCA_BONIFICO_ESTERO:
            datiModPagBonificoEstero.banca = unValore;
            datiModPagBonificoEstero.descBanca = unaLabel;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_AGENZIA_BONIFICO_ESTERO:
            datiModPagBonificoEstero.filiale = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_N_SOTTORUBRICA_BONIFICO_ESTERO:
            datiModPagBonificoEstero.nSottorubrica = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_INTESTATARIO_BONIFICO_ESTERO:
            datiModPagBonificoEstero.intestatario = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_PAESE_BONIFICO_ESTERO:
            datiModPagBonificoEstero.paese = unValore;
            datiModPagBonificoEstero.descrPaese = unaLabel;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_CODICE_SWIFT_BONIFICO_ESTERO:
            datiModPagBonificoEstero.codiceSwift = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_MOTIVO_CONTO_ESTERO_BONIFICO:
            datiModPagBonificoEstero.motivoContoEstero = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_CONTO_COINTESTATO_BONIFICO_ESTERO:
            datiModPagBonificoEstero.contoCointestato = action.unDato;
            if(datiModPagBonificoEstero.contoCointestato === false) {
                datiModPagBonificoEstero.nomeCognomeCointestatario = '';
                datiModPagBonificoEstero.relazContrCoint = '';
                datiModPagBonificoEstero.descrRelazContrCoint = '';
                datiModPagBonificoEstero.altraRelazione = '';
            }
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO_ESTERO:
            datiModPagBonificoEstero.nomeCognomeCointestatario = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_RELAZ_PAGAT_CONTR_BONIFICO_ESTERO:
            datiModPagBonificoEstero.relazContrCoint = unValore;
            datiModPagBonificoEstero.descrRelazContrCoint = unaLabel;
            if(datiModPagBonificoEstero.relazContrCoint !== CODICE_ALTRA_RELAZIONE) {
                datiModPagBonificoEstero.altraRelazione = '';
            }
            return {
                ...state,
                datiModPagBonificoEstero
            }
        case actionType.UPDATE_ALTRA_RELAZ_BONIFICO_ESTERO:
            datiModPagBonificoEstero.altraRelazione = action.unDato;
            return {
                ...state,
                datiModPagBonificoEstero
            }
        default:
            return state;
    }
}

export const inizializzaModPagBonificoEstero = state => {
    const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
    caratteristicheContratto_INI.datiModPagBonificoEstero = jsonCopy(datiModPagBonificoEsteroInizializzato);
    return caratteristicheContratto_INI;  
}

export default areaDatiModPagBonificoEsteroReducer;