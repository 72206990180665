import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Link as CoreLink } from '@material-ui/core';

import ButtonComp from '../common/ButtonComp';
import TextFieldComp from '../common/TextFieldComp';
import TextFieldAdornPwdComp from '../common/TextFieldAdornPwdComp';

import * as actions from './action/login';
import * as actionType from './actionType/login';

import { textTrim } from '../../utility/genericUtility';
import { PWD_IN_SCADENZA } from '../../utility/jsConstants';

const styles = theme => ({
  root: {
    marginTop: 200,
  },
  media: {
    maxWidth: '35%',
  },
  button: theme.button,
  buttonSmall: {
	margin: 8,
	color: theme.palette.color.windowsBlue,
	borderColor: theme.palette.color.windowsBlue,
    border: '2px solid',
    fontFamily: theme.fontFamily,	    
    width:'50px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    borderRadius: 10,
    '&:hover': { 
      background: theme.palette.color.windowsBlue, 
      color: theme.palette.color.white,
    },
  },
  link: {
	color: theme.palette.color.greyLight,
    margin: theme.spacing(1),
    textDecoration: 'underline',
    '&:hover': {  
  	  color: theme.palette.color.windowsBlue,
      textDecoration: 'underline',
    },
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textSmall: {
	fontSize: 15,
	marginLeft: theme.spacing(0.6),
  },
  appName: {
    fontSize: theme.typography.fontSize*3.3, 
	color: theme.palette.color.marine,
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*2, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*1.5, 
      },
    marginRight: theme.spacing(2),
  },
  appNameCorsivo: {
    fontSize: theme.typography.fontSize*3, 
	color: theme.palette.color.windowsBlue,
	fontStyle: 'italic',
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*1.7, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*1.3, 
      },
  },
  alignCenter: {
	textAlign: 'center',
  },
  alignCenterBreak: {
	textAlign: 'right',
	  
	[theme.breakpoints.down('xs')]: {
      textAlign: 'center', 
	  paddingRight: 0,
    },
  },
  marginLeft: {
	marginLeft: theme.spacing(1.5),
	color: theme.palette.color.marine,
  },
  textFieldLogin: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1), 
	height: 48,	
	width: '97%',
	marginTop: theme.spacing(0),
  },
});

const mapStateToProps = state => {
  return {
	areaLoginUtente: state.areaLoginReducer.areaLogin,
  };
};
	
const mapDispatchToProps = dispatch => {
  return {
	updateLogin: (unDato, unActionType) => dispatch(actions.updateLogin(unDato, unActionType)),
  	login: (user, password) => dispatch(actions.login(user, password)),
  };
};

class Login extends React.Component {

  constructor(props) {
    super(props);
    const areaLoginUtente = this.props.areaLoginUtente;
    this.state = {
      utente: areaLoginUtente.user,
      password: areaLoginUtente.password,
    };    
  };
  
  handleChangeUtente = event => {
    const nuovoUtente = textTrim(event.target.value, true);
	const vecchioUtente = textTrim(this.state.utente, true);
	if (nuovoUtente !== vecchioUtente) {
	  this.props.updateLogin(event.target.value, actionType.UPDATE_UTENTE_LOGIN);   
	  this.setState({ 
	    utente: event.target.value, 
	  });
    }
  };
  
  handleChangePassword = event => {
    const nuovoPassword = textTrim(event.target.value, true);
	const vecchioPassword = textTrim(this.state.password, true);
	if (nuovoPassword !== vecchioPassword) {
	  this.props.updateLogin(nuovoPassword, actionType.UPDATE_UTENTE_PASSWORD);   
	  this.setState({ 
		password: nuovoPassword, 
	  });
    }
  };
	
  handleClick = (utente, password) => event => {
    this.props.login(utente, password);    
  };  
  
  handleChangeCambiaPasswordInScadenza = (scelta) => event => {
	if(this.props.rilanciaProdottiArea) {
	  this.props.rilanciaProdottiArea(scelta);
    }
  };
  
  render() {
	const { classes, areaLogin, areaLoginUtente } = this.props;	 
    const { t } = this.props; //hoc	
    const area = areaLogin.area;
    const pulsanti = areaLogin.pulsanti;
    const pulsante = pulsanti[0];
    area.title = null;
    
    let linkTo = "/";
    if(this.state.utente!=='' && this.state.password!==''){
      linkTo = pulsante.to;
    }
    
    return (
      <Fragment>
        <div style={{height: '120px'}} />
{/*        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid container>
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={3} xs={12}>
              <TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textField}
                             id='utente' label='utente' value={this.state.utente} required={true}/>            
            </Grid>
            <Grid item md={3} xs={12}>
              <TextFieldAdornPwdComp onChange={this.handleChangePassword} aStyle={classes.textField} 
                                     id='password1' label='password' value={this.state.password} required={true} adornPosit='end'/>  
            </Grid>
            <Grid item md={3} xs={12}></Grid>
          </Grid>
          <Grid container>
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={3} xs={12}>
              <TextFieldAdornPwdComp onChange={this.handleChangePassword} aStyle={classes.textField} 
                                     id='password' label='password' value={this.state.password} required={true} adornPosit='end'/>          
            </Grid>
            <Grid item md={3} xs={12}>
              <TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textField}
                             id='utente1' label='utente' value={this.state.utente} required={true}/>                                        	 
            </Grid>
            <Grid item md={3} xs={12}></Grid>
          </Grid>
        </Grid> */}
      <div align='center'>
        <div align='center' style={{width: '50%'}}>
	      <Typography display='inline' className={classes.appName}>{'ZURICH'}</Typography>
          <Typography display='inline' className={classes.appNameCorsivo}>{'Semplifico'}</Typography>
		</div>
	  </div>
	  <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
	    <Grid item xs={3}></Grid>
        <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('tuoIdUtente')}</Typography></Grid>
        
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <div className={classes.middle}><TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textFieldLogin} id='utente' label='IdUtente' value={areaLoginUtente.user} required={true} /></div>      
        </Grid>
        <Grid item xs={3}></Grid>
        
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <Typography className={classes.textSmall}>
            <CoreLink href='/datiPasswordDimenticata' className={classes.link}>{t('credenzialiDimenticate')}</CoreLink>
          </Typography>
        </Grid>
        
        <Grid item xs={12}>&nbsp;</Grid>
        
        <Grid item xs={3}></Grid>
        <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('tuaPassword')}</Typography></Grid>
        
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
    	  <TextFieldAdornPwdComp onBlur={this.handleChangePassword} aStyle={classes.textFieldLogin} id='password' label='password' value={areaLoginUtente.password} required={true} adornPosit='end'/>  
        </Grid>
    	<Grid item xs={3}></Grid>
    	  
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
	      <Typography className={classes.textSmall}>
            <CoreLink href='/datiNuovaPassword' className={classes.link}>{t('nuovaPassword')}</CoreLink>
          </Typography>
        </Grid>
      </Grid>

      <div align='center'>
        <Grid container direction="row" justify="center" alignItems="center">	
          <Grid item xs={12}>&nbsp;</Grid>    
          <Grid item xs={12}>
            {this.props.codiceErroreLogin!=='' && this.props.codiceErroreLogin!==PWD_IN_SCADENZA &&
	          <Typography variant="h6">{t(this.props.codiceErroreLogin)}</Typography>
            }
            {this.props.codiceErroreLogin!=='' && this.props.codiceErroreLogin===PWD_IN_SCADENZA &&
	          <div align='center' style={{width: '60%'}}>
	            <Grid container direction="row" justify="center" alignItems="center">	
	              <Grid item xs={12}>
	                <Typography display='inline' variant="h6">{t(this.props.codiceErroreLogin)}</Typography>
	              </Grid>
	              <Grid item md={5} xs={4}></Grid>
	              <Grid item md={1} xs={2}>
	                <ButtonComp
	                  aClassName={classes.buttonSmall}
	                  buttonLabel={'si'}
		              onClick={this.handleChangeCambiaPasswordInScadenza('S')}
	                />
		          </Grid>
	              <Grid item md={1} xs={2}>	  
	                <ButtonComp 
	                  aClassName={classes.buttonSmall} 
	                  buttonLabel={'no'}
	                  onClick={this.handleChangeCambiaPasswordInScadenza('N')}
	      	        />
		          </Grid>
	                <Grid item md={5} xs={4}></Grid>
	              </Grid>
	            </div>
	          }
            {this.props.messaggioOk!=='' &&
  	          <Typography variant="h6">{t(this.props.messaggioOk)}</Typography>
            }
          </Grid>
        </Grid>
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item xs={12}>&nbsp;</Grid>
    	  <Grid item xs={12}>   
    	    {this.props.codiceErroreLogin!==PWD_IN_SCADENZA &&
              <ButtonComp 
                to={linkTo} 
                aKey={pulsante.codice} 
                component={Link} 
                aClassName={classes.button} 
                buttonLabel={'accedi'}
                onClick={this.handleClick(this.state.utente, this.state.password)}/>
    	    }
    	  </Grid> 
        </Grid>
      </div>
	  
       
        {/*        
        <Grid container direction='row' justify='center' alignItems='flex-start'>
        <Grid item xs={12}>
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3}>
            <TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textField} 
                           id='utente' label='utente' value={this.state.utente} required={true}/>
          </Grid>     
          <Grid item xs={3}>    
            <TextFieldAdornPwdComp onChange={this.handleChangePassword} aStyle={classes.textField} 
                                   id='password' label='password' value={this.state.password} required={true} adornPosit='end'/>
          </Grid> 
          <Grid item xs={3}>
          </Grid>     
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container direction='row' justify='center' alignItems='flex-start'>        
        <Grid item xs={12}>
        
        </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container direction='row' justify='center' alignItems='flex-start'>        
          <Grid item xs={3}>
          </Grid>            
          <Grid item xs={3}>
            <Typography>
              <CoreLink href='/' className={classes.link}>
                {t('accountDimenticato')}
              </CoreLink>
            </Typography>
          </Grid>      
          <Grid item xs={3}>            
            <Typography>
              <CoreLink href={'/'} className={classes.link}>
                {t('passwordDimenticata')}
              </CoreLink>
            </Typography>
          </Grid>   
          <Grid item xs={3}>
          </Grid>         
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container direction='column' justify='flex-end' alignItems='center'>
          <Grid item xs={12}>   
            <ButtonComp 
              to={pulsante.to} 
              aKey={pulsante.codice} 
              component={Link} 
              aClassName={classes.button} 
              buttonLabel={pulsante.title}
              onClick={this.handleClick(this.state.utente, this.state.password)}/>
          </Grid>             
  	  </Grid>
      </Grid>
      </Grid>
     
        
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={3} sm={2} xs={12}></Grid>
          <Grid item md={3} sm={4} xs={12} className={classes.alignCenter}>
	        <TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textField}
            	id='utente' label='utente' value={this.state.utente} required={true} />      
          </Grid>
	      <Grid item  md={3} sm={4} xs={12} className={classes.alignCenterBreak}>
	    	<TextFieldAdornPwdComp onChange={this.handleChangePassword} aStyle={classes.textField} 
	    		id='password1' label='password' value={this.state.password} required={true} adornPosit='end'/>  
          </Grid>
		    <Grid item md={3} sm={4} xs={12} className={classes.align}>
	        <TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textField}
            	id='utente' label='utente' value={this.state.utente} required={true} />      
        </Grid>

	    <Grid item md={3} sm={2} xs={12}></Grid>
	    <Grid item xs={12}>&nbsp;</Grid>
	    
	       
	    <Grid item md={3} sm={4}>
	    	<TextFieldAdornPwdComp onChange={this.handleChangePassword} aStyle={classes.textField} 
	    		id='password1' label='password' value={this.state.password} required={true} adornPosit='end'/>  
        </Grid>
	  
	    <Grid item xs={12}>
	    	<Grid container direction='column' justify='center' alignItems='center'>
	        	<Grid item xs={12}>   
		            <ButtonComp 
		              to={pulsante.to} 
		              aKey={pulsante.codice} 
		              component={Link} 
		              aClassName={classes.button} 
		              buttonLabel={pulsante.title}
		              onClick={this.handleClick(this.state.utente, this.state.password)}/>
	            </Grid> 
	        </Grid>
	  	  
	        <Grid item xs={12}>&nbsp;
		  	  
		 </Grid>
			  	<Grid item md={3} sm={2} xs={12}></Grid>
			        <Grid item md={3} sm={4} xs={12} className={classes.alignCenter}>
				        <Typography className={classes.textSmall}>
			              <CoreLink href='/' className={classes.link}>
			                {t('credenzialiDimenticate')}
			              </CoreLink>
			            </Typography>
			        </Grid>
				    <Grid item md={3} sm={4} xs={12} className={classes.alignCenter}>
					    <Typography className={classes.textSmall}>
			              <CoreLink href={'/'} className={classes.link}>
			                {t('nuovaPassword')}
			              </CoreLink>
			            </Typography>
			        </Grid>
			        <Grid item md={3} sm={2} xs={12}></Grid>
		 </Grid>*/}
	 
        		  	 		  	 
      </Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  areaLogin: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles, { withTheme: true })(Login)));