import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Email from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';

import TextFieldAdornComp from '../../common/TextFieldAdornComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import CheckboxComp from '../../common/CheckboxComp';

import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';
import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';

import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';

import { radioButtonRelazioneContraente } from '../../store/radioButtonStore';

import { BENEF_VITA_FISICO, BENEF_VITA_GIURIDICO, 
	     BENEF_MORTE_FISICO, BENEF_MORTE_GIURIDICO, TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA, CODICE_MEDESIMO_SOGGETTO, ASSICURATO_FISICO } from '../../../utility/jsConstants';
import { textTrim, checkBoxValue } from '../../../utility/genericUtility';
import SelectComp from '../../common/SelectComp';
import TextFieldComp from '../../common/TextFieldComp';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiRelazContr.mapStateToProps.state', state);
  return {
    datiRelazContrBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiRelazContr,
    datiRelazContrBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiRelazContr,
    datiRelazContrBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.datiRelazContr,    
    datiRelazContrBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiRelazContr,
    datiRelazContrTitolareEffMorteGiuridico: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiRelazContr,
    datiRelazContrTitolareEffVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiRelazContr,
    
    controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
    controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
    controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,    
    controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
    controlloDatiTitolareEffBenefMorte: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,

    dominio: state.areaDatiDominioReducer.dominio,

    controlloDatiTitolareEffBenefVita:state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,

    contrFisValida: state.areaContraenteReducer.contraenteFisico.anagraficaValida,
    contrGiuValida: state.areaContraenteReducer.contraenteGiuridico.anagraficaValida,

    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiRelazContr.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiRelazContrBenefVitaFisico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiRelazContrBenefVitaFisico(unDato, unActionType)),
    updateDatiRelazContrBenefMorteFisico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiRelazContrBenefMorteFisico(unDato, unActionType)),
    updateDatiRelazContrBenefVitaGiuridico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiRelazContrBenefVitaGiuridico(unDato, unActionType)),
    updateDatiRelazContrBenefMorteGiuridico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiRelazContrBenefMorteGiuridico(unDato, unActionType)),
    updateDatiRelazContrTitolareEffBenefMorte: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiRelazContrTitolareEffBenefMorte(unDato, unActionType)),
    updateDatiRelazContrTitolareEffBenefVita: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiRelazContrTitolareEffBenefVita(unDato, unActionType)), 
  };
};

class DatiRelazContr extends React.Component {
  constructor(props) {
    super(props);
    console.log('#RTCFA DatiRelazContr props', props);
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura);
    this.state = {
      email: datiRelazContr.email,
      relazContraente: datiRelazContr.relazContraente,
      esclusioneComunicaz: datiRelazContr.esclusioneComunicaz,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,      
	};
  };
  
  datiRelazContrFrom = figura => {  
    let datiRelazContr = null;
    if (this.props.figura === BENEF_VITA_FISICO) {
      datiRelazContr = this.props.datiRelazContrBenefVitaFisico;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      datiRelazContr = this.props.datiRelazContrBenefMorteFisico;
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
      datiRelazContr = this.props.datiRelazContrBenefMorteGiuridico;
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
      datiRelazContr = this.props.datiRelazContrBenefVitaGiuridico;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      datiRelazContr = this.props.datiRelazContrTitolareEffMorteGiuridico;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      datiRelazContr = this.props.datiRelazContrTitolareEffVitaGiuridico;
    }
    return datiRelazContr;
  }

  controlloDatiFrom = figura => {  
    let controlloDati = null;
    if (this.props.figura === BENEF_VITA_FISICO) {
      controlloDati = this.props.controlloDatiBenefVitaFisico;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      controlloDati = this.props.controlloDatiBenefMorteFisico;
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiBenefMorteGiuridico;
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
      controlloDati = this.props.controlloDatiBenefVitaGiuridico;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitolareEffBenefMorte;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitolareEffBenefVita;
    }
    return controlloDati;
  }

  handleChangeEmail = event => {
//    console.log('#RTCFA DatiRelazContr handleChangeEmail new value:' + event.target.value);
    const nuovoEmail = textTrim(event.target.value, true);   
//	const vecchioEmail = textTrim(this.state.email, true);
	const datiRelazContr = this.datiRelazContrFrom(this.props.figura);  
	const vecchioEmail = textTrim(datiRelazContr.email, true);
	if (nuovoEmail !== vecchioEmail) {
      let anActionType = null;
      if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_EMAIL_BENEF_VITA_FISICO;
        this.props.updateDatiRelazContrBenefVitaFisico(nuovoEmail, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_EMAIL_BENEF_MORTE_FISICO;
        this.props.updateDatiRelazContrBenefMorteFisico(nuovoEmail, anActionType); 	
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_EMAIL_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiRelazContrBenefMorteGiuridico(nuovoEmail, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_EMAIL_BENEF_VITA_GIURIDICO;
        this.props.updateDatiRelazContrBenefVitaGiuridico(nuovoEmail, anActionType); 	
      } 
      this.setState({
        email: nuovoEmail, 
      });
	 }
  };
	
  handleChangeRelazioneContraente = event => {
//    console.log('#RTCFA DatiRelazContr handleChangeRelazioneContraente' + event.target.value);
    const nuovoRelazioneContraente = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura); 
    const vecchioRelazioneContraente = textTrim(datiRelazContr.relazContraente, true);
    if (nuovoRelazioneContraente !== vecchioRelazioneContraente) {    	  
      let anActionType = null;
      if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_RELAZ_CONTR_BENEF_VITA_FISICO;
        this.props.updateDatiRelazContrBenefVitaFisico(event, anActionType);   
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_RELAZ_CONTR_BENEF_MORTE_FISICO;
        this.props.updateDatiRelazContrBenefMorteFisico(event, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
	    anActionType = actionTypeBenefMorte.UPDATE_RELAZ_CONTR_BENEF_MORTE_GIURIDICO;
	    this.props.updateDatiRelazContrBenefMorteGiuridico(event, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_RELAZ_CONTR_BENEF_VITA_GIURIDICO;
        this.props.updateDatiRelazContrBenefVitaGiuridico(event, anActionType); 	
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiRelazContrTitolareEffBenefMorte(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiRelazContrTitolareEffBenefVita(event, anActionType);
      }
      this.setState({
        relazContraente: nuovoRelazioneContraente,
        descrRelazContraente: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      });
    }  
  };

  handleChangeAltroRelazContr = (event) => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura); 
    const vecchioAltro = textTrim(datiRelazContr.altroRelazContr, true);
    if (nuovoAltro !== vecchioAltro) {
      let anActionType = null;
      if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_FISICO;
        this.props.updateDatiRelazContrBenefVitaFisico(nuovoAltro, anActionType); 
      } else if(this.props.figura === BENEF_MORTE_FISICO){
        anActionType = actionTypeBenefMorte.UPDATE_ALTRO_RELAZ_CONTR_BENEF_MORTE_FISICO;
        this.props.updateDatiRelazContrBenefMorteFisico(nuovoAltro, anActionType);
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_GIURIDICO;
        this.props.updateDatiRelazContrBenefVitaGiuridico(nuovoAltro, anActionType);
      } else if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_ALTRO_RELAZ_CONTR_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiRelazContrBenefMorteGiuridico(nuovoAltro, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiRelazContrTitolareEffBenefMorte(nuovoAltro, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiRelazContrTitolareEffBenefVita(nuovoAltro, anActionType);
      }
      this.setState({
        altro: nuovoAltro, 
      })
    }
  }
	  
  handleChangeEsclusioneComunicaz = event => {
    console.log('#RTCFA DatiRelazContr handleChangeEsclusioneComunicaz' + event.target.checked);
    const nuovoChecked = checkBoxValue(event.target.checked);
    const nuovoEsclusioneComunicaz = (nuovoChecked === true) ? 'S' : 'N';
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura); 
    const vecchioEsclusioneComunicaz = textTrim(datiRelazContr.esclusioneComunicaz, true);    
    if (nuovoEsclusioneComunicaz !== vecchioEsclusioneComunicaz) {    	    
      let anActionType = null;
      if (this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_ESCLUS_COMUNIC_BENEF_VITA_FISICO;
        this.props.updateDatiRelazContrBenefVitaFisico(nuovoEsclusioneComunicaz, anActionType);
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_ESCLUS_COMUNIC_BENEF_MORTE_FISICO;
        this.props.updateDatiRelazContrBenefMorteFisico(nuovoEsclusioneComunicaz, anActionType);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_ESCLUS_COMUNIC_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiRelazContrBenefMorteGiuridico(nuovoEsclusioneComunicaz, anActionType); 	
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_ESCLUS_COMUNIC_BENEF_VITA_GIURIDICO;
        this.props.updateDatiRelazContrBenefVitaGiuridico(nuovoEsclusioneComunicaz, anActionType); 	
      }
      this.setState({ 
        esclusioneComunicaz: nuovoEsclusioneComunicaz,
      });
      // funzione callback esterna
      if (this.props.handleChangeEsclusioneComunicaz) {
    	this.props.handleChangeEsclusioneComunicaz(nuovoEsclusioneComunicaz);
      }
    }
  }

  handleChangeBenDiversoImpresa = event => {
    const nuovoDiverso = textTrim(event.target.value, true);
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura);
    const vecchioDiverso = datiRelazContr.benDiversoImp;
    if(nuovoDiverso !== vecchioDiverso) {
      let anActionType = null;
      if(this.props.figura === BENEF_VITA_GIURIDICO) {
        anActionType = actionTypeBenefVita.UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_VITA_GIUR;
        this.props.updateDatiRelazContrBenefVitaGiuridico(nuovoDiverso, anActionType); 	
      } else if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        anActionType = actionTypeBenefMorte.UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_MORTE_GIUR;
        this.props.updateDatiRelazContrBenefMorteGiuridico(nuovoDiverso, anActionType); 	
      } else if(this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorte.UPDATE_BENE_DIVERSO_IMP_BENEF_MORTE_FISICO;
        this.props.updateDatiRelazContrBenefMorteFisico(nuovoDiverso, anActionType);
      } else if(this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVita.UPDATE_BENE_DIVERSO_IMP_BENEF_VITA_FISICO;
        this.props.updateDatiRelazContrBenefVitaFisico(nuovoDiverso, anActionType);
      }
    }
  }
   
  isRequiredEmail = () => {
    let required = false;
    if (this.props.figura === BENEF_VITA_FISICO) {
      required = false;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      required = false;    	
    } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
      required = false;    	
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
      required = false;
    } 
    return required;
  }
  
  isRequiredRelazContraente = () => {
    let required = false;
    if (this.props.figura === BENEF_VITA_FISICO) {
      required = true;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      required = true;
    } else if (this.props.figura === BENEF_VITA_GIURIDICO && this.props.scopeAmleto) {
      required = true;
    } else if (this.props.figura === BENEF_MORTE_GIURIDICO && this.props.scopeAmleto) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE  && this.props.scopeAmleto) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA  && this.props.scopeAmleto) {
      required = true;
    }
    return required;
  }

  isRequiredAltroRelazContr = () => {
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura);
    const altro = textTrim(datiRelazContr.relazContraente);
    return (altro === '10')
  }

  isRequiredEsclusioneComunicaz = () => {
    let required = false;
    return required;
  }

  isRequiredBenDiversoImp = () => {
    let required = false;
    const relaz = this.datiRelazContrFrom(this.props.figura).relazContraente;
    const validaFisico = this.props.contrFisValida;
    const validaGiuridico = this.props.contrGiuValida;
    if(this.props.scopeAmleto) {
      if(validaFisico !== null) {
        required = false;
      } else if(validaGiuridico !== null) {
        if(this.props.figura === BENEF_MORTE_FISICO || this.props.figura === BENEF_VITA_FISICO) {
          required = true;
        } else if(this.props.figura === BENEF_MORTE_GIURIDICO || this.props.figura === BENEF_VITA_GIURIDICO) {
          if(relaz && relaz !== CODICE_MEDESIMO_SOGGETTO) {
            required = true;
          }
        }
      }
    } else {
      required = false;
    }
    return required;
  }

  isDisabledRelazContraente = () => {
    let disabled = true;
    if(this.props.figura === BENEF_VITA_FISICO) {
      disabled = false
    } else if(this.props.figura === BENEF_MORTE_FISICO) {
      disabled = false
    } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE && this.props.scopeAmleto) {
      disabled = false
    } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA && this.props.scopeAmleto) {
      disabled = false
    } else if(this.props.figura === BENEF_MORTE_GIURIDICO && this.props.scopeAmleto) {
      disabled = false
    } else if(this.props.figura === BENEF_VITA_GIURIDICO && this.props.scopeAmleto) {
      disabled = false
    }
    return disabled;
  }

  isDisabledAltroRelazContr = () => {
     const datiRelazContr = this.datiRelazContrFrom(this.props.figura);
     const altro = textTrim(datiRelazContr.relazContraente);
     return (altro !== '10')
  }

  isDisabledBenDiversoImp = () => {
    let disabled = true;
    const relaz = this.datiRelazContrFrom(this.props.figura).relazContraente;
    const validaFisico = this.props.contrFisValida;
    const validaGiuridico = this.props.contrGiuValida;
    if(this.props.scopeAmleto) {
      if(validaFisico !== null) {
        disabled = true;
      } else if(validaGiuridico !== null) {
        if(this.props.figura === BENEF_MORTE_FISICO || this.props.figura === BENEF_VITA_FISICO) {
          disabled = false;
        } else if(this.props.figura === BENEF_MORTE_GIURIDICO || this.props.figura === BENEF_VITA_GIURIDICO) {
          if(relaz && relaz !== CODICE_MEDESIMO_SOGGETTO) {
            disabled = false;
          }
        }
      }
    } else {
      disabled = true;
    }
    return disabled;
  }
  
  initStatoCampi = () => {
    return [ {id: 'indEmail', isError: false},
             {id: 'relazioneConContr', isError: false}, 
             {id: 'altroRelazioneTraContr', isError: false}, 
             {id: 'esclusioneComunicaz', isError: false}, 
             {id: 'benDiversoImp', isError: false}, 
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  viewSectionRelazContr = () => {
    let show = true;
    if(!this.props.scopeAmleto) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        show = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        show = false;
      }
    }
    return show;
  }
  
  render() {	  
    console.log('#RTCFA DatiRelazContr this.props', this.props);    
    const { classes, figura, relazioneConContr, dominio } = this.props;	
    const datiRelazContr = this.datiRelazContrFrom(figura);  
    const controlloDati = this.controlloDatiFrom(figura);
    //const viewSectionRelazContr = (figura !== BENEF_VITA_GIURIDICO && figura !== BENEF_MORTE_GIURIDICO) && !this.props.scopeAmleto;
    return (
      <Fragment>
        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
          {figura !== TITOLARE_EFF_BENEF_MORTE && figura !== TITOLARE_EFF_BENEF_VITA &&
          <Grid item md={4} xs={12}>
            <TextFieldAdornComp onBlur={this.handleChangeEmail} id='indEmail' label='indEmail' value={datiRelazContr.email} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
            	                required={this.isRequiredEmail()} controlloDati={controlloDati} margin='normal' variant='outlined' adornComp={ <Email /> } adornPosit='start' onError={this.handleError} 
            />
          </Grid>
          }
          {/* <RadioButtonComp onChange={this.handleChangeRelazioneContraente} radioComp={radioButtonRelazioneContraente} selectedValue={datiRelazContr.relazContraente} 
                           required={this.isRequiredRelazContraente()} controlloDati={controlloDati} id='relazContraente' onError={this.handleError} /> */}
          {this.viewSectionRelazContr() &&
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <Grid item md={6} xs={12}>
              <SelectComp onChange={this.handleChangeRelazioneContraente} options={dominio.relazioneItems} id='relazioneConContr' etichetta={relazioneConContr}
                controlloDati={controlloDati} onError={this.handleError} disabled={false} required={this.isRequiredRelazContraente()}
                value={{ value: datiRelazContr.relazContraente, label: datiRelazContr.descrRelazContraente }} disabled={this.isDisabledRelazContraente()} />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextFieldComp id='altroRelazioneTraContr' onBlur={this.handleChangeAltroRelazContr} label='general.seAltroSpecificare' value={datiRelazContr.altro}
                onError={this.handleError} controlloDati={controlloDati} aStyle={classNames(classes.textField, classes.textFieldWidth100)} aStyleError={classes.textFieldWidthPercError}
                disabled={this.isDisabledAltroRelazContr()} required={this.isRequiredAltroRelazContr()} />
            </Grid>
          </Grid>
          }
          {(figura === BENEF_VITA_GIURIDICO || figura === BENEF_MORTE_GIURIDICO || figura === BENEF_MORTE_FISICO || figura === BENEF_VITA_FISICO) &&
          <Grid item xs={12}>
            <TextFieldComp onBlur={this.handleChangeBenDiversoImpresa} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                           id='benDiversoImp' label='benDiversoImp' value={datiRelazContr.benDiversoImp} required={this.isRequiredBenDiversoImp()} 
                           controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledBenDiversoImp()} />
          </Grid>
          }
	      <Grid item md={3} xs={12}></Grid>
          <Grid item xs={12}>
          {figura !== TITOLARE_EFF_BENEF_MORTE && figura !== TITOLARE_EFF_BENEF_VITA &&
            <CheckboxComp onChange={this.handleChangeEsclusioneComunicaz} required={this.isRequiredEsclusioneComunicaz()} controlloDati={controlloDati} aLabel='benefEsclusoComunicaz'
            checked={(datiRelazContr.esclusioneComunicaz === 'S') ? true : false} id='esclusioneComunicaz' onError={this.handleError} />
          }
            </Grid>
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiResidenza componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//	    console.log('########DatiResidenza componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	    console.log('########DatiResidenza componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  };
  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  };
}

DatiRelazContr.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiRelazContr));
