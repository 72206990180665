import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import NavTabsComp from '../../common/NavTabsComp';
import ExpansionPanelComp from '../../common/ExpansionPanelComp';

import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
	
const mapStateToProps = state => {
  return {
	  tabSelezionatoContraente: state.genericReducer.generic.tabSelezionatoContraente,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA ContractorPanel.mapDispatchToProps.dispatch', dispatch);
  return {        
    updateTabSelezionatoContraente: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class ContractorPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
    	tabSelezionatoContraente: this.props.tabSelezionatoContraente,
    }
  };
  
  //funzione callback esterna
  handleChangeUpdateTabSelezionatoContraente = (event, tabIndex) => {
    this.props.updateTabSelezionatoContraente(tabIndex, '', actionTypeGeneric.TAB_SELEZIONATO_CONTRANTE);
    this.setState({
    	tabSelezionatoContraente: tabIndex,
    });
  };
  
  render () {	
    const { classes, isValida, tabList } = this.props;
    console.log('####tabList: ', tabList);
    return (
      <div className={classes.root}> 
        <ExpansionPanelComp titolo={'contraente'} componente={NavTabsComp} tabList={tabList} 
        	handleChangeConfermaContraenteGiuridico={this.props.handleChangeConfermaContraenteGiuridico} 
        	handleChangeFlTitolareEff={this.props.handleChangeFlTitolareEff} 
            handleChange={this.handleChangeUpdateTabSelezionatoContraente}
        	tabSelezionato={this.props.tabSelezionatoContraente}
            isValida={isValida} />
      </div>
    );
  }
}

ContractorPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContractorPanel));
