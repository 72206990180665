import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { textTrim, checkBoxValue } from '../../../../utility/genericUtility';
import { withTranslation } from 'react-i18next';

import * as actions from './action/datiModPagBonificoEstero';
import * as actionType from './actionType/datiModPagBonificoEstero';

import Grid from '@material-ui/core/Grid';
import TextFieldComp from '../../../common/TextFieldComp';
import SelectComp from '../../../common/SelectComp';
import CheckboxComp from '../../../common/CheckboxComp';
import { CODICE_ALTRA_RELAZIONE, locale_it } from '../../../../utility/jsConstants';
import { getNomeIntestatarioFromTipo } from '../../../../utility/storeUtility';

const styles = theme => ({
    textField: theme.textField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
    button: theme.button,
    textFieldWidth: {
        width: '80%',
    },
});

const mapStateToProps = state => {
    return {
        dominio: state.areaDatiDominioReducer.dominio,
        datiModPagBonificoEstero: state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonificoEstero,
        controlloIban: state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonificoEstero.controlloIban,
        scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
        tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateDatiModPagBonificoEstero: (unDato, unActionType) => dispatch(actions.updateDatiModPagBonificoEstero(unDato, unActionType)),
    };
};

class DatiModPagBonificoEstero extends Component {
    constructor(props) {
        super(props);
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        this.state = {
            paese: datiModPagBonificoEstero.paese,
            descrPaese: datiModPagBonificoEstero.descrPaese,
            filiale: datiModPagBonificoEstero.filiale,
            nSottorubrica: datiModPagBonificoEstero.nSottorubrica,
            intestatario: datiModPagBonificoEstero.intestatario,
            codiceSwift: datiModPagBonificoEstero.codiceSwift,
            motivoContoEstero: datiModPagBonificoEstero.motivoContoEstero,
            contoCointestato: datiModPagBonificoEstero.contoCointestato,
            nomeCognomeCointestatario: datiModPagBonificoEstero.nomeCognomeCointestatario,
            relazContrCoint: datiModPagBonificoEstero.relazContrCoint,
            descrRelazContrCoint: datiModPagBonificoEstero.descrRelazContrCoint,
            altraRelazione: datiModPagBonificoEstero.altraRelazione,
            statoCampi: this.initStatoCampi(),
            isPresenteErrore: false,
            codiceIbanValido: null,
        };
    };

    handleChangeBanca = event => {
        const nuovaBanca = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchiaBanca = datiBonificoEstero.banca;
        if (nuovaBanca !== vecchiaBanca) {
            this.props.updateDatiModPagBonificoEstero(event, actionType.UPDATE_BANCA_BONIFICO_ESTERO);
            this.setState({
                banca: nuovaBanca,
                descBanca: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
            })
        }
    }

    handleChangeAgenzia = event => {
        const nuovaAgenzia = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchiaAgenzia = datiBonificoEstero.agenzia;
        if (nuovaAgenzia !== vecchiaAgenzia) {
            this.props.updateDatiModPagBonificoEstero(nuovaAgenzia, actionType.UPDATE_AGENZIA_BONIFICO_ESTERO);
            this.setState({
                filiale: nuovaAgenzia
            })
        }
    }

    handleChangeNSottorubrica = event => {
        const nuovoSottorubrica = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchiaSottorubrica = datiBonificoEstero.nSottorubrica;
        if (nuovoSottorubrica !== vecchiaSottorubrica) {
            this.props.updateDatiModPagBonificoEstero(nuovoSottorubrica, actionType.UPDATE_N_SOTTORUBRICA_BONIFICO_ESTERO);
            this.setState({
                nSottorubrica: nuovoSottorubrica
            })
        }
    }

    handleChangeIntestatario = event => {
        const nuovoIntestatario = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioIntestatario = datiBonificoEstero.intestatario;
        if (nuovoIntestatario !== vecchioIntestatario) {
            this.props.updateDatiModPagBonificoEstero(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_BONIFICO_ESTERO);
            this.setState({
                intestatario: nuovoIntestatario
            })
        }
    }

    handleChangePaeseBonifico = event => {
        const nuovoPaese = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioPaese = datiBonificoEstero.paese;
        if (nuovoPaese !== vecchioPaese) {
            this.props.updateDatiModPagBonificoEstero(event, actionType.UPDATE_PAESE_BONIFICO_ESTERO);
            this.setState({
                paese: nuovoPaese,
                descrPaese: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
            })
        }
    }

    handleChangeCodiceSwift = event => {
        const nuovoSwift = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioSwift = datiBonificoEstero.codiceSwift;
        if (nuovoSwift !== vecchioSwift) {
            this.props.updateDatiModPagBonificoEstero(nuovoSwift, actionType.UPDATE_CODICE_SWIFT_BONIFICO_ESTERO);
            this.setState({
                codiceSwift: nuovoSwift
            })
        }
    }

    handleChangeMotivoContoEstero = event => {
        const nuovoMotivo = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioMotivo = datiBonificoEstero.motivoContoEstero;
        if (vecchioMotivo !== nuovoMotivo) {
            this.props.updateDatiModPagBonificoEstero(nuovoMotivo, actionType.UPDATE_MOTIVO_CONTO_ESTERO_BONIFICO);
            this.setState({
                motivoContoEstero: nuovoMotivo
            })
        }
    }

    handleChangeContoCointestato = event => {
        const nuovoCoint = checkBoxValue(event.target.checked);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioCoint = datiBonificoEstero.contoCointestato;
        if (nuovoCoint !== vecchioCoint) {
            this.props.updateDatiModPagBonificoEstero(nuovoCoint, actionType.UPDATE_CONTO_COINTESTATO_BONIFICO_ESTERO);
            this.setState({
                contoCointestato: nuovoCoint
            })
        }
    }

    handleChangeNomeCointestatario = event => {
        const nuovoCoint = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioCoint = datiBonificoEstero.nomeCognomeCointestatario;
        if (nuovoCoint !== vecchioCoint) {
            this.props.updateDatiModPagBonificoEstero(nuovoCoint, actionType.UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO_ESTERO);
            this.setState({
                nomeCognomeCointestatario: nuovoCoint
            })
        }
    }

    handleChangeRelazione = event => {
        const nuovaRelaz = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchiaRelaz = datiBonificoEstero.relazContrCoint;
        if (nuovaRelaz !== vecchiaRelaz) {
            this.props.updateDatiModPagBonificoEstero(event, actionType.UPDATE_RELAZ_PAGAT_CONTR_BONIFICO_ESTERO);
            this.setState({
                relazContrCoint: nuovaRelaz,
                descrRelazContrCoint: (event === null) ? '' : event.label,
            })
        }
    }

    handleChangeAltraRelaz = event => {
        const nuovoAltro = textTrim(event.target.value, true);
        const datiBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioAltro = datiBonificoEstero.altraRelazione;
        if (nuovoAltro !== vecchioAltro) {
            this.props.updateDatiModPagBonificoEstero(nuovoAltro, actionType.UPDATE_ALTRA_RELAZ_BONIFICO_ESTERO);
            this.setState({
                altraRelazione: nuovoAltro
            })
        }
    }

    initStatoCampi = () => {
        return [
            { id: 'iban', isError: false },
            { id: 'banca', isError: false },
            { id: 'agenzia', isError: false },
            { id: 'nSottorubrica', isError: false },
            { id: 'intestatarioBonificoEstero', isError: false },
            { id: 'contoCointestato', isError: false },
            { id: 'nomeCognomeCointestatario', isError: false },
            { id: 'relazContrCoint', isError: false },
            { id: 'altraRelazione', isError: false },
            { id: 'paeseBonifico', isError: false },
        ]
    }

    handleError = (id, isError) => {
        console.log('########DatiModPagBonificoEstero verificaIban handleError isError', isError);
        console.log('########DatiModPagBonificoEstero verificaIban handleError id', id);
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
            unErrore = campoOnId[0];
            unErrore.isError = isError;
        } else {
            unErrore = { id: id, isError: isError };
        }
        this.setState({
            statoCampi: [...campoWithoutId, unErrore],
        });
    }

    isRequiredBanca = () => {
        return true;
    }

    isRequiredAgenzia = () => {
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        return datiModPagBonificoEstero.banca !== '';
    }

    isRequiredNSottorubrica = () => {
        return false;
    }

    isRequiredIntestatario = () => {
        return true;
    }

    isRequiredPaeseBonifico = () => {
        return true;
    }

    isRequiredSwift = () => {
        return true;
    }

    isRequiredMotivoContoEstero = () => {
        return true;
    }

    isRequiredContoCointestato = () => {
        return false;
    }

    isRequiredNomeCointestatario = () => {
    	//2021-07-01 PFD: TCM_INDIV
    	//const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
    	//return datiModPagBonificoEstero.contoCointestato;
    	const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
    	let required = datiModPagBonificoEstero.contoCointestato;
    	//se scopeTcmEmissione, non obbligatorio anche se contoCointestato
    	if (this.props.scopeTcmEmissione) {
    	  const isTcmIndividValida = this.props.tcmIndividualeValida;
    	  if (isTcmIndividValida) {
    		  required = false;
    	  }
    	}
        return required;
    }

    isRequiredRelazione = () => {
    	//2021-07-01 PFD: TCM_INDIV
    	//const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
    	//return datiModPagBonificoEstero.contoCointestato;
    	const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
    	let required = datiModPagBonificoEstero.contoCointestato;
    	//se scopeTcmEmissione, non obbligatorio anche se contoCointestato
    	if (this.props.scopeTcmEmissione) {
    	  const isTcmIndividValida = this.props.tcmIndividualeValida;
    	  if (isTcmIndividValida) {
    		  required = false;
    	  }
    	}
        return required;
    }

    isRequiredAltraRelazione = () => {
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        return datiModPagBonificoEstero.relazContrCoint === CODICE_ALTRA_RELAZIONE;
    }

    isDisabledBanca = () => {
        return false;
    }

    isDisabledAgenzia = () => {
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        return !datiModPagBonificoEstero.banca;
    }

    isDisabledNSottorubrica = () => {
        return false;
    }

    isDisabledIntestatario = () => {
        return false;
    }

    isDisabledPaeseBonifico = () => {
        return false;
    }

    isDisabledSwift = () => {
        return false;
    }

    isDisabledMotivoContoEstero = () => {
        return false;
    }

    isDisabledContoCointestato = () => {
        return false;
    }

    isDisabledNomeCognomeCointestatario = () => {
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        return !datiModPagBonificoEstero.contoCointestato;
    }

    isDisabledRelazione = () => {
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        return !datiModPagBonificoEstero.contoCointestato;
    }

    isDisabledAltraRelazione = () => {
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        return datiModPagBonificoEstero.relazContrCoint !== CODICE_ALTRA_RELAZIONE;
    }

    componentDidMount = () => {
        const nuovoIntestatario = getNomeIntestatarioFromTipo(this.props.tipoIntestatario);
        const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
        const vecchioIntestatario = datiModPagBonificoEstero.intestatario;
        if (nuovoIntestatario !== vecchioIntestatario) {
            this.props.updateDatiModPagBonificoEstero(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_BONIFICO_ESTERO);
            this.setState({
                intestatario: nuovoIntestatario
            })
        }
    }

    render() {
        const { datiModPagBonificoEstero, classes, dominio, controlloDati } = this.props;
        return (
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid item md={4} xs={12}>
                <SelectComp onChange={this.handleChangeBanca} options={dominio.bancaItems} ricerca='comboRicerca' etichetta='banca' value={{value: datiModPagBonificoEstero.banca, label: datiModPagBonificoEstero.descBanca}} 
	                        required={this.isRequiredBanca()} controlloDati={controlloDati} id='banca' onError={this.handleError} disabled={this.isDisabledBanca()} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextFieldComp aStyle={classes.textFieldWidthPerc} id='agenzia' label='agenzia' value={datiModPagBonificoEstero.descAgenzia} onError={this.handleError}
                        onBlur={this.handleChangeAgenzia} required={this.isRequiredAgenzia()} disabled={this.isDisabledAgenzia()}
                        value={datiModPagBonificoEstero.filiale} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                </Grid>
                <Grid item md={4} xs={12}></Grid>
                <Grid item md={4} xs={12}>{/* paese */}
                    <SelectComp onChange={this.handleChangePaeseBonifico} id='paeseBonifico' etichetta='paeseBonifico' onError={this.handleError} ricerca='comboRicerca'
                        value={{ value: datiModPagBonificoEstero.paese, label: datiModPagBonificoEstero.descrPaese }} options={dominio.nazioneItems}
                        disabled={this.isDisabledPaeseBonifico()} required={this.isRequiredPaeseBonifico()} controlloDati={controlloDati} />
                </Grid>
                <Grid item md={4} xs={12}>{/* swift */}
                    <TextFieldComp aStyle={classes.textFieldWidthPerc} id='codiceSwift' label='codiceSwift' onError={this.handleError}
                        value={datiModPagBonificoEstero.codiceSwift} required={this.isRequiredSwift()} disabled={this.isDisabledSwift()}
                        onBlur={this.handleChangeCodiceSwift} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextFieldComp aStyle={classes.textFieldWidthPerc} id='nSottorubrica' label='nSottorubrica' onError={this.handleError}
                        value={datiModPagBonificoEstero.nSottorubrica} required={this.isRequiredNSottorubrica()} disabled={this.isDisabledNSottorubrica()}
                        onBlur={this.handleChangeNSottorubrica} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                </Grid>
                <Grid item md={6} xs={12}>{/* motivo conto estero */}
                    <TextFieldComp aStyle={classes.textFieldWidthPerc} id='motivoContoEstero' label='motivoContoEstero' onError={this.handleError}
                        value={datiModPagBonificoEstero.motivoContoEstero} required={this.isRequiredMotivoContoEstero()} disabled={this.isDisabledMotivoContoEstero()}
                        onBlur={this.handleChangeMotivoContoEstero} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextFieldComp aStyle={classes.textFieldWidthPerc} id='intestatarioBonificoEstero' label='intestatarioBonificoEstero' onError={this.handleError}
                        value={datiModPagBonificoEstero.intestatario} required={this.isRequiredIntestatario()} disabled={this.isDisabledIntestatario()}
                        onBlur={this.handleChangeIntestatario} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} readonly />
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={4} xs={12}>{/* checkbox cointestatario */}
                        <CheckboxComp onChange={this.handleChangeContoCointestato} required={this.isRequiredContoCointestato()} controlloDati={controlloDati}
                            aLabel='contoCointestato' checked={datiModPagBonificoEstero.contoCointestato} id='contoCointestato' onError={this.handleError}
                            disabled={this.isDisabledContoCointestato()} />
                    </Grid>
                    <Grid item md={8} xs={12}>{/* dati cointestatario */}
                        <TextFieldComp onBlur={this.handleChangeNomeCointestatario} required={this.isRequiredNomeCointestatario()} controlloDati={controlloDati} id='nomeCognomeCointestatario'
                            label='nomeCognomeCointestatario' value={datiModPagBonificoEstero.nomeCognomeCointestatario} onError={this.handleError}
                            aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledNomeCognomeCointestatario()} aStyleError={classes.textFieldWidthPercError} />
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <SelectComp onChange={this.handleChangeRelazione} options={dominio.relazioneItems} etichetta='relazContrCoint' id='relazContrCoint'
                        value={{ value: datiModPagBonificoEstero.relazContrCoint, label: datiModPagBonificoEstero.descrRelazContrCoint }} onError={this.handleError}
                        controlloDati={controlloDati} disabled={false} required={this.isRequiredRelazione()} disabled={this.isDisabledRelazione()} />
                </Grid>
                <Grid item md={8} xs={12}>
                    <TextFieldComp onBlur={this.handleChangeAltraRelaz} required={this.isRequiredAltraRelazione()} controlloDati={controlloDati}
                        label='general.altro' value={datiModPagBonificoEstero.altraRelazione} onError={this.handleError} id='altraRelazione'
                        aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledAltraRelazione()} aStyleError={classes.textFieldWidthPercError} />
                </Grid>
            </Grid>
        )
    };

    isInError(prevProps, prevState) {
        let isPresenteCampiConErrore = false;
        this.state.statoCampi.map(unoStatoCampo => {
            return (
                isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
            );
        });
        console.log('########DatiModPagBonificoEstero componentDidUpdate this.state.statoCampi', this.state.statoCampi);
        if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
            if (this.props.onError) {
                this.props.onError(this.props.id, isPresenteCampiConErrore);
            }
            if (this.props.onChildrenError) {
                this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
            }
            this.setState({
                isPresenteErrore: isPresenteCampiConErrore,
            });
        }
    }

    isInBusinessError(prevProps, prevState) {
        const prevControlloIban = prevProps.controlloIban;
        const currControlloIban = this.props.controlloIban;
        console.log('########DatiModPagBonificoEstero verificaIban isInBusinessError prevProps.controlloIban', prevControlloIban);
        console.log('########DatiModPagBonificoEstero verificaIban isInBusinessError this.props.controlloIban', currControlloIban);
        if ((prevControlloIban !== currControlloIban) && (currControlloIban !== 'CHECK_IBAN')) {
            const ibanValido = (currControlloIban === 'OK') ? true : false;
            this.handleError('iban', !ibanValido);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.isInBusinessError(prevProps, prevState);
        this.isInError(prevProps, prevState);
        if (prevProps.pagatorePremio !== this.props.pagatorePremio) {
            const nuovoIntestatario = getNomeIntestatarioFromTipo(this.props.tipoIntestatario);
            const datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
            const vecchioIntestatario = datiModPagBonificoEstero.intestatario;
            if (nuovoIntestatario !== vecchioIntestatario) {
                this.props.updateDatiModPagBonificoEstero(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_BONIFICO_ESTERO);
                this.setState({
                    intestatario: nuovoIntestatario
                })
            }    
        }
    }

};

DatiModPagBonificoEstero.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiModPagBonificoEstero)));
