import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import TextTestoQuestionario from './TextFieldQuestionario';

const styles = theme => ({
  root: {
    color: theme.palette.color.windowsBlue,
    '&$checked': {
      color: theme.palette.color.windowsBlue,
    },
  },
  checked: {
  },
  textField: theme.textField,
});
	
class CheckBoxQuestionario extends React.Component {
	
	constructor(props) {
		super(props);
		let checkedValueInitial = new Array(props.risposte.length);
		props.risposte.forEach(function(item, index, array) {
		  checkedValueInitial[index] = item.selezionato;
		});
		this.state = {
			checkedValue: checkedValueInitial,
		};
	};

	handleChange = name => event => {
		const checkedValueCopy = this.state.checkedValue;
		checkedValueCopy[name] =  event.target.checked;
		this.setState({ checkedValue: checkedValueCopy });
		if(this.props.onChange){
			this.props.onChange(event);
		}
	};
	    
  render() {
    const { classes, risposte, numDomanda, idRisposta, tipoQuestionario } = this.props;
    
    let risposteTesto = [];
    
    return (
     <Grid container direction='column' justify="flex-start" alignItems='flex-start'>
        {risposte.map((risposta, index) => {
          risposteTesto = []; ('T' === risposta.tipoCampoAggiuntivo || 'N' === risposta.tipoCampoAggiuntivo)  && risposteTesto.push(risposta);
          return (
            <Grid key={index}>
	           <FormControlLabel
	             control={<Checkbox
			              checked={this.state.checkedValue[index]}
				          onChange={this.handleChange(index)}
				          value={risposta.id}
				          classes={{ root: classes.root, checked: classes.checked }}
			            />}
	             label={('T' === risposta.tipoCampoAggiuntivo || 'N' === risposta.tipoCampoAggiuntivo) ? <TextTestoQuestionario risposte={risposteTesto} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='horizzontal' /> : <Typography variant="caption">{risposta.testo}</Typography>}					
			   />
	         </Grid>   
	       )
	     })} 
	   </Grid>
	);
  }
}

CheckBoxQuestionario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckBoxQuestionario);
