import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoFigura from '../sezione/TabDatiRiepilogoFigura';
import CopiaDaAltraFigura from '../sezione/other/CopiaDaAltraFigura';
import DatiGeneraliBenefFisico from '../sezione/DatiGeneraliBenefFisico';
import DatiAntiriciclaggio from '../sezione/DatiAntiriciclaggio';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiRelazContr from '../sezione/DatiRelazContr';

import ButtonComp from '../../common/ButtonComp';
import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';

import { locale_it, BENEF_VITA_FISICO } from '../../../utility/jsConstants';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';
import { creaBeneficiarioVitaFisico } from '../../../utility/objectUtility';

import { toNumber } from '../../../utility/genericUtility';
import { calcolaPercBenfTotale, 
		 getPercBenefByCodFiscInList, getPercBenefByCognomeInList } from './figureUtils/figureUtils';

import * as actions from './action/beneficiarioVita';
import * as actionType from './actionType/beneficiarioVita';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: theme.textField,
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA BenefVitaFisicoAss.mapStateToProps.state', state);
  return {
    benefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico,
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList, 
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,
    salvaBenefVitaFisicoSuccess: state.areaBenefVitaReducer.salvaBenefVitaFisicoSuccess,
    controlloDati: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA BenefVitaFisicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
    confermaAggiungiBeneficiarioVitaFisico: (currBenefVitaFisico) => dispatch(actions.confermaAggiungiBeneficiarioVitaFisico(currBenefVitaFisico)),
    nuovoBeneficiarioVitaFisico: (currBenefVitaFisico) => dispatch(actions.nuovoBeneficiarioVitaFisico(currBenefVitaFisico)),
    updateDatiBeneficiarioVita: (unDato, unActionType) => dispatch(actions.updateDatiBeneficiarioVita(unDato, unActionType)),
    updateBeneficiarioVitaFisico: (unDato, unActionType) => dispatch(actions.updateBeneficiarioVitaFisico(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class BenefVitaFisicoAss extends React.Component {

  constructor(props) {
    super(props);
//    console.log('#RTCFA BenefVitaFisicoAss constructor props', this.props);
    const datiCopiaDaAltraFigura = props.benefVitaFisico.datiCopiaDaAltraFigura;
    const datiRelazContr = props.benefVitaFisico.datiRelazContr;  
    this.state = {
      percBeneficiario: 0,
      percentualeTotale: 0,
      isConfermaBenef: false,
      isNuovoBenef: false,
      figuraFrom: datiCopiaDaAltraFigura.figuraFrom,
      isCopiaDaAltraFigura: datiCopiaDaAltraFigura.flCopiaDaAltraFigura,
      flEsclusioneComunicaz: datiRelazContr.esclusioneComunicaz,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    }
  };
  
/*  getEmptyBenefVitaFisico = () => {
	  
	  const emptyBenefVitaFisico = {
	     controlloDati: '',
		 anagraficaValida: null,
		 datiFigureAnagrBase: {
			  ruolo: RUOLO_BENEF_VITA,
			  tipoPers: PERSONA_FISICA,
		  },
	      datiGeneraliBenefVita: {
	    	  datiGeneraliMinimi: {}
	      },
	      datiResidenza: {},
	      datiRelazContr: {},
	      datiCopiaDaAltraFigura: {
	    	  flCopiaDaAltraFigura: 'N',
	      },
	    };
	  
	  return emptyBenefVitaFisico;
  }*/

 handleClickNuovoBenefVita = event => {
//   console.log('#RTCFA BenefVitaFisicoAss:handleClickNuovoBenefVita new value:', event.target.value);
//   const emptyBenefVitaFisico = this.getEmptyBenefVitaFisico();
   const emptyBenefVitaFisico = creaBeneficiarioVitaFisico();    
   this.props.nuovoBeneficiarioVitaFisico(emptyBenefVitaFisico);
//   this.props.updateDatiBeneficiarioVita(false, actionType.SALVA_BENEF_VITA_FISICO_SUCCESS);
   this.setState({
     isConfermaBenef: false,
     isNuovoBenef: true,
     isPresenteErrore: null,
     flEsclusioneComunicaz: undefined,
   });
 }
  
  handleClickConfermaBenefVita = event => {
//    console.log('#RTCFA BenefVitaFisicoAss:handleClickConfermaBenefVita new value:', event.target.value);
    this.props.updateDatiBeneficiarioVita(true, actionType.SALVA_BENEF_VITA_FISICO);
    this.props.updateDatiBeneficiarioVita(false, actionType.SALVA_BENEF_VITA_FISICO_SUCCESS);
    this.setState({
      isConfermaBenef: true,
      isNuovoBenef: false,
	});
  }
  
  confermaBeneficiario = () => {
    const currBenef = this.props.benefVitaFisico;
    const percTotaleFigure = calcolaPercBenfTotale(this.props.benefVitaFisicoList, this.props.benefVitaGiuridicoList, this.props.benefVitaGenericoList, locale_it);
    let percTotale = percTotaleFigure;
    let percBenefInLista = undefined;
	let isNuovo = false;
	let benefVitaFromCopiaDaAltraFigura = undefined; 
    if (this.state.isConfermaBenef) {
      if (currBenef.anagraficaValida === true)  {
        if (currBenef.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!== 'S') {
          // Trova per Codice Fiscale
          percBenefInLista = getPercBenefByCodFiscInList(this.props.benefVitaFisicoList, currBenef, locale_it);
          // Edit figura corrente
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;
          }
        } else {
          const descrFiguraFrom = currBenef.datiCopiaDaAltraFigura.descrFiguraFrom;
          currBenef.datiGeneraliBenefVita.datiGeneraliMinimi.cognome = descrFiguraFrom;
          // cerco se presente
          percBenefInLista = getPercBenefByCognomeInList(this.props.benefVitaFisicoList, currBenef);
          // nuova figura
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;					
            benefVitaFromCopiaDaAltraFigura = {...currBenef};
            // reset alcune sezioni
            benefVitaFromCopiaDaAltraFigura.datiGeneraliBenefVita.datiGeneraliMinimi = {};
            benefVitaFromCopiaDaAltraFigura.datiGeneraliBenefVita.datiGeneraliMinimi.cognome = descrFiguraFrom;
            benefVitaFromCopiaDaAltraFigura.datiResidenza = {};
          }    			
        }
        const percentualeBenefCorrente = currBenef.datiGeneraliBenefVita.percBeneficiario;
        const percBenefCorrente = toNumber(percentualeBenefCorrente, locale_it);
        if (percBenefCorrente !== undefined && percBenefCorrente > 0) {
        	// 2022-01-06 PFD: ricalcolo il totale con arrotondamento alla seconda cifra decimale
            //percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista);
            percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista).round(2);
          if (percTotale<=100.0) {
            const beneficiario = benefVitaFromCopiaDaAltraFigura !== undefined ? benefVitaFromCopiaDaAltraFigura : currBenef;
            // INSERIMENTO NUOVO BENEFICIARIO
            if (isNuovo === true) {
              this.props.confermaAggiungiBeneficiarioVitaFisico(beneficiario);
            } else {
              // MODIFICA BENEFICIARIO
              this.props.updateBeneficiarioVitaFisico(beneficiario, actionType.UPDATE_BENEF_VITA_FISICO);
            }
            // Aggiorno la percentuale
            this.props.updateDatiBeneficiarioVita(percTotale, actionType.UPDATE_BENEF_VITA_PERCENTUALE_TOTALE);
            this.props.updateDatiBeneficiarioVita(true, actionType.SALVA_BENEF_VITA_FISICO_SUCCESS);
            
            //2021-07-01 PFD: TCM_INDIV
            this.handleTcmIndividualeValida();

          }
        }
      }
    }
    this.setState({
      isConfermaBenef : false,
      percentualeTotale : percTotale,
    });
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {
    if (this.props.scopeTcmEmissione) {
      const isTcmIndValida = isTcmIndividualeValida(locale_it);
	  // se cambiata 
	  if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		  this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
	  }
    }
  }

  //funzione callback 
  handleChangeEsclusioneComunicaz = (value) => {
    this.setState({
      flEsclusioneComunicaz: value,
    });
  }
  
  // funzione callback 
  handleChangeFlCopiaDaAltraFigura = (value) => {	 
    this.setState({
      isCopiaDaAltraFigura: value,
      statoCampi: this.initStatoCampi(),
    });
  }
  
  // funzione callback
  handleChangeFiguraFrom = figuraFrom => {
    this.setState({
      figuraFrom: figuraFrom,
    });
  }
  
  handlePercBeneficiario = event => {
    console.log('#RTCFA DatiGeneraliBenefFisico percBeneficiario new value:' + event.target.value);	
    this.props.updateDatiBeneficiarioVita(event.target.value,  actionType.UPDATE_PERCENTUALE_BENEF_VITA_FISICO); 	      
    this.setState({
      percBeneficiario: event.target.value,
    });	
  }
  
  initStatoCampi = () => {
    if (this.props.benefVitaFisico.datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S') {
      return [{id: 'copiaDaAltraFiguraBenefMorteFisico', isError: false},
              {id: 'datiGeneraliBenefVitaFisico', isError: false},
              {id: 'datiResidenzaBenefVitaFisico', isError: false},  
              {id: 'datiRelazContrBenefVitaFisico', isError: false},
              {id: 'datiAntiriciclaggioBenefVitaFisico', isError: false},
             ];
    } else {
      return [{id: 'copiaDaAltraFiguraBenefVitaFisico', isError: false},
              {id: 'percBeneficiario', isError: false},
              {id: 'datiRelazContrBenefVitaFisico', isError: false},
             ];
    }
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
	  
  render() {
	console.log('#RTCFA BenefVitaFisicoAss this.props', this.props);
    console.log('#RTCFA BenefVitaFisicoAss this.props.benefVitaFisico', this.props.benefVitaFisico);
//    console.log('#RTCFA BenefVitaFisicoAss this.props.aggiungiBenefVitaFisico', this.props.aggiungiBenefVitaFisico);    
//    console.log('#RTCFA BenefVitaFisicoAss this.state.benefVitaFisico', this.state.benefVitaFisico);    		
    const { classes, t, benefVitaFisico } = this.props;
    const currentBenef = benefVitaFisico;
    const datiGeneraliBenef = currentBenef.datiGeneraliBenefVita;
    const datiCopiaDaAltraFigura = currentBenef.datiCopiaDaAltraFigura;   
	const percentualeTotaleError = this.state.percentualeTotale!==0 && parseFloat(this.state.percentualeTotale)!==100.0;
	const controlloDati = this.props.controlloDati;
	const isNuovo = this.state.isNuovoBenef;	
	const salvaBenefVitaFisicoSuccess = this.props.salvaBenefVitaFisicoSuccess;
	const percBenefInLista = getPercBenefByCognomeInList(this.props.benefVitaFisicoList, currentBenef);
	const isCopiaReadonly = percBenefInLista!==undefined;
	
    return (
      <Fragment>
        <TabDatiRiepilogoFigura figura={'beneficiarioVita'} tabHeader={'tabHeaderBenVita'} />
        <CopiaDaAltraFigura id={'copiaDaAltraFiguraBenefVitaFisico'} 
			figura={BENEF_VITA_FISICO} datiCopiaDaAltraFigura={datiCopiaDaAltraFigura} 
  			handleChangeFlCopiaDaAltraFigura={this.handleChangeFlCopiaDaAltraFigura} 
  	    	handleChangeFiguraFrom={this.handleChangeFiguraFrom} 
  	    	onError={this.handleError}
			readonly={isCopiaReadonly} />
        {datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S' &&  
          <Fragment>
            <DatiGeneraliBenefFisico figura={BENEF_VITA_FISICO} datiGeneraliBenefVitaFisico={datiGeneraliBenef} labelFieldSet='datiGenerali' 
                id={'datiGeneraliBenefVitaFisico'} onError={this.handleError} />
            <DatiResidenza figura={BENEF_VITA_FISICO} datiResidenzaBenefVitaFisico={currentBenef.datiResidenza} labelFieldSet='datiDiResidenza' flEsclusioneComunicazBenef={this.state.flEsclusioneComunicaz}
                id={'datiResidenzaBenefVitaFisico'} onError={this.handleError} />           
          </Fragment>
        }
        {datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'S' &&
          <Fragment>
            <DividerComp titolo='&nbsp;' variant='subtitle1' />
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12}>
                <NumberFieldComp onBlur={this.handlePercBeneficiario} aStyle={classes.textField} id={'percBeneficiario'} label='percBeneficiario' format='###,###' 
                                 value={datiGeneraliBenef.percBeneficiario} maxLength='7' required={true} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
            </Grid>
          </Fragment>
      	}
        <DatiRelazContr figura={BENEF_VITA_FISICO} datiRelazContrBenefVitaFisico={currentBenef.datiRelazContr} handleChangeEsclusioneComunicaz={this.handleChangeEsclusioneComunicaz}
                        id={'datiRelazContrBenefVitaFisico'} onError={this.handleError} relazioneConContr={'relazContrBenefVita'} />        
        
        {/* 2020-12-01 PFD: SAV 'TO BE' - START - ALCUNI DATI ANTIRICICLAGGIO PER BENEFICIARIO */}
        <DatiAntiriciclaggio figura={BENEF_VITA_FISICO} datiAntiriciclaggio={currentBenef.datiAntiriciclaggio} labelFieldSet='datiAntiriciclaggio' 
        	id={'datiAntiriciclaggioBenefVitaFisico'} onError={this.handleError} />
        
        <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
          <Grid item xs={12}>
         	<Grid container direction='row' justify='center' alignItems='center'>            	
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && !this.state.isPresenteErrore && percentualeTotaleError && !isNuovo && 
                <Typography className={classes.textErrorMessage}>{t('errTotPercBenef')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) && (salvaBenefVitaFisicoSuccess === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
              </Grid>
	      	</Grid>
	      </Grid>
          <Grid item>
            <ButtonComp
              /* to={linkTo} */
              /* component={Link} */
              aClassName={classes.button}
              buttonLabel={t('conferma')}
              onClick={this.handleClickConfermaBenefVita}
            />
          </Grid>
          <Grid item>
            <ButtonComp
              /* to={linkTo} */
              /* component={Link} */
              aClassName={classes.button}
              buttonLabel={t('nuovoBenefic')}
              onClick={this.handleClickNuovoBenefVita}
            />
          </Grid>
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
    });
    console.log('########BenefVitaFisicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefVitaFisicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefVitaFisicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	    
    if (this.state.isPresenteErrore !== isPresenteCampiConErrore && (this.props.controlloDati !== '')) {
	/*      if (this.props.onError) {
	        this.props.onError(this.props.id, isPresenteCampiConErrore);
	      }
	      if (this.props.onChildrenError) {
	        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
	      }  */    
      this.props.updateDatiBeneficiarioVita(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_FISICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
    return isPresenteCampiConErrore;
  }
  
  isConfermaBenef = (prevProps, prevState, isPresenteCampiConErrore) => {   
//	const currIsPresenteErrore = this.state.isPresenteErrore;
	const currIsPresenteErrore = isPresenteCampiConErrore;
	const currIsConferma = this.state.isConfermaBenef;	
	const prevIsPresenteErrore = prevState.isPresenteErrore;
	const prevIsConferma = prevState.isConfermaBenef;
    if (currIsPresenteErrore !== undefined && currIsPresenteErrore !== null && currIsPresenteErrore === false) {
      if (currIsConferma) {
        this.confermaBeneficiario();
      }
    }
    if (currIsConferma !== prevIsConferma) {
      this.setState({isConfermaBenef: false,})
    }
  }
  
  isFlgEsclusioni = (prevProps, prevState) => {   
	const currflEsclusioneComunicaz = this.props.benefVitaFisico.datiRelazContr.esclusioneComunicaz;
	const prevflEsclusioneComunicaz = this.state.flEsclusioneComunicaz;
    if (currflEsclusioneComunicaz!==undefined && currflEsclusioneComunicaz!==null && currflEsclusioneComunicaz !== prevflEsclusioneComunicaz) {
      this.setState({
        flEsclusioneComunicaz: currflEsclusioneComunicaz,
      });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const isPresenteCampiConErrore = this.isInError(prevProps, prevState);
    this.isConfermaBenef(prevProps, prevState, isPresenteCampiConErrore);
    this.isFlgEsclusioni(prevProps, prevState); // allineo lo stato con le props
  }
}

BenefVitaFisicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  benefVitaFisico: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BenefVitaFisicoAss)));
