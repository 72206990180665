import * as actionType from '../actionType/datiOpzioniContrattuali';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiOpzioniContrattualiInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiOpzioniContrattualiReducer = (state = initialState, action) => {
	
  console.log('#RT2 Reducer areaDatiOpzioniContrattualiReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaOpzioniContrattuali(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };	  
    case actionType.UPDATE_OPZIONE_CONTRATTUALE:
      const caratteristicheContrattoOpzioneContrattuale = {...state}.caratteristicheContratto;
      const datiOpzioniContrattualiOpzioneContrattuale = {...caratteristicheContrattoOpzioneContrattuale}.datiOpzioniContrattuali;
      datiOpzioniContrattualiOpzioneContrattuale.opzioneContrattuale = action.unDato;
      caratteristicheContrattoOpzioneContrattuale.datiOpzioniContrattuali = {...datiOpzioniContrattualiOpzioneContrattuale};
      
      const datiPianoErogazioniPeriodiche = {...caratteristicheContrattoOpzioneContrattuale}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodiche.datiPianoErogazioniPeriodicheValida = null;
      datiPianoErogazioniPeriodiche.controlloDati = '';
      datiPianoErogazioniPeriodiche.freqPrestazione = '';
      datiPianoErogazioniPeriodiche.descFreqPrestazione = '';
      datiPianoErogazioniPeriodiche.durata = '';
      datiPianoErogazioniPeriodiche.descDurata = '';
      datiPianoErogazioniPeriodiche.percentuale = '';
      datiPianoErogazioniPeriodiche.descPercentuale = '';
      datiPianoErogazioniPeriodiche.iban = '';
      datiPianoErogazioniPeriodiche.controlloIban = '';
      caratteristicheContrattoOpzioneContrattuale.datiPianoErogazioniPeriodiche = datiPianoErogazioniPeriodiche;
      
      const datiTakeProfit = {...caratteristicheContrattoOpzioneContrattuale}.datiTakeProfit;
      datiTakeProfit.datiTakeProfitValida = null;
      datiTakeProfit.controlloDati = '';
      datiTakeProfit.percentualeObiettivo = '';
      datiTakeProfit.descPercentualeObiettivo = '';
      caratteristicheContrattoOpzioneContrattuale.datiTakeProfit = datiTakeProfit;     
      
      const datiLifeCycle = {...caratteristicheContrattoOpzioneContrattuale}.datiOpzioniContrattuali.datiLifeCycle;
      datiLifeCycle.datiLifeCycleValida = (action.unDato === '3') ? true : null;
      caratteristicheContrattoOpzioneContrattuale.datiOpzioniContrattuali.datiLifeCycle = datiLifeCycle;  
            
      const datiPianoSpostamentoAutomatico = {...caratteristicheContrattoOpzioneContrattuale}.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico;
      datiPianoSpostamentoAutomatico.datiPianoSpostamentoAutomaticoValida = null;
      caratteristicheContrattoOpzioneContrattuale.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico = datiPianoSpostamentoAutomatico;  
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoOpzioneContrattuale,
      };   
    default:
      return state;
  }
}

export const inizializzaOpzioniContrattuali = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiOpzioniContrattuali = jsonCopy(datiOpzioniContrattualiInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiOpzioniContrattualiReducer;