import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import DividerComp from '../../common/DividerComp'
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import * as actionType from '../figure/actionType/contraente';
import * as actions from '../figure/action/contraente';
import * as actionTypeBenefMorteGiuridico from '../figure/actionType/beneficiarioMorte';
import * as actionsBenefMorteGiuridico from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefVitaGiuridico from '../figure/actionType/beneficiarioVita';
import * as actionsBenefVitaGiuridico from '../figure/action/beneficiarioVita';
import * as actionsGeneric from '../../generic/action/generic';
import { textTrim, checkBoxValue, isDataValid, dataFormat } from '../../../utility/genericUtility';
import NumberFieldComp from '../../common/NumberFieldComp';
import { withTranslation } from 'react-i18next';
import SelectComp from '../../common/SelectComp';
import TextFieldComp from '../../common/TextFieldComp';
import { withStyles } from '@material-ui/styles';
import { CONTRAENTE_GIURIDICO, BENEF_MORTE_GIURIDICO, BENEF_VITA_GIURIDICO } from '../../../utility/jsConstants';
import DatePickerComp from '../../common/DatePickerComp';
import ButtonComp from '../../common/ButtonComp';
import moment from 'moment';
import { fetchParam, uris } from '../../../uris';


const styles = theme => ({
    textField: theme.textField,
    dateField: theme.dateField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
    fieldSet: theme.fieldSet,
    divider: {
        width: theme.spacing(1),
    },
    button: theme.button,
    textErrorMessage: theme.textErrorMessage,
});

const tipoIscrizioneAlboItems = [{
    label: 'alboIvass',
    value: 'alboIvass',
}, {
    label: 'alboCovip',
    value: 'alboCovip',
}, {
    label: 'alboConsob',
    value: 'alboConsob',
}, {
    label: 'alboBancaItalia',
    value: 'alboBancaItalia',
},
];

const tipoIscrizioneRegistroItems = [{
    label: 'IscrizioneARegistroDellePersoneGiuridiche',
    value: 'IscrizioneARegistroDellePersoneGiuridiche',
}, {
    label: 'iscrizioneARegistroImprese',
    value: 'iscrizioneARegistroImprese',
},
];

class DatiSocietari extends Component {

    constructor(props) {
        super(props);
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        this.state = {
            codTipoIscrRegistro: datiSocietari.codTipoIscrRegistro,
            descTipoIscrRegistro: datiSocietari.descTipoIscrRegistro,
            codTipoIscrAlbo: datiSocietari.codTipoIscrAlbo,
            descTipoIscrAlbo: datiSocietari.descTipoIscrAlbo,
            numeroRegistro: datiSocietari.numeroRegistro,
            dataIscrizioneAlbo: datiSocietari.dataIscrizioneAlbo,
            numeroIscrizioneAlbo: datiSocietari.numeroIscrizioneAlbo,
            iscrizioneRegistroItems: [],
            autoritaItems: [],
            statoCampi: this.initStatoCampi(),
        };
    };

    componentDidMount = () => {
        const dataOggiFormatta = moment().format("DD/MM/YYYY");
        const siglaVersione = 'ZURI';
        const optsIscrizione = {
            siglaVersione: siglaVersione,
            codiceCampo: 'RGIS',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.iscrizioneRegistroItems, fetchParam(optsIscrizione))
        .then(
            response => response.json(),
            error => console.error('An error occurred.', error),
        )
        .then((result) => {
            this.setState({
                iscrizioneRegistroItems: result
            })
        })
        const optsAutorita = {
            siglaVersione: siglaVersione,
            codiceCampo: 'AUTO',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.autoritaItems, fetchParam(optsAutorita))
        .then(
            response => response.json(),
            error => console.error('An error occurred.', error),
        )
        .then((result) => {
            this.setState({
                autoritaItems: result
            })
        });
    }

    initStatoCampi = () => {
        return [ {id: 'descTipoIscrizioneRegistro' , isError: false},
                 {id: 'numeroIscrizioneRegistro', isError: false},
                 {id: 'iscrizioneAlbo', isError: false},
                 {id: 'nomeAlbo', isError: false},
                 {id: 'numeroIscrizioneAlbo', isError:false},
                 {id: 'dataIscrizioneAlbo', isError:false}, 
               ];
      }

    datiSocietariFrom = figura => {
        let datiSocietari = null;
        if (figura === CONTRAENTE_GIURIDICO) {
            datiSocietari = this.props.datiSocietari;
        } else if (figura === BENEF_MORTE_GIURIDICO) {
            datiSocietari = this.props.datiSocietariBenefMorteGiuridico;
        } else if (figura === BENEF_VITA_GIURIDICO) {
            datiSocietari = this.props.datiSocietariBenefVitaGiuridico;
        }
        return datiSocietari;
    }

    controlloDatiFrom = figura => {
        let controlloDati = null;
        if (figura === CONTRAENTE_GIURIDICO) {
            controlloDati = this.props.controlloDatiContraenteGiuridico;
        } else if (figura === BENEF_MORTE_GIURIDICO) {
            controlloDati = this.props.controlloDatiBenefMorteGiuridico;
        } else if (figura === BENEF_VITA_GIURIDICO) {
            controlloDati = this.props.controlloDatiBenefVitaGiuridico;
        }
        return controlloDati;
    }

    handleChangeIscrizioneRegistro = event => {
        const nuovoTipoIscrRegistro = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        const vecchioTipoIscrRegistro = textTrim(datiSocietari.codTipoIscrRegistro, true);
        if (nuovoTipoIscrRegistro !== vecchioTipoIscrRegistro) {
            if(this.props.figura === BENEF_MORTE_GIURIDICO) {
                this.props.updateDatiSocietariBeneficiarioMorte(event, actionTypeBenefMorteGiuridico.ISCRIZIONE_REGISTRO_BENEF_MORTE_GIURIDICO);
            } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
                this.props.updateDatiSocietariBeneficiarioVita(event, actionTypeBenefVitaGiuridico.ISCRIZIONE_REGISTRO_BENEF_VITA_GIURIDICO)
            } else {
                this.props.updateDatiSocietari(event, actionType.ISCRIZIONE_REGISTRO);
            }
            this.setState({
                codTipoIscrRegistro: nuovoTipoIscrRegistro,
                descTipoIscrRegistro: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
            })
        }
    }

    handleChangeNumeroRegistro = event => {
        const datiSocietari= this.datiSocietariFrom(this.props.figura);
        const nuovoNumeroRegistro = textTrim(event.target.value, true);
        const vecchioNumeroRegistro = textTrim(datiSocietari.numeroRegistro, true);
        if (nuovoNumeroRegistro !== vecchioNumeroRegistro) {
            if(this.props.figura === BENEF_MORTE_GIURIDICO) {
                this.props.updateDatiSocietariBeneficiarioMorte(nuovoNumeroRegistro, actionTypeBenefMorteGiuridico.NUMERO_REGISTRO_BENEF_GIURIDICO)
            } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
                this.props.updateDatiSocietariBeneficiarioVita(nuovoNumeroRegistro, actionTypeBenefVitaGiuridico.NUMERO_REGISTRO_BENEF_VITA_GIURIDICO)
            } else {
                this.props.updateDatiSocietari(nuovoNumeroRegistro, actionType.NUMERO_REGISTRO);
            }
            this.setState({
                numeroRegistro: nuovoNumeroRegistro,
            })
        }
    }

    handleChangeIscrizioneAlbo = event => {
        const nuovoTipoIscrAlbo = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        const vecchioTipoIscrAlbo = textTrim(datiSocietari.codTipoIscrAlbo, true);
        if (nuovoTipoIscrAlbo !== vecchioTipoIscrAlbo) {
            this.props.updateDatiSocietari(event, actionType.ISCRIZIONE_ALBO);
            this.setState({
                codTipoIscrAlbo: nuovoTipoIscrAlbo,
                descTipoIscrAlbo: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
            })
        }
    }

    /*handleChangeNumeroRegistroPersoneGiuridiche = event => {
        const nuovoNumeroRegistro = textTrim(event.target.value, true);
        const vecchioNumeroRegistro = textTrim(this.state.numeroRegistroPersoneGiuridiche, true);
        if (nuovoNumeroRegistro !== vecchioNumeroRegistro) {
            this.props.updateDatiSocietari(nuovoNumeroRegistro, actionType.NUMERO_REGISTRO_PERSONE_GIURIDICHE);
            this.setState({
                numeroRegistro: nuovoNumeroRegistro,
            })
        }
    }*/


    handleChangeNomeAlbo = event => {
        const nuovoNomeAlbo = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
        const vecchioNomeAlbo = textTrim(this.state.nomeAlbo, true);
        if (nuovoNomeAlbo !== vecchioNomeAlbo) {
            this.props.updateDatiSocietari(nuovoNomeAlbo, actionType.UPDATE_NOME_ALBO)
            this.setState({
                nomeAlbo: nuovoNomeAlbo,
            })
        }
    }

    handleChangeNumeroIscrizioneAlbo = event => {
        const nuovoNumIscrizioneAlbo = textTrim(event.target.value, true);
        const vecchioNumIscrizioneAlbo = textTrim(this.state.numeroIscrizioneAlbo, true);
        if (nuovoNumIscrizioneAlbo !== vecchioNumIscrizioneAlbo) {
            this.props.updateDatiSocietari(nuovoNumIscrizioneAlbo, actionType.NUMERO_ISCRIZIONE_ALBO)
            this.setState({
                numeroIscrizioneAlbo: nuovoNumIscrizioneAlbo,
            })
        }
    }


    handleChangeDataIscrizioneAlbo = event => {
        if (isDataValid(event)) {
            const nuovaDataIscrizioneFormattata = event;
            const datiSocietari = this.datiSocietariFrom(this.props.figura);
            const vecchiaDataIscrizioneFormattata = datiSocietari.dataIscrizioneAlboStampa;
            if (nuovaDataIscrizioneFormattata !== vecchiaDataIscrizioneFormattata) {
                this.props.updateDatiSocietari(event, actionType.DATA_ISCRIZIONE_ALBO)
                this.setState({
                    dataIscrizioneAlbo: nuovaDataIscrizioneFormattata,
                })
            }
        }
    }

    isRequiredNumeroRegistro = () => {
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        return (datiSocietari.codTipoIscrRegistro !== '') && this.props.scopeAmleto === true;
    }

    isRequiredIscrizione = () => {
        let required = false;
        if(this.props.figura === BENEF_MORTE_GIURIDICO && this.props.scopeAmleto) {
            required = true;
        } else if(this.props.figura === CONTRAENTE_GIURIDICO && this.props.scopeAmleto) {
            required = true;
        } else if(this.props.figura === BENEF_VITA_GIURIDICO && this.props.scopeAmleto) {
            required = true;
        }
        return required && this.props.scopeAmleto === true;
    }

    isRequiredIscrizioneAlbo = () => {
        let required = false;
        if(this.props.figura === CONTRAENTE_GIURIDICO) {
            required = true;
        }
        return required && this.props.scopeAmleto === true;
    }

    isRequiredIscrizioneAlboInfo = () => {
        let required = false;
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        if(this.props.figura === CONTRAENTE_GIURIDICO && datiSocietari.codTipoIscrAlbo && datiSocietari.codTipoIscrAlbo !== 'NESSUNA') {
            required = true;
        }
        return required && this.props.scopeAmleto === true;
    }

    isRequiredAutorita = () => {
        return this.props.scopeAmleto;
    }
    
    isDisabledIscrizioneAlboInfo = () =>{
        let disabled = true;
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        if(this.props.figura === CONTRAENTE_GIURIDICO && datiSocietari.codTipoIscrAlbo !== '' && this.props.scopeAmleto === true) {
            disabled = false;   
        }
        return disabled;
    }

    isDisabledNumeroIscrizione = () => {
        const datiSocietari = this.datiSocietariFrom(this.props.figura);
        return (datiSocietari.codTipoIscrRegistro === '')
    }

    isDisabledIscrizione = () => {
        return this.props.scopeAmleto === false;
    }

    isDisabledAutorita = () => {
        return this.props.scopeAmleto === false;
    }
  
    render() {
        const { labelFieldSet, classes, figura } = this.props;
        const datiSocietari = this.datiSocietariFrom(figura);
        const controlloDati = this.controlloDatiFrom(figura);

        return (
            <Fragment>
                <DividerComp titolo={labelFieldSet} variant='subtitle1' />

                <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <Grid item md={6} xs={12}>
                        <SelectComp onChange={this.handleChangeIscrizioneRegistro} id={'descTipoIscrizioneRegistro'}
                            onError={this.handleError} options={this.state.iscrizioneRegistroItems} required={this.isRequiredIscrizione()}
                            value={{ value: datiSocietari.codTipoIscrRegistro, label: datiSocietari.descTipoIscrRegistro }}
                            etichetta={'selectedIscrizione'} controlloDati={controlloDati} disabled={this.isDisabledIscrizione()} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <NumberFieldComp onBlur={this.handleChangeNumeroRegistro} aStyle={classes.textFieldWidthPerc} id={'numeroIscrizioneRegistro'} 
                                         label='numeroIscrizione' required={this.isRequiredNumeroRegistro()} onError={this.handleError} value={datiSocietari.numeroRegistro} 
                                         maxLength='20' disabled={this.isDisabledNumeroIscrizione()} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                    </Grid>                   
                </Grid>

                {figura !== BENEF_MORTE_GIURIDICO && figura !== BENEF_VITA_GIURIDICO &&
                <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <Grid item md={6} xs={12}>
                        <SelectComp id={"iscrizioneAlbo"} onError={this.handleError} options={this.state.autoritaItems}
                            value={{ value: datiSocietari.codTipoIscrAlbo, label: datiSocietari.descTipoIscrAlbo }}
                            etichetta={"autorita"} disabled={this.isDisabledAutorita()} required={this.isRequiredAutorita()}
                            onChange={this.handleChangeIscrizioneAlbo} controlloDati={controlloDati} />
                    </Grid>

                    <Grid container direction='row' justify='flex-start' alignItems='center'>
                        <TextFieldComp onBlur={this.handleChangeNomeAlbo} aStyle={classes.textField} id='nomeAlbo' label='nomeAlbo' value={datiSocietari.nomeAlbo}
                            onError={this.handleError} disabled={this.isDisabledIscrizioneAlboInfo()}
                            required={this.isRequiredIscrizioneAlboInfo()} controlloDati={controlloDati} />
                        <NumberFieldComp onBlur={this.handleChangeNumeroIscrizioneAlbo} aStyle={classes.textField} id='numeroIscrizioneAlbo' label='numeroIscrizioneAlbo'
                            onError={this.handleError} value={datiSocietari.numeroIscrizioneAlbo} disabled={this.isDisabledIscrizioneAlboInfo()}
                            required={this.isRequiredIscrizioneAlboInfo()} controlloDati={controlloDati} />
                        <DatePickerComp id={'dataIscrizioneAlbo'} aStyle={classNames(classes.dateField, classes.dateFieldWidth)} onError={this.handleError}
                            aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} onChange={this.handleChangeDataIscrizioneAlbo}
                            label={'dataIscrizioneAlbo'} required={this.isRequiredIscrizioneAlboInfo()} value={datiSocietari.dataIscrizioneAlbo} 
                            disabled={this.isDisabledIscrizioneAlboInfo()} controlloDati={controlloDati} />
                    </Grid>
                </Grid>
                }
            </Fragment>
        )

    }
}

const mapStateToProps = (state) => ({
    datiSocietari: state.areaContraenteReducer.contraenteGiuridico.datiSocietari,
    datiSocietariBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiSocietari,
    datiSocietariBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiSocietari,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
    controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
})

const mapDispatchToProps = dispatch => {
    return {
        updateDatiSocietari: (unDato, unActionType) => dispatch(actions.updateDatiSocietari(unDato, unActionType)),
        updateDatiSocietariBeneficiarioMorte: (unDato, unActionType) => 
                    dispatch(actionsBenefMorteGiuridico.updateDatiSocietariBeneficiarioMorte(unDato, unActionType)),
        updateDatiSocietariBeneficiarioVita: (unDato, unActionType) => 
                    dispatch(actionsBenefVitaGiuridico.updateDatiSocietariBeneficiarioVita(unDato, unActionType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiSocietari)))
