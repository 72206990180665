export const UPDATE_FREQ_PRESTAZIONE_PIANO_EROGAZIONI_PERIODICHE 			= 'UPDATE_FREQ_PRESTAZIONE_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_DURATA_PIANO_EROGAZIONI_PERIODICHE           			= 'UPDATE_DURATA_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_PERCENTUALE_PIANO_EROGAZIONI_PERIODICHE      			= 'UPDATE_PERCENTUALE_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_IBAN_PIANO_EROGAZIONI_PERIODICHE             			= 'UPDATE_IBAN_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_CONTROLLO_IBAN_PIANO_EROGAZIONI_PERIODICHE				= 'UPDATE_CONTROLLO_IBAN_PIANO_EROGAZIONI_PERIODICHE';
export const SALVA_DATI_PIANO_EROGAZIONI_PERIODICHE  		          		= 'SALVA_DATI_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_DATI_PIANO_EROGAZIONI_PERIODICHE_VALIDA      			= 'UPDATE_DATI_PIANO_EROGAZIONI_PERIODICHE_VALIDA';
export const UPDATE_CONTO_COINTESTATO_PIANO_EROGAZIONI_PERIODICHE			= 'UPDATE_CONTO_COINTESTATO_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_NOME_COGNOME_COINTESTATARIO_PIANO_EROGAZIONI_PERIODICHE	= 'UPDATE_NOME_COGNOME_COINTESTATARIO_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_RELAZIONE_CONTR_PIANO_EROGAZIONI_PERIODICHE				= 'UPDATE_RELAZIONE_CONTR_PIANO_EROGAZIONI_PERIODICHE';
export const UPDATE_ALTRA_RELAZIONE_PIANO_EROGAZIONI_PERIODICHE				= 'UPDATE_ALTRA_RELAZIONE_PIANO_EROGAZIONI_PERIODICHE';