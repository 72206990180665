/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withTranslation } from 'react-i18next';
import { createMuiTheme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';

import { comboValue } from '../../utility/genericUtility';

const defaultTheme = createMuiTheme();

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: defaultTheme.spacing(1),
	marginRight: defaultTheme.spacing(1),
	marginTop: 16,
	marginBottom: 16,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 48,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
/*    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`, */
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,    
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
/*	fontSize: '1.0em', */
	padding: theme.spacing(1),
	color: theme.palette.color.windowsBlue,
  },
  singleValueReadonly: {
	  /*	fontSize: '1.0em', */
  	padding: theme.spacing(1),
  	color: theme.palette.color.greyLight,
  },
  placeholder: {
    position: 'absolute',
/*	fontSize: '1.0em',*/
	padding: theme.spacing(1),
    left: 2, 
	color: theme.palette.color.greyLight,    
	fontFamily: theme.typography.fontFamily,
	fontSize: theme.typography.fontSize,
    fontWeightLight: theme.typography.fontWeightLight,
    fontWeightRegular: theme.typography.fontWeightRegular,
    fontWeightMedium: theme.typography.fontWeightMedium,	 
  },
  placeholderError: {
    position: 'absolute',
/*	fontSize: '1.0em',*/
	padding: theme.spacing(1),
    left: 2, 
/*	color: theme.palette.color.greyLight,    */
    color: theme.palette.color.red,    
	fontFamily: theme.typography.fontFamily,
	fontSize: theme.typography.fontSize,
    fontWeightLight: theme.typography.fontWeightLight,
    fontWeightRegular: theme.typography.fontWeightRegular,
    fontWeightMedium: theme.typography.fontWeightMedium,
/*  color: '#f44336',*/
  },

  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
  },
  divider: {
    height: theme.spacing(2),
  },
  lblCls: {
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
	background: theme.palette.color.white,
  },
/*  overrides: {
	MuiTypography: {
	  body2: {
	    color: 'red',
	  },
	}
  }*/
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const { selectProps } = props;	      
//  const label = selectProps.label;
  const required = selectProps.required;
  return (
    <TextField
      fullWidth
      required={required}
      id='outlined-name'
      variant='outlined'
/*      label={label}*/  	  
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  const theme = props.selectProps.styles.Option.theme; 
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{ 
        fontWeight: props.isSelected ? 500 : 400,
		
        backgroundColor: props.isDisabled ? null : props.isSelected ? theme.palette.color.yellowChart : props.isFocused ? theme.palette.color.windowsBlue : null,
		color: props.isDisabled ? null : props.isSelected ? theme.palette.color.marine :props.isFocused ? theme.palette.color.white : theme.palette.color.windowsBlue,
		
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		fontWeightLight: theme.typography.fontWeightLight,
		fontWeightRegular: theme.typography.fontWeightRegular,
		fontWeightMedium: theme.typography.fontWeightMedium,	 
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const classes = props.selectProps.classes;	    
  const isError =  props.selectProps.error;	    
  const placeholderClass = (isError) ? classes.placeholderError : classes.placeholder;
	    
  return (
    <Typography
      color='textSecondary'
      /*className={props.selectProps.classes.placeholder}*/
    	  className={placeholderClass}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
	const isReadonly = props.selectProps.isReadonly;
	let clName = props.selectProps.classes.singleValue;
	if (isReadonly) {
		clName = props.selectProps.classes.singleValueReadonly;
	}
	
  return (
    <Typography variant='body2' className={clName} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper elevation={0} square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class SelectComp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      single: this.props.value,
      typing: false,
      isError: false,
	}
  }
  
  handleChange = value => {
    this.setState({
      single: value,
      typing: true,
    });
    if(this.props.onChange){
      this.props.onChange(value);
	}
  };

  handleOnBlur = value => {
    this.setState({
      typing: false,
    });
    if(this.props.onBlur){
      this.props.onBlur(value);
    }
  };

  handleError = (isError, id) => {
//    console.log('#######TextFieldComp handleError', id + ' ' + isError);
    this.setState({
      isError: isError,
    });    
    if(this.props.onError) {
      this.props.onError(id, isError);
    }
  };
	  
  valueSelect = () => {
	const aValue = (this.state.typing === true) ? comboValue(this.state.single) : comboValue(this.props.value);
    return aValue;
  };
	  
  calcolaRequired = required => {
	return (required === true) ? ' *' : '';
  }
  
  render() {
	const { t } = this.props;   //hoc 
    const { classes, theme, options, id, ricerca, etichetta, onChange, required, value, controlloDati, disabled, readonly } = this.props;
    //    const aValue = this.valueSelect();    
//  console.log('#RT2 SelectComp aValue', aValue);
    
    const isRequired = required === true;
    const isDisabled = disabled === true || readonly === true;
    const isReadonly = readonly === true;
    const aValue = disabled ? '' : this.valueSelect();
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === null);
    
    const etichettaCombo = t(etichetta);
    const ricercaCombo = t(ricerca) + ' ' + etichettaCombo + this.calcolaRequired(required);
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit', 
        },
      }),
      Option: {
    	 theme: theme,
      },
    };

    let textFieldProps = {
      InputLabelProps: {
        shrink: true,
        className: classes.lblCls,
/*        required: {required} */
      }
    };
    
    if (this.state.single) {
      textFieldProps.label = etichettaCombo;
/*    	required: {required}*/
    }
    
    return (
      <div className={classes.root}>
        <Select
/*          required={required} */
/*          label={ricercaCombo} */
          id={id}
          error={isError}
          required={isRequired}
          isDisabled={isDisabled}
          isReadonly={isReadonly}
          classes={classes}
          styles={selectStyles}
          options={options}
          components={components}
          value={aValue}
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
          placeholder={ricercaCombo}
          isClearable
          autosize={true}
          textFieldProps={textFieldProps}
        />
      </div>
    );
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;	  
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueSelect();
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === null);
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
//      console.log('########prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
//      console.log('########prevIsError !== isError, isError = ' + isError + ', id: ' + id);
//      console.log('########prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);
    }
  };   
}

SelectComp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(withTranslation()(withStyles(styles, { withTheme: true })(SelectComp)));
