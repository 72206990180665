import * as actionType from '../actionType/beneficiarioVita';

/* ********************** BENEFICIARIO VITA FISICO ************************* */
/*
export const aggiungiBenefVitaFisico = (currBenefVitaFisico, emptyBenefVitaFisico) => {
//  console.log('#RT2 Action aggiungiBenefVitaFisico.actionType.AGGIUNGI_BENEF_VITA_FISICO', actionType.AGGIUNGI_BENEF_VITA_FISICO);
//  console.log('#RT2 Action aggiungiBenefVitaFisico currBenefVitaFisico', currBenefVitaFisico);
  return {
    type: actionType.AGGIUNGI_BENEF_VITA_FISICO,
    currBenefVitaFisico,
    emptyBenefVitaFisico,
  }
};
*/
export const updateBeneficiarioVitaFisico = (currBenefVitaFisico, unActionType) => {
  return {
    type: unActionType,
    currBenefVitaFisico,
  }
};

export const confermaAggiungiBeneficiarioVitaFisico = (beneficiarioVitaFisico) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_FISICO,
    beneficiarioVitaFisico,
  }
};	

export const nuovoBeneficiarioVitaFisico = (beneficiarioVitaFisico) => {
  return {
    type: actionType.NUOVO_BENEFICIARIO_VITA_FISICO,
    beneficiarioVitaFisico,
  }
};

export const deleteDettaglioBenefVitaFisico = (benefVitaFisicoDelete)  => {
  return {
    type: actionType.DELETE_BENEF_VITA_FISICO,
    benefVitaFisicoDelete,
  }
};

export const caricaDettaglioBeneficiarioVita = (benefVitaList, index)  => {
  return {
    type: actionType.CARICA_BENEF_VITA,
    benefVitaList,
    figuraSelezIndex: index,
  }
};

/* UPDATE TAB SELEZIONATO BEN VITA*/
export const updateTabSelezionatoBenVita = (tabIndex) => {
  return {
    type: actionType.UPDATE_TAB_SELEZIONATO_BENEF_VITA,
    tabIndex,
  }
};

/* BENEFICIARIO VITA FISICO DATI GENERALI */
export const updateDatiGeneraliBenefVitaFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA FISICO DATI RESIDENZA */
export const updateDatiResidenzaBenefVitaFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA FISICO DATI ANTIRICICLAGGIO */
export const updateDatiAntiriciclaggioBenefVitaFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA FISICO DATI RELAZ CONTRAENTE */
export const updateDatiRelazContrBenefVitaFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* ******************** BENEFICIARIO VITA GIURIDICO ************************  */
/* AGGIUNGI BENEFICIARIO VITA GIURIDICO */
/*
export const aggiungiBenefVitaGiuridico = (currBenefVitaGiuridico, emptyBenefVitaGiuridico)  => {
  console.log('#RT2 Action aggiungiBenefVitaGiuridico.actionType.AGGIUNGI_BENEF_VITA_GIURIDICO', actionType.AGGIUNGI_BENEF_VITA_GIURIDICO);
  console.log('#RT2 Action aggiungiBenefVitaGiuridico benefVitaGiuridico', currBenefVitaGiuridico);
  return {
    type: actionType.AGGIUNGI_BENEF_VITA_GIURIDICO,
    currBenefVitaGiuridico,
    emptyBenefVitaGiuridico,
  }
};
*/

export const updateBeneficiarioVitaGiuridico = (currBenefVitaGiuridico, unActionType) => {
  return {
    type: unActionType,
    currBenefVitaGiuridico,
  }
};

export const confermaAggiungiBeneficiarioVitaGiuridico = (beneficiarioVitaGiuridico) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GIURIDICO,
    beneficiarioVitaGiuridico,
  }
};	

export const nuovoBeneficiarioVitaGiuridico = (beneficiarioVitaGiuridico) => {
  return {
    type: actionType.NUOVO_BENEFICIARIO_VITA_GIURIDICO,
    beneficiarioVitaGiuridico,
  }
};	

/* DELETE BENEFICIARIO VITA GIURIDICO */
export const deleteDettaglioBenefVitaGiuridico = (benefVitaGiuridicoDelete)  => {
  return {
    type: actionType.DELETE_BENEF_VITA_GIURIDICO,
    benefVitaGiuridicoDelete,
  }
};

/* BENEFICIARIO VITA GIURIDICO DATI GENERALI */
export const updateDatiGeneraliBenefVitaGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA GIURIDICO DATI RESIDENZA */
export const updateDatiResidenzaBenefVitaGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA GIURIDICO DATI RELAZ CONTR */
export const updateDatiRelazContrBenefVitaGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* ********************* BENEFICIARIO VITA GENERICO ************************* */
/* AGGIUNGI BENEFICIARIO VITA GENERICO */
/*
export const aggiungiBenefVitaGenerico = benefVitaGenerico => {
  console.log('#RT2 Action aggiungiBenefVitaGenerico.actionType.AGGIUNGI_BENEF_VITA_GENERICO', actionType.AGGIUNGI_BENEF_VITA_GENERICO);
  console.log('#RT2 Action aggiungiBenefVitaGenerico benefVitaGenerico', benefVitaGenerico);
  return {
    type: actionType.AGGIUNGI_BENEF_VITA_GENERICO,
    benefVitaGenerico,
  }
};
*/

export const updateBeneficiarioVitaGenerico = (currBenefVitaGenerico, unActionType) => {
  return {
    type: unActionType,
    currBenefVitaGenerico,
  }
};


export const confermaAggiungiBeneficiarioVitaGenerico = (beneficiarioVitaGenerico) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GENERICO,
    beneficiarioVitaGenerico,
  }
};

/* DELETE BENEFICIARIO VITA GENERICO */
export const deleteDettaglioBenefVitaGenerico = (benefVitaGenericoDelete)  => {
  return {
    type: actionType.DELETE_BENEF_VITA_GENERICO,
    benefVitaGenericoDelete,
  }
};

/* BENEFICIARIO VITA GENERICO DATI GENERALI */
export const updateDatiGeneraliBenefVitaGenerico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* BENEFICIARIO VITA GENERICO DATI GENERALI MINIMI*/
export const updateDatiGeneraliMinimiBeneficiarioVitaFisico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }
};

/* UPDATE DATI PER BENEFICIARI VITA */
export const updateDatiBeneficiarioVita = (unDato, unActionType, isFiduciaria) => {
  return {
    type: unActionType,
    unDato,
    isFiduciaria
  }
};

/* DATI SOCIETARI BENEFICIARIO VITA */
export const updateDatiSocietariBeneficiarioVita = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI MANDATO FIDUCIARIO BENEFICIARIO VITA */
export const updateDatiMandatoFiduciarioBenefVitaGiuridico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

export const aggiungiTitolareAlProprioBenefVitaGiuridico = (currTitolareEff) => {
  return {
    type: actionType.AGGIUNGI_TITOLARE_BENEF_VITA_GIURIDICO,
    currTitolareEff
  }
}
