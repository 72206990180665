import localURI from './localURI';
import serverURI from './serverURI';

export const isLocal = process.env.REACT_APP_API_KEY === 'LOCAL';

export const uris = (isLocal) ? localURI : serverURI;

export const fetchParam = (any) => {
  if (!isLocal) 
    return {
      accept: 'application/json',
      method: 'POST',
      body: JSON.stringify(any),
      headers: {
    	'Content-Type': 'application/json',
      },
    }
};
