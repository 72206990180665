import * as actionType from '../actionType/datiPasswordDimenticata';

export const updateDatiPasswordDimenticata = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaDatiPasswordDimenticata = controlloDati => {
  return {
    type: actionType.SALVA_DATI_PASSWORD_DIMENTICATA,
	controlloDati,
  }
};
			
export const inviaDatiPasswordDimenticata = (email) => {
  return {
    type: actionType.INVIA_DATI_PASSWORD_DIMENTICATA,
	email,
  }
};