//import { datiGeneraliBenefGiuridico, datiResidenza, datiRelazContr, datiCopiaDaAltraFigura } from './sezioni/sezioniAnagrafica';
import { datiGeneraliBenefGiuridico, datiResidenza, datiRelazContr, datiCopiaDaAltraFigura, datiSocietari, datiMandatoFiduciario, titolareEffBenefVitaList, datiAttEconomica, titolareEffBenefVitaListInBenef } from './sezioni/sezioniAnagraficaBenefVitaGiuridico';
import { RUOLO_BENEF_VITA, PERSONA_GIURIDICA } from '../../utility/jsConstants';
import { titolareEffBenefVita } from './titolareEffBenefVita';

export const benefVitaGiuridico = {
  controlloDati: '',
  anagraficaValida: null,  
  datiFigureAnagrBase: {
	ruolo: RUOLO_BENEF_VITA,
	tipoPers: PERSONA_GIURIDICA,
  },
  datiSocietari: datiSocietari,
  datiMandatoFiduciario: datiMandatoFiduciario,
  datiGeneraliBenefGiuridico: datiGeneraliBenefGiuridico,
  datiResidenza: datiResidenza,
  datiRelazContr: datiRelazContr,
  datiAttEconomica: datiAttEconomica,
  datiCopiaDaAltraFigura: datiCopiaDaAltraFigura,
  flTitolareEffBenefVita: '',
  titolareEffBenefVita: titolareEffBenefVita,
  titolareEffBenefVitaList: titolareEffBenefVitaList,
  titolareEffBenefVitaListInBenef: titolareEffBenefVitaListInBenef
};

export const benefVitaGiuridicoList = [
//  benefVitaGiuridico,
];