import React from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { uris, fetchParam } from '../../../uris';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ButtonComp from '../../common/ButtonComp';
import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DatePickerComp from '../../common/DatePickerComp';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/it';

import * as actions from './action/datiRicercaUtenza';
import * as actionType from './actionType/datiRicercaUtenza';

import DatiListaUtenza from './DatiListaUtenza';

import { ADMIN, STATO_UTENTE_REVOCATA, STATO_UTENTE_BLOCCATA } from '../../../utility/jsConstants';
import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  button: theme.button,
  dateFieldWidth: {
    width: 270,
  },
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	loginUser: state.areaProdottoReducer.loginUser,
	datiRicercaUtenza: state.areaDatiRicercaUtenzaReducer.gestioneUtenza.datiRicercaUtenza,
  };
};
	
//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  return {
	updateDatiRicercaUtenza: (unDato, unActionType) => dispatch(actions.updateDatiRicercaUtenza(unDato, unActionType)),
	selezionaDettaglioUtente: (dettaglioUtente) => dispatch(actions.selezionaDettaglioUtente(dettaglioUtente)),
  };
};

class DatiRicercaUtenza extends React.Component {
  constructor(props) {
    super(props);
    const datiRicercaUtenza = this.props.datiRicercaUtenza;
    this.state = {
      mandato: datiRicercaUtenza.mandato,
      descMandato:  datiRicercaUtenza.descMandato,
      cognome: datiRicercaUtenza.cognome,
	  nome: datiRicercaUtenza.nome,
	  utente: datiRicercaUtenza.utente,
	  profilo: datiRicercaUtenza.profilo,
      descProfilo: datiRicercaUtenza.descProfilo,
      dataCreazione: datiRicercaUtenza.dataCreazione, 
      dataScadenza: datiRicercaUtenza.dataScadenza,
      dataUltimoAccesso: datiRicercaUtenza.dataUltimoAccesso,
      mostraLista: '',
      utenteLista: [],
      esito: '',
    };
  };
    
  componentDidMount() {
	let mand = {
	  value: this.props.loginUser.mandato.trim(),
	  label: this.props.loginUser.descrMandato.trim(),
	};
	this.props.updateDatiRicercaUtenza(mand, actionType.UPDATE_MANDATO_RICERCA);
	this.setState({
	  mandato: this.props.loginUser.mandato.trim(),
	  descMandato: this.props.loginUser.descrMandato.trim(),
    });
  }
	  	  
  handleChangeConfermaDatiRicercaUtenza = event => {
    const opts = {
      codiceMandato: this.state.mandato,
	  user: this.state.utente,
	  cognome: this.state.cognome,
	  nome: this.state.nome,
	  codiceProfilo: this.state.profilo,
	  dataCreazione: this.state.dataCreazione!==null ? this.state.dataCreazione.format('DD/MM/YYYY') : '',
	  dataScadenza: this.state.dataScadenza!==null ? this.state.dataScadenza.format('DD/MM/YYYY') : '',
	  dataUltimoAccesso: this.state.dataUltimoAccesso!==null ? this.state.dataUltimoAccesso.format('DD/MM/YYYY') : '',
	};
	fetch(uris.ricercaUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  if(result.length > 1){
	      this.setState({ 
	    	mostraLista: 'S', 
	    	utenteLista: result,
	    	esito: '',
	      });
	    }else if(result.length === 1 && result[0].user!==''){
	      this.setUtenteDettaglio(result[0].user, result);
	      if(this.props.mostraDettaglio) {
	        this.props.mostraDettaglio(event, result[0].user);
	      }
	    }else{
	      this.setState({
	        esito: 'KO',
		    mostraLista: 'N', 
		    utenteLista: result,
	      });
	    }
	});
  }  
  
  ricercaUtente = () => {
	let utentiItems = [];
    const opts = {
      codiceMandato: this.state.mandato,
	  user: this.state.utente,
	  cognome: this.state.cognome,
	  nome: this.state.nome,
	  codiceProfilo: this.state.profilo,
	  dataCreazione: this.state.dataCreazione!==null ? this.state.dataCreazione.format('DD/MM/YYYY') : '',
	  dataScadenza: this.state.dataScadenza!==null ? this.state.dataScadenza.format('DD/MM/YYYY') : '',
	  dataUltimoAccesso: this.state.dataUltimoAccesso!==null ? this.state.dataUltimoAccesso.format('DD/MM/YYYY') : '',
	};
	fetch(uris.ricercaUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  utentiItems = result;
	});
	return utentiItems;
  }
  
  handleChangeMandato = event => {
	const nuovoMandato = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
	const vecchioMandato = textTrim(this.state.mandato, true);
	if (nuovoMandato !== vecchioMandato) {
      this.props.updateDatiRicercaUtenza(event, actionType.UPDATE_MANDATO_RICERCA);   
      this.setState({ 
        mandato: (event === null) ? '' : event.value,
        descMandato: (event === null) ? '' : event.label,
      });
	}
  };
  
  handleChangeCognome = event => {
	const nuovoCognome = textTrim(event.target.value, true);
	const vecchioCognome = textTrim(this.state.cognome, true);
	if (nuovoCognome !== vecchioCognome) {
      this.props.updateDatiRicercaUtenza(event.target.value, actionType.UPDATE_COGNOME_RICERCA);
      this.setState({ 
        cognome: event.target.value, 
      });
	}
  };
  
  handleChangeNome = event => {
	const nuovoNome = textTrim(event.target.value, true);
	const vecchioNome = textTrim(this.state.nome, true);
	if (nuovoNome !== vecchioNome) {
      this.props.updateDatiRicercaUtenza(event.target.value, actionType.UPDATE_NOME_RICERCA);   
      this.setState({ 
        nome: event.target.value, 
      });
	}
  };
  
  handleChangeUtente = event => {
	const nuovoUtente = textTrim(event.target.value, true);
	const vecchioUtente = textTrim(this.state.utente, true);
	if (nuovoUtente !== vecchioUtente) {
      this.props.updateDatiRicercaUtenza(event.target.value, actionType.UPDATE_UTENTE_RICERCA);   
      this.setState({ 
        utente: event.target.value, 
      });
	}
  };
    
  handleChangeProfilo = event => {
	const nuovoProfilo = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
	const vecchioProfilo = textTrim(this.state.profilo, true);
	if (nuovoProfilo !== vecchioProfilo) {
      this.props.updateDatiRicercaUtenza(event, actionType.UPDATE_PROFILO_RICERCA);   
      this.setState({ 
        profilo: (event === null) ? '' : event.value,
        descProfilo: (event === null) ? '' : event.label,
      });
	}
  };
  
  handleChangeDataCreazione = event => {
    if (isDataValid(event)) {	  
	  const nuovoDataCreazioneFormattata = dataFormat(event, 'DD/MM/YYYY');
	  const vecchiaDataNascitaFormattata = ((this.state.dataCreazione === null) || (this.state.dataCreazione === undefined)) ? null : this.state.dataCreazione.format('DD/MM/YYYY');    
      if (nuovoDataCreazioneFormattata !== vecchiaDataNascitaFormattata) {
        this.props.updateDatiRicercaUtenza(event, actionType.UPDATE_DATA_CREAZIONE_RICERCA);   
        this.setState({ 
          dataCreazione: event,
        });
      } else {
        console.log('###DATA NON VALIDA RicercaUtente handleChangeDataCreazione', event);
      }        
	}
  };
  
  handleChangeDataScadenza = event => {
    if (isDataValid(event)) {	  
      const nuovoDataScadenzaFormattata = dataFormat(event, 'DD/MM/YYYY');
	  const vecchiaDataScadenzaFormattata = ((this.state.dataScadenza === null) || (this.state.dataScadenza === undefined)) ? null : this.state.dataScadenza.format('DD/MM/YYYY');    
	  if (nuovoDataScadenzaFormattata !== vecchiaDataScadenzaFormattata) {
        this.props.updateDatiRicercaUtenza(event, actionType.UPDATE_DATA_SCADENZA_RICERCA);   
        this.setState({ 
          dataScadenza: event,
        });
      } else {
        console.log('###DATA NON VALIDA RicercaUtente handleChangeDataScadenza', event);
      }         
	}
  };
  
  handleChangeDataUltimoAccesso = event => {
    if (isDataValid(event)) {	  
	  const nuovoDataUltimoAccessoFormattata = dataFormat(event, 'DD/MM/YYYY');
      const vecchiaDataUltimoAccessoFormattata = ((this.state.dataUltimoAccesso === null) || (this.state.dataUltimoAccesso === undefined)) ? null : this.state.dataUltimoAccesso.format('DD/MM/YYYY');    
      if (nuovoDataUltimoAccessoFormattata !== vecchiaDataUltimoAccessoFormattata) {
        this.props.updateDatiRicercaUtenza(event, actionType.UPDATE_DATA_ULTIMO_ACCESSO_RICERCA);   
        this.setState({ 
          dataUltimoAccesso: event,
        });
      } else {
        console.log('###DATA NON VALIDA RicercaUtente handleChangeDataUltimoAccesso', event);
      }        
	}
  };
  
  isRequiredMandato = () => {
    return false;
  }
  
  isRequiredCognome = () => {
    return false;
  }
  
  isRequiredNome = () => {
    return false;
  }
  
  isRequiredUtente = () => {
    return false;
  }
   
  isRequiredProfilo = () => {
    return false;
  }
  
  isRequiredDataCreazione = () => {
    return false;
  }
  
  isRequiredDataScadenza = () => {
    return false;
  }
  
  isRequiredDataUltimoAccesso = () => {
    return false;
  }
  
  mostraDettaglio = (event, user) => {
	this.setUtenteDettaglio(user, null);
    if(this.props.mostraDettaglio) {
      this.props.mostraDettaglio(event, user);
    }
  }
  
  setUtenteDettaglio = (utente, utenteLista) => {
	let dettaglioUtente = {
	  cognome: '',
	  nome: '',
	  utente: '',
	  profilo: '',
	  email: '',
	  dataCreazione: null,
	  dataScadenza: null,
	  dataBlocco: null,
	  dataRevoca: null,
	  statoUtenza: '',
	  dataUltimaVariazione: null,
	};
    const utenteListaCopia = utenteLista === null ? {...this.state}.utenteLista : utenteLista;
    utenteListaCopia.forEach(function(item, index, array) {
      if(item.user === utente){
    	const dataInizio = moment(item.dataInizio, "YYYY-MM-DD");
    	const dataBlocco = item.stato===STATO_UTENTE_BLOCCATA ? dataInizio : null;
    	const dataRevoca = item.stato===STATO_UTENTE_REVOCATA ? dataInizio : null;
    	dettaglioUtente = {
    	  cognome: item.cognome,
    	  nome: item.nome,
    	  utente: item.user,
    	  profilo: item.profilo,
    	  email: item.email,
    	  dataCreazione: moment(item.dataCreazione, "YYYY-MM-DD"),
    	  dataScadenza: moment(item.dataScadenzaPwd, "YYYY-MM-DD"),
    	  dataBlocco: dataBlocco,
    	  dataRevoca: dataRevoca,
    	  statoUtenza: item.stato,
    	  dataUltimaVariazione: moment(item.dataUltimaVariazione, "YYYY-MM-DD"),
    	};
      }
    });
    this.props.selezionaDettaglioUtente(dettaglioUtente);
  }
  
  render() {
    const { classes, dominio, datiRicercaUtenza } = this.props;  
    const controlloDati = datiRicercaUtenza.controlloDati;
    const { t } = this.props; //hoc
    
    return (
     <Fragment>
       <Grid container direction='row' justify='center' alignItems='flex-start'>
         <Grid item xs={2}></Grid>
         <Grid item xs={8}>
	       <Grid container direction='row' justify='center' alignItems='flex-start'>
	         <Grid item xs={12}>
	         {this.props.loginUser.profilo.trim()===ADMIN &&
		       <TextFieldComp aStyle={classes.textFieldWidthPerc} id='mandato' label='mandato' value={datiRicercaUtenza.descMandato} readonly={true} /> 
	         }
	         {this.props.loginUser.profilo.trim()!==ADMIN &&
	           <SelectComp onChange={this.handleChangeMandato} options={dominio.mandatoItems} ricerca='comboRicerca' etichetta='mandato' id='mandato' 
		         value={{value: datiRicercaUtenza.mandato, label: datiRicercaUtenza.descMandato}} required={this.isRequiredMandato()} controlloDati={controlloDati} />
	         }
	         </Grid>  
	         <Grid item md={6} xs={12}>
	           <TextFieldComp onBlur={this.handleChangeCognome} aStyle={classes.textFieldWidthPerc} id='cognome' label='general.cognome' value={datiRicercaUtenza.cognome} 
	             required={this.isRequiredCognome()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp onBlur={this.handleChangeNome} aStyle={classes.textFieldWidthPerc} id='nome' label='general.nome' value={datiRicercaUtenza.nome} 
	             required={this.isRequiredNome()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp onBlur={this.handleChangeUtente} aStyle={classes.textFieldWidthPerc} id='utente' label='utente' value={datiRicercaUtenza.utente} 
	             required={this.isRequiredUtente()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <SelectComp onChange={this.handleChangeProfilo} options={dominio.profiloItems} ricerca='comboRicerca' etichetta='profilo' id='profilo' 
	        	 value={{value: datiRicercaUtenza.profilo, label: datiRicercaUtenza.descProfilo}} required={this.isRequiredProfilo()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp onChange={this.handleChangeDataCreazione} aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} 
	             id='dataCreazione' label='dataCreazione' value={datiRicercaUtenza.dataCreazione} required={this.isRequiredDataCreazione()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp onChange={this.handleChangeDataScadenza} aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} 
	             id='dataScadenza' label='dataScadenza' value={datiRicercaUtenza.dataScadenza} required={this.isRequiredDataScadenza()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item xs={12}>	
	           <DatePickerComp onChange={this.handleChangeDataUltimoAccesso} aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)}
	             id='dataUltimoAccesso' label='dataUltimoAccesso' value={datiRicercaUtenza.dataUltimoAccesso} 
	             required={this.isRequiredDataUltimoAccesso()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item xs={12}>
		       <Grid container direction="row" justify="center" alignItems="center">	
		         <Grid item>
		           {this.state.esito==='KO' &&
		    	     <Typography className={classes.textErrorMessage}>{t('ricercaKo')}</Typography>
		           }
		         </Grid>
		       </Grid>
		     </Grid>
	        </Grid>
	      </Grid>
	      <Grid item xs={2}></Grid>
	    </Grid>
	    <Grid container direction='row' justify='center' alignItems='flex-start'>
	      <Grid item xs={1}></Grid>
		  {this.state.mostraLista === 'S' &&
	        <Grid item xs={10}>
	          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
	            <Grid item xs={12}>
	              <DatiListaUtenza utenti={this.state.utenteLista} mostraDettaglio={this.mostraDettaglio} />
	             </Grid>
	           </Grid>
	         </Grid>
	       }
		  <Grid item xs={1}></Grid>
		</Grid>
		<Grid container direction='row' justify='center' alignItems='flex-start'>
	      <Grid item xs={1}></Grid>
	       <Grid item xs={10}>
		     <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
		       <Grid item>
		         <ButtonComp
		           aClassName={classes.button}
		           buttonLabel={'filtra'}
		           onClick={this.handleChangeConfermaDatiRicercaUtenza}
		         />
		       </Grid>
		     </Grid>
		   </Grid>
		   <Grid item xs={1}></Grid>
		 </Grid>
      </Fragment>	  
    );
  }
}

DatiRicercaUtenza.propTypes = {
  classes: PropTypes.object.isRequired, 
  dominio: PropTypes.object.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiRicercaUtenza)));
