import * as actionType from '../actionType/datiInviaNuoveCredenziali';
import initialState from '../../../../session/InitialState';

const areaDatiInviaNuoveCredenzialiReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  switch (action.type) {
    case actionType.SALVA_DATI_INVIA_NUOVE_CREDENZIALI:
      const gestioneUtenzaDatiInviaNuoveCredenzialiSalva = {...state}.gestioneUtenza;
      const datiInviaNuoveCredenzialiSalva = {...gestioneUtenzaDatiInviaNuoveCredenzialiSalva}.datiInviaNuoveCredenziali;
      datiInviaNuoveCredenzialiSalva.controlloDati = action.controlloDati;
      gestioneUtenzaDatiInviaNuoveCredenzialiSalva.datiInviaNuoveCredenziali = {...datiInviaNuoveCredenzialiSalva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiInviaNuoveCredenzialiSalva,
      };
    case actionType.UPDATE_MANDATO_INVIA:
      const gestioneUtenzaMandato = {...state}.gestioneUtenza;
      const datiNuovaUtenzaMandato = {...gestioneUtenzaMandato}.datiInviaNuoveCredenziali;
      datiNuovaUtenzaMandato.mandato = unValore;
      datiNuovaUtenzaMandato.descMandato = unaLabel;
      gestioneUtenzaMandato.datiInviaNuoveCredenziali = {...datiNuovaUtenzaMandato};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaMandato,
      };
    default:
      return state;
  }
}

export default areaDatiInviaNuoveCredenzialiReducer;