import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../common/ExpansionPanelComp';
import DatiInviaNuoveCredenziali from './sezione/DatiInviaNuoveCredenziali';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function InviaNuoveCredenzialiPanel(props) {
	
  const { classes } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'inviaNuoveCredenziali'} componente={DatiInviaNuoveCredenziali} senzaLensIcon={true} /> 
    </div>
  );
}

InviaNuoveCredenzialiPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InviaNuoveCredenzialiPanel);
