import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { textTrim } from '../../utility/genericUtility';

const styles = theme => ({
  textFieldError: theme.textFieldError,
});

class TextFieldComp extends React.Component {
		
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      typing: false,
      isError: false,
    }
  };
  
  handleChange = event => {
//    console.log('TextFieldComp old value this.state.value', this.state.value);
//    console.log('TextFieldComp new value event.target.value', event.target.value);
    if (!this.props.maxLength || (this.props.maxLength&&event.target.value.length<=this.props.maxLength)) {
      this.setState({
	    value: event.target.value,
	    typing: true,
      });
    }
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  };
	
  handleOnBlur = event => {
//    console.log('TextFieldComp handleOnBlur value this.state.value', this.state.value);
//    console.log('TextFieldComp handleOnBlur value event.target.value', event.target.value);
    this.setState({
      value: event.target.value,
      typing: false,
    });
    if(this.props.onBlur) {
      this.props.onBlur(event);
    }
  };
	 
  handleError = (isError, id) => {
//    console.log('#######TextFieldComp handleError', id + ' ' + isError);
    this.setState({
      isError: isError,
    });    
    if(this.props.onError) {
      this.props.onError(id, isError);
    }
  };  
  
  valueText = (trim) => {
	const aValue = (this.state.typing === true) ? textTrim(this.state.value, trim) : textTrim(this.props.value, trim);
	return aValue;
  };
	  
  render () {
    const { classes, onChange, aStyle, aStyleError, id, label, value, required, variantStyle, controlloDati, disabled, readonly, maxLength, onError, isControlError } = this.props;
    const { t } = this.props; //hoc	
    const variant = (variantStyle) ? variantStyle : 'outlined'; 
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const isReadonly = readonly === true;
    const isDisabledOrReadonly = (disabled === true || readonly === true);
    const aValue = isDisabled ? '' : this.valueText(false);
    const aLabel = t(label);
    const isError = (((controlloDati === true) && isRequired && !isDisabled && (aValue === '')) || (isControlError === true));   
    const aStyleToApply = (isError) ? (((aStyleError === null) || (aStyleError === undefined)) ? classes.textFieldError : aStyleError) : aStyle;       
    return (
      <TextField
        error={isError}
        required={isRequired}
        disabled={isDisabledOrReadonly}
        id={id}
        label={aLabel}
        className={classNames(aStyleToApply)}
        value={aValue}
        onChange={this.handleChange}
        onBlur={this.handleOnBlur}
        margin='normal'
        variant={variant}
      />
    )
  }; 
  
  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;	  
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueText(controlloDati);
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === '');
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
//      console.log('#######prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, isError = ' + isError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);      	
    }
  }; 
};

TextFieldComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(TextFieldComp));
