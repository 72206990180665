import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import AssicuratoFisicoAss from '../../anagrafica/figure/AssicuratoFisicoAss';
//import {buttonAssicuratoPanelItems} from '../../store/buttonItems';

const styles = theme => ({
  root: {
    width: '100%',
  }
});

function AssicuratoPanel(props) {
	
  const { classes, isValida } = props;
  return (
    <div className={classes.root}>      
      <ExpansionPanelComp titolo={'assicurato'} componente={AssicuratoFisicoAss} 
                          flAssUgContr={props.flAssUgContr} isValida={isValida} />
    </div>
  );
}

AssicuratoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssicuratoPanel);
