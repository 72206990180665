import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

//import "@babel/polyfill";

import store, { history } from './store';
import App from './App';
import AppTest from './containers/app/';

import i18n from './i18n/i18n';

import 'sanitize.css/sanitize.css';
import './index.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const windowsBlue = '#4E8FC9';
const marine = '#03296A';
const windowsBlue70 = '#8689E4';
const greyDark = '#3E3E3E';
const greyLight = '#CCCCCC';
const tan = '#F9F7F3';
const yellowChart = '#FBC56E';
const black = '#000000';
const white = '#FFFFFF';
const red = 'red';
const green = '#008000';
const gold = '#ffd700';
const fontFamily = "Frutiger";
const fontSize = 20;
const fontWeightLight = 300;
const fontWeightRegular = 400;
/*const fontWeightMedium = 500; */	
const fontWeightMedium = 700;

const defaultTheme = createMuiTheme();
const spacingUnit = defaultTheme.spacing(1);
const spacingUnit2 = defaultTheme.spacing(2);

const theme = createMuiTheme({
	palette: {
		color: {
			windowsBlue: windowsBlue, 
			marine: marine,
			windowsBlue70: windowsBlue70,
			greyDark: greyDark,
			greyLight: greyLight,
			tan: tan,
			yellowChart: yellowChart,
	        black: black,
			white: white,
			red: red,
			green: green,
			gold: gold,
		},			
	},	
	typography: {
		useNextVariants: true,
		fontFamily: fontFamily,
		fontSize: fontSize,
	    fontWeightLight: fontWeightLight,
	    fontWeightRegular: fontWeightRegular,
	    fontWeightMedium: fontWeightMedium,	    
	    color: windowsBlue,
	    h5: {
			color: windowsBlue, 
		},
	    h6: {
			color: marine,
		},
		caption: {
			color: windowsBlue,
		},
		subtitle1:  {
			color: marine,
		},
		subtitle2:  {
			color: white,
		},
		body1: {
			color: marine,
		},
		body2: {
			color: windowsBlue,
		},		
/*RT		body1: {
			color: marine,
		},
		body2: {
			color: windowsBlue,
		},	*/		
	},
	button: {
		margin: 8,
		color: windowsBlue,
		borderColor: windowsBlue,
	    border: '2px solid',
	    fontFamily: fontFamily,	    
	    width:'200px',
	    fontWeight: 'bold',
	    textTransform: 'capitalize',
	    borderRadius: 10,
	    '&:hover': { 
	    	background: windowsBlue, 
	    	color: white,
	    },
	},
	textAreaField: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit,	
	},
	textAreaFieldError: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit,	
/*		background: 'red',*/		
	},	
	textField: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit, 
		height: 48,	
	},
	textFieldWidthPerc: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit, 
		height: 48,	
		width: '97%',	
	},
	textFieldWidthPercError: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit, 
		height: 48,	
		width: '97%',
        '$notchedOutline': { 
          borderWidth: '2px',  	  
          borderColor: `${red} !important`,
        },
	},	
	textFieldError: {
        marginLeft: spacingUnit,
        marginRight: spacingUnit, 
        height: 48,	
/*		background: red, */
        '$notchedOutline': { 
          borderWidth: '2px',  	  
          borderColor: `${red} !important`,
        },
	},
	textErrorMessage: {
		fontSize: fontSize + 3,
		color: 'red',
		fontStyle: 'italic',
	},
	textStyleDisabled: {
		color: greyLight,
	},
	textStyleEnabled: {
		color: windowsBlue,
	},
	dateField: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit, 
		height: 48,
		width: 270,
	},	
	dateFieldError: {
		marginLeft: spacingUnit,
		marginRight: spacingUnit, 
		height: 48,
		width: 270,
        '$notchedOutline': { 
          borderWidth: '2px',  	  
          borderColor: `${red} !important`,
        },		
	},	
	expansion: {
/*		background: tan, */
		background: windowsBlue,
	},
	fieldSet: {
	  borderWidth: 2,
	  borderStyle: 'solid',
	  borderColor: marine,
	  borderRadius: 10,
    },
	fieldSetLikeRadioButtonCompStyle: {
	  marginLeft: spacingUnit,
	  marginBottom: spacingUnit,
//    padding: '0px  0px 0px 8px', 
	  padding: '0px',	  
	  borderWidth: 1,
	  borderRadius:6,
	  borderStyle: 'solid',
	  borderColor: greyLight,
	  float:'left',
	  width: '97%',
    },
    paperStyle: {
	  ...defaultTheme.mixins.gutters(),
	  paddingTop: spacingUnit2,
	  paddingBottom: spacingUnit2,
	  backgroundColor: tan,
	},
    overrides: {
      /* ######### Override MuiPickersUtilsProvider INIZIO ######### */     	
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: windowsBlue,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
/*          backgroundColor: windowsBlue,
          color: white,
          '&:hover': { 
    	    backgroundColor: greyLight, 
    	    color: windowsBlue,
    	  }, */
          backgroundColor: white,   
          color: windowsBlue,
        },
      },      
      MuiPickersDay: {
  	  /* giorno su calendario */
  	    day: {
          color: marine,
          '&:hover': { 
            backgroundColor: greyLight, 
            color: greyDark,
          },  
          '&$selected': {
            backgroundColor: greyDark,
            color: greyLight,
            '&:hover': { 
              backgroundColor: windowsBlue, 
              color: white,
            },
          },
        },  
/*      isSelected: {*/
/*      selected: {
          backgroundColor: greyDark,
          color: greyLight,
          '&:hover': { 
            backgroundColor: windowsBlue, 
            color: white,
	      },
        },*/ 
        /* giorno corrente */
        current: {	
          backgroundColor: yellowChart,
          color: marine,
          '&:hover': { 
            backgroundColor: yellowChart, 
            color: marine,
          },
        },
      },
      MuiPickersModal: {
      /* pulsanti */
        dialogAction: {
          backgroundColor: windowsBlue,
          color: white,
	      '&:hover': { 
	    	backgroundColor: white, 
	    	color: windowsBlue,
	      },           
        },
      },
      /* ######### Override MuiPickersUtilsProvider FINE ######### */ 
      /* ######### Override TextField INIZIO ######### */ 
      MuiFormLabel: { 
    	/* stili in override per utilizzare InputLabelProps in TextField */
        root: {
          color: greyLight, 
          '&$focused': { 
            color: windowsBlue,
          },
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeightLight: fontWeightLight,
          fontWeightRegular: fontWeightRegular,
          fontWeightMedium: fontWeightMedium,	
        },
    	focused: {
    	},
      },
      MuiOutlinedInput: { 
    	/* stili in override per utilizzare InputProps in TextField */     
        root: {
          '&$focused $notchedOutline': { 
             borderWidth: '2px',  	  
             borderColor: `${windowsBlue} !important`,
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        	  borderColor: `${windowsBlue} !important`,
              borderWidth: '2px',  	
          },
          color: windowsBlue,         
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeightLight: fontWeightLight,
          fontWeightRegular: fontWeightRegular,
      	  fontWeightMedium: fontWeightMedium,
      	  height: 'inherit',
        },
      	focused: {
      	},
      	notchedOutline: {
          borderWidth: '1px',  	 	
/*          borderColor: `${greyLight} !important`,*/
          borderColor: `${greyLight}`,          
        },      		
      },
      /* ######### Override TextField FINE ######### */   
      /* ######### Override SvgIcon INIZIO ######### */
      MuiSvgIcon: { 
    	/* stili in override per utilizzare InputProps in CheckBox */
        root: {
/*        	color: windowsBlue, */
          color: 'inherit', 
          fontSize: '1.5rem', /* serve per il dimensionamento delle icone */
      	  marginLeft: spacingUnit,
          marginRight: spacingUnit, 
        },
      },
      /* ######### Override SvgIcon FINE ######### */  
      MuiIconButton: {
        root: {
          color: 'inherit', 
        },
      },      
      /* ######### Override Tabs INIZIO ######### */    
      MuiTabs: {
    	/* barretta di selezione */
        indicator: {
          backgroundColor: windowsBlue,
        },
      },
      /* ######### Override Tabs FINE ######### */
      /* ######### Override ExpansionPanelSummary INIZIO ######### */    
      MuiExpansionPanelSummary: { 
        /* colore icona del pannello */
        expandIcon: {
            color: white,
        },
      }, 
      /* ######### Override ExpansionPanelSummary FINE ######### */   
      /* ######### Override Typography INIZIO ######### */ 
      /*MuiTypography: { 
        root: {
          color: windowsBlue, 
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeightLight: fontWeightLight,
          fontWeightRegular: fontWeightRegular,
          fontWeightMedium: fontWeightMedium,	
        },
      },*/
      /* ######### Override Typography FINE ######### */       
      /* ######### Override FormControlLabel INIZIO ######### */ 
      /* MuiFormControlLabel: { 
        root: {
          color: windowsBlue, 
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeightLight: fontWeightLight,
          fontWeightRegular: fontWeightRegular,
          fontWeightMedium: fontWeightMedium,	
        },
      }, 
      */ 
  	  /* ######### Override FormControlLabel FINE ######### */
      MuiCheckbox: {
        root: {
          color: windowsBlue,  
          '&$checked': {
            color: `${windowsBlue} !important`
          },    	
        },
/*    	  '&$checked': {color: `${windowsBlue} !important`,},*/    	  
      },
      MuiTableCell: {
    	  root: {
    		  paddingLeft: '0.3em' , 
    		  paddingRight: '0.3em' ,
    		  '&:last-child': {
    			  paddingRight: '0.3em' ,
    		  },
    	  },
      },
      MuiCardContent: {
    	  root: {
    		  '&:last-child': {
    			  paddingBottom: '0px' ,
    		  },
    		   
    	  },
      },
    },    
});		

const target = document.querySelector('#root');

//const unsubscribe = store.subscribe(() => console.log('#STORE', store.getState()));

render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
      <div>
      <App /> 
      {/* <AppTest/>  */}
        </div>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>,
  target
);

//unsubscribe();
