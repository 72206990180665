import React from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
//import { withTranslation } from 'react-i18next';

import TextFieldComp from '../../common/TextFieldComp';
import DatePickerComp from '../../common/DatePickerComp';
import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';

import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';
import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';
import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';
import { CODICE_MEDESIMO_SOGGETTO, BENEF_MORTE_GIURIDICO, BENEF_VITA_GIURIDICO } from '../../../utility/jsConstants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField, 
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  dateFieldWidth: {
    width: 270,
  },
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiGeneraliBenefGiuridico.mapStateToProps.state', state);
  return {
    contraenteFisicoValida: state.areaContraenteReducer.contraenteFisico.anagraficaValida,
    contraenteGiuridicoValida: state.areaContraenteReducer.contraenteGiuridico.anagraficaValida,
    datiGeneraliBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiGeneraliBenefGiuridico,
    controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,    
    datiGeneraliBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiGeneraliBenefGiuridico,
    controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
    codFiscContrGiur: state.areaContraenteReducer.contraenteGiuridico.datiGenerali.codiceFiscale,
    relazioneConContrVita: state.areaContraenteReducer.benefVitaGiuridico.datiRelazContr.relazContraente,
    relazioneConContrMorte: state.areaContraenteReducer.benefMorteGiuridico.datiRelazContr.relazContraente,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiGeneraliBenefGiuridico.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiGeneraliBenefVitaGiuridico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiGeneraliBenefVitaGiuridico(unDato, unActionType)),
    updateDatiGeneraliBenefMorteGiuridico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiGeneraliBenefMorteGiuridico(unDato, unActionType)),
  };
};
	
class DatiGeneraliBenefGiuridico extends React.Component {
  constructor(props) {
    super(props);
    console.log('#RTCFA DatiGeneraliBenefGiuridico props', props);	 
    const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(this.props.figura);
    this.state = {
      percBeneficiario: datiGeneraliGiuridico.percBeneficiario,
      ragioneSociale: datiGeneraliGiuridico.datiGenerali.ragioneSociale,
      codiceFiscale: datiGeneraliGiuridico.datiGenerali.codiceFiscale,
      dataNascita: datiGeneraliGiuridico.datiGenerali.dataNascita,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
      benDiversoImp: datiGeneraliGiuridico.benDiversoImp,
    };    
  };

  datiGeneraliGiuridicoFrom = figura => {
    let datiGeneraliGiuridico = null;
    if (this.props.figura === 'beneficiarioVitaGiuridico') {
      datiGeneraliGiuridico = this.props.datiGeneraliBenefVitaGiuridico;
    } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
      datiGeneraliGiuridico = this.props.datiGeneraliBenefMorteGiuridico;
    }
    return datiGeneraliGiuridico;
  }  
  
  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (this.props.figura === 'beneficiarioVitaGiuridico') {
      controlloDati = this.props.controlloDatiBenefVitaGiuridico;
    } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
      controlloDati = this.props.controlloDatiBenefMorteGiuridico;
    }
    return controlloDati;
  }  

  datiRelazioneFrom = figura => {
    let datiRelazione = null;
    if(figura === BENEF_MORTE_GIURIDICO) {
      datiRelazione = this.props.relazioneConContrMorte;
    } else if(figura === BENEF_VITA_GIURIDICO) {
      datiRelazione = this.props.relazioneConContrVita;
    }
    return datiRelazione;
  }
  
  handlePercBeneficiario = event => {	
    const nuovaPercBeneficiario = textTrim(event.target.value, true);
    const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(this.props.figura);
    const vecchiaPercBeneficiario = textTrim(datiGeneraliGiuridico.datiGenerali.percBeneficiario, true);
    if (nuovaPercBeneficiario !== vecchiaPercBeneficiario) {	     
      let anActionType = null;
      if (this.props.figura === 'beneficiarioVitaGiuridico') {
        anActionType = actionTypeBenefVita.UPDATE_PERCENTUALE_BENEF_VITA_GIURIDICO;
        this.props.updateDatiGeneraliBenefVitaGiuridico(nuovaPercBeneficiario, anActionType); 	
      } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
        anActionType = actionTypeBenefMorte.UPDATE_PERCENTUALE_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiGeneraliBenefMorteGiuridico(nuovaPercBeneficiario, anActionType);	      
      }    	
      this.setState({
        percBeneficiario: nuovaPercBeneficiario,
      });
    }  
  }
  
  handleChangeRagioneSociale = event => {
    const nuovaRagioneSociale = textTrim(event.target.value, true);
    const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(this.props.figura);   
    const vecchiaRagioneSociale = textTrim(datiGeneraliGiuridico.datiGenerali.ragioneSociale, true);
    if (nuovaRagioneSociale !== vecchiaRagioneSociale) {    
      let anActionType = null;
      if (this.props.figura === 'beneficiarioVitaGiuridico') {
        anActionType = actionTypeBenefVita.UPDATE_RAGIONE_SOCIALE_BENEF_VITA_GIURIDICO;    
        this.props.updateDatiGeneraliBenefVitaGiuridico(nuovaRagioneSociale, anActionType); 
      } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
        anActionType = actionTypeBenefMorte.UPDATE_RAGIONE_SOCIALE_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiGeneraliBenefMorteGiuridico(nuovaRagioneSociale, anActionType);
      }      
      this.setState({
        ragioneSociale: nuovaRagioneSociale, 
      });
    }  
  };
	  
  handleChangeCodFisc = event => {
    const nuovoCodiceFiscale = textTrim(event.target.value, true); 
    const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(this.props.figura);     
    const vecchioCodiceFiscale = textTrim(datiGeneraliGiuridico.datiGenerali.codiceFiscale, true);     
    if (nuovoCodiceFiscale !== vecchioCodiceFiscale) {        
      let anActionType = null;
      if (this.props.figura === 'beneficiarioVitaGiuridico') {
        anActionType = actionTypeBenefVita.UPDATE_CODICE_FISCALE_BENEF_VITA_GIURIDICO;
        this.props.updateDatiGeneraliBenefVitaGiuridico(nuovoCodiceFiscale, anActionType);
      } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
        anActionType = actionTypeBenefMorte.UPDATE_CODICE_FISCALE_BENEF_MORTE_GIURIDICO;
        this.props.updateDatiGeneraliBenefMorteGiuridico(nuovoCodiceFiscale, anActionType);
      }    
      this.setState({
        codiceFiscale: nuovoCodiceFiscale, 
      });
    }  
  }

  handleChangeBenDiversoImpresa = event => {
    const nuovoBenDiversoImp = textTrim(event.target.value, true);
    const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(this.props.figura); 
    const vecchioBenDiversoImp  = textTrim(datiGeneraliGiuridico.benDiversoImp, true);
    if(nuovoBenDiversoImp !== vecchioBenDiversoImp){
      let anActionType = null;
      if(this.props.figura === 'beneficiarioMorteGiuridico'){
        anActionType = actionTypeBenefMorte.UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_MORTE_GIUR;
        this.props.updateDatiGeneraliBenefMorteGiuridico(nuovoBenDiversoImp, anActionType);
      } else if(this.props.figura === 'beneficiarioVitaGiuridico'){
        anActionType= actionTypeBenefVita.UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_VITA_GIUR;
        this.props.updateDatiGeneraliBenefVitaGiuridico(nuovoBenDiversoImp, anActionType);
      }
      this.setState({
        benDiversoImp: nuovoBenDiversoImp,
      });
    }
  }

  

  handleChangeDataCostituzione = event => {
    if (isDataValid(event)) {
      const nuovaDataNascitaFormattata = dataFormat(event, 'DD/MM/YYYY');
      const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(this.props.figura);   
      const vecchiaDataNascitaFormattata = dataFormat(datiGeneraliGiuridico.datiGenerali.dataNascita, 'DD/MM/YYYY');
      if (nuovaDataNascitaFormattata !== vecchiaDataNascitaFormattata) {     
        let anActionType = null;
        if (this.props.figura === 'beneficiarioVitaGiuridico') {
          anActionType = actionTypeBenefVita.UPDATE_DATA_NASCITA_BENEF_VITA_GIURIDICO;
          this.props.updateDatiGeneraliBenefVitaGiuridico(event, anActionType);
        } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
          anActionType = actionTypeBenefMorte.UPDATE_DATA_NASCITA_BENEF_MORTE_GIURIDICO;
          this.props.updateDatiGeneraliBenefMorteGiuridico(event, anActionType);
        }        
        this.setState({
          dataNascita: event,
        });
      }
    } else {
      console.log('###DATA NON VALIDA ' + this.props.figura + ' handleChangeDataCostituzione', event);
    }      
  }
  
  isRequiredPercBeneficiario = () => {
    let required = false;
    if (this.props.figura === 'beneficiarioVitaGiuridico') {
      required = true;
    } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
      required = true;
    } 
    return required;
  }
  
  isRequiredRagioneSociale = () => {
	  let required = false;
	  if (this.props.figura === 'beneficiarioVitaGiuridico') {
	    required = true;
	  } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
	    required = true;
	  } 
	  return required;
  }

  isRequiredBenDiversoImp = () => {
    let required = false;
    if(this.props.scopeAmleto){
      const validaContrFis = this.props.contraenteFisicoValida;
      const validaContrGiu = this.props.contraenteGiuridicoValida;
      const relaz = this.datiRelazioneFrom(this.props.figura);
      if(validaContrFis !== null) {
        required = false;
      } else if(validaContrGiu !== null && relaz && relaz !== CODICE_MEDESIMO_SOGGETTO) {
        required = true;
      }
    }
	  return required;
  }
  
  isRequiredCodiceFiscale = () => {
	  let required = false;
	  if (this.props.figura === 'beneficiarioVitaGiuridico') {
	    required = true;
	  } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
	    required = true;
	  } 
	  return required;
  }
  
  isRequiredDataCostituzione= () => {
	  let required = false;
	  if (this.props.figura === 'beneficiarioVitaGiuridico') {
	    required = true;
	  } else if (this.props.figura === 'beneficiarioMorteGiuridico') {
	    required = true;
	  } 
	  return required;
  }

  isDisabledBenDiversoImp = () => {
    let disabled = true;
    if(this.props.scopeAmleto) {
      const validaContrFis = this.props.contraenteFisicoValida;
      const validaContrGiu = this.props.contraenteGiuridicoValida;
      const relaz = this.datiRelazioneFrom(this.props.figura);
      if(validaContrFis !== null) {
        disabled = true;
      } else if(validaContrGiu !== null && relaz && relaz !== CODICE_MEDESIMO_SOGGETTO) {
        disabled = false;
      }
    }
    return disabled;
  }
  
  initStatoCampi = () => {
    return [ {id: 'percBeneficiario', isError: false},
    		     {id: 'ragioneSociale', isError: false},
    		     {id: 'codiceFiscale', isError: false},
             {id: 'dataNascita', isError: false},
             {id: 'benDiversoImp', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {
    console.log('########DatiGeneraliBenefGiuridico handleError id', id);	  
    console.log('########DatiGeneraliBenefGiuridico handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  render() { 
	console.log('#RTCFA DatiGeneraliBenefGiuridico.this.props', this.props);
    const { classes, figura, labelFieldSet } = this.props;   
    const datiGeneraliGiuridico = this.datiGeneraliGiuridicoFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura);
//    const { t } = this.props; //hoc
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiGenerali')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={4} xs={12}>
            <NumberFieldComp onBlur={this.handlePercBeneficiario} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='percBeneficiario' label='percBeneficiario' 
                             format='###,###' value={datiGeneraliGiuridico.percBeneficiario} maxLength='7' required={this.isRequiredPercBeneficiario()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeRagioneSociale} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='ragioneSociale' label='ragioneSociale' 
            	           value={datiGeneraliGiuridico.datiGenerali.ragioneSociale} required={this.isRequiredRagioneSociale()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCodFisc} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='codiceFiscale' label='codiceFiscale' 
            	           value={datiGeneraliGiuridico.datiGenerali.codiceFiscale} required={this.isRequiredCodiceFiscale()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item xs={12}>
            <DatePickerComp onChange={this.handleChangeDataCostituzione} aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} 
                            id='dataCostituzione' label='dataCostituzione' value={datiGeneraliGiuridico.datiGenerali.dataNascita} 
            	            required={this.isRequiredDataCostituzione()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>	
          {/* <Grid item xs={12}>
            <TextFieldComp  onBlur={this.handleChangeBenDiversoImpresa} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                            id='benDiversoImp' label='benDiversoImp' value={datiGeneraliGiuridico.benDiversoImp} required={this.isRequiredBenDiversoImp()} 
                            controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledBenDiversoImp()} />
          </Grid> */}
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiEsecutore componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//		    console.log('########DatiEsecutore componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//		    console.log('########DatiEsecutore componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,      
      });
	}
  }

/*  isResetComp(prevProps, prevState) {	  
	  	
	const resetCompCurr = this.props.resetComp;
	const resetCompPrev = prevProps.resetComp;
	
	if (resetCompCurr!==undefined && resetCompCurr===true && resetCompCurr!==resetCompPrev) {
		this.setState ({
			percBeneficiario: '',
		    ragioneSociale: '',
		    codiceFiscale: '',
		    dataNascita: null,
		    statoCampi: this.initStatoCampi(),
		    isPresenteErrore: false,
		});
		console.log('########DatiGeneraliBenefGiuridico isResetComp this.state', this.state);
	}
  } */
  
  componentDidUpdate(prevProps, prevState) {
//	this.isResetComp(prevProps, prevState);
    this.isInError(prevProps, prevState);
  }
}

DatiGeneraliBenefGiuridico.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiGeneraliBenefGiuridico));
