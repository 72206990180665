import * as actionType from '../actionType/datiModificaUtenza';
import initialState from '../../../../session/InitialState';

const areaDatiModificaUtenzaReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  switch (action.type) {
    case actionType.SALVA_DATI_MODIFICA_UTENZA:
      const gestioneUtenzaDatiModificaUtenzaSalva = {...state}.gestioneUtenza;
      const datiModificaUtenzaSalva = {...gestioneUtenzaDatiModificaUtenzaSalva}.datiModificaUtenza;
      datiModificaUtenzaSalva.controlloDati = action.controlloDati;
      gestioneUtenzaDatiModificaUtenzaSalva.datiModificaUtenza = {...datiModificaUtenzaSalva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiModificaUtenzaSalva,
      };
    case actionType.RIATTIVA_DATI_MODIFICA_UTENZA:
      const gestioneUtenzaDatiModificaUtenzaRiattiva = {...state}.gestioneUtenza;
      const datiModificaUtenzaRiattiva = {...gestioneUtenzaDatiModificaUtenzaRiattiva}.datiModificaUtenza;
      datiModificaUtenzaRiattiva.dataRevoca = action.dataRevoca;
      datiModificaUtenzaRiattiva.statoUtenza = action.statoUtenza;
      datiModificaUtenzaRiattiva.dataUltimaVariazione = action.dataUltimaVariazione;
      gestioneUtenzaDatiModificaUtenzaRiattiva.datiModificaUtenza = {...datiModificaUtenzaRiattiva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiModificaUtenzaRiattiva,
      };
    case actionType.REVOCA_DATI_MODIFICA_UTENZA:
      const gestioneUtenzaDatiModificaUtenzaRevoca = {...state}.gestioneUtenza;
      const datiModificaUtenzaRevoca = {...gestioneUtenzaDatiModificaUtenzaRevoca}.datiModificaUtenza;
      datiModificaUtenzaRevoca.dataRevoca = action.dataRevoca;
      datiModificaUtenzaRevoca.statoUtenza = action.statoUtenza;
      datiModificaUtenzaRevoca.dataUltimaVariazione = action.dataUltimaVariazione;
      gestioneUtenzaDatiModificaUtenzaRevoca.datiModificaUtenza = {...datiModificaUtenzaRevoca};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDatiModificaUtenzaRevoca,
      };
    case actionType.SBLOCCA_DATI_MODIFICA_UTENZA:
      const gestioneUtenzaDatiModificaUtenzaSblocca = {...state}.gestioneUtenza;
      const datiModificaUtenzaSblocca = {...gestioneUtenzaDatiModificaUtenzaSblocca}.datiModificaUtenza;
      datiModificaUtenzaSblocca.dataSblocco = action.dataSblocco;
      datiModificaUtenzaSblocca.statoUtenza = action.statoUtenza;
      datiModificaUtenzaSblocca.dataUltimaVariazione = action.dataUltimaVariazione;
      gestioneUtenzaDatiModificaUtenzaSblocca.datiModificaUtenza = {...datiModificaUtenzaSblocca};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDatiModificaUtenzaSblocca,
      };
    case actionType.MODIFICA_DATI_MODIFICA_UTENZA:
      const gestioneUtenzaDatiModificaUtenzaModifica = {...state}.gestioneUtenza;
      const datiModificaUtenzaModifica = {...gestioneUtenzaDatiModificaUtenzaModifica}.datiModificaUtenza;
      datiModificaUtenzaModifica.email = action.email;
      datiModificaUtenzaModifica.dataUltimaVariazione = action.dataUltimaVariazione;
      gestioneUtenzaDatiModificaUtenzaModifica.datiModificaUtenza = {...datiModificaUtenzaModifica};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDatiModificaUtenzaModifica,
      };
    case actionType.UPDATE_MANDATO_MODIFICA:
      const gestioneUtenzaMandato = {...state}.gestioneUtenza;
      const datiModificaUtenzaMandato = {...gestioneUtenzaMandato}.datiModificaUtenza;
      datiModificaUtenzaMandato.mandato = unValore;
      datiModificaUtenzaMandato.descMandato = unaLabel;
      gestioneUtenzaMandato.datiModificaUtenza = {...datiModificaUtenzaMandato};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaMandato,
      };
    case actionType.UPDATE_UTENTE_MODIFICA:
        const gestioneUtenzaUtente = {...state}.gestioneUtenza;
        const datiModificaUtenzaUtente = {...gestioneUtenzaUtente}.datiModificaUtenza;
        datiModificaUtenzaUtente.utente = action.unDato;
        gestioneUtenzaUtente.datiModificaUtenza = {...datiModificaUtenzaUtente};
        return {
          ...state,
        	gestioneUtenza: gestioneUtenzaUtente,
        };
    case actionType.UPDATE_EMAIL_MODIFICA:
      const gestioneUtenzaEmail = {...state}.gestioneUtenza;
      const datiModificaUtenzaEmail = {...gestioneUtenzaEmail}.datiModificaUtenza;
      datiModificaUtenzaEmail.email = action.unDato;
      gestioneUtenzaEmail.datiModificaUtenza = {...datiModificaUtenzaEmail};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaEmail,
      };
    case actionType.UPDATE_PROFILO_MODIFICA:
      const gestioneUtenzaProfilo = {...state}.gestioneUtenza;
      const datiModificaUtenzaProfilo = {...gestioneUtenzaProfilo}.datiModificaUtenza;
      datiModificaUtenzaProfilo.profilo = unValore;
      datiModificaUtenzaProfilo.descProfilo = unaLabel;
      gestioneUtenzaProfilo.datiModificaUtenza = {...datiModificaUtenzaProfilo};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaProfilo,
      };
    case actionType.UPDATE_DATA_CREAZIONE_MODIFICA:
      const gestioneUtenzaDataCreazione = {...state}.gestioneUtenza;
      const datiModificaUtenzaDataCreazione = {...gestioneUtenzaDataCreazione}.datiModificaUtenza;
      datiModificaUtenzaDataCreazione.dataCreazione = action.unDato;
      gestioneUtenzaDataCreazione.datiModificaUtenza = {...datiModificaUtenzaDataCreazione};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataCreazione,
      };
	case actionType.UPDATE_DATA_SCADENZA_MODIFICA:
	  const gestioneUtenzaDataScadenza = {...state}.gestioneUtenza;
	  const datiModificaUtenzaDataScadenza = {...gestioneUtenzaDataScadenza}.datiModificaUtenza;
	  datiModificaUtenzaDataScadenza.dataScadenza = action.unDato;
	  gestioneUtenzaDataScadenza.datiModificaUtenza = {...datiModificaUtenzaDataScadenza};
	  return {
	    ...state,
	    gestioneUtenza: gestioneUtenzaDataScadenza,
	  };
    case actionType.UPDATE_DATA_BLOCCO_MODIFICA:
      const gestioneUtenzaDataBlocco = {...state}.gestioneUtenza;
      const datiModificaUtenzaDataBlocco = {...gestioneUtenzaDataBlocco}.datiModificaUtenza;
      datiModificaUtenzaDataBlocco.dataBlocco = action.unDato;
      gestioneUtenzaDataBlocco.datiModificaUtenza = {...datiModificaUtenzaDataBlocco};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataBlocco,
      };
    case actionType.UPDATE_DATA_REVOCA_MODIFICA:
      const gestioneUtenzaDataRevoca = {...state}.gestioneUtenza;
      const datiModificaUtenzaDataRevoca = {...gestioneUtenzaDataRevoca}.datiModificaUtenza;
      datiModificaUtenzaDataRevoca.dataRevoca = action.unDato;
      gestioneUtenzaDataRevoca.datiModificaUtenza = {...datiModificaUtenzaDataRevoca};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataRevoca,
      };
    case actionType.UPDATE_DATA_ULTIMA_VARIAZIONE_MODIFICA:
      const gestioneUtenzaDataUltimaVariazione = {...state}.gestioneUtenza;
      const datiModificaUtenzaDataUltimaVariazione = {...gestioneUtenzaDataUltimaVariazione}.datiModificaUtenza;
      datiModificaUtenzaDataUltimaVariazione.dataUltimaVariazione = action.unDato;
      gestioneUtenzaDataUltimaVariazione.datiModificaUtenza = {...datiModificaUtenzaDataUltimaVariazione};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataUltimaVariazione,
      };
    case actionType.UPDATE_STATO_UTENZA_MODIFICA:
      const gestioneUtenzaStatoUtenza = {...state}.gestioneUtenza;
      const datiModificaUtenzaStatoUtenza = {...gestioneUtenzaStatoUtenza}.datiModificaUtenza;
      datiModificaUtenzaStatoUtenza.statoUtenza = action.unDato;
      gestioneUtenzaStatoUtenza.datiModificaUtenza = {...datiModificaUtenzaStatoUtenza};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaStatoUtenza,
      };
    default:
      return state;
  }
}

export default areaDatiModificaUtenzaReducer;