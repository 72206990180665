import { datiPremio, datiRipartizionePremio, datiRipartizionePremioFondi,  datiOpzioniContrattuali, datiPianoErogazioniPeriodiche, 
	     datiTakeProfit, datiModalitaPagamento, datiModPagAssegno, datiModPagBonifico, datiModPagReimpiego, 
	     datiPremioCapitaleAssicurato, datiModPagAltro, datiModPagBonificoEstero, datiModPagCarta,
	   } from './sezioni/sezioniCaratteristicheContratto';

export const caratteristicheContratto = {
  datiPremio: datiPremio,
  datiRipartizionePremio: datiRipartizionePremio,
  datiOpzioniContrattuali: datiOpzioniContrattuali,
  datiPianoErogazioniPeriodiche: datiPianoErogazioniPeriodiche,
  datiTakeProfit: datiTakeProfit,
  datiModalitaPagamento: datiModalitaPagamento,
  datiModPagAssegno: datiModPagAssegno,
  datiModPagBonifico: datiModPagBonifico,
  datiModPagBonificoEstero: datiModPagBonificoEstero,
  datiModPagReimpiego: datiModPagReimpiego,
  datiModPagCarta: datiModPagCarta,
  datiModPagAltro: datiModPagAltro,
  datiRipartizionePremioFondi: datiRipartizionePremioFondi,
  datiPremioCapitaleAssicurato: datiPremioCapitaleAssicurato,
};