import * as actionType from '../actionType/dominio';
import * as actionTypeGeneric from '../../generic/actionType/generic';
import initialState from '../../../session/InitialState';

const areaDatiDominioReducer = (state = initialState, action) => {
	
  console.log('#RT2 Reducer areaDatiDominioReducer action.type', action.type);
  const dominio = {...state}.dominio;
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const dominioInizializza = inizializzaDominio(state);
	  return {
	    ...state,
	    dominio: dominioInizializza,
	  };   
	  
    case actionType.DOMINIO_NAZIONI:      
      dominio.nazioneItems = action.unaLista;
      return {
      	...state,
      	dominio: dominio,
      };   
    case actionType.DOMINIO_PROVINCE:      
      dominio.provinciaItems = action.unaLista;
      return {
    	...state,
    	dominio: dominio,
      };   
    case actionType.DOMINIO_TIPO_DOCUMENTO:      
      dominio.tipoDocumentoItems = action.unaLista;
      return {
        ...state,
        dominio: dominio,
      };
    case actionType.DOMINIO_ENTE_RILASCIO:      
      dominio.enteRilascioItems = action.unaLista;
      return {
        ...state,
        dominio: dominio,
      };
    case actionType.DOMINIO_SCOPO_NATURA_RAPPORTO:      
        dominio.scopoNaturaRapportoItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_TIPO_SOCIETA:      
        dominio.tipoSocietaItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_BENEFICIARIO_GENERICO_MORTE:      
        dominio.beneficiarioGenericoMorteItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_BENEFICIARIO_GENERICO_VITA:      
        dominio.beneficiarioGenericoVitaItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_PREVALENTE_ATTIVITA_SVOLTA:      
        dominio.prevalenteAttivitaSvoltaItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_PREVALENTE_ATTIVITA_SVOLTA_GIURIDICO:      
        dominio.prevalenteAttivitaSvoltaGiuridicoItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };        
    case actionType.DOMINIO_ORIGINE_FONDI:      
        dominio.origineFondiItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_ALLOCAZIONI:      
        dominio.allocazioneItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DOMINIO_FONDI:      
        dominio.fondiItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.PERCENTUALE_OBIETTIVO_TAKEPROFIT:      
        dominio.percTakeProfitItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.FREQUENZA_EROGAZIONI_PERIODICHE:      
        dominio.frequenzaErogazioniPeriodicheItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.MODALITA_PAGAMENTO:      
        dominio.modalitaPagamentoItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.BANCA:      
        dominio.bancaItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.CONSENSI:      
        dominio.consensiItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DICHIARIAZIONI_INTERMEDIARIO:      
        dominio.dichiarazioniIntermediarioItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.REGIONI:      
        dominio.regioneItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.DURATA_EROGAZIONI_PERIODICHE:      
        dominio.durataItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.CLASSE_PREMIO_POTENZIALE:      
        dominio.classePremioPotenzialeItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.TARIFFA_PER_PRODOTTO:      
        dominio.tariffaItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.CAPITALE_ASSICURATO_PER_PRODOTTO:      
        dominio.capitaleAssicuratoMalattiaGraveItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.FREQUENZA_CAPITALE_ASSICURATO:      
        dominio.frequenzaCapitaleAssicuatoItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.RUOLO_X_PRODOTTO:      
        dominio.ruoloXProdottoItems = action.unaLista;
        return {
          ...state,
          dominio: dominio,
        };
    case actionType.MANDATO:      
        dominio.mandatoItems = action.unaLista;
        return {
      	...state,
      	dominio: dominio,
        }; 
    case actionType.PROFILO:      
        dominio.profiloItems = action.unaLista;
        return {
      	...state,
      	dominio: dominio,
        };
    case actionType.DOMINIO_NAZIONE_ITALIA:      
        dominio.nazioneItaliaItems = action.unaLista;
        return {
      	...state,
      	dominio: dominio,
        };
    case actionType.DOMINIO_PROFESSIONI_QAS:
        dominio.professioniQas = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }
    case actionType.DOMINIO_SPORT_QAS:
        dominio.sportQas = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }
    case actionType.DOMINIO_RELAZIONI:
        dominio.relazioneItems = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }
    case actionType.DOMINIO_PAESI_BLACK_LIST:
        dominio.paesiBlackListItems = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }
    case actionType.DOMINIO_CITTADINANZA_PAESI_BLACK_LIST:
        dominio.cittadinanzaPaesiBlackListItems = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }       
    case actionType.DOMINIO_PROFILO_RISCHIO:
        dominio.profiloRischioItems = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }
    case actionType.DOMINIO_PROFILO_RISCHIO_OBBLIGO:
        dominio.profiloRischioObbligo = action.unValore;
        return {
          ...state,
          dominio: dominio
        }
    case actionType.DOMINIO_RAPPORTO_CONTRAENTE_INTERMEDIARIO:
        dominio.rapportoContrIntItems = action.unaLista;
        return {
          ...state,
          dominio: dominio
        }
    default:
      return state;
  }
}

export const inizializzaDominio = (state) => {
  //inizializzati solo i domini dipendenti dal prodotto/mandato
  const dominio_INI = {...state}.dominio; 
  dominio_INI.ruoloXProdottoItems = [];
  dominio_INI.allocazioneItems = [];
  dominio_INI.percTakeProfitItems = [];
  dominio_INI.frequenzaErogazioniPeriodicheItems = [];
  dominio_INI.durataItems = [];
  dominio_INI.classePremioPotenzialeItems = [];
  dominio_INI.tariffaItems = [];
  dominio_INI.capitaleAssicuratoMalattiaGraveItems = [];
  dominio_INI.frequenzaCapitaleAssicuatoItems = [];
  dominio_INI.profiloRischioObbligo = []; // legato al mandato
  return dominio_INI;
}

export default areaDatiDominioReducer;