import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import StampaProposal from '../stampa/StampaProposal';

const styles = theme => ({
  marginTop: {
	marginTop : 20,
  },
  textField: theme.textField,
  fieldSet: theme.fieldSet,
  button: theme.button, 
});


class DatiAnteprimaStampa extends React.Component {
	
  constructor(props) {
    super(props);
    this.state={
    	controlloDati: '',
    }
  };
  
  render() {
    const { classes, labelFieldSet, figura } = this.props;
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiFiscali')}</Typography></legend>*/}
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item className={classes.marginTop}>
          	<StampaProposal anteprimaStampa={true} />
          </Grid>
        </Grid>
	  {/*</fieldset>*/}
	  </Fragment>
    );
  }
}

DatiAnteprimaStampa.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatiAnteprimaStampa);
