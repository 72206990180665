import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';
import CheckboxComp from '../../common/CheckboxComp';
import RadioButtonComp from '../../common/RadioButtonComp';

import * as actionsDominio from '../../common/action/dominio';
import * as actions from '../figure/action/contraente';
import * as actionType from '../figure/actionType/contraente';

import { CONTRAENTE_FISICO, CONTRAENTE_GIURIDICO, /*, TITOLARE_EFFETTIVO */
         CODICE_ALTRA_ORIGINE_FONDI,
         CODICE_ALTRI_SOGGETTI_DA_TUTELARE,
         COD_NAZIONE_ITALIA, MODALITA_PAGAMENTO_TYPE, locale_it } from '../../../utility/jsConstants';	

import { textTrim } from  '../../../utility/genericUtility';
//2020-12-01 PFD: SAV 'TO BE' import { radioButtonStipulazionePerConto } from '../../store/radioButtonStore';
import { fetchParam, uris } from '../../../uris';
import moment from 'moment';




const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiNaturaRapportoContr: state.areaContraenteReducer.contraenteFisico.datiNaturaRapporto,
    controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,    
    datiNaturaRapportoContrGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiNaturaRapporto,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    areaProdotto: state.areaProdottoReducer.area,
    datiModalitaPagamento: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    agenziaNonAdeguataOrigineFondi: state.genericReducer.generic.proposalInfo.agenziaNonAdeguataOrigineFondi,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  return {
	dominioOrigineFondi: (origineFondiItems) => dispatch(actionsDominio.dominioOrigineFondi(origineFondiItems)),
    updateDatiNaturaRapporto: (unDato, unActionType, figura) => dispatch(actions.updateDatiNaturaRapporto(unDato, unActionType, figura)),
  };
};
	
class DatiNaturaRapporto extends React.Component {
  constructor(props) {
    super(props);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);     
    this.state = {
      scopoNaturaRapporto: datiNaturaRapporto.scopoNaturaRapporto,
      descrScopoNaturaRapporto: datiNaturaRapporto.descrScopoNaturaRapporto,
      origineFondi: datiNaturaRapporto.origineFondi,
      descrOrigineFondi: datiNaturaRapporto.descrOrigineFondi,
      altraOrigine: datiNaturaRapporto.altro,
// 2020-12-01 PFD: SAV 'TO BE'       stipulatoPerConto: datiNaturaRapporto.stipulatoPerConto,
      /* codScopoNaturaContratto: datiNaturaRapporto.codScopoNaturaContratto,
      descScopoNaturaContratto: datiNaturaRapporto.descrScopoNaturaRapporto, */
      codSoggettoDaTutelare: datiNaturaRapporto.codSoggettoDaTutelare,
      descSoggettoDaTutelare: datiNaturaRapporto.descSoggettoDaTutelare,
      altriSoggetti: datiNaturaRapporto.altriSoggetti,
      soggettiDaTutelareItems: [],
      checkedOriginiFondiList: [],
      errorOrigineFondi: false,
      isAltraOrigine: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,  
      origineFondiItems: [],
      statoProvenienzaSommeItems: [],
    };
  };
  
  componentDidMount() {	  
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);    
    if(datiNaturaRapporto.altro && !this.state.isAltraOrigine) {
      this.setState({
        isAltraOrigine: true
      })
    }
    if(datiNaturaRapporto.scopoNaturaRapporto === '') {
      let natura = {
        value: '',
        label: '',
      };
      const codiceAreaProdotto = this.props.areaProdotto.codice;
      if (this.props.dominio !== undefined && this.props.dominio.scopoNaturaRapportoItems !== undefined) {
        this.props.dominio.scopoNaturaRapportoItems.forEach(function(item, index, array) {
          if(item.value === codiceAreaProdotto) {
            natura = {
              value: item.value,
              label: item.label,
            };
          }
        });
      }
      this.props.updateDatiNaturaRapporto(natura, actionType.UPDATE_SCOPO_NATURA_RAPPORTO, this.props.figura);
      this.setState({
        scopoNaturaRapporto: natura.value,
        descrScopoNaturaRapporto: natura.label,
      });	
    }
    
    // soggetti da tutelare
    const dataOggiFormatta = moment().format('DD/MM/YYYY');
    const optsSoggettiDaTutelare = {
      siglaVersione: 'ZURI',
      codiceCampo:'STUT',
      dataRichiesta: dataOggiFormatta
    }
    fetch(uris.soggettiDaTutelareItems, fetchParam(optsSoggettiDaTutelare))
    .then(
      response => response.json(),
      error => console.error("An Error occured...", error)
    )
    .then((result) => {
      this.setState({
        soggettiDaTutelareItems: result
      })
    });

    // tempo provvista
    const optsProvvista = {
      siglaVersione: 'ZURI',
      codiceCampo: 'TEMP',
      dataRichiesta: dataOggiFormatta
    }
    fetch(uris.tempoProvvistaItems, fetchParam(optsProvvista))
    .then(
      response => response.json(),
      error => console.error("An Error occured...", error)
    )
    .then((result) => {
      this.setState({
        tempoProvvistaItems: result
      })
    });
    
  
    /* Origine dei Fondi */
  	if (!this.props.dominio.origineFondiItems) {
	  	let codiceCampo = null;
	    if (this.props.agenziaNonAdeguataOrigineFondi) {
	    	// nel caso di Agenzia NON Adeguata     	
			codiceCampo= 'OFON';
	    } else {
	    	// nel caso di Agenzia Adeguata
	    	codiceCampo= 'ORFO';
	    }
	    
	    const optsOrigineFondi = {
			siglaVersione: 'ZURI',
		    codiceCampo: codiceCampo,
		    dataRichiesta: dataOggiFormatta,
		}
	    fetch(uris.origineFondiItems, fetchParam(optsOrigineFondi))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	     )
	     .then((result) => {
	       // If request is good update state with fetched data
	       this.setState({
	    	   origineFondiItems: result,
	       });
	       console.log('[ZSE] componentDidMount result ... ', result);
	       this.props.dominioOrigineFondi(result);
	    });
  	} else {
  		 this.setState({
	    	   origineFondiItems: this.props.dominio.origineFondiItems,
	       });
  	}
    	    
  	const origini = this.props.dominio.origineFondiItems;
//2021-07-01 PFD: TCM_INDIV  perche' non mappare anche CONTRAENTE_GIURIDICO??'  if(this.props.figura === CONTRAENTE_FISICO) {
   	  var checkedOriginiFondiList = new Array();
      datiNaturaRapporto.origineFondiList.map((el, key) => {
        if(el.checked) {
//          const index = origini.findIndex(nuovaOrigine => nuovaOrigine.value === el.value);
//          this.state.checkedOriginiFondiList.push(index);
          checkedOriginiFondiList.push(el.value);
          this.setState({
//            checkedOriginiFondiList: this.state.checkedOriginiFondiList
              checkedOriginiFondiList: checkedOriginiFondiList,
          })
        }
      });
      
////2021-07-01 PFD: TCM_INDIV    }
    
    // 2020-12-01 PFD: SAV 'TO BE'
    // stato provenienza somme (esluso ITALIA)
    if (this.props.dominio !== undefined && this.props.dominio.nazioneItems !== undefined) {
        const statoProvenienzaSomme = this.props.dominio.nazioneItems.filter(item => {
          if(item.value !== COD_NAZIONE_ITALIA) {
            return item;
          }
        });
        this.setState({
        	statoProvenienzaSommeItems: statoProvenienzaSomme
          })
      }
  }
  
  datiNaturaRapportoFrom = figura => {  
    let datiNaturaRapporto = null;
    if (figura === CONTRAENTE_FISICO) {
      datiNaturaRapporto = this.props.datiNaturaRapportoContr;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      datiNaturaRapporto = this.props.datiNaturaRapportoContrGiuridico;
    } 
    return datiNaturaRapporto;
  }
	
  handleChangeScopoNaturaRapporto = (event) => {
    const nuovoScopoNaturaRapporto = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);  
    const vecchioScopoNaturaRapporto = textTrim(datiNaturaRapporto.scopoNaturaRapporto, true); 
    if (nuovoScopoNaturaRapporto !== vecchioScopoNaturaRapporto) {    	  
      this.props.updateDatiNaturaRapporto(event, actionType.UPDATE_SCOPO_NATURA_RAPPORTO, this.props.figura);
      this.setState({
        scopoNaturaRapporto: (event === null) ? '' : event.value,
        descrScopoNaturaRapporto: (event === null) ? '' : event.label,
      });
    }
  }
  	
  handleChangeOrigineFondi(el, key, event) {
    const nuovaOrigine = {
      label: el.label,
      value: el.value,
      checked: event.target.checked
    }
    this.props.updateDatiNaturaRapporto(nuovaOrigine, actionType.TOGGLE_ORIGINE_FONDI_LIST, this.props.figura);
    var checkedOriginiFondiList = this.state.checkedOriginiFondiList.filter( (item) => item === el.value);
    
//PFD    if (!this.state.checkedOriginiFondiList.includes(key) && this.state.checkedOriginiFondiList.length < 2) {
    if (checkedOriginiFondiList.length === 0 && this.state.checkedOriginiFondiList.length < 2) {
//PFD    	this.state.checkedOriginiFondiList.push(key);
    	this.state.checkedOriginiFondiList.push(el.value);
        this.setState({
          checkedOriginiFondiList: this.state.checkedOriginiFondiList
        })
        if (el.value === CODICE_ALTRA_ORIGINE_FONDI) {
          this.setState({
            isAltraOrigine: true
          })
        }
//PFD    } else if (this.state.checkedOriginiFondiList.includes(key)) {
    } else if (checkedOriginiFondiList.length > 0) {
//PFD        let index = this.state.checkedOriginiFondiList.indexOf(key);    	
        let index = this.state.checkedOriginiFondiList.indexOf(el.value);
        this.state.checkedOriginiFondiList.splice(index, 1);
        this.setState({
          checkedOriginiFondiList: this.state.checkedOriginiFondiList
        })
      if (el.value === CODICE_ALTRA_ORIGINE_FONDI) {
        this.setState({
          isAltraOrigine: false
        })
      }
    }
  }
  
  handleChangeAltraOrigine = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);  
    const vecchioAltro = textTrim(datiNaturaRapporto.altro, true);    
    if (nuovoAltro !== vecchioAltro) {	  
      this.props.updateDatiNaturaRapporto(nuovoAltro, actionType.UPDATE_ALTRA_ORIGINE_FONDI, this.props.figura);
      this.setState({
        altraOrigine: nuovoAltro, 
      });
    }
  };
  
  // 2020-12-01 PFD: SAV 'TO BE' - START 
  handleChangeStatoProvenienzaSomme = event => {
    const nuovoStatoProvenienzaSomme = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);  
    const vecchioStatoProvenienzaSomme = textTrim(datiNaturaRapporto.statoProvenienzaSomme, true); 
    if (nuovoStatoProvenienzaSomme !== vecchioStatoProvenienzaSomme) {    	  
      this.props.updateDatiNaturaRapporto(event, actionType.UPDATE_STATO_PROVENIENZA_SOMME, this.props.figura);
    }
  }
  
  handleChangeIntermediarioBancario = event => {
    const nuovoIntermediarioBancario = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);  
    const vecchioIntermediarioBancario = textTrim(datiNaturaRapporto.codTempoProvvista, true); 
    if (nuovoIntermediarioBancario !== vecchioIntermediarioBancario) {    	  
      this.props.updateDatiNaturaRapporto(event, actionType.UPDATE_INTERMEDIARIO_BANCARIO, this.props.figura);
      this.setState({
        codTempoProvvista: (event === null) ? '' : event.value,
        descTempoProvvista: (event === null) ? '' : event.label,
      });
    }
  }
  
  /* 2020-12-01 PFD: SAV 'TO BE' - START 
  handleChangeStipulatoPerConto = event => {
    const nuovoValore = textTrim(event.target.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    const vecchioValore = textTrim(datiNaturaRapporto.stipulatoPerConto);
    if(nuovoValore !== vecchioValore) {
      this.props.updateDatiNaturaRapporto(nuovoValore, actionType.UPDATE_STIPULATO_PER_CONTO, this.props.figura);
      this.setState({
        stipulatoPerConto: nuovoValore
      })
    }
  }
  2020-12-01 PFD: SAV 'TO BE' - END */

  /* handleChangeScopoNaturaContratto = event => {
    const nuovoScopo = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    const vecchioScopo = datiNaturaRapporto.codScopoNaturaContratto;
    if(nuovoScopo !== vecchioScopo) {
      this.props.updateDatiNaturaRapporto(event, actionType.UPDATE_SCOPO_NATURA_CONTRATTO, this.props.figura);
      this.setState({
        codScopoNaturaContratto: nuovoScopo,
        descScopoNaturaContratto: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      })
    }
  } */

  handleChangeSoggettiDaTutelare = event => {
    const nuovoSoggetto = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    const vecchioSoggetto = datiNaturaRapporto.codSoggettoDaTutelare;
    if(nuovoSoggetto !== vecchioSoggetto) {
      this.props.updateDatiNaturaRapporto(event, actionType.UPDATE_SOGGETTO_DA_TUTELARE, this.props.figura);
      this.setState({
        codSoggettoDaTutelare: nuovoSoggetto,
        descSoggettoDaTutelare: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      })
    }
  }

  handleChangeAltriSoggetti = event => {
    const altriSoggetti = textTrim(event.target.value, true);
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    const vecchiSoggetti = datiNaturaRapporto.altriSoggetti;
    if(altriSoggetti !== vecchiSoggetti) {
      this.props.updateDatiNaturaRapporto(altriSoggetti, actionType.UPDATE_ALTRI_SOGGETTI, this.props.figura);
      this.setState({
        altriSoggetti: altriSoggetti
      })
    }
  }

  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiContraenteGiuridico;
    } 
    return controlloDati;
  } 

  isRequiredScopoNaturaRapporto = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO/*  && this.props.scopeAmleto */) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO/*  && this.props.scopeAmleto */) {
      required = true;
    } 
    return required;
  }
  
  isRequiredOrigineFondi = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      required = true;
    }
    //2021-07-01 PFD: TCM_INDIV
    if (this.props.scopeTcmEmissione) {
	  const isTcmIndividValida = this.props.tcmIndividualeValida;
	  if (isTcmIndividValida) {
		  required = false;
	  }
    } 
    return required;
  }

  isRequiredStatoProvenienzaSomme = () => {
    let required = false;
    return required;
  }
	 
  isRequiredIntermediarioBancario = () => {
	 
	// 2020-12-01 PFD: SAV 'TO BE' let required = false;
	let required = true;
	if (this.props.datiModalitaPagamento && 
	    this.props.datiModalitaPagamento.modalitaPagamento === MODALITA_PAGAMENTO_TYPE.REIMPIEGO) {
	 required = false;
	}
	//2021-07-01 PFD: TCM_INDIV
    if (this.props.scopeTcmEmissione) {
	  const isTcmIndividValida = this.props.tcmIndividualeValida;
	  if (isTcmIndividValida) {
		  required = false;
	  }
    } 
	return required;
  }
  
  isRequiredAltro = () => {
    let required = false;
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    if (this.props.figura === CONTRAENTE_FISICO) {
      if(this.state.isAltraOrigine) {
        required = true;
      }
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      if(this.state.isAltraOrigine) {
        required = true;
      }
    }
    return required;
  }

  isRequiredAltriSoggetti = () => {
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    const soggetto = textTrim(datiNaturaRapporto.codSoggettoDaTutelare, true);
    return (soggetto === CODICE_ALTRI_SOGGETTI_DA_TUTELARE)
  }

  /*2020-12-01 PFD: SAV 'TO BE' - START
  isRequiredStipulazione = () => {    
	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
	// return this.props.scopeAmleto;
	return false;
	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END	
  }
  2020-12-01 PFD: SAV 'TO BE' - END */
  
  isRequiredScopoNaturaContratto = () => {	
	return true;
  }

  isRequiredSoggettiDaTutelare = () => {
	  //2021-07-01 PFD: TCM_INDIV    return this.props.scopeAmleto;
	  let required = true;
	  if (this.props.scopeTcmEmissione) {
		  const isTcmIndividValida = this.props.tcmIndividualeValida;
		  if (isTcmIndividValida) {
			  required = false;
		  }
	  } else {
		  required = this.props.scopeAmleto;
	  }
	  return required;
  }
 
  initStatoCampi = () => {
    return [ {id: 'scopoNatura', isError: false},
             {id: 'origineFondi', isError: false}, 
             {id: 'altro', isError: false}, 
//2020-12-01 PFD: SAV 'TO BE'             {id: 'stipulatoPerConto', isError: false},
             {id: 'altriSoggetti', isError: false}
           ];
  }
			  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  isDisabledScopoNaturaRapporto = () => {
    return false;
  }

  isDisabledAltro = () => {
    let disabled = true;
    if(this.state.isAltraOrigine) {
      disabled = false;
    } else {
      disabled = true;
    }
    return disabled;
  }

  isDisabledStatoProvenienzaSomme = () => {
    let disabled = false;
    return disabled;
  }
  
  isDisabledIntermediarioBancario = () => {
    let disabled = false;
    return disabled;
  }

  isDisabledAltriSoggetti = () => {
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(this.props.figura);
    const soggetto = textTrim(datiNaturaRapporto.codSoggettoDaTutelare, true);
    return (soggetto !== CODICE_ALTRI_SOGGETTI_DA_TUTELARE)
  }

  /* 2020-12-01 PFD: SAV 'TO BE' - START
  isDisabledStipulazionePerConto = () => {
    return this.props.scopeAmleto === false;
  }
  2020-12-01 PFD: SAV 'TO BE' - END */
  
  isDisabledSoggettiDaTutelare = () => {
    return this.props.scopeAmleto === false;
  }

  isDisabledOrigineFondi = () => {
    return false;
  }

  isChecked = (key, value) => {
    let checked = false;
    //if(this.state.checkedOriginiFondiList.includes(key)) {
    if(this.state.checkedOriginiFondiList.includes(value)) {
      checked = true;
    }
    return checked;
  }
  
  getOrigineFondiItems = () => {
	let origineFondiItems = this.state.origineFondiItems;
    if(this.props.scopeAmleto) {
      return origineFondiItems;
    } else {
      return origineFondiItems.filter(el => {
        return el.value === '01' || el.value === '04' || el.value === '14'
      })
    }
  }

  render() {
  console.log('DatiNaturaRapporto.this.props', this.props);
    const { classes, figura, labelFieldSet, dominio } = this.props;
    const { t } = this.props;
    const datiNaturaRapporto = this.datiNaturaRapportoFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura);    
    const isAgenziaAdeguataOrigneFondi = !this.props.agenziaNonAdeguataOrigineFondi;
    
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('scopoNaturaRapportoOrigineFondi')}</Typography></legend>*/}
        {labelFieldSet !== undefined && 
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        }
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeScopoNaturaRapporto} options={dominio.scopoNaturaRapportoItems} ricerca='comboRicerca' disabled={this.isDisabledScopoNaturaRapporto()}
                        etichetta='scopoNatura' value={{value: datiNaturaRapporto.scopoNaturaRapporto, label: datiNaturaRapporto.descrScopoNaturaRapporto}} 
                        required={this.isRequiredScopoNaturaRapporto()} id='scopoNatura' onError={this.handleError} controlloDati={controlloDati} />            	
          </Grid>
          <Grid item md={8} xs={12}></Grid>
          {/*2020-12-01 PFD: SAV 'TO BE' - START 
          <Grid item xs={12}>
            <RadioButtonComp radioComp={radioButtonStipulazionePerConto} onChange={this.handleChangeStipulatoPerConto} controlloDati={controlloDati} onError={this.handleError}
                             required={this.isRequiredStipulazione()} selectedValue={datiNaturaRapporto.stipulatoPerConto} id='stipulatoPerConto' 
                             disabled={this.isDisabledStipulazionePerConto()} />
          </Grid>
          2020-12-01 PFD: SAV 'TO BE' - END */}
        </Grid>
        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
        {/* <Grid item md={4} xs={12}>
          <SelectComp options={scopoNaturaContratto} ricerca='comboRicerca' etichetta='scopoNaturaContratto' required={this.isRequiredScopoNaturaContratto()} 
                      value={{value: datiNaturaRapporto.codScopoNaturaContratto, label: datiNaturaRapporto.descScopoNaturaContratto}}
                      onChange={this.handleChangeScopoNaturaContratto} id='scopoNaturaContratto' controlloDati={controlloDati} onError={this.handleError} />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <SelectComp options={this.state.soggettiDaTutelareItems} ricerca='comboRicerca' etichetta='soggettiDaTutelare' required={this.isRequiredSoggettiDaTutelare()} 
                      value={{value: datiNaturaRapporto.codSoggettoDaTutelare, label: datiNaturaRapporto.descSoggettoDaTutelare}} disabled={this.isDisabledSoggettiDaTutelare()}
                      onChange={this.handleChangeSoggettiDaTutelare} id='soggettiDaTutelare' controlloDati={controlloDati} onError={this.handleError} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFieldComp onBlur={this.handleChangeAltriSoggetti} aStyle={classes.textFieldWidthPerc} id='altriSoggetti' label='general.seAltroSpecificare'
                         value={datiNaturaRapporto.altriSoggetti} required={this.isRequiredAltriSoggetti()} onError={this.handleError} controlloDati={controlloDati}
                         disabled={this.isDisabledAltriSoggetti()} aStyleError={classes.textFieldWidthPercError} />
        </Grid>
        <Grid item md={12} xs={12}>
        <Grid item md={12} xs={12}>
              <Typography className={classes.textField}>{t('labelOrigineFondi')}</Typography>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              {this.state.origineFondiItems && this.getOrigineFondiItems().map((el, key) => {
                return (
                  <Grid item md={4} xs={12} key={key}>
                    <CheckboxComp onChange={this.handleChangeOrigineFondi.bind(this, el, key)} id='origineFondi' aLabel={el.label} checked={this.isChecked(key, el.value)} 
                                  checkError={this.state.errorOrigineFondi} controlloDati={controlloDati} required={this.isRequiredOrigineFondi()} disabled={this.isDisabledOrigineFondi()} />
                  </Grid>
                )
              })}
          </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldComp onBlur={this.handleChangeAltraOrigine} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='altro' label='general.seAltroSpecificare' 
                           value={datiNaturaRapporto.altro} required={this.isRequiredAltro()} onError={this.handleError} aStyleError={classes.textFieldWidthPercError}
                           controlloDati={controlloDati} disabled={this.isDisabledAltro()} />
          </Grid>
          { isAgenziaAdeguataOrigneFondi &&
	          <Grid item md={12} xs={12}>
		          <SelectComp options={this.state.statoProvenienzaSommeItems} ricerca='selezioneStatoProvenienzaSomme' required={this.isRequiredStatoProvenienzaSomme()}
		          	value={{ value: datiNaturaRapporto.statoProvenienzaSomme, label: datiNaturaRapporto.descrStatoProvenienzaSomme }} disabled={this.isDisabledStatoProvenienzaSomme()}
		            onChange={this.handleChangeStatoProvenienzaSomme} id='statoProvenienzaSomme' controlloDati={controlloDati} onError={this.handleError} />
	          </Grid>
          }
	      <Grid item md={12} xs={12}>
            <Typography className={classes.textField}>{t('intermediarioBancario')}</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <SelectComp options={this.state.tempoProvvistaItems} ricerca='selezioneIntermediarioBancario' required={this.isRequiredIntermediarioBancario()}
              value={{ value: datiNaturaRapporto.codTempoProvvista, label: datiNaturaRapporto.descTempoProvvista }} disabled={this.isDisabledIntermediarioBancario()}
              onChange={this.handleChangeIntermediarioBancario} id='soggettiDaTutelare' controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
        </Grid>
        {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInErrorOrigineFondi (prevProps, prevState) { 
	let errorOrigineFondi = false;
//2021-07-01 PFD: TCM_INDIV	if(this.props.figura === CONTRAENTE_FISICO) { 
		if (this.isRequiredOrigineFondi() && this.state.checkedOriginiFondiList.length < 1) {
			errorOrigineFondi = true;
//2021-07-01 PFD: TCM_INDIV		}
	}
	if(this.state.errorOrigineFondi !== errorOrigineFondi) {
		this.handleError('origineFondi', errorOrigineFondi);
		this.setState({
			errorOrigineFondi: errorOrigineFondi
	    })
	}
	return errorOrigineFondi; 
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
  //2021-07-01 PFD: TCM_INDIV    let errorOrigineFondi = false;
//2021-07-01 PFD: TCM_INDIV   if(this.props.figura === CONTRAENTE_FISICO) {
  //2021-07-01 PFD: TCM_INDIV    	errorOrigineFondi = (this.state.checkedOriginiFondiList.length < 1 ? true : false)
  //2021-07-01 PFD: TCM_INDIV    }
  //2021-07-01 PFD: TCM_INDIV    if(prevState.errorOrigineFondi !== errorOrigineFondi) {
  //2021-07-01 PFD: TCM_INDIV      this.setState({
  //2021-07-01 PFD: TCM_INDIV    	  errorOrigineFondi: errorOrigineFondi
  //2021-07-01 PFD: TCM_INDIV      })
  //2021-07-01 PFD: TCM_INDIV    }
    this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError //2021-07-01 PFD: TCM_INDIV || errorOrigineFondi
      );
	});
    console.log('########DatiNaturaRapporto componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiNaturaRapporto componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiNaturaRapporto componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
		  
  componentDidUpdate(prevProps, prevState) {
	this.isInErrorOrigineFondi(prevProps, prevState);
    this.isInError(prevProps, prevState);
  }    
}

DatiNaturaRapporto.propTypes = {
  classes: PropTypes.object.isRequired,
  dominio: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiNaturaRapporto)));
