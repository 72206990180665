import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import * as actions from './action/datiModPagCarta';
import * as actionType from './actionType/datiModPagCarta';
import { textTrim } from '../../../../utility/genericUtility';

import Grid from '@material-ui/core/Grid';
import TextFieldComp from '../../../common/TextFieldComp';
import { getNomeIntestatarioFromTipo } from '../../../../utility/storeUtility';

const styles = theme => ({
    textField: theme.textField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
});


const mapStateToProps = state => {
    return {
        datiModPagCarta: state.areaDatiModPagCartaReducer.caratteristicheContratto.datiModPagCarta,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateDatiModPagCarta: (unDato, unActionType) => dispatch(actions.updateDatiModPagCarta(unDato, unActionType))
    };
};

class DatiModPagCarta extends Component {

    constructor(props) {
        super(props);
        const datiModPagCarta = this.props.datiModPagCarta;
        this.state = {
            intestatario: datiModPagCarta.intestatario,
            statoCampi: this.initStatoCampi(),
            isPresenteErrore: null,
        }
    }

    handleChangeIntestatario = event => {
        const nuovoIntestatario = textTrim(event.target.value, true);
        const datiModPagCarta = this.props.datiModPagCarta;
        const vecchioIntestatario = datiModPagCarta.intestatario;
        if (vecchioIntestatario !== nuovoIntestatario) {
            this.props.updateDatiModPagCarta(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_CARTA);
            this.setState({
                intestatario: nuovoIntestatario
            })
        }
    }

    isRequiredIntestatario = () => {
        return true;
    }

    isDisabledIntestatario = () => {
        return false;
    }

    handleError = (id, isError) => {
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
            unErrore = campoOnId[0];
            unErrore.isError = isError;
        } else {
            unErrore = { id: id, isError: isError };
        }
        this.setState({
            statoCampi: [...campoWithoutId, unErrore],
        });
    }

    initStatoCampi = () => {
        return [
            { id: 'intestatarioCarta', isError: false }
        ]
    }

    componentDidMount = () => {
        const nuovoIntestatario = getNomeIntestatarioFromTipo();
        const datiModPagCarta = this.props.datiModPagCarta;
        const vecchioIntestatario = datiModPagCarta.intestatario;
        if (nuovoIntestatario !== vecchioIntestatario) {
            this.props.updateDatiModPagCarta(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_CARTA);
            this.setState({
                intestatario: nuovoIntestatario
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.pagatorePremio !== this.props.pagatorePremio) {
            const nuovoIntestatario = getNomeIntestatarioFromTipo(this.props.tipoIntestatario);
            const datiModPagCarta = this.props.datiModPagCarta;
            const vecchioIntestatario = datiModPagCarta.intestatario;
            if (nuovoIntestatario !== vecchioIntestatario) {
                this.props.updateDatiModPagCarta(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_CARTA);
                this.setState({
                    intestatario: nuovoIntestatario
                })
            }
        }
    }

    render() {
        const { controlloDati, datiModPagCarta, classes } = this.props;
        return (
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={12}>
                    <TextFieldComp onBlur={this.handleChangeIntestatario} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                        id='intestatarioCarta' label='intestatarioCarta' value={datiModPagCarta.intestatario} required={this.isRequiredIntestatario()}
                        disabled={this.isDisabledIntestatario()} controlloDati={controlloDati} onError={this.handleError} readonly />
                </Grid>
            </Grid>
        )
    };
};


DatiModPagCarta.propTypes = {
    classes: PropTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiModPagCarta)));
