import * as actionType from '../actionType/datiRicercaUtenza';

export const updateDatiRicercaUtenza = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const selezionaDettaglioUtente = dettaglioUtente => {
  return {
    type: actionType.SELEZIONA_DETTAGLIO_UTENTE,
    dettaglioUtente,
  }
};