export const updateDatiModalitaPagamento = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const updateNeedEsecutore = () => {
  return {
    type: 'UPDATE_NEED_ESECUTORE'
  }
}