/* 2019-12-17 PFD:
export const updateDatiRipartizionePremioLinea = (unDato, codiceLinea, unActionType) => {
  return {
    type: unActionType,
    unDato,
    codiceLinea,
  }
};
*/
export const updateDatiRipartizionePremioLinea = (unDato, unActionType) => {
  return {
    unDato,
    type: unActionType,
  }
};

export const updateDatiRipartizionePremio = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaEsitoPercentualeAllocazione = (esitoPercentualeAllocazione, esitoPercentualeFondo, unActionType) => {
  return {
    type: unActionType,
    esitoPercentualeAllocazione,
    esitoPercentualeFondo,
  }
};