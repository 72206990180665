import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import TextFieldComp from '../../common/TextFieldComp';
import Grid from '@material-ui/core/Grid';
//import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import RadioButtonComp from '../../common/RadioButtonComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import { radioButtonTasseStatiUniti, radioButtonResidenzaFiscale } from '../../store/radioButtonStore';

import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

import { CONTRAENTE_FISICO, CONTRAENTE_GIURIDICO, TITOLARE_EFFETTIVO,
	     TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA, locale_it } from '../../../utility/jsConstants';	

import { textTrim } from  '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

const styles = theme => ({
  textField: theme.textField,
  textFieldError: theme.textFieldError,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiFiscaliContraenteFisico: state.areaContraenteReducer.contraenteFisico.datiFiscali,
    controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiFiscaliContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiFiscali,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    datiFiscaliTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiFiscali,
    controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
    datiFiscaliTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiFiscali,
    controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
    datiFiscaliTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiFiscali,
    controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiFiscali: (unDato, unActionType, figura) => dispatch(actionsContraente.updateDatiFiscali(unDato, unActionType, figura)), 
    updateDatiFiscaliTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiFiscaliTitolareEff(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class DatiFiscali extends React.Component {
	
  constructor(props) {
    super(props);
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    this.state = {
      tasseStatiUniti: datiFiscali.tasseStatiUniti,
      tinGiin: datiFiscali.tinGiin,
      statoResidenzaFiscaleEstera: datiFiscali.statoResidenzaFiscaleEstera,
      statoResFiscale1: datiFiscali.statoResFiscale1,      
      descrStatoResFiscale1: datiFiscali.descrStatoResFiscale1,
      nifResFiscale1: datiFiscali.nifResFiscale1,
      statoResFiscale2: datiFiscali.statoResFiscale2,
      descrStatoResFiscale2: datiFiscali.descrStatoResFiscale2,
      nifResFiscale2: datiFiscali.nifResFiscale2,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,       
    };
  };

  handleClickTasseStatiUniti = event => {
//    this.props.updateDatiFiscali(event.target.value, actionTypeContraente.UPDATE_TASSE_STATI_UNITI, this.props.figura);  
    const tasseStatiUniti = event.target.value;
    if (tasseStatiUniti !== 'S') {
      this.setState({
        tasseStatiUniti: tasseStatiUniti,
        tinGiin: '',
      });
      if (this.props.figura === TITOLARE_EFFETTIVO ){
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_TIN_GIIN_TITOLARE_EFF);
      
      }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_MORTE);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA);

      } else {
        this.props.updateDatiFiscali('', actionTypeContraente.UPDATE_TIN_GIIN, this.props.figura);
      }
    } else {
      this.setState({
        tasseStatiUniti: tasseStatiUniti,
      });
    }	  
  };

  handleChangeTasseStatiUniti = event => {
    if (this.props.figura === TITOLARE_EFFETTIVO ) {
      this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF);

    }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
      this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_MORTE);

    }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
      this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_VITA);

    } else {
      this.props.updateDatiFiscali(event.target.value, actionTypeContraente.UPDATE_TASSE_STATI_UNITI, this.props.figura);
    }
/*    this.setState({
      tasseStatiUniti: event.target.value,
    });*/
  };
	  
  handleChangeTinGiin = event => {
    const nuovoTinGiin = textTrim(event.target.value, true);
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const vecchioTinGiin = textTrim(datiFiscali.tinGiin, true);
    if (nuovoTinGiin !== vecchioTinGiin) {
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiFiscaliTitolareEff(nuovoTinGiin, actionTypeTitolareEff.UPDATE_TIN_GIIN_TITOLARE_EFF);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff(nuovoTinGiin, actionTypeTitolareEff.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_MORTE);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff(nuovoTinGiin, actionTypeTitolareEff.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA);
        
      }  else {
        this.props.updateDatiFiscali(nuovoTinGiin, actionTypeContraente.UPDATE_TIN_GIIN, this.props.figura);
      }    	
      this.setState({
        tinGiin: nuovoTinGiin, 
      });
    }
  }; 
  
  handleClickStatoResidenzaFiscaleEstera = event => { 
    const statoResidenzaFiscaleEstera = event.target.value;
    if (statoResidenzaFiscaleEstera !== 'S') {
      this.setState({
        statoResidenzaFiscaleEstera: statoResidenzaFiscaleEstera,
        statoResFiscale1: '',
        descrStatoResFiscale1: '',
        nifResFiscale1: '',
        statoResFiscale2: '',
        descrStatoResFiscale2: '',
        nifResFiscale2: '',        
      });
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiFiscaliTitolareEff(null, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF);
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF);
        this.props.updateDatiFiscaliTitolareEff(null, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF);
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF);
        
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff(null, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE);
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE);
        this.props.updateDatiFiscaliTitolareEff(null, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE);
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE);

      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff(null, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA);
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA);
        this.props.updateDatiFiscaliTitolareEff(null, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA);
        this.props.updateDatiFiscaliTitolareEff('', actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA);

      } else {
        this.props.updateDatiFiscali(null, actionTypeContraente.UPDATE_STATO_RESIDENZA_FISCALE_1, this.props.figura);
        this.props.updateDatiFiscali('', actionTypeContraente.UPDATE_NIF_RESIDENZA_FISCALE_1, this.props.figura);        
        this.props.updateDatiFiscali(null, actionTypeContraente.UPDATE_STATO_RESIDENZA_FISCALE_2, this.props.figura);
        this.props.updateDatiFiscali('', actionTypeContraente.UPDATE_NIF_RESIDENZA_FISCALE_2, this.props.figura);          
      }
            
      //2021-07-01 PFD: TCM_INDIV
      //Sono costretto a ricalcolare TcmIndividualeValida perchè potrebbe esseere rimasta selezionata una nazione in black list al momento di selezione = 'N'
      this.handleTcmIndividualeValida();
      
    } else {
      this.setState({
        statoResidenzaFiscaleEstera: statoResidenzaFiscaleEstera,
      });
    }    
  };

  handleChangeStatoResidenzaFiscaleEstera = event => {
	if (this.props.figura === TITOLARE_EFFETTIVO) {
      this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF);

    } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_MORTE);
    
    } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_VITA);
    
    } else {
      this.props.updateDatiFiscali(event.target.value, actionTypeContraente.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA, this.props.figura);
    }
/*    this.setState({
	      statoResidenzaFiscaleEstera: event.target.value,
    });*/
  };
	  
  handleChangeStatoResFiscale1 = event => {
    const nuovoStatoResFiscale1 = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const vecchioStatoResFiscale1 = textTrim(datiFiscali.statoResFiscale1, true);    
    if (nuovoStatoResFiscale1 !== vecchioStatoResFiscale1) {
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiFiscaliTitolareEff(event, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff(event, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff(event, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA);
                
      } else {
        this.props.updateDatiFiscali(event, actionTypeContraente.UPDATE_STATO_RESIDENZA_FISCALE_1, this.props.figura);
      }
      this.setState({
        statoResFiscale1: (event === null) ? '' : event.value,
        descrStatoResFiscale1: (event === null) ? '' : event.label,
      });
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  };
  
  handleChangeNifResFiscale1 = event => {
    const nuovoNifResFiscale1 = textTrim(event.target.value, true);
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const vecchioNifResFiscale1 = textTrim(datiFiscali.nifResFiscale1, true);       
    if (nuovoNifResFiscale1 !== vecchioNifResFiscale1) {
  	  if (this.props.figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA);

      } else {
        this.props.updateDatiFiscali(event.target.value, actionTypeContraente.UPDATE_NIF_RESIDENZA_FISCALE_1, this.props.figura);
      }
      this.setState({
        nifResFiscale1: nuovoNifResFiscale1, 
      });
    }
  };  
  
  handleChangeStatoResFiscale2 = event => {	  
    const nuovoStatoResFiscale2 = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const vecchioStatoResFiscale2 = textTrim(datiFiscali.statoResFiscale2, true);       
    if (nuovoStatoResFiscale2 !== vecchioStatoResFiscale2) {
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiFiscaliTitolareEff(event, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff(event, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff(event, actionTypeTitolareEff.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA);

      } else {
        this.props.updateDatiFiscali(event, actionTypeContraente.UPDATE_STATO_RESIDENZA_FISCALE_2, this.props.figura);
      }    	
      this.setState({
        statoResFiscale2: (event === null) ? '' : event.value,
        descrStatoResFiscale2: (event === null) ? '' : event.label,
      });
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  };
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {
	  
	const figura = this.props.figura;	
	if (this.props.scopeTcmEmissione) {
		// Vale per tutti tranne Contraente Giuridico. Definito con Vittorio per avere comportamento analogo ad ELIS.
		if (figura!==CONTRAENTE_GIURIDICO) {
			const isTcmIndValida = isTcmIndividualeValida(locale_it);
			// se cambiata 
			if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
			 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
			}
		}
	}  
  }
  
  handleChangeNifResFiscale2 = event => {
    const nuovoNifResFiscale2 = textTrim(event.target.value, true);
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const vecchioNifResFiscale2 = textTrim(datiFiscali.nifResFiscale2, true);      
    if (nuovoNifResFiscale2 !== vecchioNifResFiscale2) {     	  
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE);

      }else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        this.props.updateDatiFiscaliTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA);


      } else {
        this.props.updateDatiFiscali(event.target.value, actionTypeContraente.UPDATE_NIF_RESIDENZA_FISCALE_2, this.props.figura);
      }
      this.setState({
        nifResFiscale2: event.target.value, 
      });
    }
  };  
	  
  datiFiscaliFrom = figura => {  
    let datiFiscali = null;
    if (figura === CONTRAENTE_FISICO) {
      datiFiscali = this.props.datiFiscaliContraenteFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      datiFiscali = this.props.datiFiscaliContraenteGiuridico;    	
    } else if (figura === TITOLARE_EFFETTIVO) {
      datiFiscali = this.props.datiFiscaliTitolareEff;    	
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiFiscali = this.props.datiFiscaliTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiFiscali = this.props.datiFiscaliTitEffVita;
    }
    return datiFiscali;
  };
  
  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiContraenteGiuridico;    	
    } else if (figura === TITOLARE_EFFETTIVO) {
      controlloDati = this.props.controlloDatiTitolareEff;    	
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitEffVita;
    }
    return controlloDati;
  }

  isRequiredTasseStatiUniti = () => {
/*    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      required = true;    	
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } 
    return required; */
    return true;
  }

  isRequiredTinGiin = () => {
    const datiFiscali = this.datiFiscaliFrom(this.props.figura); 
    return datiFiscali.tasseStatiUniti === 'S';
  }
 
  isRequiredStatoResidenzaFiscaleEstera = () => {
    return true;
  }

  isRequiredStatoResFiscale1 = () => {
    const datiFiscali = this.datiFiscaliFrom(this.props.figura); 
    return datiFiscali.statoResidenzaFiscaleEstera === 'S';
  }
  
  isRequiredNifResFiscale1 = () => {
    const datiFiscali = this.datiFiscaliFrom(this.props.figura); 
    return datiFiscali.statoResidenzaFiscaleEstera === 'S';
  }
  
  isRequiredStatoResFiscale2 = () => {
    return false;
  }

  isRequiredNifResFiscale2 = () => {
    return false;
  }

  initStatoCampi = () => {
    return [ {id: 'tasseStatiUniti', isError: false},
             {id: 'tinGiin', isError: false}, 
             {id: 'statoResidenzaFiscaleEstera', isError: false}, 
             {id: 'statoResidenzaFiscale1', isError: false},
             {id: 'nifResidenzaFiscale1', isError: false},             
             {id: 'statoResidenzaFiscale2', isError: false},
             {id: 'nifResidenzaFiscale2', isError: false},
           ];
  }
		  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
	  
  isDisabledTinGiin = () => {
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const tasseStatiUniti = textTrim(datiFiscali.tasseStatiUniti, true); 
	return (tasseStatiUniti !== 'S');
  }
  
  isDisabledResidenzaFiscaleEstera = () => {
    const datiFiscali = this.datiFiscaliFrom(this.props.figura);
    const statoResidenzaFiscaleEstera = textTrim(datiFiscali.statoResidenzaFiscaleEstera, true); 
	return (statoResidenzaFiscaleEstera !== 'S');
  }
  
  render() {
    const { classes, figura, labelFieldSet, dominio } = this.props;
//    const { t } = this.props; //hoc    
    const datiFiscali = this.datiFiscaliFrom(figura);  
    const controlloDati = this.controlloDatiFrom(figura); 
    return (
      <Fragment>
      {/*
      <fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiFiscali')}</Typography></legend>*/}
      	{labelFieldSet !== undefined &&
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
      	}    	
      	<Grid container direction='row' justify='center' alignItems='flex-start'>
	      <Grid item md={4} xs={12}>
	        <RadioButtonComp onClick={this.handleClickTasseStatiUniti} onChange={this.handleChangeTasseStatiUniti} 
	            radioComp={radioButtonTasseStatiUniti} selectedValue={datiFiscali.tasseStatiUniti} required={this.isRequiredTasseStatiUniti()} 
                id='tasseStatiUniti' controlloDati={controlloDati} onError={this.handleError} />
	      </Grid>
	      <Grid item md={8} xs={12}>
	        <TextFieldComp onBlur={this.handleChangeTinGiin} aStyle={classes.textField} id='tinGiin' label='tinGiin' disabled={this.isDisabledTinGiin()}
                value={datiFiscali.tinGiin} required={this.isRequiredTinGiin()} controlloDati={controlloDati} onError={this.handleError} />
	      </Grid>
	      <Grid item md={4} xs={12}>
	        <RadioButtonComp onClick={this.handleClickStatoResidenzaFiscaleEstera} onChange={this.handleChangeStatoResidenzaFiscaleEstera} 
	            radioComp={radioButtonResidenzaFiscale} selectedValue={datiFiscali.statoResidenzaFiscaleEstera} 
                required={this.isRequiredStatoResidenzaFiscaleEstera()} id='statoResidenzaFiscaleEstera'
                controlloDati={controlloDati} onError={this.handleError} />
	      </Grid>
	      <Grid item md={8} xs={12}></Grid>
	      <Grid item md={6} xs={12}>
	        <SelectComp onChange={this.handleChangeStatoResFiscale1} options={dominio.nazioneItems} ricerca='comboRicerca' etichetta='statoResidenzaFiscale1' 
	            value={{value: datiFiscali.statoResFiscale1, label: datiFiscali.descrStatoResFiscale1}} required={this.isRequiredStatoResFiscale1()} 
                id='statoResidenzaFiscale1' controlloDati={controlloDati} disabled={this.isDisabledResidenzaFiscaleEstera()} onError={this.handleError} />
	      </Grid>
	      <Grid item md={6} xs={12}>
	        <TextFieldComp onBlur={this.handleChangeNifResFiscale1} aStyle={classes.textField} id='nifResidenzaFiscale1' label='nifResidenzaFiscale1' 
                value={datiFiscali.nifResFiscale1} required={this.isRequiredNifResFiscale1()}  
                controlloDati={controlloDati} disabled={this.isDisabledResidenzaFiscaleEstera()} onError={this.handleError} />
	      </Grid>
	      <Grid item md={6} xs={12}>
	        <SelectComp onChange={this.handleChangeStatoResFiscale2} options={dominio.nazioneItems} ricerca='comboRicerca' etichetta='statoResidenzaFiscale2' 
                value={{value: datiFiscali.statoResFiscale2, label: datiFiscali.descrStatoResFiscale2}} required={this.isRequiredStatoResFiscale2()} 
                id='statoResidenzaFiscale2' controlloDati={controlloDati} disabled={this.isDisabledResidenzaFiscaleEstera()} onError={this.handleError} />
	      </Grid>
	      <Grid item md={6} xs={12}>
	        <TextFieldComp onBlur={this.handleChangeNifResFiscale2} aStyle={classes.textField} id='nifResidenzaFiscale2' label='nifResidenzaFiscale2' 
	        	value={datiFiscali.nifResFiscale2} required={this.isRequiredNifResFiscale2()} 
	            controlloDati={controlloDati} disabled={this.isDisabledResidenzaFiscaleEstera()} onError={this.handleError} />            	
	      </Grid>
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );  
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiFiscali componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiFiscali componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiFiscali componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  } 
  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }	  
}

DatiFiscali.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,  
  dominio: PropTypes.object.isRequired,    
  id: PropTypes.string.isRequired,     
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiFiscali));
