import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { uris } from '../../../uris';
import ButtonComp from '../../common/ButtonComp';

import * as actions from './action/datiStampaProposal';
import * as actionType from './actionType/datiStampaProposal';
import * as actionsGeneric from '../../generic/action/generic';

import { PRODOTTO_TIPOLOGIA_MULTINVEST, PRODOTTO_TIPOLOGIA_ALTRO, PRODOTTO_TIPOLOGIA_UNIT_LINKED, RUOLO_CONTRAENTE, RUOLO_ASSICURATO } from '../../../utility/jsConstants'; 
import { textTrim } from '../../../utility/genericUtility';
import { creaBeneficiarioVitaGenerico, creaBeneficiarioMorteGenerico} from '../../../utility/objectUtility';

const moreStyles = theme => ({
  root: {
    display: 'flex',
    marginTop: 10,
  },
  button: theme.button,
  colorRed: {
    color: 'red',
  },
  bordercolorRed : {
    border: 2,
    borderColor: 'red',
    borderStyle: 'solid',
  }, 
  media: {
	height: 35,
    objectFit: 'inherit',
  }, 
  card: {
	  // background: theme.palette.color.windowsBlue,
	  display: 'flex',
//	  flexDirection: 'row',
	  width: '98%',
  },
  cardContent: {
	margin: 0,
	padding: '10px 0 0 0',
	height: 50,
  },
  cardActionArea: {
	  width: '23%',
  },
  cardNuovaProposta: {
	  fontSize: 25,
  },
});

	
const mapStateToProps = state => {
  return {
/************ GENERIC ****************************/
	proposalInfo: state.genericReducer.generic.proposalInfo,
/************ CONTRAENTE / ASSICURATO ************/
    areaLogin: state.areaLoginReducer.areaLogin,
    prodotto: state.areaProdottoReducer.prodotto,
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,
    assicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico,   
/************ ALTRE FIGURE: BENEFICIARIO VITA / BENEFICIARIO MORTE ************/
    benefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico,
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList,
    benefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico,
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenerico: state.areaBenefVitaReducer.benefVitaGenerico,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,  
    benefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenerico: state.areaBenefMorteReducer.benefMorteGenerico,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,
/*************** REFERENTE TERZO  ******************/
    referenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico,
/*************** TITOLARE EFFETTIVO LIST  ******************/
    titolareEffList: state.areaTitolareEffReducer.titolareEffList,
/************ CARATTERISTICHE CONTRATTO ************/
	/*
	  1-MI) datiPremio: datiPremio,
	  1-ALTRI) datiPremioCapitaleAssicurato: datiPremioCapitaleAssicurato,
	  2-MI-A) datiRipartizionePremio: datiRipartizionePremio,
	  2-MI-B) datiRipartizionePremioFondi: datiRipartizionePremioFondi,
	  3-MI) datiOpzioniContrattuali: datiOpzioniContrattuali,
	     datiPianoErogazioniPeriodiche: datiPianoErogazioniPeriodiche,
	     datiTakeProfit: datiTakeProfit,
	  4-MI-ALTRI) datiModalitaPagamento: datiModalitaPagamento,
	     datiModPagAssegno: datiModPagAssegno,
	     datiModPagBonifico: datiModPagBonifico,
	     datiModPagReimpiego: datiModPagReimpiego,
	 */
    /********* SCENARIO MULTINVEST *********/
    /* 1-MI */
    datiPremio: state.areaDatiPremioReducer.caratteristicheContratto.datiPremio,
    /* 2-MI-A */
    datiRipartizionePremio: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio,
    /* 2-MI-B */
    datiRipartizionePremioFondi: state.areaDatiRipartizionePremioFondiReducer.caratteristicheContratto.datiRipartizionePremioFondi,
    /* 3-MI */
    datiOpzioniContrattuali: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali,
    datiPianoErogazioniPeriodiche: state.areaDatiPianoErogazioniPeriodicheReducer.caratteristicheContratto.datiPianoErogazioniPeriodiche,
    datiTakeProfit: state.areaDatiTakeProfitReducer.caratteristicheContratto.datiTakeProfit,    
    /********* SCENARIO ALTRI PRODOTTI *********/   
    /* 1-ALTRI */
    datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,
    /********* SCENARIO COMUNE MULTINVEST - ALTRI PRODOTTI *********/ 
    /* 4-MI 4-ALTRI */
    datiModalitaPagamento: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento,
    datiModPagAssegno: state.areaDatiModPagAssegnoReducer.caratteristicheContratto.datiModPagAssegno,
    datiModPagBonifico: state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonifico,
    datiModPagBonificoEstero: state.areaDatiModPagBonificoEsteroReducer.caratteristicheContratto.datiModPagBonificoEstero,
    datiModPagReimpiego: state.areaDatiModPagReimpiegoReducer.caratteristicheContratto.datiModPagReimpiego,  
    datiModPagAltro: state.areaDatiModPagReimpiegoReducer.caratteristicheContratto.datiModPagAltro,
/************ DICHIARAZIONE CONSENSI ************/
    datiIntermediario: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario,
    datiConsenso: state.areaDichConsensoReducer.dichiarazioni.datiConsenso,  
/************ QUESTIONARI CONTRAENTE FISICO ************/    
    datiQuestionarioAdeguatezzaFisico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico,
/************ QUESTIONARI CONTRAENTE GIURIDICO ************/
    datiQuestionarioAdeguatezzaGiuridico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico,
/************ TIPO QUESTIONARIO ANAMNESTICO ************/
    tipoQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.tipoCurrentQuestionario,
/************ QUESTIONARI ANAMNESTICO ************/    
    datiQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico,    
/************ QUESTIONARI ANAMNESTICO ************/    
    datiQuestionarioAnamnesticoSemplificato: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato,
/************ QUESTIONARI CARATTERISTICHE CONTRATTO MALATTIA GRAVE ************/
    datiQuestionarioAnamnesticoMalattiaGrave: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave,
/************ STAMPA PROPOSTA ************/    
    confermaStampaProposal: state.areaDatiStampaProposalReducer.stampaProposal.datiStampaProposal.confermaStampaProposal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateStampaProposal: (unDato, anActionType) => dispatch(actions.updateStampaProposal(unDato, anActionType)),
    inizializza: () => dispatch(actionsGeneric.inizializza()),
  };
};

function NuovaProposta (props) {
	  const classes = props.classes;
	  const t = props.t;
	  
	  return (
			  <Card className={classes.card} elevation={0}>
		      	<CardContent>
		  	        <CardMedia
		              className={classes.media} 
		          	  component='img'
		          	  image={'/images/generic/proposta.svg'}
		  	        />
        
		      	</CardContent>
		        <CardContent className={classes.cardContent}>
		            <Typography gutterBottom className={classes.textSmall} align='center'>
		              {t('nuovaProposta')}
		            </Typography>
		        </CardContent>
		        <CardActions>
		          <Grid container direction='column' justify='center' alignItems='center'>		           
				    		return (<ButtonComp
		                                to={'/areaProdotti'}
				    		            component={Link} 
				    		            aClassName={classes.button} 
				    		            buttonLabel={t('nuovaProposta')} 
				                        onClick={this.handleClickNuovaProposta()}/>
		                    );
		           
		          </Grid>
		        </CardActions>
		      </Card>	  
	  )
}

class StampaProposal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	isConferma: false,
    };
  };
  
  copiaDatiMinimiBenefFisicoFrom = (datiGeneraliMinimi, datiGeneraliFisico) => {
    datiGeneraliMinimi.codiceFiscaleValido = datiGeneraliFisico.codiceFiscaleValido;
    datiGeneraliMinimi.sesso = datiGeneraliFisico.sesso;
    datiGeneraliMinimi.codiceFiscale = datiGeneraliFisico.codiceFiscale;	  
    datiGeneraliMinimi.cognome = datiGeneraliFisico.cognome;
    datiGeneraliMinimi.nome = datiGeneraliFisico.nome;
    datiGeneraliMinimi.dataNascita = datiGeneraliFisico.dataNascita;
    datiGeneraliMinimi.dataNascitaStampa = datiGeneraliFisico.dataNascitaStampa;
    datiGeneraliMinimi.statoNascita = datiGeneraliFisico.statoNascita;
    datiGeneraliMinimi.descrStatoNascita = datiGeneraliFisico.descrStatoNascita;
    datiGeneraliMinimi.provinciaNascita = datiGeneraliFisico.provinciaNascita;
    datiGeneraliMinimi.descrProvinciaNascita = datiGeneraliFisico.descrProvinciaNascita;
    datiGeneraliMinimi.comuneNascita = datiGeneraliFisico.comuneNascita;
    datiGeneraliMinimi.descrComuneNascita = datiGeneraliFisico.descrComuneNascita;	  
  }
  
  copiaDatiGeneraliBenefGiuridicoFrom = (datiGeneraliBenefGiuridico, datiGeneraliGiuridico) => {
    datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale = datiGeneraliGiuridico.ragioneSociale;
    datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale = datiGeneraliGiuridico.codiceFiscale;	  
    datiGeneraliBenefGiuridico.datiGenerali.dataNascita = datiGeneraliGiuridico.dataNascita;
    datiGeneraliBenefGiuridico.datiGenerali.dataNascitaStampa = datiGeneraliGiuridico.dataNascitaStampa;
  }

  //controllo l'esistenza di benefeficiari originati da altre figure di proposta
  risolviBeneficiariDerivati = () => {
    // benefVitaFisico
    this.props.benefVitaFisicoList.forEach(item => {
      if(item.datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'S') {
        if (item.datiCopiaDaAltraFigura.figuraFrom[0] === RUOLO_CONTRAENTE) {
          // Prendo i dati dal contraente fisico
          this.copiaDatiMinimiBenefFisicoFrom(item.datiGeneraliBenefVita.datiGeneraliMinimi, this.props.contraenteFisico.datiGenerali);
          item.datiResidenza = {...this.props.contraenteFisico.datiResidenza};
        } else if (item.datiCopiaDaAltraFigura.figuraFrom[0] === RUOLO_ASSICURATO) {
          if (this.props.assicuratoFisico.flAssicuratoUgualeContraente === 'S') {
            // Prendo i dati dall'contraente Fisico
            this.copiaDatiMinimiBenefFisicoFrom(item.datiGeneraliBenefVita.datiGeneraliMinimi, this.props.contraenteFisico.datiGenerali);
            item.datiResidenza = {...this.props.contraenteFisico.datiResidenza};
          } else {
            // Prendo i dati dall'assicurato
            this.copiaDatiMinimiBenefFisicoFrom(item.datiGeneraliBenefVita.datiGeneraliMinimi, this.props.assicuratoFisico.datiGenerali);
            item.datiResidenza = {...this.props.assicuratoFisico.datiResidenza};
          }
        }
      }
    });
    // benefMorteFisico
    this.props.benefMorteFisicoList.forEach(item => {
      if(item.datiCopiaDaAltraFigura.flCopiaDaAltraFigura==='S') {
        if (item.datiCopiaDaAltraFigura.figuraFrom[0] === RUOLO_CONTRAENTE) {
          // Prendo i dati dal contraente fisico
          this.copiaDatiMinimiBenefFisicoFrom(item.datiGeneraliBenefMorte.datiGeneraliMinimi, this.props.contraenteFisico.datiGenerali);
          item.datiResidenza = {...this.props.contraenteFisico.datiResidenza};
        } else if (item.datiCopiaDaAltraFigura.figuraFrom[0] === RUOLO_ASSICURATO) {
          if (this.props.assicuratoFisico.flAssicuratoUgualeContraente === 'S') {
            // Prendo i dati dall'contraente Fisico
            this.copiaDatiMinimiBenefFisicoFrom(item.datiGeneraliBenefVita.datiGeneraliMinimi, this.props.contraenteFisico.datiGenerali);
            item.datiResidenza = {...this.props.contraenteFisico.datiResidenza};
          } else {
            // Prendo i dati dall'assicurato
            this.copiaDatiMinimiBenefFisicoFrom(item.datiGeneraliBenefMorte.datiGeneraliMinimi, this.props.assicuratoFisico.datiGenerali);
            item.datiResidenza = {...this.props.assicuratoFisico.datiResidenza};
          }
        }
      }
    });
    // benefVitaGiuridico
    this.props.benefVitaGiuridicoList.forEach(item => {
      if(item.datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'S'){
        if (item.datiCopiaDaAltraFigura.figuraFrom[0] === RUOLO_CONTRAENTE){
          // Prendo i dati dal contraente giuridico
          this.copiaDatiGeneraliBenefGiuridicoFrom(item.datiGeneraliBenefGiuridico, this.props.contraenteGiuridico.datiGenerali);
          item.datiResidenza = {...this.props.contraenteGiuridico.datiResidenza};
        }
      }
    });
    // benefMorteGiuridico
    this.props.benefMorteGiuridicoList.forEach(item => {
      if(item.datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'S'){
        if (item.datiCopiaDaAltraFigura.figuraFrom[0] === RUOLO_CONTRAENTE){
          // Prendo i dati dal contraente giuridico
          this.copiaDatiGeneraliBenefGiuridicoFrom(item.datiGeneraliBenefGiuridico, this.props.contraenteGiuridico.datiGenerali);
          item.datiResidenza = {...this.props.contraenteGiuridico.datiResidenza};
        }
      }
    });
  }
  
  calcolaInputStampa = (anteprimaStampa) => {
    const inputStampa = {};
    inputStampa.anteprimaStampa = anteprimaStampa;
    inputStampa.user = this.props.areaLogin.user;
    inputStampa.codiceSocieta = '143';
    inputStampa.prodotto = this.props.prodotto;
    const tipoProdotto = inputStampa.prodotto.tipo;
    /*********** PROPOSAL INFO ***********************/
    inputStampa.proposalInfo = this.props.proposalInfo;
    /************ CONTRAENTE / ASSICURATO ************/	
    inputStampa.contraenteFisico = this.props.contraenteFisico;
    inputStampa.contraenteGiuridico = this.props.contraenteGiuridico;
    if (this.props.assicuratoFisico.flAssicuratoUgualeContraente === 'S') {
      const datiGeneraliContr = {...this.props.contraenteFisico.datiGenerali};
      const datiResidenzaContr = {...this.props.contraenteFisico.datiResidenza};
      const datiAntiriciclaggioContr = {...this.props.contraenteFisico.datiAntiriciclaggio};
      const datiAttEconomicaContr = {...this.props.contraenteFisico.datiAttEconomica};
      const assicuratoFisico = {...this.props.assicuratoFisico};
      assicuratoFisico.codAttPrevalSvolta = datiAttEconomicaContr.codAttPrevalSvolta;
      assicuratoFisico.descrAttPrevalSvolta = datiAttEconomicaContr.descrAttPrevalSvolta;
      assicuratoFisico.datiGenerali = datiGeneraliContr;
      assicuratoFisico.datiResidenza = datiResidenzaContr;
      assicuratoFisico.datiAntiriciclaggio = datiAntiriciclaggioContr;
      inputStampa.assicuratoFisico = assicuratoFisico;
    } else {
      inputStampa.assicuratoFisico = this.props.assicuratoFisico;
    }
    /************ TITOLARE EFFETTIVO LIST  ************/
    inputStampa.titolareEffList = this.props.titolareEffList;    
    /************ ALTRE FIGURE: BENEFICIARIO VITA / BENEFICIARIO MORTE ************/
    // controllo l'esistenza di beneficiari originati da altre figure di proposta
    this.risolviBeneficiariDerivati();
    inputStampa.benefVitaFisicoList = this.props.benefVitaFisicoList;    
    inputStampa.benefVitaGiuridicoList = this.props.benefVitaGiuridicoList;
//    inputStampa.benefVitaGenerico = this.props.benefVitaGenerico;	
    inputStampa.benefVitaGenerico = (this.props.benefVitaGenericoList.length === 0) ? creaBeneficiarioVitaGenerico() : this.props.benefVitaGenericoList[0];   
    inputStampa.benefMorteFisicoList = this.props.benefMorteFisicoList;
    inputStampa.benefMorteGiuridicoList = this.props.benefMorteGiuridicoList;
//    inputStampa.benefMorteGenerico = if (this.props.benefMorteGenerico;
    inputStampa.benefMorteGenerico = (this.props.benefMorteGenericoList.length === 0) ? creaBeneficiarioMorteGenerico : this.props.benefMorteGenericoList[0];	
    /************ REFERENTE TERZO  ************/
    inputStampa.referenteTerzo = this.props.referenteTerzoFisico;
    /************ CARATTERISTICHE CONTRATTO ************/
    if (tipoProdotto === PRODOTTO_TIPOLOGIA_MULTINVEST || tipoProdotto === PRODOTTO_TIPOLOGIA_UNIT_LINKED) {
      /* 1-MI */    	
      inputStampa.datiPremio = this.props.datiPremio;
      /* 2-MI-A */
      inputStampa.datiRipartizionePremio = this.props.datiRipartizionePremio;
      /* 2-MI-B */
      inputStampa.datiRipartizionePremioFondi = this.props.datiRipartizionePremioFondi;
      /* 3-MI */
      inputStampa.datiOpzioniContrattuali = this.props.datiOpzioniContrattuali;
      inputStampa.datiOpzioniContrattuali.datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
      inputStampa.datiOpzioniContrattuali.datiTakeProfit = this.props.datiTakeProfit;   
      /* 4-MI */
//      inputStampa.datiModalitaPagamento = this.props.datiModalitaPagamento;
//      inputStampa.datiModalitaPagamento.datiModPagAssegno = this.props.datiModPagAssegno;
//      inputStampa.datiModalitaPagamento.datiModPagBonifico = this.props.datiModPagBonifico;
//      inputStampa.datiModalitaPagamento.datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
//      inputStampa.datiModalitaPagamento.datiModPagReimpiego = this.props.datiModPagReimpiego;
//      inputStampa.datiModalitaPagamento.datiModPagAltro = this.props.datiModPagAltro;
    } else if (tipoProdotto === PRODOTTO_TIPOLOGIA_ALTRO) {
      /* 1-ALTRI */
      inputStampa.datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
      /* 4-ALTRI */
//      inputStampa.datiModalitaPagamento = this.props.datiModalitaPagamento;
//      inputStampa.datiModalitaPagamento.datiModPagAssegno = this.props.datiModPagAssegno;
//      inputStampa.datiModalitaPagamento.datiModPagBonifico = this.props.datiModPagBonifico;
//      inputStampa.datiModalitaPagamento.datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
//      inputStampa.datiModalitaPagamento.datiModPagReimpiego = this.props.datiModPagReimpiego;
//      inputStampa.datiModalitaPagamento.datiModPagAltro = this.props.datiModPagAltro;
    } else { 	
    }
        
    /* 4-MI MOD PAG COMUNI A TUTTI I PRODOTTI */
    inputStampa.datiModalitaPagamento = this.props.datiModalitaPagamento;
    inputStampa.datiModalitaPagamento.datiModPagAssegno = this.props.datiModPagAssegno;
    inputStampa.datiModalitaPagamento.datiModPagBonifico = this.props.datiModPagBonifico;
    inputStampa.datiModalitaPagamento.datiModPagBonificoEstero = this.props.datiModPagBonificoEstero;
    inputStampa.datiModalitaPagamento.datiModPagReimpiego = this.props.datiModPagReimpiego;
    inputStampa.datiModalitaPagamento.datiModPagAltro = this.props.datiModPagAltro;
    
    /************ DICHIARAZIONE CONSENSI ************/
    inputStampa.datiIntermediario = this.props.datiIntermediario;
    inputStampa.datiConsenso = this.props.datiConsenso;  	
    /************ QUESTIONARI ************/ 
    /* CONTRAENTE FISICO */
    inputStampa.datiQuestionarioAdeguatezzaFisico = this.props.datiQuestionarioAdeguatezzaFisico;
    /* CONTRAENTE GIURIDICO */
    inputStampa.datiQuestionarioAdeguatezzaGiuridico = this.props.datiQuestionarioAdeguatezzaGiuridico;
    /* tipoQuestionarioAnamnestico */
    inputStampa.tipoQuestionarioAnamnestico = this.props.tipoQuestionarioAnamnestico;
    /* ANAMNESTICO */
    inputStampa.datiQuestionarioAnamnestico = this.props.datiQuestionarioAnamnestico;
    /* ANAMNESTICO SEMPLIFICATO */
    inputStampa.datiQuestionarioAnamnesticoSemplificato = this.props.datiQuestionarioAnamnesticoSemplificato;
    /* CARATTERISTICHE CONTRATTO MALATTIA GRAVE */
    inputStampa.datiQuestionarioAnamnesticoMalattiaGrave = this.props.datiQuestionarioAnamnesticoMalattiaGrave;
    return inputStampa;
  }
  
  paramStampa = (any) => {
    return {
      accept: 'application/json',
      method: 'POST',
      body: JSON.stringify(any),
      headers: {
    	'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }
  };
  
  sendDatiStampaFetch = () => {
    const opts = this.calcolaInputStampa();
    this.setState({
      loaded: false
    });
//	    fetch(uris.stampaProposta, fetchParam(opts))
//	    fetch(uris.stampaProposta, paramStampa(opts))
    fetch('stampa/stampaProposta', this.paramStampa(opts))
    .then (response => {
      //Create a Blob from the PDF Stream
      const file = new Blob(
	                 [response.data],
                     {type: 'application/pdf'}
                   );
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch(error => {
      console.log('Errore Stampa', error);
    });
  }  
		
  sendDatiStampa = (anteprimaStampa) => {
//    axios.post('http://localhost:9080/Semplifico.Services.Web/rest/stampa/stampaProposta',
    axios.post(uris.stampaProposta,	  
               this.calcolaInputStampa(anteprimaStampa),
               {responseType: 'blob',  // VERY IMPORTANT
			    headers: {'Accept': 'application/pdf'}})	
    .then (response => {
      //Create a Blob from the PDF Stream	
      if (anteprimaStampa === false) {
        const headers = response.headers;
        const idstampa = textTrim(headers.idstampa, true);
        console.log('####headers: ', response.headers);
        console.log('####headers.idstampa: ', idstampa);
        this.props.updateStampaProposal(idstampa, actionType.UPDATE_NUMERO_PROPOSTA);
      }   	
      const file = new Blob(
	                 [response.data],
                     {type: 'application/pdf'}
                   );
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      
      // aggiorno lo status dell'avvenuta stampa
      if (anteprimaStampa===false) {
        this.props.updateStampaProposal(true, actionType.CONFERMA_STAMPA_PROPOSAL);        
      }    
      

// 2019-07-11 PFD: Non riabilito piu' il bottone
// reset status confermaButton
//      this.setState({
//    	  isConferma: false,
//      });
      
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch(error => {
      console.log('Errore Stampa', error);
    });
  }
    
  handleStampa = anteprimaStampa => event => {
    this.sendDatiStampa(anteprimaStampa);
    this.setState({
    	isConferma: true,
    })
  }  
  
  handleClickNuovaProposta = event => {
	  this.props.inizializza();
  } 
  
  
  
  render() {
    const { classes, t, anteprimaStampa } = this.props;
    const anteprima = anteprimaStampa === true ? true : false;
    const confermaStampaProposal = this.props.confermaStampaProposal;  
    
    return (
      <div className={classes.root}>
        {anteprima === false &&
        <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
          <Grid item md={8} xs={8} className={classes.bordercolorRed}>
            <div align='center'>
              <Typography variant="h6" className={classes.colorRed}>{t('attezioneStampa')}</Typography>
            </div>
          </Grid>
          <Grid item md={8} xs={8}>            	
            <Typography>{t('seguiSempliciOperazioni')}</Typography>
          </Grid>
          <Grid item md={8} xs={8}>
            <Typography>{t('sempliceOperazioni_1')}</Typography>
          </Grid>
          <Grid item md={8} xs={8}>
            <Typography>{t('sempliceOperazioni_2')}</Typography>
          </Grid>
          {/* 2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP 
          <Grid item md={8} xs={8}>
     		<Typography>{t('sempliceOperazioni_3')}</Typography>
     	  </Grid>
          */}
     	  
     	  {/*
          {confermaStampaProposal === false &&
	          <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
	          	<Grid item>
		              <ButtonComp
		                aClassName={classes.button}
		                buttonLabel={'stampaProposta'}
		                onClick={this.handleStampa(anteprima)}
		              	disabled={this.state.isConferma===true}
		              />
	            	</Grid>
	          </Grid> 
          }
          
        
          {confermaStampaProposal === true &&
        	  <Grid container direction='row'>
			      <Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
			      		<Grid item md={4}>
				      		<Grid container direction='row' justify='flow-start' alignItems='flex-start' spacing={1}>
					        	<Grid item>
							        <ButtonComp 
						       		    to={"route"}  
								        component={Link} 
								        aClassName={classes.button} 
								        buttonLabel={'moduloSav'}
								        onClick={(event) => { event.preventDefault(); window.open("Modulo SAV.pdf"); }}	 />					        
							    </Grid>
							</Grid>     
			      		</Grid> 
			            <Grid item md={4} >
				            <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
				            	<Grid item>
					              <ButtonComp
					                aClassName={classes.button}
					                buttonLabel={'stampaProposta'}
					                onClick={this.handleStampa(anteprima)}
					              	disabled={this.state.isConferma===true}
					              />
				              	</Grid>
				            </Grid> 
				        </Grid>
			            <Grid item md={4}>
			            	<Grid container direction='row' justify='flex-end' alignItems='flex-start'>
			            		<Grid item md={6}>
							        <Card className={classes.card} elevation={2}>
								        <CardActionArea to={'/areaProdotti'} component={Link} className={classes.cardActionArea} onClick={this.handleClickNuovaProposta}>
						                  <CardActions >
									    	<CardMedia
						                        className={classes.media}
						                        component='img'
						                        image={'/images/generic/proposta.svg'}
						                        alt={t('nuovaProposta')}
						                    />
						                  </CardActions>
						                </CardActionArea>
						                <CardContent className={classes.cardContent}>
						                	<Typography gutterBottom align='center' variant='h5' className={classes.cardNuovaProposta}>
						                		{t('nuovaProposta')}
						                    </Typography>
							  	        </CardContent>
								   </Card>
								</Grid> 
							</Grid> 						   
			            </Grid>			            
			       </Grid>
			   </Grid>
           }
          */}

    	   <Grid container direction='row'>
		   		<Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
		      		<Grid item md={4}>
			      		<Grid container direction='row' justify='flow-start' alignItems='flex-start' spacing={1}>
				        	{/* <Grid item>						        
						    <ButtonComp  
						        aClassName={classes.button} 
						        buttonLabel={'moduloSav'}	
						    	onClick={(event) => { event.preventDefault(); window.open("Modulo SAV.pdf"); }}
						    	// download
						        />
						    </Grid> */}

						</Grid>     
		      		</Grid> 
		            <Grid item md={4} >
			            <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
			            	<Grid item>
				              <ButtonComp
				                aClassName={classes.button}
				                buttonLabel={'stampaProposta'}
				                onClick={this.handleStampa(anteprima)}
				              	disabled={this.state.isConferma===true}
				              />
			              	</Grid>
			            </Grid> 
			        </Grid>
		            <Grid item md={4}>
		            	{confermaStampaProposal === true &&
		            	<Grid container direction='row' justify='flex-end' alignItems='flex-start'>
		            		<Grid item md={6}>
						        <Card className={classes.card} elevation={2}>
							        <CardActionArea to={'/areaProdotti'} component={Link} className={classes.cardActionArea} onClick={this.handleClickNuovaProposta}>
					                  <CardActions >
								    	<CardMedia
					                        className={classes.media}
					                        component='img'
					                        image={'/images/generic/proposta.svg'}
					                        alt={t('nuovaProposta')}
					                    />
					                  </CardActions>
					                </CardActionArea>
					                <CardContent className={classes.cardContent}>
					                	<Typography gutterBottom align='center' variant='h5' className={classes.cardNuovaProposta}>
					                		{t('nuovaProposta')}
					                    </Typography>
						  	        </CardContent>
							   </Card>
							</Grid>
						</Grid>
		            	}
		            </Grid>
		       </Grid>
		   </Grid>
        </Grid>
        }
        
        {anteprima === true &&
        <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
          <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
            <Grid>
              <ButtonComp
                aClassName={classes.button}
                buttonLabel={'anteprima'}
                onClick={this.handleStampa(anteprima)}
              />
            </Grid>
          </Grid>
        </Grid>
        }
      </div>
    );
  }
}

StampaProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(moreStyles, { withTheme: true })(StampaProposal)));