import * as actionType from '../actionType/datiModPagBonifico';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiModPagBonificoInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';
import { CODICE_ALTRA_RELAZIONE } from '../../../../../utility/jsConstants';

const areaDatiModPagBonificoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiModPagBonificoReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaModPagBonifico(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.UPDATE_IBAN:
      const caratteristicheContrattoIban = {...state}.caratteristicheContratto;
      const datiModPagBonificoIban = {...caratteristicheContrattoIban}.datiModPagBonifico;
      datiModPagBonificoIban.iban = action.unDato;
      caratteristicheContrattoIban.datiModPagBonifico = {...datiModPagBonificoIban};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoIban,
      };   
    case actionType.UPDATE_CONTROLLO_IBAN_PAG_BONIFICO:
        const caratteristicheContrattoControlloIban = {...state}.caratteristicheContratto;
        const datiModPagBonificoControlloIban = {...caratteristicheContrattoControlloIban}.datiModPagBonifico;
        datiModPagBonificoControlloIban.controlloIban = action.unDato;
        caratteristicheContrattoControlloIban.datiModPagBonifico = {...datiModPagBonificoControlloIban};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoControlloIban,
        };
    /*case actionType.UPDATE_BANCA:
      const caratteristicheContrattoBanca = {...state}.caratteristicheContratto;
      const datiModPagBonificoBanca = {...caratteristicheContrattoBanca}.datiModPagBonifico;
      datiModPagBonificoBanca.banca =  unValore;
      datiModPagBonificoBanca.descBanca =  unaLabel;
      datiModPagBonificoBanca.agenzia = '';
      datiModPagBonificoBanca.descAgenzia = '';
      caratteristicheContrattoBanca.datiModPagBonifico = {...datiModPagBonificoBanca};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoBanca,
    }; 
    case actionType.UPDATE_AGENZIA:
        const caratteristicheContrattoAgenzia = {...state}.caratteristicheContratto;
        const datiModPagBonificoAgenzia = {...caratteristicheContrattoAgenzia}.datiModPagBonifico;
        datiModPagBonificoAgenzia.agenzia = unValore;
        datiModPagBonificoAgenzia.descAgenzia =  unaLabel;
        caratteristicheContrattoAgenzia.datiModPagBonifico = {...datiModPagBonificoAgenzia};
        return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoAgenzia,
      };*/ 
    case actionType.SALVA_BANCA_AGENZIA_DA_IBAN:
        const caratteristicheContrattoSalvaBancaAgenzia = {...state}.caratteristicheContratto;
        const datiModPagBonificoSalvaBancaAgenzia = {...caratteristicheContrattoSalvaBancaAgenzia}.datiModPagBonifico;
        datiModPagBonificoSalvaBancaAgenzia.banca = action.banca;
        datiModPagBonificoSalvaBancaAgenzia.descBanca = action.descBanca;
        datiModPagBonificoSalvaBancaAgenzia.agenzia = action.agenzia;
        datiModPagBonificoSalvaBancaAgenzia.descAgenzia = action.descAgenzia;
        caratteristicheContrattoSalvaBancaAgenzia.datiModPagBonifico = {...datiModPagBonificoSalvaBancaAgenzia};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoSalvaBancaAgenzia,
        };
    case actionType.UPDATE_N_SOTTORUBRICA:
        const caratteristicheContrattoSottorubrica = {...state}.caratteristicheContratto;
        const datiModPagBonificoSottorubrica = {...caratteristicheContrattoSottorubrica}.datiModPagBonifico;
        datiModPagBonificoSottorubrica.nSottorubrica = action.unDato;
        caratteristicheContrattoSottorubrica.datiModPagBonifico = {...datiModPagBonificoSottorubrica};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoSottorubrica
        }
    case actionType.UPDATE_INTESTATARIO_BONIFICO:
        const caratteristicheContrattoIntestatario = {...state}.caratteristicheContratto;
        const datiModPagBonificoIntestatario = {...caratteristicheContrattoIntestatario}.datiModPagBonifico;
        datiModPagBonificoIntestatario.intestatario = action.unDato;
        caratteristicheContrattoIntestatario.datiModPagBonifico = {...datiModPagBonificoIntestatario};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoIntestatario
        }
    case actionType.UPDATE_COINTESTATO_BONIFICO:
        const caratteristicheContrattoCointestato = {...state}.caratteristicheContratto;
        const datiModPagBonificoCointestato = {...caratteristicheContrattoCointestato}.datiModPagBonifico;
        datiModPagBonificoCointestato.contoCointestato = action.unDato;
        if(datiModPagBonificoCointestato.contoCointestato === false) {
          datiModPagBonificoCointestato.nomeCognomeCointestatario = '';
          datiModPagBonificoCointestato.relazContrCoint = '';
          datiModPagBonificoCointestato.descrRelazContrCoint = '';
          datiModPagBonificoCointestato.altraRelazione = '';
        }
        caratteristicheContrattoCointestato.datiModPagBonifico = {...datiModPagBonificoCointestato};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoCointestato
        }
    case actionType.UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO:
        const caratteristicheContrattoDatiCoint = {...state}.caratteristicheContratto;
        const datiModPagBonificoDatiCoint = {...caratteristicheContrattoDatiCoint}.datiModPagBonifico;
        datiModPagBonificoDatiCoint.nomeCognomeCointestatario = action.unDato;
        caratteristicheContrattoDatiCoint.datiModPagBonifico = {...datiModPagBonificoDatiCoint};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoDatiCoint
        }
    case actionType.UPDATE_RELAZ_PAGAT_CONTR_BONIFICO:
        const caratteristicheContrattoRelaz = {...state}.caratteristicheContratto;
        const datiModPagBonificoRelaz = {...caratteristicheContrattoRelaz}.datiModPagBonifico;
        datiModPagBonificoRelaz.relazContrCoint = unValore;
        datiModPagBonificoRelaz.descrRelazContrCoint = unaLabel;
        if(datiModPagBonificoRelaz.relazContrCoint !== CODICE_ALTRA_RELAZIONE) {
          datiModPagBonificoRelaz.altraRelazione = '';
        }
        caratteristicheContrattoRelaz.datiModPagBonifico = {...datiModPagBonificoRelaz};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoRelaz
        }
    case actionType.UPDATE_ALTRA_RELAZ_BONIFICO:
        const caratteristicheContrattoAltraRelaz = {...state}.caratteristicheContratto;
        const datiModPagBonificoAltraRelaz = {...caratteristicheContrattoAltraRelaz}.datiModPagBonifico;
        datiModPagBonificoAltraRelaz.altraRelazione = action.unDato;
        caratteristicheContrattoAltraRelaz.datiModPagBonifico = {...datiModPagBonificoAltraRelaz};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoAltraRelaz
        }
    default:
      return state;
  }
}

export const inizializzaModPagBonifico = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiModPagBonifico = jsonCopy(datiModPagBonificoInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiModPagBonificoReducer;