export const datiIntermediarioInizializzato = {  
  datiIntermediarioValida: null,				
  controlloDati: '',
  comportamentoCliente: '', 
  durataRapportoContrInt: '',
  descrDurataRapportoContrInt: '',
  flOperazioneCoerente: '',
  profiloRischio: '',
  descrProfiloRischio: '',
  collocatore: '',
  // 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario cognomeColloc: '',
  // 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario nomeColloc: '',
  // 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario regioneAttivita: '',
  // 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario descrRegioneAttivita: '',
  // 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario codIntermediario: '',
  // 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario codRUI: '',
  codCognomeNomeColloc: '',
  luogoColloc: '',
  dataColloc: null,
  dataCollocStampa: '',
};

export const datiConsensoInizializzato = {  
  datiConsensoValida: null,		
  controlloDati: '',
  flConsenso1Contratto: 'S', 
  flConsenso2Marketing: '',
  flConsenso3Statistic: '',
  flConsenso4Comunicaz: '',	
};