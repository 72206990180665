import * as actionType from '../actionType/datiQuestionario';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import { QUESTIONARIO_ADEGUATEZZA_FISICO, QUESTIONARIO_ADEGUATEZZA_GIURIDICO, QUESTIONARIO_ANAMNESTICO, QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE, QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO } from '../../../../../utility/jsConstants';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiQuestionarioAdeguatezzaFisicoInizializzato, datiQuestionarioAdeguatezzaGiuridicoInizializzato, datiQuestionarioAnamnesticoInizializzato, datiQuestionarioAnamnesticoMalattiaGraveInizializzato, datiQuestionarioAnamnesticoSemplificatoInizializzato } 
  from '../../../../../session/info/componentiBase/sezioniQuestionario';

//import { datiQuestionarioAnamnesticoSemplificatoInizializzato } from '../../../../../session/info/componentiBase/sezioneQuestionarioAnamnesticoSemplificato';

const areaDatiQuestionarioReducer = (state = initialState, action) => {

  let datiQuestionario = null;
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const questionario_INI = inizializzaQuestionario(state);
      return {
        ...state,
        questionario: questionario_INI,
      };
    case actionType.SALVA_DATI_QUESTIONARIO:   
      const questionarioSalva = {...state}.questionario;
      let datiQuestionarioSalva = null;
      if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO) {
    	datiQuestionarioSalva = {...questionarioSalva}.datiQuestionarioAdeguatezzaFisico;
      } else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
    	datiQuestionarioSalva = {...questionarioSalva}.datiQuestionarioAdeguatezzaGiuridico;
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO) {
    	datiQuestionarioSalva = {...questionarioSalva}.datiQuestionarioAnamnestico;
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) {
    	datiQuestionarioSalva = {...questionarioSalva}.datiQuestionarioAnamnesticoMalattiaGrave; 
      } else {
      datiQuestionarioSalva = {...questionarioSalva}.datiQuestionarioAnamnesticoSemplificato;
      }
      datiQuestionarioSalva.controlloDati = action.unDato;
      if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO) {
        questionarioSalva.datiQuestionarioAdeguatezzaFisico = {...datiQuestionarioSalva};
      } else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
    	questionarioSalva.datiQuestionarioAdeguatezzaGiuridico = {...datiQuestionarioSalva};
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO) {
    	questionarioSalva.datiQuestionarioAnamnestico = {...datiQuestionarioSalva};
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) {
    	questionarioSalva.datiQuestionarioAnamnesticoMalattiaGrave = {...datiQuestionarioSalva}; 
      } else {
      questionarioSalva.datiQuestionarioAnamnesticoSemplificato = {...datiQuestionarioSalva};
      }
      return {
      	...state,
      	questionario: questionarioSalva,
      };
    case actionType.UPDATE_DATI_QUESTIONARIO_VALIDA:   
      const questionarioUpdate = {...state}.questionario;
      let datiQuestionarioUpdate = null;
      if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO) {
    	datiQuestionarioUpdate = {...questionarioUpdate}.datiQuestionarioAdeguatezzaFisico;
      } else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
    	datiQuestionarioUpdate = {...questionarioUpdate}.datiQuestionarioAdeguatezzaGiuridico;
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO) {
    	datiQuestionarioUpdate = {...questionarioUpdate}.datiQuestionarioAnamnestico;
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) {
    	datiQuestionarioUpdate = {...questionarioUpdate}.datiQuestionarioAnamnesticoMalattiaGrave; 
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO ) {
        datiQuestionarioUpdate = {...questionarioUpdate}.datiQuestionarioAnamnesticoSemplificato;
      }
      datiQuestionarioUpdate.datiQuestionarioValida = action.unDato;
      if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO) {
        questionarioUpdate.datiQuestionarioAdeguatezzaFisico = {...datiQuestionarioUpdate};
      } else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
    	questionarioUpdate.datiQuestionarioAdeguatezzaGiuridico = {...datiQuestionarioUpdate};
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO) {
    	questionarioUpdate.datiQuestionarioAnamnestico = {...datiQuestionarioUpdate};
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) {
    	questionarioUpdate.datiQuestionarioAnamnesticoMalattiaGrave = {...datiQuestionarioUpdate}; 
      } else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO) {
        questionarioUpdate.datiQuestionarioAnamnesticoSemplificato = {...datiQuestionarioUpdate};
      }
      return {
      	...state,
      	questionario: questionarioUpdate,
      };      
    case actionType.SALVA_ESITO_DATI_QUESTIONARIO:   
        const questionarioSalvaEsito = {...state}.questionario;
        let datiQuestionarioSalvaEsito = null;
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
      	  datiQuestionarioSalvaEsito = {...questionarioSalvaEsito}.datiQuestionarioAdeguatezzaFisico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
          datiQuestionarioSalvaEsito = {...questionarioSalvaEsito}.datiQuestionarioAdeguatezzaGiuridico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          datiQuestionarioSalvaEsito = {...questionarioSalvaEsito}.datiQuestionarioAnamnestico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE){
          datiQuestionarioSalvaEsito = {...questionarioSalvaEsito}.datiQuestionarioAnamnesticoMalattiaGrave;
        } else {
          datiQuestionarioSalvaEsito = {...questionarioSalvaEsito}.datiQuestionarioAnamnesticoSemplificato;
        }
        datiQuestionarioSalvaEsito.esito = action.esito;
        datiQuestionarioSalvaEsito.esitoMessaggio = action.esitoMessaggio;
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          questionarioSalvaEsito.datiQuestionarioAdeguatezzaFisico = {...datiQuestionarioSalvaEsito};
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO) {
          questionarioSalvaEsito.datiQuestionarioAdeguatezzaGiuridico = {...datiQuestionarioSalvaEsito};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          questionarioSalvaEsito.datiQuestionarioAnamnestico = {...datiQuestionarioSalvaEsito};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE){
          questionarioSalvaEsito.datiQuestionarioAnamnesticoMalattiaGrave = {...datiQuestionarioSalvaEsito};
        } else {
          questionarioSalvaEsito.datiQuestionarioAnamnesticoSemplificato = {...datiQuestionarioSalvaEsito};
        }
        return {
        	...state,
        	questionario: questionarioSalvaEsito,
        };
    case actionType.UPDATE_RADIO_SELECTION:
        let questionarioRadio = {...state}.questionario;
        let datiQuestionarioRadio = null;
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          datiQuestionarioRadio = {...questionarioRadio}.datiQuestionarioAdeguatezzaFisico;
          datiQuestionario = {...datiQuestionarioRadio}.questionarioAdeguatezzaFisico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO){
          datiQuestionarioRadio = {...questionarioRadio}.datiQuestionarioAdeguatezzaGiuridico;
          datiQuestionario = {...datiQuestionarioRadio}.questionarioAdeguatezzaGiuridico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          datiQuestionarioRadio = {...questionarioRadio}.datiQuestionarioAnamnestico;
          datiQuestionario = {...datiQuestionarioRadio}.questionarioAnamnestico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE){
          datiQuestionarioRadio = {...questionarioRadio}.datiQuestionarioAnamnesticoMalattiaGrave;
          datiQuestionario = {...datiQuestionarioRadio}.questionarioAnamnesticoMalattiaGrave;
        } else {
          datiQuestionarioRadio = {...questionarioRadio}.datiQuestionarioAnamnesticoSemplificato;
          datiQuestionario = {...datiQuestionarioRadio}.questionarioAnamnesticoSemplificato;
        }
        datiQuestionario.forEach(function(itemSezione, indexSezione, array) {
          itemSezione.domande.forEach(function(itemDomanda, indexDomanda, array) {
      	    if(itemDomanda.numDomanda === action.numDomanda){
      		  itemDomanda.risposte.forEach(function(itemRisposta, indexRisposte, array) {
      			if(action.idRisposta==null){//risposte dirette
      			  if(itemRisposta.id === action.unDato){
      	            itemRisposta.selezionato = true;
      	            itemRisposta.testoSelezionato = itemRisposta.testoSelezionatoTemp;
      	          }else{
      	        	if(itemRisposta.tipo==='R'){
      	              itemRisposta.selezionato = false;
      	              itemRisposta.testoSelezionato = '';
      	              //cancello eventuali risposte condizionate valorizzate
      	              itemRisposta.risposteCondizionate.forEach(function(itemRispostaCondizionata, indexRispostaCondizionata, array) {
      	            	itemRispostaCondizionata.selezionato = false;
      	            	itemRispostaCondizionata.testoSelezionato = '';
      	            	itemRispostaCondizionata.testoSelezionatoTemp = '';
      	              });	
      	        	}
      	          }
      			}else{//risposte condizionate
      			  if(itemRisposta.id === action.idRisposta){
          	        itemRisposta.risposteCondizionate.forEach(function(itemRispostaCondizionata, indexRisposteCondizionata, array) {
          			  if(itemRispostaCondizionata.id === action.unDato){
          			    itemRispostaCondizionata.selezionato = true;
          			    itemRispostaCondizionata.testoSelezionato = itemRispostaCondizionata.testoSelezionatoTemp;
            	      }else{
            	        if(itemRispostaCondizionata.tipo==='R'){
                	      itemRispostaCondizionata.selezionato = false;
                	      itemRispostaCondizionata.testoSelezionato = '';
            	    	}
            	      }
          	        });
          	      }
      			}  
      		  });
      	    }
          });
        });
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          questionarioRadio.datiQuestionarioAdeguatezzaFisico = {...datiQuestionarioRadio};
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO){
          questionarioRadio.datiQuestionarioAdeguatezzaGiuridico = {...datiQuestionarioRadio};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          questionarioRadio.datiQuestionarioAnamnestico = {...datiQuestionarioRadio};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE ){
          questionarioRadio.datiQuestionarioAnamnesticoMalattiaGrave = {...datiQuestionarioRadio};
        } else {
          questionarioRadio.datiQuestionarioAnamnesticoSemplificato = {...datiQuestionarioRadio};
        }
        return {
        	...state,
        	questionario: questionarioRadio,
        };
        
    case actionType.UPDATE_CHECKBOX_SELECTION:
    	let questionarioCheck = {...state}.questionario;
        let datiQuestionarioCheck = null;
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          datiQuestionarioCheck = {...questionarioCheck}.datiQuestionarioAdeguatezzaFisico;
          datiQuestionario = {...datiQuestionarioCheck}.questionarioAdeguatezzaFisico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO){
          datiQuestionarioCheck = {...questionarioCheck}.datiQuestionarioAdeguatezzaGiuridico;
          datiQuestionario = {...datiQuestionarioCheck}.questionarioAdeguatezzaGiuridico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          datiQuestionarioCheck = {...questionarioCheck}.datiQuestionarioAnamnestico;
          datiQuestionario = {...datiQuestionarioCheck}.questionarioAnamnestico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE){
          datiQuestionarioCheck = {...questionarioCheck}.datiQuestionarioAnamnesticoMalattiaGrave;
          datiQuestionario = {...datiQuestionarioCheck}.questionarioAnamnesticoMalattiaGrave;
        } else {
          datiQuestionarioCheck = {...questionarioCheck}.datiQuestionarioAnamnesticoSemplificato;
          datiQuestionario = {...datiQuestionarioCheck}.questionarioAnamnesticoSemplificato
        }
        datiQuestionario.forEach(function(itemSezione, indexSezione, array) {
          itemSezione.domande.forEach(function(itemDomanda, indexDomanda, array) {
      	    if(itemDomanda.numDomanda === action.numDomanda){
      		  itemDomanda.risposte.forEach(function(itemRisposta, indexRisposte, array) {
      			if(action.idRisposta==null){//risposte dirette
      			  if(itemRisposta.id === action.unDato){
      				  if(itemRisposta.selezionato === true){
      					itemRisposta.selezionato = false;
      					itemRisposta.testoSelezionato = '';
      				  }else{
      					itemRisposta.selezionato = true;  
      					itemRisposta.testoSelezionato = itemRisposta.testoSelezionatoTemp;
      				  }
      	          }
      			}else{//risposte condizionate
      			  if(itemRisposta.id === action.idRisposta){
          	        itemRisposta.risposteCondizionate.forEach(function(itemRispostaCondizionata, indexRisposteCondizionata, array) {
          			  if(itemRispostaCondizionata.id === action.unDato){
          		        if(itemRispostaCondizionata.selezionato === true){
          		          itemRispostaCondizionata.selezionato = false;
          		          itemRispostaCondizionata.testoSelezionato = '';
          				}else{
          				  itemRispostaCondizionata.selezionato = true;  
          				  itemRispostaCondizionata.testoSelezionato = itemRispostaCondizionata.testoSelezionatoTemp;
          				}
            	      }
          	        });
          	      }
      			}  
      		  });
      	    }
          });
        });
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          questionarioCheck.datiQuestionarioAdeguatezzaFisico = {...datiQuestionarioCheck};
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO){
          questionarioCheck.datiQuestionarioAdeguatezzaGiuridico = {...datiQuestionarioCheck};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          questionarioCheck.datiQuestionarioAnamnestico = {...datiQuestionarioCheck};
        }else{
          questionarioCheck.datiQuestionarioAnamnesticoMalattiaGrave = {...datiQuestionarioCheck};
        }
        return {
        	...state,
        	questionario: questionarioCheck,
        };
        
    case actionType.UPDATE_TEXTFIELD:
    	let questionarioTextfield = {...state}.questionario;
        let datiQuestionarioTextfield = null;
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          datiQuestionarioTextfield = {...questionarioTextfield}.datiQuestionarioAdeguatezzaFisico;
          datiQuestionario = {...datiQuestionarioTextfield}.questionarioAdeguatezzaFisico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO){
          datiQuestionarioTextfield = {...questionarioTextfield}.datiQuestionarioAdeguatezzaGiuridico;
          datiQuestionario = {...datiQuestionarioTextfield}.questionarioAdeguatezzaGiuridico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          datiQuestionarioTextfield = {...questionarioTextfield}.datiQuestionarioAnamnestico;
          datiQuestionario = {...datiQuestionarioTextfield}.questionarioAnamnestico;
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE){
          datiQuestionarioTextfield = {...questionarioTextfield}.datiQuestionarioAnamnesticoMalattiaGrave;
          datiQuestionario = {...datiQuestionarioTextfield}.questionarioAnamnesticoMalattiaGrave;
        } else {
          datiQuestionarioTextfield = {...questionarioTextfield}.datiQuestionarioAnamnesticoSemplificato;
          datiQuestionario = {...datiQuestionarioTextfield}.questionarioAnamnesticoSemplificato;
        }
        datiQuestionario.forEach(function(itemSezione, indexSezione, array) {
          itemSezione.domande.forEach(function(itemDomanda, indexDomanda, array) {
      	    if(itemDomanda.numDomanda === action.numDomanda){
      		  itemDomanda.risposte.forEach(function(itemRisposta, indexRisposte, array) {
      			if(action.idRisposta===null || action.idRisposta===''){//risposte dirette
      				if(itemRisposta.id === action.idTesto){
      				  if(((itemRisposta.tipo==='R' || itemRisposta.tipo==='C') && itemRisposta.selezionato) || (itemRisposta.tipo!=='R' && itemRisposta.tipo!=='C')){
      				    itemRisposta.testoSelezionato = action.unDato;
      				  }
      				  itemRisposta.testoSelezionatoTemp = action.unDato;
      				}
      			}else{//risposte condizionate
      			  if(itemRisposta.id === action.idRisposta){
          	        itemRisposta.risposteCondizionate.forEach(function(itemRispostaCondizionata, indexRisposteCondizionata, array) {
          	          if(itemRispostaCondizionata.id === action.idTesto){
          	        	if(((itemRispostaCondizionata.tipo==='R' || itemRispostaCondizionata.tipo==='C') && itemRispostaCondizionata.selezionato) 
          	        			|| (itemRispostaCondizionata.tipo!=='R' && itemRispostaCondizionata.tipo!=='C')){
          			      itemRispostaCondizionata.testoSelezionato = action.unDato;
          	        	}
          	        	itemRispostaCondizionata.testoSelezionatoTemp = action.unDato;
          	          }
          	        });
          	      }
      			}  
      		  });
      	    }
          });
        });
         
        if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_FISICO){
          questionarioTextfield.datiQuestionarioAdeguatezzaFisico = {...datiQuestionarioTextfield};
        }else if(action.tipoQuestionario === QUESTIONARIO_ADEGUATEZZA_GIURIDICO){
          questionarioTextfield.datiQuestionarioAdeguatezzaGiuridico = {...datiQuestionarioTextfield};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO){
          questionarioTextfield.datiQuestionarioAnamnestico = {...datiQuestionarioTextfield};
        }else if(action.tipoQuestionario === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE){
          questionarioTextfield.datiQuestionarioAnamnesticoMalattiaGrave = {...datiQuestionarioTextfield};
        } else {
          questionarioTextfield.datiQuestionarioAnamnesticoSemplificato = {...datiQuestionarioTextfield};
        }
        return {
        	...state,
        	questionario: questionarioTextfield,
        };
    case actionType.UPDATE_CHANGED_FROM_SEMPLIFICATO:
      const questionarioIsChanged = {...state}.questionario;
      questionarioIsChanged.isChangedFromSemplificato = action.unDato;
      return {
        ...state,
        questionario: questionarioIsChanged
      };
    case actionType.UPDATE_TIPO_CURRENT_QUESTIONARIO:
      const questionarioTipo = {...state}.questionario;
      questionarioTipo.tipoCurrentQuestionario = action.unDato;
      return {
        ...state,
        questionario: questionarioTipo
      };
      /*
    case actionType.UPDATE_SHOW_SEMPLIFICATO:
      const questionarioShow = {...state}.questionario;
      questionarioShow.showSemplificato = action.showSemplificato;
      questionarioShow.showOnlySemplificato = action.showOnlySemplificato;
      return {
        ...state,
        questionario: questionarioShow
      }
      */
    default:
      return state;
  }
}

export const inizializzaQuestionario = (state) => {
  const questionario_INI = {...state}.questionario;
  questionario_INI.datiQuestionarioAdeguatezzaFisico = jsonCopy(datiQuestionarioAdeguatezzaFisicoInizializzato);
  questionario_INI.datiQuestionarioAdeguatezzaGiuridico = jsonCopy(datiQuestionarioAdeguatezzaGiuridicoInizializzato);
  questionario_INI.datiQuestionarioAnamnestico = jsonCopy(datiQuestionarioAnamnesticoInizializzato);
  questionario_INI.datiQuestionarioAnamnesticoMalattiaGrave = jsonCopy(datiQuestionarioAnamnesticoMalattiaGraveInizializzato);
  questionario_INI.datiQuestionarioAnamnesticoSemplificato = jsonCopy(datiQuestionarioAnamnesticoSemplificatoInizializzato);
  questionario_INI.controlloRisposta = false;
  questionario_INI.tipoCurrentQuestionario = '';
  questionario_INI.isChangedFromSemplificato = false;
  return questionario_INI;
}

export default areaDatiQuestionarioReducer;