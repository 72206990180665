import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import * as actions from '../figure/action/contraente'
import * as actionType from '../figure/actionType/contraente';

import DividerComp from '../../common/DividerComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import SelectComp from '../../common/SelectComp';
import NumberFieldComp from '../../common/NumberFieldComp';

//2020-12-01 PFD: SAV 'TO BE' import { radioButtonContraenteGiuridicoSedi, radioButtonContraenteGiuridicoAltriImmobili } from '../../store/radioButtonStore';

import { CONTRAENTE_GIURIDICO } from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';
import { fetchParam, uris } from '../../../uris';
import moment from 'moment';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: theme.textField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
    dateField: theme.dateField,
    fieldSet: theme.fieldSet,
});

const mapStateToProps = (state) => ({
    datiFinanziari: state.areaContraenteReducer.contraenteGiuridico.datiFinanziari,
    controlloDatiContraenteGiuridico:  state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
})

const mapDispatchToProps = dispatch => {
    return {
        updateDatiFinanziariContraenteGiuridico: (unDato, unActionType) => dispatch(actions.updateDatiFinanziariContraenteGiuridico(unDato, unActionType))
    };
};

export class DatiSituazioneFinanziariaGiuridico extends Component {
    constructor(props) {
        super(props);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const ripartizione = datiFinanziari.ripartizioneInvestimentiFinanziari;
        this.state = {
            codFatturato: datiFinanziari.codFatturato,
            descFatturato: datiFinanziari.descFatturato,
          //2020-12-01 PFD: SAV 'TO BE'            sediSocieta: datiFinanziari.sediSocieta,
            codPatrimonioFinanziario: datiFinanziari.codPatrimonioFinanziario,
            descPatrimonioFinanziario: datiFinanziari.descPatrimonioFinanziario,
          //2020-12-01 PFD: SAV 'TO BE'            altriImmobili: datiFinanziari.altriImmobili,
          //2020-12-01 PFD: SAV 'TO BE'            strumentiAssicurativi: ripartizione.strumentiAssicurativi,
          //2020-12-01 PFD: SAV 'TO BE'            strumentiAzionari: ripartizione.strumentiAzionari,
          //2020-12-01 PFD: SAV 'TO BE'            strumentiComplessi: ripartizione.strumentiComplessi,
          //2020-12-01 PFD: SAV 'TO BE'            strumentiMercMon: ripartizione.strumentiMercMon,
          //2020-12-01 PFD: SAV 'TO BE'            strumentiObbligazionari: ripartizione.strumentiObbligazionari,
            fatturatoAnnuoItems: [],
            patrimonioFinanziarioItems: [],
            statoCampi: this.initStatoCampi(),
        }
    }

    componentDidMount = () => {
        const dataOggiFormatta = moment().format('DD/MM/YYYY');
        const siglaVersione = 'ZURI';
        const optsFatturato = {
            siglaVersione: siglaVersione,
            codiceCampo: 'FANN',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.fatturatoAnnuoItems, fetchParam(optsFatturato))
        .then(
            response => response.json(),
            error => console.error("An Error occured...", error)
        )
        .then((result) => {
            this.setState({
                fatturatoAnnuoItems: result
            })
        })
        const optsPatrFinanz = {
            siglaVersione: siglaVersione,
            codiceCampo: 'PFIG',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.patrimonioFinanziarioGiuridicoItems, fetchParam(optsPatrFinanz))
        .then(
            response => response.json(),
            error => console.error("An Error occured...", error)
        )
        .then((result) => {
            this.setState({
                patrimonioFinanziarioItems: result
            })
        });
    }

    datiSitFinanziariaFrom = figura => {
        let datiFinanziari = null;
        if (figura === CONTRAENTE_GIURIDICO) {
            datiFinanziari = this.props.datiFinanziari;
        }
        return datiFinanziari;
    }

    controlloDatiFrom = figura => {
        let controlloDati = null;
        if (figura === CONTRAENTE_GIURIDICO) {
            controlloDati = this.props.controlloDatiContraenteGiuridico;
        }
        return controlloDati;
    }

    handleChangeFatturatoAnnuo = event => {
        const nuovoFatturato = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchioFatturato = textTrim(datiFinanziari.codFatturato, true);
        if (nuovoFatturato !== vecchioFatturato) {
            this.props.updateDatiFinanziariContraenteGiuridico(event, actionType.UPDATE_FATTURATO_GIURIDICO);
            this.setState({
                codFatturato: nuovoFatturato,
                descFatturato: (event === null) ? '' : event.label
            })
        }
    }
    
    handleChangePatrimonioFinanziario = event => {
        const nuovoPatrimonio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchioPatrimonio = textTrim(datiFinanziari.codPatrimonioFinanziario, true);
        if (nuovoPatrimonio !== vecchioPatrimonio) {
            this.props.updateDatiFinanziariContraenteGiuridico(event, actionType.UPDATE_PATRIMONIO_FINANZIARIO_GIURIDICO);
            this.setState({
                codPatrimonioFinanziario: nuovoPatrimonio,
                descPatrimonioFinanziario: (event === null) ? '' : event.label
            })
        }
    }
    
  /*2020-12-01 PFD: SAV 'TO BE' - START
    handleChangeSedi = event => {
        const sediSocieta = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(sediSocieta, actionType.UPDATE_SEDI_CONTRAENTE_GIURIDICO);
        this.setState({
            sediSocieta: sediSocieta
        })
    }

    handleChangeAltriImmobili = event => {
        const altriImmobili = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(altriImmobili, actionType.UPDATE_ALTRI_IMMOBILI_GIURIDICO)
        this.setState({
            altriImmobili: altriImmobili
        })
    }

    handleChangeMercMon = event => {
        const strumentiMercatoMonetario = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(strumentiMercatoMonetario, actionType.UPDATE_MERCATO_MONETARIO_GIURIDICO)
        this.setState({
            strumentiMercatoMonetario: strumentiMercatoMonetario
        })
    }

    handleChangeObbligazioni = event => {
        const strumentiObbligazionari = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(strumentiObbligazionari, actionType.UPDATE_STRUMENTI_OBBLIGAZIONARI_GIURIDICO)
        this.setState({
            strumentiObbligazionari: strumentiObbligazionari
        })
    }

    handleChangeAzioni = event => {
        const strumentiAzionari = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(strumentiAzionari, actionType.UPDATE_STRUMENTI_AZIONARI_GIURIDICO)
        this.setState({
            strumentiAzionari: strumentiAzionari
        })
    }

    handleChangeAssicurativi = event => {
        const strumentiAssicurativi = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(strumentiAssicurativi, actionType.UPDATE_STRUMENTI_ASSICURATIVI_GIURIDICO)
        this.setState({
            strumentiAssicurativi: strumentiAssicurativi
        })
    }

    handleChangeComplessi = event => {
        const strumentiComplessi = event.target.value;
        this.props.updateDatiFinanziariContraenteGiuridico(strumentiComplessi, actionType.UPDATE_STRUMENTI_COMPLESSI_GIURIDICO)
        this.setState({
            strumentiComplessi: strumentiComplessi
        })
    }    
    2020-12-01 PFD: SAV 'TO BE' - END */
    
    isRequiredFatturatoAnnuo = () => {
      //2021-07-01 PFD: TCM_INDIV    return this.props.scopeAmleto;
  	  let required = true;
  	  if (this.props.scopeTcmEmissione) {
  		  const isTcmIndividValida = this.props.tcmIndividualeValida;
  		  if (isTcmIndividValida) {
  			required = false;
  		  }
  	  } else {
  		required = this.props.scopeAmleto;
  	  }
  	  return required;
    }

    isRequiredPatrimonioFinanziario = () => {
      //2021-07-01 PFD: TCM_INDIV    return this.props.scopeAmleto;
  	  let required = true;
  	  if (this.props.scopeTcmEmissione) {
  		  const isTcmIndividValida = this.props.tcmIndividualeValida;
  		  if (isTcmIndividValida) {
  			required = false;
  		  }
  	  } else {
  		required = this.props.scopeAmleto;
  	  }
  	  return required;
    }
    
    /*2020-12-01 PFD: SAV 'TO BE' - START
    isRequiredSedi = () => {
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
        //return this.props.scopeAmleto;
    	return false;
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END

    }    

    isRequiredAltriImmobili = () => {
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
        //return this.props.scopeAmleto;
    	return false;
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }
    
    isRequiredCampoRipartizione = () => {
        return false;
    }
	2020-12-01 PFD: SAV 'TO BE' - END */
    
    isDisabledFatturatoAnnuo = () => {
        return this.props.scopeAmleto === false;
    }

    isDisabledPatrimonioFinanziario = () => {
        return this.props.scopeAmleto === false;
    }
    
    /*2020-12-01 PFD: SAV 'TO BE' - START
    isDisabledSedi = () => {    	
        return this.props.scopeAmleto === false;
    }

    isDisabledAltriImmobili = () => {
        return this.props.scopeAmleto === false;
    }    

    isDisabledCampoRipartizione = () => {
        return this.props.scopeAmleto === false;
    }
	2020-12-01 PFD: SAV 'TO BE' - END */
    
    initStatoCampi = () => {
        return [
            {id: 'fatturatoAnnuo', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'sediContraenteGiuridico', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'altriImmobiliContraenteGiuridico', isError: false},
            {id: 'patrimonioFinanziario', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'strumentiMercMon', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'strumentiObbl', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'strumentiAzionari', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'strumentiAssicurativi', isError: false},
          //2020-12-01 PFD: SAV 'TO BE'            {id: 'strumentiComplessi', isError: false},
        ]
    }

    handleError = (id, isError) => {
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
            unErrore = campoOnId[0];
            unErrore.isError = isError;
        } else {
            unErrore = { id: id, isError: isError };
        }
        this.setState({
            statoCampi: [...campoWithoutId, unErrore],
        });
    }

    render() {
        const { labelFieldSet, figura, classes } = this.props;
        const { t } = this.props;
        const datiFinanziari = this.datiSitFinanziariaFrom(figura);
        const { ripartizioneInvestimentiFinanziari } = datiFinanziari;
        const controlloDati = this.controlloDatiFrom(figura);
        return (
            <Fragment>
                <DividerComp titolo={labelFieldSet} variant='subtitle1' />
                <Grid container direction='row' justify='center' alignItems='flex-start'>
                    <Grid item md={6} xs={12}>
                        <SelectComp options={this.state.fatturatoAnnuoItems} onChange={this.handleChangeFatturatoAnnuo} disabled={this.isDisabledFatturatoAnnuo()}
                            value={{ value: datiFinanziari.codFatturato, label: datiFinanziari.descFatturato }} etichetta='fatturatoAnnuo'
                            required={this.isRequiredFatturatoAnnuo()} controlloDati={controlloDati} id='fatturatoAnnuo' onError={this.handleError} />
                    </Grid>
                    {/* <Typography>{t('inveImmoContrGiuridico')}</Typography> */}
                    {/* 2020-12-01 PFD: SAV 'TO BE' - START 
                    <Grid item md={6} xs={12}>
                        <RadioButtonComp radioComp={radioButtonContraenteGiuridicoSedi} onChange={this.handleChangeSedi} 
                            selectedValue={datiFinanziari.sediSocieta} required={this.isRequiredSedi()} controlloDati={controlloDati} 
                            id='sediContraenteGiuridico' onError={this.handleError} disabled={this.isDisabledSedi()} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RadioButtonComp radioComp={radioButtonContraenteGiuridicoAltriImmobili} onChange={this.handleChangeAltriImmobili} 
                            selectedValue={datiFinanziari.altriImmobili} required={this.isRequiredAltriImmobili()} controlloDati={controlloDati} 
                            id='altriImmobiliContraenteGiuridico' onError={this.handleError} disabled={this.isDisabledAltriImmobili()} />
                    </Grid>
                    2020-12-01 PFD: SAV 'TO BE' - END*/}
                    <Grid item md={6} xs={12}>
                        <SelectComp options={this.state.patrimonioFinanziarioItems} onChange={this.handleChangePatrimonioFinanziario} disabled={this.isDisabledPatrimonioFinanziario()}
                            value={{ value: datiFinanziari.codPatrimonioFinanziario, label: datiFinanziari.descPatrimonioFinanziario }} etichetta='patrimonioFinanziario'
                            required={this.isRequiredPatrimonioFinanziario()} controlloDati={controlloDati} id='patrimonioFinanziario' onError={this.handleError} />
                    </Grid>
                    {/* 2020-12-01 PFD: SAV 'TO BE'- START
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.textField}>{t('labelRipartizioneInvestimenti')}</Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiMercMon' label='strumentiMercMon' required={this.isRequiredCampoRipartizione()}
                            controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeMercMon} 
                            value={ripartizioneInvestimentiFinanziari.strumentiMercatoMonetario} aStyle={classes.textField} disabled={this.isDisabledCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiObbl' label='strumentiObbl' required={this.isRequiredCampoRipartizione()}
                            controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeObbligazioni} 
                            value={ripartizioneInvestimentiFinanziari.strumentiObbligazionari} aStyle={classes.textField} disabled={this.isDisabledCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiAzionari' label='strumentiAzionari' required={this.isRequiredCampoRipartizione()}
                            controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeAzioni} 
                            value={ripartizioneInvestimentiFinanziari.strumentiAzionari} aStyle={classes.textField} disabled={this.isDisabledCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiAssicurativi' label='strumentiAssicurativi' required={this.isRequiredCampoRipartizione()}
                            controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeAssicurativi}
                            value={ripartizioneInvestimentiFinanziari.strumentiAssicurativi} aStyle={classes.textField} disabled={this.isDisabledCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp fullWidth={true} id='strumentiComplessi' label='strumentiComplessi' controlloDati={controlloDati} 
                            onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeComplessi} required={this.isRequiredCampoRipartizione()}
                            value={ripartizioneInvestimentiFinanziari.strumentiComplessi} aStyle={classes.textField} disabled={this.isDisabledCampoRipartizione()} />
                    </Grid>                    
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}></Grid>
                    <Grid item md={1} xs={12}></Grid>
                    2020-12-01 PFD: SAV 'TO BE' - END*/}
                </Grid>
            </Fragment>
        )
    }


    isInError(prevProps, prevState) {   
        let isPresenteCampiConErrore = false;
        this.state.statoCampi.map(unoStatoCampo => {
        return (		
            isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
        );
        });
        console.log('########DatiNaturaRapporto componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    //    console.log('########DatiNaturaRapporto componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    //    console.log('########DatiNaturaRapporto componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
        if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
        if (this.props.onError) {
            this.props.onError(this.props.id, isPresenteCampiConErrore);
        }
        this.setState({	
            isPresenteErrore: isPresenteCampiConErrore,      
        });      
        }
    }
            
    componentDidUpdate(prevProps, prevState) {
        this.isInError(prevProps, prevState);
    }    
}

DatiSituazioneFinanziariaGiuridico.propTypes = {
    classes: PropTypes.object.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiSituazioneFinanziariaGiuridico)));