import { datiGenerali, datiResidenza, datiRecapito, datiFiscali, 
	     datiConsensoComElettr, datiAttEconomica, datiAntiriciclaggio,
	     datiNaturaRapporto, datiFinanziari, datiDomicilio } from './sezioni/sezioniAnagraficaContraenteFisico';	  
import { datiEsecutore } from './sezioni/sezioniAnagraficaEsecutoreFisico';	   	  

export const contraenteFisico = {
  anagraficaValida: null,
  controlloDati: '',
  datiGenerali: datiGenerali,
  datiResidenza: datiResidenza,
  datiDomicilio: datiDomicilio,
  datiRecapito: datiRecapito,
  datiFiscali: datiFiscali,
  datiConsensoComElettr: datiConsensoComElettr,
  datiAttEconomica: datiAttEconomica,
  datiAntiriciclaggio: datiAntiriciclaggio,
  datiEsecutore: datiEsecutore,
  datiNaturaRapporto: datiNaturaRapporto,
  datiFinanziari: datiFinanziari
};