import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ContractorPanel from './ContractorPanel';
import AssicuratoPanel from './AssicuratoPanel';
import TitolareEffPanel from './TitolareEffPanel';

import { RUOLO_CONTRAENTE, RUOLO_ASSICURATO, 
         CONTRAENTE, ASSICURATO_FISICO, TITOLARE_EFFETTIVO, CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, CODICE_SAE_FIDUCIARIA_GESTIONE, CODICE_QUALIFICA_FIDUCIANTE } from '../../../utility/jsConstants';
import { showSezione } from '../../../utility/genericUtility';

import { isContraenteGiuridico } from '../../../utility/genericUtility';
import { PERSONA_FISICA, PERSONA_GIURIDICA } from '../../../utility/jsConstants';

import * as actions from '../../../components/anagrafica/figure/action/assicurato';
import { contraenteTabItems } from '../../store/navTabProposalItems';
import { errorFiducianteContraente } from '../../../utility/storeUtility';

const styles = theme => ({
  root: {	  
  },
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    assicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico,
    assicuratoFisicoValida: state.areaAssicuratoReducer.assicuratoFisico.anagraficaValida,
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    contraenteFisicoValida: state.areaContraenteReducer.contraenteFisico.anagraficaValida,
    contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,
    contraenteGiuridicoValida: state.areaContraenteReducer.contraenteGiuridico.anagraficaValida,	
    titolareEffValida: state.areaTitolareEffReducer.titolareEff.anagraficaValida,
    titolareEff: state.areaTitolareEffReducer.titolareEff,
    titolareEffList: state.areaTitolareEffReducer.titolareEffList,
    contrGiuSae: state.areaContraenteReducer.contraenteGiuridico.datiAttEconomicaGiuridico.codSAE
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA AnagraphProposal.mapDispatchToProps.dispatch', dispatch);
  return {        
    updateDatiGeneraliAssicurato: (unDato, unActionType) => dispatch(actions.updateDatiGeneraliAssicurato(unDato, unActionType)),
  };
};
	
/*export const getIsValida = (unaFigura, contraenteFisico, contraenteGiuridico, assicuratoFisico, titolareEffList) => {
  let isValida = null;
  if (unaFigura === CONTRAENTE) {
    if (contraenteFisico.controlloDati !== '') {
      isValida = contraenteFisico.contraenteFisicoValida;
    } else if (contraenteGiuridico.controlloDati !== '') {    	  
      isValida = contraenteGiuridico.contraenteGiuridicoValida;    	  
    }
  } else if (unaFigura === ASSICURATO_FISICO) {
    if ((assicuratoFisico.controlloDati !== '') || (assicuratoFisico.flAssicuratoUgualeContraente === 'S')) {
      isValida = assicuratoFisico.assicuratoFisicoValida;
    }    	
  } else if (unaFigura === TITOLARE_EFFETTIVO) {
    let titolariOK = titolareEffList.filter(unTitolare => ((unTitolare.controlloDati === true) && (unTitolare.anagraficaValida === true)));
    let titolariKO = titolareEffList.filter(unTitolare => ((unTitolare.controlloDati === true) && (unTitolare.anagraficaValida !== true)));
    let titolariWARN = titolareEffList.filter(unTitolare => (unTitolare.controlloDati === ''));
    if ((titolariOK.length > 0) && (titolariKO.length === 0) && (titolariWARN.length === 0)) {
      isValida = true;
    } else if (titolariKO.length > 0) {
      isValida = false;
    }
  }
  return isValida;
}*/
  
class AnagraphProposal extends React.Component {
  constructor(props) {
    super(props);
    const items = this.props.dominio.ruoloXProdottoItems.map((item) => {
		                                                       return (item.value)
                                                            });    
    this.state = {
      flAssUgContr: '',
      contraenteShow : showSezione(items, RUOLO_CONTRAENTE),
      assicuratoShow : showSezione(items, RUOLO_ASSICURATO),
      titolareEffShow: this.props.contraenteGiuridico.flTitolariEff,
    };    
  }

  handleChangeConfermaContraenteGiuridico = () => {
    const isContrGiuridico = isContraenteGiuridico(this.props.contraenteGiuridico);
  	let flAssUgContr = null;
  	
	if (isContrGiuridico) {
      flAssUgContr = 'N';
//		this.props.updateDatiGeneraliAssicurato(flAssUgContr, actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE);
	}
	this.setState({
      flAssUgContr: flAssUgContr,
	});
  }
  
  /*
  // funzione callback
  handleChangeCodiceFiscale = () => {	
	let flAssUgContr = null;
    if (unTipoPersona === PERSONA_GIURIDICA) {
      if (textTrim(unCodiceFiscale, true) !== '') {
        flAssUgContr = 'N';
      } else {
        flAssUgContr = '';
      }
    } else {
      flAssUgContr = this.props.assicuratoFisico.flAssicuratoUgualeContraente;
    }	  
// const codFiscContrGiuridico = textTrim(value, true);	  
//	  if (codFiscContrGiuridico !== '') {
//		  const flAssUgContr = 'N';
//		  this.props.updateDatiGeneraliAssicurato(flAssUgContr, actionTypeAss.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE);
//	  }	  
    this.setState({
      flAssUgContr: flAssUgContr,
    });
  };
*/
	  
  // funzione callback
  handleChangeFlTitolareEff = (flTitolariEff) => {
    this.setState({
      titolareEffShow: flTitolariEff,
    });
  }
  
  getIsValida = (unaFigura) => {
    let isValida = null;
    if (unaFigura === CONTRAENTE) {
      if (this.props.contraenteFisico.controlloDati !== '') {
        isValida = this.props.contraenteFisicoValida;
      } else if (this.props.contraenteGiuridico.controlloDati !== '') {    	  
        isValida = this.props.contraenteGiuridicoValida;    	  
      }
    } else if (unaFigura === ASSICURATO_FISICO) {
      if ((this.props.assicuratoFisico.controlloDati !== '') || (this.props.assicuratoFisico.flAssicuratoUgualeContraente === 'S')) {
        isValida = this.props.assicuratoFisicoValida;
      }    	
    } else if (unaFigura === TITOLARE_EFFETTIVO) {
    	/*
      let titolariOK = this.props.titolareEffList.filter(unTitolare => ((unTitolare.controlloDati === true) && (unTitolare.anagraficaValida === true)));
      let titolariKO = this.props.titolareEffList.filter(unTitolare => ((unTitolare.controlloDati === true) && (unTitolare.anagraficaValida !== true)));
      let titolariWARN = this.props.titolareEffList.filter(unTitolare => (unTitolare.controlloDati === ''));
      if ((titolariOK.length > 0) && (titolariKO.length === 0) && (titolariWARN.length === 0)) {
        isValida = true;
      } else if (titolariKO.length > 0) {
        isValida = false;
      }
      */
      if (this.props.titolareEffList.length > 0) {
        isValida = true && !errorFiducianteContraente(
          this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_GESTIONE
        );
      } else if (this.props.titolareEffList.length === 0 && this.props.titolareEffValida !== null) {
        isValida = false;
      }    	
    }
    return isValida;
  }

  /* errorFiduciante = () => {
    const currentTit = this.props.titolareEff;
    const titList = this.props.titolareEffList;
    const isFiduciaria = this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_GESTIONE;
    let isFiducianteCurrent = false;
    let isPresenteFiducianteInList = false;
    if(currentTit.datiTitolareEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
      isFiducianteCurrent = true;
    }
    if(titList.length > 0 && titList[0].datiTitolareEff) {
      titList.map((el, i) => {
        if(el.datiTitolareEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
          isPresenteFiducianteInList = true;
        }
      });
    }
    let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
    if(!isFiduciaria) {
      return false;
    }
    return !presente;
  } */
  
/*  getTabList(ruolo) {
	let tabList = null;
    if (ruolo === CONTRAENTE) {
      if ((this.props.contraenteFisicoValida !== null) && (this.props.contraenteFisicoValida !== undefined)) {
        tabList = contraenteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_FISICA));
      } else if ((this.props.contraenteGiuridicoValida !== null) && (this.props.contraenteGiuridicoValida !== undefined)) {
        tabList = contraenteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GIURIDICA));    	  
      } else {
        tabList = contraenteTabItems;   	  
      }
    }
    return tabList;
  } */
  
/*  getTabList(ruolo) {
	let tabList = [];
	let tabListEnabled = null;
	let tabListDisabled = null;	
//	let anItemEnabled = null;
//	let anItemDisabled = null;
    if (ruolo === CONTRAENTE) {
      if ((this.props.contraenteFisicoValida !== null) && (this.props.contraenteFisicoValida !== undefined)) {
        tabListEnabled = contraenteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_FISICA));
//        anItemEnabled = { ...tabListEnabled[0], disabled: false };       
        tabListDisabled = contraenteTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_FISICA));   
//        anItemDisabled = { ...tabListDisabled[0], disabled: true };       
        tabList = [{ ...tabListEnabled[0], disabled: false }, { ...tabListDisabled[0], disabled: true }];
//        tabList = [{anItemEnabled}, {anItemDisabled}];
//        tabList.concat([{...tabListDisabled[0], disabled: true}]);
      } else if ((this.props.contraenteGiuridicoValida !== null) && (this.props.contraenteGiuridicoValida !== undefined)) {
        tabListEnabled = contraenteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GIURIDICA)); 
//        anItemEnabled = { ...tabListEnabled[0], disabled: false };     
        tabListDisabled = contraenteTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_GIURIDICA)); 
//        anItemDisabled = { ...tabListDisabled[0], disabled: true };
        tabList = [{ ...tabListDisabled[0], disabled: true }, { ...tabListEnabled[0], disabled: false }];        
//        tabList.concat([{...tabListDisabled[0], disabled: true}]);
      } else {
//        anItemEnabled = { ...contraenteTabItems[0], disabled: false };     
//        const anItemEnabled1 = { ...contraenteTabItems[1], disabled: false };    
        tabList = [{ ...contraenteTabItems[0], disabled: false }, { ...contraenteTabItems[1], disabled: false }]; 	  
//        tabList = [{anItemEnabled}, {anItemEnabled1}];
      }
    }
    return tabList;
  } */
  
  getTabList(ruolo) {
	let tabList = [];
	let tabListEnabled = null;
	let tabListDisabled = null;	
    if (ruolo === CONTRAENTE) {
      if ((this.props.contraenteFisicoValida !== null) && (this.props.contraenteFisicoValida !== undefined)) {
        tabListEnabled = contraenteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_FISICA));
        tabListDisabled = contraenteTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_FISICA));   
        tabList = [{ ...tabListEnabled[0], disabled: false }, { ...tabListDisabled[0], disabled: true }];
      } else if ((this.props.contraenteGiuridicoValida !== null) && (this.props.contraenteGiuridicoValida !== undefined)) {
        tabListEnabled = contraenteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GIURIDICA)); 
        tabListDisabled = contraenteTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_GIURIDICA)); 
        tabList = [{ ...tabListDisabled[0], disabled: true }, { ...tabListEnabled[0], disabled: false }];        
      } else {
        tabList = [{ ...contraenteTabItems[0], disabled: false }, { ...contraenteTabItems[1], disabled: false }]; 	  
      }
    }
    return tabList;
  }
    
  render () {
    const { classes } = this.props;
    const titolareEffShow = this.state.titolareEffShow;
    
    return (
      <div className={classes.root}>
      	{this.state.contraenteShow && 
         <Fragment>
           <ContractorPanel
             handleChangeConfermaContraenteGiuridico={this.handleChangeConfermaContraenteGiuridico} 
             handleChangeFlTitolareEff={this.handleChangeFlTitolareEff} 
             isValida={this.getIsValida(CONTRAENTE)} tabList={this.getTabList(CONTRAENTE)} />  
           {(this.state.titolareEffShow === 'S') &&
            <TitolareEffPanel isValida={this.getIsValida(TITOLARE_EFFETTIVO)} />        	  
           }
         </Fragment>
        }
        {this.state.assicuratoShow && 
         <AssicuratoPanel flAssUgContr={this.state.flAssUgContr} isValida={this.getIsValida(ASSICURATO_FISICO)} /> 
        }
      </div>
    );
  }
}

AnagraphProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnagraphProposal));