//import { datiGeneraliGenerico } from './sezioni/sezioniAnagrafica';
import { datiGeneraliGenerico } from './sezioni/sezioniAnagraficaBenefVitaGenerico';
import { RUOLO_BENEF_VITA, PERSONA_GENERICA } from '../../utility/jsConstants';

export const benefVitaGenerico = {
  controlloDati: '',
  anagraficaValida: null,  
  datiFigureAnagrBase: {
    ruolo: RUOLO_BENEF_VITA,
    tipoPers: PERSONA_GENERICA,
  },
  datiGenerali: datiGeneraliGenerico,
};

export const benefVitaGenericoList = [
//  benefVitaGenerico
];