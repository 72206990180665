import React from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Phone from '@material-ui/icons/Phone';
import { connect } from 'react-redux';
import { uris, fetchParam, isLocal } from '../../../uris';

import TextFieldComp from '../../common/TextFieldComp';
import TextFieldAdornComp from '../../common/TextFieldAdornComp';
import DatePickerComp from '../../common/DatePickerComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import { radioButtonSesso } from '../../store/radioButtonStore';

import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';
import * as actionsAssicurato from '../figure/action/assicurato';
import * as actionTypeAssicurato from '../figure/actionType/assicurato';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';

import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { CONTRAENTE_FISICO, ASSICURATO_FISICO, TITOLARE_EFFETTIVO, ESECUTORE_FISICO, 
	     PROVINCIA_ESTERA, CODICE_ALTRO_TITOLO_STUDIO, TITOLARE_EFF_BENEF_MORTE, 
	     TITOLARE_EFF_BENEF_VITA, locale_it } from '../../../utility/jsConstants';

import moment from 'moment';
import 'moment/locale/it';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  dateFieldWidth: {
    width: '97%',
  },
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiGeneraliFisico.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiGeneraliContraenteFisico: state.areaContraenteReducer.contraenteFisico.datiGenerali,
    controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiGeneraliAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.datiGenerali,
    controlloDatiAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.controlloDati,
    datiGeneraliTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiGenerali,
    datiRelazContrTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiRelazContr,
    controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
    datiEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore,
    datiGeneraliTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiGenerali,
    controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
    datiGeneraliTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiGenerali,
    controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
    controlloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContraenteFisico: (unDato, anActionType) => dispatch(actionsContraente.updateContraenteFisico(unDato, anActionType)),
    updateDatiGeneraliContraenteFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiGeneraliContraenteFisico(unDato, unActionType)),
    updateAssicuratoFisico: (unDato, anActionType) => dispatch(actionsAssicurato.updateAssicuratoFisico(unDato, anActionType)),
    updateDatiGeneraliAssicurato: (unDato, unActionType) => dispatch(actionsAssicurato.updateDatiGeneraliAssicurato(unDato, unActionType)),
    updateDatiGeneraliTitolareEff: (unDato, unActionType, isFiduciaria, figura) => dispatch(actionsTitolareEff.updateDatiGeneraliTitolareEff(unDato, unActionType, isFiduciaria, figura)),
    updateDatiGeneraliEsecutore: (unDato, unActionType) => dispatch(actionsContraente.updateDatiGeneraliEsecutore(unDato, unActionType)),
    updateDatiGeneraliPerContraente: (unDato, unActionType) => dispatch(actionsContraente.updateDatiGeneraliPerContraente(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};

class DatiGeneraliFisico extends React.Component {

  constructor(props) {
    super(props);
    console.log('#RTCFA DatiGeneraliFisico props', props);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    this.state = {
      sesso: datiGenerali.sesso,
      codiceFiscale: datiGenerali.codiceFiscale,
      cognome: datiGenerali.cognome,
      nome: datiGenerali.nome,
      dataNascita: datiGenerali.dataNascita,
      statoNascita: datiGenerali.statoNascita,
      descrStatoNascita: datiGenerali.descrStatoNascita,
      provinciaNascita: datiGenerali.provinciaNascita,
      descrProvinciaNascita: datiGenerali.descrProvinciaNascita,
      comuneNascita: datiGenerali.comuneNascita,
      descrComuneNascita: datiGenerali.descrComuneNascita,
      cittadinanza1: datiGenerali.cittadinanza1,
      descrCittadinanza1: datiGenerali.descrCittadinanza1,
      cittadinanza2: datiGenerali.cittadinanza2,
      descrCittadinanza2: datiGenerali.descrCittadinanza2,
      numTelefono: datiGenerali.numTelefono,
      cittadinanzaItems: [],
      nazioneItems: [],
      provinciaItems: [],
      comuneItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
      codiceFiscaleValido: null,
      titoloStudio: datiGenerali.titoloStudio,
      descrTitoloStudio: datiGenerali.titoloStudio,
      altroTitoloStudio: datiGenerali.altroTitoloStudio,
    };
  };

  componentDidMount = () => {
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const dataNascitaFormattata = datiGenerali && datiGenerali.dataNascita ? dataFormat(datiGenerali.dataNascita, "DD/MM/YYYY") : '';
    const dataOggiFormatta = moment().format('DD/MM/YYYY');
    this.setCittadinanza(dataOggiFormatta);
    //this.setCittadinanza(dataNascitaFormattata);
    this.setNazioni(dataNascitaFormattata);
    this.setProvince(dataNascitaFormattata, datiGenerali ? datiGenerali.statoNascita : '');
    this.setComuni(datiGenerali ? datiGenerali.provinciaNascita : '', dataNascitaFormattata);
    this.setTitoliDiStudio(dataOggiFormatta);
    // controllo il codiceFiscale 
    const loadingOverlay = false;
    this.verificaCodiceFiscale(loadingOverlay);
  }

  datiGeneraliFrom = figura => {
    let datiGenerali = null;
    if (figura === CONTRAENTE_FISICO) {
      datiGenerali = this.props.datiGeneraliContraenteFisico;
    } else if (figura === ASSICURATO_FISICO) {
      datiGenerali = this.props.datiGeneraliAssicuratoFisico;
    } else if (figura === TITOLARE_EFFETTIVO) {
      datiGenerali = this.props.datiGeneraliTitolareEff;
    } else if (figura === ESECUTORE_FISICO) {
      datiGenerali = this.props.datiEsecutore.datiGenerali;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiGenerali = this.props.datiGeneraliTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiGenerali = this.props.datiGeneraliTitEffVita;
    }
    return datiGenerali;
  }

  datiRelazContrFrom = figura => {
    let datiRelazContr = null;
    if (figura === TITOLARE_EFFETTIVO) {
      datiRelazContr = this.props.datiRelazContrTitolareEff
    }
    return datiRelazContr;
  }

  handleChangeSesso = event => {
    const nuovoSesso = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioSesso = textTrim(datiGenerali.sesso, true);
    if (nuovoSesso !== vecchioSesso) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_SESSO_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(nuovoSesso, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_SESSO_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(nuovoSesso, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_SESSO_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(nuovoSesso, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_SESSO_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(nuovoSesso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(nuovoSesso, anActionType, this.props.isFiduciaria)
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(nuovoSesso, anActionType, this.props.isFiduciaria)
      }
      this.verificaCodiceFiscale(true);      
      this.setState({
        sesso: nuovoSesso,
      });
    }
  };

  handleChangeCodFisc = event => {
    const nuovoCodiceFiscale = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioCodiceFiscale = textTrim(datiGenerali.codiceFiscale, true);
    if (nuovoCodiceFiscale !== vecchioCodiceFiscale) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(nuovoCodiceFiscale, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_CODICE_FISCALE_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(nuovoCodiceFiscale, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(nuovoCodiceFiscale, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(nuovoCodiceFiscale, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(nuovoCodiceFiscale, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(nuovoCodiceFiscale, anActionType, this.props.isFiduciaria);
      }
      this.verificaCodiceFiscale(true); ;
      this.setState({
        codiceFiscale: nuovoCodiceFiscale,
      });
    }
  };

  handleChangeCognome = event => {
    const nuovoCognome = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioCognome = textTrim(datiGenerali.cognome, true);
    if (nuovoCognome !== vecchioCognome) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COGNOME_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(nuovoCognome, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_COGNOME_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(nuovoCognome, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COGNOME_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(nuovoCognome, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COGNOME_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(nuovoCognome, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(nuovoCognome, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(nuovoCognome, anActionType, this.props.isFiduciaria);
      }
      this.verificaCodiceFiscale(true); ;
      this.setState({
        cognome: nuovoCognome,
      });
    }
  };

  handleChangeNome = event => {
    const nuovoNome = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioNome = textTrim(datiGenerali.nome, true);
    if (nuovoNome !== vecchioNome) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NOME_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(nuovoNome, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_NOME_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(nuovoNome, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_NOME_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(nuovoNome, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NOME_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(nuovoNome, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(nuovoNome, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(nuovoNome, anActionType, this.props.isFiduciaria);
      }
      this.verificaCodiceFiscale(true); ;      
      this.setState({
        nome: nuovoNome,
      });
    }
  }

  handleChangeDataNascita = event => {
    //    if (!((event !== null) && (event !== undefined) && (!event.isValid()))) {
    if (isDataValid(event)) {
      const nuovaDataNascitaFormattata = dataFormat(event, 'DD/MM/YYYY');
      const datiGenerali = this.datiGeneraliFrom(this.props.figura);
      const vecchiaDataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY');
      if (nuovaDataNascitaFormattata !== vecchiaDataNascitaFormattata) {
        let anActionType = null;
        if (this.props.figura === CONTRAENTE_FISICO) {
          anActionType = actionTypeContraente.UPDATE_DATA_NASCITA_CONTRAENTE_FISICO;
          this.props.updateDatiGeneraliContraenteFisico(event, anActionType);
        } else if (this.props.figura === ASSICURATO_FISICO) {
          anActionType = actionTypeAssicurato.UPDATE_DATA_NASCITA_ASSICURATO;
          this.props.updateDatiGeneraliAssicurato(event, anActionType);
        } else if (this.props.figura === TITOLARE_EFFETTIVO) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_NASCITA_TITOLARE_EFF;
          this.props.updateDatiGeneraliTitolareEff(event, anActionType);
        } else if (this.props.figura === ESECUTORE_FISICO) {
          anActionType = actionTypeContraente.UPDATE_DATA_NASCITA_ESECUTORE_FISICO;
          this.props.updateDatiGeneraliEsecutore(event, anActionType);
        } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_MORTE;
          this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
        } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA;
          this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
        }
        //this.setCittadinanza(nuovaDataNascitaFormattata);
        this.setNazioni(nuovaDataNascitaFormattata);
        this.setProvince(nuovaDataNascitaFormattata, '');
        this.setComuni('', nuovaDataNascitaFormattata);
        this.verificaCodiceFiscale(true); ;
        this.setState({
          dataNascita: event,
          statoNascita: '',
          descrStatoNascita: '',
          provinciaNascita: '',
          descrProvinciaNascita: '',
          comuneNascita: '',
          descrComuneNascita: '',
        });
      }
    } else {
      console.log('###DATA NON VALIDA ' + this.props.figura + ' handleChangeDataNascita', event);
    }
  }

  handleChangeStatoNascita = event => {
    const nuovoStatoNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioStatoNascita = textTrim(datiGenerali.statoNascita, true);
    if (nuovoStatoNascita !== vecchioStatoNascita) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_NASCITA_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_STATO_NASCITA_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_NASCITA_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_NASCITA_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
      }
      this.setProvince(dataFormat(this.state.dataNascita, 'DD/MM/YYYY'), nuovoStatoNascita);
      this.setComuni('', dataFormat(this.state.dataNascita, 'DD/MM/YYYY'));
      this.setState({
        statoNascita: nuovoStatoNascita,
        descrStatoNascita: (event === null) ? '' : event.label,
        provinciaNascita: '',
        descrProvinciaNascita: '',
      });
    }
  }

  handleChangeProvinciaNascita = event => {
    const nuovaProvinciaNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchiaProvinciaNascita = textTrim(datiGenerali.provinciaNascita, true);
    if (nuovaProvinciaNascita !== vecchiaProvinciaNascita) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_NASCITA_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_PROVINCIA_NASCITA_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_NASCITA_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
      }
    this.setComuni(nuovaProvinciaNascita, dataFormat(this.state.dataNascita, 'DD/MM/YYYY'));
      this.verificaCodiceFiscale(true); ;
      this.setState({
        provinciaNascita: nuovaProvinciaNascita,
        descrProvinciaNascita: (event === null) ? '' : event.label,
        comuneNascita: '',
        descrComuneNascita: '',
      });
    }
  }

  handleChangeComuneNascita = event => {
    const nuovoComuneNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioComuneNascita = textTrim(datiGenerali.comuneNascita, true);
    if (nuovoComuneNascita !== vecchioComuneNascita) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_NASCITA_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_COMUNE_NASCITA_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_NASCITA_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria);
      }
    this.verificaCodiceFiscale(true); ;
      this.setState({
        comuneNascita: nuovoComuneNascita,
        descrComuneNascita: (event === null) ? '' : event.label,
      });
    }
  }

  // usata per PROVINCIA ESTERA
  handleChangeComuneNascitaTextField = event => {
    const nuovoComuneNascita = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioComuneNascita = textTrim(datiGenerali.descrComuneNascita, true);
    if (nuovoComuneNascita !== vecchioComuneNascita) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_NASCITA_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(nuovoComuneNascita, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_COMUNE_NASCITA_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(nuovoComuneNascita, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(nuovoComuneNascita, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_NASCITA_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(nuovoComuneNascita, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(nuovoComuneNascita, anActionType, this.props.isFiduciaria);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA;
        this.props.updateDatiGeneraliTitolareEff(nuovoComuneNascita, anActionType, this.props.isFiduciaria);
      }
    this.verificaCodiceFiscale(true); ;
      this.setState({
        comuneNascita: '',
        descrComuneNascita: (event === null) ? '' : nuovoComuneNascita,
      });
    }
  }

  handleChangeCittadinanza1 = event => {
    const nuovaCittadinanza1 = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchiaCittadinanza1 = textTrim(datiGenerali.cittadinanza1, true);
    if (nuovaCittadinanza1 !== vecchiaCittadinanza1) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CITTADINANZA_1_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_CITTADINANZA_1_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CITTADINANZA_1_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CITTADINANZA_1_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeBenefMorte.UPDATE_CITTADINANZA_1_TITOLARE_EFF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeBenefVita.UPDATE_CITTADINANZA_1_TITOLARE_EFF_VITA;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria, this.props.figura);
      }
      this.setState({
        cittadinanza1: (event === null) ? '' : event.value,
        descrCittadinanza1: (event === null) ? '' : event.label,
      });
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  }

  handleChangeCittadinanza2 = event => {
    const nuovaCittadinanza2 = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchiaCittadinanza2 = textTrim(datiGenerali.cittadinanza2, true);
    if (nuovaCittadinanza2 !== vecchiaCittadinanza2) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CITTADINANZA_2_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(event, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_CITTADINANZA_2_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CITTADINANZA_2_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CITTADINANZA_2_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeBenefMorte.UPDATE_CITTADINANZA_2_TITOLARE_EFF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeBenefVita.UPDATE_CITTADINANZA_2_TITOLARE_EFF_VITA;
        this.props.updateDatiGeneraliTitolareEff(event, anActionType, this.props.isFiduciaria, this.props.figura);
      }
      this.setState({
        cittadinanza2: (event === null) ? '' : event.value,
        descrCittadinanza2: (event === null) ? '' : event.label,
      });

      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  }

  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  handleChangeTitoloStudio = event => {
    const nuovoTitoloStudio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioTitoloStudio = textTrim(datiGenerali.titoloStudio, true);
    if (nuovoTitoloStudio !== vecchioTitoloStudio) {
      this.props.updateDatiGeneraliContraenteFisico(event, actionTypeContraente.UPDATE_TITOLO_STUDIO, this.props.figura);
      this.setState({
        titoloStudio: (event === null) ? '' : event.value,
        descrTitoloStudio: (event === null) ? '' : event.label,
      });
      if (vecchioTitoloStudio === 'ALTRO') {
        this.setState({
          altro: '',
        });
        this.props.updateDatiGeneraliContraenteFisico('', actionTypeContraente.UPDATE_TITOLO_STUDIO_ALTRO, this.props.figura);
      }
    }
  };

  handleChangeAltro = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioAltro = textTrim(datiGenerali.altroTitoloStudio, true);
    if (nuovoAltro !== vecchioAltro) {
      this.props.updateDatiGeneraliContraenteFisico(nuovoAltro, actionTypeContraente.UPDATE_TITOLO_STUDIO_ALTRO, this.props.figura);
      this.setState({
        altro: nuovoAltro,
      })
    }
  }

  handleChangeAltroRelazioneContrTit  = (event) => {
    const nuovoAltroRelaz = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioAltroRelaz = textTrim(datiGenerali.altroRelaz, true);
    if(nuovoAltroRelaz !== vecchioAltroRelaz) {
      this.props.updateDatiGeneraliTitolareEff(nuovoAltroRelaz, actionTypeTitolareEff.UPDATE_RELAZ_ALTRO, this.props.figura);
      this.setState({
        altro: nuovoAltroRelaz,
      })
    }
  }

  handleChangeNumTelefono = event => {
    const nuovoNumTelefono = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioNumTelefono = textTrim(datiGenerali.numTelefono, true);
    if (nuovoNumTelefono !== vecchioNumTelefono) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NUM_TELEFONO_CONTRAENTE_FISICO;
        this.props.updateDatiGeneraliContraenteFisico(nuovoNumTelefono, anActionType);
      } else if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssicurato.UPDATE_NUM_TELEFONO_ASSICURATO;
        this.props.updateDatiGeneraliAssicurato(nuovoNumTelefono, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_NUM_TELEFONO_TITOLARE_EFF;
        this.props.updateDatiGeneraliTitolareEff(nuovoNumTelefono, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NUM_TELEFONO_ESECUTORE_FISICO;
        this.props.updateDatiGeneraliEsecutore(nuovoNumTelefono, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeBenefMorte.UPDATE_NUM_TELEFONO_TITOLARE_EFF_MORTE;
        this.props.updateDatiGeneraliTitolareEff(nuovoNumTelefono, anActionType, this.props.isFiduciaria, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeBenefVita.UPDATE_NUM_TELEFONO_TITOLARE_EFF_VITA;
        this.props.updateDatiGeneraliTitolareEff(nuovoNumTelefono, anActionType, this.props.isFiduciaria, this.props.figura);
      }
      this.setState({
        numTelefono: nuovoNumTelefono,
      });
    }
  }

  handleChangeRelazione = event => {
    const nuovaRel = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaRel = this.props.datiRelazContrTitolareEff.relazContraente;
    if (nuovaRel !== vecchiaRel) {
      this.props.updateDatiGeneraliTitolareEff(event, actionTypeTitolareEff.UPDATE_RELAZIONE_CONTRAENTE_TITOLARE);
      this.setState({
        relazContraente: nuovaRel,
        descrRelazContraente: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      })
    }
  };

  setTitoliDiStudio = aDate => {
    if(textTrim(aDate, true)) {
      const opts = {
        siglaVersione: 'ZURI',
        codiceCampo: 'TTST',
        dataRichiesta: aDate,
      };
      fetch(uris.titoloDiStudioItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            titoloDiStudioItems: result
          })
        })
    } else {
      this.setState({
        titoloDiStudioItems: []
      })
    }
  }

  setCittadinanza = (aDate) => {
    if (textTrim(aDate, true) !== '') {
      const opts = {
        siglaVersione: 'ZURI',
        codiceCampo: 'A010',
        dataRichiesta: aDate,
      };
      fetch(uris.cittadinanzaItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            cittadinanzaItems: result,
          });
        });
    } else {
      this.setState({
        cittadinanzaItems: [],
      });
    }
  }

  setNazioni = (aDate) => {
    if (textTrim(aDate, true) !== '') {
      const opts = {
        dataRiferimentoComune: aDate,
      };
      fetch(uris.nazioneItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            nazioneItems: result,
          });
        });
    } else {
      this.setState({
        nazioneItems: [],
      });
    }
  }

  setProvince = (aDate, nazione) => {
    if (textTrim(nazione, true) !== '') {
      const opts = {
        dataRiferimentoProv: aDate,
        nazione: nazione,
      };
      fetch(uris.provinciaItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            provinciaItems: result,
          });
        });
    } else {
      this.setState({
        provinciaItems: [],
      });
    }
  }

  setComuni = (codProvincia, dataNascita) => {
    if (textTrim(codProvincia, true) !== '' && textTrim(dataNascita, true) !== '') {
      const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            comuneItems: result,
          });
        });
    } else {
      this.setState({
        comuneItems: [],
      });
    }
  }

  timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        reject(new Error("timeout"))
      }, ms)
      promise.then(resolve, reject)
    })
  }

//NON DEVE ESSERE RICHIAMATO DAL render()  
  verificaCodiceFiscale = (viewLoadinOverlay) => {  
    let controllo = false;
    let codiceFiscaleValido = null;
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const codiceFiscale = textTrim(datiGenerali.codiceFiscale, true);
    const cognome = textTrim(datiGenerali.cognome, true);
    const nome = textTrim(datiGenerali.nome, true);
    const sesso = textTrim(datiGenerali.sesso, true);
    const dataNascita = datiGenerali.dataNascita;
    const provinciaNascita = textTrim(datiGenerali.provinciaNascita, true);
    const descrizioneLuogo = textTrim(datiGenerali.descrComuneNascita, true);
    const codiceIstat = textTrim(datiGenerali.comuneNascita, true);
    const richiamoServizio = (codiceFiscale !== '') && (cognome !== '') && (nome !== '') && (sesso !== '') && (dataNascita !== null) &&
      (provinciaNascita !== '' && provinciaNascita !== PROVINCIA_ESTERA) && (descrizioneLuogo !== '') && (codiceIstat !== '');
    if (richiamoServizio === true) {
      console.log('########DatiGeneraliFisico verificaCodiceFiscale richiamoServizio === true --> ', richiamoServizio);
      if (isLocal === true) {
        codiceFiscaleValido = true;
        let anActionType = null;
        if (this.props.figura === CONTRAENTE_FISICO) {
          anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO_CONTRAENTE_FISICO;
          this.props.updateDatiGeneraliContraenteFisico(codiceFiscaleValido, anActionType);
        } else if (this.props.figura === ASSICURATO_FISICO) {
          anActionType = actionTypeAssicurato.UPDATE_CODICE_FISCALE_VALIDO_ASSICURATO;
          this.props.updateDatiGeneraliAssicurato(codiceFiscaleValido, anActionType);
        } else if (this.props.figura === TITOLARE_EFFETTIVO) {
          //da gestire nello scenario nuovo        	  
          anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF;
          this.props.updateDatiGeneraliTitolareEff(codiceFiscaleValido, anActionType);
        } else if (this.props.figura === ESECUTORE_FISICO) {
          anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO_ESECUTORE_FISICO;
          this.props.updateDatiGeneraliEsecutore(codiceFiscaleValido, anActionType);
        }
        this.setState({
          codiceFiscaleValido: codiceFiscaleValido,
        });
      } else {
    	// sets overlay
    	if (viewLoadinOverlay===true) { 
    		const labelOverlay= 'general.verificaCodFiscInCorso';
    		this.props.setGeneric(true, labelOverlay,actionTypeGeneric.LOADING_OVERLAY);
    	}
    	
  		const dataNascitaFormattata = dataFormat(dataNascita, 'DD/MM/YYYY');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale codiceFiscale: #' + codiceFiscale + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale cognome: #' + cognome + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale nome: #' + nome + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale sesso: #' + sesso + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale dataNascita: #' + dataNascitaFormattata + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale provinciaNascita: #' + provinciaNascita + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale descrizioneLuogo: #' + descrizioneLuogo + '#');
  	    console.log('########DatiGeneraliFisico verificaCodiceFiscale codiceIstat: #' + codiceIstat + '#');
		const opts = {
          codiceFiscale: codiceFiscale,
          cognome: cognome,
          nome: nome,
          sesso: sesso,
          dataNascita : dataNascitaFormattata, 
          provinciaNascita : provinciaNascita,
          descrizioneLuogo : descrizioneLuogo,
          codiceIstat : codiceIstat
	    };		
		fetch(uris.controllaCalcolaCodiceFiscale, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
          console.log('########DatiGeneraliFisico verificaCodiceFiscale result', result);
          codiceFiscaleValido = result;
          controllo = true;          
          /* this.updateCodiceFiscaleValido(codiceFiscaleValido); */         
          let anActionType = null;
          if (this.props.figura === CONTRAENTE_FISICO) {
            anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO_CONTRAENTE_FISICO;
            this.props.updateDatiGeneraliContraenteFisico(codiceFiscaleValido, anActionType);
          } else if (this.props.figura === ASSICURATO_FISICO) {
            anActionType = actionTypeAssicurato.UPDATE_CODICE_FISCALE_VALIDO_ASSICURATO;            
            this.props.updateDatiGeneraliAssicurato(codiceFiscaleValido, anActionType);
          } else if (this.props.figura === TITOLARE_EFFETTIVO) {
//da gestire nello scenario nuovo        	  
        	anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF;        	  
            this.props.updateDatiGeneraliTitolareEff(codiceFiscaleValido, anActionType);
          } else if (this.props.figura === ESECUTORE_FISICO) {
            anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO_ESECUTORE_FISICO;        	  
            this.props.updateDatiGeneraliEsecutore(codiceFiscaleValido, anActionType);
          }          
          this.setState({	
            codiceFiscaleValido: codiceFiscaleValido,      
          });             
	    }).catch( (error) => {
	    	console.error('########ERROR: DatiGeneraliFisico verificaCodiceFiscale error', error);
	    	
	    }).finally( () => {
	    	this.props.setGeneric(false, '',actionTypeGeneric.LOADING_OVERLAY);
	    });
      }     
    } else {
      console.log('########DatiGeneraliFisico verificaCodiceFiscale richiamoServizio === false --> ', richiamoServizio);
      if (provinciaNascita === PROVINCIA_ESTERA && this.state.codiceFiscaleValido !== true) {
        // assumo che sia sempre valido
        codiceFiscaleValido = true;
        this.updateCodiceFiscaleValido(codiceFiscaleValido);
      }
    }
  }

  updateCodiceFiscaleValido = (codiceFiscaleValido) => {
    let anActionType = null;
    if (this.props.figura === CONTRAENTE_FISICO) {
      anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO_CONTRAENTE_FISICO;
      this.props.updateDatiGeneraliContraenteFisico(codiceFiscaleValido, anActionType);
    } else if (this.props.figura === ASSICURATO_FISICO) {
      anActionType = actionTypeAssicurato.UPDATE_CODICE_FISCALE_VALIDO_ASSICURATO;
      this.props.updateDatiGeneraliAssicurato(codiceFiscaleValido, anActionType);
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      //da gestire nello scenario nuovo        	  
      anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF;
      this.props.updateDatiGeneraliTitolareEff(codiceFiscaleValido, anActionType);
    } else if (this.props.figura === ESECUTORE_FISICO) {
      anActionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO_ESECUTORE_FISICO;
      this.props.updateDatiGeneraliEsecutore(codiceFiscaleValido, anActionType);
    } /* else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE;
      this.props.updateDatiGeneraliTitolareEff(codiceFiscaleValido, anActionType, this.props.isFiduciaria);
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      anActionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA;
      this.props.updateDatiGeneraliTitolareEff(codiceFiscaleValido, anActionType, this.props.isFiduciaria);
    } */
    this.setState({
      codiceFiscaleValido: codiceFiscaleValido,
    });
  }

  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    } else if (figura === ASSICURATO_FISICO) {
      controlloDati = this.props.controlloDatiAssicuratoFisico;
    } else if (figura === TITOLARE_EFFETTIVO) {
      controlloDati = this.props.controlloDatiTitolareEff;
    } else if (figura === ESECUTORE_FISICO) {
      controlloDati = this.props.controlloDatiEsecutore;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitEffVita;
    }
    return controlloDati;
  }

  isRequiredPerEsecutore = () => {
    return (this.props.datiEsecutore.flEsecutore === 'S');
  }

  isRequiredSesso = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredCodFisc = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredCognome = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredNome = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredDataNascita = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredStatoNascita = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredProvinciaNascita = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredComuneNascita = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredCittadinanza1 = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredRelazContr = () => {
    let required = false;
    /* //2020-12-01 PFD: SAV 'TO BE': defect 2892, tolta obbligatorieta per TITOLARE_EFFETTIVO
    if (this.props.figura === TITOLARE_EFFETTIVO && this.props.scopeAmleto) {
      required = true;
    } else 
    */
    
    if (this.props.figura === TITOLARE_EFF_BENEF_MORTE && this.props.scopeAmleto) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA && this.props.scopeAmleto) {
      required = true;
    }
    
    return required;
  }

  isRequiredRelazAltro = () => {
    let required = false;   
    if (this.props.figura === TITOLARE_EFFETTIVO) {
      const datiRelazContr = this.datiRelazContrFrom(this.props.figura);
      if (datiRelazContr.relazContraente === '10') {
        required = true;
      }
    }
    return required;
  }


  isRequiredTitoloStudio = () => {
	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
	// return = this.props.scopeAmleto;	
	let required = this.props.scopeAmleto;
	if (this.props.figura === CONTRAENTE_FISICO) {
		required = false;	
	}
    return required;
    // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
  }

  isRequiredAltro = () => {
    let required = false;
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = (datiGenerali.titoloStudio === CODICE_ALTRO_TITOLO_STUDIO) && this.props.scopeAmleto;
    }
    return required;
  }


  initStatoCampi = () => {
    return [{ id: 'sesso', isError: false },
    { id: 'codFisc', isError: false },
    { id: 'cognome', isError: false },
    { id: 'nome', isError: false },
    { id: 'dataNascita', isError: false },
    { id: 'statoNascita', isError: false },
    { id: 'provinciaNascita', isError: false },
    { id: 'comuneNascita', isError: false },
    { id: 'cittadinanza1', isError: false },
    { id: 'cittadinanza2', isError: false },
    { id: 'numTelefono', isError: false },
    { id: 'relazioneContrTitEff', isError: false },
    { id: 'altroRelazContrTit', isError: false },
    ];
  }

  handleError = (id, isError) => {
    console.log('########DatiGeneraliFisico verificaCodiceFiscale handleError id', id);
    console.log('########DatiGeneraliFisico verificaCodiceFiscale handleError isError', isError);
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({
      statoCampi: [...campoWithoutId, unErrore],
    });
  }

  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }

  isDisabled = () => {
    const disabled = (this.props.renderChildren !== undefined) && (this.props.renderChildren !== null) && (this.props.renderChildren.digitabile === false);
    return disabled;
  }

  isDisabledStato = () => {
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const dataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY');
    return (this.isDisabled() === true) || (dataNascitaFormattata === null);
  }

  isDisabledProvincia = () => {
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const statoNascita = textTrim(datiGenerali.statoNascita, true);
    return (this.isDisabledStato() === true) || (statoNascita === '');
  }

  isDisabledComune = () => {
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const provinciaNascita = textTrim(datiGenerali.provinciaNascita, true);
    return (this.isDisabledProvincia() === true) || (provinciaNascita === '');
  }

  isDisabledRelazContr = () => {
    let disabled = true;
    if(this.props.scopeAmleto){
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        disabled = false;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        disabled = false;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        disabled = false;
      }
    }
    return disabled;
  }

  isDisabledRelazAltro = () => {
    let disabled = true;
    const datiRelazContr = this.datiRelazContrFrom(this.props.figura);
    if (this.props.figura === TITOLARE_EFFETTIVO) {
      disabled = true;      
      if (datiRelazContr.relazContraente === '10') {
        disabled = false;
      }
    } else if (this.props.figura === ASSICURATO_FISICO) {
      disabled = true;      
      if (datiRelazContr.relazContraente === '10') {
        disabled = false;
      }
    }
    return disabled;
  }

  isDisabledTitoloStudio = () => {
    return this.props.scopeAmleto === false;
  }

  isDisabledAltro = () => {
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const altro = textTrim(datiGenerali.titoloStudio, true);
    return (altro !== CODICE_ALTRO_TITOLO_STUDIO) || this.props.scopeAmleto === false;
  }

  render() {
    console.log('########DatiGeneraliFisico render this.props', this.props);
    console.log('########DatiGeneraliFisico render this.state.statoCampi', this.state.statoCampi);
    const { classes, figura, labelFieldSet, dominio, renderChildren } = this.props;
    const datiGenerali = this.datiGeneraliFrom(figura);
    const datiRelazContr = this.datiRelazContrFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura);
    const disabled = this.isDisabled();
    //  const { t } = this.props; //hoc    
    return (
      <Fragment>
        {/*
      <fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiGenerali')}</Typography></legend>*/}
        {labelFieldSet &&
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        }
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          {figura === TITOLARE_EFFETTIVO &&
           <Grid container direction='row' justify='center' alignItems='flex-start'>
            <Grid item md={8} xs={12}>
              <SelectComp onChange={this.handleChangeRelazione} options={dominio.relazioneItems} id='relazioneContrTitEff' etichetta='relazioneContrTitEff' controlloDati={controlloDati}
                onError={this.handleError} disabled={this.isDisabledRelazContr()} required={this.isRequiredRelazContr()} aStyleError={classes.textFieldWidthPercError}
                value={{ value: datiRelazContr.relazContraente, label: datiRelazContr.descrRelazContraente }} />
            </Grid>
            <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeAltroRelazioneContrTit} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='altroRelazContrTit' label='general.altro'
                value={datiGenerali.altroRelaz} required={this.isRequiredRelazAltro()} controlloDati={controlloDati} disabled={this.isDisabledRelazAltro()} maxLength={30}
                onError={this.handleError} />
            </Grid>
            </Grid>
          }
          <Grid container>
            <Grid item md={4} xs={12}>
              <RadioButtonComp onChange={this.handleChangeSesso} radioComp={radioButtonSesso} selectedValue={datiGenerali.sesso}
                required={this.isRequiredSesso()} controlloDati={controlloDati} disabled={disabled}
                id='sesso' onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextFieldComp onBlur={this.handleChangeCodFisc} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='codFisc' label='codiceFiscale'
                value={datiGenerali.codiceFiscale} required={this.isRequiredCodFisc()} controlloDati={controlloDati} disabled={disabled} maxLength={16}
                onError={this.handleError} isControlError={this.getIsInError('codFisc')} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={12}>
              <TextFieldComp onBlur={this.handleChangeCognome} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='cognome' label='general.cognome'
                value={datiGenerali.cognome} required={this.isRequiredCognome()} controlloDati={controlloDati} disabled={disabled}
                onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextFieldComp onBlur={this.handleChangeNome} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='nome' label='general.nome'
                value={datiGenerali.nome} required={this.isRequiredNome()} controlloDati={controlloDati} disabled={disabled}
                onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}>
              <DatePickerComp onChange={this.handleChangeDataNascita} aStyle={classes.dateField} /* aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} */ id='dataNascita' label='dataNascita'
                value={datiGenerali.dataNascita} required={this.isRequiredDataNascita()} controlloDati={controlloDati}
                disabled={disabled} onError={this.handleError} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={12}>
              <SelectComp onChange={this.handleChangeStatoNascita} options={this.state.nazioneItems} ricerca='comboRicerca' etichetta='statoNascita'
                value={{ value: datiGenerali.statoNascita, label: datiGenerali.descrStatoNascita }} required={this.isRequiredStatoNascita()}
                controlloDati={controlloDati} disabled={this.isDisabledStato()} id='statoNascita' onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectComp onChange={this.handleChangeProvinciaNascita} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provinciaNascita'
                value={{ value: datiGenerali.provinciaNascita, label: datiGenerali.descrProvinciaNascita }} required={this.isRequiredProvinciaNascita()}
                controlloDati={controlloDati} disabled={this.isDisabledProvincia()} id='provinciaNascita' onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}>
              {datiGenerali.provinciaNascita !== PROVINCIA_ESTERA &&
                <SelectComp onChange={this.handleChangeComuneNascita} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comuneNascita'
                  value={{ value: datiGenerali.comuneNascita, label: datiGenerali.descrComuneNascita }} required={this.isRequiredComuneNascita()}
                  controlloDati={controlloDati} disabled={this.isDisabledComune()} id='comuneNascita' onError={this.handleError} />
              }
              {datiGenerali.provinciaNascita === PROVINCIA_ESTERA &&
                <TextFieldComp onBlur={this.handleChangeComuneNascitaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneNascita' label='comuneNascita'
                  value={datiGenerali.descrComuneNascita} required={this.isRequiredComuneNascita()} controlloDati={controlloDati} disabled={disabled}
                  onError={this.handleError} />
              }
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={12}>
              <SelectComp onChange={this.handleChangeCittadinanza1} options={this.state.cittadinanzaItems} ricerca='comboRicerca' etichetta='cittadinanza1'
                value={{ value: datiGenerali.cittadinanza1, label: datiGenerali.descrCittadinanza1 }} required={this.isRequiredCittadinanza1()}
                controlloDati={controlloDati} disabled={disabled} id='cittadinanza1' onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectComp onChange={this.handleChangeCittadinanza2} options={this.state.cittadinanzaItems} ricerca='comboRicerca' etichetta='cittadinanza2'
                value={{ value: datiGenerali.cittadinanza2, label: datiGenerali.descrCittadinanza2 }} controlloDati={controlloDati}
                disabled={disabled} id='cittadinanza2' onError={this.handleError} />
            </Grid>
            <Grid item md={4} xs={12}></Grid>
            {figura === CONTRAENTE_FISICO &&
              <Grid container>
                <Grid item md={4} xs={12}>
                  <SelectComp onChange={this.handleChangeTitoloStudio} options={this.state.titoloDiStudioItems} etichetta='titoloStudio'
                    value={{ value: datiGenerali.titoloStudio, label: datiGenerali.descrTitoloStudio }} required={this.isRequiredTitoloStudio()}
                    controlloDati={controlloDati} id='titoloStudio' onError={this.handleError} disabled={this.isDisabledTitoloStudio()} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextFieldComp id='titoloStudioAltro' onBlur={this.handleChangeAltro} label='altroTitoloStudio' value={datiGenerali.altroTitoloStudio} required={this.isRequiredAltro()}
                    onError={this.handleError} controlloDati={controlloDati} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                    disabled={this.isDisabledAltro()} />
                </Grid>
              </Grid>
            }
            <Grid item md={4} xs={12}>
              <TextFieldAdornComp onBlur={this.handleChangeNumTelefono} id='numTelefono' label='numTelefono' value={datiGenerali.numTelefono}
                aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                margin='normal' variant='outlined' adornComp={<Phone />} adornPosit='start' controlloDati={controlloDati}
                disabled={disabled} onError={this.handleError} />
            </Grid>
          </Grid>
        </Grid>
        {/*</fieldset>*/}
      </Fragment>
    );
  }

  updatePerContraente(prevProps) {
    const prevRender = prevProps.renderChildren;
    const currRender = this.props.renderChildren;
    if (this.isDisabled() && (prevRender !== currRender)) {
      this.setState({
        cittadinanzaItems: [],
        nazioneItems: [],
        provinciaItems: [],
        comuneItems: [],
      });
      const figura = this.props.figura;
      if (figura === ESECUTORE_FISICO) {
        this.props.updateDatiGeneraliPerContraente(null, actionTypeContraente.UPDATE_DATI_GENERALI_PER_ESECUTORE);
      } else if (figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiGeneraliPerContraente(null, actionTypeTitolareEff.UPDATE_DATI_GENERALI_PER_TITOLARE_EFFETTIVO);
      } /* else if (figura === CONTRAENTE_FISICO) {
        this.props.updateDatiGeneraliPerContraente(null, actionTypeContraente.UPDATE_DATI_GENERALI_PER_CONTRAENTE);    	  
      } else if (figura === ASSICURATO_FISICO) {
        this.props.updateDatiGeneraliPerAssicurato(null, actionTypeAssicurato.UPDATE_DATI_GENERALI_PER_ASSICURATO);       	  
      } */
    }
  }

  isInError(prevProps, prevState) {
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
    });
    console.log('########DatiGeneraliFisico componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    //    console.log('########DatiGeneraliFisico componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    //    console.log('########DatiGeneraliFisico componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
    if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
    }
  }

  prevDatiGeneraliFrom = (figura, prevProps) => {
    let prevDatiGenerali = null;
    if (figura === CONTRAENTE_FISICO) {
      prevDatiGenerali = prevProps.datiGeneraliContraenteFisico;
    } else if (figura === ASSICURATO_FISICO) {
      prevDatiGenerali = prevProps.datiGeneraliAssicuratoFisico;
    } else if (figura === TITOLARE_EFFETTIVO) {
      prevDatiGenerali = prevProps.datiGeneraliTitolareEff;
    } else if (figura === ESECUTORE_FISICO) {
      prevDatiGenerali = prevProps.datiEsecutore.datiGenerali;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      prevDatiGenerali = this.props.datiGeneraliTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      prevDatiGenerali = this.props.datiGeneraliTitEffVita;
    }
    return prevDatiGenerali;
  }

  isInBusinessError(prevProps, prevState) {
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const prevDatiGenerali = this.prevDatiGeneraliFrom(this.props.figura, prevProps);
    /*	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError datiGenerali.codiceFiscaleValido', datiGenerali.codiceFiscaleValido);
      console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError prevDatiGenerali.codiceFiscaleValido', prevDatiGenerali.codiceFiscaleValido);
      if (datiGenerali.codiceFiscaleValido !== prevDatiGenerali.codiceFiscaleValido) {
        this.handleError('codFisc', !datiGenerali.codiceFiscaleValido);		
      } */
    console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError prevState.codiceFiscaleValido', prevState.codiceFiscaleValido);
    console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError this.state.codiceFiscaleValido', this.state.codiceFiscaleValido);
    console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError prevProps.codiceFiscaleValido', prevDatiGenerali.codiceFiscaleValido);
    console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError this.props.datiGenerali.codiceFiscaleValido', datiGenerali.codiceFiscaleValido);
    if (this.state.codiceFiscaleValido !== null && this.state.codiceFiscaleValido !== prevState.codiceFiscaleValido) {
      this.handleError('codFisc', !this.state.codiceFiscaleValido);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.updatePerContraente(prevProps);
    this.isInBusinessError(prevProps, prevState);
    this.isInError(prevProps, prevState);
  }
}

DatiGeneraliFisico.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  dominio: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiGeneraliFisico));
