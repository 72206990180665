//import { datiGeneraliGenerico } from './sezioni/sezioniAnagrafica';
import { datiGeneraliGenerico } from './sezioni/sezioniAnagraficaBenefMorteGenerico';
import { RUOLO_BENEF_MORTE, PERSONA_GENERICA } from '../../utility/jsConstants';

export const benefMorteGenerico = {
  controlloDati: '',
  anagraficaValida: null,
  datiFigureAnagrBase: {
	ruolo: RUOLO_BENEF_MORTE,
	tipoPers: PERSONA_GENERICA,
  },
  datiGenerali: datiGeneraliGenerico,
};

export const benefMorteGenericoList = [
//  benefMorteGenerico
];