export const areaLogin = {
  area: {
    title: 'Login',
    image: '/images/generic/risparmio.svg',
    to: '',
  },
  pulsanti: [{
    codice: 'LGN',
    title: 'Login',
    to: '/areaProdotti'
  }],
};