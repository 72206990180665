import { jsonCopy } from '../../../utility/genericUtility';
import { datiGeneraliBenefVitaInizializzato, datiResidenzaInizializzato, 
	     datiAntiriciclaggioInizializzato, datiRelazContrInizializzato, 
	     datiCopiaDaAltraFiguraInizializzato } 
	from '../componentiBase/sezioniAnagrafica';

export const datiGeneraliBenefVita = jsonCopy(datiGeneraliBenefVitaInizializzato);
export const datiResidenza = jsonCopy(datiResidenzaInizializzato);
export const datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
export const datiRelazContr = jsonCopy(datiRelazContrInizializzato);
export const datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
