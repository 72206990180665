import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  root: {
	width: '100%',
  },
  inner: {
  },
  card: {
/*    width: 550, */
    display: 'flex',
    alignItems: 'center',
  },
  media: {
	  height: 200,
	  width: 200,
  },
});

class DefaultStep extends React.Component {
	render() {
	    const { classes, label, image } = this.props;
     
		return (

		  <Grid container direction="row" justify="center" alignItems="center">
		  	<Grid item xs={2} >
		  	</Grid>		  
		  	<Grid item xs={8} >
		  	 <Card className={classes.card}>
		        <CardMedia
		          className={classes.media}
		          image={image}
		          title={label}
		        />
		        <CardContent>
		          <Typography variant="h6" className={classes.inner} >{label}</Typography>
		        </CardContent>
		      </Card>
		  	</Grid>
		  	<Grid item xs={2} >
		  	</Grid>		  	
		   </Grid>

		);
	}
}

DefaultStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DefaultStep);
