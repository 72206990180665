//import { datiGeneraliBenefVita, datiResidenza, datiRelazContr, datiCopiaDaAltraFigura } from './sezioni/sezioniAnagrafica';
import { datiGeneraliBenefVita, datiResidenza, datiAntiriciclaggio, datiRelazContr, datiCopiaDaAltraFigura } from './sezioni/sezioniAnagraficaBenefVitaFisico';
import { RUOLO_BENEF_VITA, PERSONA_FISICA } from '../../utility/jsConstants';

export const benefVitaFisico = {
  controlloDati: '',
  anagraficaValida: null,  
  datiFigureAnagrBase: {
    ruolo: RUOLO_BENEF_VITA,
    tipoPers: PERSONA_FISICA,
  },
  datiGeneraliBenefVita: datiGeneraliBenefVita,
  datiResidenza: datiResidenza,
  datiRelazContr: datiRelazContr,
  datiAntiriciclaggio: datiAntiriciclaggio,
  datiCopiaDaAltraFigura: datiCopiaDaAltraFigura,
};

export const benefVitaFisicoList = [
//  benefVitaFisico
];