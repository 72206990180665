import * as actionType from '../actionType/beneficiarioVita';
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import initialState from '../../../../session/InitialState';
import { RUOLO_BENEF_VITA, PERSONA_FISICA, PERSONA_GIURIDICA, PROVINCIA_ESTERA, CONTRAENTE_GIURIDICO, CODICE_QUALIFICA_FIDUCIANTE } from '../../../../utility/jsConstants';

import { jsonCopy, dataFormat } from '../../../../utility/genericUtility';
import { datiGeneraliBenefVitaInizializzato, datiResidenzaInizializzato, datiRelazContrInizializzato, datiCopiaDaAltraFiguraInizializzato, 
         datiGeneraliBenefGiuridicoInizializzato, datiGeneraliGenericoInizializzato, datiSocietariGiuridicoInizializzato, datiMandatoFiduciarioInizializzato, datiAttEconomicaGiuridicoInizializzato, datiTitolareEffettivoBenefVitaInizializzato, titEffBenefVitaGiuridicoInizializzato } from '../../../../session/info/componentiBase/sezioniAnagrafica';
import { benefVitaGiuridico } from '../../../../session/info/beneficiarioVitaGiuridico';
    
const areaBenefVitaReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;

  const benefVitaGiuridico = {...state}.benefVitaGiuridico;
  const benefVitaGiuridicoList = {...state}.benefVitaGiuridicoList;
  const titolareEffBenefVita = {...benefVitaGiuridico}.titolareEffBenefVita;
  const datiTitolareBenefVitaEff = {...titolareEffBenefVita}.datiTitolareBenefVitaEff;

  const datiGeneraliMinimiTitEffBenefVita     = datiTitolareBenefVitaEff.datiGeneraliMinimi;
  const datiRelazContrTitEffBenefVita         = datiTitolareBenefVitaEff.datiRelazContr;

  const datiGeneraliTitEffVita				  = datiTitolareBenefVitaEff.datiGenerali;
  const datiResidenzaTitEffVita		   	      = datiTitolareBenefVitaEff.datiResidenza;
  const datiDomicilioTitEffVita				  = datiTitolareBenefVitaEff.datiDomicilio;
  const datiRecapitoTitEffVita				  = datiTitolareBenefVitaEff.datiRecapito;
  const datiAntiriciclaggioTitEffVita         = datiTitolareBenefVitaEff.datiAntiriciclaggio;
  const datiAttEconomicaTitEffVita 			  = datiTitolareBenefVitaEff.datiAttEconomica;
  const datiFiscaliTitEffVita 				  = datiTitolareBenefVitaEff.datiFiscali;

	  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  	const benefVitaFisicoInizializza = inizializzaBeneficiarioVitaFisico(state);
	    const benefVitaGiuridicoInizializza = inizializzaBeneficiarioVitaGiuridico(state);
	    const benefVitaGenericoInizializza = inizializzaBeneficiarioVitaGenerico(state);
	    const benefVitaFisicoListInizializza = inizializzaBeneficiarioVitaFisicoLista(state);
	    const benefVitaGiuridicoListInizializza = inizializzaBeneficiarioVitaGiuridicoLista(state);
      const benefVitaGenericoListInizializza = inizializzaBeneficiarioVitaGenericoLista(state);
      benefVitaGiuridico.flTitolareEffBenefVita = 'N';
      benefVitaGiuridico.tabVitaSelezionato = 0;
	    return {
	    	...state,
	    	benefVitaFisico: benefVitaFisicoInizializza,
	    	benefVitaGiuridico: benefVitaGiuridicoInizializza,
	    	benefVitaGenerico: benefVitaGenericoInizializza,
	    	benefVitaFisicoList: benefVitaFisicoListInizializza,
	    	benefVitaGiuridicoList: benefVitaGiuridicoListInizializza,
	    	benefVitaGenericoList: benefVitaGenericoListInizializza,
	    };
/* BENEFICIARIO VITA FISICO */
    case actionType.SALVA_BENEF_VITA_FISICO:
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.SALVA_BENEF_VITA_FISICO.actionType.SALVA_BENEF_VITA_FISICO', actionType.SALVA_BENEF_VITA_FISICO); 
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.SALVA_BENEF_VITA_FISICO.state', state);
      console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
      const benefVitaFisicoSalva = {...state}.benefVitaFisico;
      benefVitaFisicoSalva.controlloDati = action.unDato;
      return {
    	...state,
    	benefVitaFisico: benefVitaFisicoSalva,
      };
    case actionType.SALVA_BENEF_VITA_FISICO_SUCCESS: 
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.SALVA_BENEF_VITA_FISICO_SUCCESS.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);    
        return {
	      	...state,
	      	salvaBenefVitaFisicoSuccess: action.unDato,
        };  
    case actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_FISICO:
        console.log('#RT2 Reducer areaBenefVitaReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_FISICO);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const benefVitaFisicoAnagraficaValida = {...state}.benefVitaFisico;
        benefVitaFisicoAnagraficaValida.anagraficaValida = action.unDato;
        return {
          ...state,
          benefVitaFisico: benefVitaFisicoAnagraficaValida,
        }; 
/*        
    case actionType.AGGIUNGI_BENEF_VITA_FISICO:
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.AGGIUNGI_BENEF_VITA_FISICO.actionType.AGGIUNGI_BENEF_VITA_FISICO', actionType.AGGIUNGI_BENEF_VITA_FISICO); 
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.AGGIUNGI_BENEF_VITA_FISICO.state', state);
      console.log('#RT2 Reducer areaBenefVitaReducer.action', action);    
      const beneficiarioVitaFisico = action.currBenefVitaFisico;
      const beneficiarioVitaFisicoList = {...state}.benefVitaFisicoList;
      const beneficiarioVitaFisicoListNuovo = [...beneficiarioVitaFisicoList, beneficiarioVitaFisico];
      return {
        ...state,
       	benefVitaFisicoList: beneficiarioVitaFisicoListNuovo,
        benefVitaFisico: {...action.emptyBenefVitaFisico},
      };
*/
    case actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_FISICO:      
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const beneficiarioVitaFisicoConfermaAggiungi = action.beneficiarioVitaFisico;
        const dataNascitaBeneficiarioVitaFisicoConfermaAggiungi = beneficiarioVitaFisicoConfermaAggiungi.datiGeneraliBenefVita.datiGeneraliMinimi.dataNascita;
        const cloneBeneficiarioVitaFisico = jsonCopy(beneficiarioVitaFisicoConfermaAggiungi);
        // reimposoto la data di nascita
        cloneBeneficiarioVitaFisico.datiGeneraliBenefVita.datiGeneraliMinimi.dataNascita=dataNascitaBeneficiarioVitaFisicoConfermaAggiungi;
        const beneficiarioVitaFisicoListConfermaAggiungi = {...state}.benefVitaFisicoList;
        const beneficiarioVitaFisicoListConfermaAggiungiNuovo = [...beneficiarioVitaFisicoListConfermaAggiungi, cloneBeneficiarioVitaFisico];
                
        return {
          ...state,
//          benefVitaFisico: beneficiarioVitaFisico,
          benefVitaFisicoList: beneficiarioVitaFisicoListConfermaAggiungiNuovo,
        };
    case actionType.NUOVO_BENEFICIARIO_VITA_FISICO:      
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.NUOVO_BENEFICIARIO_VITA_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const beneficiarioVitaFisicoNuovo = action.beneficiarioVitaFisico;       
        return {
          ...state,
          benefVitaFisico: beneficiarioVitaFisicoNuovo,
          salvaBenefVitaFisicoSuccess: false,
        };        
    case actionType.UPDATE_BENEF_VITA_FISICO:
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.UPDATE_BENEF_VITA_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const beneficiarioVitaFisicoUpdate = action.currBenefVitaFisico;
        const dataNascitaBeneficiarioVitaFisicoUpdate = beneficiarioVitaFisicoUpdate.datiGeneraliBenefVita.datiGeneraliMinimi.dataNascita;
        const cloneBeneficiarioVitaFisicoUpdate = jsonCopy(beneficiarioVitaFisicoUpdate);
        // reimposoto la data di nascita
        cloneBeneficiarioVitaFisicoUpdate.datiGeneraliBenefVita.datiGeneraliMinimi.dataNascita=dataNascitaBeneficiarioVitaFisicoUpdate;
        const beneficiarioVitaFisicoCodiceFiscale = cloneBeneficiarioVitaFisicoUpdate.datiGeneraliBenefVita.datiGeneraliMinimi.codiceFiscale;
        const beneficiarioVitaFisicoCognome = cloneBeneficiarioVitaFisicoUpdate.datiGeneraliBenefVita.datiGeneraliMinimi.cognome;
        const beneficiarioVitaFisicoListUpdate = [...state.benefVitaFisicoList];
        
        let indexBeneficiarioVitaFisicoUpdate = undefined;
        beneficiarioVitaFisicoListUpdate.forEach( (item, index, array) => {
        	if (cloneBeneficiarioVitaFisicoUpdate.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!=='S') {
	        	if (beneficiarioVitaFisicoCodiceFiscale===item.datiGeneraliBenefVita.datiGeneraliMinimi.codiceFiscale){
	        		indexBeneficiarioVitaFisicoUpdate = index;
	        	}
        	} else {
        		// Cerco per cognome
	        	if (beneficiarioVitaFisicoCognome===item.datiGeneraliBenefVita.datiGeneraliMinimi.cognome){
	        		indexBeneficiarioVitaFisicoUpdate = index;
	        	}
        	}
        })
        
        beneficiarioVitaFisicoListUpdate[indexBeneficiarioVitaFisicoUpdate] = cloneBeneficiarioVitaFisicoUpdate;
        return {
          ...state,
         	benefVitaFisicoList: beneficiarioVitaFisicoListUpdate,
        };
    case actionType.CARICA_BENEF_VITA:
    	const benVitaList = {...state}.benefVitaGiuridicoList;
      const figuraSelezIndex = action.figuraSelezIndex;
     
      
      /* const benMorte = {...benMorteList[figuraSelezIndex]};
      if(benMorte.titolareEffBenefMorteListInBenef[0]) {
        if(tipoPers === CONTRAENTE_GIURIDICO) {
          const benefCorrente = {...state}.benefMorteGiuridico;
          const listaSelezionata = {...benMorte}.titolareEffBenefMorteListInBenef;
          benefCorrente.titolareEffBenefMorteList = [...listaSelezionata];
          return {
            ...state,
            benefMorteGiuridico: benefCorrente,
            tabMorteSelezionato: 1
          }
        }
      } */

      const benVita = jsonCopy(benVitaList[figuraSelezIndex]);
      const tipoPers =  benVita.datiFigureAnagrBase.tipoPers;
      if(benVita.titolareEffBenefVitaListInBenef[0]) {
        if(tipoPers === PERSONA_GIURIDICA) {
          const listaSelezionata = benVita.titolareEffBenefVitaListInBenef;
          benVita.titolareEffBenefVitaList = listaSelezionata;
          return {
            ...state,
            benefVitaGiuridico: benVita,
            tabVitaSelezionato: 1
          }
        }
      }


    	if (tipoPers===PERSONA_FISICA) {
    		 return {
    	          ...state,
    	          benefVitaFisico: {...benVita}, 
    	          tabVitaSelezionato: 0, 
    	        };
    	} else if (tipoPers===PERSONA_GIURIDICA) {
    		return {
  	          ...state,
  	          benefVitaGiuridico: {...benVita},
  	          tabVitaSelezionato: 1,
  	        };
    	} else {
    		return {
    	          ...state,
    	          benefVitaGenerico: {...benVita},
    	          tabVitaSelezionato: 2,
    	        };
    	};
    case actionType.DELETE_BENEF_VITA_FISICO:
    	let benVitaFisicoToDelete = action.benefVitaFisicoDelete;
    	const percBenefVitaFisicoDelete = benVitaFisicoToDelete.datiGeneraliBenefVita.percBeneficiario;
    	
		const filterdBenVitaFisicoDeleteList = state.benefVitaFisicoList.filter( item => {
			const codiceFiscale = item.datiGeneraliBenefVita.datiGeneraliMinimi.codiceFiscale;
			return codiceFiscale!==benVitaFisicoToDelete.datiGeneraliBenefVita.datiGeneraliMinimi.codiceFiscale
		})
		 return {
	          ...state,
	          percBenVitaTotale: state.percBenVitaTotale - percBenefVitaFisicoDelete, // ricalcolo la percentuale Totale
	          benefVitaFisicoList: filterdBenVitaFisicoDeleteList,
	     };
    case actionType.UPDATE_TAB_SELEZIONATO_BENEF_VITA:    	
      const tabIndex = action.tabIndex;
      const benefVitaGiuridicoTab = {...state}.benefVitaGiuridico;
//L'UPDATE DELLA VARIABILE flTitolareEffBenefVita SERVE A NASCONDERE O MOSTRARE L'ACCORDION DEL TIT. EFF. A SECONDA DEL TIPO DI BENEFICIARIO
      if(tabIndex === 1) {
        benefVitaGiuridicoTab.flTitolareEffBenefVita = 'S';
      } else {
        benefVitaGiuridicoTab.flTitolareEffBenefVita = 'N';
      }
		 return {
	          ...state,
            tabVitaSelezionato: tabIndex,
            benefVitaGiuridico: benefVitaGiuridicoTab
	        };
/* BENEFICIARIO VITA FISICO DATI GENERALI */
/*
    case actionType.UPDATE_CODICE_FISCALE_BENEF_VITA_FISICO:
      const benefVitaFisicoFiscale = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaFiscale = {...benefVitaFisicoFiscale}.datiGeneraliBenefVita.datiGenerali;
      datiGeneraliBenefVitaFiscale.codiceFiscale = action.unDato;
      benefVitaFisicoFiscale.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaFiscale};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoFiscale,
      };
    case actionType.UPDATE_NOME_BENEF_VITA_FISICO:
      const benefVitaFisicoNome = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaNome = {...benefVitaFisicoNome}.datiGeneraliBenefVita.datiGenerali;
      datiGeneraliBenefVitaNome.nome = action.unDato;
      benefVitaFisicoNome.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaNome};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoNome,
      };
    case actionType.UPDATE_COGNOME_BENEF_VITA_FISICO:
      const benefVitaFisicoCognome = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaCognome = {...benefVitaFisicoCognome}.datiGeneraliBenefVita.datiGenerali;
      datiGeneraliBenefVitaCognome.cognome = action.unDato;
      benefVitaFisicoCognome.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaCognome};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCognome,
      };    
    case actionType.UPDATE_SESSO_BENEF_VITA_FISICO:
      const benefVitaFisicoSesso = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaSesso = {...benefVitaFisicoSesso}.datiGeneraliBenefVita.datiGenerali;
      datiGeneraliBenefVitaSesso.sesso = action.unDato;
      benefVitaFisicoSesso.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaSesso};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoSesso,
      };   
    case actionType.UPDATE_DATA_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoDataNascita = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaDataNascita = {...benefVitaFisicoDataNascita}.datiGeneraliBenefVita.datiGenerali;
      datiGeneraliBenefVitaDataNascita.dataNascita = action.unDato;
      benefVitaFisicoDataNascita.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaDataNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoDataNascita,
      };       
    case actionType.UPDATE_STATO_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoStatoNascita = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaStatoNascita = {...benefVitaFisicoStatoNascita}.datiGeneraliBenefVita.datiGenerali;
      datiGeneraliBenefVitaStatoNascita.statoNascita = action.unDato.value;
      datiGeneraliBenefVitaStatoNascita.descrStatoNascita = action.unDato.label;
      benefVitaFisicoStatoNascita.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaStatoNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoStatoNascita,
      }; 
    case actionType.UPDATE_PROVINCIA_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoProvinciaNascita = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaProvinciaNascita = {...benefVitaFisicoProvinciaNascita}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaProvinciaNascita.provinciaNascita = action.unDato.value;
      datiGeneraliBenefVitaProvinciaNascita.descrProvinciaNascita = action.unDato.label;
      benefVitaFisicoProvinciaNascita.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaProvinciaNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoProvinciaNascita,
      };       
    case actionType.UPDATE_COMUNE_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoComuneNascita = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaComuneNascita = {...benefVitaFisicoComuneNascita}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaComuneNascita.comuneNascita = action.unDato.value;
      datiGeneraliBenefVitaComuneNascita.descrComuneNascita = action.unDato.label;
      benefVitaFisicoComuneNascita.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaComuneNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoComuneNascita,
      };           
    case actionType.UPDATE_CITTADINANZA_1_BENEF_VITA_FISICO:
      const benefVitaFisicoCittadinanza1 = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaCittadinanza1 = {...benefVitaFisicoCittadinanza1}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaCittadinanza1.cittadinanza1 = action.unDato.value;
      datiGeneraliBenefVitaCittadinanza1.descrCittadinanza1 = action.unDato.label;
      benefVitaFisicoCittadinanza1.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaCittadinanza1};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCittadinanza1,
      };      
    case actionType.UPDATE_CITTADINANZA_2_BENEF_VITA_FISICO:
      const benefVitaFisicoCittadinanza2 = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaCittadinanza2 = {...benefVitaFisicoCittadinanza2}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaCittadinanza2.cittadinanza2 = action.unDato.value;
      datiGeneraliBenefVitaCittadinanza2.descrCittadinanza2 = action.unDato.label;
      benefVitaFisicoCittadinanza2.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaCittadinanza2};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCittadinanza2,
      };     
    case actionType.UPDATE_NUM_TELEFONO_BENEF_VITA_FISICO:
      const benefVitaFisicoNumTelefono = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaNumTelefono = {...benefVitaFisicoNumTelefono}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaNumTelefono.numTelefono = action.unDato;
      benefVitaFisicoNumTelefono.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaNumTelefono};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoNumTelefono,
      };
*/
    case actionType.UPDATE_PERCENTUALE_BENEF_VITA_FISICO:
      const benefVitaFisicoPercentuale = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaPercentuale = {...benefVitaFisicoPercentuale}.datiGeneraliBenefVita;        
      datiGeneraliBenefVitaPercentuale.percBeneficiario = action.unDato;
      benefVitaFisicoPercentuale.datiGeneraliBenefVita = {...datiGeneraliBenefVitaPercentuale};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoPercentuale,
      };
    case actionType.UPDATE_BENE_DIVERSO_IMP_BENEF_VITA_FISICO:
      const benefVitaFisicoBenDiversoImpFisico = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaBenDiversoImpFisico = {...benefVitaFisicoBenDiversoImpFisico}.datiRelazContr;
      datiGeneraliBenefVitaBenDiversoImpFisico.benDiversoImp = action.unDato;
      benefVitaFisicoBenDiversoImpFisico.datiRelazContr = {...datiGeneraliBenefVitaBenDiversoImpFisico};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoBenDiversoImpFisico,
      }   
    case actionType.UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_FISICO:
        const benefVitaFisicoFlCopiaFigura = {...state}.benefVitaFisico;
        const datiCopiaDaAltraFiguraBenefVitaFisicoFlCopiaFigura = {...benefVitaFisicoFlCopiaFigura}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraBenefVitaFisicoFlCopiaFigura.flCopiaDaAltraFigura = action.unDato;
        benefVitaFisicoFlCopiaFigura.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraBenefVitaFisicoFlCopiaFigura};
        return {
          ...state,
          benefVitaFisico: benefVitaFisicoFlCopiaFigura,
        };
    case actionType.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_FISICO:
        const benefVitaFisicoCopiaFiguraFrom = {...state}.benefVitaFisico;
        const datiCopiaDaAltraFiguraCopiaBenefVitaFisicoFiguraFrom = {...benefVitaFisicoCopiaFiguraFrom}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraCopiaBenefVitaFisicoFiguraFrom.figuraFrom = unValore;
        datiCopiaDaAltraFiguraCopiaBenefVitaFisicoFiguraFrom.descrFiguraFrom = unaLabel;
        benefVitaFisicoCopiaFiguraFrom.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraCopiaBenefVitaFisicoFiguraFrom};
        return {
          ...state,
          benefVitaFisico: benefVitaFisicoCopiaFiguraFrom,
        };      
/* BENEFICIARIO VITA FISICO DATI GENERALI MINIMI*/
    case actionType.UPDATE_CODICE_FISCALE_BENEF_VITA_FISICO:
      const benefVitaFisicoFiscale = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaFiscale = {...benefVitaFisicoFiscale}.datiGeneraliBenefVita.datiGeneraliMinimi;
      datiGeneraliMinimiBenefVitaFiscale.codiceFiscale = action.unDato;
      benefVitaFisicoFiscale.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaFiscale};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoFiscale,
      };
    case actionType.UPDATE_NOME_BENEF_VITA_FISICO:
      const benefVitaFisicoNome = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaNome = {...benefVitaFisicoNome}.datiGeneraliBenefVita.datiGeneraliMinimi;
      datiGeneraliMinimiBenefVitaNome.nome = action.unDato;
      benefVitaFisicoNome.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaNome};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoNome,
      };
    case actionType.UPDATE_COGNOME_BENEF_VITA_FISICO:
      const benefVitaFisicoCognome = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaCognome = {...benefVitaFisicoCognome}.datiGeneraliBenefVita.datiGeneraliMinimi;
      datiGeneraliMinimiBenefVitaCognome.cognome = action.unDato;
      benefVitaFisicoCognome.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaCognome};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCognome,
      };    
    case actionType.UPDATE_SESSO_BENEF_VITA_FISICO:
      const benefVitaFisicoSesso = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaSesso = {...benefVitaFisicoSesso}.datiGeneraliBenefVita.datiGeneraliMinimi;
      datiGeneraliMinimiBenefVitaSesso.sesso = action.unDato;
      benefVitaFisicoSesso.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaSesso};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoSesso,
      };   
    case actionType.UPDATE_DATA_NASCITA_BENEF_VITA_FISICO:
      const unaDataNascitaMinimi = action.unDato; 
      const nuovaDataNascitaMinimiFormattata = dataFormat(unaDataNascitaMinimi, 'DD/MM/YYYY');    	
      const benefVitaFisicoDataNascita = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaDataNascita = {...benefVitaFisicoDataNascita}.datiGeneraliBenefVita.datiGeneraliMinimi;
      datiGeneraliMinimiBenefVitaDataNascita.dataNascita = unaDataNascitaMinimi;
      datiGeneraliMinimiBenefVitaDataNascita.dataNascitaStampa = nuovaDataNascitaMinimiFormattata;
      datiGeneraliMinimiBenefVitaDataNascita.statoNascita = '';
      datiGeneraliMinimiBenefVitaDataNascita.descrStatoNascita = '';
      datiGeneraliMinimiBenefVitaDataNascita.provinciaNascita = '';
      datiGeneraliMinimiBenefVitaDataNascita.descrProvinciaNascita = '';
      datiGeneraliMinimiBenefVitaDataNascita.comuneNascita = '';
      datiGeneraliMinimiBenefVitaDataNascita.descrComuneNascita = '';
      benefVitaFisicoDataNascita.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaDataNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoDataNascita,
      };       
    case actionType.UPDATE_STATO_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoStatoNascita = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaStatoNascita = {...benefVitaFisicoStatoNascita}.datiGeneraliBenefVita.datiGeneraliMinimi;
      datiGeneraliMinimiBenefVitaStatoNascita.statoNascita = unValore;
      datiGeneraliMinimiBenefVitaStatoNascita.descrStatoNascita = unaLabel;
      datiGeneraliMinimiBenefVitaStatoNascita.provinciaNascita = '';
      datiGeneraliMinimiBenefVitaStatoNascita.descrProvinciaNascita = '';
      datiGeneraliMinimiBenefVitaStatoNascita.comuneNascita = '';
      datiGeneraliMinimiBenefVitaStatoNascita.descrComuneNascita = '';
      benefVitaFisicoStatoNascita.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaStatoNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoStatoNascita,
      }; 
    case actionType.UPDATE_PROVINCIA_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoProvinciaNascita = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaProvinciaNascita = {...benefVitaFisicoProvinciaNascita}.datiGeneraliBenefVita.datiGeneraliMinimi;        
      datiGeneraliMinimiBenefVitaProvinciaNascita.provinciaNascita = unValore;
      datiGeneraliMinimiBenefVitaProvinciaNascita.descrProvinciaNascita = unaLabel;
      datiGeneraliMinimiBenefVitaProvinciaNascita.comuneNascita = '';
      datiGeneraliMinimiBenefVitaProvinciaNascita.descrComuneNascita = '';
      benefVitaFisicoProvinciaNascita.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaProvinciaNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoProvinciaNascita,
      };       
    case actionType.UPDATE_COMUNE_NASCITA_BENEF_VITA_FISICO:
      const benefVitaFisicoComuneNascita = {...state}.benefVitaFisico;
      const datiGeneraliMinimiBenefVitaComuneNascita = {...benefVitaFisicoComuneNascita}.datiGeneraliBenefVita.datiGeneraliMinimi;
      if (datiGeneraliMinimiBenefVitaComuneNascita.provinciaNascita !== PROVINCIA_ESTERA) {
    	  datiGeneraliMinimiBenefVitaComuneNascita.comuneNascita = unValore; // campo select 
    	  datiGeneraliMinimiBenefVitaComuneNascita.descrComuneNascita = unaLabel;
      } else {
    	  datiGeneraliMinimiBenefVitaComuneNascita.comuneNascita = ''; 
    	  datiGeneraliMinimiBenefVitaComuneNascita.descrComuneNascita = action.unDato; // campo testo
      }
      benefVitaFisicoComuneNascita.datiGeneraliBenefVita.datiGeneraliMinimi = {...datiGeneraliMinimiBenefVitaComuneNascita};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoComuneNascita,
      };
      /*
    case actionType.UPDATE_CITTADINANZA_1_BENEF_VITA_FISICO:
      const benefVitaFisicoCittadinanza1 = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaCittadinanza1 = {...benefVitaFisicoCittadinanza1}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaCittadinanza1.cittadinanza1 = action.unDato.value;
      datiGeneraliBenefVitaCittadinanza1.descrCittadinanza1 = action.unDato.label;
      benefVitaFisicoCittadinanza1.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaCittadinanza1};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCittadinanza1,
      };      
    case actionType.UPDATE_CITTADINANZA_2_BENEF_VITA_FISICO:
      const benefVitaFisicoCittadinanza2 = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaCittadinanza2 = {...benefVitaFisicoCittadinanza2}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaCittadinanza2.cittadinanza2 = action.unDato.value;
      datiGeneraliBenefVitaCittadinanza2.descrCittadinanza2 = action.unDato.label;
      benefVitaFisicoCittadinanza2.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaCittadinanza2};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCittadinanza2,
      };     
    case actionType.UPDATE_NUM_TELEFONO_BENEF_VITA_FISICO:
      const benefVitaFisicoNumTelefono = {...state}.benefVitaFisico;
      const datiGeneraliBenefVitaNumTelefono = {...benefVitaFisicoNumTelefono}.datiGeneraliBenefVita.datiGenerali;        
      datiGeneraliBenefVitaNumTelefono.numTelefono = action.unDato;
      benefVitaFisicoNumTelefono.datiGeneraliBenefVita.datiGenerali = {...datiGeneraliBenefVitaNumTelefono};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoNumTelefono,
      };
      */
/* BENEFICIARIO VITA FISICO DATI RESIDENZA */      
    case actionType.UPDATE_STATO_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoStatoResidenza = {...state}.benefVitaFisico;
      const datiResidenzaBenefVitaStatoResidenza = {...benefVitaFisicoStatoResidenza}.datiResidenza;
      datiResidenzaBenefVitaStatoResidenza.statoResidenza = unValore;
      datiResidenzaBenefVitaStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaBenefVitaStatoResidenza.provinciaResidenza = '';
      datiResidenzaBenefVitaStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaBenefVitaStatoResidenza.comuneResidenza = '';
      datiResidenzaBenefVitaStatoResidenza.descrComuneResidenza = '';
      datiResidenzaBenefVitaStatoResidenza.toponimoResidenza = '';
      datiResidenzaBenefVitaStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefVitaStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefVitaStatoResidenza.descrIndirizzoResidenza = '';
      benefVitaFisicoStatoResidenza.datiResidenza = {...datiResidenzaBenefVitaStatoResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoStatoResidenza,
      }; 
    case actionType.UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoProvinciaResidenza = {...state}.benefVitaFisico;
      const datiResidenzaBenefVitaProvinciaResidenza = {...benefVitaFisicoProvinciaResidenza}.datiResidenza;        
      datiResidenzaBenefVitaProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaBenefVitaProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaBenefVitaProvinciaResidenza.comuneResidenza = '';
      datiResidenzaBenefVitaProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaBenefVitaProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaBenefVitaProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefVitaProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefVitaProvinciaResidenza.descrIndirizzoResidenza = '';
      benefVitaFisicoProvinciaResidenza.datiResidenza = {...datiResidenzaBenefVitaProvinciaResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoProvinciaResidenza,
      };       
    case actionType.UPDATE_COMUNE_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoComuneResidenza = {...state}.benefVitaFisico;
      const datiResidenzaBenefVitaComuneResidenza = {...benefVitaFisicoComuneResidenza}.datiResidenza;
      if (datiResidenzaBenefVitaComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefVitaComuneResidenza.comuneResidenza = unValore; // campo select
	      datiResidenzaBenefVitaComuneResidenza.descrComuneResidenza = unaLabel;
	      datiResidenzaBenefVitaComuneResidenza.toponimoResidenza = '';
	      datiResidenzaBenefVitaComuneResidenza.descrToponimoResidenza = '';
//	      datiResidenzaBenefVitaComuneResidenza.indirizzoResidenza = '';
//	      datiResidenzaBenefVitaComuneResidenza.descrIndirizzoResidenza = '';
      } else {
	      datiResidenzaBenefVitaComuneResidenza.comuneResidenza = '';
    	  datiResidenzaBenefVitaComuneResidenza.descrComuneResidenza = action.unDato; // campo testo
      }
      benefVitaFisicoComuneResidenza.datiResidenza = {...datiResidenzaBenefVitaComuneResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoComuneResidenza,
      };           
    case actionType.UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoToponimoResidenza = {...state}.benefVitaFisico;
      const datiResidenzaBenefVitaToponimoResidenza = {...benefVitaFisicoToponimoResidenza}.datiResidenza;   
      if (datiResidenzaBenefVitaToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefVitaToponimoResidenza.toponimoResidenza = unValore; // campo select
	      datiResidenzaBenefVitaToponimoResidenza.descrToponimoResidenza = unaLabel;	      
	      //datiResidenzaBenefVitaToponimoResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefVitaToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaBenefVitaToponimoResidenza.toponimoResidenza = '';
    	  datiResidenzaBenefVitaToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo
      }
      benefVitaFisicoToponimoResidenza.datiResidenza = {...datiResidenzaBenefVitaToponimoResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoToponimoResidenza,
      };         
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoIndirizzoResidenza = {...state}.benefVitaFisico; 
      const datiResidenzaBenefVitaIndirizzoResidenza = {...benefVitaFisicoIndirizzoResidenza}.datiResidenza;
      //datiResidenzaBenefVitaIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaBenefVitaIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaBenefVitaIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      benefVitaFisicoIndirizzoResidenza.datiResidenza = {...datiResidenzaBenefVitaIndirizzoResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoIndirizzoResidenza,
      };     
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoNumeroCivicoResidenza = {...state}.benefVitaFisico;
      const datiResidenzaBenefVitaNumeroCivicoResidenza = {...benefVitaFisicoNumeroCivicoResidenza}.datiResidenza;
      datiResidenzaBenefVitaNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      benefVitaFisicoNumeroCivicoResidenza.datiResidenza = {...datiResidenzaBenefVitaNumeroCivicoResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoNumeroCivicoResidenza,
      };       
    case actionType.UPDATE_CAP_RESIDENZA_BENEF_VITA_FISICO:
      const benefVitaFisicoCapResidenza = {...state}.benefVitaFisico;
      const datiResidenzaBenefVitaCapResidenza = {...benefVitaFisicoCapResidenza}.datiResidenza;
      datiResidenzaBenefVitaCapResidenza.capResidenza = action.unDato;
      benefVitaFisicoCapResidenza.datiResidenza = {...datiResidenzaBenefVitaCapResidenza};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCapResidenza,
      };
/* BENEFICIARIO VITA FISICO DATI ANTIRICICLAGGIO */      
    case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_BENEF_VITA_FISICO:
      const benefVitaFisicoFlEspostoPoliticamente = {...state}.benefVitaFisico;
      const datiAntiriciclaggioBenefVitaFlEspostoPoliticamente = {...benefVitaFisicoFlEspostoPoliticamente}.datiAntiriciclaggio;
      datiAntiriciclaggioBenefVitaFlEspostoPoliticamente.espostaPoliticamente = action.unDato;
      if (datiAntiriciclaggioBenefVitaFlEspostoPoliticamente.espostaPoliticamente === 'N'){
    	  datiAntiriciclaggioBenefVitaFlEspostoPoliticamente.isInCarica = '';
    	  datiAntiriciclaggioBenefVitaFlEspostoPoliticamente.caricaPersonaEsposta = '';
      }
      benefVitaFisicoFlEspostoPoliticamente.datiAntiriciclaggio = {...datiAntiriciclaggioBenefVitaFlEspostoPoliticamente};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoFlEspostoPoliticamente,
      };
    case actionType.UPDATE_CARICA_PEP_BENEF_VITA_FISICO:
      const benefVitaFisicoCaricaPersonaEsposta = {...state}.benefVitaFisico;
      const datiAntiriciclaggioBenefVitaCaricaPersonaEsposta = {...benefVitaFisicoCaricaPersonaEsposta}.datiAntiriciclaggio;
      datiAntiriciclaggioBenefVitaCaricaPersonaEsposta.caricaPersonaEsposta = action.unDato;
      benefVitaFisicoCaricaPersonaEsposta.datiAntiriciclaggio = {...datiAntiriciclaggioBenefVitaCaricaPersonaEsposta};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCaricaPersonaEsposta,
      };
    case actionType.UPDATE_CARICA_PUBBLICA_NO_PEP_BENEF_VITA_FISICO:
      const benefVitaFisicoCaricaPubblicaNoPEP = {...state}.benefVitaFisico;
      const datiAntiriciclaggioBenefVitaCaricaPubblicaNoPEP = {...benefVitaFisicoCaricaPubblicaNoPEP}.datiAntiriciclaggio;
      datiAntiriciclaggioBenefVitaCaricaPubblicaNoPEP.caricaPubblicaNoPEP = action.unDato;
      benefVitaFisicoCaricaPubblicaNoPEP.datiAntiriciclaggio = {...datiAntiriciclaggioBenefVitaCaricaPubblicaNoPEP};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoCaricaPubblicaNoPEP,
      };      
/* BENEFICIARIO VITA FISICO DATI RELAZ CONTRAENTE */      
    case actionType.UPDATE_EMAIL_BENEF_VITA_FISICO:
      const benefVitaFisicoRelazContrEmail = {...state}.benefVitaFisico;
      const datiRelazContrBenefVitaEmail = {...benefVitaFisicoRelazContrEmail}.datiRelazContr;
      datiRelazContrBenefVitaEmail.email = action.unDato;
      benefVitaFisicoRelazContrEmail.datiRelazContr = {...datiRelazContrBenefVitaEmail};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoRelazContrEmail,
      }; 
    case actionType.UPDATE_RELAZ_CONTR_BENEF_VITA_FISICO:
      const benefVitaFisicoRelazContrRelazContr = {...state}.benefVitaFisico;
      const datiRelazContrBenefVitaRelazContr = {...benefVitaFisicoRelazContrRelazContr}.datiRelazContr;
      datiRelazContrBenefVitaRelazContr.relazContraente = unValore;
      datiRelazContrBenefVitaRelazContr.descrRelazContraente = unaLabel;
      datiRelazContrBenefVitaRelazContr.altro = '';
      benefVitaFisicoRelazContrRelazContr.datiRelazContr = {...datiRelazContrBenefVitaRelazContr};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoRelazContrRelazContr,
      };   
    case actionType.UPDATE_ESCLUS_COMUNIC_BENEF_VITA_FISICO:
      const benefVitaFisicoRelazContrEsclusioneComunicaz = {...state}.benefVitaFisico;
      const datiRelazContrBenefVitaEsclusioneComunicaz = {...benefVitaFisicoRelazContrEsclusioneComunicaz}.datiRelazContr;
      datiRelazContrBenefVitaEsclusioneComunicaz.esclusioneComunicaz = action.unDato;
      benefVitaFisicoRelazContrEsclusioneComunicaz.datiRelazContr = {...datiRelazContrBenefVitaEsclusioneComunicaz};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoRelazContrEsclusioneComunicaz,
      };
    case actionType.UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_FISICO:
      const benefVitaFisicoRelazContrAltro = {...state}.benefVitaFisico;
      const datiRelazContrBenefVitaAltro = {...benefVitaFisicoRelazContrAltro}.datiRelazContr;
      datiRelazContrBenefVitaAltro.altro = action.unDato;
      benefVitaFisicoRelazContrAltro.datiRelazContr = {...datiRelazContrBenefVitaAltro};
      return {
        ...state,
        benefVitaFisico: benefVitaFisicoRelazContrAltro,
      }

/* SALVA BENEFICIARIO VITA GIURIDICO */  
    case actionType.SALVA_BENEF_VITA_GIURIDICO:
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.SALVA_BENEF_VITA_GIURIDICO.actionType.SALVA_BENEF_VITA_GIURIDICO', actionType.SALVA_BENEF_VITA_GIURIDICO); 
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.SALVA_BENEF_VITA_GIURIDICO.state', state);
      console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
      const benefVitaGiuridicoSalva = {...state}.benefVitaGiuridico;
      benefVitaGiuridicoSalva.controlloDati = action.unDato;
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoSalva,
      };
    case actionType.SALVA_BENEF_VITA_GIURIDICO_SUCCESS: 
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.SALVA_BENEF_VITA_GIURIDICO_SUCCESS.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);    
        return {
	      	...state,
	      	salvaBenefVitaGiuridicoSuccess: action.unDato,
        };  
    case actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GIURIDICO:
      console.log('#RT2 Reducer areaBenefVitaReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GIURIDICO);
      console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
      const benefVitaGiuridicoAnagraficaValida = {...state}.benefVitaGiuridico;
      benefVitaGiuridicoAnagraficaValida.anagraficaValida = action.unDato;
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoAnagraficaValida,
      };
    case actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GIURIDICO:      
//        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GIURIDICO.state', state);
//        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const beneficiarioVitaGiuridicoConfermaAggiungi = action.beneficiarioVitaGiuridico;
        const dataNascitaBeneficiarioVitaGiuridicoConfermaAggiungi = beneficiarioVitaGiuridicoConfermaAggiungi.datiGeneraliBenefGiuridico.datiGenerali.dataNascita;
        const cloneBeneficiarioVitaGiuridico = jsonCopy(beneficiarioVitaGiuridicoConfermaAggiungi);
        // reimposoto la data di nascita
        cloneBeneficiarioVitaGiuridico.datiGeneraliBenefGiuridico.datiGenerali.dataNascita=dataNascitaBeneficiarioVitaGiuridicoConfermaAggiungi;      
        const beneficiarioVitaGiuridicoListConfermaAggiungi = {...state}.benefVitaGiuridicoList;
        const beneficiarioVitaGiuridicoListConfermaAggiungiNuovo = [...beneficiarioVitaGiuridicoListConfermaAggiungi, cloneBeneficiarioVitaGiuridico];                
        return {
          ...state,
//          benefVitaGiuridico: cloneBeneficiarioVitaGiuridico,
          benefVitaGiuridicoList: beneficiarioVitaGiuridicoListConfermaAggiungiNuovo,
        };
        case actionType.AGGIUNGI_TITOLARE_BENEF_VITA_GIURIDICO:
          const benefVitaListTitGiur = {...state}.benefVitaGiuridicoList;
          const benefVitaGiuridicoAggTit = {...state}.benefVitaGiuridico;
          const cloneTitEffBenefVitaInBenef = jsonCopy(action.currTitolareEff);
          benefVitaListTitGiur.map((el, key) => {
            if(el.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === benefVitaGiuridicoAggTit.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale) {
              const titEffListInBenef = el.titolareEffBenefVitaListInBenef;
              const titEffListAggiungi = [...titEffListInBenef, cloneTitEffBenefVitaInBenef];
              const benefAggiungiTitolare = {...el};
              benefAggiungiTitolare.titolareEffBenefVitaListInBenef = titEffListAggiungi;
              benefVitaListTitGiur[key] = benefAggiungiTitolare;
            }
          });
          return {
            ...state,
            benefVitaGiuridicoList: benefVitaListTitGiur
          }
    case actionType.NUOVO_BENEFICIARIO_VITA_GIURIDICO:      
//        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.AGGIUNGI_BENEFICIARIO_VITA_GIURIDICO.state', state);
//        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        //const beneficiarioVitaGiuridicoNuovo = action.beneficiarioVitaGiuridico;       
        const beneficiarioVitaGiuridicoNuovo = inizializzaBeneficiarioVitaGiuridico(state);
        return {
          ...state,
          benefVitaGiuridico: beneficiarioVitaGiuridicoNuovo,
          salvaBenefVitaGiuridicoSuccess: false,
        };        
    case actionType.UPDATE_BENEF_VITA_GIURIDICO:      
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.AGGIUNGI_BENEFICIARIO_VITA_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const beneficiarioVitaGiuridicoUpdate = action.currBenefVitaGiuridico;
        const dataNascitaBeneficiarioVitaGiuridicoUpdate = beneficiarioVitaGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.dataNascita;
        const cloneBeneficiarioVitaGiuridicoUpdate = jsonCopy(beneficiarioVitaGiuridicoUpdate);
        // reimposoto la data di nascita
        cloneBeneficiarioVitaGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.dataNascita=dataNascitaBeneficiarioVitaGiuridicoUpdate;
        const beneficiarioVitaGiuridicoCodiceFiscale = cloneBeneficiarioVitaGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale;
        const beneficiarioVitaFisicoRagioneSociale = cloneBeneficiarioVitaGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale;
        const beneficiarioVitaGiuridicoListUpdate = [...state.benefVitaGiuridicoList];
        
        let indexBeneficiarioVitaGiuridicoUpdate = undefined;        
        beneficiarioVitaGiuridicoListUpdate.forEach( (item, index, array) => {
        	if (cloneBeneficiarioVitaGiuridicoUpdate.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!=='S') {        		
	        	if (beneficiarioVitaGiuridicoCodiceFiscale===item.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale){
	        		indexBeneficiarioVitaGiuridicoUpdate = index;
	        	}
        	} else {
        		// Cerco per cognome
	        	if (beneficiarioVitaFisicoRagioneSociale===item.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale){
	        		indexBeneficiarioVitaGiuridicoUpdate = index;
	        	}
        	}
        })
        
        beneficiarioVitaGiuridicoListUpdate[indexBeneficiarioVitaGiuridicoUpdate] = cloneBeneficiarioVitaGiuridicoUpdate;

        return {
          ...state,
         	benefVitaGiuridicoList: beneficiarioVitaGiuridicoListUpdate,
        };   
	case actionType.DELETE_BENEF_VITA_GIURIDICO:
	  let benVitaGiuridicoToDelete = action.benefVitaGiuridicoDelete;
	  const percBenefVitaGiuridicoDelete = benVitaGiuridicoToDelete.datiGeneraliBenefGiuridico.percBeneficiario;
	  
	  const filterdBenVitaGiuridicoDeleteList = state.benefVitaGiuridicoList.filter( item => {
		const codiceFiscale = item.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale;   			
		return codiceFiscale!==benVitaGiuridicoToDelete.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale 
    });
    benefVitaGiuridico.anagraficaValida = false;
	  return {
	    ...state,
	    percBenVitaTotale: state.percBenVitaTotale - percBenefVitaGiuridicoDelete, // ricalcolo la percentuale Totale
      benefVitaGiuridicoList: filterdBenVitaGiuridicoDeleteList,
      benefVitaGiuridico: benefVitaGiuridico
	  };
/* BENEFICIARIO VITA GIURIDICO DATI GENERALI */
    case actionType.UPDATE_RAGIONE_SOCIALE_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoRagioneSociale = {...state}.benefVitaGiuridico;
      const datiGeneraliBenefVitaGiuridicoRagioneSociale = {...benefVitaGiuridicoRagioneSociale}.datiGeneraliBenefGiuridico.datiGenerali;
      datiGeneraliBenefVitaGiuridicoRagioneSociale.ragioneSociale = action.unDato;
      benefVitaGiuridicoRagioneSociale.datiGeneraliBenefGiuridico.datiGenerali = {...datiGeneraliBenefVitaGiuridicoRagioneSociale};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoRagioneSociale,
      };  
    case actionType.UPDATE_CODICE_FISCALE_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoFiscale = {...state}.benefVitaGiuridico;
      const datiGeneraliBenefVitaGiuridicoFiscale = {...benefVitaGiuridicoFiscale}.datiGeneraliBenefGiuridico.datiGenerali;
      datiGeneraliBenefVitaGiuridicoFiscale.codiceFiscale = action.unDato;
      benefVitaGiuridicoFiscale.datiGeneraliBenefGiuridico.datiGenerali = {...datiGeneraliBenefVitaGiuridicoFiscale};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoFiscale,
      };
    case actionType.UPDATE_DATA_NASCITA_BENEF_VITA_GIURIDICO:
      const unaDataNascitaGiuridica = action.unDato;    	
      const nuovaDataNascitaGiuridicaFormattata = dataFormat(unaDataNascitaGiuridica, 'DD/MM/YYYY');
      const benefVitaGiuridicoDataNascita = {...state}.benefVitaGiuridico;
      const datiGeneraliBenefVitaGiuridicoDataNascita = {...benefVitaGiuridicoDataNascita}.datiGeneraliBenefGiuridico.datiGenerali;
      datiGeneraliBenefVitaGiuridicoDataNascita.dataNascita = unaDataNascitaGiuridica;
      datiGeneraliBenefVitaGiuridicoDataNascita.dataNascitaStampa = nuovaDataNascitaGiuridicaFormattata;
      benefVitaGiuridicoDataNascita.datiGeneraliBenefGiuridico.datiGenerali = {...datiGeneraliBenefVitaGiuridicoDataNascita};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoDataNascita,
      };
    case actionType.UPDATE_PERCENTUALE_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoPercentuale = {...state}.benefVitaGiuridico;
      const datiGeneraliBenefVitaGiuridicoPercentuale = {...benefVitaGiuridicoPercentuale}.datiGeneraliBenefGiuridico;        
      datiGeneraliBenefVitaGiuridicoPercentuale.percBeneficiario = action.unDato;
      benefVitaGiuridicoPercentuale.datiGeneraliBenefGiuridico = {...datiGeneraliBenefVitaGiuridicoPercentuale};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoPercentuale,
      };
    case actionType.UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_GIURIDICO:
        const benefVitaGiuridicoFlCopiaFigura = {...state}.benefVitaGiuridico;
        const datiCopiaDaAltraFiguraBenefVitaGiuridicoFlCopiaFigura = {...benefVitaGiuridicoFlCopiaFigura}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraBenefVitaGiuridicoFlCopiaFigura.flCopiaDaAltraFigura = action.unDato;
        benefVitaGiuridicoFlCopiaFigura.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraBenefVitaGiuridicoFlCopiaFigura};
        return {
          ...state,
          benefVitaGiuridico: benefVitaGiuridicoFlCopiaFigura,
        };
    case actionType.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_GIURIDICO:
        const benefVitaGiuridicoCopiaFiguraFrom = {...state}.benefVitaGiuridico;
        const datiCopiaDaAltraFiguraCopiaBenefVitaGiuridicoFiguraFrom = {...benefVitaGiuridicoCopiaFiguraFrom}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraCopiaBenefVitaGiuridicoFiguraFrom.figuraFrom = unValore;  
        datiCopiaDaAltraFiguraCopiaBenefVitaGiuridicoFiguraFrom.descrFiguraFrom = unaLabel;
        datiCopiaDaAltraFiguraCopiaBenefVitaGiuridicoFiguraFrom.isFiduciaria = action.isFiduciaria;
        benefVitaGiuridicoCopiaFiguraFrom.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraCopiaBenefVitaGiuridicoFiguraFrom};
        return {
          ...state,
          benefVitaGiuridico: benefVitaGiuridicoCopiaFiguraFrom,
        };
    case actionType.UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_VITA_GIUR:
        const benefVitaGiuridicoBenefDiversoImp = {...state}.benefVitaGiuridico;
        const datiBenefDiversoImpresa = {...benefVitaGiuridicoBenefDiversoImp}.datiRelazContr;
        datiBenefDiversoImpresa.benDiversoImp = action.unDato;
        benefVitaGiuridicoBenefDiversoImp.datiRelazContr = {...datiBenefDiversoImpresa};
        return {
          ...state,
          benefVitaGiuridico:benefVitaGiuridicoBenefDiversoImp,
        }; 
    /* case actionType.TITOLARI_EFFETTIVI_BENEFICIARI_VITA:
      const benefVitaTitolariEffettivi = {...state}.benefVitaGiuridico;
      benefVitaTitolariEffettivi.flTitolareEffBenefVita = action.unDato;
      return{
        ...state,
        benefVitaGiuridico: benefVitaTitolariEffettivi,
      }; */
    

        /* DATI SOCIETARI */
    case actionType.ISCRIZIONE_REGISTRO_BENEF_VITA_GIURIDICO:
      const benefVitaRegistroBenef = {...state}.benefVitaGiuridico;
      const benefVitaRegistroDati = {...benefVitaRegistroBenef}.datiSocietari;
      benefVitaRegistroDati.codTipoIscrRegistro = unValore;
      benefVitaRegistroDati.descTipoIscrRegistro = unaLabel;
      benefVitaRegistroDati.numeroRegistro = '';
      benefVitaRegistroBenef.datiSocietari = {...benefVitaRegistroDati};
      return {
        ...state,
        benefVitaGiuridico: benefVitaRegistroBenef
      }
    case actionType.NUMERO_REGISTRO_BENEF_VITA_GIURIDICO:
      const benefVitaNumeroBenef = {...state}.benefVitaGiuridico;
      const benefVitaNumeroDati = {...benefVitaNumeroBenef}.datiSocietari;
      benefVitaNumeroDati.numeroRegistro = action.unDato;
      benefVitaNumeroBenef.datiSocietari = {...benefVitaNumeroDati};
      return {
        ...state,
        benefVitaGiuridico: benefVitaNumeroBenef
      }
      /* DATI MANDATO FIDUCIARIO */
    case actionType.UPDATE_MANDATO_BENEF_VITA_GIURIDICO:
      const benefVitaMandatoBenef = {...state}.benefVitaGiuridico;
      const benefVitaMandatoDati = {...benefVitaMandatoBenef}.datiMandatoFiduciario;
      benefVitaMandatoDati.numeroMandatoFid = action.unDato;
      benefVitaMandatoBenef.datiMandatoFiduciario = {...benefVitaMandatoDati};
      return {
        ...state,
        benefVitaGiuridico: benefVitaMandatoBenef
      }
    case actionType.UPDATE_INTESTATARIO_MANDATO_BENEF_VITA_GIURIDICO:
      const benefVitaIntestBenef = {...state}.benefVitaGiuridico;
      const benefVitaIntestDati = {...benefVitaIntestBenef}.datiMandatoFiduciario;
      benefVitaIntestDati.intestatarioMandato = action.unDato;
      benefVitaIntestBenef.datiMandatoFiduciario = {...benefVitaIntestDati};
      return {
        ...state,
        benefVitaGiuridico: benefVitaIntestBenef
      }
/* BENEFICIARIO VITA GIURIDICO DATI RESIDENZA */      
    case actionType.UPDATE_STATO_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoStatoResidenza = {...state}.benefVitaGiuridico;
      const datiResidenzaBenefVitaGiuridicoStatoResidenza = {...benefVitaGiuridicoStatoResidenza}.datiResidenza;
      datiResidenzaBenefVitaGiuridicoStatoResidenza.statoResidenza = unValore;
      datiResidenzaBenefVitaGiuridicoStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaBenefVitaGiuridicoStatoResidenza.provinciaResidenza = '';
      datiResidenzaBenefVitaGiuridicoStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaBenefVitaGiuridicoStatoResidenza.comuneResidenza = '';
      datiResidenzaBenefVitaGiuridicoStatoResidenza.descrComuneResidenza = '';
      datiResidenzaBenefVitaGiuridicoStatoResidenza.toponimoResidenza = '';
      datiResidenzaBenefVitaGiuridicoStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefVitaGiuridicoStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefVitaGiuridicoStatoResidenza.descrIndirizzoResidenza = '';
      benefVitaGiuridicoStatoResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoStatoResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoStatoResidenza,
      }; 
    case actionType.UPDATE_PROVINCIA_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoProvinciaResidenza = {...state}.benefVitaGiuridico;
      const datiResidenzaBenefVitaGiuridicoProvinciaResidenza = {...benefVitaGiuridicoProvinciaResidenza}.datiResidenza;        
      datiResidenzaBenefVitaGiuridicoProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaBenefVitaGiuridicoProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaBenefVitaGiuridicoProvinciaResidenza.comuneResidenza = '';
      datiResidenzaBenefVitaGiuridicoProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaBenefVitaGiuridicoProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaBenefVitaGiuridicoProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefVitaGiuridicoProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefVitaGiuridicoProvinciaResidenza.descrIndirizzoResidenza = '';
      benefVitaGiuridicoProvinciaResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoProvinciaResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoProvinciaResidenza,
      };       
    case actionType.UPDATE_COMUNE_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoComuneResidenza = {...state}.benefVitaGiuridico;
      const datiResidenzaBenefVitaGiuridicoComuneResidenza = {...benefVitaGiuridicoComuneResidenza}.datiResidenza;
      if (datiResidenzaBenefVitaGiuridicoComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefVitaGiuridicoComuneResidenza.comuneResidenza = unValore; // campo select 
	      datiResidenzaBenefVitaGiuridicoComuneResidenza.descrComuneResidenza = unaLabel;
	      datiResidenzaBenefVitaGiuridicoComuneResidenza.toponimoResidenza = '';
	      datiResidenzaBenefVitaGiuridicoComuneResidenza.descrToponimoResidenza = '';
	      //datiResidenzaBenefVitaGiuridicoComuneResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefVitaGiuridicoComuneResidenza.descrIndirizzoResidenza = '';
      } else {
	      datiResidenzaBenefVitaGiuridicoComuneResidenza.comuneResidenza = '';
    	  datiResidenzaBenefVitaGiuridicoComuneResidenza.descrComuneResidenza = action.unDato; // campo testo 
      }
      benefVitaGiuridicoComuneResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoComuneResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoComuneResidenza,
      };           
    case actionType.UPDATE_TOPONIMO_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoToponimoResidenza = {...state}.benefVitaGiuridico;
      const datiResidenzaBenefVitaGiuridicoToponimoResidenza = {...benefVitaGiuridicoToponimoResidenza}.datiResidenza;
      if (datiResidenzaBenefVitaGiuridicoToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefVitaGiuridicoToponimoResidenza.toponimoResidenza = unValore; // campo select 
	      datiResidenzaBenefVitaGiuridicoToponimoResidenza.descrToponimoResidenza = unaLabel;
	      //datiResidenzaBenefVitaGiuridicoToponimoResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefVitaGiuridicoToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaBenefVitaGiuridicoToponimoResidenza.toponimoResidenza = '';
	      datiResidenzaBenefVitaGiuridicoToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo 
      }
      benefVitaGiuridicoToponimoResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoToponimoResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoToponimoResidenza,
      };         
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoIndirizzoResidenza = {...state}.benefVitaGiuridico; 
      const datiResidenzaBenefVitaGiuridicoIndirizzoResidenza = {...benefVitaGiuridicoIndirizzoResidenza}.datiResidenza;
      //datiResidenzaBenefVitaGiuridicoIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaBenefVitaGiuridicoIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaBenefVitaGiuridicoIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      benefVitaGiuridicoIndirizzoResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoIndirizzoResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoIndirizzoResidenza,
      };     
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoNumeroCivicoResidenza = {...state}.benefVitaGiuridico;
      const datiResidenzaBenefVitaGiuridicoNumeroCivicoResidenza = {...benefVitaGiuridicoNumeroCivicoResidenza}.datiResidenza;
      datiResidenzaBenefVitaGiuridicoNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      benefVitaGiuridicoNumeroCivicoResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoNumeroCivicoResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoNumeroCivicoResidenza,
      };       
    case actionType.UPDATE_CAP_RESIDENZA_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoCapResidenza = {...state}.benefVitaGiuridico;
      const datiResidenzaBenefVitaGiuridicoCapResidenza = {...benefVitaGiuridicoCapResidenza}.datiResidenza;
      datiResidenzaBenefVitaGiuridicoCapResidenza.capResidenza = action.unDato;
      benefVitaGiuridicoCapResidenza.datiResidenza = {...datiResidenzaBenefVitaGiuridicoCapResidenza};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoCapResidenza,
      };
    case actionType.UPDATE_EMAIL_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoRelazContrEmail = {...state}.benefVitaGiuridico;
      const datiRelazContrBenefVitaGiuridicoEmail = {...benefVitaGiuridicoRelazContrEmail}.datiRelazContr;
      datiRelazContrBenefVitaGiuridicoEmail.email = action.unDato;
      benefVitaGiuridicoRelazContrEmail.datiRelazContr = {...datiRelazContrBenefVitaGiuridicoEmail};
      return {
        ...state,
        benefMorteGiuridico: benefVitaGiuridicoRelazContrEmail,
      }; 
    case actionType.UPDATE_RELAZ_CONTR_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoRelazContrRelazContr = {...state}.benefVitaGiuridico;
      const datiRelazContrBenefVitaGiuridicoRelazContr = {...benefVitaGiuridicoRelazContrRelazContr}.datiRelazContr;
      datiRelazContrBenefVitaGiuridicoRelazContr.relazContraente = unValore;
      datiRelazContrBenefVitaGiuridicoRelazContr.descrRelazContraente = unaLabel;
      datiRelazContrBenefVitaGiuridicoRelazContr.altro = '';
      datiRelazContrBenefVitaGiuridicoRelazContr.benDiversoImp = '';
      benefVitaGiuridicoRelazContrRelazContr.datiRelazContr = {...datiRelazContrBenefVitaGiuridicoRelazContr};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoRelazContrRelazContr,
      };   
    case actionType.UPDATE_ALTRO_RELAZ_CONTR_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoAltroRelaz = {...state}.benefVitaGiuridico;
      const datiRelazContrBenefVitaGiuAltroRelaz = {...benefVitaGiuridicoAltroRelaz}.datiRelazContr;
      datiRelazContrBenefVitaGiuAltroRelaz.altro = action.unDato;
      benefVitaGiuridicoAltroRelaz.datiRelazContr = {...datiRelazContrBenefVitaGiuAltroRelaz};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoAltroRelaz
      }
    case actionType.UPDATE_ESCLUS_COMUNIC_BENEF_VITA_GIURIDICO:
      const benefVitaGiuridicoRelazContrEsclusioneComunicaz = {...state}.benefVitaGiuridico;
      const datiRelazContrBenefVitaGiuridicoEsclusioneComunicaz = {...benefVitaGiuridicoRelazContrEsclusioneComunicaz}.datiRelazContr;
      datiRelazContrBenefVitaGiuridicoEsclusioneComunicaz.esclusioneComunicaz = action.unDato;
      benefVitaGiuridicoRelazContrEsclusioneComunicaz.datiRelazContr = {...datiRelazContrBenefVitaGiuridicoEsclusioneComunicaz};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiuridicoRelazContrEsclusioneComunicaz,
      };        
/* BENEFICIARIO VITA GENERICO */  
    case actionType.SALVA_BENEF_VITA_GENERICO:
//      console.log('#RT2 Reducer areaBenefVitaReducer SALVA_BENEF_VITA_GENERICO', actionType.SALVA_BENEF_VITA_GENERICO); 
      console.log('#RT2 Reducer areaBenefVitaReducer action', action);  
      const benefVitaGenericoSalva = {...state}.benefVitaGenerico;
      benefVitaGenericoSalva.controlloDati = action.unDato;
      return {
    	...state,
    	benefVitaGenerico: benefVitaGenericoSalva,
      };
    case actionType.SALVA_BENEF_VITA_GENERICO_SUCCESS: 
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.SALVA_BENEF_VITA_GENERICO_SUCCESS.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);    
        return {
	      	...state,
	      	salvaBenefVitaGenericoSuccess: action.unDato,
        };  
    case actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GENERICO:
      console.log('#RT2 Reducer areaBenefVitaReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GENERICO);
      console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
      const benefVitaGenericoAnagraficaValida = {...state}.benefVitaGenerico;
      benefVitaGenericoAnagraficaValida.anagraficaValida = action.unDato;
      return {
        ...state,
        benefVitaGenerico: benefVitaGenericoAnagraficaValida,
      };
/*      
    case actionType.AGGIUNGI_BENEF_VITA_GENERICO:
//      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.AGGIUNGI_BENEF_VITA_GENERICO.actionType.AGGIUNGI_BENEF_VITA_GENERICO', actionType.AGGIUNGI_BENEF_VITA_GENERICO); 
      console.log('#RT2 Reducer areaBenefVitaReducer.actionType.AGGIUNGI_BENEF_VITA_GENERICO.state', state);
      console.log('#RT2 Reducer areaBenefVitaReducer.action', action);    
      const benefVitaGenerico = action.benefVitaGenerico;
      const beneficiarioVitaGenericoList = {...state}.benefVitaGenericoList;
      let beneficiarioVitaGenericoListNuovo = {...state}.beneficiarioVitaGenericoList;
      
//      if (beneficiarioVitaGenericoList!==undefined&&beneficiarioVitaGenericoList!=null&&beneficiarioVitaGenericoList.length===0) {
    	  beneficiarioVitaGenericoListNuovo = [...beneficiarioVitaGenericoList, benefVitaGenerico];
//      }
      return {
        ...state,
        benefVitaGenerico: benefVitaGenerico,
        benefVitaGenericoList: beneficiarioVitaGenericoListNuovo,
      };
*/      
    case actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GENERICO:      
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_VITA_GENERICO.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const cloneBeneficiarioVitaGenerico = jsonCopy(action.beneficiarioVitaGenerico);
        const beneficiarioVitaGenericoListConfermaAggiungi = {...state}.benefVitaGenericoList;
        const beneficiarioVitaGenericoListConfermaAggiungiNuovo = [...beneficiarioVitaGenericoListConfermaAggiungi, cloneBeneficiarioVitaGenerico];
                
        return {
          ...state,
//          benefVitaGenerico: cloneBeneficiarioVitaGenerico,
          benefVitaGenericoList: beneficiarioVitaGenericoListConfermaAggiungiNuovo,
        };
    case actionType.UPDATE_BENEFICIARIO_VITA_GENERICO:
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.UPDATE_BENEFICIARIO_VITA_GENERICO.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const cloneBeneficiarioVitaGenericoUpdate = jsonCopy(action.currBenefVitaGenerico);
        const beneficiarioVitaGenericoListUpdateVita = [...state.benefVitaGenericoList];
        beneficiarioVitaGenericoListUpdateVita[0] = cloneBeneficiarioVitaGenericoUpdate;
        return {
          ...state,
         	benefVitaGenericoList: beneficiarioVitaGenericoListUpdateVita,
        };
    case actionType.DELETE_BENEF_VITA_GENERICO:
    	const benVitaGenericoDelete = action.benefVitaGenericoDelete;
    	const percBenefVitaGenericoDelete = benVitaGenericoDelete.datiGenerali.percBeneficiario;
	  	return {
	  	    ...state,
	  	     percBenVitaTotale: state.percBenVitaTotale - percBenefVitaGenericoDelete, // ricalcolo la percentuale Totale
	  	    benefVitaGenericoList: [],
      };  
/* ATTIVITA ECONOMICA GIURIDICO */
    case actionType.UPDATE_ATT_PREVAL_SVOLTA_VITA_GIUR:
      const benefVitaGiurAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaAttPrevalSvolta = {...benefVitaGiurAttPrevalSvolta}.datiAttEconomica;
      datiAttEconomicaAttPrevalSvolta.codAttPrevalSvolta = unValore;
      datiAttEconomicaAttPrevalSvolta.descrAttPrevalSvolta = unaLabel;  
      datiAttEconomicaAttPrevalSvolta.codSAE = '';
      datiAttEconomicaAttPrevalSvolta.descrSAE = '';
      datiAttEconomicaAttPrevalSvolta.altroAttPrevAtt = '';
      benefVitaGiurAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurAttPrevalSvolta,
      }
    case actionType.UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT_VITA_GIURIDICO:
      const benefVitaGiurAltroAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaAltroAttPrevalSvolta = {...benefVitaGiurAltroAttPrevalSvolta}.datiAttEconomica;
      datiAttEconomicaAltroAttPrevalSvolta.altroAttPrevAtt = action.unDato;
      benefVitaGiurAltroAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaAltroAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurAltroAttPrevalSvolta,
      }
    case actionType.UPDATE_STAT_ATT_PREV_SVOLTA_VITA_GIURIDICO:
      const benefVitaGiurStatoAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaStatoAttPrevalSvolta = {...benefVitaGiurStatoAttPrevalSvolta}.datiAttEconomica;
      datiAttEconomicaStatoAttPrevalSvolta.statoAttPrevalSvolta = unValore;
      datiAttEconomicaStatoAttPrevalSvolta.descrStatoAttPrevalSvolta = unaLabel;
      datiAttEconomicaStatoAttPrevalSvolta.provinciaAttPrevalSvolta = '';
      datiAttEconomicaStatoAttPrevalSvolta.descrProvinciaAttPrevalSvolta = '';
      benefVitaGiurStatoAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaStatoAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurStatoAttPrevalSvolta,
      }
    case actionType.UPDATE_PROVINCIA_ATT_PREV_SVOLTA_VITA_GIURIDICO:
      const benefVitaGiurProvinciaAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaProvinciaAttPrevalSvolta = {...benefVitaGiurProvinciaAttPrevalSvolta}.datiAttEconomica;    
      datiAttEconomicaProvinciaAttPrevalSvolta.provinciaAttPrevalSvolta = unValore;
      datiAttEconomicaProvinciaAttPrevalSvolta.descrProvinciaAttPrevalSvolta = unaLabel;
      datiAttEconomicaProvinciaAttPrevalSvolta.comuneAttPrevalSvolta = '';
      datiAttEconomicaProvinciaAttPrevalSvolta.descrComuneAttPrevalSvolta = '';
      benefVitaGiurProvinciaAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaProvinciaAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurProvinciaAttPrevalSvolta,
      }
     case actionType.UPDATE_COMUNE_ATT_PREV_SVOLTA_VITA_GIURIDICO:
      const benefVitaGiurComuneAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaComuneAttPrevalSvolta = {...benefVitaGiurComuneAttPrevalSvolta}.datiAttEconomica;    
      if(datiAttEconomicaComuneAttPrevalSvolta.provinciaAttPrevalSvolta !== PROVINCIA_ESTERA){
      datiAttEconomicaComuneAttPrevalSvolta.comuneAttPrevalSvolta = unValore;
      datiAttEconomicaComuneAttPrevalSvolta.descrComuneAttPrevalSvolta = unaLabel;
      } else {
        datiAttEconomicaComuneAttPrevalSvolta.comuneAttPrevalSvolta = '';
        datiAttEconomicaComuneAttPrevalSvolta.descrComuneAttPrevalSvolta = action.unDato;
      }
      benefVitaGiurComuneAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaComuneAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurComuneAttPrevalSvolta,
      }
    case actionType.UPDATE_TIPO_SOCIETA_VITA_GIURIDICO:
      const benefVitaGiurSocietaAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaSocietaAttPrevalSvolta = {...benefVitaGiurSocietaAttPrevalSvolta}.datiAttEconomica;   
      datiAttEconomicaSocietaAttPrevalSvolta.tipoSocieta = unValore;
      datiAttEconomicaSocietaAttPrevalSvolta.descrTipoSocieta = unaLabel;
      datiAttEconomicaSocietaAttPrevalSvolta.codSAE = '';
      datiAttEconomicaSocietaAttPrevalSvolta.descrSAE = '';
      datiAttEconomicaSocietaAttPrevalSvolta.codATECO = '';
      datiAttEconomicaSocietaAttPrevalSvolta.descrATECO = '';
      benefVitaGiurSocietaAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaSocietaAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurSocietaAttPrevalSvolta,
      }
    case actionType.UPDATE_COD_SAE_VITA_GIURIDICO:
      const benefVitaGiurSAEAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaSAEAttPrevalSvolta = {...benefVitaGiurSAEAttPrevalSvolta}.datiAttEconomica;   
      datiAttEconomicaSAEAttPrevalSvolta.codSAE = unValore;
      datiAttEconomicaSAEAttPrevalSvolta.descrSAE = unaLabel;
      datiAttEconomicaSAEAttPrevalSvolta.codATECO = '';
      datiAttEconomicaSAEAttPrevalSvolta.descrATECO = '';
      benefVitaGiurSAEAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaSAEAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurSAEAttPrevalSvolta,
      }
    case actionType.UPDATE_COD_ATECO_VITA_GIURIDICO:
      const benefVitaGiurATECOAttPrevalSvolta = {...state}.benefVitaGiuridico;
      const datiAttEconomicaATECOAttPrevalSvolta = {...benefVitaGiurATECOAttPrevalSvolta}.datiAttEconomica;     
      datiAttEconomicaATECOAttPrevalSvolta.codATECO = unValore;
      datiAttEconomicaATECOAttPrevalSvolta.descrATECO = unaLabel;
      benefVitaGiurATECOAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaATECOAttPrevalSvolta};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurATECOAttPrevalSvolta,
      }
    case actionType.UPDATE_SOCIETA_AZ_FIDUCIARIO_BENEF_VITA_GIURIDICO:
      const benefVitaGiurSocietaAzFiduciario = {...state}.benefVitaGiuridico;
      const datiAttEconomicaSocietaAzFiduciario = {...benefVitaGiurSocietaAzFiduciario}.datiAttEconomica;
      datiAttEconomicaSocietaAzFiduciario.societaAzFiduciario = action.unDato;
      benefVitaGiurSocietaAzFiduciario.datiAttEconomica = {...datiAttEconomicaSocietaAzFiduciario};
      return {
        ...state,
        benefVitaGiuridico: benefVitaGiurSocietaAzFiduciario,
      }
    

/* BENEFICIARIO VITA GENERICO DATI GENERALI */
    case actionType.UPDATE_PERCENTUALE_BENEF_VITA_GENERICO:
      const benefVitaGenericoPercentuale = {...state}.benefVitaGenerico;
      const datiGeneraliGenericoPercentuale = {...benefVitaGenericoPercentuale}.datiGenerali;
      datiGeneraliGenericoPercentuale.percBeneficiario = action.unDato;
      benefVitaGenericoPercentuale.datiGenerali = {...datiGeneraliGenericoPercentuale};
      return {
        ...state,
        benefVitaGenerico: benefVitaGenericoPercentuale,
      }; 
    case actionType.UPDATE_BENEF_VITA_GENERICO:
      const benefVitaGenericoDati = {...state}.benefVitaGenerico;
      const datiGeneraliGenericoDati = {...benefVitaGenericoDati}.datiGenerali;
      const unValoreDati = (action.unDato === null) ? '' : action.unDato.value;
      const unaLabelDati = (action.unDato === null) ? '' : action.unDato.label;
      datiGeneraliGenericoDati.benefGenerico = unValoreDati;
      datiGeneraliGenericoDati.descrBenefGenerico = unaLabelDati;
      benefVitaGenericoDati.datiGenerali = {...datiGeneraliGenericoDati};
      return {
        ...state,
        benefVitaGenerico: benefVitaGenericoDati,
      };                
    case actionType.UPDATE_ESTESO_BENEF_VITA_GENERICO:
      const benefVitaGenericoEsteso = {...state}.benefVitaGenerico;
      const datiGeneraliGenericoEsteso = {...benefVitaGenericoEsteso}.datiGenerali;
      datiGeneraliGenericoEsteso.descrBenefEsteso = action.unDato;
      benefVitaGenericoEsteso.datiGenerali = {...datiGeneraliGenericoEsteso};
      return {
        ...state,
        benefVitaGenerico: benefVitaGenericoEsteso,
      };    
    case actionType.UPDATE_BENEF_VITA_PERCENTUALE_TOTALE:
        console.log('#RT2 Reducer areaBenefVitaReducer.actionType.UPDATE_BENEF_VITA_PERCENTUALE_TOTALE.state', state);
        console.log('#RT2 Reducer areaBenefVitaReducer.action', action);
        const percBenVitaTotale = action.unDato;
        return {
          ...state,
          percBenVitaTotale: percBenVitaTotale,
        }; 
        /* DATI ATTIVITA ECONOMICA TITOLARE EFFETTIVO BENEFICIARIO VITA */
		case actionType.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA:
			datiAttEconomicaTitEffVita.codAttPrevalSvolta = unValore;
			datiAttEconomicaTitEffVita.descrAttPrevalSvolta = unaLabel;
			datiTitolareBenefVitaEff.datiAttEconomica = { ...datiAttEconomicaTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}	
		case actionType.UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA:
			datiAttEconomicaTitEffVita.statoAttPrevalSvolta = unValore;
			datiAttEconomicaTitEffVita.descrStatoAttPrevalSvolta = unaLabel;
			datiAttEconomicaTitEffVita.provinciaAttPrevalSvolta = '';
			datiAttEconomicaTitEffVita.descrProvinciaAttPrevalSvolta = '';
			datiAttEconomicaTitEffVita.comuneAttPrevalSvolta = '';
			datiAttEconomicaTitEffVita.descrComuneAttPrevalSvolta = '';
			datiTitolareBenefVitaEff.datiAttEconomica = { ...datiAttEconomicaTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}
		case actionType.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA:
			datiAttEconomicaTitEffVita.provinciaAttPrevalSvolta = unValore;
			datiAttEconomicaTitEffVita.descrProvinciaAttPrevalSvolta = unaLabel;
			datiAttEconomicaTitEffVita.comuneAttPrevalSvolta = '';
			datiAttEconomicaTitEffVita.descrComuneAttPrevalSvolta = '';
			datiTitolareBenefVitaEff.datiAttEconomica = {...datiAttEconomicaTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}			
		case actionType.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA:
			if (datiAttEconomicaTitEffVita.provinciaAttPrevalSvolta === PROVINCIA_ESTERA) {
				datiAttEconomicaTitEffVita.comuneAttPrevalSvolta = '';
				datiAttEconomicaTitEffVita.descrComuneAttPrevalSvolta = action.unDato;
				datiTitolareBenefVitaEff.datiAttEconomica = { ...datiAttEconomicaTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			} else {
				datiAttEconomicaTitEffVita.comuneAttPrevalSvolta = unValore;
				datiAttEconomicaTitEffVita.descrComuneAttPrevalSvolta = unaLabel;
				datiTitolareBenefVitaEff.datiAttEconomica = { ...datiAttEconomicaTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}
/*  DATI GENERALI MINIMI TITOLARE EFFETTIVO BENEFICIARIO VITA  */
case actionType.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA:
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.sesso = action.unDato;
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {				
    datiGeneraliMinimiTitEffBenefVita.sesso = action.unDato;
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA:
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.codiceFiscale = action.unDato;
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {
    datiGeneraliMinimiTitEffBenefVita.codiceFiscale = action.unDato;
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA:
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.cognome = action.unDato;
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {
    datiGeneraliMinimiTitEffBenefVita.cognome = action.unDato;
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA:
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.nome = action.unDato;
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {
    datiGeneraliMinimiTitEffBenefVita.nome = action.unDato;
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA:
  const unaDataTitolareBenefVita = action.unDato;
  const nuovaDataNascitaFormattataTitolareBenefVita = dataFormat(unaDataTitolareBenefVita, 'DD/MM/YYYY');
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.dataNascita = unaDataTitolareBenefVita;
    datiGeneraliTitEffVita.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefVita;
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {
    datiGeneraliMinimiTitEffBenefVita.dataNascita = unaDataTitolareBenefVita;
    datiGeneraliMinimiTitEffBenefVita.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefVita;
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA:
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.statoNascita = unValore;
    datiGeneraliTitEffVita.descrStatoNascita = unaLabel
    datiGeneraliTitEffVita.provinciaNascita = '';
    datiGeneraliTitEffVita.descrProvinciaNascita = '';
    datiGeneraliTitEffVita.comuneNascita = '';
    datiGeneraliTitEffVita.descrComuneNascita = '';
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {
    datiGeneraliMinimiTitEffBenefVita.statoNascita = unValore;
    datiGeneraliMinimiTitEffBenefVita.descrStatoNascita = unaLabel
    datiGeneraliMinimiTitEffBenefVita.provinciaNascita = '';
    datiGeneraliMinimiTitEffBenefVita.descrProvinciaNascita = '';
    datiGeneraliMinimiTitEffBenefVita.comuneNascita = '';
    datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = '';
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA:
  if(action.isFiduciaria) {
    datiGeneraliTitEffVita.provinciaNascita = unValore;
    datiGeneraliTitEffVita.descrProvinciaNascita = unaLabel;
    datiGeneraliTitEffVita.comuneNascita = '';
    datiGeneraliTitEffVita.descrComuneNascita = '';
    datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  } else {
    datiGeneraliMinimiTitEffBenefVita.provinciaNascita = unValore;
    datiGeneraliMinimiTitEffBenefVita.descrProvinciaNascita = unaLabel;
    datiGeneraliMinimiTitEffBenefVita.comuneNascita = '';
    datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = '';
    datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
    titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA:
  if(action.isFiduciaria) {
    if(datiGeneraliTitEffVita.provinciaNascita !== PROVINCIA_ESTERA) {
      datiGeneraliTitEffVita.comuneNascita = unValore;
      datiGeneraliTitEffVita.descrComuneNascita = unaLabel;
      datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
      titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
    } else {
      datiGeneraliTitEffVita.comuneNascita = '';
      datiGeneraliTitEffVita.descrComuneNascita = action.unDato;
      datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
      titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
    }
   } else {
    if (datiGeneraliMinimiTitEffBenefVita.provinciaNascita !== PROVINCIA_ESTERA) {
      datiGeneraliMinimiTitEffBenefVita.comuneNascita = unValore;
      datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = unaLabel;
      datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
      titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
    } else {
      datiGeneraliMinimiTitEffBenefVita.comuneNascita = '';
      datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = action.unDato;
      datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
      titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
    }
  }
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_NUM_TELEFONO_TITOLARE_EFF_VITA:
  datiGeneraliTitEffVita.numTelefono = action.unDato;
  datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
  titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_CITTADINANZA_1_TITOLARE_EFF_VITA:
  datiGeneraliTitEffVita.cittadinanza1 = unValore;
  datiGeneraliTitEffVita.descrCittadinanza1 = unaLabel;  
  datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
  titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_CITTADINANZA_2_TITOLARE_EFF_VITA:
  datiGeneraliTitEffVita.cittadinanza2 = unValore;
  datiGeneraliTitEffVita.descrCittadinanza2 = unaLabel;
  datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
  titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA:
  datiRelazContrTitEffBenefVita.relazContraente = unValore;
  datiRelazContrTitEffBenefVita.descrRelazContraente = unaLabel;
  datiRelazContrTitEffBenefVita.altro = '';
  datiTitolareBenefVitaEff.datiRelazContr = { ...datiRelazContrTitEffBenefVita };
  titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA:
  datiRelazContrTitEffBenefVita.altro = action.unDato;
  datiTitolareBenefVitaEff.datiRelazContr = {...datiRelazContrTitEffBenefVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA:
  const cloneTitolareEffBenefVitaConfermaAggiungi = jsonCopy(action.titolareEffettivoBenefVita);
  const titolareEffBenefVitaListConfermaAggiungi = { ...state}.benefVitaGiuridico.titolareEffBenefVitaList;
  const titolareEffBenefVitaListConfermaAggiungiNuovo = [...titolareEffBenefVitaListConfermaAggiungi, cloneTitolareEffBenefVitaConfermaAggiungi];
  benefVitaGiuridico.titolareEffBenefVitaList = [...titolareEffBenefVitaListConfermaAggiungiNuovo];
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.NUOVO_TITOLARE_EFF_BENEF_VITA:
  const titolareEffBenefVitaNuovo = inizializzaTitolareEffBenefVita(state);
  benefVitaGiuridico.titolareEffBenefVita = titolareEffBenefVitaNuovo;
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.UPDATE_TITOLARE_EFF_BENEF_VITA:

  const cloneTitolareEffBenefVitaUpdate = jsonCopy(action.titolareEffettivoBenefVita);
  const titolareEffBenefVitaUpdateCodiceFiscale = cloneTitolareEffBenefVitaUpdate.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale;
  const titolareEffBenefVitaListUpdate = [...state.benefVitaGiuridico.titolareEffBenefVitaList];

  let indexTitolareEffBenefVitaUpdate = undefined;
  titolareEffBenefVitaListUpdate.forEach((item, index, array) => {
    if (titolareEffBenefVitaUpdateCodiceFiscale === item.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale) {
      indexTitolareEffBenefVitaUpdate = index;
    }
  })
  const benIndexUpdateTit = benefVitaGiuridicoList.findIndex(el => el.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === benefVitaGiuridico.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale);
  if(cloneTitolareEffBenefVitaUpdate.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
    cloneTitolareEffBenefVitaUpdate.errorFiduciante = false;
  }
  benefVitaGiuridicoList[benIndexUpdateTit].titolareEffBenefVitaListInBenef[indexTitolareEffBenefVitaUpdate] = cloneTitolareEffBenefVitaUpdate;
  titolareEffBenefVitaListUpdate[indexTitolareEffBenefVitaUpdate] = cloneTitolareEffBenefVitaUpdate;
  benefVitaGiuridico.titolareEffBenefVitaList = titolareEffBenefVitaListUpdate;
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
    benefVitaGiuridicoList: benefVitaGiuridicoList
  };
case actionType.CARICA_TITOLARE_EFF_BENEF_VITA:
  const figuraSelezIndexCaricaBenefVita = action.figuraSelezIndex;
  const titolareEffListCaricaBenefVita = [...state.benefVitaGiuridico.titolareEffBenefVitaList];
  let titolareEffCaricaBenefVita = { ...titolareEffListCaricaBenefVita[figuraSelezIndexCaricaBenefVita]};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffCaricaBenefVita}
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  };
case actionType.SALVA_TITOLARE_EFF_BENEF_VITA:
  titolareEffBenefVita.controlloDati = action.unDato;
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }
case actionType.SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS:
  return {
    ...state,
    salvaTitolareEffBenefVitaSuccess: action.unDato,
  }
case actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA:
  titolareEffBenefVita.anagraficaValida = action.unDato;
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }	
case actionType.UPDATE_ERRORE_FIDUCIANTE_TE_VITA:
  titolareEffBenefVita.errorFiduciante = action.unDato;
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico,
  }	
case actionType.DELETE_TITOLARE_EFF_BENEF_VITA:
    let titolareEffDeleteEffBenefVita = action.titolareEffettivo;
    const cfDeleteTitolare = (titolareEffDeleteEffBenefVita.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale) ? titolareEffDeleteEffBenefVita.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale : titolareEffDeleteEffBenefVita.datiTitolareBenefVitaEff.datiGenerali.codiceFiscale
    const filterdTitolareEffDeleteListEffBenefVita = state.benefVitaGiuridico.titolareEffBenefVitaList.filter(item => {
      const datiGenerali = (item.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale) ? item.datiTitolareBenefVitaEff.datiGeneraliMinimi : item.datiTitolareBenefVitaEff.datiGenerali
      const codiceFiscale = datiGenerali.codiceFiscale;
      return codiceFiscale !== cfDeleteTitolare
    })
    const indexBenef = benefVitaGiuridicoList.findIndex(el => el.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === benefVitaGiuridico.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale);
    benefVitaGiuridicoList[indexBenef].titolareEffBenefVitaListInBenef = [...filterdTitolareEffDeleteListEffBenefVita];
    benefVitaGiuridico.titolareEffBenefVitaList = [...filterdTitolareEffDeleteListEffBenefVita];
    benefVitaGiuridico.titolareEffBenefVita = inizializzaTitolareEffBenefVita(state);
    return {
      ...state,
      benefVitaGiuridico: benefVitaGiuridico
    };	
/* DATI RESIDENZA TITOLARE EFFETTIVO BENEFICIARIO VITA */
case actionType.UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA:
  datiResidenzaTitEffVita.statoResidenza = unValore;
  datiResidenzaTitEffVita.descrStatoResidenza = unaLabel;
  datiResidenzaTitEffVita.provinciaResidenza = '';
  datiResidenzaTitEffVita.descrProvinciaResidenza = '';
  datiResidenzaTitEffVita.comuneResidenza = '';
  datiResidenzaTitEffVita.descrComuneResidenza = '';
  datiResidenzaTitEffVita.toponimoResidenza = '';
  datiResidenzaTitEffVita.descrToponimoResidenza = '';
  return {
    ...state,
    datiResidenzaTitEffVita
  }
case actionType.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA:
  datiResidenzaTitEffVita.provinciaResidenza = unValore;
  datiResidenzaTitEffVita.descrProvinciaResidenza = unaLabel;
  datiResidenzaTitEffVita.comuneResidenza = '';
  datiResidenzaTitEffVita.descrComuneResidenza = '';
  datiResidenzaTitEffVita.toponimoResidenza = '';
  datiResidenzaTitEffVita.descrToponimoResidenza = '';
  return {
    ...state,
    datiResidenzaTitEffVita
  }
case actionType.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA:
  if(datiResidenzaTitEffVita.provinciaResidenza === PROVINCIA_ESTERA) {
    datiResidenzaTitEffVita.comuneResidenza = '';
    datiResidenzaTitEffVita.descrComuneResidenza = action.unDato;
    datiResidenzaTitEffVita.toponimoResidenza = '';
    datiResidenzaTitEffVita.descrToponimoResidenza = '';	
  } else {
    datiResidenzaTitEffVita.comuneResidenza = unValore;
    datiResidenzaTitEffVita.descrComuneResidenza = unaLabel;
    datiResidenzaTitEffVita.toponimoResidenza = '';
    datiResidenzaTitEffVita.descrToponimoResidenza = '';
  }
  return {
    ...state,
    datiResidenzaTitEffVita
  }
case actionType.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA:
  if(datiResidenzaTitEffVita.provinciaResidenza === PROVINCIA_ESTERA) {
    datiResidenzaTitEffVita.toponimoResidenza = '';
    datiResidenzaTitEffVita.descrToponimoResidenza = action.unDato;	
  } else {
    datiResidenzaTitEffVita.toponimoResidenza = unValore;
    datiResidenzaTitEffVita.descrToponimoResidenza = unValore;
  }
  return {
    ...state,
    datiResidenzaTitEffVita
  }
case actionType.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA:
  datiResidenzaTitEffVita.numCivicoResidenza = action.unDato;
  return {
    ...state,
    datiResidenzaTitEffVita
  }
case actionType.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA:
  datiResidenzaTitEffVita.descrIndirizzoResidenza = action.unDato;
  return {
    ...state,
    datiResidenzaTitEffVita
  }
case actionType.UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA:
  datiResidenzaTitEffVita.capResidenza = action.unDato;
  return {
    ...state,
    datiResidenzaTitEffVita
  }
/* DATI DOMICILIO TITOLARE EFFETTIVO BENEFICIARIO VITA  */
case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA:
  datiDomicilioTitEffVita.domicilioDiversoResidenza = action.unDato;
  if(datiDomicilioTitEffVita.domicilioDiversoResidenza === 'N') {
    datiDomicilioTitEffVita.statoDomicilio = '';
    datiDomicilioTitEffVita.descrStatoDomicilio = '';
    datiDomicilioTitEffVita.provinciaDomicilio = '';
    datiDomicilioTitEffVita.descrProvinciaDomicilio = '';
    datiDomicilioTitEffVita.comuneDomicilio = '';
    datiDomicilioTitEffVita.descrComuneDomicilio = '';
    datiDomicilioTitEffVita.toponimoDomicilio = '';
    datiDomicilioTitEffVita.descrToponimoDomicilio = '';
    datiDomicilioTitEffVita.indirizzoDomicilio = '';
    datiDomicilioTitEffVita.capDomicilio = '';
    datiDomicilioTitEffVita.numCivicoDomicilio = '';
  }
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  datiDomicilioTitEffVita.statoDomicilio = unValore;
  datiDomicilioTitEffVita.descrStatoDomicilio = unaLabel;
  datiDomicilioTitEffVita.provinciaDomicilio = '';
  datiDomicilioTitEffVita.descrProvinciaDomicilio = '';
  datiDomicilioTitEffVita.comuneDomicilio = '';
  datiDomicilioTitEffVita.descrComuneDomicilio = '';
  datiDomicilioTitEffVita.toponimoDomicilio = '';
  datiDomicilioTitEffVita.descrToponimoDomicilio = '';
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  datiDomicilioTitEffVita.provinciaDomicilio = unValore;
  datiDomicilioTitEffVita.descrProvinciaDomicilio = unaLabel;
  datiDomicilioTitEffVita.comuneDomicilio = '';
  datiDomicilioTitEffVita.descrComuneDomicilio = '';
  datiDomicilioTitEffVita.toponimoDomicilio = '';
  datiDomicilioTitEffVita.descrToponimoDomicilio = '';
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  if(datiDomicilioTitEffVita.provinciaDomicilio === PROVINCIA_ESTERA) {
    datiDomicilioTitEffVita.comuneDomicilio = '';
    datiDomicilioTitEffVita.descrComuneDomicilio = action.unDato;	
    datiDomicilioTitEffVita.toponimoDomicilio = '';
    datiDomicilioTitEffVita.descrToponimoDomicilio = '';
  } else {
    datiDomicilioTitEffVita.comuneDomicilio = unValore;
    datiDomicilioTitEffVita.descrComuneDomicilio = unaLabel;	
    datiDomicilioTitEffVita.toponimoDomicilio = '';
    datiDomicilioTitEffVita.descrToponimoDomicilio = '';
  }
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  if(datiDomicilioTitEffVita.provinciaDomicilio === PROVINCIA_ESTERA) {
    datiDomicilioTitEffVita.toponimoDomicilio = '';
    datiDomicilioTitEffVita.descrToponimoDomicilio = action.unDato;
  } else {
    datiDomicilioTitEffVita.toponimoDomicilio = unValore;
    datiDomicilioTitEffVita.descrToponimoDomicilio = unaLabel;
  }
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  datiDomicilioTitEffVita.indirizzoDomicilio = action.unDato;
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  datiDomicilioTitEffVita.numCivicoDomicilio = action.unDato;
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
  datiDomicilioTitEffVita.capDomicilio = action.unDato;
  datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
/* DATI RECAPITO TITOLARE EFFETTIVO BENEFICIARIO VITA */
case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA:
  datiRecapitoTitEffVita.corrispondenzaDiversoResidenza = action.unDato;
  if(datiRecapitoTitEffVita.corrispondenzaDiversoResidenza === 'N') {
    datiRecapitoTitEffVita.corrispondenzaIntestatoContraente = '';
    datiRecapitoTitEffVita.statoRecapito = '';
    datiRecapitoTitEffVita.descrStatoRecapito = '';
    datiRecapitoTitEffVita.provinciaRecapito = '';
    datiRecapitoTitEffVita.descrProvinciaRecapito = '';
    datiRecapitoTitEffVita.comuneRecapito = '';
    datiRecapitoTitEffVita.descrComuneRecapito = '';
    datiRecapitoTitEffVita.toponimoRecapito = '';
    datiRecapitoTitEffVita.descrToponimoRecapito = '';
    datiRecapitoTitEffVita.descrIndirizzoRecapito = '';
    datiRecapitoTitEffVita.numCivicoRecapito = '';
    datiRecapitoTitEffVita.capRecapito = '';
    datiRecapitoTitEffVita.presso = '';
  }
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA:
  datiRecapitoTitEffVita.corrispondenzaIntestatoContraente = action.unDato;
  datiRecapitoTitEffVita.presso = '';
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  datiRecapitoTitEffVita.statoRecapito = unValore;
  datiRecapitoTitEffVita.descrStatoRecapito = unaLabel;
  datiRecapitoTitEffVita.provinciaRecapito = '';
  datiRecapitoTitEffVita.descrProvinciaRecapito = '';
  datiRecapitoTitEffVita.comuneRecapito = '';
  datiRecapitoTitEffVita.descrComuneRecapito = '';
  datiRecapitoTitEffVita.toponimoRecapito = '';
  datiRecapitoTitEffVita.descrToponimoRecapito = '';
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  datiRecapitoTitEffVita.provinciaRecapito = unValore;
  datiRecapitoTitEffVita.descrProvinciaRecapito = unaLabel;
  datiRecapitoTitEffVita.comuneRecapito = '';
  datiRecapitoTitEffVita.descrComuneRecapito = '';
  datiRecapitoTitEffVita.toponimoRecapito = '';
  datiRecapitoTitEffVita.descrToponimoRecapito = '';
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  if(datiRecapitoTitEffVita.provinciaRecapito === PROVINCIA_ESTERA) {
    datiRecapitoTitEffVita.comuneRecapito = '';
    datiRecapitoTitEffVita.descrComuneRecapito = action.unDato;
    datiRecapitoTitEffVita.toponimoRecapito = '';
    datiRecapitoTitEffVita.descrToponimoRecapito = '';	
  } else {
    datiRecapitoTitEffVita.comuneRecapito = unValore;
    datiRecapitoTitEffVita.descrComuneRecapito = unaLabel;
    datiRecapitoTitEffVita.toponimoRecapito = '';
    datiRecapitoTitEffVita.descrToponimoRecapito = '';	
  }
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  if(datiRecapitoTitEffVita.provinciaRecapito === PROVINCIA_ESTERA) {
    datiRecapitoTitEffVita.toponimoRecapito = '';
    datiRecapitoTitEffVita.descrToponimoRecapito = action.unDato;	
  } else {
    datiRecapitoTitEffVita.toponimoRecapito = unValore;
    datiRecapitoTitEffVita.descrToponimoRecapito = unaLabel;	
  }
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  datiRecapitoTitEffVita.descrIndirizzoRecapito = action.unDato;
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  datiRecapitoTitEffVita.numCivicoRecapito = action.unDato;
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA:
  datiRecapitoTitEffVita.capRecapito = action.unDato;
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA:
  datiRecapitoTitEffVita.presso = action.unDato;
  datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
/* DATI ANTIRICICLAGGIO TITOLARE EFFETTIVO BENEFICIARIO VITA */
case actionType.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.tipoDocumento = unValore;
  datiAntiriciclaggioTitEffVita.descrTipoDocumento = unaLabel;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.numeroDocumento = action.unDato;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.enteRilascio = unValore;
  datiAntiriciclaggioTitEffVita.descrEnteRilascio = unaLabel;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.dataRilascio = action.unDato;
  datiAntiriciclaggioTitEffVita.descrEnteRilascio = dataFormat(action.unDato, 'DD/MM/YYYY');
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA:
  const nuovaDataScadenzaVita = action.unDato;
  const nuovaDataFormattataScadenzaVita = dataFormat(nuovaDataScadenzaVita, 'DD/MM/YYYY');
  datiAntiriciclaggioTitEffVita.dataScadenza = nuovaDataScadenzaVita;
  datiAntiriciclaggioTitEffVita.dataScadenzaStampa = nuovaDataFormattataScadenzaVita;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.statoRilascio = unValore;
  datiAntiriciclaggioTitEffVita.descrStatoRilascio = unaLabel;
  datiAntiriciclaggioTitEffVita.provinciaRilascio = '';
  datiAntiriciclaggioTitEffVita.descrProvinciaRilascio = '';
  datiAntiriciclaggioTitEffVita.comuneRilascio = '';
  datiAntiriciclaggioTitEffVita.descrProvinciaRilascio = '';
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.provinciaRilascio = unValore;
  datiAntiriciclaggioTitEffVita.descrProvinciaRilascio = unaLabel;
  datiAntiriciclaggioTitEffVita.comuneRilascio = '';
  datiAntiriciclaggioTitEffVita.descrComuneRilascio = '';
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA:
  if(datiAntiriciclaggioTitEffVita.provinciaRilascio === PROVINCIA_ESTERA) {
    datiAntiriciclaggioTitEffVita.comuneRilascio = '';
    datiAntiriciclaggioTitEffVita.descrComuneRilascio = action.unDato;
  } else {
    datiAntiriciclaggioTitEffVita.comuneRilascio = unValore;
    datiAntiriciclaggioTitEffVita.descrComuneRilascio = unaLabel;
  }
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_TITOLARE_EFF_QUALIFICA_VITA:
  datiAntiriciclaggioTitEffVita.qualifica = unValore;
  datiAntiriciclaggioTitEffVita.descrQualifica = unaLabel;
  datiAntiriciclaggioTitEffVita.altro = '';
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  datiTitolareBenefVitaEff.percOwnerShip = '';
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_ALTRO_QUALIFICA_VITA:
  datiAntiriciclaggioTitEffVita.altro = action.unDato;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA:
  datiTitolareBenefVitaEff.percOwnerShip = action.unDato;	
/* datiAntiriciclaggioTitEffVita.percOwnerShip = action.unDato;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}; */
  return {
    ...state,
    datiTitolareBenefVitaEff
  }
case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.espostaPoliticamente = action.unDato;
  datiAntiriciclaggioTitEffVita.isInCarica = '';
  datiAntiriciclaggioTitEffVita.caricaPersonaEsposta = '';
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.isInCarica = action.unDato;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }
case actionType.UPDATE_CARICA_PEP_TITOLARE_EFF_VITA:
  datiAntiriciclaggioTitEffVita.caricaPersonaEsposta = action.unDato;
  datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
  titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
  benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
  return {
    ...state,
    benefVitaGiuridico: benefVitaGiuridico
  }

    default:
      return state;
  }
}

export const inizializzaBeneficiarioVitaFisico = (state) => {
  const benefVitaFisico_INI = {...state}.benefVitaFisico;
  benefVitaFisico_INI.anagraficaValida = null;
  benefVitaFisico_INI.controlloDati = '';
  benefVitaFisico_INI.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_VITA,
    tipoPers: PERSONA_FISICA,
  };  
  benefVitaFisico_INI.datiGeneraliBenefVita = jsonCopy(datiGeneraliBenefVitaInizializzato);
  benefVitaFisico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefVitaFisico_INI.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefVitaFisico_INI.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  return benefVitaFisico_INI;
}

export const inizializzaBeneficiarioVitaGiuridico = (state) => {
  const benefVitaGiuridico_INI = {...state}.benefVitaGiuridico;
  benefVitaGiuridico_INI.anagraficaValida = null;
  benefVitaGiuridico_INI.controlloDati = '';
  benefVitaGiuridico_INI.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_VITA,
    tipoPers: PERSONA_GIURIDICA,
  };  
  benefVitaGiuridico_INI.datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
  benefVitaGiuridico_INI.datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
  benefVitaGiuridico_INI.datiGeneraliBenefGiuridico = jsonCopy(datiGeneraliBenefGiuridicoInizializzato);
  benefVitaGiuridico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefVitaGiuridico_INI.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefVitaGiuridico_INI.datiAttEconomica = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  benefVitaGiuridico_INI.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  benefVitaGiuridico_INI.titolareEffBenefVita = jsonCopy(titEffBenefVitaGiuridicoInizializzato);
  benefVitaGiuridico_INI.titolareEffBenefVitaList = [];
  return benefVitaGiuridico_INI;
}

export const inizializzaBeneficiarioVitaGenerico = (state) => {
  const benefVitaGenerico_INI = {...state}.benefVitaGenerico;
  benefVitaGenerico_INI.anagraficaValida = null;
  benefVitaGenerico_INI.controlloDati = '';
  benefVitaGenerico_INI.datiGenerali = jsonCopy(datiGeneraliGenericoInizializzato);
  return benefVitaGenerico_INI;
}

export const inizializzaBeneficiarioVitaFisicoLista = (state) => {
//  const benefVitaFisicoList_INI = {...state}.benefVitaFisicoList;
//  benefVitaFisicoList_INI.benefVitaFisicoList = [];
  const benefVitaFisicoList_INI = [];
  return benefVitaFisicoList_INI;
}

export const inizializzaBeneficiarioVitaGiuridicoLista = (state) => {
//  const benefVitaGiuridicoList_INI = {...state}.benefVitaGiuridicoList;
//  benefVitaGiuridicoList_INI.benefVitaGiuridicoList = [];
  const benefVitaGiuridicoList_INI = [];	
  return benefVitaGiuridicoList_INI;
}

export const inizializzaBeneficiarioVitaGenericoLista = (state) => {
//  const benefVitaGenericoList_INI = {...state}.benefVitaGenericoList;
//  benefVitaGenericoList_INI.benefVitaGenericoList = [];
  const benefVitaGenericoList_INI = [];	
  return benefVitaGenericoList_INI;
}

export const inizializzaTitolareEffBenefVita = (state) => {
	const titolareEffBenefVita_INI = {...state}.benefVitaGiuridico.titolareEffBenefVita;
	titolareEffBenefVita_INI.anagraficaValida = null;
	titolareEffBenefVita_INI.controlloDati = '';
	titolareEffBenefVita_INI.datiTitolareBenefVitaEff = jsonCopy(datiTitolareEffettivoBenefVitaInizializzato);
	return titolareEffBenefVita_INI;
}

export default areaBenefVitaReducer;