import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import DatiModificaUtenza from './DatiModificaUtenza';
import DatiRicercaUtenza from './DatiRicercaUtenza';

const styles = theme => ({
});

class DatiListaDettaglioUtenza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDettaglio: '',
    };
  };
    
  mostraDettaglio = (event, user) => {
    this.setState({
      userDettaglio: user,
    });	
  }
  
  render() {
    const { classes } = this.props;  
    
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">	
        {this.state.userDettaglio === '' &&
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start">	
              <Grid item>
                <DatiRicercaUtenza mostraDettaglio={this.mostraDettaglio} />
              </Grid>
	        </Grid>
          </Grid>
        }
        {this.state.userDettaglio !== '' &&
          <Grid item xs={12}>
  	        <Grid container direction="row" justify="center" alignItems="flex-start">	
  	          <Grid item>
  	            <DatiModificaUtenza mostraDettaglio={this.mostraDettaglio}/>
  	          </Grid>
  		    </Grid>
  	      </Grid>
        }
	  </Grid>
    );
  }
}

DatiListaDettaglioUtenza.propTypes = {
  classes: PropTypes.object.isRequired, 
};

export default withStyles(styles)(DatiListaDettaglioUtenza);
