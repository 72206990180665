import { jsonCopy } from '../../../utility/genericUtility';
import { datiQuestionarioAdeguatezzaFisicoInizializzato, datiQuestionarioAdeguatezzaGiuridicoInizializzato, 
	datiQuestionarioAnamnesticoInizializzato, datiQuestionarioAnamnesticoMalattiaGraveInizializzato,
	datiQuestionarioAnamnesticoSemplificatoInizializzato 
	} 
    from '../componentiBase/sezioniQuestionario';
//import { datiQuestionarioAnamnesticoSemplificatoInizializzato } from '../componentiBase/sezioneQuestionarioAnamnesticoSemplificato';

export const datiQuestionarioAdeguatezzaFisico = jsonCopy(datiQuestionarioAdeguatezzaFisicoInizializzato);
export const datiQuestionarioAdeguatezzaGiuridico = jsonCopy(datiQuestionarioAdeguatezzaGiuridicoInizializzato);
export const datiQuestionarioAnamnestico = jsonCopy(datiQuestionarioAnamnesticoInizializzato);
export const datiQuestionarioAnamnesticoMalattiaGrave = jsonCopy(datiQuestionarioAnamnesticoMalattiaGraveInizializzato);
export const datiQuestionarioAnamnesticoSemplificato = jsonCopy(datiQuestionarioAnamnesticoSemplificatoInizializzato);
export const controlloRisposta = false;
export const tipoCurrentQuestionario = '';
export const isChangedFromSemplificato = false;
//2020-02-08 PFD - START export const showSemplificato = false;
//2020-02-08 PFD - START export const showOnlySemplificato = false;