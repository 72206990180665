import { combineReducers } from 'redux';
import genericReducer from '../components/generic/reducer/generic';
import areaLoginReducer from '../components/login/reducer/login';
import areaProdottoReducer from '../components/prodotto/reducer/prodotto';
import areaContraenteReducer from '../components/anagrafica/figure/reducer/contraente';
import areaAssicuratoReducer from '../components/anagrafica/figure/reducer/assicurato';
import areaBenefVitaReducer from '../components/anagrafica/figure/reducer/beneficiarioVita';
import areaBenefMorteReducer from '../components/anagrafica/figure/reducer/beneficiarioMorte';
import areaReferenteTerzoReducer from '../components/anagrafica/figure/reducer/referenteTerzo';
import areaTitolareEffReducer from '../components/anagrafica/figure/reducer/titolareEff';

import areaDichIntermediarioReducer from '../components/proposal/dichiarazioni/intermediario/reducer/intermediario';
import areaDichConsensoReducer from '../components/proposal/dichiarazioni/consenso/reducer/consenso';
import areaDatiPremioReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiPremio';
import areaDatiRipartizionePremioReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiRipartizionePremio';
import areaDatiRipartizionePremioFondiReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiRipartizionePremioFondi';
import areaDatiOpzioniContrattualiReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiOpzioniContrattuali';
import areaDatiPianoErogazioniPeriodicheReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiPianoErogazioniPeriodiche';
import areaDatiPianoSpostamentoAutomaticoReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiPianoSpostamentoAutomatico';
import areaDatiTakeProfitReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiTakeProfit';
import areaDatiModalitaPagamentoReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModalitaPagamento';
import areaDatiModPagAssegnoReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModPagAssegno';
import areaDatiModPagBonificoReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModPagBonifico';
import areaDatiModPagBonificoEsteroReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModPagBonificoEstero';
import areaDatiModPagReimpiegoReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModPagReimpiego';
import areaDatiModPagCartaReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModPagCarta';
import areaDatiModPagAltroReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiModPagAltro';
import areaDatiDominioReducer from '../components/common/reducer/dominio';
import areaDatiQuestionarioReducer from '../components/proposal/questionario/sezione/reducer/datiQuestionario';
import areaDatiPremioCapitaleAssicuratoReducer from '../components/proposal/caratteristicheContratto/sezione/reducer/datiPremioCapitaleAssicurato';
import areaDatiNuovaUtenzaReducer from '../components/gestioneUtenze/sezione/reducer/datiNuovaUtenza';
import areaDatiStampaProposalReducer from '../components/proposal/stampa/reducer/datiStampaProposal';
import areaDatiRicercaUtenzaReducer from '../components/gestioneUtenze/sezione/reducer/datiRicercaUtenza';
import areaDatiModificaUtenzaReducer from '../components/gestioneUtenze/sezione/reducer/datiModificaUtenza';
import areaDatiLoginCambioPasswordReducer from '../components/gestioneUtenze/sezione/reducer/datiLoginCambioPassword';
import areaDatiPasswordDimenticataReducer from '../components/gestioneUtenze/sezione/reducer/datiPasswordDimenticata';
import areaDatiNuovaPasswordReducer from '../components/gestioneUtenze/sezione/reducer/datiNuovaPassword';
import areaDatiInviaNuoveCredenzialiReducer from '../components/gestioneUtenze/sezione/reducer/datiInviaNuoveCredenziali';

export default combineReducers({
  genericReducer,
  areaLoginReducer,
  areaProdottoReducer,
  areaContraenteReducer,
  areaAssicuratoReducer,
  areaDichIntermediarioReducer,
  areaDichConsensoReducer,
  areaBenefVitaReducer,
  areaBenefMorteReducer,
  areaReferenteTerzoReducer,
  areaTitolareEffReducer,
  areaDatiPremioReducer,
  areaDatiOpzioniContrattualiReducer,
  areaDatiPianoErogazioniPeriodicheReducer,
  areaDatiPianoSpostamentoAutomaticoReducer,
  areaDatiTakeProfitReducer,
  areaDatiModalitaPagamentoReducer,
  areaDatiModPagBonificoReducer,
  areaDatiModPagBonificoEsteroReducer,
  areaDatiModPagAssegnoReducer,
  areaDatiModPagAltroReducer,
  areaDatiModPagReimpiegoReducer,
  areaDatiModPagCartaReducer,
  areaDatiRipartizionePremioReducer,
  areaDatiRipartizionePremioFondiReducer,
  areaDatiDominioReducer,
  areaDatiQuestionarioReducer,
  areaDatiPremioCapitaleAssicuratoReducer,  
  areaDatiNuovaUtenzaReducer,
  areaDatiStampaProposalReducer,
  areaDatiRicercaUtenzaReducer,
  areaDatiModificaUtenzaReducer,
  areaDatiLoginCambioPasswordReducer,
  areaDatiPasswordDimenticataReducer,
  areaDatiNuovaPasswordReducer,
  areaDatiInviaNuoveCredenzialiReducer
})
