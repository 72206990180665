import * as actionType from '../actionType/datiPianoSpostamentoAutomatico';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiPianoSpostamentoAutomaticoInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiPianoSpostamentoAutomaticoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiPianoSpostamentoAutomaticoReducer.action.type', action.type);
  const caratteristicheContratto = {...state}.caratteristicheContratto;
  const datiOpzioniContrattuali = {...caratteristicheContratto}.datiOpzioniContrattuali;
  const datiPianoSpostamentoAutomatico = {...datiOpzioniContrattuali}.datiPianoSpostamentoAutomatico;
  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaPianoSpostamentoAutomatico(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };  
    case actionType.SALVA_PIANO_SPOSTAMENTO_AUTOMATICO:
      /*
    	const caratteristicheContrattoDatiPianoSpostamentoAutomaticoSalva = {...state}.caratteristicheContratto;
      const datiOpzioniContrattualiSalva = {...caratteristicheContrattoDatiPianoSpostamentoAutomaticoSalva}.datiOpzioniContrattuali;
      const datiPianoSpostamentoAutomaticoSalva = {...datiOpzioniContrattualiSalva}.datiPianoSpostamentoAutomatico;
      datiPianoSpostamentoAutomaticoSalva.controlloDati = action.unDato;
      datiOpzioniContrattualiSalva.datiPianoSpostamentoAutomatico = datiPianoSpostamentoAutomaticoSalva;
      caratteristicheContrattoDatiPianoSpostamentoAutomaticoSalva.datiOpzioniContrattuali = datiOpzioniContrattualiSalva;
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPianoSpostamentoAutomaticoSalva,
      };
      */
      datiPianoSpostamentoAutomatico.controlloDati = action.unDato;
      return {
        ...state,
    	caratteristicheContratto: caratteristicheContratto,
      };
      
    case actionType.UPDATE_PIANO_SPOSTAMENTO_AUTOMATICO_VALIDA:
     /* const caratteristicheContrattoDatiPianoSpostamentoAutomaticoValida = {...state}.caratteristicheContratto;
      const datiOpzioniContrattualiValida = {...caratteristicheContrattoDatiPianoSpostamentoAutomaticoValida}.datiOpzioniContrattuali;
      const datiPianoSpostamentoAutomaticoValida = {...datiOpzioniContrattualiValida}.datiPianoSpostamentoAutomatico;
      datiPianoSpostamentoAutomaticoValida.datiPianoSpostamentoAutomaticoValida = action.unDato;
      datiOpzioniContrattualiValida.datiPianoSpostamentoAutomatico = datiPianoSpostamentoAutomaticoValida;
      caratteristicheContrattoDatiPianoSpostamentoAutomaticoValida.datiOpzioniContrattuali = datiOpzioniContrattualiValida;
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPianoSpostamentoAutomaticoValida,
      };
      */
    	datiPianoSpostamentoAutomatico.datiPianoSpostamentoAutomaticoValida = action.unDato;
        return {
          ...state,
      	caratteristicheContratto: caratteristicheContratto,
        };
    case actionType.UPDATE_PIANO_SPOSTAMENTO_AUTOMATICO_ALLOCAZIONE_TARGET:
/*      const caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTarget = {...state}.caratteristicheContratto;
      const datiOpzioniContrattualiAttivazioneTarget = {...caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTarget}.datiOpzioniContrattuali;
      const datiPianoSpostamentoAutomaticoAttivazioneTarget = {...datiOpzioniContrattualiAttivazioneTarget}.datiPianoSpostamentoAutomatico;
      datiPianoSpostamentoAutomaticoAttivazioneTarget.allocazioneTarget = unValore;
      datiPianoSpostamentoAutomaticoAttivazioneTarget.descAllocazioneTarget = unaLabel;
      datiOpzioniContrattualiAttivazioneTarget.datiPianoSpostamentoAutomatico = datiPianoSpostamentoAutomaticoAttivazioneTarget;
      caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTarget.datiOpzioniContrattuali = datiOpzioniContrattualiAttivazioneTarget;
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTarget,
      };    
      */
    	datiPianoSpostamentoAutomatico.allocazioneTarget = unValore;
    	datiPianoSpostamentoAutomatico.descAllocazioneTarget = unaLabel;
        return {
          ...state,
      	caratteristicheContratto: caratteristicheContratto,
        };
    case actionType.UPDATE_PIANO_SPOSTAMENTO_AUTOMATICO_ALLOCAZIONE_ITEMS :
/*      const caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTargetItems = {...state}.caratteristicheContratto;
      const datiOpzioniContrattualiAttivazioneTargetItems = {...caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTargetitems}.datiOpzioniContrattuali;
      const datiPianoSpostamentoAutomaticoAttivazioneTargetitems = {...datiOpzioniContrattualiAttivazioneTargetItems}.datiPianoSpostamentoAutomatico;
      datiPianoSpostamentoAutomaticoAttivazioneTargetItems.allocazioneTargetItems = action.unDato;
      datiOpzioniContrattualiAttivazioneTargetItems.datiPianoSpostamentoAutomatico = datiPianoSpostamentoAutomaticoAttivazioneTargetItems;
      caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTargetItems.datiOpzioniContrattuali = datiOpzioniContrattualiAttivazioneTargetItems;
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPianoSpostamentoAutomaticoAttivazioneTargetItems,
      };    
      */
    	datiPianoSpostamentoAutomatico.allocazioneTargetItems = action.unDato;
        return {
          ...state,
      	caratteristicheContratto: caratteristicheContratto,
        };
    default:
      return state;
  }
}

export const inizializzaPianoSpostamentoAutomatico = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiPianoSpostamentoAutomatico = jsonCopy(datiPianoSpostamentoAutomaticoInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiPianoSpostamentoAutomaticoReducer;