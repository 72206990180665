export const DOMINIO_NAZIONI = "DOMINIO_NAZIONI";
export const DOMINIO_PROVINCE = "DOMINIO_PROVINCE";
export const DOMINIO_TIPO_DOCUMENTO = "DOMINIO_TIPO_DOCUMENTO";
export const DOMINIO_ENTE_RILASCIO = "DOMINIO_ENTE_RILASCIO";
export const DOMINIO_SCOPO_NATURA_RAPPORTO = "DOMINIO_SCOPO_NATURA_RAPPORTO";
export const DOMINIO_TIPO_SOCIETA = "DOMINIO_TIPO_SOCIETA";
export const DOMINIO_BENEFICIARIO_GENERICO_MORTE = "DOMINIO_BENEFICIARIO_GENERICO_MORTE";
export const DOMINIO_BENEFICIARIO_GENERICO_VITA = "DOMINIO_BENEFICIARIO_GENERICO_VITA";
export const DOMINIO_PREVALENTE_ATTIVITA_SVOLTA = "DOMINIO_PREVALENTE_ATTIVITA_SVOLTA";
export const DOMINIO_PREVALENTE_ATTIVITA_SVOLTA_GIURIDICO = "DOMINIO_PREVALENTE_ATTIVITA_SVOLTA_GIURIDICO";
export const DOMINIO_ORIGINE_FONDI = "DOMINIO_ORIGINE_FONDI";
export const DOMINIO_ALLOCAZIONI = "DOMINIO_ALLOCAZIONI";
export const DOMINIO_FONDI = "DOMINIO_FONDI";
export const PERCENTUALE_OBIETTIVO_TAKEPROFIT = "PERCENTUALE_OBIETTIVO_TAKEPROFIT";
export const FREQUENZA_EROGAZIONI_PERIODICHE = "FREQUENZA_EROGAZIONI_PERIODICHE";
export const MODALITA_PAGAMENTO = "MODALITA_PAGAMENTO";
export const BANCA = "BANCA";
export const CONSENSI = "CONSENSI";
export const DICHIARIAZIONI_INTERMEDIARIO = "DICHIARIAZIONI_INTERMEDIARIO";
export const REGIONI = "REGIONI";
export const DURATA_EROGAZIONI_PERIODICHE = "DURATA_EROGAZIONI_PERIODICHE";
export const CLASSE_PREMIO_POTENZIALE = "CLASSE_PREMIO_POTENZIALE";
export const TARIFFA_PER_PRODOTTO = "TARIFFA_PER_PRODOTTO";
export const CAPITALE_ASSICURATO_PER_PRODOTTO = "CAPITALE_ASSICURATO_PER_PRODOTTO";
export const FREQUENZA_CAPITALE_ASSICURATO = "FREQUENZA_CAPITALE_ASSICURATO";
export const RUOLO_X_PRODOTTO = "RUOLO_X_PRODOTTO";
export const MANDATO = "MANDATO";
export const PROFILO = "PROFILO";
export const DOMINIO_NAZIONE_ITALIA = "DOMINIO_NAZIONE_ITALIA";
export const DOMINIO_PROFESSIONI_QAS = "DOMINIO_PROFESSIONI_QAS";
export const DOMINIO_SPORT_QAS = "DOMINIO_SPORT_QAS";
export const DOMINIO_RELAZIONI = "DOMINIO_RELAZIONI";
export const DOMINIO_PAESI_BLACK_LIST = "DOMINIO_PAESI_BLACK_LIST";
export const DOMINIO_CITTADINANZA_PAESI_BLACK_LIST = "DOMINIO_CITTADINANZA_PAESI_BLACK_LIST";
export const DOMINIO_PROFILO_RISCHIO = 'DOMINIO_PROFILO_RISCHIO'; // VALORI DELLA COMBO PROFILO_RISCHIO
export const DOMINIO_PROFILO_RISCHIO_OBBLIGO = 'DOMINIO_PROFILO_RISCHIO_OBBLIGO'; // L'obblogatorieta' del profilo di rischio dipende dal mandato
export const DOMINIO_RAPPORTO_CONTRAENTE_INTERMEDIARIO = 'DOMINIO_RAPPORTO_CONTRAENTE_INTERMEDIARIO'; // VALORI DELLA COMBO RAPPORTO_CONTRAENTE_INTERMEDIARIO