export const UPDATE_TARIFFA 							   = 'UPDATE_TARIFFA';
export const UPDATE_EDIZIONE_MODELLO    		 		   = 'UPDATE_EDIZIONE_MODELLO';
export const UPDATE_DURATA      						   = 'UPDATE_DURATA';
export const UPDATE_DURATA_PAGAMENTO_PREMI    		 	   = 'UPDATE_DURATA_PAGAMENTO_PREMI';
export const UPDATE_CAPITALE_ASSICURATO    		 		   = 'UPDATE_CAPITALE_ASSICURATO';
export const UPDATE_MALATTIA_GRAVE    		 			   = 'UPDATE_MALATTIA_GRAVE';
export const UPDATE_CAPITALE_ASSICURATO_MALATTIA_GRAVE 	   = 'UPDATE_CAPITALE_ASSICURATO_MALATTIA_GRAVE';
export const UPDATE_COMPLEMENTARE_INFORTUNI    		 	   = 'UPDATE_COMPLEMENTARE_INFORTUNI';
export const UPDATE_FRAZIONAMENTO    		 			   = 'UPDATE_FRAZIONAMENTO';
export const UPDATE_PREMIO_RATA      				       = 'UPDATE_PREMIO_RATA';
export const UPDATE_PREMIO_UNICO      				       = 'UPDATE_PREMIO_UNICO';
export const UPDATE_PREMIO_ANNUO      				       = 'UPDATE_PREMIO_ANNUO';
export const UPDATE_APPENDICE_COSTI_PREMIO_CAPITALE_ASSIC  = 'UPDATE_APPENDICE_COSTI_PREMIO_CAPITALE_ASSIC';
export const SALVA_DATI_PREMIO_CAPITALE_ASSICURATO   	   = 'SALVA_DATI_PREMIO_CAPITALE_ASSICURATO';
export const UPDATE_DATI_PREMIO_CAPITALE_ASSICURATO_VALIDA = 'UPDATE_DATI_PREMIO_CAPITALE_ASSICURATO_VALIDA';