import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';

import DatiAntiriciclaggio from './DatiAntiriciclaggio';
import DatiFiscali from './DatiFiscali';
import DatiResidenza from './DatiResidenza';
import DatiGeneraliFisico from './DatiGeneraliFisico';

import * as actions from '../figure/action/titolareEff';
import * as actionType from '../figure/actionType/titolareEff';
import SelectComp from '../../common/SelectComp';
import { textTrim } from '../../../utility/genericUtility';
import DatiRecapito from './DatiRecapito';
import DatiAttivitaEconomica from './DatiAttivitaEconomica';
import DatiDomicilio from './DatiDomicilio';

//import { radioButtonTitolariEffettivi } from '../../store/radioButtonStore';


const styles = theme => ({
  textField: theme.textField,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff,
    datiAttEconomica: state.areaTitolareEffReducer.titolareEff.datiAttEconomica,
    controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiFiscaliTitolareEff: (unDato, unActionType) => dispatch(actions.updateDatiFiscaliTitolareEff(unDato, unActionType)),
  };
};
	
class DatiTitolareEff extends React.Component {
	
  constructor(props) {
	super(props);
    this.state = {
      datiTitolareEff: this.props.datiTitolareEff,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
    };
  };

  aggiornaDatiTitolareEff = (unDato, anActionType) => {
    this.props.updateDatiFiscaliTitolareEff(unDato, anActionType);
    this.setState({
      datiTitolareEff: this.props.datiTitolareEff,
    });
  }
	
  handleChangeDatiTitolareEffTextField = (event, anActionType) => {
    this.aggiornaDatiTitolareEff(event.target.value, anActionType);
  };
  
  handleChangeDatiTitolareEff = event => {	  
    this.props.updateDatiFiscaliTitolareEff(event.target.value, actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF);
    this.setState({
      datiTitolareEff: this.props.datiTitolareEff,
    });	  
  }


  initStatoCampi = () => {
    return [ {id: 'datiGeneraliTitolareEff', isError: false},
    	 	 {id: 'datiResidenzaTitolareEff', isError: false},
    	 	 {id: 'datiAntiriciclaggioTitolareEff', isError: false},
    	     {id: 'datiFiscaliTitolareEff', isError: false},
           {id: 'percOwnerShip', isError: false},
//           {id: 'datiRecapitoTitolareEff', isError: false},
           {id: 'datiDomicilioTitolareEff', isError: false},
           {id: 'datiAttEconomicaTitolareEff', isError: false}
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }


  render() {
    const { classes, datiTitolareEff, dominio } = this.props;
    console.log('DATITITOLARE COMP', datiTitolareEff)
    const controlloDati = this.props.controlloDati;
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
		<legend><Typography variant="h6">{t('esecutore')}</Typography></legend>*/}
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <DatiGeneraliFisico figura={'titolareEff'} datiGeneraliTitolareEff={datiTitolareEff.datiGenerali} labelFieldSet='datiGenerali'  
                              id={'datiGeneraliTitolareEff'} onError={this.handleError} />
          <DatiResidenza figura={'titolareEff'} datiResidenzaTitolareEff={datiTitolareEff.datiResidenza} 
                         id={'datiResidenzaTitolareEff'} onError={this.handleError} />
          <DatiDomicilio figura={'titolareEff'} datiDomicilioTitolareEff={datiTitolareEff.datiDomicilio} id='datiDomicilioTitolareEff' 
                         onChildrenError={this.handleError} labelFieldSet='datiDiDomicilioTitolareEff' />
          {/* <DatiRecapito figura={'titolareEff'} datiRecapitoTitolareEff={datiTitolareEff.datiRecapito} labelFieldSet='indirizzoDiCorrispondenza' id={'datiRecapitoTitolareEff'}
                        onError={this.handleError} /> */}
          <DatiAttivitaEconomica figura={'titolareEff'} datiAttEconomicaTitolareEff={datiTitolareEff.datiAttEconomica} labelFieldSet='datiAttivitaEconomica' 
                                 id={'datiAttEconomicaTitolareEff'} onError={this.handleError} />
        </Grid>
        <DividerComp titolo='datiAntiriciclaggioTitolareEff' variant='subtitle1'/>
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <DatiAntiriciclaggio figura={'titolareEff'} datiAntiriciglaggio={datiTitolareEff.datiAntiriciglaggio} 
                               id={'datiAntiriciclaggioTitolareEff'} onError={this.handleError} />
          <DatiFiscali figura={'titolareEff'} datiFiscali={datiTitolareEff.datiFiscali} labelFieldSet='datiFiscaliTitolareEff'
                       id={'datiFiscaliTitolareEff'} onError={this.handleError} />
      </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  };
      
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########TitolareEffAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########TitolareEffAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########TitolareEffAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	    
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }     
//      this.props.updateDatiFiscaliTitolareEff(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF);
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
	}
  } 
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);  
  }
}

DatiTitolareEff.propTypes = {
  classes: PropTypes.object.isRequired,
  dominio: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiTitolareEff));