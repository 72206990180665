//TODO ### DA RIVEDERE ###
/* DATI GENERALI */
//export const UPDATE_DATI_GENERALI_PER_CONTRAENTE         = 'UPDATE_DATI_GENERALI_PER_CONTRAENTE';
export const UPDATE_DATI_GENERALI_PER_ESECUTORE          = 'UPDATE_DATI_GENERALI_PER_ESECUTORE';

/* DATI RESIDENZA */
//export const UPDATE_DATI_RESIDENZA_PER_CONTRAENTE         = 'UPDATE_DATI_RESIDENZA_PER_CONTRAENTE';
export const UPDATE_DATI_RESIDENZA_PER_ESECUTORE          = 'UPDATE_DATI_RESIDENZA_PER_ESECUTORE';

/* DATI ANTIRICICLAGGIO */
//export const UPDATE_DATI_ANTIRICICLAGGIO_PER_CONTRAENTE         = 'UPDATE_DATI_ANTIRICICLAGGIO_PER_CONTRAENTE';
export const UPDATE_DATI_ANTIRICICLAGGIO_PER_ESECUTORE          = 'UPDATE_DATI_ANTIRICICLAGGIO_PER_ESECUTORE';

/* CONTRAENTE FISICO */
export const SALVA_CONTRAENTE_FISICO = 'SALVA_CONTRAENTE_FISICO';
export const UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_FISICO = 'UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_FISICO';

/* CONTRAENTE GIURIDICO */
export const SALVA_CONTRAENTE_GIURIDICO = 'SALVA_CONTRAENTE_GIURIDICO';
export const UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_GIURIDICO = 'UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_GIURIDICO';

/* DATI GENERALI MINIMI */
export const UPDATE_CODICE_FISCALE           = 'UPDATE_CODICE_FISCALE';
export const UPDATE_CODICE_FISCALE_VALIDO    = 'UPDATE_CODICE_FISCALE_VALIDO';
export const UPDATE_NOME                     = 'UPDATE_NOME';
export const UPDATE_COGNOME                  = 'UPDATE_COGNOME';
export const UPDATE_SESSO 			         = 'UPDATE_SESSO';
export const UPDATE_DATA_NASCITA             = 'UPDATE_DATA_NASCITA';
export const UPDATE_STATO_NASCITA            = 'UPDATE_STATO_NASCITA';
export const UPDATE_PROVINCIA_NASCITA        = 'UPDATE_PROVINCIA_NASCITA';
export const UPDATE_COMUNE_NASCITA           = 'UPDATE_COMUNE_NASCITA';

/* DATI GENERALI CONTRAENTE FISICO */
export const UPDATE_CODICE_FISCALE_CONTRAENTE_FISICO    = 'UPDATE_CODICE_FISCALE_CONTRAENTE_FISICO';
//export const UPDATE_CONTROLLO_CODICE_FISCALE_CONTRAENTE_FISICO    = 'UPDATE_CONTROLLO_CODICE_FISCALE_CONTRAENTE_FISICO';
export const UPDATE_CODICE_FISCALE_VALIDO_CONTRAENTE_FISICO = 'UPDATE_CODICE_FISCALE_VALIDO_CONTRAENTE_FISICO';
export const UPDATE_NOME_CONTRAENTE_FISICO              = 'UPDATE_NOME_CONTRAENTE_FISICO';
export const UPDATE_COGNOME_CONTRAENTE_FISICO           = 'UPDATE_COGNOME_CONTRAENTE_FISICO';
export const UPDATE_SESSO_CONTRAENTE_FISICO             = 'UPDATE_SESSO_CONTRAENTE_FISICO';
export const UPDATE_DATA_NASCITA_CONTRAENTE_FISICO      = 'UPDATE_DATA_NASCITA_CONTRAENTE_FISICO';
export const UPDATE_STATO_NASCITA_CONTRAENTE_FISICO     = 'UPDATE_STATO_NASCITA_CONTRAENTE_FISICO';
export const UPDATE_PROVINCIA_NASCITA_CONTRAENTE_FISICO = 'UPDATE_PROVINCIA_NASCITA_CONTRAENTE_FISICO';
export const UPDATE_COMUNE_NASCITA_CONTRAENTE_FISICO    = 'UPDATE_COMUNE_NASCITA_CONTRAENTE_FISICO';
export const UPDATE_CITTADINANZA_1_CONTRAENTE_FISICO    = 'UPDATE_CITTADINANZA_1_CONTRAENTE_FISICO';
export const UPDATE_CITTADINANZA_2_CONTRAENTE_FISICO    = 'UPDATE_CITTADINANZA_2_CONTRAENTE_FISICO';
export const UPDATE_NUM_TELEFONO_CONTRAENTE_FISICO      = 'UPDATE_NUM_TELEFONO_CONTRAENTE_FISICO';
export const UPDATE_TITOLO_STUDIO                       = 'UPDATE_TITOLO_STUDIO';
export const UPDATE_TITOLO_STUDIO_ALTRO                 = 'UPDATE_TITOLO_STUDIO_ALTRO';

/* DATI RESIDENZA CONTRAENTE FISICO */
export const UPDATE_STATO_RESIDENZA_CONTRAENTE_FISICO         = 'UPDATE_STATO_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_FISICO     = 'UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_COMUNE_RESIDENZA_CONTRAENTE_FISICO        = 'UPDATE_COMUNE_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_FISICO      = 'UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_FISICO     = 'UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_FISICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_CAP_RESIDENZA_CONTRAENTE_FISICO           = 'UPDATE_CAP_RESIDENZA_CONTRAENTE_FISICO';

/* DATI DOMICILIO CONTRAENTE FISICO */
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_CONTRAENTE_FISICO = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_CONTRAENTE_FISICO';
export const UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO             = 'UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO';
export const UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO         = 'UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO';
export const UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO            = 'UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO';
export const UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO          = 'UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO';
export const UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO         = 'UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO';
export const UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO     = 'UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO';
export const UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO               = 'UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO';

/* DATI RECAPITO CONTRAENTE FISICO */
export const UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO         = 'UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO     = 'UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO        = 'UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO      = 'UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO     = 'UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO = 'UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO           = 'UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO';
export const UPDATE_PRESSO_CONTR_FISICO                      = 'UPDATE_PRESSO_CONTR_FISICO';
export const UPDATE_PRESSO_CONTRAENTE_GIURIDICO              = 'UPDATE_PRESSO_CONTRAENTE_GIURIDICO';


/* DATI FINANZIARI CONTRAENTE FISICO */
export const UPDATE_FONTE_REDDITO                            = 'UPDATE_FONTE_REDDITO';
export const UPDATE_FONTE_REDDITO_ALTRO                      = 'UPDATE_FONTE_REDDITO_ALTRO';
export const UPDATE_REDDITO_ANNUO                            = 'UPDATE_REDDITO_ANNUO';
export const UPDATE_PRIMA_CASA                               = 'UPDATE_PRIMA_CASA';
export const UPDATE_ALTRI_IMMOBILI                           = 'UPDATE_ALTRI_IMMOBILI';
export const UPDATE_PATRIMONIO_FINANZIARIO                   = 'UPDATE_PATRIMONIO_FINANZIARIO';
export const UPDATE_IMPEGNO_FINANZIARIO                      = 'UPDATE_IMPEGNO_FINANZIARIO';
export const UPDATE_MERCATO_MONETARIO                        = 'UPDATE_MERCATO_MONETARIO';
export const UPDATE_STRUMENTI_OBBLIGAZIONARI                 = 'UPDATE_STRUMENTI_OBBLIGAZIONARI';
export const UPDATE_STRUMENTI_AZIONARI                       = 'UPDATE_STRUMENTI_AZIONARI';
export const UPDATE_STRUMENTI_ASSICURATIVI                   = 'UPDATE_STRUMENTI_ASSICURATIVI';
export const UPDATE_STRUMENTI_COMPLESSI                      = 'UPDATE_STRUMENTI_COMPLESSI';

/* DATI FISCALI CONTRAENTE FISICO */
/*
export const UPDATE_TASSE_STATI_UNITI_CONTRAENTE_FISICO              = 'UPDATE_TASSE_STATI_UNITI_CONTRAENTE_FISICO';
export const UPDATE_TIN_GIIN_CONTRAENTE_FISICO                       = 'UPDATE_TIN_GIIN_CONTRAENTE_FISICO';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_CONTRAENTE_FISICO = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_CONTRAENTE_FISICO';
export const UPDATE_STATO_RESIDENZA_FISCALE_1_CONTRAENTE_FISICO      = 'UPDATE_STATO_RESIDENZA_FISCALE_1_CONTRAENTE_FISICO';
export const UPDATE_NIF_RESIDENZA_FISCALE_1_CONTRAENTE_FISICO        = 'UPDATE_NIF_RESIDENZA_FISCALE_1_CONTRAENTE_FISICO';
export const UPDATE_STATO_RESIDENZA_FISCALE_2_CONTRAENTE_FISICO      = 'UPDATE_STATO_RESIDENZA_FISCALE_2_CONTRAENTE_FISICO';
export const UPDATE_NIF_RESIDENZA_FISCALE_2_CONTRAENTE_FISICO        = 'UPDATE_NIF_RESIDENZA_FISCALE_2_CONTRAENTE_FISICO';
*/

/* DATI CONSENSO COMUNICAZIONI ELETTRONICHE CONTRAENTE FISICO/GIURIDICO */
export const UPDATE_FL_CONSENSO_COM_ELETTR    = 'UPDATE_FL_CONSENSO_COM_ELETTR';
export const UPDATE_EMAIL_CONSENSO_COM_ELETTR = 'UPDATE_EMAIL_CONSENSO_COM_ELETTR';

/* DATI GENERALI CONTRAENTE GIURIDICO */
export const UPDATE_CODICE_FISCALE_CONTRAENTE_GIURIDICO    = 'UPDATE_CODICE_FISCALE_CONTRAENTE_GIURIDICO';
export const UPDATE_RAGIONE_SOCIALE_CONTRAENTE_GIURIDICO   = 'UPDATE_RAGIONE_SOCIALE_CONTRAENTE_GIURIDICO';
export const UPDATE_DATA_COSTITUZIONE_CONTRAENTE_GIURIDICO = 'UPDATE_DATA_COSTITUZIONE_CONTRAENTE_GIURIDICO';

/* DATI RESIDENZA CONTRAENTE GIURIDICO */
export const UPDATE_STATO_RESIDENZA_CONTRAENTE_GIURIDICO         = 'UPDATE_STATO_RESIDENZA_CONTRAENTE_GIURIDICO';
export const UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_GIURIDICO     = 'UPDATE_PROVINCIA_RESIDENZA_CONTRAENTE_GIURIDICO';
export const UPDATE_COMUNE_RESIDENZA_CONTRAENTE_GIURIDICO        = 'UPDATE_COMUNE_RESIDENZA_CONTRAENTE_GIURIDICO';
export const UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_GIURIDICO      = 'UPDATE_TOPONIMO_RESIDENZA_CONTRAENTE_GIURIDICO';
export const UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_GIURIDICO     = 'UPDATE_INDIRIZZO_RESIDENZA_CONTRAENTE_GIURIDICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_GIURIDICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_CONTRAENTE_GIURIDICO';
export const UPDATE_CAP_RESIDENZA_CONTRAENTE_GIURIDICO           = 'UPDATE_CAP_RESIDENZA_CONTRAENTE_GIURIDICO';

/* DATI FISCALI CONTRAENTE GIURIDICO */
/*
export const UPDATE_TASSE_STATI_UNITI_CONTRAENTE_GIURIDICO              = 'UPDATE_TASSE_STATI_UNITI_CONTRAENTE_GIURIDICO';
export const UPDATE_TIN_GIIN_CONTRAENTE_GIURIDICO                       = 'UPDATE_TIN_GIIN_CONTRAENTE_GIURIDICO';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_CONTRAENTE_GIURIDICO = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_CONTRAENTE_GIURIDICO';
export const UPDATE_STATO_RESIDENZA_FISCALE_1_CONTRAENTE_GIURIDICO      = 'UPDATE_STATO_RESIDENZA_FISCALE_1_CONTRAENTE_GIURIDICO';
export const UPDATE_NIF_RESIDENZA_FISCALE_1_CONTRAENTE_GIURIDICO        = 'UPDATE_NIF_RESIDENZA_FISCALE_1_CONTRAENTE_GIURIDICO';
export const UPDATE_STATO_RESIDENZA_FISCALE_2_CONTRAENTE_GIURIDICO      = 'UPDATE_STATO_RESIDENZA_FISCALE_2_CONTRAENTE_GIURIDICO';
export const UPDATE_NIF_RESIDENZA_FISCALE_2_CONTRAENTE_GIURIDICO        = 'UPDATE_NIF_RESIDENZA_FISCALE_2_CONTRAENTE_GIURIDICO';
*/

/* DATI RECAPITO CONTRAENTE GIURIDICO */
export const UPDATE_STATO_RECAPITO_CONTRAENTE_GIURIDICO         = 'UPDATE_STATO_RECAPITO_CONTRAENTE_GIURIDICO';
export const UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_GIURIDICO     = 'UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_GIURIDICO';
export const UPDATE_COMUNE_RECAPITO_CONTRAENTE_GIURIDICO        = 'UPDATE_COMUNE_RECAPITO_CONTRAENTE_GIURIDICO';
export const UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_GIURIDICO      = 'UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_GIURIDICO';
export const UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_GIURIDICO     = 'UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_GIURIDICO';
export const UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_GIURIDICO = 'UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_GIURIDICO';
export const UPDATE_CAP_RECAPITO_CONTRAENTE_GIURIDICO           = 'UPDATE_CAP_RECAPITO_CONTRAENTE_GIURIDICO';

/* DATI FINANZIARI CONTRAENTE GIURIDICO */
export const UPDATE_PATRIMONIO_FINANZIARIO_GIURIDICO            = 'UPDATE_PATRIMONIO_FINANZIARIO_GIURIDICO';
export const UPDATE_SEDI_CONTRAENTE_GIURIDICO                   = 'UPDATE_SEDI_CONTRAENTE_GIURIDICO';
export const UPDATE_ALTRI_IMMOBILI_GIURIDICO                    = 'UPDATE_ALTRI_IMMOBILI_GIURIDICO';
export const UPDATE_MERCATO_MONETARIO_GIURIDICO                 = 'UPDATE_MERCATO_MONETARIO_GIURIDICO';
export const UPDATE_STRUMENTI_OBBLIGAZIONARI_GIURIDICO          = 'UPDATE_STRUMENTI_OBBLIGAZIONARI_GIURIDICO';
export const UPDATE_STRUMENTI_AZIONARI_GIURIDICO                = 'UPDATE_STRUMENTI_AZIONARI_GIURIDICO';
export const UPDATE_STRUMENTI_ASSICURATIVI_GIURIDICO            = 'UPDATE_STRUMENTI_ASSICURATIVI_GIURIDICO';
export const UPDATE_STRUMENTI_COMPLESSI_GIURIDICO               = 'UPDATE_STRUMENTI_COMPLESSI_GIURIDICO';
export const UPDATE_FATTURATO_GIURIDICO                         = 'UPDATE_FATTURATO_GIURIDICO';

/* DATI ESECUTORE FISICO */
export const UPDATE_ANAGRAFICA_VALIDA_ESECUTORE_FISICO   = 'UPDATE_ANAGRAFICA_VALIDA_ESECUTORE_FISICO';
export const UPDATE_FL_ESECUTORE_FISICO                  = 'UPDATE_FL_ESECUTORE_FISICO';
export const UPDATE_RELAZ_CONTR_ESECUTORE_FISICO         = 'UPDATE_RELAZ_CONTR_ESECUTORE_FISICO';
export const UPDATE_FL_DELEGA_ESECUTORE_FISICO           = 'UPDATE_FL_DELEGA_ESECUTORE_FISICO';
export const UPDATE_ORIGINE_FONDI_ESECUTORE_FISICO       = 'UPDATE_ORIGINE_FONDI_ESECUTORE_FISICO';
export const UPDATE_ORIGINE_FONDI_ALTRO_ESECUTORE_FISICO = 'UPDATE_ORIGINE_FONDI_ALTRO_ESECUTORE_FISICO';
export const UPDATE_SESSO_ESECUTORE_FISICO               = 'UPDATE_SESSO_ESECUTORE_FISICO';
export const UPDATE_CODICE_FISCALE_ESECUTORE_FISICO      = 'UPDATE_CODICE_FISCALE_ESECUTORE_FISICO';
export const UPDATE_TIPO_RAPPRESENTANZA_ESECUTORE        = 'UPDATE_TIPO_RAPPRESENTANZA_ESECUTORE';
export const UPDATE_ALTRA_RAPPRESENTANZA                 = 'UPDATE_ALTRA_RAPPRESENTANZA';
//export const UPDATE_CONTROLLO_CODICE_FISCALE_ESECUTORE_FISICO = 'UPDATE_CONTROLLO_CODICE_FISCALE_ESECUTORE_FISICO';
export const UPDATE_CODICE_FISCALE_VALIDO_ESECUTORE_FISICO = 'UPDATE_CODICE_FISCALE_VALIDO_ESECUTORE_FISICO';
export const UPDATE_COGNOME_ESECUTORE_FISICO             = 'UPDATE_COGNOME_ESECUTORE_FISICO';
export const UPDATE_NOME_ESECUTORE_FISICO                = 'UPDATE_NOME_ESECUTORE_FISICO';
export const UPDATE_DATA_NASCITA_ESECUTORE_FISICO        = 'UPDATE_DATA_NASCITA_ESECUTORE_FISICO';
export const UPDATE_STATO_NASCITA_ESECUTORE_FISICO       = 'UPDATE_STATO_NASCITA_ESECUTORE_FISICO';
export const UPDATE_PROVINCIA_NASCITA_ESECUTORE_FISICO   = 'UPDATE_PROVINCIA_NASCITA_ESECUTORE_FISICO';
export const UPDATE_COMUNE_NASCITA_ESECUTORE_FISICO      = 'UPDATE_COMUNE_NASCITA_ESECUTORE_FISICO';
export const UPDATE_CITTADINANZA_1_ESECUTORE_FISICO      = 'UPDATE_CITTADINANZA_1_ESECUTORE_FISICO';
export const UPDATE_CITTADINANZA_2_ESECUTORE_FISICO      = 'UPDATE_CITTADINANZA_2_ESECUTORE_FISICO';
export const UPDATE_NUM_TELEFONO_ESECUTORE_FISICO        = 'UPDATE_NUM_TELEFONO_ESECUTORE_FISICO';
export const UPDATE_ALTRO_RELAZ_CONTR                    = 'UPDATE_ALTRO_RELAZ_CONTR';
export const UPDATE_PRESSO_FISICO_ESECUTORE              = 'UPDATE_PRESSO_FISICO_ESECUTORE';

/* DATI RESIDENZA ESECUTORE FISICO */
export const UPDATE_STATO_RESIDENZA_ESECUTORE_FISICO         = 'UPDATE_STATO_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_PROVINCIA_RESIDENZA_ESECUTORE_FISICO     = 'UPDATE_PROVINCIA_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_COMUNE_RESIDENZA_ESECUTORE_FISICO        = 'UPDATE_COMUNE_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_TOPONIMO_RESIDENZA_ESECUTORE_FISICO      = 'UPDATE_TOPONIMO_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_INDIRIZZO_RESIDENZA_ESECUTORE_FISICO     = 'UPDATE_INDIRIZZO_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_ESECUTORE_FISICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_CAP_RESIDENZA_ESECUTORE_FISICO           = 'UPDATE_CAP_RESIDENZA_ESECUTORE_FISICO';

/* DATI RECAPITO ESECUTORE FISICO */
export const UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE         = 'UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO_ESECUTORE     = 'UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO_ESECUTORE        = 'UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE      = 'UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE     = 'UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE = 'UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO_ESECUTORE           = 'UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO_ESECUTORE';

/* DATI DOMICILIO ESECUTORE FISICO */
export const UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE         = 'UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE     = 'UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE        = 'UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE      = 'UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE     = 'UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE = 'UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE           = 'UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE';
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_ESECUTORE               = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_ESECUTORE';

/* DATI CORRISPONDENZA ESECUTORE FISICO */
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_FISICO        = 'UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_FISICO';
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_GIURIDICO     = 'UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_GIURIDICO';
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_FISICO              = 'UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_FISICO';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_FISICO               = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_FISICO';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_GIURIDICO            = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_GIURIDICO';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_ESECUTORE_FISICO           = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_ESECUTORE_FISICO';


/* DATI ATTIVITA ECONOMICA ESECUTORE FISICO */
export const UPDATE_STATO_ATT_PREVAL_ESECUTORE                         = 'UPDATE_STATO_ATT_PREVAL_ESECUTORE';
/* DATI ANTIRICICLAGGIO  */
export const UPDATE_TIPO_DOCUMENTO           = 'UPDATE_TIPO_DOCUMENTO';
export const UPDATE_NUMERO_DOCUMENTO         = 'UPDATE_NUMERO_DOCUMENTO';
export const UPDATE_ENTE_RILASCIO            = 'UPDATE_ENTE_RILASCIO';
export const UPDATE_DATA_RILASCIO            = 'UPDATE_DATA_RILASCIO';
export const UPDATE_DATA_SCADENZA            = 'UPDATE_DATA_SCADENZA';
export const UPDATE_STATO_RILASCIO           = 'UPDATE_STATO_RILASCIO';
export const UPDATE_PROVINCIA_RILASCIO       = 'UPDATE_PROVINCIA_RILASCIO';
export const UPDATE_COMUNE_RILASCIO          = 'UPDATE_COMUNE_RILASCIO';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE = 'UPDATE_FL_ESPOSTO_POLITICAMENTE';
export const UPDATE_FL_PEP_IN_CARICA         = 'UPDATE_FL_PEP_IN_CARICA';
export const UPDATE_CARICA_PEP               = 'UPDATE_CARICA_PEP';
export const UPDATE_CARICA_PUBBLICA_NO_PEP   = 'UPDATE_CARICA_PUBBLICA_NO_PEP';

/* DATI ATTIVITA ECONOMICA FISICO / GIURIDICO / RAPP LEGALE / MANDATO FID */
export const UPDATE_TIPO_SOCIETA                       = 'UPDATE_TIPO_SOCIETA';
export const UPDATE_ATT_PREVAL_SVOLTA                  = 'UPDATE_ATT_PREVAL_SVOLTA';
export const UPDATE_PROVINCIA_ATT_PREVAL               = 'UPDATE_PROVINCIA_ATT_PREVAL';
export const UPDATE_COMUNE_ATT_PREVAL                  = 'UPDATE_COMUNE_ATT_PREVAL';
export const UPDATE_CAP_ATT_PREVAL                     = 'UPDATE_CAP_ATT_PREVAL';
export const UPDATE_COD_SAE                            = 'UPDATE_COD_SAE';
export const UPDATE_COD_ATECO                          = 'UPDATE_COD_ATECO';
export const UPDATE_FL_ALLEGATI                        = 'UPDATE_FL_ALLEGATI';
export const UPDATE_ALLEGATI_ALTRO	                   = 'UPDATE_ALLEGATI_ALTRO';
export const UPDATE_FL_PAESI_BLACK_LIST                = 'UPDATE_FL_PAESI_BLACK_LIST';
export const UPDATE_RELAZ_PAESI_LIST               	   = 'UPDATE_RELAZ_PAESI_LIST'; //2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
export const UPDATE_FL_ENT_FINANZ_PASSIVA              = 'UPDATE_FL_ENT_FINANZ_PASSIVA';
export const UPDATE_STATO_ATT_PREVAL                   = 'UPDATE_STATO_ATT_PREVAL';
export const UPDATE_ATTIVITA_PRECEDENTE                = 'UPDATE_ATTIVITA_PRECEDENTE';
export const UPDATE_AMBITO_ATTIVITA                    = 'UPDATE_AMBITO_ATTIVITA';
export const UPDATE_SOCIETA_AZ_FIDUCIARIO              = 'UPDATE_SOCIETA_AZ_FIDUCIARIO';
export const UPDATE_SOCIETA_QUOTATA                    = 'UPDATE_SOCIETA_QUOTATA';
export const UPDATE_MOTIVO_RELAZIONE                   = 'UPDATE_MOTIVO_RELAZIONE';
export const UPDATE_CLASSE_BENEFICIARI                 = 'UPDATE_CLASSE_BENEFICIARI';
export const UPDATE_STAT_ATT_PREV_SVOLTA               = 'UPDATE_STAT_ATT_PREV_SVOLTA';
export const UPDATE_NUM_MANDATO_FIDUCIARIO             = 'UPDATE_NUM_MANDATO_FIDUCIARIO';
export const UPDATE_INTESTATARIO_MANDATO               = 'UPDATE_INTESTATARIO_MANDATO';
export const UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT        = 'UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT';
//2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List export const TOGLE_PAESE_BLACK_LIST                    = 'TOGLE_PAESE_BLACK_LIST';
export const DELETE_PAESE_BLACK_LIST                   = 'DELETE_PAESE_BLACK_LIST';
export const UPDATE_COD_ATECO_RAP_LEGALE               = 'UPDATE_COD_ATECO_RAP_LEGALE';


/* DATI SCOPO E NATURA RAPPORTO */
export const UPDATE_SCOPO_NATURA_RAPPORTO = 'UPDATE_SCOPO_NATURA_RAPPORTO';
export const UPDATE_ORIGINE_FONDI         = 'UPDATE_ORIGINE_FONDI';
export const UPDATE_ALTRA_ORIGINE_FONDI   = 'UPDATE_ALTRA_ORIGINE_FONDI';
export const UPDATE_STIPULATO_PER_CONTO   = 'UPDATE_STIPULATO_PER_CONTO';
export const TOGGLE_ORIGINE_FONDI_LIST    = 'TOGGLE_ORIGINE_FONDI_LIST';
export const UPDATE_INTERMEDIARIO_BANCARIO = 'UPDATE_INTERMEDIARIO_BANCARIO';
/* export const UPDATE_SCOPO_NATURA_CONTRATTO= 'UPDATE_SCOPO_NATURA_CONTRATTO'; */
export const UPDATE_SOGGETTO_DA_TUTELARE  = 'UPDATE_SOGGETTO_DA_TUTELARE';
export const UPDATE_ALTRI_SOGGETTI        = 'UPDATE_ALTRI_SOGGETTI';
export const UPDATE_STATO_PROVENIENZA_SOMME = 'UPDATE_STATO_PROVENIENZA_SOMME';

/* DATI RESIDENZA RAPPRESENTANTE LEGALE */
export const UPDATE_STATO_RESIDENZA_RAPP_LEGALE         = 'UPDATE_STATO_RESIDENZA_RAPP_LEGALE';
export const UPDATE_PROVINCIA_RESIDENZA_RAPP_LEGALE     = 'UPDATE_PROVINCIA_RESIDENZA_RAPP_LEGALE';
export const UPDATE_COMUNE_RESIDENZA_RAPP_LEGALE        = 'UPDATE_COMUNE_RESIDENZA_RAPP_LEGALE';
export const UPDATE_TOPONIMO_RESIDENZA_RAPP_LEGALE      = 'UPDATE_TOPONIMO_RESIDENZA_RAPP_LEGALE';
export const UPDATE_INDIRIZZO_RESIDENZA_RAPP_LEGALE     = 'UPDATE_INDIRIZZO_RESIDENZA_RAPP_LEGALE';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_RAPP_LEGALE = 'UPDATE_NUMERO_CIVICO_RESIDENZA_RAPP_LEGALE';
export const UPDATE_CAP_RESIDENZA_RAPP_LEGALE           = 'UPDATE_CAP_RESIDENZA_RAPP_LEGALE';
export const UPDATE_ANAGRAFICA_VALIDA_RAPP_LEGALE       = 'UPDATE_ANAGRAFICA_VALIDA_RAPP_LEGALE';

export const UPDATE_PRESENTI_TITOLARE_EFF               = 'UPDATE_PRESENTI_TITOLARE_EFF';

/* DATI DOMICILIO RAPPRESENTANTE LEGALE */
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_RAPP_LEGALE = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_RAPP_LEGALE';
export const UPDATE_STATO_DOMICILIO_RAPP_LEGALE             = 'UPDATE_STATO_DOMICILIO_RAPP_LEGALE';
export const UPDATE_PROVINCIA_DOMICILIO_RAPP_LEGALE         = 'UPDATE_PROVINCIA_DOMICILIO_RAPP_LEGALE';
export const UPDATE_COMUNE_DOMICILIO_RAPP_LEGALE            = 'UPDATE_COMUNE_DOMICILIO_RAPP_LEGALE';
export const UPDATE_TOPONIMO_DOMICILIO_RAPP_LEGALE          = 'UPDATE_TOPONIMO_DOMICILIO_RAPP_LEGALE';
export const UPDATE_INDIRIZZO_DOMICILIO_RAPP_LEGALE         = 'UPDATE_INDIRIZZO_DOMICILIO_RAPP_LEGALE';
export const UPDATE_NUMERO_CIVICO_DOMICILIO_RAPP_LEGALE     = 'UPDATE_NUMERO_CIVICO_DOMICILIO_RAPP_LEGALE';
export const UPDATE_CAP_DOMICILIO_RAPP_LEGALE               = 'UPDATE_CAP_DOMICILIO_RAPP_LEGALE';

/* DATI FISCALI FISICO / GIURIDICO / TITOLARE EFFETTIVO */
export const UPDATE_TASSE_STATI_UNITI              = 'UPDATE_TASSE_STATI_UNITI';
export const UPDATE_TIN_GIIN                       = 'UPDATE_TIN_GIIN';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA';
export const UPDATE_STATO_RESIDENZA_FISCALE_1      = 'UPDATE_STATO_RESIDENZA_FISCALE_1';
export const UPDATE_NIF_RESIDENZA_FISCALE_1        = 'UPDATE_NIF_RESIDENZA_FISCALE_1';
export const UPDATE_STATO_RESIDENZA_FISCALE_2      = 'UPDATE_STATO_RESIDENZA_FISCALE_2';
export const UPDATE_NIF_RESIDENZA_FISCALE_2        = 'UPDATE_NIF_RESIDENZA_FISCALE_2';
export const UPDATE_DATI_PERSONALI_CONTRAENTE       = 'UPDATE_DATI_PERSONALI_CONTRAENTE';

/* DATI FISCALI TITOLARE EFFETTIVO */
export const UPDATE_PERC_OWNERSHIP_TITOLARE_EFF    = 'UPDATE_PERC_OWNERSHIP_TITOLARE_EFF';

export const INIZIALIZZA_CONTRAENTE_FISICO = "INIZIALIZZA_CONTRAENTE_FISICO";
export const INIZIALIZZA_CONTRAENTE_GIURIDICO = "INIZIALIZZA_CONTRAENTE_GIURIDICO";

/* DATI SOCIETARI GIURIDICO */
export const ISCRIZIONE_REGISTRO = "ISCRIZIONE_REGISTRO";
export const UPDATE_DATI_SOCIETARI = "UPDATE_DATI_SOCIETARI";
export const NUMERO_REGISTRO = "NUMERO_REGISTRO";
export const UPDATE_NOME_ALBO = "UPDATE_NOME_ALBO";
export const ISCRIZIONE_ALBO = "ISCRIZIONE_ALBO";
export const DATA_ISCRIZIONE_ALBO = "DATA_ISCRIZIONE_ALBO";
export const SEDE_ATTIVITA_STATO = "SEDE_ATTIVITA_STATO";
export const NUMERO_ISCRIZIONE_ALBO = "NUMERO_ISCRIZIONE_ALBO"




