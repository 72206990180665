import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

//import DeleteIcon from '@material-ui/icons/Delete';
import LensIcon from '@material-ui/icons/Lens';

import { textTrim, concatena, showSezione } from '../../../utility/genericUtility';
import { COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002,
         PRODOTTO_TIPOLOGIA_ALTRO, PRODOTTO_TIPOLOGIA_MULTINVEST, PRODOTTO_TIPOLOGIA_UNIT_LINKED, 
         RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, 
         CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, CODICE_SAE_FIDUCIARIA_GESTIONE, CODICE_QUALIFICA_FIDUCIANTE,
         DATI_NESSUNO, DATI_PIANO_EROGAZIONI_PERIODICHE, DATI_TAKE_PROFIT, DATI_LIFE_CYCLE, DATI_PIANO_SPOSTAMENTO_AUTOMATICO,
       } from '../../../utility/jsConstants';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.color.greyLight,
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    color: 'inherit',
  },
  body: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    color: 'inherit',
  },
}))(TableCell);

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  table: {	  
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginTop: 5,
    padding: 0,
  },
  row: {	 
	 paddingLeft:'0.4em',
	 paddingRight:'0.4em',
/*	 backgroundColor: theme.palette.color.greyLight, */
	 backgroundColor: theme.palette.color.white,
	 borderColor: theme.palette.color.marine,
/*	 borderStyle: 'solid', */
/*	    border: 1, */
  },
  rowWidthCol1: {
	width: '25%',
  },
  rowWidthCol2: {
    width: '30%',
  },
//  rowWidthCol3: {
//    width: '15%',
//  },
//  rowWidthCol4: {
//    width: '10%',
//  },
  rowWidthCol5: {
    width: '10%',
  },
  rowScretch: {	 
	  height: 0,
  },  
  textField: theme.textField,
  marginGrid: {
    marginLeft: theme.spacing(1.5),
  },
  tableWrapper: {
	 width:'100%',
  },  
  svgDisabled: {
//	  width: '70%',
      height: '24%',
      margin: 'auto',
//      verticalAlign: 'baseline',
      fill: theme.palette.color.windowsBlue,
  },
  delIcon: {
    margin: 0,
    '&:hover': {
    	color:  theme.palette.color.windowsBlue70,
    },
  },
  lensIconOK: {
    margin: 0,
    color: theme.palette.color.green,
  },
  lensIconWARNING: {
    margin: 0,
    color: theme.palette.color.gold,
  },
  lensIconERROR: {
    margin: 0,
    color: theme.palette.color.red,
  },  
});

const mapStateToProps = state => {
//  console.log('#RTCFA DatiRiepilogo.mapStateToProps.state', state);
  return {
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,

	dominio: state.areaDatiDominioReducer.dominio,
    prodotto: state.areaProdottoReducer.prodotto,    	
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,
    assicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,
    percBenMorteTotale: state.areaBenefMorteReducer.percBenMorteTotale,
    
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList,
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,
    percBenVitaTotale: state.areaBenefVitaReducer.percBenVitaTotale,
    
    referenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico,
    titolareEff: state.areaTitolareEffReducer.titolareEff,
    titolareEffList: state.areaTitolareEffReducer.titolareEffList,

    benefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico,
    benefVitaGiuridico: state.areaBenefMorteReducer.benefVitaGiuridico,

    titolareEffBenefMorteGiuridico: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte,
    titolareEffBenefMorteGiuridicoList: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorteList,

    titolareEffBenefVitaGiuridico: state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVita,
    titolareEffBenefVitaGiuridicoList: state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVitaList,

/* CARATTERISTICHE CONTRATTO ALTRI */
    datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,
/* CARATTERISTICHE CONTRATTO MI-ALTRI */
    datiModalitaPagamento: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento,
    datiModPagAssegno: state.areaDatiModPagAssegnoReducer.caratteristicheContratto.datiModPagAssegno,
    datiModPagBonifico: state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonifico, 
    datiModPagReimpiego: state.areaDatiModPagReimpiegoReducer.caratteristicheContratto.datiModPagReimpiego,  
/* CARATTERISTICHE CONTRATTO MI */
    datiPremio: state.areaDatiPremioReducer.caratteristicheContratto.datiPremio,
    datiRipartizionePremio: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio,
    datiRipartizionePremioFondi: state.areaDatiRipartizionePremioFondiReducer.caratteristicheContratto.datiRipartizionePremioFondi,
    datiOpzioniContrattuali: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali,
    datiPianoErogazioniPeriodiche: state.areaDatiPianoErogazioniPeriodicheReducer.caratteristicheContratto.datiPianoErogazioniPeriodiche,
    datiTakeProfit: state.areaDatiTakeProfitReducer.caratteristicheContratto.datiTakeProfit,    
    datiLifeCycle: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali.datiLifeCycle,
/* DICHIARAZIONE CONSENSI */
    datiIntermediario: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario,
    datiConsenso: state.areaDichConsensoReducer.dichiarazioni.datiConsenso,     
/* QUESTIONARI */
    questionario: state.areaDatiQuestionarioReducer.questionario,
  };
};

const mapDispatchToProps = dispatch => {
//  console.log('#RTCFA DatiRiepilogo.mapDispatchToProps.dispatch', dispatch);
  return {
  };
};
  
class DatiRiepilogo extends React.Component {
	
  constructor(props) {
    super(props);
    this.state = {    	
    };
  };

  createDatiRiepilogoListContraenteFisicoFrom = (contraenteFisico) => {
    const retList = [];
    const riepilogoFisicoItem = this.createDatiRiepilogoItemFromPersFis(contraenteFisico.datiGenerali, contraenteFisico.datiResidenza, contraenteFisico.anagraficaValida);
    if ((riepilogoFisicoItem !== undefined) && (riepilogoFisicoItem !== null)) {
      retList.push(riepilogoFisicoItem);
    }
    return retList;
  }
    
  createDatiRiepilogoListContraenteGiuridicoFrom = (contraenteGiuridico) => {
    const retList = [];
    const riepilogoGiuridicoItem = this.createDatiRiepilogoItemFromPersGiur(contraenteGiuridico.datiGenerali, contraenteGiuridico.datiResidenza, contraenteGiuridico.anagraficaValida);
    if ((riepilogoGiuridicoItem !== undefined) && (riepilogoGiuridicoItem !== null)) {
      retList.push(riepilogoGiuridicoItem);
    }
    return retList;
  }

  createDatiRiepilogoListAssicuratoFrom = (anagraficaFisica, anagraficaValida) => {
    const retList = [];
    const riepilogoFisicoItem = this.createDatiRiepilogoItemFromPersFis(anagraficaFisica.datiGenerali, anagraficaFisica.datiResidenza, anagraficaValida);
    if ((riepilogoFisicoItem !== undefined) && (riepilogoFisicoItem !== null)) {
      retList.push(riepilogoFisicoItem);
    }
    return retList;
  }

  createDatiRiepilogoListFromBenefFisicoList = (benefFisicoList, isVita) => {
    let retList = [];
    if ((benefFisicoList !== undefined) && (benefFisicoList !== null) && (benefFisicoList.length > 0)) {
      benefFisicoList.map(item => {
        let riepilogoItem = null;
        if (isVita) {
          riepilogoItem = this.createDatiRiepilogoItemFromPersFis(item.datiGeneraliBenefVita.datiGeneraliMinimi, item.datiResidenza, item.anagraficaValida);
          const percBenefVita = textTrim(item.datiGeneraliBenefVita.percBeneficiario, true);
          riepilogoItem.percBeneficiario = percBenefVita === '' ? 0 : percBenefVita;
        } else {
          riepilogoItem = this.createDatiRiepilogoItemFromPersFis(item.datiGeneraliBenefMorte.datiGeneraliMinimi, item.datiResidenza, item.anagraficaValida);
          const percBenefMorte = textTrim(item.datiGeneraliBenefMorte.percBeneficiario, true);
          riepilogoItem.percBeneficiario = percBenefMorte === '' ? 0 : percBenefMorte;
        }
        retList.push(riepilogoItem);
      });
    }
    return retList;
  }

  createDatiRiepilogoListFromBenefGiuridicoList = (benefGiuridicoList) => {
	let retList = [];
    if ((benefGiuridicoList !== undefined) && (benefGiuridicoList !== null) && (benefGiuridicoList.length > 0)) {
      benefGiuridicoList.map(item => {			
        let riepilogoItem = this.createDatiRiepilogoItemFromPersGiur(item.datiGeneraliBenefGiuridico.datiGenerali, item.datiResidenza, item.anagraficaValida);
        const percBenef = textTrim(item.datiGeneraliBenefGiuridico.percBeneficiario, true);
        riepilogoItem.percBeneficiario = percBenef === ''  ? 0 : percBenef;
        retList.push(riepilogoItem);
      });
    }	
    return retList;
  }

  createDatiRiepilogoListTitEffBenefVitaList = (benefVitaGiuridicoList) => {
    let retList = [];
    if(benefVitaGiuridicoList && benefVitaGiuridicoList.length > 0) {
      benefVitaGiuridicoList.map(item => {
        if(item.titolareEffBenefVitaListInBenef && item.titolareEffBenefVitaListInBenef.length > 0) {
          item.titolareEffBenefVitaListInBenef.map(el => {
            const datiTitolare = el.datiTitolareBenefVitaEff;
            const datiGenerali = (datiTitolare.datiGenerali.codiceFiscale) ? datiTitolare.datiGenerali : datiTitolare.datiGeneraliMinimi;
            let riepilogoItem = this.createDatiRiepilogoItemFromPersFis(datiGenerali, datiTitolare.datiResidenza, datiTitolare.anagraficaValida);
            riepilogoItem.ragioneSociale = item.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale;
            retList.push(riepilogoItem);            
          })
        }
      })
    }
    return retList;
  }
  
  createDatiRiepilogoListTitEffBenefMorteList = (benefMorteGiuridicoList) => {
    let retList = [];
    if(benefMorteGiuridicoList && benefMorteGiuridicoList.length > 0) {
      benefMorteGiuridicoList.map(item => {
        if(item.titolareEffBenefMorteListInBenef && item.titolareEffBenefMorteListInBenef.length > 0) {
          item.titolareEffBenefMorteListInBenef.map(el => {
            const datiTitolare = el.datiTitolareBenefMorteEff;
            const datiGenerali = (datiTitolare.datiGenerali.codiceFiscale) ? datiTitolare.datiGenerali : datiTitolare.datiGeneraliMinimi;
            let riepilogoItem = this.createDatiRiepilogoItemFromPersFis(datiGenerali, datiTitolare.datiResidenza, datiTitolare.anagraficaValida);
            riepilogoItem.ragioneSociale = item.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale;
            retList.push(riepilogoItem);            
          })
        }
      })
    }
    return retList;
  }

  createDatiRiepilogoListFromBenefGenericoList = (benefGenericoList) => {
	let retList = [];
	if ((benefGenericoList !== undefined) && (benefGenericoList !== null) && (benefGenericoList.length > 0)) {
      benefGenericoList.map(item => {
        const riepilogoItem = this.createDatiRiepilogoItemFromGenerico(item.datiGenerali, item.anagraficaValida);
        const percBenef = textTrim(item.datiGenerali.percBeneficiario, true);
        riepilogoItem.percBeneficiario = percBenef === '' ? 0 : percBenef;
        retList.push(riepilogoItem);
      });
    }	
    return retList;
  }

  createDatiRiepilogoListFromReferenteTerzoFisico = (referenteTerzoFisico) => {
    const retList = [];
    if (referenteTerzoFisico.datiReferenteTerzo.datiGenerali.flReferenteTerzo === 'S') {
	    const riepilogoFisicoItem = this.createDatiRiepilogoItemFromPersFis(referenteTerzoFisico.datiReferenteTerzo.datiGenerali, referenteTerzoFisico.datiReferenteTerzo.datiResidenza, referenteTerzoFisico.anagraficaValida);
	    if ((riepilogoFisicoItem !== undefined) && (riepilogoFisicoItem !== null)) {
	      retList.push(riepilogoFisicoItem);
	    }
    }
    return retList;
  }

  createDatiRiepilogoListFromTitolareEffList = (titolareEffList) => {
	let retList = [];
	if ((titolareEffList !== undefined) && (titolareEffList !== null) && (titolareEffList.length > 0)) {
		titolareEffList.map(item => {
        const riepilogoItem = this.createDatiRiepilogoItemFromPersFis(item.datiTitolareEff.datiGenerali, item.datiTitolareEff.datiResidenza, item.anagraficaValida);
        const percOwnerShip = textTrim(item.datiTitolareEff.percOwnerShip, true);
        riepilogoItem.percOwnerShip = percOwnerShip === '' ? 0 : percOwnerShip;
        retList.push(riepilogoItem);
      });
    }	
    return retList;
  }
  
  createDatiRiepilogoItemFromPersFis = (datiGenerali, datiResidenza, anagraficaValida) => {
    const nominativo = concatena(datiGenerali.cognome, ' ', datiGenerali.nome, true);
    const codiceFiscale = textTrim(datiGenerali.codiceFiscale, true).toUpperCase();
    const item = { nominativo , codiceFiscale, anagraficaValida };
    return item;
  }

  createDatiRiepilogoItemFromPersGiur = (datiGenerali, datiResidenza, anagraficaValida) => {
    const nominativo = concatena(datiGenerali.ragioneSociale, '', '', true);
    const codiceFiscale = textTrim(datiGenerali.codiceFiscale, true).toUpperCase();	
    const item = { nominativo , codiceFiscale, anagraficaValida };
    return item;
  }

  createDatiRiepilogoItemFromGenerico = (datiGenerali, anagraficaValida) => {	
    let nominativo = null;
    const descrBenef = textTrim(datiGenerali.descrBenefGenerico, true);
    if (descrBenef !== '' && textTrim(datiGenerali.benefGenerico, true) !== '') {
      nominativo = descrBenef;
    } else if (textTrim(datiGenerali.descrBenefEsteso, true) !== '') {
      nominativo = 'BENEFICIARIO ESTESO';
    }
    const codiceFiscale = '';
    const item = { nominativo, codiceFiscale, anagraficaValida };
    return item;
  }

  createEtichettaDatiPremioCapitaleAssicuratoFrom = (datiPremioCapitaleAssicurato) => { 
    const durataPagamentoPremi = textTrim(datiPremioCapitaleAssicurato.durataPagamentoPremi, true);
    const durata = textTrim(datiPremioCapitaleAssicurato.durata, true);
    const durataEtichetta = (durata !== '') ? ('Durata: ' + durata) : ''; 
    const durataPagamentoPremiEtichetta = (durataPagamentoPremi !== '') ? ('Durata Pag.Premi: ' + durataPagamentoPremi) : ''; 
    const premioAnnuo = textTrim(datiPremioCapitaleAssicurato.premioAnnuo, true);    
    const premioRata = textTrim(datiPremioCapitaleAssicurato.premioRata, true);    
    const premioUnico = textTrim(datiPremioCapitaleAssicurato.premioUnico, true);    
    const premioAnnuoEtichetta = (premioAnnuo !== '') ? ('Premio annuo: ' + premioAnnuo) : ''; 
//20210616    const premioRataEtichetta = (premioRata !== '') ? ('Premio rata: ' + premioRata) : ''; 
//20210616    const premioUnicoEtichetta = (premioUnico !== '') ? ('Premio unico: ' + premioUnico) : '';
    const premioRataEtichetta = (premioRata !== '') ? ('Premio di rata versato all\'atto della sottoscrizione: ' + premioRata) : ''; 
    const premioUnicoEtichetta = (premioUnico !== '') ? ('Premio di rata versato all\'atto della sottoscrizione: ' + premioUnico) : '';
    const etichetta = durataEtichetta + '  ' + durataPagamentoPremiEtichetta + '  ' + premioAnnuoEtichetta + '  ' + premioRataEtichetta + '  ' + premioUnicoEtichetta;    
    return etichetta;
  }  

  createDatiRiepilogoDatiPremioCapitaleAssicuratoFrom = (datiPremioCapitaleAssicurato) => {
    const datiPremioCapitaleAssicuratoValida = datiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida;	  
    const etichetta = (datiPremioCapitaleAssicuratoValida !== null) ? this.createEtichettaDatiPremioCapitaleAssicuratoFrom(datiPremioCapitaleAssicurato) : '';
    const item = { etichetta, datiPremioCapitaleAssicuratoValida };
    return item;
  }  

  createEtichettaDatiModalitaPagamentoFrom = (datiModalitaPagamento) => {
	const descModalitaPagamento = textTrim(datiModalitaPagamento.descModalitaPagamento, true);
	const etichetta = (descModalitaPagamento !== '') ? ('Modalita pagamento: ' + descModalitaPagamento) : ''; 
    return etichetta;
  }  
    
  createDatiRiepilogoDatiModalitaPagamentoFrom = (datiModalitaPagamento) => {
    const datiModalitaPagamentoValida = datiModalitaPagamento.datiModalitaPagamentoValida;    
    const etichetta = (datiModalitaPagamentoValida !== null) ? this.createEtichettaDatiModalitaPagamentoFrom(datiModalitaPagamento) : '';
    const item = { etichetta, datiModalitaPagamentoValida };
    return item;
  }  
 
  createEtichettaDatiPremioFrom = (datiPremio) => {  
    const premio = textTrim(datiPremio.premioUnico, true);
    const etichetta = (premio !== '') ? ('Premio: ' + premio) : ''; 
    return etichetta;
  }
  
  createDatiRiepilogoDatiPremioFrom = (datiPremio) => {
    const datiPremioValida = datiPremio.datiPremioValida;
    const etichetta = (datiPremioValida !== null) ? this.createEtichettaDatiPremioFrom(datiPremio) : '';
    const item = { etichetta, datiPremioValida };
    return item;
  }
 
  createEtichettaDatiRipartizionePremioFrom = (datiRipartizionePremio) => {
	let eGestioneSeparata = '';
	  
	const tipoProdotto = this.props.prodotto.tipo;
	if (tipoProdotto === PRODOTTO_TIPOLOGIA_MULTINVEST) {
		const gestioneSeparata = textTrim(datiRipartizionePremio.gestioneSeparata, true);
    	eGestioneSeparata = (gestioneSeparata !== '') ? ('Gestione Separata: ' + gestioneSeparata + '%,') : '';
	}
    const lineaLibera = textTrim(datiRipartizionePremio.lineaLibera, true);
// 2019-12-17 PFD:    const eLineaLibera = (lineaLibera !== '') ? (textTrim(datiRipartizionePremio.lineaLiberaCodice, true) + ': ' + lineaLibera + '%,') : '';
    const eLineaLibera = (lineaLibera !== '') ? (textTrim(datiRipartizionePremio.lineaLiberaDescrizione, true) + ': ' + lineaLibera + '%,') : '';
    const lineaGuidata = datiRipartizionePremio.lineaGuidata.filter(unCampo => textTrim(unCampo.linea, true) !== '');
//2019-12-17 PFD:    const eLineaGuidata = (lineaGuidata.length > 0) ? (textTrim(lineaGuidata[0].codiceLinea, true) + ': ' + textTrim(lineaGuidata[0].linea, true) + '%,') : '';  
    const eLineaGuidata = (lineaGuidata.length > 0) ? (textTrim(lineaGuidata[0].descrLinea, true) + ': ' + textTrim(lineaGuidata[0].linea, true) + '%,') : '';
    const etichetta = eGestioneSeparata + ' ' + eLineaLibera + ' ' + eLineaGuidata;
    return etichetta;
  }
    
  createDatiRiepilogoDatiRipartizionePremioFrom = (datiRipartizionePremio) => {	
    const datiRipartizionePremioValida = datiRipartizionePremio.datiRipartizionePremioValida;
    const etichetta = (datiRipartizionePremioValida !== null) ? this.createEtichettaDatiRipartizionePremioFrom(datiRipartizionePremio) : '';
    const item = { etichetta, datiRipartizionePremioValida };
    return item;
  }
  
  createDatiRiepilogoDatiOpzioniContrattualiFrom = (datiOpzioniContrattuali) => {
    const opzione = textTrim(datiOpzioniContrattuali.opzioneContrattuale, true);
    let etichetta = null;
    let opzioneValida = null;
    if (opzione === DATI_PIANO_EROGAZIONI_PERIODICHE) {
      etichetta = 'Piano Erogazioni Periodiche';
      opzioneValida = this.props.datiPianoErogazioniPeriodiche.datiPianoErogazioniPeriodicheValida;
    } else if (opzione === DATI_TAKE_PROFIT) {
      etichetta = 'Take Profit';
      opzioneValida = this.props.datiTakeProfit.datiTakeProfitValida;
    } else if (opzione === DATI_LIFE_CYCLE) {
      etichetta = 'Life Cycle';
      opzioneValida = this.props.datiLifeCycle.datiLifeCycleValida;
    } else if (opzione === DATI_NESSUNO) {
      etichetta = 'Nessuna';
      opzioneValida = true;
    } else if (opzione === DATI_PIANO_SPOSTAMENTO_AUTOMATICO) {
    	etichetta = 'Piano Sposamento Automatico: ' + this.props.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.descAllocazioneTarget;
        opzioneValida = this.props.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.datiPianoSpostamentoAutomaticoValida;
    }
    const item = { etichetta, opzioneValida };
    return item;
  }
  
  createDatiRiepilogoDatiIntermediarioFrom = (datiIntermediario) => {
	const datiIntermediarioValida = datiIntermediario.datiIntermediarioValida;
	const item = { datiIntermediarioValida };
    return item;
  }
  
  createDatiRiepilogoDatiConsensoFrom = (datiConsenso) => {
	const datiConsensoValida = datiConsenso.datiConsensoValida;
	const item = { datiConsensoValida };
    return item;	  
  }
 
  createDatiRiepilogoDatiQuestionariFrom_OK = (questionario) => {
    let questionarioIvass = null;
    let questionarioAnamnestico = null;
/*	if (this.props.contraenteFisico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaFisico;       
	} else if (this.props.contraenteFisico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaGiuridico;       
	}*/
	if (questionario.datiQuestionarioAdeguatezzaFisico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaFisico;       
	} else if (questionario.datiQuestionarioAdeguatezzaGiuridico !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaGiuridico;       
	}    
	const malattiaGrave = this.props.datiPremioCapitaleAssicurato.malattiaGrave;
	const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);
	if (isQuestionarioAnamnestico === true) {
      questionarioAnamnestico = questionario.datiQuestionarioAnamnestico;       
    } else {
      questionarioAnamnestico = questionario.datiQuestionarioAnamnesticoMalattiaGrave;       
    }
	const questionarioIvassValida = (questionarioIvass !== null) ? questionarioIvass.datiQuestionarioValida : null;
  const questionarioAnamnesticoValida = questionarioAnamnestico.datiQuestionarioValida;
  const questionarioSemplificatoValida = questionario.datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida; 
	const item = { questionarioIvassValida, questionarioAnamnesticoValida, questionarioSemplificatoValida };   
    return item;    
  }
  
  createDatiRiepilogoDatiQuestionariFrom = (questionario) => {
    let questionarioIvass = null;
    let questionarioAnamnestico = null;
    let questionarioSemplificato = null;
	if (questionario.datiQuestionarioAdeguatezzaFisico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaFisico;       
	} else if (questionario.datiQuestionarioAdeguatezzaGiuridico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaGiuridico;       
	}    
	const malattiaGrave = this.props.datiPremioCapitaleAssicurato.malattiaGrave;
	const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);
	if (isQuestionarioAnamnestico === true) {
      questionarioAnamnestico = questionario.datiQuestionarioAnamnestico;       
    } else {
      questionarioAnamnestico = questionario.datiQuestionarioAnamnesticoMalattiaGrave;       
    }
  if (questionario.showSemplificato) {
    questionarioSemplificato = questionario.datiQuestionarioAnamnesticoSemplificato;
  }  
	const questionarioIvassValida = (questionarioIvass !== null) ? questionarioIvass.datiQuestionarioValida : null;
//	const questionarioAnamnesticoValida = questionarioAnamnestico.datiQuestionarioValida;
  const questionarioAnamnesticoValida = (questionarioAnamnestico !== null) ? questionarioAnamnestico.datiQuestionarioValida : null;
  const questionarioSemplificatoValida = questionario.datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida;
	const item = { questionarioIvassValida, questionarioAnamnesticoValida, questionarioSemplificatoValida };   
    return item;    
  }

  createDatiRiepilogoDatiQuestionariFrom_OLD = (questionario) => {
    let questionarioIvass = null;
    let questionarioAnamnestico = null;
/*	if (this.props.contraenteFisico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaFisico;       
	} else if (this.props.contraenteFisico.controlloDati !== '') {
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaGiuridico;       
	}*/
	if (questionario.datiQuestionarioAdeguatezzaFisico.controlloDati !== '') {
//RT semaforo questionari INIZIO		
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaFisico.controlloDati;
//RT semaforo questionari FINE
/*RT      questionarioIvass = questionario.datiQuestionarioAdeguatezzaFisico; */       
	} else if (questionario.datiQuestionarioAdeguatezzaGiuridico.controlloDati !== '') {
//RT semaforo questionari INIZIO		
      questionarioIvass = questionario.datiQuestionarioAdeguatezzaGiuridico.controlloDati;		
//RT semaforo questionari FINE
/*RT      questionarioIvass = questionario.datiQuestionarioAdeguatezzaGiuridico;  */  
	}    
	const malattiaGrave = this.props.datiPremioCapitaleAssicurato.malattiaGrave;
	const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);
	if (isQuestionarioAnamnestico === true) {
//RT semaforo questionari INIZIO		
      questionarioAnamnestico = (questionario.datiQuestionarioAnamnestico.controlloDati !== '') 
                                              ? questionario.datiQuestionarioAnamnestico.controlloDati : null;
//RT semaforo questionari FINE      
/*RT      questionarioAnamnestico = questionario.datiQuestionarioAnamnestico; */      
    } else {
//RT semaforo questionari INIZIO
      questionarioAnamnestico = (questionario.datiQuestionarioAnamnesticoMalattiaGrave.controlloDati !== '') 
                                              ? questionario.datiQuestionarioAnamnesticoMalattiaGrave.controlloDati : null;
//RT semaforo questionari FINE      
/*RT      questionarioAnamnestico = questionario.datiQuestionarioAnamnesticoMalattiaGrave; */             
    }
//RT semaforo questionari INIZIO	
	const questionarioIvassValida = questionarioIvass;
	const questionarioAnamnesticoValida = questionarioAnamnestico;
//RT semaforo questionari FINE	
/*RT	const questionarioIvassValida = (questionarioIvass !== null) ? questionarioIvass.datiQuestionarioValida : null;
	const questionarioAnamnesticoValida = questionarioAnamnestico.datiQuestionarioValida;	*/
	const item = { questionarioIvassValida, questionarioAnamnesticoValida };   
    return item;    
  }

  datiRiepilogo = () => {
    const tipoProdotto = this.props.prodotto.tipo;
/******** CONTRAENTE ********/
    const contraenteFisico = this.props.contraenteFisico;
    let datiRiepilogoContraenteList = null;
    const contraenteGiuridico = this.props.contraenteGiuridico;	
    if ((contraenteFisico.anagraficaValida !== null) && (contraenteFisico.anagraficaValida !== undefined)) {
      datiRiepilogoContraenteList = this.createDatiRiepilogoListContraenteFisicoFrom(contraenteFisico);    	
    } else if ((contraenteGiuridico.anagraficaValida !== null) && (contraenteGiuridico.anagraficaValida !== undefined)) {
      datiRiepilogoContraenteList = this.createDatiRiepilogoListContraenteGiuridicoFrom(contraenteGiuridico);       	
    } else {
      datiRiepilogoContraenteList = this.createDatiRiepilogoListContraenteFisicoFrom(contraenteFisico);       	
    }
//    const datiRiepilogoContraenteFisicoList = this.createDatiRiepilogoListContraenteFrom(contraenteFisico);  
/******** ASSICURATO ********/
    const assicuratoFisico = this.props.assicuratoFisico;
    let datiRiepilogoAssicuratoFisicoList = null; 
    if (assicuratoFisico.flAssicuratoUgualeContraente === 'S') {
      datiRiepilogoAssicuratoFisicoList = this.createDatiRiepilogoListAssicuratoFrom(contraenteFisico, assicuratoFisico.anagraficaValida);    	
    } else {
      datiRiepilogoAssicuratoFisicoList = this.createDatiRiepilogoListAssicuratoFrom(assicuratoFisico, assicuratoFisico.anagraficaValida);
    }
/********  BENEFICIARIO VITA ********/
    /* BENEFICIARIO VITA FISICO */
    const isVita = true;
    const benefVitaFisicoList = this.props.benefVitaFisicoList;
    const datiRiepilogoBenefVitaFisicoList = this.createDatiRiepilogoListFromBenefFisicoList(benefVitaFisicoList, isVita);
    /* BENEFICIARIO VITA GIURIDICO */
    const benefVitaGiuridicoList = this.props.benefVitaGiuridicoList;
    const datiRiepilogoBenefVitaGiuridicoList = this.createDatiRiepilogoListFromBenefGiuridicoList(benefVitaGiuridicoList);
    /* TITOLARI EFFETTIVI DEL BENEFICIARIO MORTE GIURIDICO */
    const datiRiepilogoTitEffBenefVitaGiuridicoList = this.createDatiRiepilogoListTitEffBenefVitaList(benefVitaGiuridicoList);
    /* BENEFICIARIO VITA GENERICO */
    const benefVitaGenericoList = this.props.benefVitaGenericoList;
    const datiRiepilogoBenefVitaGenericoList = this.createDatiRiepilogoListFromBenefGenericoList(benefVitaGenericoList);
    const datiRiepilogoBenefVita = datiRiepilogoBenefVitaFisicoList.concat(datiRiepilogoBenefVitaGiuridicoList).concat(datiRiepilogoBenefVitaGenericoList);
/********  BENEFICIARIO MORTE ********/
    /* BENEFICIARIO MORTE FISICO */
    const benefMorteFisicoList = this.props.benefMorteFisicoList;
    const datiRiepilogoBenefMorteFisicoList = this.createDatiRiepilogoListFromBenefFisicoList(benefMorteFisicoList, !isVita);
    /* BENEFICIARIO MORTE GIURIDICO */
    const benefMorteGiuridicoList = this.props.benefMorteGiuridicoList;
    const datiRiepilogoBenefMorteGiuridicoList = this.createDatiRiepilogoListFromBenefGiuridicoList(benefMorteGiuridicoList);
    /* TITOLARI EFFETTIVI DEL BENEFICIARIO MORTE GIURIDICO */
    const datiRiepilogoTitEffBenefMorteGiuridicoList = this.createDatiRiepilogoListTitEffBenefMorteList(benefMorteGiuridicoList);
    /* BENEFICIARIO MORTE GENERICO */
    const benefMorteGenericoList = this.props.benefMorteGenericoList;
    const datiRiepilogoBenefMorteGenericoList = this.createDatiRiepilogoListFromBenefGenericoList(benefMorteGenericoList);	    
    const datiRiepilogoBenefMorte = datiRiepilogoBenefMorteFisicoList.concat(datiRiepilogoBenefMorteGiuridicoList).concat(datiRiepilogoBenefMorteGenericoList);
/********  REFERENTE TERZO ********/
    const referenteTerzoFisico = this.props.referenteTerzoFisico;
    const datiRiepilogoReferenteTerzoFisicoList = this.createDatiRiepilogoListFromReferenteTerzoFisico(referenteTerzoFisico);
/********  TITOLARI EFFETTIVI ********/
    const titolareEffList = this.props.titolareEffList;
    const datiRiepilogoTitolareEffList = this.createDatiRiepilogoListFromTitolareEffList(titolareEffList);
/************ CARATTERISTICHE CONTRATTO ************/
    let datiRiepilogoDatiPremioCapitaleAssicurato = null;
    let datiRiepilogoDatiPremio = null;
    let datiRiepilogoDatiRipartizionePremio = null;
    let datiRiepilogoDatiOpzioniContrattuali = null;
    if (tipoProdotto === PRODOTTO_TIPOLOGIA_MULTINVEST || tipoProdotto === PRODOTTO_TIPOLOGIA_UNIT_LINKED) { 
    /* SCENARIO MULTINVEST */    	
      datiRiepilogoDatiPremio = this.createDatiRiepilogoDatiPremioFrom(this.props.datiPremio);   
      datiRiepilogoDatiRipartizionePremio = this.createDatiRiepilogoDatiRipartizionePremioFrom(this.props.datiRipartizionePremio);       
      datiRiepilogoDatiOpzioniContrattuali = this.createDatiRiepilogoDatiOpzioniContrattualiFrom(this.props.datiOpzioniContrattuali);
    } else if (tipoProdotto === PRODOTTO_TIPOLOGIA_ALTRO) {
    /* SCENARIO ALTRI PRODOTTI */
      datiRiepilogoDatiPremioCapitaleAssicurato = this.createDatiRiepilogoDatiPremioCapitaleAssicuratoFrom(this.props.datiPremioCapitaleAssicurato);   	
    } else {	
    }  
    /* SCENARIO COMUNE MULTINVEST - ALTRI PRODOTTI */
    const datiModalitaPagamento = this.props.datiModalitaPagamento;
    datiModalitaPagamento.datiModPagAssegno = this.props.datiModPagAssegno;
    datiModalitaPagamento.datiModPagBonifico = this.props.datiModPagBonifico; 
    datiModalitaPagamento.datiModPagReimpiego = this.props.datiModPagReimpiego;    
    const datiRiepilogoDatiModalitaPagamento = this.createDatiRiepilogoDatiModalitaPagamentoFrom(datiModalitaPagamento);
/********* DICHIARAZIONE CONSENSI *********/    
    const datiRiepilogoDatiIntermediario = this.createDatiRiepilogoDatiIntermediarioFrom(this.props.datiIntermediario);
    const datiRiepilogoDatiConsenso = this.createDatiRiepilogoDatiConsensoFrom(this.props.datiConsenso);
/********* QUESTIONARI *********/ 
    const datiRiepilogoDatiQuestionari = this.createDatiRiepilogoDatiQuestionariFrom(this.props.questionario);
    
	const datiRiepilogoList = {
      datiRiepilogoContraenteList,
      datiRiepilogoAssicuratoFisicoList,
      datiRiepilogoBenefVita,
      datiRiepilogoTitEffBenefVitaGiuridicoList,
      datiRiepilogoBenefMorte, 
      datiRiepilogoTitEffBenefMorteGiuridicoList,
      datiRiepilogoReferenteTerzoFisicoList,
      datiRiepilogoTitolareEffList,
      datiRiepilogoDatiPremioCapitaleAssicurato,    
      datiRiepilogoDatiPremio,
      datiRiepilogoDatiRipartizionePremio,
      datiRiepilogoDatiOpzioniContrattuali,
      datiRiepilogoDatiModalitaPagamento,
      datiRiepilogoDatiIntermediario,
      datiRiepilogoDatiConsenso,
      datiRiepilogoDatiQuestionari,
    }		
    return datiRiepilogoList;
  }

  getErrorFiducianteVita = () => {
    let errorFiduciante = false;
    const benefVitaGiuridicoList = this.props.benefVitaGiuridicoList;
    benefVitaGiuridicoList.map(el => {
      el.titolareEffBenefVitaListInBenef.map(item => {
        if(item.errorFiduciante && this.props.scopeAmleto) {
          errorFiduciante = true;
        }
      })
    })
    return errorFiduciante;
  }

  getErrorFiducianteMorte = () => {
    let errorFiduciante = false;
    const benefMorteGiuridicoList = this.props.benefMorteGiuridicoList;
    benefMorteGiuridicoList.map(el => {
      el.titolareEffBenefMorteListInBenef.map(item => {
        if(item.errorFiduciante && this.props.scopeAmleto) {
          errorFiduciante = true;
        }
      })
    })
    return errorFiduciante;
  }

  getErrorFiducianteContraente = () => {
    let errorFiduciante = false;
    this.props.titolareEffList.map(item => {
      if(item.errorFiduciante && this.props.scopeAmleto) {
        errorFiduciante = true;
      }
    })
    return errorFiduciante;
  }
	
  render() {
    const { classes } = this.props;
    const { t } = this.props; //hoc
    const prodotto = this.props.prodotto;
    const datiRiepilogo = this.datiRiepilogo();
    const datiRiepilogoContraenteList = datiRiepilogo.datiRiepilogoContraenteList;
    const datiRiepilogoAssicuratoFisicoList = datiRiepilogo.datiRiepilogoAssicuratoFisicoList;
    const datiRiepilogoBenefVita = datiRiepilogo.datiRiepilogoBenefVita;
    const datiRiepilogoTitEffBenefVitaGiuridicoList = datiRiepilogo.datiRiepilogoTitEffBenefVitaGiuridicoList;
    const datiRiepilogoTitEffBenefMorteGiuridicoList = datiRiepilogo.datiRiepilogoTitEffBenefMorteGiuridicoList;
    const datiRiepilogoBenefMorte = datiRiepilogo.datiRiepilogoBenefMorte;
    const datiRiepilogoReferenteTerzoFisicoList = datiRiepilogo.datiRiepilogoReferenteTerzoFisicoList;
    const datiRiepilogoTitolareEffList = datiRiepilogo.datiRiepilogoTitolareEffList;
    const datiRiepilogoDatiPremioCapitaleAssicurato = datiRiepilogo.datiRiepilogoDatiPremioCapitaleAssicurato;
    const datiRiepilogoDatiModalitaPagamento = datiRiepilogo.datiRiepilogoDatiModalitaPagamento;
    const datiRiepilogoDatiPremio = datiRiepilogo.datiRiepilogoDatiPremio;
    const datiRiepilogoDatiRipartizionePremio = datiRiepilogo.datiRiepilogoDatiRipartizionePremio;
    const datiRiepilogoDatiOpzioniContrattuali = datiRiepilogo.datiRiepilogoDatiOpzioniContrattuali;
    const datiRiepilogoDatiIntermediario = datiRiepilogo.datiRiepilogoDatiIntermediario;
    const datiRiepilogoDatiConsenso = datiRiepilogo.datiRiepilogoDatiConsenso;
    const datiRiepilogoDatiQuestionari = datiRiepilogo.datiRiepilogoDatiQuestionari;
    
    const adeguatezzaShow = showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011);
    const anamnesticoShow = showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002);
    const semplificatoShow = this.props.questionario.showSemplificato;
    const showOnlySemplificato = this.props.questionario.showOnlySemplificato;
    const isChangedFromSemplificato = this.props.questionario.isChangedFromSemplificato;
	
    const items = this.props.dominio.ruoloXProdottoItems.map((item) => {
      return (item.value)
	});
    
	const beneficiarioVitaShow = showSezione(items, RUOLO_BENEF_VITA);	  
	const beneficiarioMorteShow = showSezione(items, RUOLO_BENEF_MORTE);
  const titolareEffShow = this.props.titolareEffList.length > 0;
  const titolareEffBenefMorteShow = this.props.benefMorteGiuridico.flTitolareEffBenef === 'S';
  const titolareEffBenefVitaShow = this.props.benefVitaGiuridico.flTitolareEffBenefVita === 'S';
	
    return (   
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <CustomTableCell align='center'>
                    <Table className={classes.table}>
                      <TableBody>
                      {datiRiepilogoContraenteList !== undefined && datiRiepilogoContraenteList !== null && 
                       datiRiepilogoContraenteList.map((item, index) => (
                        <TableRow key={index}>
                          {/*<TableCell rowSpan={2} align='left'   className={classNames(classes.row, classes.rowWidthCol0)} nowrap='true'><img className={classes.svgDisabled} src='/images/generic/stepper/contraente_assicurato.svg' /></TableCell>*/}				   	    		
                          <TableCell align='left' className={classNames(classes.row, classes.rowWidthCol1)} nowrap='true'><Typography variant='subtitle1'>{t('contraente').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
                          <TableCell align='left' className={classNames(classes.row, classes.rowWidthCol3)} nowrap='true'><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
                          <TableCell align='center' className={classNames(classes.row, classes.rowWidthCol4)} nowrap='true'></TableCell>
                          <TableCell align='right' className={classNames(classes.row, classes.rowWidthCol5)} nowrap='true'>
                            <LensIcon className={((item.anagraficaValida === undefined) || (item.anagraficaValida === null)) ? classes.lensIconWARNING : ((item.anagraficaValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                        </TableRow>
                      ))}
                      {datiRiepilogoAssicuratoFisicoList !== undefined && datiRiepilogoAssicuratoFisicoList !== null &&
                       datiRiepilogoAssicuratoFisicoList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('assicurato').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
                          <TableCell align='center' className={classes.row}></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((item.anagraficaValida === undefined) || (item.anagraficaValida === null)) ? classes.lensIconWARNING : ((item.anagraficaValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>                         
                        </TableRow>
                      ))}
                      {beneficiarioVitaShow &&
                    	  <TableRow>
	                    	  <TableCell align='left' className={classes.row} colSpan={4}><Typography variant='subtitle1'>{t('beneficiarioVita').toUpperCase()}</Typography></TableCell>
	                    	  <TableCell align='right' className={classes.row} nowrap='true'>
	                    		  <LensIcon className={((this.props.percBenVitaTotale === undefined) || (this.props.percBenVitaTotale === null)) ? classes.lensIconWARNING : ((this.props.percBenVitaTotale === 100.0) ? classes.lensIconOK : classes.lensIconERROR)} />
	                    	  </TableCell>
	                       </TableRow>
	                  }                      
	                  {datiRiepilogoBenefVita !== undefined && datiRiepilogoBenefVita !== null && datiRiepilogoBenefVita.map((item, index) => (             
	                     <TableRow key={index}>
	                         <TableCell align='left' className={classes.row}></TableCell>
	                         <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
	                         <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
	                         <TableCell align='center' className={classes.row}><Typography variant='caption'>{item.percBeneficiario}%</Typography></TableCell>
	                         <TableCell align='center' className={classes.row}></TableCell>
	                     </TableRow>						                       
	                   ))}
	                  {beneficiarioMorteShow &&
                    	  <TableRow>
	                    	  <TableCell align='left' className={classes.row} colSpan={4}><Typography variant='subtitle1'>{t('beneficiarioMorte').toUpperCase()}</Typography></TableCell>
	                    	  <TableCell align='right' className={classes.row} nowrap='true'>
	                    		  <LensIcon className={((this.props.percBenMorteTotale === undefined) || (this.props.percBenMorteTotale === null) || (this.props.percBenMorteTotale === 0.0)) ? classes.lensIconWARNING : ((this.props.percBenMorteTotale === 100.0) ? classes.lensIconOK : classes.lensIconERROR)} />
	                    	  </TableCell>
	                       </TableRow>
	                  }                    
	                  {datiRiepilogoBenefMorte !== undefined && datiRiepilogoBenefMorte !== null && datiRiepilogoBenefMorte.map((item, index) => (             
	                     <TableRow key={index}>
	                         <TableCell align='left' className={classes.row}><Typography variant='subtitle1'></Typography></TableCell>
	                         <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
	                         <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
	                         <TableCell align='center' className={classes.row}><Typography variant='caption'>{item.percBeneficiario}%</Typography></TableCell>
	                         <TableCell align='center' className={classes.row}></TableCell>
	                     </TableRow>						                       
	                   ))}    
                     {titolareEffShow &&
                    	  <TableRow>
	                    	  <TableCell align='left' className={classes.row} colSpan={4}><Typography variant='subtitle1'>{t('titolareEffettivo').toUpperCase()}</Typography></TableCell>
	                    	  <TableCell align='right' className={classes.row} nowrap='true'>
	                    		  <LensIcon className={(this.props.titolareEffList.length === 0 && this.props.titolareEff.anagraficaValida === null) ? classes.lensIconWARNING : ((this.props.titolareEffList.length > 0 ) ? (this.getErrorFiducianteContraente() ? classes.lensIconERROR : classes.lensIconOK) : classes.lensIconERROR)} />
	                    	  </TableCell>
	                       </TableRow>
	                  }                    
                    {datiRiepilogoTitolareEffList !== undefined && datiRiepilogoTitolareEffList !== null && datiRiepilogoTitolareEffList.map((item, index) => (             
                        <TableRow key={index}>
                            <TableCell align='left' className={classes.row}><Typography variant='subtitle1'></Typography></TableCell>
                            <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
                            <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
                            <TableCell align='center' className={classes.row}><Typography variant='caption'>{item.percOwnerShip}%</Typography></TableCell>
                            <TableCell align='center' className={classes.row}></TableCell>
                        </TableRow>						                       
                      ))}     
	                  {titolareEffBenefMorteShow &&
                    	  <TableRow>
	                    	  <TableCell align='left' className={classes.row} colSpan={4}><Typography variant='subtitle1'>{t('titolareEffBenefMorte').toUpperCase()}</Typography></TableCell>
	                    	  <TableCell align='right' className={classes.row} nowrap='true'>
	                    		  <LensIcon className={(this.props.titolareEffBenefMorteGiuridicoList.length === 0 && this.props.titolareEffBenefMorteGiuridico.anagraficaValida === null) ? classes.lensIconWARNING : ((this.props.titolareEffBenefMorteGiuridicoList.length > 0 ) ? (this.getErrorFiducianteMorte() ? classes.lensIconERROR : classes.lensIconOK) : classes.lensIconERROR)} />
	                    	  </TableCell>
	                       </TableRow>
	                  }
                    {datiRiepilogoTitEffBenefMorteGiuridicoList && datiRiepilogoTitEffBenefMorteGiuridicoList.length > 0 && datiRiepilogoTitEffBenefMorteGiuridicoList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align='left' className={classes.row} colSpan={2}><Typography variant='caption'>Beneficiario: {item.ragioneSociale}</Typography></TableCell>
                          <TableCell align='left' className={classes.row} colSpan={1}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
                          <TableCell align='left' className={classes.row} colSpan={2}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
                        </TableRow>						                                                             
                    ))}                
	                  {titolareEffBenefVitaShow &&
                    	  <TableRow>
	                    	  <TableCell align='left' className={classes.row} colSpan={4}><Typography variant='subtitle1'>{t('titolareEffBenefVita').toUpperCase()}</Typography></TableCell>
	                    	  <TableCell align='right' className={classes.row} nowrap='true'>
	                    		  <LensIcon className={(this.props.titolareEffBenefVitaGiuridicoList.length === 0 && this.props.titolareEffBenefVitaGiuridico.anagraficaValida === null) ? classes.lensIconWARNING : ((this.props.titolareEffBenefVitaGiuridicoList.length > 0 ) ? (this.getErrorFiducianteVita() ? classes.lensIconERROR : classes.lensIconOK) : classes.lensIconERROR)} />
	                    	  </TableCell>
	                       </TableRow>
	                  }                    
                    {datiRiepilogoTitEffBenefVitaGiuridicoList && datiRiepilogoTitEffBenefVitaGiuridicoList.length > 0 && datiRiepilogoTitEffBenefVitaGiuridicoList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align='left' className={classes.row} colSpan={2}><Typography variant='caption'>Beneficiario: {item.ragioneSociale}</Typography></TableCell>
                        <TableCell align='left' className={classes.row} colSpan={1}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
                        <TableCell align='left' className={classes.row} colSpan={2}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
                      </TableRow>						                                         
                    ))}    
                    {datiRiepilogoReferenteTerzoFisicoList !== undefined && datiRiepilogoReferenteTerzoFisicoList !== null &&
                      datiRiepilogoReferenteTerzoFisicoList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('referenteTerzo').toUpperCase()}:</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.nominativo}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{item.codiceFiscale}</Typography></TableCell>
                          <TableCell align='center' className={classes.row}></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((item.anagraficaValida === undefined) || (item.anagraficaValida === null)) ? classes.lensIconWARNING : ((item.anagraficaValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>                         
                        </TableRow>
                    ))}
                      </TableBody>
                    </Table>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell>
                    <Table className={classes.table}>
                      <TableBody>
                      {prodotto.tipo === PRODOTTO_TIPOLOGIA_ALTRO && 
                      <Fragment>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('premioCapitaleAssicurato').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{datiRiepilogoDatiPremioCapitaleAssicurato.etichetta}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida === undefined) || (datiRiepilogoDatiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('modalitaPagamento').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{datiRiepilogoDatiModalitaPagamento.etichetta}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiModalitaPagamento.datiModalitaPagamentoValida === undefined) || (datiRiepilogoDatiModalitaPagamento.datiModalitaPagamentoValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiModalitaPagamento.datiModalitaPagamentoValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>                        
                      </Fragment>
                      }
                      {(prodotto.tipo === PRODOTTO_TIPOLOGIA_MULTINVEST || prodotto.tipo === PRODOTTO_TIPOLOGIA_UNIT_LINKED) && 
                      <Fragment>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('premio').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{datiRiepilogoDatiPremio.etichetta}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiPremio.datiPremioValida === undefined) || (datiRiepilogoDatiPremio.datiPremioValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiPremio.datiPremioValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>                          
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('ripartizionePremio').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{datiRiepilogoDatiRipartizionePremio.etichetta}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiRipartizionePremio.datiRipartizionePremioValida === undefined) || (datiRiepilogoDatiRipartizionePremio.datiRipartizionePremioValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiRipartizionePremio.datiRipartizionePremioValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>                                                    
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('opzioniContrattuali').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{datiRiepilogoDatiOpzioniContrattuali.etichetta}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiOpzioniContrattuali.opzioneValida === undefined) || (datiRiepilogoDatiOpzioniContrattuali.opzioneValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiOpzioniContrattuali.opzioneValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('modalitaPagamento').toUpperCase()}</Typography></TableCell>
                          <TableCell align='left' className={classes.row}><Typography variant='caption'>{datiRiepilogoDatiModalitaPagamento.etichetta}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiModalitaPagamento.datiModalitaPagamentoValida === undefined) || (datiRiepilogoDatiModalitaPagamento.datiModalitaPagamentoValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiModalitaPagamento.datiModalitaPagamentoValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>                                                                       
                      </Fragment>
                      }
                      </TableBody>
                    </Table>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>  
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('dichiarazioneIntermediario').toUpperCase()}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiIntermediario.datiIntermediarioValida === undefined) || (datiRiepilogoDatiIntermediario.datiIntermediarioValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiIntermediario.datiIntermediarioValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>                          
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('consenso').toUpperCase()}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                            <LensIcon className={((datiRiepilogoDatiConsenso.datiConsensoValida === undefined) || (datiRiepilogoDatiConsenso.datiConsensoValida === null))
                                                      ? classes.lensIconWARNING 
                                                      : ((datiRiepilogoDatiConsenso.datiConsensoValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>                   
                          {/*<TableCell align='right' className={classes.row} nowrap='true'><LensIcon className={classes.lensIconERROR}/><DeleteIcon className={classes.delIcon} /></TableCell>*/}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CustomTableCell>
                </TableRow>
                {(adeguatezzaShow || anamnesticoShow || semplificatoShow) &&
                <TableRow>
                 <CustomTableCell>
                    <Table className={classes.table}>
                      <TableBody>
                      {adeguatezzaShow &&
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('questionarioIvass').toUpperCase()}</Typography></TableCell>
                          <TableCell align='right' className={classes.row} nowrap='true'>
                          <LensIcon className={((datiRiepilogoDatiQuestionari.questionarioIvassValida === undefined) || (datiRiepilogoDatiQuestionari.questionarioIvassValida === null))
                                                    ? classes.lensIconWARNING 
                                                    : ((datiRiepilogoDatiQuestionari.questionarioIvassValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                        </TableRow>
                      }                
                      {anamnesticoShow && !showOnlySemplificato &&
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('questionarioSanitario').toUpperCase()}</Typography></TableCell>                      
                          <TableCell align='right' className={classes.row} nowrap='true'>
                          <LensIcon className={((datiRiepilogoDatiQuestionari.questionarioAnamnesticoValida === undefined) || (datiRiepilogoDatiQuestionari.questionarioAnamnesticoValida === null))
                                                    ? classes.lensIconWARNING 
                                                    : ((datiRiepilogoDatiQuestionari.questionarioAnamnesticoValida === true) ? classes.lensIconOK : classes.lensIconERROR)} /></TableCell>
                        </TableRow>
                      }                    
                      {semplificatoShow &&
                        <TableRow>
                          <TableCell align='left' className={classes.row}><Typography variant='subtitle1'>{t('questionarioSanitarioSemplificato').toUpperCase()}</Typography></TableCell>                      
                          <TableCell align='right' className={classes.row} nowrap='true'>
                          <LensIcon className={((datiRiepilogoDatiQuestionari.questionarioSemplificatoValida === undefined) || (datiRiepilogoDatiQuestionari.questionarioSemplificatoValida === null))
                                                    ? classes.lensIconWARNING 
                                                    : (isChangedFromSemplificato === true ? classes.lensIconOK : (datiRiepilogoDatiQuestionari.questionarioSemplificatoValida === true ? classes.lensIconOK : classes.lensIconERROR))} /></TableCell>
                        </TableRow>
                      }                    
                      </TableBody>
                    </Table>
                  </CustomTableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    );
  }
}

DatiRiepilogo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiRiepilogo)));

