import * as actionType from '../actionType/datiNuovaPassword';

export const updateDatiNuovaPassword = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaDatiNuovaPassword = controlloDati => {
  return {
    type: actionType.SALVA_DATI_NUOVA_PASSWORD,
	controlloDati,
  }
};
			
export const inviaDatiNuovaPassword = (utente) => {
  return {
    type: actionType.INVIA_DATI_NUOVA_PASSWORD,
	utente,
  }
};