import * as actionType from '../actionType/datiStampaProposal';
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import initialState from '../../../../session/InitialState';

import { jsonCopy } from '../../../../utility/genericUtility';
import { datiStampaProposalInizializzato, datiInvioEmailInizializzato } from '../../../../session/info/componentiBase/sezioniStampaProposal';

const areaDatiStampaProposalReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
//  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
//  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
    
  const stampaProposal = {...state}.stampaProposal;
  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const stampaProposal_INI = inizializzaStampaProposal(state);
      return {
        ...state,
        stampaProposal: stampaProposal_INI,
      };
    case actionType.CONFERMA_STAMPA_PROPOSAL:
      stampaProposal.datiStampaProposal.confermaStampaProposal = action.unDato;
      return {
        ...state,
        stampaProposal: stampaProposal,
      };
    case actionType.INVIO_EMAIL_PROPOSAL:
      stampaProposal.datiInvioEmail = action.unDato;
      stampaProposal.datiStampaProposal.mailInviata = true;
      return {
        ...state,
        stampaProposal: stampaProposal,
      };      
    case actionType.UPDATE_NUMERO_PROPOSTA:
      stampaProposal.datiInvioEmail.idstampa = action.unDato;
      return {
        ...state,
        stampaProposal: stampaProposal,
      };      
    default:
      return state;
  }
}

export const inizializzaStampaProposal = (state) => {
  const stampaProposal_INI = {...state}.stampaProposal;
  stampaProposal_INI.datiStampaProposal = jsonCopy(datiStampaProposalInizializzato);
  stampaProposal_INI.datiInvioEmail = jsonCopy(datiInvioEmailInizializzato);
  return stampaProposal_INI;
}

export default areaDatiStampaProposalReducer;