import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Fragment } from 'react';

import CardComp from '../common/CardComp';
import * as actions from './action/prodotto';
import { uris, fetchParam } from '../../uris';

import moment from 'moment';
import 'moment/locale/it';

import * as actionsDominio from '../common/action/dominio';

import { STATO_UTENTE_PENDENTE, PWD_IN_SCADENZA, PWD_SCADUTA, CAMBIO_PWD_IN_SCADENZA, NON_CAMBIO_PWD_IN_SCADENZA } from '../../utility/jsConstants';

import { areaLogin } from '../store/areaLogin';
import DatiLoginCambioPassword from '../gestioneUtenze/sezione/DatiLoginCambioPassword';
import Login from '../login/Login';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: 100,
  },
  media: {
    height: 109,
  },  
  divider: {
	width: theme.spacing(30),
  }
});

const mapStateToProps = state => {
  return {
  	user: state.areaLoginReducer.areaLogin.user,
	password: state.areaLoginReducer.areaLogin.password,
	userpassword: state.areaLoginReducer.areaLogin.userpassword,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  	selezionaProdotto: (area, prodotto) => dispatch(actions.selezionaProdotto(area, prodotto)),
  	selezionaUtente: (utente) => dispatch(actions.selezionaUtente(utente)),
  	dominioMandato: (mandatoItems) => dispatch(actionsDominio.dominioMandato(mandatoItems)),
  	dominioProfilo: (profiloItems) => dispatch(actionsDominio.dominioProfilo(profiloItems)),
  };
};
	
class ProdottiArea extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      statoUtente: '',
      codiceErroreLogin: 'X',
      areaProdotto: this.props.areaProdotto,
    };
  };

  componentDidMount() {
    const dataOggiFormatta = moment().format('DD/MM/YYYY');
    this.loadMandato(dataOggiFormatta);
  }
  
  componentDidUpdate(prevProps, prevState) {
   if(this.props.userpassword.trim()===(this.props.user+this.props.password)){
	 if(this.state.codiceErroreLogin==='X' || (this.state.codiceErroreLogin!==CAMBIO_PWD_IN_SCADENZA && this.state.codiceErroreLogin!==PWD_IN_SCADENZA 
			 && this.state.codiceErroreLogin!=='' && this.state.codiceErroreLogin===prevState.codiceErroreLogin)){
		const opts = {
	      user: this.props.user,
	      password: this.props.password,
	    };
	    fetch(uris.areaProdotto, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      const codErr = result.utente.codiceErrore.trim();
	      if(this.state.codiceErroreLogin!==codErr){
	        if(codErr==='' || codErr===PWD_IN_SCADENZA){
	          this.props.selezionaUtente(result.utente);
	    	  this.loadProfilo(result.utente.profilo.trim());
	    	  this.setState({
	    	    statoUtente: result.utente.stato,
	    	    areaProdotto: result.areaProdotti,
	    	    codiceErroreLogin: codErr,
	    	  });
	        }else{
	          this.setState({
	            codiceErroreLogin: codErr,
	          });
	        }
	      }
	    });
	  }
    }
  }
	  
  loadMandato = (dataOggiFormatta) => {
    const opts = {
      dataRichiesta: dataOggiFormatta,
    };
    fetch(uris.mandatoItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
    	mandatoItems: result,
    	codiceErroreLogin: 'X',
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioMandato(result);
    });
  }
  
  loadProfilo = (profiloUtente) => {
    const opts = {
      siglaVersione: 'ZURI',
      codiceProfilo: profiloUtente,
    };
    fetch(uris.profiloItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data
      this.setState({
    	profiloItems: result,
      });
      console.log('[ZSE] componentDidMount result ... ', result);
	  this.props.dominioProfilo(result);
    });
  }
  
  rilanciaLogin = (utente, passwordNuova) => {
    const opts = {
	  user: utente,
	  password: passwordNuova,
	};
	fetch(uris.areaProdotto, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  this.props.selezionaUtente(result.utente);
	  this.setState({
		statoUtente: result.utente.stato,
		areaProdotto: result.areaProdotti,
		codiceErroreLogin: '',
	  });
	});
  }
  
  rilanciaProdottiArea = (scelta) => {
    let codErr = '';
	if(scelta==='N'){
	  codErr = CAMBIO_PWD_IN_SCADENZA;
    }else if(scelta==='S'){
	  codErr = NON_CAMBIO_PWD_IN_SCADENZA;
    }
	this.setState({
	  codiceErroreLogin: codErr,
	});
  }
  
  render() {
    const { classes } = this.props;
    const { areaProdotto, codiceErroreLogin } = this.state;

    return (
      <Fragment>
 {/*     <Grid container direction='row' justify='space-evenly' alignItems='baseline' className={classes.root}> */}
        {(codiceErroreLogin===CAMBIO_PWD_IN_SCADENZA || (codiceErroreLogin==='' && this.state.statoUtente!==STATO_UTENTE_PENDENTE)) &&
          <Grid container direction='row' justify='center' alignItems='baseline' className={classes.root}>
            {areaProdotto.map((areaProdottoItem, index) => (
              <Grid item key={index}>
                <CardComp 
                  to={(areaProdottoItem.to) ? areaProdottoItem.to : '/linkProdotto'} 
                  area={areaProdottoItem.area} 
                  elementi={areaProdottoItem.prodotti}
                  onClick={this.props.selezionaProdotto}
                  aMediaStyle={classes.media}
                />
                <div className={classes.divider} />
              </Grid>
            ))}
          </Grid>
        }
        {(codiceErroreLogin===NON_CAMBIO_PWD_IN_SCADENZA || codiceErroreLogin===PWD_SCADUTA || (codiceErroreLogin==='' && this.state.statoUtente===STATO_UTENTE_PENDENTE)) &&
          <Grid container direction='row' justify='center' alignItems='flex-start'>
            <Grid item key='0'>
              <DatiLoginCambioPassword rilanciaLogin={this.rilanciaLogin} codiceErroreLogin={codiceErroreLogin} />
            </Grid>
          </Grid>
        }
        {codiceErroreLogin!=='' && codiceErroreLogin!=='X' && codiceErroreLogin!==PWD_IN_SCADENZA && codiceErroreLogin!==CAMBIO_PWD_IN_SCADENZA && codiceErroreLogin!==NON_CAMBIO_PWD_IN_SCADENZA &&
        	codiceErroreLogin!==PWD_SCADUTA &&
          <Login areaLogin={areaLogin} codiceErroreLogin={codiceErroreLogin} messaggioOk='' />
        }
        {codiceErroreLogin!=='' && codiceErroreLogin!=='X' && codiceErroreLogin===PWD_IN_SCADENZA &&
          <Login areaLogin={areaLogin} codiceErroreLogin={codiceErroreLogin} rilanciaProdottiArea={this.rilanciaProdottiArea} messaggioOk='' />
        }
      </Fragment>
    );
  }
}

ProdottiArea.propTypes = {
  classes: PropTypes.object.isRequired,
  areaProdotto: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ProdottiArea));