import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import DichIntermediarioPanel from './DichIntermediarioPanel';
import DichConsensoPanel from './DichConsensoPanel';
import { INTERMEDIARIO, CONSENSO } from '../../../utility/jsConstants';

const styles = theme => ({
  root: {	  
/*	 marginTop: 200, 
	 marginLeft: 100,
	 marginRight: 100, */
  },
  heading: {
	color: theme.palette.color.marine,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: theme.button,
});

const mapStateToProps = state => {
  return {
    datiIntermediarioValida: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario.datiIntermediarioValida,
    controlloDatiIntermediario: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario.controlloDati,
    datiConsensoValida: state.areaDichConsensoReducer.dichiarazioni.datiConsenso.datiConsensoValida,
    controlloDatiConsenso: state.areaDichConsensoReducer.dichiarazioni.datiConsenso.controlloDati,    
  };
};

class DichIntermediarioConsensoProposal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  
    };
  }
	  
  getIsValida = (unPannello) => {
    let isValida = null;
    if (unPannello === INTERMEDIARIO) {
      if (this.props.controlloDatiIntermediario !== '') {
        isValida = this.props.datiIntermediarioValida;
      }
    } else if (unPannello === CONSENSO) {
      if (this.props.controlloDatiConsenso !== '') {
        isValida = this.props.datiConsensoValida;
      }    	
    }
    return isValida;
  }
 
  render () {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <DichIntermediarioPanel isValida={this.getIsValida(INTERMEDIARIO)} />
        <DichConsensoPanel isValida={this.getIsValida(CONSENSO)} />
      </div>
    );
  }
}

DichIntermediarioConsensoProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(withStyles(styles)(DichIntermediarioConsensoProposal));