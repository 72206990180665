import * as actionType from '../actionType/beneficiarioMorte';
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import initialState from '../../../../session/InitialState';
import { PERSONA_FISICA, PERSONA_GIURIDICA, PERSONA_GENERICA, PROVINCIA_ESTERA, RUOLO_BENEF_MORTE, CONTRAENTE_GIURIDICO, CODICE_QUALIFICA_FIDUCIANTE } from '../../../../utility/jsConstants';
import { jsonCopy, dataFormat } from '../../../../utility/genericUtility';
import { datiGeneraliBenefMorteInizializzato, datiResidenzaInizializzato, datiRelazContrInizializzato, datiCopiaDaAltraFiguraInizializzato,
         datiGeneraliBenefGiuridicoInizializzato, datiGeneraliGenericoInizializzato, datiSocietariGiuridicoInizializzato, datiMandatoFiduciarioInizializzato,
         datiTitolareEffettivoBenefMorteInizializzato, datiAttEconomicaGiuridicoInizializzato, titEffBenefMorteGiuridicoInizializzato } from '../../../../session/info/componentiBase/sezioniAnagrafica';
    
const areaBenefMorteReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;

  const benefMorteGiuridico = {...state}.benefMorteGiuridico;
  const benefMorteGiuridicoList = {...state}.benefMorteGiuridicoList;
  const titolareEffBenefMorte = {...benefMorteGiuridico}.titolareEffBenefMorte;
  const datiTitolareBenefMorteEff = {...titolareEffBenefMorte}.datiTitolareBenefMorteEff;

	const datiGeneraliMinimiTitEffBenefMorte    = datiTitolareBenefMorteEff.datiGeneraliMinimi;
  const datiRelazContrTitEffBenefMorte        = datiTitolareBenefMorteEff.datiRelazContr;
  
  const datiGeneraliTitEffMorte 				= datiTitolareBenefMorteEff.datiGenerali;	
	const datiResidenzaTitEffMorte				= datiTitolareBenefMorteEff.datiResidenza;
	const datiDomicilioTitEffMorte				= datiTitolareBenefMorteEff.datiDomicilio;
	const datiRecapitoTitEffMorte 				= datiTitolareBenefMorteEff.datiRecapito;
	const datiAntiriciclaggioTitEffMorte        = datiTitolareBenefMorteEff.datiAntiriciclaggio;
	const datiAttEconomicaTitEffMorte			= datiTitolareBenefMorteEff.datiAttEconomica;
	const datiFiscaliTitEffMorte				= datiTitolareBenefMorteEff.datiFiscali

	  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
      const benefMorteFisicoInizializza = inizializzaBeneficiarioMorteFisico(state);
      const benefMorteGiuridicoInizializza = inizializzaBeneficiarioMorteGiuridico(state);
      const benefMorteGenericoInizializza = inizializzaBeneficiarioMorteGenerico(state);
      const benefMorteFisicoListInizializza = inizializzaBeneficiarioMorteFisicoLista(state);
      const benefMorteGiuridicoListInizializza = inizializzaBeneficiarioMorteGiuridicoLista(state);
      const benefMorteGenericoListInizializza = inizializzaBeneficiarioMorteGenericoLista(state);
      benefMorteGiuridico.flTitolareEffBenef = 'N';
      benefMorteGiuridico.tabMorteSelezionato = 0;
      return {
      	...state,
      	benefMorteFisico: benefMorteFisicoInizializza,
      	benefMorteGiuridico: benefMorteGiuridicoInizializza,
      	benefMorteGenerico: benefMorteGenericoInizializza,
      	benefMorteFisicoList: benefMorteFisicoListInizializza,
      	benefMorteGiuridicoList: benefMorteGiuridicoListInizializza,
      	benefMorteGenericoList: benefMorteGenericoListInizializza,
      };      
/* BENEFICIARIO MORTE FISICO */  
    case actionType.SALVA_BENEF_MORTE_FISICO: 
      console.log('#RT2 Reducer areaBenefMorteReducer.actionType.SALVA_BENEF_MORTE_FISICO.state', state);
      console.log('#RT2 Reducer areaBenefMorteReducer.action', action);    
      const benefMorteFisicoSalva = {...state}.benefMorteFisico;
      benefMorteFisicoSalva.controlloDati = action.unDato;
      return {
    	...state,
    	benefMorteFisico: benefMorteFisicoSalva,
      };
    case actionType.SALVA_BENEF_MORTE_FISICO_SUCCESS: 
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.SALVA_BENEF_MORTE_FISICO_SUCCESS.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);    
        return {
      	...state,
      	salvaBenefMorteFisicoSuccess: action.unDato,
        };   
    case actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_FISICO:
        console.log('#RT2 Reducer areaBenefMorteReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_FISICO);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);
        const benefMorteFisicoAnagraficaValida = {...state}.benefMorteFisico;
        benefMorteFisicoAnagraficaValida.anagraficaValida = action.unDato;
        return {
          ...state,
          benefMorteFisico: benefMorteFisicoAnagraficaValida,
        };
        /* TOREMOVE
    case actionType.AGGIUNGI_BENEF_MORTE_FISICO:      
      console.log('#RT2 Reducer areaBenefMorteReducer.actionType.AGGIUNGI_BENEF_MORTE_FISICO.state', state);
      console.log('#RT2 Reducer areaBenefMorteReducer.action', action);
      const beneficiarioMorteFisico = action.currBenefMorteFisico;
      const beneficiarioMorteFisicoList = {...state}.benefMorteFisicoList;
      const beneficiarioMorteFisicoListNuovo = [...beneficiarioMorteFisicoList, beneficiarioMorteFisico];
      return {
        ...state,
       	benefMorteFisicoList: beneficiarioMorteFisicoListNuovo,
        benefMorteFisico: {...action.emptyBenefMorteFisico},
      };
      */
    case actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_FISICO:      
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);            	
        const beneficiarioMorteFisicoConfermaAggiungi = action.beneficiarioMorteFisico;
        const dataNascitaBeneficiarioMorteFisicoConfermaAggiungi = beneficiarioMorteFisicoConfermaAggiungi.datiGeneraliBenefMorte.datiGeneraliMinimi.dataNascita;
        const cloneBeneficiarioMorteFisico = jsonCopy(beneficiarioMorteFisicoConfermaAggiungi);
        // reimposoto la data di nascita
        cloneBeneficiarioMorteFisico.datiGeneraliBenefMorte.datiGeneraliMinimi.dataNascita=dataNascitaBeneficiarioMorteFisicoConfermaAggiungi;
        const beneficiarioMorteFisicoListConfermaAggiungi = {...state}.benefMorteFisicoList;
        const beneficiarioMorteFisicoListConfermaAggiungiNuovo = [...beneficiarioMorteFisicoListConfermaAggiungi, cloneBeneficiarioMorteFisico];
                
        return {
          ...state,
//          benefMorteFisico: beneficiarioMorteFisico,
          benefMorteFisicoList: beneficiarioMorteFisicoListConfermaAggiungiNuovo,
        };
    case actionType.NUOVO_BENEFICIARIO_MORTE_FISICO:      
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.AGGIUNGI_BENEFICIARIO_MORTE_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);
        const beneficiarioMorteFisicoNuovo = action.beneficiarioMorteFisico;
        
        return {
          ...state,
          salvaBenefMorteFisicoSuccess: false,
          benefMorteFisico: beneficiarioMorteFisicoNuovo,
        };        
    case actionType.UPDATE_BENEF_MORTE_FISICO:
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.AGGIUNGI_BENEFICIARIO_MORTE_FISICO.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);
        const beneficiarioMorteFisicoUpdate = action.currBenefMorteFisico;
        const dataNascitaBeneficiarioMorteFisicoUpdate = beneficiarioMorteFisicoUpdate.datiGeneraliBenefMorte.datiGeneraliMinimi.dataNascita;
        const cloneBeneficiarioMorteFisicoUpdate = jsonCopy(beneficiarioMorteFisicoUpdate);
        // reimposoto la data di nascita
        cloneBeneficiarioMorteFisicoUpdate.datiGeneraliBenefMorte.datiGeneraliMinimi.dataNascita=dataNascitaBeneficiarioMorteFisicoUpdate;
        const beneficiarioMorteFisicoCodiceFiscale = cloneBeneficiarioMorteFisicoUpdate.datiGeneraliBenefMorte.datiGeneraliMinimi.codiceFiscale;
        const beneficiarioMorteFisicoCognome = cloneBeneficiarioMorteFisicoUpdate.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome;  
        const beneficiarioMorteFisicoListUpdate = [...state.benefMorteFisicoList];
        
        let indexBeneficiarioMorteFisicoUpdate = undefined;
        beneficiarioMorteFisicoListUpdate.forEach( (item, index, array) => {
        	if (cloneBeneficiarioMorteFisicoUpdate.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!=='S') {
        		if (beneficiarioMorteFisicoCodiceFiscale===item.datiGeneraliBenefMorte.datiGeneraliMinimi.codiceFiscale){
        			indexBeneficiarioMorteFisicoUpdate = index;
        		}
        	} else {
        		// Cerco per cognome
	        	if (beneficiarioMorteFisicoCognome===item.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome){
	        		indexBeneficiarioMorteFisicoUpdate = index;
	        	}
        	}
        })
                
        beneficiarioMorteFisicoListUpdate[indexBeneficiarioMorteFisicoUpdate] = cloneBeneficiarioMorteFisicoUpdate;
        return {
          ...state,
         	benefMorteFisicoList: beneficiarioMorteFisicoListUpdate,
        };
    case actionType.CARICA_BENEF_MORTE:
    	//const benMorteList = {...action.benefMorteList};
    	const benMorteList = {...state}.benefMorteGiuridicoList;
    	const figuraSelezIndex = action.figuraSelezIndex;
      
      const benMorte = jsonCopy(benMorteList[figuraSelezIndex]);
      const tipoPers =  benMorte.datiFigureAnagrBase.tipoPers;
      if(benMorte.titolareEffBenefMorteListInBenef[0]) {
        if(tipoPers === PERSONA_GIURIDICA) {
          const listaSelezionata = benMorte.titolareEffBenefMorteListInBenef;
          benMorte.titolareEffBenefMorteList = listaSelezionata;
          return {
            ...state,
            benefMorteGiuridico: benMorte,
            tabMorteSelezionato: 1
          }
        }
      }
      
    	if (tipoPers===PERSONA_FISICA) {
    		return {
    			...state,
    	        benefMorteFisico: {...benMorte}, 
    	        tabMorteSelezionato: 0,
    	    };
    	} else if (tipoPers===PERSONA_GIURIDICA) {
    		return {
    			...state,
    			benefMorteGiuridico: {...benMorte},
  	          	tabMorteSelezionato: 1,
  	        };
    	} else if (tipoPers===PERSONA_GENERICA) {
			return {
		        ...state,
		        benefMorteGenerico: {...benMorte},
		        tabMorteSelezionato: 2,
		    };
    	} else {
    		// NO TAB selection
    		return {
		        ...state,
		        benefMorteGenerico: {...benMorte},
		    };
    	};
    case actionType.DELETE_BENEF_MORTE_FISICO:
    	let benMorteFisicoToDelete = action.benefMorteFisicoDelete;
    	const percBenefMorteFisicoDelete = benMorteFisicoToDelete.datiGeneraliBenefMorte.percBeneficiario;
    	
		const filterdBenMorteFisicoDeleteList = state.benefMorteFisicoList.filter( item => {
			let filterCond = undefined;			
			if(benMorteFisicoToDelete.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!=='S') {
				const codiceFiscale = item.datiGeneraliBenefMorte.datiGeneraliMinimi.codiceFiscale;
				filterCond = (codiceFiscale!==benMorteFisicoToDelete.datiGeneraliBenefMorte.datiGeneraliMinimi.codiceFiscale);
			} else {
				const cognome = item.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome;
				filterCond = (cognome!==benMorteFisicoToDelete.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome);
			}
			
			return filterCond;
		})
		
		return {
			...state,			
			percBenMorteTotale: state.percBenMorteTotale - percBenefMorteFisicoDelete, // ricalcolo la percentuale Totale
	        benefMorteFisicoList: filterdBenMorteFisicoDeleteList,
	    };    	
    case actionType.UPDATE_TAB_SELEZIONATO_BENEF_MORTE:
      const tabIndex = action.tabIndex;
      const benefMorteGiuridicoTab = {...state}.benefMorteGiuridico;
//L'UPDATE DELLA VARIABILE flTitolareEffBenefVita SERVE A NASCONDERE O MOSTRARE L'ACCORDION DEL TIT. EFF. A SECONDA DEL TIPO DI BENEFICIARIO
      if(tabIndex === 1) {
        benefMorteGiuridicoTab.flTitolareEffBenef = 'S';
      } else {
        benefMorteGiuridicoTab.flTitolareEffBenef = 'N';
      }
		 return {
	          ...state,
            tabMorteSelezionato: tabIndex,
            benefMorteGiuridico: benefMorteGiuridicoTab
//	          figuraSelezIndex: undefined,
	        };
/* BENEFICIARIO MORTE FISICO DATI GENERALI */
/*    	
    case actionType.UPDATE_CODICE_FISCALE_BENEF_MORTE_FISICO:
      const benefMorteFisicoFiscale = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteFiscale = {...benefMorteFisicoFiscale}.datiGeneraliBenefMorte.datiGenerali;
      datiGeneraliBenefMorteFiscale.codiceFiscale = action.unDato;
      benefMorteFisicoFiscale.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteFiscale};
      return {
     	...state,
       	benefMorteFisico: benefMorteFisicoFiscale,
      };
    case actionType.UPDATE_NOME_BENEF_MORTE_FISICO:
      const benefMorteFisicoNome = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteNome = {...benefMorteFisicoNome}.datiGeneraliBenefMorte.datiGenerali;
      datiGeneraliBenefMorteNome.nome = action.unDato;
      benefMorteFisicoNome.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteNome};
      return {
      	...state,
      	benefMorteFisico: benefMorteFisicoNome,
      };
    case actionType.UPDATE_COGNOME_BENEF_MORTE_FISICO:
      const benefMorteFisicoCognome = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteCognome = {...benefMorteFisicoCognome}.datiGeneraliBenefMorte.datiGenerali;
      datiGeneraliBenefMorteCognome.cognome = action.unDato;
      benefMorteFisicoCognome.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteCognome};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoCognome,
      };
    case actionType.UPDATE_SESSO_BENEF_MORTE_FISICO:
      const benefMorteFisicoSesso = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteSesso = {...benefMorteFisicoSesso}.datiGeneraliBenefMorte.datiGenerali;
      datiGeneraliBenefMorteSesso.sesso = action.unDato;
      benefMorteFisicoSesso.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteSesso};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoSesso,
      };   
    case actionType.UPDATE_DATA_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoDataNascita = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteDataNascita = {...benefMorteFisicoDataNascita}.datiGeneraliBenefMorte.datiGenerali;
      datiGeneraliBenefMorteDataNascita.dataNascita = action.unDato;
      datiGeneraliBenefMorteDataNascita.statoNascita = '';
      datiGeneraliBenefMorteDataNascita.descrStatoNascita = '';
      datiGeneraliBenefMorteDataNascita.provinciaNascita = '';
      datiGeneraliBenefMorteDataNascita.descrProvinciaNascita = '';
      datiGeneraliBenefMorteDataNascita.comuneNascita = '';
      datiGeneraliBenefMorteDataNascita.descrComuneNascita = '';
      datiGeneraliBenefMorteDataNascita.cittadinanza1 = '';
      datiGeneraliBenefMorteDataNascita.descrCittadinanza1 = '';
      datiGeneraliBenefMorteDataNascita.cittadinanza2 = '';
      datiGeneraliBenefMorteDataNascita.descrCittadinanza2 = '';
      benefMorteFisicoDataNascita.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteDataNascita};
      return {
       	...state,
      	benefMorteFisico: benefMorteFisicoDataNascita,
      };       
    case actionType.UPDATE_STATO_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoStatoNascita = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteStatoNascita = {...benefMorteFisicoStatoNascita}.datiGeneraliBenefMorte.datiGenerali;
      datiGeneraliBenefMorteStatoNascita.statoNascita = unValore;
      datiGeneraliBenefMorteStatoNascita.descrStatoNascita = unaLabel;
      datiGeneraliBenefMorteStatoNascita.provinciaNascita = '';
      datiGeneraliBenefMorteStatoNascita.descrProvinciaNascita = '';
      datiGeneraliBenefMorteStatoNascita.comuneNascita = '';
      datiGeneraliBenefMorteStatoNascita.descrComuneNascita = '';
      benefMorteFisicoStatoNascita.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteStatoNascita};
      return {
       	...state,
       	benefMorteFisico: benefMorteFisicoStatoNascita,
      }; 
    case actionType.UPDATE_PROVINCIA_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoProvinciaNascita = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteProvinciaNascita = {...benefMorteFisicoProvinciaNascita}.datiGeneraliBenefMorte.datiGenerali;        
      datiGeneraliBenefMorteProvinciaNascita.provinciaNascita = unValore;
      datiGeneraliBenefMorteProvinciaNascita.descrProvinciaNascita = unaLabel;
      datiGeneraliBenefMorteProvinciaNascita.comuneNascita = '';
      datiGeneraliBenefMorteProvinciaNascita.descrComuneNascita = '';
      benefMorteFisicoProvinciaNascita.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteProvinciaNascita};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoProvinciaNascita,
      };       
    case actionType.UPDATE_COMUNE_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoComuneNascita = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteComuneNascita = {...benefMorteFisicoComuneNascita}.datiGeneraliBenefMorte.datiGenerali;        
      datiGeneraliBenefMorteComuneNascita.comuneNascita = unValore;
      datiGeneraliBenefMorteComuneNascita.descrComuneNascita = unaLabel;
      benefMorteFisicoComuneNascita.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteComuneNascita};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoComuneNascita,
      };           
    case actionType.UPDATE_CITTADINANZA_1_BENEF_MORTE_FISICO:
      const benefMorteFisicoCittadinanza1 = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteCittadinanza1 = {...benefMorteFisicoCittadinanza1}.datiGeneraliBenefMorte.datiGenerali;        
      datiGeneraliBenefMorteCittadinanza1.cittadinanza1 = unValore;
      datiGeneraliBenefMorteCittadinanza1.descrCittadinanza1 = unaLabel;
      benefMorteFisicoCittadinanza1.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteCittadinanza1};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoCittadinanza1,
      };      
    case actionType.UPDATE_CITTADINANZA_2_BENEF_MORTE_FISICO:
      const benefMorteFisicoCittadinanza2 = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteCittadinanza2 = {...benefMorteFisicoCittadinanza2}.datiGeneraliBenefMorte.datiGenerali;        
      datiGeneraliBenefMorteCittadinanza2.cittadinanza2 = unValore;
      datiGeneraliBenefMorteCittadinanza2.descrCittadinanza2 = unaLabel;
      benefMorteFisicoCittadinanza2.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteCittadinanza2};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoCittadinanza2,
      };     
    case actionType.UPDATE_NUM_TELEFONO_BENEF_MORTE_FISICO:
      const benefMorteFisicoNumTelefono = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteNumTelefono = {...benefMorteFisicoNumTelefono}.datiGeneraliBenefMorte.datiGenerali;        
      datiGeneraliBenefMorteNumTelefono.numTelefono = action.unDato;
      benefMorteFisicoNumTelefono.datiGeneraliBenefMorte.datiGenerali = {...datiGeneraliBenefMorteNumTelefono};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoNumTelefono,
      };
*/
    case actionType.UPDATE_PERCENTUALE_BENEF_MORTE_FISICO:
      const benefMorteFisicoPercentuale = {...state}.benefMorteFisico;
      const datiGeneraliBenefMortePercentuale = {...benefMorteFisicoPercentuale}.datiGeneraliBenefMorte;        
      datiGeneraliBenefMortePercentuale.percBeneficiario = action.unDato;
      benefMorteFisicoPercentuale.datiGeneraliBenefMorte = {...datiGeneraliBenefMortePercentuale};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoPercentuale,
      };
    case actionType.UPDATE_BENE_DIVERSO_IMP_BENEF_MORTE_FISICO:
      const benefMorteFisicoBenefDiversoImp = {...state}.benefMorteFisico;
      const datiGeneraliBenefMorteBenefDIversoImp = {...benefMorteFisicoBenefDiversoImp}.datiRelazContr;
      datiGeneraliBenefMorteBenefDIversoImp.benDiversoImp = action.unDato;
      benefMorteFisicoBenefDiversoImp.datiRelazContr = {...datiGeneraliBenefMorteBenefDIversoImp};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoBenefDiversoImp,
      }
    case actionType.UPDATE_FL_COPIA_DA_FIGURA_BENEF_MORTE_FISICO:
        const benefMorteFisicoFlCopiaFigura = {...state}.benefMorteFisico;
        const datiCopiaDaAltraFiguraBenefMorteFisicoFlCopiaFigura = {...benefMorteFisicoFlCopiaFigura}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraBenefMorteFisicoFlCopiaFigura.flCopiaDaAltraFigura = action.unDato;
        benefMorteFisicoFlCopiaFigura.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraBenefMorteFisicoFlCopiaFigura};
        return {
          ...state,
          benefMorteFisico: benefMorteFisicoFlCopiaFigura,
        };
    case actionType.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_MORTE_FISICO:
        const benefMorteFisicoCopiaFiguraFrom = {...state}.benefMorteFisico;
        const datiCopiaDaAltraFiguraCopiaBenefMorteFisicoFiguraFrom = {...benefMorteFisicoCopiaFiguraFrom}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraCopiaBenefMorteFisicoFiguraFrom.figuraFrom = unValore;
        datiCopiaDaAltraFiguraCopiaBenefMorteFisicoFiguraFrom.descrFiguraFrom = unaLabel;
        benefMorteFisicoCopiaFiguraFrom.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraCopiaBenefMorteFisicoFiguraFrom};
        return {
          ...state,
          benefMorteFisico: benefMorteFisicoCopiaFiguraFrom,
        };
/* BENEFICIARIO MORTE FISICO DATI GENERALI MINIMI */
    case actionType.UPDATE_CODICE_FISCALE_BENEF_MORTE_FISICO:
      const benefMorteFisicoFiscale = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteFiscale = {...benefMorteFisicoFiscale}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      datiGeneraliMinimiBenefMorteFiscale.codiceFiscale = action.unDato;
      benefMorteFisicoFiscale.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteFiscale};
      return {
     	...state,
       	benefMorteFisico: benefMorteFisicoFiscale,
      };
    case actionType.UPDATE_NOME_BENEF_MORTE_FISICO:
      const benefMorteFisicoNome = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteNome = {...benefMorteFisicoNome}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      datiGeneraliMinimiBenefMorteNome.nome = action.unDato;
      benefMorteFisicoNome.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteNome};
      return {
      	...state,
      	benefMorteFisico: benefMorteFisicoNome,
      };
    case actionType.UPDATE_COGNOME_BENEF_MORTE_FISICO:
      const benefMorteFisicoCognome = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteCognome = {...benefMorteFisicoCognome}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      datiGeneraliMinimiBenefMorteCognome.cognome = action.unDato;
      benefMorteFisicoCognome.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteCognome};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoCognome,
      };
    case actionType.UPDATE_SESSO_BENEF_MORTE_FISICO:
      const benefMorteFisicoSesso = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteSesso = {...benefMorteFisicoSesso}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      datiGeneraliMinimiBenefMorteSesso.sesso = action.unDato;
      benefMorteFisicoSesso.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteSesso};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoSesso,
      };   
    case actionType.UPDATE_DATA_NASCITA_BENEF_MORTE_FISICO:
      const unaDataNascitaMinimi = action.unDato; 
      const nuovaDataNascitaMinimiFormattata = dataFormat(unaDataNascitaMinimi, 'DD/MM/YYYY');       	
      const benefMorteFisicoDataNascita = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteDataNascita = {...benefMorteFisicoDataNascita}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      datiGeneraliMinimiBenefMorteDataNascita.dataNascita = unaDataNascitaMinimi;
      datiGeneraliMinimiBenefMorteDataNascita.dataNascitaStampa = nuovaDataNascitaMinimiFormattata;
      datiGeneraliMinimiBenefMorteDataNascita.statoNascita = '';
      datiGeneraliMinimiBenefMorteDataNascita.descrStatoNascita = '';
      datiGeneraliMinimiBenefMorteDataNascita.provinciaNascita = '';
      datiGeneraliMinimiBenefMorteDataNascita.descrProvinciaNascita = '';
      datiGeneraliMinimiBenefMorteDataNascita.comuneNascita = '';
      datiGeneraliMinimiBenefMorteDataNascita.descrComuneNascita = '';
      datiGeneraliMinimiBenefMorteDataNascita.cittadinanza1 = '';
      datiGeneraliMinimiBenefMorteDataNascita.descrCittadinanza1 = '';
      datiGeneraliMinimiBenefMorteDataNascita.cittadinanza2 = '';
      datiGeneraliMinimiBenefMorteDataNascita.descrCittadinanza2 = '';
      benefMorteFisicoDataNascita.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteDataNascita};
      return {
       	...state,
      	benefMorteFisico: benefMorteFisicoDataNascita,
      };       
    case actionType.UPDATE_STATO_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoStatoNascita = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteStatoNascita = {...benefMorteFisicoStatoNascita}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      datiGeneraliMinimiBenefMorteStatoNascita.statoNascita = unValore;
      datiGeneraliMinimiBenefMorteStatoNascita.descrStatoNascita = unaLabel;
      datiGeneraliMinimiBenefMorteStatoNascita.provinciaNascita = '';
      datiGeneraliMinimiBenefMorteStatoNascita.descrProvinciaNascita = '';
      datiGeneraliMinimiBenefMorteStatoNascita.comuneNascita = '';
      datiGeneraliMinimiBenefMorteStatoNascita.descrComuneNascita = '';
      benefMorteFisicoStatoNascita.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteStatoNascita};
      return {
       	...state,
       	benefMorteFisico: benefMorteFisicoStatoNascita,
      }; 
    case actionType.UPDATE_PROVINCIA_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoProvinciaNascita = {...state}.benefMorteFisico;
      const datiGeneraliMinimiBenefMorteProvinciaNascita = {...benefMorteFisicoProvinciaNascita}.datiGeneraliBenefMorte.datiGeneraliMinimi;        
      datiGeneraliMinimiBenefMorteProvinciaNascita.provinciaNascita = unValore;
      datiGeneraliMinimiBenefMorteProvinciaNascita.descrProvinciaNascita = unaLabel;
      datiGeneraliMinimiBenefMorteProvinciaNascita.comuneNascita = '';
      datiGeneraliMinimiBenefMorteProvinciaNascita.descrComuneNascita = '';
      benefMorteFisicoProvinciaNascita.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteProvinciaNascita};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoProvinciaNascita,
      };       
    case actionType.UPDATE_COMUNE_NASCITA_BENEF_MORTE_FISICO:
      const benefMorteFisicoComuneNascita = {...state}.benefMorteFisico;      
      const datiGeneraliMinimiBenefMorteComuneNascita = {...benefMorteFisicoComuneNascita}.datiGeneraliBenefMorte.datiGeneraliMinimi;
      if (datiGeneraliMinimiBenefMorteComuneNascita.provinciaNascita !== PROVINCIA_ESTERA) {
	      datiGeneraliMinimiBenefMorteComuneNascita.comuneNascita = unValore; // campo select 
	      datiGeneraliMinimiBenefMorteComuneNascita.descrComuneNascita = unaLabel;
      } else {
    	  datiGeneraliMinimiBenefMorteComuneNascita.comuneNascita = '';
    	  datiGeneraliMinimiBenefMorteComuneNascita.descrComuneNascita = action.unDato; // campo testo
      }
      benefMorteFisicoComuneNascita.datiGeneraliBenefMorte.datiGeneraliMinimi = {...datiGeneraliMinimiBenefMorteComuneNascita};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoComuneNascita,
      };
/* BENEFICIARIO MORTE FISICO DATI RESIDENZA */     
    case actionType.UPDATE_STATO_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoStatoResidenza = {...state}.benefMorteFisico;
      const datiResidenzaBenefMorteStatoResidenza = {...benefMorteFisicoStatoResidenza}.datiResidenza;
      datiResidenzaBenefMorteStatoResidenza.statoResidenza = unValore;
      datiResidenzaBenefMorteStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaBenefMorteStatoResidenza.provinciaResidenza = '';
      datiResidenzaBenefMorteStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaBenefMorteStatoResidenza.comuneResidenza = '';
      datiResidenzaBenefMorteStatoResidenza.descrComuneResidenza = '';
      datiResidenzaBenefMorteStatoResidenza.toponimoResidenza = '';
      datiResidenzaBenefMorteStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefMorteStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefMorteStatoResidenza.descrIndirizzoResidenza = '';
      benefMorteFisicoStatoResidenza.datiResidenza = {...datiResidenzaBenefMorteStatoResidenza};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoStatoResidenza,
      }; 
    case actionType.UPDATE_PROVINCIA_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoProvinciaResidenza = {...state}.benefMorteFisico;
      const datiResidenzaBenefMorteProvinciaResidenza = {...benefMorteFisicoProvinciaResidenza}.datiResidenza;        
      datiResidenzaBenefMorteProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaBenefMorteProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaBenefMorteProvinciaResidenza.comuneResidenza = '';
      datiResidenzaBenefMorteProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaBenefMorteProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaBenefMorteProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefMorteProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefMorteProvinciaResidenza.descrIndirizzoResidenza = '';
      benefMorteFisicoProvinciaResidenza.datiResidenza = {...datiResidenzaBenefMorteProvinciaResidenza};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoProvinciaResidenza,
      };       
    case actionType.UPDATE_COMUNE_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoComuneResidenza = {...state}.benefMorteFisico;
      const datiResidenzaBenefMorteComuneResidenza = {...benefMorteFisicoComuneResidenza}.datiResidenza;
      if (datiResidenzaBenefMorteComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefMorteComuneResidenza.comuneResidenza = unValore;
	      datiResidenzaBenefMorteComuneResidenza.descrComuneResidenza = unaLabel;  // campo select
	      datiResidenzaBenefMorteComuneResidenza.toponimoResidenza = '';
	      datiResidenzaBenefMorteComuneResidenza.descrToponimoResidenza = '';
	      //datiResidenzaBenefMorteComuneResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefMorteComuneResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaBenefMorteComuneResidenza.comuneResidenza = '';
    	  datiResidenzaBenefMorteComuneResidenza.descrComuneResidenza = action.unDato; // campo testo
      }
      benefMorteFisicoComuneResidenza.datiResidenza = {...datiResidenzaBenefMorteComuneResidenza};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoComuneResidenza,
      };           
    case actionType.UPDATE_TOPONIMO_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoToponimoResidenza = {...state}.benefMorteFisico;
      const datiResidenzaBenefMorteToponimoResidenza = {...benefMorteFisicoToponimoResidenza}.datiResidenza; 
      if (datiResidenzaBenefMorteToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefMorteToponimoResidenza.toponimoResidenza = unValore;
	      datiResidenzaBenefMorteToponimoResidenza.descrToponimoResidenza = unaLabel; // campo select
	      //datiResidenzaBenefMorteToponimoResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefMorteToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaBenefMorteToponimoResidenza.toponimoResidenza = '';
    	  datiResidenzaBenefMorteToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo
      }
      benefMorteFisicoToponimoResidenza.datiResidenza = {...datiResidenzaBenefMorteToponimoResidenza};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoToponimoResidenza,
      };         
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoIndirizzoResidenza = {...state}.benefMorteFisico; 
      const datiResidenzaBenefMorteIndirizzoResidenza = {...benefMorteFisicoIndirizzoResidenza}.datiResidenza;
      //datiResidenzaBenefMorteIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaBenefMorteIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaBenefMorteIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      benefMorteFisicoIndirizzoResidenza.datiResidenza = {...datiResidenzaBenefMorteIndirizzoResidenza};
      return {
      	...state,
      	benefMorteFisico: benefMorteFisicoIndirizzoResidenza,
      };     
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoNumeroCivicoResidenza = {...state}.benefMorteFisico;
      const datiResidenzaBenefMorteNumeroCivicoResidenza = {...benefMorteFisicoNumeroCivicoResidenza}.datiResidenza;
      datiResidenzaBenefMorteNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      benefMorteFisicoNumeroCivicoResidenza.datiResidenza = {...datiResidenzaBenefMorteNumeroCivicoResidenza};
      return {
       	...state,
       	benefMorteFisico: benefMorteFisicoNumeroCivicoResidenza,
      };       
    case actionType.UPDATE_CAP_RESIDENZA_BENEF_MORTE_FISICO:
      const benefMorteFisicoCapResidenza = {...state}.benefMorteFisico;
      const datiResidenzaBenefMorteCapResidenza = {...benefMorteFisicoCapResidenza}.datiResidenza;
      datiResidenzaBenefMorteCapResidenza.capResidenza = action.unDato;
      benefMorteFisicoCapResidenza.datiResidenza = {...datiResidenzaBenefMorteCapResidenza};
      return {
      	...state,
       	benefMorteFisico: benefMorteFisicoCapResidenza,
      };
/* BENEFICIARIO MORTE FISICO DATI ANTIRICICLAGGIO */
    case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_BENEF_MORTE_FISICO:
      const benefMorteFisicoFlEspostoPoliticamente = {...state}.benefMorteFisico;
      const datiAntiriciclaggioBenefMorteFlEspostoPoliticamente = {...benefMorteFisicoFlEspostoPoliticamente}.datiAntiriciclaggio;
      datiAntiriciclaggioBenefMorteFlEspostoPoliticamente.espostaPoliticamente = action.unDato;
      if (datiAntiriciclaggioBenefMorteFlEspostoPoliticamente.espostaPoliticamente === 'N'){
    	  datiAntiriciclaggioBenefMorteFlEspostoPoliticamente.isInCarica = '';
    	  datiAntiriciclaggioBenefMorteFlEspostoPoliticamente.caricaPersonaEsposta = '';
      }
      benefMorteFisicoFlEspostoPoliticamente.datiAntiriciclaggio = {...datiAntiriciclaggioBenefMorteFlEspostoPoliticamente};
      return {
        ...state,
        benefVitaFisico: benefMorteFisicoFlEspostoPoliticamente,
      };
    case actionType.UPDATE_CARICA_PEP_BENEF_MORTE_FISICO:
      const benefMorteFisicoCaricaPersonaEsposta = {...state}.benefMorteFisico;
      const datiAntiriciclaggioBenefMorteCaricaPersonaEsposta = {...benefMorteFisicoCaricaPersonaEsposta}.datiAntiriciclaggio;
      datiAntiriciclaggioBenefMorteCaricaPersonaEsposta.caricaPersonaEsposta = action.unDato;
      benefMorteFisicoCaricaPersonaEsposta.datiAntiriciclaggio = {...datiAntiriciclaggioBenefMorteCaricaPersonaEsposta};
      return {
        ...state,
        benefVitaFisico: benefMorteFisicoCaricaPersonaEsposta,
      };
    case actionType.UPDATE_CARICA_PUBBLICA_NO_PEP_BENEF_MORTE_FISICO:
      const benefMorteFisicoCaricaPubblicaNoPEP = {...state}.benefMorteFisico;
      const datiAntiriciclaggioBenefMorteCaricaPubblicaNoPEP = {...benefMorteFisicoCaricaPubblicaNoPEP}.datiAntiriciclaggio;
      datiAntiriciclaggioBenefMorteCaricaPubblicaNoPEP.caricaPubblicaNoPEP = action.unDato;
      benefMorteFisicoCaricaPubblicaNoPEP.datiAntiriciclaggio = {...datiAntiriciclaggioBenefMorteCaricaPubblicaNoPEP};
      return {
        ...state,
        benefVitaFisico: benefMorteFisicoCaricaPubblicaNoPEP,
      };       
/* BENEFICIARIO MORTE FISICO DATI RELAZ CONTRAENTE */      
    case actionType.UPDATE_EMAIL_BENEF_MORTE_FISICO:
      const benefMorteFisicoRelazContrEmail = {...state}.benefMorteFisico;
      const datiRelazContrBenefMorteEmail = {...benefMorteFisicoRelazContrEmail}.datiRelazContr;
      datiRelazContrBenefMorteEmail.email = action.unDato;
      benefMorteFisicoRelazContrEmail.datiRelazContr = {...datiRelazContrBenefMorteEmail};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoRelazContrEmail,
      }; 
    case actionType.UPDATE_RELAZ_CONTR_BENEF_MORTE_FISICO:
      const benefMorteFisicoRelazContrRelazContr = {...state}.benefMorteFisico;
      const datiRelazContrBenefMorteRelazContr = {...benefMorteFisicoRelazContrRelazContr}.datiRelazContr;
      datiRelazContrBenefMorteRelazContr.relazContraente = unValore;
      datiRelazContrBenefMorteRelazContr.descrRelazContraente = unaLabel;
      datiRelazContrBenefMorteRelazContr.altro = '';
      benefMorteFisicoRelazContrRelazContr.datiRelazContr = {...datiRelazContrBenefMorteRelazContr};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoRelazContrRelazContr,
      };   
    case actionType.UPDATE_ESCLUS_COMUNIC_BENEF_MORTE_FISICO:
      const benefMorteFisicoRelazContrEsclusioneComunicaz = {...state}.benefMorteFisico;
      const datiRelazContrBenefMorteEsclusioneComunicaz = {...benefMorteFisicoRelazContrEsclusioneComunicaz}.datiRelazContr;
      datiRelazContrBenefMorteEsclusioneComunicaz.esclusioneComunicaz = action.unDato;
      benefMorteFisicoRelazContrEsclusioneComunicaz.datiRelazContr = {...datiRelazContrBenefMorteEsclusioneComunicaz};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoRelazContrEsclusioneComunicaz,
      };    
    case actionType.UPDATE_ALTRO_RELAZ_CONTR_BENEF_MORTE_FISICO:
      const benefMorteFisicoRelazContrAltro = {...state}.benefMorteFisico;
      const datiRelazContrBenefMorteAltro   = {...benefMorteFisicoRelazContrAltro}.datiRelazContr;
      datiRelazContrBenefMorteAltro.altro = action.unDato;
      benefMorteFisicoRelazContrAltro.datiRelazContr = {...datiRelazContrBenefMorteAltro};
      return {
        ...state,
        benefMorteFisico: benefMorteFisicoRelazContrAltro,
      };
/* BENEFICIARIO MORTE GIURIDICO */  
    case actionType.SALVA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoSalva = {...state}.benefMorteGiuridico;
      benefMorteGiuridicoSalva.controlloDati = action.unDato;
      return {
    	...state,
    	benefMorteGiuridico: benefMorteGiuridicoSalva,
      };
    case actionType.SALVA_BENEF_MORTE_GIURIDICO_SUCCESS:    
        return {
      	...state,
      	salvaBenefMorteGiuridicoSuccess: action.unDato,
        };       
    case actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_GIURIDICO:
        const benefMorteGiuridicoAnagraficaValida = {...state}.benefMorteGiuridico;
        benefMorteGiuridicoAnagraficaValida.anagraficaValida = action.unDato;
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridicoAnagraficaValida,
        };
    case actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_GIURIDICO:            
        const beneficiarioMorteGiuridicoConfermaAggiungi = action.beneficiarioMorteGiuridico;
        const dataNascitaBeneficiarioMorteGiuridicoConfermaAggiungi = beneficiarioMorteGiuridicoConfermaAggiungi.datiGeneraliBenefGiuridico.datiGenerali.dataNascita;
        const cloneBeneficiarioMorteGiuridico = jsonCopy(beneficiarioMorteGiuridicoConfermaAggiungi);
        // reimposoto la data di nascita
        cloneBeneficiarioMorteGiuridico.datiGeneraliBenefGiuridico.datiGenerali.dataNascita=dataNascitaBeneficiarioMorteGiuridicoConfermaAggiungi;
        const beneficiarioMorteGiuridicoListConfermaAggiungi = {...state}.benefMorteGiuridicoList;
        const beneficiarioMorteGiuridicoListConfermaAggiungiNuovo = [...beneficiarioMorteGiuridicoListConfermaAggiungi, cloneBeneficiarioMorteGiuridico];                
        return {
          ...state,
//          benefMorteGiuridico: beneficiarioMorteGiuridico,
          benefMorteGiuridicoList: beneficiarioMorteGiuridicoListConfermaAggiungiNuovo,
        };
    case actionType.AGGIUNGI_TITOLARE_BENEF_MORTE_GIURIDICO:
        const benefMorteListTitGiur = {...state}.benefMorteGiuridicoList;
        const benefMorteGiuridicoAggTit = {...state}.benefMorteGiuridico;
        const cloneTitEffBenefMorteInBenef = jsonCopy(action.currTitolareEff);
        benefMorteListTitGiur.map((el, key) => {
          if(el.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === benefMorteGiuridicoAggTit.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale) {
            const titEffListInBenef = el.titolareEffBenefMorteListInBenef;
            const titEffListAggiungi = [...titEffListInBenef, cloneTitEffBenefMorteInBenef];
            const benefAggiungiTitolare = {...el};
            benefAggiungiTitolare.titolareEffBenefMorteListInBenef = titEffListAggiungi;
            benefMorteListTitGiur[key] = benefAggiungiTitolare;
          }
        });
        return {
          ...state,
          benefMorteGiuridicoList: benefMorteListTitGiur
        }
    case actionType.NUOVO_BENEFICIARIO_MORTE_GIURIDICO:      
        const beneficiarioMorteGiuridicoNuovo = inizializzaBeneficiarioMorteGiuridico(state);
        
        return {
          ...state,
          benefMorteGiuridico: beneficiarioMorteGiuridicoNuovo,
          salvaBenefMorteGiuridicoSuccess: false,
        };
    case actionType.UPDATE_BENEF_MORTE_GIURIDICO:      
        const beneficiarioMorteGiuridicoUpdate = action.currBenefMorteGiuridico;
        const dataNascitaBeneficiarioMorteGiuridicoUpdate = beneficiarioMorteGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.dataNascita;
        const cloneBeneficiarioMorteGiuridicoUpdate = jsonCopy(beneficiarioMorteGiuridicoUpdate);
         // reimposoto la data di nascita
        cloneBeneficiarioMorteGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.dataNascita=dataNascitaBeneficiarioMorteGiuridicoUpdate;
        const beneficiarioMorteGiuridicoCodiceFiscale = cloneBeneficiarioMorteGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale;
        const beneficiarioMorteGiuridicoRagioneSociale = cloneBeneficiarioMorteGiuridicoUpdate.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale;
        const beneficiarioMorteGiuridicoListUpdate = [...state.benefMorteGiuridicoList];
        
        let indexBeneficiarioMorteGiuridicoUpdate = undefined;        
        beneficiarioMorteGiuridicoListUpdate.forEach( (item, index, array) => {
        	if (cloneBeneficiarioMorteGiuridicoUpdate.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!=='S') {
	        	if (beneficiarioMorteGiuridicoCodiceFiscale===item.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale){
	        		indexBeneficiarioMorteGiuridicoUpdate = index;
	        	}
        	} else {
        		// Cerco per cognome
	        	if (beneficiarioMorteGiuridicoRagioneSociale===item.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale){
	        		indexBeneficiarioMorteGiuridicoUpdate = index;
	        	}
        	}
        });
        
        beneficiarioMorteGiuridicoListUpdate[indexBeneficiarioMorteGiuridicoUpdate] = cloneBeneficiarioMorteGiuridicoUpdate;

        return {
          ...state,
         	benefMorteGiuridicoList: beneficiarioMorteGiuridicoListUpdate,
        };   
    case actionType.DELETE_BENEF_MORTE_GIURIDICO:
    	let benMorteGiuridicoToDelete = action.benefMorteGiuridicoDelete;
    	const percBenefMorteGiuridicoDelete = benMorteGiuridicoToDelete.datiGeneraliBenefGiuridico.percBeneficiario; 
    	
		const filterdBenMorteGiuridicoDeleteList = state.benefMorteGiuridicoList.filter( item => {
			const codiceFiscale = item.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale;   			
			return codiceFiscale!==benMorteGiuridicoToDelete.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale
    })
    benefMorteGiuridico.anagraficaValida = false;
		 return {
	          ...state,
	          percBenMorteTotale: state.percBenMorteTotale - percBenefMorteGiuridicoDelete, // ricalcolo la percentuale Totale
            benefMorteGiuridicoList: filterdBenMorteGiuridicoDeleteList,
            benefMorteGiuridico: benefMorteGiuridico
       };
    case actionType.TITOLARI_EFFETTIVI_BENEFICIARI_MORTE:
      const benefMorteTitolariEffettivi = {...state}.benefMorteGiuridico;
      benefMorteTitolariEffettivi.flTitolareEffBenef = action.unDato;
      return{
        ...state,
        benefMorteGiuridico: benefMorteTitolariEffettivi,
      };
/* DATI ATTIVITA ECONOMICA GIURIDICO MORTE */
case actionType.UPDATE_ATT_PREVAL_SVOLTA_MORTE_GIUR:
  const benefMorteGiurAttPrevalSvolta = {...state}.benefMorteGiuridico;
  const datiAttEconomicaMorteAttPrevalSvolta = {...benefMorteGiurAttPrevalSvolta}.datiAttEconomica;
  datiAttEconomicaMorteAttPrevalSvolta.codAttPrevalSvolta = unValore;
  datiAttEconomicaMorteAttPrevalSvolta.descrAttPrevalSvolta = unaLabel;  
  datiAttEconomicaMorteAttPrevalSvolta.codSAE = '';
  datiAttEconomicaMorteAttPrevalSvolta.descrSAE = '';
  datiAttEconomicaMorteAttPrevalSvolta.altroAttPrevAtt = '';
  benefMorteGiurAttPrevalSvolta.datiAttEconomica = {...datiAttEconomicaMorteAttPrevalSvolta};
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurAttPrevalSvolta,
  }
case actionType.UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT_MORTE_GIURIDICO:
  const benefMorteGiurAltroAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteAltroAttPrevalSvolta = { ...benefMorteGiurAltroAttPrevalSvolta }.datiAttEconomica;
  datiAttEconomicaMorteAltroAttPrevalSvolta.altroAttPrevAtt = action.unDato;
  benefMorteGiurAltroAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteAltroAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurAltroAttPrevalSvolta,
  }  
case actionType.UPDATE_STAT_ATT_PREV_SVOLTA_MORTE_GIURIDICO:
  const benefMorteGiurStatoAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteStatoAttPrevalSvolta = { ...benefMorteGiurStatoAttPrevalSvolta }.datiAttEconomica;
  datiAttEconomicaMorteStatoAttPrevalSvolta.statoAttPrevalSvolta = unValore;
  datiAttEconomicaMorteStatoAttPrevalSvolta.descrStatoAttPrevalSvolta = unaLabel;
  datiAttEconomicaMorteStatoAttPrevalSvolta.provinciaAttPrevalSvolta = '';
  datiAttEconomicaMorteStatoAttPrevalSvolta.descrProvinciaAttPrevalSvolta = '';
  benefMorteGiurStatoAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteStatoAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurStatoAttPrevalSvolta,
  }
case actionType.UPDATE_PROVINCIA_ATT_PREV_SVOLTA_MORTE_GIURIDICO:
  const benefMorteGiurProvinciaAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteProvinciaAttPrevalSvolta = { ...benefMorteGiurProvinciaAttPrevalSvolta }.datiAttEconomica;
  datiAttEconomicaMorteProvinciaAttPrevalSvolta.provinciaAttPrevalSvolta = unValore;
  datiAttEconomicaMorteProvinciaAttPrevalSvolta.descrProvinciaAttPrevalSvolta = unaLabel;
  datiAttEconomicaMorteProvinciaAttPrevalSvolta.comuneAttPrevalSvolta = '';
  datiAttEconomicaMorteProvinciaAttPrevalSvolta.descrComuneAttPrevalSvolta = '';
  benefMorteGiurProvinciaAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteProvinciaAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurProvinciaAttPrevalSvolta,
  }
case actionType.UPDATE_COMUNE_ATT_PREV_SVOLTA_MORTE_GIURIDICO:
  const benefMorteGiurComuneAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteComuneAttPrevalSvolta = { ...benefMorteGiurComuneAttPrevalSvolta }.datiAttEconomica;
  if(datiAttEconomicaMorteComuneAttPrevalSvolta.provinciaAttPrevalSvolta !== PROVINCIA_ESTERA){
  datiAttEconomicaMorteComuneAttPrevalSvolta.comuneAttPrevalSvolta = unValore;
  datiAttEconomicaMorteComuneAttPrevalSvolta.descrComuneAttPrevalSvolta = unaLabel;
  }else {
    datiAttEconomicaMorteComuneAttPrevalSvolta.comuneAttPrevalSvolta = '';
    datiAttEconomicaMorteComuneAttPrevalSvolta.descrComuneAttPrevalSvolta = action.unDato;
  }
  benefMorteGiurComuneAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteComuneAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurComuneAttPrevalSvolta,
  }
case actionType.UPDATE_TIPO_SOCIETA_MORTE_GIURIDICO:
  const benefMorteGiurSocietaAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteSocietaAttPrevalSvolta = { ...benefMorteGiurSocietaAttPrevalSvolta }.datiAttEconomica;
  datiAttEconomicaMorteSocietaAttPrevalSvolta.tipoSocieta = unValore;
  datiAttEconomicaMorteSocietaAttPrevalSvolta.descrTipoSocieta = unaLabel;
  datiAttEconomicaMorteSocietaAttPrevalSvolta.codSAE = '';
  datiAttEconomicaMorteSocietaAttPrevalSvolta.descrSAE = '';
  datiAttEconomicaMorteSocietaAttPrevalSvolta.codATECO = '';
  datiAttEconomicaMorteSocietaAttPrevalSvolta.descrATECO = '';
  benefMorteGiurSocietaAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteSocietaAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurSocietaAttPrevalSvolta,
  }
case actionType.UPDATE_COD_SAE_MORTE_GIURIDICO:
  const benefMorteGiurSAEAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteSAEAttPrevalSvolta = { ...benefMorteGiurSAEAttPrevalSvolta }.datiAttEconomica;
  datiAttEconomicaMorteSAEAttPrevalSvolta.codSAE = unValore;
  datiAttEconomicaMorteSAEAttPrevalSvolta.descrSAE = unaLabel;
  datiAttEconomicaMorteSAEAttPrevalSvolta.codATECO = '';
  datiAttEconomicaMorteSAEAttPrevalSvolta.descrATECO = '';
  benefMorteGiurSAEAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteSAEAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurSAEAttPrevalSvolta,
  }      
case actionType.UPDATE_COD_ATECO_MORTE_GIURIDICO:
  const benefMorteGiurATECOAttPrevalSvolta = { ...state }.benefMorteGiuridico;
  const datiAttEconomicaMorteATECOAttPrevalSvolta = { ...benefMorteGiurATECOAttPrevalSvolta }.datiAttEconomica;
  datiAttEconomicaMorteATECOAttPrevalSvolta.codATECO = unValore;
  datiAttEconomicaMorteATECOAttPrevalSvolta.descrATECO = unaLabel;
  benefMorteGiurATECOAttPrevalSvolta.datiAttEconomica = { ...datiAttEconomicaMorteATECOAttPrevalSvolta };
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurATECOAttPrevalSvolta,
  }
case actionType.UPDATE_SOCIETA_AZ_FIDUCIARIO_BENEF_MORTE_GIURIDICO:
  const benefMorteGiurSocietaAzFiduciario  = {...state}.benefMorteGiuridico;
  const datiAttEconomicaSocietaAzFiduciario = {...benefMorteGiurSocietaAzFiduciario}.datiAttEconomica;
  datiAttEconomicaSocietaAzFiduciario.societaAzFiduciario = action.unDato;
  benefMorteGiurSocietaAzFiduciario.datiAttEconomica = {...datiAttEconomicaSocietaAzFiduciario};
  return {
    ...state,
    benefMorteGiuridico: benefMorteGiurSocietaAzFiduciario,
  }


/* BENEFICIARIO MORTE GIURIDICO DATI GENERALI */
    case actionType.UPDATE_RAGIONE_SOCIALE_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoRagioneSociale = {...state}.benefMorteGiuridico;
      const datiGeneraliBenefMorteGiuridicoRagioneSociale = {...benefMorteGiuridicoRagioneSociale}.datiGeneraliBenefGiuridico.datiGenerali;
      datiGeneraliBenefMorteGiuridicoRagioneSociale.ragioneSociale = action.unDato;
      benefMorteGiuridicoRagioneSociale.datiGeneraliBenefGiuridico.datiGenerali = {...datiGeneraliBenefMorteGiuridicoRagioneSociale};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoRagioneSociale,
      };  
    case actionType.UPDATE_CODICE_FISCALE_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoFiscale = {...state}.benefMorteGiuridico;
      const datiGeneraliBenefMorteGiuridicoFiscale = {...benefMorteGiuridicoFiscale}.datiGeneraliBenefGiuridico.datiGenerali;
      datiGeneraliBenefMorteGiuridicoFiscale.codiceFiscale = action.unDato;
      benefMorteGiuridicoFiscale.datiGeneraliBenefGiuridico.datiGenerali = {...datiGeneraliBenefMorteGiuridicoFiscale};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoFiscale,
      };
    case actionType.UPDATE_DATA_NASCITA_BENEF_MORTE_GIURIDICO:
      const unaDataNascitaGiuridica = action.unDato;    	
      const nuovaDataNascitaGiuridicaFormattata = dataFormat(unaDataNascitaGiuridica, 'DD/MM/YYYY');
      const benefMorteGiuridicoDataNascita = {...state}.benefMorteGiuridico;
      const datiGeneraliBenefMorteGiuridicoDataNascita = {...benefMorteGiuridicoDataNascita}.datiGeneraliBenefGiuridico.datiGenerali;
      datiGeneraliBenefMorteGiuridicoDataNascita.dataNascita = unaDataNascitaGiuridica;
      datiGeneraliBenefMorteGiuridicoDataNascita.dataNascitaStampa = nuovaDataNascitaGiuridicaFormattata;
      benefMorteGiuridicoDataNascita.datiGeneraliBenefGiuridico.datiGenerali = {...datiGeneraliBenefMorteGiuridicoDataNascita};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoDataNascita,
      };
    case actionType.UPDATE_BENEFICIARIO_DIVERSO_IMPRESA_BENEF_MORTE_GIUR:
      const benefMorteDiversoImpresa = {...state}.benefMorteGiuridico;
      const datiGeneraliBenefMorteDiversoImpresa = {...benefMorteDiversoImpresa}.datiRelazContr;
      datiGeneraliBenefMorteDiversoImpresa.benDiversoImp = action.unDato;
      benefMorteDiversoImpresa.datiRelazContr = {...datiGeneraliBenefMorteDiversoImpresa};
      return {
        ...state,
        benefMorteGiuridico: benefMorteDiversoImpresa,
      };      
    case actionType.UPDATE_PERCENTUALE_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoPercentuale = {...state}.benefMorteGiuridico;
      const datiGeneraliBenefMorteGiuridicoPercentuale = {...benefMorteGiuridicoPercentuale}.datiGeneraliBenefGiuridico;        
      datiGeneraliBenefMorteGiuridicoPercentuale.percBeneficiario = action.unDato;
      benefMorteGiuridicoPercentuale.datiGeneraliBenefGiuridico = {...datiGeneraliBenefMorteGiuridicoPercentuale};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoPercentuale,
      };
    case actionType.UPDATE_FL_COPIA_DA_FIGURA_BENEF_MORTE_GIURIDICO:
        const benefMorteGiuridicoFlCopiaFigura = {...state}.benefMorteGiuridico;
        const datiCopiaDaAltraFiguraBenefMorteGiuridicoFlCopiaFigura = {...benefMorteGiuridicoFlCopiaFigura}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraBenefMorteGiuridicoFlCopiaFigura.flCopiaDaAltraFigura = action.unDato;
        benefMorteGiuridicoFlCopiaFigura.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraBenefMorteGiuridicoFlCopiaFigura};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridicoFlCopiaFigura,
        };
    case actionType.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_MORTE_GIURIDICO:
        const benefMorteGiuridicoCopiaFiguraFrom = {...state}.benefMorteGiuridico;
        const datiCopiaDaAltraFiguraCopiaBenefMorteGiuridicoFiguraFrom = {...benefMorteGiuridicoCopiaFiguraFrom}.datiCopiaDaAltraFigura;        
        datiCopiaDaAltraFiguraCopiaBenefMorteGiuridicoFiguraFrom.figuraFrom = unValore;
        datiCopiaDaAltraFiguraCopiaBenefMorteGiuridicoFiguraFrom.descrFiguraFrom = unaLabel;
        datiCopiaDaAltraFiguraCopiaBenefMorteGiuridicoFiguraFrom.isFiduciaria = action.isFiduciaria;
        benefMorteGiuridicoCopiaFiguraFrom.datiCopiaDaAltraFigura = {...datiCopiaDaAltraFiguraCopiaBenefMorteGiuridicoFiguraFrom};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridicoCopiaFiguraFrom,
        };      
/* BENEFICIARIO MORTE GIURIDICO DATI RESIDENZA */      
    case actionType.UPDATE_STATO_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoStatoResidenza = {...state}.benefMorteGiuridico;
      const datiResidenzaBenefMorteGiuridicoStatoResidenza = {...benefMorteGiuridicoStatoResidenza}.datiResidenza;
      datiResidenzaBenefMorteGiuridicoStatoResidenza.statoResidenza = unValore;
      datiResidenzaBenefMorteGiuridicoStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaBenefMorteGiuridicoStatoResidenza.provinciaResidenza = '';
      datiResidenzaBenefMorteGiuridicoStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaBenefMorteGiuridicoStatoResidenza.comuneResidenza = '';
      datiResidenzaBenefMorteGiuridicoStatoResidenza.descrComuneResidenza = '';
      datiResidenzaBenefMorteGiuridicoStatoResidenza.toponimoResidenza = '';
      datiResidenzaBenefMorteGiuridicoStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefMorteGiuridicoStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefMorteGiuridicoStatoResidenza.descrIndirizzoResidenza = '';
      benefMorteGiuridicoStatoResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoStatoResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoStatoResidenza,
      }; 
    case actionType.UPDATE_PROVINCIA_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoProvinciaResidenza = {...state}.benefMorteGiuridico;
      const datiResidenzaBenefMorteGiuridicoProvinciaResidenza = {...benefMorteGiuridicoProvinciaResidenza}.datiResidenza;        
      datiResidenzaBenefMorteGiuridicoProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaBenefMorteGiuridicoProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaBenefMorteGiuridicoProvinciaResidenza.comuneResidenza = '';
      datiResidenzaBenefMorteGiuridicoProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaBenefMorteGiuridicoProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaBenefMorteGiuridicoProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaBenefMorteGiuridicoProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaBenefMorteGiuridicoProvinciaResidenza.descrIndirizzoResidenza = '';
      benefMorteGiuridicoProvinciaResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoProvinciaResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoProvinciaResidenza,
      };       
    case actionType.UPDATE_COMUNE_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoComuneResidenza = {...state}.benefMorteGiuridico;
      const datiResidenzaBenefMorteGiuridicoComuneResidenza = {...benefMorteGiuridicoComuneResidenza}.datiResidenza;
      if (datiResidenzaBenefMorteGiuridicoComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefMorteGiuridicoComuneResidenza.comuneResidenza = unValore;
	      datiResidenzaBenefMorteGiuridicoComuneResidenza.descrComuneResidenza = unaLabel; // campo select
	      datiResidenzaBenefMorteGiuridicoComuneResidenza.toponimoResidenza = '';
	      datiResidenzaBenefMorteGiuridicoComuneResidenza.descrToponimoResidenza = '';
	      //datiResidenzaBenefMorteGiuridicoComuneResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefMorteGiuridicoComuneResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaBenefMorteGiuridicoComuneResidenza.comuneResidenza = '';
    	  datiResidenzaBenefMorteGiuridicoComuneResidenza.descrComuneResidenza = action.unDato; // campo testo
      }
      benefMorteGiuridicoComuneResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoComuneResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoComuneResidenza,
      };           
    case actionType.UPDATE_TOPONIMO_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoToponimoResidenza = {...state}.benefMorteGiuridico;
      const datiResidenzaBenefMorteGiuridicoToponimoResidenza = {...benefMorteGiuridicoToponimoResidenza}.datiResidenza;
      if (datiResidenzaBenefMorteGiuridicoToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA) {
	      datiResidenzaBenefMorteGiuridicoToponimoResidenza.toponimoResidenza = unValore;
	      datiResidenzaBenefMorteGiuridicoToponimoResidenza.descrToponimoResidenza = unaLabel; // campo select
	      //datiResidenzaBenefMorteGiuridicoToponimoResidenza.indirizzoResidenza = '';
	      //datiResidenzaBenefMorteGiuridicoToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaBenefMorteGiuridicoToponimoResidenza.toponimoResidenza = '';
    	  datiResidenzaBenefMorteGiuridicoToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo
      }
      benefMorteGiuridicoToponimoResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoToponimoResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoToponimoResidenza,
      };         
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoIndirizzoResidenza = {...state}.benefMorteGiuridico; 
      const datiResidenzaBenefMorteGiuridicoIndirizzoResidenza = {...benefMorteGiuridicoIndirizzoResidenza}.datiResidenza;
      //datiResidenzaBenefMorteGiuridicoIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaBenefMorteGiuridicoIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaBenefMorteGiuridicoIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      benefMorteGiuridicoIndirizzoResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoIndirizzoResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoIndirizzoResidenza,
      };     
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoNumeroCivicoResidenza = {...state}.benefMorteGiuridico;
      const datiResidenzaBenefMorteGiuridicoNumeroCivicoResidenza = {...benefMorteGiuridicoNumeroCivicoResidenza}.datiResidenza;
      datiResidenzaBenefMorteGiuridicoNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      benefMorteGiuridicoNumeroCivicoResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoNumeroCivicoResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoNumeroCivicoResidenza,
      };       
    case actionType.UPDATE_CAP_RESIDENZA_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoCapResidenza = {...state}.benefMorteGiuridico;
      const datiResidenzaBenefMorteGiuridicoCapResidenza = {...benefMorteGiuridicoCapResidenza}.datiResidenza;
      datiResidenzaBenefMorteGiuridicoCapResidenza.capResidenza = action.unDato;
      benefMorteGiuridicoCapResidenza.datiResidenza = {...datiResidenzaBenefMorteGiuridicoCapResidenza};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoCapResidenza,
      };
/* BENEFICIARIO MORTE GIURIDICO DATI RELAZ CONTRAENTE */      
    case actionType.UPDATE_EMAIL_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoRelazContrEmail = {...state}.benefMorteGiuridico;
      const datiRelazContrBenefMorteGiuridicoEmail = {...benefMorteGiuridicoRelazContrEmail}.datiRelazContr;
      datiRelazContrBenefMorteGiuridicoEmail.email = action.unDato;
      benefMorteGiuridicoRelazContrEmail.datiRelazContr = {...datiRelazContrBenefMorteGiuridicoEmail};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoRelazContrEmail,
      }; 
    case actionType.UPDATE_RELAZ_CONTR_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoRelazContrRelazContr = {...state}.benefMorteGiuridico;
      const datiRelazContrBenefMorteGiuridicoRelazContr = {...benefMorteGiuridicoRelazContrRelazContr}.datiRelazContr;
      datiRelazContrBenefMorteGiuridicoRelazContr.relazContraente = unValore;
      datiRelazContrBenefMorteGiuridicoRelazContr.descrRelazContraente = unaLabel;
      datiRelazContrBenefMorteGiuridicoRelazContr.altro = '';
      datiRelazContrBenefMorteGiuridicoRelazContr.benDiversoImp = '';
      benefMorteGiuridicoRelazContrRelazContr.datiRelazContr = {...datiRelazContrBenefMorteGiuridicoRelazContr};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoRelazContrRelazContr,
      };   
    case actionType.UPDATE_ALTRO_RELAZ_CONTR_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoRelazContrAltro = {...state}.benefMorteGiuridico;
      const datiRelazContrRelazione = {...benefMorteGiuridicoRelazContrAltro}.datiRelazContr;
      datiRelazContrRelazione.altro = action.unDato;
      benefMorteGiuridicoRelazContrAltro.datiRelazContr = {...datiRelazContrRelazione};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoRelazContrAltro
      }
    case actionType.UPDATE_ESCLUS_COMUNIC_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoRelazContrEsclusioneComunicaz = {...state}.benefMorteGiuridico;
      const datiRelazContrBenefMorteGiuridicoEsclusioneComunicaz = {...benefMorteGiuridicoRelazContrEsclusioneComunicaz}.datiRelazContr;
      datiRelazContrBenefMorteGiuridicoEsclusioneComunicaz.esclusioneComunicaz = action.unDato;
      benefMorteGiuridicoRelazContrEsclusioneComunicaz.datiRelazContr = {...datiRelazContrBenefMorteGiuridicoEsclusioneComunicaz};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoRelazContrEsclusioneComunicaz,
      };  
        /* DATI SOCIETARI */
    case actionType.ISCRIZIONE_REGISTRO_BENEF_MORTE_GIURIDICO:
      const benefMorteGiuridicoIscr = {...state}.benefMorteGiuridico;
      const benefDatiSocietariIscr = {...benefMorteGiuridicoIscr}.datiSocietari;
      benefDatiSocietariIscr.codTipoIscrRegistro = unValore;
      benefDatiSocietariIscr.descTipoIscrRegistro = unaLabel;
      benefMorteGiuridicoIscr.datiSocietari = {...benefDatiSocietariIscr};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoIscr
      }     
    case actionType.NUMERO_REGISTRO_BENEF_GIURIDICO:
      const benefMorteGiuridicoReg = {...state}.benefMorteGiuridico;
      const benefDatiSocietariReg = {...benefMorteGiuridicoReg}.datiSocietari;
      benefDatiSocietariReg.numeroRegistro = action.unDato;
      benefMorteGiuridicoReg.datiSocietari = {...benefDatiSocietariReg};
      return {
        ...state,
        benefMorteGiuridico: benefMorteGiuridicoReg
      }
      /* DATI MANDATO FIDUCIARIO */
    case actionType.UPDATE_MANDATO_BENEF_MORTE_GIURIDICO:
      const mandatoBenefMorteGiuridico = {...state}.benefMorteGiuridico;
      const mandatoDatiMandato = {...mandatoBenefMorteGiuridico}.datiMandatoFiduciario;
      mandatoDatiMandato.numeroMandatoFid = action.unDato;
      mandatoBenefMorteGiuridico.datiMandatoFiduciario = {...mandatoDatiMandato};
      return {
        ...state,
        benefMorteGiuridico: mandatoBenefMorteGiuridico
      }
    case actionType.UPDATE_INTESTATARIO_MANDATO_BENEF_MORTE_GIURIDICO:
      const intestBenefMorteGiuridico = {...state}.benefMorteGiuridico;
      const intestDatiMandato = {...intestBenefMorteGiuridico}.datiMandatoFiduciario;
      intestDatiMandato.intestatarioMandato = action.unDato;
      intestBenefMorteGiuridico.datiMandatoFiduciario = {...intestDatiMandato};
      return {
        ...state,
        benefMorteGiuridico: intestBenefMorteGiuridico
      }
/* BENEFICIARIO MORTE GENERICO */  
    case actionType.SALVA_BENEF_MORTE_GENERICO:
      console.log('#RT2 Reducer areaBenefMorteReducer SALVA_BENEF_MORTE_GENERICO', actionType.SALVA_BENEF_MORTE_GENERICO); 
      console.log('#RT2 Reducer areaBenefMorteReducer action', action);  
      const benefMorteGenericoSalva = {...state}.benefMorteGenerico;
      benefMorteGenericoSalva.controlloDati = action.unDato;
      return {
    	...state,
    	benefMorteGenerico: benefMorteGenericoSalva,
      };
    case actionType.SALVA_BENEF_MORTE_GENERICO_SUCCESS: 
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.SALVA_BENEF_MORTE_GENERICO_SUCCESS.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);    
        return {
      	...state,
      	salvaBenefMorteGenericoSuccess: action.unDato,
        };         
    case actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_GENERICO:
        console.log('#RT2 Reducer areaBenefMorteReducer actionType', actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_GENERICO);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);
        const benefMorteGenericoAnagraficaValida = {...state}.benefMorteGenerico;
        benefMorteGenericoAnagraficaValida.anagraficaValida = action.unDato;
        return {
          ...state,
          benefMorteGenerico: benefMorteGenericoAnagraficaValida,
        };
    case actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_GENERICO:      
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_GENERICO.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);   
        const cloneBeneficiarioMorteGenerico = jsonCopy(action.beneficiarioMorteGenerico);
        const beneficiarioMorteGenericoListConfermaAggiungi = {...state}.benefMorteGenericoList;
        const beneficiarioMorteGenericoListConfermaAggiungiNuovo = [...beneficiarioMorteGenericoListConfermaAggiungi, cloneBeneficiarioMorteGenerico];
                
        return {
          ...state,
//          benefMorteGenerico: cloneBeneficiarioMorteGenerico,
          benefMorteGenericoList: beneficiarioMorteGenericoListConfermaAggiungiNuovo,          
        };
    case actionType.UPDATE_BENEFICIARIO_MORTE_GENERICO:
        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.UPDATE_BENEFICIARIO_MORTE_GENERICO.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);
        const cloneBeneficiarioMorteGenericoUpdate = jsonCopy(action.currBenefMorteGenerico);
        const beneficiarioMorteGenericoListUpdateMorte = [...state.benefMorteGenericoList];
        beneficiarioMorteGenericoListUpdateMorte[0] = {...cloneBeneficiarioMorteGenericoUpdate};
        return {
          ...state,
         	benefMorteGenericoList: beneficiarioMorteGenericoListUpdateMorte,
        };
    case actionType.DELETE_BENEF_MORTE_GENERICO:
/*      const benMorteGenericoDelete = action.benefMorteGenericoDelete;
      const percBenefMorteGenericoDelete = benMorteGenericoDelete.datiGenerali.percBeneficiario;*/
      //const beneficiarioMorteGenericoDelete = inizializzaBeneficiarioMorteGenerico(state);
      return {
  	    ...state,
  	    percBenMorteTotale: null,  	    
  	    benefMorteGenericoList: [],        
      };
/* BENEFICIARIO MORTE GENERICO DATI GENERALI */
    case actionType.UPDATE_PERCENTUALE_BENEF_MORTE_GENERICO:
      const benefMorteGenericoPercentuale = {...state}.benefMorteGenerico;
      const datiGeneraliGenericoPercentuale = {...benefMorteGenericoPercentuale}.datiGenerali;
      datiGeneraliGenericoPercentuale.percBeneficiario = action.unDato;
      benefMorteGenericoPercentuale.datiGenerali = {...datiGeneraliGenericoPercentuale};
      return {
        ...state,
        benefMorteGenerico: benefMorteGenericoPercentuale,
      }; 
    case actionType.UPDATE_BENEF_MORTE_GENERICO:
      const benefMorteGenericoDati = {...state}.benefMorteGenerico;
      const datiGeneraliGenericoDati = {...benefMorteGenericoDati}.datiGenerali;
      datiGeneraliGenericoDati.benefGenerico = unValore;
      datiGeneraliGenericoDati.descrBenefGenerico = unaLabel;
      benefMorteGenericoDati.datiGenerali = {...datiGeneraliGenericoDati};
      return {
        ...state,
        benefMorteGenerico: benefMorteGenericoDati,
      };                
    case actionType.UPDATE_ESTESO_BENEF_MORTE_GENERICO:
      const benefMorteGenericoEsteso = {...state}.benefMorteGenerico;
      const datiGeneraliGenericoEsteso = {...benefMorteGenericoEsteso}.datiGenerali;
      datiGeneraliGenericoEsteso.descrBenefEsteso = action.unDato;
      benefMorteGenericoEsteso.datiGenerali = {...datiGeneraliGenericoEsteso};
      return {
        ...state,
        benefMorteGenerico: benefMorteGenericoEsteso,
      };
    case actionType.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE:
      datiGeneraliTitEffMorte.sesso = action.unDato;
      if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.sesso = action.unDato;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};	
			} else {
				datiGeneraliMinimiTitEffBenefMorte.sesso = action.unDato;
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}

			/* DATI RESIDENZA TITOLARE EFFETTIVO BENEFICIARIO MORTE */
      case actionType.UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_MORTE:
        datiResidenzaTitEffMorte.statoResidenza = unValore;
        datiResidenzaTitEffMorte.descrStatoResidenza = unaLabel;
        datiResidenzaTitEffMorte.provinciaResidenza = '';
        datiResidenzaTitEffMorte.descrProvinciaResidenza = '';
        datiResidenzaTitEffMorte.comuneResidenza = '';
        datiResidenzaTitEffMorte.descrComuneResidenza = '';
        datiResidenzaTitEffMorte.toponimoResidenza = '';
        datiResidenzaTitEffMorte.descrToponimoResidenza = '';
        return {
          ...state,
          datiResidenzaTitEffMorte
        }
      case actionType.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_MORTE:
        datiResidenzaTitEffMorte.provinciaResidenza = unValore;
        datiResidenzaTitEffMorte.descrProvinciaResidenza = unaLabel;
        datiResidenzaTitEffMorte.comuneResidenza = '';
        datiResidenzaTitEffMorte.descrComuneResidenza = '';
        datiResidenzaTitEffMorte.toponimoResidenza = '';
        datiResidenzaTitEffMorte.descrToponimoResidenza = '';
        return {
          ...state,
          datiResidenzaTitEffMorte
        }
      case actionType.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_MORTE:
        if(datiResidenzaTitEffMorte.provinciaResidenza === PROVINCIA_ESTERA) {
          datiResidenzaTitEffMorte.comuneResidenza = '';
          datiResidenzaTitEffMorte.descrComuneResidenza = action.unDato;
          datiResidenzaTitEffMorte.toponimoResidenza = '';
          datiResidenzaTitEffMorte.descrToponimoResidenza = '';
        } else {
          datiResidenzaTitEffMorte.toponimoResidenza = '';
          datiResidenzaTitEffMorte.descrToponimoResidenza = '';
          datiResidenzaTitEffMorte.comuneResidenza = unValore;
          datiResidenzaTitEffMorte.descrComuneResidenza = unaLabel;
        }
        return {
          ...state,
          datiResidenzaTitEffMorte
        }
      case actionType.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_MORTE:
        if(datiResidenzaTitEffMorte.provinciaResidenza === 	PROVINCIA_ESTERA) {
          datiResidenzaTitEffMorte.toponimoResidenza = '';
          datiResidenzaTitEffMorte.descrToponimoResidenza = action.unDato;	
        } else {
          datiResidenzaTitEffMorte.toponimoResidenza = unValore;
          datiResidenzaTitEffMorte.descrToponimoResidenza = unValore;
        }
        return {
          ...state,
          datiResidenzaTitEffMorte
        }
      case actionType.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_MORTE:
        datiResidenzaTitEffMorte.numCivicoResidenza = action.unDato;
        return {
          ...state,
          datiResidenzaTitEffMorte
        }
      case actionType.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_MORTE:
        datiResidenzaTitEffMorte.descrIndirizzoResidenza = action.unDato;
        return {
          ...state,
          datiResidenzaTitEffMorte
        }	
      case actionType.UPDATE_CAP_RESIDENZA_TITOLARE_EFF_MORTE:
        datiResidenzaTitEffMorte.capResidenza = action.unDato;
        return {
          ...state,
          datiResidenzaTitEffMorte
        }
  
        
  /* DATI GENERALI MINIMI TITOLARE EFFETTIVO BENEFICIARIO MORTE */
      case actionType.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE:
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.sesso = action.unDato;
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};	
        } else {
          datiGeneraliMinimiTitEffBenefMorte.sesso = action.unDato;
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE:
        const prov = {...state}.benefMorteGiuridicoList
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.codiceFiscale = action.unDato;
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff}
        } else {
          datiGeneraliMinimiTitEffBenefMorte.codiceFiscale = action.unDato;
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
          benefMorteGiuridicoList: prov 
        }
      case actionType.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_MORTE:
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.cognome = action.unDato;
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        } else {
          datiGeneraliMinimiTitEffBenefMorte.cognome = action.unDato;
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_MORTE:
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.nome = action.unDato;
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        } else {
          datiGeneraliMinimiTitEffBenefMorte.nome = action.unDato;
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_MORTE:
        const unaDataTitolareBenefMorte = action.unDato;
        const nuovaDataNascitaFormattataTitolareBenefMorte = dataFormat(unaDataTitolareBenefMorte, 'DD/MM/YYYY'); 
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.dataNascita = unaDataTitolareBenefMorte;
          datiGeneraliTitEffMorte.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefMorte;
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        } else {
          datiGeneraliMinimiTitEffBenefMorte.dataNascita = unaDataTitolareBenefMorte;
          datiGeneraliMinimiTitEffBenefMorte.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefMorte;
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_MORTE:
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.statoNascita = unValore
          datiGeneraliTitEffMorte.descrStatoNascita = unaLabel
          datiGeneraliTitEffMorte.provinciaNascita = '';
          datiGeneraliTitEffMorte.descrProvinciaNascita = '';
          datiGeneraliTitEffMorte.comuneNascita = '';
          datiGeneraliTitEffMorte.descrComuneNascita = '';
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        } else {
          datiGeneraliMinimiTitEffBenefMorte.statoNascita = unValore;
          datiGeneraliMinimiTitEffBenefMorte.descrStatoNascita = unaLabel
          datiGeneraliMinimiTitEffBenefMorte.provinciaNascita = '';
          datiGeneraliMinimiTitEffBenefMorte.descrProvinciaNascita = '';
          datiGeneraliMinimiTitEffBenefMorte.comuneNascita = '';
          datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = '';
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_MORTE:
        if(action.isFiduciaria) {
          datiGeneraliTitEffMorte.provinciaNascita = unValore;
          datiGeneraliTitEffMorte.descrProvinciaNascita = unaLabel;
          datiGeneraliTitEffMorte.comuneNascita = '';
          datiGeneraliTitEffMorte.descrComuneNascita = '';
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        } else {
          datiGeneraliMinimiTitEffBenefMorte.provinciaNascita = unValore;
          datiGeneraliMinimiTitEffBenefMorte.descrProvinciaNascita = unaLabel;
          datiGeneraliMinimiTitEffBenefMorte.comuneNascita = '';
          datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = '';
          datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE:
        if(action.isFiduciaria) {
          if(datiGeneraliTitEffMorte.provinciaNascita !== PROVINCIA_ESTERA) {
            datiGeneraliTitEffMorte.comuneNascita = unValore;
            datiGeneraliTitEffMorte.descrComuneNascita = unaLabel;
            datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
            titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
          } else {
            datiGeneraliTitEffMorte.comuneNascita = '';
            datiGeneraliTitEffMorte.descrComuneNascita = action.unDato;
            datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
            titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
          }
        } else {
          if(datiGeneraliMinimiTitEffBenefMorte.provinciaNascita !== PROVINCIA_ESTERA) {	
            datiGeneraliMinimiTitEffBenefMorte.comuneNascita = unValore;
            datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = unaLabel;
            datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
            titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
          } else {
            datiGeneraliMinimiTitEffBenefMorte.comuneNascita = '';
            datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = action.unDato;
            datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
            titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
          }
        }
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_NUM_TELEFONO_TITOLARE_EFF_MORTE:
        datiGeneraliTitEffMorte.numTelefono = action.unDato;
        datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_CITTADINANZA_1_TITOLARE_EFF_MORTE:
        datiGeneraliTitEffMorte.cittadinanza1 = unValore;
        datiGeneraliTitEffMorte.descrCittadinanza1 = unaLabel;
        datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
        case actionType.UPDATE_CITTADINANZA_2_TITOLARE_EFF_MORTE:
          datiGeneraliTitEffMorte.cittadinanza2 = unValore;
          datiGeneraliTitEffMorte.descrCittadinanza2 = unaLabel;
          datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
          benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
          return {
            ...state,
            benefMorteGiuridico: benefMorteGiuridico,
          }  
      case actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_MORTE:
        const cloneTitolareEffBenefMorteConfermaAggiungi = jsonCopy(action.titolareEffettivoBenefMorte);
        const titolareEffBenefMorteListConfermaAggiungi = {...state}.benefMorteGiuridico.titolareEffBenefMorteList;
        const titolareEffBenefMorteListConfermaAggiungiNuovo = [...titolareEffBenefMorteListConfermaAggiungi, cloneTitolareEffBenefMorteConfermaAggiungi];
        benefMorteGiuridico.titolareEffBenefMorteList = [...titolareEffBenefMorteListConfermaAggiungiNuovo];
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.NUOVO_TITOLARE_EFF_BENEF_MORTE:
        const titolareEffBenefMorteNuovo = inizializzaTitolareEffBenefMorte(state);
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorteNuovo};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_TITOLARE_EFF_BENEF_MORTE:
        
        const cloneTitolareEffBenefMorteUpdate = jsonCopy(action.titolareEffettivoBenefMorte);
        const titolareEffBenefMorteUpdateCodiceFiscale = cloneTitolareEffBenefMorteUpdate.datiTitolareBenefMorteEff.datiGeneraliMinimi.codiceFiscale;
        const titolareEffBenefMorteListUpdate = [...state.benefMorteGiuridico.titolareEffBenefMorteList];
  
        let indexTitolareEffBenefMorteUpdate = undefined;
        titolareEffBenefMorteListUpdate.map( (item, index, array) => {  
          if(titolareEffBenefMorteUpdateCodiceFiscale === item.datiTitolareBenefMorteEff.datiGeneraliMinimi.codiceFiscale){
            indexTitolareEffBenefMorteUpdate = index;
          }
        })
        //titolareEffBenefMorteListUpdate[indexTitolareEffBenefMorteUpdate] =cloneTitolareEffBenefMorteUpdate;
        const benIndexUpdateTit = benefMorteGiuridicoList.findIndex(el => el.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === benefMorteGiuridico.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale);
        if(cloneTitolareEffBenefMorteUpdate.datiTitolareBenefMorteEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
          cloneTitolareEffBenefMorteUpdate.errorFiduciante = false;
        }
        benefMorteGiuridicoList[benIndexUpdateTit].titolareEffBenefMorteListInBenef[indexTitolareEffBenefMorteUpdate] = cloneTitolareEffBenefMorteUpdate;
        titolareEffBenefMorteListUpdate[indexTitolareEffBenefMorteUpdate] = cloneTitolareEffBenefMorteUpdate;
        benefMorteGiuridico.titolareEffBenefMorteList = titolareEffBenefMorteListUpdate;
        return{
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
          benefMorteGiuridicoList: benefMorteGiuridicoList
        };
      case actionType.SALVA_TITOLARE_EFF_BENEF_MORTE:
        titolareEffBenefMorte.controlloDati = action.unDato;
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.SALVA_TITOLARE_EFF_BENEF_MORTE_SUCCESS:
        return {
          ...state,
          salvaTitolareEffBenefMorteSuccess: action.unDato,
        }	
      case actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_MORTE:
        titolareEffBenefMorte.anagraficaValida = action.unDato;
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_ERRORE_FIDUCIANTE_TE_MORTE:
        titolareEffBenefMorte.errorFiduciante = action.unDato;
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE:
        datiRelazContrTitEffBenefMorte.relazContraente = unValore;
        datiRelazContrTitEffBenefMorte.descrRelazContraente = unaLabel;
        datiRelazContrTitEffBenefMorte.altro = '';
        datiTitolareBenefMorteEff.datiRelazContr = {...datiRelazContrTitEffBenefMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE:
        datiRelazContrTitEffBenefMorte.altro = action.unDato;
        datiTitolareBenefMorteEff.datiRelazContr = {...datiRelazContrTitEffBenefMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        }
      case actionType.CARICA_TITOLARE_EFF_BENEF_MORTE:
        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.CARICA_TITOLARE_EFF.state', state);
        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        const figuraSelezIndexCaricaBenefMorte = action.figuraSelezIndex;
        const titolareEffListCaricaBenefMorte = [...state.benefMorteGiuridico.titolareEffBenefMorteList];
        let titolareEffCaricaBenefMorte = { ...titolareEffListCaricaBenefMorte[figuraSelezIndexCaricaBenefMorte] };
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffCaricaBenefMorte}
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico,
        };	
      case actionType.DELETE_TITOLARE_EFF_BENEF_MORTE:
          let titolareEffDeleteEffBenefMorte = action.titolareEffettivo;
          const cfDeleteTitolare = (titolareEffDeleteEffBenefMorte.datiTitolareBenefMorteEff.datiGeneraliMinimi.codiceFiscale) ? titolareEffDeleteEffBenefMorte.datiTitolareBenefMorteEff.datiGeneraliMinimi.codiceFiscale : titolareEffDeleteEffBenefMorte.datiTitolareBenefMorteEff.datiGenerali.codiceFiscale
          const filterdTitolareEffDeleteListEffBenefMorte = state.benefMorteGiuridico.titolareEffBenefMorteList.filter(item => {
            const datiGenerali = (item.datiTitolareBenefMorteEff.datiGeneraliMinimi.codiceFiscale) ? item.datiTitolareBenefMorteEff.datiGeneraliMinimi : item.datiTitolareBenefMorteEff.datiGenerali;
            const codiceFiscale = datiGenerali.codiceFiscale;
            return codiceFiscale !== cfDeleteTitolare
          })
          const indexBenef = {...state}.benefMorteGiuridicoList.findIndex(el => el.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale === benefMorteGiuridico.datiGeneraliBenefGiuridico.datiGenerali.codiceFiscale);
          benefMorteGiuridicoList[indexBenef].titolareEffBenefMorteListInBenef = [...filterdTitolareEffDeleteListEffBenefMorte];
          benefMorteGiuridico.titolareEffBenefMorteList = [...filterdTitolareEffDeleteListEffBenefMorte];
          benefMorteGiuridico.titolareEffBenefMorte = inizializzaTitolareEffBenefMorte(state);
          return {
            ...state,
            benefMorteGiuridico: benefMorteGiuridico,
            benefMorteGiuridicoList: benefMorteGiuridicoList
          };
          
  
  /* DATI ATTIVITA ECONOMICA TITOLARE BENEFICIARIO MORTE */				
      case actionType.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_MORTE:
        datiAttEconomicaTitEffMorte.codAttPrevalSvolta = unValore;
        datiAttEconomicaTitEffMorte.descrAttPrevalSvolta = unaLabel;
        datiTitolareBenefMorteEff.datiAttEconomica = { ...datiAttEconomicaTitEffMorte };
        titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
        return {
          ...state,
          titolareEffBenefMorte: titolareEffBenefMorte
        }
      case actionType.UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_MORTE:
        datiAttEconomicaTitEffMorte.statoAttPrevalSvolta = unValore;
        datiAttEconomicaTitEffMorte.descrStatoAttPrevalSvolta = unaLabel;
        datiAttEconomicaTitEffMorte.provinciaAttPrevalSvolta = '';
        datiAttEconomicaTitEffMorte.descrProvinciaAttPrevalSvolta = '';
        datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = '';
        datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = '';
        datiTitolareBenefMorteEff.datiAttEconomica = { ...datiAttEconomicaTitEffMorte };
        titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
        return {
          ...state,
          titolareEffBenefMorte: titolareEffBenefMorte
        }
      case actionType.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_MORTE:
        datiAttEconomicaTitEffMorte.provinciaAttPrevalSvolta = unValore;
        datiAttEconomicaTitEffMorte.descrProvinciaAttPrevalSvolta = unaLabel;
        datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = '';
        datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = '';
        datiTitolareBenefMorteEff.datiAttEconomica = {...datiAttEconomicaTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        return {
          ...state,
          titolareEffBenefMorte: titolareEffBenefMorte
        }
      case actionType.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_MORTE:
        if(datiAttEconomicaTitEffMorte.provinciaAttPrevalSvolta === PROVINCIA_ESTERA) {
          datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = '';
          datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = action.unDato;
          datiTitolareBenefMorteEff.datiAttEconomica = {...datiAttEconomicaTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        } else {
          datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = unValore;
          datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = unaLabel;
          datiTitolareBenefMorteEff.datiAttEconomica = {...datiAttEconomicaTitEffMorte};
          titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        }
        return {
          ...state,
          titolareEffBenefMorte: titolareEffBenefMorte
        }	
  /* DATI DOMICILIO TITOLARE EFFETTIVO BENEFICIARIO MORTE */
      case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_MORTE:
        datiDomicilioTitEffMorte.domicilioDiversoResidenza = action.unDato;
        if(datiDomicilioTitEffMorte.domicilioDiversoResidenza === 'N') {
          datiDomicilioTitEffMorte.statoDomicilio = '';
          datiDomicilioTitEffMorte.descrStatoDomicilio = '';
          datiDomicilioTitEffMorte.provinciaDomicilio = '';
          datiDomicilioTitEffMorte.descrProvinciaDomicilio = '';
          datiDomicilioTitEffMorte.comuneDomicilio = '';
          datiDomicilioTitEffMorte.descrComuneDomicilio = '';
          datiDomicilioTitEffMorte.toponimoDomicilio = '';
          datiDomicilioTitEffMorte.descrToponimoDomicilio = '';
          datiDomicilioTitEffMorte.indirizzoDomicilio = '';
          datiDomicilioTitEffMorte.capDomicilio = '';
          datiDomicilioTitEffMorte.numCivicoDomicilio = '';
        }
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        datiDomicilioTitEffMorte.statoDomicilio = unValore;
        datiDomicilioTitEffMorte.descrStatoDomicilio = unaLabel;
        datiDomicilioTitEffMorte.provinciaDomicilio = '';
        datiDomicilioTitEffMorte.descrProvinciaDomicilio = '';
        datiDomicilioTitEffMorte.comuneDomicilio = '';
        datiDomicilioTitEffMorte.descrComuneDomicilio = '';
        datiDomicilioTitEffMorte.toponimoDomicilio = '';
        datiDomicilioTitEffMorte.descrToponimoDomicilio = '';
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        datiDomicilioTitEffMorte.provinciaDomicilio = unValore;
        datiDomicilioTitEffMorte.descrProvinciaDomicilio = unaLabel;
        datiDomicilioTitEffMorte.comuneDomicilio = '';
        datiDomicilioTitEffMorte.descrComuneDomicilio = '';
        datiDomicilioTitEffMorte.toponimoDomicilio = '';
        datiDomicilioTitEffMorte.descrToponimoDomicilio = '';
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        if(datiDomicilioTitEffMorte.provinciaDomicilio === PROVINCIA_ESTERA) {
          datiDomicilioTitEffMorte.comuneDomicilio = '';
          datiDomicilioTitEffMorte.descrComuneDomicilio = action.unDato;
          datiDomicilioTitEffMorte.toponimoDomicilio = '';
          datiDomicilioTitEffMorte.descrToponimoDomicilio = '';	
        } else {
          datiDomicilioTitEffMorte.comuneDomicilio = unValore;
          datiDomicilioTitEffMorte.descrComuneDomicilio = unaLabel;
          datiDomicilioTitEffMorte.toponimoDomicilio = '';
          datiDomicilioTitEffMorte.descrToponimoDomicilio = '';	
        }
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        if(datiDomicilioTitEffMorte.provinciaDomicilio === PROVINCIA_ESTERA) {
          datiDomicilioTitEffMorte.toponimoDomicilio = '';
          datiDomicilioTitEffMorte.descrToponimoDomicilio = action.unDato;	
        } else {
          datiDomicilioTitEffMorte.toponimoDomicilio = unValore;
          datiDomicilioTitEffMorte.descrToponimoDomicilio = unaLabel;	
        }
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        datiDomicilioTitEffMorte.indirizzoDomicilio = action.unDato;
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        datiDomicilioTitEffMorte.numCivicoDomicilio = action.unDato;
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
        datiDomicilioTitEffMorte.capDomicilio = action.unDato;
        datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
  /* DATI RECAPITO TITOLARE EFFETTIVO BENEFICIARIO MORTE */
      case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_MORTE:
        datiRecapitoTitEffMorte.corrispondenzaDiversoResidenza = action.unDato;
        if(datiRecapitoTitEffMorte.corrispondenzaDiversoResidenza === 'N') {
          datiRecapitoTitEffMorte.corrispondenzaIntestatoContraente = '';
          datiRecapitoTitEffMorte.statoRecapito = '';
          datiRecapitoTitEffMorte.descrStatoRecapito = '';
          datiRecapitoTitEffMorte.provinciaRecapito = '';
          datiRecapitoTitEffMorte.descrProvinciaRecapito = '';
          datiRecapitoTitEffMorte.comuneRecapito = '';
          datiRecapitoTitEffMorte.descrComuneRecapito = '';
          datiRecapitoTitEffMorte.toponimoRecapito = '';
          datiRecapitoTitEffMorte.descrToponimoRecapito = '';
          datiRecapitoTitEffMorte.descrIndirizzoRecapito = '';
          datiRecapitoTitEffMorte.numCivicoRecapito = '';
          datiRecapitoTitEffMorte.capRecapito = '';
          datiRecapitoTitEffMorte.presso = '';
        }
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_MORTE:
        datiRecapitoTitEffMorte.corrispondenzaIntestatoContraente = action.unDato;
        datiRecapitoTitEffMorte.presso = '';
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        datiRecapitoTitEffMorte.statoRecapito = unValore;
        datiRecapitoTitEffMorte.descrStatoRecapito = unaLabel;
        datiRecapitoTitEffMorte.provinciaRecapito = '';
        datiRecapitoTitEffMorte.descrProvinciaRecapito = '';
        datiRecapitoTitEffMorte.comuneRecapito = '';
        datiRecapitoTitEffMorte.descrComuneRecapito = '';
        datiRecapitoTitEffMorte.toponimoRecapito = '';
        datiRecapitoTitEffMorte.descrToponimoRecapito = '';
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        datiRecapitoTitEffMorte.provinciaRecapito = unValore;
        datiRecapitoTitEffMorte.descrProvinciaRecapito = unaLabel;
        datiRecapitoTitEffMorte.comuneRecapito = '';
        datiRecapitoTitEffMorte.descrComuneRecapito = '';
        datiRecapitoTitEffMorte.toponimoRecapito = '';
        datiRecapitoTitEffMorte.descrToponimoRecapito = '';
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        if(datiRecapitoTitEffMorte.provinciaRecapito === PROVINCIA_ESTERA) {
          datiRecapitoTitEffMorte.comuneRecapito = '';
          datiRecapitoTitEffMorte.descrComuneRecapito = action.unDato;
          datiRecapitoTitEffMorte.toponimoRecapito = '';
          datiRecapitoTitEffMorte.descrToponimoRecapito = '';
        } else {
          datiRecapitoTitEffMorte.comuneRecapito = unValore;
          datiRecapitoTitEffMorte.descrComuneRecapito = unaLabel;
          datiRecapitoTitEffMorte.toponimoRecapito = '';
          datiRecapitoTitEffMorte.descrToponimoRecapito = '';
        }
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        if(datiRecapitoTitEffMorte.provinciaRecapito === PROVINCIA_ESTERA) {
          datiRecapitoTitEffMorte.toponimoRecapito = '';
          datiRecapitoTitEffMorte.descrToponimoRecapito = action.unDato;
        } else {
          datiRecapitoTitEffMorte.toponimoRecapito = unValore;
          datiRecapitoTitEffMorte.descrToponimoRecapito = unaLabel;
        }
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        datiRecapitoTitEffMorte.descrIndirizzoRecapito = action.unDato;
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        datiRecapitoTitEffMorte.numCivicoRecapito = action.unDato;
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
        datiRecapitoTitEffMorte.capRecapito = action.unDato;
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_PRESSO_TITOLARE_EFFETTIVO_MORTE:
        datiRecapitoTitEffMorte.presso = action.unDato;
        datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
  /* DATI ANTIRICICLAGGIO TITOLARE EFFETTIVO BENEFICIARIO MORTE */
      case actionType.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.tipoDocumento = unValore;
        datiAntiriciclaggioTitEffMorte.descrTipoDocumento = unaLabel;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.numeroDocumento = action.unDato;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_ENTE_RILASCIO_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.enteRilascio = unValore;
        datiAntiriciclaggioTitEffMorte.descrEnteRilascio = unaLabel;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_DATA_RILASCIO_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.dataRilascio = action.unDato;
        datiAntiriciclaggioTitEffMorte.dataRilascioStampa = dataFormat(action.unDato, 'DD/MM/YYYY');
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_DATA_SCADENZA_TITOLARE_EFF_MORTE:
        const nuovaData = action.unDato;
        const nuovaDataFormattata = dataFormat(nuovaData, 'DD/MM/YYYY');
        datiAntiriciclaggioTitEffMorte.dataScadenza = nuovaData;
        datiAntiriciclaggioTitEffMorte.dataScadenzaStampa = nuovaDataFormattata;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_STATO_RILASCIO_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.statoRilascio = unValore;
        datiAntiriciclaggioTitEffMorte.descrStatoRilascio = unaLabel;
        datiAntiriciclaggioTitEffMorte.provinciaRilascio = '';
        datiAntiriciclaggioTitEffMorte.descrProvinciaRilascio = '';
        datiAntiriciclaggioTitEffMorte.comuneRilascio = '';
        datiAntiriciclaggioTitEffMorte.descrComuneRilascio = '';
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.provinciaRilascio = unValore;
        datiAntiriciclaggioTitEffMorte.descrProvinciaRilascio = unaLabel;
        datiAntiriciclaggioTitEffMorte.comuneRilascio = '';
        datiAntiriciclaggioTitEffMorte.descrComuneRilascio = '';
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_MORTE:
        if(datiAntiriciclaggioTitEffMorte.provinciaRilascio === PROVINCIA_ESTERA) {
          datiAntiriciclaggioTitEffMorte.comuneRilascio = '';
          datiAntiriciclaggioTitEffMorte.descrComuneRilascio = action.unDato;
        } else {
          datiAntiriciclaggioTitEffMorte.comuneRilascio = unValore;
          datiAntiriciclaggioTitEffMorte.descrComuneRilascio = unaLabel;
        }
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_TITOLARE_EFF_QUALIFICA_MORTE:
        datiAntiriciclaggioTitEffMorte.qualifica = unValore;
        datiAntiriciclaggioTitEffMorte.descrQualifica = unaLabel;
        datiAntiriciclaggioTitEffMorte.altro = '';
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        datiTitolareBenefMorteEff.percOwnerShip = '';
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_ALTRO_QUALIFICA_MORTE:
        datiAntiriciclaggioTitEffMorte.altro = action.unDato;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_MORTE:
        datiTitolareBenefMorteEff.percOwnerShip = action.unDato
        /* const datiTitEffBenefMorte = {...state}.benefMorteGiuridico;
        const titolareBenefMorte = {...datiTitEffBenefMorte}.titolareEffBenefMorte;
        const antiBenefMorte = {...titolareBenefMorte}.datiTitolareBenefMorteEff;
        antiBenefMorte.percOwnerShip = action.unDato;
        titolareBenefMorte.datiTitolareBenefMorteEff = {...antiBenefMorte};
        datiTitEffBenefMorte.titolareEffBenefMorte = {...titolareBenefMorte}; */
        return {
          ...state,
          datiTitolareBenefMorteEff
        }
      case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.espostaPoliticamente = action.unDato;
        datiAntiriciclaggioTitEffMorte.isInCarica = '';
        datiAntiriciclaggioTitEffMorte.caricaPersonaEsposta = '';
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.isInCarica = action.unDato;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }
      case actionType.UPDATE_CARICA_PEP_TITOLARE_EFF_MORTE:
        datiAntiriciclaggioTitEffMorte.caricaPersonaEsposta = action.unDato;
        datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
        titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
        benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
        return {
          ...state,
          benefMorteGiuridico: benefMorteGiuridico
        }

        /* DATI FISCALI TITOLARE EFF BENEF MORTE  */ 				
		case actionType.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.tinGiin = action.unDato;
            datiTitolareBenefMorteEff.datiFiscali = {...datiFiscaliTitEffMorte};
            titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
            return {
	   	       ...state,
	   	       titolareEffBenefMorte: titolareEffBenefMorte,
   	        };
		case actionType.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.tasseStatiUniti = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.statoResFiscale1 = unValore;
			datiFiscaliTitEffMorte.descrStatoResFiscale1 = unaLabel;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.nifResFiscale1 = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.statoResFiscale2 = unValore;
			datiFiscaliTitEffMorte.descrStatoResFiscale2 = unaLabel;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.nifResFiscale2 = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};	
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.statoResidenzaFiscaleEstera = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};	

  
/* BENEFICIARIO MORTE FISICO - AGGIORNAMENTO DATI REFERETE TERZO*/
      /*
    case actionType.UPDATE_DATI_GENERALI_PER_REFERENTE_TERZO:
        const benefMorteFisico = {...state}.benefMorteFisico;
        const datiReferenteEsteso = {...benefMorteFisico}.datiReferenteTerzo;
        // dati generali
        const datiGeneraliRefEstesoUpdDatiGen = {...datiReferenteEsteso}.datiGenerali;
        datiGeneraliRefEstesoUpdDatiGen.sesso = '';
        datiGeneraliRefEstesoUpdDatiGen.codiceFiscale = '';
        datiGeneraliRefEstesoUpdDatiGen.cognome = '';
        datiGeneraliRefEstesoUpdDatiGen.nome = '';
        datiGeneraliRefEstesoUpdDatiGen.dataNascita = null;
        datiGeneraliRefEstesoUpdDatiGen.statoNascita = '';
        datiGeneraliRefEstesoUpdDatiGen.descrStatoNascita = '';
        datiGeneraliRefEstesoUpdDatiGen.provinciaNascita = '';
        datiGeneraliRefEstesoUpdDatiGen.descrProvinciaNascita = '';
        datiGeneraliRefEstesoUpdDatiGen.comuneNascita = '';
        datiGeneraliRefEstesoUpdDatiGen.descrComuneNascita = '';
        
        benefMorteFisico.datiReferenteTerzo.datiGenerali = {...datiGeneraliRefEstesoUpdDatiGen};
        // dati residenza
        const datiResidenzaRefEstesoUpdDatiGen = {...datiReferenteEsteso}.datiResidenza;
        datiResidenzaRefEstesoUpdDatiGen.statoResidenza='';
        datiResidenzaRefEstesoUpdDatiGen.descrStatoResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.provinciaResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.descrProvinciaResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.comuneResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.descrComuneResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.toponimoResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.descrToponimoResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.indirizzoResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.descrIndirizzoResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.numCivicoResidenza= '';
        datiResidenzaRefEstesoUpdDatiGen.capResidenza= '';
        
        benefMorteFisico.datiReferenteTerzo.datiResidenza = {...datiResidenzaRefEstesoUpdDatiGen};
        return {
          ...state,
          benefMorteFisico: benefMorteFisico,
        };
        */
    case actionType.UPDATE_BENEF_MORTE_PERCENTUALE_TOTALE:
    	console.log('#RT2 Reducer areaBenefMorteReducer.actionType.UPDATE_BENEF_MORTE_PERCENTUALE_TOTALE.state', state);
        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);   
        const percBenMorteTotale = action.unDato;
        return {
          ...state,
          percBenMorteTotale: percBenMorteTotale,
        }; 
    default:
      return state;
  }
};

export const inizializzaBeneficiarioMorteFisico = (state) => {
  const benefMorteFisico_INI = {...state}.benefMorteFisico;
  benefMorteFisico_INI.anagraficaValida = null;
  benefMorteFisico_INI.controlloDati = '';
  benefMorteFisico_INI.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_MORTE,
    tipoPers: PERSONA_FISICA,
  };
  benefMorteFisico_INI.datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
  benefMorteFisico_INI.datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
  benefMorteFisico_INI.datiGeneraliBenefMorte = jsonCopy(datiGeneraliBenefMorteInizializzato);
  benefMorteFisico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefMorteFisico_INI.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefMorteFisico_INI.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  return benefMorteFisico_INI;
}

export const inizializzaBeneficiarioMorteGiuridico = (state) => {
  const benefMorteGiuridico_INI = {...state}.benefMorteGiuridico;
  benefMorteGiuridico_INI.anagraficaValida = null;
  benefMorteGiuridico_INI.controlloDati = '';
  benefMorteGiuridico_INI.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_MORTE,
    tipoPers: PERSONA_GIURIDICA,
  };
  benefMorteGiuridico_INI.datiGeneraliBenefGiuridico = jsonCopy(datiGeneraliBenefGiuridicoInizializzato);
  benefMorteGiuridico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefMorteGiuridico_INI.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefMorteGiuridico_INI.datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
  benefMorteGiuridico_INI.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  benefMorteGiuridico_INI.datiAttEconomica = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  benefMorteGiuridico_INI.datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
  benefMorteGiuridico_INI.titolareEffBenefMorte =jsonCopy(titEffBenefMorteGiuridicoInizializzato);
  benefMorteGiuridico_INI.titolareEffBenefMorteList = [];
  return benefMorteGiuridico_INI;
}

export const inizializzaBeneficiarioMorteGenerico = (state) => {
  const benefMorteGenerico_INI = {...state}.benefMorteGenerico;
  benefMorteGenerico_INI.anagraficaValida = null;
  benefMorteGenerico_INI.controlloDati = '';
  benefMorteGenerico_INI.datiGenerali = jsonCopy(datiGeneraliGenericoInizializzato);
  return benefMorteGenerico_INI;
}

export const inizializzaBeneficiarioMorteFisicoLista = (state) => {
//  const benefMorteFisicoList_INI = {...state}.benefMorteFisicoList;
//  benefMorteFisicoList_INI.benefMorteFisicoList = [];
  const benefMorteFisicoList_INI = [];
  return benefMorteFisicoList_INI;
}

export const inizializzaBeneficiarioMorteGiuridicoLista = (state) => {
//  const benefMorteGiuridicoList_INI = {...state}.benefMorteGiuridicoList;
//  benefMorteGiuridicoList_INI.benefMorteGiuridicoList = [];
  const benefMorteGiuridicoList_INI = [];	
  return benefMorteGiuridicoList_INI;
}

export const inizializzaBeneficiarioMorteGenericoLista = (state) => {
//  const benefMorteGenericoList_INI = {...state}.benefMorteGenericoList;
//  benefMorteGenericoList_INI.benefMorteGenericoList = [];
  const benefMorteGenericoList_INI = [];	
  return benefMorteGenericoList_INI;
}

export const inizializzaTitolareEffBenefMorte = (state) => {
	const titolareEffBenefMorte_INI = {...state}.benefMorteGiuridico.titolareEffBenefMorte;
	titolareEffBenefMorte_INI.anagraficaValida = null;
	titolareEffBenefMorte_INI.controlloDati = '';
	titolareEffBenefMorte_INI.datiTitolareBenefMorteEff = jsonCopy(datiTitolareEffettivoBenefMorteInizializzato);
	return titolareEffBenefMorte_INI;
}
	
export default areaBenefMorteReducer;