import Login from './components/login/Login';
import ProdottiArea from './components/prodotto/ProdottiArea';
import InitProposal from './components/proposal/InitProposal';
import StepperComp from './components/common/StepperComp';
import DefaultStep from './components/core/DefaultStep';
import GestioneUtenze from './components/proposal/GestioneUtenze';
import DatiPasswordDimenticata from './components/gestioneUtenze/sezione/DatiPasswordDimenticata';
import DatiNuovaPassword from './components/gestioneUtenze/sezione/DatiNuovaPassword';

import { areaLogin } from './components/store/areaLogin';
import { initPropostaItems } from './components/store/initPropostaItems';
import { propostaStepperItems } from './components/store/propostaStepperItems';

const areaProdotto = [];

export const routes = [
/* Home INIZIO */	
                       {	
  path: "/",
  exact: true,
  text: "Login",
  component: Login,
  props: {areaLogin: areaLogin, codiceErroreLogin: '', messaggioOk:''}
},
/* Home FINE */	
/* Area Prodotti INIZIO */	
                       {	
  path: "/areaProdotti",
  exact: true,
  text: "ProdottiArea",
  component: ProdottiArea,
  props: {areaProdotto: areaProdotto}
}, 	
/* Home FINE */
/* link da Selezione Prodotti INIZIO */	
	                   {	
  path: "/linkProdotto",
  text: "InitProposal",
  component: InitProposal,
  props: {initPropostaItems: initPropostaItems}
}, 
/* link da Selezione Prodotti FINE */	
/* STEPPER INIZIO */	
                       {	//da rivedere scrivendo un componente che ha sia lo stepper che i componeneti aplicativi
  path: "/nuovaProposta",
  exact: true,
  text: "NuovaProposta",
  component: StepperComp,
  props: {slidePage: propostaStepperItems}
}, 	

{
  path: "/gestioneUtenze",
  text: "GestioneUtenze",
  component: GestioneUtenze,
}, 	

{
  path: "/datiPasswordDimenticata",
  text: "DatiPasswordDimenticata",
  component: DatiPasswordDimenticata,
}, 

{
  path: "/datiNuovaPassword",
  text: "DatiNuovaPassword",
  component: DatiNuovaPassword,
}, 
	
/* STEPPER  FINE */	
/* link da STEPPER INIZIO */
                         {
  path: "/linkContraenteAssicurato",
  text: "AnagraphProposal",
  component: StepperComp,
  props: {slidePage: propostaStepperItems},
}, {
  path: "/linkAltreFigure",
  text: "linkAltreFigure",
  component: StepperComp,
  props: {slidePage: propostaStepperItems},
}, {
  path: "/linkCaratteristicheContratto",
  text: "linkCaratteristicheContratto",
  component: DefaultStep,
  //  props: {slidePage: propostaStepperItems, label: "linkCaratteristicheContratto"},
  props: {label: "Caratteristiche Contratto"}
}, {
  path: "/linkDichiarazioniConsensi",
  text: "linkDichiarazioniConsensi",
  component: DefaultStep,
  props: {label: "Dichiarazioni Consensi"}
}, {
  path: "/linkQuestionari",
  text: "linkQuestionari",
  component: DefaultStep,
  props: {label: "Questionari"}
}, {
  path: "/linkStampaProposta",
  text: "linkStampaProposta",
  component: DefaultStep,
  props: {label: "Stampa Proposta"}
}
/* link da STEPPER FINE */
];