/* ASSICURATO */
export const updateAssicuratoFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI GENERALI ASSICURATO */
export const updateDatiGeneraliAssicurato = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI RESIDENZA ASSICURATO */
export const updateDatiResidenzaAssicuratoFisico = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }
};

/* DATI ATTIVITA ECONOMICA */
export const updateDatiAttEconomicaAssicuratoFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
}

/* DATI ANTIRICICLAGGIO ASSICURATO */
export const updateDatiAntiriciclaggioAssicurato = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura,
  }
};

/* DATI RELAZIONE ASSICURATO */
export const updateDatiRelazioneAssicurato = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

/* 
export const updateDatiGeneraliAssicuratoFromContraente = (unDato, contraenteFisico, unActionType) => {
  return {
    type: unActionType,
    unDato,
    contraenteFisico,
  }
};
 */
