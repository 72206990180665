import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

import ButtonComp from './ButtonComp';

const styles = theme => ({
  media: {
    width: '100%',
    objectFit: 'inherit',
  },
  card: {
/*	  raised: true, */
  },
  pad: {
	  marginTop: 20,
	  padding: 0,
  },
  //button: theme.button,
  textSmall: {
    fontSize: 25,
  },
  button: {
	margin: 8,
	color: theme.palette.color.windowsBlue,
	borderColor: theme.palette.color.windowsBlue,
    border: '1.5px solid',
    fontFamily: theme.palette.color.fontFamily,	    
    width:'150px',
    fontWeight: 'bold',
    fontSize: 15,
    textTransform: 'capitalize',
    borderRadius: 10,
    '&:hover': { 
    	background: theme.palette.color.windowsBlue, 
    	color: theme.palette.color.white,
    },
  },
});

class CardComp extends React.Component {

  constructor(props) {
    super(props);
	this.state = {
      value: this.props.value,
    }
  };

/*  handleChange = (codice) => event => {

    console.log('#RT CardComp value codice: ', codice); */
  handleChange = (area, elemento) => event => {
    console.log('#RT1 CardComp value area: ', area);
    console.log('#RT1 CardComp value elemento ', elemento);        
/*    this.setState({
      value: event.target.value,
    }); */
	if(this.props.onClick) {
	  this.props.onClick(area, elemento);
    }
  };
	
  render () {
    const { classes, to, area, elementi, onClick, aMediaStyle } = this.props;    
    const { t } = this.props; //hoc 
    const aStyle = classNames(aMediaStyle, classes.media);
    console.log('#RT CardComp onClick', onClick);
    return (
      <Card className={classes.card} elevation={0}>
      	<CardContent>
  	      {area.image &&
  	        <CardMedia
/*RT   	          className={classes.media}	
              className={aMediaStyle}  */
              className={aStyle} 
          	  component='img'
          	  image={area.image}
  	        />
  	      }          
      	</CardContent>
        <CardContent className={classes.pad}>
          {area.title &&
            <Typography gutterBottom variant='h5' className={classes.textSmall} align='center'>
              {t(area.title)}
            </Typography>
          }
        </CardContent>
        <CardActions>
          <Grid container direction='column' justify='center' alignItems='center'>
            {elementi &&
              elementi.map((elemItem, index) => {
		    		let elemTo = elemItem.to;
		    		console.log('elemTo ' + elemItem.title, elemTo);
		    		let linkTo = elemTo ? to+elemTo : to;
		    		console.log('linkTo ' + elemItem.title, linkTo);
		    		let codice = elemItem.codice;
		    		return (<ButtonComp key={index} 
                                to={linkTo}
                                aKey={codice} 
		    		            component={Link} 
		    		            aClassName={classes.button} 
		    		            buttonLabel={t(elemItem.title)} 
		                        onClick={this.handleChange(area, elemItem)}/>
                    );
              })  
		    }
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

CardComp.propTypes = {
  classes: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
//  elementi: PropTypes.array.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(CardComp));
