export const UPDATE_IBAN                                    = 'UPDATE_IBAN';
export const UPDATE_BANCA                                   = 'UPDATE_BANCA';
export const UPDATE_AGENZIA                                 = 'UPDATE_AGENZIA';
export const UPDATE_CONTROLLO_IBAN_PAG_BONIFICO             = 'UPDATE_CONTROLLO_IBAN_PAG_BONIFICO';
export const SALVA_BANCA_AGENZIA_DA_IBAN                    = 'SALVA_BANCA_AGENZIA_DA_IBAN';
export const UPDATE_N_SOTTORUBRICA                          = 'UPDATE_N_SOTTORUBRICA';
export const UPDATE_INTESTATARIO_BONIFICO                   = 'UPDATE_INTESTATARIO_BONIFICO';
export const UPDATE_COINTESTATO_BONIFICO                    = 'UPDATE_COINTESTATO_BONIFICO';
export const UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO    = 'UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO';
export const UPDATE_RELAZ_PAGAT_CONTR_BONIFICO              = 'UPDATE_RELAZ_PAGAT_CONTR_BONIFICO';
export const UPDATE_ALTRA_RELAZ_BONIFICO                    = 'UPDATE_ALTRA_RELAZ_BONIFICO';