import React from 'react';
import { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SwipeableViews from 'react-swipeable-views';
import { Grid, Typography } from '@material-ui/core';
//import { MobileStepper, SvgIcon } from '@material-ui/core';
import { autoPlay } from 'react-swipeable-views-utils';

//import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import LensIcon from '@material-ui/icons/Lens';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AnagraphProposal from '../proposal/anagraph/AnagraphProposal';
import AltreFigureProposal from '../proposal/anagraph/AltreFigureProposal';
import CaratteristicheContrattoProposal from '../proposal/caratteristicheContratto/CaratteristicheContrattoProposal';
import DichIntermediarioConsensoProposal from '../proposal/dichiarazioni/DichIntermediarioConsensoProposal';
import QuestionarioProposal from '../proposal/questionario/QuestionarioProposal';
import StampaPropostaAss from '../proposal/stampa/StampaPropostaAss';
import { STEP_CONTRAENTE_ASSICURATO, STEP_ALTRE_FIGURE, STEP_CARATTERISTICHE_CONTRATTO, 
         STEP_CONSENSI, STEP_QUESTIONARI, STEP_STAMPA_PROPOSTA,
         RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, 
         PRODOTTO_TIPOLOGIA_ALTRO, DATI_PIANO_EROGAZIONI_PERIODICHE, DATI_TAKE_PROFIT, DATI_LIFE_CYCLE, DATI_NESSUNO, DATI_PIANO_SPOSTAMENTO_AUTOMATICO,
         COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002, COD_QUESTIONARIO_ANAMNESTICO, COD_QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE,
         QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO, locale_it } from '../../utility/jsConstants';
import { showSezione, isContraenteGiuridico } from '../../utility/genericUtility'; 
import { getQuestionarioAnamnestico } from '../../utility/questionarioUtility';
import { getIsValidaStepQuestionari } from '../../utility/storeUtility';
import { isTcmIndividualeValida, checkMandatoryFieldsTcmIndividuale } from '../../utility/tcmIndividualeUtility'; 


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  // Contenitore di componenti
  componentContainer: {
    padding: '1em',
    width: '100%',
    [theme.breakpoints.up('md')]: {
 /*     padding: '2em 4em', */
      height: 'auto',
    }
  },
  gridContainer: {
    width: '100%',
  },
  gridTab: {
    textAlign: 'center',
    maxWidth: '20%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'none'
    }
  },
  gridArrowLeft: {
    textAlign: 'left'
  },
  gridArrowRight: {
    textAlign: 'right'
  },
  active: {
    borderBottom: '3.5px solid', /* spessore riga elemento selezionato */
    borderBottomColor: theme.palette.color.windowsBlue,
    padding: '10px 0',
    height: 'auto', 
  },
  disabled: {
    cursor: 'pointer',
    borderRadius: '0px',
    padding: '10px 0',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.color.greyLight,
    height: 'auto',
    '&:hover': {
      borderRadius: '5px',
      backgroundColor: 'rgb(238,238,238, 0.5)',
      borderBottom: '0px solid',         
    },
  },
  disabledAfterPrint: {
    borderRadius: '0px',
    padding: '10px 0',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.color.greyLight,
    height: 'auto',
  },
  divSvgActive: {
    backgroundColor: theme.palette.color.windowsBlue,
    fontSize: '1.1rem', /* serve per il dimensionamento delle icone */
    borderRadius: '50%',
    width: '2.5em', /*1.5*/
    height: '2.5em', /*1.5*/
    margin: 'auto',
    lineHeight: '3.1em', /*1.5*/  
    [theme.breakpoints.up('sm')]: {
      width: '5.0em', /*3*/
      height: '5.0em', /*3*/
      margin: 'auto',
      lineHeight: '7em' /*4*/
    },
  },
  divSvgDisabled: {
    backgroundColor: theme.palette.color.greyLight,
    fontSize: '1.1rem', /* serve per il dimensionamento delle icone */
    borderRadius: '50%',
    width: '2.5em', /*1.5*/
    height: '2.5em', /*1.5*/
    margin: 'auto',
    lineHeight: '3.1em', /*1.5*/
    [theme.breakpoints.up('sm')]: {
      width: '5.0em', /*3*/
      height: '5.0em', /*3*/
      margin: 'auto',
      lineHeight: '7em' /*4*/
    },      
  },
  svgActive: {
    width: '60%',
    height: '60%',
    margin: 'auto',
    verticalAlign: 'baseline',
    fill: theme.palette.color.white,
  },
  svgDisabled: {
    width: '60%',
    height: '60%',
    margin: 'auto',
    verticalAlign: 'baseline',
    fill: theme.palette.color.windowsBlue,
  },
  tabTypo: {
/*    padding: '0.1em 0', */
	padding: '0',	  
    height: '3em',
    /*fontSize: '1.5em',*/
/*    fontSize: theme.typography.fontSize*1.05,  */
    fontSize: theme.typography.fontSize,    
    fontWeight: 'bold',
    color:  theme.palette.color.windowsBlue,       
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*0.75, 
/*      padding: '1em 0',*/
      height: '4em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*0.5, 
/*      padding: '0.5em 0', */
      height: '3em',
//      display:'none',
    }
  },
  arrows: {
    color: theme.palette.color.windowsBlue,
    backgroundColor: theme.palette.color.greyLight,
    margin: '70% 0',
    borderRadius: '50%',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '1em', 
    '&:hover': {
      color: theme.palette.color.white,
      backgroundColor: theme.palette.color.windowsBlue,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2em',
    }
  },
  mobileStepper: {
    backgroundColor: theme.palette.color.white,
    textAlign: 'center'
  },
  stepperDotsContainer: {
    width: '20%',
    backgroundColor: theme.palette.color.white,
    margin: '1em auto',
    [theme.breakpoints.up('sm')]: {
      width: '5%',
    }
  },
  stepperDots: {
    backgroundColor: theme.palette.color.greyLight,
    margin: 'auto',
  },
  stepperDotActive: {
    backgroundColor: theme.palette.color.windowsBlue,
    margin: 'auto',
  },
  divider: {
    height: theme.spacing(4),
  },
  lensIconOK: {
    margin: 0,
    color: theme.palette.color.green,
  },
  lensIconWARNING: {
    margin: 0,
    color: theme.palette.color.gold,
  },
  lensIconERROR: {
    margin: 0,
    color: theme.palette.color.red,
  },  
})

const mapStateToProps = state => {
//  console.log('#RTCFA StepperComp.mapStateToProps.state', state);
  return {
	assicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico,
	assicuratoFisicoValida: state.areaAssicuratoReducer.assicuratoFisico.anagraficaValida,
	contraenteFisico: state.areaContraenteReducer.contraenteFisico,
	contraenteFisicoValida: state.areaContraenteReducer.contraenteFisico.anagraficaValida,

	contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,	  
	contraenteGiuridicoValida: state.areaContraenteReducer.contraenteGiuridico.anagraficaValida,	
//	contraenteGiuridicoFlTitolariEff: state.areaContraenteReducer.contraenteGiuridico.flTitolariEff,		
	titolareEffList: state.areaTitolareEffReducer.titolareEffList,
	titolareEffValida: state.areaTitolareEffReducer.titolareEff.anagraficaValida,	
	
	dominio: state.areaDatiDominioReducer.dominio,
	percBenMorteTotale: state.areaBenefMorteReducer.percBenMorteTotale,
	controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
	controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
	controlloDatiBenefMorteGenerico: state.areaBenefMorteReducer.benefMorteGenerico.controlloDati,
	percBenVitaTotale: state.areaBenefVitaReducer.percBenVitaTotale,
	controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,
	controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
	controlloDatiBenefVitaGenerico: state.areaBenefVitaReducer.benefVitaGenerico.controlloDati,
	controlloDatiReferenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico.controlloDati,
	referenteTerzoFisicoValida: state.areaReferenteTerzoReducer.referenteTerzoFisico.anagraficaValida,
	
    prodotto: state.areaProdottoReducer.prodotto,
    datiPremioCapitaleAssicuratoValida: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida,
    datiPremioValida: state.areaDatiPremioReducer.caratteristicheContratto.datiPremio.datiPremioValida,    
    datiRipartizionePremioValida: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio.datiRipartizionePremioValida,
    datiOpzioniContrattuali: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali,
    datiPianoErogazioniPeriodicheValida: state.areaDatiPianoErogazioniPeriodicheReducer.caratteristicheContratto.datiPianoErogazioniPeriodiche.datiPianoErogazioniPeriodicheValida,
    datiTakeProfitValida: state.areaDatiTakeProfitReducer.caratteristicheContratto.datiTakeProfit.datiTakeProfitValida,
    datiModalitaPagamentoValida: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento.datiModalitaPagamentoValida,	
    
    datiIntermediarioValida: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario.datiIntermediarioValida,
    controlloDatiIntermediario: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario.controlloDati,
    datiConsensoValida: state.areaDichConsensoReducer.dichiarazioni.datiConsenso.datiConsensoValida,
    controlloDatiConsenso: state.areaDichConsensoReducer.dichiarazioni.datiConsenso.controlloDati,     
    
    datiQuestionarioAdeguatezzaFisicoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico.datiQuestionarioValida,
    datiQuestionarioAdeguatezzaGiuridicoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico.datiQuestionarioValida,
    datiQuestionarioAnamnesticoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico.datiQuestionarioValida,
    datiQuestionarioAnamnesticoMalattiaGraveValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida,
//    datiGeneraliContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiGenerali,
    datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,   
  //2020-02-08 PFD - START    
//    showSemplificato: state.areaDatiQuestionarioReducer.questionario.showSemplificato,
//    showOnlySemplificato: state.areaDatiQuestionarioReducer.questionario.showOnlySemplificato,
  //2020-02-08 PFD - END
    datiQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico,
    datiQuestionarioAnamnesticoMalattiaGrave: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave,
    datiQuestionarioAnamnesticoSemplificato: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato,
    datiQuestionarioAnamnesticoSemplificatoValida: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida,
    isChangedFromSemplificato: state.areaDatiQuestionarioReducer.questionario.isChangedFromSemplificato,
//RT semaforo questionari INIZIO
//    datiQuestionarioAdeguatezzaFisicoControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico.controlloDati,
//    datiQuestionarioAdeguatezzaGiuridicoControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico.controlloDati,
//    datiQuestionarioAnamnesticoControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico.controlloDati,
//    datiQuestionarioAnamnesticoMalattiaGraveControlloDati: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave.controlloDati,    
//RT semaforo questionari FINE    
    confermaStampaProposal: state.areaDatiStampaProposalReducer.stampaProposal.datiStampaProposal.confermaStampaProposal,
    mailInviata: state.areaDatiStampaProposalReducer.stampaProposal.datiStampaProposal.mailInviata,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

class StepperComp extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      value: 0,
      properties: this.props.slidePage,
      id: 1,
  	};          
/*     const nextLink = this.state.properties.slidePage[0].tab[0].link;
       console.log("[AER] constructor: ", nextLink);
       props.history.push(nextLink);
      
      // Don't call this.setState() here!
      this.state.store = { navTabProposalItems: navTabProposalItems };*/
  };
    
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleNext = () => {
    console.log("[AER] StepperComp.handleNext this.state", this.state);    
    const nextId = (this.state.id === this.state.properties.slidePage[0].tab.length) ? this.state.id : this.state.id + 1;
    console.log("[AER] StepperComp.handleNext nextId: ", nextId);
    const nextLink = this.state.properties.slidePage[0].tab[nextId - 1].link;
    console.log("[AER] StepperComp.handleNext nextLink: ", nextLink);
    this.setState(prevState => ({
      activeStep: prevState.activeStep,
      id: (prevState.id === this.state.properties.slidePage[0].tab.length) ? prevState.id : prevState.id + 1,
    }));
    this.setState({ value: nextId - 1 });
  };
    
  handleBack = () => {
    console.log("[AER] StepperComp.handleBack this.state", this.state);       
    const nextId = (this.state.id === 1) ? this.state.id : this.state.id - 1;
    console.log("[AER] StepperComp.handleBack nextId: ", nextId);
    const nextLink = this.state.properties.slidePage[0].tab[nextId - 1].link;
    console.log("[AER] StepperComp.handleBack nextLink: ", nextLink);
    this.setState(prevState => ({
      activeStep: prevState.activeStep,
      id: (prevState.id === 1) ? prevState.id : prevState.id - 1,          
    }));    
    this.setState({ value: nextId - 1 });
  };
      
  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };
    
  switch = (event) => {
    this.setState({ id: parseInt(event.target.id), value: event.target.id - 1});
  };

  handleErrore = event => {
    console.log('#StepperComp handleErrore', event.target.value);
  }; 
    
  getIsValida = (unoStep) => {
    let isValida = null;
    if (unoStep === STEP_CONTRAENTE_ASSICURATO) {
      isValida = this.getIsValidaStepContraenteAssicurato();    	  
    } else if (unoStep === STEP_ALTRE_FIGURE) {
      isValida = this.getIsValidaStepAltreFigure(); 
    } else if (unoStep === STEP_CARATTERISTICHE_CONTRATTO) {
      isValida = this.getIsValidaStepCaratteristicheContratto(); 
    } else if (unoStep === STEP_CONSENSI) {
      isValida = this.getIsValidaStepConsensi(); 
    } else if (unoStep === STEP_QUESTIONARI) {
      isValida = this.getIsValidaStepQuestionari(); 
    } else if (unoStep === STEP_STAMPA_PROPOSTA) {
      isValida = this.getIsValidaStampaProposta(); 
    }
    return isValida;
  }
    
  getIsValidaStepContraenteAssicurato = () => {
    let isValidaContraente = null;
    let isValidaAssicurato = null;
    let isValidaTitolareEff = null;
    let isValidaScopeTcmInd = null;
    if (this.props.contraenteFisico.controlloDati !== '') {
      isValidaContraente = this.props.contraenteFisicoValida;
      isValidaTitolareEff = true;
    } else if (this.props.contraenteGiuridico.controlloDati !== '') {
      isValidaContraente = this.props.contraenteGiuridicoValida;    	  
    }
    if ((this.props.assicuratoFisico.controlloDati !== '') || (this.props.assicuratoFisico.flAssicuratoUgualeContraente === 'S')) {
      isValidaAssicurato = this.props.assicuratoFisicoValida;
    }        
    if(this.props.titolareEffList.length > 0) {
      isValidaTitolareEff = true;
    } else if ((this.props.titolareEffList.length === 0) && (this.props.titolareEffValida !== null)) {
      isValidaTitolareEff = false;
    }
    
    //2021-07-01 PFD: TCM_INDIV
    //let isTcmIndivValida = true; // default
    let isTcmIndivValida = this.props.tcmIndividualeValida; // default   
    const scopeTcmEmissione = this.props.scopeTcmEmissione; // valorizzato in InitProposal // regola TCM_EMISSIONE sulla TZSEV0988
    // Se sono in scopeTcmEmisione e ho confermato il Contraente PF/PG    
    if (scopeTcmEmissione 
    	 &&	(this.props.contraenteFisico.controlloDati !== null || this.props.contraenteGiuridico.controlloDati !== null)
    		) {
    	const isTcmIndValid = isTcmIndividualeValida(locale_it);
    	if (!isTcmIndValid) {
    		isTcmIndivValida = checkMandatoryFieldsTcmIndividuale();
    	}
    }

    
    const isValida = ((isValidaContraente === null) && (isValidaAssicurato === null) && (isValidaTitolareEff === null)) 
                          ? null 
                          : ((isValidaContraente === false) || (isValidaAssicurato === false) || (isValidaTitolareEff === false) || isTcmIndivValida === false)
                               ? false
                               : ((isValidaContraente === true) && (isValidaAssicurato === true) && (isValidaTitolareEff === true) && isTcmIndivValida === true)
                                  ? true
                                  : null;
    return isValida;
  }

  
  
  
  getIsValidaStepAltreFigure = () => {
    let isValidaBenefMorte = null;	 
    let isValidaBenefVita = null;
    let isValidaRefTerzo = null;
    const items = this.props.dominio.ruoloXProdottoItems.map((item) => {
    														return (item.value)
    													});
    if (showSezione(items, RUOLO_BENEF_VITA) !== true) {
    	isValidaBenefVita = true;
      } else { 
        let controlloDati = this.props.controlloDatiBenefVitaFisico !== '' || 
                            this.props.controlloDatiBenefVitaGiuridico !== '' || 
                            this.props.controlloDatiBenefVitaGenerico !== '';     
        if (controlloDati) {
          const percBenVitaTotale = this.props.percBenVitaTotale;
          isValidaBenefVita = percBenVitaTotale === 100.0;
        }      	
      }    
    if (showSezione(items, RUOLO_BENEF_MORTE) !== true) {
      isValidaBenefMorte = true;
      isValidaRefTerzo = true;
    } else { 
      let controlloDati = this.props.controlloDatiBenefMorteFisico !== '' || 
       					  this.props.controlloDatiBenefMorteGiuridico !== '' || 
       					  this.props.controlloDatiBenefMorteGenerico !== '';    
      if (controlloDati) {
        const percBenMorteTotale = this.props.percBenMorteTotale;
        isValidaBenefMorte = percBenMorteTotale === 100.0;
      }    	
      if (this.props.controlloDatiReferenteTerzoFisico !== '') {
        isValidaRefTerzo = this.props.referenteTerzoFisicoValida;
      }  
    }
    const isValida = ((isValidaBenefVita === null) && (isValidaBenefMorte === null) && (isValidaRefTerzo === null)) 
                     ? null 
                     : ((isValidaBenefVita === false) || (isValidaBenefMorte === false) || (isValidaRefTerzo === false))
                           ? false
                           : ((isValidaBenefVita === true) && (isValidaBenefMorte === true) && (isValidaRefTerzo === true))
                                  ? true
                                  : null;      
    return isValida;
  }  
  
  getIsValidaStepCaratteristicheContratto = () => {
    let isValida = null;
    let isValidaModalitaPagamento = this.props.datiModalitaPagamentoValida;
    if (this.props.prodotto.tipo === PRODOTTO_TIPOLOGIA_ALTRO) {
      let isValidaCapitaleAssicurato = this.props.datiPremioCapitaleAssicuratoValida;	
      isValida = ((isValidaModalitaPagamento === null) && (isValidaCapitaleAssicurato === null)) 
                     ? null 
                     : ((isValidaModalitaPagamento === false) || (isValidaCapitaleAssicurato === false))
                           ? false
                           : ((isValidaModalitaPagamento === true) && (isValidaCapitaleAssicurato === true))
                                  ? true
                                  : null;      
    } else {
      let isValidaPremio = this.props.datiPremioValida;
      let isValidaRipartizionePremio = this.props.datiRipartizionePremioValida;	    
      let isValidaOpzioniContrattuali = null;
      const opzioneContrattuale = this.props.datiOpzioniContrattuali.opzioneContrattuale;
      if (opzioneContrattuale === DATI_PIANO_EROGAZIONI_PERIODICHE) {
        isValidaOpzioniContrattuali = this.props.datiPianoErogazioniPeriodicheValida;
      } else if (opzioneContrattuale === DATI_TAKE_PROFIT) {
        isValidaOpzioniContrattuali = this.props.datiTakeProfitValida;
      } else if (opzioneContrattuale === DATI_LIFE_CYCLE) {
        isValidaOpzioniContrattuali = this.props.datiOpzioniContrattuali.datiLifeCycle.datiLifeCycleValida;
      } else if (opzioneContrattuale === DATI_NESSUNO) {
        isValidaOpzioniContrattuali = true;
      } else if (opzioneContrattuale === DATI_PIANO_SPOSTAMENTO_AUTOMATICO) {
        isValidaOpzioniContrattuali = this.props.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.datiPianoSpostamentoAutomaticoValida;
      } 
      
      isValida = ((isValidaModalitaPagamento === null) && (isValidaPremio === null) && (isValidaRipartizionePremio === null) && (isValidaOpzioniContrattuali === null)) 
                      ? null 
                      : ((isValidaModalitaPagamento === false) || (isValidaPremio === false) || (isValidaRipartizionePremio === false) || (isValidaOpzioniContrattuali === false))
                           ? false
                           : ((isValidaModalitaPagamento === true) && (isValidaPremio === true) && (isValidaRipartizionePremio === true) && (isValidaOpzioniContrattuali === true))
                                ? true
                                : null;        
    }
    return isValida;
  }
  
  getIsValidaStepConsensi = () => {
    const isValidaDatiIntermediario = this.props.datiIntermediarioValida;
    const isValidaDatiConsenso = this.props.datiConsensoValida;
    const isValida = ((isValidaDatiIntermediario === null) && (isValidaDatiConsenso === null)) 
                     ? null 
                     : ((isValidaDatiIntermediario === false) || (isValidaDatiConsenso === false))
                           ? false
                           : ((isValidaDatiIntermediario === true) && (isValidaDatiConsenso === true))
                                  ? true
                                  : null;      
    return isValida;
  }
  
  getIsValidaStepQuestionari = () => {
	let isValida = false;
	
	const prodotto = this.props.prodotto;
	const contraenteFisico = this.props.contraenteFisico;
	const assicuratoFisico = this.props.assicuratoFisico;
	const contraenteGiuridico = this.props.contraenteGiuridico;
	const datiQuestionarioAdeguatezzaFisicoValida = this.props.datiQuestionarioAdeguatezzaFisicoValida;
	const datiQuestionarioAdeguatezzaGiuridicoValida = this.props.datiQuestionarioAdeguatezzaGiuridicoValida;
	const datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
	const datiQuestionarioAnamnestico = this.props.datiQuestionarioAnamnestico;
	const datiQuestionarioAnamnesticoMalattiaGrave = this.props.datiQuestionarioAnamnesticoMalattiaGrave;
	const datiQuestionarioAnamnesticoSemplificato = this.props.datiQuestionarioAnamnesticoSemplificato;
	const isChangedFromSemplificato = this.props.isChangedFromSemplificato;
	  
    isValida = getIsValidaStepQuestionari(prodotto, contraenteFisico, assicuratoFisico, contraenteGiuridico, 
  		  							datiQuestionarioAdeguatezzaFisicoValida, datiQuestionarioAdeguatezzaGiuridicoValida, 
  		  							datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoMalattiaGrave,
  		  							datiQuestionarioAnamnesticoSemplificato, isChangedFromSemplificato);
	
    return isValida;
  }
  
  getIsValidaStepQuestionari_OLD = () => {
	    let isValidaAdeguatezza = null;	 
	    let isValidaAnamnestico = null;
	    let isValidaSemplificato = null;
	    console.log(showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002));
	    if (showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ADEG_011) !== true) {
	      isValidaAdeguatezza = true;
	    } else {
	//RT semaforo questionari INIZIO
	/*      const controlloDati = (this.props.datiGeneraliContraenteGiuridico.codiceFiscale === '') 
	                                        ? this.props.datiQuestionarioAdeguatezzaFisicoControlloDati : this.props.datiQuestionarioAdeguatezzaGiuridicoControlloDati;
	      isValidaAdeguatezza = (controlloDati === '') ? null : true;  */  	  
	//RT semaforo questionari FINE     	
	      isValidaAdeguatezza = (this.props.datiGeneraliContraenteGiuridico.codiceFiscale === '') 
	                                   ? this.props.datiQuestionarioAdeguatezzaFisicoValida : this.props.datiQuestionarioAdeguatezzaGiuridicoValida;
	    }
	//2020-02-08 PFD - START     if (this.props.showOnlySemplificato || !showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002)/*  || !showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) */) {
	    const dataNascita = (this.props.assicuratoFisico.flAssicuratoUgualeContraente === 'S') ? this.props.contraenteFisico.datiGenerali.dataNascita : this.props.assicuratoFisico.datiGenerali.dataNascita;
		  const datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
		  const datiQuestionarioAnamnestico = this.props.datiQuestionarioAnamnestico;
		  const datiQuestionarioAnamnesticoMalattiaGrave = this.props.datiQuestionarioAnamnesticoMalattiaGrave;
		  const datiQuestionarioAnamnesticoSemplificato = this.props.datiQuestionarioAnamnesticoSemplificato;
		  
		  const questionarioAnamnestico = getQuestionarioAnamnestico (dataNascita, datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoSemplificato, datiQuestionarioAnamnesticoMalattiaGrave);
		  const tipoQuestionarioAnam = questionarioAnamnestico.tipoQuestionario;
		  
	    if (!showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002) ||  
	    	 (tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO && !this.props.isChangedFromSemplificato) ) {
	    	isValidaAnamnestico = true;
	    } else {
	      const malattiaGrave = this.props.datiPremioCapitaleAssicurato.malattiaGrave;
	      const isQuestionarioAnamnestico = (malattiaGrave === null) || (malattiaGrave === undefined) || (malattiaGrave === '') || (malattiaGrave === false);    
	//RT semaforo questionari INIZIO
	/*      const controlloDati = (isQuestionarioAnamnestico) 
	                               ? this.props.datiQuestionarioAnamnesticoControlloDati : this.props.datiQuestionarioAnamnesticoMalattiaGraveControlloDati;        
	      isValidaAnamnestico = (controlloDati === '') ? null : true;  	  */
	//RT semaforo questionari FINE    	
	      isValidaAnamnestico = (isQuestionarioAnamnestico) ? this.props.datiQuestionarioAnamnesticoValida : this.props.datiQuestionarioAnamnesticoMalattiaGraveValida;
	    }
	//2020-02-08 PFD - START     if(!this.props.showSemplificato) {
	    if (tipoQuestionarioAnam !== QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO || !showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ADEGUATEZZA_ANAM_002)/*  || !showSezione(this.props.prodotto.questionari, COD_QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) */) {    
	      isValidaSemplificato = true;
	    } else {
	      isValidaSemplificato = (this.props.isChangedFromSemplificato) ? true : this.props.datiQuestionarioAnamnesticoSemplificatoValida;
	    }
	    const isValida = ((isValidaAdeguatezza === false) || (isValidaAnamnestico === false) || (isValidaSemplificato === false))
	                           ? false
	                           : ((isValidaAdeguatezza === true) && (isValidaAnamnestico === true) && (isValidaSemplificato === true))
	                                  ? true
	                                  : null;      
	    return isValida;
	  }
    
  getIsValidaStampaProposta = () => {
    let isValida = null;
    if (this.props.confermaStampaProposal === true) {
      let datiConsensoComElettr = null;
      let flConsensoComElettr = null;
      if ((this.props.contraenteFisico.controlloDati !== '') && (this.props.contraenteFisicoValida === true)) {
        datiConsensoComElettr = this.props.contraenteFisico.datiConsensoComElettr;
      } else if ((this.props.contraenteGiuridico.controlloDati !== '') && (this.props.contraenteGiuridicoValida === true)) {    	  
        datiConsensoComElettr = this.props.contraenteGiuridico.datiConsensoComElettr;
      }
      if (datiConsensoComElettr !== null) {
        flConsensoComElettr = datiConsensoComElettr.flConsensoComElettr;
      }
      if (((flConsensoComElettr === 'S') && (this.props.mailInviata === true)) ||
    	  (flConsensoComElettr === 'N')) {
        isValida = true;
      }
    }
    return isValida;
  }
  
  
  render() {
    const { classes } = this.props;
  	const { t } = this.props; //hoc		
//    console.log("StepperComp.render this.props", this.props);        
    const { activeStep, properties, id, value } = this.state;        
//    const maxSteps = properties.slidePage.length;
//    console.log("StepperComp.render activeStep", activeStep);
//    console.log("StepperComp.render id", id);
    return (
      <Fragment>
        <div style={{height: '65px'}} />        		
        <div className={classes.componentContainer}>
          <Grid container>
          {/*<Grid item xs={1} classes={{ item: classes.gridArrowLeft }}>
            <KeyboardArrowLeft onClick={this.handleBack} className={classes.arrows} />
            </Grid>*/}
            <Grid item xs={12}>
              <AutoPlaySwipeableViews
                step={2}
                index={activeStep}
                enableMouseEvents
                autoPlay={false}
              >                            
                {properties.slidePage.map((singleTab, tabIndex) => (
                  <Grid key={tabIndex} container justify={'space-between'} classes={{container: classes.gridContainer}}>
                    {singleTab.tab.map((elemento, indexElemento) => {
                      const isValida = this.getIsValida(elemento.type);
                      return (
                        <Grid key={indexElemento} item xs={2} classes={{ item: classes.gridTab }}>
                          <div 
                            onClick={(this.props.confermaStampaProposal === false || (this.props.confermaStampaProposal === true && elemento.type === STEP_STAMPA_PROPOSTA)) ? this.switch : ''} 
                            className={(this.props.confermaStampaProposal === false || (this.props.confermaStampaProposal === true && elemento.type === STEP_STAMPA_PROPOSTA)) 
                                 ? (id===elemento.id ? classes.active : classes.disabled) 
                                 : classes.disabledAfterPrint} id={elemento.id}>
                            <div id={elemento.id} className={id===elemento.id ? classes.divSvgActive : classes.divSvgDisabled}>
                                      
                                {/* <SvgIcon id={elemento.id}
                                         className={id===elemento.id ? classes.svgActive : classes.svgDisabled} 
                                         component={elemento.img} /> */}
                                        
                              <img id={elemento.id} className={id===elemento.id ? classes.svgActive : classes.svgDisabled} src={elemento.img} alt={elemento.titolo} /> 
                            </div>                            
                            <Typography classes={{root: classes.tabTypo}} id={elemento.id}>
                              {t(elemento.titolo)}
                            </Typography>
                            <LensIcon className={((isValida === undefined) || (isValida === null))
                                ? classes.lensIconWARNING 
                                : ((isValida === true) ? classes.lensIconOK : classes.lensIconERROR)} />                          
                          </div>  
{/*                         <Typography classes={{root: classes.tabTypo}}></Typography>
                          <div>
                              <LensIcon className={((isValida === undefined) || (isValida === null))
                                ? classes.lensIconWARNING 
                                : ((isValida === true) ? classes.lensIconOK : classes.lensIconERROR)} />
                          </div> */}
                        </Grid>
                      )
                    })}
                  </Grid>
                ))}
              </AutoPlaySwipeableViews>
              {/*<MobileStepper steps={maxSteps}
                           position="static"
                           variant={'dots'}
                           activeStep={activeStep}
                           classes={{root: classes.mobileStepper, dot: classes.stepperDots, dots: classes.stepperDotsContainer, dotActive: classes.stepperDotActive}}
              />*/}
              <div className={classes.divider} />
            </Grid>  
            {/*<Grid item xs={1} classes={{item: classes.gridArrowRight}}>
            <KeyboardArrowRight onClick={this.handleNext} className={classes.arrows}/> 
            </Grid>*/}
                
            <Grid item xs={12}>
              {value === 0 && <AnagraphProposal onConferma={this.handleErrore} />}
              {value === 1 && <AltreFigureProposal />}
              {value === 2 && <CaratteristicheContrattoProposal />}
              {value === 3 && <DichIntermediarioConsensoProposal />}
              {value === 4 && <QuestionarioProposal />}
              {value === 5 && <StampaPropostaAss />}
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

StepperComp.propTypes = {
  classes: PropTypes.object.isRequired,
  slidePage: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(withTranslation()(withStyles(styles)(StepperComp)));