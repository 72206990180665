import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PremioPanel from './PremioPanel';
import RipartizionePremioPanel from './RipartizionePremioPanel';
import OpzioniContrattualiPanel from './OpzioniContrattualiPanel';
import ModalitaPagamentoPanel from './ModalitaPagamentoPanel';
import PremioCapitaleAssicuratoPanel from './PremioCapitaleAssicuratoPanel';
import { PRODOTTO_TIPOLOGIA_ALTRO, 
         PREMIO_CAPITALE_ASSICURATO, PREMIO, RIPARTIZIONE_PREMIO, OPZIONI_CONTRATTUALI, MODALITA_PAGAMENTO, 
         DATI_PIANO_EROGAZIONI_PERIODICHE, DATI_TAKE_PROFIT, DATI_LIFE_CYCLE, DATI_NESSUNO, DATI_PIANO_SPOSTAMENTO_AUTOMATICO } from '../../../utility/jsConstants';

const styles = theme => ({
  root: {	  
/*	 marginTop: 200, 
	 marginLeft: 100,
	 marginRight: 100, */
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiQuestionarioAdeguatezzaFisico.mapStateToProps.state', state);
  return {
    prodotto: state.areaProdottoReducer.prodotto,
    datiPremioCapitaleAssicuratoValida: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato.datiPremioCapitaleAssicuratoValida,
    datiPremioValida: state.areaDatiPremioReducer.caratteristicheContratto.datiPremio.datiPremioValida,    
    datiRipartizionePremioValida: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio.datiRipartizionePremioValida,
    datiOpzioniContrattuali: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali,
    datiPianoErogazioniPeriodicheValida: state.areaDatiPianoErogazioniPeriodicheReducer.caratteristicheContratto.datiPianoErogazioniPeriodiche.datiPianoErogazioniPeriodicheValida,
    datiTakeProfitValida: state.areaDatiTakeProfitReducer.caratteristicheContratto.datiTakeProfit.datiTakeProfitValida,
    datiModalitaPagamentoValida: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento.datiModalitaPagamentoValida,
    datiPianoSpostamentoAutomaticoValida: state.areaDatiPianoSpostamentoAutomaticoReducer.caratteristicheContratto.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.datiPianoSpostamentoAutomaticoValida,
  };
};

class CaratteristicheContrattoProposal extends React.Component {
  constructor(props) {
    super(props); 
  };
    
  getIsValida = (unaTipologia) => {
    let isValida = null;
    if (unaTipologia === PREMIO) {
      isValida = this.props.datiPremioValida;
    } else if (unaTipologia === RIPARTIZIONE_PREMIO) {
      isValida = this.props.datiRipartizionePremioValida;    	
    } else if (unaTipologia === OPZIONI_CONTRATTUALI) {
      const opzioneContrattuale = this.props.datiOpzioniContrattuali.opzioneContrattuale;
      if (opzioneContrattuale === DATI_PIANO_EROGAZIONI_PERIODICHE) {
        isValida = this.props.datiPianoErogazioniPeriodicheValida;
      } else if (opzioneContrattuale === DATI_TAKE_PROFIT) {
        isValida = this.props.datiTakeProfitValida;
      } else if (opzioneContrattuale === DATI_LIFE_CYCLE) {
        isValida = this.props.datiOpzioniContrattuali.datiLifeCycle.datiLifeCycleValida;
      } else if (opzioneContrattuale === DATI_NESSUNO) {
        isValida = true;
      } else if (opzioneContrattuale === DATI_PIANO_SPOSTAMENTO_AUTOMATICO) {
        isValida = this.props.datiPianoSpostamentoAutomaticoValida;
      } 
    } else if (unaTipologia === MODALITA_PAGAMENTO) {
      isValida = this.props.datiModalitaPagamentoValida;    	
    } else if (unaTipologia === PREMIO_CAPITALE_ASSICURATO) {
      isValida = this.props.datiPremioCapitaleAssicuratoValida;    	
    }
    return isValida;
  }
    
  render() {
    const { classes } = this.props;
    const prodotto = this.props.prodotto;
    
    return (
      <div className={classes.root}>
        {prodotto.tipo === PRODOTTO_TIPOLOGIA_ALTRO 
	       ? 
	        <PremioCapitaleAssicuratoPanel isValida={this.getIsValida(PREMIO_CAPITALE_ASSICURATO)} />
	       : 
	    	<div>
	          <PremioPanel isValida={this.getIsValida(PREMIO)} />
	          <RipartizionePremioPanel isValida={this.getIsValida(RIPARTIZIONE_PREMIO)} />
	          <OpzioniContrattualiPanel isValida={this.getIsValida(OPZIONI_CONTRATTUALI)} />
	        </div>
        }
	      <ModalitaPagamentoPanel isValida={this.getIsValida(MODALITA_PAGAMENTO)} />
      </div>
    );
  }
}

CaratteristicheContrattoProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CaratteristicheContrattoProposal));