import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
//import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Search from '@material-ui/icons/Search';

import TextFieldComp from '../../../common/TextFieldComp';
import NumberFieldComp from '../../../common/NumberFieldComp';
import * as actions from './action/datiRipartizionePremioFondi';
import * as actionType from './actionType/datiRipartizionePremioFondi';
import { textTrim, calcolaTotalePercentualeFondi } from '../../../../utility/genericUtility';

function desc(a, b, orderBy, order) {
  if(orderBy==='percInvest'){
	const percInvestA = textTrim(a.percInvest, true)!=='' ? new Number(textTrim(a.percInvest, true)) : (order==='desc' ? 0 :  10000); 
	const percInvestB = textTrim(b.percInvest, true)!=='' ? new Number(textTrim(b.percInvest, true)) : (order==='desc' ? 0 :  10000);  
    if (percInvestB < percInvestA) {
	  return -1;
	}
	if (percInvestB > percInvestA) {
	  return 1;
	}
	return 0;
  }else{
    if (b[orderBy] < a[orderBy]) {
	  return -1;
	}
	if (b[orderBy] > a[orderBy]) {
	  return 1;
	}
	return 0;
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy, order)
    : (a, b) => -desc(a, b, orderBy, order);
}

function compareFondi(a, b) {
  const percInvestA = textTrim(a.percInvest, true)!=='' ? textTrim(a.percInvest, true) : 0; 
  const percInvestB = textTrim(b.percInvest, true)!=='' ? textTrim(b.percInvest, true) : 0; 
  if ((percInvestB - percInvestA) === 0) {
    if (a.nomeFondo > b.nomeFondo) {
      return 1;
  	}else if (a.nomeFondo < b.nomeFondo) {
  	  return -1;
  	}else{
      if (a.codiceIsin > b.codiceIsin) {
        return 1;
      }else if (a.codiceIsin < b.codiceIsin) {
      	return -1;
      }else{
        return 0;
      } 
  	}
  }else{
    return percInvestB - percInvestA;  
  }
}

class EnhancedTableHead extends React.Component {
  
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, t } = this.props;  
    return ( 				
      <TableHead>
        <TableRow>
          <CustomTableCell width='20%' key='codiceIsin' sortDirection={orderBy === 'codiceIsin' ? order : false}>
            <Tooltip title={t('ordina')} placement='bottom-end' enterDelay={300}>
              <TableSortLabel active={orderBy === 'codiceIsin'} direction={order} onClick={this.createSortHandler('codiceIsin')}> 
                <Typography variant='subtitle1'>{t('codiceIsin')}</Typography>
              </TableSortLabel>
            </Tooltip>
          </CustomTableCell>
          <CustomTableCell width='30%' key='nomeFondo' sortDirection={orderBy === 'nomeFondo' ? order : false}>
            <Tooltip title={t('ordina')} placement='bottom-end' enterDelay={300}>
              <TableSortLabel active={orderBy === 'nomeFondo'} direction={order} onClick={this.createSortHandler('nomeFondo')}>
                <Typography variant='subtitle1'>{t('nomeFondo')}</Typography>
              </TableSortLabel>
            </Tooltip>
          </CustomTableCell>
          <CustomTableCell width='10%' key='percInvest' align='center' padding='none' sortDirection={orderBy === 'percInvest' ? order : false}>
            <Tooltip title={t('ordina')} placement='bottom-end' enterDelay={300}>
              <TableSortLabel active={orderBy === 'percInvest'} direction={order} onClick={this.createSortHandler('percInvest')}>
                <Typography variant='subtitle1'>{t('percInvest')}</Typography>
              </TableSortLabel>
            </Tooltip>
          </CustomTableCell>
          <CustomTableCell width='40%' key='note' align='left'><Typography variant='subtitle1'>{t('note')}</Typography></CustomTableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.color.greyLight,
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
  },
  body: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  table: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
  },
  textField: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1), 
	marginBottom: theme.spacing(2),
	height: 48,	
  },
  textFieldWidthPerc: theme.textFieldWidthPerc,
/*  marginGrid: {
    marginLeft: theme.spacing(1.5),
  },*/
  tableWrapper: {
	 width:'100%',
  },
  divider: {
    height: theme.spacing(3),
  },
  totale: {
    backgroundColor: theme.palette.color.greyLight,
  },
  marginGrid: {
    marginLeft: theme.spacing(0.5),
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.color.windowsBlue,
      '&:hover': { 
         backgroundColor: theme.palette.color.windowsBlue, 
         color: theme.palette.color.white,
    }, 
  }
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiRipartizionePremioFondi.mapStateToProps.state', state);
  return {
    datiRipartizionePremioFondi: state.areaDatiRipartizionePremioFondiReducer.caratteristicheContratto.datiRipartizionePremioFondi,
    datiRipartizionePremio: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio,
    controlloDati: state.areaDatiRipartizionePremioReducer.caratteristicheContratto.datiRipartizionePremio.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiRipartizionePremioFondi.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiRipartizionePremioFondi: (unDato, codiceFondo, nomeFondo, unActionType) => dispatch(actions.updateDatiRipartizionePremioFondi(unDato, codiceFondo, nomeFondo, unActionType)),
  };
};
	
class DatiRipartizionePremioFondi extends React.Component {
	
  constructor(props) {
    super(props);
    const datiRipartizionePremioFondi = this.props.datiRipartizionePremioFondi;
    let listaFondi = datiRipartizionePremioFondi.fondo;
    this.state = {
      order: 'asc',
      //orderBy: 'codiceIsin',
      orderBy: '',
      fondo: listaFondi,
      codiceIsin: datiRipartizionePremioFondi.codiceIsin,
      nomeFondo: datiRipartizionePremioFondi.nomeFondo,	
      fondi: this.filtraFondi(datiRipartizionePremioFondi.codiceIsin, datiRipartizionePremioFondi.nomeFondo),
    //PFD 2019-12-09 :     totale: this.calcolaTotale(),
      totale: calcolaTotalePercentualeFondi(listaFondi),
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,	      
    }
  };

/* PFD 2019-12-09 Spostato in genericUtility  
  calcolaTotale() {
    let tot = 0;
    this.props.datiRipartizionePremioFondi.fondo.forEach(function(item, index, array) {
      if(!isNaN(item.fondo) && item.fondo !== '') {
        tot = tot + parseInt(item.fondo)
      }
    });	 
    return tot;
  };
*/ 
  
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ 
      order: order, 
      orderBy: orderBy 
    });
  };
      
  handleChangeFondo = (codiceFondo, nomeFondo) => event => {  
    let vecchioCampoFondo = null;
    let vecchioValoreFondo = null;    
    const nuovoValoreFondo = textTrim(event.target.value, true);
    const unCodiceFondo = textTrim(codiceFondo, true);
    let fondo = this.state.fondo.filter(unCampo => textTrim(unCampo.codiceFondo, true) === unCodiceFondo);
    if (fondo.length > 0) {
      vecchioCampoFondo = fondo[0];
      vecchioValoreFondo = textTrim(vecchioCampoFondo.fondo, true);
    }
    if ((nuovoValoreFondo !== vecchioValoreFondo) && 
        (((fondo.length === 0) && (nuovoValoreFondo !== '')) || (fondo.length > 0))) {	
      this.props.updateDatiRipartizionePremioFondi(nuovoValoreFondo, codiceFondo, nomeFondo, actionType.UPDATE_FONDO);
      const fondoCopia = {...this.state}.fondo;
      fondoCopia.forEach(function(item, index, array) {
        if(item.codiceFondo === codiceFondo) {
          item.fondo = nuovoValoreFondo;
        }
      }); 
      const copiaFondi =  {...this.state}.fondi;
      copiaFondi.forEach(function(item, index, array) {
        if(item.codiceIsin === codiceFondo) {
          item.percInvest = nuovoValoreFondo;
        }
      });  	
      this.setState({
        fondo: fondoCopia,
        //2019-09-12 PFD: totale: this.calcolaTotale(),
        totale: calcolaTotalePercentualeFondi(fondoCopia),
        fondi: copiaFondi,
      });    	
    }
  };
       
  getFondoByCode = codiceFondo => {
    const fondo = this.state.fondo.filter(unItem => (textTrim(unItem.codiceFondo, true) === textTrim(codiceFondo, true)));
    const unFondo = (fondo.length > 0) ? fondo[0].fondo : '';
    return unFondo;
  }
    
  handleChangeCodiceIsin = event => {
	const nuovoCodiceIsin = textTrim(event.target.value, true);
	const vecchioCodiceIsin = textTrim(this.props.datiRipartizionePremioFondi.codiceIsin, true);
	if (nuovoCodiceIsin !== vecchioCodiceIsin) {
      this.props.updateDatiRipartizionePremioFondi(event.target.value, 0, 0, actionType.UPDATE_CODICE_ISIN);   
      this.setState({ 
        codiceIsin: event.target.value 
      });
	}
  };
    
  handleChangeNomeFondo = event => {
	const nuovoNomeFondo = textTrim(event.target.value, true);
	const vecchioNomeFondo = textTrim(this.props.datiRipartizionePremioFondi.nomeFondo, true);
	if (nuovoNomeFondo !== vecchioNomeFondo) {
      this.props.updateDatiRipartizionePremioFondi(event.target.value, 0, 0, actionType.UPDATE_NOME_FONDO);   
      this.setState({ 
        nomeFondo: event.target.value 
      });
	}
  };
    
  changeFilter = () => {
    this.setState({
      fondi: this.filtraFondi(this.state.codiceIsin, this.state.nomeFondo),
    });
  }
    
  filtraFondi = (codiceIsin, nomeFondo) => {
    let fondi = [];
    let codiceIsinSearch = codiceIsin ? codiceIsin.toUpperCase() : '';
    let nomeFondoSearch = nomeFondo ? nomeFondo.toUpperCase() : '';
    if(codiceIsinSearch !== '' || nomeFondoSearch !== ''){
      this.props.fondiItems.forEach(function(item, index, array) {
        if(codiceIsinSearch !== '' && nomeFondoSearch !== ''){
    	  if((item.codiceIsin.toUpperCase().indexOf(codiceIsinSearch) !== -1 && item.nomeFondo.toUpperCase().indexOf(nomeFondoSearch) !== -1) || textTrim(item.percInvest, true) !== ''){
            fondi.push(item);
    	  } 
        }else if(codiceIsinSearch !== ''){
    	  if((item.codiceIsin.toUpperCase().indexOf(codiceIsinSearch) !== -1) || textTrim(item.percInvest, true) !== ''){
	        fondi.push(item);
	      }
        }else{
    	  if((item.nomeFondo.toUpperCase().indexOf(nomeFondoSearch) !== -1) || textTrim(item.percInvest, true) !== ''){
	        fondi.push(item);
	      }  
        }
   	  });
    }else{
  	  fondi = this.props.fondiItems;
    }
    fondi.sort(compareFondi);  
    return fondi;
    //return this.props.fondiItems;
  }
    
  isRequiredFondo = () => {
    if(this.props.datiRipartizionePremio.lineaLibera === '') {
      return false;
    } else {
      const fondo = this.props.datiRipartizionePremioFondi.fondo.filter(unItem => (textTrim(unItem.fondo, true) !== ''));    	
      return (fondo.length === 0);
	}
  } 
    
  initStatoCampi = () => {
    return [ {id: 'fondo', isError: false},
    	     {id: 'totale', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {   
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
    
  render() {
    const { classes, fondiItems, datiRipartizionePremioFondi } = this.props;
    const { order, orderBy, totale} = this.state;
    const { t } = this.props; //hoc  
    const controlloDati = this.props.controlloDati;
    return (
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.marginGrid}>
 	        <Grid item md={2} xs={12}>
 	           <TextFieldComp onBlur={this.handleChangeCodiceIsin} aStyle={classes.textFieldWidthPerc} id='codiceIsin' label='codiceIsin' value={datiRipartizionePremioFondi.codiceIsin} required={false}/>
	    	</Grid>
	    	<Grid item md={4} xs={12}>
	    	  <TextFieldComp onBlur={this.handleChangeNomeFondo} aStyle={classes.textFieldWidthPerc} id='nomeFondo' label='nomeFondo' value={datiRipartizionePremioFondi.nomeFondo} required={false}/>
	    	</Grid>
	    	<Grid item md={6} xs={12}>
	    	  <IconButton className={classes.button} aria-label='Search' onClick={this.changeFilter}>
	            <Search fontSize='large' />
	          </IconButton>
	    	</Grid>
	      </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.root}>
            <Table className={classes.table}>
              <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
    	          t={t}
              />
    	      <TableBody>
              {stableSort(this.state.fondi, getSorting(order, orderBy)).map((elementoDetail, indexElementoDetail) => (
                <TableRow key={indexElementoDetail}>
                  <CustomTableCell><Typography variant='caption'>{elementoDetail.codiceIsin}</Typography></CustomTableCell>
                  <CustomTableCell><Typography variant='caption'>{elementoDetail.nomeFondo}</Typography></CustomTableCell>
                  <CustomTableCell padding='none'>
                    <NumberFieldComp onBlur={this.handleChangeFondo(elementoDetail.codiceIsin, elementoDetail.nomeFondo)} aStyle={classes.textField} id={'fondo' + elementoDetail.codiceIsin} label=' ' 
                      format='###,###' value={this.getFondoByCode(elementoDetail.codiceIsin)} maxLength='7' required={this.isRequiredFondo()} controlloDati={controlloDati} onError={this.handleError} />	
                  </CustomTableCell>
                  {indexElementoDetail === 0 &&
                    <CustomTableCell style={{align: 'center', verticalAlign: 'top'}} align='center' rowSpan={fondiItems.length + 1}>
                      <Typography variant='caption'>{t('noteFondi')}</Typography>
                      <div className={classes.divider} />
                      <Typography variant='caption'>{t('noteFondi2')}</Typography>
  	      	        </CustomTableCell>
  	      	      }
                </TableRow>
              ))}
                <TableRow className={classes.totale}>
                  <CustomTableCell align='center' colSpan={2}><Typography variant='subtitle1'>{t('totale')}</Typography></CustomTableCell>
                  <CustomTableCell align='right'><Typography variant='subtitle1'>{totale}</Typography></CustomTableCell>
                </TableRow>
              </TableBody>
   	        </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
   
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    
    // PFD 2019-09-12: const erroreCalcoloTotale = this.props.datiRipartizionePremioFondi.esitoPercentualeFondo === 'KO';  
	
    // PFD 2019-09-12::
	if (prevState.totale!==calcolaTotalePercentualeFondi(this.state.fondo)) {
		this.handleError('totale', this.state.totale!==100.0);
	}
	
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
    	      isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError // PFD:2019-09-12: || erroreCalcoloTotale
      );
	});
    console.log('########DatiRipartizionePremioFondi componentDidUpdate this.state.statoCampi', this.state.statoCampi);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
/*      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      */
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
    
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }  
}

DatiRipartizionePremioFondi.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiRipartizionePremioFondi)));

