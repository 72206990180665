export const areaLogin = {
  user: '',
  password: '',
  userpassword: '',
};

export const areaProdotto = [{
  area: {
    codice: '',
    dataInizioVal: null,
    dataFineVal: null,
    title: '',
    dataInserimento: null,
    dataVariazione: null,
    image: '',
  },
  prodotti: [{
    codice: '',
    dataInizioVal: null,
    dataFineVal: null,
    title: '',
    dataInserimento: null,
    dataVariazione: null,
    tipo: '',
  }],
}];

export const loginUser = {
  codConsulente: '',
  cognome: '',
  dataScadenzaPwd: null,
  dataUltAccesso: null,
  descrMandato: '',
  email: '',
  gruppo: '',
  mandato: '',
  nome: '',
  numAccessi: 0,
  pk: 0,
  profilo: '',
  pwd: '',
  stato: '',
  user: '',
};
