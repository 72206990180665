import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoFigura from '../sezione/TabDatiRiepilogoFigura';
import DatiGeneraliBenefGenerico from '../sezione/DatiGeneraliBenefGenerico';

import ButtonComp from '../../common/ButtonComp';

import { toNumber } from '../../../utility/genericUtility';
import { calcolaPercBenfTotale, getPercBenefByCodFiscInList } from './figureUtils/figureUtils';
import { locale_it } from '../../../utility/jsConstants';

import * as actions from './action/beneficiarioVita';
import * as actionType from './actionType/beneficiarioVita';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA BenefVitaGenericoAss.mapStateToProps.state', state);
  return {
    benefVitaGenerico: state.areaBenefVitaReducer.benefVitaGenerico,
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList,
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,
    salvaBenefVitaGenericoSuccess: state.areaBenefMorteReducer.salvaBenefVitaGenericoSuccess,
  };
};

const mapDispatchToProps = dispatch => {
//  console.log('#RTCFA BenefVitaGenericoAss.mapDispatchToProps.dispatch', dispatch);
  return {
//    aggiungiBenefVitaGenerico: (benefVitaGenerico) => dispatch(actions.aggiungiBenefVitaGenerico(benefVitaGenerico)),
    confermaAggiungiBeneficiarioVitaGenerico: (currBenefVitaGenerico) => dispatch(actions.confermaAggiungiBeneficiarioVitaGenerico(currBenefVitaGenerico)),
    updateDatiBeneficiarioVita: (unDato, unActionType) => dispatch(actions.updateDatiBeneficiarioVita(unDato, unActionType)),
    updateBeneficiarioVitaGenerico: (unDato, unActionType) => dispatch(actions.updateBeneficiarioVitaGenerico(unDato, unActionType)),
  };
};
	
class BenefVitaGenericoAss extends React.Component {

  constructor(props) {
    super(props);
//    console.log('#RTCFA BenefVitaGenericoAss constructor props', this.props);
    this.state = {
  	  percentualeTotale: 0,
   	  isConfermaBenef: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    }
  };
  
  handleClickConfermaGenerico = event => {
//    console.log('#RTCFA BenefVitaGenericoAss handleClickConfermaGenerico', event.target.value);
    this.props.updateDatiBeneficiarioVita(true, actionType.SALVA_BENEF_VITA_GENERICO);
    this.props.updateDatiBeneficiarioVita(false, actionType.SALVA_BENEF_VITA_GENERICO_SUCCESS);    
    this.setState({
   	  isConfermaBenef: true,
    });
  }
  
  confermaBeneficiario = () => {
    const currBenef = this.props.benefVitaGenerico;
    const percTotaleFigure = calcolaPercBenfTotale(this.props.benefVitaFisicoList, this.props.benefVitaGiuridicoList, this.props.benefVitaGenericoList, locale_it);
    let percTotale = percTotaleFigure;
    if (currBenef.anagraficaValida === true && this.state.isConfermaBenef) {
      let percBenefInLista = 0;
      let percBenefCorrente = 0;
      let isNuovo = false;
      // Trova per Codice Fiscale
      percBenefInLista = getPercBenefByCodFiscInList(this.props.benefVitaGenericoList, currBenef, locale_it);
      // Edit figura corrente
      if (percBenefInLista === undefined) {
        isNuovo = true;
        percBenefInLista = 0;
      }
      percBenefCorrente = toNumber(currBenef.datiGenerali.percBeneficiario, locale_it);		
      if (percBenefCorrente !== undefined && percBenefCorrente > 0) {
    	// 2022-01-06 PFD: ricalcolo il totale con arrotondamento alla seconda cifra decimale
          //percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista);
          percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista).round(2);
        if (percTotale <= 100.0) {		
          // INSERIMENTO NUOVO BENEFICIARIO
          if (isNuovo === true) {
            this.props.confermaAggiungiBeneficiarioVitaGenerico(currBenef);
          } else {
            // MODIFICA BENEFICIARIO
            this.props.updateBeneficiarioVitaGenerico(currBenef, actionType.UPDATE_BENEFICIARIO_VITA_GENERICO);
          }
          // Aggiorno la percentuale
          this.props.updateDatiBeneficiarioVita(percTotale, actionType.UPDATE_BENEF_VITA_PERCENTUALE_TOTALE);
          this.props.updateDatiBeneficiarioVita(true, actionType.SALVA_BENEF_VITA_GENERICO_SUCCESS);
        }		
      }
    }	
    this.setState({
      isConfermaBenef : false,
      percentualeTotale : percTotale,
    });
  } 
  
  initStatoCampi = () => {
    return [ {id: 'datiGeneraliBenefVitaGenerico', isError: false},
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  render() {
//	console.log('#RTCFA BenefVitaGenericoAss.render this.props', this.props);
    const { classes, t, benefVitaGenerico, salvaBenefVitaGenericoSuccess } = this.props;
    const drawAggiungi = this.props.benefVitaGenericoList!==undefined && this.props.benefVitaGenericoList!==null && this.props.benefVitaGenericoList.length===0 ;
    const percentualeTotaleError = this.state.percentualeTotale!==0 && parseFloat(this.state.percentualeTotale)!==100.0;
    const controlloDati = this.props.controlloDati;
    return (
      <Fragment>
        <TabDatiRiepilogoFigura figura={'beneficiarioVita'} tabHeader={'tabHeaderBenVita'} />
        <DatiGeneraliBenefGenerico figura={'beneficiarioVitaGenerico'} datiGeneraliBenefVitaGenerico={benefVitaGenerico.datiGenerali} labelFieldSet='datiGenerali'
                                   id={'datiGeneraliBenefVitaGenerico'} onError={this.handleError} />
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center' >            	
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && percentualeTotaleError && 
                <Typography className={classes.textErrorMessage}>{t('errTotPercBenef')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) && (salvaBenefVitaGenericoSuccess === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
              </Grid>
            </Grid>
          </Grid>   	        
          <Grid item>
            <ButtonComp
              aClassName={classes.button}
              buttonLabel={t('conferma')}
              onClick={this.handleClickConfermaGenerico}
            />
          </Grid>
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########BenefVitaGenericoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefVitaGenericoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefVitaGenericoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore && (this.props.controlloDati !== '')) {
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiBeneficiarioVita(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GENERICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
	return isPresenteCampiConErrore;
  }
  
  isConfermaBenef = (prevProps, prevState, isPresenteCampiConErrore) => {   
	//const currIsPresenteErrore = this.state.isPresenteErrore;
    const currIsPresenteErrore = isPresenteCampiConErrore;
    const currIsConferma =  this.state.isConfermaBenef;	
    const prevIsPresenteErrore = prevState.isPresenteErrore;
    const prevIsConferma =  prevState.isConfermaBenef;	
    if (currIsPresenteErrore !== undefined && currIsPresenteErrore !== null && currIsPresenteErrore === false) {	
      if (currIsConferma) {
        this.confermaBeneficiario();
      }
    }	
    if (currIsConferma !== prevIsConferma) {
      this.setState({isConfermaBenef: false,})
    }	
  }
	  
  componentDidUpdate(prevProps, prevState) {
	const isPresenteCampiConErrore = this.isInError(prevProps, prevState);
    this.isConfermaBenef(prevProps, prevState, isPresenteCampiConErrore);
  }
}

BenefVitaGenericoAss.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BenefVitaGenericoAss)));
