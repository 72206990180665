import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DividerComp from '../../common/DividerComp';

import DatiResidenza from './DatiResidenza';
import DatiAntiriciclaggio from './DatiAntiriciclaggio';
import DatiGeneraliMinimi from './DatiGeneraliMinimi';

import { RAPPRESENTANTE_LEGALE } from '../../../utility/jsConstants';

import * as actions from '../figure/action/contraente';
import * as actionType from '../figure/actionType/contraente';
import DatiAttivitaEconomica from './DatiAttivitaEconomica';
import DatiDomicilio from './DatiDomicilio';

const styles = theme => ({
  textField: theme.textField,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale,
  };
};

const mapDispatchToProps = dispatch => {
  return {
	updateDatiRappLegale: (unDato, unActionType, figura) => dispatch(actions.updateDatiRappLegale(unDato, unActionType, figura)),
  };
};
	
class DatiRappLegale extends React.Component {
	
  constructor(props) {
	super(props);
    this.state = {
//    datiRappLegale: this.props.datiRappLegale,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,   
    };
  };

  initStatoCampi = () => {
    return [ {id: 'datiGeneraliMinimiRappLegale', isError: false},
             {id: 'datiResidenzaRappLegale', isError: false},  
             {id: 'datiAntiriciclaggioRappLegale', isError: false},
             {id: 'datiDomicilioRappLegale', isError: false},
             {id: 'datiAttivitaEconomicaRappLegale', isError: false},
           ];
  }
	  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
		  
  render() {
    const { classes, datiRappLegale, dominio } = this.props;      
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="h6">{t('esecutore')}</Typography></legend>*/}
        <DividerComp titolo='rappresentanteLegale' variant='subtitle1'/>
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <DatiGeneraliMinimi figura={'rappLegale'} datiGeneraliMinimi={datiRappLegale.datiGeneraliMinimi} id='datiGeneraliMinimiRappLegale' onError={this.handleError} />
          <DatiResidenza figura={'rappLegale'} datiResidenzaRappLegale={datiRappLegale.datiResidenza} id={'datiResidenzaRappLegale'} onError={this.handleError} />
          <DatiDomicilio figura={'rappLegale'} id={'datiDomicilioRappLegale'} onError={this.handleError} 
                         labelFieldSet={'datiDiDomicilioRappLegale'}/>
          <DatiAntiriciclaggio figura={'rappLegale'} datiAntiriciglaggio={datiRappLegale.datiAntiriciglaggio} 
                               labelFieldSet='datiAntiriciclaggioRappLegale' id={'datiAntiriciclaggioRappLegale'} onError={this.handleError} />
          <DatiAttivitaEconomica figura={'rappLegale'} datiAttEconomica={datiRappLegale.datiAttEconomica} labelFieldSet='datiAttivitaEconomicaRappLegale' 
                                 id={'datiAttivitaEconomicaRappLegale'} onError={this.handleError} />  
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########BenefMorteFisicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefMorteFisicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefMorteFisicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	    
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }    
      this.props.updateDatiRappLegale(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_RAPP_LEGALE, RAPPRESENTANTE_LEGALE);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }
}

DatiRappLegale.propTypes = {
  classes: PropTypes.object.isRequired,
  dominio: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiRappLegale));
