export const UPDATE_BANCA_BONIFICO_ESTERO                           = 'UPDATE_BANCA_BONIFICO_ESTERO';
export const UPDATE_AGENZIA_BONIFICO_ESTERO                         = 'UPDATE_AGENZIA_BONIFICO_ESTERO';
export const UPDATE_N_SOTTORUBRICA_BONIFICO_ESTERO                  = 'UPDATE_N_SOTTORUBRICA_BONIFICO_ESTERO';
export const UPDATE_INTESTATARIO_BONIFICO_ESTERO                    = 'UPDATE_INTESTATARIO_BONIFICO_ESTERO';
export const UPDATE_PAESE_BONIFICO_ESTERO                           = 'UPDATE_PAESE_BONIFICO_ESTERO';
export const UPDATE_CODICE_SWIFT_BONIFICO_ESTERO                    = 'UPDATE_CODICE_SWIFT_BONIFICO_ESTERO';
export const UPDATE_MOTIVO_CONTO_ESTERO_BONIFICO                    = 'UPDATE_MOTIVO_CONTO_ESTERO_BONIFICO';
export const UPDATE_CONTO_COINTESTATO_BONIFICO_ESTERO               = 'UPDATE_CONTO_COINTESTATO_BONIFICO_ESTERO';
export const UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO_ESTERO     = 'UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO_ESTERO';
export const UPDATE_RELAZ_PAGAT_CONTR_BONIFICO_ESTERO               = 'UPDATE_RELAZ_PAGAT_CONTR_BONIFICO_ESTERO';
export const UPDATE_ALTRA_RELAZ_BONIFICO_ESTERO                     = 'UPDATE_ALTRA_RELAZ_BONIFICO_ESTERO';