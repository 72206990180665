import * as actionType from '../actionType/generic';

export const inizializza = () => {
  return {
    type: actionType.INIZIALIZZA,
  }
};

export const setGeneric = (unDato, unaLabel, unActionType) => {
  return {
    type: unActionType,
    unDato,
    unaLabel,
  }
};

export const selectScopeAmleto = (unDato) => {
  return {
    type: actionType.SELECT_SCOPE_AMLETO,
    unDato
  }
};