import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link as CoreLink } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import TextFieldAdornComp from '../../common/TextFieldAdornComp';

import ButtonComp from '../../common/ButtonComp';
import { textTrim } from '../../../utility/genericUtility';

import * as actions from './action/datiPasswordDimenticata';
import * as actionType from './actionType/datiPasswordDimenticata';

import { areaLogin } from '../../store/areaLogin';
import Login from '../../login/Login';

import { uris, fetchParam } from '../../../uris';

const styles = theme => ({
  root: {
    marginTop: 200,
  },
  button: theme.button,
  textField: theme.textField,
  link: {
	color: theme.palette.color.greyLight,
    margin: theme.spacing(1),
    textDecoration: 'underline',
    '&:hover': {  
  	  color: theme.palette.color.windowsBlue,
      textDecoration: 'underline',
    },
  },
  textSmall: {
	fontSize: 15,
	marginLeft: theme.spacing(0.6),
  },
  appName: {
    fontSize: theme.typography.fontSize*3.3, 
	color: theme.palette.color.marine,
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*2, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*1.5, 
      },
    marginRight: theme.spacing(2),
  },
  appNameCorsivo: {
    fontSize: theme.typography.fontSize*3, 
	color: theme.palette.color.windowsBlue,
	fontStyle: 'italic',
	[theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.fontSize*1.7, 
      },
	[theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSize*1.3, 
      },
  },
  marginLeft: {
	marginLeft: theme.spacing(1.5),
	color: theme.palette.color.marine,
  },
  textFieldLogin: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1), 
	height: 48,	
	width: '97%',
	marginTop: theme.spacing(0),
  },
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	datiPasswordDimenticata: state.areaDatiPasswordDimenticataReducer.gestioneUtenza.datiPasswordDimenticata,
  };
};
	
const mapDispatchToProps = dispatch => {
  return {
	updateDatiPasswordDimenticata: (unDato, unActionType) => dispatch(actions.updateDatiPasswordDimenticata(unDato, unActionType)),
	salvaDatiPasswordDimenticata: (controlloDati) => dispatch(actions.salvaDatiPasswordDimenticata(controlloDati)),
	inviaDatiPasswordDimenticata: (email) => dispatch(actions.inviaDatiPasswordDimenticata(email)),
  };
};

class DatiPasswordDimenticata extends React.Component {

  constructor(props) {
    super(props);
    const datiPasswordDimenticata = this.props.datiPasswordDimenticata;
    this.state = {
      email: datiPasswordDimenticata.email,
      codiceErrore: '',
      mostraLogin: 'N',
    };    
  };
    
  handleChangeEmail = event => {
    const nuovoEmail = textTrim(event.target.value, true);
	const vecchioEmail = textTrim(this.state.email, true);
	if(nuovoEmail !== vecchioEmail) {
	  this.props.updateDatiPasswordDimenticata(event.target.value, actionType.UPDATE_EMAIL_PASSWORD_DIMENTICATA);   
	  this.setState({
	    email: event.target.value, 
      });
	}
  };
	  
  handleChangeInviaDatiPasswordDimenticata = (email) => event => {
    this.props.salvaDatiPasswordDimenticata(true, actionType.SALVA_DATI_PASSWORD_DIMENTICATA);
	if(this.state.email.trim() !== ''){
	  this.setInviaEmail(email);
	}
  }  
  
  setInviaEmail = (email) => {
    const opts = {
      email: email.trim(),
	};
	fetch(uris.recuperoCredenzialiItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const codiceErrore = result.codiceErrore.trim();  
	  this.setState({
	    codiceErrore: codiceErrore,
	    mostraLogin: 'N',
	  });
	  if(codiceErrore===''){
	    this.props.inviaDatiPasswordDimenticata(email.trim());
		this.setState({
		  email: email.trim(),
		  codiceErrore: codiceErrore,
		  mostraLogin: 'S',
		});
	  }
	});
  }
	  
  isRequiredEmail = () => {
    return true;
  }
   
  render() {
	const { classes, datiPasswordDimenticata } = this.props;	 
    const { t } = this.props; //hoc	
    const controlloDati = datiPasswordDimenticata.controlloDati;
    
    return (
      <Fragment>
        {this.state.mostraLogin==='N' &&
          <Fragment>
	        <div style={{height: '120px'}} />
		    <div align='center'>
		      <div align='center' style={{width: '50%'}}>
			    <Typography display='inline' className={classes.appName}>{'ZURICH'}</Typography>
				<Typography display='inline' className={classes.appNameCorsivo}>{'Semplifico'}</Typography>  
				<Typography variant="h6">{t('passwordDimenticata')}</Typography>
		      </div>
			</div>
			<Grid container direction='row' justify='flex-start' alignItems='flex-start'> 
			  <Grid item xs={3}></Grid>
		      <Grid item xs={9}><Typography variant="caption" className={classes.marginLeft}>{t('tuaEmailUtente')}</Typography></Grid>
		        
		      <Grid item xs={3}></Grid>
		      <Grid item xs={6}>
		        <div className={classes.middle}>
		          <TextFieldAdornComp onBlur={this.handleChangeEmail} id='email' label='indEmail' value={datiPasswordDimenticata.email} aStyle={classes.textFieldLogin} margin='normal' variant='outlined' 
		             adornComp={ <AlternateEmail />} adornPosit='end' required={this.isRequiredEmail()} controlloDati={controlloDati} />
		        </div>      
		      </Grid>
		      <Grid item xs={3}></Grid>
		          
		      <Grid item xs={3}></Grid>
		      <Grid item xs={9}>
		        <Typography className={classes.textSmall}>
		          <CoreLink href='/' className={classes.link}>{t('tornaLogin')}</CoreLink>
		        </Typography>
		      </Grid>
		        		        
		    </Grid>
		
		    <div align='center'>
		      {this.state.codiceErrore!=='' &&
		        <Grid container direction="row" justify="center" alignItems="center">	
		          <Grid item xs={12}>&nbsp;</Grid>    
		          <Grid item xs={12}>
		  	        <Typography className={classes.textErrorMessage}>{t(this.state.codiceErrore)}</Typography>
		          </Grid>
		        </Grid>
		       }
		      <Grid container direction='row' justify='center' alignItems='flex-start'>
		        <Grid item xs={12}>&nbsp;</Grid>
		        <Grid item xs={12}> 
		          <ButtonComp
	                aClassName={classes.button}
	                buttonLabel={'invia'}
		            onClick={this.handleChangeInviaDatiPasswordDimenticata(datiPasswordDimenticata.email)}
	              />
		        </Grid> 
		      </Grid>
		    </div>
		  </Fragment>
        }
	    {this.state.mostraLogin==='S' &&
	      <Login areaLogin={areaLogin} codiceErroreLogin='' messaggioOk='operazioneOk' />
	    }
      </Fragment>
    );
  }
}

DatiPasswordDimenticata.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles, { withTheme: true })(DatiPasswordDimenticata)));