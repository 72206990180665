import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import BenefVitaPanel from './BenefVitaPanel';
import BenefMortePanel from './BenefMortePanel';
import ReferenteTerzoPanel from './ReferenteTerzoPanel';
import TitolareEffPanel from './TitolareEffPanel';

import { RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, REFERENTE_TERZO,
	     BENEFICIARIO_VITA, BENEFICIARIO_MORTE, TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA, CODICE_QUALIFICA_FIDUCIANTE, CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, CODICE_SAE_FIDUCIARIA_GESTIONE} from '../../../utility/jsConstants';
import { PERSONA_GENERICA } from '../../../utility/jsConstants';

import { showSezione } from '../../../utility/genericUtility';
import { benefMorteTabItems, benefVitaTabItems } from '../../store/navTabProposalItems';
import TitolareEffBenefMortePanel from './TitolareEffBenefMortePanel';
import TitolareEffBenefVitaPanel from './TitolareEffBenefVitaPanel';
import { errorFiducianteVita, errorFiducianteMorte } from '../../../utility/storeUtility';

const styles = theme => ({
  root: {	  
/*	 marginTop: 200, 
	 marginLeft: 100,
	 marginRight: 100, */
  },
  heading: {
	color: theme.palette.color.marine,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: theme.button,
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	percBenMorteTotale: state.areaBenefMorteReducer.percBenMorteTotale,
	controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
	controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
  controlloDatiBenefMorteGenerico: state.areaBenefMorteReducer.benefMorteGenerico.controlloDati,
  benefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico,
  benefMorteSae: state.areaBenefMorteReducer.benefMorteGiuridico.datiAttEconomica.codSAE,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,
    
	percBenVitaTotale: state.areaBenefVitaReducer.percBenVitaTotale,
	controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,
	controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
  controlloDatiBenefVitaGenerico: state.areaBenefVitaReducer.benefVitaGenerico.controlloDati,
  benefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico,
  benefVitaSae: state.areaBenefVitaReducer.benefVitaGiuridico.datiAttEconomica.codSAE,
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList,
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,
    
	controlloDatiReferenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico.controlloDati,
	referenteTerzoFisicoValida: state.areaReferenteTerzoReducer.referenteTerzoFisico.anagraficaValida,
  flTitolareEffBenef: state.areaBenefMorteReducer.benefMorteGiuridico.flTitolareEffBenef,
  flTitolareEffBenefVita: state.areaBenefVitaReducer.benefVitaGiuridico.flTitolareEffBenefVita,

  controlloDatiTitEffBenefVita: state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
  titEffBenefVitaValida: state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVita.anagraficaValida,
  titolareBenefVitaEff: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita,
  titolareEffBenefVitaList: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVitaList,
  
  controlloDatiTitEffBenefMorte: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
  titEffBenefMorteValida: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte.anagraficaValida,
  titolareEffBenefMorteList: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorteList,
  titolareBenefMorteEff: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte,

  };
};
	
class AltreFigureProposal extends React.Component {
  constructor(props) {
    super(props);   
    const items = this.props.dominio.ruoloXProdottoItems.map((item) => {
		                                                       return (item.value)
                                                            });
    this.state = {
      beneficiarioMorteShow : showSezione(items, RUOLO_BENEF_MORTE),
      beneficiarioVitaShow : showSezione(items, RUOLO_BENEF_VITA),
    };
  }

  getIsValida = (unaFigura) => {
    let isValida = null;
    if (unaFigura === BENEFICIARIO_MORTE) {
      // se ho premuto conferma
      const controlloDati = this.props.controlloDatiBenefMorteFisico !== '' || 
       					  this.props.controlloDatiBenefMorteGiuridico !== '' || 
       					  this.props.controlloDatiBenefMorteGenerico !== '';    
      if (controlloDati) {
        const percBenMorteTotale = this.props.percBenMorteTotale;
        isValida = percBenMorteTotale === 100.0;
      }
    } else if (unaFigura === BENEFICIARIO_VITA) {
    	// se ho premuto conferma
      const controlloDati = this.props.controlloDatiBenefVitaFisico !== '' || 
	   		   	          this.props.controlloDatiBenefVitaGiuridico !== '' || 
                          this.props.controlloDatiBenefVitaGenerico !== '';     
      if (controlloDati) {
        const percBenVitaTotale = this.props.percBenVitaTotale;
        isValida = percBenVitaTotale === 100.0;
      }
    } else if (unaFigura === REFERENTE_TERZO) {
      if (this.props.controlloDatiReferenteTerzoFisico !== '') {
        isValida = this.props.referenteTerzoFisicoValida;
      }    	
    } else if (unaFigura === TITOLARE_EFF_BENEF_VITA) {
      if(this.props.controlloDatiTitEffBenefVita) {
        isValida = this.props.titEffBenefVitaValida && !errorFiducianteVita(
          this.props.benefVitaSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.benefVitaSae === CODICE_SAE_FIDUCIARIA_GESTIONE
        );
      }
    } else if(unaFigura === TITOLARE_EFF_BENEF_MORTE) {
      if(this.props.controlloDatiTitEffBenefMorte) {
        isValida = this.props.titEffBenefMorteValida && !errorFiducianteMorte(
          this.props.benefMorteSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.benefMorteSae === CODICE_SAE_FIDUCIARIA_GESTIONE
        );
      }
    }
    return isValida;
  }

  /* errorFiducianteMorte = () => {
    const isFiduciaria = this.props.benefMorteSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.benefMorteSae === CODICE_SAE_FIDUCIARIA_GESTIONE;
    const currentTit = this.props.titolareBenefMorteEff;
    const titList = this.props.titolareEffBenefMorteList;
    let isFiducianteCurrent = false;
    let isPresenteFiducianteInList = false;
    if(currentTit.datiTitolareBenefMorteEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
      isFiducianteCurrent = true;
    }
    if(titList.length > 0 && titList[0].datiTitolareBenefMorteEff) {
      titList.map((el, i) => {
        if(el.datiTitolareBenefMorteEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
          isPresenteFiducianteInList = true;
        }
      });
    }
    let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
    if(!isFiduciaria) {
      return false;
    }
    return !presente;
  }

  errorFiducianteVita = () => {
    const isFiduciaria = this.props.benefVitaSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.benefVitaSae === CODICE_SAE_FIDUCIARIA_GESTIONE;
    const currentTit = this.props.titolareBenefVitaEff;
    const titList = this.props.titolareEffBenefVitaList;
    let isFiducianteCurrent = false;
    let isPresenteFiducianteInList = false;
    if(currentTit.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
      isFiducianteCurrent = true;
    }
    if(titList.length > 0 && titList[0].datiTitolareBenefVitaEff) {
      titList.map((el, i) => {
        if(el.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
          isPresenteFiducianteInList = true;
        }
      });
    }
    let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
    if(!isFiduciaria) {
      return false;
    }
    return !presente;
  } */
  
  getTabList(ruolo) {
	let tabList = [];
	let tabListEnabled = null;
	let tabListDisabled = null;	
    if (ruolo === BENEFICIARIO_MORTE) {
      if ((this.props.benefMorteFisicoList.length > 0) || (this.props.benefMorteGiuridicoList.length > 0)) {
        tabListEnabled = benefMorteTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_GENERICA));
        tabListDisabled = benefMorteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GENERICA)); 
        tabList = [{ ...tabListEnabled[0], disabled: false }, { ...tabListEnabled[1], disabled: false }, { ...tabListDisabled[0], disabled: true }];        
      } else if (this.props.benefMorteGenericoList.length > 0) {
        tabListEnabled = benefMorteTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GENERICA));
        tabListDisabled = benefMorteTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_GENERICA)); 
        tabList = [{ ...tabListDisabled[0], disabled: true }, { ...tabListDisabled[1], disabled: true }, { ...tabListEnabled[0], disabled: false }];        
      } else {
        tabList = [{ ...benefMorteTabItems[0], disabled: false }, { ...benefMorteTabItems[1], disabled: false }, { ...benefMorteTabItems[2], disabled: false }]; 	 
      }
    } else if (ruolo === BENEFICIARIO_VITA) {
      if ((this.props.benefVitaFisicoList.length > 0) || (this.props.benefVitaGiuridicoList.length > 0)) {
        tabListEnabled = benefVitaTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_GENERICA));
        tabListDisabled = benefVitaTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GENERICA)); 
        tabList = [{ ...tabListEnabled[0], disabled: false }, { ...tabListEnabled[1], disabled: false }, { ...tabListDisabled[0], disabled: true }];        
      } else if (this.props.benefVitaGenericoList.length > 0) {
        tabListEnabled = benefVitaTabItems.filter(unTab => (unTab.tipoPers === PERSONA_GENERICA));
        tabListDisabled = benefVitaTabItems.filter(unTab => (unTab.tipoPers !== PERSONA_GENERICA)); 
        tabList = [{ ...tabListDisabled[0], disabled: true }, { ...tabListDisabled[1], disabled: true }, { ...tabListEnabled[0], disabled: false }];        
      } else {
        tabList = [{ ...benefVitaTabItems[0], disabled: false }, { ...benefVitaTabItems[1], disabled: false }, { ...benefVitaTabItems[2], disabled: false }]; 	 
      }
    } 
    return tabList;
  }

  isDisabledMorte = () => {
    let disabled = true;
    if(this.props.benefMorteGiuridico.anagraficaValida) {
      disabled = false;
    }
    return disabled;
  }

  isDisabledVita = () => {
    let disabled = true;
    if(this.props.benefVitaGiuridico.anagraficaValida) {
      disabled = false;
    }
    return disabled;
  }
  
  render () {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.beneficiarioVitaShow &&
          <BenefVitaPanel isValida={this.getIsValida(BENEFICIARIO_VITA)} tabList={this.getTabList(BENEFICIARIO_VITA)} />
        }
        {this.state.beneficiarioMorteShow &&
          <Fragment>
            <BenefMortePanel isValida={this.getIsValida(BENEFICIARIO_MORTE)} tabList={this.getTabList(BENEFICIARIO_MORTE)} />
            {(this.props.flTitolareEffBenef === 'S') &&
              <TitolareEffBenefMortePanel isValida={this.getIsValida(TITOLARE_EFF_BENEF_MORTE)} isDisabled={this.isDisabledMorte()} />        	       	  
            }
            {(this.props.flTitolareEffBenefVita === 'S') &&
              <TitolareEffBenefVitaPanel isValida={this.getIsValida(TITOLARE_EFF_BENEF_VITA)} isDisabled={this.isDisabledVita()} />        	       	  
            }
            <ReferenteTerzoPanel isValida={this.getIsValida(REFERENTE_TERZO)} />
            
          </Fragment>
        }
      </div>
    );
  }
}

AltreFigureProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(AltreFigureProposal));