/*import HealingRounded from '@material-ui/icons/HealingRounded';
import ChildCareRounded from '@material-ui/icons/ChildCareRounded';
import SchoolRounded from '@material-ui/icons/SchoolRounded';
import HotTubRounded from '@material-ui/icons/HotTubRounded';
import PetsRounded from '@material-ui/icons/PetsRounded';
import Error from '@material-ui/icons/Error'; */

import { STEP_CONTRAENTE_ASSICURATO, STEP_ALTRE_FIGURE, STEP_CARATTERISTICHE_CONTRATTO, 
         STEP_CONSENSI, STEP_QUESTIONARI, STEP_STAMPA_PROPOSTA } from '../../utility/jsConstants';
         
export const propostaStepperItems = {
    slidePage: [{
    	/*    	tab: [{
            titolo: 'contraenteAssicurato', img: HealingRounded, id: 1, link: '/linkContraenteAssicurato',        
        }, {  
        	titolo: 'altreFigure', img: ChildCareRounded, id: 2, link: '/linkAltreFigure',        	
        }, {
            titolo: 'caratteristicheContratto', img: SchoolRounded, id: 3, link: '/linkCaratteristicheContratto',
        }, {
            titolo: 'dichiarazioniConsensi', img: HotTubRounded, id: 4, link: '/linkDichiarazioniConsensi',
        }, {
        	titolo: 'questionari', img: PetsRounded, id: 5, link: '/linkQuestionari',
        }, {
            titolo: 'stampaProposta', img: HotTubRounded, id: 6, link: '/linkStampaProposta',
        }], */   
        tab: [{
            titolo: 'contraenteAssicurato', type: STEP_CONTRAENTE_ASSICURATO, img: '/images/generic/stepper/contraente_assicurato.svg', id: 1, link: '/linkContraenteAssicurato',        
          }, {  
          	titolo: 'altreFigure', type: STEP_ALTRE_FIGURE, img: '/images/generic/stepper/altre_figure.svg', id: 2, link: '/linkAltreFigure',        	
          }, {
            titolo: 'caratteristicheContratto', type: STEP_CARATTERISTICHE_CONTRATTO, img: '/images/generic/stepper/caratteristiche_contratto.svg', id: 3, link: '/linkCaratteristicheContratto',
          }, {
            titolo: 'dichiarazioniConsensi', type: STEP_CONSENSI, img: '/images/generic/stepper/dichiarazioni.svg', id: 4, link: '/linkDichiarazioniConsensi',
          }, {
          	titolo: 'questionari', type: STEP_QUESTIONARI, img: '/images/generic/stepper/questionari.svg', id: 5, link: '/linkQuestionari',
          }, {
            titolo: 'stampaProposta', type: STEP_STAMPA_PROPOSTA, img: '/images/generic/stepper/stampa_proposta.svg', id: 6, link: '/linkStampaProposta',
        }],         
    }],
};