import { datiGeneraliBenefGiuridico, datiResidenza, datiRelazContr, datiCopiaDaAltraFigura, datiSocietari, datiMandatoFiduciario, datiTitolareBenefMorteEff, titolareEffBenefMorteList, datiAttEconomica, titolareEffBenefMorteListInBenef } from './sezioni/sezioniAnagraficaBenefMorteGiuridico';
import { RUOLO_BENEF_MORTE, PERSONA_GIURIDICA } from '../../utility/jsConstants';
import { titolareEffBenefMorte } from './titolareEffBenefMorte';


export const benefMorteGiuridico = {
  controlloDati: '',
  anagraficaValida: null,
  datiFigureAnagrBase: {
	ruolo: RUOLO_BENEF_MORTE,
	tipoPers: PERSONA_GIURIDICA,
  },
  datiGeneraliBenefGiuridico: datiGeneraliBenefGiuridico,
  datiResidenza: datiResidenza,
  datiRelazContr: datiRelazContr,
  datiCopiaDaAltraFigura: datiCopiaDaAltraFigura,
  datiAttEconomica: datiAttEconomica,
  datiSocietari: datiSocietari,
  datiMandatoFiduciario: datiMandatoFiduciario,
  flTitolareEffBenef: '',
  titolareEffBenefMorte: titolareEffBenefMorte,
  titolareEffBenefMorteList: titolareEffBenefMorteList,
  titolareEffBenefMorteListInBenef: titolareEffBenefMorteListInBenef
};

export const benefMorteGiuridicoList = [
//  benefMorteGiuridico,
];
