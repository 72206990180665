import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as actions from './action/datiModPagAltro';
import * as actionType from './actionType/datiModPagAltro';
import Grid from '@material-ui/core/Grid';
import TextFieldComp from '../../../common/TextFieldComp';
import { textTrim } from '../../../../utility/genericUtility';

const styles = theme => ({
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError
});

const mapStateToProps = state => {
    return {
        datiModPagAltro: state.areaDatiModPagAltroReducer.caratteristicheContratto.datiModPagAltro
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateDatiModPagAltro: (unDato, unActionType) => dispatch(actions.updateDatiModPagAltro(unDato, unActionType))
    };
};

class DatiModPagAltro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            altro: '',
            statoCampi: this.initStatoCampi(),
            isPresenteErrore: null
        }
    }

    handleChangeAltro = event => {
      const nuovoAltro = textTrim(event.target.value, true);
      const vecchioAltro = textTrim(this.state.altro, true); 
      if (nuovoAltro !== vecchioAltro) {	  
        this.props.updateDatiModPagAltro(nuovoAltro, actionType.UPDATE_ALTRO);   
        this.setState({ 
          altro: nuovoAltro 
        });
      }  
    }

    initStatoCampi = () => {
        return [
            {is: "altro", isError: false}
        ]
    }

    handleError = (id, isError) => {   
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
          unErrore = campoOnId[0];
          unErrore.isError = isError;
        } else {
          unErrore = { id: id, isError: isError };
        }
        this.setState({	
          statoCampi: [...campoWithoutId, unErrore],      
        });
      }    

    render() {
        const { classes, controlloDati, datiModPagAltro } = this.props;
        return (
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={12}>
                    <TextFieldComp onBlur={this.handleChangeAltro} aStyle={classes.textFieldWidthPerc} id='altro' label='modPagAltro' value={datiModPagAltro.altro}
                        required={true} controlloDati={controlloDati} onError={this.handleError} aStyleError={classes.textFieldWidthPercError} />
                </Grid>
            </Grid>
        )
    }

isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiModPagAssegno componentDidUpdate this.state.statoCampi', this.state.statoCampi);
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
    
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }    
}

DatiModPagAltro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiModPagAltro)));
