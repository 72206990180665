import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiIntermediarioComp from './intermediario/DatiIntermediarioComp';

const styles = theme => ({
  root: {
    width: '100%',
  },
/*  heading: {
	color: theme.palette.color.marine,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },*/
});

class DichIntermediarioPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  };
  
  render () {	
    const { classes, isValida } = this.props;
    return (
      <div className={classes.root}> 
        <ExpansionPanelComp titolo={'dichiarazioneIntermediario'} componente={DatiIntermediarioComp} isValida={isValida} />
      </div>
    );
  }
}

DichIntermediarioPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DichIntermediarioPanel);
