import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoFigura from '../sezione/TabDatiRiepilogoFigura';
import DatiGeneraliBenefGenerico from '../sezione/DatiGeneraliBenefGenerico';

import ButtonComp from '../../common/ButtonComp';

import { toNumber } from '../../../utility/genericUtility';
import { calcolaPercBenfTotale, getPercBenefByCodFiscInList } from './figureUtils/figureUtils';

import { locale_it } from '../../../utility/jsConstants';

import * as actions from './action/beneficiarioMorte';
import * as actionType from './actionType/beneficiarioMorte';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
//  console.log('#RTCFA BenefMorteGenericoAss.mapStateToProps.state', state);
  return {
    benefMorteGenerico: state.areaBenefMorteReducer.benefMorteGenerico,
    controlloDati: state.areaBenefMorteReducer.benefMorteGenerico.controlloDati,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,   
    salvaBenefMorteGenericoSuccess: state.areaBenefMorteReducer.salvaBenefMorteGenericoSuccess,
  };
};

const mapDispatchToProps = dispatch => {
//  console.log('#RTCFA BenefMorteGenericoAss.mapDispatchToProps.dispatch', dispatch);
  return {
//  aggiungiBenefMorteGenerico: (benefMorteGenerico) => dispatch(actions.aggiungiBenefMorteGenerico(benefMorteGenerico)),
    confermaAggiungiBeneficiarioMorteGenerico: (currBenefMorteGenerico) => dispatch(actions.confermaAggiungiBeneficiarioMorteGenerico(currBenefMorteGenerico)),
    updateDatiBeneficiarioMorte: (unDato, unActionType) => dispatch(actions.updateDatiBeneficiarioMorte(unDato, unActionType)), 
    updateBeneficiarioMorteGenerico: (unDato, unActionType) => dispatch(actions.updateBeneficiarioMorteGenerico(unDato, unActionType)),
  };
};
	
class BenefMorteGenericoAss extends React.Component {

  constructor(props) {	
	super(props);
//	console.log('#RTCFA BenefMorteGenericoAss constructor props', this.props);
    this.state = {	
      percentualeTotale: 0,
      isConfermaBenef: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    }
  };
  
  handleClickConfermaGenerico = (event) => {
//    console.log('#RTCFA BenefMorteGenericoAss handleClickConfermaGenerico', event.target.value);
    this.props.updateDatiBeneficiarioMorte(true, actionType.SALVA_BENEF_MORTE_GENERICO);
    this.props.updateDatiBeneficiarioMorte(false, actionType.SALVA_BENEF_MORTE_GENERICO_SUCCESS);  
    this.setState({
      isConfermaBenef: true,
    });
  } 
  
  confermaBeneficiario = () => {
	const currBenef = this.props.benefMorteGenerico;
	const percTotaleFigure = calcolaPercBenfTotale(this.props.benefMorteFisicoList, this.props.benefMorteGiuridicoList, this.props.benefMorteGenericoList, locale_it);
	let percTotale = percTotaleFigure;
    if (currBenef.anagraficaValida === true && this.state.isConfermaBenef) {
      let percBenefCorrente = 0;
      let isNuovo = false;
      // Trova per Codice Fiscale
      let percBenefInLista = getPercBenefByCodFiscInList(this.props.benefMorteGenericoList, currBenef, locale_it);
      // Edit figura corrente
      if ((percBenefInLista === undefined) || (percBenefInLista === null)) {
        isNuovo = true;
        percBenefInLista = 0;
      }
      percBenefCorrente = toNumber(currBenef.datiGenerali.percBeneficiario, locale_it);
      if ((percBenefCorrente !== undefined) && (percBenefCorrente !== null) && (percBenefCorrente > 0)) {
    	// 2022-01-06 PFD: ricalcolo il totale con arrotondamento alla seconda cifra decimale
          //percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista);
          percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista).round(2);
        if (percTotale <= 100.0) {
          // INSERIMENTO NUOVO BENEFICIARIO
          if (isNuovo === true) {
            this.props.confermaAggiungiBeneficiarioMorteGenerico(currBenef);
          } else {
            // MODIFICA BENEFICIARIO
            this.props.updateBeneficiarioMorteGenerico(currBenef, actionType.UPDATE_BENEFICIARIO_MORTE_GENERICO);
          }
          // Aggiorno la percentuale
          this.props.updateDatiBeneficiarioMorte(percTotale, actionType.UPDATE_BENEF_MORTE_PERCENTUALE_TOTALE);
          this.props.updateDatiBeneficiarioMorte(true, actionType.SALVA_BENEF_MORTE_GENERICO_SUCCESS);
        }
	  }
	}
    this.setState({
      isConfermaBenef : false,
      percentualeTotale : percTotale,
	});
  }
  
  initStatoCampi = () => {
    return [ {id: 'datiGeneraliBenefGenericoMorte', isError: false},
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  };
	  
  render() {
	console.log('#RTCFA BenefMorteGenericoAss.render this.props', this.props);
    const { classes, t, benefMorteGenerico, salvaBenefMorteGenericoSuccess } = this.props;
    const percentualeTotaleError = (this.state.percentualeTotale !== 0) && (parseFloat(this.state.percentualeTotale) !== 100.0);
    const controlloDati = this.props.controlloDati;
    return (
      <Fragment>
        <TabDatiRiepilogoFigura figura={'beneficiarioMorte'} tabHeader={'tabHeaderBenMorte'} />
        <DatiGeneraliBenefGenerico figura={'beneficiarioMorteGenerico'} datiGeneraliBenefMorteGenerico={benefMorteGenerico.datiGenerali} labelFieldSet='datiGenerali'
                                   id={'datiGeneraliBenefGenericoMorte'} onError={this.handleError} />     
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center'>            	
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {percentualeTotaleError && 
                <Typography className={classes.textErrorMessage}>{t('errTotPercBenef')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) && (salvaBenefMorteGenericoSuccess === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ButtonComp
                /* to={linkTo} */
                /* component={Link} */
                aClassName={classes.button}
                buttonLabel={'conferma'}
                onClick={this.handleClickConfermaGenerico}
            />
          </Grid>			 
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########BenefMorteGenericoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefMorteGenericoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefMorteGenericoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDati !== '')) {
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiBeneficiarioMorte(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_GENERICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
	return isPresenteCampiConErrore;
  }
	  
  isConfermaBenef = (prevProps, prevState, isPresenteCampiConErrore) => {   
	//const currIsPresenteErrore = this.state.isPresenteErrore;
	const currIsPresenteErrore = isPresenteCampiConErrore;
	const currIsConferma =  this.state.isConfermaBenef;	
	const prevIsPresenteErrore = prevState.isPresenteErrore;
	const prevIsConferma =  prevState.isConfermaBenef;
	
	if (currIsPresenteErrore!==undefined && currIsPresenteErrore!==null && 
			currIsPresenteErrore===false ) {
	
		if (currIsConferma) {
			this.confermaBeneficiario();
		}
	}
	
	if (currIsConferma !== prevIsConferma) {
		this.setState({isConfermaBenef: false,})
	}
	
  }
  
  componentDidUpdate(prevProps, prevState) {
    const isPresenteCampiConErrore = this.isInError(prevProps, prevState);
    this.isConfermaBenef(prevProps, prevState, isPresenteCampiConErrore);
  }
}

BenefMorteGenericoAss.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BenefMorteGenericoAss)));
