import { jsonCopy } from '../../../utility/genericUtility';
import { datiGeneraliGiuridicoInizializzato, datiResidenzaInizializzato, datiRecapitoInizializzato, datiFiscaliInizializzato, 
         datiConsensoComElettrInizializzato, datiAttEconomicaInizializzato, datiNaturaRapportoInizializzato, 
         datiAttEconomicaGiuridicoInizializzato, datiGeneraliGenericoInizializzato, datiSocietariGiuridicoInizializzato, datiFinanziariGiuridicoInizializzato, datiMandatoFiduciarioInizializzato } from '../componentiBase/sezioniAnagrafica';

export const datiGeneraliGiuridico = jsonCopy(datiGeneraliGiuridicoInizializzato);
export const datiResidenza = jsonCopy(datiResidenzaInizializzato);
export const datiRecapito = jsonCopy(datiRecapitoInizializzato);
export const datiFiscali = jsonCopy(datiFiscaliInizializzato);
export const datiConsensoComElettr = jsonCopy(datiConsensoComElettrInizializzato);
export const datiAntiriciclaggio = jsonCopy(datiAttEconomicaInizializzato);
export const datiNaturaRapporto = jsonCopy(datiNaturaRapportoInizializzato);
export const datiAttEconomicaGiuridico = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
export const datiGeneraliGenerico = jsonCopy(datiGeneraliGenericoInizializzato);
export const datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
export const datiFinanziari = jsonCopy(datiFinanziariGiuridicoInizializzato);
export const datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);