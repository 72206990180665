import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import RadioButtonComp from '../../../common/RadioButtonComp';
import DividerComp from '../../../common/DividerComp';

import { radioButtonFlConsenso1Contratto, radioButtonFlConsenso2Marketing, 
	     radioButtonFlConsenso3Statistic, radioButtonFlConsenso4Comunicaz } from '../../../store/radioButtonStore';

import * as actions from './action/consenso';
import * as actionType from './actionType/consenso';

import ButtonComp from '../../../common/ButtonComp';
import { textTrim } from '../../../../utility/genericUtility';

const styles = theme => ({
  textField: theme.textField,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  button: theme.button,
  typography: theme.typography,
  textErrorMessage: theme.textErrorMessage,  
  paddingArea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
});

const mapStateToProps = state => {
//  console.log('#RTCFA DatiConsensoComp.mapStateToProps.state', state);
  return { 
    dominio: state.areaDatiDominioReducer.dominio,
    datiConsenso: state.areaDichConsensoReducer.dichiarazioni.datiConsenso,
  };
};

const mapDispatchToProps = dispatch => {
//  console.log('#RTCFA DatiConsensoComp.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiConsenso: (unDato, unActionType) => dispatch(actions.updateDatiConsenso(unDato, unActionType)),	 
  };
};

class DatiConsensoComp extends React.Component {
  constructor(props) {
	super(props);
	const datiConsenso = this.props.datiConsenso;
	this.state = {
//	  datiConsenso: this.props.datiConsenso,
      flConsenso1Contratto: datiConsenso.flConsenso1Contratto, 
      flConsenso2Marketing: datiConsenso.flConsenso2Marketing,
      flConsenso3Statistic: datiConsenso.flConsenso3Statistic,
      flConsenso4Comunicaz: datiConsenso.flConsenso4Comunicaz,	
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,      
	};
  };
  
  handleChangeConsenso1Contratto = event => {
//    console.log('#RTCFA DatiConsensoComp flConsenso1Contratto new value:' + event.target.value);
    const nuovoConsenso1Contratto = textTrim(event.target.value, true);
    const vecchioConsenso1Contratto = textTrim(this.state.flConsenso1Contratto, true); 
    if (nuovoConsenso1Contratto !== vecchioConsenso1Contratto) {    
      this.props.updateDatiConsenso(nuovoConsenso1Contratto, actionType.UPDATE_FL_CONSENSO1_CONTRATTO);
      this.setState({
        flConsenso1Contratto: nuovoConsenso1Contratto,
      });
    }
  };
  
  handleChangeConsenso2Marketing = event => {
//    console.log('#RTCFA DatiConsensoComp flConsenso2Marketing new value:' + event.target.value);
    const nuovoConsenso2Marketing = textTrim(event.target.value, true);
    const vecchioConsenso2Marketing = textTrim(this.state.flConsenso2Marketing, true); 
    if (nuovoConsenso2Marketing !== vecchioConsenso2Marketing) {    
      this.props.updateDatiConsenso(nuovoConsenso2Marketing, actionType.UPDATE_FL_CONSENSO2_MARKETING);
      this.setState({
        flConsenso2Marketing: nuovoConsenso2Marketing,
      });
    }
  };
  
  handleChangeConsenso3Statistic = event => {
//    console.log('#RTCFA DatiConsensoComp flConsenso3Statistic new value:' + event.target.value);
    const nuovoConsenso3Statistic = textTrim(event.target.value, true);
    const vecchioConsenso3Statistic = textTrim(this.state.flConsenso3Statistic, true); 
    if (nuovoConsenso3Statistic !== vecchioConsenso3Statistic) {    
      this.props.updateDatiConsenso(nuovoConsenso3Statistic, actionType.UPDATE_FL_CONSENSO3_STATISTIC);
      this.setState({
        flConsenso3Statistic: nuovoConsenso3Statistic,
      });
    }
  };
  
  handleChangeConsenso4Comunicaz = event => {
//    console.log('#RTCFA DatiConsensoComp flConsenso4Comunicaz new value:' + event.target.value);
    const nuovoConsenso4Comunicaz = textTrim(event.target.value, true);
    const vecchioConsenso4Comunicaz = textTrim(this.state.flConsenso4Comunicaz, true); 
    if (nuovoConsenso4Comunicaz !== vecchioConsenso4Comunicaz) {    
      this.props.updateDatiConsenso(nuovoConsenso4Comunicaz, actionType.UPDATE_FL_CONSENSO4_COMUNICAZ);
      this.setState({
        flConsenso4Comunicaz: nuovoConsenso4Comunicaz,
      });
    }
  };
  
  handleChangeConfermaDatiConsenso = event => {
    this.props.updateDatiConsenso(true, actionType.SALVA_DATI_CONSENSO);
  } 
	
  isRequiredFlConsenso1Contratto = () => {
    return true;
  }
  
  isRequiredFlConsenso2Marketing = () => {
    return true; 
  }
  
  isRequiredFlConsenso3Statistic = () => {
    return true;
  }
  
  isRequiredFlConsenso4Comunicaz = () => {
    return true;
  }
  
  initStatoCampi = () => {
    return [ {id: 'consenso1Contratto', isError: false},
             {id: 'consenso2Marketing', isError: false},  
             {id: 'consenso3Statistic', isError: false},  
             {id: 'consenso4Comunicaz', isError: false},             
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
    
  render() {
	console.log("DatiConsensoComp.this.props", this.props);
    const { classes, datiConsenso, dominio } = this.props;
    const { t } = this.props; //hoc    
    const controlloDati = datiConsenso.controlloDati;
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="h6">{t('datiAnagrafici')}</Typography></legend>*/}
        <DividerComp titolo='consensoTrattamentoDati' variant="subtitle1" />
        <Grid container direction='row' justify='center' alignItems='flex-start' className={classes.paddingArea}>
          <Grid item xs={12}>    
{/*          <Grid item xs={12} sm={10}>  */}
            <Typography variant="caption" className={classNames(classes.root, classes.typography)}>{dominio.consensiItems[0].value}) {dominio.consensiItems[0].label}</Typography>            	
          </Grid>            	
          <Grid item xs={12}>  
{/*         <Grid item xs={12} sm={1}> */}  
            <RadioButtonComp onChange={this.handleChangeConsenso1Contratto} selectedValue={datiConsenso.flConsenso1Contratto}  
                             radioComp={radioButtonFlConsenso1Contratto} required={this.isRequiredFlConsenso1Contratto()} controlloDati={controlloDati} readonly={true} 
                             id='consenso1Contratto' onError={this.handleError} />
          </Grid>
{/*            <Grid item sm={1}></Grid> */}  
{/*          <Grid item xs={12}>&nbsp;</Grid> */}
          <Grid item xs={12}>
            <Typography variant="caption" className={classNames(classes.root, classes.typography)}>{dominio.consensiItems[1].value}) {dominio.consensiItems[1].label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioButtonComp onChange={this.handleChangeConsenso2Marketing} selectedValue={datiConsenso.flConsenso2Marketing}  
                             radioComp={radioButtonFlConsenso2Marketing} required={this.isRequiredFlConsenso2Marketing()} controlloDati={controlloDati}
                             id='consenso2Marketing' onError={this.handleError} />  
          </Grid>
{/*            <Grid item sm={1}></Grid> */}  
{/*          <Grid item xs={12}>&nbsp;</Grid> */}
          <Grid item xs={12}>
            <Typography variant="caption" className={classNames(classes.root, classes.typography)}>{dominio.consensiItems[2].value}) {dominio.consensiItems[2].label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioButtonComp onChange={this.handleChangeConsenso3Statistic} selectedValue={datiConsenso.flConsenso3Statistic}  
                             radioComp={radioButtonFlConsenso3Statistic} required={this.isRequiredFlConsenso3Statistic()} controlloDati={controlloDati}
                             id='consenso3Statistic' onError={this.handleError} /> 
          </Grid>
{/*            <Grid item sm={1}></Grid> */}  
{/*           <Grid item xs={12}>&nbsp;</Grid> */}
            <Grid item xs={12}> 
            <Typography variant="caption" className={classNames(classes.root, classes.typography)}>{dominio.consensiItems[3].value}) {dominio.consensiItems[3].label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioButtonComp onChange={this.handleChangeConsenso4Comunicaz} selectedValue={datiConsenso.flConsenso4Comunicaz}  
                             radioComp={radioButtonFlConsenso4Comunicaz} required={this.isRequiredFlConsenso4Comunicaz()} controlloDati={controlloDati}
                             id='consenso4Comunicaz' onError={this.handleError} />
          </Grid>  
{/*            <Grid item sm={1}></Grid> */}  
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center' >
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }            
              </Grid>
            </Grid>          
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
              <Grid item>
                      <ButtonComp
                        aClassName={classes.button}
                        buttonLabel={'conferma'}
                        onClick={this.handleChangeConfermaDatiConsenso}
                      />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiConsensoComp componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiModalitaPagamento componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiModalitaPagamento componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiConsenso.controlloDati === true)) {		
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiConsenso(!isPresenteCampiConErrore, actionType.UPDATE_DATI_CONSENSO_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }      
}

DatiConsensoComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiConsensoComp)));

