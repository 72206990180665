import * as actionType from '../actionType/prodotto';

export const selezionaProdotto = (area, prodotto) => {
  return {
    type: actionType.SELEZIONA_PRODOTTO,
    area,
    prodotto,
  }
};

export const selezionaUtente = (utente) => {
  return {
    type: actionType.SELEZIONA_UTENTE,
    utente,
  }
};
