import { datiNuovaUtenza, datiRicercaUtenza, datiModificaUtenza, datiLoginCambioPassword, 
	datiPasswordDimenticata, datiNuovaPassword, datiInviaNuoveCredenziali } from './sezioni/sezioniGestioneUtenza';

export const gestioneUtenza = {
  datiNuovaUtenza: datiNuovaUtenza,
  datiRicercaUtenza: datiRicercaUtenza,
  datiModificaUtenza: datiModificaUtenza,
  datiLoginCambioPassword: datiLoginCambioPassword,
  datiPasswordDimenticata: datiPasswordDimenticata,
  datiNuovaPassword: datiNuovaPassword,
  datiInviaNuoveCredenziali: datiInviaNuoveCredenziali,
};