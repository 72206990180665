//import { datiGeneraliBenefMorte, datiResidenza, datiRelazContr, datiCopiaDaAltraFigura } from './sezioni/sezioniAnagrafica';
import { datiGeneraliBenefMorte, datiResidenza, datiAntiriciclaggio, datiRelazContr, datiCopiaDaAltraFigura } from './sezioni/sezioniAnagraficaBenefMorteFisico';
import { RUOLO_BENEF_MORTE, PERSONA_FISICA } from '../../utility/jsConstants';

export const benefMorteFisico = {
  controlloDati: '',
  anagraficaValida: null,
  datiFigureAnagrBase: {
    ruolo: RUOLO_BENEF_MORTE,
    tipoPers: PERSONA_FISICA,
  },
  datiGeneraliBenefMorte: datiGeneraliBenefMorte,
  datiResidenza: datiResidenza,
  datiRelazContr: datiRelazContr,
  datiAntiriciclaggio: datiAntiriciclaggio,
  datiCopiaDaAltraFigura: datiCopiaDaAltraFigura,
};

export const benefMorteFisicoList = [
//  benefMorteFisico
];
