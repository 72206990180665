import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { routes } from './routes';
import { connect } from 'react-redux';

//import BottomNavigation from '@material-ui/core/BottomNavigation';
//import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
//import RestoreIcon from '@material-ui/icons/Restore';

import MenuAppBar from './components/header/MenuAppBar';
import {menuAppBarItems} from './components/store/menuAppBarItems';
import { initPropostaItems } from './components/store/initPropostaItems';
import LoadingOverlayComp from './components/common/LoadingOverlayComp';

const styles = theme => ({
  root: {
    backgroundColor: 'white',
    height: '100vh',
  },
  central: {
//    height: '80vh',
  },
});

const mapStateToProps = state => {
  console.log('#RTCFA App.mapStateToProps.state', state);	
  return {
	  activeOverlay: state.genericReducer.generic.activeOverlay,
	  labelOverlay: state.genericReducer.generic.labelOverlay,
  };
};

class App extends React.Component {
  state = {
    open: false,
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  constructor(props) {
    super(props);
    console.log('#RT App constructor');
    // Don't call this.setState() here!
    this.state.store = {
      menuAppBarItems: menuAppBarItems,
//      areaProdotto: [], //empty array
      initPropostaItems: initPropostaItems,
    };
    
    this.state.error = null;
    this.state.isLoaded = false;

    this.state.shrinkAppBar = false; //FIXME
  };

  render() {
    const { classes, t } = this.props;
    const { store, shrinkAppBar, isLoaded, value } = this.state;
    const activeOverlay = this.props.activeOverlay;
    const labelOverlay = this.props.labelOverlay;

    return (
      <Grid container className={classes.root}>
      	<CssBaseline />
      	<LoadingOverlayComp active={activeOverlay} drawSpinner={true} label={labelOverlay} children={this.props.children}>
	      	<Grid item xs={12}>
	          <Grid container >
		        <Grid item>
		      	  <MenuAppBar menuItems={store.menuAppBarItems} shrinkAppBar={shrinkAppBar} />
		      	</Grid>
		        <Grid item xs={12} className={classes.central}>
	              {routes.map((route, index) => (
		            <FadingRoute
	                  key={index}
		              path={route.path}
		              exact={route.exact}
		              store={store}
		              component={route.component}
		              props={route.props}
		            />
		          ))} 
		        </Grid>
		      </Grid>	     
		    </Grid>
		</LoadingOverlayComp>
		
	    {/* COMMENTED RIPROVA
        <Grid item xs={12}>
          {!isLoaded && 
        	   <BottomNavigation
              value={value}
              onChange={this.handleChange}
              showLabels
            >
          <BottomNavigationAction label="Riprova" icon={<RestoreIcon />} />   
             <BottomNavigationAction />
            </BottomNavigation> 
            
          }
        </Grid>
        */}
      </Grid>
    );
  }
}

//wrapping/composing
const FadingRoute = ({ component: Component, props, ...rest }) => {
  return (
    <Route {...rest} render={_ => {
      return ( <Component {...props} {...rest.store} /> );
    }}/>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default  withRouter(connect(mapStateToProps, null)(withTranslation()(withStyles(styles, { withTheme: true })(App))));
