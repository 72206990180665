import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation }from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoFigura from '../sezione/TabDatiRiepilogoFigura';
import CopiaDaAltraFigura from '../sezione/other/CopiaDaAltraFigura';
import DatiGeneraliBenefGiuridico from '../sezione/DatiGeneraliBenefGiuridico';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiRelazContr from '../sezione/DatiRelazContr';
import DatiSocietari from '../sezione/DatiSocietari';
import DatiMandatoFiduciario from '../sezione/DatiMandatoFiduciario';
import DatiAttivitaEconomicaGiuridico from '../sezione/DatiAttivitaEconomicaGiuridico';

import RadioButtonComp from '../../common/RadioButtonComp';
import ButtonComp from '../../common/ButtonComp';
import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';

import { locale_it, BENEF_VITA_GIURIDICO } from '../../../utility/jsConstants';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';
import { creaBeneficiarioVitaGiuridico } from '../../../utility/objectUtility';

import { toNumber } from '../../../utility/genericUtility';
import { calcolaPercBenfTotale, 
		 getPercBenefByCodFiscInList, getPercBenefByCognomeInList } from './figureUtils/figureUtils';
import { radioButtonTitolariEffBeneficiari } from '../../store/radioButtonStore';

import * as actions from './action/beneficiarioVita';
import * as actionType from './actionType/beneficiarioVita';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textField: theme.textField,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA BenefVitaGiuridicoAss.mapStateToProps.state', state);
  return {
    benefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico,
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList,
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,
    salvaBenefVitaGiuridicoSuccess: state.areaBenefVitaReducer.salvaBenefVitaGiuridicoSuccess,
    controlloDati: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA BenefVitaGiuridicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
//    aggiungiBenefVitaGiuridico: (benefVitaGiuridico, emptyBenefVitaGiuridico) => dispatch(actions.aggiungiBenefVitaGiuridico(benefVitaGiuridico, emptyBenefVitaGiuridico)),
    confermaAggiungiBeneficiarioVitaGiuridico: (currBenefVitaGiuridico) => dispatch(actions.confermaAggiungiBeneficiarioVitaGiuridico(currBenefVitaGiuridico)),
    nuovoBeneficiarioVitaGiuridico: (currBenefVitaGiuridico) => dispatch(actions.nuovoBeneficiarioVitaGiuridico(currBenefVitaGiuridico)),
    updateDatiBeneficiarioVita: (unDato, unActionType) => dispatch(actions.updateDatiBeneficiarioVita(unDato, unActionType)),
    updateBeneficiarioVitaGiuridico: (unDato, unActionType) => dispatch(actions.updateBeneficiarioVitaGiuridico(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class BenefVitaGiuridicoAss extends React.Component {

  constructor(props) {
    super(props);
//    console.log('#RTCFA BenefVitaGiuridicoAss constructor props', this.props);
    const datiCopiaDaAltraFigura = props.benefVitaGiuridico.datiCopiaDaAltraFigura;
    const datiRelazContr = props.benefVitaGiuridico.datiRelazContr;
    this.state = {
      percBeneficiario: 0,
      percentualeTotale: 0, 
      isConfermaBenef: false,
      isNuovoBenef: false,
      figuraFrom: datiCopiaDaAltraFigura.figuraFrom,
      isCopiaDaAltraFigura: datiCopiaDaAltraFigura.flCopiaDaAltraFigura,
      flEsclusioneComunicaz: datiRelazContr.esclusioneComunicaz,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    }   
  };
  
/*  getEmptyBenefVitaGiuridico = () => {
	  const emptyBenefMorteGiuridico = {
	  	  controlloDati: '',
		  anagraficaValida: null,
		  
	 	  datiFigureAnagrBase: {
			ruolo: RUOLO_BENEF_VITA,
			tipoPers: PERSONA_GIURIDICA,
		   },
	    	datiGeneraliBenefGiuridico: {
	        datiGenerali: {},
	      },
	      datiResidenza: {},
	      datiRelazContr: {},
	      datiCopiaDaAltraFigura: {
	    	  flCopiaDaAltraFigura: 'N',
	      },
	    };
	  
	  return emptyBenefMorteGiuridico;	  
  }*/
  
  handleClickNuovoBenefVita = event => {
//    console.log('#RTCFA BenefVitaGiuridicoAss:handleClickNuovoBenefVita new value:', event.target.value);    
//    const emptyBenefVita = this.getEmptyBenefVitaGiuridico();
    const emptyBenefVita = creaBeneficiarioVitaGiuridico();
    this.props.nuovoBeneficiarioVitaGiuridico(emptyBenefVita);
//    this.props.updateDatiBeneficiarioVita(false, actionType.SALVA_BENEF_VITA_GIURIDICO_SUCCESS);   
    this.setState({
      isNuovoBenef: true,
      isPresenteErrore: null,
      flEsclusioneComunicaz: undefined,
	});
  }
  
  handleClickConfermaBenefVita = event => {
//	console.log('#RTCFA BenefVitaGiuridicoAss:handleClickConfermaBenefVita new value:', event.target.value);
    this.props.updateDatiBeneficiarioVita(true, actionType.SALVA_BENEF_VITA_GIURIDICO);
    this.props.updateDatiBeneficiarioVita(false, actionType.SALVA_BENEF_VITA_GIURIDICO_SUCCESS);
    this.setState({
      isConfermaBenef: true,
      isNuovoBenef: false,
    });
  }
  
  confermaBeneficiario = () => {
    const currBenef = this.props.benefVitaGiuridico;
    const percTotaleFigure = calcolaPercBenfTotale(this.props.benefVitaFisicoList, this.props.benefVitaGiuridicoList, this.props.benefVitaGenericoList, locale_it);
    let percTotale = percTotaleFigure;
    let percBenefInLista = undefined;
    let isNuovo = false;
    let benefVitaFromCopiaDaAltraFigura = undefined;
    if (this.state.isConfermaBenef) {
      if (currBenef.anagraficaValida === true) {
        if (currBenef.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!== 'S') {	
          // Trova per Codice Fiscale
          percBenefInLista = getPercBenefByCodFiscInList(this.props.benefVitaGiuridicoList, currBenef, locale_it);
          // Edit figura corrente
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;
          }
        } else {
          const descrFiguraFrom = currBenef.datiCopiaDaAltraFigura.descrFiguraFrom;
          currBenef.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale = descrFiguraFrom;
          // cerco se presente
          percBenefInLista = getPercBenefByCognomeInList(this.props.benefVitaGiuridicoList, currBenef);
          // nuova figura
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;
            benefVitaFromCopiaDaAltraFigura = {...currBenef};
            // reset alcune sezioni
            benefVitaFromCopiaDaAltraFigura.datiGeneraliBenefGiuridico.datiGenerali = {};
            benefVitaFromCopiaDaAltraFigura.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale = descrFiguraFrom;
            benefVitaFromCopiaDaAltraFigura.datiResidenza = {};
          }
        }
        const percentualeBenefCorrente = currBenef.datiGeneraliBenefGiuridico.percBeneficiario;
        const percBenefCorrente = toNumber(percentualeBenefCorrente, locale_it);
        if (percBenefCorrente !== undefined && percBenefCorrente > 0) {
        	// 2022-01-06 PFD: ricalcolo il totale con arrotondamento alla seconda cifra decimale
            //percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista);
            percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista).round(2);
          if (percTotale<=100.0) {
            const benificiario = benefVitaFromCopiaDaAltraFigura !== undefined ? benefVitaFromCopiaDaAltraFigura : currBenef;
            // INSERIMENTO NUOVO BENEFICIARIO
            if (isNuovo === true) {
              this.props.confermaAggiungiBeneficiarioVitaGiuridico(benificiario);
            } else {
              // MODIFICA BENEFICIARIO
              this.props.updateBeneficiarioVitaGiuridico(benificiario, actionType.UPDATE_BENEF_VITA_GIURIDICO);
            }
            // Aggiorno la percentuale
            this.props.updateDatiBeneficiarioVita(percTotale, actionType.UPDATE_BENEF_VITA_PERCENTUALE_TOTALE);
            this.props.updateDatiBeneficiarioVita(true, actionType.SALVA_BENEF_VITA_GIURIDICO_SUCCESS);
            
            //2021-07-01 PFD: TCM_INDIV
            this.handleTcmIndividualeValida();
          }
        }
      }
    }
    this.setState({
      percentualeTotale : percTotale,
    });
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  //funzione callback 
  handleChangeEsclusioneComunicaz = (value) => {
    this.setState({
      flEsclusioneComunicaz: value,
    });
  }
  
  // funzione callback 
  handleChangeFlCopiaDaAltraFigura = (value) => {	 
    this.setState({
      isCopiaDaAltraFigura: value,
      statoCampi: this.initStatoCampi(),
    });
  }
  
  // funzione callback
  handleChangeFiguraFrom = figuraFrom => {
    this.setState({
      figuraFrom: figuraFrom,
    });
  }
  
  handlePercBeneficiario = event => {
//    console.log('#RTCFA BenefVitaGiuridicoAss percBeneficiario new value:' + event.target.value);	
    this.props.updateDatiBeneficiarioVita(event.target.value,  actionType.UPDATE_PERCENTUALE_BENEF_VITA_GIURIDICO); 	      
    this.setState({
      percBeneficiario: event.target.value,
    });	
  }

  handleChangeTitolareEffBenef = event => {
    const flTitolareEffBenefVita = event.target.value;
    this.props.updateDatiBeneficiarioVita(flTitolareEffBenefVita, actionType.TITOLARI_EFFETTIVI_BENEFICIARI_VITA);
    this.setState({
      flTitolareEffBenefVita: flTitolareEffBenefVita,
    })
  }
  
  requiredTitolareEffettivoBenef = () =>{
    return this.props.scopeAmleto;
  }
  
  initStatoCampi = () => {
    if (this.props.benefVitaGiuridico.datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S') {
      return [{id: 'copiaDaAltraFiguraBenefVitaGiuridico', isError: false},
              {id: 'datiGeneraliBenefVitaGiuridico', isError: false},
              {id: 'datiResidenzaBenefVitaGiuridico', isError: false},
              {id: 'datiAttivitaEconomicaBenefVitaGiuridico', isError: false},
             ];
    } else {
      return [{id: 'copiaDaAltraFiguraBenefVitaGiuridico', isError: false},
              {id: 'percBeneficiario', isError: false},
              {id: 'datiRelazContrBenefVitaGiuridico', isError: false},
              {id: 'datiAttivitaEconomicaBenefVitaGiuridico', isError: false},
	         ];
    }
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  isDisabledTitolareEffettivoBenef = () => {
    return this.props.scopeAmleto === false;
  }
	  
  render() {
//    console.log('#RTCFA BenefVitaGiuridicoAss this.props.benefVitaFisico', this.props.benefVitaGiuridico);
//    console.log('#RTCFA BenefVitaGiuridicoAss this.props.aggiungiBenefVitaFisico', this.props.aggiungiBenefVitaGiuridico);    		
    const { classes, t, benefVitaGiuridico } = this.props;
    const currentBenef = benefVitaGiuridico;
    const datiGeneraliBenef = currentBenef.datiGeneraliBenefGiuridico;
    const datiSocietariBenef = {...currentBenef}.datiSocietari;
    const datiMandatoBenef ={...currentBenef}.datiMandatoFiduciario;
    const datiCopiaDaAltraFigura = currentBenef.datiCopiaDaAltraFigura;
    const percentualeTotaleError = this.state.percentualeTotale!==0 && parseFloat(this.state.percentualeTotale)!==100.0;
    const controlloDati = this.props.controlloDati;
    const isNuovo = this.state.isNuovoBenef;
    const salvaBenefVitaGiuridicoSuccess = this.props.salvaBenefVitaGiuridicoSuccess;
    const percBenefInLista = getPercBenefByCognomeInList(this.props.benefVitaGiuridicoList, currentBenef);
    const isCopiaReadonly = percBenefInLista!==undefined;	
    return (
      <Fragment>
        <TabDatiRiepilogoFigura figura={'beneficiarioVita'} tabHeader={'tabHeaderBenVita'} />        
        <CopiaDaAltraFigura id={'copiaDaAltraFiguraBenefVitaGiuridico'} 
			figura={BENEF_VITA_GIURIDICO} datiCopiaDaAltraFigura={datiCopiaDaAltraFigura} 
	  		handleChangeFlCopiaDaAltraFigura={this.handleChangeFlCopiaDaAltraFigura} 
	  	    handleChangeFiguraFrom={this.handleChangeFiguraFrom} 
	  	    onError={this.handleError} 
			readonly={isCopiaReadonly} />      
        {datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S' &&
          <Fragment>
            <DatiGeneraliBenefGiuridico figura={BENEF_VITA_GIURIDICO} datiGeneraliBenefVitaGiuridico={benefVitaGiuridico.datiGeneraliBenefGiuridico} labelFieldSet='datiGenerali' 
                                        id={'datiGeneraliBenefVitaGiuridico'} onError={this.handleError} />
            <DatiSocietari figura={BENEF_VITA_GIURIDICO} labelFieldSet='datiSocietari' datiSocietari={datiSocietariBenef} onError={this.handleError}
                          id='datiSocietariBenefMorte' />

            <DatiResidenza figura={BENEF_VITA_GIURIDICO} datiResidenzaBenefVitaGiuridico={benefVitaGiuridico.datiResidenza} labelFieldSet='datiDiResidenzaGiuridico' flEsclusioneComunicazBenef={this.state.flEsclusioneComunicaz}
                           id={'datiResidenzaBenefVitaGiuridico'} onError={this.handleError} />
                           
            <DatiAttivitaEconomicaGiuridico figura={BENEF_VITA_GIURIDICO} datiAttEconomicaGiuridico={benefVitaGiuridico.datiAttEconomicaGiuridico} 
                                            statoResidenza={this.state.statoResidenza} id={'datiAttivitaEconomicaBenefVitaGiuridico'} onError={this.handleError} />             
            
            <DatiRelazContr figura={BENEF_VITA_GIURIDICO} datiRelazContrBenefVitaGiuridico={currentBenef.datiRelazContr} handleChangeEsclusioneComunicaz={this.handleChangeEsclusioneComunicaz}
                            id={'datiRelazContrBenefVitaGiuridico'} onError={this.handleError} relazioneConContr={'relazContrBenefVita'} /> 	

            <DatiMandatoFiduciario figura={BENEF_VITA_GIURIDICO} onError={this.handleError} id='datiMandatoFiduciarioBenefVita' 
                                  datiMandatoFiduciario={datiMandatoBenef} labelFieldSet='datiMandatoFiduciario' />
          </Fragment>
        }        
      	{datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'S' &&
          <Fragment>
            <DividerComp titolo='&nbsp;' variant='subtitle1' />
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12}>
                <NumberFieldComp onBlur={this.handlePercBeneficiario} aStyle={classes.textField} id={'percBeneficiario'} label='percBeneficiario' format='###,###' 
                                 value={datiGeneraliBenef.percBeneficiario} maxLength='7' required={true} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
            </Grid>
          </Fragment>
      	}     	
      
        {/* <DividerComp titolo='titolareEffBeneficiario' variant='subtitle1' />
        <Grid>
          <RadioButtonComp selectedValue={currentBenef.flTitolareEffBenefVita} controlloDati={controlloDati} onError={this.handleError} id='titolareEffettivoBeneficiario'
            onChange={this.handleChangeTitolareEffBenef} classes={{ divider: classes.divider }} radioComp={radioButtonTitolariEffBeneficiari}
            required={this.requiredTitolareEffettivoBenef()} disabled={this.isDisabledTitolareEffettivoBenef()} />
        </Grid> */}

        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center' >            	
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && !this.state.isPresenteErrore && percentualeTotaleError && !isNuovo && 
                <Typography className={classes.textErrorMessage}>{t('errTotPercBenef')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) && (salvaBenefVitaGiuridicoSuccess === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }		                
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ButtonComp
               /* to={linkTo} */
               /* component={Link} */
               aClassName={classes.button}
               buttonLabel={t('conferma')}
               onClick={this.handleClickConfermaBenefVita}
            />
          </Grid>
          <Grid item>
            <ButtonComp
              /* to={linkTo} */
              /* component={Link} */
              aClassName={classes.button}
              buttonLabel={t('nuovoBenefic')}
              onClick={this.handleClickNuovoBenefVita}
            />
          </Grid>
	    </Grid>	            	
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########BenefVitaGiuridicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefVitaGiuridicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefVitaGiuridicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore && (this.props.controlloDati !== '')) {
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiBeneficiarioVita(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_VITA_GIURIDICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,
      });      
	}
	return isPresenteCampiConErrore;
  }
  
  isConfermaBenef = (prevProps, prevState, isPresenteCampiConErrore) => {   
//	const currIsPresenteErrore = this.state.isPresenteErrore;
    const currIsPresenteErrore = isPresenteCampiConErrore;
	const currIsConferma =  this.state.isConfermaBenef;	
	const prevIsPresenteErrore = prevState.isPresenteErrore;
	const prevIsConferma =  prevState.isConfermaBenef;	
	if (currIsPresenteErrore!==undefined && currIsPresenteErrore!==null && currIsPresenteErrore===false ) {
		if (currIsConferma) {
			this.confermaBeneficiario();
		}
	}
	if (currIsConferma !== prevIsConferma) {
		this.setState({isConfermaBenef: false,})
	}
  }
  
  isFlgEsclusioni = (prevProps, prevState) => {   	
	const currflEsclusioneComunicaz = this.props.benefVitaGiuridico.datiRelazContr.esclusioneComunicaz;
	const prevflEsclusioneComunicaz = this.state.flEsclusioneComunicaz;
	if (currflEsclusioneComunicaz!==undefined && currflEsclusioneComunicaz!==null && currflEsclusioneComunicaz !== prevflEsclusioneComunicaz) {
      this.setState({
        flEsclusioneComunicaz: currflEsclusioneComunicaz,
      });
	}  
  }
  
  componentDidUpdate(prevProps, prevState) {
    const isPresenteCampiConErrore = this.isInError(prevProps, prevState);
    this.isConfermaBenef(prevProps, prevState, isPresenteCampiConErrore);
    this.isFlgEsclusioni(prevProps, prevState); // allineo lo stato con le props
  }
}

BenefVitaGiuridicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  benefVitaGiuridico: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BenefVitaGiuridicoAss)));
