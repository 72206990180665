import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { uris, fetchParam, isLocal } from '../../../../uris';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';


import SelectComp from '../../../common/SelectComp';
import ButtonComp from '../../../common/ButtonComp';
import RadioButtonComp from '../../../common/RadioButtonComp';

import * as actions from './action/datiPianoSpostamentoAutomatico';
import * as actionType from './actionType/datiPianoSpostamentoAutomatico';
import * as actionsOpzioniContrattuali from './action/datiOpzioniContrattuali';
import * as actionTypeOpzioniContrattuali from './actionType/datiOpzioniContrattuali';

import { textTrim, checkBoxValue } from '../../../../utility/genericUtility';
import { DATI_PIANO_SPOSTAMENTO_AUTOMATICO } from '../../../../utility/jsConstants';


import moment from 'moment';
import 'moment/locale/it';

const styles = theme => ({
  paperStyle: theme.paperStyle,	
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  fieldSetLikeRadioButtonCompStyle: theme.fieldSetLikeRadioButtonCompStyle,
  divider: {
    width: theme.spacing(1),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
  legend: {
	  color: theme.palette.color.greyLight,
	  fontSize: 15, 
  },
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	prodotto: state.areaProdottoReducer.prodotto, 
	caratteristicheContratto: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto,
	controlloDati: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  return {
	  updateDatiPianoSpostamentoAutomatico: (unDato, unActionType) => dispatch(actions.updateDatiPianoSpostamentoAutomatico(unDato, unActionType)),
	  updateDatiOpzioniContrattuali: (unDato, unActionType) => dispatch(actionsOpzioniContrattuali.updateDatiOpzioniContrattuali(unDato, unActionType)),
  };
};
	
class DatiPianoSpostamentoAutomatico extends React.Component {
	
  constructor(props) {
    super(props);
    const caratteristicheContratto = this.props.caratteristicheContratto;
    const datiOpzioniContrattuali = caratteristicheContratto.datiOpzioniContrattuali;
    const datiPianoSpostamentoAutomatico = datiOpzioniContrattuali.datiPianoSpostamentoAutomatico;
    this.state = {
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
      radioCompAllocazioneTargetItems: datiPianoSpostamentoAutomatico.allocazioneTargetItems, // i dati json per la RadioButtonComp vengono caricati nel servizio
      allocazioneTarget: datiPianoSpostamentoAutomatico.allocazioneTarget,
	};
  };

  componentDidMount() {
	  //Inizializzo redux obj
	  if (!this.props.caratteristicheContratto.datiOpzioniContrattuali.opzioneContrattuale) {
		  this.props.updateDatiOpzioniContrattuali(DATI_PIANO_SPOSTAMENTO_AUTOMATICO, actionTypeOpzioniContrattuali.UPDATE_OPZIONE_CONTRATTUALE);  
	  }
	  
	  this.loadRadioCompAllocazioneTargetItems();
  }  
  
  loadRadioCompAllocazioneTargetItems = () => {
    if(this.state.radioCompAllocazioneTargetItems === null) {
	  const opts = {
        siglaVersione: 'ZURI',
        codiceProdotto: this.props.prodotto.codice,
        dataRichiesta: moment().format('DD/MM/YYYY'),
      };
      fetch(uris.allocazioneTargetItems, fetchParam(opts))
        .then (
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.props.updateDatiPianoSpostamentoAutomatico(result, actionType.UPDATE_PIANO_SPOSTAMENTO_AUTOMATICO_ALLOCAZIONE_ITEMS);
          this.setState({
        	  radioCompAllocazioneTargetItems: result,
          });
        });
    }
  }
  
  isRequiredFrequenza = () => {
    return true;
  }
  
  initStatoCampi = () => {
    return [ {id: 'allocazioneTarget', isError: false},             
    	   ];
  }
  
  handleChangeAllocazioneTarget = event => {
    //    console.log('#RTCFA DatiOpzioniContrattuali opzioneContrattuale new value:' + event.target.value);
    const nuovaAllocazioneTarget = textTrim(event.target.value, true);
    const vecchiaAllocazioneTarget = textTrim(this.state.allocazioneTarget, true);
    if (nuovaAllocazioneTarget !== vecchiaAllocazioneTarget) {
      const alloc = {
         value: nuovaAllocazioneTarget,
    	 label: event.target.labels[0].innerText,
      }
      this.props.updateDatiPianoSpostamentoAutomatico(alloc, actionType.UPDATE_PIANO_SPOSTAMENTO_AUTOMATICO_ALLOCAZIONE_TARGET);
      this.setState({
    	  allocazioneTarget: nuovaAllocazioneTarget,
      });
    }
  };
	
	  
  handleChangeConfermaDatiPianoSpostamentoAutomatico = event => {
    this.props.updateDatiPianoSpostamentoAutomatico(true, actionType.SALVA_PIANO_SPOSTAMENTO_AUTOMATICO);
  }
    
  handleError = (id, isError) => {
    console.log('########DatiPianoSpostamentoAutomatico handleError id', id);	  
    console.log('########DatiPianoSpostamentoAutomatico handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
	  
  render() {
    const { classes, caratteristicheContratto, dominio, prodotto } = this.props;	  
    const { t } = this.props; //hoc    
	const datiOpzioniContrattuali = caratteristicheContratto.datiOpzioniContrattuali;
	const datiPianoSpostamentoAutomatico = datiOpzioniContrattuali.datiPianoSpostamentoAutomatico;	          
    const controlloDati = datiPianoSpostamentoAutomatico.controlloDati;
    return (
      <Fragment>
      <fieldset className={classes.fieldSetLikeRadioButtonCompStyle}>
        <legend><Typography variant="caption" className={classes.legend}>{t('selezionarePianoSpostamento')}</Typography></legend>
        {/* <DividerComp titolo='pianoErogazioniPeriodiche' variant="subtitle1" />*/}
        <Grid container direction="row" justify="left" alignItems="flex-start" >
          <Grid item xs={12}>
          	<Typography variant="caption" >{t('pianoSpostamentoAutomatico')}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
           {/* 
          	<SelectComp onChange={this.handleChangeFrequenza} options={dominio.frequenzaErogazioniPeriodicheItems} ricerca='comboRicerca' etichetta='freqPrestazione' 
                        value={{value: datiPianoSpostamentoAutomatico.freqPrestazione, label: datiPianoSpostamentoAutomatico.descFreqPrestazione}} 
                        required={this.isRequiredFrequenza()} controlloDati={controlloDati} id='frequenza' onError={this.handleError} />                       
          	*/}
          	<RadioButtonComp onChange={this.handleChangeAllocazioneTarget} radioComp={this.state.radioCompAllocazioneTargetItems} classes={{divider: classes.divider}} 
          		selectedValue={datiPianoSpostamentoAutomatico.allocazioneTarget} controlloDati={controlloDati} required={true} id='allocazioneTarget' onError={this.handleError} />
          </Grid>
        </Grid>
      </fieldset>
      	
      <Grid container direction="row" justify="center" alignItems="flex-start" >
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
            <Grid container direction='row' justify='center' alignItems='center'>
              <Grid item>
                {(this.state.isPresenteErrore === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(controlloDati === true) && (this.state.isPresenteErrore === false) &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
              </Grid>
            </Grid>
            <Grid item>
	                <ButtonComp
	                  aClassName={classes.button}
	                  buttonLabel={t('conferma')}
	                  onClick={this.handleChangeConfermaDatiPianoSpostamentoAutomatico}
	                />
            </Grid>
          </Grid>
        </Grid>
      </Grid>      
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiPianoSpostamentoAutomatico componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########DatiPianoSpostamentoAutomatico componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.caratteristicheContratto.datiOpzioniContrattuali.datiPianoSpostamentoAutomatico.controlloDati === true)) {		
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiPianoSpostamentoAutomatico(!isPresenteCampiConErrore, actionType.UPDATE_PIANO_SPOSTAMENTO_AUTOMATICO_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }      
}

DatiPianoSpostamentoAutomatico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiPianoSpostamentoAutomatico)));

