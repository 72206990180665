import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import RisposteQuestionario from './RisposteQuestionario';
import { QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO } from '../../../../utility/jsConstants';

const styles = theme => ({
/*  sezione: {
	width:'100%',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },*/
  sezione: {
		width:'100%',
	    paddingLeft: theme.spacing(3),
	    paddingRight: theme.spacing(3),
	    paddingBottom: theme.spacing(2),
	  },  
  domande: {
	width:'95%',
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
});

class DomandeQuestionario extends React.Component {
	
  render() {
    const { classes, domande, titoloSezione, tipoQuestionario} = this.props;
    return (
      <div className={classes.sezione}>
        <Typography variant="body1">{titoloSezione}</Typography>
        {domande.map((elemento, index) => (	
          <div key={index} className={classes.domande}>
          <Typography variant="body2">
            {tipoQuestionario === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO && (titoloSezione === 'Attività Professionale' || titoloSezione === 'Attività Sportiva') ? '' : elemento.testo}
          </Typography>
          <RisposteQuestionario risposte={elemento.risposte} numDomanda={elemento.numDomanda} tipoQuestionario={tipoQuestionario} didascalia={elemento.testo} 
                                tipoAttivita={titoloSezione} />          
          </div>
        ))}
      </div>
    );
  }
}

DomandeQuestionario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DomandeQuestionario);

