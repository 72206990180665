export default {
  'stampaProposta': 'stampa/stampaProposta',	
  'areaProdotto': '/store/areaProdotto.json',
  'nazioneItems': '/store/nazioneItems.json',
  'tipoDocumentoItems': '/store/tipoDocumentoItems.json',
  'enteRilascioItems': '/store/enteRilascioItems.json',
  'scopoNaturaRapportoItems': '/store/scopoNaturaRapportoItems.json',
  'tipoSocietaItems': '/store/tipoSocietaItems.json',
  'beneficiarioGenericoVitaItems': '/store/beneficiarioGenericoVitaItems.json',
  'beneficiarioGenericoMorteItems': '/store/beneficiarioGenericoMorteItems.json',
  'prevalenteAttivitaSvoltaItems': '/store/prevalenteAttivitaSvoltaItems.json',
  'prevalenteAttivitaSvoltaGiuridicoItems': '/store/prevalenteAttivitaSvoltaGiuridicoItems.json',
  'cittadinanzaItems': '/store/cittadinanzaItems.json',
  'provinciaItems': '/store/provinciaItems.json',
  'comuneItems': '/store/comuneItems.json',
  'toponimoItems': '/store/toponimoItems.json',
  'stradarioItems': '/store/stradarioItems.json',
  'origineFondiItems': '/store/origineFondiItems.json',
  'saeItems': '/store/saeItems.json',
  'atecoItems': '/store/atecoItems.json',
  'allocazioneItems': '/store/allocazioneItems.json',
  'fondiItems': '/store/fondiItems.json',
  'percTakeProfitItems': '/store/percTakeProfitItems.json',
  'frequenzaErogazioniPeriodicheItems': '/store/frequenzaErogazioniPeriodicheItems.json',
  'percentualeErogazioniPeriodicheItems': '/store/percentualeErogazioniPeriodicheItems.json',
  'modalitaPagamentoItems': '/store/modalitaPagamentoItems.json',
  'bancaItems': '/store/bancaItems.json',
  'filialeItems': '/store/filialeItems.json',
  'consensiItems': '/store/consensiItems.json',
  'dichiarazioniIntermediarioItems': '/store/dichiarazioniIntermediarioItems.json',
  'regioneItems': '/store/regioneItems.json',
  'durataItems': '/store/durataItems.json',
  'classePremioPotenzialeItems': '/store/classePremioPotenzialeItems.json',
  'calcoloAdeguatezza': 'questionari/calcoloAdeguatezza',
  'controllaAnamnestico': 'questionari/controllaAnamnestico',
  'controllaCalcolaCodiceFiscale': 'customer/controllaCalcolaCodiceFiscale',
  'controlloIban': 'customer/controlloIban',  
  'campiTariffaItems': '/store/campiTariffaItems.json',
  'tariffaItems': '/store/tariffaItems.json',
  'capitaleAssicuratoMalattiaGraveItems': '/store/capitaleAssicuratoMalattiaGraveItems.json',
  'frequenzaCapitaleAssicuratoItems': '/store/frequenzaCapitaleAssicuratoItems.json',
  'ruoloXProdottoItems': '/store/ruoloXProdottoItems.json',
  'bancaAgenziaDaIbanItems': '/store/bancaAgenziaDaIbanItems.json',
  'inserimentoUtenteItems': '/store/inserimentoUtenteItems.json',
  'modificaUtenteItems': '/store/modificaUtenteItems.json',
  'revocaUtenteItems': '/store/revocaUtenteItems.json',
  'riattivaSbloccaUtenteItems': '/store/riattivaSbloccaUtenteItems.json',
  'mandatoItems': '/store/mandatoItems.json',
  'profiloItems': '/store/profiloItems.json',
  'ricercaUtenteItems': '/store/ricercaUtenteItems.json',
  'cambioPasswordUtenteItems': '/store/cambioPasswordUtenteItems.json',
  'recuperoCredenzialiItems': '/store/recuperoCredenzialiItems.json',
  'resetPasswordItems': '/store/resetPasswordItems.json',
  'inviaNuoveCredenzialiItems': 'store/inviaMailInizialeCredenziali',
  'isJavaMailAPI': 'store/isJavaMailAPI.json',
  'selectUserListInvioMailInizialeCredenziali': 'store/selectUserListInvioMailInizialeCredenziali.json',
  'inviaEmailCredenzialiByUserList': 'store/inviaEmailCredenzialiByUserList.json',
  'userMailMaxSize': 'store/userMailMaxSize.json',
}