import { datiQuestionarioAdeguatezzaFisico, datiQuestionarioAdeguatezzaGiuridico, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoMalattiaGrave,
   datiQuestionarioAnamnesticoSemplificato, controlloRisposta, tipoCurrentQuestionario, isChangedFromSemplificato, showSemplificato, showOnlySemplificato } 
from './sezioni/sezioniQuestionario';

export const questionario = {
  datiQuestionarioAdeguatezzaFisico: datiQuestionarioAdeguatezzaFisico,
  datiQuestionarioAdeguatezzaGiuridico: datiQuestionarioAdeguatezzaGiuridico,
  datiQuestionarioAnamnestico: datiQuestionarioAnamnestico,
  datiQuestionarioAnamnesticoMalattiaGrave: datiQuestionarioAnamnesticoMalattiaGrave,
  datiQuestionarioAnamnesticoSemplificato: datiQuestionarioAnamnesticoSemplificato,
  controlloRisposta: controlloRisposta,
  tipoCurrentQuestionario: tipoCurrentQuestionario,
  isChangedFromSemplificato: isChangedFromSemplificato,
//2020-02-08 PFD - START   showSemplificato: showSemplificato,
//2020-02-08 PFD - START   showOnlySemplificato: showOnlySemplificato
};