import { jsonCopy } from '../../../utility/genericUtility';
import { datiGeneraliBenefGiuridicoInizializzato, datiResidenzaInizializzato, datiRelazContrInizializzato, datiSocietariGiuridicoInizializzato,
	     datiCopiaDaAltraFiguraInizializzato, datiMandatoFiduciarioInizializzato, datiTitolareEffettivoBenefMorteInizializzato, datiAttEconomicaGiuridicoInizializzato, titEffBenefMorteGiuridicoInizializzato } from '../componentiBase/sezioniAnagrafica';

export const datiGeneraliBenefGiuridico = jsonCopy(datiGeneraliBenefGiuridicoInizializzato);
export const datiResidenza = jsonCopy(datiResidenzaInizializzato);
export const datiRelazContr = jsonCopy(datiRelazContrInizializzato);
export const datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
export const datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
export const datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato)
export const titolareBenefMorteEff = jsonCopy(titEffBenefMorteGiuridicoInizializzato);//jsonCopy(datiTitolareEffettivoBenefMorteInizializzato);
export const datiAttEconomica = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
export const titolareEffBenefMorteList = [];
export const titolareEffBenefMorteListInBenef = [];
export const flTitolareEffBenef = '';
