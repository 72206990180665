import React from 'react';
//import styled from 'styled-components'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';

{/*
const StyledLoader = styled(LoadingOverlay)`
width: 100%;
height: 100%;
.LoadingOverlayComp_overlay {    
  background: inherit;
}
.InnerLoadingOverlayComp_overlay {    
  background: rgba(1, 0, 0, 0.1);    
}    
&.InnerLoadingOverlayComp_wrapper--active {  
  -webkit-filter: blur(1px);    
}
`;


export default function LoadingOverlayComp({ active, drawSpinner, text, children }) {
  
 return (				
    <StyledLoader
      active={active}
      spinner={drawSpinner} 
      text={text}
      classNamePrefix='LoadingOverlayComp_'
    >
		    <StyledLoader
		      active={active}
		      classNamePrefix='InnerLoadingOverlayComp_'
		    >
		      {children}
		    </StyledLoader>

     </StyledLoader>
		 
  )
}

*/}

const styles = theme => ({
	
});

class LoadingOverlayComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		   active: props.active,
		   scrollY: 0,
	    };
		
		this.handleScroll = this.handleScroll.bind(this);
	};
	
	componentDidMount = () => {
		window.addEventListener('scroll', this.handleScroll);
	};

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScroll);
	};
	
	handleScroll = (event) => {
//		const scrollTop = this.myRef.current.scrollTop;
	    let scrollTop = event.srcElement.body.scrollTop;	        
	    
//	    console.log('handleScroll scrollTop: ' + event.srcElement.body.scrollTop);
//	    console.log('handleScroll innerHeight: ' + event.srcElement.body.innerHeight);
//	    console.log('handleScroll offsetHeight: ' + event.srcElement.body.offsetHeight);
//	    console.log('handleScroll window.scrollY: ' +  window.scrollY);
	    
	    const clientHeight = event.srcElement.body.clientHeight;
	    const windowInnerHeight = window.innerHeight;
//	    const inner = windowInnerHeight > clientHeight ? clientHeight : windowInnerHeight;
	    
	    this.setState({ 
	        scrollY: window.scrollY,
	    });
	};
	
	render () {
		const { classes, t, active, drawSpinner, label, children } = this.props;
		const text = t(label);
		const scrollY = this.state.scrollY;
		const marginTop = window.innerHeight/2 + scrollY - 80;
		
	    return (
	      <LoadingOverlay active={active} spinner={drawSpinner} text={text} 
		      styles={{
	    	  wrapper: (base) => ({
    				width: '100%',
//    				background: 'inherit',
    	        }),
    	        overlay: (base) => ({
    	           	...base, 
    	           	width: '100%',
    	           	background: 'inherit',
    	        }),
    	        content: (base) => ({
    	        	...base,
    	        	fontSize: '1.2em',
    	        	marginTop: marginTop !== null ? marginTop : 'auto',
    	        }),
	  	    }}
	  	    >
	      	<LoadingOverlay
	      		active={active}
	    		styles={{
	    			wrapper: (base) => ({
	    			    ...base,
	    	            WebkitFilter: active ? 'blur(1px)' : 'none',
	    	        }),
	    	        overlay: (base) => ({
	    	        	...base,
	    	           	width: '100%',
	    	           	background: 'rgba(1, 0, 0, 0.15)',	    	            
	    	        }),
	    	    }}
	          >
	      	{children}
	      	 
	      	</LoadingOverlay>
	      	 
	      </LoadingOverlay>
	    		
	    		
	    )
	};
}

LoadingOverlayComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(LoadingOverlayComp));


 





