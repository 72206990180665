/* REFERENTE TERZO */
export const updateReferenteTerzoFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};
	
export const updateDatiReferenteTerzoFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* RESET DATI REFERENTE TERZO */
export const resetDatiReferenteTerzoFisico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};