import * as actionType from '../actionType/datiPianoErogazioniPeriodiche';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiPianoErogazioniPeriodicheInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiPianoErogazioniPeriodicheReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiPianoErogazioniPeriodicheReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaPianoErogazioniPeriodiche(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };  
    case actionType.SALVA_DATI_PIANO_EROGAZIONI_PERIODICHE:
//      console.log('#RT2 Reducer areaDatiPianoErogazioniPeriodicheReducer SALVA_DATI_PIANO_EROGAZIONI_PERIODICHE', actionType.SALVA_DATI_PIANO_EROGAZIONI_PERIODICHE); 
//      console.log('#RT2 Reducer areaDatiPianoErogazioniPeriodicheReducer.action', action);    
      const caratteristicheContrattoDatiPianoErogazioniPeriodicheSalva = {...state}.caratteristicheContratto;
      const datiPianoErogazioniPeriodicheSalva = {...caratteristicheContrattoDatiPianoErogazioniPeriodicheSalva}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodicheSalva.controlloDati = action.unDato;
      caratteristicheContrattoDatiPianoErogazioniPeriodicheSalva.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheSalva};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPianoErogazioniPeriodicheSalva,
      };
    case actionType.UPDATE_DATI_PIANO_EROGAZIONI_PERIODICHE_VALIDA:  
      const caratteristicheContrattoDatiPianoErogazioniPeriodicheUpdate = {...state}.caratteristicheContratto;
      const datiPianoErogazioniPeriodicheUpdate = {...caratteristicheContrattoDatiPianoErogazioniPeriodicheUpdate}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodicheUpdate.datiPianoErogazioniPeriodicheValida = action.unDato;
      caratteristicheContrattoDatiPianoErogazioniPeriodicheUpdate.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheUpdate};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPianoErogazioniPeriodicheUpdate,
      };      
    case actionType.UPDATE_FREQ_PRESTAZIONE_PIANO_EROGAZIONI_PERIODICHE:
      const caratteristicheContrattoFreqPrestazione = {...state}.caratteristicheContratto;
      const datiPianoErogazioniPeriodicheFreqPrestazione = {...caratteristicheContrattoFreqPrestazione}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodicheFreqPrestazione.freqPrestazione = unValore;
      datiPianoErogazioniPeriodicheFreqPrestazione.descFreqPrestazione = unaLabel;
      datiPianoErogazioniPeriodicheFreqPrestazione.percentuale = '';
      datiPianoErogazioniPeriodicheFreqPrestazione.descPercentuale = '';
      caratteristicheContrattoFreqPrestazione.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheFreqPrestazione};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoFreqPrestazione,
      };
    case actionType.UPDATE_DURATA_PIANO_EROGAZIONI_PERIODICHE:
      const caratteristicheContrattoDurata = {...state}.caratteristicheContratto;
      const datiPianoErogazioniPeriodicheDurata = {...caratteristicheContrattoDurata}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodicheDurata.durata = unValore;
      datiPianoErogazioniPeriodicheDurata.descDurata = unaLabel;
      caratteristicheContrattoDurata.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheDurata};
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoDurata,
      }; 
    case actionType.UPDATE_PERCENTUALE_PIANO_EROGAZIONI_PERIODICHE:
      const caratteristicheContrattoPercentuale = {...state}.caratteristicheContratto;
      const datiPianoErogazioniPeriodichePercentuale = {...caratteristicheContrattoPercentuale}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodichePercentuale.percentuale = unValore;
      datiPianoErogazioniPeriodichePercentuale.descPercentuale = unaLabel;
      caratteristicheContrattoPercentuale.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodichePercentuale};
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoPercentuale,
      }; 
    case actionType.UPDATE_IBAN_PIANO_EROGAZIONI_PERIODICHE:
      const caratteristicheContrattoIban = {...state}.caratteristicheContratto;
      const datiPianoErogazioniPeriodicheIban = {...caratteristicheContrattoIban}.datiPianoErogazioniPeriodiche;
      datiPianoErogazioniPeriodicheIban.iban = action.unDato;
      caratteristicheContrattoIban.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheIban};
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoIban,
      }; 
    case actionType.UPDATE_CONTROLLO_IBAN_PIANO_EROGAZIONI_PERIODICHE:
        const caratteristicheContrattoControlloIban = {...state}.caratteristicheContratto;
        const datiPianoErogazioniPeriodicheControlloIban = {...caratteristicheContrattoControlloIban}.datiPianoErogazioniPeriodiche;
        datiPianoErogazioniPeriodicheControlloIban.controlloIban = action.unDato;
        caratteristicheContrattoControlloIban.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheControlloIban};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoControlloIban,
        }; 
    case actionType.UPDATE_CONTO_COINTESTATO_PIANO_EROGAZIONI_PERIODICHE:
        const caratteristicheContrattoContoCointestato = {...state}.caratteristicheContratto;
        const datiPianoErogazioniPeriodicheContrattoContoCointestato = {...caratteristicheContrattoContoCointestato}.datiPianoErogazioniPeriodiche;
        datiPianoErogazioniPeriodicheContrattoContoCointestato.contoCointestato = action.unDato;
        caratteristicheContrattoContoCointestato.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheContrattoContoCointestato};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoContoCointestato,
        }; 
    case actionType.UPDATE_NOME_COGNOME_COINTESTATARIO_PIANO_EROGAZIONI_PERIODICHE:
    	const caratteristicheContrattoNomeCognomeCointestatario = {...state}.caratteristicheContratto;
        const datiPianoErogazioniPeriodicheContrattoNomeCognomeCointestatario = {...caratteristicheContrattoNomeCognomeCointestatario}.datiPianoErogazioniPeriodiche;
        datiPianoErogazioniPeriodicheContrattoNomeCognomeCointestatario.nomeCognomeCointestatario = action.unDato;
        caratteristicheContrattoNomeCognomeCointestatario.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheContrattoNomeCognomeCointestatario};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoNomeCognomeCointestatario,
        };
    case actionType.UPDATE_RELAZIONE_CONTR_PIANO_EROGAZIONI_PERIODICHE:
    	const caratteristicheContrattoRelazioneContr = {...state}.caratteristicheContratto;
        const datiPianoErogazioniPeriodicheContrattoRelazioneContr = {...caratteristicheContrattoRelazioneContr}.datiPianoErogazioniPeriodiche;
        datiPianoErogazioniPeriodicheContrattoRelazioneContr.relazContrCoint = unValore;
        datiPianoErogazioniPeriodicheContrattoRelazioneContr.descrRelazContrCoint = unaLabel;
        caratteristicheContrattoRelazioneContr.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheContrattoRelazioneContr};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoRelazioneContr,
        }; 
    case actionType.UPDATE_ALTRA_RELAZIONE_PIANO_EROGAZIONI_PERIODICHE:
    	const caratteristicheContrattoAltraRelazione = {...state}.caratteristicheContratto;
        const datiPianoErogazioniPeriodicheContrattoAltraRelazione = {...caratteristicheContrattoAltraRelazione}.datiPianoErogazioniPeriodiche;
        datiPianoErogazioniPeriodicheContrattoAltraRelazione.altraRelazione = action.unDato;
        caratteristicheContrattoAltraRelazione.datiPianoErogazioniPeriodiche = {...datiPianoErogazioniPeriodicheContrattoAltraRelazione};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoAltraRelazione,
        }; 
    default:
      return state;
  }
}

export const inizializzaPianoErogazioniPeriodiche = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiPianoErogazioniPeriodiche = jsonCopy(datiPianoErogazioniPeriodicheInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiPianoErogazioniPeriodicheReducer;