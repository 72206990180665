import * as actionType from '../actionType/datiPremioCapitaleAssicurato';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiPremioCapitaleAssicuratoInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiPremioCapitaleAssicuratoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  console.log('#RT2 Reducer areaDatiPremioCapitaleAssicuratoReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaPremioCapitaleAssicurato(state);
	  return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.SALVA_DATI_PREMIO_CAPITALE_ASSICURATO:  
      const caratteristicheContrattoDatiPremioCapitaleAssicuratoSalva = {...state}.caratteristicheContratto;
      const datiPremioCapitaleAssicuratoSalva = {...caratteristicheContrattoDatiPremioCapitaleAssicuratoSalva}.datiPremioCapitaleAssicurato;
      datiPremioCapitaleAssicuratoSalva.controlloDati = action.unDato;
      caratteristicheContrattoDatiPremioCapitaleAssicuratoSalva.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoSalva};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPremioCapitaleAssicuratoSalva,
      };
    case actionType.UPDATE_DATI_PREMIO_CAPITALE_ASSICURATO_VALIDA:  
      const caratteristicheContrattoDatiPremioCapitaleAssicuratoUpdate = {...state}.caratteristicheContratto;
      const datiPremioCapitaleAssicuratoUpdate = {...caratteristicheContrattoDatiPremioCapitaleAssicuratoUpdate}.datiPremioCapitaleAssicurato;
      datiPremioCapitaleAssicuratoUpdate.datiPremioCapitaleAssicuratoValida = action.unDato;
      caratteristicheContrattoDatiPremioCapitaleAssicuratoUpdate.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoUpdate};
      return {
    	...state,
    	caratteristicheContratto: caratteristicheContrattoDatiPremioCapitaleAssicuratoUpdate,
      };      
    case actionType.UPDATE_TARIFFA:
      const caratteristicheContrattoTariffa = {...state}.caratteristicheContratto;
      const datiPremioCapitaleAssicuratoTariffa = {...caratteristicheContrattoTariffa}.datiPremioCapitaleAssicurato;
      datiPremioCapitaleAssicuratoTariffa.tariffa = unValore;
      datiPremioCapitaleAssicuratoTariffa.descTariffa = unaLabel;
      caratteristicheContrattoTariffa.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoTariffa};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoTariffa,
      };
    case actionType.UPDATE_EDIZIONE_MODELLO:
      const caratteristicheContrattoEdizioneModello = {...state}.caratteristicheContratto;
      const datiPremioCapitaleAssicuratoEdizioneModello = {...caratteristicheContrattoEdizioneModello}.datiPremioCapitaleAssicurato;
      datiPremioCapitaleAssicuratoEdizioneModello.edizioneModello = action.unDato;
      caratteristicheContrattoEdizioneModello.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoEdizioneModello};
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoEdizioneModello,
      }; 
    case actionType.UPDATE_DURATA:
        const caratteristicheContrattoDurata = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoDurata = {...caratteristicheContrattoDurata}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoDurata.durata = action.unDato;
        caratteristicheContrattoDurata.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoDurata};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoDurata,
        }; 
    case actionType.UPDATE_DURATA_PAGAMENTO_PREMI:
        const caratteristicheContrattoDurataPagamentoPremi = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoDurataPagamentoPremi = {...caratteristicheContrattoDurataPagamentoPremi}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoDurataPagamentoPremi.durataPagamentoPremi = action.unDato;
        caratteristicheContrattoDurataPagamentoPremi.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoDurataPagamentoPremi};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoDurataPagamentoPremi,
        }; 
    case actionType.UPDATE_CAPITALE_ASSICURATO:
        const caratteristicheContrattoCapitaleAssicurato = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoCapitaleAssicurato = {...caratteristicheContrattoCapitaleAssicurato}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoCapitaleAssicurato.capitaleAssicurato = action.unDato;
        caratteristicheContrattoCapitaleAssicurato.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoCapitaleAssicurato};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoCapitaleAssicurato,
        }; 
    case actionType.UPDATE_MALATTIA_GRAVE:
        const caratteristicheContrattoMalattiaGrave = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoMalattiaGrave = {...caratteristicheContrattoMalattiaGrave}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoMalattiaGrave.malattiaGrave = action.unDato;
        caratteristicheContrattoMalattiaGrave.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoMalattiaGrave};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoMalattiaGrave,
        };  
    case actionType.UPDATE_CAPITALE_ASSICURATO_MALATTIA_GRAVE:
        const caratteristicheContrattoCapitaleAssicuratoMalattiaGrave = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoCapitaleAssicuratoMalattiaGrave = {...caratteristicheContrattoCapitaleAssicuratoMalattiaGrave}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoCapitaleAssicuratoMalattiaGrave.capitaleAssicuratoMalattiaGrave = unValore;
        datiPremioCapitaleAssicuratoCapitaleAssicuratoMalattiaGrave.descCapitaleAssicuratoMalattiaGrave = unaLabel;
        caratteristicheContrattoCapitaleAssicuratoMalattiaGrave.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoCapitaleAssicuratoMalattiaGrave};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoCapitaleAssicuratoMalattiaGrave,
        };
    case actionType.UPDATE_COMPLEMENTARE_INFORTUNI:
        const caratteristicheContrattoComplementareInfortuni = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoComplementareInfortuni = {...caratteristicheContrattoComplementareInfortuni}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoComplementareInfortuni.complementareInfortuni = action.unDato;
        caratteristicheContrattoComplementareInfortuni.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoComplementareInfortuni};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoComplementareInfortuni,
        }; 
    case actionType.UPDATE_FRAZIONAMENTO:
        const caratteristicheContrattoFrazionamento = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoFrazionamento = {...caratteristicheContrattoFrazionamento}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoFrazionamento.frazionamento = unValore;
        datiPremioCapitaleAssicuratoFrazionamento.descFrazionamento = unaLabel;
        caratteristicheContrattoFrazionamento.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoFrazionamento};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoFrazionamento,
        };
    case actionType.UPDATE_PREMIO_RATA:
        const caratteristicheContrattoPremioRata = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoPremioRata = {...caratteristicheContrattoPremioRata}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoPremioRata.premioRata = action.unDato;
        caratteristicheContrattoPremioRata.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoPremioRata};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoPremioRata,
        }; 
    case actionType.UPDATE_PREMIO_UNICO:
        const caratteristicheContrattoPremioUnico = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoPremioUnico = {...caratteristicheContrattoPremioUnico}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoPremioUnico.premioUnico = action.unDato;
        caratteristicheContrattoPremioUnico.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoPremioUnico};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoPremioUnico,
        }; 
    case actionType.UPDATE_PREMIO_ANNUO:
        const caratteristicheContrattoPremioAnnuo = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoPremioAnnuo = {...caratteristicheContrattoPremioAnnuo}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoPremioAnnuo.premioAnnuo = action.unDato;
        caratteristicheContrattoPremioAnnuo.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoPremioAnnuo};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoPremioAnnuo,
        }; 
    case actionType.UPDATE_APPENDICE_COSTI_PREMIO_CAPITALE_ASSIC:
        const caratteristicheContrattoAppendiceCosti = {...state}.caratteristicheContratto;
        const datiPremioCapitaleAssicuratoAppendiceCosti = {...caratteristicheContrattoAppendiceCosti}.datiPremioCapitaleAssicurato;
        datiPremioCapitaleAssicuratoAppendiceCosti.appendiceCosti = action.unDato;
        caratteristicheContrattoAppendiceCosti.datiPremioCapitaleAssicurato = {...datiPremioCapitaleAssicuratoAppendiceCosti};
        return {
          ...state,
          caratteristicheContratto: caratteristicheContrattoAppendiceCosti,
        };                 
    default:
      return state;
  }
}

export const inizializzaPremioCapitaleAssicurato = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiPremioCapitaleAssicurato = jsonCopy(datiPremioCapitaleAssicuratoInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiPremioCapitaleAssicuratoReducer;