import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withTranslation } from 'react-i18next';

import { textTrim } from '../../utility/genericUtility';

const styles = theme => ({
  extFieldError: theme.textFieldError,	
});

class TextFieldAdornComp extends React.Component {
		
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      typing: false,
      isError: false,
    }
  };
  
  handleChange = event => {
//    console.log('TextFieldAdornComp old value this.state.value: ', this.state.value);
//    console.log('TextFieldAdornComp new value event.target.value: ', event.target.value);
    this.setState({
      value: event.target.value,
      typing: true,
    });
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  };

  handleOnBlur = event => {
//    console.log('TextFieldAdornComp handleOnBlur value this.state.value:', this.state.value);
//    console.log('TextFieldAdornComp handleOnBlur value event.target.value:', event.target.value);
    this.setState({
      value: event.target.value,
      typing: false,
    });
    if(this.props.onBlur) {
      this.props.onBlur(event);
    }
  };
		  
  handleError = (isError, id) => {
//    console.log('#######TextFieldAdornComp handleError', id + ' ' + isError);
    this.setState({
      isError: isError,
    });    
    if(this.props.onError) {
      this.props.onError(id, isError);
    }
  };  
	  
  valueText = (trim) => {
    const aValue = (this.state.typing === true) ? textTrim(this.state.value, trim) : textTrim(this.props.value, trim);
    return aValue;
  };
	  
  render () {
    const { classes, onChange, aStyle, id, label, value, required, variantStyle, adornComp, adornPosit, controlloDati, disabled } = this.props;
    const { t } = this.props; //hoc		
    const variant = (variantStyle) ? variantStyle : 'outlined';
//    console.log('#RT2 TextFieldAdornComp render props.value', value);
//    console.log('#RT2 TextFieldAdornComp render this.state.value', this.state.value); 
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueText(controlloDati);    
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === '');    
    const aStyleToApply = (isError) ? classes.textFieldError : aStyle;        
    return (
      <TextField
        error={isError}
        required={isRequired}
        disabled={isDisabled}      
        id={id}
        label={t(label)}
        className={classNames(aStyle, aStyleToApply)}
        value={aValue}
        onChange={this.handleChange}
        onBlur={this.handleOnBlur}
        margin='normal'
        variant={variant}
        InputProps={{
          endAdornment: (
            <InputAdornment position={adornPosit}>
              {adornComp}
            </InputAdornment>
          ),
        }}
      />
    )
  }; 
  
  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;	  
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueText(controlloDati);
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === '');
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
//      console.log('#######prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, isError = ' + isError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);      
    }
  };  
};

TextFieldAdornComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(TextFieldAdornComp));
