import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import RadioButtonComp from '../../../common/RadioButtonComp';

import { radioButtonOpzioneContrattuale, radioButtonOpzioneContrattualeUL } from '../../../store/radioButtonStore';

import DatiPianoErogazioniPeriodiche from './DatiPianoErogazioniPeriodiche';
import DatiTakeProfit from './DatiTakeProfit';
import DatiLifeCycle from './DatiLifeCycle';
import DatiPianoSpostamentoAutomatico from './DatiPianoSpostamentoAutomatico';

import * as actions from './action/datiOpzioniContrattuali';
import * as actionType from './actionType/datiOpzioniContrattuali';
import { textTrim } from '../../../../utility/genericUtility';
import { DATI_PIANO_EROGAZIONI_PERIODICHE, DATI_TAKE_PROFIT, DATI_LIFE_CYCLE, DATI_NESSUNO,
	     PRODOTTO_TIPOLOGIA_MULTINVEST, PRODOTTO_TIPOLOGIA_UNIT_LINKED, PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 } from '../../../../utility/jsConstants';

const styles = theme => ({
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiOpzioniContrattuali.mapStateToProps.state', state);
  return {
    datiOpzioniContrattuali: state.areaDatiOpzioniContrattualiReducer.caratteristicheContratto.datiOpzioniContrattuali,
    prodotto: state.areaProdottoReducer.prodotto,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiOpzioniContrattuali.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiOpzioniContrattuali: (unDato, unActionType) => dispatch(actions.updateDatiOpzioniContrattuali(unDato, unActionType)),
  };
};

class DatiOpzioniContrattuali extends React.Component {

  constructor(props) {
    super(props);
    const datiOpzioniContrattuali = this.props.datiOpzioniContrattuali;
    this.state = {
      opzioneContrattuale: datiOpzioniContrattuali.opzioneContrattuale,
    };
  };

  handleChangeOpzioneContrattuale = event => {
    //    console.log('#RTCFA DatiOpzioniContrattuali opzioneContrattuale new value:' + event.target.value);
    const nuovaOpzioneContrattuale = textTrim(event.target.value, true);
    const vecchiaOpzioneContrattuale = textTrim(this.state.opzioneContrattuale, true);
    if (nuovaOpzioneContrattuale !== vecchiaOpzioneContrattuale) {
      this.props.updateDatiOpzioniContrattuali(nuovaOpzioneContrattuale, actionType.UPDATE_OPZIONE_CONTRATTUALE);
      this.setState({
        opzioneContrattuale: nuovaOpzioneContrattuale,
      });
    }
  };

  render() {
    const { classes, datiOpzioniContrattuali, prodotto } = this.props;
    const tipoProdotto = prodotto.tipo;
    
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12}>        
        {/*20230313 PFD - MISTEP-IFA: Gestione PRODOTTO MISTEP M118*/}
      	{prodotto.codice === PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 &&      	
      		<Grid item xs={12}>  
      			<DatiPianoSpostamentoAutomatico id='datiSpostamentoAutomatico' onError={this.handleError} />
      		</Grid>
      	}
      	
      	{/*20230313 PFD - MISTEP-IFA: Gestione PRODOTTO MULTIINVEST TRDIZIONALE*/}
      	{prodotto.codice !== PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 && <>
      		{tipoProdotto === PRODOTTO_TIPOLOGIA_MULTINVEST &&
      			<RadioButtonComp onChange={this.handleChangeOpzioneContrattuale} radioComp={radioButtonOpzioneContrattuale} classes={{divider: classes.divider}} 
                	selectedValue={datiOpzioniContrattuali.opzioneContrattuale} required={false} id='opzioneContrattuale' onError={this.handleError} />
            }
            {tipoProdotto === PRODOTTO_TIPOLOGIA_UNIT_LINKED &&
            	<RadioButtonComp onChange={this.handleChangeOpzioneContrattuale} radioComp={radioButtonOpzioneContrattualeUL} classes={{divider: classes.divider}} 
            	selectedValue={datiOpzioniContrattuali.opzioneContrattuale} required={false} id='opzioneContrattuale' onError={this.handleError} />      		
            }
            </>
        }
        </Grid>
        {datiOpzioniContrattuali.opzioneContrattuale === DATI_NESSUNO &&	
        <Grid item xs={12}></Grid>
        }
        {datiOpzioniContrattuali.opzioneContrattuale === DATI_PIANO_EROGAZIONI_PERIODICHE &&	
        <Grid item xs={12}>  
          <DatiPianoErogazioniPeriodiche id='datiPianoErogazioniPeriodiche' onError={this.handleError} />
        </Grid>
        }
        {datiOpzioniContrattuali.opzioneContrattuale === DATI_TAKE_PROFIT &&	
        <Grid item xs={12}>  
          <DatiTakeProfit id='datiTakeProfit' onError={this.handleError} />
        </Grid>
        }
        {datiOpzioniContrattuali.opzioneContrattuale === DATI_LIFE_CYCLE &&	
        <Grid item xs={12}>  
          <DatiLifeCycle id='datiLifeCycle' onError={this.handleError} />
        </Grid>
        }
      </Grid>
    );
  }
}

DatiOpzioniContrattuali.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiOpzioniContrattuali));
