import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import TextFieldComp from '../../../common/TextFieldComp';

import * as actions from './action/datiModPagReimpiego';
import * as actionType from './actionType/datiModPagReimpiego';
import { textTrim } from '../../../../utility/genericUtility';

const styles = theme => ({
  textField: theme.textField,
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiModPagReimpiego.mapStateToProps.state', state);
  return {
    datiModPagReimpiego: state.areaDatiModPagReimpiegoReducer.caratteristicheContratto.datiModPagReimpiego,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModPagReimpiego.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiModPagReimpiego: (unDato, unActionType) => dispatch(actions.updateDatiModPagReimpiego(unDato, unActionType)),
  };
};
	
class DatiModPagReimpiego extends React.Component {
	
  constructor(props) {
    super(props);
	const datiModPagReimpiego = this.props.datiModPagReimpiego;
	this.state = {
	  polizza: datiModPagReimpiego.polizza,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
	};
  };
	
  handleChangePolizza = event => {
    //console.log('#RTCFA DatiModPagReimpiego polizza new value:' + event.target.value);
    const nuovaPolizza = textTrim(event.target.value, true);
    const vecchiaPolizza = textTrim(this.state.polizza, true); 
    if (nuovaPolizza !== vecchiaPolizza) {	  
      this.props.updateDatiModPagReimpiego(nuovaPolizza, actionType.UPDATE_POLIZZA);   
      this.setState({ 
        polizza: nuovaPolizza 
      });
    }  
  };
  
  initStatoCampi = () => {
    return [ {id: 'polizza', isError: false},        
    	   ];
  }
  
  handleError = (id, isError) => {   
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }    

  render() {
    const { classes, datiModPagReimpiego, controlloDati } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">  
        <Grid item xs={12}>
          <TextFieldComp onBlur={this.handleChangePolizza} aStyle={classes.textField} id='polizza' label='polizzaPolizze' value={datiModPagReimpiego.polizza} 
                         required={true} controlloDati={controlloDati} onError={this.handleError} />
        </Grid>	
      </Grid>
    );
  }
    
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiModPagAssegno componentDidUpdate this.state.statoCampi', this.state.statoCampi);
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
    
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }    
}

DatiModPagReimpiego.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiModPagReimpiego));

