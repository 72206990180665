import { QUESTIONARIO_ADEGUATEZZA_FISICO, QUESTIONARIO_ADEGUATEZZA_GIURIDICO, 
	     QUESTIONARIO_ANAMNESTICO, QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE, QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO } from '../../../utility/jsConstants';

export const datiQuestionarioAdeguatezzaFisicoInizializzato = {
	tipoQuestionario: QUESTIONARIO_ADEGUATEZZA_FISICO,
    datiQuestionarioValida: null, 
	controlloDati: '',
	esito: '',
	esitoMessaggio: '',
	testataQuestionario: {
	  codSocieta: '',
	  codiceProdotto: '',
	  profiloInvestimento: '',
	  codiceQuestionario: '',
	  dataProposta: '',
	},
	questionarioAdeguatezzaFisico : [{
	  sezione: {
		testo: 'QUESTIONARIO PER LA VALUTAZIONE DELLA RISPONDENZA DEL PRODOTTO ALLE RICHIESTE ED ESIGENZE DEL CLIENTE',
	  },
	  domande: [{
	    id: '1',
	    numDomanda: '0',
	    testo: '',
	    risposte: [{}],
	  }],
	}, /* //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP -  START Rinumerazione di tutti gli id: e numDomanda: del questionario {
	sezione: {
	  testo: '',
	  },
	  domande: [{
	    id: '3',
	    numDomanda: '1',
	    testo: 'Il cliente rifiuta la compilazione del questionario?',
	    risposte: [{
		  id: '4',  
	      testo: 'SI',
	      tipo: 'C',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],
	  }],
	}, //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP -  END */ {
	sezione: {
	  id: '2',
	  testo: 'A. Informazioni anagrafiche',
	  },
	  domande: [{
		id: '3',
		numDomanda: '1',
		testo: 'a1. Età',
	    risposte: [{
	      id: '4',  
	      testo: 'fino a 25',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
	      id: '5',  
	      testo: 'da 26 a 40',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
	      id: '6',  
	      testo: 'da 41 a 55',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
	      id: '7',  
	      testo: 'da 56 a 65',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
	      id: '8',  
	      testo: 'oltre 65',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],
	  }],
	}, {
	sezione: {
	  id: '9', 
	  testo: 'B. Informazioni sullo stato occupazionale',
	  },
	  domande: [{
	    id: '10',	
	    numDomanda: '2',
	    testo: 'b1. Qual è attualmente il Suo stato occupazionale?',
		risposte: [{
		  id: '11', 
		  testo: 'occupato con contratto a tempo indeterminato',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{
			  id: '12', 
			  testo: 'dipendente',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			}, {
			  id: '13', 
			  testo: 'quadro / dirigente',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			}],
		}, {
		  id: '14',   
		  testo: 'autonomo',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{
			  id: '15', 
			  testo: 'artigiano',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			}, {
			  id: '16', 
			  testo: 'libero professionista',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			}, {
			  id: '17', 
			  testo: 'commerciante',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			}],
		}, {
		  id: '18', 
		  testo: 'pensionato',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
		}, {
		  id: '19',   
		  testo: 'occupato con contratto temporaneo, a progetto, co.co.co, ecc.',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
		}, {
		  id: '20', 
		  testo: 'non occupato',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
		}, {
		  id: '21',
	      testo: 'altro (specificare).',
		  tipo: 'R',
		  tipoCampoAggiuntivo: 'T',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
		}],
	  }],
	}, {
	sezione: {
	  id: '22',
	  testo: 'C. Informazioni sulle persone da tutelare',
	  },
	  domande: [{
	    id: '23',
	    numDomanda: '3',
	    testo: 'c1. Lei ha eventuali soggetti da tutelare (carichi familiari o altri soggetti)?',
	    risposte: [{
		  id: '24',
	      testo: 'si',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{
			  id: '25', 
			  testo: 'figli',
			  tipo: 'C',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			}, {
			  id: '26', 
			  testo: 'altri',
			  tipo: 'C',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			}],
	    }, {
		  id: '27',
		  testo: 'no',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],
	  }],
	}, {
	sezione: {
	  id: '28',
	  testo: 'D. Informazioni sulla attuale situazione assicurativa',
	  },
	  domande: [{
	    id: '29',
	    numDomanda: '4',
	    testo: 'd1. Attualmente possiede altri prodotti assicurativi vita?',
	    risposte: [{
		  id: '30',
	      testo: 'risparmio/investimento',
	      tipo: 'C',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '31',
		  testo: 'previdenza/pensione complementare',
		  tipo: 'C',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '32',
	      testo: 'protezione assicurativa di rischio (morte, invalidità, malattie gravi, LTC)',
		  tipo: 'C',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '33',
		  testo: 'nessuna copertura assicurativa vita',
		  tipo: 'C',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],	
	  }],
	}, {
	sezione: {
	  id: '34',
	  testo: 'E.Informazioni sulla situazione finanziaria e sull esperienza in materia d\'investimenti in strumenti finanziari',
	  },
	  domande: [{
	    id: '35',
	    numDomanda: '5',
	    testo: 'e1. Qual è la Sua capacità di risparmio annuo?',
	    risposte: [{
		  id: '36',
	      testo: 'fino a 5.000 euro',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '37',
		  testo: 'da 5.001 a 15.000 euro',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '38',
	      testo: 'oltre 15.000 euro',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '40',
		  testo: 'Eventuali informazioni aggiuntive sulla situazione finanziaria',
		  tipo: 'T',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],	
	  }, {
	    id: '41',
	    numDomanda: '6',
	    testo: 'e2. Qual è il Suo patrimonio disponibile all investimento?',
	    risposte: [{
		  id: '42',
	      testo: '',
	      tipo: 'N',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],	
	  }, {
	    id: '43',
	    numDomanda: '7',
	    testo: 'e3. Quali delle seguenti affermazioni descrive meglio le Sue aspettative sull andamento futuro dei Suoi redditi personali?',
	    risposte: [{
		  id: '44',
	      testo: 'in crescita',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '45',
		  testo: 'stazionaria',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '46',
	      testo: 'in diminuzione',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],
	  }, {
	    id: '47',
	    numDomanda: '8',
	    testo: 'e4. Qual è il Suo grado di esperienza e conoscenza degli strumenti e del mercato finanziario?',
	    risposte: [{
		  id: '48',
	      testo: 'basso (conoscenza ed esperienza base del mercato e strumenti finanziari)',
	      tipo: 'R',
	      tipoCampoAggiuntivo: '',
	      selezionato: false,
	      testoSelezionato: '',
	      testoSelezionatoTemp: '',
	      mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '49',
		  testo: 'medio (buona conoscenza ed esperienza del mercato strumenti finanziari)',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }, {
		  id: '50',
	      testo: 'alto (ottima conoscenza degli strumenti finanziari disponibili ed elevata esperienza del mercato strumenti finanziari)',
		  tipo: 'R',
		  tipoCampoAggiuntivo: '',
		  selezionato: false,
		  testoSelezionato: '',
		  testoSelezionatoTemp: '',
		  mostraRisposteCondizionate: 'N',
		  risposteCondizionate: [{}],
	    }],
	  }],
	 }, {
	 sezione: {
		id: '51',
	    testo: 'F. Informazioni sulle aspettative in relazione al contratto',
	    },
	    domande: [{
		  id: '52',
		  numDomanda: '9',
		  testo: 'f1. Quali sono gli obiettivi assicurativo-previdenziali che intende perseguire con il contratto?',
		  risposte: [{
			id: '53',
		    testo: 'risparmio/investimento',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '54',
			testo: 'previdenza/pensione complementare',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
		    id: '55',
			testo: 'protezione assicurativa di rischio (morte, invalidità, malattie gravi, LTC)',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
	    }, {
		  id: '56',
		  numDomanda: '10',
		  testo: 'f2. In relazione agli obiettivi selezionati, quali sono le sue aspettative?',
		  risposte: [{
			id: '57',
		    testo: 'conservazione del capitale investito',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '58',
			testo: 'crescita del capitale investito',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '59',
			testo: 'integrazione del reddito',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '60',
			testo: 'creazione di un piano di risparmio',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '61',
			testo: 'protezione assicurativa di rischio',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '62',
			testo: 'costituzione di una rendita',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
	    }, {
		  id: '63',
		  numDomanda: '11',
		  testo: 'f3. Il Contratto che si intende sottoscrivere è a copertura esclusiva di un mutuo o finanziamento?',
		  risposte: [{
			id: '64',
		    testo: 'si',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '65',
			testo: 'no',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
	    }, {
		  id: '66',
		  numDomanda: '12',
		  testo: 'f4. Qual è l orizzonte temporale che si prefigge per la realizzazione degli obiettivi?',
		  risposte: [{
			id: '67',
		    testo: 'breve (<= 5 anni)',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '68',
			testo: 'medio (6-10 anni)',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '69',
			testo: 'lungo (>10 anni)',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
	    }, {
		  id: '70',
		  numDomanda: '13',
		  testo: 'f5. Quali sono le Sue aspettative rispetto alla capacità di assorbire eventuali perdite sul capitale investito?',
		  risposte: [{
			id: '71',
		    testo: 'voglio che il capitale investito sia garantito in periodi predefiniti o alla scadenza del contratto',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '72',
			testo: 'sono disposto a sopportare che il 100% del capitale investito non sia garantito',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '73',
			testo: 'posso sopportare perdite fino alla perdita totale del capitale investito',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
	    }, {
		  id: '74',
		  numDomanda: '14',
		  testo: 'f6. Qual è il Suo livello di tolleranza al rischio?',
		  risposte: [{
			id: '75',
		    testo: 'basso (non sono disposto ad accettare oscillazioni del valore del mio investimento nel tempo e di conseguenza accetto rendimenti modesti ma sicuri nel tempo)',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '76',
			testo: 'medio (sono disposto ad accettare oscillazioni contenute del valore del mio investimento nel tempo e di conseguenza accetto probabili rendimenti medi a scadenza del contratto o al termine dell orizzonte temporale consigliato)',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '77',
			testo: 'alto (sono disposto ad accettare possibili forti oscillazioni del valore del mio investimento nell ottica di massimizzarne la redditività e nella consapevolezza che ciò comporta dei rischi)',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }],
	    }, {
		  id: '78',
		  numDomanda: '15',
		  testo: 'f7. Intende perseguire i Suoi obiettivi assicurativo-previdenziali attraverso:',
		  risposte: [{
			id: '79',
		    testo: 'versamenti periodici',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '80',
			testo: 'versamento unico',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
	    }, {
		  id: '81',
		  numDomanda: '16',
		  testo: 'f8. Qual è la probabilità di liquidabilità nei primi anni di contratto?',
		  risposte: [{
			id: '82',
		    testo: 'bassa',
		    tipo: 'R',
		    tipoCampoAggiuntivo: '',
		    selezionato: false,
		    testoSelezionato: '',
		    testoSelezionatoTemp: '',
		    mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }, {
			id: '83',
			testo: 'alta',
			tipo: 'R',
			tipoCampoAggiuntivo: '',
			selezionato: false,
			testoSelezionato: '',
			testoSelezionatoTemp: '',
			mostraRisposteCondizionate: 'N',
			risposteCondizionate: [{}],
		  }], 
		}],
	  },
	],
};
export const datiQuestionarioAdeguatezzaGiuridicoInizializzato = {
	tipoQuestionario: QUESTIONARIO_ADEGUATEZZA_GIURIDICO,
	datiQuestionarioValida: null,
	controlloDati: '',
	esito: '',
	esitoMessaggio: '',
	testataQuestionario: {
	  codSocieta: '',
	  codiceProdotto: '',
	  profiloInvestimento: '',
	  codiceQuestionario: '',
	  dataProposta: '',
	},
	questionarioAdeguatezzaGiuridico : [{
		sezione: {
		  testo: 'QUESTIONARIO PER LA VALUTAZIONE DELLA RISPONDENZA DEL PRODOTTO ALLE RICHIESTE ED ESIGENZE DEL CLIENTE',
		  },
		  domande: [{
	        id: '1',
	        numDomanda: '0',
	        testo: '',
	        risposte: [{}],
		  }],
	    }, /* //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP -  START Rinumerazione di tutti gli id: e numDomanda: del questionario {
	    sezione: {
	      testo: '',
	      },
	      domande: [{
	        id: '3',
	        numDomanda: '1',
	        testo: 'Il cliente rifiuta la compilazione del questionario?',
	        risposte: [{
	    	  id: '4',  
	          testo: 'SI',
	          tipo: 'C',
	          tipoCampoAggiuntivo: '',
	          selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	          mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
	        }],
	      }],
	    }, //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP -  END */ {
	    sezione: {
	      id: '2',
	      testo: 'A. Informazioni generali',
	      },
	    domande: [{
		    id: '3',
			  numDomanda: '1',
			  testo: 'a1. Forma giuridica',
				risposte: [{
					id: '4',  
					testo: 'società di capitale',
					tipo: 'R',
					tipoCampoAggiuntivo: '',
					selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{
						id: '5', 
				    testo: 's.p.a',
				    tipo: 'R',
				    tipoCampoAggiuntivo: '',
				    selezionato: false,
				    testoSelezionato: '',
				    testoSelezionatoTemp: '',
					  }, {
					  id: '6', 
					  testo: 's.r.l.',
					  tipo: 'R',
					  tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
						}, {
						id: '7', 
						testo: 's.a.a',
						tipo: 'R',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
						}],
					}, {
					id: '8',  
					testo: 'ditta individuale',
					tipo: 'R',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
					}, {
					id: '9',  
					testo: 'società di persone',
					tipo: 'R',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{
						id: '10', 
						testo: 'semplice',
						tipo: 'R',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
					}, {
						id: '11', 
						testo: 's.n.c.',
						tipo: 'R',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
						}, {
						id: '12', 
						testo: 's.a.s',
						tipo: 'R',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
					}],
					}, {
						id: '13',  
						testo: 'altro (specificare) .',
						tipo: 'R',
						tipoCampoAggiuntivo: 'T',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
						mostraRisposteCondizionate: 'N',
					 risposteCondizionate: [{}],
					}],
				}],
	    }, {
	    sezione: {
	      id: '14',
	      testo: 'B. Informazioni sull Azienda',
	      },
	    domande: [{
	      id: '15',	
		    numDomanda: '2',
		    testo: 'b1. Settore merceologico di appartenenza',
				risposte: [{
				  id: '16', 
				  testo: 'Industria',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
			  	selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				  mostraRisposteCondizionate: 'N',
				  risposteCondizionate: [{}],
				}, {
				  id: '17',   
				  testo: 'Commercio',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
			  	selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				  mostraRisposteCondizionate: 'N',
				  risposteCondizionate: [{}],
				}, {
				  id: '18', 
				  testo: 'Credito e Finanza',
				  tipo: 'R',
			  	tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				  mostraRisposteCondizionate: 'N',
			  	risposteCondizionate: [{}],
				}, {
				  id: '19',   
				  testo: 'Ente Pubblico',
				  tipo: 'R',
			  	tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				  mostraRisposteCondizionate: 'N',
			  	risposteCondizionate: [{}],
				}, {
				  id: '20', 
				  testo: 'Impresa Artigiana',
				  tipo: 'R',
			  	tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				  mostraRisposteCondizionate: 'N',
			  	risposteCondizionate: [{}],
				}, {
			  	id: '21',
		      testo: 'altro (specificare).',
				  tipo: 'R',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
			  	testoSelezionatoTemp: '',
			  	mostraRisposteCondizionate: 'N',
			  	risposteCondizionate: [{}],
				}],
	    	}, {
	      id: '22',	
	  	  numDomanda: '3',
	  	  testo: 'b2. Numero collaboratori',
	  		risposte: [{
	  		  id: '23', 
	  		  testo: 'Amministratori < 5',
	  		  tipo: '',
	  		  tipoCampoAggiuntivo: 'N',
		      selezionato: false,
			  	testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  		}, {
	  		  id: '24',   
	  		  testo: 'Dirigenti < 5',
	  		  tipo: '',
	  		  tipoCampoAggiuntivo: 'N',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  		}, {
	  		  id: '25', 
	  		  testo: 'Quadri < 5',
	  		  tipo: '',
	  		  tipoCampoAggiuntivo: 'N',
		      selezionato: false,
				  testoSelezionato: '',
			  	testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  		}, {
	  		  id: '26',   
	  		  testo: 'Dipendenti < 5',
	  		  tipo: '',
	  		  tipoCampoAggiuntivo: 'N',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  		}, {
	  		  id: '27', 
	  		  testo: 'Consulenti, co.pro. ecc. < 5',
	  		  tipo: '',
	  		  tipoCampoAggiuntivo: 'N',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  		}, {
	  		  id: '28',
	  	      testo: 'altro (specificare) < 5',
	  		  tipo: '',
	  		  tipoCampoAggiuntivo: 'T',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  		}],
	      }],
	    }, {
	    sezione: {
	      id: '29',
	      testo: 'C. Informazioni sulle persone da assicurare',
	      },
	    domande: [{
		    id: '30',
		    numDomanda: '4',
		    testo: 'c1. Chi sono le persone da assicurare?',
		    risposte: [{
				  id: '31',
		      testo: 'Amministratori',
		      tipo: 'C',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
		      risposteCondizionate: [{}],
		    }, {
				  id: '32',
					testo: 'Dirigenti',
					tipo: 'C',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }, {
					id: '33',
					testo: 'Quadri',
					tipo: 'C',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }, {
					id: '34',
					testo: 'Dipendenti',
					tipo: 'C',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }, {
					id: '35',
					testo: 'Consulenti, co.pro. ecc.',
					tipo: 'C',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }, {
					id: '36',
					testo: 'altro (specificare)',
					tipo: 'C',
					tipoCampoAggiuntivo: 'T',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }],
	      }, {
		    id: '37',
		    numDomanda: '5',
		    testo: 'c2. In base a:',
		    risposte: [{
			  	id: '38',
		      testo: 'C.C.N.L.',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
		      risposteCondizionate: [{}],
		    }, {
					id: '39',
					testo: 'Accordo tra le parti (Azienda/Collaboratore)',
					tipo: 'R',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }, {
					id: '40',
					testo: 'Contratto integrativo/Regolamento Aziendale',
					tipo: 'R',
					tipoCampoAggiuntivo: '',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }, {
					id: '41',
					testo: 'altro (specificare)',
					tipo: 'R',
					tipoCampoAggiuntivo: 'T',
					selezionato: false,
					testoSelezionato: '',
					testoSelezionatoTemp: '',
					mostraRisposteCondizionate: 'N',
					risposteCondizionate: [{}],
		    }],
	      }],
	    }, {
	    sezione: {
	      id: '42',
	      testo: 'D. Informazioni sulla attuale situazione assicurativa',
	      },
	    domande: [{
		    id: '43',
		    numDomanda: '6',
		    testo: 'd1. Attualmente possiede altri prodotti assicurativi vita?',
		    risposte: [{
			 		id: '44',
		      testo: 'SI, indicare la  tipologia di prodotti che sono stati sottoscritti',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
		      risposteCondizionate: [{
		        id: '45', 
						testo: 'risparmio/investimento',
						tipo: 'C',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
			    }, {
						id: '46', 
						testo: 'previdenza/pensione complementare',
						tipo: 'C',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
			    }, {
						id: '47', 
						testo: 'protezione assicurativa di rischio (morte, invalidità, malattie gravi, LTC)',
						tipo: 'C',
						tipoCampoAggiuntivo: '',
						selezionato: false,
						testoSelezionato: '',
						testoSelezionatoTemp: '',
			    }],
		    }, {
			  id: '48',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],	
	    }],
	  }, {
		sezione: {
		  id: '49',
	    testo: 'E.Informazioni sulla situazione finanziaria e sull esperienza in materia d investimenti in strumenti finanziari',
		  },
	  domande: [{
		    id: '50',
		    numDomanda: '7',
		    testo: 'e1. Fatturato (riferito all ultimo Bilancio)',
		    risposte: [{
			  id: '51',
		      testo: 'fino a 7.000.000 euro',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '52',
			  testo: 'da 7.000.001 a 30.000.000 euro',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '53',
		      testo: 'oltre 30.000.000 euro',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '55',
			  testo: 'Eventuali informazioni aggiuntive sulla situazione finanziaria',
			  tipo: 'T',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],	
	      }, {
		    id: '56',
		    numDomanda: '8',
		    testo: 'e2. Qual è il patrimonio disponibile all investimento?',
		    risposte: [{
			  id: '57',
		      testo: '',
		      tipo: 'N',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],	
	      }, {
		    id: '58',
		    numDomanda: '9',
		    testo: 'e3. Quali delle seguenti affermazioni descrive meglio l andamento della situazione finanziaria futura dell azienda?',
		    risposte: [{
			  id: '59',
		      testo: 'in crescita',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '60',
			  testo: 'stazionaria',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '61',
		      testo: 'in diminuzione',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
		    id: '62',
		    numDomanda: '10',
		    testo: 'e4. Qual è il suo grado d\'esperienza e conoscenza degli strumenti e del mercato finanziario?',
		    risposte: [{
			  id: '63',
		      testo: 'basso (conoscenza ed esperienza base del mercato e strumenti finanziari)',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '64',
			  testo: 'medio (buona conoscenza ed esperienza del mercato strumenti finanziari)',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }, {
			  id: '65',
		      testo: 'alto (ottima conoscenza degli strumenti finanziari disponibili ed elevata esperienza del mercato strumenti finanziari)',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	     }, {
	     sezione: {
	    	id: '66',
		    testo: 'F. Informazioni sulle aspettative in relazione al contratto',
	        },
		    domande: [{
			  id: '67',
			  numDomanda: '11',
			  testo: 'f1. Quali sono gli obiettivi assicurativo-previdenziali che intende perseguire con il contratto?',
			  risposte: [{
				id: '68',
			    testo: 'Accantonamento (Tfm, Tfr, ecc)',
			    tipo: 'C',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '69',
				testo: 'Investimento',
				tipo: 'C',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
			    id: '70',
				testo: 'Costituzione di garanzia finanziaria',
				tipo: 'C',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
			    id: '71',
				testo: 'previdenza/pensione complementare',
				tipo: 'C',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
			    testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
			    id: '72',
				testo: 'protezione assicurativa di rischio (morte, invalidità, malattie gravi, LTC)',
				tipo: 'C',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }], 
		    }, {
			  id: '73',
			  numDomanda: '12',
			  testo: 'f2. In relazione agli obiettivi selezionati, quali sono le sue aspettative?',
			  risposte: [{
				id: '74',
			    testo: 'conservazione del capitale investito',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '75',
				testo: 'crescita del capitale investito',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '76',
				testo: 'creazione di un piano di risparmio',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '77',
				testo: 'protezione assicurativa di rischio',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '78',
				testo: 'costituzione di una rendita',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }], 
		    }, {
			  id: '79',
			  numDomanda: '13',
			  testo: 'f3. Il Contratto che si intende sottoscrivere è a copertura esclusiva di un mutuo o finanziamento?',
			  risposte: [{
				id: '80',
			    testo: 'si',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '81',
				testo: 'no',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }], 
		    }, {
			  id: '82',
			  numDomanda: '14',
			  testo: 'f4. Qual è l orizzonte temporale che si prefigge per la realizzazione degli obiettivi?',
			  risposte: [{
				id: '83',
			    testo: 'breve (<= 5 anni)',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '84',
				testo: 'medio (6-10 anni)',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '85',
				testo: 'lungo (>10 anni)',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }], 
		    }, {
			  id: '86',
			  numDomanda: '15',
			  testo: 'f5. Quali sono le Sue aspettative rispetto alla capacità di assorbire eventuali perdite sul capitale investito?',
			  risposte: [{
				id: '87',
			    testo: 'voglio che il capitale investito sia garantito in periodi predefiniti o alla scadenza del contratto',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '88',
				testo: 'sono disposto a sopportare che il 100% del capitale investito non sia garantito',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '89',
				testo: 'posso sopportare perdite fino alla perdita totale del capitale investito',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }],
		    }, {
			  id: '90',
			  numDomanda: '16',
			  testo: 'f6. Qual è il suo livello di tolleranza al rischio?',
			  risposte: [{
				id: '91',
			    testo: 'basso (non sono disposto ad accettare oscillazioni del valore del mio investimento nel tempo e di conseguenza accetto rendimenti modesti ma sicuri nel tempo)',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '92',
				testo: 'medio (sono disposto ad accettare oscillazioni contenute del valore del mio investimento nel tempo e di conseguenza accetto probabili rendimenti medi a scadenza del contratto o al termine dell\'orizzonte temporale consigliato)',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '93',
				testo: 'alto (sono disposto ad accettare possibili forti oscillazioni del valore del mio investimento nell\'ottica di massimizzarne la redditivita e nella consapevolezza che ciò comporta dei rischi)',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }],
		    }, {
			  id: '94',
			  numDomanda: '17',
			  testo: 'f7. Intende perseguire gli obiettivi assicurativo-previdenziali attraverso:',
			  risposte: [{
				id: '95',
			    testo: 'versamenti periodici',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '96',
				testo: 'versamento unico',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }], 
		    }, {
			  id: '97',
			  numDomanda: '18',
			  testo: 'f8. Qual è la probabilità di liquidabilità nei primi anni di contratto?',
			  risposte: [{
				id: '98',
			    testo: 'bassa',
			    tipo: 'R',
			    tipoCampoAggiuntivo: '',
			    selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
			    mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }, {
				id: '99',
				testo: 'alta',
				tipo: 'R',
				tipoCampoAggiuntivo: '',
				selezionato: false,
				testoSelezionato: '',
				testoSelezionatoTemp: '',
				mostraRisposteCondizionate: 'N',
				risposteCondizionate: [{}],
			  }], 
		    }],
	      },
	   ],
};
export const datiQuestionarioAnamnesticoInizializzato = {
	tipoQuestionario: QUESTIONARIO_ANAMNESTICO,
    datiQuestionarioValida: null,
	controlloDati: '',
	esito: '',
	esitoMessaggio: '',	
	testataQuestionario: {
	  codSocieta: '',
	  codiceProdotto: '',
	  profiloInvestimento: '',
	  codiceQuestionario: '',
	  dataProposta: '',
	},
	questionarioAnamnestico : [{
		sezione: {
		  testo: 'QUESTIONARIO ANAMNESTICO',
		  },
		  domande: [{
	        id: '1',
	        numDomanda: '0',
	        testo: '',
	        risposte: [{}],
		  }],
	    }, {
	    sezione: {
	      testo: 'Dichiarazione dell\'Assicurando',
	      },
	      domande: [{
	        id: '3',
	        numDomanda: '1',
	        testo: 'L\'Assicurando pratica sports pericolosi? (es. freeclimbing, alpinismo, sub, parapendio, paracadutismo, etc.)',
	        risposte: [{
	    	  id: '4',  
	          testo: 'SI, specificare quali :',
	          tipo: 'R',
	          tipoCampoAggiuntivo: '',
	          selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	          mostraRisposteCondizionate: 'N',
	          risposteCondizionate: [{
				  id: '5', 
				  testo: 'ALPINISMO - ARRAMPICATA- SCALATE  - ESCURSIONISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
			      testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '6', 
				  testo: 'AUTOMOBILISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '7', 
				  testo: 'DELTAPLANO/PARAPENDIO/PARAPLANO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '8', 
				  testo: 'PARACADUTISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
			      testoSelezionatoTemp: '',
				}, {
				  id: '9', 
				  testo: 'MOTOCICLISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '10', 
				  testo: 'MOTONAUTICA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '11', 
				  testo: 'SPORT DI LOTTA (COMPETITIVA O PROFESSIONISTI)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '12', 
				  testo: 'SUBACQUEA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '13', 
				  testo: 'ULTRALEGGERI ',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '14', 
				  testo: 'SPELEOLOGIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '15', 
				  testo: 'VELA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '16', 
				  testo: 'PUGILATO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '17', 
				  testo: 'ARTI MARZIALI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '18', 
				  testo: 'ALIANTE/VOLO A VELA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '19', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '20', 
				  testo: 'Prende parte a gare o competizioni sportive? Come professionista o come dilettante?',
				  tipo: 'T',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
	        }, {
	  		  id: '22',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      },{
	        id: '23',
	        numDomanda: '2',
	        testo: 'L\'Assicurando, nell\'esercizio della sua professione, è esposto a speciali pericoli? (es. contatti con: materie venefiche, esplosive, linee elettriche alta tensione, fonditura, lavori su impalcatura o tetti)',
	        risposte: [{
	    	  id: '24',  
	          testo: 'SI, specificare quali :',
	          tipo: 'R',
	          tipoCampoAggiuntivo: '',
	          selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	          mostraRisposteCondizionate: 'N',
	          risposteCondizionate: [{
				  id: '25', 
				  testo: 'ARCHITETTI, INGEGNERI EDILI, GEOMETRI, IMPRESARI EDILI CON ACCESSO SALTUARIO AI CANTIERI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '26', 
				  testo: 'ACCESSO AD IMPALCATURE PONTI, TETTI, SCALE (ES. MURATORI, ANTENNISTI)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '27', 
				  testo: 'ACCESSO AD IMPIANTI SU IMPALCATURA A CONTATTO CON CORRENTE AD ALTA TENSIONE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '28', 
				  testo: 'ATTIVITA\' SOGGETTE A RISCHI DI MALATTIE PROFESSIONALI (LAVORO IN CEMENTERIE, VETRERIE, CONCERIE, FONDERIE, REPARTI DI VERNICIATURA, ETC.)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '29', 
				  testo: 'COLLAUDO DI VEICOLI O NATANTI A MOTORE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '30', 
				  testo: 'CONTATTO CON CORRENTE AD ALTA TENSIONE, SOSTANZE VENEFICHE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '31', 
				  testo: 'FABBRICAZIONE E MANUTENZIONE DI CASE, PONTI , DIGHE, AUTOSTRADE, ACQUEDOTTI, CIMINIERE, TORRI SENZA USO DI ESPLOSIVI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '32', 
				  testo: 'FINANZIERI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '33', 
				  testo: 'VIGILI DEL FUOCO ',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '34', 
				  testo: 'FORZE DELL\'ORDINE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '35', 
				  testo: 'GUARDIE GIURATE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '36', 
				  testo: 'GUARDIE GIURATE ADDETTE AL TRASPORTO VALORI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '37', 
				  testo: 'GUIDA ABITUALE DI AUTOCARRI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '38', 
				  testo: 'LAVORO IN GALLERIA, CAVE E MINIERE CON L\'USO DI SOSTANZE ESPLOSIVE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '39', 
				  testo: 'PESCA, NAVIGAZIONE E ATTIVITA\' PORTUALI ',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '40', 
				  testo: 'RICERCA, PERFORAZIONE, ESTRAZIONE PETROLIFERA (AD ES. INGEGNERI, GEOLOGI, CHIMICI) SENZA USO DI ESPLOSIVI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '41', 
				  testo: 'GUIDA ABITUALE DI AUTOCARRI CON TRASPORTO SOSTANZE PERICOLOSE (TOSSICHE, INFIAMMABILI, ESPLOSIVE)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '42', 
				  testo: 'FORZE ARMATE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '43', 
				  testo: 'MARINA MERCANTILE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '44', 
				  testo: 'SOMMOZZATORE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '45', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
	        }, {
	  		  id: '46',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      },{
		    id: '47',
		    numDomanda: '3',
		    testo: 'L\'Assicurando guida con continuità autocarri o autotreni?',
		    risposte: [{
			  id: '48',
		      testo: 'SI',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '49', 
				  testo: 'Specificare la merce trasportata',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '50',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	    }, {
	    sezione: {
	      testo: 'Dichiarazioni sanitarie per assunzione del rischio - garanzia CASO MORTE',
	      },
	      domande: [{
		    id: '52',
		    numDomanda: '4',
		    testo: '1. È mai stato ricoverato in Case di cura, Ospedali, ecc. o si è mai sottoposto ad interventi chirurgici (salvo per appendicectomia, ernia inguinale, adenotonsillectomia, chirurgia estetica, parto, emorroidi, ragadi, varici, lesioni a menischi o legamenti, fratture di arti, estrazioni dentali, deviazione del setto nasale, alluce valgo )?',
		    risposte: [{
			  id: '53',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '54', 
				  testo: 'CHIRURGIA BARIATRICA PER OBESITA\'',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '55', 
				  testo: 'TUMORE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '56', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '57', 
				  testo: 'Specificare: Per quali cause - In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '58', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '59', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '60', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '61',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	    }, {
	    sezione: {
	      testo: '2. Ha sofferto o soffre di malattie:',
	      },
	      domande: [{
		    id: '63',
		    numDomanda: '5',
		    testo: '2.1 dell\'apparato respiratorio? (asma, bronchite, enfisema, pleurite, tubercolosi, tumori, altre malattie o disturbi)',
		    risposte: [{
			  id: '64',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
		      testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '65', 
				  testo: 'ASMA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '66', 
				  testo: 'BRONCHITE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '67', 
				  testo: 'BRONCOPATIA CRONICO OSTRUTTIVA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '68', 
				  testo: 'BRONCHIETTASIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '69', 
				  testo: 'ENFISEMA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '70', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '71', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '72', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '73', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '74', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '75',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	    	id: '76',
	  	    numDomanda: '6',
	  	    testo: '2.2 - dell\' apparato cardiocircolatorio? (infarto, angina pectoris, ipertensione, valvulopatie, varici degli arti inferiori, altre malattie o disturbi)',
	  	    risposte: [{
	  		  id: '77',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '78', 
	  			  testo: 'Specificare: Quali - In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '79', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '80', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '81', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '82', 
	  			  testo: 'DICHIARI I VALORI PRESSORI:',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '83', 
	  			  testo: 'PRESSIONE MAX. :',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'N',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '84', 
	  			  testo: 'PRESSIONE MIN .  :',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'N',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '86',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }], 
	      }, {
	  	    id: '87',
		    numDomanda: '7',
		    testo: '2.3 - dell\'apparato digerente? (gastroduodenite, ulcera gastroduodenale, coliti ulcerose, epatite virale, cirrosi epatica, calcolosi della colicisti, tumori, altre malattie e disturbi)',
		    risposte: [{
			  id: '88',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '89', 
				  testo: 'CELIACHIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '90', 
				  testo: 'MORBO DI CHRON',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '91', 
				  testo: 'COLITE ULCEROSA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '92', 
				  testo: 'GASTRITE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '93', 
				  testo: 'ULCERA GASTRICA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '94', 
				  testo: 'ULCERA DUODENALE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '95', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '96', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '97', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
			      testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '98', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
			      testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '99', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '100',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	    	id: '101',
	  	    numDomanda: '8',
	  	    testo: '2.4 - dell\'apparato genito-urinario? (nefriti, calcolosi, ipertrofia prostatica, tumori, alterazioni del ciclo o disturbi genitali)',
	  	    risposte: [{
	  		  id: '102',
	  	      testo: 'SI, specificare quali :',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '103', 
	  			  testo: 'NEFRITE',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '104', 
	  			  testo: 'CALCOLOSI RENALE',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '105', 
	  			  testo: 'NEFROSI',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '106', 
	  			  testo: 'INSUFFICIENZA RENALE',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '107', 
	  			  testo: 'Altro',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '108', 
	  			  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '109', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '110', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '111', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '112',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	      	id: '113',
		    numDomanda: '9',
		    testo: '2.5 - dell\'apparato muscolo-scheletrico? (artriti, artrosi, tumori, altre malattie o disturbi)',
		    risposte: [{
			  id: '114',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '115', 
				  testo: 'ARTRITE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '116', 
				  testo: 'ARTROSI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '117', 
				  testo: 'ARTRITE REUMATOIDE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '118', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '119', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '120', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '121', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '122', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '123',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '124',
	  	    numDomanda: '10',
	  	    testo: '2.6 - del sistema nervoso o della psiche? (epilessia, paresi, paralisi, tumori, nevrosi, ansia, depressioni, schizofrenia, altre malattie o disturbi)',
	  	    risposte: [{
	  		  id: '125',
	  	      testo: 'SI, specificare quali :',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '126', 
	  			  testo: 'SCLEROSI MULTIPLA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '127', 
	  			  testo: 'PARESI',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '128', 
	  			  testo: 'ICTUS',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '129', 
	  			  testo: 'CEFALEA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
			      id: '130', 
				  testo: 'EPILESSIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
			      id: '131', 
				  testo: 'ANSIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
			      id: '132', 
				  testo: 'DEPRESSIONE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '133', 
	  			  testo: 'Altro',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '134', 
	  			  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '135', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '136', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '137', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '138',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '139',
		    numDomanda: '11',
		    testo: '2.7 - del sistema endocrino metabolico? (diabete, alterazioni del colesterolo o degli altri grassi del sangue, alterazioni dell\'ipofisi, della tiroide, del surrene, altre malattie o disturbi)',
		    risposte: [{
			  id: '140',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '141', 
				  testo: 'TIROIDE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '142', 
				  testo: 'DIABETE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '143', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '144', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '145', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '146', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '147', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '148',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '149',
	  	    numDomanda: '12',
	  	    testo: '2.8 - del sangue? (anemie, emorragie, leucemie, linfomi, altre malattie o disturbi)',
	  	    risposte: [{
	  		  id: '150',
	  	      testo: 'SI, specificare quali :',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '151', 
	  			  testo: 'ANEMIA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '152', 
	  			  testo: 'LEUCEMIA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  }, {
	  		      id: '153', 
	  			  testo: 'LINFOMI',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  }, {
			      id: '154', 
				  testo: 'HODGKIN',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '155', 
	  			  testo: 'Altro',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '156', 
	  			  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '157', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '158', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
			 	  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '159', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '160',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '161',
		    numDomanda: '13',
		    testo: '2.9 - del sistema immunitario? (artrite reumatoide, sindrome di Syogren, positività ad HIV, etc.)',
		    risposte: [{
			  id: '162',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '163', 
				  testo: 'CELIACHIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '164', 
				  testo: 'ARTRITE REUMATOIDE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '165', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '166', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '167', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '168', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '169', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '170',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '171',
	  	    numDomanda: '14',
	  	    testo: '3. Fa o ha fatto uso di sostanze stupefacenti o di alcolici oppure ha in questo momento patologie che prevedono un trattamento farmacologico superiore a 30 giorni consecutivi?',
	  	    risposte: [{
	  		  id: '172',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '173', 
	  			  testo: 'Specificare: Quali - In che anno - Da quanto tempo - Quantità giornaliera',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '174', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '175', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '176', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '177',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '178',
		    numDomanda: '15',
		    testo: '4. Fa o ha fatto uso di tabacco?',
		    risposte: [{
			  id: '179',
		      testo: 'SI',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '180', 
				  testo: 'Da quanti anni',
				  tipo: '',
				  tipoCampoAggiuntivo: 'N',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '181', 
				  testo: 'Quantità giornaliera',
				  tipo: '',
				  tipoCampoAggiuntivo: 'N',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '182', 
				  testo: 'Se ha smesso, indichi da quanti anni',
				  tipo: '',
				  tipoCampoAggiuntivo: 'N',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '183',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '184',
	  	    numDomanda: '16',
	  	    testo: '5. Si è sottoposto negli ultimi 12 mesi a indagini diagnostiche od esami ematochimici (ad es. risonanza magnetica, ecografie, TAC, scintigrafie, radiografie, biopsie, colonscopia,ECG) che abbiano dato esiti fuori dalla norma? Oppure è in attesa di effettuare indagini diagnostiche particolari?',
	  	    risposte: [{
	  		  id: '185',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '186', 
	  			  testo: 'Specificare: Quali - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '187',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '188',
		    numDomanda: '17',
		    testo: '6. Dichiari altezza e peso attuali',
		    risposte: [{
			  id: '189',
		      testo: 'Altezza cm. :',
		      tipo: '',
		      tipoCampoAggiuntivo: 'N',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
		      risposteCondizionate: [{}],
		    }, {
			  id: '190',
			  testo: 'Peso     Kg. :',
			  tipo: '',
			  tipoCampoAggiuntivo: 'N',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	    },
	  ],
};

export const datiQuestionarioAnamnesticoMalattiaGraveInizializzato = {
	tipoQuestionario: QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE,
	datiQuestionarioValida: null,
	controlloDati: '',
	esito: '',
	esitoMessaggio: '',
	testataQuestionario: {
	  codSocieta: '',
	  codiceProdotto: '',
	  profiloInvestimento: '',
	  codiceQuestionario: '',
	  dataProposta: '',
	},
	questionarioAnamnesticoMalattiaGrave : [{
		sezione: {
		  testo: 'QUESTIONARIO ANAMNESTICO',
		  },
		  domande: [{
	        id: '1',
	        numDomanda: '0',
	        testo: '',
	        risposte: [{}],
		  }],
	    }, {
	    sezione: {
	      testo: 'Dichiarazione dell\'Assicurando',
	      },
	      domande: [{
	        id: '3',
	        numDomanda: '1',
	        testo: 'L\'Assicurando pratica sports pericolosi? (es. freeclimbing, alpinismo, sub, parapendio, paracadutismo, etc.)',
	        risposte: [{
	    	  id: '4',  
	          testo: 'SI, specificare quali :',
	          tipo: 'R',
	          tipoCampoAggiuntivo: '',
	          selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	          mostraRisposteCondizionate: 'N',
	          risposteCondizionate: [{
				  id: '5', 
				  testo: 'ALPINISMO - ARRAMPICATA- SCALATE  - ESCURSIONISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
			      testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '6', 
				  testo: 'AUTOMOBILISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '7', 
				  testo: 'DELTAPLANO/PARAPENDIO/PARAPLANO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '8', 
				  testo: 'PARACADUTISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
			      testoSelezionatoTemp: '',
				}, {
				  id: '9', 
				  testo: 'MOTOCICLISMO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '10', 
				  testo: 'MOTONAUTICA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '11', 
				  testo: 'SPORT DI LOTTA (COMPETITIVA O PROFESSIONISTI)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '12', 
				  testo: 'SUBACQUEA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '13', 
				  testo: 'ULTRALEGGERI ',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '14', 
				  testo: 'SPELEOLOGIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '15', 
				  testo: 'VELA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '16', 
				  testo: 'PUGILATO',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '17', 
				  testo: 'ARTI MARZIALI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '18', 
				  testo: 'ALIANTE/VOLO A VELA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '19', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '20', 
				  testo: 'Prende parte a gare o competizioni sportive? Come professionista o come dilettante?',
				  tipo: 'T',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
	        }, {
	  		  id: '22',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      },{
	        id: '23',
	        numDomanda: '2',
	        testo: 'L\'Assicurando, nell\'esercizio della sua professione, è esposto a speciali pericoli? (es. contatti con: materie venefiche, esplosive, linee elettriche alta tensione, fonditura, lavori su impalcatura o tetti)',
	        risposte: [{
	    	  id: '24',  
	          testo: 'SI, specificare quali :',
	          tipo: 'R',
	          tipoCampoAggiuntivo: '',
	          selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	          mostraRisposteCondizionate: 'N',
	          risposteCondizionate: [{
				  id: '25', 
				  testo: 'ARCHITETTI, INGEGNERI EDILI, GEOMETRI, IMPRESARI EDILI CON ACCESSO SALTUARIO AI CANTIERI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '26', 
				  testo: 'ACCESSO AD IMPALCATURE PONTI, TETTI, SCALE (ES. MURATORI, ANTENNISTI)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '27', 
				  testo: 'ACCESSO AD IMPIANTI SU IMPALCATURA A CONTATTO CON CORRENTE AD ALTA TENSIONE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '28', 
				  testo: 'ATTIVITA\' SOGGETTE A RISCHI DI MALATTIE PROFESSIONALI (LAVORO IN CEMENTERIE, VETRERIE, CONCERIE, FONDERIE, REPARTI DI VERNICIATURA, ETC.)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '29', 
				  testo: 'COLLAUDO DI VEICOLI O NATANTI A MOTORE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '30', 
				  testo: 'CONTATTO CON CORRENTE AD ALTA TENSIONE, SOSTANZE VENEFICHE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '31', 
				  testo: 'FABBRICAZIONE E MANUTENZIONE DI CASE, PONTI , DIGHE, AUTOSTRADE, ACQUEDOTTI, CIMINIERE, TORRI SENZA USO DI ESPLOSIVI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '32', 
				  testo: 'FINANZIERI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '33', 
				  testo: 'VIGILI DEL FUOCO ',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '34', 
				  testo: 'FORZE DELL\'ORDINE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '35', 
				  testo: 'GUARDIE GIURATE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '36', 
				  testo: 'GUARDIE GIURATE ADDETTE AL TRASPORTO VALORI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '37', 
				  testo: 'GUIDA ABITUALE DI AUTOCARRI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '38', 
				  testo: 'LAVORO IN GALLERIA, CAVE E MINIERE CON L\'USO DI SOSTANZE ESPLOSIVE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '39', 
				  testo: 'PESCA, NAVIGAZIONE E ATTIVITA\' PORTUALI ',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '40', 
				  testo: 'RICERCA, PERFORAZIONE, ESTRAZIONE PETROLIFERA (AD ES. INGEGNERI, GEOLOGI, CHIMICI) SENZA USO DI ESPLOSIVI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '41', 
				  testo: 'GUIDA ABITUALE DI AUTOCARRI CON TRASPORTO SOSTANZE PERICOLOSE (TOSSICHE, INFIAMMABILI, ESPLOSIVE)',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '42', 
				  testo: 'FORZE ARMATE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '43', 
				  testo: 'MARINA MERCANTILE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '44', 
				  testo: 'SOMMOZZATORE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}, {
				  id: '45', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
	        }, {
	  		  id: '46',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      },{
		    id: '47',
		    numDomanda: '3',
		    testo: 'L\'Assicurando guida con continuità autocarri o autotreni?',
		    risposte: [{
			  id: '48',
		      testo: 'SI',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '49', 
				  testo: 'Specificare la merce trasportata',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '50',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	    }, {
	    sezione: {
	      testo: 'Dichiarazioni sanitarie per assunzione del rischio - garanzia CASO MORTE',
	      },
	      domande: [{
		    id: '52',
		    numDomanda: '4',
		    testo: '1. È mai stato ricoverato in Case di cura, Ospedali, ecc. o si è mai sottoposto ad interventi chirurgici (salvo per appendicectomia, ernia inguinale, adenotonsillectomia, chirurgia estetica, parto, emorroidi, ragadi, varici, lesioni a menischi o legamenti, fratture di arti, estrazioni dentali, deviazione del setto nasale, alluce valgo )?',
		    risposte: [{
			  id: '53',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '54', 
				  testo: 'CHIRURGIA BARIATRICA PER OBESITA\'',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '55', 
				  testo: 'TUMORE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '56', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '57', 
				  testo: 'Specificare: Per quali cause - In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '58', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '59', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '60', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '61',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	    }, {
	    sezione: {
	      testo: '2. Ha sofferto o soffre di malattie:',
	      },
	      domande: [{
		    id: '63',
		    numDomanda: '5',
		    testo: '2.1 dell\'apparato respiratorio? (asma, bronchite, enfisema, pleurite, tubercolosi, tumori, altre malattie o disturbi)',
		    risposte: [{
			  id: '64',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
		      testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '65', 
				  testo: 'ASMA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '66', 
				  testo: 'BRONCHITE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '67', 
				  testo: 'BRONCOPATIA CRONICO OSTRUTTIVA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '68', 
				  testo: 'BRONCHIETTASIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '69', 
				  testo: 'ENFISEMA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '70', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '71', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '72', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '73', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '74', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '75',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	    	id: '76',
	  	    numDomanda: '6',
	  	    testo: '2.2 - dell\' apparato cardiocircolatorio? (infarto, angina pectoris, ipertensione, valvulopatie, varici degli arti inferiori, altre malattie o disturbi)',
	  	    risposte: [{
	  		  id: '77',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '78', 
	  			  testo: 'Specificare: Quali - In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '79', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '80', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '81', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '82', 
	  			  testo: 'DICHIARI I VALORI PRESSORI:',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '83', 
	  			  testo: 'PRESSIONE MAX. :',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'N',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '84', 
	  			  testo: 'PRESSIONE MIN .  :',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'N',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '86',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }], 
	      }, {
	  	    id: '87',
		    numDomanda: '7',
		    testo: '2.3 - dell\'apparato digerente? (gastroduodenite, ulcera gastroduodenale, coliti ulcerose, epatite virale, cirrosi epatica, calcolosi della colicisti, tumori, altre malattie e disturbi)',
		    risposte: [{
			  id: '88',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '89', 
				  testo: 'CELIACHIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '90', 
				  testo: 'MORBO DI CHRON',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '91', 
				  testo: 'COLITE ULCEROSA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '92', 
				  testo: 'GASTRITE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '93', 
				  testo: 'ULCERA GASTRICA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '94', 
				  testo: 'ULCERA DUODENALE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '95', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '96', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '97', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
			      testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '98', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
			      testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '99', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '100',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	    	id: '101',
	  	    numDomanda: '8',
	  	    testo: '2.4 - dell\'apparato genito-urinario? (nefriti, calcolosi, ipertrofia prostatica, tumori, alterazioni del ciclo o disturbi genitali)',
	  	    risposte: [{
	  		  id: '102',
	  	      testo: 'SI, specificare quali :',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '103', 
	  			  testo: 'NEFRITE',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '104', 
	  			  testo: 'CALCOLOSI RENALE',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '105', 
	  			  testo: 'NEFROSI',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '106', 
	  			  testo: 'INSUFFICIENZA RENALE',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '107', 
	  			  testo: 'Altro',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '108', 
	  			  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '109', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '110', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '111', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '112',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	      	id: '113',
		    numDomanda: '9',
		    testo: '2.5 - dell\'apparato muscolo-scheletrico? (artriti, artrosi, tumori, altre malattie o disturbi)',
		    risposte: [{
			  id: '114',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '115', 
				  testo: 'ARTRITE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '116', 
				  testo: 'ARTROSI',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '117', 
				  testo: 'ARTRITE REUMATOIDE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '118', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '119', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '120', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '121', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '122', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '123',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '124',
	  	    numDomanda: '10',
	  	    testo: '2.6 - del sistema nervoso o della psiche? (epilessia, paresi, paralisi, tumori, nevrosi, ansia, depressioni, schizofrenia, altre malattie o disturbi)',
	  	    risposte: [{
	  		  id: '125',
	  	      testo: 'SI, specificare quali :',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '126', 
	  			  testo: 'SCLEROSI MULTIPLA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '127', 
	  			  testo: 'PARESI',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '128', 
	  			  testo: 'ICTUS',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '129', 
	  			  testo: 'CEFALEA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
			      id: '130', 
				  testo: 'EPILESSIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
			      id: '131', 
				  testo: 'ANSIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
			      id: '132', 
				  testo: 'DEPRESSIONE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '133', 
	  			  testo: 'Altro',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '134', 
	  			  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '135', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '136', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '137', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '138',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '139',
		    numDomanda: '11',
		    testo: '2.7 - del sistema endocrino metabolico? (diabete, alterazioni del colesterolo o degli altri grassi del sangue, alterazioni dell\'ipofisi, della tiroide, del surrene, altre malattie o disturbi)',
		    risposte: [{
			  id: '140',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '141', 
				  testo: 'TIROIDE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '142', 
				  testo: 'DIABETE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '143', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '144', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '145', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '146', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '147', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '148',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '149',
	  	    numDomanda: '12',
	  	    testo: '2.8 - del sangue? (anemie, emorragie, leucemie, linfomi, altre malattie o disturbi)',
	  	    risposte: [{
	  		  id: '150',
	  	      testo: 'SI, specificare quali :',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '151', 
	  			  testo: 'ANEMIA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '152', 
	  			  testo: 'LEUCEMIA',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  }, {
	  		      id: '153', 
	  			  testo: 'LINFOMI',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		  }, {
			      id: '154', 
				  testo: 'HODGKIN',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '155', 
	  			  testo: 'Altro',
	  			  tipo: 'C',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '156', 
	  			  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '157', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '158', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
			 	  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '159', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '160',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '161',
		    numDomanda: '13',
		    testo: '2.9 - del sistema immunitario? (artrite reumatoide, sindrome di Syogren, positività ad HIV, etc.)',
		    risposte: [{
			  id: '162',
		      testo: 'SI, specificare quali :',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '163', 
				  testo: 'CELIACHIA',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '164', 
				  testo: 'ARTRITE REUMATOIDE',
				  tipo: 'C',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '165', 
				  testo: 'Altro',
				  tipo: 'C',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '166', 
				  testo: 'Specificare: In che anno - Per quanto tempo - Diagnosi',
				  tipo: '',
				  tipoCampoAggiuntivo: 'T',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '167', 
				  testo: 'Specificare se guarito senza conseguenze',
				  tipo: 'X',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '168', 
				  testo: 'SI',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '169', 
				  testo: 'NO',
				  tipo: 'R',
				  tipoCampoAggiuntivo: '',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '170',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '171',
	  	    numDomanda: '14',
	  	    testo: '3. Fa o ha fatto uso di sostanze stupefacenti o di alcolici oppure ha in questo momento patologie che prevedono un trattamento farmacologico superiore a 30 giorni consecutivi?',
	  	    risposte: [{
	  		  id: '172',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '173', 
	  			  testo: 'Specificare: Quali - In che anno - Da quanto tempo - Quantità giornaliera',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '174', 
	  			  testo: 'Specificare se guarito senza conseguenze',
	  			  tipo: 'X',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '175', 
	  			  testo: 'SI',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  		    }, {
	  		      id: '176', 
	  			  testo: 'NO',
	  			  tipo: 'R',
	  			  tipoCampoAggiuntivo: '',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '177',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '178',
		    numDomanda: '15',
		    testo: '4. Fa o ha fatto uso di tabacco?',
		    risposte: [{
			  id: '179',
		      testo: 'SI',
		      tipo: 'R',
		      tipoCampoAggiuntivo: '',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{
				  id: '180', 
				  testo: 'Da quanti anni',
				  tipo: '',
				  tipoCampoAggiuntivo: 'N',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '181', 
				  testo: 'Quantità giornaliera',
				  tipo: '',
				  tipoCampoAggiuntivo: 'N',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
			    }, {
			      id: '182', 
				  testo: 'Se ha smesso, indichi da quanti anni',
				  tipo: '',
				  tipoCampoAggiuntivo: 'N',
				  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
				}],
		    }, {
			  id: '183',
			  testo: 'NO',
			  tipo: 'R',
			  tipoCampoAggiuntivo: '',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }, {
	        id: '184',
	  	    numDomanda: '16',
	  	    testo: '5. Si è sottoposto negli ultimi 12 mesi a indagini diagnostiche od esami ematochimici (ad es. risonanza magnetica, ecografie, TAC, scintigrafie, radiografie, biopsie, colonscopia,ECG) che abbiano dato esiti fuori dalla norma? Oppure è in attesa di effettuare indagini diagnostiche particolari?',
	  	    risposte: [{
	  		  id: '185',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '186', 
	  			  testo: 'Specificare: Quali - Diagnosi',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '187',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
	        id: '188',
		    numDomanda: '17',
		    testo: '6. Dichiari altezza e peso attuali',
		    risposte: [{
			  id: '189',
		      testo: 'Altezza cm. :',
		      tipo: '',
		      tipoCampoAggiuntivo: 'N',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
		      risposteCondizionate: [{}],
		    }, {
			  id: '190',
			  testo: 'Peso     Kg. :',
			  tipo: '',
			  tipoCampoAggiuntivo: 'N',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],
	    }, {
		sezione: {
		  testo: 'Dichiarazioni sanitarie da compilare per garanzia aggiuntiva MALATTIA GRAVE',
		  },
		  domande: [{
	        id: '191',
			numDomanda: '18',
			testo: '7. Nella sua parentela consanguinea (madre, padre, fratelli, sorelle) ci sono stati casi di malattie del cuore o della circolazione, ictus, tumore, diabete, malattie renali o malattie ereditarie prima dell\'età di 65 anni?',
			risposte: [{
		  	  id: '192',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '193', 
	  			  testo: 'Specificare: Quali - Grado di parentela - A che età - Presenza in vita (SI/NO)',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '194',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }],
	      }, {
    	    id: '195',
			numDomanda: '19',
			testo: '8. Assume abitualmente medicinali?',
			risposte: [{
		  	  id: '196',
	  	      testo: 'SI',
	  	      tipo: 'R',
	  	      tipoCampoAggiuntivo: '',
	  	      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  	      mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{
	  			  id: '197', 
	  			  testo: 'Specificare: Quali - Da quanto tempo - Per quale motivo',
	  			  tipo: '',
	  			  tipoCampoAggiuntivo: 'T',
	  			  selezionato: false,
				  testoSelezionato: '',
				  testoSelezionatoTemp: '',
	  			}],
	  	    }, {
	  		  id: '198',
	  		  testo: 'NO',
	  		  tipo: 'R',
	  		  tipoCampoAggiuntivo: '',
	  		  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
	  		  mostraRisposteCondizionate: 'N',
	  		  risposteCondizionate: [{}],
	  	    }], 	
	      }, {
	        id: '199',
		    numDomanda: '20',
		    testo: '9. Dichiari pressione arteriosa:',
		    risposte: [{
			  id: '200',
		      testo: 'Pressione Max :',
		      tipo: '',
		      tipoCampoAggiuntivo: 'N',
		      selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
		      mostraRisposteCondizionate: 'N',
		      risposteCondizionate: [{}],
		    }, {
			  id: '201',
			  testo: 'Pressione Min. :',
			  tipo: '',
			  tipoCampoAggiuntivo: 'N',
			  selezionato: false,
			  testoSelezionato: '',
			  testoSelezionatoTemp: '',
			  mostraRisposteCondizionate: 'N',
			  risposteCondizionate: [{}],
		    }],
	      }],	    	
		}
	  ],
};

export const datiQuestionarioAnamnesticoSemplificatoInizializzato = {
	    //TODO cambiare tutti gli id, una volta capito se tenere o no le parti su professione e sport
		tipoQuestionario: QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO,
	    datiQuestionarioValida: null,
	    controlloDati: '',
	    esito: '',
	    esitoMessaggio: '',
	    testataQuestionario: {
	        codSocieta: '',
	        codiceProdotto: '',
	        profiloInvestimento: '',
	        codiceQuestionario: '',
	        dataProposta: '',
	    },
	    questionarioAnamnesticoSemplificato: [{
	        sezione: {
	            testo: 'QUESTIONARIO ANAMNESTICO SEMPLIFICATO',
	        },
	        domande: [{
	            id: '1',
	            numDomanda: '0',
	            testo: '',
	            risposte: [{}],
	        }],
	    }, {
	        sezione: {
	            testo: 'Dati dell\'Assicurando',
	        },
	        domande: [{
	            id: '2',
	            numDomanda: '0',
	            testo: '',
	            risposte: [{}]
	        }],
	    }, {
	        sezione: {
	            testo: 'Attività Professionale',
	        },
	        domande: [{
	            id: '3',
	            numDomanda: '1',
	            testo: 'CODICE:',
	            risposte: [{
	                id: '4',
	                testo: '1',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '5',
	                testo: '2',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '6',
	                testo: '3',
	                tipo: 'R',
	                tipoCampoAggiuntivo: 'T',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }],
	    }, {
	        sezione: {
	            testo: 'Attività Sportiva',
	        },
	        domande: [{
	            id: '7',
	            numDomanda: '2',
	            testo: 'CODICE:',
	            risposte: [{
	                id: '8',
	                testo: '1',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '9',
	                testo: '2',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}],
	            }, {
	                id: '10',
	                testo: '3',
	                tipo: 'R',
	                tipoCampoAggiuntivo: 'T',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }],
	    }, {
	        sezione: {
	            testo: '',
	        },
	        domande: [{
	            id: '11',
	            numDomanda: '3',
	            testo: 'Fuma o ha mai fumato sigarette, sigari, pipe, sigarette elettroniche o altro negli ultimi 36 mesi?',
	            risposte: [{
	                id: '12',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '13',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}],
	            }],
	        }],
	    }, {
	        sezione: {
	            testo: 'Dichiarazioni sanitarie per assunzione del rischio - garanzia Caso Morte e Malattie Gravi',
	        },
	        domande: [{
	            id: '14',
	            numDomanda: '4',
	            testo: 'Negli ultimi 5 anni è stato ricoverato o si è sottoposto a visite specialistiche o interventi chirurgici, anche in day hospital, o attualmente è in attesa di farlo (salvo per interventi minori: difetti della vista, deviazione del setto nasale, denti, chirurgia estetica, impianto protesi articolare, appendicite, ernia, parto, emorroidi, ragadi, varici, lesioni a menischi/legamenti/ tendini, fratture, alluce valgo)?',
	            risposte: [{
	                id: '15',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '16',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '17',
	            numDomanda: '0',
	            testo: 'Soffre o ha sofferto di:',
	            risposte: [{

	            }],
	        }, {
	            id: '18',
	            numDomanda: '5',
	            testo: '- Tumori maligni?',
	            risposte: [{
	                id: '19',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '20',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '21',
	            numDomanda: '6',
	            testo: '- Malattie del sistema nervoso o della psiche (psicosi, depressione, ansia, ritardo mentale, demenza, Alzheimer, Parkinson, SLA, sclerosi multipla, epilessia)?',
	            risposte: [{
	                id: '22',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '23',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '24',
	            numDomanda: '7',
	            testo: '- Malattie dell’apparato respiratorio (bronchite cronica, asma, sarcoidosi, enfisema, tubercolosi)?',
	            risposte: [{
	                id: '25',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '26',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '27',
	            numDomanda: '8',
	            testo: '- Malattie dell’apparato cardiocircolatorio (infarto, angina, valvulopatie, aritmie)?',
	            risposte: [{
	                id: '28',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '29',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '30',
	            numDomanda: '9',
	            testo: '- Malattie renali (insufficienza renale, glomerulonefriti, calcoli), genitourinarie?',
	            risposte: [{
	                id: '31',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '32',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '33',
	            numDomanda: '10',
	            testo: '- Malattie del sangue (anemie, policitemie, piastrinopatie, HIV), del sistema immunitario (spondilite anchilosante, artrite psoriasica, artrite reumatoide, connettivopatie, lupus )?',
	            risposte: [{
	                id: '34',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '35',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '36',
	            numDomanda: '11',
	            testo: '- Malattie del sistema endocrino metabolico (diabete, malattie dell’ipofisi o surreni)?',
	            risposte: [{
	                id: '37',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '38',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '39',
	            numDomanda: '12',
	            testo: '- Malattie dell’apparato digerente (esofago di Barret, ulcera, steatosi, epatite, cirrosi, pancreatite, morbo di Crohn, rettocolite ulcerosa, poliposi familiare)?',
	            risposte: [{
	                id: '40',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '41',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '42',
	            numDomanda: '13',
	            testo: 'Fa o ha fatto uso di sostanze stupefacenti, fa o ha fatto abuso di sostanze alcooliche oppure ha in questo momento patologie che prevedono un trattamento farmacologico superiore a 30 giorni consecutivi?',
	            risposte: [{
	                id: '43',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '44',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '45',
	            numDomanda: '14',
	            testo: 'La differenza tra la sua altezza in centimetri ed il suo peso in chilogrammi è inferiore a 80 o superiore a 120?',
	            risposte: [{
	                id: '46',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '47',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }, {
	            id: '48',
	            numDomanda: '15',
	            testo: 'La misurazione della pressione arteriosa è compresa fra i seguenti valori: minima tra 60 e 85 mm Hg, massima tra 90 e 130 mm Hg?',
	            risposte: [{
	                id: '49',
	                testo: 'SI',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }, {
	                id: '50',
	                testo: 'NO',
	                tipo: 'R',
	                tipoCampoAggiuntivo: '',
	                selezionato: false,
	                testoSelezionato: '',
	                testoSelezionatoTemp: '',
	                mostraRisposteCondizionate: 'N',
	                risposteCondizionate: [{}]
	            }],
	        }],
	    },
	    ],
	};