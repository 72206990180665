import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
//import TextFieldComp from '../../common/TextFieldComp';
import Grid from '@material-ui/core/Grid';
//import { withTranslation } from 'react-i18next';

import RadioButtonComp from '../../common/RadioButtonComp';
import TextFieldAdornComp from '../../common/TextFieldAdornComp';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import DividerComp from '../../common/DividerComp';

import { radioButtonConsensoComunicazioneElettronica } from '../../store/radioButtonStore';

import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';
import { CONTRAENTE_FISICO, CONTRAENTE_GIURIDICO } from '../../../utility/jsConstants';

const styles = theme => ({
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  fieldSet: theme.fieldSet,
  divider: {	
  },
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiConsensoComunicazioneElettronica.mapStateToProps.state', state);
  return {
    datiConsensoComElettrFisico: state.areaContraenteReducer.contraenteFisico.datiConsensoComElettr,
	controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiConsensoComElettrGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiConsensoComElettr,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiConsensoComunicazioneElettronica.mapDispatchToProps.dispatch', dispatch);
  return {
	updateDatiConsensoComElettrContraente: (unDato, unActionType, figura) => dispatch(actionsContraente.updateDatiConsensoComElettrContraente(unDato, unActionType, figura)),	  
  };
};
		
class DatiConsensoComunicazioneElettronica extends React.Component {
	
  constructor(props) {
    super(props);
    const datiConsenso = this.datiConsensoFrom(this.props.figura);  
    this.state = {
      flConsensoComElettr: datiConsenso.flConsensoComElettr,
      emailConsensoComElettr: datiConsenso.emailConsensoComElettr,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,        
    };
  };
    
  handleClickConsensoComunicazioneElettronica = (event) => {
//    this.props.updateDatiConsensoComElettrContraente(event.target.value, actionTypeContraente.UPDATE_FL_CONSENSO_COM_ELETTR, this.props.figura);
    if (event.target.value !== 'S') {
      this.setState({
        flConsensoComElettr: event.target.value,
        emailConsensoComElettr: '',
      });    	
      this.props.updateDatiConsensoComElettrContraente('', actionTypeContraente.UPDATE_EMAIL_CONSENSO_COM_ELETTR, this.props.figura);
    } else {
      this.setState({
        flConsensoComElettr: event.target.value,
      });
    }
  }
  
  handleChangeConsensoComunicazioneElettronica = (event) => {
    this.props.updateDatiConsensoComElettrContraente(event.target.value, actionTypeContraente.UPDATE_FL_CONSENSO_COM_ELETTR, this.props.figura);
/*    this.setState({
      flConsensoComElettr: event.target.value,
    });*/
  }
 
  handleChangeEmailConsensoComElettr = (event) => {
    this.props.updateDatiConsensoComElettrContraente(event.target.value, actionTypeContraente.UPDATE_EMAIL_CONSENSO_COM_ELETTR, this.props.figura);
    this.setState({
      emailConsensoComElettr: event.target.value,
    });
  }
  
  datiConsensoFrom = figura => {  
    let datiConsenso = null;
    if (figura === CONTRAENTE_FISICO) {
      datiConsenso = this.props.datiConsensoComElettrFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      datiConsenso = this.props.datiConsensoComElettrGiuridico;
    }
    return datiConsenso;
  }  
  
  controlloDatiFrom = figura => {  
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiContraenteGiuridico;    	
    }
    return controlloDati;
  } 
  
  isRequiredConsensoComunicazioneElettronica = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      required = true;    	
    }
    return required;
  }
  
  isRequiredEmailConsensoComElettr = () => {
    const datiConsenso = this.datiConsensoFrom(this.props.figura); 
    return datiConsenso.flConsensoComElettr === 'S';
  }

  initStatoCampi = () => {
    return [ {id: 'flConsensoComElettr', isError: false},
             {id: 'emailConsensoComElettr', isError: false}, 
           ];
    }
			  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
	  
  render() {
    const { classes, labelFieldSet, figura } = this.props;    
    const controlloDati = this.controlloDatiFrom(figura); 
    const datiConsenso = this.datiConsensoFrom(figura);  
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiFiscali')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1' />
{/*        <Grid container direction='row' justify='flex-start' alignItems='center'> */}
        <Grid container direction='row' justify='center' alignItems='flex-start'>        
          <Grid item md={6} xs={12}>
            <RadioButtonComp radioComp={radioButtonConsensoComunicazioneElettronica} selectedValue={datiConsenso.flConsensoComElettr}
                             onClick={this.handleClickConsensoComunicazioneElettronica} onChange={this.handleChangeConsensoComunicazioneElettronica} 
                             required={this.isRequiredConsensoComunicazioneElettronica()} controlloDati={controlloDati}
                             id='flConsensoComElettr' onError={this.handleError} />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldAdornComp onBlur={this.handleChangeEmailConsensoComElettr} id='emailConsensoComElettr' label='indEmailRecapito' 
            	                value={datiConsenso.emailConsensoComElettr} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} margin='normal' variant='outlined' 
                                adornComp={ <AlternateEmail />} adornPosit='end' required={this.isRequiredEmailConsensoComElettr()} 
                                onError={this.handleError} controlloDati={controlloDati} />
          </Grid>
        </Grid>
	  {/*</fieldset>*/}
	  </Fragment>
    );
  }

  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (    	
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiConsensoComunicazioneElettronica componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiConsensoComunicazioneElettronica componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiConsensoComunicazioneElettronica componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}  
  }  
		  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }   
}

DatiConsensoComunicazioneElettronica.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiConsensoComunicazioneElettronica));
