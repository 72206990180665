import { jsonCopy } from '../../../utility/genericUtility';
import { datiNuovaUtenzaInizializzato, datiRicercaUtenzaInizializzato, datiModificaUtenzaInizializzato, datiLoginCambioPasswordInizializzato, datiPasswordDimenticataInizializzato, 
	datiNuovaPasswordInizializzato, datiInviaNuoveCredenzialiInizializzato} from '../componentiBase/sezioniGestioneUtenza';

export const datiNuovaUtenza = jsonCopy(datiNuovaUtenzaInizializzato);
export const datiRicercaUtenza = jsonCopy(datiRicercaUtenzaInizializzato);
export const datiModificaUtenza = jsonCopy(datiModificaUtenzaInizializzato);
export const datiLoginCambioPassword = jsonCopy(datiLoginCambioPasswordInizializzato);
export const datiPasswordDimenticata = jsonCopy(datiPasswordDimenticataInizializzato);
export const datiNuovaPassword = jsonCopy(datiNuovaPasswordInizializzato);
export const datiInviaNuoveCredenziali = jsonCopy(datiInviaNuoveCredenzialiInizializzato);
