import * as actionType from '../actionType/consenso';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiConsensoInizializzato } from '../../../../../session/info/componentiBase/sezioniDichiarazioni';

const areaDichConsensoReducer = (state = initialState, action) => {
    
  switch (action.type) {    
    case actionTypeGeneric.INIZIALIZZA: 
	  const dichiarazioni_INI = inizializzaConsenso(state);
      return {
        ...state,
        dichiarazioni: dichiarazioni_INI,
      };
    case actionType.SALVA_DATI_CONSENSO:  
      const dichiarazioniDatiConsensoSalva = {...state}.dichiarazioni;
      const datiConsensoSalva = {...dichiarazioniDatiConsensoSalva}.datiConsenso;
      datiConsensoSalva.controlloDati = action.unDato;
      dichiarazioniDatiConsensoSalva.datiConsenso = {...datiConsensoSalva};
      return {
    	...state,
    	dichiarazioni: dichiarazioniDatiConsensoSalva,
      };
    case actionType.UPDATE_DATI_CONSENSO_VALIDA:  
      const dichiarazioniDatiConsensoUpdate = {...state}.dichiarazioni;
      const datiConsensoUpdate = {...dichiarazioniDatiConsensoUpdate}.datiConsenso;
      datiConsensoUpdate.datiConsensoValida = action.unDato;
      dichiarazioniDatiConsensoUpdate.datiConsenso = {...datiConsensoUpdate};
      return {
    	...state,
    	dichiarazioni: dichiarazioniDatiConsensoUpdate,
      };      
    case actionType.UPDATE_FL_CONSENSO1_CONTRATTO:    
      const dichiarazioniDatiConsensoConsenso1 = {...state}.dichiarazioni;
      const datiConsensoUpdateConsenso1 = {...dichiarazioniDatiConsensoConsenso1}.datiConsenso;
      datiConsensoUpdateConsenso1.flConsenso1Contratto = action.unDato;
      dichiarazioniDatiConsensoConsenso1.datiConsenso = {...datiConsensoUpdateConsenso1};
      return {
        ...state, 
        dichiarazioni: dichiarazioniDatiConsensoConsenso1,
      };
    case actionType.UPDATE_FL_CONSENSO2_MARKETING:    
      const dichiarazioniDatiConsensoConsenso2 = {...state}.dichiarazioni;
      const datiConsensoUpdateConsenso2 = {...dichiarazioniDatiConsensoConsenso2}.datiConsenso;
      datiConsensoUpdateConsenso2.flConsenso2Marketing = action.unDato;
      dichiarazioniDatiConsensoConsenso2.datiConsenso = {...datiConsensoUpdateConsenso2};      
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiConsensoConsenso2,
      };   
    case actionType.UPDATE_FL_CONSENSO3_STATISTIC:    
      const dichiarazioniDatiConsensoConsenso3 = {...state}.dichiarazioni;
      const datiConsensoUpdateConsenso3 = {...dichiarazioniDatiConsensoConsenso3}.datiConsenso;
      datiConsensoUpdateConsenso3.flConsenso3Statistic = action.unDato;
      dichiarazioniDatiConsensoConsenso3.datiConsenso = {...datiConsensoUpdateConsenso3};            
      return {
        ...state,
        dichiarazioni: dichiarazioniDatiConsensoConsenso3,
      };   
    case actionType.UPDATE_FL_CONSENSO4_COMUNICAZ: 
      const dichiarazioniDatiConsensoConsenso4 = {...state}.dichiarazioni;
      const datiConsensoUpdateConsenso4 = {...dichiarazioniDatiConsensoConsenso4}.datiConsenso;
      datiConsensoUpdateConsenso4.flConsenso4Comunicaz = action.unDato;
      dichiarazioniDatiConsensoConsenso4.datiConsenso = {...datiConsensoUpdateConsenso4};       
      return {
  	    ...state,
        dichiarazioni: dichiarazioniDatiConsensoConsenso4,
      };   
    default:
      return state;
  }
}

export const inizializzaConsenso = (state) => {
  const dichiarazioni_INI = {...state}.dichiarazioni;
  dichiarazioni_INI.datiConsenso = jsonCopy(datiConsensoInizializzato);
  return dichiarazioni_INI;
}

export default areaDichConsensoReducer;