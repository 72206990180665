import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoTitolareEff from '../sezione/TabDatiRiepilogoTitolareEff';
import DatiRelazContr from '../sezione/DatiRelazContr';
import DatiGeneraliFisico from '../sezione/DatiGeneraliFisico';
import DatiGeneraliMinimi from '../sezione/DatiGeneraliMinimi';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiDomicilio from '../sezione/DatiDomicilio';
import DatiRecapito from '../sezione/DatiRecapito';
import DatiAttivitaEconomica from '../sezione/DatiAttivitaEconomica';
import DatiAntiriciclaggio from '../sezione/DatiAntiriciclaggio';
import DatiFiscali from '../sezione/DatiFiscali';

import DividerComp from '../../common/DividerComp';
import ButtonComp from '../../common/ButtonComp';

import { trovaDatiTitolareEffInList, toNumber } from '../../../utility/genericUtility';
import { locale_it, TITOLARE_EFF_BENEF_VITA, 
	     CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, CODICE_SAE_FIDUCIARIA_GESTIONE, 
	     CODICE_QUALIFICA_FIDUCIANTE } from '../../../utility/jsConstants';
import { creaTitolareEff } from '../../../utility/objectUtility';
import { errorFiducianteVita } from '../../../utility/storeUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

	     
import * as actions from './action/titolareEff';
import * as actionType from './actionType/titolareEff';
import * as actionsBenef from './action/beneficiarioVita';
import * as actionTypeBenef from './actionType/beneficiarioVita';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    button: theme.button,
    textField: theme.textField,
    textErrorMessage: theme.textErrorMessage,
});


const mapStateToProps = state => {
    return {
        titolareEffBenefVitaValida: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.anagraficaValida,
        datiGeneraliMinimiTitEffBenef: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiGeneraliMinimi,
        controlloDati: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
        titolareEffBenefVitaList: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVitaList,
        titolareBenefVitaEff: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita,
        datiRelazContr: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiRelazContr,
        benefVitaSae: state.areaBenefVitaReducer.benefVitaGiuridico.datiAttEconomica.codSAE,
        isFiduciariaFromCopia: state.areaBenefVitaReducer.benefVitaGiuridico.datiCopiaDaAltraFigura.isFiduciaria,
        scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
        tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateDatiGeneraliMinimiTitolareEffBenefVita: (unDato, unActionType) => dispatch(actions.updateDatiGeneraliMinimiTitolareEffBenefVita(unDato, unActionType)),
        confermaAggiungiTitolareEffBenefVita: (currTitolareEff) => dispatch(actions.confermaAggiungiTitolareEffBenefVita(currTitolareEff)),
        nuovoTitolareEffBenefVita: () => dispatch(actions.nuovoTitolareEffBenefVita()),
        updateTitolareEffBenefVita: (currTitolareEff) => dispatch(actions.updateTitolareEffBenefVita(currTitolareEff)),
        aggiungiTitolareAlProprioBenefVitaGiuridico: (currTitolareEff) => dispatch(actionsBenef.aggiungiTitolareAlProprioBenefVitaGiuridico(currTitolareEff)),
        setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
    }
}

class TitolareEffBenefVitaAss extends React.Component {
    constructor(props) {
        super(props);
        //    console.log('#RTCFA TitolareEffAss constructor props', this.props);
        this.state = {
            percentualeTotale: 0,
            isConfermaTitEffBenefVita: false,
            isNuovoTitEffBenefVita: false,
            statoCampi: this.initStatoCampi(),
            isPresenteErrore: null,
        }
    };

    getIsFiduciaria = () => {
        let isFiduciaria = false;
        if(this.props.isFiduciariaFromCopia) {
            isFiduciaria = true;
        } else {
            isFiduciaria =
            this.props.benefVitaSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE 
         || this.props.benefVitaSae === CODICE_SAE_FIDUCIARIA_GESTIONE;
        }
        return isFiduciaria;
    }

    initStatoCampi = () => {
        return [{ id: 'datiGeneraliMinimi', isError: false },
                { id: 'datiRelazContr', isError: false },
        ];
    }

    handleError = (id, isError) => {
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
            unErrore = campoOnId[0];
            unErrore.isError = isError;
        } else {
            unErrore = { id: id, isError: isError };
        }
        this.setState({
            statoCampi: [...campoWithoutId, unErrore],
        });
    }

    handleClickConfermaTitolareEffBenefVita = (event) => {
        this.props.updateDatiGeneraliMinimiTitolareEffBenefVita(true, actionType.SALVA_TITOLARE_EFF_BENEF_VITA);
        this.props.updateDatiGeneraliMinimiTitolareEffBenefVita(false, actionType.SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS);
        this.setState({
            isConfermaTitEffBenefVita: true,
            isNuovoTitEffBenefVita: false,
        });
    }

    handleClickNuovoTitolareEffBenefVita = (event) => {
        this.props.nuovoTitolareEffBenefVita();
        this.props.updateDatiGeneraliMinimiTitolareEffBenefVita(false, actionType.SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS);
        this.setState({
            isConfermaTitEffBenefVita: false,
            isNuovoTitEffBenefVita: true,
            isPresenteErrore: null,
        });
    }

    confermaTitolareEffBenefVita = () => {

        this.props.updateDatiGeneraliMinimiTitolareEffBenefVita(errorFiducianteVita(this.getIsFiduciaria()), actionTypeBenef.UPDATE_ERRORE_FIDUCIANTE_TE_VITA);
    
        const currTitEff = this.props.titolareBenefVitaEff;
        const anagraficaValidaBenefVita = this.props.titolareEffBenefVitaValida;
        const isFiduciaria = this.getIsFiduciaria();
        if (anagraficaValidaBenefVita === true) {
            let isNuovo = false;
            // Trova per Codice Fiscale
            //const datiTitEffInLista = trovaDatiTitolareEffInList(this.props.titolareEffBenefVitaList, currTitEff, locale_it);
            
            let datiTitEffInLista = null;      
            const codiceFiscaleTitEff = isFiduciaria ? currTitEff.datiTitolareBenefVitaEff.datiGenerali.codiceFiscale : currTitEff.datiTitolareBenefVitaEff.datiGeneraliMinimi.codiceFiscale;
            this.props.titolareEffBenefVitaList.forEach((item, index, array) => {
                const datiGeneraliItem = isFiduciaria ? item.datiTitolareBenefVitaEff.datiGenerali : item.datiTitolareBenefVitaEff.datiGeneraliMinimi;
                if (datiGeneraliItem.codiceFiscale === codiceFiscaleTitEff) {
                    datiTitEffInLista = item;
                }	
            });	
                
            // Edit figura corrente
            if (!datiTitEffInLista) {
                isNuovo = true;
            }
            // INSERIMENTO NUOVO TITOLARE EFFETTIVO
            if (isNuovo === true) {
                if(this.props.titolareEffBenefVitaList.length < 4) {
                    this.props.confermaAggiungiTitolareEffBenefVita(currTitEff);
                    this.props.aggiungiTitolareAlProprioBenefVitaGiuridico(currTitEff);
                }
            } else {
                // MODIFICA TITOLARE EFFETTIVO
                this.props.updateTitolareEffBenefVita(currTitEff);
            }
            
            //2021-07-01 PFD: TCM_INDIV
            this.handleTcmIndividualeValida();
        }
        this.setState({
            isConfermaTitEffBenefVita: false,
        });
    }

    //2021-07-01 PFD: TCM_INDIV
    //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
    //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
    handleTcmIndividualeValida = () => {	
	  if (this.props.scopeTcmEmissione) {
	    const isTcmIndValida = isTcmIndividualeValida(locale_it);
	  	// se cambiata 
	  	if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
	  	  this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
	  	}
	  } 
    }
    
    /* errorFiduciante = () => {
        const isFiduciaria = this.getIsFiduciaria();
        const currentTit = this.props.titolareBenefVitaEff;
        const titList = this.props.titolareEffBenefVitaList;
        let isFiducianteCurrent = false;
        let isPresenteFiducianteInList = false;
        if(currentTit.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
          isFiducianteCurrent = true;
        }
        if(titList.length > 0 && titList[0].datiTitolareBenefVitaEff) {
          titList.map((el, i) => {
            if(el.datiTitolareBenefVitaEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
              isPresenteFiducianteInList = true;
            }
          });
        }
        const presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
        if(isFiduciaria) {
            return !presente;
        } else {
            return null;
        }
      } */

    render() {
        const { classes, t } = this.props;
        const datiGeneraliMinimi = this.props.datiGeneraliMinimiTitEffBenef;
        const datiTitolareBenefVitaEff = this.props.titolareBenefVitaEff.datiTitolareBenefVitaEff;
        const datiRelazContr = this.props.datiRelazContr;
        const controlloDati = this.props.controlloDati;
        const isNuovo = this.state.isNuovoTitEff;
        const percentualeTotaleError = this.state.percentualeTotale !== 0 && parseFloat(this.state.percentualeTotale) > 100.0;
        const isFiduciaria = this.getIsFiduciaria();
        return (
            <Fragment>
                <TabDatiRiepilogoTitolareEff figura={TITOLARE_EFF_BENEF_VITA} tabHeader={'tabHeaderTitolareEffBenef'} titolareEffBenefVitaList={this.props.titolareEffBenefVitaList} />
                
                {(!isFiduciaria) ?
                <>
                <DatiRelazContr id={'datiRelazContr'} figura='TITOLARE_EFF_BENEF_VITA' datiRelazContr={datiRelazContr}  onError={this.handleError} controlloDati={controlloDati} relazioneConContr={'relazioneTitolareContrVita'}/>
                <DatiGeneraliMinimi id={'datiGeneraliMinimi'} datiGeneraliMinimi={datiGeneraliMinimi} onError={this.handleError} controlloDati={controlloDati}
                    figura='TITOLARE_EFF_BENEF_VITA' />
                </>
                :
                <>
                <Grid container direction='row' justify='center' alignItems='flex-start'>
                    <DatiGeneraliFisico figura={TITOLARE_EFF_BENEF_VITA} datiGeneraliTitolareEff={datiTitolareBenefVitaEff.datiGenerali} labelFieldSet='datiGenerali'  
                                        id={'datiGeneraliTitolareEff'} onError={this.handleError} isFiduciaria={isFiduciaria} />
                    <DatiResidenza figura={TITOLARE_EFF_BENEF_VITA} datiResidenzaTitolareEff={datiTitolareBenefVitaEff.datiResidenza} 
                                    id={'datiResidenzaTitolareEff'} onError={this.handleError} isFiduciaria={isFiduciaria} />
                    <DatiDomicilio figura={TITOLARE_EFF_BENEF_VITA} datiDomicilioTitolareEff={datiTitolareBenefVitaEff.datiDomicilio} id='datiDomicilioTitolareEff' 
                                    onChildrenError={this.handleError} labelFieldSet='datiDiDomicilioTitolareEff' isFiduciaria={isFiduciaria} />
                    <DatiRecapito figura={TITOLARE_EFF_BENEF_VITA} datiRecapitoTitolareEff={datiTitolareBenefVitaEff.datiRecapito} labelFieldSet='indirizzoDiCorrispondenza' id={'datiRecapitoTitolareEff'}
                                    onError={this.handleError} isFiduciaria={isFiduciaria} />
                    <DatiAttivitaEconomica figura={TITOLARE_EFF_BENEF_VITA} datiAttEconomicaTitolareEff={datiTitolareBenefVitaEff.datiAttEconomica} labelFieldSet='datiAttivitaEconomica' 
                                            id={'datiAttEconomicaTitolareEff'} onError={this.handleError} isFiduciaria={isFiduciaria} />
                </Grid>
                <DividerComp titolo='datiAntiriciclaggioTitolareEff' variant='subtitle1'/>
                <Grid container direction='row' justify='center' alignItems='flex-start'>
                    <DatiAntiriciclaggio figura={TITOLARE_EFF_BENEF_VITA} datiAntiriciglaggio={datiTitolareBenefVitaEff.datiAntiriciglaggio} 
                                        id={'datiAntiriciclaggioTitolareEff'} onError={this.handleError} isFiduciaria={isFiduciaria} />
                    <DatiFiscali figura={TITOLARE_EFF_BENEF_VITA} datiFiscali={datiTitolareBenefVitaEff.datiFiscali} labelFieldSet='datiFiscaliTitolareEff'
                                id={'datiFiscaliTitolareEff'} onError={this.handleError} isFiduciaria={isFiduciaria} />
                </Grid>
                </>
                }
                <Grid container direction='row' justify='center' alignItems='center'>
                    <Grid item>
                        {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                            <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                        }
                        {(controlloDati === true) && !this.state.isPresenteErrore && percentualeTotaleError && !isNuovo &&
                            <Typography className={classes.textErrorMessage}>{t('errTotPercTitEff')}</Typography>
                        }
                        {(controlloDati === true) && (this.state.isPresenteErrore === false) && !isNuovo &&
                            <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                        }
                        {(controlloDati === true) && (errorFiducianteVita(this.getIsFiduciaria())) &&
                            <Typography className={classes.textErrorMessage}>{t('erroreFiduciante')}</Typography>
                        }
                        {(controlloDati === true) && (this.props.titolareEffBenefVitaList.length === 4) &&
                            <Typography className={classes.textErrorMessage}>{t('massimoTitEff')}</Typography>
                        }
                    </Grid>
                </Grid>
                <Grid container>
                <Grid xs={3}></Grid>
                <Grid xs={3} item>
                    <ButtonComp
                        /* to={linkTo} */
                        /* component={Link} */
                        aClassName={classes.button}
                        buttonLabel={t('conferma')}
                        onClick={this.handleClickConfermaTitolareEffBenefVita}
                    />
                </Grid>
                <Grid xs={3} item>
                    <ButtonComp
                        /* to={linkTo} */
                        /* component={Link} */
                        aClassName={classes.button}
                        buttonLabel={t('nuovoTitEff')}
                        onClick={this.handleClickNuovoTitolareEffBenefVita}
                    />
                </Grid>
                <Grid xs={3}></Grid>
                </Grid>

            </Fragment >
        )
    }

    isConfermaTitolareEffBenefVita = (prevProps, prevState) => {
        if(this.props.titolareEffBenefVitaValida && this.state.isConfermaTitEffBenefVita) {
            this.confermaTitolareEffBenefVita();
        }
    }

    isError(prevProps, prevState) {
        let isPresenteCampiConErrore = false;
        this.state.statoCampi.map(unoStatoCampo => {
            return (
                isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
            );
        });
        if (this.state.isPresenteErrore !== isPresenteCampiConErrore && (this.props.controlloDati !== '')) {
            this.props.updateDatiGeneraliMinimiTitolareEffBenefVita(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA);
            this.setState({
                isPresenteErrore: isPresenteCampiConErrore,
            });
        }
    }



    componentDidUpdate(prevProps, prevState) {
        this.isError(prevProps, prevState);
        this.isConfermaTitolareEffBenefVita(prevProps, prevState);
        if(this.props.titolareEffBenefVitaList.length <= 0 && this.props.titolareEffBenefVitaList.length !== prevProps.titolareEffBenefVitaList.length) {
            this.setState({
                isPresenteErrore: true
            })
        }
    }
}


TitolareEffBenefVitaAss.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TitolareEffBenefVitaAss)));