import moment from 'moment';
import { checkEtaMassima } from './genericUtility'; 
import { QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO } from './jsConstants';


export const esaminaRiposteQuestionario  = (questionario) => {
	let counterDomande = 0;
	
	let counterRisposteSi = 0;
	let counterRisposte = 0;
	
	questionario.map((listaDomande) => {
        listaDomande.domande.map((domanda) => {
          if(domanda.numDomanda && domanda.numDomanda > 0) {
            counterDomande++;
          }
          domanda.risposte.map((risposta) => {
            if(risposta.selezionato) {
              counterRisposte++;
              if(risposta.testo === "SI") {               
                  counterRisposteSi++;               
              }
              if(risposta.testo === '3' && !risposta.testoSelezionato) {
                counterRisposte--;
              }
            }
        });
      });
    });
	
	 return ({
		counterRisposteSi: counterRisposteSi,
		counterRisposte: counterRisposte,
		counterDomande: counterDomande,
    });
}

export const getQuestionarioAnamnestico = (dataNascita, 
		datiPremioCapitaleAssicurato, 
		datiQuestionarioAnamnestico, 
		datiQuestionarioAnamnesticoSemplificato, 
		datiQuestionarioAnamnesticoMalattiaGrave ) => {
	
	let questionario = null;
	const malattiaGrave = datiPremioCapitaleAssicurato.malattiaGrave;	
	if (malattiaGrave) {
		questionario = datiQuestionarioAnamnesticoMalattiaGrave;
	} else {
		// verifica eta
		const isLimiteEtaOK = checkEtaMassima(moment(), dataNascita, 55, 6);
		//verifica risposte
		const riposteQuestionarioSemplificato = esaminaRiposteQuestionario (datiQuestionarioAnamnesticoSemplificato.questionarioAnamnesticoSemplificato)
		
		
		const isQuestionarioSemplificato = isLimiteEtaOK 
		     && datiPremioCapitaleAssicurato.capitaleAssicurato
		     && datiPremioCapitaleAssicurato.capitaleAssicurato <= 200000
		     && riposteQuestionarioSemplificato.counterRisposteSi === 0
//		     && (datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida === null || datiQuestionarioAnamnesticoSemplificato.datiQuestionarioValida)
		     && !datiQuestionarioAnamnestico.datiQuestionarioValida
		     && !datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida;
		
		if (isQuestionarioSemplificato) {
			questionario = datiQuestionarioAnamnesticoSemplificato;
		} else{
			questionario = datiQuestionarioAnamnestico;
		}
	}

	return questionario;
};

