export default {
  'stampaProposta': 'Semplifico.Services.Web/rest/stampa/stampaProposta',	
  'areaProdotto': 'Semplifico.Services.Web/rest/dominio/prodottiUtente',
  'nazioneItems': 'Semplifico.Services.Web/rest/stradario/selectStati',
  'tipoDocumentoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini', 
  'enteRilascioItems': 'Semplifico.Services.Web/rest/dominio/selectDomini', 
  'scopoNaturaRapportoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'tipoSocietaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'beneficiarioGenericoVitaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'beneficiarioGenericoMorteItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'prevalenteAttivitaSvoltaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'prevalenteAttivitaSvoltaGiuridicoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'cittadinanzaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'provinciaItems': 'Semplifico.Services.Web/rest/stradario/selectProvince',
  'comuneItems': 'Semplifico.Services.Web/rest/stradario/selectComuni',
  'toponimoItems': 'Semplifico.Services.Web/rest/stradario/selectTipoToponimo201',
  'stradarioItems': 'Semplifico.Services.Web/rest/stradario/selectIndirizzo201',
  'origineFondiItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'saeItems': 'Semplifico.Services.Web/rest/customer/selectDatiSAE',
  'attPrevFisSaeItems': 'Semplifico.Services.Web/rest/dominio/selectCodSaeFisico',
  'attPrevGiuSaeItems': 'Semplifico.Services.Web/rest/dominio/selectCodSaeGiuridico',
  'atecoItems': 'Semplifico.Services.Web/rest/customer/selectDatiAteco', 
  'allocazioneItems': 'Semplifico.Services.Web/rest/dominio/ripartizionePremioProdotto',
  'fondiItems': 'Semplifico.Services.Web/rest/dominio/fondoPerProdottoInvestimento',
  'percTakeProfitItems': 'Semplifico.Services.Web/rest/dominio/selectPercentualeObiettivoTP',
  'frequenzaErogazioniPeriodicheItems': 'Semplifico.Services.Web/rest/dominio/selectErogazioniPeriodiche',
  'percentualeErogazioniPeriodicheItems': 'Semplifico.Services.Web/rest/dominio/selectErogazioniPeriodiche',
  'modalitaPagamentoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'bancaItems': 'Semplifico.Services.Web/rest/banca/selectBancaList',
  'filialeItems': 'Semplifico.Services.Web/rest/banca/selectFilialeList',
  'consensiItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'dichiarazioniIntermediarioItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'regioneItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'durataItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'classePremioPotenzialeItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'calcoloAdeguatezza': 'Semplifico.Services.Web/rest/questionari/calcoloAdeguatezza',
  'controllaAnamnestico': 'Semplifico.Services.Web/rest/questionari/controllaAnamnestico',
  'controllaCalcolaCodiceFiscale': 'Semplifico.Services.Web/rest/customer/controllaCalcolaCodiceFiscale',
  'controlloIban': 'Semplifico.Services.Web/rest/customer/controlloIban',
  'campiTariffaItems': 'Semplifico.Services.Web/rest/dominio/selectCampiTariffa',
  'tariffaItems': 'Semplifico.Services.Web/rest/dominio/selectTariffePerProdotto',
  'capitaleAssicuratoMalattiaGraveItems': 'Semplifico.Services.Web/rest/dominio/selectCapitaleMGPerProdotto',
  'frequenzaCapitaleAssicuratoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'ruoloXProdottoItems': 'Semplifico.Services.Web/rest/dominio/selectRuoliProdotto',
  'bancaAgenziaDaIbanItems': 'Semplifico.Services.Web/rest/banca/selectBancaFilialeFromIban',
  'inserimentoUtenteItems': 'Semplifico.Services.Web/rest/utente/insertUtente',
  'modificaUtenteItems': 'Semplifico.Services.Web/rest/utente/modificaUtente',
  'revocaUtenteItems': 'Semplifico.Services.Web/rest/utente/revocaUtente',
  'riattivaSbloccaUtenteItems': 'Semplifico.Services.Web/rest/utente/riattivaSbloccaUtente',
  'mandatoItems': 'Semplifico.Services.Web/rest/agenzia/selectMandati',
  'profiloItems': 'Semplifico.Services.Web/rest/dominio/selectProfiliPerProfilo',
  'ricercaUtenteItems': 'Semplifico.Services.Web/rest/utente/ricercaUtenti',
  'cambioPasswordUtenteItems': 'Semplifico.Services.Web/rest/utente/cambioPassword',
  'inviaEmailProposta': 'Semplifico.Services.Web/rest/mail/inviaEmailProposta',
  'recuperoCredenzialiItems': 'Semplifico.Services.Web/rest/utente/recuperoCredenziali',
  'resetPasswordItems': 'Semplifico.Services.Web/rest/utente/resetPassword',
  'inviaNuoveCredenzialiItems': 'Semplifico.Services.Web/rest/mail/inviaMailInizialeCredenziali',
  'nazioneItaliaItems': 'Semplifico.Services.Web/rest/stradario/selectStati',
  'isJavaMailAPI': 'Semplifico.Services.Web/rest/mail/isJavaMailAPI',
  'selectUserListInvioMailInizialeCredenziali': 'Semplifico.Services.Web/rest/mail/selectUserListInvioMailInizialeCredenziali',
  'inviaEmailCredenzialiByUserList': 'Semplifico.Services.Web/rest/mail/inviaEmailCredenzialiByUserList',
  'userMailMaxSize': 'Semplifico.Services.Web/rest/dominio/selectDomini', 
  'selectProfessioniQas': 'Semplifico.Services.Web/rest/dominio/selectProfessioniQas',
  'selectSportsQas': 'Semplifico.Services.Web/rest/dominio/selectSportsQas',
  'relazioneItems': 'Semplifico.Services.Web/rest/dominio/selectDomini', 
  'titoloDiStudioItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'fonteRedditoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'redditoAnnuoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'patrimonioFinanziarioFisicoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'patrimonioFinanziarioGiuridicoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'impegniFinanziariItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'fatturatoAnnuoItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'soggettiDaTutelareItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'iscrizioneRegistroItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'autoritaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'tipoRappresentanzaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'qualificaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'rapportoContrIntItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'profiloRichioItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'tempoProvvistaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'profiloRischioObbligo': 'Semplifico.Services.Web/rest/dominio/profiloRischioObbligo',
  'ambitoAttivitaItems': 'Semplifico.Services.Web/rest/dominio/selectDomini',
  'selectPatrimonioFinanziarioMassimo': 'Semplifico.Services.Web/rest/dominio/selectPatrimonioFinanziarioMassimo',
  'selectPaesiBlackList': 'Semplifico.Services.Web/rest/dominio/selectPaesiBlackList',
  'selectCittadinanzaPaesiBlackList': 'Semplifico.Services.Web/rest/dominio/selectCittadinanzaPaesiBlackList',
  'selectScopeAmleto': 'Semplifico.Services.Web/rest/dominio/selectScopeAmleto',
  'agenziaNonAgeduataOriginiFondi': 'Semplifico.Services.Web/rest/dominio/selectDominiCorrelati',
  'selectScopeTcmEmissione': 'Semplifico.Services.Web/rest/dominio/selectScopeTcmEmissione',
  'allocazioneTargetItems': 'Semplifico.Services.Web/rest/dominio/allocazioneTargetItems',
}