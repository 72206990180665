import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import SelectComp from '../../../common/SelectComp';
import TextFieldComp from '../../../common/TextFieldComp';
import DatePickerComp from '../../../common/DatePickerComp';
import RadioButtonComp from '../../../common/RadioButtonComp';
import DividerComp from '../../../common/DividerComp';

import { radioButtonComportamentoCliente, radioButtonOperzioneCoerente } from '../../../store/radioButtonStore';

import ButtonComp from '../../../common/ButtonComp';

import * as actions from './action/intermediario';
import * as actionType from './actionType/intermediario';
import * as actionsDominio from '../../../common/action/dominio';
import { textTrim, dataFormat, isDataValid } from '../../../../utility/genericUtility';
import moment from 'moment';
import { uris, fetchParam } from '../../../../uris';

const styles = theme => ({
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  //  checkbox: theme.checkbox,
  fieldSet: theme.fieldSet,
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiIntermediarioComp.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiIntermediario: state.areaDichIntermediarioReducer.dichiarazioni.datiIntermediario,
    loginUser: state.areaProdottoReducer.loginUser,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiIntermediarioComp.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiIntermediario: (unDato, unActionType) => dispatch(actions.updateDatiIntermediario(unDato, unActionType)),
    dominioDichiarazioniIntermediario: (dichiarazioniIntermediarioItems) => dispatch(actionsDominio.dominioDichiarazioniIntermediario(dichiarazioniIntermediarioItems)),
    dominioProfiloRischio: (profiloRischioItems) => dispatch(actionsDominio.dominioProfiloRischio(profiloRischioItems)),
    dominioProfiloRischioObbligo: (profiloRischioObbligo) => dispatch(actionsDominio.dominioProfiloRischioObbligo(profiloRischioObbligo)),
    dominioRapportoContraenteIntermediario: (rapportoContrIntermItems) => dispatch(actionsDominio.dominioRapportoContraenteIntermediario(rapportoContrIntermItems)),
  };
};


class DatiIntermediarioComp extends React.Component {
  constructor(props) {
    super(props);
    const dominio = this.props.dominio;
    const datiIntermediario = this.props.datiIntermediario;
    this.state = {
      comportamentoCliente: datiIntermediario.comportamentoCliente,      
      durataRapportoContrInt: datiIntermediario.durataRapportoContrInt,
      descrDurataRapportoContrInt: datiIntermediario.descrDurataRapportoContrInt,
      collocatore: datiIntermediario.collocatore,
      flOperazioneCoerente: datiIntermediario.flOperazioneCoerente,
      profiloRischio: datiIntermediario.profiloRischio,
      descrProfiloRischio: datiIntermediario.descrProfiloRischio,
      /*	  collocatore: textTrim(this.props.loginUser.mandato, true), */
      /* 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario 
       * cognomeColloc: datiIntermediario.cognomeColloc,
      nomeColloc: datiIntermediario.nomeColloc,
      regioneAttivita: datiIntermediario.regioneAttivita,
      descrRegioneAttivita: datiIntermediario.descrRegioneAttivita,
      codIntermediario: datiIntermediario.codIntermediario,
      codRUI: datiIntermediario.codRUI,
      */
      codCognomeNomeColloc: datiIntermediario.codCognomeNomeColloc,
      luogoColloc: datiIntermediario.luogoColloc,
      dataColloc: datiIntermediario.dataColloc,
      dichiarazioniIntermediarioItems: dominio.dichiarazioniIntermediarioItems,
      profiloRischioObbligo: dominio.profiloRischioObbligo,
      profiloRischioItems: dominio.profiloRischioItems,
      rapportoContrIntItems: dominio.rapportoContrIntItems,      
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    };
    
    var a = 1;
    a++;
  };

  componentDidMount() {	  
    //Update Collocatore
	const collocatore = textTrim(this.props.loginUser.mandato, true);
    this.props.updateDatiIntermediario(collocatore, actionType.UPDATE_COLLOCATORE);
    //Update DataColloc
    const dataColloc = moment(new Date());
    this.props.updateDatiIntermediario(dataColloc, actionType.UPDATE_DATA_COLLOCATORE);
    
    
    this.setState({
      collocatore: collocatore,
    });
    
    const dataOggiFormatta = moment().format("DD/MM/YYYY");
    this.loadRapportoContrInt(dataOggiFormatta);
    this.loadProfiliRischio(dataOggiFormatta);
    this.loadProfiloRischioObbligo(dataOggiFormatta);
    this.loadDichiarazioniIntermediario(dataOggiFormatta);
    
  }

  loadRapportoContrInt = (dataOggiFormatta) => {
	//inizializzazione dominio
    if (this.state.rapportoContrIntItems===null || this.state.rapportoContrIntItems===undefined ) {
	    const siglaVersione = 'ZURI';
		const optsRapportoContrInt = {
		  siglaVersione: siglaVersione,
		  dataRichiesta: dataOggiFormatta,
		  codiceCampo: 'TRAT'
	    }
	    fetch(uris.rapportoContrIntItems, fetchParam(optsRapportoContrInt))
		.then(
		  response => response.json(),
		  error => console.error("An Error occured...", error)
		)
		.then((result) => {
		  this.setState({
		  rapportoContrIntItems: result,
		 });
		  this.props.dominioRapportoContraenteIntermediario(result);
	    });
    }
  }
  
  loadProfiliRischio = (dataOggiFormatta) => {
	//inizializzazione dominio
    if (this.state.profiloRischioItems===null || this.state.profiloRischioItems===undefined) {
		const siglaVersione = 'ZURI';
	    const optsProfiloRichio = {
		  siglaVersione: siglaVersione,
	      dataRichiesta: dataOggiFormatta,
		  codiceCampo: 'PRIN'
	    }
		fetch(uris.profiloRichioItems, fetchParam(optsProfiloRichio))
		.then(
		  response => response.json(),
		  error => console.error("An Error occured...", error)
	    )
		.then((result) => {
		  this.setState({
		    profiloRischioItems: result
		  });
		  this.props.dominioProfiloRischio(result);
		});
    }
  }
  
  loadProfiloRischioObbligo = (dataOggiFormatta) => {
	//inizializzazione dominio
	if (this.state.profiloRischioObbligo===null || this.state.profiloRischioObbligo===undefined || this.state.profiloRischioObbligo.length===0) {
		const siglaVersione = 'ZURI';
	    const optsObbligoRischio = {
	      siglaVersione: siglaVersione,
	      dataRichiesta: dataOggiFormatta,
	      mandato: this.props.loginUser.mandato
	    }
	    fetch(uris.profiloRischioObbligo, fetchParam(optsObbligoRischio))
	    .then(
	      response => response.json(),
	      error => console.error("An Error occured...", error)
	    )
	    .then((result) => {
	      this.setState({
	        profiloRischioObbligo: result
	      });
	      this.props.dominioProfiloRischioObbligo(result);
	    });
	}
  }
  
  //20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
  loadDichiarazioniIntermediario = (dataOggiFormatta) => {
	//inizializzazione dominio
	if (this.state.dichiarazioniIntermediarioItems===null || this.state.dichiarazioniIntermediarioItems===undefined) {
	    const opts = {
	      siglaVersione: 'ZURI',
	      codiceCampo: 'TCMP',
	      dataRichiesta: dataOggiFormatta,
	    };
	    this.setState({
	      loaded: false
	    });
	    fetch(uris.dichiarazioniIntermediarioItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      // If request is good update state with fetched data
	      this.setState({
	    	dichiarazioniIntermediarioItems: result,
	      });
	      this.props.dominioDichiarazioniIntermediario(result);
	    });
	}
  }
  
  handleChangeConfermaDatiIntermediario = event => {
    this.props.updateDatiIntermediario(true, actionType.SALVA_DATI_INTERMEDIARIO);
  }

  handleChangeComportamentoCliente = event => {
    const nuovoComportamentoCliente = textTrim(event.target.value, true);
    const vecchioComportamentoCliente = textTrim(this.state.comportamentoCliente, true);
    if (nuovoComportamentoCliente !== vecchioComportamentoCliente) {
      this.props.updateDatiIntermediario(nuovoComportamentoCliente, actionType.UPDATE_COMPORTAMENTO_CLIENTE);
      this.setState({
        comportamentoCliente: nuovoComportamentoCliente,
      });
    }
  };

  handleChangeCollocatore = event => {
    const nuovoCollocatore = textTrim(event.target.value, true);
    const vecchioCollocatore = textTrim(this.state.collocatore, true);
    if (nuovoCollocatore !== vecchioCollocatore) {
      this.props.updateDatiIntermediario(nuovoCollocatore, actionType.UPDATE_COLLOCATORE);
      this.setState({
        collocatore: nuovoCollocatore,
      });
    }
  };

  /* 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario 
  handleChangeCognomeCollocatore = event => {
    const nuovoCognomeColloc = textTrim(event.target.value, true);
    const vecchioCognomeColloc = textTrim(this.state.cognomeColloc, true);
    if (nuovoCognomeColloc !== vecchioCognomeColloc) {
      this.props.updateDatiIntermediario(nuovoCognomeColloc, actionType.UPDATE_COGNOME_COLLOCATORE);
      this.setState({
        cognomeColloc: nuovoCognomeColloc,
      });
    }
  };

  handleChangeNomeCollocatore = event => {
    const nuovoNomeColloc = textTrim(event.target.value, true);
    const vecchioNomeColloc = textTrim(this.state.nomeColloc, true);
    if (nuovoNomeColloc !== vecchioNomeColloc) {
      this.props.updateDatiIntermediario(nuovoNomeColloc, actionType.UPDATE_NOME_COLLOCATORE);
      this.setState({
        nomeColloc: nuovoNomeColloc,
      });
    }
  };

  handleChangeCodIntermediario = event => {
    const nuovoCodIntermediario = textTrim(event.target.value, true);
    const vecchioCodIntermediario = textTrim(this.state.codIntermediario, true);
    if (nuovoCodIntermediario !== vecchioCodIntermediario) {
      this.props.updateDatiIntermediario(nuovoCodIntermediario, actionType.UPDATE_COD_INTERMEDIARIO);
      this.setState({
        codIntermediario: nuovoCodIntermediario,
      });
    }
  };

  handleChangeCodRUI = event => {
    const nuovoCodRUI = textTrim(event.target.value, true);
    const vecchioCodRUI = textTrim(this.state.codRUI, true);
    if (nuovoCodRUI !== vecchioCodRUI) {
      this.props.updateDatiIntermediario(nuovoCodRUI, actionType.UPDATE_COD_RUI);
      this.setState({
        codRUI: nuovoCodRUI,
      });
    }
  };
  
  handleChangeRegioneAttivita = event => {
    const nuovaRegioneAttivita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaRegioneAttivita = textTrim(this.state.regioneAttivita, true);
    if (nuovaRegioneAttivita !== vecchiaRegioneAttivita) {
      this.props.updateDatiIntermediario(event, actionType.UPDATE_REGIONE_ATT_COLLOCATORE);
      this.setState({
        regioneAttivita: (event === null) ? '' : event.value,
        descrRegioneAttivita: (event === null) ? '' : event.label,
      });
    }
  }
  */
  
  handleChangeCodCognomeNomeCollocatore = event => {
    const nuovoCodCognomeNomeColloc = textTrim(event.target.value, true);
    const vecchioCodCognomeNomeColloc = textTrim(this.state.codCognomeNomeColloc, true);
    if (nuovoCodCognomeNomeColloc !== vecchioCodCognomeNomeColloc) {
      this.props.updateDatiIntermediario(nuovoCodCognomeNomeColloc, actionType.UPDATE_COD_COGNOME_NOME_COLLOCATORE);
      this.setState({
    	  codCognomeNomeColloc: nuovoCodCognomeNomeColloc,
      });
    }
  };
	  
  handleChangeLuogoColloc = event => {
    const nuovoLuogoColloc = textTrim(event.target.value, true);
    const vecchioLuogoColloc = textTrim(this.state.luogoColloc, true);
    if (nuovoLuogoColloc !== vecchioLuogoColloc) {
      this.props.updateDatiIntermediario(nuovoLuogoColloc, actionType.UPDATE_LUOGO_COLLOCATORE);
      this.setState({
        luogoColloc: nuovoLuogoColloc,
      });
    }
  };

  handleChangeDataColloc = event => {
    if (isDataValid(event)) {
      const nuovaDataColloc = dataFormat(event, 'DD/MM/YYYY');
      const vecchiaDataColloc = dataFormat(this.state.dataColloc, 'DD/MM/YYYY');
      if (nuovaDataColloc !== vecchiaDataColloc) {
        this.props.updateDatiIntermediario(event, actionType.UPDATE_DATA_COLLOCATORE);
        this.setState({
          dataColloc: event,
        });
      } else {
        console.log('###DATA NON VALIDA DatiIntermediario handleChangeDataColloc', event);
      }
    }
  };

  handleChangeDurataRapportoContrInt = event => {
    const nuovoDurataRapContrInt = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchioDurataRapContrInt = textTrim(this.props.datiIntermediario.durataRapportoContrInt, true);
    if (nuovoDurataRapContrInt !== vecchioDurataRapContrInt) {
      this.props.updateDatiIntermediario(event, actionType.UPDATE_DURATA_RAPPORTO_CONTR_INT);
      this.setState({
        durataRapportoContrInt: (event === null) ? '' : event.value,
        descrDurataRapportoContrInt: (event === null) ? '' : event.label,
      })
    }
  };

  handleChangeOperazioneCoerente = event => {
    const nuovaOperazioneCoerente = textTrim(event.target.value, true);
    const vecchiaOperazioneCoerente = textTrim(this.props.datiIntermediario.flOperazioneCoerente, true);
    if (nuovaOperazioneCoerente !== vecchiaOperazioneCoerente) {
      this.props.updateDatiIntermediario(nuovaOperazioneCoerente, actionType.UPDATE_OPERAZIONE_COERENTE);
      this.setState({
        flOperazioneCoerente: nuovaOperazioneCoerente,
      })
    }
  };

  handleChangeProfiloRischio = event => {
    const nuovoProfiloRischio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchioProfiloRischio = textTrim(this.props.datiIntermediario.profiloRischio, true);
    if (nuovoProfiloRischio !== vecchioProfiloRischio) {
      this.props.updateDatiIntermediario(event, actionType.UPDATE_PROFILO_RISCHIO);
      this.setState({
        profiloRischio: (event === null) ? '' : event.value,
        descrProfiloRischio: (event === null) ? '' : event.label,
      })
    }
  };


  isRequiredComportamentoCliente = () => {
    return true;
  }

  isRequiredDurataRapportoContrInt = () => {
    return this.props.scopeAmleto;
  }

  isRequiredCollocatore = () => {
    return false;
  }
/* 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario 
  isRequiredCognomeCollocatore = () => {
    return true;
  }

  isRequiredNomeCollocatore = () => {
    return true;
  }

  isRequiredCodIntermediario = () => {
    return true;
  }

  isRequiredCodRUI = () => {
    return false;
  }
*/
  
  isRequiredCodCognomeNomeCollocatore = () => {
    return true;
  }
  
  isRequiredLuogoColloc = () => {
    return true;
  }

  isRequiredDataColloc = () => {
    return true;
  }

  isRequiredProfiloRischio = () => {
    let required = false;
    if(this.props.scopeAmleto) {
      if(this.state.profiloRischioObbligo) {
        required = true;
      }
    }
    return required;
  }

  isRequiredOperazioneCoerente = () => {
    return this.props.scopeAmleto;
  }

  isDisabledDurataRapportoContrInt = () => {
    return this.props.scopeAmleto === false;
  }

  isDisabledOperazioneCoerente = () => {
    return this.props.scopeAmleto === false;
  }

  isDisabledProfiloRischio = () => {
    return this.props.scopeAmleto === false;
  }

  initStatoCampi = () => {
    return [{ id: 'comportamentoCliente', isError: false },
    { id: 'collocatore', isError: false },
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario     { id: 'cognomeColloc', isError: false },
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario     { id: 'nomeColloc', isError: false },
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario     { id: 'regioneAttivita', isError: false },
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario     { id: 'codIntermediario', isError: false },
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario     { id: 'codRUI', isError: false },
    { id: 'luogoColloc', isError: false },
    { id: 'dataColloc', isError: false },
    ];
  }

  handleError = (id, isError) => {
    console.log('########DatiIntermediarioComp handleError id', id);
    console.log('########DatiIntermediarioComp handleError isError', isError);
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({
      statoCampi: [...campoWithoutId, unErrore],
    });
  }

  render() {
    const { classes, datiIntermediario, dominio } = this.props;
    const { t } = this.props; //hoc  
    const controlloDati = datiIntermediario.controlloDati;
    return (
      <Fragment>
        {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="h6">{t('datiAnagrafici')}</Typography></legend>*/}
        <DividerComp titolo='valutazioneAiSensiLgs' variant="subtitle1" />
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
          {/*//20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio
            <RadioButtonComp onChange={this.handleChangeComportamentoCliente} selectedValue={datiIntermediario.comportamentoCliente} radioComp={radioButtonComportamentoCliente} radioCompDb={dominio.dichiarazioniIntermediarioItems}
              required={this.isRequiredComportamentoCliente()} controlloDati={controlloDati} id='comportamentoCliente' onError={this.handleError} />
          */}
          <RadioButtonComp onChange={this.handleChangeComportamentoCliente} selectedValue={datiIntermediario.comportamentoCliente} radioComp={this.state.dichiarazioniIntermediarioItems}
          	required={this.isRequiredComportamentoCliente()} controlloDati={controlloDati} id='comportamentoCliente' onError={this.handleError} />

          </Grid>
          <Grid item xs={12}>
            <SelectComp id='durataRapportoContraenteIntermediario' onError={this.handleError} controlloDati={controlloDati} etichetta='durataRapportoContrInt'
              onChange={this.handleChangeDurataRapportoContrInt} required={this.isRequiredDurataRapportoContrInt()} options={this.state.rapportoContrIntItems}
              value={{ value: datiIntermediario.durataRapportoContrInt, label: datiIntermediario.descrDurataRapportoContrInt}} disabled={this.isDisabledDurataRapportoContrInt()} />
          </Grid>
          <Grid item xs={12}>
            <RadioButtonComp id='operazioneCoerente' onChange={this.handleChangeOperazioneCoerente} radioComp={radioButtonOperzioneCoerente}
              selectedValue={datiIntermediario.flOperazioneCoerente} onError={this.handleError} controlloDati={controlloDati} 
              disabled={this.isDisabledOperazioneCoerente()} required={this.isRequiredOperazioneCoerente()} />
          </Grid>
          <Grid item xs={12}>
            <SelectComp id='profiloRischio' onError={this.handleError} controlloDati={controlloDati} etichetta='profiloDiRischio'
              onChange={this.handleChangeProfiloRischio} required={this.isRequiredProfiloRischio()} options={this.state.profiloRischioItems}
              value={{ value: datiIntermediario.profiloRischio, label: datiIntermediario.descrProfiloRischio}} disabled={this.isDisabledProfiloRischio()} />
          </Grid>
        </Grid>
        <DividerComp titolo='dichiarazioniDatiSoggetto' variant="subtitle1" />
        <Grid container direction="row" justify="center" alignItems="flex-start">
        	
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCollocatore} id='collocatore' label='general.collocatore' value={datiIntermediario.collocatore} aStyle={classes.textField}
              required={this.isRequiredCollocatore()} controlloDati={controlloDati} onError={this.handleError} readonly={true} />
          </Grid>
            {/* 2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario 
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCognomeCollocatore} id='cognomeColloc' label='general.cognome' value={datiIntermediario.cognomeColloc}
              aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} required={this.isRequiredCognomeCollocatore()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeNomeCollocatore} id="nomeColloc" label="general.nome" value={datiIntermediario.nomeColloc} aStyle={classes.textFieldWidthPerc}
              aStyleError={classes.textFieldWidthPercError} required={this.isRequiredNomeCollocatore()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>         
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeRegioneAttivita} options={dominio.regioneItems} id="regioneAttivita" etichetta="regioneAttivita" ricerca='comboRicerca'
              value={{ value: datiIntermediario.regioneAttivita, label: datiIntermediario.descrRegioneAttivita }} aStyle={classes.textField}
              onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCodIntermediario} id="codIntermediario" label="codiceIntermediario" value={datiIntermediario.codIntermediario} aStyle={classes.textField}
              required={this.isRequiredCodIntermediario()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCodRUI} id="codRUI" label="codiceRUI" value={datiIntermediario.codRUI} aStyle={classes.textField}
              required={this.isRequiredCodRUI()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          */}
          <Grid item md={8} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCodCognomeNomeCollocatore} id='codCognomeNomeColloc' label='codCognomeNomeColloc' value={datiIntermediario.codCognomeNomeColloc}
              aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} required={this.isRequiredCodCognomeNomeCollocatore()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
        </Grid>
        
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeLuogoColloc} id="luogoColloc" label="general.luogo" value={datiIntermediario.luogoColloc} aStyle={classes.textFieldWidthPerc}
              aStyleError={classes.textFieldWidthPercError} required={this.isRequiredLuogoColloc()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={8} xs={12}>
            <DatePickerComp onChange={this.handleChangeDataColloc} id="dataColloc" label="general.data" value={datiIntermediario.dataColloc} aStyle={classes.textField}
              required={this.isRequiredDataColloc()} controlloDati={controlloDati} onError={this.handleError} readonly/>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
              <Grid item>
                {(this.props.datiIntermediario.controlloDati === true) && (this.state.isPresenteErrore === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(this.props.datiIntermediario.controlloDati === true) && (this.state.isPresenteErrore === false) &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
              </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
              <Grid item>
                <ButtonComp
                  aClassName={classes.button}
                  buttonLabel={'conferma'}
                  onClick={this.handleChangeConfermaDatiIntermediario}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*</fieldset>*/}
      </Fragment>
    );
  }

  isInError(prevProps, prevState) {
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
    });
    console.log('########DatiIntermediarioComp componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    //    console.log('########DatiIntermediarioComp componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    //    console.log('########DatiIntermediarioComp componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
    //	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
    if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiIntermediario.controlloDati === true)) {
      /*      if (this.props.onError) {
              this.props.onError(this.props.id, isPresenteCampiConErrore);
            }
            if (this.props.onChildrenError) {
              this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
            }  */
      this.props.updateDatiIntermediario(!isPresenteCampiConErrore, actionType.UPDATE_DATI_INTERMEDIARIO_VALIDA);
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }
}

DatiIntermediarioComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiIntermediarioComp)));
