import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import RadioButtonComp from '../../common/RadioButtonComp';
import DividerComp from '../../common/DividerComp';
import SelectComp from '../../common/SelectComp';
import TextFieldComp from '../../common/TextFieldComp';

import DatiGeneraliFisico from './DatiGeneraliFisico';
import DatiResidenza from './DatiResidenza';
import DatiAntiriciclaggio from './DatiAntiriciclaggio';
import DatiAttivitaEconomica from './DatiAttivitaEconomica';

import { radioButtonEsisteEsecutore, radioButtonRelazContrEsec, radioButtonEsecutoreDelega } from '../../store/radioButtonStore';

import * as actions from '../figure/action/contraente';
import * as actionType from '../figure/actionType/contraente';

import { textTrim } from  '../../../utility/genericUtility';
import { ESECUTORE_FISICO, CODICE_ALTRA_ORIGINE_FONDI, CODICE_ALTRA_RAPPRESENTANZA, CODICE_ALTRA_RELAZIONE } from '../../../utility/jsConstants';
import DatiRecapito from './DatiRecapito';
import DatiDomicilio from './DatiDomicilio';
import moment from 'moment';
import { uris, fetchParam } from '../../../uris';

const tipoRappresentanzaItems = [
  {value: 'Legale rappresentante', label: 'Legale rappresentante'},
  {value: 'Delegato', label: 'Delegato'},
  {value: 'Tutore', label: 'Tutore'},
  {value: 'Trustee', label: 'Trustee'},
  {value: 'ALTRO', label: 'ALTRO'},
]

const styles = theme => ({
  textField: theme.textField,
  textFieldWidth100: {
    width: '100%',
  },
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },  
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore,
    controlloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.controlloDati,
    pagatorePremio: state.areaDatiModalitaPagamentoReducer.caratteristicheContratto.datiModalitaPagamento.pagatorePremio,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiEsecutore: (unDato, unActionType) => dispatch(actions.updateDatiEsecutore(unDato, unActionType)),
  };
};
	
class DatiEsecutore extends React.Component {
	
  constructor(props) {
	super(props);
    this.state = {
      flEsecutore: this.props.datiEsecutore.flEsecutore,
      relazContrEsecut: this.props.datiEsecutore.relazContrEsecut,
      descrRelazContrEsecut: this.props.datiEsecutore.descrRelazContrEsecut,
      flDelega: this.props.datiEsecutore.flDelega,
      tipoRappresentanza: this.props.datiEsecutore.tipoRappresentanza,
      descrTipoRappresentanza: this.props.datiEsecutore.descrTipoRappresentanza,
      altraRappresentanza: this.props.datiEsecutore.altraRappresentanza,
      origineFondi: this.props.datiEsecutore.origineFondi,
      descrOrigineFondi: this.props.datiEsecutore.descrOrigineFondi,
      altro: this.props.datiEsecutore.altro,
      datiGenerali: this.props.datiEsecutore.datiGenerali,
      datiResidenza: this.props.datiEsecutore.datiResidenza,
      datiAntiriciclaggio: this.props.datiEsecutore.datiAntiriciclaggio,
      datiRecapito: this.props.datiEsecutore.datiRecapito,
      datiAttEconomica: this.props.datiEsecutore.datiAttEconomica,
      tipoRappresentanzaItems: [],
      renderChildren: {
        digitabile: true,
      },
      origineFondiItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,       
    };
  };

  componentDidMount = () => {
    const opts = {
      siglaVersione: 'ZURI',
      dataRichiesta: moment().format("DD/MM/YYYY"),
      codiceCampo: 'TRAP'
    }
    fetch(uris.tipoRappresentanzaItems, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An Error occured...", error)
    )
    .then((result) => {
      this.setState({
        tipoRappresentanzaItems: result
      })
    });
  }

  handleClickEsecutore = (event) => {
//    this.props.updateDatiEsecutore(event.target.value, actionType.UPDATE_FL_ESECUTORE_FISICO);
    if (event.target.value !== 'S') {
      this.setState({
        flEsecutore: event.target.value,
        relazContrEsecut: '',
        flDelega: '',
        origineFondi: '',
        descrOrigineFondi: '',
        altro: '',
        renderChildren: {
          digitabile: false,
        }
      });    	
      this.props.updateDatiEsecutore('', actionType.UPDATE_FL_DELEGA_ESECUTORE_FISICO);
      this.props.updateDatiEsecutore('', actionType.UPDATE_RELAZ_CONTR_ESECUTORE_FISICO);
      this.props.updateDatiEsecutore('', actionType.UPDATE_ORIGINE_FONDI_ESECUTORE_FISICO);
      this.props.updateDatiEsecutore('', actionType.UPDATE_ORIGINE_FONDI_ALTRO_ESECUTORE_FISICO);   
    } else {
      this.setState({
        flEsecutore: event.target.value,      
        renderChildren: {
          digitabile: true,
        }        
      });
    }
	console.log('#####handleClickEsecutore event.target.value', event.target.value);
  }
  
  handleChangeEsecutore = (event) => {
    console.log('#####handleChangeEsecutore event.target.value', event.target.value);	  
    this.props.updateDatiEsecutore(event.target.value, actionType.UPDATE_FL_ESECUTORE_FISICO);
/*    this.setState({
        flEsecutore: event.target.value,
    });*/
  }
  
  handleChangeRelazContrEsec = (event) => {
    const nuovaRelazContrEsec = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiEsecutore = this.props.datiEsecutore;
    const vecchiaRelazContrEsec = textTrim(this.props.datiEsecutore.relazContrEsecut, true);
    if (nuovaRelazContrEsec !== vecchiaRelazContrEsec) {    
      this.props.updateDatiEsecutore(event, actionType.UPDATE_RELAZ_CONTR_ESECUTORE_FISICO);
      this.setState({
        relazContrEsecut: nuovaRelazContrEsec,
        descrRelazContrEsecut: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      });
    }
  }

  handleChangeAltroRelazContr = (event) => {
    const nuovoAltro = textTrim(event.target.value, true);
    const vecchioAltro = textTrim(this.props.datiEsecutore.altroRelazContr, true);
    if (nuovoAltro !== vecchioAltro) {
      this.props.updateDatiEsecutore(nuovoAltro, actionType.UPDATE_ALTRO_RELAZ_CONTR);
      this.setState({
        altro: nuovoAltro, 
      })
    }
  }
  
  handleChangeDelega = (event) => {
    const nuovaDelega = textTrim(event.target.value, true);
    const vecchiaDelega = textTrim(this.props.datiEsecutore.flDelega, true);
    if (nuovaDelega !== vecchiaDelega) {  
	  this.props.updateDatiEsecutore(nuovaDelega, actionType.UPDATE_FL_DELEGA_ESECUTORE_FISICO);
      this.setState({
        flDelega: nuovaDelega,
      });
    }
  };

  handleChangeTipoRappresentanza = event => {
    const nuovaRapp = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiEsecutore = this.props.datiEsecutore;
    const vecchiaRapp = datiEsecutore.tipoRappresentanza;
    if(nuovaRapp !== vecchiaRapp) {
      this.props.updateDatiEsecutore(event, actionType.UPDATE_TIPO_RAPPRESENTANZA_ESECUTORE);
      this.setState({
        tipoRappresentanza: nuovaRapp,
        descrTipoRappresentanza: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      })
    }
  }

  handleChangeAltraRappresentanza = event => {
    const nuovaAltraRapp = textTrim(event.target.value);
    const datiEsecutore = this.props.datiEsecutore;
    const vecchiaAltraRapp = datiEsecutore.altraRappresentanza;
    if(nuovaAltraRapp !== vecchiaAltraRapp) {
      this.props.updateDatiEsecutore(nuovaAltraRapp, actionType.UPDATE_ALTRA_RAPPRESENTANZA);
      this.setState({
        altraRappresentanza: nuovaAltraRapp
      })
    }
  }

  handleChangeOrigineFondi = (event) => {
    const nuovaOrigineFondi = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaOrigineFondi = textTrim(this.props.datiEsecutore.origineFondi, true);    
    if (nuovaOrigineFondi !== vecchiaOrigineFondi) {
	  this.props.updateDatiEsecutore(event, actionType.UPDATE_ORIGINE_FONDI_ESECUTORE_FISICO);
      this.setState({
        origineFondi: (event === null) ? '' : event.value,
        descrOrigineFondi: (event === null) ? '' : event.label,
      });
      if (vecchiaOrigineFondi === 'ALTRO') {
        this.setState({
          altro: '',
        });
        this.props.updateDatiEsecutore('', actionType.UPDATE_ORIGINE_FONDI_ALTRO_ESECUTORE_FISICO);
      }      
    }
  };
  
  handleChangeAltro = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const vecchioAltro = textTrim(this.props.datiEsecutore.altro, true);     
    if (nuovoAltro !== vecchioAltro) {    
	  this.props.updateDatiEsecutore(nuovoAltro, actionType.UPDATE_ORIGINE_FONDI_ALTRO_ESECUTORE_FISICO);
      this.setState({
        altro: nuovoAltro, 
      });
    }
  };

  handleChangeEspostaPoliticamenteEsecutore = event => {
    this.setState({
      datiAntiriciclaggio: this.props.datiEsecutore.datiAntiriciclaggio,
    })
  }
  
  isRequiredEsecutore = () => {
    return true;
  }
  
  isRequiredRelazContrEsec = () => {
    return this.props.datiEsecutore.flEsecutore === 'S';
  }
  
  isRequiredDelega = () => {
    return this.props.datiEsecutore.flEsecutore === 'S';
  }
  
  isRequiredOrigineFondi = () => {
    return this.props.datiEsecutore.datiAntiriciclaggio.espostaPoliticamente === 'S';
  }
  
  isRequiredTipoRappresentanza = () => {
    let required = false;
    const datiEsecutore = this.props.datiEsecutore;
    console.log("hhhhhkhkh", this.props.scopeAmleto === false)
    if(datiEsecutore.flDelega === "S"){
      if(this.props.scopeAmleto === true){      
        required = true;
      }
    }
    return required;// DA FARE L'OBBLIGO
  }
  
  isRequiredAltraRappresentanza = () => {
    const datiEsecutore = this.props.datiEsecutore;
    return textTrim(datiEsecutore.tipoRappresentanza, true) === CODICE_ALTRA_RAPPRESENTANZA;
  }
  
  isRequiredAltro = () => {
    return textTrim(this.props.datiEsecutore.origineFondi, true) === CODICE_ALTRA_ORIGINE_FONDI ;
  }

  isRequiredAltroRelazContr = () => {
    return textTrim(this.props.datiEsecutore.relazContrEsecut, true) === CODICE_ALTRA_RELAZIONE;
  }
  
  initStatoCampi = () => {
    return [ {id: 'flEsecutore', isError: false},
    {id: 'relazContrEsecut', isError: false}, 
    {id: 'flDelega', isError: false},
    {id: 'datiGeneraliEsecutore', isError: false},
    {id: 'datiResidenzaEsecutore', isError: false},
    {id: 'datiAntiriciclaggioEsecutore', isError: false},
    {id: 'origineFondi', isError: false},
    {id: 'altro', isError: false}, 
    {id: 'datiDiDomicilioEsecutore', isError: false},
    {id: 'tipoRappresentanza', isError: false},
    {id: 'altraRappresentanza', isError: false}
  ];
}

handleError = (id, isError) => {
  let unErrore = null;
  let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
  let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
  if (campoOnId.length > 0) {
    unErrore = campoOnId[0];
    unErrore.isError = isError;
  } else {
    unErrore = { id: id, isError: isError };
  }
  this.setState({	
    statoCampi: [...campoWithoutId, unErrore],      
  });
}

isDisabled = () => {
  return this.props.datiEsecutore.flEsecutore === 'N';
}

isDisabledRelaziContrEsec = () => {
  return false;
}

isDisabledAltro = () => {
  const origineFondi = textTrim(this.props.datiEsecutore.origineFondi, true); 
	return this.isDisabled() || (origineFondi !== CODICE_ALTRA_ORIGINE_FONDI);
}

isDisabledAltroRelazContr = () => {
  const relazContrAltro = textTrim(this.props.datiEsecutore.relazContrEsecut, true);
  return this.isDisabled() || (relazContrAltro !== CODICE_ALTRA_RELAZIONE);
}

isDisabledTipoRappresentanza = () => {
  let disabled = true;
  const datiEsecutore = this.props.datiEsecutore;
  if (this.props.scopeAmleto === true){  
    if(datiEsecutore.flDelega === "S"){
      disabled = false
    }
  }
  return disabled;
}

isDisabledAltraRappresentanza = () => { 
  return !this.isRequiredAltraRappresentanza();
}

isDisabledEsecutore = () => {
  let disabled = false;
  if(this.props.pagatorePremio === '2') {
    disabled = true;
  }
  return disabled;
}
    
  render() {
    const { classes, datiEsecutore, labelFieldSet, dominio } = this.props;  
    const controlloDati = this.props.controlloDatiEsecutore;   
    const origFondiItems = dominio.origineFondiItems;
    const origineFondiList = this.isDisabled() !== true ? origFondiItems :  [] ;
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('esecutore')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1'/>
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={datiEsecutore.flEsecutore==='S'?4:4} xs={12}>
            <RadioButtonComp onClick={this.handleClickEsecutore} onChange={this.handleChangeEsecutore} radioComp={radioButtonEsisteEsecutore} selectedValue={datiEsecutore.flEsecutore} //classes={{divider: classes.divider}}
                             required={this.isRequiredEsecutore()} id='flEsecutore' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledEsecutore()} />
          </Grid>
          <Grid item md={datiEsecutore.flEsecutore==='S'?8:8} xs={12}></Grid>
          {datiEsecutore.flEsecutore === 'S' &&
       	    <Fragment>
            <Grid item md={4} xs={12}>
              <SelectComp onChange={this.handleChangeRelazContrEsec} options={dominio.relazioneItems} id='relazContrEsecut' etichetta='relazContrEsecut' controlloDati={controlloDati}
                onError={this.handleError} disabled={this.isDisabledRelaziContrEsec()} required={this.isRequiredRelazContrEsec()}
                value={{ value: datiEsecutore.relazContrEsecut, label: datiEsecutore.descrRelazContrEsecut }} />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextFieldComp id='altroRelazContr' onBlur={this.handleChangeAltroRelazContr} label='general.seAltroSpecificare' value={datiEsecutore.altroRelazContr}
                  onError={this.handleError} controlloDati={controlloDati} aStyle={classNames(classes.textField, classes.textFieldWidth100)} aStyleError={classes.textFieldWidthPercError}
                  disabled={this.isDisabledAltroRelazContr()} required={this.isRequiredAltroRelazContr()} />
              </Grid>
              <Grid item md={4} xs={12}>
                <RadioButtonComp onChange={this.handleChangeDelega} radioComp={radioButtonEsecutoreDelega} selectedValue={datiEsecutore.flDelega} //classes={{divider: classes.divider}}
                  required={this.isRequiredDelega()} id='flDelega' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabled()} />
              </Grid>
                             
              <Grid item md={6} xs={12}>
                <SelectComp onChange={this.handleChangeTipoRappresentanza} options={this.state.tipoRappresentanzaItems} etichetta='tipoRappresentanza' 
                            value={{value: datiEsecutore.tipoRappresentanza, label: datiEsecutore.descrTipoRappresentanza}} required={this.isRequiredTipoRappresentanza()} 
                            id='tipoRappresentanza' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledTipoRappresentanza()} />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldComp onBlur={this.handleChangeAltraRappresentanza} aStyle={classNames(classes.textField, classes.textFieldWidth100)} aStyleError={classes.textFieldWidthPercError} id='altraRappresentanza' label='general.seAltroSpecificare'
                               value={datiEsecutore.altraRappresentanza} required={this.isRequiredAltraRappresentanza()} onError={this.handleError} controlloDati={controlloDati}
                               disabled={this.isDisabledAltraRappresentanza()} /> 
              </Grid>
              <DatiGeneraliFisico figura={ESECUTORE_FISICO} datiGeneraliFisico={datiEsecutore.datiGenerali} renderChildren={this.state.renderChildren} labelFieldSet={'datiGeneraliEsecutore'}
                                  onChangeCodFiscale={this.props.onChangeCodFiscale} id={'datiGeneraliEsecutore'} onChildrenError={this.handleError} /> 
              <DatiResidenza figura={ESECUTORE_FISICO} datiResidenzaContraenteFisico={datiEsecutore.datiResidenza} renderChildren={this.state.renderChildren} 
                             id={'datiResidenzaEsecutore'} onChildrenError={this.handleError} labelFieldSet='datiDiResidenzaEsecutore' />
          {/* <DatiRecapito figura={ESECUTORE_FISICO} datiRecapitoEsecutore={datiEsecutore.datiRecapito} labelFieldSet='datiDiRecapitoEsecutore'
                                      id={'datiDiRecapitoEsecutore'} onError={this.handleError} /> */}
              <DatiDomicilio figura={ESECUTORE_FISICO} datiDomicilioEsecutore={datiEsecutore.datiDomicilio} 
                             id={'datiDiDomicilioEsecutore'} onChildrenError={this.handleError} labelFieldSet='datiDiDomicilioEsecutore' />
              <DatiAttivitaEconomica figura={ESECUTORE_FISICO} datiAttEconomica={datiEsecutore.datiAttEconomica} labelFieldSet='datiAttivitaEconomicaEsecutore' 
                                     id={'datiAttivitaEconomicaEsecutore'} onError={this.handleError} />
              <DatiAntiriciclaggio figura={ESECUTORE_FISICO} datiAntiriciclaggio={datiEsecutore.datiAntiriciclaggio} renderChildren={this.state.renderChildren} 
                                   labelFieldSet='datiAntiriciclaggioEsecutore' onChangeEspostaPoliticamente={this.handleChangeEspostaPoliticamenteEsecutore} 
                                   id={'datiAntiriciclaggioEsecutore'} onChildrenError={this.handleError} /> 
              {/* <Grid item md={4} xs={12}>
                <SelectComp onChange={this.handleChangeOrigineFondi} options={origineFondiList} ricerca='comboRicerca' etichetta='origineFondi' 
                            value={{value: datiEsecutore.origineFondi, label: datiEsecutore.descrOrigineFondi}} 
                            required={this.isRequiredOrigineFondi()} id='origineFondi' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabled()} /> 
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldComp onBlur={this.handleChangeAltro} aStyle={classNames(classes.textField, classes.textFieldWidth100)} id='altro' label='general.seAltroSpecificare' 
                               value={datiEsecutore.altro} required={this.isRequiredAltro()} onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledAltro()} />
              </Grid> */}
            </Fragment>
          }
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  updateEsecutore(prevProps, prevState) {
    console.log('########DatiEsecutore updateEsecutore this.props.datiEsecutore.flEsecutore', this.props.datiEsecutore.flEsecutore);
    console.log('########DatiEsecutore updateEsecutore prevState.flEsecutore', prevState.flEsecutore);	       
//	if ((this.props.datiEsecutore.flEsecutore === 'N') && (prevProps.datiEsecutore.flEsecutore !== 'N')) {
	if ((this.props.datiEsecutore.flEsecutore === 'N') && (prevState.flEsecutore !== 'N')) {    
      this.setState({	
        statoCampi: this.initStatoCampi(),      
      });	
	}
  };
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiEsecutore componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiEsecutore componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiEsecutore componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
		  
  componentDidUpdate(prevProps, prevState) {
    this.updateEsecutore(prevProps, prevState);	  
    this.isInError(prevProps, prevState);
  }  
}

DatiEsecutore.propTypes = {
  classes: PropTypes.object.isRequired,
  dominio: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiEsecutore));