import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uris, fetchParam } from '../../../uris';

import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import * as actionsContraente from '../figure/action/contraente';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeContraente from '../figure/actionType/contraente';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

import { textTrim } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';
import { CONTRAENTE_FISICO, CONTRAENTE_GIURIDICO, PROVINCIA_ESTERA, 
		 TITOLARE_EFFETTIVO, ESECUTORE_FISICO, TITOLARE_EFF_BENEF_MORTE, 
		 TITOLARE_EFF_BENEF_VITA, locale_it } from '../../../utility/jsConstants';

import moment from 'moment';
import 'moment/locale/it';
import RadioButtonComp from '../../common/RadioButtonComp';
import { radioButtonCorrispondenzaDiversoResidenza, radioButtonCorrispondenzaIntestatoContraente } from '../../store/radioButtonStore';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiRecapito.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiRecapitoContraenteFisico: state.areaContraenteReducer.contraenteFisico.datiRecapito,
    controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiRecapitoContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiRecapito,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    datiRecapitoTitolareEffettivo: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiRecapito,
    controlloDatiTitolareEffettivo: state.areaTitolareEffReducer.titolareEff.controlloDati,
    datiRecapitoEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore.datiRecapito,
    controlloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiRecapitoTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiRecapito,
    controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
    datiRecapitoTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiRecapito,
    controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiRecapito.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiRecapitoContraenteGiuridico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiRecapitoContraenteGiuridico(unDato, unActionType)),
    updateDatiRecapitoContraenteFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiRecapitoContraenteFisico(unDato, unActionType)),
    updateDatiRecapitoTitolareEffettivo: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiRecapitoTitolareEffettivo(unDato, unActionType)),
    updateDatiRecapitoEsecutore: (unDato, unActionType) => dispatch(actionsContraente.updateDatiRecapitoEsecutore(unDato, unActionType)),
    updateDatiCorrispondenzaContrFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiCorrispondenzaContrFisico(unDato, unActionType)),
    updateDatiCorrispondenzaContrGiuridico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiCorrispondenzaContrGiuridico(unDato, unActionType)),
    updateDatiCorrispondenzaTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiCorrispondenzaTitolareEff(unDato, unActionType)),
    updateDatiCorrispondenzaEsecutoreFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiCorrispondenzaEsecutoreFisico(unDato, unActionType)),

    updateDatiCorrispondenzaIntestatoContrFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiCorrispondenzaIntestatoContrFisico(unDato, unActionType)),
    updateDatiCorrispondenzaIntestatarioContrGiuridico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiCorrispondenzaIntestatarioContrGiuridico(unDato, unActionType)),
    updateDatiCorrispondenzaIntestatarioTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiCorrispondenzaIntestatarioTitolareEff(unDato, unActionType)),
    updateDatiCorrispondenzaIntestatarioEsecutoreFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiCorrispondenzaIntestatarioEsecutoreFisico(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};

class DatiRecapito extends React.Component {
  constructor(props) {
    super(props);
    console.log('#RTCFA DatiRecapito props', props);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    this.state = {
      statoRecapito: datiRecapito.statoRecapito,
      descrStatoRecapito: datiRecapito.descrStatoRecapito,
      provinciaRecapito: datiRecapito.provinciaRecapito,
      descrProvinciaRecapito: datiRecapito.descrProvinciaRecapito,
      comuneRecapito: datiRecapito.comuneRecapito,
      descrComuneRecapito: datiRecapito.descrComuneRecapito,
      toponimoRecapito: datiRecapito.toponimoRecapito,
      descrToponimoRecapito: datiRecapito.descrToponimoRecapito,
      indirizzoRecapito: datiRecapito.indirizzoRecapito,
      descrIndirizzoRecapito: datiRecapito.descrIndirizzoRecapito,
      numCivicoRecapito: datiRecapito.numCivicoRecapito,
      capRecapito: datiRecapito.capRecapito,
      presso: datiRecapito.capRecapito,
      datiRecapitoCorrispondenzaDiversoResidenza: datiRecapito.corrispondenzaDiversoResidenza,
      provinciaItems: [],
      comuneItems: [],
      toponimoItems: [],
      stradarioItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
    };
  };

  componentDidMount() {
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    this.setProvince(datiRecapito ? datiRecapito.statoRecapito : '');
    this.setComuni(datiRecapito ? datiRecapito.provinciaRecapito : '', moment().format('DD/MM/YYYY'));
    this.setToponimo(datiRecapito ? datiRecapito.comuneRecapito : '');
    //this.setStradario(datiRecapito ? datiRecapito.comuneRecapito : '', datiRecapito ? datiRecapito.toponimoRecapito : '', datiRecapito ? datiRecapito.descrIndirizzoRecapito : '');
  }

  datiRecapitoFrom = figura => {
    let datiRecapito = null;
    if (figura === CONTRAENTE_FISICO) {
      datiRecapito = this.props.datiRecapitoContraenteFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      datiRecapito = this.props.datiRecapitoContraenteGiuridico;
    } else if (figura === TITOLARE_EFFETTIVO) {
      datiRecapito = this.props.datiRecapitoTitolareEffettivo;
    } else if (figura === ESECUTORE_FISICO) {
      datiRecapito = this.props.datiRecapitoEsecutore;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiRecapito = this.props.datiRecapitoTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiRecapito = this.props.datiRecapitoTitEffVita;
    }
    return datiRecapito;
  }

  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    } else if (figura === CONTRAENTE_GIURIDICO) {
      controlloDati = this.props.controlloDatiContraenteGiuridico;
    } else if (figura === TITOLARE_EFFETTIVO) {
      controlloDati = this.props.controlloDatiTitolareEffettivo;
    } else if (figura === ESECUTORE_FISICO) {
      controlloDati = this.props.controlloDatiEsecutore;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitEffMorte;
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitEffVita;
    }
    return controlloDati;
  }

  handleChangeStatoRecapito = event => {
    //    console.log('#RTCFA DatiRecapito statoRecapito new value:' + event);
    const nuovoStatoRecapito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioStatoRecapito = textTrim(datiRecapito.statoRecapito, true);
    if (nuovoStatoRecapito !== vecchioStatoRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(event, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_STATO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(event, anActionType)
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      }
      this.setProvince(nuovoStatoRecapito);
      this.setComuni('', moment().format('DD/MM/YYYY'));
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        statoRecapito: nuovoStatoRecapito,
        descrStatoRecapito: (event === null) ? '' : event.label,
        provinciaRecapito: '',
        descrProvinciaRecapito: '',
      });
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  }

  handleChangeProvinciaRecapito = event => {
    //    console.log('#RTCFA DatiRecapito provinciaRecapito new value:' + event);
    const nuovaProvinciaRecapito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchiaProvinciaRecapito = textTrim(datiRecapito.provinciaRecapito, true);
    if (nuovaProvinciaRecapito !== vecchiaProvinciaRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(event, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PROVINCIA_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      }
      this.setComuni(nuovaProvinciaRecapito, moment().format('DD/MM/YYYY'));
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        provinciaRecapito: nuovaProvinciaRecapito,
        descrProvinciaRecapito: (event === null) ? '' : event.label,
        comuneRecapito: '',
        descrComuneRecapito: '',
      });
    }
  }

  handleChangeComuneRecapito = event => {
    //    console.log('#RTCFA DatiRecapito ComuneNascita new value:' + event);
    const nuovoComuneRecapito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioComuneRecapito = textTrim(datiRecapito.comuneRecapito, true);
    if (nuovoComuneRecapito !== vecchioComuneRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(event, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      }
      this.setToponimo(nuovoComuneRecapito);
      this.setStradario(nuovoComuneRecapito, '', '');
      this.setState({
        comuneRecapito: nuovoComuneRecapito,
        descrComuneRecapito: (event === null) ? '' : event.label,
        toponimoRecapito: '',
        descrToponimoRecapito: '',
      });
    }
  }

  //usato per PROVINCIA ESTERA
  handleChangeComuneRecapitoTextField = event => {
    //    console.log('#RTCFA DatiRecapito ComuneNascita new value:' + event);
    const nuovoComuneRecapito = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioComuneRecapito = textTrim(datiRecapito.descrComuneRecapito, true);
    if (nuovoComuneRecapito !== vecchioComuneRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(nuovoComuneRecapito, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(nuovoComuneRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoComuneRecapito, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_COMUNE_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(nuovoComuneRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoComuneRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoComuneRecapito, anActionType);
      }
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        comuneRecapito: '',
        descrComuneRecapito: nuovoComuneRecapito,
      });
      // need error Reset if previously had an error
      this.handleError('toponimoRecapito', false);
    }
  }

  handleChangeToponimoRecapito = event => {
    //    console.log('#RTCFA DatiRecapito toponimoRecapito new value:' + event.value);
    const nuovoToponimoRecapito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioToponimoRecapito = textTrim(datiRecapito.toponimoRecapito, true);
    if (nuovoToponimoRecapito !== vecchioToponimoRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(event, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(event, anActionType);
      }
      //this.setStradario(this.state.comuneRecapito, (event === null) ? '' : event.value, this.state.descrIndirizzoRecapito);
      this.setState({
        toponimoRecapito: nuovoToponimoRecapito,
        descrToponimoRecapito: (event === null) ? '' : event.label,
      });
    }
  };

  //usato per PROVINCIA ESTERA
  handleChangeToponimoRecapitoTextField = event => {
    //    console.log('#RTCFA DatiRecapito toponimoRecapito new value:' + event.value);
    const nuovoToponimoRecapito = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioToponimoRecapito = textTrim(datiRecapito.descrToponimoRecapito, true);
    if (nuovoToponimoRecapito !== vecchioToponimoRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(nuovoToponimoRecapito, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(nuovoToponimoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoToponimoRecapito, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_TOPONIMO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(nuovoToponimoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoToponimoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoToponimoRecapito, anActionType);
      }
      //this.setStradario(this.state.comuneRecapito, (event === null) ? '' : event.value, this.state.descrIndirizzoRecapito);
      this.setState({
        toponimoRecapito: '',
        descrToponimoRecapito: nuovoToponimoRecapito,
      });
    }
  };

  /*
  handleChangeIndirizzoRecapito = event => {
    console.log('#RTCFA DatiRecapito indirizzoRecapito new value:' + event.value);
    let anActionType = null;
    if (this.props.figura === CONTRAENTE_FISICO) {
      anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO;
      this.props.updateDatiRecapitoContraenteFisico(event, anActionType);
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_GIURIDICO;
      this.props.updateDatiRecapitoContraenteGiuridico(event, anActionType);
    }
    this.setState({
      indirizzoRecapito: (event === null) ? '' : event.value,
      descrIndirizzoRecapito: (event === null) ? '' : event.label,
    });
  };*/

  handleChangeIndirizzoRecapito = event => {
    const nuovoIndirizzoRecapito = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioIndirizzoRecapito = textTrim(datiRecapito.descrIndirizzoRecapito, true);
    if (nuovoIndirizzoRecapito !== vecchioIndirizzoRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(nuovoIndirizzoRecapito, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(nuovoIndirizzoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoIndirizzoRecapito, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_INDIRIZZO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(nuovoIndirizzoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoIndirizzoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoIndirizzoRecapito, anActionType);
      }
      this.setState({
        descrIndirizzoRecapito: nuovoIndirizzoRecapito,
      });
    }
  }

  handleChangeNumCivicoRecapito = event => {
    //    console.log('#RTCFA DatiRecapito numCivicoRecapito new value:' + event.target.value);
    const nuovoNumCivicoRecapito = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioNumCivicoRecapito = textTrim(datiRecapito.numCivicoRecapito, true);
    if (nuovoNumCivicoRecapito !== vecchioNumCivicoRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(nuovoNumCivicoRecapito, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(nuovoNumCivicoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoNumCivicoRecapito, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(nuovoNumCivicoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoNumCivicoRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoNumCivicoRecapito, anActionType);
      }
      this.setState({
        numCivicoRecapito: nuovoNumCivicoRecapito,
      });
    }
  };

  handleChangeCapRecapito = event => {
    //    console.log('#RTCFA DatiRecapito capRecapito new value:' + event.target.value);
    const nuovoCapRecapito = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioCapRecapito = textTrim(datiRecapito.capRecapito, true);
    if (nuovoCapRecapito !== vecchioCapRecapito) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO;
        this.props.updateDatiRecapitoContraenteFisico(nuovoCapRecapito, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_CAP_RECAPITO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(nuovoCapRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoCapRecapito, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CAP_RECAPITO_CONTRAENTE_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(nuovoCapRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoCapRecapito, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoCapRecapito, anActionType);
      }
      this.setState({
        capRecapito: nuovoCapRecapito,
      });
    }
  };

  handleChangePresso = (event) => {
    const nuovoPresso = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioPresso = textTrim(datiRecapito.presso, true);
    if(nuovoPresso !== vecchioPresso) {
      let anActionType = null;
      if(this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PRESSO_CONTR_FISICO;
        this.props.updateDatiCorrispondenzaContrFisico(nuovoPresso, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_PRESSO_CONTRAENTE_GIURIDICO;
        this.props.updateDatiRecapitoContraenteGiuridico(nuovoPresso, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_PRESSO_TITOLARE_EFFETTIVO;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoPresso, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_PRESSO_FISICO_ESECUTORE;
        this.props.updateDatiRecapitoEsecutore(nuovoPresso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_PRESSO_TITOLARE_EFFETTIVO_MORTE;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoPresso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA;
        this.props.updateDatiRecapitoTitolareEffettivo(nuovoPresso, anActionType);
      }
      this.setState({
        presso: nuovoPresso,
      });
    }
   };
  

  handleChangeCorrispondenzaDiversoResidenza = (event) => {
    const nuovoDiverso = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioDiverso = textTrim(datiRecapito.corrispondenzaDiversoResidenza);
    if (nuovoDiverso !== vecchioDiverso) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_FISICO;
        this.props.updateDatiCorrispondenzaContrFisico(nuovoDiverso, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_CONTR_GIURIDICO;
        this.props.updateDatiCorrispondenzaContrGiuridico(nuovoDiverso, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF;
        this.props.updateDatiCorrispondenzaTitolareEff(nuovoDiverso, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_FISICO;
        this.props.updateDatiCorrispondenzaEsecutoreFisico(nuovoDiverso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_MORTE;
        this.props.updateDatiCorrispondenzaTitolareEff(nuovoDiverso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA;
        this.props.updateDatiCorrispondenzaTitolareEff(nuovoDiverso, anActionType);
      }
    
      // Sono costretto a ricalcolare TcmIndividualeValida perchè potrebbe esseere rimasta selezionata una nazione in black list al momento di selezione = 'N'
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }

  handleChangeCorrispondenzaIntestatoContraente = (event) => {
    const nuovoDiverso = textTrim(event.target.value, true);
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const vecchioDiverso = textTrim(datiRecapito.corrispondenzaIntestatoContraente);
    if (nuovoDiverso !== vecchioDiverso) {
      let anActionType = null;
      if (this.props.figura === CONTRAENTE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_FISICO;
        this.props.updateDatiCorrispondenzaIntestatoContrFisico(nuovoDiverso, anActionType);
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        anActionType = actionTypeContraente.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_CONTR_GIURIDICO;
        this.props.updateDatiCorrispondenzaIntestatarioContrGiuridico(nuovoDiverso, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF;
        this.props.updateDatiCorrispondenzaIntestatarioTitolareEff(nuovoDiverso, anActionType);
      } else if (this.props.figura === ESECUTORE_FISICO) {
        anActionType = actionTypeContraente.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_ESECUTORE_FISICO;
        this.props.updateDatiCorrispondenzaIntestatarioEsecutoreFisico(nuovoDiverso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_MORTE;
        this.props.updateDatiCorrispondenzaIntestatarioTitolareEff(nuovoDiverso, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA;
        this.props.updateDatiCorrispondenzaIntestatarioTitolareEff(nuovoDiverso, anActionType);
      }
    }
  }



  setProvince = (nazione) => {
    if (textTrim(nazione, true) !== '') {
      const opts = {
        nazione: nazione,
      };
      fetch(uris.provinciaItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          // If request is good update state with fetched data
          this.setState({
            provinciaItems: result,
          });
        });
    } else {
      this.setState({
        provinciaItems: [],
      });
    }
  }

  setComuni = (codProvincia, dataNascita) => {
    if (textTrim(codProvincia, true) !== '' && textTrim(dataNascita, true) !== '') {
      const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          // If request is good update state with fetched data
          this.setState({
            comuneItems: result,
          });
        });
    } else {
      this.setState({
        comuneItems: [],
      });
    }
  }

  setToponimo = (codiceIstat) => {
    if (textTrim(codiceIstat, true) !== '') {
      const opts = {
        codiceIstat: codiceIstat,
      };
      fetch(uris.toponimoItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          // If request is good update state with fetched data
          this.setState({
            toponimoItems: result,
          });
        });
    } else {
      this.setState({
        toponimoItems: [],
      });
    }
  }

  setStradario = (codiceIstat, toponimo, descrStrada) => {
    if (textTrim(codiceIstat, true) !== '' && textTrim(toponimo, true) !== '') {
      const opts = {
        codiceIstat: codiceIstat,
        descrToponimo: toponimo,
        descrStrada: descrStrada,
      };
      fetch(uris.stradarioItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          // If request is good update state with fetched data
          this.setState({
            stradarioItems: result,
          });
        });
    } else {
      this.setState({
        stradarioItems: [],
      });
    }
  }

  isPartialFilled() {
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const controlloDati = this.controlloDatiFrom(this.props.figura);
    if (controlloDati &&
      textTrim(datiRecapito.statoRecapito, true) !== '' ||
      textTrim(datiRecapito.provinciaRecapito, true) !== '' ||
      textTrim(datiRecapito.comuneRecapito, true) !== '' ||
      textTrim(datiRecapito.toponimoRecapito, true) !== '' ||
      textTrim(datiRecapito.indirizzoRecapito, true) !== '' ||
      textTrim(datiRecapito.numCivicoRecapito, true) !== '' ||
      textTrim(datiRecapito.presso, true) !== '' ||
      textTrim(datiRecapito.capRecapito, true) !== '') {
      return true;
    }
    return false;
  }

  isRequiredCorrispondenzaDiversoResidenza = () => {
    let required = false;
    if (this.props.scopeAmleto) {
      if (this.props.figura === CONTRAENTE_FISICO) {
        required = true;
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        required = true;
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if(this.props.figura == TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
      return required;
    }
  }

  isRequiredCorrispondenzaIntestatoContrante = () => {
    let required = false;
      if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      return required;
  }

  isRequiredStatoRecapito = () => {
    let required = false;
      if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      return required;
  }


  isRequiredProvinciaRecapito = () => {
    let required = false;
      if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      return required;
  }

  isRequiredComuneRecapito = () => {
    let required = false;
      if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      return required;
  }

  isRequiredToponimoRecapito = () => {
    let required = false;
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
      if (datiRecapito.corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      if(datiRecapito.provinciaRecapito === PROVINCIA_ESTERA) {
        required = false;
      }
      return required;
  }

  isRequiredIndirizzoRecapito = () => {
    let required = false;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      return required;
  }

  isRequiredNumCivicoRecapito = () => {
    let required = false;
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
      if (datiRecapito.corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      if(datiRecapito.provinciaRecapito === PROVINCIA_ESTERA) {
        required = false;
      }
      return required;
  }

  isRequiredCapRecapito = () => {
    let required = false;
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
      if(datiRecapito.corrispondenzaDiversoResidenza === 'S') {
        required = true;
      }
      if(datiRecapito.provinciaRecapito === PROVINCIA_ESTERA) {
        required = false;
      }
      return required;
  }

  isRequiredPresso = () => {
    let required = false;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaIntestatoContraente === 'N' && this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      required = true;
      }
      return required;
  }

  initStatoCampi = () => {
    return [{ id: 'statoRecapito', isError: false },
    { id: 'provinciaRecapito', isError: false },
    { id: 'comuneRecapito', isError: false },
    { id: 'toponimoRecapito', isError: false },
    { id: 'indirizzoRecapito', isError: false },
    { id: 'numCivicoRecapito', isError: false },
    { id: 'capRecapito', isError: false },
    { id: 'presso', isError: false },
    ];
  }

  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({
      statoCampi: [...campoWithoutId, unErrore],
    });
  }

  isDisabledCorrispondenzaDiversoResidenza = () => {
    let disabled = false;
    return disabled;
  }

  isDisabledCorrispondenzaIntestatarioContraente = () => {
    let disabled = true;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      disabled = false;
    }
    return disabled;
  }



  isDisabledStatoRecapito = () => {
    let disabled = true;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      disabled = false;
    }
    return disabled;
  }

  isDisabledIndirizzo = () => {
    let disabled = true;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      disabled = false;
    }
    return disabled;
  }

  isDisabledCivico = () => {
    let disabled = true;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      disabled = false;
    }
    return disabled;
  }

  isDisabledCap = () => {
    let disabled = true;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      disabled = false;
    }
    return disabled;
  }

  isDisabledPresso = () => {
    let disabled = true;
    if (this.datiRecapitoFrom(this.props.figura).corrispondenzaIntestatoContraente === 'N' && this.datiRecapitoFrom(this.props.figura).corrispondenzaDiversoResidenza === 'S') {
      disabled = false;
    }
    return disabled;
  }

  isDisabledProvincia = () => {
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const statoRecapito = textTrim(datiRecapito.statoRecapito, true);
    return (statoRecapito === '');
  }

  isDisabledComune = () => {
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const provinciaRecapito = textTrim(datiRecapito.provinciaRecapito, true);
    return (this.isDisabledProvincia() === true) || (provinciaRecapito === '');
  }

  isDisabledToponimo = () => {
    const datiRecapito = this.datiRecapitoFrom(this.props.figura);
    const comuneRecapito = textTrim(datiRecapito.comuneRecapito, true);
    return (this.isDisabledComune() === true) || (comuneRecapito === '');
  }

  render() {
    console.log('#RT2 DatiRecapito.this.props', this.props);
    const { classes, figura, labelFieldSet, dominio } = this.props;
    const datiRecapito = this.datiRecapitoFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura);
    //    const { t } = this.props; //hoc
    return (
      <Fragment>
        {/*<fieldset className={classes.fieldSet}>
       <legend><Typography variant='h6'>{t('datiDiRecapito')}</Typography></legend>*/}
        {labelFieldSet &&
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        }
        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
          <Grid item md={6} xs={12}>
            <RadioButtonComp onChange={this.handleChangeCorrispondenzaDiversoResidenza} radioComp={radioButtonCorrispondenzaDiversoResidenza}
              selectedValue={datiRecapito.corrispondenzaDiversoResidenza} required={this.isRequiredCorrispondenzaDiversoResidenza()}
              disabled={this.isDisabledCorrispondenzaDiversoResidenza()} controlloDati={controlloDati} id='corrispondenzaDiversoResidenza'
              onError={this.handleError} />
          </Grid>
          <Grid item md={6} xs={12}>
            <RadioButtonComp onChange={this.handleChangeCorrispondenzaIntestatoContraente} radioComp={radioButtonCorrispondenzaIntestatoContraente}
              selectedValue={datiRecapito.corrispondenzaIntestatoContraente} required={this.isRequiredCorrispondenzaIntestatoContrante()}
              disabled={this.isDisabledCorrispondenzaIntestatarioContraente()} controlloDati={controlloDati} id='corrispondenzaIntestatarioContraente'
              onError={this.handleError} />
          </Grid>
          <Grid item md={12} xs={12}>
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeStatoRecapito} options={dominio.nazioneItems} ricerca='comboRicerca' etichetta='stato'
              value={{ value: datiRecapito.statoRecapito, label: datiRecapito.descrStatoRecapito }} required={this.isRequiredStatoRecapito()}
              id='statoRecapito' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledStatoRecapito()} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeProvinciaRecapito} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provincia'
              value={{ value: datiRecapito.provinciaRecapito, label: datiRecapito.descrProvinciaRecapito }} disabled={this.isDisabledProvincia()}
              required={this.isRequiredProvinciaRecapito()} controlloDati={controlloDati} id='provinciaRecapito' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            {datiRecapito.provinciaRecapito !== PROVINCIA_ESTERA &&
              <SelectComp onChange={this.handleChangeComuneRecapito} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comune'
                value={{ value: datiRecapito.comuneRecapito, label: datiRecapito.descrComuneRecapito }} disabled={this.isDisabledComune()}
                required={this.isRequiredComuneRecapito()} id='comuneRecapito' onError={this.handleError} controlloDati={controlloDati} />
            }
            {datiRecapito.provinciaRecapito === PROVINCIA_ESTERA &&
              <TextFieldComp onBlur={this.handleChangeComuneRecapitoTextField} aStyle={classes.textFieldWidthPerc} id='comuneRecapito'
                label='comune' value={datiRecapito.descrComuneRecapito} required={this.isRequiredComuneRecapito()}
                onError={this.handleError} controlloDati={controlloDati} />
            }
          </Grid>
          <Grid item md={3} xs={12}>
            {datiRecapito.provinciaRecapito !== PROVINCIA_ESTERA &&
              <SelectComp onChange={this.handleChangeToponimoRecapito} options={this.state.toponimoItems} ricerca='comboRicerca' etichetta='toponimo'
                value={{ value: datiRecapito.toponimoRecapito, label: datiRecapito.descrToponimoRecapito }} disabled={this.isDisabledToponimo()}
                required={this.isRequiredToponimoRecapito()} id='toponimoRecapito' onError={this.handleError} controlloDati={controlloDati} />
            }
            {datiRecapito.provinciaRecapito === PROVINCIA_ESTERA &&
              <TextFieldComp onBlur={this.handleChangeToponimoRecapitoTextField} aStyle={classes.textFieldWidthPerc} id='toponimoRecapito'
                label='toponimo' value={datiRecapito.descrToponimoRecapito} required={this.isRequiredToponimoRecapito()}
                onError={this.handleError} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
            }
          </Grid>
          <Grid item md={5} xs={12}>
            {/*<SelectComp onChange={this.handleChangeIndirizzoRecapito} options={this.state.stradarioItems} ricerca='comboRicerca' etichetta='indirizzo'
        	           value={{value: datiRecapito.indirizzoRecapito, label: datiRecapito.descrIndirizzoRecapito}}
                       required={this.isRequiredIndirizzoRecapito()} id='indirizzoRecapito' onError={this.handleError} controlloDati={controlloDati} />*/}
            <TextFieldComp onBlur={this.handleChangeIndirizzoRecapito} aStyle={classes.textFieldWidthPerc} id='indirizzoRecapito' disabled={this.isDisabledIndirizzo()}
              label='indirizzo' value={datiRecapito.descrIndirizzoRecapito} required={this.isRequiredIndirizzoRecapito()}
              onError={this.handleError} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextFieldComp onBlur={this.handleChangeNumCivicoRecapito} aStyle={classes.textField} label='numCivico' disabled={this.isDisabledCivico()}
              value={datiRecapito.numCivicoRecapito} required={this.isRequiredNumCivicoRecapito()}
              id='numCivicoRecapito' onError={this.handleError} controlloDati={controlloDati} />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextFieldComp onBlur={this.handleChangeCapRecapito} aStyle={classes.textField} label='cap' disabled={this.isDisabledCap()}
              value={datiRecapito.capRecapito} required={this.isRequiredCapRecapito()}
              id='capRecapito' onError={this.handleError} controlloDati={controlloDati} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangePresso} aStyle={classes.textFieldWidthPerc} label='presso' disabled={this.isDisabledPresso()}
              value={datiRecapito.presso} required={this.isRequiredPresso()} aStyleError={classes.textFieldWidthPercError}
              id='presso' onError={this.handleError} controlloDati={controlloDati} />
          </Grid>
        </Grid>
        {/*</fieldset>*/}
      </Fragment>
    );
  }

  isInError(prevProps, prevState) {
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
    });
    console.log('########DatiRecapito componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    //    console.log('########DatiRecapito componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore);
    //    console.log('########DatiRecapito componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);
    if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }
}

DatiRecapito.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  dominio: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiRecapito));
