export const radioButtonEsisteEsecutore = {
	align: 'horizontal',
	titolo: 'esisteEsecutore',
	name: 'flEsecutore',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'esisteEsecutoreS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'esisteEsecutoreN',
    }],
};

export const radioButtonRelazContrEsec = {
	align: 'horizontal',
	titolo: 'relazContrEsecut',	
	name: 'relazContrEsecut',
	elementi: [{
	  value: 'N',
	  label: 'nucleoFam',
	  ariaLabel: 'relazContrN',
    }, {
      value: 'A',
      label: 'general.altro',
      ariaLabel: 'relazContrA',
    }],
};

export const radioButtonEsecutoreDelega = {
	align: 'horizontal',
	titolo: 'ilPresenteEsecutore',	
	name: 'flDelega',
	elementi: [{
	  value: 'S',
	  label: 'munitoDelega',
	  ariaLabel: 'delegaS',
    }, {
      value: 'N',
      label: 'privoDelega',
      ariaLabel: 'delegaN',
    }],
};

export const radioButtonSesso = {
	align: 'horizontal',
	titolo: 'sesso',
	onChange: 'this.handleChangeSesso',
	name: 'sesso',
	elementi: [{
	  value: 'M',
	  label: 'maschio',
	  ariaLabel: 'sessoM',
    }, {
      value: 'F',
      label: 'femmina',
      ariaLabel: 'sessoF',
    }],
};

export const radioButtonTasseStatiUniti = {
	align: 'horizontal',
	titolo: 'tasseStatiUniti',
	onChange: 'this.handleChangeTasseStatiUniti',
	name: 'tasseStatiUniti',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'tasseStatiUnitiS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'tasseStatiUnitiN',
    }],
};

export const radioButtonResidenzaFiscale = {
	align: 'horizontal',
	titolo: 'residenzaFiscale',
	onChange: 'this.handleChangeResidenzaFiscale',
	name: 'residenzaFiscale',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'residenzaFiscaleS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'residenzaFiscaleN',
    }],
};

export const radioButtonTitolariEffettivi = {
	align: 'horizontal',
	titolo: 'presentiTitolariEffettivi',
//	onChange: 'this.handleChangeTitolariEffettivi',
	name: 'titolariEffettivi',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'titolariEffettiviS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'titolariEffettiviN',
    }],
};

export const radioButtonTitolariEffBeneficiari = {
	align: 'horizontal',
	titolo: 'titolareEffBeneficiario',
	name: 'titolariEffBeneficiari',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'titolariEffBeneficiariS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'titolariEffBeneficiariN',
    }],
};

/* 20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
export const radioButtonClassePremioPotenziale = {
	align: 'vertical',
	titolo: 'classePremiPotenziale',
	onChange: 'this.handleChangeClassePremioPotenziale',
	name: 'classePremioPotenziale',
	elementi: [{
	  value: 'A',
	  label: 'classeA',
	  ariaLabel: 'classePremioPotenzialeA',
    }, {
      value: 'B',
      label: 'classeB',
      ariaLabel: 'classePremioPotenzialeB',
    }, {
	  value: 'C',
	  label: 'classeC',
	  ariaLabel: 'classePremioPotenzialeC',
    }, {
	  value: 'D',
	  label: 'classeD',
	  ariaLabel: 'classePremioPotenzialeD',
    }],
};
*/

export const appendiceCostiScelta = {
	align: 'horizontal',
	titolo: 'variazioneCostiStandard',
//	onChange: 'this.handleChangeAppendiceCosti',
	name: 'appendiceCosti',
	elementi: [{
		value: 'S',
		label: 'si',
		ariaLabel: 'appendiceS',
	}, {
		value: 'N',
		label: 'no',
		ariaLabel: 'appendiceN',
	}],
};

export const radioButtonPagatorePremio = {
		align: 'horizontal',
		titolo: 'contraenteDichiaraPagatore',
		onChange: 'this.handleChangePagatorePremio',
		name: 'pagatorePremio',
		elementi: [{
		  value: '1',
		  label: 'contraente',
		  ariaLabel: 'pagatorePremio1',
	    }, {
	      value: '2',
	      label: 'esecutore',
	      ariaLabel: 'pagatorePremio2',
	    }],
	};

export const radioButtonOpzioneContrattuale = {
	align: 'horizontal',
	titolo: 'selezionareOpzioniContrattuali',
	onChange: 'this.handleChangePagatorePremio',
	name: 'opzioneContrattuale',
	elementi: [{
	  value: '0',
	  label: 'nessuna',
	  ariaLabel: 'opzioneContrattuale0',
	}, {
	  value: '1',
	  label: 'pianoErogazioniPeriodiche',
	  ariaLabel: 'opzioneContrattuale1',
    }, {
      value: '2',
      label: 'takeProfit',
      ariaLabel: 'opzioneContrattuale2',
    }, {
	  value: '3',
	  label: 'lifeCycle',
	  ariaLabel: 'opzioneContrattuale3',
    }],
};

export const radioButtonOpzioneContrattualeUL = {
	align: 'horizontal',
	titolo: 'selezionareOpzioniContrattuali',
	onChange: 'this.handleChangePagatorePremio',
	name: 'opzioneContrattuale',
	elementi: [{
	  value: '0',
	  label: 'nessuna',
	  ariaLabel: 'opzioneContrattuale0',
	}, {
	  value: '1',
	  label: 'pianoErogazioniPeriodiche',
	  ariaLabel: 'opzioneContrattuale1',
    }],
};

export const radioButtonRibilanciamento = {
	align: 'horizontal',
	titolo: 'ribilanciamento',
	onChange: 'this.handleChangeRibilanciamento',
	name: 'ribilanciamento',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'ribilanciamentoS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'ribilanciamentoN',
    }],
};

export const radioButtonRelazioneContraente = {
	align: 'horizontal',
	titolo: 'relazioneContraente',
	onChange: 'this.handleChangeRelazioneContraente',
	name: 'relazioneContraente',
	elementi: [{
	  value: 'N',
	  label: 'nucleoFamiliare',
	  ariaLabel: 'relazioneContraenteN',
    }, {
      value: 'A',
      label: 'general.altro',
      ariaLabel: 'relazioneContraenteA',
    }],
};

export const radioButtonConsensoComunicazioneElettronica = {
	align: 'horizontal',
	titolo: 'consensoComunicazioneElettronica',
// NOT USED	onChange: 'this.handleChangeConsensoComunicazioneElettronica',
	name: 'flConsensoComElettr',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'flConsensoComElettrS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'flConsensoComElettrN',
    }],
};

/* 20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
export const radioButtonComportamentoCliente = {
	align: 'vertical',
	titolo: 'valutazioneAiSensiLgs',
// NOT USED:	onChange: 'this.handleChangeComportamentoCliente',
	name: 'comportamentoCliente',
	elementi: [{
	  value: '1',
	  label: 'reticenzaFonireInfo',
	  ariaLabel: 'comportamentoClienteA',
    }, {
      value: '2',
      label: 'pocaTrasparenzaComp',
      ariaLabel: 'comportamentoClienteB',
    }, {
      value: '3',
      label: 'inusualitaTransazione',
      ariaLabel: 'comportamentoClienteC',
    }, {
      value: '4',
      label: 'interposizioneTerzi',
      ariaLabel: 'comportamentoClienteD',
    }, {
      value: '5',
      label: 'comportamentoNonDenotaAnomalie',
      ariaLabel: 'comportamentoClienteE',
    }],
};
*/

export const radioButtonOperzioneCoerente = {
	titolo: 'flOperazioneCoerente',		
	name: 'flOperazioneCoerente',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'operazioneCoerenteS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'operazioneCoerenteN',
    }],
};


export const radioButtonFlConsenso1Contratto = {
//	align: 'vertical',
	titolo: '',		
	name: 'flConsenso1Contratto',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'testo.privacy1S',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'testo.privacy1N',
    }],
};	    
	    
export const radioButtonFlConsenso2Marketing = {
//	align: 'vertical',
	titolo: '',
	name: 'flConsenso2Marketing',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'testo.privacy2S',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'testo.privacy2N',
    }],
};
	    
export const radioButtonFlConsenso3Statistic = {
//		align: 'vertical',
	titolo: '',
	name: 'flConsenso3Statistic',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'testo.privacy3S',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'testo.privacy3N',
    }],
};
	    
export const radioButtonFlConsenso4Comunicaz = {
//		align: 'vertical',
	titolo: '',
	name: 'flConsenso4Comunicaz',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'testo.privacy4S',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'testo.privacy4N',
    }],
};

export const radioButtonSocietaAzFiduc = {
	titolo: 'Società ad Azionariato Fiduciario',
	name: 'societaAzFiduciario',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'societaAzFiduciarioS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'societaAzFiduciarioN',
    }],
};

export const radioButtonSocietaQuotata = {
	titolo: 'Società Quotata',
	name: 'societaQuotata',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'societaQuotataS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'societaQuotataN',
    }],
};


export const radioButtonIntestatarioMandato = {
	titolo: 'Intestatario del mandato fiduciario',
	name: 'intestatarioMand',
	elementi: [{
	  value: 'PF',
	  label: 'pf',
	  ariaLabel: 'intestatarioMandatoPf',
    }, {
      value: 'PG',
      label: 'pg',
      ariaLabel: 'intestatarioMandatoPg',
    }],
};



export const radioButtonAttEconomicaFlAllegati = {
//		align: 'vertical',
	titolo: 'allega',
	name: 'flAllegati',
	elementi: [{
	  value: 'V',
	  label: 'visuraCamerale',
	  ariaLabel: 'flAllegatiV',
    }, {
      value: 'A',
      label: 'general.altro',
      ariaLabel: 'flAllegatiA',
    }],
};

export const radioButtonPaesiBlackList = {
//		align: 'vertical',
	titolo: 'paesiBlackList',
	name: 'flPaesiBlackList',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'flPaesiBlackListS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'flPaesiBlackListN',
    }],
};

export const radioButtonEntitaFinanzPassiva = {
//		align: 'vertical',
	titolo: 'entitaFinanzPassiva',
	name: 'flEntitaFinanzPassiva',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'flEntitaFinanzPassivaS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'flEntitaFinanzPassivaN',
    }],
};


export const radioButtonEspostoPoliticamente = {
	align: 'horizontal',
	titolo: 'persEspostoPoliticamente',
	name: 'flEspostoPoliticamente',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'persEspostoPoliticamenteS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'persEspostoPoliticamenteN',
    }],
};

export const radioButtonEspostoPoliticamenteInCarica = {
	align: 'horizontal',
	titolo: 'persEspostoPoliticamenteInCarica',
	name: 'flEspostoPoliticamenteInCarica',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'persEspostoPoliticamenteInCaricaS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'persEspostoPoliticamenteInCaricaN',
    }],
};

export const radioButtonEsisteReferenteTerzo = {
	align: 'horizontal',
	titolo: 'esisteReferenteTerzo',
	name: 'flReferenteTerzo',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'esisteReferenteTerzoS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'esisteReferenteTerzoN',
    }],
};

export const radioButtonAssicuratoUgualeContraente = {
	align: 'horizontal',
	titolo: 'assicuratoUgualeContraente',
	name: 'flAssicuratoUgualeContraente',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'assicuratoUgualeContraenteS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'assicuratoUgualeContraenteN',
    }],
};


export const radioButtonCopiaDaAltraFigura = {
	align: 'horizontal',
	titolo: 'copiaDaAltraFigura',
	name: 'copiaDaAltraFigura',
	elementi: [{
	  value: 'S',
	  label: 'si',
	  ariaLabel: 'copiaDaAltraFiguraS',
    }, {
      value: 'N',
      label: 'no',
      ariaLabel: 'copiaDaAltraFiguraN',
    }],
};


export const radioButtonContraentePrimaCasa = {
	align: 'horizontal',
	titolo: 'primaCasaContraente',
	name: 'primaCasaContraente',
	elementi: [{
		value: 'S',
		label: 'si',
		ariaLabel: 'primaCasaContraenteS'
	}, {
		value: 'N',
		label: 'no',
		ariaLabel: 'primaCasaContraenteN'
	}]
}

export const radioButtonContraenteAtriImmobili = {
	align: 'horizontal',
	titolo: 'altriImmobiliContraente',
	name: 'altriImmobiliContraente',
	elementi: [{
		value: 'S',
		label: 'si',
		ariaLabel: 'altriImmobiliContraenteS'
	}, {
		value: 'N',
		label: 'no',
		ariaLabel: 'altriImmobiliContraenteN'
	}]
}

export const radioButtonContraenteGiuridicoSedi = {
	align: 'horizontal',
	titolo: 'sediSocContrGiuridico',
	name: 'sediSocContrGiuridico',
	elementi: [{
		value: 'S',
		label: 'si',
		ariaLabel: 'sediSocContrGiuridicoS'
	}, {
		value: 'N',
		label: 'no',
		ariaLabel: 'sediSocContrGiuridicoN'
	}]
}

export const radioButtonContraenteGiuridicoAltriImmobili = {
	align: 'horizontal',
	titolo: 'altriImmobiliContraenteGiuridico',
	name: 'altriImmobiliContraenteGiuridico',
	elementi: [{
		value: 'S',
		label: 'si',
		ariaLabel: 'altriImmobiliContraenteGiuridicoS'
	}, {
		value: 'N',
		label: 'no',
		ariaLabel: 'altriImmobiliContraenteGiuridicoN'
	}]
}

export const radioButtonStipulazionePerConto = {
	align: 'horizontal',
	titolo: 'stipulatoPerConto',
	name: 'stipulatoPerConto',
	elementi: [{
		value: 'PROPRIO',
		label: 'Per conto proprio',
		ariaLabel: 'stipulazioneContoProprio'
	}, {
		value: 'ASSICURATO',
		label: "Per conto dell'assicurato",
		ariaLabel: 'stipulazioneContoAssicurato'
	}]
}

export const radioButtonDomicilioDiversoResidenza = {
	align: 'horizontal',
	titolo: 'domicilioDiversoResidenza',
	name: 'domicilioDiversoResidenza',
	elementi: [{
		value: 'S',
		label: 'SI',
		ariaLabel: 'domicilioDiversoS'
	}, {
		value: 'N',
		label: 'NO',
		ariaLabel: 'domicilioDiversoN'
	}]
}

export const radioButtonCorrispondenzaDiversoResidenza = {
	align: 'horizontal',
	titolo: 'corrispondenzaDiversoResidenza',
	name: 'corrispondenzaDiversoResidenza',
	elementi: [{
		value: 'S',
		label: 'SI',
		ariaLabel: 'corrispondenzaDiversoS'
	}, {
		value: 'N',
		label: 'NO',
		ariaLabel: 'corrispondenzaDiversoN'
	}]
}

export const radioButtonCorrispondenzaIntestatoContraente = {
	align: 'horizontal',
	titolo: 'corrispondenzaIntestatoContraente',
	name: 'corrispondenzaIntestatoContraente',
	elementi: [{
		value: 'S',
		label: 'SI',
		ariaLabel: 'corrispondenzaIntestatoContraenteS'
	}, {
		value: 'N',
		label: 'NO',
		ariaLabel: 'corrispondenzaIntestatoContraenteN'
	}]
}