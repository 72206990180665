//import { datiGenerali, datiAntiriciclaggio, datiResidenza } from './sezioni/sezioniAnagrafica';
import { datiGenerali, datiAntiriciclaggio, datiResidenza, datiAttEconomica, datiRelazContr } from './sezioni/sezioniAnagraficaAssicuratoFisico';

export const assicuratoFisico = {
  anagraficaValida: null,
  controlloDati: '',
  flAssicuratoUgualeContraente: '',  // se assicurato === contraente
  /* codAttPrevalSvolta: '',
  descrAttPrevalSvolta: '', */
  datiGenerali: datiGenerali,
  datiResidenza: datiResidenza,
  datiAntiriciclaggio: datiAntiriciclaggio,
  datiAttEconomica: datiAttEconomica,
  datiRelazContr: datiRelazContr
};