import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoFigura from '../sezione/TabDatiRiepilogoFigura';
import CopiaDaAltraFigura from '../sezione/other/CopiaDaAltraFigura';
import DatiGeneraliBenefFisico from '../sezione/DatiGeneraliBenefFisico';
import DatiAntiriciclaggio from '../sezione/DatiAntiriciclaggio';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiRelazContr from '../sezione/DatiRelazContr';

import ButtonComp from '../../common/ButtonComp';
import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';

import { locale_it, BENEF_MORTE_FISICO } from '../../../utility/jsConstants';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';
import { creaBeneficiarioMorteFisico } from '../../../utility/objectUtility';

import { toNumber } from '../../../utility/genericUtility';
import { calcolaPercBenfTotale, 
	     getPercBenefByCodFiscInList, getPercBenefByCognomeInList, } from './figureUtils/figureUtils';

import * as actions from './action/beneficiarioMorte';
import * as actionType from './actionType/beneficiarioMorte';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textField: theme.textField,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA BenefMorteFisicoAss.mapStateToProps.state', state);
  return {
    benefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,
    salvaBenefMorteFisicoSuccess: state.areaBenefMorteReducer.salvaBenefMorteFisicoSuccess,
    controlloDati: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA BenefMorteFisicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
	confermaAggiungiBeneficiarioMorteFisico: (currBenefMorteFisico) => dispatch(actions.confermaAggiungiBeneficiarioMorteFisico(currBenefMorteFisico)),
	nuovoBeneficiarioMorteFisico: (currBenefMorteFisico) => dispatch(actions.nuovoBeneficiarioMorteFisico(currBenefMorteFisico)),
    updateDatiBeneficiarioMorte: (unDato, unActionType) => dispatch(actions.updateDatiBeneficiarioMorte(unDato, unActionType)),
    updateBeneficiarioMorteFisico: (unDato, unActionType) => dispatch(actions.updateBeneficiarioMorteFisico(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class BenefMorteFisicoAss extends React.Component {
  constructor(props) {
    super(props);
    console.log('#RTCFA BenefMorteFisicoAss constructor props', this.props);
    const datiCopiaDaAltraFigura = props.benefMorteFisico.datiCopiaDaAltraFigura;
    const datiRelazContr = props.benefMorteFisico.datiRelazContr;    
    this.state = {
      percBeneficiario: 0,
      percentualeTotale: 0,
      isConfermaBenef: false,
      isNuovoBenef: false,
      figuraFrom: datiCopiaDaAltraFigura.figuraFrom,
      isCopiaDaAltraFigura: datiCopiaDaAltraFigura.flCopiaDaAltraFigura,
      flEsclusioneComunicaz: datiRelazContr.esclusioneComunicaz,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    }   
  };
  
/*  getEmptyBenefMorteFisico = () => {
	  const emptyBenefMorteFisico = {
		  controlloDati: '',
		  anagraficaValida: null,
		  
	  	  datiFigureAnagrBase: {
			  ruolo: RUOLO_BENEF_MORTE,
			  tipoPers: PERSONA_FISICA,
		  },
	      datiGeneraliBenefMorte: {
	        datiGeneraliMinimi: {}
	      },
	      datiResidenza: {},
	      datiRelazContr: {},
	      datiReferenteTerzo: {
	        datiGenerali: {},
	        datiResidenza: {},
	      },
	      datiCopiaDaAltraFigura: {
	    	  flCopiaDaAltraFigura: 'N',
	      },
	  };
	  
	  return emptyBenefMorteFisico;
  } */
  
  handleClickNuovoBenefMorte = event => {
//    console.log('#RTCFA BenefMorteFisicoAss:handleChangeAggiungiBenefMort new value:', event.target.value);
//    const emptyBenefMorteFisico = this.getEmptyBenefMorteFisico();
    const emptyBenefMorteFisico = creaBeneficiarioMorteFisico();    
    this.props.nuovoBeneficiarioMorteFisico(emptyBenefMorteFisico);
//    this.props.updateDatiBeneficiarioMorte(false, actionType.SALVA_BENEF_MORTE_FISICO_SUCCESS);
    this.setState({
      isConfermaBenef: false,
      isNuovoBenef: true,
      isPresenteErrore: null,
      flEsclusioneComunicaz: undefined,
    });
  }
  
  handleClickConfermaBenefMorte = (event) => {
	console.log('#RTCFA BenefMorteFisicoAss:handleClickConfermaBenefMorte new value:', event.target.value);
	this.props.updateDatiBeneficiarioMorte(true, actionType.SALVA_BENEF_MORTE_FISICO);
	this.props.updateDatiBeneficiarioMorte(false, actionType.SALVA_BENEF_MORTE_FISICO_SUCCESS);
	this.setState({
      isConfermaBenef: true,
      isNuovoBenef: false,
	});
  }
  
  confermaBeneficiario = () => {
    const currBenef = this.props.benefMorteFisico;
	const percTotaleFigure = calcolaPercBenfTotale(this.props.benefMorteFisicoList, this.props.benefMorteGiuridicoList, this.props.benefMorteGenericoList, locale_it);
	let percTotale = percTotaleFigure;
	let percBenefInLista = undefined;
	let isNuovo = false;
	let benefMorteFromCopiaDaAltraFigura = undefined; 
    if (this.state.isConfermaBenef) {
      if (currBenef.anagraficaValida === true) {
        if (currBenef.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!== 'S') {
          // Trova per Codice Fiscale
          percBenefInLista = getPercBenefByCodFiscInList(this.props.benefMorteFisicoList, currBenef, locale_it);
          // Edit figura corrente
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;
          }
        } else {
          const descrFiguraFrom = currBenef.datiCopiaDaAltraFigura.descrFiguraFrom;				
          currBenef.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome = descrFiguraFrom;
          // cerco se presente
          percBenefInLista = getPercBenefByCognomeInList(this.props.benefMorteFisicoList, currBenef);
          // nuova figura
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;					
            benefMorteFromCopiaDaAltraFigura = {...currBenef};
            // reset alcune sezioni
            benefMorteFromCopiaDaAltraFigura.datiGeneraliBenefMorte.datiGeneraliMinimi = {};
            benefMorteFromCopiaDaAltraFigura.datiGeneraliBenefMorte.datiGeneraliMinimi.cognome = descrFiguraFrom;
            benefMorteFromCopiaDaAltraFigura.datiResidenza = {};
          }
        }
        const percentualeBenefCorrente = currBenef.datiGeneraliBenefMorte.percBeneficiario;
        const percBenefCorrente = toNumber(percentualeBenefCorrente, locale_it);
        if (percBenefCorrente !== undefined && percBenefCorrente > 0) {
          // 2022-01-06 PFD: ricalcolo il totale con arrotondamento alla seconda cifra decimale
          //percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista);
          percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista).round(2);
          if (percTotale<=100.0) {
            const benificiario = benefMorteFromCopiaDaAltraFigura !== undefined ? benefMorteFromCopiaDaAltraFigura : currBenef;
            // INSERIMENTO NUOVO BENEFICIARIO
            if (isNuovo === true) {
              this.props.confermaAggiungiBeneficiarioMorteFisico(benificiario);
            } else {
              // MODIFICA BENEFICIARIO
              this.props.updateBeneficiarioMorteFisico(benificiario, actionType.UPDATE_BENEF_MORTE_FISICO);
            }
            // Aggiorno la percentuale
            this.props.updateDatiBeneficiarioMorte(percTotale, actionType.UPDATE_BENEF_MORTE_PERCENTUALE_TOTALE);		
            this.props.updateDatiBeneficiarioMorte(true, actionType.SALVA_BENEF_MORTE_FISICO_SUCCESS);
            
            //2021-07-01 PFD: TCM_INDIV
            this.handleTcmIndividualeValida();
          }
        }
      }
    }
    this.setState({
      isConfermaBenef : false,
      percentualeTotale : percTotale,
	});
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  // funzione callback 
  handleChangeEsclusioneComunicaz = (value) => {
    this.setState({
      flEsclusioneComunicaz: value,
    });
  }
  
  // funzione callback 
  handleChangeFlCopiaDaAltraFigura = (value) => {	 
    this.setState({
      isCopiaDaAltraFigura: value,
      statoCampi: this.initStatoCampi(),
    });
  }
  
  // funzione callback
  handleChangeFiguraFrom = figuraFrom => {
    this.setState({
      figuraFrom: figuraFrom,
    });
  }
  
  handlePercBeneficiario = event => {
    console.log('#RTCFA BenefMorteFisicoAss percBeneficiario new value:' + event.target.value);	
    this.props.updateDatiBeneficiarioMorte(event.target.value,  actionType.UPDATE_PERCENTUALE_BENEF_MORTE_FISICO); 	      
    this.setState({
      percBeneficiario: event.target.value,
    });	
  }
  
  initStatoCampi = () => {
    if (this.props.benefMorteFisico.datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S') {
      return [ {id: 'copiaDaAltraFiguraBenefMorteFisico', isError: false},
               {id: 'datiGeneraliBenefMorteFisico', isError: false},
               {id: 'datiResidenzaBenefMorteFisico', isError: false},  
               {id: 'datiRelazContrBenefMorteFisico', isError: false},
               {id: 'datiAntiriciclaggioBenefMorteFisico', isError: false},
             ];
    } else {
      return [ {id: 'copiaDaAltraFiguraBenefMorteFisico', isError: false},
               {id: 'percBeneficiario', isError: false},
               {id: 'datiRelazContrBenefMorteFisico', isError: false},
             ];
    }  
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
	  
  render() {
    console.log('#RTCFA BenefMorteFisicoAss this.props', this.props);
    //console.log('#RTCFA BenefMorteFisicoAss this.props.benefMorteFisico', this.props.benefMorteFisico);    
    const { classes, benefMorteFisico } = this.props;
    const currentBenef = benefMorteFisico;
    const datiGeneraliBenef = currentBenef.datiGeneraliBenefMorte;
    const datiCopiaDaAltraFigura = currentBenef.datiCopiaDaAltraFigura;  
	const { t } = this.props; //hoc 
	const percentualeTotaleError = this.state.percentualeTotale!==0 && parseFloat(this.state.percentualeTotale)!==100.0;
	const controlloDati = this.props.controlloDati;
	const isNuovo = this.state.isNuovoBenef;	
	const salvaBenefMorteFisicoSuccess = this.props.salvaBenefMorteFisicoSuccess;
	const percBenefInLista = getPercBenefByCognomeInList(this.props.benefMorteFisicoList, currentBenef);
	const isCopiaReadonly = percBenefInLista!==undefined;
	
    return (
      <Fragment>
      	<TabDatiRiepilogoFigura figura={'beneficiarioMorte'} tabHeader={'tabHeaderBenMorte'} />	
 	    <CopiaDaAltraFigura id={'copiaDaAltraFiguraBenefMorteFisico'} 
      			figura={BENEF_MORTE_FISICO} datiCopiaDaAltraFigura={datiCopiaDaAltraFigura} 
	      		handleChangeFlCopiaDaAltraFigura={this.handleChangeFlCopiaDaAltraFigura} 
	      	    handleChangeFiguraFrom={this.handleChangeFiguraFrom} 
	      	    onError={this.handleError}
      			readonly={isCopiaReadonly} />
      	{datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S' &&      	
      	  <Fragment>
            <DatiGeneraliBenefFisico figura={BENEF_MORTE_FISICO} datiGeneraliBenefMorteFisico={datiGeneraliBenef} labelFieldSet='datiGenerali'
                id={'datiGeneraliBenefMorteFisico'} onError={this.handleError} />
            <DatiResidenza figura={BENEF_MORTE_FISICO} datiResidenzaBenefMorteFisico={currentBenef.datiResidenza} labelFieldSet='datiDiResidenza' flEsclusioneComunicazBenef={this.state.flEsclusioneComunicaz}
                id={'datiResidenzaBenefMorteFisico'} onError={this.handleError} />
          </Fragment>
        } 
        {datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'S' &&
          <Fragment>
            <DividerComp titolo='&nbsp;' variant='subtitle1' />
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12}>
                <NumberFieldComp onBlur={this.handlePercBeneficiario} aStyle={classes.textField} id={'percBeneficiario'} label='percBeneficiario' format='###,###' 
                                 value={datiGeneraliBenef.percBeneficiario} maxLength='7' required={true} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
            </Grid>
          </Fragment>
      	}
        <DatiRelazContr figura={BENEF_MORTE_FISICO} datiRelazContrBenefMorteFisico={currentBenef.datiRelazContr} handleChangeEsclusioneComunicaz={this.handleChangeEsclusioneComunicaz}
                        id={'datiRelazContrBenefMorteFisico'} onError={this.handleError} relazioneConContr={'relazContrBenefMorte'} />
        
        {/* 2020-12-01 PFD: SAV 'TO BE' - START - ALCUNI DATI ANTIRICICLAGGIO PER BENEFICIARIO */}
        <DatiAntiriciclaggio figura={BENEF_MORTE_FISICO} datiAntiriciclaggio={currentBenef.datiAntiriciclaggio} labelFieldSet='datiAntiriciclaggio' 
        	id={'datiAntiriciclaggioBenefMorteFisico'} onError={this.handleError} />

        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center' >
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && !this.state.isPresenteErrore && percentualeTotaleError && !isNuovo && 
                <Typography className={classes.textErrorMessage}>{t('errTotPercBenef')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) && (salvaBenefMorteFisicoSuccess === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ButtonComp
               /* to={linkTo} */
               /* component={Link} */
               aClassName={classes.button}
               buttonLabel={t('conferma')}
               onClick={this.handleClickConfermaBenefMorte}
            />
          </Grid>
          <Grid item>
            <ButtonComp
              /* to={linkTo} */
              /* component={Link} */
              aClassName={classes.button}
              buttonLabel={t('nuovoBenefic')}
              onClick={this.handleClickNuovoBenefMorte}
            />
          </Grid>
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########BenefMorteFisicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefMorteFisicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefMorteFisicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	    
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDati !== '')) {
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiBeneficiarioMorte(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_FISICO);
/*      this.setState((isPresenteCampiConErrore) => ({
        isPresenteErrore: isPresenteCampiConErrore,
      }));*/
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });     
	}
	return isPresenteCampiConErrore;
  }
  
  isConfermaBenef = (prevProps, prevState, isPresenteCampiConErrore) => {   
//	const currIsPresenteErrore = this.state.isPresenteErrore;
	const currIsPresenteErrore = isPresenteCampiConErrore;
	const currIsConferma =  this.state.isConfermaBenef;	
	const prevIsPresenteErrore = prevState.isPresenteErrore;
	const prevIsConferma =  prevState.isConfermaBenef;
	if (currIsPresenteErrore!==undefined && currIsPresenteErrore!==null && currIsPresenteErrore===false ) {
      if (currIsConferma) {
        this.confermaBeneficiario();
      }
	}
	if (currIsConferma !== prevIsConferma) {
      this.setState({isConfermaBenef: false,})
	}	
  }
	 
  isFlgEsclusioni = (prevProps, prevState) => {   
	const currflEsclusioneComunicaz = this.props.benefMorteFisico.datiRelazContr.esclusioneComunicaz;
	const prevflEsclusioneComunicaz = this.state.flEsclusioneComunicaz;
    if (currflEsclusioneComunicaz!==undefined && currflEsclusioneComunicaz!==null && currflEsclusioneComunicaz !== prevflEsclusioneComunicaz) {
      this.setState({
        flEsclusioneComunicaz: currflEsclusioneComunicaz,
      });
	}
  }

  componentDidUpdate(prevProps, prevState) {
    const isPresenteCampiConErrore = this.isInError(prevProps, prevState);
    this.isConfermaBenef(prevProps, prevState, isPresenteCampiConErrore);
    this.isFlgEsclusioni(prevProps, prevState); // allineo lo stato con le props
  }
}

BenefMorteFisicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  benefMorteFisico: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BenefMorteFisicoAss)));
