import React, { Fragment, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../figure/action/contraente'
import * as actionType from '../figure/actionType/contraente';
import * as actionsBenefMorteGiuridico from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorteGiuridico from '../figure/actionType/beneficiarioMorte';
import * as actionsBenefVitaGiuridico from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVitaGiuridico from '../figure/actionType/beneficiarioVita';

import { CONTRAENTE_GIURIDICO, BENEF_MORTE_GIURIDICO, BENEF_VITA_GIURIDICO, CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, CODICE_SAE_FIDUCIARIA_GESTIONE } from '../../../utility/jsConstants';
import DividerComp from '../../common/DividerComp';
import { Grid } from '@material-ui/core';
import NumberFieldComp from '../../common/NumberFieldComp';
import { textTrim } from '../../../utility/genericUtility';
import RadioButtonComp from '../../common/RadioButtonComp';
import { radioButtonIntestatarioMandato } from '../../store/radioButtonStore';


const styles = theme => ({
    textField: theme.textField,
    dateField: theme.dateField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
    fieldSet: theme.fieldSet,
    divider: {
        width: theme.spacing(1),
    },
    button: theme.button,
    textErrorMessage: theme.textErrorMessage,
});


class DatiMandatoFiduciario extends Component {
    constructor(props) {
        super(props);
        const datiMandatoFiduciario = this.datiMandatoFiduciarioFrom(this.props.figura);
        this.state = {
            numeroMandatoFid: datiMandatoFiduciario.numeroMandatoFid,
            intestatarioMandato: datiMandatoFiduciario.intestatarioMandato,
        };
    };

    codiceSaeFrom = (figura) => {
        let codSae = null;
        if(figura === CONTRAENTE_GIURIDICO) {
            codSae = this.props.saeContraenteGiuridico;
        } else if(figura === BENEF_MORTE_GIURIDICO) {
            codSae = this.props.saeBenefMorteGiuridico;
        } else if(figura === BENEF_VITA_GIURIDICO) {
            codSae = this.props.saeBenefVitaGiuridico;
        }
        return codSae;
    }

    initStatoCampi = () => {
        return [
            { id: 'numeroMandatoFiduciario', isError: false },
            { id: 'intestatarioMandatoFid', isError: false },
        ]
    }

    isRequiredIntestatarioMandato = () => {
        let required = false;
        if(this.props.scopeAmleto) {
            if(this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_GESTIONE) {
                required = true;
            }
        }
        return required;
    }

    isRequiredNumeroMandato = () => {
        let required = false;
        if(this.props.scopeAmleto) {
            if(this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_GESTIONE) {
                required = true;
            }
        }
        return required;
    }

    static getDerivatedStateFromProps() {
        
    }

    datiMandatoFiduciarioFrom = figura => {
        let datiMandatoFiduciario = null;
        if (figura === CONTRAENTE_GIURIDICO) {
            datiMandatoFiduciario = this.props.datiMandatoFiduciario
        } else if(figura === BENEF_MORTE_GIURIDICO) {
            datiMandatoFiduciario = this.props.datiMandatoFiduciarioBenefMorteGiuridico
        } else if(figura === BENEF_VITA_GIURIDICO) {
            datiMandatoFiduciario = this.props.datiMandatoFiduciarioBenefVitaGiuridico
        }
        return datiMandatoFiduciario;
    }

    controlloDatiFrom = figura => {
        let controlloDati = null;
        if (figura === CONTRAENTE_GIURIDICO) {
            controlloDati = this.props.controlloDatiContraenteGiuridico
        } else if(figura === BENEF_MORTE_GIURIDICO) {
            controlloDati = this.props.controlloDatiBenefMorteGiuridico
        } else if(figura === BENEF_VITA_GIURIDICO) {
            controlloDati = this.props.controlloDatiBenefVitaGiuridico
        }
        return controlloDati;
    }

    handleChangeIntestatarioMandato = event => {
        const nuovoIntestatario = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
        const vecchioIntestatario = textTrim(this.props.intestatarioMandato, true);
        if (nuovoIntestatario !== vecchioIntestatario) {
            if(this.props.figura === BENEF_MORTE_GIURIDICO) {
                this.props.updateDatiMandatoFiduciarioBenefMorteGiuridico(nuovoIntestatario, actionTypeBenefMorteGiuridico.UPDATE_INTESTATARIO_MANDATO_BENEF_MORTE_GIURIDICO)
            } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
                this.props.updateDatiMandatoFiduciarioBenefVitaGiuridico(nuovoIntestatario, actionTypeBenefVitaGiuridico.UPDATE_INTESTATARIO_MANDATO_BENEF_VITA_GIURIDICO)
            } else {
                this.props.updateDatiMandatoFiduciario(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_MANDATO, this.props.figura);
            }
            this.setState({
                intestatarioMandato: (event === null) ? '' : event.target.value,
            })
        }
    }

    handleChangeDatiMandatoFiduciario = event => {
        const nuovoNumeroMandato = textTrim(event.target.value, true);
        const datiMandFiduc = this.datiMandatoFiduciarioFrom(this.props.figura);
        const vecchioNumeroMandato = textTrim(datiMandFiduc.numeroMandatoFid, true);
        if (nuovoNumeroMandato !== vecchioNumeroMandato) {
            if(this.props.figura === BENEF_MORTE_GIURIDICO) {
                this.props.updateDatiMandatoFiduciarioBenefMorteGiuridico(nuovoNumeroMandato, actionTypeBenefMorteGiuridico.UPDATE_MANDATO_BENEF_MORTE_GIURIDICO);
            } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
                this.props.updateDatiMandatoFiduciarioBenefVitaGiuridico(nuovoNumeroMandato, actionTypeBenefVitaGiuridico.UPDATE_MANDATO_BENEF_VITA_GIURIDICO);
            } else {    
                this.props.updateDatiMandatoFiduciario(nuovoNumeroMandato, actionType.UPDATE_NUM_MANDATO_FIDUCIARIO, this.props.figura);
            }
            this.setState({
                numeroMandatoFid: nuovoNumeroMandato,
            })
        }
    }

    isDisabledNumeroMandato = () => {
        let disabled = true;
        if(this.props.scopeAmleto) {
            if(this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_GESTIONE) {
                disabled = false;
            }
        }
        return disabled;
    }

    isDisabledIntestatarioMandato = () => {
        let disabled = true;
        if(this.props.scopeAmleto) {
            if(this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.codiceSaeFrom(this.props.figura) === CODICE_SAE_FIDUCIARIA_GESTIONE) {
                disabled = false;
            }
        }
        return disabled;
    }

    render() {
        const { labelFieldSet, classes, figura } = this.props;
        const datiMandatoFiduciario = this.datiMandatoFiduciarioFrom(figura);
        const controlloDati = this.controlloDatiFrom(figura);
        return (
            <Fragment>
                <DividerComp titolo={labelFieldSet} variant='subtitle1' />
                <Grid container direction='row' justify='flex-start' alignItems='center'>

                    <NumberFieldComp id='numeroMandatoFiduciario' aStyle={classes.textFieldWidthPerc} onError={this.handleError} required={this.isRequiredNumeroMandato()}
                        onBlur={this.handleChangeDatiMandatoFiduciario} value={datiMandatoFiduciario.numeroMandatoFid} controlloDati={controlloDati}
                        label='numeroMandatoFid' disabled={this.isDisabledNumeroMandato()} aStyleError={classes.textFieldWidthPercError} />

                    <Grid item md={5} xs={12}>
                        <RadioButtonComp id='intestatarioMandatoFid' radioComp={radioButtonIntestatarioMandato} onError={this.handleError}
                            controlloDati={controlloDati} required={this.isRequiredIntestatarioMandato()} onChange={this.handleChangeIntestatarioMandato}
                            selectedValue={datiMandatoFiduciario.intestatarioMandato} classes={{ divider: classes.divider }} disabled={this.isDisabledIntestatarioMandato()} />
                    </Grid>
                    <Grid item md={7} xs={12} />
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    datiMandatoFiduciario: state.areaContraenteReducer.contraenteGiuridico.datiMandatoFiduciario,
    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
    controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
    datiMandatoFiduciarioBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiMandatoFiduciario,
    datiMandatoFiduciarioBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiMandatoFiduciario,
    saeContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiAttEconomicaGiuridico.codSAE,
    saeBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiAttEconomica.codSAE,
    saeBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiAttEconomica.codSAE,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
})


const mapDispatchToProps = dispatch => {
    return {
        updateDatiMandatoFiduciario: (unDato, unActionType, figura) => dispatch(actions.updateDatiMandatoFiduciario(unDato, unActionType, figura)),
        updateDatiMandatoFiduciarioBenefMorteGiuridico: (unDato, unActionType) => 
                        dispatch(actionsBenefMorteGiuridico.updateDatiMandatoFiduciarioBenefMorteGiuridico(unDato, unActionType)),
        updateDatiMandatoFiduciarioBenefVitaGiuridico: (unDato, unActionType) => 
                        dispatch(actionsBenefVitaGiuridico.updateDatiMandatoFiduciarioBenefVitaGiuridico(unDato, unActionType))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiMandatoFiduciario)));
