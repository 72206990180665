import * as actionType from '../actionType/dominio';

export const dominioNazioni = unaLista => {
  return {
    type: actionType.DOMINIO_NAZIONI,
    unaLista,
  }
};

export const dominioProvince = unaLista => {
  return {
    type: actionType.DOMINIO_PROVINCE,
    unaLista,
  }
};

export const dominioTipoDocumento = unaLista => {
  return {
    type: actionType.DOMINIO_TIPO_DOCUMENTO,
    unaLista,
  }
};

export const dominioEnteRilascio = unaLista => {
  return {
    type: actionType.DOMINIO_ENTE_RILASCIO,
    unaLista,
  }
};

export const dominioScopoNaturaRapporto = unaLista => {
  return {
    type: actionType.DOMINIO_SCOPO_NATURA_RAPPORTO,
    unaLista,
  }
};

export const dominioTipoSocieta = unaLista => {
  return {
    type: actionType.DOMINIO_TIPO_SOCIETA,
    unaLista,
  }
};

export const dominioBeneficiarioGenericoMorte = unaLista => {
  return {
    type: actionType.DOMINIO_BENEFICIARIO_GENERICO_MORTE,
    unaLista,
  }
};

export const dominioBeneficiarioGenericoVita = unaLista => {
  return {
    type: actionType.DOMINIO_BENEFICIARIO_GENERICO_VITA,
    unaLista,
  }
};

export const dominioPrevalenteAttivitaSvolta = unaLista => {
  return {
    type: actionType.DOMINIO_PREVALENTE_ATTIVITA_SVOLTA,
    unaLista,
  }
};

export const dominioPrevalenteAttivitaSvoltaGiuridico = unaLista => {
  return {
    type: actionType.DOMINIO_PREVALENTE_ATTIVITA_SVOLTA_GIURIDICO,
    unaLista,
  }
};

export const dominioOrigineFondi = unaLista => {
  return {
    type: actionType.DOMINIO_ORIGINE_FONDI,
    unaLista,
  }
};

export const dominioAllocazioni = unaLista => {
  return {
    type: actionType.DOMINIO_ALLOCAZIONI,
    unaLista,
  }
};

export const dominioFondi = unaLista => {
  return {
    type: actionType.DOMINIO_FONDI,
    unaLista,
  }
};

export const dominioPercTakeProfit = unaLista => {
  return {
    type: actionType.PERCENTUALE_OBIETTIVO_TAKEPROFIT,
    unaLista,
  }
};

export const dominioFrequenzaErogazioniPeriodiche = unaLista => {
  return {
    type: actionType.FREQUENZA_EROGAZIONI_PERIODICHE,
    unaLista,
  }
};

export const dominioModalitaPagamento = unaLista => {
  return {
    type: actionType.MODALITA_PAGAMENTO,
    unaLista,
  }
};

export const dominioBanca = unaLista => {
  return {
    type: actionType.BANCA,
    unaLista,
  }
};

export const dominioConsensi = unaLista => {
  return {
    type: actionType.CONSENSI,
    unaLista,
  }
};

export const dominioDichiarazioniIntermediario = unaLista => {
  return {
    type: actionType.DICHIARIAZIONI_INTERMEDIARIO,
    unaLista,
  }
};
	
export const dominioRegione = unaLista => {
  return {
    type: actionType.REGIONI,
    unaLista,
  }
};

export const dominioDurataErogazioniPeriodiche = unaLista => {
  return {
    type: actionType.DURATA_EROGAZIONI_PERIODICHE,
    unaLista,
  }
};

export const dominioClassePremioPotenziale = unaLista => {
  return {
    type: actionType.CLASSE_PREMIO_POTENZIALE,
    unaLista,
  }
};

export const dominioTariffaPerProdotto = unaLista => {
  return {
    type: actionType.TARIFFA_PER_PRODOTTO,
    unaLista,
  }
};

export const dominioCapitaleAssicuratoPerProdotto = unaLista => {
  return {
    type: actionType.CAPITALE_ASSICURATO_PER_PRODOTTO,
    unaLista,
  }
};

export const dominioFrequenzaCapitaleAssicurato = unaLista => {
  return {
    type: actionType.FREQUENZA_CAPITALE_ASSICURATO,
    unaLista,
  }
};

export const dominioRuoloXProdotto = unaLista => {
  return {
    type: actionType.RUOLO_X_PRODOTTO,
    unaLista,
  }
};

export const dominioMandato = unaLista => {
  return {
    type: actionType.MANDATO,
    unaLista,
  }
};

export const dominioProfilo = unaLista => {
  return {
    type: actionType.PROFILO,
    unaLista,
  }
};

export const dominioNazioneItalia = unaLista => {
  return {
    type: actionType.DOMINIO_NAZIONE_ITALIA,
    unaLista,
  }
};

export const dominioProfessioniQas = unaLista => {
  return {
    type: actionType.DOMINIO_PROFESSIONI_QAS,
    unaLista
  }
}

export const dominioSportsQas = unaLista => {
  return {
    type: actionType.DOMINIO_SPORT_QAS,
    unaLista
  }
}

export const dominioRelazione = unaLista => {
  return {
    type: actionType.DOMINIO_RELAZIONI,
    unaLista
  }
}

export const dominioPaesiBlackList = unaLista => {
  return {
    type: actionType.DOMINIO_PAESI_BLACK_LIST,
    unaLista
  }
}

export const dominioCittadinanzaPaesiBlackList = unaLista => {
  return {
    type: actionType.DOMINIO_CITTADINANZA_PAESI_BLACK_LIST,
    unaLista
  }
}

export const dominioProfiloRischio = unaLista => {
  return {
    type: actionType.DOMINIO_PROFILO_RISCHIO,
    unaLista,
  }
};


export const dominioProfiloRischioObbligo = unValore => {
  return {
    type: actionType.DOMINIO_PROFILO_RISCHIO_OBBLIGO,
    unValore,
  }
};

export const dominioRapportoContraenteIntermediario = unaLista => {
  return {
    type: actionType.DOMINIO_RAPPORTO_CONTRAENTE_INTERMEDIARIO,
    unaLista,
  }
};