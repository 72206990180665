import * as actionType from '../actionType/datiQuestionario';

export const updateDatiQuestionario = (unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario) => {	
  return {
    type: unActionType,
    unDato,
    numDomanda,
    idRisposta,
    idTesto,
    tipoQuestionario,
  }
};

export const updateQuestionario = (unDato, tipoQuestionario, unActionType) => {
  return {
    type: unActionType,
    unDato,
    tipoQuestionario,
  }
};

export const salvaEsitoDatiQuestionario = (esito, esitoMessaggio, tipoQuestionario) => {
  return {
    type: actionType.SALVA_ESITO_DATI_QUESTIONARIO,
    esito,
    esitoMessaggio,
    tipoQuestionario,
  }
};


export const updateIsChangedFromSemplificato = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

export const updateTipoCurrentQuestionario = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}
/*
export const updateShowSemplificato = (showSemplificato, showOnlySemplificato, unActionType) => {
  return {
    type: unActionType,
    showSemplificato,
    showOnlySemplificato
  }
}
*/