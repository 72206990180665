import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
//INI import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
//import { MuiPickersUtilsProvider, DatePicker } from '@material-ui-pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRange from '@material-ui/icons/DateRange';
import { withTranslation } from 'react-i18next';

import { isDataValid } from '../../utility/genericUtility';

import moment from 'moment';
import 'moment/locale/it';

//import { MAX_DATE } from '../../utility/jsConstants';
//import useState from 'react';

const styles = theme => ({
	dateFieldError: theme.dateFieldError,	
});

moment.locale('it');

const formatDate = 'DD/MM/YYYY';

class DatePickerComp extends React.Component {
	
  constructor(props) {
    super(props);
	this.state = {
      selectedDate: this.props.value,
      typing: false,
      isError: false,
	};
  };

  handleChange = aDate => {
//    console.log('DatePickerComp handleChange old value this.state.selectedDate', this.state.selectedDate);
//    console.log('DatePickerComp handleChange new value date', aDate);
    this.setState({
      selectedDate: aDate,
      typing: false,
    });
	if(this.props.onChange) {
      this.props.onChange(aDate);
	}
  };
	 
  handleOnOpen = () => {
    this.setState({
      selectedDate: this.props.value,
      typing: true,
    });
//    if(this.props.onOpen) {
//      this.props.onOpen();
//    }
  };
	  
  handleOnClose = () => {
    this.setState({
      typing: false,
    });
//    if(this.props.onClose) {
//      this.props.onClose(aDate);
//    }
  };
	  
  handleOnBlur = aDate => {
//    console.log('DatePickerComp handleChange old value this.state.selectedDate', this.state.selectedDate);
//    console.log('DatePickerComp handleChange new value date', aDate);
    this.setState({
      selectedDate: aDate,
      typing: false,
    });
	if(this.props.onBlur) {
      this.props.onBlur(aDate);
	}
  };
	   
  handleError = (isError, id) => {
//    console.log('#######DatePickerComp handleError', id + ' ' + isError);
    this.setState({
      isError: isError,
    });    
    if(this.props.onError) {
      this.props.onError(id, isError);
    }
  }; 
	  
  dateFormat = aDate => {
    const unaData = ((aDate === undefined) || (aDate === null)) ? null : aDate;
    return unaData;
  }
			  
  valueDate = () => {
    const aValue = (this.state.typing === true) ? this.dateFormat(this.state.selectedDate) : this.dateFormat(this.props.value);
    return aValue;
  };  
	  
  render() {
	const { classes, onChange, aStyle, aStyleError, id, label, value, required, controlloDati, disabled, readonly, isControlError } = this.props;	    
	const { t } = this.props; //hoc		
//    console.log('#RT2 DatePickerComp render props.value', value);
//    console.log('#RT2 DatePickerComp render this.state.selectedDate', this.state.selectedDate); 
//    const aValue = this.valueDate();
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const isReadonly = readonly === true;
    const isDisabledOrReadonly = (disabled === true || readonly === true);
    const aValue = isDisabled ? null : this.valueDate();
//    console.log('###RT2 DatePickerComp render aValue', aValue); 
//    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === null);  
    const isError = (((controlloDati === true) && isRequired && !isDisabled && (aValue === null)) || (isControlError === true) || (!isDataValid(this.state.selectedDate)));       
    const aStyleToApply = (isError) ? (aStyleError ? aStyleError : classes.dateFieldError) : aStyle;
//    console.log('###RT2 DatePickerComp render aStyleToApply', aStyleToApply); 
//    const [selectedDate, handleDateChange] = useState(new Date());
    const aPlaceholder = 'gg/mm/aaaa';
    const aLabel = t(label);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>  		
        <KeyboardDatePicker
          id={id}
          error={isError}
          required={required}
          disabled={isDisabledOrReadonly}
          className={classNames(aStyleToApply)}	
          margin='normal'
          onOpen={this.handleOnOpen}
          onClose={this.handleOnClose}        	  
          autoOk
          strictCompareDates={true}
//          mask='gg/mm/aaaa'
          invalidDateMessage=''
          minDate='01-01-1900'
          minDateMessage=''        
          maxDate='01-01-2200'
          maxDateMessage=''
          variant='inline'
          inputVariant='outlined'
//          label={t(label)}
          label={aLabel}        	  
          placeholder={aPlaceholder}
          format={formatDate}
//        value={selectedDate}
          value={aValue}    	  
          InputAdornmentProps={{ position: 'end' }}      
//        onChange={date => handleDateChange(date)}
          onChange={this.handleChange}
//          onBlur={this.handleOnBlur}
        />      
{/*        <KeyboardDatePicker
            id={id}
            clearable 
        	error={isError}
            required={required}
        	disabled={isDisabledOrReadonly}
        	className={classNames(aStyleToApply)}	
            margin='normal'
            label={t(label)}
            value={aValue}
            onChange={this.handleChange}
            onOpen={this.handleOnOpen}
            onClose={this.handleOnClose}        
            variant='outlined'
            format={formatDate}
            //maxDate={moment(MAX_DATE, formatDate)}
            InputProps={{
			  endAdornment: (							    		
			    <InputAdornment position='end'>
				  <DateRange />
				</InputAdornment>
			  ),
			}}
        />*/}
      </MuiPickersUtilsProvider>
    );
  }
 
  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;    
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? null : this.valueDate();
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === null);
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
//      console.log('#######prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, isError = ' + isError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);      
    }
  }  
}

DatePickerComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(DatePickerComp));