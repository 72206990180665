import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import SelectComp from '../../common/SelectComp'
import DividerComp from '../../common/DividerComp'
import { CONTRAENTE_FISICO, CODICE_ATRA_FONTE_REDDITO } from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';
import PropTypes from 'prop-types';

import * as actions from '../figure/action/contraente';
import * as actionType from '../figure/actionType/contraente';

import * as dominioActions from '../../common/action/dominio';

import { Grid, Typography } from '@material-ui/core';
import TextFieldComp from '../../common/TextFieldComp';
import { withStyles } from '@material-ui/styles';
import RadioButtonComp from '../../common/RadioButtonComp';
import { radioButtonContraentePrimaCasa, radioButtonContraenteAtriImmobili } from '../../store/radioButtonStore';
import NumberFieldComp from '../../common/NumberFieldComp';
import { withTranslation } from 'react-i18next';
import { fetchParam, uris } from '../../../uris';
import moment from 'moment';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: theme.textField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
    dateField: theme.dateField,
    fieldSet: theme.fieldSet,
});

const mapStateToProps = (state) => ({
    datiFinanziari: state.areaContraenteReducer.contraenteFisico.datiFinanziari,
    controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
    dominio: state.areaDatiDominioReducer.dominio,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
})

const mapDispatchToProps = dispatch => {
    return {
        updateDatiFinanziariContraenteFisico: (unDato, unActionType) => dispatch(actions.updateDatiFinanziariContraenteFisico(unDato, unActionType)),
    }
}

export class DatiSituazioneFinanziaria extends Component {
    constructor(props) {
        super(props);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const ripartizione = datiFinanziari.ripartizioneInvestimentiFinanziari;
        this.state = {
//2020-12-01 PFD: SAV 'TO BE'            descFonteReddito: datiFinanziari.descFonteReddito,
//2020-12-01 PFD: SAV 'TO BE'            codFonteReddito: datiFinanziari.codFonteReddito,
            codRedditoAnnuo: datiFinanziari.codRedditoAnnuo,
            descRedditoAnnuo: datiFinanziari.descRedditoAnnuo,
//2020-12-01 PFD: SAV 'TO BE'            primaCasa: datiFinanziari.primaCasa,
//2020-12-01 PFD: SAV 'TO BE'            altriImmobili: datiFinanziari.altriImmobili,
            codPatrimonioFinanziario: datiFinanziari.codPatrimonioFinanziario,
            descPatrimonioFinanziario: datiFinanziari.descPatrimonioFinanziario,
//2020-12-01 PFD: SAV 'TO BE'            codImpegnoFinanziario: datiFinanziari.codImpegnoFinanziario,
//2020-12-01 PFD: SAV 'TO BE'            descImpegnoFinanziario: datiFinanziari.descImpegnoFinanziario,
//2020-12-01 PFD: SAV 'TO BE'            strumentiMercatoMonetario: ripartizione.strumentiMercatiMonetario,
//2020-12-01 PFD: SAV 'TO BE'            strumentiObbligazionari: ripartizione.strumentiObbligazionari,
//2020-12-01 PFD: SAV 'TO BE'            strumentiAzionari: ripartizione.strumentiAzionari,
//2020-12-01 PFD: SAV 'TO BE'            strumentiAssicurativi: ripartizione.strumentiAssicurativi,
//2020-12-01 PFD: SAV 'TO BE'            strumentiComplessi: ripartizione.strumentiComplessi,
            fonteRedditoItems: [],
            redditoAnnuoItems: [],
            patrimonioFinanziarioItems: [],
//2020-12-01 PFD: SAV 'TO BE'           impegniFinanziariItems: [],
            statoCampi: this.initStatoCampi(),
            isPresenteError: false
        }
    }

    componentDidMount = () => {
        const dataOggiFormatta = moment().format('DD/MM/YYYY');
        const siglaVersione = 'ZURI';
        /*2020-12-01 PFD: SAV 'TO BE' - START
        const optsFonteReddito = {
            siglaVersione: siglaVersione,
            codiceCampo: 'FRED',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.fonteRedditoItems, fetchParam(optsFonteReddito))
        .then(
            response => response.json(),
            error => console.error("An Error occured...", error)
        )
        .then((result) => {
            this.setState({
                fonteRedditoItems: result
            });
        });
        2020-12-01 PFD: SAV 'TO BE' - END */
    	
        const optsRedditoAnnuo = {
            siglaVersione: siglaVersione,
            codiceCampo: 'RANN',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.redditoAnnuoItems, fetchParam(optsRedditoAnnuo))
        .then(
            response => response.json(),
            error => console.error("An Error occured...", error)
        )
        .then((result) => {
            this.setState({
                redditoAnnuoItems: result
            })
        });
        const optsPatrFinanFis = {
            siglaVersione: siglaVersione,
            codiceCampo: 'PFIF',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.patrimonioFinanziarioFisicoItems, fetchParam(optsPatrFinanFis))
        .then(
            response => response.json(),
            error => console.error("An Error occured...", error)
        )
        .then((result) => {
            this.setState({
                patrimonioFinanziarioItems: result
            })
        })
        /* 2020-12-01 PFD: SAV 'TO BE' - START
        const optsImpegniFinan = {
            siglaVersione: siglaVersione,
            codiceCampo: 'IFME',
            dataRichiesta: dataOggiFormatta
        }
        fetch(uris.impegniFinanziariItems, fetchParam(optsImpegniFinan))
        .then(
            response => response.json(),
            error => console.error("An Error occured...", error)
        )
        .then((result) => {
            this.setState({
                impegniFinanziariItems: result
            })
        })
        2020-12-01 PFD: SAV 'TO BE' - END */ 
    }

    datiSitFinanziariaFrom = figura => {
        let datiFinanziari = null;
        if (figura === CONTRAENTE_FISICO) {
            datiFinanziari = this.props.datiFinanziari;
        }
        return datiFinanziari;
    }

    controlloDatiFrom = figura => {
        let controlloDati = null;
        if (figura === CONTRAENTE_FISICO) {
            controlloDati = this.props.controlloDatiContraenteFisico;
        }
        return controlloDati;
    }

    initStatoCampi = () => {
        return [
        	/* 2020-12-01 PFD: SAV 'TO BE' - START
            { id: 'fonteReddito', isError: false },
            { id: 'altro', isError: false },
            2020-12-01 PFD: SAV 'TO BE' - END */ 
            { id: 'redditoAnnuo', isError: false },
            { id: 'patrimonioFinanziario', isError: false },
            /* 2020-12-01 PFD: SAV 'TO BE' - START
            { id: 'primaCasa', isError: false },            
            { id: 'altriImmobili', isError: false },
            { id: 'impegnoFinanziario', isError: false },            
            { id: 'strumentiMercMon', isError: false },
            { id: 'strumentiObbl', isError: false },
            { id: 'strumentiAzionari', isError: false },
            { id: 'strumentiAssicurativi', isError: false },
            { id: 'strumentiComplessi', isError: false },
            2020-12-01 PFD: SAV 'TO BE' - END */ 
        ];
    }

    handleError = (id, isError) => {
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
            unErrore = campoOnId[0];
            unErrore.isError = isError;
        } else {
            unErrore = { id: id, isError: isError };
        }
        this.setState({
            statoCampi: [...campoWithoutId, unErrore],
        });
    }
   /* 2020-12-01 PFD: SAV 'TO BE' - START
    handleChangeFonteReddito = (event) => {
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchiaFonteReddito = textTrim(datiFinanziari.codFonteReddito, true);
        const nuovaFonteReddito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        if (nuovaFonteReddito !== vecchiaFonteReddito) {
            this.props.updateDatiFinanziariContraenteFisico(event, actionType.UPDATE_FONTE_REDDITO)
            this.setState({
                codFonteReddito: nuovaFonteReddito,
                descFonteReddito: (event === null) ? '' : event.label
            })
            if (nuovaFonteReddito === 'ALTRO') {
                this.setState({
                    altro: ''
                })
                this.props.updateDatiFinanziariContraenteFisico('', actionType.UPDATE_FONTE_REDDITO_ALTRO)
            }
        }
    }
    
    handleChangeAltro = event => {
        const nuovoAltro = textTrim(event.target.value, true);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchioAltro = textTrim(datiFinanziari.altro, true);
        if (nuovoAltro !== vecchioAltro) {
            this.props.updateDatiFinanziariContraenteFisico(nuovoAltro, actionType.UPDATE_FONTE_REDDITO_ALTRO);
            this.setState({
                altro: nuovoAltro,
            });
        }
    };
	2020-12-01 PFD: SAV 'TO BE' - END */
    
    handleChangeRedditoAnnuo = event => {
        const nuovoReddito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchioReddito = textTrim(datiFinanziari.codRedditoAnnuo, true);
        if (nuovoReddito !== vecchioReddito) {
            this.props.updateDatiFinanziariContraenteFisico(event, actionType.UPDATE_REDDITO_ANNUO);
            this.setState({
                codRedditoAnnuo: nuovoReddito,
                descRedditoAnnuo: (event === null) ? '' : event.label
            })
        }
    }
    
    handleChangePatrimonioFinanziario = event => {
        const nuovoPatrimonio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchioPatrimonio = textTrim(datiFinanziari.codPatrimonioFinanziario, true);
        if (nuovoPatrimonio !== vecchioPatrimonio) {
            this.props.updateDatiFinanziariContraenteFisico(event, actionType.UPDATE_PATRIMONIO_FINANZIARIO);
            this.setState({
                codPatrimonioFinanziario: nuovoPatrimonio,
                descPatrimonioFinanziario: (event === null) ? '' : event.label
            })
        }
    }

    /* 2020-12-01 PFD: SAV 'TO BE' - START
    handleChangePrimaCasa = event => {
        const primaCasa = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(primaCasa, actionType.UPDATE_PRIMA_CASA);
        this.setState({
            primaCasa: primaCasa
        })
    }

    handleChangeAltriImmobili = event => {
        const altriImmobili = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(altriImmobili, actionType.UPDATE_ALTRI_IMMOBILI);
        this.setState({
            altriImmobili: altriImmobili
        })
    }	
    
    handleChangeImpegniFinanziari = event => {
        const nuovoImpegno = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const vecchioImpegno = textTrim(datiFinanziari.codImpegnoFinanziario, true);
        if (nuovoImpegno !== vecchioImpegno) {
            this.props.updateDatiFinanziariContraenteFisico(event, actionType.UPDATE_IMPEGNO_FINANZIARIO);
            this.setState({
                codImpegnoFinanziario: nuovoImpegno,
                descImpegnoFinanziario: (event === null) ? '' : event.label
            })
        }
    }	
    
    handleChangeMercMon = event => {
        const strumentiMercatoMonetario = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(strumentiMercatoMonetario, actionType.UPDATE_MERCATO_MONETARIO)
        this.setState({
            strumentiMercatiMonetario: strumentiMercatoMonetario
        })
    }

    handleChangeObbligazioni = event => {
        const strumentiObbligazionari = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(strumentiObbligazionari, actionType.UPDATE_STRUMENTI_OBBLIGAZIONARI)
        this.setState({
            strumentiObbligazionari: strumentiObbligazionari
        })
    }

    handleChangeAzioni = event => {
        const strumentiAzionari = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(strumentiAzionari, actionType.UPDATE_STRUMENTI_AZIONARI)
        this.setState({
            strumentiAzionari: strumentiAzionari
        })
    }

    handleChangeAssicurativi = event => {
        const strumentiAssicurativi = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(strumentiAssicurativi, actionType.UPDATE_STRUMENTI_ASSICURATIVI)
        this.setState({
            strumentiAssicurativi: strumentiAssicurativi
        })
    }

    handleChangeComplessi = event => {
        const strumentiComplessi = event.target.value;
        this.props.updateDatiFinanziariContraenteFisico(strumentiComplessi, actionType.UPDATE_STRUMENTI_COMPLESSI)
        this.setState({
            strumentiComplessi: strumentiComplessi
        })
    }	
    
    isRequiredFonteReddito = () => {
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
        // return this.props.scopeAmleto === true;
    	return false;
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }
	2020-12-01 PFD: SAV 'TO BE' - END */
    
    isRequiredRedditoAnnuo = () => {
      //2021-07-01 PFD: TCM_INDIV    return this.props.scopeAmleto;
  	  let required = true;
  	  if (this.props.scopeTcmEmissione) {
  		  const isTcmIndividValida = this.props.tcmIndividualeValida;
  		  if (isTcmIndividValida) {
  			required = false;
  		  }
  	  } else {
  		required = this.props.scopeAmleto;
  	  }
  	  return required;
    }

    /* 2020-12-01 PFD: SAV 'TO BE' - START
    isRequiredAltro = () => {
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const fonteReddito = textTrim(datiFinanziari.codFonteReddito, true);
        return (fonteReddito === CODICE_ATRA_FONTE_REDDITO) && this.props.scopeAmleto === true;
    }	
    
    isRequiredPrimaCasa = () => {
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
        // return this.props.scopeAmleto === true;
    	return false;
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }

    isRequiredAltriImmobili = () => {
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
        // return this.props.scopeAmleto === true;
    	return false;
    	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }
    2020-12-01 PFD: SAV 'TO BE' - END */
    
    isRequiredPatrimonioFinanziario = () => {
	  //2021-07-01 PFD: TCM_INDIV    return this.props.scopeAmleto;
	  let required = true;
	  if (this.props.scopeTcmEmissione) {
		  const isTcmIndividValida = this.props.tcmIndividualeValida;
		  if (isTcmIndividValida) {
			required = false;
		  }
	  } else {
		required = this.props.scopeAmleto;
	  }
	  return required;
    }
	
    /* 2020-12-01 PFD: SAV 'TO BE' - START    
    isRequiredImpegnoFinanziario = () => {
        return false;
    }
    
    isRequiredCampoRipartizione = () => {
        let required = false;
        //if(this.props.scopeAmleto) {
        //   required = true;
        //}
        return required;
    }
    
    isDisabledFonteReddito = () => {
        return this.props.scopeAmleto === false;
    }
    2020-12-01 PFD: SAV 'TO BE' - END */ 

    isDisabledRedditoAnnuo = () => {
        return this.props.scopeAmleto === false;
    }

    /* 2020-12-01 PFD: SAV 'TO BE' - START
    isDisabledPrimaCasa = () => {
        return this.props.scopeAmleto === false;
    }

    isDisabledAltriImmobili = () => {
        return this.props.scopeAmleto === false;
    }
    2020-12-01 PFD: SAV 'TO BE' - END */
    isDisabledPatriFinanz = () => {
        return this.props.scopeAmleto === false;
    }
	
    /* 2020-12-01 PFD: SAV 'TO BE' - START
    isDisabledImpegnoFinanz = () => {
        return this.props.scopeAmleto === false;
    }
    
    isDisabledCampoRipartizione = () => {
        return this.props.scopeAmleto === false;
    }

    isDisabledAltro = () => {
        const datiFinanziari = this.datiSitFinanziariaFrom(this.props.figura);
        const fonteReddito = textTrim(datiFinanziari.codFonteReddito, true);
        return (fonteReddito !== CODICE_ATRA_FONTE_REDDITO);
    }
    2020-12-01 PFD: SAV 'TO BE' - END */

    render() {
        const { labelFieldSet, figura, classes, dominio } = this.props;
        const { t } = this.props;
        const datiFinanziari = this.datiSitFinanziariaFrom(figura)
        const { ripartizioneInvestimentiFinanziari } = datiFinanziari;
        const controlloDati = this.controlloDatiFrom(figura);
        /* TODO settare dominio */
        return (
            <Fragment>
                <DividerComp titolo={labelFieldSet} variant='subtitle1' />
                <Grid container direction='row' justify='center' alignItems='flex-start'>
                    {/* 2020-12-01 PFD: SAV 'TO BE' - START
                	<Grid item md={6} xs={12}>
                        <SelectComp options={this.state.fonteRedditoItems} onChange={this.handleChangeFonteReddito}
                            value={{ value: datiFinanziari.codFonteReddito, label: datiFinanziari.descFonteReddito }} etichetta='fonteReddito' disabled={this.isDisabledFonteReddito()}
                            required={this.isRequiredFonteReddito()} controlloDati={controlloDati} id='fonteReddito' onError={this.handleError} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextFieldComp onBlur={this.handleChangeAltro} id='altro' label='general.seAltroSpecificare' aStyle={classes.textFieldWidthPerc}
                            aStyleError={classes.textFieldWidthPercError} value={datiFinanziari.altro} required={this.isRequiredAltro()}
                            onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledAltro()} />
                    </Grid>
                    2020-12-01 PFD: SAV 'TO BE' - END */}
                    <Grid item md={6} xs={12}>
                        <SelectComp options={this.state.redditoAnnuoItems} onChange={this.handleChangeRedditoAnnuo} disabled={this.isDisabledRedditoAnnuo()}
                            value={{ value: datiFinanziari.codRedditoAnnuo, label: datiFinanziari.descRedditoAnnuo }} etichetta='redditoAnnuo'
                            required={this.isRequiredRedditoAnnuo()} controlloDati={controlloDati} id='redditoAnnuo' onError={this.handleError} />
                    </Grid>
                    {/* 2020-12-01 PFD: SAV 'TO BE' - START
                    <Grid item md={6} xs={12}></Grid>                    
                    <Grid item md={6} xs={12}>
                        <RadioButtonComp radioComp={radioButtonContraentePrimaCasa} id='primaCasa' onChange={this.handleChangePrimaCasa} selectedValue={datiFinanziari.primaCasa}
                            required={this.isRequiredPrimaCasa()} controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledPrimaCasa()} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RadioButtonComp radioComp={radioButtonContraenteAtriImmobili} id='altriImmobili' onChange={this.handleChangeAltriImmobili} disabled={this.isDisabledAltriImmobili()}
                            selectedValue={datiFinanziari.altriImmobili} required={this.isRequiredAltriImmobili()} controlloDati={controlloDati} onError={this.handleError} />
                    </Grid>
                    2020-12-01 PFD: SAV 'TO BE' - END */}                
                    <Grid item md={6} xs={12}>
                        <SelectComp options={this.state.patrimonioFinanziarioItems} onChange={this.handleChangePatrimonioFinanziario} disabled={this.isDisabledPatriFinanz()}
                            value={{ value: datiFinanziari.codPatrimonioFinanziario, label: datiFinanziari.descPatrimonioFinanziario }} etichetta='patrimonioFinanziario'
                            required={this.isRequiredPatrimonioFinanziario()} controlloDati={controlloDati} id='patrimonioFinanziario' onError={this.handleError} />
                    </Grid>
                    
                    {/* 2020-12-01 PFD: SAV 'TO BE' - START
                    <Grid item md={6} xs={12}>
                        <SelectComp options={this.state.impegniFinanziariItems} onChange={this.handleChangeImpegniFinanziari} disabled={this.isDisabledImpegnoFinanz()}
                            value={{ value: datiFinanziari.codImpegnoFinanziario, label: datiFinanziari.descImpegnoFinanziario }} etichetta='impegnoFinanziario'
                            required={this.isRequiredImpegnoFinanziario()} controlloDati={controlloDati} id='impegnoFinanziario' onError={this.handleError} />
                    </Grid>                   
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.textField}>{t('labelRipartizioneInvestimenti')}</Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiMercMon' label='strumentiMercMon' controlloDati={controlloDati} 
                                         onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeMercMon} disabled={this.isDisabledCampoRipartizione()}
                                         value={ripartizioneInvestimentiFinanziari.strumentiMercatoMonetario} aStyle={classes.textField} 
                                         required={this.isRequiredCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiObbl' label='strumentiObbl' controlloDati={controlloDati} 
                                         onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeObbligazioni} disabled={this.isDisabledCampoRipartizione()}
                                         value={ripartizioneInvestimentiFinanziari.strumentiObbligazionari} aStyle={classes.textField}
                                         required={this.isRequiredCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiAzionari' label='strumentiAzionari' controlloDati={controlloDati} 
                                         onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeAzioni} disabled={this.isDisabledCampoRipartizione()}
                                         value={ripartizioneInvestimentiFinanziari.strumentiAzionari} aStyle={classes.textField}
                                         required={this.isRequiredCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp margin={5} fullWidth={true} id='strumentiAssicurativi' label='strumentiAssicurativi' controlloDati={controlloDati} 
                                         onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeAssicurativi} disabled={this.isDisabledCampoRipartizione()}
                                         value={ripartizioneInvestimentiFinanziari.strumentiAssicurativi} aStyle={classes.textField}
                                         required={this.isRequiredCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <NumberFieldComp fullWidth={true} id='strumentiComplessi' label='strumentiComplessi' controlloDati={controlloDati} 
                                         onError={this.handleError} maximumFractionDigits={2} onBlur={this.handleChangeComplessi} disabled={this.isDisabledCampoRipartizione()}
                                         value={ripartizioneInvestimentiFinanziari.strumentiComplessi} aStyle={classes.textField}
                                         required={this.isRequiredCampoRipartizione()} />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}></Grid>
                    <Grid item md={1} xs={12}></Grid>
                    2020-12-01 PFD: SAV 'TO BE' - END */}
                </Grid>
            </Fragment>
        )
    }

isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiNaturaRapporto componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiNaturaRapporto componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiNaturaRapporto componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
		  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }    
}

DatiSituazioneFinanziaria.propTypes = {
    classes: PropTypes.object.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiSituazioneFinanziaria)));
