import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import LensIcon from '@material-ui/icons/Lens';

import ButtonComp from './ButtonComp';

import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    color: theme.palette.color.white,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
  },
  centerDiv: {
	  textAlign: 'center',
  },
  button: theme.button,
  lensIconOK: {
    margin: 0,
    color: theme.palette.color.green,
  },
  lensIconWARNING: {
    margin: 0,
    color: theme.palette.color.gold,
  },
  lensIconERROR: {
    margin: 0,
    color: theme.palette.color.red,
  },  
});

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },   
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {	
	backgroundColor: theme.expansion.background,
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
/*    iconColor: 'white', */ 
  },
/*  expandIcon: {
    iconColor: 'white', 
  },  */
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
  },
}))(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({

  root: {
    //padding: theme.spacing(2),
	  padding: 0,
//		  height: 600,
//		  background: 'red',
  },
})) (MuiExpansionPanelDetails);

function ExpansionPanelComp(props) {
	
  const { classes, titolo, componente, tabList, tabSelezionato, buttonList, isValida, senzaLensIcon, isDisabled, key } = props;
  const { t } = props;   //hoc 
  
  const UnComponente = componente; 
  const disabled = props.isDisabled===true;

  const [expanded, setExpanded] = useState(key)
  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  
  return (
    <div className={classes.root}> 
      <ExpansionPanel disabled={disabled} expanded={expanded} onChange={handleChangePanel(key)} >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id={key} >
          <Grid container direction='row' justify='flex-start' alignItems='center'>
            {(senzaLensIcon===undefined || senzaLensIcon===null) &&
              <LensIcon className={((isValida === undefined) || (isValida === null))
                                      ? classes.lensIconWARNING 
                                      : ((isValida === true) ? classes.lensIconOK : classes.lensIconERROR)} />
            }
            <Typography variant='h6' className={classes.heading}>&nbsp;</Typography>
            <Typography variant='h6' className={classes.heading}>{t(titolo)}</Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
		  <Grid container>
		    <Grid item xs={12}>
			  <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={2}>
				 <Grid item xs={12}>
				   {/* <NavTabsComp tabList={tabList} />*/}
				   {tabList && componente &&
				     <UnComponente tabList={tabList} value={props.tabSelezionato} 
				   		handleChange={props.handleChange} 
				   		handleChangeConfermaContraenteGiuridico={props.handleChangeConfermaContraenteGiuridico}
              handleChangeFlTitolareEff={props.handleChangeFlTitolareEff}
				   	 />
				   }
				   {!tabList && componente &&
                     <UnComponente flAssUgContr={props.flAssUgContr} />
				   }
			     </Grid>
				 <Grid item xs={12}>
				   <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
				     {buttonList && buttonList.map((buttonItem, index) => (
					   <Grid item key={index}>
					     <ButtonComp
		                   /* to={linkTo} */
                           /* component={Link} */
				    	   aClassName={classes.button}
				    	   buttonLabel={t(buttonItem.title)}
                           onClick={buttonItem.onClick}
                         />
                       </Grid>
                     ))}
                   </Grid>
                 </Grid>
               </Grid>
			 </Grid>
	      </Grid> 
        </ExpansionPanelDetails>
      </ExpansionPanel>     
    </div>
  );
}

ExpansionPanelComp.propTypes = {
  classes: PropTypes.object.isRequired,
  titolo: PropTypes.string.isRequired,
//  componente: PropTypes.element.isRequired,
//  componente: PropTypes.func.isRequired,  
//  componente: PropTypes.object.isRequired,  
  componente: PropTypes.any.isRequired,  
};

export default withTranslation()(withStyles(styles)(ExpansionPanelComp));