import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import DatiRiepilogoPanel from '../riepilogo/DatiRiepilogoPanel';
import DatiAnteprimaStampaPanel from '../riepilogo/DatiAnteprimaStampaPanel';
import StampaProposalPanel from './StampaProposalPanel';
import DatiInvioEmailPanel from '../riepilogo/DatiInvioEmailPanel';

import { isContraenteGiuridico, isContraenteFisico } from '../../../utility/genericUtility';
import { getIsValidaProposal } from '../../../utility/storeUtility';

const styles = theme => ({
  root: {	  
/*	 marginTop: 200, 
	 marginLeft: 100,
	 marginRight: 100, */
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const mapStateToProps = state => {	  	
  return {
	contraenteFisico: state.areaContraenteReducer.contraenteFisico,
	contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,
	confermaStampaProposal: state.areaDatiStampaProposalReducer.stampaProposal.datiStampaProposal.confermaStampaProposal,
  };
};
	
class StampaPropostaAss extends React.Component {
  constructor(props) {
    super(props); 
  };
    
  render() {
    const { classes } = this.props;
//    const prodotto = this.props.prodotto;
    const isContrGiuridico = isContraenteGiuridico(this.props.contraenteGiuridico);
    const isContrFisico = isContraenteFisico(this.props.contraenteFisico);
    const isValidaProposal = getIsValidaProposal();
    const confermaStampaProposal = this.props.confermaStampaProposal;
    const flConsensoComElettr = ((isContrFisico && this.props.contraenteFisico.datiConsensoComElettr.flConsensoComElettr === 'S') ||
            					 (isContrGiuridico && this.props.contraenteGiuridico.datiConsensoComElettr.flConsensoComElettr === 'S')
            					) ? true : false;
    
    const isDisabledStampaPropostaPanel = isValidaProposal===null || isValidaProposal === false;    
    const isDisabledInvioEmailPanel     = (confermaStampaProposal === false) ? true : false;
    
    return (
      <div className={classes.root}>	    	
        <DatiRiepilogoPanel />
        <DatiAnteprimaStampaPanel />
{/*        {isValidaProposal && (confermaStampaProposal === false) &&*/}
          <StampaProposalPanel isDisabled={isDisabledStampaPropostaPanel}/>
{/*        }  */}
        {/*        {((isContrFisico && this.props.contraenteFisico.datiConsensoComElettr.flConsensoComElettr === 'S') ||
            		(isContrGiuridico && this.props.contraenteGiuridico.datiConsensoComElettr.flConsensoComElettr === 'S')) && 
                    (confermaStampaProposal === true)) && 
          <DatiInvioEmailPanel flConsensoComElettrconfermaStampaProposal={confermaStampaProposal} />
        }
        */}
        
        {flConsensoComElettr && 
          <DatiInvioEmailPanel isDisabled={isDisabledInvioEmailPanel} />
        }

	  </div>
    );
  }
}

StampaPropostaAss.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(withStyles(styles)(StampaPropostaAss));