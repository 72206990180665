import * as actionType from '../actionType/titolareEff';
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import initialState from '../../../../session/InitialState';

import { jsonCopy, dataFormat } from '../../../../utility/genericUtility';
import { PROVINCIA_ESTERA, TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA, CODICE_QUALIFICA_FIDUCIANTE } from '../../../../utility/jsConstants';
import { datiTitolareEffInizializzato, datiRecapitoInizializzato, datiAttEconomicaInizializzato, datiTitolareEffettivoBenefMorteInizializzato, datiTitolareEffettivoBenefVitaInizializzato, titEffBenefVitaGiuridicoInizializzato } from '../../../../session/info/componentiBase/sezioniAnagrafica';

const areaTitolareEffReducer = (state = initialState, action) => {
	
	const titolareEff = {...state}.titolareEff;	
	const datiTitolareEff = {...titolareEff}.datiTitolareEff;

	const benefMorteGiuridico = {...state}.benefMorteGiuridico;
	const benefMorteGiuridicoList = {...state}.benefMorteGiuridicoList;
	const titolareEffBenefMorte= {...benefMorteGiuridico}.titolareEffBenefMorte;
	const datiTitolareBenefMorteEff = {...titolareEffBenefMorte}.datiTitolareBenefMorteEff;

	const benefVitaGiuridico = {...state}.benefVitaGiuridico;
	const benefVitaGiuridicoList = {...state}.benefVitaGiuridicoList;
	const titolareEffBenefVita = {...benefVitaGiuridico}.titolareEffBenefVita;
	const datiTitolareBenefVitaEff = {...titolareEffBenefVita}.datiTitolareBenefVitaEff;
		
	const datiGeneraliTitEff   				    = datiTitolareEff.datiGenerali;
	const datiResidenzaTitEff       			= datiTitolareEff.datiResidenza;
	const datiDomicilioTitEff					= datiTitolareEff.datiDomicilio;
	const datiAntiriciclaggioTitEff 			= datiTitolareEff.datiAntiriciclaggio;
	const datiFiscaliTitEff    					= datiTitolareEff.datiFiscali;
	const datiRecapitoTitEff					= datiTitolareEff.datiRecapito;
	const datiAttEconomica 						= datiTitolareEff.datiAttEconomica;

	const datiGeneraliMinimiTitEffBenefMorte    = datiTitolareBenefMorteEff.datiGeneraliMinimi;
	const datiRelazContrTitEffBenefMorte        = datiTitolareBenefMorteEff.datiRelazContr;

	const datiGeneraliMinimiTitEffBenefVita     = datiTitolareBenefVitaEff.datiGeneraliMinimi;
	const datiRelazContrTitEffBenefVita         = datiTitolareBenefVitaEff.datiRelazContr;

	// CASE IN CUI IL BENEFICIARIO GIURIDICO SIA UNA FIDUCIARIA, C'E' BISOGNO DI PIU DATI DEL TITOLARE
	const datiGeneraliTitEffMorte 				= datiTitolareBenefMorteEff.datiGenerali;	
	const datiGeneraliTitEffVita				= datiTitolareBenefVitaEff.datiGenerali;
	const datiResidenzaTitEffMorte				= datiTitolareBenefMorteEff.datiResidenza;
	const datiResidenzaTitEffVita				= datiTitolareBenefVitaEff.datiResidenza;
	const datiDomicilioTitEffMorte				= datiTitolareBenefMorteEff.datiDomicilio;
	const datiDomicilioTitEffVita				= datiTitolareBenefVitaEff.datiDomicilio;
	const datiRecapitoTitEffMorte 				= datiTitolareBenefMorteEff.datiRecapito;
	const datiRecapitoTitEffVita				= datiTitolareBenefVitaEff.datiRecapito;
	const datiAntiriciclaggioTitEffMorte        = datiTitolareBenefMorteEff.datiAntiriciclaggio;
	const datiAntiriciclaggioTitEffVita        	= datiTitolareBenefVitaEff.datiAntiriciclaggio;
	const datiAttEconomicaTitEffMorte			= datiTitolareBenefMorteEff.datiAttEconomica;
	const datiATtEconomicaTitEffVita 			= datiTitolareBenefVitaEff.datiAttEconomica;
	const datiFiscaliTitEffMorte				= datiTitolareBenefMorteEff.datiFiscali
	const datiFiscaliTitEffVita 				= datiTitolareBenefVitaEff.datiFiscali;

    //DA UTILIZZARE SOLO PER LE COMBO
    const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
    const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;

    switch (action.type) {
        case actionTypeGeneric.INIZIALIZZA: 
    	  const titolareEffInizializza = inizializzaTitolareEff(state);
		  const titolareEffListInizializza = inizializzaTitolareEffLista(state);
		  const titolareEffBenefMorteInizializza = inizializzaTitolareEffBenefMorte(state);
		  const titolareEffBenefMorteListInizializza = inizializzaTitolareEffBenefMorteLista(state);
		  const titolareEffBenefVitaInizializza = inizializzaTitolareEffBenefVita(state);
		  const titolareEffBenefVitaListInizializza = inizializzaTitolareEffBenefVitaLista(state);
    	  return {
	  	  	...state,
	  	  	titolareEff: titolareEffInizializza,
			titolareEffList: titolareEffListInizializza, 
			titolareEffBenefMorte: titolareEffBenefMorteInizializza,
			titolareEffBenefMorteList: titolareEffBenefMorteListInizializza,
			titolareEffBenefVita: titolareEffBenefVitaInizializza,
			titolareEffBenefVitaList: titolareEffBenefVitaListInizializza,
	  	  };
		case actionType.SALVA_TITOLARE_EFF:
	        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.SALVA_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);	    				
  	  	  	titolareEff.controlloDati = action.unDato;
  	  	  	return {
  	  	  		...state,
  	  	  		titolareEff: titolareEff,
  	  	  	};
		case actionType.SALVA_TITOLARE_EFF_SUCCESS:
	        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.SALVA_TITOLARE_EFF_SUCCESS.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);	    	
	        return {
	        	...state,
	        	salvaTitolareEffSuccess: action.unDato,
	        };
		case actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF:
	        console.log('#RT2 Reducer areaBenefMorteReducer.actionType.CONFERMA_AGGIUNGI_BENEFICIARIO_MORTE_FISICO.state', state);
	        console.log('#RT2 Reducer areaBenefMorteReducer.action', action);			
			titolareEff.anagraficaValida = action.unDato;
	      	return {
	      		...state,
	      		titolareEff: titolareEff,
			  };
		case actionType.UPDATE_ERRORE_FIDUCIANTE_TE_CONTRAENTE:
			titolareEff.errorFiduciante = action.unDato;
			return {
				...state,
				titolareEff: titolareEff
			}		  
		case actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF:      
	        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	        const cloneTitolareEffConfermaAggiungi = jsonCopy(action.titolareEffettivo);
	        cloneTitolareEffConfermaAggiungi.datiTitolareEff.datiAntiriciclaggio.dataRilascio = action.titolareEffettivo.datiTitolareEff.datiAntiriciclaggio.dataRilascio;
	        cloneTitolareEffConfermaAggiungi.datiTitolareEff.datiAntiriciclaggio.dataScadenza = action.titolareEffettivo.datiTitolareEff.datiAntiriciclaggio.dataScadenza;
	        const titolareEffListConfermaAggiungi = {...state}.titolareEffList;
	        const titolareEffListConfermaAggiungiNuovo = [...titolareEffListConfermaAggiungi, cloneTitolareEffConfermaAggiungi];
	        return {
	          ...state,
//	          titolareEff: titolareEffConfermaAggiungi,
	          titolareEffList: titolareEffListConfermaAggiungiNuovo,
	        };
	    case actionType.NUOVO_TITOLARE_EFF:
	        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.NUOVO_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	        //const titolareEffNuovo = action.titolareEffettivo;
	        const titolareEffNuovo = inizializzaTitolareEff(state);
	        return {
	          ...state,
	          titolareEff: titolareEffNuovo,
	        };
	    case actionType.UPDATE_TITOLARE_EFF:
	        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	        const titolareEffUpdate = action.titolareEffettivo;
	        const cloneTitolareEffUpdate = jsonCopy(action.titolareEffettivo);
	        cloneTitolareEffUpdate.datiTitolareEff.datiAntiriciclaggio.dataRilascio = titolareEffUpdate.datiTitolareEff.datiAntiriciclaggio.dataRilascio;
	        cloneTitolareEffUpdate.datiTitolareEff.datiAntiriciclaggio.dataScadenza = titolareEffUpdate.datiTitolareEff.datiAntiriciclaggio.dataScadenza;
	        const titolareEffUpdateCodiceFiscale = cloneTitolareEffUpdate.datiTitolareEff.datiGenerali.codiceFiscale;       
	        const titolareEffListUpdate = [...state.titolareEffList];
	        
	        let indexTitolareEffUpdate = undefined;        
	        titolareEffListUpdate.forEach( (item, index, array) => {        	
	        	if (titolareEffUpdateCodiceFiscale===item.datiTitolareEff.datiGenerali.codiceFiscale){
	        		indexTitolareEffUpdate = index;
	        	}
	        })
	        
	        titolareEffListUpdate[indexTitolareEffUpdate] = cloneTitolareEffUpdate;
	        return {
	          ...state,
	          titolareEffList: titolareEffListUpdate,
	        };
	    case actionType.CARICA_TITOLARE_EFF:
	        console.log('#RT2 Reducer areaTitolareEffReducer.actionType.CARICA_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);	    	
	    	const figuraSelezIndexCarica = action.figuraSelezIndex;
	    	const titolareEffListCarica = [...state.titolareEffList];
	    	let titolareEffCarica = {...titolareEffListCarica[figuraSelezIndexCarica]};
	    	
	    	return {
	    		...state,
	    		titolareEff: {...titolareEffCarica},
	    	};
		case actionType.DELETE_TITOLARE_EFF:
			console.log('#RT2 Reducer areaTitolareEffReducer.actionType.DELETE_TITOLARE_EFF.state', state);
			console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
				let titolareEffDelete = action.titolareEffettivo;
				const filterdTitolareEffDeleteList = state.titolareEffList.filter(item => {
					const codiceFiscale = item.datiTitolareEff.datiGenerali.codiceFiscale;
					return codiceFiscale !== titolareEffDelete.datiTitolareEff.datiGenerali.codiceFiscale
				})
				return {
					...state,
					titolareEffList: filterdTitolareEffDeleteList,
				};  	
	    case actionType.UPDATE_CODICE_FISCALE_TITOLARE_EFF:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_CODICE_FISCALE_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);

	        datiGeneraliTitEff.codiceFiscale = action.unDato;
	        datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	        	...state,
	        	titolareEff: titolareEff,
	        };
	    case actionType.UPDATE_NOME_TITOLARE_EFF:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_NOME_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	        datiGeneraliTitEff.nome = action.unDato;
	        datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	        	...state,
	        	titolareEff: titolareEff,
	        };
        case actionType.UPDATE_COGNOME_TITOLARE_EFF:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_COGNOME_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
           datiGeneraliTitEff.cognome = action.unDato;
           datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
           titolareEff.datiTitolareEff= {...datiTitolareEff};
           return {
         	...state,
         	titolareEff: titolareEff,
           };   
         case actionType.UPDATE_SESSO_TITOLARE_EFF:
 	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_SESSO_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
           datiGeneraliTitEff.sesso = action.unDato;
           datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
           titolareEff.datiTitolareEff= {...datiTitolareEff};
           return {
         	...state,
         	titolareEff: titolareEff,
           };   
         case actionType.UPDATE_DATA_NASCITA_TITOLARE_EFF:
           const unaData = action.unDato;
           const nuovaDataNascitaFormattata = dataFormat(unaData, 'DD/MM/YYYY');          	 
           datiGeneraliTitEff.dataNascita = unaData;
           datiGeneraliTitEff.dataNascitaStampa = nuovaDataNascitaFormattata;
           datiGeneraliTitEff.statoNascita = '';
           datiGeneraliTitEff.descrStatoNascita = '';
           datiGeneraliTitEff.provinciaNascita = '';
           datiGeneraliTitEff.descrProvinciaNascita = '';
           datiGeneraliTitEff.comuneNascita = '';
           datiGeneraliTitEff.descrComuneNascita = '';
           //datiGeneraliTitEff.cittadinanza1 = '';
           //datiGeneraliTitEff.descrCittadinanza1 = '';
           //datiGeneraliTitEff.cittadinanza2 = '';
           //datiGeneraliTitEff.descrCittadinanza2 = '';
           datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
           titolareEff.datiTitolareEff= {...datiTitolareEff};
           return {
         	...state,
         	titolareEff: titolareEff,
           };      
         case actionType.UPDATE_STATO_NASCITA_TITOLARE_EFF:
   	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_STATO_NASCITA_TITOLARE_EFF.state', state);
	        console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
           datiGeneraliTitEff.statoNascita = unValore;
           datiGeneraliTitEff.descrStatoNascita = unaLabel;
           datiGeneraliTitEff.provinciaNascita = '';
           datiGeneraliTitEff.descrProvinciaNascita = '';
           datiGeneraliTitEff.comuneNascita = '';
           datiGeneraliTitEff.descrComuneNascita = '';
           datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
           titolareEff.datiTitolareEff= {...datiTitolareEff};
           return {
         	...state,
         	titolareEff: titolareEff,
           };
         case actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
           datiGeneraliTitEff.provinciaNascita = unValore;
           datiGeneraliTitEff.descrProvinciaNascita = unaLabel;
           datiGeneraliTitEff.comuneNascita = '';
           datiGeneraliTitEff.descrComuneNascita = '';
           datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
           titolareEff.datiTitolareEff= {...datiTitolareEff};
           return {
         	...state,
         	titolareEff: titolareEff,
           };    
         case actionType.UPDATE_COMUNE_NASCITA_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_COMUNE_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	      if (datiGeneraliTitEff.provinciaNascita!==PROVINCIA_ESTERA) {
	    	  datiGeneraliTitEff.comuneNascita = unValore;
	    	  datiGeneraliTitEff.descrComuneNascita = unaLabel; // campo select
	      } else {
	    	  datiGeneraliTitEff.comuneNascita = '';
	    	  datiGeneraliTitEff.descrComuneNascita = action.unDato;  // campo testo
	      }
           datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
           titolareEff.datiTitolareEff= {...datiTitolareEff};
           return {
         	...state,
         	titolareEff: titolareEff,
           };        
         case actionType.UPDATE_CITTADINANZA_1_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_CITTADINANZA_1_TITOLARE_EFF.state', state);
			 console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
		   if(action.isFiduciaria) {
			if(action.figura === TITOLARE_EFF_BENEF_MORTE) {
				datiGeneraliTitEffMorte.cittadinanza1 = unValore;
				datiGeneraliTitEffMorte.descrCittadinanza1 = unaLabel;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
				return {
					...state,
					titolareEffBenefMorte: titolareEffBenefMorte
				}
			} else if(action.figura === TITOLARE_EFF_BENEF_VITA) {
				datiGeneraliTitEffVita.cittadinanza1 = unValore;
				datiGeneraliTitEffVita.descrCittadinanza1 = unaLabel;
				datiTitolareBenefVitaEff.datiGenerali = {...datiGeneraliTitEffVita};
				titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
				return {
					...state,
					titolareEffBenefVita: titolareEffBenefVita
				}
			}
		   } else {
			datiGeneraliTitEff.cittadinanza1 = unValore;
			datiGeneraliTitEff.descrCittadinanza1 = unaLabel;
			datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
			titolareEff.datiTitolareEff= {...datiTitolareEff};
			return {
			  ...state,
			  titolareEff: titolareEff,
			};    
		   }
         case actionType.UPDATE_CITTADINANZA_2_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
			 if(action.isFiduciaria) {
				if(action.figura === TITOLARE_EFF_BENEF_MORTE) {
					datiGeneraliTitEffMorte.cittadinanza2 = unValore;
					datiGeneraliTitEffMorte.descrCittadinanza2 = unaLabel;
					datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
					titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
					return {
						...state,
						titolareEffBenefMorte: titolareEffBenefMorte
					}
				} else if(action.figura === TITOLARE_EFF_BENEF_VITA) {
					datiGeneraliTitEffVita.cittadinanza2 = unValore;
					datiGeneraliTitEffVita.descrCittadinanza2 = unaLabel;
					datiTitolareBenefVitaEff.datiGenerali = {...datiGeneraliTitEffVita};
					titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
					return {
						...state,
						titolareEffBenefVita: titolareEffBenefVita
					}
				}
			   } else {
				datiGeneraliTitEff.cittadinanza2 = unValore;
				datiGeneraliTitEff.descrCittadinanza2 = unaLabel;
				datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
				titolareEff.datiTitolareEff= {...datiTitolareEff};
				return {
				  ...state,
				  titolareEff: titolareEff,
				};    
			   }
         case actionType.UPDATE_NUM_TELEFONO_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
			 console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
		   if(action.isFiduciaria) {
			if(action.figura === TITOLARE_EFF_BENEF_MORTE) {
				datiGeneraliTitEffMorte.numTelefono = action.unDato;
				datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
				titolareEff.datiTitolareEff= {...datiTitolareEff};	
			} else if(action.figura === TITOLARE_EFF_BENEF_VITA) {
				datiGeneraliTitEffVita.numTelefono = action.unDato;
				datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
				titolareEff.datiTitolareEff= {...datiTitolareEff};	
			}
		   } else {
			datiGeneraliTitEff.numTelefono = action.unDato;
			datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
			titolareEff.datiTitolareEff= {...datiTitolareEff};
		   }
           return {
         	...state,
         	titolareEff: titolareEff,
		   };
		case actionType.UPDATE_RELAZ_ALTRO:
		   datiGeneraliTitEff.altroRelaz = action.unDato;
		   datiTitolareEff.datiGenerali = { ...datiGeneraliTitEff };
		   titolareEff.datiTitolareEff = { ...datiTitolareEff };
		   return {
		   	...state,
		   	titolareEff: titolareEff,
		   }
/* DATI ANTIRICICLAGGIO TITOLARE EFFETTIVO */
         case actionType.UPDATE_DATI_ANTIRICICLAGGIO_PER_TITOLARE_EFFETTIVO:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
           datiAntiriciclaggioTitEff.tipoDocumento = '';
	       datiAntiriciclaggioTitEff.descrTipoDocumento = '';
	       datiAntiriciclaggioTitEff.numeroDocumento = '';
	       datiAntiriciclaggioTitEff.enteRilascio = '';
	       datiAntiriciclaggioTitEff.descrEnteRilascio = '';
	       datiAntiriciclaggioTitEff.dataRilascio = null;
	       datiAntiriciclaggioTitEff.dataScadenza = null;
	       datiAntiriciclaggioTitEff.statoRilascio = '';
	       datiAntiriciclaggioTitEff.descrStatoRilascio = ''; 
	       datiAntiriciclaggioTitEff.provinciaRilascio = '';
	       datiAntiriciclaggioTitEff.descrProvinciaRilascio = '';
	       datiAntiriciclaggioTitEff.comuneRilascio = '';
	       datiAntiriciclaggioTitEff.descrComuneRilascio = '';
	       datiAntiriciclaggioTitEff.espostaPoliticamente = '';     
	       datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
	       titolareEff.datiTitolareEff = {...datiTitolareEff};
	       return {
	          ...state,
	          titolareEff: titolareEff,
		   };
		case actionType.UPDATE_TITOLARE_EFF_QUALIFICA:
			if(unValore === CODICE_QUALIFICA_FIDUCIANTE) {
				datiTitolareEff.errorFiduciante = false;
			}
			const datiTitolareEffQualifica = {...datiTitolareEff}.datiAntiriciclaggio;
			datiTitolareEffQualifica.qualifica = unValore;
			datiTitolareEffQualifica.descrQualifica = unaLabel;
			datiTitolareEffQualifica.altro = '';
			datiTitolareEff.datiAntiriciclaggio = {...datiTitolareEffQualifica}
			datiTitolareEff.percOwnerShip = '';
			titolareEff.datiTitolareEff = {...datiTitolareEff};
		   return {
			  ...state,
		      titolareEff: titolareEff,
				} 
		case actionType.UPDATE_ALTRO_QUALIFICA:
			const datiTitolareAltroQualifica = {...datiTitolareEff}.datiAntiriciclaggio;	
			datiTitolareAltroQualifica.altro = action.unDato;
			datiTitolareEff.datiAntiriciclaggio = {...datiTitolareAltroQualifica}
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff,
			}
	       /* DATI RESIDENZA TITOLARE EFFETTIVO */
	     case actionType.UPDATE_DATI_RESIDENZA_PER_TITOLARE_EFFETTIVO:
	    	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_DATI_RESIDENZA_PER_TITOLARE_EFFETTIVO.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	       datiResidenzaTitEff.statoResidenza = '';
	       datiResidenzaTitEff.descrStatoResidenza = '';
	       datiResidenzaTitEff.provinciaResidenza = '';
	       datiResidenzaTitEff.descrProvinciaResidenza = '';
	       datiResidenzaTitEff.comuneResidenza = '';
	       datiResidenzaTitEff.descrComuneResidenza = '';
	       datiResidenzaTitEff.toponimoResidenza = '';
	       datiResidenzaTitEff.descrToponimoResidenza = '';
	       datiResidenzaTitEff.indirizzoResidenza = ''; 
	       datiResidenzaTitEff.descrIndirizzoResidenza = '';
	       datiResidenzaTitEff.numCivicoResidenza = '';
	       datiResidenzaTitEff.capResidenza = ''; 
	       datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	       titolareEff.datiTitolareEff = {...datiTitolareEff};
	       return {
	          ...state,
	          titolareEff: titolareEff,
	       };        
	        
	     case actionType.UPDATE_STATO_RESIDENZA_TITOLARE_EFF:
	    	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_STATO_RESIDENZA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	        datiResidenzaTitEff.statoResidenza = unValore;
	        datiResidenzaTitEff.descrStatoResidenza = unaLabel;
	        datiResidenzaTitEff.provinciaResidenza = '';
	        datiResidenzaTitEff.descrProvinciaResidenza = '';
	        datiResidenzaTitEff.comuneResidenza = '';
	        datiResidenzaTitEff.descrComuneResidenza = '';
	        datiResidenzaTitEff.toponimoResidenza = '';
	        datiResidenzaTitEff.descrToponimoResidenza = '';
	        //datiResidenzaTitEff.indirizzoResidenza = '';
	        //datiResidenzaTitEff.descrIndirizzoResidenza = '';
	        datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        }; 
	     case actionType.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF:
	    	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	    	datiResidenzaTitEff.provinciaResidenza = unValore;
	    	datiResidenzaTitEff.descrProvinciaResidenza = unaLabel;
	    	datiResidenzaTitEff.comuneResidenza = '';
	        datiResidenzaTitEff.descrComuneResidenza = '';
	        datiResidenzaTitEff.toponimoResidenza = '';
	        datiResidenzaTitEff.descrToponimoResidenza = '';
	        //datiResidenzaTitEff.indirizzoResidenza = '';
	        //datiResidenzaTitEff.descrIndirizzoResidenza = '';
	        datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        };
	     case actionType.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF.state', state);
    	    console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
    	    if(datiResidenzaTitEff.provinciaResidenza !== PROVINCIA_ESTERA) {
		    	datiResidenzaTitEff.comuneResidenza = unValore;
		    	datiResidenzaTitEff.descrComuneResidenza = unaLabel; // campo select
		    	datiResidenzaTitEff.toponimoResidenza = '';
		        datiResidenzaTitEff.descrToponimoResidenza = '';
		        //datiResidenzaTitEff.indirizzoResidenza = '';
		        //datiResidenzaTitEff.descrIndirizzoResidenza = '';
    	     } else {
    	    	datiResidenzaTitEff.comuneResidenza = '';
 		    	datiResidenzaTitEff.descrComuneResidenza = action.unDato; // campo testo
    	     }
	    	datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        };
	     case actionType.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF.state', state);
    	    console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
    	    if(datiResidenzaTitEff.provinciaResidenza !== PROVINCIA_ESTERA) {
		    	datiResidenzaTitEff.toponimoResidenza = unValore;
		    	datiResidenzaTitEff.descrToponimoResidenza = unaLabel; // campo select
		    	//datiResidenzaTitEff.indirizzoResidenza = '';
		        //datiResidenzaTitEff.descrIndirizzoResidenza = '';
    	    } else {
    	    	datiResidenzaTitEff.toponimoResidenza = '';
    	    	datiResidenzaTitEff.descrToponimoResidenza = action.unDato; // campo testo
    	    }
	    	datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        };
	     case actionType.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF:
	    	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	    	//datiResidenzaTitEff.indirizzoResidenza = unValore;
	    	//datiResidenzaTitEff.descrIndirizzoResidenza = unaLabel;
	    	datiResidenzaTitEff.descrIndirizzoResidenza = action.unDato;
	    	datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        };      
	     case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_TITOLARE_EFF:
	    	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	    	datiResidenzaTitEff.numCivicoResidenza = action.unDato;
	    	datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        };
	     case actionType.UPDATE_CAP_RESIDENZA_TITOLARE_EFF:
	    	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	    	datiResidenzaTitEff.capResidenza = action.unDato;
	    	datiTitolareEff.datiResidenza = {...datiResidenzaTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
			};	       
/* DATI RECAPITO */
		case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF:
			datiRecapitoTitEff.corrispondenzaDiversoResidenza = action.unDato;
			if(datiRecapitoTitEff.corrispondenzaDiversoResidenza === 'N') {
				datiRecapitoTitEff.statoRecapito = '';
				datiRecapitoTitEff.descrStatoRecapito = '';
				datiRecapitoTitEff.provinciaRecapito = '';
				datiRecapitoTitEff.descrProvinciaRecapito = '';
				datiRecapitoTitEff.comuneRecapito = '';
				datiRecapitoTitEff.descrComuneRecapito = '';
				datiRecapitoTitEff.toponimoRecapito = '';
				datiRecapitoTitEff.descrToponimoRecapito = '';
				datiRecapitoTitEff.descrIndirizzoRecapito = '';
				datiRecapitoTitEff.numCivicoRecapito = '';
				datiRecapitoTitEff.capRecapito = '';
			}
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF:
			datiRecapitoTitEff.corrispondenzaIntestatoContraente = action.unDato;
			datiRecapitoTitEff.presso = '';
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}

		case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO:
			datiDomicilioTitEff.domicilioDiversoResidenza = action.unDato;
			if(datiDomicilioTitEff.domicilioDiversoResidenza === 'N') {
				datiDomicilioTitEff.statoDomicilio = '';
				datiDomicilioTitEff.descrStatoDomicilio = '';
				datiDomicilioTitEff.provinciaDomicilio = '';
				datiDomicilioTitEff.descrProvinciaDomicilio = '';
				datiDomicilioTitEff.comuneDomicilio = '';
				datiDomicilioTitEff.descrComuneDomicilio = '';
				datiDomicilioTitEff.toponimoDomicilio = '';
				datiDomicilioTitEff.descrToponimoDomicilio = '';
				datiDomicilioTitEff.indirizzoDomicilio = '';
				datiDomicilioTitEff.capDomicilio = '';
				datiDomicilioTitEff.numCivicoDomicilio = '';
			}
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO:
			datiDomicilioTitEff.statoDomicilio = unValore;
			datiDomicilioTitEff.descrStatoDomicilio = unaLabel;
			datiDomicilioTitEff.provinciaDomicilio = '';
			datiDomicilioTitEff.descrProvinciaDomicilio = '';
			datiDomicilioTitEff.comuneDomicilio = '';
			datiDomicilioTitEff.descrComuneDomicilio = '';
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO:
			datiDomicilioTitEff.provinciaDomicilio = unValore;
			datiDomicilioTitEff.descrProvinciaDomicilio = unaLabel;
			datiDomicilioTitEff.comuneDomicilio = '';
			datiDomicilioTitEff.descrComuneDomicilio = '';
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO:
			if(datiDomicilioTitEff.provinciaDomicilio === PROVINCIA_ESTERA) {
				datiDomicilioTitEff.comuneDomicilio = '';
				datiDomicilioTitEff.descrComuneDomicilio = action.unDato;
			} else {
				datiDomicilioTitEff.comuneDomicilio = unValore;
				datiDomicilioTitEff.descrComuneDomicilio = unaLabel;
			}
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO:
			if(datiDomicilioTitEff.provinciaDomicilio === PROVINCIA_ESTERA) {
				datiDomicilioTitEff.toponimoDomicilio = '';
				datiDomicilioTitEff.descrToponimoDomicilio = action.unDato;
			} else {
				datiDomicilioTitEff.toponimoDomicilio = unValore;
				datiDomicilioTitEff.descrToponimoDomicilio = unaLabel;
			}
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO:
			datiDomicilioTitEff.indirizzoDomicilio = action.unDato;
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO:
			datiDomicilioTitEff.numCivicoDomicilio = action.unDato;
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO:
			datiDomicilioTitEff.capDomicilio = action.unDato;
			datiTitolareEff.datiDomicilio = {...datiDomicilioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
/* DATI ANTIRICICLAGGIO */
         case actionType.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
             datiAntiriciclaggioTitEff.tipoDocumento = unValore;
             datiAntiriciclaggioTitEff.descrTipoDocumento = unaLabel;
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
   	          ...state,
   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF: 
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
             datiAntiriciclaggioTitEff.numeroDocumento = action.unDato;
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_ENTE_RILASCIO_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_ENTE_RILASCIO_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
             datiAntiriciclaggioTitEff.enteRilascio = unValore;
             datiAntiriciclaggioTitEff.descrEnteRilascio = unaLabel;
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_DATA_RILASCIO_TITOLARE_EFF:
             const unaDataRilascio = action.unDato;
             const nuovaDataRilascioFormattata = dataFormat(unaDataRilascio, 'DD/MM/YYYY'); 
             datiAntiriciclaggioTitEff.dataRilascio = unaDataRilascio;
             datiAntiriciclaggioTitEff.dataRilascioStampa = nuovaDataRilascioFormattata; 
             datiAntiriciclaggioTitEff.statoRilascio = '';
             datiAntiriciclaggioTitEff.descrStatoRilascio = '';
             datiAntiriciclaggioTitEff.provinciaRilascio = '';
             datiAntiriciclaggioTitEff.descrProvinciaRilascio = '';
             datiAntiriciclaggioTitEff.comuneRilascio = '';
             datiAntiriciclaggioTitEff.descrComuneRilascio = '';
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_DATA_SCADENZA_TITOLARE_EFF:
             const unaDataScadenza = action.unDato;
             const nuovaDataScadenzaFormattata = dataFormat(unaDataScadenza, 'DD/MM/YYYY');         	 
             datiAntiriciclaggioTitEff.dataScadenza = unaDataScadenza;
             datiAntiriciclaggioTitEff.dataScadenzaStampa = nuovaDataScadenzaFormattata;
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_STATO_RILASCIO_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_STATO_RILASCIO_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
             datiAntiriciclaggioTitEff.statoRilascio = unValore;
             datiAntiriciclaggioTitEff.descrStatoRilascio = unaLabel;
             datiAntiriciclaggioTitEff.provinciaRilascio = '';
             datiAntiriciclaggioTitEff.descrProvinciaRilascio = '';
             datiAntiriciclaggioTitEff.comuneRilascio = '';
             datiAntiriciclaggioTitEff.descrComuneRilascio = '';
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
             datiAntiriciclaggioTitEff.provinciaRilascio = unValore;
             datiAntiriciclaggioTitEff.descrProvinciaRilascio = unaLabel;
             datiAntiriciclaggioTitEff.comuneRilascio = '';
             datiAntiriciclaggioTitEff.descrComuneRilascio = '';
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
    	     if (datiAntiriciclaggioTitEff.provinciaRilascio !== PROVINCIA_ESTERA){
	             datiAntiriciclaggioTitEff.comuneRilascio = unValore;
	             datiAntiriciclaggioTitEff.descrComuneRilascio = unaLabel;
    	     } else {
	             datiAntiriciclaggioTitEff.comuneRilascio = '';
	             datiAntiriciclaggioTitEff.descrComuneRilascio = action.unDato;
    	     }
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
			 datiAntiriciclaggioTitEff.espostaPoliticamente = action.unDato;
			 datiAntiriciclaggioTitEff.isInCarica = '';
			 datiAntiriciclaggioTitEff.caricaPersonaEsposta = '';
             datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
			};
		case actionType.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF:
			datiAntiriciclaggioTitEff.isInCarica = action.unDato;
			datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
            titolareEff.datiTitolareEff = {...datiTitolareEff};
            return {
	   	          ...state,
	   	          titolareEff: titolareEff,
			};
		case actionType.UPDATE_CARICA_PEP_TITOLARE_EFF:
			datiAntiriciclaggioTitEff.caricaPersonaEsposta = action.unDato;
			datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
            titolareEff.datiTitolareEff = {...datiTitolareEff};
            return {
	   	          ...state,
	   	          titolareEff: titolareEff,
			};
	    /* 2020-12-01 PFD: SAV 'TO BE' */
		case actionType.UPDATE_CARICA_PUBBLICA_NO_PEP_TITOLARE_EFF:
			datiAntiriciclaggioTitEff.caricaPubblicaNoPEP = action.unDato;
			datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
            titolareEff.datiTitolareEff = {...datiTitolareEff};
            return {
	   	          ...state,
	   	          titolareEff: titolareEff,
			};
/* DATI FISCALI */
         case actionType.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.tasseStatiUniti = action.unDato;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_TIN_GIIN_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_TIN_GIIN_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.tinGiin = action.unDato;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.statoResidenzaFiscaleEstera = action.unDato;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.statoResFiscale1 = unValore;
             datiFiscaliTitEff.descrStatoResFiscale1 = unaLabel;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.nifResFiscale1 = action.unDato;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.statoResFiscale2 = unValore;
             datiFiscaliTitEff.descrStatoResFiscale2 = unaLabel;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
   	         };
         case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF:
        	 console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF.state', state);
    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
        	 datiFiscaliTitEff.nifResFiscale2 = action.unDato;
             datiTitolareEff.datiFiscali = {...datiFiscaliTitEff};
             titolareEff.datiTitolareEff = {...datiTitolareEff};
             return {
	   	          ...state,
	   	          titolareEff: titolareEff,
				};
/* DATI FISCALI TITOLARE EFF BENEF MORTE  */ 				
		case actionType.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.tinGiin = action.unDato;
            datiTitolareBenefMorteEff.datiFiscali = {...datiFiscaliTitEffMorte};
            titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
            return {
	   	       ...state,
	   	       titolareEffBenefMorte: titolareEffBenefMorte,
   	        };
		case actionType.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.tasseStatiUniti = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.statoResFiscale1 = unValore;
			datiFiscaliTitEffMorte.descrStatoResFiscale1 = unaLabel;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.nifResFiscale1 = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.statoResFiscale2 = unValore;
			datiFiscaliTitEffMorte.descrStatoResFiscale2 = unaLabel;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};
		case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.nifResFiscale2 = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};	
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_MORTE:
			datiFiscaliTitEffMorte.statoResidenzaFiscaleEstera = action.unDato;
			datiTitolareBenefMorteEff.datiFiscali = { ...datiFiscaliTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte,
			};	



/* DATI FISCALI TITOLARE EFF BENEF VITA  */
		case actionType.UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.tinGiin = action.unDato;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};
		case actionType.UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.tasseStatiUniti = action.unDato;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.statoResFiscale1 = unValore;
			datiFiscaliTitEffVita.descrStatoResFiscale1 = unaLabel;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};
		case actionType.UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.nifResFiscale1 = action.unDato;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.statoResFiscale2 = unValore;
			datiFiscaliTitEffVita.descrStatoResFiscale2 = unaLabel;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};
		case actionType.UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.nifResFiscale2 = action.unDato;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};
		case actionType.UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_VITA:
			datiFiscaliTitEffVita.statoResidenzaFiscaleEstera = action.unDato;
			datiTitolareBenefVitaEff.datiFiscali = { ...datiFiscaliTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			};		




/* DATI GENERALI TITOLARE EFFETTIVO */
	      case actionType.UPDATE_DATI_GENERALI_PER_TITOLARE_EFFETTIVO:
	    	  console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_DATI_GENERALI_PER_TITOLARE_EFFETTIVO.state', state);
	    	     console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
	        datiGeneraliTitEff.nome = action.unDato;
	        datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};    	     	  
	        datiGeneraliTitEff.sesso = '';
	        datiGeneraliTitEff.codiceFiscale = '';
	        datiGeneraliTitEff.cognome = '';
	        datiGeneraliTitEff.nome = '';
	        datiGeneraliTitEff.dataNascita = null;
	        datiGeneraliTitEff.statoNascita = '';
	        datiGeneraliTitEff.descrStatoNascita = '';
	        datiGeneraliTitEff.provinciaNascita = '';
	        datiGeneraliTitEff.descrProvinciaNascita = ''; 
	        datiGeneraliTitEff.comuneNascita = '';
	        datiGeneraliTitEff.descrComuneNascita = '';
	        datiGeneraliTitEff.cittadinanza1 = '';
	        datiGeneraliTitEff.descrCittadinanza1 = '';
	        datiGeneraliTitEff.cittadinanza2 = '';
	        datiGeneraliTitEff.descrCittadinanza2 = '';
	        datiGeneraliTitEff.numTelefono = '';     
	        datiTitolareEff.datiGenerali = {...datiGeneraliTitEff};
	        titolareEff.datiTitolareEff = {...datiTitolareEff};
	        return {
	          ...state,
	          titolareEff: titolareEff,
	        };   
	    case actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF.state', state);
   	     	console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
   	     	datiTitolareEff.percOwnerShip = action.unDato;
   	     	titolareEff.datiTitolareEff = {...datiTitolareEff};
   	     	return {
	  		  ...state,
	  		  titolareEff: titolareEff,
   	     	};
	    case actionType.UPDATE_PERCENTUALE_OWNERSHIP_TOTALE:
	    	console.log('#RT2 Reducer areaTitolareEffReducer.actionType.UPDATE_PERCENTUALE_OWNERSHIP_TOTALE.state', state);
   	     	console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
   	     	const percTitolareEffTotale = action.unDato;
   	     	return {
	  		  ...state,
	  		percTitolareEffTotale,
				};
		case actionType.UPDATE_RELAZIONE_CONTRAENTE_TITOLARE:
			const datiRelazContr = {...datiTitolareEff}.datiRelazContr;
			datiRelazContr.relazContraente = unValore;
			datiRelazContr.descrRelazContraente = unaLabel;
			datiRelazContr.altroRelaz = '';
			datiTitolareEff.datiRelazContr = {...datiRelazContr}
			return {
				...state,
				datiRelazContr: datiRelazContr
			}

			/* DATI RESIDENZA TITOLARE EFFETTIVO BENEFICIARIO MORTE */
		case actionType.UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_MORTE:
			datiResidenzaTitEffMorte.statoResidenza = unValore;
			datiResidenzaTitEffMorte.descrStatoResidenza = unaLabel;
			datiResidenzaTitEffMorte.provinciaResidenza = '';
			datiResidenzaTitEffMorte.descrProvinciaResidenza = '';
			datiResidenzaTitEffMorte.comuneResidenza = '';
			datiResidenzaTitEffMorte.descrComuneResidenza = '';
			datiResidenzaTitEffMorte.toponimoResidenza = '';
			datiResidenzaTitEffMorte.descrToponimoResidenza = '';
			return {
				...state,
				datiResidenzaTitEffMorte
			}
		case actionType.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_MORTE:
			datiResidenzaTitEffMorte.provinciaResidenza = unValore;
			datiResidenzaTitEffMorte.descrProvinciaResidenza = unaLabel;
			datiResidenzaTitEffMorte.comuneResidenza = '';
			datiResidenzaTitEffMorte.descrComuneResidenza = '';
			datiResidenzaTitEffMorte.toponimoResidenza = '';
			datiResidenzaTitEffMorte.descrToponimoResidenza = '';
			return {
				...state,
				datiResidenzaTitEffMorte
			}
		case actionType.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_MORTE:
			if(datiResidenzaTitEffMorte.provinciaResidenza === PROVINCIA_ESTERA) {
				datiResidenzaTitEffMorte.comuneResidenza = '';
				datiResidenzaTitEffMorte.descrComuneResidenza = action.unDato;
				datiResidenzaTitEffMorte.toponimoResidenza = '';
				datiResidenzaTitEffMorte.descrToponimoResidenza = '';
			} else {
				datiResidenzaTitEffMorte.toponimoResidenza = '';
				datiResidenzaTitEffMorte.descrToponimoResidenza = '';
				datiResidenzaTitEffMorte.comuneResidenza = unValore;
				datiResidenzaTitEffMorte.descrComuneResidenza = unaLabel;
			}
			return {
				...state,
				datiResidenzaTitEffMorte
			}
		case actionType.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_MORTE:
			if(datiResidenzaTitEffMorte.provinciaResidenza === 	PROVINCIA_ESTERA) {
				datiResidenzaTitEffMorte.toponimoResidenza = '';
				datiResidenzaTitEffMorte.descrToponimoResidenza = action.unDato;	
			} else {
				datiResidenzaTitEffMorte.toponimoResidenza = unValore;
				datiResidenzaTitEffMorte.descrToponimoResidenza = unValore;
			}
			return {
				...state,
				datiResidenzaTitEffMorte
			}
		case actionType.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_MORTE:
			datiResidenzaTitEffMorte.numCivicoResidenza = action.unDato;
			return {
				...state,
				datiResidenzaTitEffMorte
			}
		case actionType.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_MORTE:
			datiResidenzaTitEffMorte.descrIndirizzoResidenza = action.unDato;
			return {
				...state,
				datiResidenzaTitEffMorte
			}	
		case actionType.UPDATE_CAP_RESIDENZA_TITOLARE_EFF_MORTE:
			datiResidenzaTitEffMorte.capResidenza = action.unDato;
			return {
				...state,
				datiResidenzaTitEffMorte
			}


		
/* DATI RECAPITO TITOLARE EFFETTIVO */
		case actionType.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO:
			datiRecapitoTitEff.statoRecapito = unValore;
			datiRecapitoTitEff.descrStatoRecapito = unaLabel;
			datiRecapitoTitEff.provinciaRecapito = '';
			datiRecapitoTitEff.descrProvinciaRecapito = '';
			datiRecapitoTitEff.comuneRecapito = '';
			datiRecapitoTitEff.descrComuneRecapito = '';
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO:
			datiRecapitoTitEff.provinciaRecapito = unValore;
			datiRecapitoTitEff.descrProvinciaRecapito = unaLabel;
			datiRecapitoTitEff.comuneRecapito = '';
			datiRecapitoTitEff.descrComuneRecapito = '';
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO:
			if(datiRecapitoTitEff.provinciaRecapito !== PROVINCIA_ESTERA) {
				datiRecapitoTitEff.comuneRecapito = unValore;
				datiRecapitoTitEff.descrComuneRecapito = unaLabel;
				datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
				titolareEff.datiTitolareEff = {...datiTitolareEff};
			} else {
				datiRecapitoTitEff.comuneRecapito = '';
				datiRecapitoTitEff.descrComuneRecapito = action.unDato;
				datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
				titolareEff.datiTitolareEff = {...datiTitolareEff};
			}
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO:
			if(datiRecapitoTitEff.provinciaRecapito !== PROVINCIA_ESTERA) {
				datiRecapitoTitEff.toponimoRecapito = unValore;
				datiRecapitoTitEff.descrToponimoRecapito = unaLabel;
				datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
				titolareEff.datiTitolareEff = {...datiTitolareEff};
			} else {
				datiRecapitoTitEff.toponimoRecapito = '';
				datiRecapitoTitEff.descrToponimoRecapito = action.unDato;
				datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
				titolareEff.datiTitolareEff = {...datiTitolareEff};
			}
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO:
			datiRecapitoTitEff.descrIndirizzoRecapito = action.unDato;
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO:
			datiRecapitoTitEff.numCivicoRecapito = action.unDato;
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO:
			datiRecapitoTitEff.capRecapito = action.unDato;
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_PRESSO_TITOLARE_EFFETTIVO:
			datiRecapitoTitEff.presso = action.unDato;
			datiTitolareEff.datiRecapito = {...datiRecapitoTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			};
			
/* DATI GENERALI MINIMI TITOLARE EFFETTIVO BENEFICIARIO MORTE */
		case actionType.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE:
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.sesso = action.unDato;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};	
			} else {
				datiGeneraliMinimiTitEffBenefMorte.sesso = action.unDato;
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE:
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.codiceFiscale = action.unDato;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff}
			} else {
				datiGeneraliMinimiTitEffBenefMorte.codiceFiscale = action.unDato;
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_MORTE:
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.cognome = action.unDato;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			} else {
				datiGeneraliMinimiTitEffBenefMorte.cognome = action.unDato;
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_MORTE:
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.nome = action.unDato;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			} else {
				datiGeneraliMinimiTitEffBenefMorte.nome = action.unDato;
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_MORTE:
			const unaDataTitolareBenefMorte = action.unDato;
			const nuovaDataNascitaFormattataTitolareBenefMorte = dataFormat(unaDataTitolareBenefMorte, 'DD/MM/YYYY'); 
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.dataNascita = unaDataTitolareBenefMorte;
				datiGeneraliTitEffMorte.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefMorte;
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			} else {
				datiGeneraliMinimiTitEffBenefMorte.dataNascita = unaDataTitolareBenefMorte;
				datiGeneraliMinimiTitEffBenefMorte.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefMorte;
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_MORTE:
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.statoNascita = unValore
				datiGeneraliTitEffMorte.descrStatoNascita = unaLabel
				datiGeneraliTitEffMorte.provinciaNascita = '';
				datiGeneraliTitEffMorte.descrProvinciaNascita = '';
				datiGeneraliTitEffMorte.comuneNascita = '';
				datiGeneraliTitEffMorte.descrComuneNascita = '';
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			} else {
				datiGeneraliMinimiTitEffBenefMorte.statoNascita = unValore;
				datiGeneraliMinimiTitEffBenefMorte.descrStatoNascita = unaLabel
				datiGeneraliMinimiTitEffBenefMorte.provinciaNascita = '';
				datiGeneraliMinimiTitEffBenefMorte.descrProvinciaNascita = '';
				datiGeneraliMinimiTitEffBenefMorte.comuneNascita = '';
				datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = '';
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_MORTE:
			if(action.isFiduciaria) {
				datiGeneraliTitEffMorte.provinciaNascita = unValore;
				datiGeneraliTitEffMorte.descrProvinciaNascita = unaLabel;
				datiGeneraliTitEffMorte.comuneNascita = '';
				datiGeneraliTitEffMorte.descrComuneNascita = '';
				datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			} else {
				datiGeneraliMinimiTitEffBenefMorte.provinciaNascita = unValore;
				datiGeneraliMinimiTitEffBenefMorte.descrProvinciaNascita = unaLabel;
				datiGeneraliMinimiTitEffBenefMorte.comuneNascita = '';
				datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = '';
				datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE:
			if(action.isFiduciaria) {
				if(datiGeneraliTitEffMorte.provinciaNascita !== PROVINCIA_ESTERA) {
					datiGeneraliTitEffMorte.comuneNascita = unValore;
					datiGeneraliTitEffMorte.descrComuneNascita = unaLabel;
					datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
					titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
				} else {
					datiGeneraliTitEffMorte.comuneNascita = '';
					datiGeneraliTitEffMorte.descrComuneNascita = action.unDato;
					datiTitolareBenefMorteEff.datiGenerali = {...datiGeneraliTitEffMorte};
					titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
				}
			} else {
				if(datiGeneraliMinimiTitEffBenefMorte.provinciaNascita !== PROVINCIA_ESTERA) {	
					datiGeneraliMinimiTitEffBenefMorte.comuneNascita = unValore;
					datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = unaLabel;
					datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
					titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
				} else {
					datiGeneraliMinimiTitEffBenefMorte.comuneNascita = '';
					datiGeneraliMinimiTitEffBenefMorte.descrComuneNascita = action.unDato;
					datiTitolareBenefMorteEff.datiGeneraliMinimi = {...datiGeneraliMinimiTitEffBenefMorte};
					titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
				}
			}
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		/* case actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_MORTE:
			const cloneTitolareEffBenefMorteConfermaAggiungi = jsonCopy(action.titolareEffettivoBenefMorte);
			const titolareEffBenefMorteListConfermaAggiungi = {...state}.benefMorteGiuridico.titolareEffBenefMorteList;
			const titolareEffBenefMorteListConfermaAggiungiNuovo = [...titolareEffBenefMorteListConfermaAggiungi, cloneTitolareEffBenefMorteConfermaAggiungi];
			benefMorteGiuridico.titolareEffBenefMorteList = [...titolareEffBenefMorteListConfermaAggiungiNuovo];
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			} */
		/* case actionType.NUOVO_TITOLARE_EFF_BENEF_MORTE:
			const titolareEffBenefMorteNuovo = inizializzaTitolareEffBenefMorte(state);
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorteNuovo};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			} */
		/* case actionType.UPDATE_TITOLARE_EFF_BENEF_MORTE:
			
			const cloneTitolareEffBenefMorteUpdate = jsonCopy(action.titolareEffettivoBenefMorte);
			const titolareEffBenefMorteUpdateCodiceFiscale = cloneTitolareEffBenefMorteUpdate.datiGeneraliMinimi.codiceFiscale;
			const titolareEffBenefMorteListUpdate = [...state.benefMorteGiuridico.titolareEffBenefMorteList];

			let indexTitolareEffBenefMorteUpdate = undefined;
			titolareEffBenefMorteListUpdate.forEach( (item, index, array) => {  
				if(titolareEffBenefMorteUpdateCodiceFiscale === item.datiGeneraliMinimi.codiceFiscale){
					indexTitolareEffBenefMorteUpdate = index;
				}
			})
			//titolareEffBenefMorteListUpdate[indexTitolareEffBenefMorteUpdate] =cloneTitolareEffBenefMorteUpdate;
			titolareEffBenefMorteListUpdate[indexTitolareEffBenefMorteUpdate] = cloneTitolareEffBenefMorteUpdate;
			benefMorteGiuridico.titolareEffBenefMorteList = titolareEffBenefMorteListUpdate;
			return{
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}; */
		case actionType.SALVA_TITOLARE_EFF_BENEF_MORTE:
			titolareEffBenefMorte.controlloDati = action.unDato;
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.SALVA_TITOLARE_EFF_BENEF_MORTE_SUCCESS:
			return {
				...state,
				salvaTitolareEffBenefMorteSuccess: action.unDato,
			}	
		case actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_MORTE:
			titolareEffBenefMorte.anagraficaValida = action.unDato;
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE:
			datiRelazContrTitEffBenefMorte.relazContraente = unValore;
			datiRelazContrTitEffBenefMorte.descrRelazContraente = unaLabel;
			datiRelazContrTitEffBenefMorte.altro = '';
			datiTitolareBenefMorteEff.datiRelazContr = {...datiRelazContrTitEffBenefMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		case actionType.UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE:
			datiRelazContrTitEffBenefMorte.altro = action.unDato;
			datiTitolareBenefMorteEff.datiRelazContr = {...datiRelazContrTitEffBenefMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			}
		/* case actionType.CARICA_TITOLARE_EFF_BENEF_MORTE:
			console.log('#RT2 Reducer areaTitolareEffReducer.actionType.CARICA_TITOLARE_EFF.state', state);
			console.log('#RT2 Reducer areaTitolareEffReducer.action', action);
			const figuraSelezIndexCaricaBenefMorte = action.figuraSelezIndex;
			const titolareEffListCaricaBenefMorte = [...state.benefMorteGiuridico.titolareEffBenefMorteList];
			let titolareEffCaricaBenefMorte = { ...titolareEffListCaricaBenefMorte[figuraSelezIndexCaricaBenefMorte] };
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffCaricaBenefMorte}
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico,
			};	 */
		/* case actionType.DELETE_TITOLARE_EFF_BENEF_MORTE:
				let titolareEffDeleteEffBenefMorte = action.titolareEffettivo;
				const filterdTitolareEffDeleteListEffBenefMorte = state.benefMorteGiuridico.titolareEffBenefMorteList.filter(item => {
					const codiceFiscale = item.datiGeneraliMinimi.codiceFiscale;
					return codiceFiscale !== titolareEffDeleteEffBenefMorte.datiGeneraliMinimi.codiceFiscale
				})
				benefMorteGiuridico.titolareEffBenefMorteList = [...filterdTitolareEffDeleteListEffBenefMorte]
				return {
					...state,
					benefMorteGiuridico: benefMorteGiuridico
				}; */
				

/* DATI ATTIVITA ECONOMICA TITOLARE BENEFICIARIO MORTE */				
		case actionType.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_MORTE:
			datiAttEconomicaTitEffMorte.codAttPrevalSvolta = unValore;
			datiAttEconomicaTitEffMorte.descrAttPrevalSvolta = unaLabel;
			datiTitolareBenefMorteEff.datiAttEconomica = { ...datiAttEconomicaTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte
			}
		case actionType.UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_MORTE:
			datiAttEconomicaTitEffMorte.statoAttPrevalSvolta = unValore;
			datiAttEconomicaTitEffMorte.descrStatoAttPrevalSvolta = unaLabel;
			datiAttEconomicaTitEffMorte.provinciaAttPrevalSvolta = '';
			datiAttEconomicaTitEffMorte.descrProvinciaAttPrevalSvolta = '';
			datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = '';
			datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = '';
			datiTitolareBenefMorteEff.datiAttEconomica = { ...datiAttEconomicaTitEffMorte };
			titolareEffBenefMorte.datiTitolareBenefMorteEff = { ...datiTitolareBenefMorteEff };
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte
			}
		case actionType.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_MORTE:
			datiAttEconomicaTitEffMorte.provinciaAttPrevalSvolta = unValore;
			datiAttEconomicaTitEffMorte.descrProvinciaAttPrevalSvolta = unaLabel;
			datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = '';
			datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = '';
			datiTitolareBenefMorteEff.datiAttEconomica = {...datiAttEconomicaTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte
			}
		case actionType.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_MORTE:
			if(datiAttEconomicaTitEffMorte.provinciaAttPrevalSvolta === PROVINCIA_ESTERA) {
				datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = '';
				datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = action.unDato;
				datiTitolareBenefMorteEff.datiAttEconomica = {...datiAttEconomicaTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			} else {
				datiAttEconomicaTitEffMorte.comuneAttPrevalSvolta = unValore;
				datiAttEconomicaTitEffMorte.descrComuneAttPrevalSvolta = unaLabel;
				datiTitolareBenefMorteEff.datiAttEconomica = {...datiAttEconomicaTitEffMorte};
				titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			}
			return {
				...state,
				titolareEffBenefMorte: titolareEffBenefMorte
			}	
/* DATI ATTIVITA ECONOMICA TITOLARE EFFETTIVO BENEFICIARIO VITA */
		case actionType.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA:
			datiATtEconomicaTitEffVita.codAttPrevalSvolta = unValore;
			datiATtEconomicaTitEffVita.descrAttPrevalSvolta = unaLabel;
			datiTitolareBenefVitaEff.datiAttEconomica = { ...datiATtEconomicaTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}	
		case actionType.UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA:
			datiATtEconomicaTitEffVita.statoAttPrevalSvolta = unValore;
			datiATtEconomicaTitEffVita.descrStatoAttPrevalSvolta = unaLabel;
			datiATtEconomicaTitEffVita.provinciaAttPrevalSvolta = '';
			datiATtEconomicaTitEffVita.descrProvinciaAttPrevalSvolta = '';
			datiATtEconomicaTitEffVita.comuneAttPrevalSvolta = '';
			datiATtEconomicaTitEffVita.descrComuneAttPrevalSvolta = '';
			datiTitolareBenefVitaEff.datiAttEconomica = { ...datiATtEconomicaTitEffVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}
		case actionType.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA:
			datiATtEconomicaTitEffVita.provinciaAttPrevalSvolta = unValore;
			datiATtEconomicaTitEffVita.descrProvinciaAttPrevalSvolta = unaLabel;
			datiATtEconomicaTitEffVita.comuneAttPrevalSvolta = '';
			datiATtEconomicaTitEffVita.descrComuneAttPrevalSvolta = '';
			datiTitolareBenefVitaEff.datiAttEconomica = {...datiATtEconomicaTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}			
		case actionType.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA:
			if (datiATtEconomicaTitEffVita.provinciaAttPrevalSvolta === PROVINCIA_ESTERA) {
				datiATtEconomicaTitEffVita.comuneAttPrevalSvolta = '';
				datiATtEconomicaTitEffVita.descrComuneAttPrevalSvolta = action.unDato;
				datiTitolareBenefVitaEff.datiAttEconomica = { ...datiATtEconomicaTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			} else {
				datiATtEconomicaTitEffVita.comuneAttPrevalSvolta = unValore;
				datiATtEconomicaTitEffVita.descrComuneAttPrevalSvolta = unaLabel;
				datiTitolareBenefVitaEff.datiAttEconomica = { ...datiATtEconomicaTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita
			}
/* DATI DOMICILIO TITOLARE EFFETTIVO BENEFICIARIO MORTE */
		case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_MORTE:
			datiDomicilioTitEffMorte.domicilioDiversoResidenza = action.unDato;
			if(datiDomicilioTitEffMorte.domicilioDiversoResidenza === 'N') {
				datiDomicilioTitEffMorte.statoDomicilio = '';
				datiDomicilioTitEffMorte.descrStatoDomicilio = '';
				datiDomicilioTitEffMorte.provinciaDomicilio = '';
				datiDomicilioTitEffMorte.descrProvinciaDomicilio = '';
				datiDomicilioTitEffMorte.comuneDomicilio = '';
				datiDomicilioTitEffMorte.descrComuneDomicilio = '';
				datiDomicilioTitEffMorte.toponimoDomicilio = '';
				datiDomicilioTitEffMorte.descrToponimoDomicilio = '';
				datiDomicilioTitEffMorte.indirizzoDomicilio = '';
				datiDomicilioTitEffMorte.capDomicilio = '';
				datiDomicilioTitEffMorte.numCivicoDomicilio = '';
			}
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			datiDomicilioTitEffMorte.statoDomicilio = unValore;
			datiDomicilioTitEffMorte.descrStatoDomicilio = unaLabel;
			datiDomicilioTitEffMorte.provinciaDomicilio = '';
			datiDomicilioTitEffMorte.descrProvinciaDomicilio = '';
			datiDomicilioTitEffMorte.comuneDomicilio = '';
			datiDomicilioTitEffMorte.descrComuneDomicilio = '';
			datiDomicilioTitEffMorte.toponimoDomicilio = '';
			datiDomicilioTitEffMorte.descrToponimoDomicilio = '';
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			datiDomicilioTitEffMorte.provinciaDomicilio = unValore;
			datiDomicilioTitEffMorte.descrProvinciaDomicilio = unaLabel;
			datiDomicilioTitEffMorte.comuneDomicilio = '';
			datiDomicilioTitEffMorte.descrComuneDomicilio = '';
			datiDomicilioTitEffMorte.toponimoDomicilio = '';
			datiDomicilioTitEffMorte.descrToponimoDomicilio = '';
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			if(datiDomicilioTitEffMorte.provinciaDomicilio === PROVINCIA_ESTERA) {
				datiDomicilioTitEffMorte.comuneDomicilio = '';
				datiDomicilioTitEffMorte.descrComuneDomicilio = action.unDato;
				datiDomicilioTitEffMorte.toponimoDomicilio = '';
				datiDomicilioTitEffMorte.descrToponimoDomicilio = '';	
			} else {
				datiDomicilioTitEffMorte.comuneDomicilio = unValore;
				datiDomicilioTitEffMorte.descrComuneDomicilio = unaLabel;
				datiDomicilioTitEffMorte.toponimoDomicilio = '';
				datiDomicilioTitEffMorte.descrToponimoDomicilio = '';	
			}
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			if(datiDomicilioTitEffMorte.provinciaDomicilio === PROVINCIA_ESTERA) {
				datiDomicilioTitEffMorte.toponimoDomicilio = '';
				datiDomicilioTitEffMorte.descrToponimoDomicilio = action.unDato;	
			} else {
				datiDomicilioTitEffMorte.toponimoDomicilio = unValore;
				datiDomicilioTitEffMorte.descrToponimoDomicilio = unaLabel;	
			}
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			datiDomicilioTitEffMorte.indirizzoDomicilio = action.unDato;
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			datiDomicilioTitEffMorte.numCivicoDomicilio = action.unDato;
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_MORTE:
			datiDomicilioTitEffMorte.capDomicilio = action.unDato;
			datiTitolareBenefMorteEff.datiDomicilio = {...datiDomicilioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
/* DATI RECAPITO TITOLARE EFFETTIVO BENEFICIARIO MORTE */
		case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_MORTE:
			datiRecapitoTitEffMorte.corrispondenzaDiversoResidenza = action.unDato;
			if(datiRecapitoTitEffMorte.corrispondenzaDiversoResidenza === 'N') {
				datiRecapitoTitEffMorte.corrispondenzaIntestatoContraente = '';
				datiRecapitoTitEffMorte.statoRecapito = '';
				datiRecapitoTitEffMorte.descrStatoRecapito = '';
				datiRecapitoTitEffMorte.provinciaRecapito = '';
				datiRecapitoTitEffMorte.descrProvinciaRecapito = '';
				datiRecapitoTitEffMorte.comuneRecapito = '';
				datiRecapitoTitEffMorte.descrComuneRecapito = '';
				datiRecapitoTitEffMorte.toponimoRecapito = '';
				datiRecapitoTitEffMorte.descrToponimoRecapito = '';
				datiRecapitoTitEffMorte.descrIndirizzoRecapito = '';
				datiRecapitoTitEffMorte.numCivicoRecapito = '';
				datiRecapitoTitEffMorte.capRecapito = '';
				datiRecapitoTitEffMorte.presso = '';
			}
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_MORTE:
			datiRecapitoTitEffMorte.corrispondenzaIntestatoContraente = action.unDato;
			datiRecapitoTitEffMorte.presso = '';
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			datiRecapitoTitEffMorte.statoRecapito = unValore;
			datiRecapitoTitEffMorte.descrStatoRecapito = unaLabel;
			datiRecapitoTitEffMorte.provinciaRecapito = '';
			datiRecapitoTitEffMorte.descrProvinciaRecapito = '';
			datiRecapitoTitEffMorte.comuneRecapito = '';
			datiRecapitoTitEffMorte.descrComuneRecapito = '';
			datiRecapitoTitEffMorte.toponimoRecapito = '';
			datiRecapitoTitEffMorte.descrToponimoRecapito = '';
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			datiRecapitoTitEffMorte.provinciaRecapito = unValore;
			datiRecapitoTitEffMorte.descrProvinciaRecapito = unaLabel;
			datiRecapitoTitEffMorte.comuneRecapito = '';
			datiRecapitoTitEffMorte.descrComuneRecapito = '';
			datiRecapitoTitEffMorte.toponimoRecapito = '';
			datiRecapitoTitEffMorte.descrToponimoRecapito = '';
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			if(datiRecapitoTitEffMorte.provinciaRecapito === PROVINCIA_ESTERA) {
				datiRecapitoTitEffMorte.comuneRecapito = '';
				datiRecapitoTitEffMorte.descrComuneRecapito = action.unDato;
				datiRecapitoTitEffMorte.toponimoRecapito = '';
				datiRecapitoTitEffMorte.descrToponimoRecapito = '';
			} else {
				datiRecapitoTitEffMorte.comuneRecapito = unValore;
				datiRecapitoTitEffMorte.descrComuneRecapito = unaLabel;
				datiRecapitoTitEffMorte.toponimoRecapito = '';
				datiRecapitoTitEffMorte.descrToponimoRecapito = '';
			}
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			if(datiRecapitoTitEffMorte.provinciaRecapito === PROVINCIA_ESTERA) {
				datiRecapitoTitEffMorte.toponimoRecapito = '';
				datiRecapitoTitEffMorte.descrToponimoRecapito = action.unDato;
			} else {
				datiRecapitoTitEffMorte.toponimoRecapito = unValore;
				datiRecapitoTitEffMorte.descrToponimoRecapito = unaLabel;
			}
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			datiRecapitoTitEffMorte.descrIndirizzoRecapito = action.unDato;
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			datiRecapitoTitEffMorte.numCivicoRecapito = action.unDato;
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_MORTE:
			datiRecapitoTitEffMorte.capRecapito = action.unDato;
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_PRESSO_TITOLARE_EFFETTIVO_MORTE:
			datiRecapitoTitEffMorte.presso = action.unDato;
			datiTitolareBenefMorteEff.datiRecapito = {...datiRecapitoTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
/* DATI ANTIRICICLAGGIO TITOLARE EFFETTIVO BENEFICIARIO MORTE */
		case actionType.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.tipoDocumento = unValore;
			datiAntiriciclaggioTitEffMorte.descrTipoDocumento = unaLabel;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.numeroDocumento = action.unDato;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_ENTE_RILASCIO_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.enteRilascio = unValore;
			datiAntiriciclaggioTitEffMorte.descrEnteRilascio = unaLabel;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_DATA_RILASCIO_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.dataRilascio = action.unDato;
			datiAntiriciclaggioTitEffMorte.dataRilascioStampa = dataFormat(action.unDato, 'DD/MM/YYYY');
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_DATA_SCADENZA_TITOLARE_EFF_MORTE:
			const nuovaData = action.unDato;
			const nuovaDataFormattata = dataFormat(nuovaData, 'DD/MM/YYYY');
			datiAntiriciclaggioTitEffMorte.dataScadenza = nuovaData;
			datiAntiriciclaggioTitEffMorte.dataScadenzaStampa = nuovaDataFormattata;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_STATO_RILASCIO_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.statoRilascio = unValore;
			datiAntiriciclaggioTitEffMorte.descrStatoRilascio = unaLabel;
			datiAntiriciclaggioTitEffMorte.provinciaRilascio = '';
			datiAntiriciclaggioTitEffMorte.descrProvinciaRilascio = '';
			datiAntiriciclaggioTitEffMorte.comuneRilascio = '';
			datiAntiriciclaggioTitEffMorte.descrComuneRilascio = '';
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.provinciaRilascio = unValore;
			datiAntiriciclaggioTitEffMorte.descrProvinciaRilascio = unaLabel;
			datiAntiriciclaggioTitEffMorte.comuneRilascio = '';
			datiAntiriciclaggioTitEffMorte.descrComuneRilascio = '';
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_MORTE:
			if(datiAntiriciclaggioTitEffMorte.provinciaRilascio === PROVINCIA_ESTERA) {
				datiAntiriciclaggioTitEffMorte.comuneRilascio = '';
				datiAntiriciclaggioTitEffMorte.descrComuneRilascio = action.unDato;
			} else {
				datiAntiriciclaggioTitEffMorte.comuneRilascio = unValore;
				datiAntiriciclaggioTitEffMorte.descrComuneRilascio = unaLabel;
			}
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_TITOLARE_EFF_QUALIFICA_MORTE:
			datiAntiriciclaggioTitEffMorte.qualifica = unValore;
			datiAntiriciclaggioTitEffMorte.descrQualifica = unaLabel;
			datiAntiriciclaggioTitEffMorte.altro = '';
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			datiTitolareBenefMorteEff.percOwnerShip = '';
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_ALTRO_QUALIFICA_MORTE:
			datiAntiriciclaggioTitEffMorte.altro = action.unDato;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_MORTE:
			datiTitolareBenefMorteEff.percOwnerShip = action.unDato
			/* const datiTitEffBenefMorte = {...state}.benefMorteGiuridico;
			const titolareBenefMorte = {...datiTitEffBenefMorte}.titolareEffBenefMorte;
			const antiBenefMorte = {...titolareBenefMorte}.datiTitolareBenefMorteEff;
			antiBenefMorte.percOwnerShip = action.unDato;
			titolareBenefMorte.datiTitolareBenefMorteEff = {...antiBenefMorte};
			datiTitEffBenefMorte.titolareEffBenefMorte = {...titolareBenefMorte}; */
			return {
				...state,
				datiTitolareBenefMorteEff
			}
		case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.espostaPoliticamente = action.unDato;
			datiAntiriciclaggioTitEffMorte.isInCarica = '';
			datiAntiriciclaggioTitEffMorte.caricaPersonaEsposta = '';
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.isInCarica = action.unDato;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}
		case actionType.UPDATE_CARICA_PEP_TITOLARE_EFF_MORTE:
			datiAntiriciclaggioTitEffMorte.caricaPersonaEsposta = action.unDato;
			datiTitolareBenefMorteEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffMorte};
			titolareEffBenefMorte.datiTitolareBenefMorteEff = {...datiTitolareBenefMorteEff};
			benefMorteGiuridico.titolareEffBenefMorte = {...titolareEffBenefMorte};
			return {
				...state,
				benefMorteGiuridico: benefMorteGiuridico
			}


/*  DATI GENERALI MINIMI TITOLARE EFFETTIVO BENEFICIARIO VITA  */
		case actionType.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA:
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.sesso = action.unDato;
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {				
				datiGeneraliMinimiTitEffBenefVita.sesso = action.unDato;
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		/* case actionType.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA:
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.codiceFiscale = action.unDato;
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {
				datiGeneraliMinimiTitEffBenefVita.codiceFiscale = action.unDato;
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			} */	
		case actionType.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA:
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.cognome = action.unDato;
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {
				datiGeneraliMinimiTitEffBenefVita.cognome = action.unDato;
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA:
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.nome = action.unDato;
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {
				datiGeneraliMinimiTitEffBenefVita.nome = action.unDato;
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA:
			const unaDataTitolareBenefVita = action.unDato;
			const nuovaDataNascitaFormattataTitolareBenefVita = dataFormat(unaDataTitolareBenefVita, 'DD/MM/YYYY');
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.dataNascita = unaDataTitolareBenefVita;
				datiGeneraliTitEffVita.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefVita;
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {
				datiGeneraliMinimiTitEffBenefVita.dataNascita = unaDataTitolareBenefVita;
				datiGeneraliMinimiTitEffBenefVita.dataNascitaStampa = nuovaDataNascitaFormattataTitolareBenefVita;
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA:
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.statoNascita = unValore;
				datiGeneraliTitEffVita.descrStatoNascita = unaLabel
				datiGeneraliTitEffVita.provinciaNascita = '';
				datiGeneraliTitEffVita.descrProvinciaNascita = '';
				datiGeneraliTitEffVita.comuneNascita = '';
				datiGeneraliTitEffVita.descrComuneNascita = '';
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {
				datiGeneraliMinimiTitEffBenefVita.statoNascita = unValore;
				datiGeneraliMinimiTitEffBenefVita.descrStatoNascita = unaLabel
				datiGeneraliMinimiTitEffBenefVita.provinciaNascita = '';
				datiGeneraliMinimiTitEffBenefVita.descrProvinciaNascita = '';
				datiGeneraliMinimiTitEffBenefVita.comuneNascita = '';
				datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = '';
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA:
			if(action.isFiduciaria) {
				datiGeneraliTitEffVita.provinciaNascita = unValore;
				datiGeneraliTitEffVita.descrProvinciaNascita = unaLabel;
				datiGeneraliTitEffVita.comuneNascita = '';
				datiGeneraliTitEffVita.descrComuneNascita = '';
				datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
			} else {
				datiGeneraliMinimiTitEffBenefVita.provinciaNascita = unValore;
				datiGeneraliMinimiTitEffBenefVita.descrProvinciaNascita = unaLabel;
				datiGeneraliMinimiTitEffBenefVita.comuneNascita = '';
				datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = '';
				datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
				titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA:
			if(action.isFiduciaria) {
				if(datiGeneraliTitEffVita.provinciaNascita !== PROVINCIA_ESTERA) {
					datiGeneraliTitEffVita.comuneNascita = unValore;
					datiGeneraliTitEffVita.descrComuneNascita = unaLabel;
					datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
					titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
				} else {
					datiGeneraliTitEffVita.comuneNascita = '';
					datiGeneraliTitEffVita.descrComuneNascita = action.unDato;
					datiTitolareBenefVitaEff.datiGenerali = { ...datiGeneraliTitEffVita };
					titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };	
				}
 			} else {
				if (datiGeneraliMinimiTitEffBenefVita.provinciaNascita !== PROVINCIA_ESTERA) {
					datiGeneraliMinimiTitEffBenefVita.comuneNascita = unValore;
					datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = unaLabel;
					datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
					titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
				} else {
					datiGeneraliMinimiTitEffBenefVita.comuneNascita = '';
					datiGeneraliMinimiTitEffBenefVita.descrComuneNascita = action.unDato;
					datiTitolareBenefVitaEff.datiGeneraliMinimi = { ...datiGeneraliMinimiTitEffBenefVita };
					titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
				}
			}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA:
			datiRelazContrTitEffBenefVita.relazContraente = unValore;
			datiRelazContrTitEffBenefVita.descrRelazContraente = unaLabel;
			datiRelazContrTitEffBenefVita.altro = '';
			datiTitolareBenefVitaEff.datiRelazContr = { ...datiRelazContrTitEffBenefVita };
			titolareEffBenefVita.datiTitolareBenefVitaEff = { ...datiTitolareBenefVitaEff };
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}	
		case actionType.UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA:
			datiRelazContrTitEffBenefVita.altro = action.unDato;
			datiTitolareBenefVitaEff.datiRelazContr = {...datiRelazContrTitEffBenefVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		/* case actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA:
			const cloneTitolareEffBenefVitaConfermaAggiungi = jsonCopy(action.titolareEffettivoBenefVita);
			const titolareEffBenefVitaListConfermaAggiungi = { ...state}.benefVitaGiuridico.titolareEffBenefVitaList;
			const titolareEffBenefVitaListConfermaAggiungiNuovo = [...titolareEffBenefVitaListConfermaAggiungi, cloneTitolareEffBenefVitaConfermaAggiungi];
			benefVitaGiuridico.titolareEffBenefVitaList = [...titolareEffBenefVitaListConfermaAggiungiNuovo];
			return {
				...state,
				titolareEffBenefVitaList: titolareEffBenefVitaListConfermaAggiungiNuovo
			} */
		/* case actionType.NUOVO_TITOLARE_EFF_BENEF_VITA:
			const titolareEffBenefVitaNuovo = inizializzaTitolareEffBenefVita(state);
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVitaNuovo,
			} */
		/* case actionType.UPDATE_TITOLARE_EFF_BENEF_VITA:

			const cloneTitolareEffBenefVitaUpdate = jsonCopy(action.titolareEffettivoBenefVita);
			const titolareEffBenefVitaUpdateCodiceFiscale = cloneTitolareEffBenefVitaUpdate.datiGeneraliMinimi.codiceFiscale;
			const titolareEffBenefVitaListUpdate = [...state.benefVitaGiuridico.titolareEffBenefVitaList];

			let indexTitolareEffBenefVitaUpdate = undefined;
			titolareEffBenefVitaListUpdate.forEach((item, index, array) => {
				if (titolareEffBenefVitaUpdateCodiceFiscale === item.datiGeneraliMinimi.codiceFiscale) {
					indexTitolareEffBenefVitaUpdate = index;
				}
			})
			titolareEffBenefVitaListUpdate[indexTitolareEffBenefVitaUpdate] = cloneTitolareEffBenefVitaUpdate;
			benefVitaGiuridico.titolareEffBenefVitaList = titolareEffBenefVitaListUpdate;
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico,
			}; */
		/* case actionType.CARICA_TITOLARE_EFF_BENEF_VITA:
			const figuraSelezIndexCaricaBenefVita = action.figuraSelezIndex;
			const titolareEffListCaricaBenefVita = [...state.benefVitaGiuridico.titolareEffBenefVitaList];
			let titolareEffCaricaBenefVita = { ...titolareEffListCaricaBenefVita[figuraSelezIndexCaricaBenefVita]};
			benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff = {...titolareEffCaricaBenefVita}
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico,
			}; */
		/* case actionType.SALVA_TITOLARE_EFF_BENEF_VITA:
			titolareEffBenefVita.controlloDati = action.unDato;
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}
			return {
				...state,
				titolareEffBenefVita: titolareEffBenefVita,
			}
		case actionType.SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS:
			return {
				...state,
				salvaTitolareEffBenefVitaSuccess: action.unDato,
			} */
		case actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA:
			titolareEffBenefVita.anagraficaValida = action.unDato;
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico,
			}	
		/* case actionType.DELETE_TITOLARE_EFF_BENEF_VITA:
				let titolareEffDeleteEffBenefVita = action.titolareEffettivo;
				const filterdTitolareEffDeleteListEffBenefVita = state.benefVitaGiuridico.titolareEffBenefVitaList.filter(item => {
					
					const codiceFiscale = item.datiGeneraliMinimi.codiceFiscale;
					return codiceFiscale !== titolareEffDeleteEffBenefVita.datiGeneraliMinimi.codiceFiscale
				})
				benefVitaGiuridico.titolareEffBenefVitaList = [...filterdTitolareEffDeleteListEffBenefVita]
				return {
					...state,
					benefVitaGiuridico: benefVitaGiuridico
				}; */	
/* DATI RESIDENZA TITOLARE EFFETTIVO BENEFICIARIO VITA */
		case actionType.UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA:
			datiResidenzaTitEffVita.statoResidenza = unValore;
			datiResidenzaTitEffVita.descrStatoResidenza = unaLabel;
			datiResidenzaTitEffVita.provinciaResidenza = '';
			datiResidenzaTitEffVita.descrProvinciaResidenza = '';
			datiResidenzaTitEffVita.comuneResidenza = '';
			datiResidenzaTitEffVita.descrComuneResidenza = '';
			datiResidenzaTitEffVita.toponimoResidenza = '';
			datiResidenzaTitEffVita.descrToponimoResidenza = '';
			return {
				...state,
				datiResidenzaTitEffVita
			}
		case actionType.UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA:
			datiResidenzaTitEffVita.provinciaResidenza = unValore;
			datiResidenzaTitEffVita.descrProvinciaResidenza = unaLabel;
			datiResidenzaTitEffVita.comuneResidenza = '';
			datiResidenzaTitEffVita.descrComuneResidenza = '';
			datiResidenzaTitEffVita.toponimoResidenza = '';
			datiResidenzaTitEffVita.descrToponimoResidenza = '';
			return {
				...state,
				datiResidenzaTitEffVita
			}
		case actionType.UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA:
			if(datiResidenzaTitEffVita.provinciaResidenza === PROVINCIA_ESTERA) {
				datiResidenzaTitEffVita.comuneResidenza = '';
				datiResidenzaTitEffVita.descrComuneResidenza = action.unDato;
				datiResidenzaTitEffVita.toponimoResidenza = '';
				datiResidenzaTitEffVita.descrToponimoResidenza = '';	
			} else {
				datiResidenzaTitEffVita.comuneResidenza = unValore;
				datiResidenzaTitEffVita.descrComuneResidenza = unaLabel;
				datiResidenzaTitEffVita.toponimoResidenza = '';
				datiResidenzaTitEffVita.descrToponimoResidenza = '';
			}
			return {
				...state,
				datiResidenzaTitEffVita
			}
		case actionType.UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA:
			if(datiResidenzaTitEffVita.provinciaResidenza === PROVINCIA_ESTERA) {
				datiResidenzaTitEffVita.toponimoResidenza = '';
				datiResidenzaTitEffVita.descrToponimoResidenza = action.unDato;	
			} else {
				datiResidenzaTitEffVita.toponimoResidenza = unValore;
				datiResidenzaTitEffVita.descrToponimoResidenza = unValore;
			}
			return {
				...state,
				datiResidenzaTitEffVita
			}
		case actionType.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA:
			datiResidenzaTitEffVita.numCivicoResidenza = action.unDato;
			return {
				...state,
				datiResidenzaTitEffVita
			}
		case actionType.UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA:
			datiResidenzaTitEffVita.descrIndirizzoResidenza = action.unDato;
			return {
				...state,
				datiResidenzaTitEffVita
			}
		case actionType.UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA:
			datiResidenzaTitEffVita.capResidenza = action.unDato;
			return {
				...state,
				datiResidenzaTitEffVita
			}
/* DATI DOMICILIO TITOLARE EFFETTIVO BENEFICIARIO VITA  */
		case actionType.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA:
			datiDomicilioTitEffVita.domicilioDiversoResidenza = action.unDato;
			if(datiDomicilioTitEffVita.domicilioDiversoResidenza === 'N') {
				datiDomicilioTitEffVita.statoDomicilio = '';
				datiDomicilioTitEffVita.descrStatoDomicilio = '';
				datiDomicilioTitEffVita.provinciaDomicilio = '';
				datiDomicilioTitEffVita.descrProvinciaDomicilio = '';
				datiDomicilioTitEffVita.comuneDomicilio = '';
				datiDomicilioTitEffVita.descrComuneDomicilio = '';
				datiDomicilioTitEffVita.toponimoDomicilio = '';
				datiDomicilioTitEffVita.descrToponimoDomicilio = '';
				datiDomicilioTitEffVita.indirizzoDomicilio = '';
				datiDomicilioTitEffVita.capDomicilio = '';
				datiDomicilioTitEffVita.numCivicoDomicilio = '';
			}
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			datiDomicilioTitEffVita.statoDomicilio = unValore;
			datiDomicilioTitEffVita.descrStatoDomicilio = unaLabel;
			datiDomicilioTitEffVita.provinciaDomicilio = '';
			datiDomicilioTitEffVita.descrProvinciaDomicilio = '';
			datiDomicilioTitEffVita.comuneDomicilio = '';
			datiDomicilioTitEffVita.descrComuneDomicilio = '';
			datiDomicilioTitEffVita.toponimoDomicilio = '';
			datiDomicilioTitEffVita.descrToponimoDomicilio = '';
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			datiDomicilioTitEffVita.provinciaDomicilio = unValore;
			datiDomicilioTitEffVita.descrProvinciaDomicilio = unaLabel;
			datiDomicilioTitEffVita.comuneDomicilio = '';
			datiDomicilioTitEffVita.descrComuneDomicilio = '';
			datiDomicilioTitEffVita.toponimoDomicilio = '';
			datiDomicilioTitEffVita.descrToponimoDomicilio = '';
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			if(datiDomicilioTitEffVita.provinciaDomicilio === PROVINCIA_ESTERA) {
				datiDomicilioTitEffVita.comuneDomicilio = '';
				datiDomicilioTitEffVita.descrComuneDomicilio = action.unDato;	
				datiDomicilioTitEffVita.toponimoDomicilio = '';
				datiDomicilioTitEffVita.descrToponimoDomicilio = '';
			} else {
				datiDomicilioTitEffVita.comuneDomicilio = unValore;
				datiDomicilioTitEffVita.descrComuneDomicilio = unaLabel;	
				datiDomicilioTitEffVita.toponimoDomicilio = '';
				datiDomicilioTitEffVita.descrToponimoDomicilio = '';
			}
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			if(datiDomicilioTitEffVita.provinciaDomicilio === PROVINCIA_ESTERA) {
				datiDomicilioTitEffVita.toponimoDomicilio = '';
				datiDomicilioTitEffVita.descrToponimoDomicilio = action.unDato;
			} else {
				datiDomicilioTitEffVita.toponimoDomicilio = unValore;
				datiDomicilioTitEffVita.descrToponimoDomicilio = unaLabel;
			}
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			datiDomicilioTitEffVita.indirizzoDomicilio = action.unDato;
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			datiDomicilioTitEffVita.numCivicoDomicilio = action.unDato;
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA:
			datiDomicilioTitEffVita.capDomicilio = action.unDato;
			datiTitolareBenefVitaEff.datiDomicilio = {...datiDomicilioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
/* DATI RECAPITO TITOLARE EFFETTIVO BENEFICIARIO VITA */
		case actionType.UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA:
			datiRecapitoTitEffVita.corrispondenzaDiversoResidenza = action.unDato;
			if(datiRecapitoTitEffVita.corrispondenzaDiversoResidenza === 'N') {
				datiRecapitoTitEffVita.corrispondenzaIntestatoContraente = '';
				datiRecapitoTitEffVita.statoRecapito = '';
				datiRecapitoTitEffVita.descrStatoRecapito = '';
				datiRecapitoTitEffVita.provinciaRecapito = '';
				datiRecapitoTitEffVita.descrProvinciaRecapito = '';
				datiRecapitoTitEffVita.comuneRecapito = '';
				datiRecapitoTitEffVita.descrComuneRecapito = '';
				datiRecapitoTitEffVita.toponimoRecapito = '';
				datiRecapitoTitEffVita.descrToponimoRecapito = '';
				datiRecapitoTitEffVita.descrIndirizzoRecapito = '';
				datiRecapitoTitEffVita.numCivicoRecapito = '';
				datiRecapitoTitEffVita.capRecapito = '';
				datiRecapitoTitEffVita.presso = '';
			}
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA:
			datiRecapitoTitEffVita.corrispondenzaIntestatoContraente = action.unDato;
			datiRecapitoTitEffVita.presso = '';
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			datiRecapitoTitEffVita.statoRecapito = unValore;
			datiRecapitoTitEffVita.descrStatoRecapito = unaLabel;
			datiRecapitoTitEffVita.provinciaRecapito = '';
			datiRecapitoTitEffVita.descrProvinciaRecapito = '';
			datiRecapitoTitEffVita.comuneRecapito = '';
			datiRecapitoTitEffVita.descrComuneRecapito = '';
			datiRecapitoTitEffVita.toponimoRecapito = '';
			datiRecapitoTitEffVita.descrToponimoRecapito = '';
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			datiRecapitoTitEffVita.provinciaRecapito = unValore;
			datiRecapitoTitEffVita.descrProvinciaRecapito = unaLabel;
			datiRecapitoTitEffVita.comuneRecapito = '';
			datiRecapitoTitEffVita.descrComuneRecapito = '';
			datiRecapitoTitEffVita.toponimoRecapito = '';
			datiRecapitoTitEffVita.descrToponimoRecapito = '';
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			if(datiRecapitoTitEffVita.provinciaRecapito === PROVINCIA_ESTERA) {
				datiRecapitoTitEffVita.comuneRecapito = '';
				datiRecapitoTitEffVita.descrComuneRecapito = action.unDato;
				datiRecapitoTitEffVita.toponimoRecapito = '';
				datiRecapitoTitEffVita.descrToponimoRecapito = '';	
			} else {
				datiRecapitoTitEffVita.comuneRecapito = unValore;
				datiRecapitoTitEffVita.descrComuneRecapito = unaLabel;
				datiRecapitoTitEffVita.toponimoRecapito = '';
				datiRecapitoTitEffVita.descrToponimoRecapito = '';	
			}
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			if(datiRecapitoTitEffVita.provinciaRecapito === PROVINCIA_ESTERA) {
				datiRecapitoTitEffVita.toponimoRecapito = '';
				datiRecapitoTitEffVita.descrToponimoRecapito = action.unDato;	
			} else {
				datiRecapitoTitEffVita.toponimoRecapito = unValore;
				datiRecapitoTitEffVita.descrToponimoRecapito = unaLabel;	
			}
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			datiRecapitoTitEffVita.descrIndirizzoRecapito = action.unDato;
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			datiRecapitoTitEffVita.numCivicoRecapito = action.unDato;
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA:
			datiRecapitoTitEffVita.capRecapito = action.unDato;
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA:
			datiRecapitoTitEffVita.presso = action.unDato;
			datiTitolareBenefVitaEff.datiRecapito = {...datiRecapitoTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
/* DATI ANTIRICICLAGGIO TITOLARE EFFETTIVO BENEFICIARIO VITA */
		case actionType.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.tipoDocumento = unValore;
			datiAntiriciclaggioTitEffVita.descrTipoDocumento = unaLabel;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.numeroDocumento = action.unDato;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.enteRilascio = unValore;
			datiAntiriciclaggioTitEffVita.descrEnteRilascio = unaLabel;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.dataRilascio = action.unDato;
			datiAntiriciclaggioTitEffVita.descrEnteRilascio = dataFormat(action.unDato, 'DD/MM/YYYY');
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA:
			const nuovaDataScadenzaVita = action.unDato;
			const nuovaDataFormattataScadenzaVita = dataFormat(nuovaDataScadenzaVita, 'DD/MM/YYYY');
			datiAntiriciclaggioTitEffVita.dataScadenza = nuovaDataScadenzaVita;
			datiAntiriciclaggioTitEffVita.dataScadenzaStampa = nuovaDataFormattataScadenzaVita;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.statoRilascio = unValore;
			datiAntiriciclaggioTitEffVita.descrStatoRilascio = unaLabel;
			datiAntiriciclaggioTitEffVita.provinciaRilascio = '';
			datiAntiriciclaggioTitEffVita.descrProvinciaRilascio = '';
			datiAntiriciclaggioTitEffVita.comuneRilascio = '';
			datiAntiriciclaggioTitEffVita.descrProvinciaRilascio = '';
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.provinciaRilascio = unValore;
			datiAntiriciclaggioTitEffVita.descrProvinciaRilascio = unaLabel;
			datiAntiriciclaggioTitEffVita.comuneRilascio = '';
			datiAntiriciclaggioTitEffVita.descrComuneRilascio = '';
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA:
			if(datiAntiriciclaggioTitEffVita.provinciaRilascio === PROVINCIA_ESTERA) {
				datiAntiriciclaggioTitEffVita.comuneRilascio = '';
				datiAntiriciclaggioTitEffVita.descrComuneRilascio = action.unDato;
			} else {
				datiAntiriciclaggioTitEffVita.comuneRilascio = unValore;
				datiAntiriciclaggioTitEffVita.descrComuneRilascio = unaLabel;
			}
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_TITOLARE_EFF_QUALIFICA_VITA:
			datiAntiriciclaggioTitEffVita.qualifica = unValore;
			datiAntiriciclaggioTitEffVita.descrQualifica = unaLabel;
			datiAntiriciclaggioTitEffVita.altro = '';
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			datiTitolareBenefVitaEff.percOwnerShip = '';
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_ALTRO_QUALIFICA_VITA:
			datiAntiriciclaggioTitEffVita.altro = action.unDato;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA:
			datiTitolareBenefVitaEff.percOwnerShip = action.unDato;	
		/* datiAntiriciclaggioTitEffVita.percOwnerShip = action.unDato;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita}; */
			return {
				...state,
				datiTitolareBenefVitaEff
			}
		case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.espostaPoliticamente = action.unDato;
			datiAntiriciclaggioTitEffVita.isInCarica = '';
			datiAntiriciclaggioTitEffVita.caricaPersonaEsposta = '';
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.isInCarica = action.unDato;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}
		case actionType.UPDATE_CARICA_PEP_TITOLARE_EFF_VITA:
			datiAntiriciclaggioTitEffVita.caricaPersonaEsposta = action.unDato;
			datiTitolareBenefVitaEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEffVita};
			titolareEffBenefVita.datiTitolareBenefVitaEff = {...datiTitolareBenefVitaEff};
			benefVitaGiuridico.titolareEffBenefVita = {...titolareEffBenefVita};
			return {
				...state,
				benefVitaGiuridico: benefVitaGiuridico
			}


/* DATI ATTIVITA ECONOMICA TITOLARE EFFETTIVO */
		case actionType.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_EFFETTIVO:
			datiAttEconomica.codAttPrevalSvolta = unValore;
			datiAttEconomica.descrAttPrevalSvolta = unaLabel;
			datiTitolareEff.datiAttEconomica = {...datiAttEconomica};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_ATTIVITA_PRECEDENTE_TITOLARE_EFFETTIVO:
			datiAntiriciclaggioTitEff.codAttivitaPrecedente = unValore;
			datiAntiriciclaggioTitEff.descrAttivitaPrecedente = unaLabel;
			datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_AMBITO_ATTIVITA_TITOLARE_EFFETTIVO:
			datiAntiriciclaggioTitEff.codAmbitoAttivita = unValore;
			datiAntiriciclaggioTitEff.descrAmbitoAttivita = unaLabel;
			datiTitolareEff.datiAntiriciclaggio = {...datiAntiriciclaggioTitEff};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_STATO_ATT_PREVAL_TITOLARE_EFFETTIVO:
			datiAttEconomica.statoAttPrevalSvolta = unValore;
			datiAttEconomica.descrStatoAttPrevalSvolta = unaLabel;
			datiAttEconomica.provinciaAttPrevalSvolta = '';
			datiAttEconomica.descrProvinciaAttPrevalSvolta = '';
			datiAttEconomica.comuneAttPrevalSvolta = '';
			datiAttEconomica.descrComuneAttPrevalSvolta = '';
			datiTitolareEff.datiAttEconomica = {...datiAttEconomica};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}			
		case actionType.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_EFFETTIVO:
			datiAttEconomica.provinciaAttPrevalSvolta = unValore;
			datiAttEconomica.descrProvinciaAttPrevalSvolta = unaLabel;
			datiAttEconomica.comuneAttPrevalSvolta = '';
			datiAttEconomica.descrComuneAttPrevalSvolta = '';
			datiTitolareEff.datiAttEconomica = {...datiAttEconomica};
			titolareEff.datiTitolareEff = {...datiTitolareEff};
			return {
				...state,
				titolareEff: titolareEff
			}
		case actionType.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_EFFETTIVO:
			if(datiAttEconomica.provinciaAttPrevalSvolta === PROVINCIA_ESTERA) {
				datiAttEconomica.comuneAttPrevalSvolta = '';
				datiAttEconomica.descrComuneAttPrevalSvolta = action.unDato;
				datiTitolareEff.datiAttEconomica = {...datiAttEconomica};
				titolareEff.datiTitolareEff = {...datiTitolareEff};
			} else {
				datiAttEconomica.comuneAttPrevalSvolta = unValore;
				datiAttEconomica.descrComuneAttPrevalSvolta = unaLabel;
				datiTitolareEff.datiAttEconomica = {...datiAttEconomica};
				titolareEff.datiTitolareEff = {...datiTitolareEff};
			}
			return {
				...state,
				titolareEff: titolareEff
			}
	    default:
	        return state;
    }
};

export const inizializzaTitolareEff = (state) => {
  const titolareEff_INI = {...state}.titolareEff;
  titolareEff_INI.anagraficaValida = null;
  titolareEff_INI.controlloDati = '';
  titolareEff_INI.datiTitolareEff = jsonCopy(datiTitolareEffInizializzato);
  titolareEff_INI.datiRecapito = jsonCopy(datiRecapitoInizializzato);
  titolareEff_INI.datiAttEconomica = jsonCopy(datiAttEconomicaInizializzato);
  return titolareEff_INI;
}

export const inizializzaTitolareEffBenefMorte = (state) => {
	const titolareEffBenefMorte_INI = {...state}.benefMorteGiuridico.titolareEffBenefMorte;
	titolareEffBenefMorte_INI.anagraficaValida = null;
	titolareEffBenefMorte_INI.controlloDati = '';
	titolareEffBenefMorte_INI.datiTitolareBenefMorteEff = jsonCopy(datiTitolareEffettivoBenefMorteInizializzato);
	return titolareEffBenefMorte_INI;
}

export const inizializzaTitolareEffBenefVita = (state) => {
	const titolareEffBenefVita_INI = {...state}.benefVitaGiuridico.titolareEffBenefVita;
	titolareEffBenefVita_INI.anagraficaValida = null;
	titolareEffBenefVita_INI.controlloDati = '';
	titolareEffBenefVita_INI.titolareEffBenefVita = jsonCopy(datiTitolareEffettivoBenefVitaInizializzato);
	return titolareEffBenefVita_INI;
}

export const inizializzaTitolareEffLista = (state) => {
  const titolareEffList_INI = [];
  return titolareEffList_INI;
}

export const inizializzaTitolareEffBenefMorteLista = (state) => {
	const titolareEffBenefMorteList_INI = [];
	return titolareEffBenefMorteList_INI;
}

export const inizializzaTitolareEffBenefVitaLista = (state) => {
	const titolareEffBenefVitaList_INI = [];
	return titolareEffBenefVitaList_INI;
}

export default areaTitolareEffReducer;