import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { textTrim } from '../../utility/genericUtility';

const styles = theme => ({
	margin16 : { marginTop: '16px',
//		width: 'calc(100% - 104px)',
//		width: '60%',
		},
	buttonpwd: {
		padding: 0,
		[theme.breakpoints.down('xs')]: {
	          display: 'none', 
	      },
	},
});

class TextFieldAdornPwdComp extends React.Component {
		
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      showPassword: false,
      typing: false,
      isError: false,
    }
  };
  
/*  handleChange = event => {
    console.log('TextFieldComp old value this.state.value: ', this.state.value);
    console.log('TextFieldComp new value event.target.value: ', event.target.value);
    this.setState({
      value: event.target.value,
    });
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  }; */
  
  handleChange = event => {
	//console.log('#RTCFA Login TextFieldAdornPwdComp handleChange value event.target.value:', event.target.value);
	if (!this.props.maxLength || (this.props.maxLength&&event.target.value.length<=this.props.maxLength)) {
	  this.setState({
	    value: event.target.value,
	    typing: true, 
	  });
	}
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  };
  
  handleOnBlur = event => {
    this.setState({
      value: event.target.value,
      typing: false,
    });
    if(this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleError = (isError, id) => {
    this.setState({
      isError: isError,
    });    
    if(this.props.onError) {
      this.props.onError(id, isError);
    }
  };  
  
  valueText = (trim) => {
	const aValue = (this.state.typing === true) ? textTrim(this.state.value, trim) : textTrim(this.props.value, trim);
	return aValue;
  };
	  
  handleClickShowPassword = () => {
    this.setState(
      state => ({
        showPassword: !state.showPassword 
    }));
  };
	  
/*  handleOnBlur = event => {
    console.log('#RTCFA Login TextFieldAdornPwdComp handleOnBlur value event.target.value:', event.target.value);
    this.setState({
      value: event.target.value,
    });
    if(this.props.onBlur) {
      this.props.onBlur(event);
    }
  };*/
	  
  render () {
    const { classes, onChange, aStyle, id, label, value, required, adornComp, adornPosit, controlloDati, disabled, readonly, maxLength, onError, isControlError } = this.props;
    const { t } = this.props; //hoc		
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const isReadonly = readonly === true;
    const isDisabledOrReadonly = (disabled === true || readonly === true);
    const aValue = isDisabled ? '' : this.valueText(controlloDati);
    const aLabel = t(label);
    const isError = (((controlloDati === true) && isRequired && !isDisabled && (aValue === '')) || (isControlError === true));     
    return (
      <TextField
        error={isError}
        required={isRequired}
        disabled={isDisabledOrReadonly}
        //id='outlined-adornment-password'
        type={this.state.showPassword ? 'text' : 'password'}
        label={aLabel}
        className={classNames(aStyle, classes.margin16)}
        value={aValue}
        onChange={this.handleChange}
        onBlur={this.handleOnBlur}
        variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position={adornPosit} className={classes.buttonpwd}>
                <IconButton aria-label='Toggle password visibility' onClick={this.handleClickShowPassword} className={classes.buttonpwd}>
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}	
      />
    );
  };
  
  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;	  
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueText(controlloDati);
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === '');
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
      this.handleError(isError, id);      	
    }
  }; 
};

TextFieldAdornPwdComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(TextFieldAdornPwdComp));
