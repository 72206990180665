import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ButtonComp from '../../common/ButtonComp';
import SelectComp from '../../common/SelectComp';
import Typography from '@material-ui/core/Typography';
import { uris, fetchParam } from '../../../uris';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

import moment from 'moment';
import 'moment/locale/it';

import * as actions from './action/datiInviaNuoveCredenziali';
import * as actionType from './actionType/datiInviaNuoveCredenziali';

import { textTrim } from '../../../utility/genericUtility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,  
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	datiInviaNuoveCredenziali: state.areaDatiInviaNuoveCredenzialiReducer.gestioneUtenza.datiInviaNuoveCredenziali,
  };
};
	
//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  return {
	updateDatiInviaNuoveCredenziali: (unDato, unActionType) => dispatch(actions.updateDatiInviaNuoveCredenziali(unDato, unActionType)),
	salvaDatiInviaNuoveCredenziali: (controlloDati) => dispatch(actions.salvaDatiInviaNuoveCredenziali(controlloDati)),
  };
};

class DatiInviaNuoveCredenziali extends React.Component {
  constructor(props) {
    super(props);
    this.props.salvaDatiInviaNuoveCredenziali(false, actionType.SALVA_DATI_INVIA_NUOVE_CREDENZIALI);
    let resetMandato = {
      value: '',
      label: '',
    } 
    this.props.updateDatiInviaNuoveCredenziali(resetMandato, actionType.UPDATE_MANDATO_INVIA); 
//    const datiInviaNuoveCredenziali = this.props.datiInviaNuoveCredenziali;
    this.state = {
//      mandato: datiInviaNuoveCredenziali.mandato,
//      descMandato:  datiInviaNuoveCredenziali.descMandato,
      mandato: '',
      descMandato:  '',
      esito: '',
      isInvio: false,
      mailMaxSize: null, // numero di utenti a cui in inviare la mail, per volta 
      javaMailAPI: false,
      totalUser: -1,
      processedUser: 0,
    };
  };
  
 handleChangeInviaDatiInviaNuoveCredenziali = event => {
    this.props.salvaDatiInviaNuoveCredenziali(true, actionType.SALVA_DATI_INVIA_NUOVE_CREDENZIALI);
    if(this.state.mandato !== ''){ 
      this.inviaEmail();    	
      this.setState({
	    esito: '', // reset del messaggio informativo
	    isInvio: true,
	  });
    }
  }  
  
 isJavaMailAPI = () => {
	  
	const response = fetch(uris.isJavaMailAPI, fetchParam())
	.then (	
		response => response.json(),
		error => console.error('An error occurred.', error),
    )
	.then((result) => {
		// If request is good update state with fetched data
		const javaMailAPI = (result === true) ;
		this.setState({
		   javaMailAPI : result,
		})		
    });
  }
 
  inviaEmail = () => {	  
	 if (this.state.javaMailAPI) {
    	  // javaMailAPI
    	  this.inviaEmailJavaMailAPI();
      } else {
    	  // sendGridAPI
    	  this.inviaEmailSendGrid();
      }
  }
  
  inviaEmailJavaMailAPI = () => {
	const opts = {
	  codiceMandato: this.state.mandato,
	};
	try{
		// Recupera la lista di utenti cui inviare le email
		const response = fetch(uris.selectUserListInvioMailInizialeCredenziali, fetchParam(opts))
		.then (
		  response => response.json(),
		  error => console.error('An error occurred.', error),
		)
		.then((result) => {
		  // If request is good update state with fetched data
		  const userList = result;		  
	      this.inviaEmailCredenzialiByUserList(userList);
	      this.setState({
	    	  totalUser: userList.length, 	    	  
	      });
		});	
	} catch(e){
		console.error('An error occurred.', e);
	}
  }
  
  // Invio email a blocchi
  inviaEmailCredenzialiByUserList = (userList) => {
	
	if (userList!==null&&userList.length>0) {
		// prepare input for service
		let nextUserList = null;
		
		if (userList!==null&&userList.length>0 &&userList.length>=this.state.mailMaxSize){
			nextUserList=userList.slice(0, this.state.mailMaxSize);
	    } else {
	    	nextUserList=userList.slice(0);
	    }
				
		const opts = {
		  codiceMandato: this.state.mandato,
		  userList: nextUserList,
		};
		
		// Invia le email al gruppo di utenti indicato
		const response = fetch(uris.inviaEmailCredenzialiByUserList, fetchParam(opts))
		.then (
		  response => response.ok,
		  error => console.error('An error occurred.', error),
		)
		.then((result) => {
		  // If request is good update state with fetched data	      
		  this.inviaEmailCredenzialiByUserList(userList.slice(this.state.mailMaxSize));
		  let processedUser = this.state.processedUser + nextUserList.length;
		  this.setState({
		    processedUser: processedUser,
		  });		  
		  
		  console.log('[ZSE] inviaEmailCredenzialiByUserList ... this.state.totalUser: ', this.state.totalUser);
		  if (this.state.totalUser>0) {
		  	let percentage = Math.round((processedUser / this.state.totalUser)*100);
			  this.setState({
			    percentage: percentage,
			  });
		  }
		  
		  if (nextUserList.length===userList.length) {
			  this.setState({
			    isInvio: false,
			    esito: 'OK', // non controllo che li abbia inviati a destinazione
			  });
		  }
		});	
    } else {
    	console.log('Invio Email Credenziali completato');
    }
  }
	  
  inviaEmailSendGrid = () => {
    const opts = {
	  codiceMandato: this.state.mandato,
	};
	fetch(uris.inviaNuoveCredenzialiItems, fetchParam(opts))
	.then (	
	  response => response.ok,
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const esito = (result === true) ? 'OK': 'KO';
	  this.setState({
	    esito: esito,
	  });
	});
  }

  // numero di utenti da trattare alla volta con JavaMail
  loadUserMailMaxSize = (dataOggiFormatta) => {
    const opts = {
	  siglaVersione: 'ZURI',
	  codiceCampo: 'MAIL_MAX_S',
	  dataRiferimentoComune: dataOggiFormatta,
	};
    	   
    fetch(uris.userMailMaxSize, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      // If request is good update state with fetched data
      if (result!==null&&result.length===1) {
    	const mailMaxSize=result[0].value;      
        this.setState({
    	  mailMaxSize: mailMaxSize,
        });
      }
      console.log('[ZSE] componentDidMount result ... ', result);
    });
  }
  
  
  handleChangeMandato = event => {
	const nuovoMandato = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
	const vecchioMandato = textTrim(this.state.mandato, true);
	if (nuovoMandato !== vecchioMandato) {
      this.props.updateDatiInviaNuoveCredenziali(event, actionType.UPDATE_MANDATO_INVIA);   
      this.setState({ 
        mandato: (event === null) ? '' : event.value,
        descMandato: (event === null) ? '' : event.label,
        esito: '',  // reset del messaggio informativo
        totalUser: -1,
      });
      if (nuovoMandato!=='') {
         this.setState({
          isInvio: false, // reset invio button
         });
      }
	}
  };
	  
  isRequiredMandato = () => {
    return true;
  }
    
  render() {
    const { classes, dominio, datiInviaNuoveCredenziali } = this.props;  
    const controlloDati = datiInviaNuoveCredenziali.controlloDati;
    const { t } = this.props; //hoc
    
    return (
     <Fragment>
       <Grid container direction='row' justify='center' alignItems='flex-start'>
         <Grid item xs={2}></Grid>
         <Grid item xs={8}>
	       <Grid container direction='row' justify='center' alignItems='flex-start'>
	         <Grid item xs={12}>
		         <SelectComp onChange={this.handleChangeMandato} options={dominio.mandatoItems} ricerca='comboRicerca' etichetta='mandato' id='mandato' 
			       value={{value: datiInviaNuoveCredenziali.mandato, label: datiInviaNuoveCredenziali.descMandato}} required={this.isRequiredMandato()} controlloDati={controlloDati} />
	         </Grid>  
	         <Grid item xs={12}>
		       <Grid container direction="row" justify="center" alignItems="center">	
		         <Grid item>
		           {this.state.esito==='OK' &&
		    	     <Typography className={classes.textErrorMessage}>{t('operazioneOk')}</Typography>
		           }
		           {this.state.esito==='KO' &&
		    	     <Typography className={classes.textErrorMessage}>{t('ERR_PWD_99')}</Typography>
		           }
		          </Grid>
		        </Grid>
		      </Grid>
		      <Grid item xs={12}>
		      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
		      	  {this.state.totalUser > 0 &&
				    <Grid item xs={12}>
		   				<Progress percent={this.state.percentage} className={classes.progressBar}/>
		   			</Grid>
		      	  }
			      <Grid item> 
			      	<ButtonComp
		             aClassName={classes.button}
		             buttonLabel={'invia'}
		             onClick={this.handleChangeInviaDatiInviaNuoveCredenziali}
		             disabled={this.state.isInvio===true}
		            />
		          </Grid>
		        </Grid>
		      </Grid>
	        </Grid>
	      </Grid>
	      <Grid item xs={2}></Grid>
		</Grid>
      </Fragment>	  
    );
  }
  
  componentDidMount() {
	  const dataOggiFormatta = moment().format('DD/MM/YYYY');
	  if (this.state.mailMaxSize===null) {
		  this.isJavaMailAPI(); // flag che indica se usare sendGrip oppure javaMail
		  this.loadUserMailMaxSize(dataOggiFormatta);
	  }
  }
}

DatiInviaNuoveCredenziali.propTypes = {
  classes: PropTypes.object.isRequired, 
  dominio: PropTypes.object.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiInviaNuoveCredenziali)));
