import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import { uris, fetchParam } from '../../../../uris';
import moment from 'moment';
import 'moment/locale/it';

//import TextFieldComp from '../../../common/TextFieldComp';
import NumberFieldComp from '../../../common/NumberFieldComp';
import RadioButtonComp from '../../../common/RadioButtonComp';
import ButtonComp from '../../../common/ButtonComp';
import SelectComp from '../../../common/SelectComp';

import { radioButtonClassePremioPotenziale, appendiceCostiScelta } from '../../../store/radioButtonStore';

import * as actions from './action/datiPremio';
import * as actionType from './actionType/datiPremio';
import * as actionsDominio from '../../../common/action/dominio';

import { textTrim } from '../../../../utility/genericUtility';
import { PRODOTTO_TIPOLOGIA_MULTINVEST, PRODOTTO_TIPOLOGIA_UNIT_LINKED, 
	     PRODOTTO_MULTINVEST_M111, PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 } from '../../../../utility/jsConstants';


const styles = theme => ({
  textField: theme.textField,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiPremio.mapStateToProps.state', state);
  return {
    datiPremio: state.areaDatiPremioReducer.caratteristicheContratto.datiPremio,
    controlloDati: state.areaDatiPremioReducer.caratteristicheContratto.datiPremio.controlloDati,
    dominio: state.areaDatiDominioReducer.dominio,
    prodotto: state.areaProdottoReducer.prodotto
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiPremio.mapDispatchToProps.dispatch', dispatch);
  return {
	  dominioClassePremioPotenziale: (classePremioPotenzialeItems) => dispatch(actionsDominio.dominioClassePremioPotenziale(classePremioPotenzialeItems)),
	  updateDatiPremio: (unDato, unActionType) => dispatch(actions.updateDatiPremio(unDato, unActionType)),    
  };
};

class DatiPremio extends React.Component {

  constructor(props) {
    super(props);
    const dominio = this.props.dominio;
    const datiPremio = this.props.datiPremio;
    this.state = {
      tariffa: datiPremio.tariffa,
      descTariffa: datiPremio.descTariffa,
      appendiceCosti: datiPremio.appendiceCosti,
      premioUnico: datiPremio.premioUnico,
      classePremioPotenziale: datiPremio.classePremioPotenziale,
      classePremioPotenzialeItems: dominio.classePremioPotenzialeItems,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    };
  };

  //20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
  componentDidMount() {
    const dataOggiFormatta = moment().format('DD/MM/YYYY');
    // Classe di Premio attribuibile solamente al M111 (al momento)
    if(this.isRequiredClassePremioPotenziale()) {
	  this.loadClassePremioPotenzialeItems(dataOggiFormatta);
    }
  }  
  
  loadClassePremioPotenzialeItems = (dataOggiFormatta) => {
	 if (this.state.classePremioPotenzialeItems===undefined || this.state.classePremioPotenzialeItems.length===0 ) {
		// carico la lista
	    const opts = {
	      siglaVersione: 'ZURI',
	      codiceCampo: 'CLPP',
	      dataRichiesta: dataOggiFormatta,
	    };
	    this.setState({
	      loaded: false
	    });
	    fetch(uris.classePremioPotenzialeItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	    // If request is good update state with fetched data
	      this.setState({
		    classePremioPotenzialeItems: result,
	      });
	      console.log('[ZSE] componentDidMount result ... ', result);
		  this.props.dominioClassePremioPotenziale(result);
	    });
	}
  }
  
  handleChangeConfermaDatiPremio = event => {
    this.props.updateDatiPremio(true, actionType.SALVA_DATI_PREMIO);
  }

  handleChangePremioUnico = event => {
    //console.log('#RTCFA DatiPremio handleChangePremioUnico new value:' + event.target.value);
    const nuovoPremioUnico = textTrim(event.target.value, true);
    const vecchioPremioUnico = textTrim(this.state.premioUnico, true);
    if (nuovoPremioUnico !== vecchioPremioUnico) {
      this.props.updateDatiPremio(nuovoPremioUnico, actionType.UPDATE_PREMIO_UNICO);
      this.setState({
        premioUnico: nuovoPremioUnico,
      });
    }
  };

  handleChangeClassePremioPotenziale = event => {
    //console.log('#RTCFA DatiPremio classePremioPotenziale new value:' + event.target.value);
    const nuovaClassePremioPotenziale = textTrim(event.target.value, true);
    const vecchiaClassePremioPotenziale = textTrim(this.state.classePremioPotenziale, true);
    if (nuovaClassePremioPotenziale !== vecchiaClassePremioPotenziale) {
      this.props.updateDatiPremio(nuovaClassePremioPotenziale, actionType.UPDATE_CLASSE_PREMIO_POTENZIALE);
      this.setState({
        classePremioPotenziale: nuovaClassePremioPotenziale,
      });
    }
  };

  isRequiredPremioUnico = () => {
    return true;
  }

  isRequiredTariffa = () => {
    return true;
  }

  isRequiredClassePremioPotenziale = () => {
	 let required = false; 
	 if(this.props.prodotto.tipo === PRODOTTO_TIPOLOGIA_MULTINVEST && 
		      this.props.prodotto.codice === PRODOTTO_MULTINVEST_M111)  {
		 required = true;
	 }
	  
    return required;
  }

  isRequiredAppendiceCostiScelta = () => {
    return true;
  }

  initStatoCampi = () => {
    return [{ id: 'premioUnico', isError: false },
            { id: 'classePremioPotenziale', isError: false },
            { id: 'appendiceCostiScelta', isError: false },
           ];
  }

  handleChangeTariffa = event => {
    const nuovaTariffa = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const vecchiaTariffa = textTrim(this.state.tariffa, true); 
    if (nuovaTariffa !== vecchiaTariffa) {   	  
      this.props.updateDatiPremio(event, actionType.UPDATE_TARIFFA); 
      this.setState({
        tariffa: (event === null) ? '' : event.value,
        descTariffa: (event === null) ? '' : event.label,
      });	
    }
  }  

  handleChangeAppendiceCosti = event => {
    const scelta = event.target.value;
    this.props.updateDatiPremio(scelta, actionType.UPDATE_APPENDICE_COSTI);
    this.setState({
      appendiceCosti: scelta
    })
  }

  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({
      statoCampi: [...campoWithoutId, unErrore],
    });
  }

  render() {
    const { classes, datiPremio, dominio, prodotto } = this.props;
    const { t } = this.props; //hoc    
    const controlloDati = this.props.controlloDati;
    const tipoProdotto = prodotto.tipo;
    const codiceProdotto = prodotto.codice;
    return (
      <Grid container direction='row' justify='center' alignItems='flex-start'>
        <Grid item xs={12}>
          <Grid container direction='row' justify='flex-start' alignItems='center'>
            <Grid item>
              {/*<TextFieldComp onBlur={this.handleChangePremioUnico} aStyle={classes.textField} id='premioUnico' label='premioUnico' value={datiPremio.premioUnico}
                             required={this.isRequiredPremioUnico()} controlloDati={controlloDati} onError={this.handleError} /> */}

              <NumberFieldComp onBlur={this.handleChangePremioUnico} aStyle={classes.textField} id={'premioUnico'} label='premioUnico' value={datiPremio.premioUnico}
                required={true} controlloDati={controlloDati} onError={this.handleError} maximumFractionDigits={2} />

            </Grid>
            <Grid item>
               {/*20230518 PFD: Importo Minomo 20K */}
               {codiceProdotto !== PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 &&  
            	   <Typography variant='caption'>{t('importoPremioUnicoIniziale_20K')}</Typography>
               }
               {/*20230518 PFD: Importo Minomo 30K */}
               {codiceProdotto === PRODOTTO_ZURICH_MISTEP_DYNAMIC_INVEST_EXTRA_M118 && 
            	   <Typography variant='caption'>{t('importoPremioUnicoIniziale_30K')}</Typography>
               }
            </Grid>
          </Grid>
        </Grid>
        {tipoProdotto === PRODOTTO_TIPOLOGIA_MULTINVEST &&
        <Grid item xs={12}>
        {/*   //20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio
        <RadioButtonComp onChange={this.handleChangeClassePremioPotenziale} radioComp={radioButtonClassePremioPotenziale} radioCompDb={dominio.classePremioPotenzialeItems} classes={{divider: classes.divider}} selectedValue={datiPremio.classePremioPotenziale} 
                           required={this.isRequiredClassePremioPotenziale()} controlloDati={controlloDati} id='classePremioPotenziale' onError={this.handleError} /> */}
          <RadioButtonComp onChange={this.handleChangeClassePremioPotenziale} radioComp={this.state.classePremioPotenzialeItems} classes={{divider: classes.divider}} selectedValue={datiPremio.classePremioPotenziale} 
          required={this.isRequiredClassePremioPotenziale()} controlloDati={controlloDati} id='classePremioPotenziale' onError={this.handleError} />
        </Grid>
        }
        {tipoProdotto === PRODOTTO_TIPOLOGIA_UNIT_LINKED &&
        <Grid item xs={12}>
          <Grid item md={6} xs={12}>
            <SelectComp options={dominio.tariffaItems} onChange={this.handleChangeTariffa} etichetta='tariffa' 
            value={{value: datiPremio.tariffa, label: datiPremio.descTariffa}} required={this.isRequiredTariffa()}
            controlloDati={controlloDati} onError={this.handleError} id="selectTariffa"/>
          </Grid>          
        </Grid>
        }
        <Grid item xs={12}>
        	<RadioButtonComp onChange={this.handleChangeAppendiceCosti} radioComp={appendiceCostiScelta} classes={{divider: classes.divider}} selectedValue={datiPremio.appendiceCosti} 
               required={this.isRequiredAppendiceCostiScelta()} controlloDati={controlloDati} id='appendiceCostiScelta' onError={this.handleError} />
        </Grid>
        
        {datiPremio.appendiceCosti === 'S' &&
        <Grid item>
          <Typography className={classes.textErrorMessage}>{t("infoPropostaAppendice")}</Typography>
        </Grid>
        }
        <Grid item xs={12}>
          <Grid container direction='row' justify='center' alignItems='center'>   	
            <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
            <Grid item>
              <ButtonComp
                aClassName={classes.button}
                buttonLabel={t('conferma')}
                onClick={this.handleChangeConfermaDatiPremio}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  isInError(prevProps, prevState) {
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
    });
    console.log('########DatiPremio componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDati !== '')) {
      /*      if (this.props.onError) {
              this.props.onError(this.props.id, isPresenteCampiConErrore);
            }
            if (this.props.onChildrenError) {
              this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
            }      */
      this.props.updateDatiPremio(!isPresenteCampiConErrore, actionType.UPDATE_DATI_PREMIO_VALIDA);
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }
}

DatiPremio.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiPremio)));