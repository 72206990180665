import * as actionType from '../actionType/datiNuovaPassword';
import initialState from '../../../../session/InitialState';

const areaDatiNuovaPasswordReducer = (state = initialState, action) => {
	  
  switch (action.type) {
    case actionType.SALVA_DATI_NUOVA_PASSWORD:
      const gestioneUtenzaDatiNuovaPasswordSalva = {...state}.gestioneUtenza;
      const datiNuovaPasswordSalva = {...gestioneUtenzaDatiNuovaPasswordSalva}.datiNuovaPassword;
      datiNuovaPasswordSalva.controlloDati = action.controlloDati;
      gestioneUtenzaDatiNuovaPasswordSalva.datiNuovaPassword = {...datiNuovaPasswordSalva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiNuovaPasswordSalva,
      };
    case actionType.INVIA_DATI_NUOVA_PASSWORD:
      const gestioneUtenzaDatiNuovaPasswordInvia = {...state}.gestioneUtenza;
      const datiNuovaPasswordInvia = {...gestioneUtenzaDatiNuovaPasswordInvia}.datiNuovaPassword;
      datiNuovaPasswordInvia.utente = action.utente;
      gestioneUtenzaDatiNuovaPasswordInvia.datiNuovaPassword = {...datiNuovaPasswordInvia};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDatiNuovaPasswordInvia,
      };
    case actionType.UPDATE_UTENTE_NUOVA_PASSWORD:
      const gestioneUtenzaDatiNuovaPasswordUtente = {...state}.gestioneUtenza;
      const datiNuovaPasswordUtente = {...gestioneUtenzaDatiNuovaPasswordUtente}.datiNuovaPassword;
      datiNuovaPasswordUtente.utente = action.unDato;
      gestioneUtenzaDatiNuovaPasswordUtente.datiNuovaPassword = {...datiNuovaPasswordUtente};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaDatiNuovaPasswordUtente,
      };
    default:
      return state;
  }
}

export default areaDatiNuovaPasswordReducer;