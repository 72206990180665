import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoFigura from '../sezione/TabDatiRiepilogoFigura';
import CopiaDaAltraFigura from '../sezione/other/CopiaDaAltraFigura';
import DatiGeneraliBenefGiuridico from '../sezione/DatiGeneraliBenefGiuridico';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiRelazContr from '../sezione/DatiRelazContr';
import DatiSocietari from '../sezione/DatiSocietari';
import DatiMandatoFiduciario from '../sezione/DatiMandatoFiduciario';
import DatiAttivitaEconomicaGiuridico from '../sezione/DatiAttivitaEconomicaGiuridico';

import { locale_it, BENEF_MORTE_GIURIDICO } from '../../../utility/jsConstants';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { creaBeneficiarioMorteGiuridico } from '../../../utility/objectUtility';
import { toNumber } from '../../../utility/genericUtility';
import { calcolaPercBenfTotale, 
		 getPercBenefByCodFiscInList, getPercBenefByCognomeInList } from './figureUtils/figureUtils';
import { radioButtonTitolariEffBeneficiari } from '../../store/radioButtonStore';

import RadioButtonComp from '../../common/RadioButtonComp';
import ButtonComp from '../../common/ButtonComp';
import NumberFieldComp from '../../common/NumberFieldComp';
import DividerComp from '../../common/DividerComp';

import * as actions from './action/beneficiarioMorte';
import * as actionType from './actionType/beneficiarioMorte';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA BenefMorteGiuridicoAss.mapStateToProps.state', state);
  return {
    benefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,
    salvaBenefMorteGiuridicoSuccess: state.areaBenefMorteReducer.salvaBenefMorteGiuridicoSuccess,
    controlloDati: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA BenefMorteGiuridicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
    confermaAggiungiBeneficiarioMorteGiuridico: (currBenefMorteGiuridico) => dispatch(actions.confermaAggiungiBeneficiarioMorteGiuridico(currBenefMorteGiuridico)),
    nuovoBeneficiarioMorteGiuridico: (currBenefMorteGiuridico) => dispatch(actions.nuovoBeneficiarioMorteGiuridico(currBenefMorteGiuridico)),
    updateDatiBeneficiarioMorte: (unDato, unActionType) => dispatch(actions.updateDatiBeneficiarioMorte(unDato, unActionType)),
    updateBeneficiarioMorteGiuridico: (unDato, unActionType) => dispatch(actions.updateBeneficiarioMorteGiuridico(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class BenefMorteGiuridicoAss extends React.Component {
  constructor(props) {
    super(props);
    console.log('#RTCFA BenefMorteGiuridicoAss constructor props', this.props);
    const datiCopiaDaAltraFigura = props.benefMorteGiuridico.datiCopiaDaAltraFigura;
    const datiRelazContr = props.benefMorteGiuridico.datiRelazContr;    
    this.state = {
    	percBeneficiario: 0,
    	percentualeTotale: 0,
    	isConfermaBenef: false,
    	isNuovoBenef: false,
    	figuraFrom: datiCopiaDaAltraFigura.figuraFrom,
        isCopiaDaAltraFigura: datiCopiaDaAltraFigura.flCopiaDaAltraFigura,
        flEsclusioneComunicaz: datiRelazContr.esclusioneComunicaz,
        statoCampi: this.initStatoCampi(),
        isPresenteErrore: null,
    }   
  };
  
/*  getEmptyBenefMorteGiuridico = () => {
	  const emptyBenefMorteGiuridico = {
	  	  controlloDati: '',
		  anagraficaValida: null,
		  
	 	  datiFigureAnagrBase: {
			ruolo: RUOLO_BENEF_MORTE,
			tipoPers: PERSONA_GIURIDICA,
		   },
	    	datiGeneraliBenefGiuridico: {
	        datiGenerali: {},
	      },
	      datiResidenza: {},
	      datiRelazContr: {},
	      datiCopiaDaAltraFigura: {
	    	  flCopiaDaAltraFigura: 'N',
	      },
	    };
	  
	  return emptyBenefMorteGiuridico;	  
  }
  */
  
  handleClickNuovoBenefMorte = event => {
//    console.log('#RTCFA BenefMorteGiuridicoAss:handleClickNuovoBenefMorte new value:', event.target.value);
//  const emptyBenefMorte = this.getEmptyBenefMorteGiuridico();
    const emptyBenefMorte = creaBeneficiarioMorteGiuridico();
    this.props.nuovoBeneficiarioMorteGiuridico(emptyBenefMorte);
//    this.props.updateDatiBeneficiarioMorte(false, actionType.SALVA_BENEF_MORTE_GIURIDICO_SUCCESS);
    this.setState({
      isConfermaBenef : false,
      isNuovoBenef: true,
      isPresenteErrore: null,
      flEsclusioneComunicaz: undefined,
	});
  }
  
  handleClickConfermaBenefMorte = (event) => {
	console.log('#RTCFA BenefMorteFisicoAss:handleClickConfermaBenefMorte new value:', event.target.value);
    this.props.updateDatiBeneficiarioMorte(true, actionType.SALVA_BENEF_MORTE_GIURIDICO);
    this.props.updateDatiBeneficiarioMorte(false, actionType.SALVA_BENEF_MORTE_GIURIDICO_SUCCESS);
    this.setState({
      isConfermaBenef: true,
      isNuovoBenef: false,
    });
  }

  handleChangeTitolareEffBenef = event => {
    const flTitolareEffBenef = event.target.value;
    this.props.updateDatiBeneficiarioMorte(flTitolareEffBenef, actionType.TITOLARI_EFFETTIVI_BENEFICIARI_MORTE);
    this.setState({
      flTitolareEffBenef: flTitolareEffBenef,
    }) 
  }

  
  confermaBeneficiario = () => {
    const currBenef = this.props.benefMorteGiuridico;
    const percTotaleFigure = calcolaPercBenfTotale(this.props.benefMorteFisicoList, this.props.benefMorteGiuridicoList, this.props.benefMorteGenericoList, locale_it);
    let percTotale = percTotaleFigure;
	let percBenefInLista = undefined;
	let isNuovo = false;
	let benefMorteFromCopiaDaAltraFigura = undefined; 
    if (this.state.isConfermaBenef) {
      if (currBenef.anagraficaValida === true) {    		
        if (currBenef.datiCopiaDaAltraFigura.flCopiaDaAltraFigura!== 'S') {				
          // Trova per Codice Fiscale
          percBenefInLista = getPercBenefByCodFiscInList(this.props.benefMorteGiuridicoList, currBenef, locale_it);
          // Edit figura corrente
          if (percBenefInLista === undefined) {			
            isNuovo = true;
            percBenefInLista = 0;
          }
        } else {
          const descrFiguraFrom = currBenef.datiCopiaDaAltraFigura.descrFiguraFrom;
          currBenef.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale = descrFiguraFrom;
          // cerco se presente
          percBenefInLista = getPercBenefByCognomeInList(this.props.benefMorteGiuridicoList, currBenef);
          // nuova figura
          if (percBenefInLista === undefined) {
            isNuovo = true;
            percBenefInLista = 0;
            benefMorteFromCopiaDaAltraFigura = {...currBenef};
            // reset alcune sezioni
            benefMorteFromCopiaDaAltraFigura.datiGeneraliBenefGiuridico.datiGenerali = {};
            benefMorteFromCopiaDaAltraFigura.datiGeneraliBenefGiuridico.datiGenerali.ragioneSociale = descrFiguraFrom;
            benefMorteFromCopiaDaAltraFigura.datiResidenza = {};
          }
        }
        const percentualeBenefCorrente = currBenef.datiGeneraliBenefGiuridico.percBeneficiario;
        const percBenefCorrente = toNumber(percentualeBenefCorrente, locale_it);
        if (percBenefCorrente !== undefined && percBenefCorrente > 0) {
        	// 2022-01-06 PFD: ricalcolo il totale con arrotondamento alla seconda cifra decimale
            //percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista);
            percTotale = (percTotaleFigure + percBenefCorrente - percBenefInLista).round(2);
          if (percTotale<=100.0) {
            const benificiario = benefMorteFromCopiaDaAltraFigura !== undefined ? benefMorteFromCopiaDaAltraFigura : currBenef;
            // INSERIMENTO NUOVO BENEFICIARIO
            if (isNuovo === true) {
              this.props.confermaAggiungiBeneficiarioMorteGiuridico(benificiario);
            } else {
              // MODIFICA BENEFICIARIO
              this.props.updateBeneficiarioMorteGiuridico(benificiario, actionType.UPDATE_BENEF_MORTE_GIURIDICO);
            }
            // Aggiorno la percentuale
            this.props.updateDatiBeneficiarioMorte(percTotale, actionType.UPDATE_BENEF_MORTE_PERCENTUALE_TOTALE);
            this.props.updateDatiBeneficiarioMorte(true, actionType.SALVA_BENEF_MORTE_GIURIDICO_SUCCESS);
            
            //2021-07-01 PFD: TCM_INDIV
            this.handleTcmIndividualeValida();
          }			
        }
      }
    }
    this.setState({
      isConfermaBenef: false,
      percentualeTotale : percTotale,
    });
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  //funzione callback 
  handleChangeEsclusioneComunicaz = (value) => {
    this.setState({
      flEsclusioneComunicaz: value,
    });
  }
  
  // funzione callback 
  handleChangeFlCopiaDaAltraFigura = (value) => {	 
    this.setState({
      isCopiaDaAltraFigura: value,
      statoCampi: this.initStatoCampi(),
    });
  }
  
  // funzione callback
  handleChangeFiguraFrom = figuraFrom => {
    this.setState({
      figuraFrom: figuraFrom,
    });
  }
  
  handlePercBeneficiario = event => {
    console.log('#RTCFA BenefMorteGiuridicoAss percBeneficiario new value:' + event.target.value);	
    this.props.updateDatiBeneficiarioMorte(event.target.value, actionType.UPDATE_PERCENTUALE_BENEF_MORTE_GIURIDICO); 	      
    this.setState({
      percBeneficiario: event.target.value,
    });	
  }

  requiredTitolareEffettivoBenef = () =>{
    return this.props.scopeAmleto;
  }
  
  initStatoCampi = () => {
	if (this.props.benefMorteGiuridico.datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S') {
	    return [ {id: 'datiGeneraliBenefMorteGiuridico', isError: false},
               {id: 'datiResidenzaBenefMorteGiuridico', isError: false},  
               {id: 'datiAttivitaEconomicaBenefMorteGiuridico', isError: false},
	           ];
	} else {		
		return [ {id: 'copiaDaAltraFiguraBenefMorteGiuridico', isError: false},
 			     {id: 'percBeneficiario', isError: false},
            {id: 'datiRelazContrBenefMorteGiuridico', isError: false},
            {id: 'datiSocietariBenefMorte', isError: false},
            {id: 'datiMandatoFiduciarioBenefMorte', isError: false},
            {id: 'datiAttivitaEconomicaBenefMorteGiuridico', isError: false},
 		       ];
	}
  }

  isDisabledTitolareEffettivoBenef = () => {
    return this.props.scopeAmleto === false;
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  render() {
//    console.log('#RTCFA BenefMorteGiuridicoAss this.props.benefMorteGiuridico', this.props.benefMorteGiuridico);
    const { classes, t, benefMorteGiuridico } = this.props;  
    const currentBenef = benefMorteGiuridico;
    const datiGeneraliBenef = currentBenef.datiGeneraliBenefGiuridico;
    const datiSocietariBenef = currentBenef.datiSocietari;
    const datiMandatoBenef = currentBenef.datiMandatoFiduciario;
    const datiCopiaDaAltraFigura = currentBenef.datiCopiaDaAltraFigura;  
    const percentualeTotaleError = this.state.percentualeTotale!==0 && parseFloat(this.state.percentualeTotale)!==100.0;
	const controlloDati = this.props.controlloDati;
	const isNuovo = this.state.isNuovoBenef;
    const salvaBenefMorteGiuridicoSuccess = this.props.salvaBenefMorteGiuridicoSuccess;
	const percBenefInLista = getPercBenefByCognomeInList(this.props.benefMorteGiuridicoList, currentBenef);
	const isCopiaReadonly = percBenefInLista!==undefined;

    return (
      <Fragment>
      	<TabDatiRiepilogoFigura figura={'beneficiarioMorte'} tabHeader={'tabHeaderBenMorte'} />
       	<CopiaDaAltraFigura id={'copiaDaAltraFiguraBenefMorteGiuridico'} 
			figura={BENEF_MORTE_GIURIDICO} datiCopiaDaAltraFigura={datiCopiaDaAltraFigura} 
	  		handleChangeFlCopiaDaAltraFigura={this.handleChangeFlCopiaDaAltraFigura} 
	  	    handleChangeFiguraFrom={this.handleChangeFiguraFrom} 
	  	    onError={this.handleError}
			readonly={isCopiaReadonly} />
      	{datiCopiaDaAltraFigura.flCopiaDaAltraFigura !== 'S' &&   
         <Fragment>
           <DatiGeneraliBenefGiuridico figura={BENEF_MORTE_GIURIDICO} datiGeneraliBenefMorteGiuridico={datiGeneraliBenef} labelFieldSet='datiGenerali' 
                                       id={'datiGeneraliBenefMorteGiuridico'} onError={this.handleError} />
           <DatiSocietari figura={BENEF_MORTE_GIURIDICO} labelFieldSet='datiSocietari' datiSocietari={datiSocietariBenef} onError={this.handleError}
                          id='datiSocietariBenefMorte' />

           <DatiResidenza figura={BENEF_MORTE_GIURIDICO} datiGeneraliBenefMorteGiuridico={currentBenef.datiResidenza} labelFieldSet='datiDiResidenzaGiuridico' flEsclusioneComunicazBenef={this.state.flEsclusioneComunicaz}
                          id={'datiResidenzaBenefMorteGiuridico'} onError={this.handleError} />
                          
           <DatiAttivitaEconomicaGiuridico figura={BENEF_MORTE_GIURIDICO} datiAttEconomicaGiuridico={benefMorteGiuridico.datiAttEconomicaGiuridico}
                                           statoResidenza={this.state.statoResidenza} id={'datiAttivitaEconomicaBenefMorteGiuridico'} onError={this.handleError} />   
           
           <DatiRelazContr figura={BENEF_MORTE_GIURIDICO} datiRelazContrBenefMorteGiuridico={currentBenef.datiRelazContr} handleChangeEsclusioneComunicaz={this.handleChangeEsclusioneComunicaz}
                        id={'datiRelazContrBenefMorteGiuridico'} onError={this.handleError} relazioneConContr={'relazContrBenefMorte'} /> 
           
           <DatiMandatoFiduciario figura={BENEF_MORTE_GIURIDICO} onError={this.handleError} id='datiMandatoFiduciarioBenefMorte' 
                                  datiMandatoFiduciario={datiMandatoBenef} labelFieldSet='datiMandatoFiduciario' />
         </Fragment>
      	}
      	{datiCopiaDaAltraFigura.flCopiaDaAltraFigura==='S' &&
          <Fragment>
            <DividerComp titolo='&nbsp;' variant='subtitle1' />
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12}>
                <NumberFieldComp onBlur={this.handlePercBeneficiario} aStyle={classes.textField} id={'percBeneficiario'} label='percBeneficiario' format='###,###' 
                                 value={datiGeneraliBenef.percBeneficiario} maxLength='7' required={true} controlloDati={controlloDati} onError={this.handleError}/>
              </Grid>
            </Grid>
          </Fragment>
      	}
    	
    	{/* <DividerComp titolo='titolareEffBeneficiario' variant='subtitle1' />
        <Grid>
          <RadioButtonComp selectedValue={currentBenef.flTitolareEffBenef} controlloDati={controlloDati} onError={this.handleError} id='titolareEffettivoBeneficiario'
                           onChange={this.handleChangeTitolareEffBenef} classes={{divider: classes.divider}} radioComp={radioButtonTitolariEffBeneficiari}
                           required={this.requiredTitolareEffettivoBenef()} disabled={this.isDisabledTitolareEffettivoBenef()} />
        </Grid> */}
    
    	
    	<Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center'>            	
              <Grid item>
              {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
              }
              {(controlloDati === true) && !this.state.isPresenteErrore && percentualeTotaleError && !isNuovo && /* (salvaBenefMorteGiuridicoSuccess === true) && */
                <Typography className={classes.textErrorMessage}>{t('errTotPercBenef')}</Typography>
              }
              {(controlloDati === true) && (this.state.isPresenteErrore === false)  && (salvaBenefMorteGiuridicoSuccess === true) &&
                <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
              }
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ButtonComp
               /* to={linkTo} */
               /* component={Link} */
               aClassName={classes.button}
               buttonLabel={t('conferma')}
               onClick={this.handleClickConfermaBenefMorte}
            />
          </Grid>
          <Grid item>
            <ButtonComp
               /* to={linkTo} */
               /* component={Link} */
               aClassName={classes.button}
               buttonLabel={t('nuovoBenefic')}
               onClick={this.handleClickNuovoBenefMorte}
            />
          </Grid>
	    </Grid>       
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########BenefMorteGiuridicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########BenefMorteGiuridicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########BenefMorteGiuridicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDati !== '')) {
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiBeneficiarioMorte(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_BENEF_MORTE_GIURIDICO);
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });      
	}
	return isPresenteCampiConErrore;
  }

  isConfermaBenef = (prevProps, prevState, isPresenteCampiConErrore) => {   
	//const currIsPresenteErrore = this.state.isPresenteErrore;
	const currIsPresenteErrore = isPresenteCampiConErrore;
	const currIsConferma =  this.state.isConfermaBenef;	
	const prevIsPresenteErrore = prevState.isPresenteErrore;
	const prevIsConferma =  prevState.isConfermaBenef;
	if (currIsPresenteErrore!==undefined && currIsPresenteErrore!==null && currIsPresenteErrore===false) {
      if (currIsConferma) {
        this.confermaBeneficiario();
      }
	}
    if (currIsConferma !== prevIsConferma) {
      this.setState({isConfermaBenef: false,})
    }
  }
  
  isFlgEsclusioni = (prevProps, prevState) => {   
	const currflEsclusioneComunicaz = this.props.benefMorteGiuridico.datiRelazContr.esclusioneComunicaz;
	const prevflEsclusioneComunicaz = this.state.flEsclusioneComunicaz;
	if (currflEsclusioneComunicaz!==undefined && currflEsclusioneComunicaz!==null && currflEsclusioneComunicaz !== prevflEsclusioneComunicaz) {
      this.setState({
        flEsclusioneComunicaz: currflEsclusioneComunicaz,
      });
	}
  }

  componentDidUpdate(prevProps, prevState) {
    const isPresenteCampiConErrore = this.isInError(prevProps, prevState);
    this.isConfermaBenef(prevProps, prevState, isPresenteCampiConErrore);
    this.isFlgEsclusioni(prevProps, prevState); // allineo lo stato con le props    
  }
}

BenefMorteGiuridicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  benefMorteGiuridico: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BenefMorteGiuridicoAss)));
