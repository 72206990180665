import * as actionType from '../actionType/login';
import initialState from '../../../session/InitialState';

const areaLoginReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionType.LOGIN:
      const areaLogin = {...state}.areaLogin;
      areaLogin.user = action.user;
      areaLogin.password = action.password;
      areaLogin.userpassword = action.user + action.password;
      return { 
    	...state,
    	areaLogin: areaLogin,
      };
    case actionType.UPDATE_UTENTE_LOGIN:
      const areaLoginUtente = {...state}.areaLogin;
      areaLoginUtente.user = action.unDato;
      return {
        ...state,
        areaLogin: areaLoginUtente,
      };
    case actionType.UPDATE_UTENTE_PASSWORD:
      const areaLoginPassword = {...state}.areaLogin;
      areaLoginPassword.password = action.unDato;
      return {
        ...state,
        areaLogin: areaLoginPassword,
      };
    default:
      return state;
  }
}

export default areaLoginReducer;