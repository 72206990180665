import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TextFieldComp from '../../../common/TextFieldComp';

import * as actions from './action/datiQuestionario';
import * as actionType from './actionType/datiQuestionario';

const styles = theme => ({
  testo: {
   marginTop: theme.spacing(2),
  },
});

const mapStateToProps = state => {
  return {
    datiQuestionarioAdeguatezzaFisico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico,
    datiQuestionarioAdeguatezzaGiuridico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaGiuridico,
    datiQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico,
    datiQuestionarioAnamnesticoMalattiaGrave: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave,
    datiQuestionarioAnamnesticoSemplificato: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato,
  };
};

//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiQuestionarioAdeguatezzaFisico.mapDispatchToProps.dispatch', dispatch);
  return {
	updateDatiQuestionario: (unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario) => dispatch(actions.updateDatiQuestionario(unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario)),
  };
};
	
class TextFieldQuestionario extends React.Component {
	
  handleChangeRispostaTesto = idTesto => event => {
    this.props.updateDatiQuestionario(event.target.value, this.props.numDomanda, this.props.idRisposta, idTesto, actionType.UPDATE_TEXTFIELD, this.props.tipoQuestionario);   
  };
  
  getTestoByIdTesto = (idTesto, numDomanda, idRisposta) => {
	  let datiQuestionario = null;
	  if(this.props.tipoQuestionario === 'questionarioAdeguatezzaFisico'){
          datiQuestionario = this.props.datiQuestionarioAdeguatezzaFisico.questionarioAdeguatezzaFisico;
        }else if(this.props.tipoQuestionario === 'questionarioAdeguatezzaGiuridico'){
          datiQuestionario = this.props.datiQuestionarioAdeguatezzaGiuridico.questionarioAdeguatezzaGiuridico;
        }else if(this.props.tipoQuestionario === 'questionarioAnamnestico'){
          datiQuestionario = this.props.datiQuestionarioAnamnestico.questionarioAnamnestico;
        }else if(this.props.tipoQuestionario === 'questionarioAnamnesticoMalattiaGrave'){
          datiQuestionario = this.props.datiQuestionarioAnamnesticoMalattiaGrave.questionarioAnamnesticoMalattiaGrave;
        }else {
          datiQuestionario = this.props.datiQuestionarioAnamnesticoSemplificato.questionarioAnamnesticoSemplificato;
        }
	  
    let testo = '';
    datiQuestionario.forEach(function(itemSezione, indexSezione, array) {
        itemSezione.domande.forEach(function(itemDomanda, indexDomanda, array) {
    	    if(itemDomanda.numDomanda === numDomanda){
    		  itemDomanda.risposte.forEach(function(itemRisposta, indexRisposte, array) {
    			if(idRisposta===null || idRisposta===''){//risposte dirette
    				if(itemRisposta.id === idTesto){
    					testo = itemRisposta.testoSelezionato;
    				}
    			}else{//risposte condizionate
    			  if(itemRisposta.id === idRisposta){
        	        itemRisposta.risposteCondizionate.forEach(function(itemRispostaCondizionata, indexRisposteCondizionata, array) {
        	          if(itemRispostaCondizionata.id === idTesto){
        	        	  testo = itemRispostaCondizionata.testoSelezionato;
        	          }
        	        });
        	      }
    			}  
    		  });
    	    }
        });
      });
    return testo;
  }
    
  render() {
    const { classes, risposte, aStyle, required, direction, numDomanda, idRisposta } = this.props;
    
    return (
     <Grid container direction='column' justify="flex-start" alignItems='flex-start'>
        {risposte.map((risposta, index) => (
          <Grid key={index} item xs={12}>
            <Grid container direction={'vertical' === direction ? 'column' : 'row'} justify="flex-start" alignItems={'vertical' === direction ? 'flex-start' : 'center'}>
              <Grid key={index + 'titolo'} item xs={'vertical' === direction ? 12 : 5} className={(risposta.tipo === 'X' || risposta.tipo === 'T'  || risposta.tipo === '') ? classes.testo : ''}>
                <Typography variant="caption">{risposta.testo}</Typography>
              </Grid>
                {risposta.tipo !== 'X' &&
                  <Grid key={index + 'campo'} item xs={'vertical' === direction ? 12 : 7} >
                    <TextFieldComp
                      id={risposta.id}
                      label=''	 
                      aStyle={aStyle}			 
                      value={this.getTestoByIdTesto(risposta.id, numDomanda, idRisposta)}
                      onBlur={this.handleChangeRispostaTesto(risposta.id)}
                      required={required}
                    />
                  </Grid>
                }
            </Grid> 
          </Grid> 
        ))} 
	   </Grid>
	);
  }
}

TextFieldQuestionario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TextFieldQuestionario));

