import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import RadioButtonQuestionario from './RadioButtonQuestionario';
import CheckBoxQuestionario from './CheckBoxQuestionario';
import TextTestoQuestionario from './TextFieldQuestionario';

import * as actions from './action/datiQuestionario';
import * as actionType from './actionType/datiQuestionario';

const styles = theme => ({
  risposteCondizionate: {
    width:'95%',
/*	marginLeft: theme.spacing(9), */
	paddingLeft: theme.spacing(9),
/*	paddingRight: theme.spacing(3), */
  },
  textField: theme.textField,
});

const mapStateToProps = state => {
  return {
    datiQuestionarioAdeguatezzaFisico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico,
  };
};

//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  return {
	  updateDatiQuestionario: (unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario) => dispatch(actions.updateDatiQuestionario(unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario)),
  };
};
	
class RisposteCondizionateQuestionario extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {

		};
	};
	
	handleChangeRispostaRadio = event => {
	  this.props.updateDatiQuestionario(event.target.value, this.props.numDomanda, this.props.idRisposta, null, actionType.UPDATE_RADIO_SELECTION, this.props.tipoQuestionario);       
	};
	
	handleChangeRispostaCheck = event => {
		this.props.updateDatiQuestionario(event.target.value, this.props.numDomanda, this.props.idRisposta, null, actionType.UPDATE_CHECKBOX_SELECTION, this.props.tipoQuestionario);       
	};
	
  render() {
    const { classes, risposte, numDomanda, idRisposta, mostraRisposteCondizionate, tipoQuestionario } = this.props;  
    
    let tipoTutte = '-';
    let risposteTutte = [];
  
    let rispostaConTipo = {
          tipo: '',
    	  risposte: [],
    	};

    let rispostePerTipo = [];
    
    if(mostraRisposteCondizionate === 'S'){
      risposte.forEach(function(item, index, array) {
        if('-' === tipoTutte){
    	  tipoTutte = item.tipo;
    	}
    	if(tipoTutte!==item.tipo){
    	  rispostaConTipo = {
		    tipo: tipoTutte,
		    risposte: risposteTutte,
		  };
    	  rispostePerTipo.push(rispostaConTipo);
		  risposteTutte = [];
		  risposteTutte.push(item);
		  tipoTutte = item.tipo;
		  if(index===(risposte.length - 1)){
		    rispostaConTipo = {
		      tipo: tipoTutte,
		      risposte: risposteTutte,
		    };
		    rispostePerTipo.push(rispostaConTipo);
		  }
    	}else{
    	  risposteTutte.push(item);
    	  if(index===(risposte.length - 1)){
    	    rispostaConTipo = {
    		  tipo: tipoTutte,
    		  risposte: risposteTutte,
    		};
    		rispostePerTipo.push(rispostaConTipo);
    	  }
        }
	  });
    }
	  
    return ( 
      <div className={classes.risposteCondizionate}>
	    {mostraRisposteCondizionate === 'S' &&
		  rispostePerTipo.map((elemento, index) => (	
	        <div key={index}>
	          {elemento.tipo==='R' && <RadioButtonQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta={idRisposta} onChange={this.handleChangeRispostaRadio} tipoQuestionario={tipoQuestionario} />}
	          {elemento.tipo==='C' && <CheckBoxQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} onChange={this.handleChangeRispostaCheck} />}
	          {elemento.tipo==='T' && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
	          {elemento.tipo==='N' && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
	          {elemento.tipo==='X' && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
	          {elemento.tipo===''  && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta={idRisposta} tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
	        </div>
		  ))
	    }
	  </div>
    );
  }
}

RisposteCondizionateQuestionario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RisposteCondizionateQuestionario));

