import * as actionType from '../actionType/datiModPagCarta';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { datiModPagCartaInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';
import { jsonCopy } from '../../../../../utility/genericUtility';

const areaDatiModPagCartaReducer = (state = initialState, action) => {

    const caratteristicheContratto = {...state}.caratteristicheContratto;
    const datiModPagCarta = {...caratteristicheContratto}.datiModPagCarta;

    switch (action.type) {
        case actionTypeGeneric.INIZIALIZZA:
            const caratteristicheContratto_INI = inizializzaModPagCarta(state);
            return {
                ...state,
                caratteristicheContratto: caratteristicheContratto_INI,
            };
        case actionType.UPDATE_INTESTATARIO_CARTA:
            datiModPagCarta.intestatario = action.unDato;
            return {
                ...state,
                datiModPagCarta
            }
        default:
            return state
    }
}

export const inizializzaModPagCarta = (state) => {
    const caratteristicheContratto_INI = { ...state }.caratteristicheContratto;
    caratteristicheContratto_INI.datiModPagCarta = jsonCopy(datiModPagCartaInizializzato);
    return caratteristicheContratto_INI;
}

export default areaDatiModPagCartaReducer;