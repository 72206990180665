import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { uris, fetchParam } from '../../../uris';
//import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import DividerComp from '../../common/DividerComp';
import DatePickerComp from '../../common/DatePickerComp';

import { radioButtonEspostoPoliticamente, radioButtonEspostoPoliticamenteInCarica } from '../../store/radioButtonStore';

import * as actions from '../figure/action/contraente';

import * as actionType from '../figure/actionType/contraente';
import * as actionsAssic from '../figure/action/assicurato';
import * as actionTypeAssic from '../figure/actionType/assicurato';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionsBenefVitaFisico from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVitaFisico from '../figure/actionType/beneficiarioVita';
import * as actionsBenefMorteFisico from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorteFisico from '../figure/actionType/beneficiarioMorte';

import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

import moment from 'moment';
import 'moment/locale/it';

import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { CONTRAENTE_FISICO, ASSICURATO_FISICO, ESECUTORE_FISICO, 
	     RAPPRESENTANTE_LEGALE, TITOLARE_EFFETTIVO, BENEF_VITA_FISICO, BENEF_MORTE_FISICO, 	     
	     PROVINCIA_ESTERA, CODICE_ALTRA_QUALIFICA, CODICE_PERC_QUALIFICA_ANTIRICICLAGGIO, 
	     CODICE_QUALIFICA_SOCIO_PROPRIETARIO, TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA, 
	     locale_it } from '../../../utility/jsConstants';
import NumberFieldComp from '../../common/NumberFieldComp';


const styles = theme => ({
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiAntiriciclaggioContr: state.areaContraenteReducer.contraenteFisico.datiAntiriciclaggio,
//    datiAttivitaEconomica: state.areaContraenteReducer.contraenteFisico.datiAttEconomica,
//    datiAttivitaEconomicaRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiAttEconomica,
    controlloDatiAntiriciclaggioContr: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore,
    controlloDatiAntiriciclaggioEsecut: state.areaContraenteReducer.contraenteFisico.controlloDati,
    datiAntiriciclaggioRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiAntiriciclaggio,
    controlloDatiAntiriciclaggioRappLegale: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    datiAntiriciclaggioTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiAntiriciclaggio,
    controlloDatiAntiriciclaggioTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
    datiAntiriciclaggioTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiAntiriciclaggio,
    controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
    datiAntiriciclaggioTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiAntiriciclaggio,
    controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
    datiAntiriciclaggioAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.datiAntiriciclaggio,
//    datiAttivitaEconomicaAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.datiAttEconomica,
    controlloDatiAntiriciclaggioAssic: state.areaAssicuratoReducer.assicuratoFisico.controlloDati,
//    datiAttivitaEconomicaTitEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiAttEconomica,
//    datiAttivitaEconomicaEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore.datiAttEconomica,
    coontrolloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore.controlloDati,
    datiTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff,
    datiTitolareEffMorte: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff,
    datiTitolareEffVita: state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff,
    datiAntiriciclaggioBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.datiAntiriciclaggio,
	controlloDatiAntiriciclaggioBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,	
	datiAntiriciclaggioBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiAntiriciclaggio,
	controlloDatiAntiriciclaggioBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiAntiriciclaggio: (unDato, unActionType, figura) => dispatch(actions.updateDatiAntiriciclaggio(unDato, unActionType, figura)),
    updateDatiAntiriciclaggioAssicurato: (unDato, unActionType, figura) => dispatch(actionsAssic.updateDatiAntiriciclaggioAssicurato(unDato, unActionType, figura)),
    updateDatiAntiriciclaggioPerContraente: (unDato, unActionType) => dispatch(actions.updateDatiAntiriciclaggioPerContraente(unDato, unActionType)),
    updateDatiAntiriciclaggioTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiAntiriciclaggioTitolareEff(unDato, unActionType)),
    updateDatiAntiriciclaggioBenefVitaFisico: (unDato, unActionType) => dispatch(actionsBenefVitaFisico.updateDatiAntiriciclaggioBenefVitaFisico(unDato, unActionType)),
    updateDatiAntiriciclaggioBenefMorteFisico: (unDato, unActionType) => dispatch(actionsBenefMorteFisico.updateDatiAntiriciclaggioBenefMorteFisico(unDato, unActionType)),
    updateDatiAttEconomica: (unDato, unActionType, figura) => dispatch(actions.updateDatiAttEconomica(unDato, unActionType, figura)),
    updateDatiAttEconomicaTitolareEff: (unDato, unActionType, figura) => dispatch(actionsTitolareEff.updateDatiAttEconomicaTitolareEff(unDato, unActionType)),
    updateDatiAttEconomicaAssFis: (unDato, unActionType) => dispatch(actionsAssic.updateDatiAttEconomicaAssicuratoFisico(unDato, unActionType)),
    updateDatiFiscaliTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiFiscaliTitolareEff(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};

class DatiAntiriciclaggio extends React.Component {

  constructor(props) {
    super(props);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    //    const dataRilascioFormattata = datiAntiriciclaggio && datiAntiriciclaggio.dataRilascio ? (datiAntiriciclaggio.dataRilascio).format('DD/MM/YYYY') : '';
    this.state = {
      tipoDocumento: datiAntiriciclaggio.tipoDocumento,
      descrTipoDocumento: datiAntiriciclaggio.descrTipoDocumento,
      numeroDocumento: datiAntiriciclaggio.numeroDocumento,
      enteRilascio: datiAntiriciclaggio.enteRilascio,
      descrEnteRilascio: datiAntiriciclaggio.descrEnteRilascio,
      dataRilascio: datiAntiriciclaggio.dataRilascio,
      dataScadenza: datiAntiriciclaggio.dataScadenza,
      statoRilascio: datiAntiriciclaggio.statoRilascio,
      descrStatoRilascio: datiAntiriciclaggio.descrStatoRilascio,
      provinciaRilascio: datiAntiriciclaggio.provinciaRilascio,
      descrProvinciaRilascio: datiAntiriciclaggio.descrProvinciaRilascio,
      comuneRilascio: datiAntiriciclaggio.comuneRilascio,
      descrComuneRilascio: datiAntiriciclaggio.descrComuneRilascio,
      espostaPoliticamente: datiAntiriciclaggio.espostaPoliticamente,
      isInCarica: datiAntiriciclaggio.isInCarica,
      caricaPersonaEsposta: datiAntiriciclaggio.caricaPersonaEsposta,
      caricaPubblicaNoPEP: datiAntiriciclaggio.caricaPubblicaNoPEP,
//      codAttPrevalSvolta: datiAntiriciclaggio.codAttPrevalSvolta,
//      descrAttPrevalSvolta: datiAntiriciclaggio.descrAttPrevalSvolta,
      nazioneItems: [],
      provinciaItems: [],
      comuneItems: [],
      qualificaItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
      datiTitolareEff: this.props.datiTitolareEff,
      qualifica: datiAntiriciclaggio.qualifica,      
      descrQualifica: datiAntiriciclaggio.descrQualifica,
    };
  };

  componentDidMount() {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const dataRilascioFormattata = datiAntiriciclaggio && datiAntiriciclaggio.dataRilascio ? dataFormat(datiAntiriciclaggio.dataRilascio, 'DD/MM/YYYY') : '';
    this.setNazioni(dataRilascioFormattata);
    this.setProvince(dataRilascioFormattata, datiAntiriciclaggio ? datiAntiriciclaggio.statoRilascio : '');
    this.setComuni(datiAntiriciclaggio ? datiAntiriciclaggio.provinciaRilascio : '', dataRilascioFormattata);
    const opts = {
      siglaVersione: 'ZURI',
      dataRichiesta: moment().format("DD/MM/YYYY"),
      codiceCampo: 'QLTE'
    }
    fetch(uris.qualificaItems, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An Error occured...", error)
    )
    .then((result) => {
      this.setState({
        qualificaItems: result
      })
    });
  }

  datiAntiriciclaggioFrom = figura => {
    let datiAntiriciclaggio = null;
    if (figura === CONTRAENTE_FISICO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioContr;
    } else if (figura === ESECUTORE_FISICO) {
      datiAntiriciclaggio = this.props.datiEsecutore.datiAntiriciclaggio; 
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioRappLegale;
    } else if (figura === TITOLARE_EFFETTIVO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioTitolareEff;
    } else if (figura === ASSICURATO_FISICO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioAssicuratoFisico;
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioRappLegale
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioTitEffMorte
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioTitEffVita
    } else if (figura === BENEF_VITA_FISICO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioBenefVitaFisico
    } else if (figura === BENEF_MORTE_FISICO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioBenefMorteFisico
    }
    return datiAntiriciclaggio;
  }

  /*
  datiAttivitaEconomicaFrom = figura => {
    let datiAttEc = null;
    if(figura === CONTRAENTE_FISICO) {
      datiAttEc = this.props.datiAttivitaEconomica;
    }
    if(figura === RAPPRESENTANTE_LEGALE) {
      datiAttEc = this.props.datiAttivitaEconomicaRappLegale
    }
    if(figura === TITOLARE_EFFETTIVO) {
      datiAttEc = this.props.datiAttivitaEconomicaTitEff
    }
    if(figura === ESECUTORE_FISICO) {
      datiAttEc = this.props.datiAttivitaEconomicaEsecutore
    }
    if(figura === ASSICURATO_FISICO) {
      datiAttEc = this.props.datiAttivitaEconomicaAssicuratoFisico;
    }
    if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiAttEc = this.props.datiAttEconomicaTitEffMorte;
    }
    if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiAttEc = this.props.datiAttEconomicaTitEffVita;
    }
    return datiAttEc;
  }
*/
  
  datiTitolareFrom = figura => {
    let datiTitolareEff = null;
    if(figura === TITOLARE_EFF_BENEF_MORTE) {
      datiTitolareEff = this.props.datiTitolareEffMorte;
    } else if(figura === TITOLARE_EFF_BENEF_VITA) {
      datiTitolareEff = this.props.datiTitolareEffVita;
    } else {
      datiTitolareEff = this.props.datiTitolareEff;
    }
    return datiTitolareEff;
  }

  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiAntiriciclaggioContr;
    } else if (figura === ESECUTORE_FISICO) {
      controlloDati = this.props.controlloDatiAntiriciclaggioEsecut;
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      controlloDati = this.props.controlloDatiAntiriciclaggioRappLegale;
    } else if (figura === TITOLARE_EFFETTIVO) {
      controlloDati = this.props.controlloDatiAntiriciclaggioTitolareEff;
    } else if (figura === ASSICURATO_FISICO) {
      controlloDati = this.props.controlloDatiAntiriciclaggioAssic;
    } else if (figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitEffMorte
    } else if (figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitEffVita
    } else if (figura === BENEF_VITA_FISICO) {
      controlloDati = this.props.controlloDatiAntiriciclaggioBenefVitaFisico
    } else if (figura === BENEF_MORTE_FISICO) {
      controlloDati = this.props.controlloDatiAntiriciclaggioBenefMorteFisico
    }
    return controlloDati;
  }

  handleChangeTipoDocumento = (event) => {
    const nuovoTipoDocumento = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioTipoDocumento = textTrim(datiAntiriciclaggio.tipoDocumento, true);
    if (nuovoTipoDocumento !== vecchioTipoDocumento) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_TIPO_DOCUMENTO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_TIPO_DOCUMENTO;
        this.props.updateDatiAntiriciclaggio(event, actionType.UPDATE_TIPO_DOCUMENTO, this.props.figura);
      }
      this.setState({
        tipoDocumento: (event === null) ? '' : event.value,
        descrTipoDocumento: (event === null) ? '' : event.label,
      });
    }
  }

  handleChangeNumeroDocumento = (event) => {
    const nuovoNumeroDocumento = textTrim(event.target.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioNumeroDocumento = textTrim(datiAntiriciclaggio.numeroDocumento, true);
    if (nuovoNumeroDocumento !== vecchioNumeroDocumento) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_NUMERO_DOCUMENTO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(nuovoNumeroDocumento, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoNumeroDocumento, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoNumeroDocumento, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoNumeroDocumento, anActionType);
      } else {
        anActionType = actionType.UPDATE_NUMERO_DOCUMENTO;
        this.props.updateDatiAntiriciclaggio(nuovoNumeroDocumento, anActionType, this.props.figura);
      }
      this.setState({
        numeroDocumento: nuovoNumeroDocumento,
      });
    }
  }

  handleChangeEnteRilascio = (event) => {
    const nuovoEnteRilascio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioEnteRilascio = textTrim(datiAntiriciclaggio.enteRilascio, true);
    if (nuovoEnteRilascio !== vecchioEnteRilascio) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_ENTE_RILASCIO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_ENTE_RILASCIO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_ENTE_RILASCIO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_ENTE_RILASCIO;
        this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
      }
      this.setState({
        enteRilascio: (event === null) ? '' : event.value,
        descrEnteRilascio: (event === null) ? '' : event.label,
      });
    }
  }

  handleChangeDataRilascio = (event) => {
    if (isDataValid(event)) {
      const nuovaDataRilascioFormattata = dataFormat(event, 'DD/MM/YYYY');
      const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
      const vecchiaDataRilascioFormattata = dataFormat(datiAntiriciclaggio.dataRilascio, 'DD/MM/YYYY');
      if (nuovaDataRilascioFormattata !== vecchiaDataRilascioFormattata) {
        let anActionType = null;
        if (this.props.figura === ASSICURATO_FISICO) {
          anActionType = actionTypeAssic.UPDATE_DATA_RILASCIO_ASSICURATO;
          this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
        } else if (this.props.figura === TITOLARE_EFFETTIVO) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_RILASCIO_TITOLARE_EFF;
          this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
        } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_RILASCIO_TITOLARE_EFF_MORTE;
          this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
        } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA;
          this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
        } else {
          anActionType = actionType.UPDATE_DATA_RILASCIO;
          this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
        }
        this.setNazioni(nuovaDataRilascioFormattata);
        this.setProvince(nuovaDataRilascioFormattata, '');
        this.setComuni('', nuovaDataRilascioFormattata);
        this.setState({
          dataRilascio: event,
        });
      }
    } else {
      console.log('###DATA NON VALIDA ' + this.props.figura + ' handleChangeDataRilascio', event);
    }
  }

  handleChangeDataScadenza = (event) => {
    if (isDataValid(event)) {
      const nuovaDataScadenzaFormattata = dataFormat(event, 'DD/MM/YYYY');
      const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
      const vecchiaDataScadenzaFormattata = dataFormat(datiAntiriciclaggio.dataScadenza, 'DD/MM/YYYY');
      if (nuovaDataScadenzaFormattata !== vecchiaDataScadenzaFormattata) {
        let anActionType = null;
        if (this.props.figura === ASSICURATO_FISICO) {
          anActionType = actionTypeAssic.UPDATE_DATA_SCADENZA_ASSICURATO;
          this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
        } else if (this.props.figura === TITOLARE_EFFETTIVO) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_SCADENZA_TITOLARE_EFF;
          this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
        } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_SCADENZA_TITOLARE_EFF_MORTE;
          this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
        } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
          anActionType = actionTypeTitolareEff.UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA;
          this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
        } else {
          anActionType = actionType.UPDATE_DATA_SCADENZA;
          this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
        }
        this.setState({
          dataScadenza: event,
        });
      }
    } else {
      console.log('###DATA NON VALIDA ' + this.props.figura + ' handleChangeDataScadenza', event);
    }
  }

  handleChangeStatoRilascio = (event) => {
    const nuovoStatoRilascio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    //    const vecchioStatoRilascio = textTrim(this.state.statoRilascio, true); 
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioStatoRilascio = textTrim(datiAntiriciclaggio.statoRilascio, true);
    if (nuovoStatoRilascio !== vecchioStatoRilascio) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_STATO_RILASCIO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RILASCIO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RILASCIO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_STATO_RILASCIO;
        this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
      }
      this.setProvince(dataFormat(this.state.dataRilascio, 'DD/MM/YYYY'), nuovoStatoRilascio);
      this.setComuni('', dataFormat(this.state.dataRilascio, 'DD/MM/YYYY'));
      this.setState({
        statoRilascio: nuovoStatoRilascio,
        descrStatoRilascio: (event === null) ? '' : event.label,
        provinciaRilascio: '',
        descrProvinciaRilascio: '',
      });
    }
  }

  handleChangeProvinciaRilascio = (event) => {
    const nuovaProvinciaRilascio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    //    const vecchiaProvinciaRilascio = textTrim(this.state.provinciaRilascio, true); 
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchiaProvinciaRilascio = textTrim(datiAntiriciclaggio.provinciaRilascio, true);
    if (nuovaProvinciaRilascio !== vecchiaProvinciaRilascio) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_PROVINCIA_RILASCIO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_PROVINCIA_RILASCIO;
        this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
      }
      this.setComuni(nuovaProvinciaRilascio, dataFormat(this.state.dataRilascio, 'DD/MM/YYYY'));
      this.setState({
        provinciaRilascio: nuovaProvinciaRilascio,
        descrProvinciaRilascio: (event === null) ? '' : event.label,
        comuneRilascio: '',
        descrComuneRilascio: '',
      });
    }
  }

  handleChangeComuneRilascio = (event) => {
    const nuovoComuneRilascio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    //    const vecchioComuneRilascio = textTrim(this.state.comuneRilascio, true); 
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioComuneRilascio = textTrim(datiAntiriciclaggio.comuneRilascio, true);
    if (nuovoComuneRilascio !== vecchioComuneRilascio) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_COMUNE_RILASCIO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_COMUNE_RILASCIO;
        this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
      }
      this.setState({
        comuneRilascio: nuovoComuneRilascio,
        descrComuneRilascio: (event === null) ? '' : event.label,
      });
    }
  }

  handleChangeQualifica = event => {
    const nuovaQualifica = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchiaQualifica = textTrim(datiAntiriciclaggio.qualifica, true);
    if(nuovaQualifica !== vecchiaQualifica) {
      if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        this.props.updateDatiAntiriciclaggioTitolareEff(event, actionTypeTitolareEff.UPDATE_TITOLARE_EFF_QUALIFICA_MORTE);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        this.props.updateDatiAntiriciclaggioTitolareEff(event, actionTypeTitolareEff.UPDATE_TITOLARE_EFF_QUALIFICA_VITA);
      } else {
        this.props.updateDatiAntiriciclaggioTitolareEff(event, actionTypeTitolareEff.UPDATE_TITOLARE_EFF_QUALIFICA);
      }
      this.setState({
        qualifica: nuovaQualifica,
        descrQualifica: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      })
    }
  };

  handleChangeAltroQualifica = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioAltro = textTrim(datiAntiriciclaggio.altro, true);
    if (nuovoAltro !== vecchioAltro) {
      if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoAltro, actionTypeTitolareEff.UPDATE_ALTRO_QUALIFICA_MORTE);
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoAltro, actionTypeTitolareEff.UPDATE_ALTRO_QUALIFICA_VITA);
      } else {
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoAltro, actionTypeTitolareEff.UPDATE_ALTRO_QUALIFICA);
      }
      this.setState({
        altro: nuovoAltro,
      })
    }
  }

  handleChangeDatiTitolareEff = event => {	  
    if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      this.props.updateDatiAntiriciclaggioTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_MORTE);
    } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      this.props.updateDatiAntiriciclaggioTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA);
    } else {
      this.props.updateDatiAntiriciclaggioTitolareEff(event.target.value, actionTypeTitolareEff.UPDATE_PERC_OWNERSHIP_TITOLARE_EFF);
    }
    this.setState({
      datiTitolareEff: this.props.datiTitolareEff,
    });	  
  }

  // usata per PROVINCIA ESTERA
  handleChangeComuneRilascioTextField = (event) => {
    const nuovoComuneRilascio = textTrim(event.target.value, true);
    //	    const vecchioComuneRilascio = textTrim(this.state.comuneRilascio, true); 
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioComuneRilascio = textTrim(datiAntiriciclaggio.descrComuneRilascio, true);
    if (nuovoComuneRilascio !== vecchioComuneRilascio) {
      let anActionType = null;
      if (this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_COMUNE_RILASCIO_ASSICURATO;
        this.props.updateDatiAntiriciclaggioAssicurato(nuovoComuneRilascio, anActionType, this.props.figura);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoComuneRilascio, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoComuneRilascio, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoComuneRilascio, anActionType);
      } else {
        anActionType = actionType.UPDATE_COMUNE_RILASCIO;
        this.props.updateDatiAntiriciclaggio(nuovoComuneRilascio, anActionType, this.props.figura);
      }
      this.setState({
        comuneRilascio: '',
        descrComuneRilascio: nuovoComuneRilascio,
      });
    }
  }

  handleChangeEspostoPoliticamente = (event) => {
    const nuovoEspostoPoliticamente = textTrim(event.target.value, true);
    //    const vecchioEspostoPoliticamente = textTrim(this.state.espostaPoliticamente, true); 
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioEspostoPoliticamente = textTrim(datiAntiriciclaggio.espostaPoliticamente, true);
    if (nuovoEspostoPoliticamente !== vecchioEspostoPoliticamente) {
      let anActionType = null;
      if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoEspostoPoliticamente, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_FL_ESPOSTO_POLITICAMENTE_ASSICURATO_FISICO;
        this.props.updateDatiAntiriciclaggioAssicurato(nuovoEspostoPoliticamente, anActionType)
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoEspostoPoliticamente, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoEspostoPoliticamente, anActionType);
      } else if(this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVitaFisico.UPDATE_FL_ESPOSTO_POLITICAMENTE_BENEF_VITA_FISICO;
        this.props.updateDatiAntiriciclaggioBenefVitaFisico(nuovoEspostoPoliticamente, anActionType);
      } else if(this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorteFisico.UPDATE_FL_ESPOSTO_POLITICAMENTE_BENEF_MORTE_FISICO;
        this.props.updateDatiAntiriciclaggioBenefMorteFisico(nuovoEspostoPoliticamente, anActionType);
      } else { 
        anActionType = actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE;
        this.props.updateDatiAntiriciclaggio(nuovoEspostoPoliticamente, anActionType, this.props.figura);
      }
      this.setState({
        espostaPoliticamente: nuovoEspostoPoliticamente,
      });
      // Funzione di CALLBACK
      if (this.props.onChangeEspostaPoliticamente) {
        this.props.onChangeEspostaPoliticamente(event);
      }
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();     
    }
  }

  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  handleChangeEspostoPoliticamenteInCarica = event => {
    const nuovoInCarica = textTrim(event.target.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchioInCarica = textTrim(datiAntiriciclaggio.isInCarica, true);
    if(nuovoInCarica !== vecchioInCarica) {
      let anActionType = null;
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoInCarica, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_FL_PEP_IN_CARICA_ASSICURATO_FISICO;
        this.props.updateDatiAntiriciclaggioAssicurato(nuovoInCarica, anActionType)
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoInCarica, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovoInCarica, anActionType);
      } else {
        anActionType = actionType.UPDATE_FL_PEP_IN_CARICA;
        this.props.updateDatiAntiriciclaggio(nuovoInCarica, anActionType, this.props.figura);
      }
      this.setState({
        isInCarica: nuovoInCarica
      })
    }
  }

  handleChangeCaricaPersonaEsposta = event => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchiaCarica = datiAntiriciclaggio.caricaPersonaEsposta;
    const nuovaCarica = event.target.value;
    if(vecchiaCarica !== nuovaCarica) {
      let anActionType = null;
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_CARICA_PEP_TITOLARE_EFF;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovaCarica, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssic.UPDATE_CARICA_PEP_ASSICURATO_FISICO;
        this.props.updateDatiAntiriciclaggioAssicurato(nuovaCarica, anActionType)
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        anActionType = actionTypeTitolareEff.UPDATE_CARICA_PEP_TITOLARE_EFF_MORTE;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovaCarica, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        anActionType = actionTypeTitolareEff.UPDATE_CARICA_PEP_TITOLARE_EFF_VITA;
        this.props.updateDatiAntiriciclaggioTitolareEff(nuovaCarica, anActionType);
      } else if(this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVitaFisico.UPDATE_CARICA_PEP_BENEF_VITA_FISICO;
        this.props.updateDatiAntiriciclaggioBenefVitaFisico(nuovaCarica, anActionType);
      } else if(this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorteFisico.UPDATE_CARICA_PEP_BENEF_MORTE_FISICO;
        this.props.updateDatiAntiriciclaggioBenefMorteFisico(nuovaCarica, anActionType);
      } else {
        anActionType = actionType.UPDATE_CARICA_PEP;
        this.props.updateDatiAntiriciclaggio(nuovaCarica, anActionType, this.props.figura);
      }
      this.setState({
        caricaPersonaEsposta: nuovaCarica
      })
    }
  }

  handleChangeCaricaPubblicaNoPEP = event => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchiaCaricaPubblicaNoPEP = datiAntiriciclaggio.caricaPubblicaNoPEP;
    const nuovaCaricaPubblicaNoPEP = event.target.value;
    if(vecchiaCaricaPubblicaNoPEP !== nuovaCaricaPubblicaNoPEP) {
      let anActionType = null;
      if(this.props.figura === CONTRAENTE_FISICO) {
    	  anActionType = actionType.UPDATE_CARICA_PUBBLICA_NO_PEP;
          this.props.updateDatiAntiriciclaggio(nuovaCaricaPubblicaNoPEP, anActionType, this.props.figura);
      } else if(this.props.figura === BENEF_VITA_FISICO) {
        anActionType = actionTypeBenefVitaFisico.UPDATE_CARICA_PUBBLICA_NO_PEP_BENEF_VITA_FISICO;
        this.props.updateDatiAntiriciclaggioBenefVitaFisico(nuovaCaricaPubblicaNoPEP, anActionType);
      } else if(this.props.figura === BENEF_MORTE_FISICO) {
        anActionType = actionTypeBenefMorteFisico.UPDATE_CARICA_PUBBLICA_NO_PEP_BENEF_MORTE_FISICO;
        this.props.updateDatiAntiriciclaggioBenefMorteFisico(nuovaCaricaPubblicaNoPEP, anActionType);
      } else if(this.props.figura === TITOLARE_EFFETTIVO) {
          anActionType = actionTypeTitolareEff.UPDATE_CARICA_PUBBLICA_NO_PEP_TITOLARE_EFF;
          this.props.updateDatiAntiriciclaggioTitolareEff(nuovaCaricaPubblicaNoPEP, anActionType);
        } 
      this.setState({
    	  caricaPubblicaNoPEP: nuovaCaricaPubblicaNoPEP
      })
    }
  }
  
  setNazioni = (aDate) => {
    const opts = {
      dataRiferimentoComune: aDate,
    };
    fetch(uris.nazioneItems, fetchParam(opts))
      .then(
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          nazioneItems: result,
        });
      });
  }

  setProvince = (aDate, nazione) => {
    if (textTrim(nazione, true) !== '') {
      const opts = {
        dataRiferimentoProv: aDate,
        nazione: nazione,
      };
      fetch(uris.provinciaItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          // If request is good update state with fetched data
          this.setState({
            provinciaItems: result,
          });
        });
    } else {
      this.setState({
        provinciaItems: [],
      });
    }
  }

  setComuni = (codProvincia, dataNascita) => {
    if (textTrim(codProvincia, true) !== '' && textTrim(dataNascita, true) !== '') {
      const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          // If request is good update state with fetched data
          this.setState({
            comuneItems: result,
          });
        });
    } else {
      this.setState({
        comuneItems: [],
      });
    }
  }

  isRequiredPerEsecutore = () => {
    return (this.props.datiEsecutore.flEsecutore === 'S' && this.props.scopeAmleto === true);
  }

  isRequiredTipoDocumento = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredNumeroDocumento = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredEnteRilascio = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredDataRilascio = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredDataScadenza = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredStatoRilascio = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredProvinciaRilascio = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredComuneRilascio = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO) {
      required = this.isRequiredPerEsecutore();
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredEspostoPoliticamente = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === ESECUTORE_FISICO ) {
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE) {      
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      required = true;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    } else if (this.props.figura === BENEF_VITA_FISICO) {
      required = true;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      required = true;
    } 
    
    return required;
  }

  isRequiredInCarica = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const esposto = datiAntiriciclaggio.espostaPoliticamente;
    let required = false;
    
    if(this.props.scopeAmleto) {
      if(esposto === 'S') {
    	  // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
          if (this.props.figura !== RAPPRESENTANTE_LEGALE &&
        	  this.props.figura !== ASSICURATO_FISICO &&
        	  this.props.figura !== ESECUTORE_FISICO ) {
        	  required = true;
          }
          // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
      }
    }
    return required;
  }

  isRequiredCaricaPersona = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const esposto = datiAntiriciclaggio.espostaPoliticamente;
    let required = false;
    if(this.props.scopeAmleto) {
      if(esposto === 'S') {
    	  // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
    	  if (this.props.figura !== RAPPRESENTANTE_LEGALE &&
              this.props.figura !== ASSICURATO_FISICO &&
        	  this.props.figura !== ESECUTORE_FISICO ) {
        	  required = true;
          }
          // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
      }
    }
    return required;
  }

  isRequiredCaricaPubblicaNoPEP = () => {
    let required = false;
	return required;
  }

  isRequiredQualifica = () => {
    let required = false;
    if (this.props.figura === TITOLARE_EFFETTIVO && this.props.scopeAmleto) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      required = true;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      required = true;
    }
    return required;
  }

  isRequiredAltroQualifica = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const altroQualifica = textTrim(datiAntiriciclaggio.qualifica, true);
    return (altroQualifica === CODICE_ALTRA_QUALIFICA);
  }

  isRequiredPerc = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const codQualifica = datiAntiriciclaggio.qualifica;
    return codQualifica === CODICE_QUALIFICA_SOCIO_PROPRIETARIO;
  }

  isDisabled = () => {
    const disabled = (this.props.renderChildren !== undefined) && (this.props.renderChildren !== null) && (this.props.renderChildren.digitabile === false);
    return disabled;
  }

  isDisabledStato = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const dataRilascioFormattata = dataFormat(datiAntiriciclaggio.dataRilascio, 'DD/MM/YYYY');
    return (this.isDisabled() === true) || (dataRilascioFormattata === null);
  }

  isDisabledProvincia = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const statoRilascio = textTrim(datiAntiriciclaggio.statoRilascio, true);
    return (this.isDisabledStato() === true) || (statoRilascio === '');
  }

  isDisabledComune = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const provinciaRilascio = textTrim(datiAntiriciclaggio.provinciaRilascio, true);
    return (this.isDisabledProvincia() === true) || (provinciaRilascio === '');
  }

  isDisabledCaricaPersona = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const esposto = datiAntiriciclaggio.espostaPoliticamente;
//2020-09-10 PFD: Abbassamento Controlli AMLETO - START
//    if(this.props.figura === CONTRAENTE_FISICO) {
//      return (esposto !== 'S') || this.props.scopeAmleto === false
//    } else {  
//      return (esposto !== 'S') || this.props.scopeAmleto === false
//    }
    return (esposto !== 'S') || this.props.scopeAmleto === false
// 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
  }

  isDisabledCaricaPubblicaNoPEP = () => {
	return false
  }

  isDisabledInCarica = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const esposto = datiAntiriciclaggio.espostaPoliticamente;
//2020-09-10 PFD: Abbassamento Controlli AMLETO - START    
//    if(this.props.figura === CONTRAENTE_FISICO) {
//      return (esposto !== 'S') || this.props.scopeAmleto === false
//    } else {  
//        return (esposto !== 'S') || this.props.scopeAmleto === false
//    }
    return (esposto !== 'S') || this.props.scopeAmleto === false
//2020-09-10 PFD: Abbassamento Controlli AMLETO - END    
  }

  isDisabledQualifica = () => {
    let disabled = false;
    if(this.props.scopeAmleto === false) {
      if (this.props.figura !== TITOLARE_EFFETTIVO || this.props.figura !== TITOLARE_EFF_BENEF_MORTE || this.props.figura !== TITOLARE_EFF_BENEF_VITA) {
        disabled = true
      }
    }
    return disabled;
  }

  isDisabledAltroQualifica = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const altroQualifica = textTrim(datiAntiriciclaggio.qualifica, true);
    return (altroQualifica !== CODICE_ALTRA_QUALIFICA);
  }

  isDisabledPerc = () => {
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const codQualifica = datiAntiriciclaggio.qualifica;
    return codQualifica !== CODICE_QUALIFICA_SOCIO_PROPRIETARIO;
  }

  isDisabledEspostoPoliticamente = () => {
    let disabled = false;
    /* if(this.props.figura === ESECUTORE_FISICO && this.props.scopeAmleto === false) {
      disabled = true;
    } */
    return disabled;
  }

  initStatoCampi = () => {
    return [ {id: 'tipoDocumento', isError: false},
             {id: 'numeroDocumento', isError: false}, 
             {id: 'enteRilascio', isError: false}, 
             {id: 'dataRilascio', isError: false},
             {id: 'dataScadenza', isError: false},             
             {id: 'statoRilascio', isError: false},
             {id: 'provinciaRilascio', isError: false},
             {id: 'comuneRilascio', isError: false},
             {id: 'espostaPoliticamente', isError: false},
             {id: 'isInCarica', isError: false},
             {id: 'caricaPEP', isError: false},
             {id: 'titolareEffQualifica', isError: false},
           ];
  }
			  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({
      statoCampi: [...campoWithoutId, unErrore],
    });
  }

  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }

  render() {
    const { classes, figura, labelFieldSet, dominio, renderChildren } = this.props;
    const datiTitolareEff = this.datiTitolareFrom(figura);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(figura);
//    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(figura);
    //	    const { t } = this.props; //hoc
    const controlloDati = this.controlloDatiFrom(figura);
    const disabled = this.isDisabled();
    return (
      <Fragment>
        {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiAntiriciclaggio')}</Typography></legend>*/}
        {labelFieldSet &&
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        }
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          {/* 2020-12-01 PFD: SAV 'TO BE' - NASCONDERE SEZIONE PEP PER ASSICURATO E SOGGETTO TERZO (alias ESECUTORE)*/}
          {figura !== ASSICURATO_FISICO && figura !== ESECUTORE_FISICO && 
        	<Grid container direction='row' justify='center' alignItems='flex-start'>
	            <Grid item md={6} xs={12}>
	              <RadioButtonComp onChange={this.handleChangeEspostoPoliticamente} radioComp={radioButtonEspostoPoliticamente} disabled={this.isDisabledEspostoPoliticamente()}
	              	selectedValue={datiAntiriciclaggio.espostaPoliticamente} required={this.isRequiredEspostoPoliticamente()} 
	                id='espostaPoliticamente' onError={this.handleError} controlloDati={controlloDati} />
	            </Grid>
	            {/* 2020-12-01 PFD: SAV 'TO BE' - NASCONDERE CAMPO IN_CARICA PER BENEFICIARI*/}
	            {(figura !== BENEF_VITA_FISICO && figura !== BENEF_MORTE_FISICO) 
	            	? 
			            <Grid item md={6} xs={12}>
			              <RadioButtonComp onChange={this.handleChangeEspostoPoliticamenteInCarica} radioComp={radioButtonEspostoPoliticamenteInCarica} 
			              	selectedValue={datiAntiriciclaggio.isInCarica} required={this.isRequiredInCarica()} 
			                id='isInCarica' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledInCarica()} />
			            </Grid>
		            :
		               
		            	<Grid item md={6} xs={12}></Grid>
	            }
	            <Grid item md={12} xs={12}>
	              <TextFieldComp onBlur={this.handleChangeCaricaPersonaEsposta} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
	                id='caricaPEP' label='caricaPEP' value={datiAntiriciclaggio.caricaPersonaEsposta} required={this.isRequiredCaricaPersona()} 
	                controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledCaricaPersona()} />
	            </Grid>
	        </Grid>
          }
          {/* 2020-12-01 PFD: SAV 'TO BE' */}
          {(figura === CONTRAENTE_FISICO || figura === BENEF_VITA_FISICO || figura === BENEF_MORTE_FISICO || figura === TITOLARE_EFFETTIVO) && 
        	<Grid container direction='row' justify='flex-start' alignItems='flex-start'>
	          <Grid item md={6} xs={12}>
	            <TextFieldComp onBlur={this.handleChangeCaricaPubblicaNoPEP} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
	              id='caricaPubblicaNoPEP' label='caricaPubblicaNoPEP' value={datiAntiriciclaggio.caricaPubblicaNoPEP} required={this.isRequiredCaricaPubblicaNoPEP()} 
	              controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledCaricaPubblicaNoPEP()} />
	          </Grid>
	          <Grid item md={6} xs={12}></Grid>
            </Grid>
          }
          
          {/* 2020-12-01 PFD: SAV 'TO BE' - DATI DOCUMENTI PER TUTTI I RUOLI TRANNE BENFICIARI */}
          {figura !== BENEF_VITA_FISICO && figura !== BENEF_MORTE_FISICO &&
        	  <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
		          <Grid item md={4} xs={12}>
		            <SelectComp onChange={this.handleChangeTipoDocumento} options={dominio.tipoDocumentoItems} ricerca='comboRicerca' etichetta='tipoDocumento'
		              value={{ value: datiAntiriciclaggio.tipoDocumento, label: datiAntiriciclaggio.descrTipoDocumento }} required={this.isRequiredTipoDocumento()}
		              controlloDati={controlloDati} id='tipoDocumento' onError={this.handleError} disabled={disabled} />
		          </Grid>
		          <Grid item md={4} xs={12}>
		            <TextFieldComp onBlur={this.handleChangeNumeroDocumento} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='numeroDocumento' label='numDocumento'
		              value={datiAntiriciclaggio.numeroDocumento} required={this.isRequiredNumeroDocumento()}
		              controlloDati={controlloDati} onError={this.handleError} disabled={disabled} />
		          </Grid>
		          <Grid item md={4} xs={12}>
		            <SelectComp onChange={this.handleChangeEnteRilascio} options={dominio.enteRilascioItems} ricerca='comboRicerca' etichetta='enteRilascio'
		              value={{ value: datiAntiriciclaggio.enteRilascio, label: datiAntiriciclaggio.descrEnteRilascio }} required={this.isRequiredEnteRilascio()}
		              id='enteRilascio' onError={this.handleError} controlloDati={controlloDati} disabled={disabled} />
		          </Grid>
		          <Grid item md={4} xs={12}>
		            <DatePickerComp onChange={this.handleChangeDataRilascio} aStyle={classes.dateField} id='dataRilascio' label='dataRilascio'
		              value={datiAntiriciclaggio.dataRilascio} required={this.isRequiredDataRilascio()}
		              onError={this.handleError} controlloDati={controlloDati} disabled={disabled} isControlError={this.getIsInError('dataRilascio')} />
		          </Grid>
		          <Grid item md={8} xs={12}>
		            <DatePickerComp onChange={this.handleChangeDataScadenza} aStyle={classes.dateField} id='dataScadenza' label='dataScadenza'
		              value={datiAntiriciclaggio.dataScadenza} required={this.isRequiredDataScadenza()}
		              onError={this.handleError} controlloDati={controlloDati} disabled={disabled} isControlError={this.getIsInError('dataScadenza')} />
		          </Grid>
		          <Grid item md={4} xs={12}>
		            <SelectComp onChange={this.handleChangeStatoRilascio} options={this.state.nazioneItems} ricerca='comboRicerca' etichetta='stato'
		              value={{ value: datiAntiriciclaggio.statoRilascio, label: datiAntiriciclaggio.descrStatoRilascio }} required={this.isRequiredStatoRilascio()}
		              id='statoRilascio' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledStato()} />
		          </Grid>
		          <Grid item md={4} xs={12}>
		            <SelectComp onChange={this.handleChangeProvinciaRilascio} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provincia'
		              value={{ value: datiAntiriciclaggio.provinciaRilascio, label: datiAntiriciclaggio.descrProvinciaRilascio }} required={this.isRequiredProvinciaRilascio()}
		              id='provinciaRilascio' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledProvincia()} />
		          </Grid>
		          <Grid item md={4} xs={12}>
		            {datiAntiriciclaggio.provinciaRilascio !== PROVINCIA_ESTERA &&
		              <SelectComp onChange={this.handleChangeComuneRilascio} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comune'
		                value={{ value: datiAntiriciclaggio.comuneRilascio, label: datiAntiriciclaggio.descrComuneRilascio }} required={this.isRequiredComuneRilascio()}
		                id='comuneRilascio' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledComune()} />
		            }
		            {datiAntiriciclaggio.provinciaRilascio === PROVINCIA_ESTERA &&
		              <TextFieldComp onBlur={this.handleChangeComuneRilascioTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneRilascio' label='comune'
		                value={datiAntiriciclaggio.descrComuneRilascio} required={this.isRequiredComuneRilascio()}
		                controlloDati={controlloDati} onError={this.handleError} disabled={disabled} />
		            }
		          </Grid>
		          {(figura === TITOLARE_EFFETTIVO || figura === TITOLARE_EFF_BENEF_MORTE || figura === TITOLARE_EFF_BENEF_VITA) &&
		            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
		              <Grid item md={4} xs={12}>
		                <SelectComp onChange={this.handleChangeQualifica} options={this.state.qualificaItems} id='titolareEffQualifica' etichetta='titolareEffQualifica'
		                  onError={this.handleError} disabled={this.isDisabledQualifica()} required={this.isRequiredQualifica()} controlloDati={controlloDati}
		                  value={{ value: datiAntiriciclaggio.qualifica, label: datiAntiriciclaggio.descrQualifica }} />
		              </Grid>
		              <Grid item md={4} xs={12}>
		                <TextFieldComp onBlur={this.handleChangeAltroQualifica} id='altro' label='general.seAltroSpecificare' aStyle={classes.textFieldWidthPerc}
		                  aStyleError={classes.textFieldWidthPercError} value={datiAntiriciclaggio.altro} required={this.isRequiredAltroQualifica()}
		                  onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledAltroQualifica()} />
		              </Grid>
		              <Grid item md={4} xs={12}>
		                <NumberFieldComp id='percOwnerShip' value={datiTitolareEff.percOwnerShip} onBlur={this.handleChangeDatiTitolareEff}
		                  aStyle={classes.textField} label='percOwnership' required={true} controlloDati={controlloDati} onError={this.handleError}
		                  required={this.isRequiredPerc()} disabled={this.isDisabledPerc()} />
		              </Grid>
		            </Grid>
		          }
		          {/*
		            {figura === TITOLARE_EFF_BENEF_MORTE &&
		            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
		            <Grid item md={4} xs={12}>
		              <SelectComp onChange={this.handleChangeQualifica} options={this.state.qualificaItems} id='titolareEffQualifica' etichetta='titolareEffQualifica'
		                onError={this.handleError} disabled={this.isDisabledQualifica()} required={this.isRequiredQualifica()} controlloDati={controlloDati}
		                value={{ value: datiAntiriciclaggio.qualifica, label: datiAntiriciclaggio.descrQualifica }} />
		            </Grid>
		            <Grid item md={4} xs={12}>
		              <TextFieldComp onBlur={this.handleChangeAltroQualifica} id='altro' label='general.seAltroSpecificare' aStyle={classes.textFieldWidthPerc}
		                aStyleError={classes.textFieldWidthPercError} value={datiAntiriciclaggio.altro} required={this.isRequiredAltroQualifica()}
		                onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledAltroQualifica()} />
		            </Grid>
		            <Grid item md={4} xs={12}>
		              <NumberFieldComp id='percOwnerShip' value={datiTitolareEff.percOwnerShip} onBlur={this.handleChangeDatiTitolareEff}
		                aStyle={classes.textField} label='percOwnership' required={true} controlloDati={controlloDati} onError={this.handleError}
		                required={this.isRequiredPerc()} disabled={this.isDisabledPerc()} />
		            </Grid>
		            </Grid>
		            }
		            {figura === TITOLARE_EFF_BENEF_VITA &&
		            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
		            <Grid item md={4} xs={12}>
		              <SelectComp onChange={this.handleChangeQualifica} options={this.state.qualificaItems} id='titolareEffQualifica' etichetta='titolareEffQualifica'
		                onError={this.handleError} disabled={this.isDisabledQualifica()} required={this.isRequiredQualifica()} controlloDati={controlloDati}
		                value={{ value: datiAntiriciclaggio.qualifica, label: datiAntiriciclaggio.descrQualifica }} />
		            </Grid>
		            <Grid item md={4} xs={12}>
		              <TextFieldComp onBlur={this.handleChangeAltroQualifica} id='altro' label='general.seAltroSpecificare' aStyle={classes.textFieldWidthPerc}
		                aStyleError={classes.textFieldWidthPercError} value={datiAntiriciclaggio.altro} required={this.isRequiredAltroQualifica()}
		                onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledAltroQualifica()} />
		            </Grid>
		            <Grid item md={4} xs={12}>
		              <NumberFieldComp id='percOwnerShip' value={datiTitolareEff.percOwnerShip} onBlur={this.handleChangeDatiTitolareEff}
		                aStyle={classes.textField} label='percOwnership' required={true} controlloDati={controlloDati} onError={this.handleError}
		                required={this.isRequiredPerc()} disabled={this.isDisabledPerc()} />
		            </Grid>
		            </Grid>
		            }
		          */}
		      </Grid>
          }
        </Grid>    
      {/*</fieldset>*/}
      </Fragment>
    );
  }

  updatePerContraente(prevProps) {
    const prevRender = prevProps.renderChildren;
    const currRender = this.props.renderChildren;
    if (this.isDisabled() && (prevRender !== currRender)) {
      this.setState({
        nazioneItems: [],
        provinciaItems: [],
        comuneItems: [],
      });
      const figura = this.props.figura;
      if (figura === ESECUTORE_FISICO) {
        this.props.updateDatiAntiriciclaggioPerContraente(null, actionType.UPDATE_DATI_ANTIRICICLAGGIO_PER_ESECUTORE);
      } else if (figura === TITOLARE_EFFETTIVO) {
        this.props.updateDatiAntiriciclaggioPerContraente(null, actionTypeTitolareEff.UPDATE_DATI_ANTIRICICLAGGIO_PER_TITOLARE_EFFETTIVO);
      } /* else if (figura === CONTRAENTE_FISICO) {
        this.props.updateDatiAntiriciclaggioPerContraente(null, actionType.UPDATE_DATI_ANTIRICICLAGGIO_PER_CONTRAENTE);    	  
      } else if (figura === ASSICURATO_FISICO) {
        this.props.updateDatiAntiriciclaggioPerAssicurato(null, actionTypeAssic.UPDATE_DATI_ANTIRICICLAGGIO_PER_ASSICURATO);       	  
      } */
    }
  }

  isInError(prevProps, prevState) {
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
    });
    console.log('########DatiAntiriciclaggio componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    //    console.log('########DatiAntiriciclaggio componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    //    console.log('########DatiAntiriciclaggio componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
    if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
    }
  }

  isInBusinessError(prevProps, prevState) {
    if (this.state.dataScadenza !== null && this.state.dataScadenza !== prevState.dataScadenza) {
      this.handleError('dataScadenza', (this.state.dataScadenza).isSameOrBefore(moment()));
    }
    if (this.state.dataRilascio !== null && this.state.dataRilascio !== prevState.dataRilascio) {
      //this.handleError('dataRilascio', (this.state.dataRilascio).isAfter(moment()) || (this.state.dataRilascio).format('DD/MM/YYYY')===moment().format('DD/MM/YYYY'));	
      this.handleError('dataRilascio', (this.state.dataRilascio).isAfter(moment()));
    }
  }

  /*  isResetComp(prevProps, prevState) {	  
    const resetCompCurr = this.props.resetComp;
    const resetCompPrev = prevProps.resetComp;
    if (resetCompCurr!==undefined && resetCompCurr!==null && resetCompCurr===true && resetCompCurr!==resetCompPrev) {
        this.setState ({
        tipoDocumento: '', 
          descrTipoDocumento: '',
          numeroDocumento: '',
          enteRilascio: '',
          descrEnteRilascio: '',
          dataRilascio: null,
          dataScadenza: null,
          statoRilascio: '',
          descrStatoRilascio: '',
          provinciaRilascio: '',
          descrProvinciaRilascio: '',
          comuneRilascio: '',
          descrComuneRilascio: '',
          espostaPoliticamente: '',
          statoCampi: this.initStatoCampi(),
          isPresenteErrore: false,  		      
        });
        console.log('########DatiAntiriciclaggio isResetComp this.state', this.state);
    }
    };*/

  componentDidUpdate(prevProps, prevState) {
    //	this.isResetComp(prevProps, prevState);
    this.updatePerContraente(prevProps);
    this.isInBusinessError(prevProps, prevState);
    this.isInError(prevProps, prevState);
  }
}

DatiAntiriciclaggio.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  dominio: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiAntiriciclaggio));
