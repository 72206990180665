import React from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uris, fetchParam, isLocal } from '../../../uris';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Email from '@material-ui/icons/Email';

import TextFieldComp from '../../common/TextFieldComp';
import TextFieldAdornComp from '../../common/TextFieldAdornComp';
import DatePickerComp from '../../common/DatePickerComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import SelectComp from '../../common/SelectComp';

import DividerComp from '../../common/DividerComp';

import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';
import { PROVINCIA_ESTERA } from '../../../utility/jsConstants';

import moment from 'moment';
import 'moment/locale/it';
 
import { radioButtonSesso, radioButtonEsisteReferenteTerzo  } from '../../store/radioButtonStore';

import * as actions from '../figure/action/referenteTerzo';
import * as actionType from '../figure/actionType/referenteTerzo';

const styles = theme => ({
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
  dateFieldWidth: {
	  width: '97%',
  },
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
//    datiReferenteTerzoBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiReferenteTerzo,
    referenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico,
    controlloDatiReferenteTerzoFisico: state.areaReferenteTerzoReducer.referenteTerzoFisico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiReferenteTerzoFisico: (unDato, unActionType) => dispatch(actions.updateDatiReferenteTerzoFisico(unDato, unActionType)),
    resetDatiReferenteTerzoFisico: (unDato, unActionType) => dispatch(actions.resetDatiReferenteTerzoFisico(unDato, unActionType)),  
  };
};

class DatiReferenteTerzo extends React.Component {
	
  constructor(props) {
    super(props);
    const referenteTerzoFisico = this.props.referenteTerzoFisico;
    const datiReferenteTerzo = referenteTerzoFisico.datiReferenteTerzo;
    const datiGenerali = datiReferenteTerzo.datiGenerali;
    const datiResidenza = datiReferenteTerzo.datiResidenza;
//    const dataNascitaFormattata = datiGenerali && datiGeneraliUPDATE_CAP_RESIDENZA_REFERENTE_TERZO_FISICO.dataNascita ? (datiGenerali.dataNascita).format('DD/MM/YYYY') : '';
    this.state = {
      flReferenteTerzo: datiGenerali.flReferenteTerzo,
      sesso: datiGenerali.sesso,
      codiceFiscale: datiGenerali.codiceFiscale,
      
      cognome: datiGenerali.cognome,
      nome: datiGenerali.nome,      
      dataNascita: datiGenerali.dataNascita,
      statoNascita: datiGenerali.statoNascita,
      descrStatoNascita: datiGenerali.descrStatoNascita,
      provinciaNascita: datiGenerali.provinciaNascita,
      descrProvinciaNascita: datiGenerali.descrProvinciaNascita,
      comuneNascita: datiGenerali.comuneNascita,
      descrComuneNascita: datiGenerali.descrComuneNascita,
      statoResidenza: datiResidenza.statoResidenza,
      descrStatoResidenza: datiResidenza.descrStatoResidenza,
      provinciaResidenza: datiResidenza.provinciaResidenza,
      descrProvinciaResidenza: datiResidenza.descrProvinciaResidenza,
      comuneResidenza: datiResidenza.comuneResidenza,
      descrComuneResidenza: datiResidenza.descrComuneResidenza,
      toponimoResidenza: datiResidenza.toponimoResidenza,
      descrToponimoResidenza: datiResidenza.descrToponimoResidenza,
      indirizzoResidenza: datiResidenza.indirizzoResidenza,
      descrIndirizzoResidenza: datiResidenza.descrIndirizzoResidenza,
      numCivicoResidenza: datiResidenza.numCivicoResidenza,
      capResidenza: datiResidenza.capResidenza,
      email: datiReferenteTerzo.email,
      
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
      codiceFiscaleValido: null,
      
      renderChildren: {
        digitabile: true,
      },    
      
      nazioneItems: [],
      provinciaItems: [],
      comuneItems: [],
      provinciaResidenzaItems: [],
      comuneResidenzaItems: [],
      toponimoItems: [],
      stradarioItems: [],
    };
  };

  componentDidMount() {	  
    const datiReferenteTerzo = this.props.referenteTerzoFisico.datiReferenteTerzo;
    const datiGenerali = datiReferenteTerzo.datiGenerali;
    const datiResidenza = datiReferenteTerzo.datiResidenza;
//    const dataNascitaFormattata = datiGenerali && datiGenerali.dataNascita ? (datiGenerali.dataNascita).format('DD/MM/YYYY') : '';
    const dataNascitaFormattata = datiGenerali && datiGenerali.dataNascita ? (datiGenerali.dataNascita).format('DD/MM/YYYY') : '';
    this.setNazioni(dataNascitaFormattata);
    this.setProvince(dataNascitaFormattata, datiGenerali ? datiGenerali.statoNascita : '');
    this.setComuni(datiGenerali ? datiGenerali.provinciaNascita : '', dataNascitaFormattata);
    this.setProvinceResidenza(datiResidenza ? datiResidenza.statoResidenza : '');
    this.setComuniResidenza(datiResidenza ? datiResidenza.provinciaResidenza : '', moment().format('DD/MM/YYYY'));    
    this.setToponimo(datiResidenza ? datiResidenza.comuneResidenza : '');
    //this.setStradario(datiResidenza ? datiResidenza.comuneResidenza : '', datiResidenza ? datiResidenza.toponimoResidenza : '', datiResidenza ? datiResidenza.descrIndirizzoResidenza : '');    
  }
  
  handleChangeConfermaReferenteTerzo = event => {
    this.props.updateReferenteTerzoFisico(true, actionType.SALVA_REFERENTE_TERZO_FISICO);    
  }  

  handleChangeReferenteTerzo = event => {
    const nuovoFlReferenteTerzo = textTrim(event.target.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioFlReferenteTerzo = textTrim(datiGenerali.flReferenteTerzo, true);
    if (nuovoFlReferenteTerzo !== vecchioFlReferenteTerzo) { 	  
      this.props.updateDatiReferenteTerzoFisico(nuovoFlReferenteTerzo, actionType.UPDATE_FL_REFERENTE_TERZO_FISICO);
      if(nuovoFlReferenteTerzo === 'N') {
        this.props.resetDatiReferenteTerzoFisico(null, actionType.RESET_DATI_REFERENTE_TERZO);
      }   
      this.setState({ 
   	    flReferenteTerzo: nuovoFlReferenteTerzo,
        isPresenteErrore: null, // ripartenza
        statoCampi: this.initStatoCampi(),
      });
    }  
  };
  
  handleChangeSesso = event => {
    const nuovoSesso = textTrim(event.target.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioSesso = textTrim(datiGenerali.sesso, true); 
    if (nuovoSesso !== vecchioSesso) {     
      this.props.updateDatiReferenteTerzoFisico(nuovoSesso, actionType.UPDATE_SESSO_REFERENTE_TERZO_FISICO); 
      this.verificaCodiceFiscale();
      this.setState({
        sesso: nuovoSesso,
      });
    }  
  };
	  
  handleChangeCodFisc = event => {
    const nuovoCodiceFiscale = textTrim(event.target.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioCodiceFiscale = textTrim(datiGenerali.codiceFiscale, true);
    if (nuovoCodiceFiscale !== vecchioCodiceFiscale) {	  
      this.props.updateDatiReferenteTerzoFisico(nuovoCodiceFiscale, actionType.UPDATE_CODICE_FISCALE_REFERENTE_TERZO_FISICO);
      this.verificaCodiceFiscale();
      this.setState({
        codiceFiscale: nuovoCodiceFiscale, 
      });
    }
  };
		  
  handleChangeCognome = event => {
    const nuovoCognome = textTrim(event.target.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioCognome = textTrim(datiGenerali.cognome, true); 
    if (nuovoCognome !== vecchioCognome) {     
      this.props.updateDatiReferenteTerzoFisico(nuovoCognome, actionType.UPDATE_COGNOME_REFERENTE_TERZO_FISICO);
      this.verificaCodiceFiscale();
      this.setState({
        cognome: nuovoCognome, 
      });
    }  
  };
	    
  handleChangeNome = event => {
    const nuovoNome = textTrim(event.target.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioNome = textTrim(datiGenerali.nome, true); 
    if (nuovoNome !== vecchioNome) { 	    
      this.props.updateDatiReferenteTerzoFisico(nuovoNome, actionType.UPDATE_NOME_REFERENTE_TERZO_FISICO);
      this.verificaCodiceFiscale();
      this.setState({
        nome: nuovoNome,
      });
    }
  };

  handleChangeDataNascita = event => {
    if (isDataValid(event)) {	  
      const nuovaDataNascitaFormattata = dataFormat(event, 'DD/MM/YYYY');
      const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
      const vecchiaDataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY');
      if (nuovaDataNascitaFormattata !== vecchiaDataNascitaFormattata) { 	    
        this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_DATA_NASCITA_REFERENTE_TERZO_FISICO);
        this.verificaCodiceFiscale();
        this.setNazioni(nuovaDataNascitaFormattata);
        this.setProvince(nuovaDataNascitaFormattata, '');
        this.setComuni('', nuovaDataNascitaFormattata);
        this.setState({
          dataNascita: event,    	
          statoNascita: '',
          descrStatoNascita: '',
          provinciaNascita: '',
          descrProvinciaNascita: '',
          comuneNascita: '' ,
          descrComuneNascita: '',        
        });
      } else {
        console.log('###DATA NON VALIDA ' + this.props.figura + ' handleChangeDataNascita', event);
      }        
    }  
  };
	  
  handleChangeStatoNascita = event => {
    const nuovoStatoNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioStatoNascita = textTrim(datiGenerali.statoNascita, true); 
    if (nuovoStatoNascita !== vecchioStatoNascita) {     
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_STATO_NASCITA_REFERENTE_TERZO_FISICO);
//      this.verificaCodiceFiscale();
      const dataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY');
      this.setProvince(dataNascitaFormattata, nuovoStatoNascita);
      this.setComuni('', dataNascitaFormattata);
      this.setState({
        statoNascita: nuovoStatoNascita,
        descrStatoNascita: (event === null) ? '' : event.label,
		provinciaNascita: '',
        descrProvinciaNascita: '',
      });
    }  
  };  
	  
  handleChangeProvinciaNascita = event => {
    const nuovaProvinciaNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchiaProvinciaNascita = textTrim(datiGenerali.provinciaNascita, true); 
    if (nuovaProvinciaNascita !== vecchiaProvinciaNascita) {        
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_PROVINCIA_NASCITA_REFERENTE_TERZO_FISICO);
      this.verificaCodiceFiscale();
      const dataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY'); 
      this.setComuni(nuovaProvinciaNascita, dataNascitaFormattata);
      this.setState({
        provinciaNascita: nuovaProvinciaNascita,
        descrProvinciaNascita: (event === null) ? '' : event.label,
		comuneNascita: '',
        descrComuneNascita: '',
      });
    }  
  };    

  handleChangeComuneNascita = event => {
    const nuovoComuneNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioComuneNascita = textTrim(datiGenerali.comuneNascita, true); 
    if (nuovoComuneNascita !== vecchioComuneNascita) {   	  
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_COMUNE_NASCITA_REFERENTE_TERZO_FISICO);
      this.verificaCodiceFiscale();
      this.setState({
        comuneNascita: nuovoComuneNascita,
        descrComuneNascita: (event === null) ? '' : event.label,
      });
    }  
  };
  
  // usato per PROVINCIA ESTERA
  handleChangeComuneNascitaTextField = event => {
    const nuovoComuneNascita = textTrim(event.target.value, true);
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const vecchioComuneNascita = textTrim(datiGenerali.descrComuneNascita, true); 
    if (nuovoComuneNascita !== vecchioComuneNascita) {     
      this.props.updateDatiReferenteTerzoFisico(nuovoComuneNascita, actionType.UPDATE_COMUNE_NASCITA_REFERENTE_TERZO_FISICO);
      this.verificaCodiceFiscale();
      this.setState({
        comuneNascita: '' ,
        descrComuneNascita: (event === null) ? '' : nuovoComuneNascita,
      });
    }  
  }   
  
  handleChangeStatoResidenza = event => {
    const nuovoStatoResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioStatoResidenza = textTrim(datiResidenza.statoResidenza, true); 
    if (nuovoStatoResidenza !== vecchioStatoResidenza) {     
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_STATO_RESIDENZA_REFERENTE_TERZO_FISICO); 	
      this.setProvinceResidenza(nuovoStatoResidenza);
      this.setComuniResidenza('', moment().format('DD/MM/YYYY'));
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        statoResidenza: nuovoStatoResidenza,
        descrStatoResidenza: (event === null) ? '' : event.label,
        provinciaResidenza: '',
        descrProvinciaResidenza: '',
      });
    }  
  };  
			  
  handleChangeProvinciaResidenza = event => {
    const nuovaProvinciaResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchiaProvinciaResidenza = textTrim(datiResidenza.provinciaResidenza, true); 
    if (nuovaProvinciaResidenza !== vecchiaProvinciaResidenza) { 	  
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_PROVINCIA_RESIDENZA_REFERENTE_TERZO_FISICO); 
      this.setComuniResidenza(nuovaProvinciaResidenza, moment().format('DD/MM/YYYY'));
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        provinciaResidenza: nuovaProvinciaResidenza,
        descrProvinciaResidenza: (event === null) ? '' : event.label,
        comuneResidenza: '',
        descrComuneResidenza: '',
      });
    }  
  };
		  
  handleChangeComuneResidenza = event => {
    const nuovoComuneResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioComuneResidenza = textTrim(datiResidenza.comuneResidenza, true); 
    if (nuovoComuneResidenza !== vecchioComuneResidenza) { 		  
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_COMUNE_RESIDENZA_REFERENTE_TERZO_FISICO); 	
      this.setToponimo(nuovoComuneResidenza);
      this.setStradario(nuovoComuneResidenza, '', '');
      this.setState({
        comuneResidenza: nuovoComuneResidenza,
        descrComuneResidenza: (event === null) ? '' : event.label,
        toponimoResidenza: '',
        descrToponimoResidenza: '',
      });
    }
  };
  
  // usato per PROVINCIA ESTERA
  handleChangeComuneResidenzaTextField = event => {
    const nuovoComuneResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioComuneResidenza = textTrim(datiResidenza.descrComuneResidenza, true);
    if (nuovoComuneResidenza !== vecchioComuneResidenza) { 		  
      this.props.updateDatiReferenteTerzoFisico(nuovoComuneResidenza, actionType.UPDATE_COMUNE_RESIDENZA_REFERENTE_TERZO_FISICO); 	
      this.setToponimo('');
      this.setStradario('', '', '');
      this.setState({
        comuneResidenza: '',
        descrComuneResidenza: nuovoComuneResidenza,
      });
      
      // need error Reset if previously had an error
      this.handleError('toponimoResidenza', false);
    }
  };
		 
  handleChangeToponimoResidenza = event => {
    const nuovoToponimoResidenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioToponimoResidenza = textTrim(datiResidenza.toponimoResidenza, true); 
    if (nuovoToponimoResidenza !== vecchioToponimoResidenza) { 	  
      this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_TOPONIMO_RESIDENZA_REFERENTE_TERZO_FISICO); 
    //this.setStradario(this.state.comuneResidenza, (event === null) ? '' : event.value, this.state.descrIndirizzoResidenza);
      this.setState({
        toponimoResidenza: nuovoToponimoResidenza,
        descrToponimoResidenza: (event === null) ? '' : event.label,  
      });
    }  
  };

  // usato per PROVINCIA ESTERA
  handleChangeToponimoResidenzaTextField = event => {
    const nuovoToponimoResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioToponimoResidenza = textTrim(datiResidenza.descrToponimoResidenza, true); 
    if (nuovoToponimoResidenza !== vecchioToponimoResidenza) { 	  
      this.props.updateDatiReferenteTerzoFisico(nuovoToponimoResidenza, actionType.UPDATE_TOPONIMO_RESIDENZA_REFERENTE_TERZO_FISICO); 
    //this.setStradario(this.state.comuneResidenza, (event === null) ? '' : event.value, this.state.descrIndirizzoResidenza);
      this.setState({
        toponimoResidenza: '',
        descrToponimoResidenza: nuovoToponimoResidenza,
      });
    }  
  };
  
  /*
  handleChangeIndirizzoResidenza = event => {
    console.log('#RTCFA DatiReferenteTerzo indirizzoResidenza new value:' + event.value);
    this.props.updateDatiReferenteTerzoFisico(event, actionType.UPDATE_INDIRIZZO_RESIDENZA_REFERENTE_TERZO_FISICO); 	
    this.setState({
      indirizzoResidenza: (event === null) ? '' : event.value,
      descrIndirizzoResidenza: (event === null) ? '' : event.label,        
    });
  };*/
  
  handleChangeIndirizzoResidenza = event => {
    const nuovoIndirizzoResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioIndirizzoResidenza = textTrim(datiResidenza.descrIndirizzoResidenza, true);
    if (nuovoIndirizzoResidenza !== vecchioIndirizzoResidenza) {	  
      this.props.updateDatiReferenteTerzoFisico(nuovoIndirizzoResidenza, actionType.UPDATE_INDIRIZZO_RESIDENZA_REFERENTE_TERZO_FISICO); 	
      this.setState({
        descrIndirizzoResidenza: nuovoIndirizzoResidenza,         
      });
    }  
  };
		  
  handleChangeNumCivicoResidenza = event => {
    const nuovoNumCivicoResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioNumCivicoResidenza = textTrim(datiResidenza.numCivicoResidenza, true);
    if (nuovoNumCivicoResidenza !== vecchioNumCivicoResidenza) {	  
      this.props.updateDatiReferenteTerzoFisico(nuovoNumCivicoResidenza, actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_REFERENTE_TERZO_FISICO); 	
      this.setState({
        numCivicoResidenza: nuovoNumCivicoResidenza, 
      });
    }  
  };
		  
  handleChangeCapResidenza = event => {
    const nuovoCapResidenza = textTrim(event.target.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchioCapResidenza = textTrim(datiResidenza.capResidenza, true);
    if (nuovoCapResidenza !== vecchioCapResidenza) {    
      this.props.updateDatiReferenteTerzoFisico(nuovoCapResidenza, actionType.UPDATE_CAP_RESIDENZA_REFERENTE_TERZO_FISICO); 	
      this.setState({
        capResidenza: nuovoCapResidenza, 
      });
    }  
  };
	  
  handleChangeEmail = event => {
    const nuovaEmail = textTrim(event.target.value, true);
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const vecchiaEmail = textTrim(datiResidenza.email, true);
    if (nuovaEmail !== vecchiaEmail) {     
      this.props.updateDatiReferenteTerzoFisico(nuovaEmail, actionType.UPDATE_EMAIL_REFERENTE_TERZO_FISICO); 	
      this.setState({
        email: nuovaEmail, 
      });
    }  
  };
 
  handleClickReferenteTerzo = (event) => {
    if (event.target.value !== 'S') {
      this.setState({
        flReferenteTerzo: event.target.value,
        renderChildren: {
          digitabile: false,
        }
      });
    } else {
      this.setState({
        flReferenteTerzo: event.target.value,
    	renderChildren: {
          digitabile: true,
        }
      });
    };
  }
  
  setNazioni = (aDate) => {
	  if(textTrim(aDate, true)!==''){
	    const opts = {
	      dataRiferimentoComune: aDate,
	    };
	    fetch(uris.nazioneItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      this.setState({
	        nazioneItems: result,
	      });
	    });
	  }
  }
  
  setProvince = (aDate, nazione) => {
    if(textTrim(nazione, true)!==''){
	    const opts = {
	      dataRiferimentoProv: aDate,
	      nazione: nazione,
	    };
	    fetch(uris.provinciaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      this.setState({
	    	provinciaItems: result,
	      });
	    });
    }else{
        this.setState({
        	provinciaItems: [],
        });
    }
  }
    
  setComuni = (codProvincia, dataNascita) => {
    if(textTrim(codProvincia, true)!=='' && textTrim(dataNascita, true)!==''){
	  const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        this.setState({
          comuneItems: result,
        });
      });
    }else{
      this.setState({
        comuneItems: [],
      });
    }
  }
  
  setProvinceResidenza = (nazione) => {
    if(textTrim(nazione, true)!==''){
	    const opts = {
	      nazione: nazione,
	    };
	    fetch(uris.provinciaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      this.setState({
	    	provinciaResidenzaItems: result,
	      });
	    });
    }else{
        this.setState({
        	provinciaResidenzaItems: [],
        });
    }
  }
  
  setComuniResidenza = (codProvincia, dataNascita) => {
    if(textTrim(codProvincia, true)!=='' && textTrim(dataNascita, true)!==''){
	  const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        this.setState({
          comuneResidenzaItems: result,
        });
      });
    }else{
      this.setState({
        comuneResidenzaItems: [],
      });
    }
  }
  
  setToponimo = (codiceIstat) => {
    if(textTrim(codiceIstat, true)!==''){
	  const opts = {
	    codiceIstat: codiceIstat,
	  };
	  fetch(uris.toponimoItems, fetchParam(opts))
	  .then (
	    response => response.json(),
	    error => console.error('An error occurred.', error),
	  )
	  .then((result) => {
	    this.setState({
	      toponimoItems: result,
	    });
	  });
    }else{
      this.setState({
        toponimoItems: [],
      });
    }
  }
  
  setStradario = (codiceIstat, toponimo, descrStrada) => {
	if(textTrim(codiceIstat, true)!=='' && textTrim(toponimo, true)!==''){
	  const opts = {
        codiceIstat: codiceIstat,
        descrToponimo: toponimo,
        descrStrada: descrStrada,
      };
      fetch(uris.stradarioItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        this.setState({
    	  stradarioItems: result,
        });
      });
	}else{
      this.setState({
        stradarioItems: [],
       });
    }
  }
	
  verificaCodiceFiscale = () => {  
    let controllo = false;
    let codiceFiscaleValido = null;	  

    const referenteTerzoFisico = this.props.referenteTerzoFisico;
    const datiReferenteTerzo = referenteTerzoFisico.datiReferenteTerzo;
    const datiGenerali = datiReferenteTerzo.datiGenerali;
    const codiceFiscale = textTrim(datiGenerali.codiceFiscale, true);
    
    const cognome = textTrim(datiGenerali.cognome, true);
    const nome = textTrim(datiGenerali.nome, true);
    const sesso = textTrim(datiGenerali.sesso, true);
    const dataNascita = datiGenerali.dataNascita; 
    const provinciaNascita = textTrim(datiGenerali.provinciaNascita, true);
    const descrizioneLuogo = textTrim(datiGenerali.descrComuneNascita, true);
    const codiceIstat = textTrim(datiGenerali.comuneNascita, true);
    console.log('########DatiReferenteTerzo verificaCodiceFiscale codiceFiscale: #' + codiceFiscale + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale cognome: #' + cognome + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale nome: #' + nome + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale sesso: #' + sesso + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale dataNascita: #' + dataNascita + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale provinciaNascita: #' + provinciaNascita + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale descrizioneLuogo: #' + descrizioneLuogo + '#');
    console.log('########DatiReferenteTerzo verificaCodiceFiscale codiceIstat: #' + codiceIstat + '#');
    const richiamoServizio = (codiceFiscale !== '') && (cognome !== '') && (nome !== '') && (sesso !== '') && (dataNascita !== null) &&	
                             (provinciaNascita !== '' && provinciaNascita !== PROVINCIA_ESTERA) && (descrizioneLuogo !== '') && (codiceIstat !== '');
    if (richiamoServizio === true) {	
      console.log('########DatiReferenteTerzo verificaCodiceFiscale richiamoServizio === true --> ', richiamoServizio);
      if (isLocal === true) {
        codiceFiscaleValido = true;  
        this.props.updateDatiReferenteTerzoFisico(codiceFiscaleValido, actionType.UPDATE_CODICE_FISCALE_VALIDO_REFERENTE_TERZO_FISICO);  
        this.setState({	
          codiceFiscaleValido: codiceFiscaleValido,      
        });          
      } else {
  		const dataNascitaFormattata = dataNascita.format('DD/MM/YYYY');
		const opts = {
          codiceFiscale: codiceFiscale,
          cognome: cognome,
          nome: nome,
          sesso: sesso,
          dataNascita : dataNascitaFormattata, 
          provinciaNascita : provinciaNascita,
          descrizioneLuogo : descrizioneLuogo,
          codiceIstat : codiceIstat
	    };		
		fetch(uris.controllaCalcolaCodiceFiscale, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
          console.log('########DatiReferenteTerzo verificaCodiceFiscale result', result);
          codiceFiscaleValido = result;
          console.log('########DatiReferenteTerzo verificaCodiceFiscale codiceFiscaleValido', codiceFiscaleValido);
          this.props.updateDatiReferenteTerzoFisico(codiceFiscaleValido, actionType.UPDATE_CODICE_FISCALE_VALIDO_REFERENTE_TERZO_FISICO);
          this.setState({	
            codiceFiscaleValido: codiceFiscaleValido,      
          });          
	    });    	  
      }     
    } else {
      console.log('########DatiReferenteTerzo verificaCodiceFiscale richiamoServizio === false --> ', richiamoServizio); 
      if (provinciaNascita === PROVINCIA_ESTERA && this.state.codiceFiscaleValido !== true) {
    	// assumo che sia sempre valido
    	  codiceFiscaleValido = true; 
    	  this.updateCodiceFiscaleValido(codiceFiscaleValido);
      }
    }
  }
  
  updateCodiceFiscaleValido = (codiceFiscaleValido) => {
    this.props.updateDatiReferenteTerzoFisico(codiceFiscaleValido, actionType.UPDATE_CODICE_FISCALE_VALIDO_REFERENTE_TERZO_FISICO);
    this.setState({	
      codiceFiscaleValido: codiceFiscaleValido,      
    });    
  }
  
  isRequiredFlReferenteTerzo = () => {
    return (this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali.flReferenteTerzo === 'S');
  };
  
  isRequiredSesso = () => {
	  return this.isRequiredFlReferenteTerzo();
  };
  isRequiredCodFisc = () => {
	  return this.isRequiredFlReferenteTerzo();
  };
  isRequiredCognome = () => {
	  return this.isRequiredFlReferenteTerzo();
  };
  isRequiredNome = () => {
	  return this.isRequiredFlReferenteTerzo();
  };			  
  isRequiredDataNascita = () => {
	  return this.isRequiredFlReferenteTerzo();
  };	 
  isRequiredStatoNascita = () => {
	  return this.isRequiredFlReferenteTerzo();
  };	 
  isRequiredProvinciaNascita = () => {
	  return this.isRequiredFlReferenteTerzo();
  };
  isRequiredComuneNascita = () => {
	  return this.isRequiredFlReferenteTerzo();
  };	
  isRequiredStatoResidenza = () => {
	  return this.isRequiredFlReferenteTerzo();
  };	
  isRequiredProvinciaResidenza = () => {
	  return this.isRequiredFlReferenteTerzo();
  };	
  isRequiredComuneResidenza = () => {
	  return this.isRequiredFlReferenteTerzo();
  };			  
  isRequiredToponimoResidenza = () => {
	  return this.isRequiredFlReferenteTerzo() && this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA;
  };
  isRequiredIndirizzoResidenza = () => {
	  return this.isRequiredFlReferenteTerzo() && this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA;
  };
  isRequiredNumCivicoResidenza = () => {
	  return this.isRequiredFlReferenteTerzo() && this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA;
  };
  isRequiredCapResidenza = () => {
	  return this.isRequiredFlReferenteTerzo() && this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA;
  };
  isRequiredEmail = () => {
	  return false;
  };
	   
  initStatoCampi = () => {
//    if (this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali.flReferenteTerzo === 'S') {
      return [ {id: 'referenteTerzo', isError: false},
      	       {id: 'sesso', isError: false},
	           {id: 'codFisc', isError: false}, 
	           {id: 'cognome', isError: false}, 
	           {id: 'nome', isError: false},
	           {id: 'dataNascitaReferenteTerzo', isError: false},
	           {id: 'statoNascita', isError: false},
	           {id: 'provinciaNascita', isError: false},
	           {id: 'comuneNascita', isError: false},	             
	           {id: 'statoResidenza', isError: false},
	           {id: 'provinciaResidenza', isError: false}, 
	           {id: 'comuneResidenza', isError: false}, 
	           {id: 'toponimoResidenza', isError: false},
	           {id: 'indirizzoResidenza', isError: false},             
	           {id: 'numCivDiResidenza', isError: false},
	           {id: 'capDiResidenza', isError: false},
	           {id: 'indEmail', isError: false},             
	    	 ];
//    } else {
//      return [ {id: 'referenteTerzo', isError: false},
//             ]; 
//    }
  }
	  
  handleError = (id, isError) => {
    console.log('########DatiReferenteTerzo verificaCodiceFiscale handleError id', id);	  
    console.log('########DatiReferenteTerzo verificaCodiceFiscale handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],
    });
  }
	  
  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }

  isDisabled = () => {
    //return this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali.flReferenteTerzo === 'N';
    return false;
  }
  
  isDisabledStato = () => {
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const dataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY');
	return (this.isDisabled() === true) || (dataNascitaFormattata === null);
  }
  
  isDisabledProvincia = () => {
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const statoNascita = textTrim(datiGenerali.statoNascita, true); 
	return (this.isDisabledStato() === true) || (statoNascita === '');
  }
 
  isDisabledComune = () => {
    const datiGenerali = this.props.referenteTerzoFisico.datiReferenteTerzo.datiGenerali;
    const provinciaNascita = textTrim(datiGenerali.provinciaNascita, true); 
	return (this.isDisabledProvincia() === true) || (provinciaNascita === '');
  }
    
  isDisabledProvinciaResidenza = () => {
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const statoResidenza = textTrim(datiResidenza.statoResidenza, true); 
	return (this.isDisabled() === true) || (statoResidenza === '');
  }
 
  isDisabledComuneResidenza = () => {
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const provinciaResidenza = textTrim(datiResidenza.provinciaResidenza, true); 
	return (this.isDisabledProvinciaResidenza() === true) || (provinciaResidenza === '');
  }
   
  isDisabledToponimoResidenza = () => {
    const datiResidenza = this.props.referenteTerzoFisico.datiReferenteTerzo.datiResidenza;
    const comuneResidenza = textTrim(datiResidenza.comuneResidenza, true); 
	return (this.isDisabledComuneResidenza() === true) || (comuneResidenza === '');
  }
    
  render() {
    const { t, classes, labelFieldSet, dominio } = this.props;   
    const controlloDati = this.props.controlloDatiReferenteTerzoFisico;
    const referenteTerzoFisico = this.props.referenteTerzoFisico;
    const datiReferenteTerzo = referenteTerzoFisico.datiReferenteTerzo;
    const datiGenerali = datiReferenteTerzo.datiGenerali;
    const datiResidenza = datiReferenteTerzo.datiResidenza;
    const disabled = this.isDisabled();
    
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('esecutore')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1' />
	    <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={datiGenerali.flReferenteTerzo==='S'?4:12} xs={12}>
            <RadioButtonComp onClick={this.handleClickReferenteTerzo} onChange={this.handleChangeReferenteTerzo} radioComp={radioButtonEsisteReferenteTerzo} 
                             selectedValue={datiGenerali.flReferenteTerzo} 
          	              	 required={true} controlloDati={controlloDati} id='referenteTerzo' onError={this.handleError} />
          </Grid>
          {datiGenerali.flReferenteTerzo === 'S' && 
           <Fragment>
	         <Grid item md={4} xs={12}>
	           <RadioButtonComp onChange={this.handleChangeSesso} id='sesso' radioComp={radioButtonSesso} selectedValue={datiGenerali.sesso} 
                                required={this.isRequiredSesso()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
             </Grid>
	         <Grid item md={4} xs={12}>
	           <TextFieldComp onBlur={this.handleChangeCodFisc} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='codFisc' label='codiceFiscale' 
                              value={datiGenerali.codiceFiscale} required={this.isRequiredCodFisc()} controlloDati={controlloDati} disabled={disabled} maxLength={16} 
	           				  onError={this.handleError} isControlError={this.getIsInError('codFisc')} />
	          </Grid>
	          <Grid item md={4} xs={12}>
	            <TextFieldComp onBlur={this.handleChangeCognome} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='cognome' label='general.cognome' 
                               value={datiGenerali.cognome} required={this.isRequiredCognome()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
	          </Grid>
	          <Grid item md={4} xs={12}>	
	            <TextFieldComp onBlur={this.handleChangeNome} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='nome' label='general.nome' value={datiGenerali.nome} 
                               required={this.isRequiredNome()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
	          </Grid>
	          <Grid item md={4} xs={12}>
	            <DatePickerComp onChange={this.handleChangeDataNascita} aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} 
	                            id='dataNascitaReferenteTerzo' label='dataNascita' value={datiGenerali.dataNascita} 
	                            required={this.isRequiredDataNascita()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError}/>
  	          </Grid>
	          <Grid item md={4} xs={12}>
	            <SelectComp onChange={this.handleChangeStatoNascita} options={this.state.nazioneItems} ricerca='comboRicerca' id='statoNascita' etichetta='statoNascita' 
	            	        value={{value: datiGenerali.statoNascita, label: datiGenerali.descrStatoNascita}} 
	            	        required={this.isRequiredStatoNascita()} controlloDati={controlloDati} disabled={this.isDisabledStato()} onError={this.handleError} />
	          </Grid>
	          <Grid item md={4} xs={12}>	
	            <SelectComp onChange={this.handleChangeProvinciaNascita} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provinciaNascita' 
	            	        id='provinciaNascita' value={{value: datiGenerali.provinciaNascita, label: datiGenerali.descrProvinciaNascita}} 
	                        required={this.isRequiredProvinciaNascita()} controlloDati={controlloDati} disabled={this.isDisabledProvincia()} onError={this.handleError} />
	          </Grid>
	          <Grid item md={4} xs={12}>
	          {datiGenerali.provinciaNascita !== PROVINCIA_ESTERA &&
                <SelectComp onChange={this.handleChangeComuneNascita} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comuneNascita' 
	            	        value={{value: datiGenerali.comuneNascita, label: datiGenerali.descrComuneNascita}} id='comuneNascita' 
	            	        required={this.isRequiredComuneNascita()} controlloDati={controlloDati} disabled={this.isDisabledComune()} onError={this.handleError} />
              }
              {datiGenerali.provinciaNascita === PROVINCIA_ESTERA &&
                <TextFieldComp onBlur={this.handleChangeComuneNascitaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneNascita' label='comuneNascita' 
                               value={datiGenerali.descrComuneNascita} required={this.isRequiredComuneNascita()} controlloDati={controlloDati} disabled={disabled}
                               onError={this.handleError} />
              }
	          </Grid>
              <Grid item md={4} xs={12}>
	            <SelectComp onChange={this.handleChangeStatoResidenza} options={dominio.nazioneItems} ricerca='comboRicerca' etichetta='statoResidenza' 
	            	        value={{value: datiResidenza.statoResidenza, label: datiResidenza.descrStatoResidenza}} id='statoResidenza'
	            	        required={this.isRequiredStatoResidenza()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
	          </Grid>
	          <Grid item md={4} xs={12}>	
	            <SelectComp onChange={this.handleChangeProvinciaResidenza} options={this.state.provinciaResidenzaItems} ricerca='comboRicerca' 
                            etichetta='provinciaResidenza' id='provinciaResidenza' value={{value: datiResidenza.provinciaResidenza, label: datiResidenza.descrProvinciaResidenza}} 
                            required={this.isRequiredProvinciaResidenza()} controlloDati={controlloDati} disabled={this.isDisabledProvinciaResidenza()} onError={this.handleError} />
	          </Grid>
	          <Grid item md={4} xs={12}>
              {datiResidenza.provinciaResidenza !== PROVINCIA_ESTERA &&
                <SelectComp onChange={this.handleChangeComuneResidenza} options={this.state.comuneResidenzaItems} ricerca='comboRicerca' 
	            	        etichetta='comuneResidenza' id='comuneResidenza' value={{value: datiResidenza.comuneResidenza, label: datiResidenza.descrComuneResidenza}} 
	            	        required={this.isRequiredComuneResidenza()} controlloDati={controlloDati} disabled={this.isDisabledComuneResidenza()} onError={this.handleError} />
              }
              {datiResidenza.provinciaResidenza === PROVINCIA_ESTERA &&
	          		<TextFieldComp onBlur={this.handleChangeComuneResidenzaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneResidenza' label='comuneResidenza' 
	    	             value={datiResidenza.descrComuneResidenza} required={this.isRequiredComuneResidenza()} controlloDati={controlloDati} disabled={disabled}
	                     onError={this.handleError} />
	          	}
	          </Grid>
	          <Grid item md={3} xs={12}>
              {datiResidenza.provinciaResidenza!==PROVINCIA_ESTERA &&
                <SelectComp onChange={this.handleChangeToponimoResidenza} options={this.state.toponimoItems} ricerca='comboRicerca' etichetta='toponimo' 
    	                    value={{value: datiResidenza.toponimoResidenza, label: datiResidenza.descrToponimoResidenza}} id='toponimoResidenza'
	                        required={this.isRequiredToponimoResidenza()} controlloDati={controlloDati} disabled={this.isDisabledToponimoResidenza()} onError={this.handleError} />
              }
              {datiResidenza.provinciaResidenza===PROVINCIA_ESTERA &&
                <TextFieldComp onBlur={this.handleChangeToponimoResidenzaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='toponimoResidenza' label='toponimo' 
                               value={datiResidenza.descrToponimoResidenza} required={this.isRequiredToponimoResidenza()} controlloDati={controlloDati} disabled={disabled}
                               onError={this.handleError} />
              }
	          </Grid>
	          <Grid item md={5} xs={12}>	
	          {/*<SelectComp onChange={this.handleChangeIndirizzoResidenza} options={this.state.stradarioItems} ricerca='comboRicerca' etichetta='indirizzo' value={{value: datiResidenza.indirizzoResidenza, 
	                         label: datiResidenza.descrIndirizzoResidenza}} required={this.isRequiredIndirizzoResidenza()} controlloDati={controlloDati} 
	                         disabled={disabled} id='indirizzoResidenza' onError={this.handleError} />*/}
	            <TextFieldComp onBlur={this.handleChangeIndirizzoResidenza} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='indirizzoResidenza' label='indirizzo' 
	                           value={datiResidenza.descrIndirizzoResidenza} required={this.isRequiredIndirizzoResidenza()} controlloDati={controlloDati} 
	                           disabled={disabled} onError={this.handleError} />
	          </Grid>
	          <Grid item md={2} xs={12}>
	            <TextFieldComp onBlur={this.handleChangeNumCivicoResidenza} aStyle={classes.textField} id='numCivDiResidenza' label='numCivico' 
            	               value={datiResidenza.numCivicoResidenza} required={this.isRequiredNumCivicoResidenza()} controlloDati={controlloDati} 
                               disabled={disabled} onError={this.handleError} />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextFieldComp onBlur={this.handleChangeCapResidenza} aStyle={classes.textField} id='capDiResidenza' label='cap'value={datiResidenza.capResidenza} 
                               required={this.isRequiredCapResidenza()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
              </Grid>	
              <Grid item md={4} xs={12}>
                 <TextFieldAdornComp onBlur={this.handleChangeEmail} id='indEmail' label='indEmail' aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                                     value={datiReferenteTerzo.email}  margin='normal' variant='outlined' adornComp={ <Email /> } adornPosit='start'
                                     required={this.isRequiredEmail()} controlloDati={controlloDati} disabled={disabled} onError={this.handleError} />
              </Grid>
              <Grid item md={8} xs={12}></Grid>
            </Fragment>
          }
          </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiReferenteTerzo componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//	    console.log('########DatiReferenteTerzo componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	    console.log('########DatiReferenteTerzo componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDatiReferenteTerzoFisico === true)) {
      
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
//      this.props.updateDatiReferenteTerzoFisico(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_REFERENTE_TERZO_FISICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });
	}
  }
	  
  isInBusinessError(prevProps, prevState) {
  /*	const datiGenerali = this.datiGeneraliFrom(this.props.figura);
  	const prevDatiGenerali = this.prevDatiGeneraliFrom(this.props.figura, prevProps);
  	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError datiGenerali.codiceFiscaleValido', datiGenerali.codiceFiscaleValido);
  	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError prevDatiGenerali.codiceFiscaleValido', prevDatiGenerali.codiceFiscaleValido);
  	if (datiGenerali.codiceFiscaleValido !== prevDatiGenerali.codiceFiscaleValido) {
  	  this.handleError('codFisc', !datiGenerali.codiceFiscaleValido);		
  	} */
  	console.log('########DatiReferenteTerzo verificaCodiceFiscale isInBusinessError prevState.codiceFiscaleValido', prevState.codiceFiscaleValido);
  	console.log('########DatiReferenteTerzo verificaCodiceFiscale isInBusinessError this.state.codiceFiscaleValido', this.state.codiceFiscaleValido);
  	if (this.state.codiceFiscaleValido!==null && this.state.codiceFiscaleValido !== prevState.codiceFiscaleValido) {
  	  this.handleError('codFisc', !this.state.codiceFiscaleValido);		
  	}
  }
      
  componentDidUpdate(prevProps, prevState) {
    this.isInBusinessError(prevProps, prevState);
    this.isInError(prevProps, prevState);
  }
}

DatiReferenteTerzo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiReferenteTerzo)));