import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiOpzioniContrattuali from './sezione/DatiOpzioniContrattuali';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function OpzioniContrattualiPanel(props) {
	
  const { classes, isValida } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'opzioniContrattuali'} componente={DatiOpzioniContrattuali} isValida={isValida} /> 
    </div>
  );
}

OpzioniContrattualiPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OpzioniContrattualiPanel);
