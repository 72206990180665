import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/it';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.color.tan,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
    marginBottom: theme.spacing(2),
  },
  intestazione: {
	width: '100%',
	maxWidth: '100%',
    backgroundColor: theme.palette.color.windowsBlue,
    marginTop: theme.spacing(2),
  },
});

class DatiListaUtenza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: '',
    };
  };
    
  handleListItemClick = (event, index, user) => {
    this.setState({ selectedIndex: index });
    if(this.props.mostraDettaglio) {
      this.props.mostraDettaglio(event, user);
    }
  };
  
  render() {
    const { classes, utenti } = this.props;  
    const { t } = this.props; //hoc
    
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">	
        <Grid item xs={12}>
          <div className={classes.intestazione}>
            <ListItem>
	          <ListItemText style={{width: '23%'}}><Typography variant="subtitle2">{t('general.cognome')}</Typography></ListItemText>
	          <ListItemText style={{width: '22%'}}><Typography variant="subtitle2">{t('general.nome')}</Typography></ListItemText>
	          <ListItemText style={{width: '27%'}}><Typography variant="subtitle2">{t('indEmail')}</Typography></ListItemText>
	          <ListItemText style={{width: '14%'}}><Typography variant="subtitle2">{t('dataCreazione')}</Typography></ListItemText>
	          <ListItemText style={{width: '14%'}}><Typography variant="subtitle2">{t('dataScadenza')}</Typography></ListItemText>
	        </ListItem>
	      </div>
	      <div className={classes.root}>
            <List>
              {utenti.map((elementoDetail, indexElementoDetail) => (
                <ListItem button selected={this.state.selectedIndex === {indexElementoDetail}} onClick={event => this.handleListItemClick(event, indexElementoDetail, elementoDetail.user)}>
                  <ListItemText style={{width: '23%'}}><Typography variant="caption">{elementoDetail.cognome}</Typography></ListItemText>
                  <ListItemText style={{width: '23%'}}><Typography variant="caption">{elementoDetail.nome}</Typography></ListItemText>
                  <ListItemText style={{width: '30%'}}><Typography variant="caption">{elementoDetail.email}</Typography></ListItemText>
                  <ListItemText style={{width: '12%'}}><Typography variant="caption">{moment(elementoDetail.dataCreazione, "YYYY-MM-DD").format('DD/MM/YYYY')}</Typography></ListItemText>
                  <ListItemText style={{width: '12%'}}><Typography variant="caption">{moment(elementoDetail.dataScadenzaPwd, "YYYY-MM-DD").format('DD/MM/YYYY')}</Typography></ListItemText>
                </ListItem>
              ))}
            </List>
          </div>	
        </Grid>
	  </Grid>
    );
  }
}

DatiListaUtenza.propTypes = {
  classes: PropTypes.object.isRequired, 
};

export default withTranslation()(withStyles(styles)(DatiListaUtenza));
