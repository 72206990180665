export const dominio = {
	nazioneItems: undefined,
	provinciaItems: undefined,
	tipoDocumentoItems: undefined,
	enteRilascioItems: undefined,
	scopoNaturaRapportoItems: undefined,
	tipoSocietaItems: undefined,
	beneficiarioGenericoMorteItems: undefined,
	beneficiarioGenericoVitaItems: undefined,
	prevalenteAttivitaSvoltaItems: undefined,
	prevalenteAttivitaSvoltaGiuridicoItems: undefined,
	origineFondiItems: undefined,
	allocazioneItems: undefined,
	fondiItems: undefined,
	percTakeProfitItems: undefined,
	frequenzaErogazioniPeriodicheItems: undefined,
	modalitaPagamentoItems: undefined,
	bancaItems: undefined,
	consensiItems: undefined,
	dichiarazioniIntermediarioItems: undefined,
	regioneItems: undefined,
	durataItems: undefined,
	classePremioPotenzialeItems: undefined,
	tariffaItems: undefined,
	capitaleAssicuratoMalattiaGraveItems: undefined,
	frequenzaCapitaleAssicuatoItems: undefined,
	ruoloXProdottoItems: undefined,
	mandatoItems: undefined,
	profiloItems: undefined,
	nazioneItaliaItems: undefined,
	professioniQas: undefined,
	sportQas: undefined,
	relazioneItems: undefined,
	paesiBlackListItems: undefined,
	cittadinanzaPaesiBlackListItems: undefined,
	profiloRischioItems: undefined,
	profiloRischioObbligo: undefined,
	rapportoContrIntItems: undefined,
};