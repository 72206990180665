import { jsonCopy } from '../../../utility/genericUtility';
import { datiGeneraliInizializzato, datiResidenzaInizializzato, datiRecapitoInizializzato, datiFiscaliInizializzato, datiConsensoComElettrInizializzato, 
         datiAttEconomicaInizializzato, datiAntiriciclaggioInizializzato, datiEsecutoreInizializzato, 
         datiNaturaRapportoInizializzato, datiFinanziariInizializzato, datiDomicilioInizializzato } from '../componentiBase/sezioniAnagrafica';

export const datiGenerali = jsonCopy(datiGeneraliInizializzato);
export const datiResidenza = jsonCopy(datiResidenzaInizializzato);
export const datiDomicilio = jsonCopy(datiDomicilioInizializzato);
export const datiRecapito = jsonCopy(datiRecapitoInizializzato);
export const datiFiscali = jsonCopy(datiFiscaliInizializzato);
export const datiConsensoComElettr = jsonCopy(datiConsensoComElettrInizializzato);
export const datiAttEconomica = jsonCopy(datiAttEconomicaInizializzato);
export const datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
export const datiEsecutore = jsonCopy(datiEsecutoreInizializzato);
export const datiNaturaRapporto = jsonCopy(datiNaturaRapportoInizializzato);
export const datiFinanziari = jsonCopy(datiFinanziariInizializzato);

