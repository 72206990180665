import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
//import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
//import Phone from '@material-ui/icons/Phone';
import { connect } from 'react-redux';
import { uris, fetchParam, isLocal } from '../../../uris';

import TextFieldComp from '../../common/TextFieldComp';
import DatePickerComp from '../../common/DatePickerComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import { radioButtonSesso } from '../../store/radioButtonStore';

import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';

import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';

import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';

import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';

import { textTrim, dataFormat, isDataValid } from '../../../utility/genericUtility';
import { RAPPRESENTANTE_LEGALE, BENEF_VITA_FISICO, BENEF_MORTE_FISICO, PROVINCIA_ESTERA , TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA } from '../../../utility/jsConstants';
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiGeneraliMinimiRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiGeneraliMinimi,
    controlloDatiRappLegale: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    
    datiGeneraliMinimiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.datiGeneraliBenefVita.datiGeneraliMinimi,
    controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,

    datiGeneraliMinimiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiGeneraliBenefMorte.datiGeneraliMinimi,
    controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,
    
    datiGeneraliMinimiTitEffBenefMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiGeneraliMinimi,
    controlloDatiGeneraliMinimiTitEffBenefMorte: state.areaTitolareEffReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,

    datiGeneraliMinimiTitEffBenefVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiGeneraliMinimi,
    controlloDatiGeneraliMinimiTitEffBenefVita: state.areaTitolareEffReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,

    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiGeneraliMinimiRappLegale: (unDato, unActionType, figura) => dispatch(actionsContraente.updateDatiGeneraliMinimiRappLegale(unDato, unActionType, figura)),
    updateDatiGeneraliMinimiBeneficiarioVitaFisico: (unDato, unActionType, figura) => dispatch(actionsBenefVita.updateDatiGeneraliMinimiBeneficiarioVitaFisico(unDato, unActionType, figura)),
    updateDatiGeneraliMinimiBeneficiarioMorteFisico: (unDato, unActionType, figura) => dispatch(actionsBenefMorte.updateDatiGeneraliMinimiBeneficiarioMorteFisico(unDato, unActionType, figura)),
    updateDatiGeneraliMinimiTitolareEffBenefMorte: (unDato, unActionType, figura) => dispatch(actionsTitolareEff.updateDatiGeneraliMinimiTitolareEffBenefMorte(unDato, unActionType, figura)),
    updateDatiGeneraliMinimiTitolareEffBenefVita: (unDato, unActionType, figura) => dispatch(actionsTitolareEff.updateDatiGeneraliMinimiTitolareEffBenefVita(unDato, unActionType, figura)),
  };
};
	
class DatiGeneraliMinimi extends React.Component {
	
  constructor(props) {
    super(props);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom (this.props.figura);
    this.state = {
      sesso: datiGeneraliMinimi.sesso,
      codiceFiscale: datiGeneraliMinimi.codiceFiscale,
      cognome: datiGeneraliMinimi.cognome,
      nome: datiGeneraliMinimi.nome,      
      dataNascita: datiGeneraliMinimi.dataNascita,
      statoNascita: datiGeneraliMinimi.statoNascita,
      descrStatoNascita: datiGeneraliMinimi.descrStatoNascita,
      provinciaNascita: datiGeneraliMinimi.provinciaNascita,
      descrProvinciaNascita: datiGeneraliMinimi.descrProvinciaNascita,
      comuneNascita: datiGeneraliMinimi.comuneNascita,
      descrComuneNascita: datiGeneraliMinimi.descrComuneNascita,
      nazioneItems: [],
      provinciaItems: [],
      comuneItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,	      
      codiceFiscaleValido: null,
    };
  };

  componentDidMount() {	  
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);
    const dataNascitaFormattata = datiGeneraliMinimi && datiGeneraliMinimi.dataNascita ? dataFormat(datiGeneraliMinimi.dataNascita, 'DD/MM/YYYY') : '';
    this.setNazioni(dataNascitaFormattata);
    this.setProvince(dataNascitaFormattata, datiGeneraliMinimi ? datiGeneraliMinimi.statoNascita : '');
    this.setComuni(datiGeneraliMinimi ? datiGeneraliMinimi.provinciaNascita : '', dataNascitaFormattata);
  }
	  
  updateDatiGenMinimi = (unDato, anActionType) => {
    const figura = this.props.figura;
    if (figura === RAPPRESENTANTE_LEGALE) {
	  this.props.updateDatiGeneraliMinimiRappLegale(unDato, anActionType, figura);
    } else if (figura === BENEF_VITA_FISICO) {
 	  this.props.updateDatiGeneraliMinimiBeneficiarioVitaFisico(unDato, anActionType, figura);
    } else if (figura === BENEF_MORTE_FISICO) {
      this.props.updateDatiGeneraliMinimiBeneficiarioMorteFisico(unDato, anActionType, figura);
    } else if (figura === TITOLARE_EFF_BENEF_MORTE){
      this.props.updateDatiGeneraliMinimiTitolareEffBenefMorte(unDato, anActionType, figura);
    }else if (figura === TITOLARE_EFF_BENEF_VITA){
      this.props.updateDatiGeneraliMinimiTitolareEffBenefVita(unDato, anActionType, figura);
    }
  }

  handleChangeSesso = (event) => {
    const nuovoSesso = textTrim(event.target.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioSesso = textTrim(datiGeneraliMinimi.sesso, true);     
    if (nuovoSesso !== vecchioSesso) {    	  
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_SESSO;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_SESSO_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_SESSO_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        actionType = actionTypeTitolareEff.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        actionType = actionTypeTitolareEff.UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA;
      }

      this.updateDatiGenMinimi (nuovoSesso, actionType);
      this.verificaCodiceFiscale('sesso', nuovoSesso, null);
      this.setState({
        sesso: nuovoSesso,
      });
    }
  }
  
  handleChangeCodiceFiscale = (event) => {
    const nuovoCodiceFiscale = textTrim(event.target.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioCodiceFiscale = textTrim(datiGeneraliMinimi.codiceFiscale, true);      
    if (nuovoCodiceFiscale !== vecchioCodiceFiscale) {
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_CODICE_FISCALE;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_CODICE_FISCALE_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_CODICE_FISCALE_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA){
        actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA;
      }
      this.updateDatiGenMinimi (nuovoCodiceFiscale, actionType);
      this.verificaCodiceFiscale('codFisc', nuovoCodiceFiscale, null);
      this.setState({
        codiceFiscale: nuovoCodiceFiscale,
      });    	
    }
  }

  handleChangeCognome = (event) => {
    const nuovoCognome = textTrim(event.target.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioCognome = textTrim(datiGeneraliMinimi.cognome, true);      
    if (nuovoCognome !== vecchioCognome) {    
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_COGNOME;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_COGNOME_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_COGNOME_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        actionType = actionTypeTitolareEff.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA){
        actionType = actionTypeTitolareEff.UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA;
      }
      this.updateDatiGenMinimi (nuovoCognome, actionType);
      this.verificaCodiceFiscale('cognome', nuovoCognome, null);
      this.setState({
        cognome: nuovoCognome,
      });	    	
    }
  }
	
  handleChangeNome = (event) => {
    const nuovoNome = textTrim(event.target.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioNome = textTrim(datiGeneraliMinimi.nome, true);        
    if (nuovoNome !== vecchioNome) { 	
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_NOME;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_NOME_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_NOME_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        actionType = actionTypeTitolareEff.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA){
        actionType = actionTypeTitolareEff.UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA;
      }
      this.updateDatiGenMinimi (nuovoNome, actionType);
      this.verificaCodiceFiscale('nome', nuovoNome, null);
      this.setState({
        nome: nuovoNome,
      });
    }
  }

  handleChangeDataNascita = (event) => {
    if (isDataValid(event)) {	  
      const nuovaDataNascitaFormattata = dataFormat(event, 'DD/MM/YYYY');
      const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
      const vecchiaDataNascitaFormattata = dataFormat(datiGeneraliMinimi.dataNascita, 'DD/MM/YYYY');            
      if (nuovaDataNascitaFormattata !== vecchiaDataNascitaFormattata) { 	
        let actionType = null;			
        if (this.props.figura === RAPPRESENTANTE_LEGALE) {
          actionType = actionTypeContraente.UPDATE_DATA_NASCITA;
        } else if (this.props.figura === BENEF_VITA_FISICO) {
          actionType = actionTypeBenefVita.UPDATE_DATA_NASCITA_BENEF_VITA_FISICO;
        } else if (this.props.figura === BENEF_MORTE_FISICO) {
          actionType = actionTypeBenefMorte.UPDATE_DATA_NASCITA_BENEF_MORTE_FISICO;
        } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE){
          actionType = actionTypeTitolareEff.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_MORTE;
        } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA){
          actionType = actionTypeTitolareEff.UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA;
        }  
        this.updateDatiGenMinimi (event, actionType);
        this.verificaCodiceFiscale('dataNascita', event, null);
        this.setNazioni(nuovaDataNascitaFormattata);
        this.setProvince(nuovaDataNascitaFormattata, '');
        this.setComuni('', nuovaDataNascitaFormattata);
        this.setState({
          dataNascita: event,
          statoNascita: '',
          descrStatoNascita: '',
          provinciaNascita: '',
          descrProvinciaNascita: '',
          comuneNascita: '' ,
          descrComuneNascita: '',
        });
      } else {
        console.log('###DATA NON VALIDA ' + this.props.figura + ' handleChangeDataNascita', event);
      }        
    }
  }
	
  handleChangeStatoNascita = (event) => {
    const nuovoStatoNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioStatoNascita = textTrim(datiGeneraliMinimi.statoNascita, true);            
    if (nuovoStatoNascita !== vecchioStatoNascita) {
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_STATO_NASCITA;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_STATO_NASCITA_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_STATO_NASCITA_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        actionType = actionTypeTitolareEff.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA){
        actionType = actionTypeTitolareEff.UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA;
      }
      const descrizioneStatoNascita = (event === null) ? '' : event.label;
      this.updateDatiGenMinimi (event, actionType);
      this.verificaCodiceFiscale('statoNascita', nuovoStatoNascita, descrizioneStatoNascita);
      if ((this.state.dataNascita !== null) && (this.state.dataNascita !== undefined)) {
        this.setProvince(this.state.dataNascita.format('DD/MM/YYYY'), nuovoStatoNascita);
        this.setComuni('', this.state.dataNascita.format('DD/MM/YYYY'));
      }
      this.setState({
        statoNascita: nuovoStatoNascita,
        descrStatoNascita: descrizioneStatoNascita,
		provinciaNascita: '',
        descrProvinciaNascita: '',
      });
    }
  }

  handleChangeProvinciaNascita_OLD = (event) => {
    const nuovaProvinciaNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchiaProvinciaNascita = textTrim(datiGeneraliMinimi.provinciaNascita, true);    
    if (nuovaProvinciaNascita !== vecchiaProvinciaNascita) {     
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_PROVINCIA_NASCITA;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_PROVINCIA_NASCITA_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_PROVINCIA_NASCITA_BENEF_MORTE_FISICO;
      }
      const descrizioneProvinciaNascita = (event === null) ? '' : event.label;
      this.updateDatiGenMinimi (event, actionType);
      this.verificaCodiceFiscale('provinciaNascita', nuovaProvinciaNascita, descrizioneProvinciaNascita);
      if ((this.state.dataNascita !== null) && (this.state.dataNascita !== undefined)) {
        this.setComuni(nuovaProvinciaNascita, this.state.dataNascita.format('DD/MM/YYYY'));
      }
      this.setState({
        provinciaNascita: nuovaProvinciaNascita,
        descrProvinciaNascita: descrizioneProvinciaNascita,
        comuneNascita: '',
        descrComuneNascita: '',
      });
    }
  }
  
  handleChangeProvinciaNascita = (event) => {
    const nuovaProvinciaNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchiaProvinciaNascita = textTrim(datiGeneraliMinimi.provinciaNascita, true);    
    if (nuovaProvinciaNascita !== vecchiaProvinciaNascita) {     
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType = actionTypeContraente.UPDATE_PROVINCIA_NASCITA;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_PROVINCIA_NASCITA_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_PROVINCIA_NASCITA_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        actionType = actionTypeTitolareEff.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA){
        actionType = actionTypeTitolareEff.UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA;
      }
      const descrizioneProvinciaNascita = (event === null) ? '' : event.label;
      this.updateDatiGenMinimi (event, actionType);
      if (nuovaProvinciaNascita === PROVINCIA_ESTERA) {
        this.updateCodiceFiscaleValido(true);    	  
      } else {
        this.verificaCodiceFiscale('provinciaNascita', nuovaProvinciaNascita, descrizioneProvinciaNascita);
      }
      if ((this.state.dataNascita !== null) && (this.state.dataNascita !== undefined) && (nuovaProvinciaNascita !== PROVINCIA_ESTERA)) {
        this.setComuni(nuovaProvinciaNascita, this.state.dataNascita.format('DD/MM/YYYY'));
      }
      this.setState({
        provinciaNascita: nuovaProvinciaNascita,
        descrProvinciaNascita: descrizioneProvinciaNascita,
        comuneNascita: '',
        descrComuneNascita: '',
      });
    }
  }
    
  handleChangeComuneNascita = (event) => {
    const nuovoComuneNascita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioComuneNascita = textTrim(datiGeneraliMinimi.comuneNascita, true);     
    if (nuovoComuneNascita !== vecchioComuneNascita) {     
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType =  actionTypeContraente.UPDATE_COMUNE_NASCITA;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_COMUNE_NASCITA_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_COMUNE_NASCITA_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        actionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        actionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA;
      }
      const descrizioneComuneNascita = (event === null) ? '' : event.label;
      this.updateDatiGenMinimi (event, actionType);
      this.verificaCodiceFiscale('codiceIstat', nuovoComuneNascita, descrizioneComuneNascita);
      this.setState({
        comuneNascita: nuovoComuneNascita,
        descrComuneNascita: descrizioneComuneNascita,
      });
    }
  }

  // usata per PROVINCIA ESTERA
  handleChangeComuneNascitaTextField = event => {
    const nuovoComuneNascita = textTrim(event.target.value, true);
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);    
    const vecchioComuneNascita = textTrim(datiGeneraliMinimi.descrComuneNascita, true);      
    if (nuovoComuneNascita !== vecchioComuneNascita) {
      let actionType = null;
      if (this.props.figura === RAPPRESENTANTE_LEGALE) {
        actionType =  actionTypeContraente.UPDATE_COMUNE_NASCITA;
      } else if (this.props.figura === BENEF_VITA_FISICO) {
        actionType = actionTypeBenefVita.UPDATE_COMUNE_NASCITA_BENEF_VITA_FISICO;
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        actionType = actionTypeBenefMorte.UPDATE_COMUNE_NASCITA_BENEF_MORTE_FISICO;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        actionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        actionType = actionTypeTitolareEff.UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA;
      }
      this.updateDatiGenMinimi(nuovoComuneNascita, actionType);
//      this.verificaCodiceFiscale();
      this.setState({
        comuneNascita: '' ,
        descrComuneNascita: (event === null) ? '' : nuovoComuneNascita,
      });
    }  
  }
  
  datiGeneraliMinimiFrom = figura => {
    let datiGeneraliMinimi = null;
    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      datiGeneraliMinimi = this.props.datiGeneraliMinimiRappLegale;
    } else if (this.props.figura === BENEF_VITA_FISICO) {
      datiGeneraliMinimi = this.props.datiGeneraliMinimiBenefVitaFisico;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      datiGeneraliMinimi = this.props.datiGeneraliMinimiBenefMorteFisico;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE ){
      datiGeneraliMinimi = this.props.datiGeneraliMinimiTitEffBenefMorte;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA ){
      datiGeneraliMinimi = this.props.datiGeneraliMinimiTitEffBenefVita;
    }
	return datiGeneraliMinimi;
  }
	
  verificaCodiceFiscale = (tipoDato, unDato, unaLabel) => {  
    let controllo = false;
    let codiceFiscaleValido = null;	  
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(this.props.figura);
    const codiceFiscale = (tipoDato === 'codFisc') ? unDato : textTrim(datiGeneraliMinimi.codiceFiscale, true);
    const cognome = (tipoDato === 'cognome') ? unDato : textTrim(datiGeneraliMinimi.cognome, true);
    const nome = (tipoDato === 'nome') ? unDato : textTrim(datiGeneraliMinimi.nome, true);
    const sesso = (tipoDato === 'sesso') ? unDato : textTrim(datiGeneraliMinimi.sesso, true);
    const dataNascita = (tipoDato === 'dataNascita') ? unDato : datiGeneraliMinimi.dataNascita; 
    const provinciaNascita = (tipoDato === 'provinciaNascita') ? unDato : textTrim(datiGeneraliMinimi.provinciaNascita, true);
//    const descrizioneLuogo = textTrim(datiGeneraliMinimi.descrComuneNascita, true);
    const descrizioneLuogo = (tipoDato === 'codiceIstat') ? unaLabel : textTrim(datiGeneraliMinimi.descrComuneNascita, true);
    const codiceIstat = (tipoDato === 'codiceIstat') ? unDato : textTrim(datiGeneraliMinimi.comuneNascita, true);  
    const richiamoServizio = (codiceFiscale !== '') && (cognome !== '') && (nome !== '') && (sesso !== '') && (dataNascita !== null) &&	
                             (provinciaNascita !== '' && provinciaNascita !== PROVINCIA_ESTERA) && (descrizioneLuogo !== '') && (codiceIstat !== '');
                          
                             console.log('########DatiGeneraliMinimi verificaCodiceFiscale richiamoServizio === true --> ', richiamoServizio);
    if (richiamoServizio === true) {
      console.log('########DatiGeneraliMinimi verificaCodiceFiscale richiamoServizio === true --> ', richiamoServizio);
      if (isLocal === true) {
        codiceFiscaleValido = true;
        let actionType = null;
        if (this.props.figura === RAPPRESENTANTE_LEGALE) {
          actionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO;
        } else if (this.props.figura === BENEF_VITA_FISICO) {
          actionType = actionTypeBenefVita.UPDATE_CODICE_FISCALE_VALIDO_BENEF_VITA_FISICO;
        } else if (this.props.figura === BENEF_MORTE_FISICO) {
          actionType = actionTypeBenefMorte.UPDATE_CODICE_FISCALE_VALIDO_BENEF_MORTE_FISICO;
        } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
          actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_MORTE;
        } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
          actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA;
        }
        this.updateDatiGenMinimi (codiceFiscaleValido, actionType);
        this.setState({	
          codiceFiscaleValido: codiceFiscaleValido,
        });
      } else {
  		const dataNascitaFormattata = moment(dataNascita).format('DD/MM/YYYY');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale codiceFiscale: #' + codiceFiscale + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale cognome: #' + cognome + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale nome: #' + nome + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale sesso: #' + sesso + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale dataNascita: #' + dataNascitaFormattata + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale provinciaNascita: #' + provinciaNascita + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale descrizioneLuogo: #' + descrizioneLuogo + '#');
        console.log('########DatiGeneraliMinimi verificaCodiceFiscale codiceIstat: #' + codiceIstat + '#');	  		
		const opts = {			
          codiceFiscale: codiceFiscale,
          cognome: cognome,
          nome: nome,
          sesso: sesso,
          dataNascita : dataNascitaFormattata, 
          provinciaNascita : provinciaNascita,
          descrizioneLuogo : descrizioneLuogo,
          codiceIstat : codiceIstat
	    };		
		fetch(uris.controllaCalcolaCodiceFiscale, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
          console.log('########DatiGeneraliMinimi verificaCodiceFiscale result', result);
          codiceFiscaleValido = result;
          console.log('########DatiGeneraliMinimi verificaCodiceFiscale codiceFiscaleValido', codiceFiscaleValido);
          
          /* this.updateCodiceFiscaleValido(codiceFiscaleValido); */  
          
          let actionType = null;
          if (this.props.figura === RAPPRESENTANTE_LEGALE) {
            actionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO;
          } else if (this.props.figura===BENEF_VITA_FISICO) {
  	        actionType = actionTypeBenefVita.UPDATE_CODICE_FISCALE_VALIDO_BENEF_VITA_FISICO;
  	      } else if (this.props.figura===BENEF_MORTE_FISICO) {
  	        actionType = actionTypeBenefMorte.UPDATE_CODICE_FISCALE_VALIDO_BENEF_MORTE_FISICO;
  	      }	else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
            actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_MORTE;
          } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
            actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA;
          }
          this.updateDatiGenMinimi(codiceFiscaleValido, actionType);
          this.setState({
            codiceFiscaleValido: codiceFiscaleValido,
          });
	    });
      }
/*    } else {
      console.log('########DatiGeneraliMinimi verificaCodiceFiscale richiamoServizio === false --> ', richiamoServizio);
      if (provinciaNascita === PROVINCIA_ESTERA && this.state.codiceFiscaleValido !== true) {
    	// assumo che sia sempre valido
        codiceFiscaleValido = true; 
        this.updateCodiceFiscaleValido(codiceFiscaleValido);    	  
      } */
    }
  }
  
  updateCodiceFiscaleValido = (codiceFiscaleValido) => {
    let actionType = null;
    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      actionType = actionTypeContraente.UPDATE_CODICE_FISCALE_VALIDO;
    } else if (this.props.figura === BENEF_VITA_FISICO) {
      actionType = actionTypeBenefVita.UPDATE_CODICE_FISCALE_VALIDO_BENEF_VITA_FISICO;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      actionType = actionTypeBenefMorte.UPDATE_CODICE_FISCALE_VALIDO_BENEF_MORTE_FISICO;
    }	else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_MORTE;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      actionType = actionTypeTitolareEff.UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA;
    }       
    this.updateDatiGenMinimi(codiceFiscaleValido, actionType );
    this.setState({	
      codiceFiscaleValido: codiceFiscaleValido,      
    }); 
  }
  
  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      controlloDati = this.props.controlloDatiRappLegale;
    } else if (this.props.figura === BENEF_VITA_FISICO) {
   	  controlloDati = this.props.controlloDatiBenefVitaFisico;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      controlloDati = this.props.controlloDatiBenefMorteFisico;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiGeneraliMinimiTitEffBenefMorte;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiGeneraliMinimiTitEffBenefVita;
    }
	return controlloDati;
  }
	
	isRequiredSesso = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE && this.props.scopeAmleto) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA && this.props.scopeAmleto) {
        required = true;
      }
	    return required;
	  }
	
	  isRequiredCodiceFiscale = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	 
	  isRequiredCognome = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	
	  isRequiredNome = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	 
	  isRequiredDataNascita = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	
	  isRequiredStatoNascita = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	  
	  isRequiredProvinciaNascita = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	
	  isRequiredComuneNascita = () => {
		let required = false;
	    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
	      required = true;
	    } else if (this.props.figura === BENEF_VITA_FISICO) {
	    	required = true;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	required = true;
	    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
        required = true;
      } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
        required = true;
      }
	    return required;
	  }
	  
  setNazioni = (aDate) => {
    const opts = {
      dataRiferimentoComune: aDate,
    };
    fetch(uris.nazioneItems, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
      this.setState({
        nazioneItems: result,
      });
    });
  }
  
  setProvince = (aDate, nazione) => {
    if(textTrim(nazione, true)!==''){
	    const opts = {
	      dataRiferimentoProv: aDate,
	      nazione: nazione,
	    };
	    fetch(uris.provinciaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      this.setState({
	    	provinciaItems: result,
	      });
	    });
    }else{
        this.setState({
        	provinciaItems: [],
        });
    }
  }
	    
  setComuni = (codProvincia, dataNascita) => {
    if(textTrim(codProvincia, true)!=='' && textTrim(dataNascita, true)!==''){
	  const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        this.setState({
          comuneItems: result,
        });
      });
    }else{
      this.setState({
        comuneItems: [],
      });
    }
  }
  
  initStatoCampi = () => {
    return [ {id: 'sesso', isError: false},
             {id: 'codFisc', isError: false}, 
             {id: 'cognome', isError: false}, 
             {id: 'nome', isError: false},
             {id: 'dataNascita', isError: false},             
             {id: 'statoNascita', isError: false},
             {id: 'provinciaNascita', isError: false},
             {id: 'comuneNascita', isError: false},
 	       ];
  }
				  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }

  isDisabled = () => {
    let disabled = null;
    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
      disabled = false;
    } else if (this.props.figura === BENEF_VITA_FISICO) {
      disabled = false;
    } else if (this.props.figura === BENEF_MORTE_FISICO) {
      disabled = false;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
      disabled = false;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA) {
      disabled = false;
    }
	return disabled;
  }
    
  isDisabledStato = () => {
    const datiGenerali = this.datiGeneraliMinimiFrom(this.props.figura);
    const dataNascitaFormattata = dataFormat(datiGenerali.dataNascita, 'DD/MM/YYYY');
	return (this.isDisabled() === true) || (dataNascitaFormattata === null);
  }
  
  isDisabledProvincia = () => {
    const datiGenerali = this.datiGeneraliMinimiFrom(this.props.figura);
    const statoNascita = textTrim(datiGenerali.statoNascita, true); 
	return (this.isDisabledStato() === true) || (statoNascita === '');
  }
 
  isDisabledComune = () => {
    const datiGenerali = this.datiGeneraliMinimiFrom(this.props.figura);
    const provinciaNascita = textTrim(datiGenerali.provinciaNascita, true); 
	return (this.isDisabledProvincia() === true) || (provinciaNascita === '');
  }
    
  render() {
	console.log('#RTCFA DatiGeneraliMinimi.this.props', this.props);
    const { classes, figura, labelFieldSet, dominio } = this.props;
    const datiGeneraliMinimi = this.datiGeneraliMinimiFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura);
//  const { t } = this.props; //hoc    
    return (
      <Fragment>
      {/*
      <fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiGenerali')}</Typography></legend>*/}
        {labelFieldSet &&
          <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        }
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={4} xs={12}>
          	<RadioButtonComp selectedValue={datiGeneraliMinimi.sesso} onChange={this.handleChangeSesso} radioComp={radioButtonSesso} id='sesso'
                             required={this.isRequiredSesso()} controlloDati={controlloDati} disabled={this.isDisabled()} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp id='codFisc' value={datiGeneraliMinimi.codiceFiscale} onBlur={this.handleChangeCodiceFiscale} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
                           required={this.isRequiredCodiceFiscale()} controlloDati={controlloDati} disabled={this.isDisabled()} label='codiceFiscale' 
                           onError={this.handleError} isControlError={this.getIsInError('codFisc')} />				
          </Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <TextFieldComp id='cognome' value={datiGeneraliMinimi.cognome} onBlur={this.handleChangeCognome} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                           required={this.isRequiredCognome()} disabled={this.isDisabled()} controlloDati={controlloDati} label='general.cognome' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>		
            <TextFieldComp id='nome' value={datiGeneraliMinimi.nome} onBlur={this.handleChangeNome} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
                           required={this.isRequiredNome()} disabled={this.isDisabled()} controlloDati={controlloDati} label='general.nome' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
             <DatePickerComp id='dataNascita' value={datiGeneraliMinimi.dataNascita} onChange={this.handleChangeDataNascita} aStyle={classes.dateField}
                             required={this.isRequiredDataNascita()} disabled={this.isDisabled()} controlloDati={controlloDati} label='dataNascita' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp options={this.state.nazioneItems} value={{value: datiGeneraliMinimi.statoNascita, label: datiGeneraliMinimi.descrStatoNascita}} 
                        id='statoNascita' onChange={this.handleChangeStatoNascita} required={this.isRequiredStatoNascita()}
                        controlloDati={controlloDati} disabled={this.isDisabledStato()} ricerca='comboRicerca' etichetta='statoNascita' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>	
            <SelectComp options={this.state.provinciaItems} value={{value: datiGeneraliMinimi.provinciaNascita, label: datiGeneraliMinimi.descrProvinciaNascita}} 
                        id='provinciaNascita' onChange={this.handleChangeProvinciaNascita} required={this.isRequiredProvinciaNascita()} 
                        controlloDati={controlloDati} disabled={this.isDisabledProvincia()} ricerca='comboRicerca' etichetta='provinciaNascita'onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
          {datiGeneraliMinimi.provinciaNascita !== PROVINCIA_ESTERA &&
            <SelectComp options={this.state.comuneItems} value={{value: datiGeneraliMinimi.comuneNascita, label: datiGeneraliMinimi.descrComuneNascita}}
                        id='comuneNascita' onChange={this.handleChangeComuneNascita} required={this.isRequiredComuneNascita()}
                        controlloDati={controlloDati} disabled={this.isDisabledComune()} ricerca='comboRicerca' etichetta='comuneNascita' onError={this.handleError} />
          }
          {datiGeneraliMinimi.provinciaNascita === PROVINCIA_ESTERA && 
         	 <TextFieldComp onBlur={this.handleChangeComuneNascitaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneNascita' label='comuneNascita' 
	             value={datiGeneraliMinimi.descrComuneNascita} required={this.isRequiredComuneNascita()} controlloDati={controlloDati} disabled={this.isDisabled()}
                 onError={this.handleError} />
          }
          </Grid>
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiGeneraliFisico componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//	    console.log('########DatiGeneraliFisico componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	    console.log('########DatiGeneraliFisico componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	    
  isInBusinessError(prevProps, prevState) {
/*	const datiGenerali = this.datiGeneraliFrom(this.props.figura);
	const prevDatiGenerali = this.prevDatiGeneraliFrom(this.props.figura, prevProps);
	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError datiGenerali.codiceFiscaleValido', datiGenerali.codiceFiscaleValido);
	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError prevDatiGenerali.codiceFiscaleValido', prevDatiGenerali.codiceFiscaleValido);
	if (datiGenerali.codiceFiscaleValido !== prevDatiGenerali.codiceFiscaleValido) {
	  this.handleError('codFisc', !datiGenerali.codiceFiscaleValido);		
	} */
	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError prevState.codiceFiscaleValido', prevState.codiceFiscaleValido);
	console.log('########DatiGeneraliFisico verificaCodiceFiscale isInBusinessError this.state.codiceFiscaleValido', this.state.codiceFiscaleValido);
	if (this.state.codiceFiscaleValido!==null && this.state.codiceFiscaleValido !== prevState.codiceFiscaleValido) {
	  this.handleError('codFisc', !this.state.codiceFiscaleValido);
	}
  }
  
  componentDidUpdate(prevProps, prevState) {	    
	this.isInError(prevProps, prevState);
    this.isInBusinessError(prevProps, prevState);    
  }
}

DatiGeneraliMinimi.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  dominio: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiGeneraliMinimi));
