import * as actionType from "../actionType/assicurato";
import * as actionTypeGeneric from '../../../generic/actionType/generic';
import initialState from '../../../../session/InitialState';

import { jsonCopy, dataFormat } from '../../../../utility/genericUtility';
import { PROVINCIA_ESTERA } from '../../../../utility/jsConstants';
import { datiGeneraliInizializzato, datiResidenzaInizializzato, datiAntiriciclaggioInizializzato, datiAttEconomicaInizializzato, datiRelazContrInizializzato} from '../../../../session/info/componentiBase/sezioniAnagrafica';

const areaAssicuratoReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
  	  const assicuratoFisicoInizializza =  inizializzaAssicuratoFisico(state);
  	  return {
    	...state,
    	assicuratoFisico: assicuratoFisicoInizializza,
      };
  	  
/* ASSICURATO */   
    case actionType.SALVA_ASSICURATO_FISICO:
      console.log("#RT2 Reducer areaAssicuratoReducer actionType", actionType.SALVA_ASSICURATO_FISICO); 
      console.log("#RT2 Reducer areaAssicuratoReducer.action", action);    
      const assicuratoFisicoSalva = {...state}.assicuratoFisico;
      assicuratoFisicoSalva.controlloDati = action.unDato;
      return {
    	...state,
    	assicuratoFisico: assicuratoFisicoSalva,
      };
    case actionType.UPDATE_ANAGRAFICA_VALIDA_ASSICURATO:
      console.log("#RT2 Reducer areaAssicuratoReducer actionType", actionType.UPDATE_ANAGRAFICA_VALIDA_ASSICURATO); 
      console.log("#RT2 Reducer areaAssicuratoReducer.action", action);    
      const assicuratoFisicoAnagraficaValida = {...state}.assicuratoFisico;
      assicuratoFisicoAnagraficaValida.anagraficaValida = action.unDato;
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoAnagraficaValida,
      };      
/* DATI GENERALI ASSICURATO */
    case actionType.UPDATE_CODICE_FISCALE_ASSICURATO:
      const assicuratoFisicoFiscale = {...state}.assicuratoFisico;
      console.log('#RT2 Reducer areaDatiGeneraliReducer.actionType.UPDATE_CODICE_FISCALE_ASSICURATO.assicuratoFisico_IN', assicuratoFisicoFiscale);   
      const assicuratoDatiGeneraliFiscale = {...assicuratoFisicoFiscale}.datiGenerali;
      console.log('#RT2 Reducer areaDatiGeneraliReducer.actionType.UPDATE_CODICE_FISCALE_ASSICURATO.datiGenerali_IN', assicuratoDatiGeneraliFiscale);      
      assicuratoDatiGeneraliFiscale.codiceFiscale = action.unDato;
      assicuratoFisicoFiscale.datiGenerali = {...assicuratoDatiGeneraliFiscale};
      console.log('#RT2 Reducer areaDatiGeneraliReducer.actionType.UPDATE_CODICE_FISCALE_ASSICURATO.assicuratoFisico_OUT', assicuratoFisicoFiscale);      
      return {
       	...state,
        assicuratoFisico: assicuratoFisicoFiscale,
      };
/*    case actionType.UPDATE_CONTROLLO_CODICE_FISCALE_ASSICURATO:
        const assicuratoFisicoFiscaleControllo = {...state}.assicuratoFisico;
        const datiGeneraliFiscaleControllo = {...assicuratoFisicoFiscaleControllo}.datiGenerali;
        datiGeneraliFiscaleControllo.controlloCodiceFiscale = action.unDato;
        assicuratoFisicoFiscaleControllo.datiGenerali = {...datiGeneraliFiscaleControllo};
        return {
      	...state,
      	assicuratoFisico: assicuratoFisicoFiscaleControllo,
        };*/
    case actionType.UPDATE_CODICE_FISCALE_VALIDO_ASSICURATO:
      const assicuratoFisicoFiscaleControllo = {...state}.assicuratoFisico;
      const datiGeneraliFiscaleControllo = {...assicuratoFisicoFiscaleControllo}.datiGenerali;
      datiGeneraliFiscaleControllo.codiceFiscaleValido = action.unDato;
      assicuratoFisicoFiscaleControllo.datiGenerali = {...datiGeneraliFiscaleControllo};
      return {
      	...state,
      	assicuratoFisico: assicuratoFisicoFiscaleControllo,
      };      
    case actionType.UPDATE_NOME_ASSICURATO:
      const assicuratoFisicoNome = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliNome = {...assicuratoFisicoNome}.datiGenerali;
      assicuratoDatiGeneraliNome.nome = action.unDato;
      assicuratoFisicoNome.datiGenerali = {...assicuratoDatiGeneraliNome};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoNome,
      };
    case actionType.UPDATE_COGNOME_ASSICURATO:
      const assicuratoFisicoCognome = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliCognome = {...assicuratoFisicoCognome}.datiGenerali;
      assicuratoDatiGeneraliCognome.cognome = action.unDato;
      assicuratoFisicoCognome.datiGenerali = {...assicuratoDatiGeneraliCognome};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoCognome,
      };    
    case actionType.UPDATE_SESSO_ASSICURATO:
      const assicuratoFisicoSesso = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliSesso = {...assicuratoFisicoSesso}.datiGenerali;
      assicuratoDatiGeneraliSesso.sesso = action.unDato;
      assicuratoFisicoSesso.datiGenerali = {...assicuratoDatiGeneraliSesso};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoSesso,
      };   
    case actionType.UPDATE_DATA_NASCITA_ASSICURATO:
      const unaData = action.unDato;
      const nuovaDataNascitaFormattata = dataFormat(unaData, 'DD/MM/YYYY');    	
      const assicuratoFisicoDataNascita = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliDataNascita = {...assicuratoFisicoDataNascita}.datiGenerali;
      assicuratoDatiGeneraliDataNascita.dataNascita = unaData;
      assicuratoDatiGeneraliDataNascita.dataNascitaStampa = nuovaDataNascitaFormattata;
      assicuratoDatiGeneraliDataNascita.statoNascita = '';
      assicuratoDatiGeneraliDataNascita.descrStatoNascita = '';
      assicuratoDatiGeneraliDataNascita.provinciaNascita = '';
      assicuratoDatiGeneraliDataNascita.descrProvinciaNascita = '';
      assicuratoDatiGeneraliDataNascita.comuneNascita = '';
      assicuratoDatiGeneraliDataNascita.descrComuneNascita = '';
      //assicuratoDatiGeneraliDataNascita.cittadinanza1 = '';
      //assicuratoDatiGeneraliDataNascita.descrCittadinanza1 = '';
      //assicuratoDatiGeneraliDataNascita.cittadinanza2 = '';
      //assicuratoDatiGeneraliDataNascita.descrCittadinanza2 = '';
      assicuratoFisicoDataNascita.datiGenerali = {...assicuratoDatiGeneraliDataNascita};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoDataNascita,
      };       
    case actionType.UPDATE_STATO_NASCITA_ASSICURATO:
      const assicuratoFisicoStatoNascita = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliStatoNascita = {...assicuratoFisicoStatoNascita}.datiGenerali;
      assicuratoDatiGeneraliStatoNascita.statoNascita = unValore;
      assicuratoDatiGeneraliStatoNascita.descrStatoNascita = unaLabel;
      assicuratoDatiGeneraliStatoNascita.provinciaNascita = '';
      assicuratoDatiGeneraliStatoNascita.descrProvinciaNascita = '';
      assicuratoDatiGeneraliStatoNascita.comuneNascita = '';
      assicuratoDatiGeneraliStatoNascita.descrComuneNascita = '';
      assicuratoFisicoStatoNascita.datiGenerali = {...assicuratoDatiGeneraliStatoNascita};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoStatoNascita,
      }; 
    case actionType.UPDATE_PROVINCIA_NASCITA_ASSICURATO:
      const assicuratoFisicoProvinciaNascita = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliProvinciaNascita = {...assicuratoFisicoProvinciaNascita}.datiGenerali;        
      assicuratoDatiGeneraliProvinciaNascita.provinciaNascita = unValore;
      assicuratoDatiGeneraliProvinciaNascita.descrProvinciaNascita = unaLabel;
      assicuratoDatiGeneraliProvinciaNascita.comuneNascita = '';
      assicuratoDatiGeneraliProvinciaNascita.descrComuneNascita = '';
      assicuratoFisicoProvinciaNascita.datiGenerali = {...assicuratoDatiGeneraliProvinciaNascita};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoProvinciaNascita,
      };       
    case actionType.UPDATE_COMUNE_NASCITA_ASSICURATO:
      const assicuratoFisicoComuneNascita = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliComuneNascita = {...assicuratoFisicoComuneNascita}.datiGenerali;
      const assicuratoProvNascita = assicuratoDatiGeneraliComuneNascita.provinciaNascita;
      if (assicuratoProvNascita!==PROVINCIA_ESTERA) {
    	  assicuratoDatiGeneraliComuneNascita.comuneNascita = unValore;
    	  assicuratoDatiGeneraliComuneNascita.descrComuneNascita = unaLabel; // campo select
      } else {
    	  assicuratoDatiGeneraliComuneNascita.comuneNascita = '';
    	  assicuratoDatiGeneraliComuneNascita.descrComuneNascita = action.unDato;  // campo testo
      }
      assicuratoFisicoComuneNascita.datiGenerali = {...assicuratoDatiGeneraliComuneNascita};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoComuneNascita,
      };           
    case actionType.UPDATE_CITTADINANZA_1_ASSICURATO:
      const assicuratoFisicoCittadinanza1 = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliCittadinanza1 = {...assicuratoFisicoCittadinanza1}.datiGenerali;        
      assicuratoDatiGeneraliCittadinanza1.cittadinanza1 = unValore;
      assicuratoDatiGeneraliCittadinanza1.descrCittadinanza1 = unaLabel;
      assicuratoFisicoCittadinanza1.datiGenerali = {...assicuratoDatiGeneraliCittadinanza1};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoCittadinanza1,
      };      
    case actionType.UPDATE_CITTADINANZA_2_ASSICURATO:
      const assicuratoFisicoCittadinanza2 = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliCittadinanza2 = {...assicuratoFisicoCittadinanza2}.datiGenerali;        
      assicuratoDatiGeneraliCittadinanza2.cittadinanza2 = unValore;
      assicuratoDatiGeneraliCittadinanza2.descrCittadinanza2 = unaLabel;
      assicuratoFisicoCittadinanza2.datiGenerali = {...assicuratoDatiGeneraliCittadinanza2};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoCittadinanza2,
      };     
    case actionType.UPDATE_NUM_TELEFONO_ASSICURATO:
      const assicuratoFisicoNumTelefono = {...state}.assicuratoFisico;
      const assicuratoDatiGeneraliNumTelefono = {...assicuratoFisicoNumTelefono}.datiGenerali;        
      assicuratoDatiGeneraliNumTelefono.numTelefono = action.unDato;
      assicuratoFisicoNumTelefono.datiGenerali = {...assicuratoDatiGeneraliNumTelefono};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoNumTelefono,
      };
/* DATI RESIDENZA ASSICURATO FISICO */
    case actionType.UPDATE_STATO_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoStatoResidenza = {...state}.assicuratoFisico;
      const datiResidenzaStatoResidenza = {...assicuratoFisicoStatoResidenza}.datiResidenza;
      datiResidenzaStatoResidenza.statoResidenza = unValore;
      datiResidenzaStatoResidenza.descrStatoResidenza = unaLabel;
      datiResidenzaStatoResidenza.provinciaResidenza = '';
      datiResidenzaStatoResidenza.descrProvinciaResidenza = '';
      datiResidenzaStatoResidenza.comuneResidenza = '';
      datiResidenzaStatoResidenza.descrComuneResidenza = '';
      datiResidenzaStatoResidenza.toponimoResidenza = '';
      datiResidenzaStatoResidenza.descrToponimoResidenza = '';
      //datiResidenzaStatoResidenza.indirizzoResidenza = '';
      //datiResidenzaStatoResidenza.descrIndirizzoResidenza = '';
      assicuratoFisicoStatoResidenza.datiResidenza = {...datiResidenzaStatoResidenza};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoStatoResidenza,
      }; 
    case actionType.UPDATE_PROVINCIA_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoProvinciaResidenza = {...state}.assicuratoFisico;
      const datiResidenzaProvinciaResidenza = {...assicuratoFisicoProvinciaResidenza}.datiResidenza;        
      datiResidenzaProvinciaResidenza.provinciaResidenza = unValore;
      datiResidenzaProvinciaResidenza.descrProvinciaResidenza = unaLabel;
      datiResidenzaProvinciaResidenza.comuneResidenza = '';
      datiResidenzaProvinciaResidenza.descrComuneResidenza = '';
      datiResidenzaProvinciaResidenza.toponimoResidenza = '';
      datiResidenzaProvinciaResidenza.descrToponimoResidenza = '';
      //datiResidenzaProvinciaResidenza.indirizzoResidenza = '';
      //datiResidenzaProvinciaResidenza.descrIndirizzoResidenza = '';
      assicuratoFisicoProvinciaResidenza.datiResidenza = {...datiResidenzaProvinciaResidenza};
      return {
      	...state,
      	assicuratoFisico: assicuratoFisicoProvinciaResidenza,
      };       
    case actionType.UPDATE_COMUNE_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoComuneResidenza = {...state}.assicuratoFisico;
      const datiResidenzaComuneResidenza = {...assicuratoFisicoComuneResidenza}.datiResidenza;
      if (datiResidenzaComuneResidenza.provinciaResidenza !== PROVINCIA_ESTERA ) { 
	      datiResidenzaComuneResidenza.comuneResidenza = unValore; 
	      datiResidenzaComuneResidenza.descrComuneResidenza = unaLabel; // campo select
	      datiResidenzaComuneResidenza.toponimoResidenza = '';
	      datiResidenzaComuneResidenza.descrToponimoResidenza = '';
	      //datiResidenzaComuneResidenza.indirizzoResidenza = '';
	      //datiResidenzaComuneResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaComuneResidenza.comuneResidenza = '';
	      datiResidenzaComuneResidenza.descrComuneResidenza = action.unDato; // campo testo
      }
      assicuratoFisicoComuneResidenza.datiResidenza = {...datiResidenzaComuneResidenza};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoComuneResidenza,
      };
    case actionType.UPDATE_TOPONIMO_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoToponimoResidenza = {...state}.assicuratoFisico;
      const datiResidenzaToponimoResidenza = {...assicuratoFisicoToponimoResidenza}.datiResidenza;
      if (datiResidenzaToponimoResidenza.provinciaResidenza !== PROVINCIA_ESTERA ) {  
	      datiResidenzaToponimoResidenza.toponimoResidenza = unValore; 
	      datiResidenzaToponimoResidenza.descrToponimoResidenza = unaLabel; // campo select
	      //datiResidenzaToponimoResidenza.indirizzoResidenza = '';
	      //datiResidenzaToponimoResidenza.descrIndirizzoResidenza = '';
      } else {
    	  datiResidenzaToponimoResidenza.toponimoResidenza = ''; 
	      datiResidenzaToponimoResidenza.descrToponimoResidenza = action.unDato; // campo testo
      }
      assicuratoFisicoToponimoResidenza.datiResidenza = {...datiResidenzaToponimoResidenza};
      return {
        ...state,
        assicuratoFisico: assicuratoFisicoToponimoResidenza,
      };         
    case actionType.UPDATE_INDIRIZZO_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoIndirizzoResidenza = {...state}.assicuratoFisico; 
      const datiResidenzaIndirizzoResidenza = {...assicuratoFisicoIndirizzoResidenza}.datiResidenza;
      //datiResidenzaIndirizzoResidenza.indirizzoResidenza = unValore;
      //datiResidenzaIndirizzoResidenza.descrIndirizzoResidenza = unaLabel;
      datiResidenzaIndirizzoResidenza.descrIndirizzoResidenza = action.unDato;
      assicuratoFisicoIndirizzoResidenza.datiResidenza = {...datiResidenzaIndirizzoResidenza};
      return {
    	...state,
    	assicuratoFisico: assicuratoFisicoIndirizzoResidenza,
      };     
    case actionType.UPDATE_NUMERO_CIVICO_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoNumeroCivicoResidenza = {...state}.assicuratoFisico;
      const datiResidenzaNumeroCivicoResidenza = {...assicuratoFisicoNumeroCivicoResidenza}.datiResidenza;
      datiResidenzaNumeroCivicoResidenza.numCivicoResidenza = action.unDato;
      assicuratoFisicoNumeroCivicoResidenza.datiResidenza = {...datiResidenzaNumeroCivicoResidenza};
      return {
      	...state,
      	assicuratoFisico: assicuratoFisicoNumeroCivicoResidenza,
      };       
    case actionType.UPDATE_CAP_RESIDENZA_ASSICURATO_FISICO:
      const assicuratoFisicoCapResidenza = {...state}.assicuratoFisico;
      const datiResidenzaCapResidenza = {...assicuratoFisicoCapResidenza}.datiResidenza;
      datiResidenzaCapResidenza.capResidenza = action.unDato;
      assicuratoFisicoCapResidenza.datiResidenza = {...datiResidenzaCapResidenza};
      return {
      	...state,
      	assicuratoFisico: assicuratoFisicoCapResidenza,
      };      
      /* DATI ATTIVITA ECONOMICA ASSICURATO FISICO */
    case actionType.UPDATE_STATO_ATT_PREVAL_ASSICURATO_FISICO:
      const assFisAttEcStato = {...state}.assicuratoFisico;
      const datiAttEcStato = {...assFisAttEcStato}.datiAttEconomica;
      datiAttEcStato.statoAttPrevalSvolta = unValore;
      datiAttEcStato.descrStatoAttPrevalSvolta = unaLabel;
      datiAttEcStato.provinciaAttPrevalSvolta = '';
      datiAttEcStato.descrProvinciaAttPrevalSvolta = '';
      datiAttEcStato.comuneAttPrevalSvolta = '';
      datiAttEcStato.descrComuneAttPrevalSvolta = '';
      assFisAttEcStato.datiAttEconomica = {...datiAttEcStato};
      return {
        ...state,
        assicuratoFisico: assFisAttEcStato
      }
    case actionType.UPDATE_PROVINCIA_ATT_PREVAL_ASSICURATO_FISICO:
      const assFisAttEcProv = {...state}.assicuratoFisico;
      const datiAttEcProv = {...assFisAttEcProv}.datiAttEconomica;
      datiAttEcProv.provinciaAttPrevalSvolta = unValore;
      datiAttEcProv.descrProvinciaAttPrevalSvolta = unaLabel;
      datiAttEcProv.comuneAttPrevalSvolta = '';
      datiAttEcProv.descrComuneAttPrevalSvolta = '';
      assFisAttEcProv.datiAttEconomica = {...datiAttEcProv};
      return {
        ...state,
        assicuratoFisico: assFisAttEcProv
      }
    case actionType.UPDATE_COMUNE_ATT_PREVAL_ASSICURATO_FISICO:
      const assFisAttEcCom = {...state}.assicuratoFisico;
      const datiAttEcCom = {...assFisAttEcCom}.datiAttEconomica;
      if(datiAttEcCom.provinciaAttPrevalSvolta === PROVINCIA_ESTERA) {
        datiAttEcCom.comuneAttPrevalSvolta = '';
        datiAttEcCom.descrComuneAttPrevalSvolta = action.unDato;
      } else {
        datiAttEcCom.comuneAttPrevalSvolta = unValore;
        datiAttEcCom.descrComuneAttPrevalSvolta = unaLabel;
      }
      assFisAttEcCom.datiAttEconomica = {...datiAttEcCom};
      return {
        ...state,
        assicuratoFisico: assFisAttEcCom
      }
    case actionType.UPDATE_ATT_PREVAL_SVOLTA_ASSICURATO_FISICO:
      const assFisEcAttPreval = {...state}.assicuratoFisico;
      const attEcAttPreval = {...assFisEcAttPreval}.datiAttEconomica;
      const antiEcAttPrec = {...assFisEcAttPreval}.datiAntiriciclaggio;
      attEcAttPreval.codAttPrevalSvolta = unValore;
      attEcAttPreval.descrAttPrevalSvolta = unaLabel;
      antiEcAttPrec.codAttivitaPrecedente = '';
      antiEcAttPrec.descrAttivitaPrecedente = '';
      assFisEcAttPreval.datiAttEconomica = {...attEcAttPreval};
      assFisEcAttPreval.datiAntiriciclaggio = {...antiEcAttPrec};
      return {
        ...state,
        assicuratoFisico: assFisEcAttPreval
      }
    case actionType.UPDATE_ATTIVITA_PRECEDENTE_ASSICURATO_FISICO:
      const assFisAttPrece = {...state}.assicuratoFisico;
      const attPrece = {...assFisAttPrece}.datiAntiriciclaggio;
      attPrece.codAttivitaPrecedente = unValore;
      attPrece.descrAttivitaPrecedente = unaLabel;
      assFisAttPrece.datiAntiriciclaggio = {...attPrece};
      return {
        ...state,
        assicuratoFisico: assFisAttPrece
      }
/* DATI ANTIRICICLAGGIO ASSICURATO*/
    case actionType.UPDATE_FL_ESPOSTO_POLITICAMENTE_ASSICURATO_FISICO:
        const assFisEsposto = {...state}.assicuratoFisico;
        const antiEsposto = {...assFisEsposto}.datiAntiriciclaggio;
        antiEsposto.espostaPoliticamente = action.unDato;
        antiEsposto.isInCarica = '';
        antiEsposto.caricaPersonaEsposta = '';
        assFisEsposto.datiAntiriciclaggio = {...antiEsposto};
        return {
          ...state,
          assicuratoFisico: assFisEsposto
        }
    case actionType.UPDATE_FL_PEP_IN_CARICA_ASSICURATO_FISICO:
        const assFisInCarica = {...state}.assicuratoFisico;
        const antiInCarica = {...assFisInCarica}.datiAntiriciclaggio;
        antiInCarica.isInCarica = action.unDato;
        assFisInCarica.datiAntiriciclaggio = {...antiInCarica};
        return {
          ...state,
          assicuratoFisico: assFisInCarica
        }
    case actionType.UPDATE_CARICA_PEP_ASSICURATO_FISICO:
        const assFisCarica = {...state}.assicuratoFisico;
        const antiCarica = {...assFisCarica}.datiAntiriciclaggio;
        antiCarica.caricaPersonaEsposta = action.unDato;
        assFisCarica.datiAntiriciclaggio = {...antiCarica};
        return {
          ...state,
          assicuratoFisico: assFisCarica
        }
    case actionType.UPDATE_TIPO_DOCUMENTO_ASSICURATO:
        const assicuratoFisicoTipoDoc = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioTipoDoc = {...assicuratoFisicoTipoDoc}.datiAntiriciclaggio;
        assicuratoDatiAntiriciclaggioTipoDoc.tipoDocumento = unValore;
        assicuratoDatiAntiriciclaggioTipoDoc.descrTipoDocumento = unaLabel;
        
        assicuratoFisicoTipoDoc.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioTipoDoc};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoTipoDoc,
        };
    case actionType.UPDATE_NUMERO_DOCUMENTO_ASSICURATO:
        const assicuratoFisicoNumDoc = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioNumDoc = {...assicuratoFisicoNumDoc}.datiAntiriciclaggio;
        assicuratoDatiAntiriciclaggioNumDoc.numeroDocumento = action.unDato;
        
        assicuratoFisicoNumDoc.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioNumDoc};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoNumDoc,
        };
    case actionType.UPDATE_ENTE_RILASCIO_ASSICURATO:
        const assicuratoFisicoEnteRil = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioEnteRil = {...assicuratoFisicoEnteRil}.datiAntiriciclaggio;
        assicuratoDatiAntiriciclaggioEnteRil.enteRilascio = unValore;
        assicuratoDatiAntiriciclaggioEnteRil.descrEnteRilascio = unaLabel;
        
        assicuratoFisicoEnteRil.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioEnteRil};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoEnteRil,
        };
    case actionType.UPDATE_DATA_RILASCIO_ASSICURATO:
        const unaDataRilascio = action.unDato;
        const nuovaDataRilascioFormattata = dataFormat(unaDataRilascio, 'DD/MM/YYYY'); 
        const assicuratoFisicoDataRil = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioDataRil = {...assicuratoFisicoDataRil}.datiAntiriciclaggio;
        assicuratoDatiAntiriciclaggioDataRil.dataRilascio = unaDataRilascio;
        assicuratoDatiAntiriciclaggioDataRil.dataRilascioStampa = nuovaDataRilascioFormattata;        
        assicuratoDatiAntiriciclaggioDataRil.statoRilascio = '';
        assicuratoDatiAntiriciclaggioDataRil.descrStatoRilascio = '';
        assicuratoDatiAntiriciclaggioDataRil.provinciaRilascio = '';
        assicuratoDatiAntiriciclaggioDataRil.descrProvinciaRilascio = '';
        assicuratoDatiAntiriciclaggioDataRil.comuneRilascio = '';
        assicuratoDatiAntiriciclaggioDataRil.descrComuneRilascio = '';
         
        assicuratoFisicoDataRil.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioDataRil};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoDataRil,
        };
    case actionType.UPDATE_DATA_SCADENZA_ASSICURATO:
        const unaDataScadenza = action.unDato;
        const nuovaDataScadenzaFormattata = dataFormat(unaDataScadenza, 'DD/MM/YYYY');
        const assicuratoFisicoDataScad = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioDataScad = {...assicuratoFisicoDataScad}.datiAntiriciclaggio;
        assicuratoDatiAntiriciclaggioDataScad.dataScadenza = unaDataScadenza;
        assicuratoDatiAntiriciclaggioDataScad.dataScadenzaStampa = nuovaDataScadenzaFormattata;
        assicuratoFisicoDataScad.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioDataScad};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoDataScad,
        };
    case actionType.UPDATE_STATO_RILASCIO_ASSICURATO:
        const assicuratoFisicoStatoRil = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioStatoRil = {...assicuratoFisicoStatoRil}.datiAntiriciclaggio;        
        assicuratoDatiAntiriciclaggioStatoRil.statoRilascio = unValore;
        assicuratoDatiAntiriciclaggioStatoRil.descrStatoRilascio = unaLabel;
        assicuratoDatiAntiriciclaggioStatoRil.provinciaRilascio = '';
        assicuratoDatiAntiriciclaggioStatoRil.descrProvinciaRilascio = '';
        assicuratoDatiAntiriciclaggioStatoRil.comuneRilascio = '';
        assicuratoDatiAntiriciclaggioStatoRil.descrComuneRilascio = '';
        
        assicuratoFisicoStatoRil.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioStatoRil};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoStatoRil,
        };
    case actionType.UPDATE_PROVINCIA_RILASCIO_ASSICURATO:
        const assicuratoFisicoProvRil = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioProvRil = {...assicuratoFisicoProvRil}.datiAntiriciclaggio;
        assicuratoDatiAntiriciclaggioProvRil.provinciaRilascio = unValore;
        assicuratoDatiAntiriciclaggioProvRil.descrProvinciaRilascio = unaLabel;
        assicuratoDatiAntiriciclaggioProvRil.comuneRilascio = '';
        assicuratoDatiAntiriciclaggioProvRil.descrComuneRilascio = '';

        assicuratoFisicoProvRil.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioProvRil};
        return {
         	...state,
          assicuratoFisico: assicuratoFisicoProvRil,
        };
    case actionType.UPDATE_COMUNE_RILASCIO_ASSICURATO:    	
        const assicuratoFisicoComuneRil = {...state}.assicuratoFisico;
        const assicuratoDatiAntiriciclaggioComuneRil = {...assicuratoFisicoComuneRil}.datiAntiriciclaggio;
        if (assicuratoDatiAntiriciclaggioComuneRil.provinciaRilascio !== PROVINCIA_ESTERA) {
	        assicuratoDatiAntiriciclaggioComuneRil.comuneRilascio = unValore;
	        assicuratoDatiAntiriciclaggioComuneRil.descrComuneRilascio = unaLabel;
        } else {
	        assicuratoDatiAntiriciclaggioComuneRil.comuneRilascio = '';
	        assicuratoDatiAntiriciclaggioComuneRil.descrComuneRilascio = action.unDato;        	
        }
        assicuratoFisicoComuneRil.datiAntiriciclaggio = {...assicuratoDatiAntiriciclaggioComuneRil};
        return {
          ...state,
         assicuratoFisico: assicuratoFisicoComuneRil,
       };
    /* DATI RELAZIONE ASSICURATO E CONTRAENTE */
    case actionType.UPDATE_RELAZIONE_CONTRAENTE_ASSICURATO:
      const assRelazAss = {...state}.assicuratoFisico;
      const assRelazDati = {...assRelazAss}.datiRelazContr;
      assRelazDati.relazContraente = unValore;
      assRelazDati.descrRelazContraente = unaLabel;
      assRelazDati.altro = '';
      assRelazAss.datiRelazContr = {...assRelazDati};
      return {
        ...state, 
        assicuratoFisico: assRelazAss
      }
    case actionType.UPDATE_ALTRA_RELAZIONE_CONTRAENTE_ASSICURATO:
      const assAltraRelazAss = {...state}.assicuratoFisico;
      const datiAltraRelaz = {...assAltraRelazAss}.datiRelazContr;
      datiAltraRelaz.altro = action.unDato;
      assAltraRelazAss.datiRelazContr = {...datiAltraRelaz};
      return {
        ...state,
        assicuratoFisico: assAltraRelazAss
      }        
    case actionType.UPDATE_MOTIVO_DESIGNAZIONE_ASSICURATO:
      const assMotivoAss = {...state}.assicuratoFisico;
      const datiMotivo = {...assMotivoAss}.datiRelazContr;
      datiMotivo.motivoDesignazione = action.unDato;
      return {
        ...state,
        assicuratoFisico: assMotivoAss
      }
       /*
    case actionType.UPDATE_DATI_GENERALI_COPIA_FROM_CONTRAENTE:
    	
        const contraenteFisicoCopiaContr = {...action}.contraenteFisico;
        const datiGeneraliCopiaContr = {...contraenteFisicoCopiaContr}.datiGenerali;
        const datiResidenzaCopiaContr = {...contraenteFisicoCopiaContr}.datiResidenza;
        const datiAntiriciclaggioCopiaContr = {...contraenteFisicoCopiaContr}.datiAntiriciclaggio;
        
        
        const assicuratoFisicoCopiaContr = {...state}.assicuratoFisico;
        assicuratoFisicoCopiaContr.flAssicuratoUgualeContraente = action.unDato;
        assicuratoFisicoCopiaContr.datiGenerali = datiGeneraliCopiaContr;
        assicuratoFisicoCopiaContr.datiResidenza = datiResidenzaCopiaContr;
        assicuratoFisicoCopiaContr.datiAntiriciclaggio = datiAntiriciclaggioCopiaContr;
              
        return {
         	...state,
          assicuratoFisico: {...assicuratoFisicoCopiaContr},
        };
    case actionType.UPDATE_DATI_GENERALI_COPIA_NUOVO_ASSICURATO:
        const assicuratoFisicoCopiaNuovo = {...state}.assicuratoFisico;
        assicuratoFisicoCopiaNuovo.flAssicuratoUgualeContraente = action.unDato;
        assicuratoFisicoCopiaNuovo.controlloDati= '';
        assicuratoFisicoCopiaNuovo.codAttPrevalSvolta= '';
        assicuratoFisicoCopiaNuovo.descrAttPrevalSvolta= '';
        assicuratoFisicoCopiaNuovo.datiGenerali = {};
        assicuratoFisicoCopiaNuovo.datiResidenza = {};
        assicuratoFisicoCopiaNuovo.datiAntiriciclaggio = {};
              
        return {
         	...state,
          assicuratoFisico: {...assicuratoFisicoCopiaNuovo},
        };
        */
    /* case actionType.UPDATE_ATTIVITA_PREV_ASSICURATO:
        console.log("#RT2 Reducer areaAssicuratoReducer actionType", actionType.UPDATE_ATTIVITA_PREV_ASSICURATO); 
        console.log("#RT2 Reducer areaAssicuratoReducer.action", action);    
        const assicuratoFisicoAttPreval = {...state}.assicuratoFisico;
        assicuratoFisicoAttPreval.codAttPrevalSvolta = unValore;      
        assicuratoFisicoAttPreval.descrAttPrevalSvolta = unaLabel;
        return {
         	...state,
          assicuratoFisico: {...assicuratoFisicoAttPreval},
        }; */
    case actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE:
        console.log("#RT2 Reducer areaAssicuratoReducer actionType", actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE); 
        console.log("#RT2 Reducer areaAssicuratoReducer.action", action);    
        const assicuratoFisicoAssUguContr = {...state}.assicuratoFisico;
        assicuratoFisicoAssUguContr.flAssicuratoUgualeContraente = action.unDato;
        assicuratoFisicoAssUguContr.controlloDati = '';    
        assicuratoFisicoAssUguContr.anagraficaValida = null;
//        assicuratoFisicoAssUguContr.controlloDati = (assicuratoFisicoAssUguContr.controlloDati === true) ? false : assicuratoFisicoAssUguContr.controlloDati; 
        /* assicuratoFisicoAssUguContr.codAttPrevalSvolta='';
        assicuratoFisicoAssUguContr.descrAttPrevalSvolta=''; */
        assicuratoFisicoAssUguContr.datiGenerali = jsonCopy(datiGeneraliInizializzato);
        assicuratoFisicoAssUguContr.datiResidenza = jsonCopy(datiResidenzaInizializzato);
        assicuratoFisicoAssUguContr.datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
        assicuratoFisicoAssUguContr.datiAttEconomica = jsonCopy(datiAttEconomicaInizializzato);
        assicuratoFisicoAssUguContr.datiRelazContr = jsonCopy(datiRelazContrInizializzato);

        return {
          ...state,
          assicuratoFisico: assicuratoFisicoAssUguContr,
        };
    default:
      return state;
  }
}

export const inizializzaAssicuratoFisico = (state) => {
  const assicuratoFisico_INI = {...state}.assicuratoFisico;
  assicuratoFisico_INI.anagraficaValida= null;
  assicuratoFisico_INI.controlloDati= '';
  assicuratoFisico_INI.flAssicuratoUgualeContraente= '';
  /* assicuratoFisico_INI.codAttPrevalSvolta= '';
  assicuratoFisico_INI.descrAttPrevalSvolta= ''; */
  assicuratoFisico_INI.datiGenerali = jsonCopy(datiGeneraliInizializzato);
  assicuratoFisico_INI.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  assicuratoFisico_INI.datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
  assicuratoFisico_INI.datiAttEconomica = jsonCopy(datiAttEconomicaInizializzato);
  assicuratoFisico_INI.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  return assicuratoFisico_INI;
}

export default areaAssicuratoReducer;