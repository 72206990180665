import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0px 0px 0px 0px',    
  },
  appBar: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.windowsBlue,
  },  
  tabSelHeader: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.windowsBlue,    
    color: theme.palette.color.white,
  },
/*  tabHeader: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.greyLight,
  },*/
});

const MyTabs = withStyles(theme => ({
  root: {
/*		flexGrow: 1, */
/*    color: theme.palette.color.windowsBlue, */
  },
})) (Tabs);

class NavTabsComp extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
		value: props.value ? props.value : 0,
	};
  };

  handleChange = (event, value) => {
    this.setState({ value }); 
    
    // callback chiamante
    if (this.props.handleChange) { 
      this.props.handleChange(event, value);
    }
  };

  render() {
    const { classes, tabList } = this.props;
    const value = this.props.value!==undefined ? this.props.value : this.state.value; 
    //const { value } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <MyTabs variant="fullWidth" value={value} onChange={this.handleChange}>
            {tabList.map((tabItem, index) => {
              const isDisabled = ((tabItem.disabled !== null) && (tabItem.disabled !== undefined)) ? tabItem.disabled : false;
              return (
                <LinkTab disabled={isDisabled} label={tabItem.label} key={index} className={index===value ? classes.tabSelHeader : classes.appBar} />
              );            
            })}
{/*            {tabList.map((tabItem, index) => (
              <LinkTab label={tabItem.label} key={index} className={index===value ? classes.tabSelHeader : classes.appBar}/>
            ))}*/}
          </MyTabs>           
        </AppBar>                  
        {tabList.map((tabItem, index) => {
          const UnTabItem = tabItem.content;
          const isDisabled = ((tabItem.disabled !== null) && (tabItem.disabled !== undefined)) ? tabItem.disabled : false;
          return (
            (index === value && (isDisabled === false) &&
             <TabContainer key={index}>
               <UnTabItem 
                 handleChangeConfermaContraenteGiuridico={this.props.handleChangeConfermaContraenteGiuridico}
                 handleChangeFlTitolareEff={this.props.handleChangeFlTitolareEff} />
             </TabContainer>)
          );
        })}
      </div>
    );
  }
}

NavTabsComp.propTypes = {
  classes: PropTypes.object.isRequired,
  tabList: PropTypes.array.isRequired,
};

export default withStyles(styles)(NavTabsComp);