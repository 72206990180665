import * as actionType from '../actionType/datiNuovaUtenza';
import initialState from '../../../../session/InitialState';

const areaDatiNuovaUtenzaReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  switch (action.type) {
    case actionType.SALVA_DATI_NUOVA_UTENZA:
      const gestioneUtenzaDatiNuovaUtenzaSalva = {...state}.gestioneUtenza;
      const datiNuovaUtenzaSalva = {...gestioneUtenzaDatiNuovaUtenzaSalva}.datiNuovaUtenza;
      datiNuovaUtenzaSalva.controlloDati = action.controlloDati;
      gestioneUtenzaDatiNuovaUtenzaSalva.datiNuovaUtenza = {...datiNuovaUtenzaSalva};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaDatiNuovaUtenzaSalva,
      };
    case actionType.INSERIMENTO_DATI_NUOVA_UTENZA:
        const gestioneUtenzaDatiNuovaUtenzaInserimento = {...state}.gestioneUtenza;
        const datiNuovaUtenzaInserimento = {...gestioneUtenzaDatiNuovaUtenzaInserimento}.datiNuovaUtenza;
        datiNuovaUtenzaInserimento.utente = action.utente;
        datiNuovaUtenzaInserimento.dataScadenza = action.dataScadenza;
        gestioneUtenzaDatiNuovaUtenzaInserimento.datiNuovaUtenza = {...datiNuovaUtenzaInserimento};
        return {
      	...state,
      	gestioneUtenza: gestioneUtenzaDatiNuovaUtenzaInserimento,
        };
    case actionType.UPDATE_MANDATO_UTENTE:
      const gestioneUtenzaMandato = {...state}.gestioneUtenza;
      const datiNuovaUtenzaMandato = {...gestioneUtenzaMandato}.datiNuovaUtenza;
      datiNuovaUtenzaMandato.mandato = unValore;
      datiNuovaUtenzaMandato.descMandato = unaLabel;
      gestioneUtenzaMandato.datiNuovaUtenza = {...datiNuovaUtenzaMandato};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaMandato,
      };
    case actionType.UPDATE_COGNOME_UTENTE:
      const gestioneUtenzaCognome = {...state}.gestioneUtenza;
      const datiNuovaUtenzaCognome = {...gestioneUtenzaCognome}.datiNuovaUtenza;
      datiNuovaUtenzaCognome.cognome = action.unDato;
      gestioneUtenzaCognome.datiNuovaUtenza = {...datiNuovaUtenzaCognome};
      return {
      	...state,
      	gestioneUtenza: gestioneUtenzaCognome,
      };   
    case actionType.UPDATE_NOME_UTENTE:
      const gestioneUtenzaNome = {...state}.gestioneUtenza;
      const datiNuovaUtenzaNome = {...gestioneUtenzaNome}.datiNuovaUtenza;
      datiNuovaUtenzaNome.nome = action.unDato;
      gestioneUtenzaNome.datiNuovaUtenza = {...datiNuovaUtenzaNome};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaNome,
      };
    case actionType.UPDATE_EMAIL_UTENTE:
      const gestioneUtenzaEmail = {...state}.gestioneUtenza;
      const datiNuovaUtenzaEmail = {...gestioneUtenzaEmail}.datiNuovaUtenza;
      datiNuovaUtenzaEmail.email = action.unDato;
      gestioneUtenzaEmail.datiNuovaUtenza = {...datiNuovaUtenzaEmail};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaEmail,
      };
    case actionType.UPDATE_CODICE_CONSULENTE_UTENTE:
      const gestioneUtenzaCodiceConsulente = {...state}.gestioneUtenza;
      const datiNuovaUtenzaCodiceConsulente = {...gestioneUtenzaCodiceConsulente}.datiNuovaUtenza;
      datiNuovaUtenzaCodiceConsulente.codiceConsulente = action.unDato;
      gestioneUtenzaCodiceConsulente.datiNuovaUtenza = {...datiNuovaUtenzaCodiceConsulente};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaCodiceConsulente,
      };
    case actionType.UPDATE_PROFILO_UTENTE:
      const gestioneUtenzaProfilo = {...state}.gestioneUtenza;
      const datiNuovaUtenzaProfilo = {...gestioneUtenzaProfilo}.datiNuovaUtenza;
      datiNuovaUtenzaProfilo.profilo = unValore;
      datiNuovaUtenzaProfilo.descProfilo = unaLabel;
      gestioneUtenzaProfilo.datiNuovaUtenza = {...datiNuovaUtenzaProfilo};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaProfilo,
      };
    case actionType.UPDATE_DATA_CREAZIONE_UTENTE:
      const gestioneUtenzaDataCreazione = {...state}.gestioneUtenza;
      const datiNuovaUtenzaDataCreazione = {...gestioneUtenzaDataCreazione}.datiNuovaUtenza;
      datiNuovaUtenzaDataCreazione.dataCreazione = action.unDato;
      gestioneUtenzaDataCreazione.datiNuovaUtenza = {...datiNuovaUtenzaDataCreazione};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataCreazione,
      };
    case actionType.UPDATE_DATA_SCADENZA_UTENTE:
      const gestioneUtenzaDataScadenza = {...state}.gestioneUtenza;
      const datiNuovaUtenzaDataScadenza = {...gestioneUtenzaDataScadenza}.datiNuovaUtenza;
      datiNuovaUtenzaDataScadenza.dataScadenza = action.unDato;
      gestioneUtenzaDataScadenza.datiNuovaUtenza = {...datiNuovaUtenzaDataScadenza};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataScadenza,
      };
    default:
      return state;
  }
}

export default areaDatiNuovaUtenzaReducer;