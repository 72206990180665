import React from 'react';
//import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { uris, fetchParam } from '../../../uris';
import { Link } from 'react-router-dom';

//import TextFieldAdornComp from '../../common/TextFieldAdornComp';
//import AlternateEmail from '@material-ui/icons/AlternateEmail';
//import TextFieldComp from '../../common/TextFieldComp';
import ButtonComp from '../../common/ButtonComp';

import { isContraenteGiuridico } from '../../../utility/genericUtility';

import * as actions from '../stampa/action/datiStampaProposal';
import * as actionType from '../stampa/actionType/datiStampaProposal';
import * as actionsGeneric from '../../generic/action/generic';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: 10,
  },
//textField: theme.textField,
//fieldSet: theme.fieldSet,  
  button: theme.button,
  marginTop5: {
	  marginTop: '15px',
  },
  indiMail: {
	  width: '95%',
  },
  colorRed: {
    color: 'red',
  },
  bordercolorRed : {
    border: 2,
    borderColor: 'red',
    borderStyle: 'solid',
  },
  media: {
	height: 35,
    objectFit: 'inherit',
  }, 
  card: {
	  // background: theme.palette.color.windowsBlue,
	  display: 'flex',
//		  flexDirection: 'row',
	  width: '98%',
  },
  cardContent: {
	margin: 0,
	padding: '10px 0 0 0',
	height: 50,
  },
  cardActionArea: {
	  width: '23%',
  },
  cardNuovaProposta: {
	  fontSize: 25,
  },
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	prodotto: state.areaProdottoReducer.prodotto,
	loginUser: state.areaProdottoReducer.loginUser,
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,   
    stampaProposal: state.areaBenefMorteReducer.stampaProposal,    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateStampaProposal: (datiInvioEmail, anActionType) => dispatch(actions.updateStampaProposal(datiInvioEmail, anActionType)),
    inizializza: () => dispatch(actionsGeneric.inizializza()),
  };
};
	
class DatiInvioEmail extends React.Component {
	
  constructor(props) {
    super(props);
    this.state = {
//      controlloDati: '',
      emailInviata: false,
      isInvioEmail: false,
    }
  };
/*  
  handleChangeIndEmail = (event) => {
	  this.setState({
		  indEmail: event.target.value,
	  });
  }
*/
  
  invioEmail = (datiInvioEmail) => {
    const opts = {
      datiInvioEmail,
    };
	if (datiInvioEmail) {
      fetch(uris.inviaEmailProposta, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )     
      .then((result) => {
        console.log('########DatiInvioEmail result', result);
        this.props.updateStampaProposal(datiInvioEmail, actionType.INVIO_EMAIL_PROPOSAL);  	  
        this.setState({
          emailInviata: true,
          // 2019-07-11 PFD: Non riabilito piu' il bottone
          // isInvioEmail: false,
        });
      });
    }
  }
  
  handleClickInvioEmail_TEST = (event) => {
    const isContrGiuridico = isContraenteGiuridico(this.props.contraenteGiuridico);
    let nominativo = 'PROVA';
    let nome = 'INVIO';	  
    let datiConsensoComElettr = 'ruggero.treglia@sistinf.it';
    const indEmailTo = [datiConsensoComElettr];
    const indEmailCc = [this.props.loginUser.email];	
    const flConsensoInvioComunicazElettr = 'S';
    const idstampa = '100';
    const datiInvioEmail = {
      indEmailTo: indEmailTo,
      indEmailCc: indEmailCc,
      idstampa: idstampa,
      prodotto: this.props.prodotto.title,
      nominativo: nominativo,
      nome: nome,
    };
//    this.props.updateStampaProposal(datiInvioEmail, actionType.INVIO_EMAIL_PROPOSAL);
    this.invioEmail(datiInvioEmail);
    this.setState({
    	isInvioEmail: true,
    });
  }
  
  handleClickInvioEmail = (event) => {
    const isContrGiuridico = isContraenteGiuridico(this.props.contraenteGiuridico);
    let nominativo = null;
    let nome = null;	  
    let datiConsensoComElettr = null;
    if (isContrGiuridico) {
      datiConsensoComElettr = this.props.contraenteGiuridico.datiConsensoComElettr;
      nominativo = this.props.contraenteGiuridico.datiGenerali.ragioneSociale;
      nome = '';
    } else {
      datiConsensoComElettr = this.props.contraenteFisico.datiConsensoComElettr;
      nominativo = this.props.contraenteFisico.datiGenerali.cognome;
      nome = this.props.contraenteFisico.datiGenerali.nome;
    }
    const indEmailTo = [datiConsensoComElettr.emailConsensoComElettr];
    const indEmailCc = [this.props.loginUser.email];	
    const flConsensoInvioComunicazElettr = datiConsensoComElettr.flConsensoComElettr;
    const idstampa = this.props.stampaProposal.datiInvioEmail.idstampa;
    const datiInvioEmail = {
      indEmailTo: indEmailTo,
      indEmailCc: indEmailCc,
      idstampa: idstampa,
      prodotto: this.props.prodotto.title,
      nominativo: nominativo,
      nome: nome,
    };
//    this.props.updateStampaProposal(datiInvioEmail, actionType.INVIO_EMAIL_PROPOSAL);
    this.invioEmail(datiInvioEmail); 
    this.setState({
    	isInvioEmail: true,
    })
  }
  
  isRequiredIndirizzoEmail = () => {
    return true;
  }
  
  handleClickNuovaProposta = event => {
	  this.props.inizializza();
  } 
  
  render() {
    const { t, classes, labelFieldSet, figura } = this.props;
    const confermaStampaProposal = this.props.stampaProposal.datiStampaProposal.confermaStampaProposal; 
    
    return (
      <div className={classes.root}>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiFiscali')}</Typography></legend>*/}
        <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
        {/*<Grid item md={5}>          
            <TextFieldAdornComp onBlur={this.handleChangeIndEmail} id='emailInvioProposta' label='indEmail' 
                            value={this.state.indEmail} aStyle={classNames(classes.textField, classes.indiMail)} margin='normal' variant='outlined' 
                            adornComp={ <AlternateEmail />} adornPosit='end'
                            required={this.isRequiredIndirizzoEmail()} controlloDati={this.state.controlloDati}/>
          </Grid>*/}
          {this.state.emailInviata===false &&
            <Grid item md={8} xs={8} className={classes.bordercolorRed}>
          	  <div align='center'>
          		<Typography variant="h6" className={classes.colorRed}>{t('invioEmailTxt')}</Typography>
              </div>
            </Grid>
          }
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start">
              <Grid item xs={12}>
	            <Grid container direction="row" justify="center" alignItems="center">	
	              <Grid item>
	                {this.state.emailInviata===true &&
	    	          <Typography className={classes.textErrorMessage}>{t('emailInvioOk')}</Typography>
	                }
	              </Grid>
	            </Grid>
	          </Grid>
	        </Grid>
	      </Grid>
	      
	      {confermaStampaProposal === false &&
	          <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
		          <Grid item>
		            <ButtonComp
		              aClassName={classNames(classes.button, classes.marginTop5)}
		              buttonLabel={'invia'}
		              onClick={this.handleClickInvioEmail}
		              disabled={this.state.isInvioEmail}
		            />
		          </Grid>
		      </Grid>
	      }
	      
	      {confermaStampaProposal === true &&
		      <Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
		      		<Grid item md={4}>
		      		&nbsp;
		      		</Grid> 
		            <Grid item md={4} >
			            <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>
			            	<Grid item>
				            	<ButtonComp
					              aClassName={classNames(classes.button, classes.marginTop5)}
					              buttonLabel={'invia'}
					              onClick={this.handleClickInvioEmail}
					              disabled={this.state.isInvioEmail}
					            />
			              	</Grid>
			            </Grid> 
			        </Grid> 
		            <Grid item md={4}>
		            	<Grid container direction='row' justify='flex-end' alignItems='flex-start'>
		            		<Grid item md={6}>
						        <Card className={classes.card} elevation={2}>
							        <CardActionArea to={'/areaProdotti'} component={Link} className={classes.cardActionArea} onClick={this.handleClickNuovaProposta}>
					                  <CardActions >
								    	<CardMedia
					                        className={classes.media}
					                        component='img'
					                        image={'/images/generic/proposta.svg'}
					                        alt={t('nuovaProposta')}
					                    />
					                  </CardActions>
					                </CardActionArea>
					                <CardContent className={classes.cardContent}>
					                	<Typography gutterBottom align='center' variant='h5' className={classes.cardNuovaProposta}>
					                		{t('nuovaProposta')}
					                    </Typography>
						  	        </CardContent>
							   </Card>
							</Grid> 
						</Grid> 						   
		            </Grid>          
		       </Grid>
           }
        </Grid>
      {/*</fieldset>*/}
      </div>
    );
  }
}

DatiInvioEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiInvioEmail)));
