import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import NavTabsComp from '../../common/NavTabsComp';
//import { benefMorteTabItems } from '../../store/navTabProposalItems';

import * as actions from '../../anagrafica/figure/action/beneficiarioMorte'; 

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const mapStateToProps = state => {
  return {	  
    tabSelezionato: state.areaBenefMorteReducer.tabMorteSelezionato,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA BenefMortePanel.mapDispatchToProps.dispatch', dispatch);
  return {
    updateTabSelezionatoBenMorte: (tabIndex) => dispatch(actions.updateTabSelezionatoBenMorte(tabIndex)),
  };
};

class BenefMortePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state =  { 
      tabSelezionato: ((props.tabSelezionato !== undefined) && (props.tabSelezionato !== null)) ? props.tabMSelezionato : 0,
    }
  }; 

  // funzione callback esterna
  handleChangeUpdateTabSelezionato = (event, tabIndex) => {
    this.props.updateTabSelezionatoBenMorte(tabIndex);
    this.setState({
  	  tabSelezionato: tabIndex,
    });
  };

  render () {
    // console.log('#RTCFA BenefMortePanel this.props', this.props);
    const { classes, isValida, tabList } = this.props;
    console.log('####tabList: ', tabList);    
    //const tabSelezionato = this.props.tabSelezionato != undefined ? this.props.tabSelezionato : this.state.tabMorteSelezionato
    const tabSelezionato = this.props.tabSelezionato
    return (
      <div className={classes.root}> 
        <ExpansionPanelComp titolo={'beneficiarioMorte'} componente={NavTabsComp} tabSelezionato={tabSelezionato} tabList={tabList} 
                            handleChange={this.handleChangeUpdateTabSelezionato} isValida={isValida} />
      </div>
    );
  };
};

BenefMortePanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BenefMortePanel));
