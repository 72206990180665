import * as actionType from '../actionType/generic';
import initialState from '../../../session/InitialState';
import moment from 'moment';

const genericReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
  
  const generic = {...state}.generic;
  
  switch (action.type) {
    case actionType.LOADING_OVERLAY:
	    const activeOverlay = action.unDato;
	    const labelOverlay = action.unaLabel;
	    const genericLoadingOverlay = {...state}.generic;
	    genericLoadingOverlay.activeOverlay = activeOverlay;
	    genericLoadingOverlay.labelOverlay = labelOverlay;
	      return {
	    	...state,
	    	generic: genericLoadingOverlay,
		  };
	case actionType.SELECT_SCOPE_AMLETO:
		const genericScopeAmleto = {...state}.generic;
		genericScopeAmleto.proposalInfo.scopeAmleto = action.unDato;
		return {
			...state,
			generic: genericScopeAmleto,
		};
	case actionType.AGENZIA_NON_ADEGUATA_ORIGINI_FONDI:
	    let agenziaNonAdeguataOrigineFondi = action.unDato;
	    const genericNonAdeguataOrigineFondi = {...state}.generic;
	    // se l'agenzia e' tra quelle che rispondono alla regola 'REFRESH' della V988
	    if (agenziaNonAdeguataOrigineFondi === true) {
	    	// per i prodotti non in scopeAmleto l'agenzia sarà sempre non adeguata
	    	if (!genericNonAdeguataOrigineFondi.proposalInfo.scopeAmleto) {
	    		agenziaNonAdeguataOrigineFondi = true; 
	    	}
	    } 
	    genericNonAdeguataOrigineFondi.proposalInfo.agenziaNonAdeguataOrigineFondi = agenziaNonAdeguataOrigineFondi;
	      return {
	    	...state,
	    	generic: genericNonAdeguataOrigineFondi,
		  };
	case actionType.SCOPE_TCM_EMISSIONE:
		const genericScopeTcmEmissione = {...state}.generic;
		genericScopeTcmEmissione.proposalInfo.scopeTcmEmissione = action.unDato;
		return {
			...state,
			generic: genericScopeTcmEmissione,
		};
	case actionType.TCM_INDIVIDUALE_VALIDA:
		generic.proposalInfo.tcmIndividualeValida = action.unDato;
		return {
			...state,
			generic: generic,
		};
	case actionType.TAB_SELEZIONATO_CONTRANTE:
		generic.tabSelezionatoContraente = action.unDato;
		return {
			...state,
			generic: generic,
		};
    default:
      return state;
  }
}

export default genericReducer;