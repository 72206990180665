import * as actionType from '../actionType/datiRipartizionePremioFondi';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';
import { textTrim, calcolaTotalePercentualeFondi } from '../../../../../utility/genericUtility';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiRipartizionePremioFondiInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiRipartizionePremioFondiReducer = (state = initialState, action) => {
	
  console.log('#RT2 Reducer areaDatiRipartizionePremioFondiReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaRipartizionePremioFondi(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.UPDATE_FONDO:
      const caratteristicheContrattoFondo = {...state}.caratteristicheContratto;
      let datiRipartizionePremioFondiFondo = {...caratteristicheContrattoFondo}.datiRipartizionePremioFondi;
      let fondo = {
  	    codiceFondo: '',
  	    nomeFondo: '',
  		fondo: '',
      };
      let check = false;
      let pos = null;
      datiRipartizionePremioFondiFondo.fondo.forEach(function(item, index, array) {
  	    if(item.codiceFondo === (action.codiceFondo)) {
          item.fondo = action.unDato;
          check = true;
          pos = index;
        }
      });
      if(!check) {
        fondo = {
 	      codiceFondo: action.codiceFondo,
  	      nomeFondo: action.nomeFondo,
  		  fondo: action.unDato,
  	    };
        datiRipartizionePremioFondiFondo.fondo.push(fondo);
      } else if (textTrim(action.unDato, true) === '') {
        datiRipartizionePremioFondiFondo.fondo.splice(pos, 1);
      }
      
      // Calcolo Totale Fondi
      let totalePercFondi = calcolaTotalePercentualeFondi(datiRipartizionePremioFondiFondo.fondo);
      datiRipartizionePremioFondiFondo.esitoPercentualeFondo = (totalePercFondi === 100.0 ? 'OK' : 'KO');
      
      caratteristicheContrattoFondo.datiRipartizionePremioFondi = {...datiRipartizionePremioFondiFondo};
      return {
       	...state,
       	caratteristicheContratto: caratteristicheContrattoFondo,       	
      };
/*    case actionType.UPDATE_FONDO:
        const caratteristicheContrattoFondo = {...state}.caratteristicheContratto;
        const datiRipartizionePremioFondiFondo = {...caratteristicheContrattoFondo}.datiRipartizionePremioFondi;
        let fondo = {
  	      codiceFondo: '',
  	      nomeFondo: '',
  		  fondo: '',
  	    };
        let check = false;
        datiRipartizionePremioFondiFondo.fondo.forEach(function(item, index, array) {
      	  if(item.codiceFondo === (action.codiceFondo) ){
      		item.fondo = action.unDato;
      	    check = true;
      	  }
      	});
        if(!check){
          fondo = {
	  	    codiceFondo: action.codiceFondo,
	  	    nomeFondo: action.nomeFondo,
	  		fondo: action.unDato,
	  	  };
          datiRipartizionePremioFondiFondo.fondo.push(fondo);
        }
        caratteristicheContrattoFondo.datiRipartizionePremioFondi = {...datiRipartizionePremioFondiFondo};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoFondo,
        }; */       
    case actionType.UPDATE_CODICE_ISIN:
        const caratteristicheContrattoCodiceIsin = {...state}.caratteristicheContratto;
        const datiRipartizionePremioFondiCodiceIsin = {...caratteristicheContrattoCodiceIsin}.datiRipartizionePremioFondi;
        datiRipartizionePremioFondiCodiceIsin.codiceIsin = action.unDato;
        caratteristicheContrattoCodiceIsin.datiRipartizionePremioFondi = {...datiRipartizionePremioFondiCodiceIsin};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoCodiceIsin,
        };  
    case actionType.UPDATE_NOME_FONDO:
        const caratteristicheContrattoNomeFondo = {...state}.caratteristicheContratto;
        const datiRipartizionePremioFondiNomeFondo = {...caratteristicheContrattoNomeFondo}.datiRipartizionePremioFondi;
        datiRipartizionePremioFondiNomeFondo.nomeFondo = action.unDato;
        caratteristicheContrattoNomeFondo.datiRipartizionePremioFondi = {...datiRipartizionePremioFondiNomeFondo};
        return {
        	...state,
        	caratteristicheContratto: caratteristicheContrattoNomeFondo,
        };  
    default:
      return state;
  }
}

export const inizializzaRipartizionePremioFondi = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiRipartizionePremioFondi = jsonCopy(datiRipartizionePremioFondiInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiRipartizionePremioFondiReducer;