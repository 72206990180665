import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { uris, fetchParam } from '../../../uris';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import * as actions from '../figure/action/contraente';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionsAssFis from '../figure/action/assicurato';
import * as actionType from '../figure/actionType/contraente';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionTypeAssFis from '../figure/actionType/assicurato';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

import { textTrim } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { CONTRAENTE_FISICO, RAPPRESENTANTE_LEGALE,
	     TITOLARE_EFFETTIVO, ESECUTORE_FISICO, ASSICURATO_FISICO, 
	     CONTRAENTE_GIURIDICO, CODICE_ATT_PREV_PENSIONATO, 
	     TITOLARE_EFF_BENEF_MORTE, TITOLARE_EFF_BENEF_VITA,
	     PROVINCIA_ESTERA, PRODOTTO_TABOO_601, locale_it } from '../../../utility/jsConstants';

import moment from 'moment';
import 'moment/locale/it';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    prodotto: state.areaProdottoReducer.prodotto,
    
    datiAttEconomica: state.areaContraenteReducer.contraenteFisico.datiAttEconomica,
    datiAttEconomicaRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiAttEconomica,
    datiAttEconomicaTitEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiAttEconomica,
    datiAttEconomicaEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore.datiAttEconomica,
    datiAttEconomicaAssFisico: state.areaAssicuratoReducer.assicuratoFisico.datiAttEconomica,
    datiAttEconomicaTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiAttEconomica,
    datiAttEconomicaTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiAttEconomica,    
    statoResidenza: state.areaContraenteReducer.contraenteFisico.datiResidenza.statoResidenza, 

    controlloDatiContraenteFisico: state.areaContraenteReducer.contraenteFisico.controlloDati,
//2020-12-01 PFD: SAV 'TO BE' - ATTENZIONE:CODICE FUNZIONANTE, commentare la riga seguente      controlloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.controlloDati,
    controlloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore.controlloDati,

    controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
    controlloDatiAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.controlloDati,
    controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
    controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
    controlloDatiRappLegale: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,

    statoResidenzaRapLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiResidenza.statoResidenza,
    datiAntiriciclaggioContr: state.areaContraenteReducer.contraenteFisico.datiAntiriciclaggio,
    datiAntiriciclaggioRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiAntiriciclaggio,
    datiAntiriciclaggioTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiAntiriciclaggio,
    datiAntiriciclaggioAssicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico.datiAntiriciclaggio,
    datiEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiAttEconomica: (unDato, unActionType, figura) => dispatch(actions.updateDatiAttEconomica(unDato, unActionType, figura)),
    updateDatiAttEconomicaTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiAttEconomicaTitolareEff(unDato, unActionType)),
    updateDatiAttEconomicaEsecutore: (unDato, unActionType, figura) => dispatch(actions.updateDatiAttEconomicaEsecutore(unDato, unActionType, figura)),
    updateDatiAttEconomicaAssicuratoFisico: (unDato, unActionType) => dispatch(actionsAssFis.updateDatiAttEconomicaAssicuratoFisico(unDato, unActionType)),
  
    updateDatiAntiriciclaggio: (unDato, unActionType, figura) => dispatch(actions.updateDatiAntiriciclaggio(unDato, unActionType, figura)),
    updateDatiAntiriciclaggioAssicurato: (unDato, unActionType, figura) => dispatch(actionsAssFis.updateDatiAntiriciclaggioAssicurato(unDato, unActionType, figura)),
    updateDatiAntiriciclaggioPerContraente: (unDato, unActionType) => dispatch(actions.updateDatiAntiriciclaggioPerContraente(unDato, unActionType)),
    updateDatiAntiriciclaggioTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiAntiriciclaggioTitolareEff(unDato, unActionType)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),

  };
};
	
class DatiAttivitaEconomica extends React.Component {
  constructor(props) {
    super(props);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    this.state = {
      datiAttEconomica: datiAttivitaEconomica,
      attivitaSae: '',
      tipoPersona: 'F',
      statoResidenza: '',  
      errorPensione: false,
      codAttivitaPrecedente: '',
      codAttPrevalSvolta: '',
      statoResidenzaRapLegale: this.props.statoResidenza, 
      tipoSocietaItems: [],      
      provinciaItems: [],
      nazioneItems: [],
//2020-12-01 PFD: SAV 'TO BE'      comuneItems: [],
      saeItems: [],
      atecoItems: [],
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,        
    };
  };
  
  componentDidMount() {	  
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    this.setProvince(datiAttivitaEconomica ? datiAttivitaEconomica.statoAttPrevalSvolta : '', moment().format('DD/MM/YYYY'))
//2020-12-01 PFD: SAV 'TO BE'    this.setComuni(datiAttivitaEconomica ? datiAttivitaEconomica.provinciaAttPrevalSvolta : '', moment().format('DD/MM/YYYY'));
    this.setSae('F', this.setResidenza(this.props.figura));
    this.setAteco('F', datiAttivitaEconomica ? datiAttivitaEconomica.codATECO : '');
    const opts = {
      siglaVersione: 'ZURI',
      dataRichiesta: moment().format("DD/MM/YYYY"),
      codiceCampo: 'AATT'
    }
    fetch(uris.ambitoAttivitaItems, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An Error occured...", error)
    )
    .then((result) => {
      this.setState({
        ambitoItems: result
      })
    });
  } 
  
  datiAttivitaEconomicaFrom = figura => {  
    let datiAttivitaEconomica = null;
    if (figura === CONTRAENTE_FISICO) {
    	datiAttivitaEconomica = this.props.datiAttEconomica;
    }
    if (figura === RAPPRESENTANTE_LEGALE) {
      datiAttivitaEconomica = this.props.datiAttEconomicaRappLegale;
    } 
    if (figura === TITOLARE_EFFETTIVO) {
      datiAttivitaEconomica = this.props.datiAttEconomicaTitEff;
    }
    if (figura === ESECUTORE_FISICO) {
      datiAttivitaEconomica = this.props.datiAttEconomicaEsecutore;
    }
    if (figura === ASSICURATO_FISICO) {
      datiAttivitaEconomica = this.props.datiAttEconomicaAssFisico;
    }
    if (figura === TITOLARE_EFF_BENEF_MORTE) {
      datiAttivitaEconomica = this.props.datiAttEconomicaTitEffMorte;
    }
    if (figura === TITOLARE_EFF_BENEF_VITA) {
      datiAttivitaEconomica = this.props.datiAttEconomicaTitEffVita;
    }
    return datiAttivitaEconomica;
  }  

  datiAntiriciclaggioFrom = figura => {
    let datiAntiriciclaggio = null;
    if (figura === CONTRAENTE_FISICO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioContr;
    } else if (figura === ESECUTORE_FISICO) {
      datiAntiriciclaggio = this.props.datiEsecutore.datiAntiriciclaggio; 
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioRappLegale;
    } else if (figura === TITOLARE_EFFETTIVO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioTitolareEff;
    } else if (figura === ASSICURATO_FISICO) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioAssicuratoFisico;
    } else if (figura === RAPPRESENTANTE_LEGALE) {
      datiAntiriciclaggio = this.props.datiAntiriciclaggioRappLegale
    }
    return datiAntiriciclaggio;
  }
  
  controlloDatiFrom = figura => {  
    let controlloDati = null;
    if (figura === CONTRAENTE_FISICO) {
      controlloDati = this.props.controlloDatiContraenteFisico;
    }
    if (figura === TITOLARE_EFFETTIVO) {
      controlloDati = this.props.controlloDatiTitolareEff;
    }
    if (figura === ESECUTORE_FISICO) {
      controlloDati = this.props.controlloDatiEsecutore;
    }
    if (figura === ASSICURATO_FISICO) {
      controlloDati = this.props.controlloDatiAssicuratoFisico;
    }
    if (figura === TITOLARE_EFF_BENEF_MORTE) {
      controlloDati = this.props.controlloDatiTitEffMorte;
    }
    if (figura === TITOLARE_EFF_BENEF_VITA) {
      controlloDati = this.props.controlloDatiTitEffVita;
    }
    if (figura === RAPPRESENTANTE_LEGALE) {
      controlloDati = this.props.controlloDatiRappLegale;
    }
    return controlloDati;
  } 
  
  handleChangeAttPrevalSvolta = (event) => {		
    const nuovaAttPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const vecchiaAttPrevalSvolta = textTrim(datiAttEconomica.codAttPrevalSvolta, true);
    if (nuovaAttPrevalSvolta !== vecchiaAttPrevalSvolta) {
      let anActionType = null;
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_EFFETTIVO;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssFis.UPDATE_ATT_PREVAL_SVOLTA_ASSICURATO_FISICO;
        this.props.updateDatiAntiriciclaggioAssicurato(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_MORTE;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_ATT_PREVAL_SVOLTA;
        this.props.updateDatiAttEconomica(event, anActionType, this.props.figura);	
      }
      this.setState({
        codAttPrevalSvolta: (event === null) ? '' : event.value,
        descrAttPrevalSvolta: (event === null) ? '' : event.label,
      });
      if(!nuovaAttPrevalSvolta) {
        this.setState({
          errorPensione: false
        })
      }
    }
  }
  
  handleChangeAttivitaPrecedente = event => {
    const nuovaAttivita = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const vecchiaAttivita = textTrim(datiAntiriciclaggio.codAttivitaPrecedente, true);
    if(vecchiaAttivita !== nuovaAttivita) {
      let anActionType = null;
      if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssFis.UPDATE_ATTIVITA_PRECEDENTE_ASSICURATO_FISICO;
        this.props.updateDatiAttEconomicaAssicuratoFisico(event, anActionType);
      } else if (this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_ATTIVITA_PRECEDENTE_TITOLARE_EFFETTIVO;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_ATTIVITA_PRECEDENTE;
        this.props.updateDatiAntiriciclaggio(event, anActionType, this.props.figura);
      }
        this.setState({
          codAttivitaPrecedente: nuovaAttivita,
          descrAttivitaPrecedente: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
        });
      }
  }

  handleChangeAmbitoAttivita = event => {
    const nuovoAmbito = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura)
    const vecchioAmbito = textTrim(datiAntiriciclaggio.codAmbitoAttivita, true);
    if(vecchioAmbito !== nuovoAmbito) {
      this.props.updateDatiAntiriciclaggio(event, actionType.UPDATE_AMBITO_ATTIVITA, this.props.figura);
      }
      this.setState({
        codAmbitoAttivita: nuovoAmbito,
        descrAmbitoAttivita: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      });
    //this.setSae('F', this.setResidenza(this.props.figura));
  }

  
  handleChangeStatoPrevalSvolta = event => {
    const nuovoStatoPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const vecchioStatoPrevalSvolta = datiAttivitaEconomica.statoAttPrevalSvolta;
    if(nuovoStatoPrevalSvolta !== vecchioStatoPrevalSvolta) {
      let anActionType = null;
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_STATO_ATT_PREVAL_TITOLARE_EFFETTIVO;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssFis.UPDATE_STATO_ATT_PREVAL_ASSICURATO_FISICO;
        this.props.updateDatiAttEconomicaAssicuratoFisico(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_MORTE;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_STATO_ATT_PREVAL;
        this.props.updateDatiAttEconomica(event, anActionType, this.props.figura);
      }
      this.setProvince(nuovoStatoPrevalSvolta);
//2020-12-01 PFD: SAV 'TO BE'      this.setComuni('', moment().format('DD/MM/YYYY'));
      this.setState({
        statoAttPrevalSvolta: nuovoStatoPrevalSvolta,
        descrStatoAttPrevalSvolta: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      });
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  handleChangeProvinciaPrevalSvolta = (event) => {	
    const nuovaProvinciaPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const vecchiaProvinciaPrevalSvolta = textTrim(datiAttivitaEconomica.provinciaAttPrevalSvolta, true);
    if (nuovaProvinciaPrevalSvolta !== vecchiaProvinciaPrevalSvolta) {
      let anActionType = null;
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_EFFETTIVO;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_MORTE;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssFis.UPDATE_PROVINCIA_ATT_PREVAL_ASSICURATO_FISICO;
        this.props.updateDatiAttEconomicaAssicuratoFisico(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_PROVINCIA_ATT_PREVAL;
        this.props.updateDatiAttEconomica(event, anActionType, this.props.figura);
      }
//2020-12-01 PFD: SAV 'TO BE'      this.setComuni((event === null) ? '' : event.value, moment().format('DD/MM/YYYY'));
      this.setState({
        provinciaAttPrevalSvolta: (event === null) ? '' : event.value,
        descrProvinciaAttPrevalSvolta: (event === null) ? '' : event.label,
      });
    }
  }
/*2020-12-01 PFD: SAV 'TO BE' START
  handleChangeComunePrevalSvolta = (event) => {	
    const nuovoComunePrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    const vecchioComunePrevalSvolta = textTrim(datiAttivitaEconomica.comuneAttPrevalSvolta, true);     
    if (nuovoComunePrevalSvolta !== vecchioComunePrevalSvolta) {    
      let anActionType = null; 	 
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_EFFETTIVO;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);

      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_MORTE;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA;
        this.props.updateDatiAttEconomicaTitolareEff(event, anActionType);

      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssFis.UPDATE_COMUNE_ATT_PREVAL_ASSICURATO_FISICO;
        this.props.updateDatiAttEconomicaAssicuratoFisico(event, anActionType);
      } else {
        anActionType = actionType.UPDATE_COMUNE_ATT_PREVAL;
        this.props.updateDatiAttEconomica(event, anActionType, this.props.figura);
      }
      this.setState({
        comuneAttPrevalSvolta: (event === null) ? '' : event.value,
        descrComuneAttPrevalSvolta: (event === null) ? '' : event.label,
      });
    }
  }

  
  handleChangeComunePrevalSvoltaTextField = event => {
    const nuovoComunePrevalSvolta = textTrim(event.target.value, true);
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const vecchioComunePrevalSvolta = textTrim(datiAttEconomica.comuneAttPrevalSvolta, true);
    if(nuovoComunePrevalSvolta !== vecchioComunePrevalSvolta) {
      let anActionType = null;
      if(this.props.figura === TITOLARE_EFFETTIVO) {
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_EFFETTIVO;
        this.props.updateDatiAttEconomicaTitolareEff(nuovoComunePrevalSvolta, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE){
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_MORTE;
        this.props.updateDatiAttEconomicaTitolareEff(nuovoComunePrevalSvolta, anActionType);
      } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA){
        anActionType = actionTypeTitolareEff.UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA;
        this.props.updateDatiAttEconomicaTitolareEff(nuovoComunePrevalSvolta, anActionType);
      } else if(this.props.figura === ASSICURATO_FISICO) {
        anActionType = actionTypeAssFis.UPDATE_COMUNE_ATT_PREVAL_ASSICURATO_FISICO;
        this.props.updateDatiAttEconomicaAssicuratoFisico(nuovoComunePrevalSvolta, anActionType);
      } else {
        anActionType = actionType.UPDATE_COMUNE_ATT_PREVAL;
        this.props.updateDatiAttEconomica(nuovoComunePrevalSvolta, anActionType, this.props.figura);
      }
      this.setState({
        comuneAttPrevalSvolta: '',
        descrComuneAttPrevalSvolta: (event === null) ? '' : nuovoComunePrevalSvolta
      });
    }
  }
2020-12-01 PFD: SAV 'TO BE' END */
  
  handleChangeSaePrevalSvolta = (event) => {		
    const nuovoSaePrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    const vecchioSaePrevalSvolta = textTrim(datiAttivitaEconomica.codSAE, true);       
    if (nuovoSaePrevalSvolta !== vecchioSaePrevalSvolta) { 
      this.props.updateDatiAttEconomica(event, actionType.UPDATE_COD_SAE, this.props.figura);
      this.setAteco(this.state.tipoPersona, (event === null) ? '' : event.value);
      this.setState({
        codSAE: (event === null) ? '' : event.value,
        descrSAE: (event === null) ? '' : event.label,
      });
    }  
  }

  handleChangeAtecoPrevalSvoltaRapLegale = (event) => {
    const nuovoAtecoPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    const vecchioAtecoPrevalSvolta = textTrim(datiAttivitaEconomica.codATECORapLegale, true);   
    if(nuovoAtecoPrevalSvolta !== vecchioAtecoPrevalSvolta) {
      this.props.updateDatiAttEconomica(event, actionType.UPDATE_COD_ATECO_RAP_LEGALE, this.props.figura);
      this.setState({
        codATECORapLegale: (event === null) ? '' : event.value,
        descrATECORapLegale: (event === null) ? '' : event.label,
      });
    }
  }

  handleChangeAtecoPrevalSvolta = (event) => {	
    const nuovoAtecoPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    const vecchioAtecoPrevalSvolta = textTrim(datiAttivitaEconomica.codATECO, true);        
    if (nuovoAtecoPrevalSvolta !== vecchioAtecoPrevalSvolta) {    	  	  	  
      this.props.updateDatiAttEconomica(event, actionType.UPDATE_COD_ATECO, this.props.figura);
      this.setState({
        codATECO: (event === null) ? '' : event.value,
        descrATECO: (event === null) ? '' : event.label,
      });
    }
  }

  /* handleChangeCapPrevalSvolta = event => {
    const nuovoCapPrevalSvolta = textTrim(event.target.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);  
    const vecchioCapPrevalSvolta = textTrim(datiAttivitaEconomica.capAttPrevalSvolta, true);      
    if (nuovoCapPrevalSvolta !== vecchioCapPrevalSvolta) {     
      this.props.updateDatiAttEconomica(nuovoCapPrevalSvolta, actionType.UPDATE_CAP_ATT_PREVAL, this.props.figura);
      this.setState({
        capAttPrevalSvolta: nuovoCapPrevalSvolta, 
      });
    }
  }; */  

  handleChangeTipoSocieta = event => {
    const nuovoTipoSocieta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const vecchioTipoSocieta = textTrim(datiAttivitaEconomica.tipoSocieta, true);    
    if (nuovoTipoSocieta !== vecchioTipoSocieta) {       
      this.props.updateDatiAttEconomica(event, actionType.UPDATE_TIPO_SOCIETA, this.props.figura);
      this.setState({
        tipoSocieta: nuovoTipoSocieta,
        descrTipoSocieta: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true)
      });
	  }
  }

  setSae = (tipoPersona, nazioneResidenza) => {
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    if(this.props.figura !== CONTRAENTE_FISICO){
      if(textTrim(tipoPersona, true) !== '' && textTrim(nazioneResidenza, true) !== '') {
        const optsAttPrevSaeFis = {
          dataRichiesta: moment().format("DD/MM/YYYY"),
          siglaVersione: 'ZURI',
          descrStatoResidenza: nazioneResidenza === '86' ? '086' : '999',
          ATPS: datiAttEconomica.codAttPrevalSvolta
        };
        fetch(uris.attPrevFisSaeItems, fetchParam(optsAttPrevSaeFis))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            saeItems: result,
          });
        });
      } else {
        this.setState({
          saeItems: []
        })
      }
    } else {
      if(datiAttEconomica.codAttPrevalSvolta && nazioneResidenza) {
        const optsAttPrevSaeFis = {
          dataRichiesta: moment().format("DD/MM/YYYY"),
          siglaVersione: 'ZURI',
          descrStatoResidenza: nazioneResidenza === '86' ? '086' : '999',
          ATPS: datiAttEconomica.codAttPrevalSvolta
        };
        fetch(uris.attPrevFisSaeItems, fetchParam(optsAttPrevSaeFis))
        .then(
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            saeItems: result,
          });
        });
      } else {
        this.setState({
          saeItems: []
        })
      }
    }
  }
  
  setAteco = (tipoPersona, sottogruppo) => {
    if(textTrim(tipoPersona, true) !== '' && textTrim(sottogruppo, true) !== '') {
	  const opts = {
	    tipoPersona: tipoPersona,
	    sottogruppo: sottogruppo,
      };
      fetch(uris.atecoItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          atecoItems: result,
        });
      });
    }else{
      this.setState({
        atecoItems: [],
      });
    }
  }

  setResidenza = (figura) => {
    const statoResidenzaContraenteFisico = this.props.statoResidenza;
    const statoResidenzaRapLegale = this.props.statoResidenzaRapLegale;
    let statoResidenzaDatiAttEcon = '';
    if(figura === RAPPRESENTANTE_LEGALE){
      statoResidenzaDatiAttEcon = statoResidenzaRapLegale; 
    }else {
      statoResidenzaDatiAttEcon = statoResidenzaContraenteFisico;
    }
    return statoResidenzaDatiAttEcon;
  }

  isRequiredAttPrevalSvolta = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    } else if (this.props.figura === RAPPRESENTANTE_LEGALE /*2021-07-01 PFD: TCM_INDIV  && this.props.scopeAmleto*/) {
      required = true;
      //2021-07-01 PFD: TCM_INDIV
  	  if (this.props.scopeTcmEmissione) {
  		  const isTcmIndividValida = this.props.tcmIndividualeValida;
  		  if (isTcmIndividValida) {
  			required = false;
  		  }
  	  } else {
  		required = this.props.scopeAmleto;
  	  }
    } else if (this.props.figura === TITOLARE_EFFETTIVO) {
      //2020-12-01 PFD: SAV 'TO BE': defect 2880
      if (this.props.prodotto.codice !== PRODOTTO_TABOO_601) {
        required = true;
      }
    } else if (this.props.figura === ESECUTORE_FISICO /*2021-07-01 PFD: TCM_INDIV  && this.props.scopeAmleto*/) {
      required = this.props.scopeAmleto;
    } else if (this.props.figura === ASSICURATO_FISICO) {
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE /*2021-07-01 PFD: TCM_INDIV  && this.props.scopeAmleto*/) {
      required = this.props.scopeAmleto;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA /*2021-07-01 PFD: TCM_INDIV  && this.props.scopeAmleto*/) {  
      required = this.props.scopeAmleto;
    }
    return required;
  }

  isRequiredAttivitaPrecedente = () => {
    let required = false;
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura)
    
    //2020-12-01 PFD: SAV 'TO BE' - SE PENSIONATO IGNORO LO SCOPE AMLETO
    if(this.props.figura === CONTRAENTE_FISICO || this.props.figura === ASSICURATO_FISICO || 
       this.props.figura === TITOLARE_EFFETTIVO || this.props.figura === RAPPRESENTANTE_LEGALE) {
    	// Se Pensionato
    	if (datiAttivitaEconomica.codAttPrevalSvolta === CODICE_ATT_PREV_PENSIONATO) {
    		 required = true;
    	}
    }
    
    /*2020-12-01 PFD: SAV 'TO BE' - START  
    if(this.props.figura === CONTRAENTE_FISICO && datiAttivitaEconomica.codAttPrevalSvolta === CODICE_ATT_PREV_PENSIONATO && this.props.scopeAmleto === true) {
      required = true;
    }
    if(this.props.figura === ASSICURATO_FISICO && datiAttivitaEconomica.codAttPrevalSvolta === CODICE_ATT_PREV_PENSIONATO && this.props.scopeAmleto === true) {
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }
    if(this.props.figura === TITOLARE_EFFETTIVO && datiAttivitaEconomica.codAttPrevalSvolta === CODICE_ATT_PREV_PENSIONATO && this.props.scopeAmleto === true) {
      required = true;
    }
    if(this.props.figura === RAPPRESENTANTE_LEGALE && datiAttivitaEconomica.codAttPrevalSvolta === CODICE_ATT_PREV_PENSIONATO && this.props.scopeAmleto === true) {
      required = true;
    }
    2020-12-01 PFD: SAV 'TO BE' - END */
    
    return required;
  }

  isRequiredAmbitoAttivita = () => {
    let required = false;
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura)
    if(this.props.figura === CONTRAENTE_FISICO && datiAttivitaEconomica.codSAE === '600' && this.props.scopeAmleto) {
      required = true;
    }else if (this.props.figura === TITOLARE_EFFETTIVO && datiAttivitaEconomica.codSAE === '600' && this.props.scopeAmleto){
      required = true;
    }
    return required;
  }

  isRequiredStatoPrevalSvolta = () => {
    let required = false;
    if (this.props.figura === RAPPRESENTANTE_LEGALE ) {
    	required = true;
    	//2021-07-01 PFD: TCM_INDIV
	   	if (this.props.scopeTcmEmissione) {
	   		const isTcmIndividValida = this.props.tcmIndividualeValida;
	   		if (isTcmIndividValida) {
	   			required = false;
	   		}
	   	}
    }
    if(this.props.figura === CONTRAENTE_FISICO ) {
      required = true;
    }
    if(this.props.figura === TITOLARE_EFFETTIVO ) {
    	//2020-12-01 PFD: SAV 'TO BE': defect 2880
        if (this.props.prodotto.codice !== PRODOTTO_TABOO_601) {
          required = true;
        }
    }
    if(this.props.figura === TITOLARE_EFF_BENEF_MORTE ) {
      required = true;
    }
    if(this.props.figura === TITOLARE_EFF_BENEF_VITA ) {
      required = true;
    }
    if(this.props.figura === ESECUTORE_FISICO ) {
      required = true;
    }
    if(this.props.figura === ASSICURATO_FISICO ) {
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }
    return required;
  }

  isRequiredProvinciaPrevalSvolta = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO ) {
      required = true;
    }
    if (this.props.figura === RAPPRESENTANTE_LEGALE ) {
    	required = true;
    	//2021-07-01 PFD: TCM_INDIV
    	if (this.props.scopeTcmEmissione) {
	   		const isTcmIndividValida = this.props.tcmIndividualeValida;
	   		if (isTcmIndividValida) {
	   			required = false;
	   		}
	   	}
    }
    if(this.props.figura === TITOLARE_EFFETTIVO ) {
    	//2020-12-01 PFD: SAV 'TO BE': defect 2880
        if (this.props.prodotto.codice !== PRODOTTO_TABOO_601) {
          required = true;
        }
    }
    if(this.props.figura === TITOLARE_EFF_BENEF_MORTE ) {
      required = true;
    }
    if(this.props.figura === TITOLARE_EFF_BENEF_VITA ) {
      required = true;
    }
    if(this.props.figura === ESECUTORE_FISICO ) {
      required = true;
    }
    if(this.props.figura === ASSICURATO_FISICO  ) {
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
      // required = true;
      required = false;
      // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    }
    return required;
  }
  
//2020-09-10 PFD: Abbassamento Controlli AMLETO - START	  
//  isRequiredComunePrevalSvolta = () => {
//	let required = false;	  
//    if (this.props.figura === CONTRAENTE_FISICO) {    
//      required = true;
//    }
//    if (this.props.figura === RAPPRESENTANTE_LEGALE) {
//      required = true
//    }
//    if(this.props.figura === TITOLARE_EFFETTIVO) {
//      required = true;
//    }
//    if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
//      required = true;
//    }
//    if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
//      required = true;
//    }
//    if(this.props.figura === ESECUTORE_FISICO) {
//      required = true;
//    }
//    if(this.props.figura === ASSICURATO_FISICO) {
//      required = true;
//    }
    // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
//    return required;
//  }
  
  /* isRequiredCapPrevalSvolta = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    }
    return required;
  } */
  
  isRequiredSaePrevalSvolta = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    }
    return required;
  }
  
  isRequiredAtecoPrevalSvolta = () => {
    let required = false;
    if (this.props.figura === CONTRAENTE_FISICO) {
      required = true;
    }
    
    return required;
  }

  initStatoCampi = () => {
    return [ {id: 'prevalAttSvolta', isError: false},
             {id: 'provAttPreval', isError: false}, 
             {id: 'comuneAttPreval', isError: false}, 
             {id: 'capAttPrevalSvolta', isError: false},
             {id: 'sae', isError: false},             
             {id: 'ateco', isError: false},
             {id: 'statoAttPreval', isError: false},
             {id: 'tipoSocieta', isError: false},
           ];
  }
		  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  isDisabledAttPrevalSvolta = () => {
    let disabled = true;
    if (this.props.figura === RAPPRESENTANTE_LEGALE && this.props.scopeAmleto) {
      disabled = false;
    } else if (this.props.figura === ESECUTORE_FISICO && this.props.scopeAmleto) {
      disabled = false;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_MORTE && this.props.scopeAmleto) {
      disabled = false;
    } else if (this.props.figura === TITOLARE_EFF_BENEF_VITA && this.props.scopeAmleto) {
      disabled = false;
    }
      return disabled;
    }

  isDisabledAttivitaPrecedente = () => {
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    if(this.props.figura === CONTRAENTE_FISICO || this.props.figura === TITOLARE_EFFETTIVO) {
      return (datiAttivitaEconomica.codAttPrevalSvolta !== CODICE_ATT_PREV_PENSIONATO);
    }
    return (datiAttivitaEconomica.codAttPrevalSvolta !== CODICE_ATT_PREV_PENSIONATO);
  }

  isDisabledAmbitoAttivita = () => {
    const datiAttivitaEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    if(this.props.figura === CONTRAENTE_FISICO || this.props.figura === TITOLARE_EFFETTIVO) {
      return (datiAttivitaEconomica.codSAE !== '600') || this.props.scopeAmleto === false
    }
    return (datiAttivitaEconomica.codSAE !== '600')
  }

  isDisabledStatoAttPrevalSvolta = () => {
    let disabled = true;
    if (this.props.figura === RAPPRESENTANTE_LEGALE ) {
      disabled = false;
    }
    if(this.props.figura === TITOLARE_EFFETTIVO ) {
      disabled = false;
    }
    if(this.props.figura === TITOLARE_EFF_BENEF_MORTE ) {
      disabled = false;
    }
    if(this.props.figura === TITOLARE_EFF_BENEF_VITA ) {
      disabled = false;
    }
    if(this.props.figura === ASSICURATO_FISICO ) {
      disabled = false;
    }
    if(this.props.figura === ESECUTORE_FISICO ) {
      disabled = false;
    }
    if(this.props.figura === CONTRAENTE_FISICO ) {
      disabled = false;
    }
    return disabled;
  }

  isDisabledProvinciaAttPrevalSvolta = () => {
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const statoAttPrevalSvolta = textTrim(datiAttEconomica.statoAttPrevalSvolta, true);
    return (statoAttPrevalSvolta === '');
  }

  /* 2020-12-01 PFD: SAV 'TO BE' START
  isDisabledComune = () => {
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    const provinciaAttPrevalSvolta = textTrim(datiAttEconomica.provinciaAttPrevalSvolta, true); 
	return (provinciaAttPrevalSvolta === '');
  }
  2020-12-01 PFD: SAV 'TO BE' END */
  
  isDisabledSae = () => {
    var disabled = false;
	const figura = this.props.figura;
    if (figura === RAPPRESENTANTE_LEGALE) {
    	disabled = true
    } 
	return disabled;
  }
  
  isDisabledAteco = () => {
    var disabled = false;
	const figura = this.props.figura;
    if (figura === RAPPRESENTANTE_LEGALE) {
    	disabled = true
    } else { 
	    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
	    const codSAE = textTrim(datiAttEconomica.codSAE, true); 
	    disabled = (codSAE === '');
    }    
    return disabled;
  }
  

  setProvince = (nazione) => {
    if(textTrim(nazione, true)!==''){
	    const opts = {
        dataRiferimentoProv: null,
	      nazione: nazione,
	    };
	    fetch(uris.provinciaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      this.setState({
	    	provinciaItems: result,
	      });
	    });
    }else{
        this.setState({
        	provinciaItems: [],
        });
    }
  }
/*2020-12-01 PFD: SAV 'TO BE' - START
  setComuni = (codProvincia, dataNascita) => {
    if(textTrim(codProvincia, true)!=='' && textTrim(dataNascita, true)!==''){
	  const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        this.setState({
          comuneItems: result,
        });
      });
    }else{
      this.setState({
        comuneItems: [],
      });
    }
  }
2020-12-01 PFD: SAV 'TO BE' - END*/
  
  render() {
    const { classes, dominio, figura, labelFieldSet } = this.props;
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(figura);  
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(figura);
    const controlloDati = this.controlloDatiFrom(figura); 
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant='h6'>{t('datiAttivitaEconomica')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant='subtitle1' />
        {/* {(figura === RAPPRESENTANTE_LEGALE) ?
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item md={6} xs={12}>
            <SelectComp onChange={this.handleChangeTipoSocieta} options={dominio.tipoSocietaItems} ricerca='comboRicerca' etichetta='tipoSocieta' 
                        value={{value: datiAttEconomica.tipoSocieta, label: datiAttEconomica.descrTipoSocieta}} required={this.isRequiredTipoSocieta} 
                        controlloDati={controlloDati} id='tipoSocieta' onError={this.handleError} />
          </Grid>
 */}
        <Grid container direction='row' justify='center' alignItems='flex-start'>
        {(figura === RAPPRESENTANTE_LEGALE || figura === ESECUTORE_FISICO || figura === TITOLARE_EFF_BENEF_VITA ||figura === TITOLARE_EFF_BENEF_MORTE) &&
            <Grid container direction='row' justify='center' alignItems='flex-start'>
              <Grid item md={8} xs={12}>
                <SelectComp onChange={this.handleChangeAttPrevalSvolta} options={dominio.prevalenteAttivitaSvoltaItems} ricerca='comboRicerca' etichetta='prevalAttSvolta'
                  value={{ value: datiAttEconomica.codAttPrevalSvolta, label: datiAttEconomica.descrAttPrevalSvolta }} disabled={this.isDisabledAttPrevalSvolta()}
                  required={this.isRequiredAttPrevalSvolta()} controlloDati={controlloDati} id='prevalAttSvolta' onError={this.handleError} />
              </Grid>
              <Grid item md={4} xs={12}></Grid>
            </Grid>
          }
          
          {(figura === CONTRAENTE_FISICO || figura === ASSICURATO_FISICO || figura === TITOLARE_EFFETTIVO) &&
            <Grid container direction='row' justify='center' alignItems='flex-start'>
              <Grid item md={6} xs={12}>
                <SelectComp onChange={this.handleChangeAttPrevalSvolta} options={dominio.prevalenteAttivitaSvoltaItems} ricerca='comboRicerca' etichetta='prevalAttSvolta' 
                            value={{value: datiAttEconomica.codAttPrevalSvolta, label: datiAttEconomica.descrAttPrevalSvolta}} 
                            required={this.isRequiredAttPrevalSvolta()} controlloDati={controlloDati} id='prevalAttSvolta' onError={this.handleError} />            	
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectComp onChange={this.handleChangeAttivitaPrecedente} options={dominio.prevalenteAttivitaSvoltaItems} ricerca='comboRicerca' etichetta='attivitaPrecedente' 
                            value={{value: datiAntiriciclaggio.codAttivitaPrecedente, label: datiAntiriciclaggio.descrAttivitaPrecedente}} disabled={this.isDisabledAttivitaPrecedente()}
                            required={this.isRequiredAttivitaPrecedente()} controlloDati={controlloDati} id='attivitaPrecedente' onError={this.handleError} />
              </Grid>
            </Grid>
          }
         {/*2020-12-01 PFD: SAV 'TO BE' - START - aggiunta nella condizione precedente in OR con CONTRAENTE_FISICO
          {figura === ASSICURATO_FISICO && 
          <Grid container direction='row' justify='center' alignItems='flex-start'>
            <Grid item md={6} xs={12}>
            <SelectComp onChange={this.handleChangeAttPrevalSvolta} options={dominio.prevalenteAttivitaSvoltaItems} ricerca='comboRicerca' etichetta='prevalAttSvolta' 
                        value={{value: datiAttEconomica.codAttPrevalSvolta, label: datiAttEconomica.descrAttPrevalSvolta}} 
                        required={this.isRequiredAttPrevalSvolta()} controlloDati={controlloDati} id='prevalAttSvolta' onError={this.handleError} />            	
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectComp onChange={this.handleChangeAttivitaPrecedente} options={dominio.prevalenteAttivitaSvoltaItems} ricerca='comboRicerca' etichetta='attivitaPrecedente' 
                          value={{value: datiAntiriciclaggio.codAttivitaPrecedente, label: datiAntiriciclaggio.descrAttivitaPrecedente}} disabled={this.isDisabledAttivitaPrecedente()}
                          required={this.isRequiredAttivitaPrecedente()} controlloDati={controlloDati} id='attivitaPrecedente' onError={this.handleError} />
            </Grid>
          </Grid>
          } 
          */} 
          <Grid item md={4} xs={12}>
              <SelectComp onChange={this.handleChangeStatoPrevalSvolta} options={dominio.nazioneItems} ricerca='comboRicerca' etichetta='stato' 
                          value={{value: datiAttEconomica.statoAttPrevalSvolta, label: datiAttEconomica.descrStatoAttPrevalSvolta}} disabled={this.isDisabledStatoAttPrevalSvolta()}
                          required={this.isRequiredStatoPrevalSvolta()} controlloDati={controlloDati} id='statoAttPreval' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeProvinciaPrevalSvolta} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provAttPreval' 
                        value={{value: datiAttEconomica.provinciaAttPrevalSvolta, label: datiAttEconomica.descrProvinciaAttPrevalSvolta}} disabled={this.isDisabledProvinciaAttPrevalSvolta()}
                        required={this.isRequiredProvinciaPrevalSvolta()} controlloDati={controlloDati} id='provAttPreval' onError={this.handleError} />            	            	
          </Grid>
          {/* 2020-12-01 PFD: SAV 'TO BE' START
          	<Grid item md={4} xs={12}>
            {datiAttEconomica.provinciaAttPrevalSvolta !== PROVINCIA_ESTERA &&
              <SelectComp onChange={this.handleChangeComunePrevalSvolta} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comuneAttPreval' 
                          value={{value: datiAttEconomica.comuneAttPrevalSvolta, label: datiAttEconomica.descrComuneAttPrevalSvolta}} disabled={this.isDisabledComune()}
                          required={this.isRequiredComunePrevalSvolta()} controlloDati={controlloDati} id='comuneAttPreval' onError={this.handleError} />
            }
            {datiAttEconomica.provinciaAttPrevalSvolta === PROVINCIA_ESTERA && 
              <TextFieldComp onBlur={this.handleChangeComunePrevalSvoltaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneAttPreval' label='comuneAttPreval' 
                             value={datiAttEconomica.descrComuneAttPrevalSvolta} required={this.isRequiredComunePrevalSvolta()} controlloDati={controlloDati} disabled={this.isDisabledComune()}
                             onError={this.handleError} />
            }
          </Grid> 
          2020-12-01 PFD: SAV 'TO BE' END */}
          {/* <Grid item md={2} xs={12}> 
              <TextFieldComp onBlur={this.handleChangeCapPrevalSvolta} aStyle={classes.textField} id='capAttPrevalSvolta' label='capAttPreval' 
                            value={datiAttEconomica.capAttPrevalSvolta} required={this.isRequiredCapPrevalSvolta()} 
                            controlloDati={controlloDati} onError={this.handleError} />
          </Grid> */}
          {/* 2020-12-01 PFD: SAV 'TO BE' START */}
          <Grid item md={4} xs={12}></Grid>
          {/* 2020-12-01 PFD: SAV 'TO BE' END */}
          
          {figura === RAPPRESENTANTE_LEGALE &&
            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
              <Grid item md={6} xs={12}>
                <SelectComp onChange={this.handleChangeSaePrevalSvolta} options={this.state.saeItems} ricerca='comboRicerca' etichetta='sae'
                  value={{ value: datiAttEconomica.codSAE, label: datiAttEconomica.descrSAE }}
                  required={this.isRequiredSaePrevalSvolta()} controlloDati={controlloDati} id='sae' onError={this.handleError} disabled={this.isDisabledSae()}/>
              </Grid> 
              <Grid item md={6} xs={12}>
                <SelectComp onChange={this.handleChangeAtecoPrevalSvoltaRapLegale} options={this.state.atecoItems} ricerca='comboRicerca' etichetta='ateco'
                  value={{ value: datiAttEconomica.codATECORapLegale, label: datiAttEconomica.descrATECORapLegale }} disabled={this.isDisabledAteco()}
                  required={this.isRequiredAtecoPrevalSvolta()} controlloDati={controlloDati} id='atecoRapLegale' onError={this.handleError} />
              </Grid>
            </Grid>
          }
          {figura === CONTRAENTE_FISICO &&   	
          <Grid container direction='row' justify='center' alignItems='flex-start'>		
            <Grid item md={6} xs={12}>
              <SelectComp onChange={this.handleChangeSaePrevalSvolta} options={this.state.saeItems} ricerca='comboRicerca' etichetta='sae' 
                          value={{value: datiAttEconomica.codSAE, label: datiAttEconomica.descrSAE}} 
                          required={this.isRequiredSaePrevalSvolta()} controlloDati={controlloDati} id='sae' onError={this.handleError} />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectComp onChange={this.handleChangeAtecoPrevalSvolta} options={this.state.atecoItems} ricerca='comboRicerca' etichetta='ateco' 
                          value={{value: datiAttEconomica.codATECO, label: datiAttEconomica.descrATECO}} disabled={this.isDisabledAteco()}
                          required={this.isRequiredAtecoPrevalSvolta()} controlloDati={controlloDati} id='ateco' onError={this.handleError} />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextFieldComp onBlur={this.handleChangeAltroTitolo} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
                             id='altroTitolo' value={datiAttEconomica.altroTitoloDiStudio} required={this.isRequiredAltroTitolo()} controlloDati={controlloDati}
                             disabled={this.isDisabledAltroTitolo()} onError={this.handleError} label='altroTitoloStudio' />
            </Grid> */}
          </Grid>
          }
          {(figura === CONTRAENTE_FISICO)  &&
            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
              <Grid item md={4} xs={12}>
                <SelectComp onChange={this.handleChangeAmbitoAttivita} options={this.state.ambitoItems} ricerca='comboRicerca' etichetta='ambitoAttivita'
                  value={{ value: datiAntiriciclaggio.codAmbitoAttivita, label: datiAntiriciclaggio.descrAmbitoAttivita }} disabled={this.isDisabledAmbitoAttivita()}
                  required={this.isRequiredAmbitoAttivita()} controlloDati={controlloDati} id='ambitoAttivita' onError={this.handleError} />
              </Grid>
            </Grid>
          }   
        </Grid>
      {/*</fieldset>*/}
      </Fragment>		    
    );
  }
		        	
  updateSaeAteco(prevProps) {
  const statoResidenzaCorrente = this.setResidenza(this.props.figura);
  const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
  const codAttPreval = datiAttEconomica.codAttPrevalSvolta;
    if (this.state.statoResidenza !== statoResidenzaCorrente || this.state.attivitaSae !== codAttPreval) {   	
      this.setSae(this.state.tipoPersona, statoResidenzaCorrente);
      this.setState({
        statoResidenza: statoResidenzaCorrente, 
        attivitaSae: codAttPreval
 /*       codSAE: '',
        descrSAE: '',    
        codATECO: '',
        descrATECO: '',        */
      }); 
      /* if(this.props.figura === CONTRAENTE_FISICO) {
        this.props.updateDatiAttEconomica(null, actionType.UPDATE_COD_ATECO, this.props.figura);
        this.props.updateDatiAttEconomica(null, actionType.UPDATE_COD_SAE, this.props.figura);
      } */
      this.setAteco(this.state.tipoPersona, this.state.datiAttEconomica.codSAE);
    }
  }  
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
      return (
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError || this.state.errorPensione
      );
	});
    console.log('########DatiAttivitaEconomica componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//    console.log('########DatiAttivitaEconomica componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//    console.log('########DatiAttivitaEconomica componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }  
	  
  componentDidUpdate(prevProps, prevState) {
    this.updateSaeAteco(prevProps);
    this.isInError(prevProps, prevState);
    const datiAntiriciclaggio = this.datiAntiriciclaggioFrom(this.props.figura);
    const datiAttEconomica = this.datiAttivitaEconomicaFrom(this.props.figura);
    if(prevState.codAttivitaPrecedente !== this.state.codAttivitaPrecedente && datiAntiriciclaggio.codAttivitaPrecedente === datiAttEconomica.codAttPrevalSvolta && datiAttEconomica.codAttPrevalSvolta) {
      this.setState({
        errorPensione: true
      })
    } else if (prevState.codAttivitaPrecedente !== this.state.codAttivitaPrecedente && 
              (datiAntiriciclaggio.codAttivitaPrecedente !== datiAttEconomica.codAttPrevalSvolta)) {
      this.setState({
        errorPensione: false
      })
    }
    if(prevState.errorPensione !== this.state.errorPensione) {
      this.props.checkErrorPensione(this.state.errorPensione);
    }
  }    
}

DatiAttivitaEconomica.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,  
  id: PropTypes.string.isRequired,   
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiAttivitaEconomica));
