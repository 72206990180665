import React from 'react';
import { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
//import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NumberFieldComp from '../../common/NumberFieldComp';
//import TextAreaComp from '../../common/TextAreaComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';
import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';

import { textTrim } from '../../../utility/genericUtility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },  
  textField: theme.textField,
  textFieldWidth100: {
	 width: '100%',
  },
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiGeneraliBenefGenerico.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiGeneraliBenefMorteGenerico: state.areaBenefMorteReducer.benefMorteGenerico.datiGenerali,
    controlloDatiBenefMorteGenerico: state.areaBenefMorteReducer.benefMorteGenerico.controlloDati,
	datiGeneraliBenefVitaGenerico: state.areaBenefVitaReducer.benefVitaGenerico.datiGenerali,
	controlloDatiBenefVitaGenerico: state.areaBenefVitaReducer.benefVitaGenerico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiGeneraliBenefGenerico.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiGeneraliBenefMorteGenerico: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiGeneraliBenefMorteGenerico(unDato, unActionType)),
    updateDatiGeneraliBenefVitaGenerico: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiGeneraliBenefVitaGenerico(unDato, unActionType)),    
  };
};
	
class DatiGeneraliBenefGenerico extends React.Component {
  constructor(props) {
	super(props);
    let datiGenerali = this.datiGeneraliFrom(this.props.figura);
    this.state = {
      percBeneficiario: datiGenerali.percBeneficiario,
      benefGenerico: datiGenerali.benefGenerico,
      descrBenefGenerico: datiGenerali.descrBenefGenerico,
      descrBenefEsteso: datiGenerali.descrBenefEsteso, 
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
	};
  };

  controlloDatiFrom = figura => {
    let controlloDati = null;
    if (this.props.figura === 'beneficiarioVitaGenerico') {
      controlloDati = this.props.controlloDatiBenefVitaGenerico;
    } else if (this.props.figura === 'beneficiarioMorteGenerico') {
      controlloDati = this.props.controlloDatiBenefMorteGenerico;
    }  
    return controlloDati;
  }
  
  datiGeneraliFrom = figura => {
    let datiGenerali = null;
    if (this.props.figura === 'beneficiarioVitaGenerico') {
      datiGenerali = this.props.datiGeneraliBenefVitaGenerico;
    } else if (this.props.figura === 'beneficiarioMorteGenerico') {
      datiGenerali = this.props.datiGeneraliBenefMorteGenerico;
    }  
    return datiGenerali;
  }
  
  benefGenericoItemsFrom = figura => {
    let benefGenericoItems = null;
    if (this.props.figura === 'beneficiarioVitaGenerico') {
      benefGenericoItems = this.props.dominio.beneficiarioGenericoVitaItems;
    } else if (this.props.figura === 'beneficiarioMorteGenerico') {
      benefGenericoItems = this.props.dominio.beneficiarioGenericoMorteItems;
    }  
    return benefGenericoItems;
  }
  
  handleChangePercBeneficiario = event => {
//    console.log('#RTCFA DatiGeneraliBenefGenerico CodFisc new value:' + event.target.value);
    const nuovoPercBeneficiario = textTrim(event.target.value, true);
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioPercBeneficiario = textTrim(datiGenerali.percBeneficiario, true);
    if (nuovoPercBeneficiario !== vecchioPercBeneficiario) {         
      let anActionType = null;
      if (this.props.figura === 'beneficiarioMorteGenerico') {
        anActionType = actionTypeBenefMorte.UPDATE_PERCENTUALE_BENEF_MORTE_GENERICO;
        this.props.updateDatiGeneraliBenefMorteGenerico(nuovoPercBeneficiario, anActionType);
      } else if (this.props.figura === 'beneficiarioVitaGenerico') {
        anActionType = actionTypeBenefVita.UPDATE_PERCENTUALE_BENEF_VITA_GENERICO;
        this.props.updateDatiGeneraliBenefVitaGenerico(nuovoPercBeneficiario, anActionType);
      } 
      this.setState({
        percBeneficiario: nuovoPercBeneficiario, 
      });
    }  
  };
  
  handleChangeBenefGenerico = event => {
//    console.log('#RTCFA DatiGeneraliBenefGenerico benefGenerico new value', event);
    const nuovoBenefGenerico = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
//  const vecchiaProvinciaNascita = textTrim(this.state.provinciaNascita, true); 
    const datiGenerali = this.datiGeneraliFrom(this.props.figura);
    const vecchioBenefGenerico = textTrim(datiGenerali.benefGenerico, true);    
    if (nuovoBenefGenerico !== vecchioBenefGenerico) {     
      let anActionType = null;
      if (this.props.figura === 'beneficiarioMorteGenerico') {
        anActionType = actionTypeBenefMorte.UPDATE_BENEF_MORTE_GENERICO;
        this.props.updateDatiGeneraliBenefMorteGenerico(event, anActionType);
        // azzero il bebeficiario generico
        anActionType = actionTypeBenefMorte.UPDATE_ESTESO_BENEF_MORTE_GENERICO;
        this.props.updateDatiGeneraliBenefMorteGenerico('', anActionType);
      } else if (this.props.figura === 'beneficiarioVitaGenerico') {
        anActionType = actionTypeBenefVita.UPDATE_BENEF_VITA_GENERICO;
        this.props.updateDatiGeneraliBenefVitaGenerico(event, anActionType);
        // azzero il bebeficiario generico
        anActionType = actionTypeBenefVita.UPDATE_ESTESO_BENEF_VITA_GENERICO;
        this.props.updateDatiGeneraliBenefVitaGenerico('', anActionType);
      }
      this.setState({
        benefGenerico: nuovoBenefGenerico,
        descrBenefGenerico: (event === null) ? '' : event.label,
        descrBenefEsteso: '',
      });
    }  
  };
 
  handleChangeEsteso = event => {
    console.log('#RTCFA DatiGeneraliBenefGenerico Esteso new value:' + event.target.value);
    let anActionType = null;
    if (this.props.figura === 'beneficiarioMorteGenerico') {
      anActionType = actionTypeBenefMorte.UPDATE_ESTESO_BENEF_MORTE_GENERICO;
      this.props.updateDatiGeneraliBenefMorteGenerico(event.target.value, anActionType);
    } else if (this.props.figura === 'beneficiarioVitaGenerico') {
      anActionType = actionTypeBenefVita.UPDATE_ESTESO_BENEF_VITA_GENERICO;
      this.props.updateDatiGeneraliBenefVitaGenerico(event.target.value, anActionType);
    } 
    this.setState({
      descrBenefEsteso: event.target.value, 
    });
  };
	  
  isDisabledSelectBenefGenerico = () => {
	  const datiGenerali = this.datiGeneraliFrom(this.props.figura); 	  
	  return textTrim(datiGenerali.descrBenefEsteso, true) !== '';
  }
  
  isDisabledSelectBenefEsteso = () => {
	  const datiGenerali = this.datiGeneraliFrom(this.props.figura); 	  
	  return textTrim(datiGenerali.descrBenefGenerico, true) !== '';
  }
  
  isRequiredBenefGenerico = () => {	 
	  return !this.isDisabledSelectBenefGenerico();
  }

  isRequiredBenefEsteso= () => {	 
	  return !this.isDisabledSelectBenefEsteso();
  }

  initStatoCampi = () => {
    return [ {id: 'percBeneficiario', isError: false},
             {id: 'beneficiarioGenerico', isError: false}, 
    	   ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  render() {
	console.log("DatiGeneraliBenefGenerico.this.props", this.props);
//    const { t } = this.props; //hoc    
    const { classes, figura, labelFieldSet, dominio } = this.props; 
    const datiGenerali = this.datiGeneraliFrom(figura); 
    const controlloDati = this.controlloDatiFrom(figura); 
    const benefGenericoItems = this.benefGenericoItemsFrom(figura); 
    const benefGenericoList = this.isDisabledSelectBenefGenerico() !== true ? benefGenericoItems :  [] ;
    
    const isRequiredBenefGenerico = this.isRequiredBenefGenerico();
    const isRequiredBenefEsteso = this.isRequiredBenefEsteso();
    
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="h6">{t('datiGenerali')}</Typography></legend>*/}
        <DividerComp titolo={labelFieldSet} variant="subtitle1" />
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
            <NumberFieldComp onBlur={this.handleChangePercBeneficiario} aStyle={classes.textField} id='percBeneficiario' label='percBeneficiario' format='###,###' 
            	             value={datiGenerali.percBeneficiario} maxLength='7' required={true} controlloDati={controlloDati} onError={this.handleError} disabled={true} />
          </Grid>
          <Grid item xs={12}>
            <SelectComp onChange={this.handleChangeBenefGenerico} options={benefGenericoList} ricerca='comboRicerca' etichetta='beneficiarioGenerico' 
            	value={{value: datiGenerali.benefGenerico, label: datiGenerali.descrBenefGenerico}} required={isRequiredBenefGenerico} 
            	controlloDati={controlloDati} disabled={this.isDisabledSelectBenefGenerico()} id='beneficiarioGenerico' onError={this.handleError} />
          </Grid>
          {/*  
          <Grid item xs={12}>
            <TextAreaComp onBlur={this.handleChangeEsteso} aStyle={classNames(classes.textFieldWidth100)} rows="4" id="descrBenGen" label="beneficiarioEsteso" value={datiGenerali.descrBenefEsteso} required={isRequiredBenefEsteso} controlloDati={controlloDati} disabled={this.isDisabledSelectBenefEsteso()} />
          </Grid>
          */}
        </Grid>    
      {/*</fieldset>*/}
      </Fragment>    
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiGeneraliFisico componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//	    console.log('########DatiGeneraliFisico componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	    console.log('########DatiGeneraliFisico componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
  
  componentDidUpdate(prevProps, prevState) {
	 this.isInError(prevProps, prevState);
  }
}

DatiGeneraliBenefGenerico.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
  dominio: PropTypes.object.isRequired,  
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiGeneraliBenefGenerico));
