import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { uris, fetchParam } from '../../../uris';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';


import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';
import RadioButtonComp from '../../common/RadioButtonComp';

import { CONTRAENTE_GIURIDICO, BENEF_VITA_GIURIDICO, BENEF_MORTE_GIURIDICO, 
	     PROVINCIA_ESTERA, CODICE_ATT_PREV_ALTRO } from '../../../utility/jsConstants';
import { radioButtonAttEconomicaFlAllegati, radioButtonPaesiBlackList, 
	     radioButtonEntitaFinanzPassiva, radioButtonSocietaAzFiduc, 
	     radioButtonSocietaQuotata } from '../../store/radioButtonStore';

import * as actions from '../figure/action/contraente';
import * as actionType from '../figure/actionType/contraente';
import * as actionTypeBenefVitaGiuridico from '../figure/actionType/beneficiarioVita';
import * as actionsBenefVitaGiuridico from '../figure/action/beneficiarioVita';
import * as actionsBenefMorteGiuridico from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorteGiuridico from '../figure/actionType/beneficiarioMorte';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { textTrim, jsonCopy } from '../../../utility/genericUtility';
import { PERSONA_GIURIDICA, locale_it } from '../../../utility/jsConstants';
import { relazPaesiListInizializzato } from '../../../session/info/componentiBase/sezioniAnagrafica';


import moment from 'moment';
import 'moment/locale/it';
import ButtonComp from '../../common/ButtonComp';
import { Checkbox } from '@material-ui/core';
import CheckboxComp from '../../common/CheckboxComp';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textRelazPaesi: {
    fontSize: 18,  
    marginLeft: 8,
  },
  
  textStyleEnabled: theme.textStyleEnabled,
  textStyleDisabled: theme.textStyleDisabled,

  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  console.log('#RTCFA DatiAttivitaEconomicaGiuridico.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,	  
    datiAttEconomicaGiuridico: state.areaContraenteReducer.contraenteGiuridico.datiAttEconomicaGiuridico,
    datiAttEconomicaBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiAttEconomica,
    datiAttEconomicaBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiAttEconomica,

    statoResidenza: state.areaContraenteReducer.contraenteGiuridico.datiResidenza.statoResidenza, 
    statoResidenzaBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiResidenza.statoResidenza, 
    statoResidenzaBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiResidenza.statoResidenza, 

    controlloDatiContraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
    controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
    controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,

    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiAttivitaEconomicaGiuridico.mapDispatchToProps.dispatch', dispatch);  
  return {
    updateDatiAttEconomicaGiuridico: (unDato, unActionType, figura) => dispatch(actions.updateDatiAttEconomicaGiuridico(unDato, unActionType, figura)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};
	
class DatiAttivitaEconomicaGiuridico extends React.Component {
  constructor(props) {
    super(props);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);  
    this.state = {
      datiAttEconomicaGiuridico: this.props.datiAttEconomicaGiuridico,
      datiAttEconomicaBenefVitaGiuridico: this.props.datiAttEconomicaBenefVitaGiuridico,
      datiAttEconomicaBenefMorteGiuridico: this.props.datiAttEconomicaBenefMorteGiuridico,
	  tipoSocieta: '',
	  codAttPrevalSvolta: '',
	  provinciaAttPrevalSvolta: '',
//2020-12-01 PFD: SAV 'TO BE'    	comuneAttPrevalSvolta: '',
	  capAttPrevalSvolta: '',
	  codSAE: '',
	  codATECO: '',
	  flAllegati: '',
      altro: '',
      altroAttPrevAtt: '',
      
      flEntitaFinanzPassiva: '',
      motivoDellaRelazione: '',
      descrStatoAttPrevalSvolta: datiAttEconomicaGiuridico.descrStatoAttPrevalSvolta,
      statoAttPrevalSvolta: datiAttEconomicaGiuridico.statoAttPrevalSvolta,

//2021-07-01 PFD: TCM_INDIV      codStatoAttivPrevalSvolta: datiAttEconomicaGiuridico.codStatoAttivPrevalSvolta,
//2021-07-01 PFD: TCM_INDIV      descrStatoAttivPrevalSvolta: datiAttEconomicaGiuridico.descrStatoAttivPrevalSvolta,
      classeBeneficiari: '',
      societaQuotata: '',
      tipoPersona: PERSONA_GIURIDICA,
      statoResidenza: '',
      attivitaSae: '',
      societaSae: '',
//2020-12-01 PFD: SAV 'TO BE'      comuneItems: [],
      provinciaItems: [],
      saeItems: [],
      atecoItems: [],
      nazioneItems: [],
      flPaesiBlackList: '',
      relazPaesiList: [],
      isErrorRelazPaesi: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
      societaAzFiduciario: '',
      descrTipoSocieta: '',
      descrProvinciaAttPrevalSvolta: '',
//2020-12-01 PFD: SAV 'TO BE'      descrComuneAttPrevalSvolta:'',
      descrSAE:'',
      motivoRelazione:'',
      descrATECO:'',
    };
  };


  datiAttEconGiuridicoFrom = () => {
    let datiAttEconGiuridico = null;
    if (this.props.figura === CONTRAENTE_GIURIDICO) {
      datiAttEconGiuridico = this.props.datiAttEconomicaGiuridico;
    } else if(this.props.figura === BENEF_MORTE_GIURIDICO){
      datiAttEconGiuridico = this.props.datiAttEconomicaBenefMorteGiuridico;
    } else if(this.props.figura === BENEF_VITA_GIURIDICO){
      datiAttEconGiuridico = this.props.datiAttEconomicaBenefVitaGiuridico;
    }
    return datiAttEconGiuridico;
}
  
  datiStatoResidenzaFrom = () => {
    let statoResidenza = null;
    if (this.props.figura === CONTRAENTE_GIURIDICO) {
      statoResidenza = this.props.statoResidenza;
    } else if(this.props.figura === BENEF_MORTE_GIURIDICO){
      statoResidenza = this.props.statoResidenzaBenefMorteGiuridico;
    } else if(this.props.figura === BENEF_VITA_GIURIDICO){
      statoResidenza = this.props.statoResidenzaBenefVitaGiuridico;
    }
    return statoResidenza;
  }

  componentDidMount() {	  
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);  
    this.setProvince(datiAttEconomicaGiuridico ? datiAttEconomicaGiuridico.statoAttPrevalSvolta : '', moment().format('DD/MM/YYYY'))
//2020-12-01 PFD: SAV 'TO BE' - START    this.setComuni(datiAttEconomicaGiuridico ? datiAttEconomicaGiuridico.provinciaAttPrevalSvolta : '', moment().format('DD/MM/YYYY'));
    this.setSae(PERSONA_GIURIDICA, this.datiStatoResidenzaFrom());
    this.setAteco(PERSONA_GIURIDICA, datiAttEconomicaGiuridico ? datiAttEconomicaGiuridico.codATECO : '');
    /*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
    if(this.props.figura === CONTRAENTE_GIURIDICO && this.props.scopeAmleto) {
      this.props.datiAttEconomicaGiuridico.relazPaesiList.map((el, key) => {
        if(el.checked) {
          const nazioni = this.props.dominio.nazioneItems;
//2021-07-01 PFD: TCM_INDIV: meglio inserire il codiceNazione piuttosto dell'indice
//2021-07-01 PFD: TCM_INDIV è rischioso ragionare per index, tant'e' vero che non funziona          const index = nazioni.findIndex(nuovoPaese => nuovoPaese.value === el.value);
//2021-07-01 PFD: TCM_INDIV è rischioso ragionare per index, tant'e' vero che non funziona          this.state.relazPaesiList.push(index);
          this.state.relazPaesiList.push(el.value);
          this.setState({
            relazPaesiList: this.state.relazPaesiList
          })
        }
      });
    }
    const opts = {
      siglaVersione: 'ZURI',
      dataRichiesta: moment().format('DD/MM/YYYY')
    }
    fetch(uris.selectPaesiBlackList, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An error occured...", error)
    )
    .then((result) => {
      result.sort((a,b) => {
        if(a.label > b.label) {
            return 1;
        } else {
            return -1;
        }
      })
      this.setState({
        blackListItems: result
      })
    })
    2022-05-27 PFD: END*/
  }

  updateDatiAttEconomica = (unDato, anActionType) => {
      this.props.updateDatiAttEconomicaGiuridico(unDato, anActionType, this.props.figura);
       
//    this.setState({
//      datiAttEconomicaGiuridico: this.props.datiAttEconomicaGiuridico,
//    });
  }
  
  /* CHANGE HANDLER */ 
  handleChangeTipoSocieta = event => {
    const nuovoTipoSocieta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioTipoSocieta = textTrim(datiAttEconomicaGiuridico.tipoSocieta, true);    
    if (nuovoTipoSocieta !== vecchioTipoSocieta) {   
      if (this.props.figura === BENEF_VITA_GIURIDICO) {
      this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_TIPO_SOCIETA_VITA_GIURIDICO);

      }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
        this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_TIPO_SOCIETA_MORTE_GIURIDICO);

      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {    
      this.updateDatiAttEconomica(event, actionType.UPDATE_TIPO_SOCIETA);
      }
      this.setState({
        tipoSocieta: (event === null) ? '' : event.value,
        descrTipoSocieta: (event === null) ? '' : event.label,
      });
   }
  }

  setProvince = (nazione) => {
    if(textTrim(nazione, true)!==''){
	    const opts = {
        dataRiferimentoProv: null,
	      nazione: nazione,
	    };
	    fetch(uris.provinciaItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      this.setState({
	    	provinciaItems: result,
	      });
	    });
    }else{
        this.setState({
        	provinciaItems: [],
        });
    }
  }
  
/*2020-12-01 PFD: SAV 'TO BE' - START
  setComuni = (codProvincia, dataNascita) => {
    if(textTrim(codProvincia, true)!=='' && textTrim(dataNascita, true)!==''){
	  const opts = {
        codiceProvincia: codProvincia,
        dataRiferimentoComune: dataNascita,
      };
      fetch(uris.comuneItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        this.setState({
          comuneItems: result,
        });
      });
    }else{
      this.setState({
        comuneItems: [],
      });
    }
  }
  2020-12-01 PFD: SAV 'TO BE' - END */
  
  handleChangeCodAttPrevalSvolta = event => {
  const nuovoCodAttPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
  const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
  const vecchioCodAttPrevalSvolta = textTrim(datiAttEconomicaGiuridico.codAttPrevalSvolta, true);
	if (nuovoCodAttPrevalSvolta !== vecchioCodAttPrevalSvolta) {
    if(this.props.figura === BENEF_VITA_GIURIDICO){
      this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_ATT_PREVAL_SVOLTA_VITA_GIUR);

    }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
      this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_ATT_PREVAL_SVOLTA_MORTE_GIUR);

    }else if (this.props.figura === CONTRAENTE_GIURIDICO ){
      this.updateDatiAttEconomica(event, actionType.UPDATE_ATT_PREVAL_SVOLTA);

    }
      this.setState({
   	    codAttPrevalSvolta: (event === null) ? '' : event.value,
        descrAttPrevalSvolta: (event === null) ? '' : event.label,
      });
    }
  }

  handleChangeAltroPrevAttSvolta = event => {
    const nuovoAltro = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioAltro = textTrim(datiAttEconomicaGiuridico.altroAttPrevAtt, true);		
    if (nuovoAltro !== vecchioAltro) {  
      if(this.props.figura === BENEF_VITA_GIURIDICO){
        this.updateDatiAttEconomica(event.target.value, actionTypeBenefVitaGiuridico.UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT_VITA_GIURIDICO);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
          this.updateDatiAttEconomica(event.target.value, actionTypeBenefMorteGiuridico.UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT_MORTE_GIURIDICO);

      } else if (this.props.figura === CONTRAENTE_GIURIDICO ){
        this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_ALLEGATI_ALTRO_ATT_PREV_ATT);
      }
        this.setState({
          altro: (event === null) ? '' : event.target.value,
        });
      }
  }

  
  handleChangeProvinciaAttPrevalSvolta = event => {
    const nuovoProvinciaAttPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioProvinciaAttPrevalSvolta = textTrim(datiAttEconomicaGiuridico.provinciaAttPrevalSvolta, true);
    if (nuovoProvinciaAttPrevalSvolta !== vecchioProvinciaAttPrevalSvolta) {
      if (this.props.figura === BENEF_VITA_GIURIDICO) {
        this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_PROVINCIA_ATT_PREV_SVOLTA_VITA_GIURIDICO);
      
      }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
        this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_PROVINCIA_ATT_PREV_SVOLTA_MORTE_GIURIDICO); 

      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        this.updateDatiAttEconomica(event, actionType.UPDATE_PROVINCIA_ATT_PREVAL);
      }
//2020-12-01 PFD: SAV 'TO BE' - START	  this.setComuni((event === null) ? '' : event.value, moment().format('DD/MM/YYYY'));
	  this.setState({
		  provinciaAttPrevalSvolta: (event === null) ? '' : event.value,
	      descrProvinciaAttPrevalSvolta: (event === null) ? '' : event.label,
	  });
    }
  }
  /* 2020-12-01 PFD: SAV 'TO BE' - START
  handleChangeComuneAttPrevalSvolta = event => {
  const nuovoComuneAttPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
  const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioComuneAttPrevalSvolta = textTrim(datiAttEconomicaGiuridico.comuneAttPrevalSvolta, true);    
	if (nuovoComuneAttPrevalSvolta !== vecchioComuneAttPrevalSvolta) {
    if (this.props.figura === BENEF_VITA_GIURIDICO) {
      this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_COMUNE_ATT_PREV_SVOLTA_VITA_GIURIDICO);
    
    }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
      this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_COMUNE_ATT_PREV_SVOLTA_MORTE_GIURIDICO); 


    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      this.updateDatiAttEconomica(event, actionType.UPDATE_COMUNE_ATT_PREVAL);
    }
      this.setState({
        comuneAttPrevalSvolta: (event === null) ? '' : event.value,
        descrComuneAttPrevalSvolta: (event === null) ? '' : event.label,
      });
	 }
  }
   
  handleChangeComuneAttPrevalSvoltaTextField = (event) => {
    const nuovoComuneAttPrevalSvoltaText = textTrim(event.target.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioComuneAttPrevalSvoltaText = textTrim(datiAttEconomicaGiuridico.comuneAttPrevalSvolta, true);    
    if (nuovoComuneAttPrevalSvoltaText !== vecchioComuneAttPrevalSvoltaText) {
      if (this.props.figura === BENEF_VITA_GIURIDICO) {
        this.updateDatiAttEconomica(nuovoComuneAttPrevalSvoltaText, actionTypeBenefVitaGiuridico.UPDATE_COMUNE_ATT_PREV_SVOLTA_VITA_GIURIDICO);
      
      }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
        this.updateDatiAttEconomica(nuovoComuneAttPrevalSvoltaText, actionTypeBenefMorteGiuridico.UPDATE_COMUNE_ATT_PREV_SVOLTA_MORTE_GIURIDICO); 
  
  
      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        this.updateDatiAttEconomica(nuovoComuneAttPrevalSvoltaText, actionType.UPDATE_COMUNE_ATT_PREVAL);
      }
        this.setState({
          comuneAttPrevalSvolta: '',
          descrComuneAttPrevalSvolta: (event === null) ? '' : nuovoComuneAttPrevalSvoltaText,
        });
     }
  }
 2020-12-01 PFD: SAV 'TO BE' - END */

  
  handleChangeStatoAttPrevalStato = event => {
    const nuovoStatoAttPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioStatoAttPrevalSvolta = textTrim(datiAttEconomicaGiuridico.statoAttPrevalSvolta, true);
    if (nuovoStatoAttPrevalSvolta !== vecchioStatoAttPrevalSvolta) {
      if (this.props.figura === BENEF_VITA_GIURIDICO) {
        this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_STAT_ATT_PREV_SVOLTA_VITA_GIURIDICO);

      }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
        this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_STAT_ATT_PREV_SVOLTA_MORTE_GIURIDICO);

      } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
        this.updateDatiAttEconomica(event, actionType.UPDATE_STAT_ATT_PREV_SVOLTA);
      }
        this.setProvince(nuovoStatoAttPrevalSvolta);
//2020-12-01 PFD: SAV 'TO BE' - START        this.setComuni('', moment().format('DD/MM/YYYY'));
        this.setState({
//2021-07-01 PFD: TCM_INDIV        statoAttivPrevalSvolta: (event === null) ? '' : event.value,
//2021-07-01 PFD: TCM_INDIV        descrStatoAttivPrevalSvolta: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true),
          statoAttPrevalSvolta: (event === null) ? '' : event.value,
          descrStatoAttPrevalSvolta: ((event === null) || (event === undefined)) ? '' : textTrim(event.label, true),

      });
    }
    
    //2021-07-01 PFD: TCM_INDIV
    this.handleTcmIndividualeValida();

  }


/*  handleChangeCapAttPrevalSvolta = event => {
	const nuovoCapAttPrevalSvolta = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
	const vecchioCapAttPrevalSvolta = textTrim(this.props.datiAttEconomicaGiuridico.capAttPrevalSvolta, true);    	
	if (nuovoCapAttPrevalSvolta !== vecchioCapAttPrevalSvolta) {
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_CAP_ATT_PREVAL);
      this.setState({
        capAttPrevalSvolta: (event === null) ? '' : event.target.value,
      });
    }
  }*/
  
  handleChangeCodSAE = event => {
  const nuovoCodSAE = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
  const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioCodSAE = textTrim(datiAttEconomicaGiuridico.codSAE, true);    	
	if (nuovoCodSAE !== vecchioCodSAE) {
    if (this.props.figura === BENEF_VITA_GIURIDICO) {
      this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_COD_SAE_VITA_GIURIDICO);

    }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
      this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_COD_SAE_MORTE_GIURIDICO);


    } else if (this.props.figura === CONTRAENTE_GIURIDICO) {
      this.updateDatiAttEconomica(event, actionType.UPDATE_COD_SAE);
    }
      this.setAteco(this.state.tipoPersona, (event === null) ? '' : event.value);
      this.setState({
        codSAE: (event === null) ? '' : event.value,
        descrCodSAE: (event === null) ? '' : event.label,
      });
    }
  }
  
  handleChangeCodATECO = event => {
  const nuovoCodATECO = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
  const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioCodATECO = textTrim(datiAttEconomicaGiuridico.codATECO, true);	
	if (nuovoCodATECO !== vecchioCodATECO) { 
    if (this.props.figura === BENEF_VITA_GIURIDICO) {
      this.updateDatiAttEconomica(event, actionTypeBenefVitaGiuridico.UPDATE_COD_ATECO_VITA_GIURIDICO);
     
    }else if (this.props.figura === BENEF_MORTE_GIURIDICO ){
      this.updateDatiAttEconomica(event, actionTypeBenefMorteGiuridico.UPDATE_COD_ATECO_MORTE_GIURIDICO);
  
    } else if (this.props.figura === CONTRAENTE_GIURIDICO) { 
      this.updateDatiAttEconomica(event, actionType.UPDATE_COD_ATECO);
    }
      this.setState({
        codATECO: (event === null) ? '' : event.value,
        descrATECO: (event === null) ? '' : event.label,
      });
    }
  }
  
  handleClickFlAllegati = event => { 
    if (event.target.value !== 'S') {    
    } else {
    }    
  };

  handleChangeSocietaAzFiduc = event =>{
    const nuovoSocietaFiduc = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioSocietaFiduc = textTrim(datiAttEconomicaGiuridico.societaAzFiduciario, true);
    if(nuovoSocietaFiduc !== vecchioSocietaFiduc){
      if (this.props.figura === BENEF_VITA_GIURIDICO) {
        this.updateDatiAttEconomica(nuovoSocietaFiduc, actionTypeBenefVitaGiuridico.UPDATE_SOCIETA_AZ_FIDUCIARIO_BENEF_VITA_GIURIDICO);
      }else if(this.props.figura === BENEF_MORTE_GIURIDICO){
        this.updateDatiAttEconomica(nuovoSocietaFiduc, actionTypeBenefMorteGiuridico.UPDATE_SOCIETA_AZ_FIDUCIARIO_BENEF_MORTE_GIURIDICO);
      }else if(this.props.figura === CONTRAENTE_GIURIDICO){
      this.updateDatiAttEconomica(nuovoSocietaFiduc, actionType.UPDATE_SOCIETA_AZ_FIDUCIARIO, this.props.figura);
      }
      this.setState({
        societaAzFiduc: (event === null) ? '' : event.target.value,
      });
    }
  }

  
  handleChangeFlAllegati = event => {
	const nuovoFlAllegati = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
	const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioFlAllegati = textTrim(datiAttEconomicaGiuridico.flAllegati, true);	
	if (nuovoFlAllegati !== vecchioFlAllegati) {   
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_FL_ALLEGATI);
      if (nuovoFlAllegati !== 'A') {
   	    this.updateDatiAttEconomica('', actionType.UPDATE_ALLEGATI_ALTRO);
      }
      this.setState({
        flAllegati: (event === null) ? '' : event.target.value,
      });
    }
  }
  
  handleChangeMotivoRelazione = event => {
    const nuovoMotivoRel = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioMotivoRel = textTrim(datiAttEconomicaGiuridico.motivoRel, true);
    if(nuovoMotivoRel !== vecchioMotivoRel){
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_MOTIVO_RELAZIONE);
      this.setState({
        motivoRel: (event === null) ? '' : event.target.value,
      });
    }
  }

  handleChangeClasseBeneficiari = event => {
    const nuovoClasseBeneficiari = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioClasseBeneficiari =  textTrim(datiAttEconomicaGiuridico.classeBeneficiari, true );
    if(nuovoClasseBeneficiari !== vecchioClasseBeneficiari){
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_CLASSE_BENEFICIARI);
      this.setState({
        classeBeneficiari: (event === null) ? '' : event.target.value,
      })
    }
  }

  handleChangeAltro = event => {
	const nuovoAltro = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
	const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioAltro = textTrim(datiAttEconomicaGiuridico.altro, true);		
	if (nuovoAltro !== vecchioAltro) {  
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_ALLEGATI_ALTRO);
      this.setState({
        altro: (event === null) ? '' : event.target.value,
      });
    }
  }
  
  handleClickFlPaesiBlackList = event => { 
    if (event.target.value !== 'S') {    
    } else {
    }    
  };
  
  handleChangeFlPaesiBlackList = event => {
	const nuovoFlPaesiBlackList = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
	const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioFlPaesiBlackList = textTrim(datiAttEconomicaGiuridico.flPaesiBlackList, true);	
	if (nuovoFlPaesiBlackList !== vecchioFlPaesiBlackList) {
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_FL_PAESI_BLACK_LIST, this.props.figura);
      this.setState({
   	    flPaesiBlackList: (event === null) ? '' : event.target.value,
      });
    }
	/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List , non serve
	if (nuovoFlPaesiBlackList === 'N') {
      this.setState({
        relazPaesiList: [...relazPaesiListInizializzato],
      })
    }
	*/
    //2021-07-01 PFD: TCM_INDIV
    this.handleTcmIndividualeValida();
  }
  
  handleChangeRelazPaesiList = (index, event) => {
    const nuovoStato = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioStato = textTrim(datiAttEconomicaGiuridico.relazPaesiList[index].value, true);
    if (nuovoStato !== vecchioStato) {   
      if (this.props.figura === CONTRAENTE_GIURIDICO) {
    	  if(event===null){
    		  //Inizializzo un nuovo item (nel caso in cui cancello dalla comboBox)
    		  event = {label: '', value: '',};
    	  }
    	  datiAttEconomicaGiuridico.relazPaesiList[index] = event; // aggiorno l'elemento
    	  this.updateDatiAttEconomica(datiAttEconomicaGiuridico.relazPaesiList, actionType.UPDATE_RELAZ_PAESI_LIST);
      }
      this.setState({
    	  /* molto interessante
    	  relazPaesiList:[
    	        ...this.state.relazPaesiList.splice(0,index) ,
    	        {
    	            ...this.state.relazPaesiList[index],
    	            value: event.target.value,
    	            label: event.target.label,
    	        },
    	        ...this.state.relazPaesiList.splice(index+1) ,
    	  ]
    	  */
    	  relazPaesiList: datiAttEconomicaGiuridico.relazPaesiList,
      });
      
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
  }
  
  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  handleClickFlEntitaFinanzPassiva = event => { 
    if (event.target.value !== 'S') {   
    } else {
    }    
  };
  
  handleChangeFlEntitaFinanzPassiva = event => {
	const nuovoFlEntitaFinanzPassiva = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
	const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
	const vecchioFlEntitaFinanzPassiva = textTrim(datiAttEconomicaGiuridico.flEntitaFinanzPassiva, true);		
	if (nuovoFlEntitaFinanzPassiva !== vecchioFlEntitaFinanzPassiva) {
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_FL_ENT_FINANZ_PASSIVA);
      this.setState({
        flEntitaFinanzPassiva: (event === null) ? '' : event.target.value,
      });
    }
  }

  handleChangeSocietaQuotata = event =>{
    const nuovoSocietaQuotata = ((event === null) || (event === undefined)) ? '' : textTrim(event.target.value, true);
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const vecchioSocietaQuotata = textTrim(datiAttEconomicaGiuridico.societaQuotata, true);
    if(nuovoSocietaQuotata !== vecchioSocietaQuotata) {
      this.updateDatiAttEconomica(event.target.value, actionType.UPDATE_SOCIETA_QUOTATA);
      this.setState({
        societaQuotata: (event === null) ? '' : event.target.value,
      })

    }
  }
/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
  handleChangeListaPaesi(paese, key, event) {
    
	const nuovoPaeseBlack = {
      label: paese.label,
      value: paese.value,
      checked: event.target.checked  
    }
    this.updateDatiAttEconomica(nuovoPaeseBlack, actionType.TOGLE_PAESE_BLACK_LIST);  
    const codNazione = paese.value;
    if(!this.state.relazPaesiList.includes(codNazione)) {
        this.state.relazPaesiList.push(codNazione);     
    } else {
    	var i = this.state.relazPaesiList.indexOf(codNazione);
        this.state.relazPaesiList.splice(i, 1);      
    }
    
    this.setState({
    	relazPaesiList: this.state.relazPaesiList
    })
  }
  */
  
  setSae = (tipoPersona, nazioneResidenza) => {
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    if (this.props.figura !== CONTRAENTE_GIURIDICO) {
      if (textTrim(tipoPersona, true) !== '' && textTrim(nazioneResidenza, true) !== '') {
        /* const opts = {
          tipoPersona: tipoPersona,
          nazioneResidenza: nazioneResidenza,
          };
          fetch(uris.saeItems, fetchParam(opts))
          .then (
            response => response.json(),
            error => console.error('An error occurred.', error),
          )
          .then((result) => {
            // If request is good update state with fetched data
            this.setState({
              saeItems: result,
            });
          });
        }else{
          this.setState({
            saeItems: [],
          });
        } */
        if (datiAttEconomicaGiuridico.codAttPrevalSvolta && datiAttEconomicaGiuridico.tipoSocieta) {
          const opts = {
            dataRichiesta: moment().format("DD/MM/YYYY"),
            siglaVersione: 'ZURI',
            descrStatoResidenza: nazioneResidenza === '86' ? '086' : '999',
            A021: datiAttEconomicaGiuridico.codAttPrevalSvolta,
            tipoSocieta: datiAttEconomicaGiuridico.tipoSocieta
          }
          fetch(uris.attPrevGiuSaeItems, fetchParam(opts))
            .then(
              response => response.json(),
              error => console.error("An error occured...", error)
            )
            .then((result) => {
              this.setState({
                saeItems: result
              });
            });
        }
      }
    } else {
      if (datiAttEconomicaGiuridico.codAttPrevalSvolta && datiAttEconomicaGiuridico.tipoSocieta && nazioneResidenza) {
        const opts = {
          dataRichiesta: moment().format("DD/MM/YYYY"),
          siglaVersione: 'ZURI',
          descrStatoResidenza: nazioneResidenza === '86' ? '086' : '999',
          A021: datiAttEconomicaGiuridico.codAttPrevalSvolta,
          tipoSocieta: datiAttEconomicaGiuridico.tipoSocieta
        }
        fetch(uris.attPrevGiuSaeItems, fetchParam(opts))
          .then(
            response => response.json(),
            error => console.error("An error occured...", error)
          )
          .then((result) => {
            this.setState({
              saeItems: result
            });
          });
      } else {
        this.setState({
          saeItems: []
        })
      }
    }
  }
  
  
  setAteco = (tipoPersona, sottogruppo) => {
    if(textTrim(tipoPersona, true)!=='' && textTrim(sottogruppo, true)!==''){
	  const opts = {
	    tipoPersona: tipoPersona,
	    sottogruppo: sottogruppo,
      };
      fetch(uris.atecoItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          atecoItems: result,
        });
      });
    }else{
      this.setState({
        atecoItems: [],
      });
    }
  }
  
  isRequiredTipoSocieta = () => {
    let required = true;
    //if(this.props.scopeAmleto) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    //}
	  return required;
  }
  isRequiredCodAttPrevalSvolta = () => {
    let required = true;
    //if(this.props.scopeAmleto) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    //}
	  return required;
  }
  isRequiredAltroAttPrevAtt = () => {
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    return datiAttEconomicaGiuridico.codAttPrevalSvolta === CODICE_ATT_PREV_ALTRO;
  }
  isRequiredProvinciaAttPrevalSvolta = () => {
    let required = true;
    //if(this.props.scopeAmleto) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    //}
	  return required;
  }

/*2020-12-01 PFD: SAV 'TO BE' - START
  isRequiredComuneAttPrevalSvolta = () => {
	// 2020-09-10 PFD: Abbassamento Controlli AMLETO - START
//    let required = true;
//    if (this.props.figura === BENEF_MORTE_GIURIDICO) {
//      required = false;
//    } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
//      required = false;
//    }
	  let required = false;
 // 2020-09-10 PFD: Abbassamento Controlli AMLETO - END
    return required;
  }
  2020-12-01 PFD: SAV 'TO BE' - END */ 

  isRequiredStatoAttPrevalSvolta = () => {
    let required = true;
    //if(this.props.scopeAmleto) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    //}
	  return required;
  }
  isRequiredSocietaQuotata = () => {
    let required = false;
    if(this.props.scopeAmleto) {
      required = true;
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    }
	  return required;
  }
  isRequiredCodSAE = () => {
    let required = true;
    //if(this.props.scopeAmleto) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    //}
	  return required;
  }
  isRequiredCodATECO = () => {
    let required = true;
    let datiAttEconomica = this.datiAttEconGiuridicoFrom(this.props.figura);
    if(/* this.props.scopeAmleto &&  */!datiAttEconomica.codSAE) {
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    }
	  return required;
  }
  isRequiredFlAllegati = () => {
	  return true;
  }
  isRequiredClasseBeneficiari = () => {
    return (
      this.props.datiAttEconomicaGiuridico.codAttPrevalSvolta === '06' && this.props.scopeAmleto
    );
  }
  isRequiredSocietaAzFiduc = () => {
    let required = false;
    if(this.props.scopeAmleto) {
      required = true;
      if(this.props.figura === BENEF_MORTE_GIURIDICO) {
        required = false;
      } else if(this.props.figura === BENEF_VITA_GIURIDICO) {
        required = false;
      }
    }
    return required;
  }
  isRequiredMotivoRelazione =() => {
    return this.props.scopeAmleto && this.props.datiAttEconomicaGiuridico.flPaesiBlackList === 'S';
  }
  isRequiredAltro = () => {
	  return this.props.datiAttEconomicaGiuridico.flAllegati === 'A';
  }
  isRequiredFlPaesiBlackList = () => {
	  return true;
  }
  isRequiredFlEntitaFinanzPassiva = () => {
	  return true;
  }
/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
  isRequiredListaPaesi = () => {
    let required = false;
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    //if(this.props.scopeAmleto) {
      if(datiAttEconomicaGiuridico.flPaesiBlackList === 'S') {
        required = true;
      }
    //}
    return required;
  }
*/
  
  isRequiredRelazPaesiList = () => {
    let required = false;
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    //if(this.props.scopeAmleto) {
      if(datiAttEconomicaGiuridico.flPaesiBlackList === 'S') {
    	  const filterdRelazPaesiList = datiAttEconomicaGiuridico.relazPaesiList.filter((item) => {
    		  if(item.value!==undefined && item.value!==''){
    			  return item;
    		  }
    	  });
    	  required = (filterdRelazPaesiList.length===0 ? true : false);
      }
    //}
    return required;
  }
  
  isDisabledAltroAttPrevAtt = () => {
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    return datiAttEconomicaGiuridico.codAttPrevalSvolta !== CODICE_ATT_PREV_ALTRO;
  }

  isDisabledAltro = () => {
    return this.props.datiAttEconomicaGiuridico.flAllegati !== 'A';
  }

/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
  isDisabledListaPaesi = () => {
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    return (datiAttEconomicaGiuridico.flPaesiBlackList !== 'S');
  }
*/
  isDisabledClasseBeneficiari = () => {
    let disabled = true;
    if(this.props.datiAttEconomicaGiuridico.codAttPrevalSvolta === '06' && this.props.scopeAmleto){
      disabled = false;
    }
    return disabled;
  }

  isDisabledSocietaAzFiduc = () => {
//2022-07-07 PFD: START 
//viene chiesto di renderlo disabled per i Beneficiari Vita / Morte PG
//vedi mail Holly Sottosanti del 07-07-2022 ore 15:46
//	  return this.props.scopeAmleto === false;
	  return !this.isRequiredSocietaAzFiduc();
//2022-07-07 PFD: END
  }

  isDisabledSocietaQuotata = () => {
    return this.props.scopeAmleto === false;
  }
    
  isDisabledMotivoRelazione = () => {
    let disabled = true;
    //if(this.props.scopeAmleto){
      if(this.props.datiAttEconomicaGiuridico.flPaesiBlackList === 'S'){
        disabled= false;
      }
    //}
    return disabled;
  }
  
  isDisabledRelazPaesiList= () => {
    let disabled = true;
    if(this.props.datiAttEconomicaGiuridico.flPaesiBlackList === 'S'){
      disabled= false;
    }
    return disabled;
  }
  
  initStatoCampi = () => {
    return [ {id: 'tipoSocieta', isError: false},
             {id: 'codAttPrevalSvolta', isError: false}, 
             {id: 'provinciaAttPrevalSvolta', isError: false}, 
//2020-12-01 PFD: SAV 'TO BE'             {id: 'comuneAttPrevalSvolta', isError: false},
             {id: 'capAttPrevalSvolta', isError: false},             
             {id: 'codSAE', isError: false},
             {id: 'codATECO', isError: false},
             {id: 'flAllegati', isError: false},
             {id: 'altro', isError: false},
             {id: 'altroAttPrevAtt', isError: false},
             {id: 'flPaesiBlackList', isError: false},
             {id: 'relazPaesiList_0', isError: false},
             {id: 'relazPaesiList_1', isError: false},
             {id: 'relazPaesiList_2', isError: false},
             {id: 'flEntitaFinanzPassiva', isError: false},
             {id: 'societaAzionarioFiduciario', isError: false},
             {id: 'motivoRelazione', isError: false},
             {id: 'classeBeneficiari', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  /*2020-12-01 PFD: SAV 'TO BE' - START
  isDisabledComune = () => {
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const provinciaAttPrevalSvolta = textTrim(datiAttEconomicaGiuridico.provinciaAttPrevalSvolta, true);
	  return (provinciaAttPrevalSvolta === '');
  }
  2020-12-01 PFD: SAV 'TO BE' - END */
  
  isDisabledProvicinciaAttPrevalSvolta = () =>{
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const statoAttPrevalSvolta = textTrim(datiAttEconomicaGiuridico.statoAttPrevalSvolta, true);
	  return (statoAttPrevalSvolta === '');
  }

  isDisabledStato= () => {
    return false;
  }
    
  isDisabledAteco = () => {
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(this.props.figura);
    const codSAE = textTrim(datiAttEconomicaGiuridico.codSAE, true); 
	return (codSAE === '');
  }

  controlloDatiFrom = (figura) => {
    let controlloDati = null;
    if(figura === BENEF_VITA_GIURIDICO){
      controlloDati = this.props.controlloDatiBenefVitaGiuridico
    } else if(this.props.figura === BENEF_MORTE_GIURIDICO){
      controlloDati = this.props.controlloDatiBenefMorteGiuridico;

    } else {
      controlloDati = this.props.controlloDatiContraenteGiuridico;
    }
    return controlloDati;
  }

/*
//2021-07-01 PFD: TCM_INDIV: meglio inserire il codiceNazione piuttosto dell'indice       
  isCheckedPaesiBlackList = (key) => {
    let checked = false;
    // faccio tornare true alla funzione solo se nella lista dei paesi "checked" è presente lo stesso paese
    if(this.state.relazPaesiList.includes(key)) {
      checked = true
    }
    return checked;
  }  
*/  
  
/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
  isCheckedPaesiBlackList = (codNazione) => {
    let checked = false;
    // faccio tornare true alla funzione solo se nella lista dei paesi "checked" è presente lo stesso paese
    if(this.state.relazPaesiList.includes(codNazione)) {
      checked = true
    }
    return checked;
  }
*/  
  render() {
	console.log("DatiAttivitaEconomicaGiuridico.this.props", this.props);
    const { classes, dominio, figura, t } = this.props;
    const datiAttEconomicaGiuridico = this.datiAttEconGiuridicoFrom(figura)
    const controlloDati = this.controlloDatiFrom(figura);
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
		<legend><Typography variant="h6">{t('datiAttivitaEconomica')}</Typography></legend>*/}
        <DividerComp titolo='datiAttivitaEconomica' variant="subtitle1" />
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item md={6} xs={12}>
            <SelectComp options={dominio.prevalenteAttivitaSvoltaGiuridicoItems} id='codAttPrevalSvolta' value={{ value: datiAttEconomicaGiuridico.codAttPrevalSvolta, label: datiAttEconomicaGiuridico.descrAttPrevalSvolta }} ricerca='comboRicerca' etichetta='prevalAttSvolta'
              onChange={this.handleChangeCodAttPrevalSvolta} required={this.isRequiredCodAttPrevalSvolta()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldComp value={datiAttEconomicaGiuridico.altroAttPrevAtt} id='altroAttPrevAtt' onBlur={this.handleChangeAltroPrevAttSvolta} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
              label='general.AltroAttPrevAttSpecificare' required={this.isRequiredAltroAttPrevAtt()} disabled={this.isDisabledAltroAttPrevAtt()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp options={dominio.nazioneItems} id='statoAttivPrevalSvolta' value={{ value: datiAttEconomicaGiuridico.statoAttPrevalSvolta, label: datiAttEconomicaGiuridico.descrStatoAttPrevalSvolta }} ricerca='comboRicerca' etichetta='stato'
              onChange={this.handleChangeStatoAttPrevalStato} required={this.isRequiredStatoAttPrevalSvolta()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp options={this.state.provinciaItems} id='provinciaAttPrevalSvolta' value={{ value: datiAttEconomicaGiuridico.provinciaAttPrevalSvolta, label: datiAttEconomicaGiuridico.descrProvinciaAttPrevalSvolta }} ricerca='comboRicerca' etichetta='provincia'
              onChange={this.handleChangeProvinciaAttPrevalSvolta} required={this.isRequiredProvinciaAttPrevalSvolta()} controlloDati={controlloDati} onError={this.handleError} 
              disabled={this.isDisabledProvicinciaAttPrevalSvolta()}/>
          </Grid>
          {/*2020-12-01 PFD: SAV 'TO BE'*/}
          <Grid item md={4} xs={12}> </Grid>
          {/* 2020-12-01 PFD: SAV 'TO BE' - START
            {datiAttEconomicaGiuridico.provinciaAttPrevalSvolta !== PROVINCIA_ESTERA &&
          <Grid item md={4} xs={12}>
            <SelectComp options={this.state.comuneItems} id='comuneAttPrevalSvolta' value={{ value: datiAttEconomicaGiuridico.comuneAttPrevalSvolta, label: datiAttEconomicaGiuridico.descrComuneAttPrevalSvolta }} ricerca='comboRicerca' etichetta='comune'
              onChange={this.handleChangeComuneAttPrevalSvolta} required={this.isRequiredComuneAttPrevalSvolta()} controlloDati={controlloDati}
              disabled={this.isDisabledComune()} onError={this.handleError} />
          </Grid>
            }
            {datiAttEconomicaGiuridico.provinciaAttPrevalSvolta === PROVINCIA_ESTERA &&
          <Grid item md={4} xs={12}>
            <TextFieldComp onBlur={this.handleChangeComuneAttPrevalSvoltaTextField} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='comuneAttPrevalSvolta' label='comune' 
              value={datiAttEconomicaGiuridico.descrComuneAttPrevalSvolta} required={this.isRequiredComuneAttPrevalSvolta()}
              controlloDati={controlloDati} onError={this.handleError} disabled={this.isDisabledComune()} />
          </Grid>
            }
          2020-12-01 PFD: SAV 'TO BE' - END */}
          <Grid item md={6} xs={12}>
            <SelectComp options={dominio.tipoSocietaItems} id='tipoSocieta' value={{ value: datiAttEconomicaGiuridico.tipoSocieta, label: datiAttEconomicaGiuridico.descrTipoSocieta }} ricerca='comboRicerca' etichetta='tipoSocieta'
              onChange={this.handleChangeTipoSocieta} required={this.isRequiredTipoSocieta()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>

          <Grid item xs={12}>
            <SelectComp options={this.state.saeItems} id='codSAE' value={{ value: datiAttEconomicaGiuridico.codSAE, label: datiAttEconomicaGiuridico.descrSAE }} 
                        onChange={this.handleChangeCodSAE} ricerca='comboRicerca' etichetta='sae' required={this.isRequiredCodSAE()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          <Grid item xs={12}>
            <SelectComp options={this.state.atecoItems} id='codATECO' value={{ value: datiAttEconomicaGiuridico.codATECO, label: datiAttEconomicaGiuridico.descrATECO }} ricerca='comboRicerca' etichetta='ateco'
              onChange={this.handleChangeCodATECO} required={this.isRequiredCodATECO()} controlloDati={controlloDati} disabled={this.isDisabledAteco()} onError={this.handleError} />
          </Grid>
          {(figura === BENEF_MORTE_GIURIDICO || figura === BENEF_VITA_GIURIDICO) &&
          <Grid item md={4} xs={12}>
            <RadioButtonComp selectedValue={datiAttEconomicaGiuridico.societaAzFiduciario} id='societaAzionarioFiduciario' onChange={this.handleChangeSocietaAzFiduc}
              radioComp={radioButtonSocietaAzFiduc} classes={{ divider: classes.divider }} required={this.isRequiredSocietaAzFiduc()}
              onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledSocietaAzFiduc()} />
          </Grid>
          }
          {figura === CONTRAENTE_GIURIDICO &&
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item md={4} xs={12}>
                <RadioButtonComp selectedValue={datiAttEconomicaGiuridico.societaAzFiduciario} id='societaAzionarioFiduciario' onChange={this.handleChangeSocietaAzFiduc}
                  radioComp={radioButtonSocietaAzFiduc} classes={{ divider: classes.divider }} required={this.isRequiredSocietaAzFiduc()}
                  onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledSocietaAzFiduc()} />
              </Grid>
              <Grid item md={4} xs={12}>
                <RadioButtonComp selectedValue={datiAttEconomicaGiuridico.societaQuotata} id='societaQuotata' onChange={this.handleChangeSocietaQuotata}
                  radioComp={radioButtonSocietaQuotata} classes={{ divider: classes.divider }} required={this.isRequiredSocietaQuotata()}
                  onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledSocietaQuotata()} />
              </Grid>
              <Grid item md={4} xs={12}></Grid>
              <Grid item md={4} xs={12}>
                <RadioButtonComp selectedValue={datiAttEconomicaGiuridico.flAllegati} id='flAllegati' onChange={this.handleChangeFlAllegati} onClick={this.handleClickFlAllegati} radioComp={radioButtonAttEconomicaFlAllegati} classes={{ divider: classes.divider }}
                  required={this.isRequiredFlAllegati()} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldComp value={datiAttEconomicaGiuridico.altro} id='altro' onBlur={this.handleChangeAltro} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError}
                  label='general.seAltroSpecificare' required={this.isRequiredAltro()} disabled={this.isDisabledAltro()} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
              <Grid item md={3} xs={12}></Grid>

              <Grid item md={10} xs={12}>
                <RadioButtonComp selectedValue={datiAttEconomicaGiuridico.flPaesiBlackList} id='flPaesiBlackList' onChange={this.handleChangeFlPaesiBlackList} onClick={this.handleClickFlPaesiBlackList} radioComp={radioButtonPaesiBlackList} classes={{ divider: classes.divider }}
                  required={this.isRequiredFlPaesiBlackList()} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
              <Grid item md={2} xs={12}></Grid>
              {/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
               
                {this.state.blackListItems && this.state.blackListItems.map((el, key) => {
                  return (
                    <CheckboxComp onChange={this.handleChangeListaPaesi.bind(this, el, key)} id='listaPaesi' aLabel={el.label} checked={this.isCheckedPaesiBlackList(el.value)} disabled={this.isDisabledListaPaesi()}
                    controlloDati={controlloDati} aStyleError={classes.textFieldError} required={this.isRequiredListaPaesi()} checkError={this.state.isErrorRelazPaesi} />                  
                    </Grid>
                  )
                })}
              </Grid>
              */}
              {/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List START*/}
              <Grid item md={10} xs={12}>
              	<Typography className={classNames(classes.textRelazPaesi,  datiAttEconomicaGiuridico.flPaesiBlackList==='S'?classes.textStyleEnabled:classes.textStyleDisabled)}>{t('relazPaesiBlackList')}</Typography>
              </Grid> 
              <Grid item md={4} xs={12}>                          	
              	<SelectComp options={dominio.nazioneItems} id='relazPaesiList_0' value={{ value: datiAttEconomicaGiuridico.relazPaesiList[0].value, label: datiAttEconomicaGiuridico.relazPaesiList[0].label }} ricerca='comboRicerca' etichetta='stato'
                    onChange={this.handleChangeRelazPaesiList.bind(this, 0)} required={this.isRequiredRelazPaesiList()} controlloDati={controlloDati} disabled={this.isDisabledRelazPaesiList()} onError={this.handleError} />
              </Grid>
              <Grid item md={4} xs={12}>                          	
              	<SelectComp options={dominio.nazioneItems} id='relazPaesiList_1' value={{ value: datiAttEconomicaGiuridico.relazPaesiList[1].value, label: datiAttEconomicaGiuridico.relazPaesiList[1].label }} ricerca='comboRicerca' etichetta='stato'
                    onChange={this.handleChangeRelazPaesiList.bind(this, 1)} controlloDati={controlloDati} disabled={this.isDisabledRelazPaesiList()} onError={this.handleError} />
              </Grid>
              <Grid item md={4} xs={12}>                          	
              	<SelectComp options={dominio.nazioneItems} id='relazPaesiList_2' value={{ value: datiAttEconomicaGiuridico.relazPaesiList[2].value, label: datiAttEconomicaGiuridico.relazPaesiList[2].label }} ricerca='comboRicerca' etichetta='stato'
                    onChange={this.handleChangeRelazPaesiList.bind(this, 2)} controlloDati={controlloDati} disabled={this.isDisabledRelazPaesiList()} onError={this.handleError} />
              </Grid>              	
              {/*2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List END*/}
              <Grid item md={10} xs={12}>
                <TextFieldComp value={datiAttEconomicaGiuridico.motivoRelazione} id='motivoRelazione' onBlur={this.handleChangeMotivoRelazione} aStyle={classes.textFieldWidthPerc}
                  aStyleError={classes.textFieldWidthPercError} controlloDati={controlloDati} onError={this.handleError} label='general.motivoDellaRelazione'
                  required={this.isRequiredMotivoRelazione()} disabled={this.isDisabledMotivoRelazione()} />
              </Grid>              
              <Grid item md={2} xs={12}></Grid>
              <Grid item md={10} xs={12}>
                <TextFieldComp value={datiAttEconomicaGiuridico.classeBeneficiari} id='classeBeneficiari' onBlur={this.handleChangeClasseBeneficiari} aStyle={classes.textFieldWidthPerc}
                  aStyleError={classes.textFieldWidthPercError} controlloDati={controlloDati} onError={this.handleError} label='general.classeBeneficiari'
                  required={this.isRequiredClasseBeneficiari()} disabled={this.isDisabledClasseBeneficiari()} />
              </Grid>
              <Grid item md={10} xs={12}>
                <RadioButtonComp selectedValue={datiAttEconomicaGiuridico.flEntitaFinanzPassiva} id='flEntitaFinanzPassiva' onChange={this.handleChangeFlEntitaFinanzPassiva} onClick={this.handleClickFlEntitaFinanzPassiva} radioComp={radioButtonEntitaFinanzPassiva} classes={{ divider: classes.divider }}
                  required={this.isRequiredFlEntitaFinanzPassiva()} controlloDati={controlloDati} onError={this.handleError} />
              </Grid>
              <Grid item md={2} xs={12}></Grid>
            </Grid>
          }
        </Grid>
        {/*</fieldset>*/}
      </Fragment>		    
    );
  }
 
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    const datiAttEconomica = this.datiAttEconGiuridicoFrom(this.props.figura);
    let isErrorRelazPaesi = false;
   /* //2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List - non serve
    * if(this.props.figura === CONTRAENTE_GIURIDICO) {
      isErrorRelazPaesi = (this.state.relazPaesiList.length < 1 ? true : false) && datiAttEconomica.flPaesiBlackList === 'S';
    }
    if(prevState.isErrorRelazPaesi !== isErrorRelazPaesi) {
      this.setState({
        isErrorRelazPaesi: isErrorRelazPaesi
      })
    }*/
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError || isErrorRelazPaesi
      );
	});
    console.log('########DatiEsecutore componentDidUpdate this.state.statoCampi', this.state.statoCampi);
//		    console.log('########DatiEsecutore componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//		    console.log('########DatiEsecutore componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
 
  updateSaeAteco(prevProps) {
    const statoResidenzaCorrente = this.datiStatoResidenzaFrom(this.props.figura);
    const datiAttEconomica = this.datiAttEconGiuridicoFrom(this.props.figura);
    const codAttPreval = datiAttEconomica.codAttPrevalSvolta;
    const tipoSocieta = datiAttEconomica.tipoSocieta;
    if (this.state.statoResidenza !== statoResidenzaCorrente || codAttPreval !== this.state.attivitaSae || tipoSocieta !== this.state.societaSae) { 	
      this.setSae(this.state.tipoPersona, statoResidenzaCorrente);
      this.setState({
        statoResidenza: statoResidenzaCorrente, 
        attivitaSae: codAttPreval,
        societaSae: tipoSocieta
      }); 
      /* if(this.props.figura === CONTRAENTE_GIURIDICO) {
        this.updateDatiAttEconomica(null, actionType.UPDATE_COD_ATECO);
        this.updateDatiAttEconomica(null, actionType.UPDATE_COD_SAE);
      } */
 	   this.setAteco(this.state.tipoPersona, this.state.codSAE);
    }
  } 
  
 componentDidUpdate(prevProps, prevState) {	
    this.updateSaeAteco(prevProps);
    this.isInError(prevProps, prevState);
  }  
}

DatiAttivitaEconomicaGiuridico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiAttivitaEconomicaGiuridico)));
