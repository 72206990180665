import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paperStyle: theme.paperStyle,	
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
});

class DatiLifeCycle extends React.Component {
	
  constructor(props) {
    super(props);
    this.state = {
    };
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props; //hoc    
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="subtitle1">{t('lifeCycle')}</Typography></legend>
        <DividerComp titolo='lifeCycle' variant="subtitle1" />*/}
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>  
            <Paper className={classes.paperStyle} elevation={0}>
              <Typography variant="caption">{t('legendaLifeCycle')}</Typography>
            </Paper>
          </Grid>
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
}

DatiLifeCycle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(DatiLifeCycle));

