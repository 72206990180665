import * as actionType from '../actionType/datiNuovaUtenza';

export const updateDatiNuovaUtenza = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaDatiNuovaUtenza = controlloDati => {
  return {
    type: actionType.SALVA_DATI_NUOVA_UTENZA,
	controlloDati,
  }
};

export const inserimentoDatiNuovaUtenza = (utente, dataScadenza) => {
  return {
    type: actionType.INSERIMENTO_DATI_NUOVA_UTENZA,
    utente,
    dataScadenza,
  }
};