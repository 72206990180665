import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import RadioButtonQuestionario from './RadioButtonQuestionario';
import CheckBoxQuestionario from './CheckBoxQuestionario';
import TextTestoQuestionario from './TextFieldQuestionario';

import * as actions from './action/datiQuestionario';
import * as actionType from './actionType/datiQuestionario';
import { QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO } from '../../../../utility/jsConstants';

const styles = theme => ({
  risposte: {
    width:'95%',
/*    marginLeft: theme.spacing(7), */
    paddingLeft: theme.spacing(7),    
    paddingRight: theme.spacing(3),
  },
  textField: theme.textField,
});

const mapStateToProps = state => {
  return {
    datiQuestionarioAdeguatezzaFisico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAdeguatezzaFisico,
  };
};

//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiQuestionarioAdeguatezzaFisico.mapDispatchToProps.dispatch', dispatch);
  return {
	  updateDatiQuestionario: (unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario) => dispatch(actions.updateDatiQuestionario(unDato, numDomanda, idRisposta, idTesto, unActionType, tipoQuestionario)),
  };
};
	
class RisposteQuestionario extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			
		};
	};
	
	handleChangeRispostaRadio = event => {
		this.props.updateDatiQuestionario(event.target.value, this.props.numDomanda, null, null, actionType.UPDATE_RADIO_SELECTION, this.props.tipoQuestionario);       
	    this.props.risposte.forEach(function(item, index, array) {
		  if(item.id === event.target.value ){
		    item.mostraRisposteCondizionate = "S";
		  }else{
			item.mostraRisposteCondizionate = "N";
		  }
		});
	};
	
	handleChangeRispostaCheck = event => {
		this.props.updateDatiQuestionario(event.target.value, this.props.numDomanda, null, null, actionType.UPDATE_CHECKBOX_SELECTION, this.props.tipoQuestionario);       
	};
	
  render() {
    const { classes, risposte, numDomanda, tipoQuestionario, didascalia, tipoAttivita } = this.props;  
    
    let tipoTutte = '-';
    let risposteTutte = [];
  
    let rispostaConTipo = {
          tipo: '',
    	  risposte: [],
    	};

    let rispostePerTipo = [];
    
    risposte.forEach(function(item, index, array) {
      if('-' === tipoTutte){
	    tipoTutte = item.tipo;
	  }
	  if(tipoTutte!==item.tipo){
	    rispostaConTipo = {
  		  tipo: tipoTutte,
  		  risposte: risposteTutte,
  	    };
	    rispostePerTipo.push(rispostaConTipo);
  	    risposteTutte = [];
  	    risposteTutte.push(item);
  	    tipoTutte = item.tipo;
  	    if(index===(risposte.length - 1)){
	      rispostaConTipo = {
	        tipo: tipoTutte,
	    	risposte: risposteTutte,
	      };
	      rispostePerTipo.push(rispostaConTipo);
	    }
	  }else{
	    risposteTutte.push(item);
	    if(index===(risposte.length - 1)){
	      rispostaConTipo = {
	        tipo: tipoTutte,
	    	risposte: risposteTutte,
	      };
	      rispostePerTipo.push(rispostaConTipo);
	    }
	  }
	});
    
    return ( 
      <div>
        {rispostePerTipo.map((elemento, index) => (	
	      <div key={index} className={classes.risposte}>
	        {elemento.tipo==='R' && <RadioButtonQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta='' onChange={this.handleChangeRispostaRadio} tipoQuestionario={tipoQuestionario} didascalia={didascalia} tipoAttivita={tipoAttivita} />}
	        {elemento.tipo==='C' && <CheckBoxQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta='' tipoQuestionario={tipoQuestionario} onChange={this.handleChangeRispostaCheck} />}
	        {elemento.tipo==='T' && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta='' tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical'/>}
	        {elemento.tipo==='N' && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta='' tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
	        {elemento.tipo==='X' && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta='' tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
		    {elemento.tipo===''  && <TextTestoQuestionario risposte={elemento.risposte} numDomanda={numDomanda} idRisposta='' tipoQuestionario={tipoQuestionario} aStyle={classes.textField} onBlur='' value='' required={false} direction='vertical' />}
	      </div>
         ))}
      </div>
    );
  }
}

RisposteQuestionario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RisposteQuestionario));

