import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteForever from '@material-ui/icons/DeleteForever';

import * as actionsBenefMorte from '../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../figure/actionType/beneficiarioMorte';
import * as actionsBenefVita from '../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../figure/actionType/beneficiarioVita';

import {PERSONA_FISICA, PERSONA_GIURIDICA, PERSONA_GENERICA} from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  }, 
  tableWrapper: {
	 width:'90%',
	 margin: 'auto',
  },
  textField: theme.textField,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  row: {
	  cursor: 'pointer', 
  },
  cellTotale: {
	  fontSize: 15,
	  fontWeight: 'bold',
	  fontStyle: 'italic',
	  color: theme.palette.color.windowsBlue,
  },	    
  cellPercTotale: {
	  color: 'red',	  
  },	  
});

const CustomTableCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(1.6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  }, 
  head: {
    backgroundColor: theme.palette.color.greyLight,
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginBottom: theme.spacing(1),
  },
  body: {
    borderColor: theme.palette.color.marine,
    borderStyle: 'solid',
    border: 1,
    marginBottom: theme.spacing(3),
/*    color: 'inherit', */
    color: theme.palette.color.windowsBlue,      
  }, 
}))(TableCell);


function isBeneFisicoOKForRiepilogo(datiGeneraliMinimi) {
	let isOk = false;
//	if (datiGeneraliMinimi!==null&&datiGeneraliMinimi.cognome!==undefined&&datiGeneraliMinimi.cognome!=='') {
		isOk = true;
//	}
	return isOk;
}
function isBeneGiuridicoOKForRiepilogo(datiGeneraliGiuridico) {
	let isOk = false;
//	if (datiGeneraliGiuridico!==null&&datiGeneraliGiuridico.ragioneSociale!==undefined&&datiGeneraliGiuridico.ragioneSociale!=='') {
		isOk = true;
//	}
	return isOk;
}

function isBeneGenericoOKForRiepilogo(datiGeneraliGenerico) {
	let isOk = false;
//	if (datiGeneraliGenerico!==null&&datiGeneraliGenerico.benefGenerico!==undefined&&datiGeneraliGenerico.benefGenerico!=='') {
		isOk = true;
//	}
	return isOk;
}

function creaDatiRiepilogo(id, tipoPers, ruolo, datiGeneraliBenef, datiResidenza) {
  let cognome = undefined;
  let nome = undefined;
  let toponimo = undefined;
  let indir = undefined;
  let numCivico = undefined;
  let comune = undefined;
  let prov = undefined;
  let perc = datiGeneraliBenef.percBeneficiario;
  let codiceFiscale = undefined;
  if (tipoPers===PERSONA_FISICA) {
    const datiGeneraliMinimi = datiGeneraliBenef.datiGeneraliMinimi;
    cognome = datiGeneraliMinimi.cognome;
    nome = datiGeneraliMinimi.nome;
    codiceFiscale = datiGeneraliMinimi.codiceFiscale;
  } else if (tipoPers===PERSONA_GIURIDICA){
    cognome = datiGeneraliBenef.datiGenerali.ragioneSociale;
    codiceFiscale = datiGeneraliBenef.datiGenerali.codiceFiscale;
  } else if (tipoPers===PERSONA_GENERICA){
    const descrBenefGenerico = datiGeneraliBenef.descrBenefGenerico;
    cognome = descrBenefGenerico;
    if(textTrim(descrBenefGenerico, true) === '') {
      const descrBenefEsteso = datiGeneraliBenef.descrBenefEsteso;
      cognome = descrBenefEsteso;
    }
  }
  /*
  if (tipoPers===PERSONA_FISICA||tipoPers===PERSONA_GIURIDICA) {
    toponimo = datiResidenza.toponimo;
    indir = datiResidenza.indirizzoResidenza; 
    numCivico = datiResidenza.numCivicoResidenza;
    comune =  datiResidenza.comuneResidenza;
    prov =  datiResidenza.provinciaResidenza;
  }
  let indirizzo = (toponimo!==undefined?toponimo + " ":"") + 
    (indir!==undefined?indir + " ": "") + 
    (numCivico!==undefined?numCivico + " ":"");
	*/
  let nominativo = (cognome !== undefined ? cognome + " " : "") + (nome !== undefined ? nome : "");
  return { id, tipoPers, ruolo, nominativo, codiceFiscale, /*indirizzo, comune, prov, */ perc };
}

function createDataRiepilogoList(riepilogoList, figura) {
  let riepilogoArray = [];  
  for (let index = 0; index < riepilogoList.length; index++) {
    const item = riepilogoList[index];
    console.log('Item CreateDataRiepilogo TIPOPERSONA', item.datiFigureAnagrBase.tipoPers);
    let datiResidenza = null;
    let datiGeneraliBenef = null;
    let ruolo = item.datiFigureAnagrBase.ruolo;
    let tipoPers = item.datiFigureAnagrBase.tipoPers;
    if (figura === 'beneficiarioMorteFisico') {
      datiGeneraliBenef = item.datiGeneraliBenefMorte;
      datiResidenza = item.datiResidenza;
    } else if (figura === 'beneficiarioVitaFisico') {
      datiGeneraliBenef = item.datiGeneraliBenefVita;
      datiResidenza = item.datiResidenza;
    } else if (figura === 'beneficiarioMorteGiuridico') {
      datiGeneraliBenef = item.datiGeneraliBenefGiuridico;
      datiResidenza = item.datiResidenza;
    } else if (figura === 'beneficiarioVitaGiuridico') {
      datiGeneraliBenef = item.datiGeneraliBenefGiuridico;
      datiResidenza = item.datiResidenza;
    } else if (figura === 'beneficiarioMorteGenerico') {
      datiGeneraliBenef = item.datiGenerali;
    } else if (figura === 'beneficiarioVitaGenerico') {
      datiGeneraliBenef = item.datiGenerali;
    }
    let isOk = false;
    if ((figura === 'beneficiarioMorteFisico' || figura === 'beneficiarioVitaFisico')) {
      isOk = isBeneFisicoOKForRiepilogo(datiGeneraliBenef.datiGeneraliMinimi);
    } else if ((figura === 'beneficiarioMorteGiuridico' || figura === 'beneficiarioVitaGiuridico')) {
      isOk = isBeneGiuridicoOKForRiepilogo(datiGeneraliBenef.datiGenerali);
    } else if ((figura === 'beneficiarioMorteGenerico' || figura === 'beneficiarioVitaGenerico')) {
      isOk = isBeneGenericoOKForRiepilogo(datiGeneraliBenef);
    }
    if (isOk) {
      riepilogoArray[index] = creaDatiRiepilogo(index, tipoPers, ruolo, datiGeneraliBenef, datiResidenza);
    }
  }
  return riepilogoArray;
}

const mapStateToProps = state => {
  console.log('#RTCFA DatiGeneraliBenefFisico.mapStateToProps.state', state);
  return {	  
    percBenMorteTotale: state.areaBenefMorteReducer.percBenMorteTotale,
    benefMorteFisicoList: state.areaBenefMorteReducer.benefMorteFisicoList,
    benefMorteGiuridicoList: state.areaBenefMorteReducer.benefMorteGiuridicoList,
    benefMorteGenericoList: state.areaBenefMorteReducer.benefMorteGenericoList,
    percBenVitaTotale: state.areaBenefVitaReducer.percBenVitaTotale,
    benefVitaFisicoList: state.areaBenefVitaReducer.benefVitaFisicoList,
    benefVitaGiuridicoList: state.areaBenefVitaReducer.benefVitaGiuridicoList,
    benefVitaGenericoList: state.areaBenefVitaReducer.benefVitaGenericoList,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA DatiGeneraliBenefFisico.mapDispatchToProps.dispatch', dispatch);
  return {
    caricaDettaglioBeneficiarioMorte: (riepilogoList, selectedIndex) => dispatch(actionsBenefMorte.caricaDettaglioBeneficiarioMorte(riepilogoList, selectedIndex)),
    caricaDettaglioBeneficiarioVita: (riepilogoList, selectedIndex) => dispatch(actionsBenefVita.caricaDettaglioBeneficiarioVita(riepilogoList, selectedIndex)),
    updateDatiBeneficiarioMorte: (unDato, unActionType) => dispatch(actionsBenefMorte.updateDatiBeneficiarioMorte(unDato, unActionType)),
    updateDatiBeneficiarioVita: (unDato, unActionType) => dispatch(actionsBenefVita.updateDatiBeneficiarioVita(unDato, unActionType)),
    deleteDettaglioBenefVitaFisico: (benefVitaFisicoDelete) => dispatch(actionsBenefVita.deleteDettaglioBenefVitaFisico(benefVitaFisicoDelete)),
    deleteDettaglioBenefVitaGiuridico: (benefVitaGiuridicoDelete) => dispatch(actionsBenefVita.deleteDettaglioBenefVitaGiuridico(benefVitaGiuridicoDelete)),
    deleteDettaglioBenefVitaGenerico: (benefVitaGenericoDelete) => dispatch(actionsBenefVita.deleteDettaglioBenefVitaGenerico(benefVitaGenericoDelete)),
    deleteDettaglioBenefMorteFisico: (benefMorteFisicoDelete) => dispatch(actionsBenefMorte.deleteDettaglioBenefMorteFisico(benefMorteFisicoDelete)),
    deleteDettaglioBenefMorteGiuridico: (benefMorteGiuridicoDelete) => dispatch(actionsBenefMorte.deleteDettaglioBenefMorteGiuridico(benefMorteGiuridicoDelete)),
    deleteDettaglioBenefMorteGenerico: (benefMorteGenericoDelete) => dispatch(actionsBenefMorte.deleteDettaglioBenefMorteGenerico(benefMorteGenericoDelete)),
  };
};
	
class TabDatiRiepilogoFigura extends React.Component {
  
  constructor(props) {
    super(props);
  };  
  
  datiRiepilogoFrom = figura => {
    let datiRiepilogoList = null;
    if (this.props.figura === 'beneficiarioMorte') {
      const datiMorteFisicoList = this.props.benefMorteFisicoList;
      let datiRiepilogoMorteFisicoList = createDataRiepilogoList(datiMorteFisicoList, 'beneficiarioMorteFisico');
      const datiMorteGiuridicoList = this.props.benefMorteGiuridicoList;
      const datiRiepilogoMorteGiuridicoList = createDataRiepilogoList(datiMorteGiuridicoList, 'beneficiarioMorteGiuridico');
      const datiMorteGenericoList = this.props.benefMorteGenericoList;
      const datiRiepilogoMorteGenericoList = createDataRiepilogoList(datiMorteGenericoList, 'beneficiarioMorteGenerico');
      datiRiepilogoList = datiRiepilogoMorteFisicoList.concat(datiRiepilogoMorteGiuridicoList).concat(datiRiepilogoMorteGenericoList);
    } else if (this.props.figura === 'beneficiarioVita') {
      const datiVitaFisicoList = this.props.benefVitaFisicoList;
      let datiRiepilogoVitaFisicoList = createDataRiepilogoList(datiVitaFisicoList, 'beneficiarioVitaFisico');
      const datiVitaGiuridicoList = this.props.benefVitaGiuridicoList;
      const datiRiepilogoVitaGiuridicoList = createDataRiepilogoList(datiVitaGiuridicoList, 'beneficiarioVitaGiuridico');
      const datiVitaGenericoList = this.props.benefVitaGenericoList;
      const datiRiepilogoVitaGenericoList = createDataRiepilogoList(datiVitaGenericoList, 'beneficiarioVitaGenerico');
      datiRiepilogoList = datiRiepilogoVitaFisicoList.concat(datiRiepilogoVitaGiuridicoList).concat(datiRiepilogoVitaGenericoList);
    }
    for (let index = 0; datiRiepilogoList!==null && index < datiRiepilogoList.length; index++) {
      if (datiRiepilogoList[index].nominativo!==undefined&&datiRiepilogoList[index].nominativo!=='') {
        datiRiepilogoList[index].id=index;
      }
    }
    return datiRiepilogoList;
  }
  
  caricaRiepilogoList = (figura) => {
    let riepilogoList = [];
    if (figura === 'beneficiarioMorte') {
      const riepilogoFisicoList = this.props.benefMorteFisicoList;
      const riepilogoGiuridicoList = this.props.benefMorteGiuridicoList;
      const riepilogoGenericoList = this.props.benefMorteGenericoList;
      for (let index = 0; index < riepilogoFisicoList.length; index++) {
        riepilogoList.push(riepilogoFisicoList[index]);
      }
      for (let index = 0; index < riepilogoGiuridicoList.length; index++) {
        riepilogoList.push(riepilogoGiuridicoList[index]);
      }
      for (let index = 0; index < riepilogoGenericoList.length; index++) {
        riepilogoList.push(riepilogoGenericoList[index]);
      }
    }
    if (figura === 'beneficiarioVita') {
      const riepilogoFisicoList = this.props.benefVitaFisicoList;
      const riepilogoGiuridicoList = this.props.benefVitaGiuridicoList;
      const riepilogoGenericoList = this.props.benefVitaGenericoList;
      for (let index = 0; index < riepilogoFisicoList.length; index++) {
        riepilogoList.push(riepilogoFisicoList[index]);
      }
      for (let index = 0; index < riepilogoGiuridicoList.length; index++) {
        riepilogoList.push(riepilogoGiuridicoList[index]);
      }
      for (let index = 0; index < riepilogoGenericoList.length; index++) {
        riepilogoList.push(riepilogoGenericoList[index]);
      }
    }
    return riepilogoList;
  } 
  
  handleClick = (event, id, figura) => {
    let riepilogoList = [];
    if (figura === 'beneficiarioMorte') {
      riepilogoList = this.caricaRiepilogoList(figura);
      this.props.caricaDettaglioBeneficiarioMorte(riepilogoList, id);
      this.props.updateDatiBeneficiarioMorte(false, actionTypeBenefMorte.SALVA_BENEF_MORTE_FISICO_SUCCESS);
    }
    if (figura === 'beneficiarioVita') {
      riepilogoList = this.caricaRiepilogoList(figura);
      this.props.caricaDettaglioBeneficiarioVita(riepilogoList, id);
      this.props.updateDatiBeneficiarioVita(false, actionTypeBenefVita.SALVA_BENEF_VITA_FISICO_SUCCESS);
    }
  };

  handleClickDelete = (event, id, figura) => {
    const riepilogoList = this.caricaRiepilogoList(figura);
    const benefDel = riepilogoList[id];
    const tipoPersDel = benefDel.datiFigureAnagrBase.tipoPers;
    if (figura === 'beneficiarioVita') {
      if (tipoPersDel === PERSONA_FISICA) {
        this.props.deleteDettaglioBenefVitaFisico(benefDel);
      } else if (tipoPersDel === PERSONA_GIURIDICA) {
        this.props.deleteDettaglioBenefVitaGiuridico(benefDel);
      } else if (tipoPersDel === PERSONA_GENERICA) {
        this.props.deleteDettaglioBenefVitaGenerico(benefDel);
      }
    } else if (figura === 'beneficiarioMorte') {
      if (tipoPersDel === PERSONA_FISICA) {
        this.props.deleteDettaglioBenefMorteFisico(benefDel);
      } else if (tipoPersDel === PERSONA_GIURIDICA) {
        this.props.deleteDettaglioBenefMorteGiuridico(benefDel);
      } else if (tipoPersDel === PERSONA_GENERICA) {
        this.props.deleteDettaglioBenefMorteGenerico(benefDel);
      }
    }
  }

  render() {
	console.log('#RTCFA TabDatiRiepilogoFigura.this.props', this.props);
    const { classes, t, figura, tabHeader } = this.props;
    
    const riepilogoList = this.datiRiepilogoFrom(figura);
    const percTotale = figura === 'beneficiarioMorte' ? this.props.percBenMorteTotale : figura === 'beneficiarioVita' ? this.props.percBenVitaTotale : undefined;
    let tableList = riepilogoList;
    return (      
	  <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
	      {tableList && (tableList !== undefined) && (tableList !== null) &&
           <div className={classes.tableWrapper}>
             <Table className={classes.table}>
               <TableHead>
                 <TableRow>
                   <CustomTableCell align='left' colSpan={5} ><Typography variant='subtitle1'>{t(tabHeader)}</Typography></CustomTableCell>
                 </TableRow>
                 <TableRow>
                   <CustomTableCell width='60%' align='left'><Typography variant='subtitle1'>{t('general.nominativo')}</Typography></CustomTableCell>
                   <CustomTableCell width='25%' align='left'><Typography variant='subtitle1'>{t('codiceFiscale')}</Typography></CustomTableCell>
                   <CustomTableCell width='7%' align='center'><Typography variant='subtitle1'>{'%'}</Typography></CustomTableCell>
                   <CustomTableCell width='3%' align='center'></CustomTableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                {tableList.map(row => (
                 <TableRow hover className={classes.row} key={row.id}>
                   <CustomTableCell align='left' onClick={event => this.handleClick(event, row.id, figura)}><Typography variant='body2'>{row.nominativo}</Typography></CustomTableCell>
                   <CustomTableCell align='left' onClick={event => this.handleClick(event, row.id, figura)}><Typography variant='body2'>{row.codiceFiscale}</Typography></CustomTableCell>
                   <CustomTableCell align='center' onClick={event => this.handleClick(event, row.id, figura)}><Typography variant='body2'>{row.perc}</Typography></CustomTableCell>
                   <CustomTableCell align='center'><DeleteForever onClick={event => this.handleClickDelete(event, row.id, figura)}/></CustomTableCell>
                 </TableRow>
                ))}
                {(percTotale !== undefined) && (percTotale !== null) && (percTotale > 0) &&
                 <TableRow>
                   <CustomTableCell align='left'colSpan={2} className={classes.cellTotale}>{t('totale')}</CustomTableCell>
                   <CustomTableCell align='center' className={percTotale!==100.0 ? classes.cellPercTotale: ''}>{percTotale}</CustomTableCell>
                   <CustomTableCell align='center'></CustomTableCell>
                 </TableRow>
                }
               </TableBody>
             </Table>
           </div>
	      }
  	    </Grid>
  	  </Grid>
    );
  }
}

TabDatiRiepilogoFigura.propTypes = {
  classes: PropTypes.object.isRequired,
  figura: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TabDatiRiepilogoFigura)));
