import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NuovaUtenzaPanel from '../gestioneUtenze/NuovaUtenzaPanel';
import ModificaUtenzaPanel from '../gestioneUtenze/ModificaUtenzaPanel';
import InviaNuoveCredenzialiPanel from '../gestioneUtenze/InviaNuoveCredenzialiPanel';
import { connect } from 'react-redux';
import { MASTER } from '../../utility/jsConstants';

const styles = theme => ({
  componentContainer: {
    marginTop: 100,
    padding: '1em',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '2em 4em',
      height: 'auto',
    }
  },
});
	
const mapStateToProps = state => {
  return {
	loginUser: state.areaProdottoReducer.loginUser,
  };
};
	
class GestioneUtenze extends React.Component {
  constructor(props) {
    super(props);
	this.state = {
	};
  }
    
  render () {
    const { classes } = this.props;	 
    return (
      <div className={classes.componentContainer}> 
        <NuovaUtenzaPanel /> 
        <ModificaUtenzaPanel />
        {this.props.loginUser.profilo.trim()===MASTER &&
          <InviaNuoveCredenzialiPanel />
        }
      </div>
    );
  }
}

GestioneUtenze.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(GestioneUtenze));