import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import RadioButtonComp from '../../../common/RadioButtonComp';
import SelectComp from '../../../common/SelectComp';

import { RUOLO_CONTRAENTE, RUOLO_ASSICURATO, 
	     BENEF_VITA_FISICO, BENEF_MORTE_FISICO,
	     BENEF_VITA_GIURIDICO, BENEF_MORTE_GIURIDICO, CODICE_SAE_FIDUCIARIA_GESTIONE, CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, } from '../../../../utility/jsConstants';
import { isContraenteFisico } from '../../../../utility/genericUtility';
import { isContraenteGiuridico, textTrim } from '../../../../utility/genericUtility';

import { radioButtonCopiaDaAltraFigura } from '../../../store/radioButtonStore';

import * as actionsBenefMorte from '../../figure/action/beneficiarioMorte';
import * as actionTypeBenefMorte from '../../figure/actionType/beneficiarioMorte';
import * as actionsBenefVita from '../../figure/action/beneficiarioVita';
import * as actionTypeBenefVita from '../../figure/actionType/beneficiarioVita';

const styles = theme => ({
  root: {
//    display: 'flex',
//    flexWrap: 'wrap',
//    color: theme.palette.color.windowsBlue,     
//    '&$checked': {
//       color: theme.palette.color.windowsBlue,
//     },
    marginTop: theme.spacing(5),
  },
  textField: theme.textField,
  dateField: theme.dateField,
//  checkbox: theme.checkbox,
  fieldSet: theme.fieldSet,
});

const mapStateToProps = state => {
  return {
	datiCopiaDaAltraFiguraBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.datiCopiaDaAltraFigura,
	datiCopiaDaAltraFiguraBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.datiCopiaDaAltraFigura,
	datiCopiaDaAltraFiguraBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.datiCopiaDaAltraFigura,
	datiCopiaDaAltraFiguraBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.datiCopiaDaAltraFigura,
	
	controlloDatiBenefMorteFisico: state.areaBenefMorteReducer.benefMorteFisico.controlloDati,		
	controlloDatiBenefMorteGiuridico: state.areaBenefMorteReducer.benefMorteGiuridico.controlloDati,
	controlloDatiBenefVitaFisico: state.areaBenefVitaReducer.benefVitaFisico.controlloDati,
	controlloDatiBenefVitaGiuridico: state.areaBenefVitaReducer.benefVitaGiuridico.controlloDati,
	contraenteFisico: state.areaContraenteReducer.contraenteFisico,
	contraenteGiuridico: state.areaContraenteReducer.contraenteGiuridico,
	assicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiBeneficiarioMorte: (unDato, unActionType, isFiduciaria) => dispatch(actionsBenefMorte.updateDatiBeneficiarioMorte(unDato, unActionType, isFiduciaria)),
    updateDatiBeneficiarioVita: (unDato, unActionType, isFiduciaria) => dispatch(actionsBenefVita.updateDatiBeneficiarioVita(unDato, unActionType, isFiduciaria)),
  };
};

class CopiaDaAltraFigura extends React.Component {
  constructor(props) {
    super(props);
//    const datiCopiaDaAltraFigura = props.datiCopiaDaAltraFigura;
//    const flCopiaDaAltraFigura = datiCopiaDaAltraFigura.flCopiaDaAltraFigura;	  
    this.state = {		 
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,
    };
  };
   
  getFigureFromList = () => {
    let figureList = [];
    if (this.props.figura === BENEF_VITA_FISICO || this.props.figura === BENEF_MORTE_FISICO ) {
      const isContrFisico = isContraenteFisico(this.props.contraenteFisico);
      //const isContrFisico = true; // TODO: sostituire righe prec
      if (isContrFisico &&
         (this.props.figura === BENEF_VITA_FISICO ||
         (this.props.figura === BENEF_MORTE_FISICO &&
          this.props.assicuratoFisico.flAssicuratoUgualeContraente!=='S'))) {
        figureList = [...figureList, {value: RUOLO_CONTRAENTE.concat(0), 
                                      label: this.props.t('contraente').toUpperCase()}
                     ];
      } 
      // per benfVitaFisico e se l'assicurato e' valido
      if (this.props.figura === BENEF_VITA_FISICO && this.props.assicuratoFisico.controlloDati===true && this.props.assicuratoFisico.anagraficaValida) {
        figureList = [...figureList, {value: RUOLO_ASSICURATO.concat(0), 
                                      label: this.props.t('assicurato').toUpperCase()}
                     ];
      }
    } else if (this.props.figura === BENEF_VITA_GIURIDICO || this.props.figura === BENEF_MORTE_GIURIDICO ) {
      const isContrGiuridico = isContraenteGiuridico(this.props.contraenteGiuridico);
      //const isContrGiuridico = true; // TODO: sostituire righe prec
      if (isContrGiuridico) {
        figureList = [...figureList, {value: RUOLO_CONTRAENTE.concat(0),
                                      label: this.props.t('contraente').toUpperCase()}
                     ];
      } 
    }
    return figureList;
  }
	
	controlloDatiFrom = figura => {
	    let controlloDati = null;
	    if (this.props.figura ===BENEF_VITA_FISICO) {
	    	controlloDati = this.props.controlloDatiBenefVitaFisico;
	    } else if (this.props.figura ===BENEF_MORTE_FISICO) {
	    	controlloDati = this.props.controlloDatiBenefMorteFisico;
	    } else if (this.props.figura ===BENEF_VITA_GIURIDICO) {
	    	controlloDati = this.props.controlloDatiBenefVitaGiuridico;
	    } else if (this.props.figura ===BENEF_MORTE_GIURIDICO) {
	    	controlloDati = this.props.controlloDatiBenefMorteGiuridico;
	    } 
		return controlloDati;
	}

	datiCopiaDaAltraFiguraFrom = figura => {
	    let datiCopiaDaAltraFigura = null;
	    if (this.props.figura === BENEF_VITA_FISICO) {
	    	datiCopiaDaAltraFigura = this.props.datiCopiaDaAltraFiguraBenefVitaFisico;
	    } else if (this.props.figura === BENEF_MORTE_FISICO) {
	    	datiCopiaDaAltraFigura = this.props.datiCopiaDaAltraFiguraBenefMorteFisico;
	    } else if (this.props.figura ===BENEF_VITA_GIURIDICO) {
	    	datiCopiaDaAltraFigura = this.props.datiCopiaDaAltraFiguraBenefVitaGiuridico;
	    } else if (this.props.figura ===BENEF_MORTE_GIURIDICO) {
	    	datiCopiaDaAltraFigura = this.props.datiCopiaDaAltraFiguraBenefMorteGiuridico;
	    } 
		return datiCopiaDaAltraFigura;
	}

	
  handleFlCopiaDaAltraFigura = event => {
//    console.log('handleFlCopiaDaAltraFigura.new value:' + event.target.value);
//    const flCopiaDaAltraFigura = event.target.value;
    const nuovoFlCopiaDaAltraFigura = textTrim(event.target.value, true);
    const datiCopiaDaAltraFigura = this.datiCopiaDaAltraFiguraFrom(this.props.figura);
    const vecchioFlCopiaDaAltraFigura = textTrim(datiCopiaDaAltraFigura.flCopiaDaAltraFigura, true);
    if (nuovoFlCopiaDaAltraFigura !== vecchioFlCopiaDaAltraFigura) {
      if (this.props.figura === BENEF_VITA_FISICO) {
        this.props.updateDatiBeneficiarioVita(nuovoFlCopiaDaAltraFigura, actionTypeBenefVita.UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_FISICO);
        if (nuovoFlCopiaDaAltraFigura==='N') {
          // reset figura From
          this.props.updateDatiBeneficiarioVita('', actionTypeBenefVita.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_FISICO);
        }
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        this.props.updateDatiBeneficiarioMorte(nuovoFlCopiaDaAltraFigura, actionTypeBenefMorte.UPDATE_FL_COPIA_DA_FIGURA_BENEF_MORTE_FISICO);
        if (nuovoFlCopiaDaAltraFigura==='N') {
          // reset figura From
          this.props.updateDatiBeneficiarioMorte('', actionTypeBenefMorte.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_MORTE_FISICO);
        }
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        this.props.updateDatiBeneficiarioVita(nuovoFlCopiaDaAltraFigura, actionTypeBenefVita.UPDATE_FL_COPIA_DA_FIGURA_BENEF_VITA_GIURIDICO);
        if (nuovoFlCopiaDaAltraFigura==='N') {
          // reset figura From
          this.props.updateDatiBeneficiarioVita('', actionTypeBenefVita.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_GIURIDICO, false);
        }
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        this.props.updateDatiBeneficiarioMorte(nuovoFlCopiaDaAltraFigura, actionTypeBenefMorte.UPDATE_FL_COPIA_DA_FIGURA_BENEF_MORTE_GIURIDICO);
        if (nuovoFlCopiaDaAltraFigura==='N') {
          // reset figura From
          this.props.updateDatiBeneficiarioMorte('', actionTypeBenefMorte.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_MORTE_GIURIDICO, false);
        }
      }
      // funzione di callback
      if (this.props.handleChangeFlCopiaDaAltraFigura) {
        this.props.handleChangeFlCopiaDaAltraFigura(nuovoFlCopiaDaAltraFigura);
      }
    }
  }
  
  handleChangeFiguraFrom = event => {
//  console.log('handleChangeFiguraFrom.new value:' + event);	  
    const nuovaFiguraFrom = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiCopiaDaAltraFigura = this.datiCopiaDaAltraFiguraFrom(this.props.figura);
    const vecchiaFiguraFrom = textTrim(datiCopiaDaAltraFigura.figuraFrom, true);      
    const isFiduciaria = this.props.contraenteGiuridico.datiAttEconomicaGiuridico.codSAE === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE ||
                                   this.props.contraenteGiuridico.datiAttEconomicaGiuridico.codSAE === CODICE_SAE_FIDUCIARIA_GESTIONE;
    if (nuovaFiguraFrom !== vecchiaFiguraFrom) {    
      if (this.props.figura === BENEF_VITA_FISICO) {
        this.props.updateDatiBeneficiarioVita(event, actionTypeBenefVita.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_FISICO);
      } else if (this.props.figura === BENEF_MORTE_FISICO) {
        this.props.updateDatiBeneficiarioMorte(event, actionTypeBenefMorte.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_MORTE_FISICO);
      } else if (this.props.figura === BENEF_VITA_GIURIDICO) {
        this.props.updateDatiBeneficiarioVita(event, actionTypeBenefVita.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_VITA_GIURIDICO, isFiduciaria);
      } else if (this.props.figura === BENEF_MORTE_GIURIDICO) {
        this.props.updateDatiBeneficiarioMorte(event, actionTypeBenefMorte.UPDATE_COPIA_DA_FIGURA_FROM_BENEF_MORTE_GIURIDICO, isFiduciaria);
      }
      // funzione di callback
      if (this.props.handleChangeFiguraFrom) {
        this.props.handleChangeFiguraFrom(nuovaFiguraFrom);
      }	  
    }
  }
  
	isRequiredFiguraFrom = () => {
		return this.props.datiCopiaDaAltraFigura.flCopiaDaAltraFigura==='S';
	} 
	
	initStatoCampi = () => {
	    return [ {id: 'flCopiaDaAltraFigura', isError: false},
	             {id: 'figuraFrom', isError: false},
	           ];
	}

	handleError = (id, isError) => {
		console.log('########CopiaDaAltraFigura handleError id', id);	  
		console.log('########CopiaDaAltraFigura handleError isError', isError);	    
	    let unErrore = null;
	    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
	    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
	    if (campoOnId.length > 0) {
	      unErrore = campoOnId[0];
	      unErrore.isError = isError;
	    } else {
	      unErrore = { id: id, isError: isError };
	    }
	    this.setState({	
	      statoCampi: [...campoWithoutId, unErrore],      
	    });
	}
	  
  render() {
	console.log('CopiaDaAltraFigura.this.props', this.props);
    const { classes, figura, disabled } = this.props;
//    const { t } = this.props; //hoc
    const controlloDati = this.controlloDatiFrom(figura);
    const datiCopiaDaAltraFigura = this.datiCopiaDaAltraFiguraFrom(figura);
    const disabledComp = this.props.disabled !== undefined ? this.props.disabled : false;
    const readonlyComp = this.props.readonly !== undefined ? this.props.readonly : false;
    const readonlyFigureComp = readonlyComp || datiCopiaDaAltraFigura.flCopiaDaAltraFigura === 'N';
    const figureFromList = this.getFigureFromList();
    
    return (
      <Fragment>
        <Grid container direction='row' justify='space-evenly' alignItems='flex-start' className={classes.root}>
          <Grid item>
            <RadioButtonComp onChange={this.handleFlCopiaDaAltraFigura} id={'flCopiaDaAltraFigura'} radioComp={radioButtonCopiaDaAltraFigura} required={false} 
                             selectedValue={datiCopiaDaAltraFigura.flCopiaDaAltraFigura} disabled={disabledComp} readonly={readonlyComp} onError={this.handleError}/>
          </Grid> 
          <Grid item sm={3} xs={12}>
            <SelectComp onChange={this.handleChangeFiguraFrom} id={'figuraFrom'} options={figureFromList} ricerca='comboRicerca' etichetta='Figura'   
                        value={{value: datiCopiaDaAltraFigura.figuraFrom, label: datiCopiaDaAltraFigura.descrFiguraFrom}} required={this.isRequiredFiguraFrom()} 
                        controlloDati={controlloDati} disabled={disabledComp} readonly={readonlyFigureComp} onError={this.handleError} /> 
          </Grid>
        </Grid>
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########CopiaDaAltraFigura componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########CopiaDaAltraFigura componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########CopiaDaAltraFigura componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	    
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      } 
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
	}
  }

/*  isResetComp(prevProps, prevState) {	  
	  	
	const resetCompCurr = this.props.resetComp;
	const resetCompPrev = prevProps.resetComp;
	
	if (resetCompCurr!==undefined && resetCompCurr===true && resetCompCurr!==resetCompPrev) { 
		this.setState ({
			statoCampi: this.initStatoCampi(),
			isPresenteErrore: false,      
		});
	}
  }
*/
  
  componentDidUpdate(prevProps, prevState) {
//	this.isResetComp(prevProps, prevState);
    this.isInError(prevProps, prevState);
  }
}

CopiaDaAltraFigura.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(CopiaDaAltraFigura)));
