import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
//import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { uris, fetchParam, isLocal } from '../../../../uris';
import TextFieldComp from '../../../common/TextFieldComp';
import SelectComp from '../../../common/SelectComp';
import CheckboxComp from '../../../common/CheckboxComp';

import * as actions from './action/datiModPagBonifico';
import * as actionType from './actionType/datiModPagBonifico';

import { textTrim, checkBoxValue } from '../../../../utility/genericUtility';
import { CODICE_ALTRA_RELAZIONE, locale_it } from '../../../../utility/jsConstants';
import { getNomeIntestatarioFromTipo } from '../../../../utility/storeUtility';

import moment from 'moment';
import 'moment/locale/it';


const styles = theme => ({
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  button: theme.button,
  textFieldWidth: {
	  width: '80%',	
  },
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiModPagBonifico.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiModPagBonifico: state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonifico,
    controlloIban: state.areaDatiModPagBonificoReducer.caratteristicheContratto.datiModPagBonifico.controlloIban,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModPagBonifico.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiModPagBonifico: (unDato, unActionType) => dispatch(actions.updateDatiModPagBonifico(unDato, unActionType)),
    salvaBancaAgenziaDaIban: (banca, descBanca, agenzia, descAgenzia) => dispatch(actions.salvaBancaAgenziaDaIban(banca, descBanca, agenzia, descAgenzia)),
  };
};
	
class DatiModPagBonifico extends React.Component {
	
  constructor(props) {
	super(props);
	const datiModPagBonifico = this.props.datiModPagBonifico;
	this.state = {
	  iban: datiModPagBonifico.iban,
	  banca: datiModPagBonifico.banca,
	  descBanca: datiModPagBonifico.descBanca,
	  agenzia: datiModPagBonifico.agenzia,
    descAgenzia: datiModPagBonifico.descAgenzia,
    nSottorubrica: datiModPagBonifico.nSottorubrica,
    intestatario: datiModPagBonifico.intestatario,
    contoCointestato: datiModPagBonifico.contoCointestato,
    nomeCognomeCointestatario: datiModPagBonifico.nomeCognomeCointestatario,
    relazContrCoint: datiModPagBonifico.relazContrCoint,
    descrRelazContrCoint: datiModPagBonifico.descrRelazContrCoint,
    altraRelazione: datiModPagBonifico.altraRelazione,
    statoCampi: this.initStatoCampi(),
    isPresenteErrore: false,
    codiceIbanValido: null,
	  //filialeItems: [],
	};
  };
  
  /*
  componentDidMount() {	     
    this.setFiliale(this.props.datiModPagBonifico ? this.props.datiModPagBonifico.banca : '');
  }*/
  
  handleChangeIban = event => {
    //console.log('#RTCFA DatiModPagBonifico iban new value:' + event.target.value);
    const nuovoIban = textTrim(event.target.value, true);
    const vecchioIban = textTrim(this.state.iban, true);
    if (nuovoIban !== vecchioIban) {	  
      this.props.updateDatiModPagBonifico(nuovoIban, actionType.UPDATE_IBAN);   
      this.setState({ 
        iban: nuovoIban 
      });
      this.setBancaAgenziaDaIban(nuovoIban);
    }
  };

  handleChangeNSottorubrica = event => {
    const nuovoSottorubrica = textTrim(event.target.value, true);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchioSottorubrica = datiModPagBonifico.nSottorubrica;
    if(nuovoSottorubrica !== vecchioSottorubrica) {
      this.props.updateDatiModPagBonifico(nuovoSottorubrica, actionType.UPDATE_N_SOTTORUBRICA);
      this.setState({
        nSottorubrica: nuovoSottorubrica
      })
    }
  }

  handleChangeIntestatario = event => {
    const nuovoIntestatario = textTrim(event.target.value, true);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchioIntestatario = datiModPagBonifico.intestatario;
    if(nuovoIntestatario !== vecchioIntestatario) {
      this.props.updateDatiModPagBonifico(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_BONIFICO);
      this.setState({
        intestatario: nuovoIntestatario
      })
    }
  }

  handleChangeContoCointestato = event => {
    const nuovoCoint = checkBoxValue(event.target.checked, true);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchioCoint = datiModPagBonifico.contoCointestato;
    if(nuovoCoint !== vecchioCoint) {
      this.props.updateDatiModPagBonifico(nuovoCoint, actionType.UPDATE_COINTESTATO_BONIFICO);
      this.setState({
        contoCointestato: nuovoCoint
      })
    }
  }

  handleChangeNomeCointestatario = event => {
    const nuovoCoint = textTrim(event.target.value, true);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchioCoint = datiModPagBonifico.nomeCognomeCointestatario;
    if(nuovoCoint !== vecchioCoint) {
      this.props.updateDatiModPagBonifico(nuovoCoint, actionType.UPDATE_NOME_COGNOME_COINTESTATARIO_BONIFICO);
      this.setState({
        nomeCognomeCointestatario: nuovoCoint
      })
    }
  }

  handleChangeRelazione = event => {
    const nuovaRelaz = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchiaRelaz = datiModPagBonifico.relazContrCoint;
    if(nuovaRelaz !== vecchiaRelaz) {
      this.props.updateDatiModPagBonifico(event, actionType.UPDATE_RELAZ_PAGAT_CONTR_BONIFICO);
      this.setState({
        relazContrCoint: nuovaRelaz,
        descrRelazContrCoint: (event === null) ? '' : event.label,
      })
    }
  }

  handleChangeAltraRelaz = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchioAltro = datiModPagBonifico.altraRelazione;
    if(nuovoAltro !== vecchioAltro) {
      this.props.updateDatiModPagBonifico(nuovoAltro, actionType.UPDATE_ALTRA_RELAZ_BONIFICO);
      this.setState({
        altraRelazione: nuovoAltro
      })
    }
  }

  setBancaAgenziaDaIban = (iban) => {
	if(textTrim(iban, true)!==''){
	  const opts = {
	    iban: iban,
	    dataRichiesta: moment().format('DD/MM/YYYY'),
      };
      fetch(uris.bancaAgenziaDaIbanItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
    	this.props.salvaBancaAgenziaDaIban(result.banca.value, result.banca.label, result.filiale.value, result.filiale.label, actionType.SALVA_BANCA_AGENZIA_DA_IBAN);
        this.setState({
    	  banca: result.banca.value,
      	  descBanca: result.banca.label,
      	  agenzia: result.filiale.value,
      	  descAgenzia: result.filiale.label,
        });
      });
	}else{
      this.props.salvaBancaAgenziaDaIban('', '', '', '', actionType.SALVA_BANCA_AGENZIA_DA_IBAN);	
      this.setState({
        banca: '',
    	descBanca: '',
    	agenzia: '',
    	descAgenzia: '',
      });
    }
  }
  
  /*
  handleChangeBanca = event => {
    //console.log('#RTCFA DatiModPagBonifico banca new value:' + event); 
    this.props.updateDatiModPagBonifico(event, actionType.UPDATE_BANCA); 
    this.setFiliale((event === null) ? '' : event.value);
    this.setState({
      banca: (event === null) ? '' : event.value,
      descBanca: (event === null) ? '' : event.label,
    });	
  }  
  
  handleChangeAgenzia = event => {
    //console.log('#RTCFA DatiModPagBonifico agenzia new value:' + event); 
    this.props.updateDatiModPagBonifico(event, actionType.UPDATE_AGENZIA); 	
    this.setState({
      agenzia: (event === null) ? '' : event.value,
      descAgenzia: (event === null) ? '' : event.label,
    });	
  }  
  
  setFiliale = (codiceAbi) => {
    if(textTrim(codiceAbi, true)!==''){
	  const opts = {
        codiceAbi: codiceAbi,
        dataRichiesta: moment().format('DD/MM/YYYY'),
      };
      fetch(uris.filialeItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          filialeItems: result,
        });
      });
    }else{
      this.setState({
        filialeItems: [],
      });
    }
  }*/
  
  verificaIban = () => {
	let controlloIban = '';
	const datiModPagBonifico = this.props.datiModPagBonifico;
	const iban = textTrim(datiModPagBonifico.iban, true);
	if (iban !== '') {
      if (isLocal === true) {	
    	controlloIban = 'KO';
        this.props.updateDatiModPagBonifico(controlloIban, actionType.UPDATE_CONTROLLO_IBAN_PAG_BONIFICO);
        if (this.props.onChangeControlloIban){
          this.props.onChangeControlloIban(controlloIban);
        }
      } else {
        const opts = {
          iban: iban,
        };
        fetch(uris.controlloIban, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    // If request is good update state with fetched data
	    .then((result) => {
          controlloIban = (result === true) ? 'OK': 'KO';
          this.props.updateDatiModPagBonifico(controlloIban, actionType.UPDATE_CONTROLLO_IBAN_PAG_BONIFICO);
          if (this.props.onChangeControlloIban){
            this.props.onChangeControlloIban(controlloIban);
          }
        });
	  }
	}  
	return controlloIban;
  }
    
/*  verificaIban = () => {
	let controlloIban = '';
	const datiModPagBonifico = this.props.datiModPagBonifico;
	if (textTrim(datiModPagBonifico.iban, true) !== '') {
      const opts = {
        iban: datiModPagBonifico.iban,
      };
      fetch(uris.controlloIban, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	    )
	    // If request is good update state with fetched data
	    .then((result) => {
          controlloIban = (result === true) ? 'OK': 'KO';
          this.props.updateDatiModPagBonifico(controlloIban, actionType.UPDATE_CONTROLLO_IBAN_PAG_BONIFICO);
          if (this.props.onChangeControlloIban){
            this.props.onChangeControlloIban(controlloIban);
          }
        });
	}
	return controlloIban;
  }
*/    
  controlloIban = (controlloDati) => {
    let controlloIban = this.props.controlloIban;       
    if (controlloDati===true) {
      if (controlloIban === 'CHECK_IBAN') {
        // service call
        controlloIban = this.verificaIban();
      }
    }    
    return controlloIban;
  }
  
  isRequiredIban = () => {
    return true;
  }
  
  isRequiredBanca = () => {
    return false;
  }
  
  isRequiredAgenzia = () => {
    return false;
  }
  
  isRequiredNSottorubrica = () => {
    return false;
  }

  isRequiredIntestatario = () => {
    return true;
  }

  isRequiredContoCointestato = () => {
    return false;
  }

  isRequiredNomeCointestatario = () => {
	//2021-07-01 PFD: TCM_INDIV
	//const datiModPagBonifico = this.props.datiModPagBonifico;
	//return datiModPagBonifico.contoCointestato;
	const datiModPagBonifico = this.props.datiModPagBonifico;
	let required = datiModPagBonifico.contoCointestato;
	//se scopeTcmEmissione, non obbligatorio anche se contoCointestato
	if (this.props.scopeTcmEmissione) {
	  const isTcmIndividValida = this.props.tcmIndividualeValida;
	  if (isTcmIndividValida) {
		  required = false;
	  }
	}
    return required;
  }

  isRequiredRelazione = () => {
	//2021-07-01 PFD: TCM_INDIV
	//const datiModPagBonifico = this.props.datiModPagBonifico;
	//return datiModPagBonifico.contoCointestato;
	const datiModPagBonifico = this.props.datiModPagBonifico;
	let required = datiModPagBonifico.contoCointestato;
	//se scopeTcmEmissione, non obbligatorio anche se contoCointestato
	if (this.props.scopeTcmEmissione) {
	  const isTcmIndividValida = this.props.tcmIndividualeValida;
	  if (isTcmIndividValida) {
		  required = false;
	  }
	}
    return required;
  }

  isRequiredAltraRelazione = () => {
    const datiModPagBonifico = this.props.datiModPagBonifico;
    return datiModPagBonifico.relazContrCoint === CODICE_ALTRA_RELAZIONE;
  }

  getIsInError = (id) => {
    const campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    const isInErrore = (campoOnId.length > 0) ? campoOnId[0].isError : false;
    return isInErrore;
  }
    
  initStatoCampi = () => {
    return [ {id: 'iban', isError: false},
             {id: 'banca', isError: false}, 
             {id: 'agenzia', isError: false},
             {id: 'nSottorubrica', isError: false},
             {id: 'intestatario', isError: false},
             {id: 'contoCointestato', isError: false},
             {id: 'nomeCognomeCointestatario', isError: false},
             {id: 'relazContrCoint', isError: false},
             {id: 'altraRelazione', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {
    console.log('########DatiModPagBonifico verificaIban handleError id', id);	  
    console.log('########DatiModPagBonifico verificaIban handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  isDisabledNSottorubrica = () => {
    return false;
  }

  isDisabledIntestatario = () => {
    return false;
  }

  isDisabledContoCointestato = () => {
    return false;
  }

  isDisabledNomeCognomeCointestatario = () => {
    const datiModPagBonifico = this.props.datiModPagBonifico;
    return !datiModPagBonifico.contoCointestato;
  }

  isDisabledRelazione = () => {
    const datiModPagBonifico = this.props.datiModPagBonifico;
    return !datiModPagBonifico.contoCointestato;
  }

  isDisabledAltraRelazione = () => {
    const datiModPagBonifico = this.props.datiModPagBonifico;
    return datiModPagBonifico.relazContrCoint !== CODICE_ALTRA_RELAZIONE;
  }

  componentDidMount = () => {
    const nuovoIntestatario = getNomeIntestatarioFromTipo(this.props.tipoIntestatario);
    const datiModPagBonifico = this.props.datiModPagBonifico;
    const vecchioIntestatario = datiModPagBonifico.intestatario;
    if(nuovoIntestatario !== vecchioIntestatario) {
      this.props.updateDatiModPagBonifico(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_BONIFICO);
      this.setState({
        intestatario: nuovoIntestatario
      })
    }
  }

  render() {
    const { classes, datiModPagBonifico, controlloDati, dominio } = this.props;  
    const controlloIban = this.controlloIban(controlloDati);   
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item md={4} xs={12}>
          <TextFieldComp onBlur={this.handleChangeIban} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} id='iban' label='iban' 
        	  			 value={datiModPagBonifico.iban} required={this.isRequiredIban()} controlloDati={controlloDati} maxLength={27} 
                         onError={this.handleError} isControlError={this.getIsInError('iban')} />
        </Grid>
        <Grid item md={4} xs={12}>
          {/*<SelectComp onChange={this.handleChangeBanca} options={dominio.bancaItems} ricerca='comboRicerca' etichetta='banca' value={{value: datiModPagBonifico.banca, label: datiModPagBonifico.descBanca}} 
                         required={this.isRequiredBanca()} controlloDati={controlloDati} id='banca' onError={this.handleError} />*/}
          <TextFieldComp aStyle={classes.textFieldWidthPerc} id='banca' label='banca' value={datiModPagBonifico.descBanca} readonly={true} 
                         onError={this.handleError} aStyleError={classes.textFieldWidthPercError}  />
        </Grid>
        <Grid item md={4} xs={12}>
          {/*<SelectComp onChange={this.handleChangeAgenzia} options={this.state.filialeItems} ricerca='comboRicerca' etichetta='agenzia' value={{value: datiModPagBonifico.agenzia, label: datiModPagBonifico.descAgenzia}} 
                         required={this.isRequiredAgenzia()} controlloDati={controlloDati} id='agenzia' onError={this.handleError} />*/}
          <TextFieldComp aStyle={classes.textFieldWidthPerc} id='agenzia' label='agenzia' value={datiModPagBonifico.descAgenzia} readonly
                         onError={this.handleError} aStyleError={classes.textFieldWidthPercError}  />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFieldComp aStyle={classes.textFieldWidthPerc} id='nSottorubrica' label='nSottorubrica' onError={this.handleError} 
                         value={datiModPagBonifico.nSottorubrica} required={this.isRequiredNSottorubrica()} disabled={this.isDisabledNSottorubrica()} 
                         onBlur={this.handleChangeNSottorubrica} aStyleError={classes.textFieldWidthPercError}  />
        </Grid>

        <Grid item md={8} xs={12}>
          <TextFieldComp aStyle={classes.textFieldWidthPerc} id='intestatario' label='intestatario' onError={this.handleError} controlloDati={controlloDati}
                         value={this.state.intestatario} required={this.isRequiredIntestatario()} disabled={this.isDisabledIntestatario()} 
                         onBlur={this.handleChangeIntestatario} aStyleError={classes.textFieldWidthPercError} readonly={true}  />
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={4} xs={12}>
            <CheckboxComp onChange={this.handleChangeContoCointestato} required={this.isRequiredContoCointestato()} controlloDati={controlloDati}
                          aLabel='contoCointestato' checked={datiModPagBonifico.contoCointestato} id='contoCointestato' onError={this.handleError} 
                          disabled={this.isDisabledContoCointestato()} />
          </Grid>

          <Grid item md={8} xs={12}>
            <TextFieldComp onBlur={this.handleChangeNomeCointestatario} required={this.isRequiredNomeCointestatario()} controlloDati={controlloDati} id='nomeCognomeCointestatario'
                          label='nomeCognomeCointestatario' value={datiModPagBonifico.nomeCognomeCointestatario} onError={this.handleError} 
                          aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledNomeCognomeCointestatario()} aStyleError={classes.textFieldWidthPercError}  />
          </Grid>
        </Grid>

        <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeRelazione} options={dominio.relazioneItems} etichetta='relazContrCoint' id='relazContrCoint'
                        value={{value: datiModPagBonifico.relazContrCoint, label: datiModPagBonifico.descrRelazContrCoint}} onError={this.handleError} 
                        controlloDati={controlloDati} disabled={false} required={this.isRequiredRelazione()} disabled={this.isDisabledRelazione()} />
        </Grid>
        <Grid item md={8} xs={12}>
            <TextFieldComp onBlur={this.handleChangeAltraRelaz} required={this.isRequiredAltraRelazione()} controlloDati={controlloDati}
                           label='general.altro' value={datiModPagBonifico.altraRelazione} onError={this.handleError} id='altraRelazione'
                           aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledAltraRelazione()} aStyleError={classes.textFieldWidthPercError}  />
        </Grid>

      </Grid>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiModPagBonifico componentDidUpdate this.state.statoCampi', this.state.statoCampi);
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
  
  isInBusinessError(prevProps, prevState) {
  /*	console.log('########DatiModPagBonifico verificaIban isInBusinessError prevState.codiceIbanValido', prevState.codiceIbanValido);
	console.log('########DatiModPagBonifico verificaIban isInBusinessError this.state.codiceIbanValido', this.state.codiceIbanValido);
	if (this.state.codiceIbanValido !== prevState.codiceIbanValido) {
	  this.handleError('iban', !this.state.codiceIbanValido);		
	}*/		  
	const prevControlloIban = prevProps.controlloIban;
	const currControlloIban = this.props.controlloIban;
	console.log('########DatiModPagBonifico verificaIban isInBusinessError prevProps.controlloIban', prevControlloIban);
	console.log('########DatiModPagBonifico verificaIban isInBusinessError this.props.controlloIban', currControlloIban);
/*  if (this.state.codiceIbanValido !== prevState.codiceIbanValido) { 
	  this.handleError('iban', !this.state.codiceIbanValido);
    } */
    if ((prevControlloIban !== currControlloIban) && (currControlloIban !== 'CHECK_IBAN')) {
      const ibanValido = (currControlloIban === 'OK') ? true : false;
	  this.handleError('iban', !ibanValido);		
	}
  }
    
  componentDidUpdate(prevProps, prevState) {
    this.isInBusinessError(prevProps, prevState);
    this.isInError(prevProps, prevState);
    if (prevProps.pagatorePremio !== this.props.pagatorePremio) {
      const nuovoIntestatario = getNomeIntestatarioFromTipo(this.props.tipoIntestatario);
      const datiModPagBonifico = this.props.datiModPagBonifico;
      const vecchioIntestatario = datiModPagBonifico.intestatario;
      if(nuovoIntestatario !== vecchioIntestatario) {
        this.props.updateDatiModPagBonifico(nuovoIntestatario, actionType.UPDATE_INTESTATARIO_BONIFICO);
        this.setState({
          intestatario: nuovoIntestatario
        })
      }
    }
  }  
}

DatiModPagBonifico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiModPagBonifico));
