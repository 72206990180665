import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../common/ExpansionPanelComp';
import DatiListaDettaglioUtenza from './sezione/DatiListaDettaglioUtenza';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function ModificaUtenzaPanel(props) {
	
  const { classes } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'modificaUtenza'} componente={DatiListaDettaglioUtenza} senzaLensIcon={true} /> 
    </div>
  );
}

ModificaUtenzaPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModificaUtenzaPanel);
