import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import DatiGeneraliFisico from '../sezione/DatiGeneraliFisico';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiAntiriciclaggio from '../sezione/DatiAntiriciclaggio';

import ButtonComp from '../../common/ButtonComp';
import RadioButtonComp from '../../common/RadioButtonComp';
import SelectComp from '../../common/SelectComp';

import { radioButtonAssicuratoUgualeContraente } from '../../store/radioButtonStore';

import { ASSICURATO_FISICO } from '../../../utility/jsConstants';
import { isContraenteGiuridico, textTrim } from '../../../utility/genericUtility';

import * as actions from './action/assicurato';
import * as actionType from './actionType/assicurato';
import DatiAttivitaEconomica from '../sezione/DatiAttivitaEconomica';
import TextFieldComp from '../../common/TextFieldComp';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  console.log('#RTCFA AssicuratoFisicoAss.mapStateToProps.state', state);
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	contraenteGiuridico: state.areaAssicuratoReducer.contraenteGiuridico,
	contraenteGiuridicoValida: state.areaContraenteReducer.contraenteGiuridico.anagraficaValida,
    assicuratoFisico: state.areaAssicuratoReducer.assicuratoFisico,
    datiRelazContr: state.areaAssicuratoReducer.assicuratoFisico.datiRelazContr,
    controlloDati: state.areaAssicuratoReducer.assicuratoFisico.controlloDati,
    prodotto: state.areaProdottoReducer.prodotto,  
    scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA AssicuratoFisicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
    updateAssicuratoFisico: (unDato, anActionType) => dispatch(actions.updateAssicuratoFisico(unDato, anActionType)),        
    updateDatiGeneraliAssicurato: (unDato, unActionType) => dispatch(actions.updateDatiGeneraliAssicurato(unDato, unActionType)),
    updateDatiRelazioneAssicurato: (unDato, unActionType) => dispatch(actions.updateDatiRelazioneAssicurato(unDato, unActionType)),
  };
};
	
class AssicuratoFisicoAss extends React.Component {
	
  constructor(props) {
    super(props);
    console.log('#RTCFA AssicuratoFisicoAss constructor props', this.props);
    this.state = {
      assicuratoFisico: this.props.assicuratoFisico,
      flAssUgContr: this.props.assicuratoFisico.flAssicuratoUgualeContraente,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
      isMounting: false,
      isConferma: false,
    }   
  };
  
  handleChangeConfermaAssicurato = event => {
//    console.log('#RTCFA AssicuratoFisicoAss handleChangeConfermaContraente', event.target.value);
    this.props.updateAssicuratoFisico(true, actionType.SALVA_ASSICURATO_FISICO);
    this.setState({
        isConferma: true,
    });	
  }  
  
  /* handleChangeAttPrevalSvolta = event => {
//	  console.log('#RTCFA AssicuratoFisicoAss handleChangeConfermaContraente', event);
    this.props.updateDatiGeneraliAssicurato(event, actionType.UPDATE_ATTIVITA_PREV_ASSICURATO);
  } */
  
  handleChangeAssicuratoUgualeContraente_OLD = event => {
    const nuovoValore = textTrim(event.target.value, true);  
    this.props.updateDatiGeneraliAssicurato(nuovoValore, actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE);
//    if (nuovoValore === 'S') {
      const campi = this.state.statoCampi.map(unCampo => this.valorizzaIsErrorCon(unCampo, false));
      this.setState({	
        statoCampi: campi,  
        isPresenteErrore: null, 
      });
//    }
  }
  
  handleChangeAssicuratoUgualeContraente = event => {
    const nuovoValore = textTrim(event.target.value, true);
    const vecchioValore = textTrim(this.props.assicuratoFisico.flAssicuratoUgualeContraente, true);
    if (nuovoValore !== vecchioValore) {       
      this.props.updateDatiGeneraliAssicurato(nuovoValore, actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE);
//    if (nuovoValore === 'S') {
      const campi = this.state.statoCampi.map(unCampo => this.valorizzaIsErrorCon(unCampo, false));
      this.setState({	
        statoCampi: campi,  
        isPresenteErrore: null, 
      });
//    }
    }
  }  

  handleChangeRelazione = event => {
    const nuovaRelazione = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiRelaz = this.props.datiRelazContr;
    const vecchiaRelazione = datiRelaz.relazContraente;
    if(nuovaRelazione !== vecchiaRelazione) {
      this.props.updateDatiRelazioneAssicurato(event, actionType.UPDATE_RELAZIONE_CONTRAENTE_ASSICURATO);
      this.setState({
        datiRelazContr: datiRelaz
      })
    }
  }

  handleChangeAltraRelazione = event => {
    const nuovaAltra = textTrim(event.target.value, true);
    const datiRelaz = this.props.datiRelazContr;
    const vecchiaRelaz = datiRelaz.altraRelazione;
    if(nuovaAltra !== vecchiaRelaz) {
      this.props.updateDatiRelazioneAssicurato(nuovaAltra, actionType.UPDATE_ALTRA_RELAZIONE_CONTRAENTE_ASSICURATO);
      this.setState({
        datiRelazContr: datiRelaz
      })
    }
  }

  handleChangeMotivoDesignazione = event => {
    const nuovoMotivo = textTrim(event.target.value, true);
    const datiRelaz = this.props.datiRelazContr;
    const vecchioMotivo = datiRelaz.motivoDesignazione;
    if(nuovoMotivo !== vecchioMotivo) {
      this.props.updateDatiRelazioneAssicurato(nuovoMotivo, actionType.UPDATE_MOTIVO_DESIGNAZIONE_ASSICURATO);
      this.setState({
        datiRelazContr: datiRelaz
      })
    }
  }
  
  valorizzaIsErrorCon = (unCampo, valore) => {
    const nuovoValore = {...unCampo};
    nuovoValore.isError = valore;    
/*    if (nuovoValore.id === 'assicuratoUgualeContraente') {
      nuovoValore.isError = null;
    } else {
      nuovoValore.isError = valore;
    }  */
    return nuovoValore;
  }  
 
  initStatoCampi = () => {
    return [ {id: 'assicuratoUgualeContraente', isError: false},
    	       {id: 'datiGeneraliAssicurato', isError: false},
             {id: 'codAttPrevalSvolta', isError: false},  
             {id: 'datiResidenzaAssicurato', isError: false},  
             {id: 'datiAntiriciclaggioAssicurato', isError: false},      
             {id: 'relazContrAss', isError: false}
           ];
  }
    
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  isRequiredRelaz = () => {
    return this.props.scopeAmleto;
  }

  isRequiredMotivoDesignazione = () => {
    return this.props.scopeAmleto;
  }

  isRequiredAltraRelazione = () => {
    const rel = this.props.datiRelazContr.relazContraente;
    return (rel === '10')
  }

  isDisabledRelaz = () => {
    return this.props.scopeAmleto === false;
  }

  isDisabledMotivoDesignazione = () => {
    return this.props.scopeAmleto === false;
  }

  isDisabledAltraRelazione = () => {
    const rel = this.props.datiRelazContr.relazContraente;
    return (rel !== '10')
  }

  render() {
    const { classes, assicuratoFisico, prodotto, dominio, contraenteGiuridico, flAssUgContr, datiRelazContr } = this.props;
    const { t } = this.props;   //hoc    
    const controlloDati = this.props.controlloDati;
    const isMounting = this.state.isMounting;
    let isRadioAssContrReadonly = false;
    const isContrGiur = isContraenteGiuridico(contraenteGiuridico);
    if (isContrGiur) {
    	isRadioAssContrReadonly = true;
    }
    
    return (
      <Fragment>
        <Grid container direction='row' justify='center' alignItems='flex-start'>	
          <Grid item xs={12}>
            <RadioButtonComp onChange={this.handleChangeAssicuratoUgualeContraente} radioComp={radioButtonAssicuratoUgualeContraente} 
                             selectedValue={assicuratoFisico.flAssicuratoUgualeContraente} 
                             required={true} readonly={isRadioAssContrReadonly} controlloDati={controlloDati} 
                             id='assicuratoUgualeContraente' onError={this.handleError} />
          </Grid>
        </Grid>
        {assicuratoFisico.flAssicuratoUgualeContraente === 'N' && 
          <Fragment>
            <DatiGeneraliFisico figura={ASSICURATO_FISICO} datiGeneraliAssicuratoFisico={assicuratoFisico.datiGenerali} labelFieldSet='datiGenerali' 
                                id={'datiGeneraliAssicurato'} onError={this.handleError} />   		
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>	
              {/* <Grid item md={8} xs={12}>
                <SelectComp onChange={this.handleChangeAttPrevalSvolta} options={dominio.prevalenteAttivitaSvoltaItems} ricerca='comboRicerca' etichetta='prevalAttSvolta' 
                            value={{value: assicuratoFisico.codAttPrevalSvolta, label: assicuratoFisico.descrAttPrevalSvolta}} required={true} 
                            id='codAttPrevalSvolta' onError={this.handleError} controlloDati={controlloDati} disabled={false} />
              </Grid> */}
              {/* DATI RELAZIONE */}
            <Grid item md={6} xs={12}>
              <SelectComp onChange={this.handleChangeRelazione} options={dominio.relazioneItems} etichetta='relazContrAss' id='relazContrAss'
                          value={{value: datiRelazContr.relazContraente, label: datiRelazContr.descrRelazContraente}} onError={this.handleError} 
                          controlloDati={controlloDati} disabled={this.isDisabledRelaz()}  required={this.isRequiredRelaz()} />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextFieldComp onBlur={this.handleChangeAltraRelazione} label='general.altro' id='assicuratoAltraRelazione' onError={this.handleError}
                             required={this.isRequiredAltraRelazione()} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
                             disabled={this.isDisabledAltraRelazione()} value={datiRelazContr.altro} controlloDati={controlloDati} />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextFieldComp onBlur={this.handleChangeMotivoDesignazione} label='motivoDesignazione' id='motivoDesignazione' onError={this.handleError}
                             required={this.isRequiredMotivoDesignazione()} aStyle={classes.textFieldWidthPerc} aStyleError={classes.textFieldWidthPercError} 
                             disabled={this.isDisabledMotivoDesignazione()} value={datiRelazContr.motivoDesignazione} controlloDati={controlloDati} />
            </Grid>

            </Grid>
            <DatiResidenza figura={ASSICURATO_FISICO} datiResidenzaAssicuratoFisico={assicuratoFisico.datiResidenza} labelFieldSet='datiDiResidenza'
                           id={'datiResidenzaAssicurato'} onError={this.handleError} />		    
            {/*2020-12-01 PFD: SAV 'TO BE' - ELIMINATA l'INTERA SEZIONE DATI ATTIVITA ECONOMICA
            <DatiAttivitaEconomica figura={ASSICURATO_FISICO} datiAttEconomicaAssicuratoFisico={assicuratoFisico.datiAttEconomica} labelFieldSet='datiAttivitaEconomica' 
                                   id={'datiAttivitaEconomica'} onError={this.handleError} />              
            */}
            <DatiAntiriciclaggio figura={ASSICURATO_FISICO} datiAntiriciclaggio={assicuratoFisico.datiAntiriciclaggio} labelFieldSet='datiAntiriciclaggio' 
                                 id={'datiAntiriciclaggioAssicurato'} onError={this.handleError} />
          </Fragment>
	      }     
        <Grid container direction='row' justify='center' alignItems='flex-start'>
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center' >
              <Grid item>
                {(this.state.isPresenteErrore === true) && (assicuratoFisico.controlloDati === true) && isMounting === false &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(this.state.isPresenteErrore === false) && (assicuratoFisico.controlloDati === true) && isMounting === false &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          	<Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>	
          		<Grid item>              
		            <ButtonComp
		              aClassName={classes.button}
		              buttonLabel={'conferma'}
		              onClick={this.handleChangeConfermaAssicurato}
		            />
		        </Grid>
		    </Grid>
		  </Grid>
        </Grid>        
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########AssicuratoFisicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########AssicuratoFisicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########AssicuratoFisicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
    console.log('RR########AssicuratoFisicoAss componentDidUpdate prevProps.assicuratoFisico', prevProps.assicuratoFisico);
    console.log('RR########AssicuratoFisicoAss componentDidUpdate this.props.assicuratoFisico', this.props.assicuratoFisico);
    console.log('RR########AssicuratoFisicoAss componentDidUpdate prevState.assicuratoFisico', prevState.assicuratoFisico);      
    console.log('RR########AssicuratoFisicoAss componentDidUpdate this.state.assicuratoFisico', this.state.assicuratoFisico);	 
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore && (this.props.controlloDati !== '')) {   
/*	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && 
        (prevProps.assicuratoFisico.flAssicuratoUgualeContraente === this.props.assicuratoFisico.flAssicuratoUgualeContraente)) { */   
      this.props.updateAssicuratoFisico(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_ASSICURATO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }

  isMounting(prevProps, prevState) {
	if(this.state.isMounting===true){
		this.setState({
			isMounting: false,
		});
	}  
  }
  
  isConferma(prevProps, prevState) {
	if(this.state.isConferma===true){
		this.setState({
			isConferma: false, // reset click conferma
		});
	}  
  }
	  
  isFlAssUgContr(prevProps, prevState) {
	 const flAssUgContrPrev = prevState.flAssUgContr;
	 const flAssUgContrCurr = this.props.flAssUgContr;	 
	 if (flAssUgContrCurr !== flAssUgContrPrev) {
		 
		 if (textTrim(flAssUgContrCurr,true)!=='' && this.props.assicuratoFisico.flAssicuratoUgualeContraente !== 'N') {
			 this.props.updateDatiGeneraliAssicurato(flAssUgContrCurr, actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE);
		 }
		 
		 this.setState({
			 flAssUgContr: flAssUgContrCurr,
		 });
	 }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
    this.isFlAssUgContr(prevProps, prevState);
    this.isConferma(prevProps, prevState);
	this.isMounting(prevProps, prevState);
  };
  
  /* 
  componentDidMount() {	  
	const isContrGiuridico = isContraenteGiuridico(this.props.contraenteGiuridico);
	if (isContrGiuridico) {
		this.props.updateDatiGeneraliAssicurato('N', actionType.UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE);
	}
  }
  */
}

AssicuratoFisicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  assicuratoFisico: PropTypes.object.isRequired,   
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(AssicuratoFisicoAss)));
