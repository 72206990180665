/* TITOLARE EFFETTIVO */
export const SALVA_TITOLARE_EFF 						= 'SALVA_TITOLARE_EFF';
export const SALVA_TITOLARE_EFF_SUCCESS 				= 'SALVA_TITOLARE_EFF_SUCCESS';
export const UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF 		= 'UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF';
export const UPDATE_ERRORE_FIDUCIANTE_TE_CONTRAENTE     = 'UPDATE_ERRORE_FIDUCIANTE_TE_CONTRAENTE';

export const UPDATE_TITOLARE_EFF   						= 'UPDATE_TITOLARE_EFF';
export const CONFERMA_AGGIUNGI_TITOLARE_EFF 			= 'CONFERMA_AGGIUNGI_TITOLARE_EFF';
export const NUOVO_TITOLARE_EFF 						= 'NUOVO_TITOLARE_EFF';

/* DATI GENERALI TITOLARE EFFETTIVO */
export const UPDATE_CODICE_FISCALE_TITOLARE_EFF		    = 'UPDATE_CODICE_FISCALE_TITOLARE_EFF';
export const UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF 	= 'UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF';
export const UPDATE_NOME_TITOLARE_EFF                  	= 'UPDATE_NOME_TITOLARE_EFF';
export const UPDATE_COGNOME_TITOLARE_EFF           		= 'UPDATE_COGNOME_TITOLARE_EFF';
export const UPDATE_SESSO_TITOLARE_EFF        	    	= 'UPDATE_SESSO_TITOLARE_EFF';
export const UPDATE_DATA_NASCITA_TITOLARE_EFF   	   	= 'UPDATE_DATA_NASCITA_TITOLARE_EFF';
export const UPDATE_STATO_NASCITA_TITOLARE_EFF     		= 'UPDATE_STATO_NASCITA_TITOLARE_EFF';
export const UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF 		= 'UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF';
export const UPDATE_COMUNE_NASCITA_TITOLARE_EFF    		= 'UPDATE_COMUNE_NASCITA_TITOLARE_EFF';
export const UPDATE_CITTADINANZA_1_TITOLARE_EFF    		= 'UPDATE_CITTADINANZA_1_TITOLARE_EFF';
export const UPDATE_CITTADINANZA_2_TITOLARE_EFF    		= 'UPDATE_CITTADINANZA_2_TITOLARE_EFF';
export const UPDATE_NUM_TELEFONO_TITOLARE_EFF      		= 'UPDATE_NUM_TELEFONO_TITOLARE_EFF';
export const UPDATE_RELAZ_ALTRO                         = 'UPDATE_RELAZ_ALTRO';

/* DATI RESIDENZA TITOLARE EFFETTIVO */
export const UPDATE_STATO_RESIDENZA_TITOLARE_EFF         = 'UPDATE_STATO_RESIDENZA_TITOLARE_EFF';
export const UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF     = 'UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF';
export const UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF        = 'UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF';
export const UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF      = 'UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF';
export const UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF     = 'UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_TITOLARE_EFF = 'UPDATE_NUMERO_CIVICO_RESIDENZA_TITOLARE_EFF';
export const UPDATE_CAP_RESIDENZA_TITOLARE_EFF           = 'UPDATE_CAP_RESIDENZA_TITOLARE_EFF';

/* DATI RECAPITO TITOLARE EFFETTIVO */
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO  = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO';
export const UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO              = 'UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO';
export const UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO          = 'UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO';
export const UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO             = 'UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO';
export const UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO           = 'UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO';
export const UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO          = 'UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO';
export const UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO       = 'UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO';
export const UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO                = 'UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO';

/* DATI ANTIRICICLAGGIO  */
export const UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF           = 'UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF';
export const UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF         = 'UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF';
export const UPDATE_ENTE_RILASCIO_TITOLARE_EFF            = 'UPDATE_ENTE_RILASCIO_TITOLARE_EFF';
export const UPDATE_DATA_RILASCIO_TITOLARE_EFF            = 'UPDATE_DATA_RILASCIO_TITOLARE_EFF';
export const UPDATE_DATA_SCADENZA_TITOLARE_EFF            = 'UPDATE_DATA_SCADENZA_TITOLARE_EFF';
export const UPDATE_STATO_RILASCIO_TITOLARE_EFF           = 'UPDATE_STATO_RILASCIO_TITOLARE_EFF';
export const UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF       = 'UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF';
export const UPDATE_COMUNE_RILASCIO_TITOLARE_EFF          = 'UPDATE_COMUNE_RILASCIO_TITOLARE_EFF';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF';
export const UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF         = 'UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF';
export const UPDATE_CARICA_PEP_TITOLARE_EFF               = 'UPDATE_CARICA_PEP_TITOLARE_EFF';
export const UPDATE_CARICA_PUBBLICA_NO_PEP_TITOLARE_EFF   = 'UPDATE_CARICA_PUBBLICA_NO_PEP_TITOLARE_EFF';
export const UPDATE_TITOLARE_EFF_QUALIFICA                = 'UPDATE_TITOLARE_EFF_QUALIFICA';
export const UPDATE_ALTRO_QUALIFICA                       = 'UPDATE_ALTRO_QUALIFICA';

/* DATI FISCALI FISICO / GIURIDICO / TITOLARE EFFETTIVO */
export const UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF              = 'UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF';
export const UPDATE_TIN_GIIN_TITOLARE_EFF                       = 'UPDATE_TIN_GIIN_TITOLARE_EFF';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF';
export const UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF      = 'UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF';
export const UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF        = 'UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF';
export const UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF      = 'UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF';
export const UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF        = 'UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF';

/* DATI FISCALI TITOLARE EFFETTIVO */
export const UPDATE_PERC_OWNERSHIP_TITOLARE_EFF    				= 'UPDATE_PERC_OWNERSHIP_TITOLARE_EFF';
export const UPDATE_PERCENTUALE_OWNERSHIP_TOTALE    			= 'UPDATE_PERCENTUALE_OWNERSHIP_TOTALE';

//TABELLA DI RIEPILOGO TYPES
export const CARICA_TITOLARE_EFF								= 'CARICA_TITOLARE_EFF';
export const DELETE_TITOLARE_EFF 								= 'DELETE_TITOLARE_EFF';

/* RESET SEZIONI */
export const UPDATE_DATI_GENERALI_PER_TITOLARE_EFFETTIVO        = 'UPDATE_DATI_GENERALI_PER_TITOLARE_EFFETTIVO';
export const UPDATE_DATI_RESIDENZA_PER_TITOLARE_EFFETTIVO       = 'UPDATE_DATI_RESIDENZA_PER_TITOLARE_EFFETTIVO';
export const UPDATE_DATI_ANTIRICICLAGGIO_PER_TITOLARE_EFFETTIVO = 'UPDATE_DATI_ANTIRICICLAGGIO_PER_TITOLARE_EFFETTIVO';

/* DATI RELAZIONE TITOLARE EFFETTIVO E CONTRAENTE */
export const UPDATE_RELAZIONE_CONTRAENTE_TITOLARE               = 'UPDATE_RELAZIONE_CONTRAENTE_TITOLARE';
export const UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA   = 'UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE  = 'UPDATE_ALTRO_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE';


/* DATI RECAPITO */
export const UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO                           = 'UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO                       = 'UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO                          = 'UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO                        = 'UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO                       = 'UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO                   = 'UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO                             = 'UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO';
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF     ='UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF            = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF';
export const UPDATE_PRESSO_TITOLARE_EFFETTIVO                                   = 'UPDATE_PRESSO_TITOLARE_EFFETTIVO';            

/* DATI ATTIVITA ECONOMICA */
export const UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_EFFETTIVO        = 'UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_EFFETTIVO';
export const UPDATE_STATO_ATT_PREVAL_TITOLARE_EFFETTIVO         = 'UPDATE_STATO_ATT_PREVAL_TITOLARE_EFFETTIVO';
export const UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_EFFETTIVO     = 'UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_EFFETTIVO';
export const UPDATE_COMUNE_ATT_PREVAL_TITOLARE_EFFETTIVO        = 'UPDATE_COMUNE_ATT_PREVAL_TITOLARE_EFFETTIVO';
export const UPDATE_ATTIVITA_PRECEDENTE_TITOLARE_EFFETTIVO      = 'UPDATE_ATTIVITA_PRECEDENTE_TITOLARE_EFFETTIVO';
export const UPDATE_AMBITO_ATTIVITA_TITOLARE_EFFETTIVO          = 'UPDATE_AMBITO_ATTIVITA_TITOLARE_EFFETTIVO';


/* TITOLARE EFFETTIVO BENEFICIARIO MORTE */
export const UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE                        = 'UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_MORTE';
export const UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE               = 'UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_MORTE';
export const UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_MORTE                      = 'UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_MORTE';
export const UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_MORTE                         = 'UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_MORTE';
export const UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_MORTE                 = 'UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_MORTE';
export const UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_MORTE                      = 'UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_MORTE                  = 'UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE                     = 'UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_MORTE';
export const CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_MORTE                         = 'CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_MORTE';
export const NUOVO_TITOLARE_EFF_BENEF_MORTE                                     = 'NUOVO_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_TITOLARE_EFF_BENEF_MORTE                                    = 'UPDATE_TITOLARE_EFF_BENEF_MORTE';
export const SALVA_TITOLARE_EFF_BENEF_MORTE                                     = 'SALVA_TITOLARE_EFF_BENEF_MORTE';
export const SALVA_TITOLARE_EFF_BENEF_MORTE_SUCCESS                             = 'SALVA_TITOLARE_EFF_BENEF_MORTE_SUCCESS';
export const UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_MORTE                  = 'UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_MORTE              = 'UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_EMAIL_TITOLARE_EFF_BENEF_MORTE                              = 'UPDATE_EMAIL_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE                        = 'UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_MORTE';
export const CARICA_TITOLARE_EFF_BENEF_MORTE					                = 'CARICA_TITOLARE_EFF_BENEF_MORTE';
export const DELETE_TITOLARE_EFF_BENEF_MORTE					                = 'DELETE_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_MORTE                    = 'UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_MORTE                      = 'UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_MORTE';
export const UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_MORTE                         = 'UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_MORTE';
export const UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_MORTE                       = 'UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_MORTE';
export const UPDATE_CAP_RESIDENZA_TITOLARE_EFF_MORTE                            = 'UPDATE_CAP_RESIDENZA_TITOLARE_EFF_MORTE';
export const UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_MORTE                      = 'UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_MORTE';
export const UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_MORTE                      = 'UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_MORTE';
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_MORTE        = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE                    = 'UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_MORTE                = 'UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_MORTE                   = 'UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE                 = 'UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE                = 'UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_MORTE             = 'UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_MORTE                      = 'UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE            = 'UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_MORTE                     = 'UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_MORTE= 'UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_MORTE';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_MORTE      = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_MORTE';
export const UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_MORTE                 = 'UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_MORTE                    = 'UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_MORTE                  = 'UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_MORTE                 = 'UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_MORTE             = 'UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_MORTE                       = 'UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_PRESSO_TITOLARE_EFFETTIVO_MORTE                             = 'UPDATE_PRESSO_TITOLARE_EFFETTIVO_MORTE';
export const UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_MORTE                           = 'UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_MORTE';
export const UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_MORTE                         = 'UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_MORTE';
export const UPDATE_ENTE_RILASCIO_TITOLARE_EFF_MORTE                            = 'UPDATE_ENTE_RILASCIO_TITOLARE_EFF_MORTE';
export const UPDATE_DATA_RILASCIO_TITOLARE_EFF_MORTE                            = 'UPDATE_DATA_RILASCIO_TITOLARE_EFF_MORTE';
export const UPDATE_DATA_SCADENZA_TITOLARE_EFF_MORTE                            = 'UPDATE_DATA_SCADENZA_TITOLARE_EFF_MORTE';
export const UPDATE_STATO_RILASCIO_TITOLARE_EFF_MORTE                           = 'UPDATE_STATO_RILASCIO_TITOLARE_EFF_MORTE';
export const UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_MORTE                       = 'UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_MORTE';
export const UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_MORTE                          = 'UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_MORTE';
export const UPDATE_TITOLARE_EFF_QUALIFICA_MORTE                                = 'UPDATE_TITOLARE_EFF_QUALIFICA_MORTE';
export const UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_MORTE                           = 'UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_MORTE';
export const UPDATE_ALTRO_QUALIFICA_MORTE                                       = 'UPDATE_ALTRO_QUALIFICA_MORTE';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_MORTE                 = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_MORTE';
export const UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_MORTE                         = 'UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_MORTE';
export const UPDATE_CARICA_PEP_TITOLARE_EFF_MORTE                               = 'UPDATE_CARICA_PEP_TITOLARE_EFF_MORTE';
export const UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_MORTE                       = 'UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_MORTE';
export const UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_MORTE                   = 'UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_MORTE';
export const UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_MORTE                      = 'UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_MORTE';


/* TITOLARE EFFETTIVO BENEFICIARIO VITA */
export const SALVA_TITOLARE_EFF_BENEF_VITA                                          = 'SALVA_TITOLARE_EFF_BENEF_VITA';
export const SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS                                  = 'SALVA_TITOLARE_EFF_BENEF_VITA_SUCCESS';
export const UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA                       = 'UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF_BENEF_VITA';
export const CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA                              = 'CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA';
export const NUOVO_TITOLARE_EFF_BENEF_VITA                                          = 'NUOVO_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_TITOLARE_EFF_BENEF_VITA                                         = 'UPDATE_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA                             = 'UPDATE_SESSO_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA                    = 'UPDATE_CODICE_FISCALE_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA                           = 'UPDATE_COGNOME_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA                              = 'UPDATE_NOME_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA                      = 'UPDATE_DATA_NASCITA_TITOLARE_EFFETTIVO_BENEF_VITA';
export const UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA                           = 'UPDATE_STATO_NASCITA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA                       = 'UPDATE_PROVINCIA_NASCITA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA                          = 'UPDATE_COMUNE_NASCITA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA                   = 'UPDATE_CODICE_FISCALE_VALIDO_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA                             = 'UPDATE_RELAZ_CONTR_TITOLARE_EFF_BENEF_VITA';
export const CARICA_TITOLARE_EFF_BENEF_VITA					                        = 'CARICA_TITOLARE_EFF_BENEF_VITA';
export const DELETE_TITOLARE_EFF_BENEF_VITA			                                = 'DELETE_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA                         = 'UPDATE_STATO_RESIDENZA_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA                           = 'UPDATE_PROVINCIA_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA                              = 'UPDATE_COMUNE_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA                            = 'UPDATE_TOPONIMO_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA                  = 'UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA                                 = 'UPDATE_CAP_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA                           = 'UPDATE_INDIRIZZO_RESIDENZA_TITOLARE_EFF_VITA';
export const UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA                           = 'UPDATE_ATT_PREVAL_SVOLTA_TITOLARE_BENEF_VITA';
export const UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA             = 'UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                         = 'UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA                     = 'UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA                        = 'UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                      = 'UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                     = 'UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA                           = 'UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA                 = 'UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA                          = 'UPDATE_STATO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA    = 'UPDATE_CORRISPONDENZA_DIVERSO_RESIDENZA_ESECUTORE_TITOLARE_EFF_VITA';
export const UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA           = 'UPDATE_CORRISPONDENZA_INTESTATO_CONTRAENTE_TITOLARE_EFF_VITA';
export const UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA                      = 'UPDATE_PROVINCIA_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA                         = 'UPDATE_COMUNE_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA                       = 'UPDATE_TOPONIMO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA                      = 'UPDATE_INDIRIZZO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA                  = 'UPDATE_NUMERO_CIVICO_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA                            = 'UPDATE_CAP_RECAPITO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA                                  = 'UPDATE_PRESSO_TITOLARE_EFFETTIVO_VITA';
export const UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA                                = 'UPDATE_TIPO_DOCUMENTO_TITOLARE_EFF_VITA';
export const UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA                              = 'UPDATE_NUMERO_DOCUMENTO_TITOLARE_EFF_VITA';
export const UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA                                 = 'UPDATE_ENTE_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA                                 = 'UPDATE_DATA_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA                                 = 'UPDATE_DATA_SCADENZA_TITOLARE_EFF_VITA';
export const UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA                                = 'UPDATE_STATO_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA                            = 'UPDATE_PROVINCIA_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA                               = 'UPDATE_COMUNE_RILASCIO_TITOLARE_EFF_VITA';
export const UPDATE_TITOLARE_EFF_QUALIFICA_VITA                                     = 'UPDATE_TITOLARE_EFF_QUALIFICA_VITA';
export const UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA                                = 'UPDATE_PERC_OWNERSHIP_TITOLARE_EFF_VITA';
export const UPDATE_ALTRO_QUALIFICA_VITA                                            = 'UPDATE_ALTRO_QUALIFICA_VITA';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA                      = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_TITOLARE_EFF_VITA';
export const UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA                              = 'UPDATE_FL_PEP_IN_CARICA_TITOLARE_EFF_VITA';
export const UPDATE_CARICA_PEP_TITOLARE_EFF_VITA                                    = 'UPDATE_CARICA_PEP_TITOLARE_EFF_VITA';
export const UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA                            = 'UPDATE_STATO_ATT_PREVAL_TITOLARE_BENEF_VITA';
export const UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA                        = 'UPDATE_PROVINCIA_ATT_PREVAL_TITOLARE_BENEF_VITA';
export const UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA                           = 'UPDATE_COMUNE_ATT_PREVAL_TITOLARE_BENEF_VITA';

/*  DATI FISCALI TITOLARE EFF BENEF MORTE */

export const UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_MORTE                           = 'UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_MORTE                  = 'UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE          = 'UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE            = 'UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE          = 'UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE            = 'UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_MORTE';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_MORTE     = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_MORTE';

/*  DATI FISCALI TITOLARE EFF BENEF VITA  */
export const UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA                            = 'UPDATE_TIN_GIIN_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_VITA                   = 'UPDATE_TASSE_STATI_UNITI_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA           = 'UPDATE_STATO_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA             = 'UPDATE_NIF_RESIDENZA_FISCALE_1_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA           = 'UPDATE_STATO_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA             = 'UPDATE_NIF_RESIDENZA_FISCALE_2_TITOLARE_EFF_BENEF_VITA';
export const UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_VITA      = 'UPDATE_STATO_RESIDENZA_FISCALE_ESTERA_TITOLARE_EFF_BENEF_VITA';
