import * as actionType from '../actionType/prodotto';
import * as actionTypeGeneric from '../../generic/actionType/generic';
import initialState from '../../../session/InitialState';

const areaProdottoReducer = (state = initialState, action) => {
	
  switch (action.type) {
  case actionTypeGeneric.INIZIALIZZA:   	  
      return {
        ...state,
        prodotto: {},
      };   
  case actionType.SELEZIONA_PRODOTTO:	 
    return {
  	  ...state,
   	  area: action.area,
   	  prodotto: action.prodotto,
    };
/*  case actionType.REQUEST_LOGIN:
    return { 
      ...state, 
      loading: true 
    };
  case actionType.RECEIVED_LOGIN:
    const rc = {
      ...state,
      areaProdotto: action.areaProdotto,
      loading: false
    };
    return rc;*/
  case actionType.SELEZIONA_UTENTE:      
    return {
      ...state,
      loginUser: action.utente,
    };
  default:
    return state;
  }
}

export default areaProdottoReducer;