export const datiStampaProposalInizializzato = {
  controlloDati: '',
  confermaStampaProposal: false,
  mailInviata: false,
};

export const datiInvioEmailInizializzato = {
  indEmailTo: [],
  indEmailCc: [],
  idstampa: '',
  prodotto: '',
  nominativo: '', // cognome/ragioneSociale
  nome: '',
};

