import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import DatiGeneraliFisico from '../sezione/DatiGeneraliFisico';
import DatiResidenza from '../sezione/DatiResidenza';
import DatiRecapito from '../sezione/DatiRecapito';
import DatiConsensoComunicazioneElettronica from '../sezione/DatiConsensoComunicazioneElettronica';
import DatiAttivitaEconomica from '../sezione/DatiAttivitaEconomica';
import DatiSituazioneFinanziaria from '../sezione/DatiSituazioneFinanziaria';
import DatiFiscali from '../sezione/DatiFiscali';
import DatiAntiriciclaggio from '../sezione/DatiAntiriciclaggio';
import DatiEsecutore from '../sezione/DatiEsecutore';
import DatiNaturaRapporto from '../sezione/DatiNaturaRapporto';

import * as actions from './action/contraente';
import * as actionType from './actionType/contraente';
import ButtonComp from '../../common/ButtonComp';

import { CONTRAENTE_FISICO } from '../../../utility/jsConstants';
import DatiDomicilio from '../sezione/DatiDomicilio';
import moment from 'moment';
import { fetchParam, uris } from '../../../uris';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});
	
const mapStateToProps = state => {
  console.log('#RTCFA ContraenteFisicoAss.mapStateToProps.state', state);
  return {
    contraenteFisico: state.areaContraenteReducer.contraenteFisico,
    controlloDati: state.areaContraenteReducer.contraenteFisico.controlloDati,
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA ContraenteFisicoAss.mapDispatchToProps.dispatch', dispatch);
  return {
    updateContraenteFisico: (unDato, anActionType) => dispatch(actions.updateContraenteFisico(unDato, anActionType)),    
    inizializzaContraente: (anActionType) => dispatch(actions.inizializzaContraente(anActionType)),    
  };
};
	
class ContraenteFisicoAss extends React.Component {
	
  constructor(props) {
    super(props);
    console.log('#RTCFA ContraenteFisicoAss constructor props', this.props);
    this.state = {
      contraenteFisico: this.props.contraenteFisico,	
      statoResidenza: '',
//      controlloCodiceFiscaleContraente: '',
//      controlloCodiceFiscaleEsecutore: '',
      patrimonioMassimo: '',
      errorRipartizione: false,
      errorPensione: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
      isMounting: false,
      isConferma: false,
    }   
  };
  
  handleChangeConfermaContraente = event => {
    console.log('#RTCFA ContraenteFisicoAss handleChangeConfermaContraente', event.target.value);
    this.verificaPatrimonio();
    this.props.updateContraenteFisico(true, actionType.SALVA_CONTRAENTE_FISICO);  
    this.setState({
      isConferma: true,
    });	
  }  
  
  handleChangeInizializzaContraente = event => {
    this.props.inizializzaContraente(actionType.INIZIALIZZA_CONTRAENTE_FISICO);    
    this.setState({
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,           
    });
  }  

  handleChangeStatoResidenzaPerSae = event => {
    this.setState({
      statoResidenza: (event === null) ? '' : event.value,
    });	
  } 
 
  initStatoCampi = () => {
    return [ {id: 'datiGeneraliContraente', isError: false},
             {id: 'datiResidenzaContraente', isError: false},  
             {id: 'datiDiDomicilioContrFis', isError: false},
             {id: 'datiRecapitoContraente', isError: false},  
             {id: 'datiConsensoComunicazioneElettronicaContraente', isError: false},
             {id: 'datiAttivitaEconomicaContraente', isError: false},
             {id: 'datiFiscaliContraente', isError: false},  
             {id: 'datiAntiriciclaggioContraente', isError: false},               
             {id: 'datiNaturaRapportoContraente', isError: false},
             {id: 'datiEsecutoreContraente', isError: false}, 
             {id: 'datiSituazioneFinanziariaContraente', isError: false}                  
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  // TODO: Spostare da qualche altra parte, non sul Conferma
  verificaPatrimonio = () => {
    const datiFinanziari = this.props.contraenteFisico.datiFinanziari;
    const ripartizione = datiFinanziari.ripartizioneInvestimentiFinanziari;
    let tot = 0;
    // somma gli strumenti finanziari
    for(var key in ripartizione) {
      if(ripartizione.hasOwnProperty(key) && ripartizione[key]) {
          tot = tot + parseInt(ripartizione[key])
      }
    }
    // chiede al servizio qual è il patrimonio massimo possibile
    const opts = {
      siglaVersione: 'ZURI',
      codiceRegola: 'CONTR_X_PFIF',
      codPatrimonioFinanziario: datiFinanziari.codPatrimonioFinanziario,
      dataRichiesta: moment().format("DD/MM/YYYY")
    }
    fetch(uris.selectPatrimonioFinanziarioMassimo, fetchParam(opts))
    .then(
      response => response.json(),
      error => console.error("An error occured...", error)
    )
    .then((result) => {
        const patrimonioMassimo = result;
        if(patrimonioMassimo && tot <= patrimonioMassimo) {
          console.log("RIPARTIZIONE OK");
          this.setState({
            errorRipartizione: false,
          })
        } else {
          console.log("RIPARTIZIONE KO");
          this.setState({
            errorRipartizione: true,
            isPresenteErrore: true
          })
        }
    });
  }

  checkErrorPensione = errorPensione => {
    this.setState({
      errorPensione: errorPensione
    })
  }

  render() {  
    const { classes, contraenteFisico, dominio } = this.props;
    const { t } = this.props;   //hoc
    const controlloDati = this.props.controlloDati;
    const isMounting = this.state.isMounting;
    
    return (
      <Fragment>
        <DatiGeneraliFisico figura={CONTRAENTE_FISICO} datiGeneraliContraenteFisico={contraenteFisico.datiGenerali} labelFieldSet='datiGenerali' 
        	                id={'datiGeneraliContraente'} onError={this.handleError} /> 
        <DatiResidenza figura={CONTRAENTE_FISICO} datiResidenzaContraenteFisico={contraenteFisico.datiResidenza} labelFieldSet='datiDiResidenza' 
        	           onChangeStatoResidenza={this.handleChangeStatoResidenzaPerSae} id={'datiResidenzaContraente'} onError={this.handleError} />
        <DatiDomicilio figura={CONTRAENTE_FISICO} datiDomicilioContrFis={contraenteFisico.datiDomicilio} labelFieldSet='datiDiDomicilioContrFis' 
                       id={'datiDiDomicilioContrFis'} onError={this.handleError} />
        <DatiRecapito figura={CONTRAENTE_FISICO} datiRecapitoContraenteFisico={contraenteFisico.datiRecapito} labelFieldSet='indirizzoDiCorrispondenza' 
                      id={'datiRecapitoContraente'} onError={this.handleError} />
        <DatiConsensoComunicazioneElettronica figura={CONTRAENTE_FISICO} datiConsensoComElettr={contraenteFisico.datiConsensoComElettr} 
                                              labelFieldSet='datiConsensoComunicazioneElettronica' 
                                              id={'datiConsensoComunicazioneElettronicaContraente'} onError={this.handleError} /> 
        <DatiAttivitaEconomica figura={CONTRAENTE_FISICO} datiAttEconomica={contraenteFisico.datiAttEconomica} statoResidenza={this.state.statoResidenza}
                               labelFieldSet='datiAttivitaEconomica' id={'datiAttivitaEconomicaContraente'} onError={this.handleError} checkErrorPensione={this.checkErrorPensione} />  
        <DatiFiscali figura={CONTRAENTE_FISICO} datiFiscali={contraenteFisico.datiFiscali} labelFieldSet='datiFiscali' 
        	         id={'datiFiscaliContraente'} onError={this.handleError} />
        <DatiAntiriciclaggio figura={CONTRAENTE_FISICO} datiAntiriciclaggio={contraenteFisico.datiAntiriciclaggio} labelFieldSet='datiAntiriciclaggio' 
                             id={'datiAntiriciclaggioContraente'} onError={this.handleError} /> 
        <DatiNaturaRapporto figura={CONTRAENTE_FISICO} datiNaturaRapporto={contraenteFisico.datiNaturaRapporto} 
                            labelFieldSet='scopoNaturaRapportoOrigineFondi' id={'datiNaturaRapportoContraente'} onError={this.handleError} /> 
        <DatiSituazioneFinanziaria figura={CONTRAENTE_FISICO} datiFinanziari={contraenteFisico.datiFinanziari} labelFieldSet='datiSituazioneFinanziaria'
          id={'datiSituazioneFinanziariaContraente'} onError={this.handleError} />
        <DatiEsecutore datiEsecutore={contraenteFisico.datiEsecutore} labelFieldSet='esecutore' id={'datiEsecutoreContraente'}
          onError={this.handleError} />
        <Grid container direction='row' justify='center' alignItems='flex-start'>	
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='center'>   	
{/*              <Grid item >
                {this.props.contraenteFisico.controlloDati===true&&this.state.controlloCodiceFiscaleContraente==='KO'&&
                  <Typography className={classes.textErrorMessage}>{'CODICE FISCALE CONTRAENTE ERRATO'}</Typography>
                }
                {this.props.contraenteFisico.controlloDati===true&&this.state.controlloCodiceFiscaleEsecutore==='KO'&&
                  <Typography className={classes.textErrorMessage}>{'CODICE FISCALE ESECUTORE ERRATO'}</Typography>
                }
              </Grid>	    	*/}
              <Grid item>
                {(controlloDati === true) && (this.state.isPresenteErrore === true) && isMounting === false &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(controlloDati === true) && (this.state.isPresenteErrore === false) && isMounting === false &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
                {this.state.errorRipartizione && 
                  <Typography className={classes.textErrorMessage}>{t('ripartizioneNonValida')}</Typography>
                }
                {this.state.errorPensione && 
                  <Typography className={classes.textErrorMessage}>{t('errorPensione')}</Typography>
                }
              </Grid>
            </Grid>
          </Grid>   
          <Grid item xs={12}>
            <Grid container direction='row' justify='center' alignItems='flex-start' spacing={1}>	
              <Grid item>
                <ButtonComp
                  aClassName={classes.button}
                  buttonLabel={'conferma'}
                  onClick={this.handleChangeConfermaContraente}
                />
              </Grid>
              <Grid item>
                <ButtonComp
                  aClassName={classes.button}
                  buttonLabel={'cancellaDati'}
                  onClick={this.handleChangeInizializzaContraente}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
	);
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
    this.state.statoCampi.map(unoStatoCampo => {
        return (		
          isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError || this.state.errorRipartizione
        );
    });
    console.log('########ContraenteFisicoAss componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########ContraenteFisicoAss componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
    console.log('########ContraenteFisicoAss componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);	
//	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
    if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.controlloDati !== '')) {    		
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateContraenteFisico(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_CONTRAENTE_FISICO);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	  }
  }
	
  isMounting(prevProps, prevState) {
	if(this.state.isMounting===true){
		this.setState({
			isMounting: false,
		});
	}  
  }
  
  isConferma(prevProps, prevState) {
	if(this.state.isConferma===true){
		this.setState({
			isConferma: false, // reset click conferma
		});
	}  
  }
  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
    this.isConferma(prevProps, prevState);
	  this.isMounting(prevProps, prevState);
  }
}

ContraenteFisicoAss.propTypes = {
  classes: PropTypes.object.isRequired,
  contraenteFisico: PropTypes.object.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ContraenteFisicoAss)));
