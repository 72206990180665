import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import TitolareEffBenefVitaAss from '../../anagrafica/figure/TitolareEffBenefVitaAss.js';


const styles = theme => ({
    root: {
        width: '100%',
    },
});

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    console.log('#RTCFA TitolareEffBenefPanel.mapDispatchToProps.dispatch', dispatch);
    return {
    };
};



class TitolareEffBenefVitaPanel extends React.Component {
    constructor(props) {
        super(props);
    };

    render() {
        const { classes, isValida, isDisabled, isExpanded } = this.props;
        let key = '';
        if(isDisabled) {
            key = 'panelVita'
        }
        return (
            <div className={classes.root}>
                <ExpansionPanelComp titolo={'titolareEffBenefVita'} componente={TitolareEffBenefVitaAss} isValida={isValida} isDisabled={isDisabled} isExpanded={isExpanded} key={key} /> 
            </div>
        )
    }
}

TitolareEffBenefVitaPanel.propTypes = {
    classes: PropTypes.object.isRequired,
  };


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TitolareEffBenefVitaPanel));

