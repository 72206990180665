import * as actionType from '../actionType/datiModPagReimpiego';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiModPagReimpiegoInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiModPagReimpiegoReducer = (state = initialState, action) => {
	
  console.log('#RT2 Reducer areaDatiModPagReimpiegoReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaModPagReimpiego(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.UPDATE_POLIZZA:
      const caratteristicheContrattoPolizza = {...state}.caratteristicheContratto;
      const datiModPagReimpiegoPolizza = {...caratteristicheContrattoPolizza}.datiModPagReimpiego;
      datiModPagReimpiegoPolizza.polizza = action.unDato;
      caratteristicheContrattoPolizza.datiModPagReimpiego = {...datiModPagReimpiegoPolizza};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoPolizza,
      };   
    default:
      return state;
  }
}

export const inizializzaModPagReimpiego = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiModPagReimpiego = jsonCopy(datiModPagReimpiegoInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiModPagReimpiegoReducer;