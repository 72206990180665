import ContraenteFisicoAss from '../anagrafica/figure/ContraenteFisicoAss';
import ContraenteGiuridicoAss from '../anagrafica/figure/ContraenteGiuridicoAss';
import AssicuratoFisicoAss from '../anagrafica/figure/AssicuratoFisicoAss';
import BenefVitaFisicoAss from '../anagrafica/figure/BenefVitaFisicoAss';
import BenefVitaGiuridicoAss from '../anagrafica/figure/BenefVitaGiuridicoAss';
import BenefVitaGenericoAss from '../anagrafica/figure/BenefVitaGenericoAss';
import BenefMorteFisicoAss from '../anagrafica/figure/BenefMorteFisicoAss';
import BenefMorteGiuridicoAss from '../anagrafica/figure/BenefMorteGiuridicoAss';
import BenefMorteGenericoAss from '../anagrafica/figure/BenefMorteGenericoAss';

export const contraenteTabItems = [{
  ruolo: 'A',
  tipoPers: 'F',
  label: 'Persona Fisica',
  onChange: 'this.handleChangePersonaFisica',
  content: ContraenteFisicoAss,
}, {
  ruolo: 'A',
  tipoPers: 'G',
  label: 'Persona Giuridica',
  onChange: 'this.handleChangePersonaGiuridica',
  content: ContraenteGiuridicoAss,
}];

export const assicuratoTabItems = [{
  ruolo: 'B',
  tipoPers: 'F',
  label: 'Persona Fisica',
  onChange: 'this.handleChangePersonaFisica',
  content: AssicuratoFisicoAss,
}];

export const benefVitaTabItems = [{
  ruolo: 'V',
  tipoPers: 'F',
  label: 'Persona Fisica',
  onChange: 'this.handleChangePersonaFisica',
  content: BenefVitaFisicoAss,
}, {
  ruolo: 'V',
  tipoPers: 'G',
  label: 'Persona Giuridica',
  onChange: 'this.handleChangePersonaGiuridica',
  content: BenefVitaGiuridicoAss,
}, {
  ruolo: 'V',
  tipoPers: 'Q',
  label: 'Beneficiario Generico',
  onChange: 'this.handleChangeBeneficiarioGenerico',
  content: BenefVitaGenericoAss,
}];

export const benefMorteTabItems = [{
  ruolo: 'C',
  tipoPers: 'F',
  label: 'Persona Fisica',
  onChange: 'this.handleChangePersonaFisica',
  content: BenefMorteFisicoAss,
}, {
  ruolo: 'C',
  tipoPers: 'G',
  label: 'Persona Giuridica',
  onChange: 'this.handleChangePersonaGiuridica',
  content: BenefMorteGiuridicoAss,
}, {
  ruolo: 'C',
  tipoPers: 'Q',
  label: 'Beneficiario Generico',
  onChange: 'this.handleChangeBeneficiarioGenerico',
  content: BenefMorteGenericoAss,
}];

