import { datiGeneraliGiuridico, datiResidenza, datiFiscali, datiRecapito,
	     datiConsensoComElettr, datiAttEconomicaGiuridico, datiNaturaRapporto, datiSocietari, datiFinanziari, datiMandatoFiduciario } from './sezioni/sezioniAnagraficaContraenteGiuridico';
import { datiRappLegale } from './sezioni/sezioniAnagraficaRappLegale';


export const contraenteGiuridico = {
  controlloDati: '',
  anagraficaValida: null,
  flTitolariEff: '', 
  datiGenerali: datiGeneraliGiuridico,
  datiResidenza: datiResidenza,
  datiRecapito: datiRecapito,
  datiConsensoComElettr: datiConsensoComElettr,
  datiAttEconomicaGiuridico: datiAttEconomicaGiuridico,
  datiFiscali: datiFiscali,
  datiRappLegale: datiRappLegale,
  datiNaturaRapporto: datiNaturaRapporto,
  datiSocietari: datiSocietari,
  datiFinanziari: datiFinanziari,
  datiMandatoFiduciario: datiMandatoFiduciario
};