import * as actionType from '../actionType/datiModPagBonifico';

export const updateDatiModPagBonifico = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const salvaBancaAgenziaDaIban = (banca, descBanca, agenzia, descAgenzia) => {
  return {
    type: actionType.SALVA_BANCA_AGENZIA_DA_IBAN,
    banca,
    descBanca,
    agenzia,
    descAgenzia,
  }
};