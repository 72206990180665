import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import ReferenteTerzoFisicoAss from '../../anagrafica/figure/ReferenteTerzoFisicoAss';
//import {buttonAssicuratoPanelItems} from '../../store/buttonItems';

const styles = theme => ({
  root: {
    width: '100%',
  }
});

function ReferenteTerzoPanel(props) {
	
  const { classes, isValida } = props;
  return (
    <div className={classes.root}>      
      <ExpansionPanelComp titolo={'referenteTerzo'} componente={ReferenteTerzoFisicoAss}
      	isValida={isValida} />
    </div>
  );
}

ReferenteTerzoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReferenteTerzoPanel);
