import * as actionType from '../actionType/datiRicercaUtenza';
import initialState from '../../../../session/InitialState';

const areaDatiRicercaUtenzaReducer = (state = initialState, action) => {
	
  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;
	  
  switch (action.type) {
    case actionType.UPDATE_MANDATO_RICERCA:
      const gestioneUtenzaMandato = {...state}.gestioneUtenza;
      const datiRicercaUtenzaMandato = {...gestioneUtenzaMandato}.datiRicercaUtenza;
      datiRicercaUtenzaMandato.mandato = unValore;
      datiRicercaUtenzaMandato.descMandato = unaLabel;
      gestioneUtenzaMandato.datiRicercaUtenza = {...datiRicercaUtenzaMandato};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaMandato,
      };
    case actionType.UPDATE_COGNOME_RICERCA:
      const gestioneUtenzaCognome = {...state}.gestioneUtenza;
      const datiRicercaUtenzaCognome = {...gestioneUtenzaCognome}.datiRicercaUtenza;
      datiRicercaUtenzaCognome.cognome = action.unDato;
      gestioneUtenzaCognome.datiRicercaUtenza = {...datiRicercaUtenzaCognome};
      return {
      	...state,
      	gestioneUtenza: gestioneUtenzaCognome,
      };   
    case actionType.UPDATE_NOME_RICERCA:
      const gestioneUtenzaNome = {...state}.gestioneUtenza;
      const datiRicercaUtenzaNome = {...gestioneUtenzaNome}.datiRicercaUtenza;
      datiRicercaUtenzaNome.nome = action.unDato;
      gestioneUtenzaNome.datiRicercaUtenza = {...datiRicercaUtenzaNome};
      return {
    	...state,
    	gestioneUtenza: gestioneUtenzaNome,
      };
    case actionType.UPDATE_UTENTE_RICERCA:
        const gestioneUtenzaUtente = {...state}.gestioneUtenza;
        const datiRicercaUtenzaUtente = {...gestioneUtenzaUtente}.datiRicercaUtenza;
        datiRicercaUtenzaUtente.utente = action.unDato;
        gestioneUtenzaUtente.datiRicercaUtenza = {...datiRicercaUtenzaUtente};
        return {
      	...state,
      	gestioneUtenza: gestioneUtenzaUtente,
        };
    case actionType.UPDATE_PROFILO_RICERCA:
      const gestioneUtenzaProfilo = {...state}.gestioneUtenza;
      const datiRicercaUtenzaProfilo = {...gestioneUtenzaProfilo}.datiRicercaUtenza;
      datiRicercaUtenzaProfilo.profilo = unValore;
      datiRicercaUtenzaProfilo.descProfilo = unaLabel;
      gestioneUtenzaProfilo.datiRicercaUtenza = {...datiRicercaUtenzaProfilo};
      return {
        ...state,
      	gestioneUtenza: gestioneUtenzaProfilo,
      };
    case actionType.UPDATE_DATA_CREAZIONE_RICERCA:
      const gestioneUtenzaDataCreazione = {...state}.gestioneUtenza;
      const datiRicercaUtenzaDataCreazione = {...gestioneUtenzaDataCreazione}.datiRicercaUtenza;
      datiRicercaUtenzaDataCreazione.dataCreazione = action.unDato;
      gestioneUtenzaDataCreazione.datiRicercaUtenza = {...datiRicercaUtenzaDataCreazione};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataCreazione,
      };
    case actionType.UPDATE_DATA_SCADENZA_RICERCA:
      const gestioneUtenzaDataScadenza = {...state}.gestioneUtenza;
      const datiRicercaUtenzaDataScadenza = {...gestioneUtenzaDataScadenza}.datiRicercaUtenza;
      datiRicercaUtenzaDataScadenza.dataScadenza = action.unDato;
      gestioneUtenzaDataScadenza.datiRicercaUtenza = {...datiRicercaUtenzaDataScadenza};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataScadenza,
      };
    case actionType.UPDATE_DATA_ULTIMO_ACCESSO_RICERCA:
      const gestioneUtenzaDataUltimoAccesso = {...state}.gestioneUtenza;
      const datiRicercaUtenzaDataUltimoAccesso = {...gestioneUtenzaDataUltimoAccesso}.datiRicercaUtenza;
      datiRicercaUtenzaDataUltimoAccesso.dataUltimoAccesso = action.unDato;
      gestioneUtenzaDataUltimoAccesso.datiRicercaUtenza = {...datiRicercaUtenzaDataUltimoAccesso};
      return {
        ...state,
        gestioneUtenza: gestioneUtenzaDataUltimoAccesso,
      };
    case actionType.SELEZIONA_DETTAGLIO_UTENTE:
        const gestioneUtenzaSelezionaDettaglioUtente = {...state}.gestioneUtenza;
        const datiRicercaUtenzaSelezionaDettaglioUtente = {...gestioneUtenzaSelezionaDettaglioUtente}.datiRicercaUtenza;
        datiRicercaUtenzaSelezionaDettaglioUtente.dettaglioUtente = action.dettaglioUtente;
        gestioneUtenzaSelezionaDettaglioUtente.datiRicercaUtenza = {...datiRicercaUtenzaSelezionaDettaglioUtente};
        return {
      	...state,
      	gestioneUtenza: gestioneUtenzaSelezionaDettaglioUtente,
        };
    default:
      return state;
  }
}

export default areaDatiRicercaUtenzaReducer;