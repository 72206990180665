import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    width: '98%',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.color.white,
  },
  divider: {
    backgroundColor: theme.palette.color.marine,
    //paddingTop: theme.spacing(0.1),
    //paddingBottom: theme.spacing(0.2),
    //marginBottom: theme.spacing(0.1),
    //marginTop: theme.spacing(0.1),
  },
  titolo: {
    paddingLeft: theme.spacing(1),
  },
});

class DividerComp extends React.Component {
  constructor(props) {
	super(props);
  };  

  render() {
    const {classes, titolo, variant} = this.props; 
    const { t } = this.props; //hoc   
    
    return (
      <Grid container direction="row" justify="center" alignItems="center">
    	<List className={classes.root}>
	      <Divider className={classes.divider} />
	        <Typography className={classes.titolo} variant={variant}>{t(titolo)}</Typography>
	      <Divider className={classes.divider}/>
	    </List>
	  </Grid>
    )
  }
}

DividerComp.propTypes = {
  classes: PropTypes.object.isRequired,
};
	
export default withTranslation()(withStyles(styles)(DividerComp));
