/* ASSICURATO */
export const SALVA_ASSICURATO_FISICO             = 'SALVA_ASSICURATO_FISICO';
export const UPDATE_ANAGRAFICA_VALIDA_ASSICURATO = 'UPDATE_ANAGRAFICA_VALIDA_ASSICURATO';

/* DATI GENERALI ASSICURATO */
export const UPDATE_CODICE_FISCALE_ASSICURATO    = 'UPDATE_CODICE_FISCALE_ASSICURATO';
//export const UPDATE_CONTROLLO_CODICE_FISCALE_ASSICURATO    = 'UPDATE_CONTROLLO_CODICE_FISCALE_ASSICURATO';
export const UPDATE_CODICE_FISCALE_VALIDO_ASSICURATO = 'UPDATE_CODICE_FISCALE_VALIDO_ASSICURATO';
export const UPDATE_NOME_ASSICURATO              = 'UPDATE_NOME_ASSICURATO';
export const UPDATE_COGNOME_ASSICURATO           = 'UPDATE_COGNOME_ASSICURATO';
export const UPDATE_SESSO_ASSICURATO             = 'UPDATE_SESSO_ASSICURATO';
export const UPDATE_DATA_NASCITA_ASSICURATO      = 'UPDATE_DATA_NASCITA_ASSICURATO';
export const UPDATE_STATO_NASCITA_ASSICURATO     = 'UPDATE_STATO_NASCITA_ASSICURATO';
export const UPDATE_PROVINCIA_NASCITA_ASSICURATO = 'UPDATE_PROVINCIA_NASCITA_ASSICURATO';
export const UPDATE_COMUNE_NASCITA_ASSICURATO    = 'UPDATE_COMUNE_NASCITA_ASSICURATO';
export const UPDATE_CITTADINANZA_1_ASSICURATO    = 'UPDATE_CITTADINANZA_1_ASSICURATO';
export const UPDATE_CITTADINANZA_2_ASSICURATO    = 'UPDATE_CITTADINANZA_2_ASSICURATO';
export const UPDATE_NUM_TELEFONO_ASSICURATO      = 'UPDATE_NUM_TELEFONO_ASSICURATO';
export const UPDATE_ATTIVITA_PREV_ASSICURATO     = 'UPDATE_ATTIVITA_PREV_ASSICURATO';

/* DATI RESIDENZA ASSICURATO FISICO */
export const UPDATE_STATO_RESIDENZA_ASSICURATO_FISICO         = 'UPDATE_STATO_RESIDENZA_ASSICURATO_FISICO';
export const UPDATE_PROVINCIA_RESIDENZA_ASSICURATO_FISICO     = 'UPDATE_PROVINCIA_RESIDENZA_ASSICURATO_FISICO';
export const UPDATE_COMUNE_RESIDENZA_ASSICURATO_FISICO        = 'UPDATE_COMUNE_RESIDENZA_ASSICURATO_FISICO';
export const UPDATE_TOPONIMO_RESIDENZA_ASSICURATO_FISICO      = 'UPDATE_TOPONIMO_RESIDENZA_ASSICURATO_FISICO';
export const UPDATE_INDIRIZZO_RESIDENZA_ASSICURATO_FISICO     = 'UPDATE_INDIRIZZO_RESIDENZA_ASSICURATO_FISICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_ASSICURATO_FISICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_ASSICURATO_FISICO';
export const UPDATE_CAP_RESIDENZA_ASSICURATO_FISICO           = 'UPDATE_CAP_RESIDENZA_ASSICURATO_FISICO';

/* DATI ATTIVITA ECONOMICA ASSICURATO FISICO */
export const UPDATE_STATO_ATT_PREVAL_ASSICURATO_FISICO        = 'UPDATE_STATO_ATT_PREVAL_ASSICURATO_FISICO';
export const UPDATE_PROVINCIA_ATT_PREVAL_ASSICURATO_FISICO    = 'UPDATE_PROVINCIA_ATT_PREVAL_ASSICURATO_FISICO';
export const UPDATE_COMUNE_ATT_PREVAL_ASSICURATO_FISICO       = 'UPDATE_COMUNE_ATT_PREVAL_ASSICURATO_FISICO';

/* DATI ANTIRICICLAGGIO ASSICURATO FISICO */
export const UPDATE_TIPO_DOCUMENTO_ASSICURATO                   = 'UPDATE_TIPO_DOCUMENTO_ASSICURATO';
export const UPDATE_NUMERO_DOCUMENTO_ASSICURATO                 = 'UPDATE_NUMERO_DOCUMENTO_ASSICURATO';  
export const UPDATE_ENTE_RILASCIO_ASSICURATO                    = 'UPDATE_ENTE_RILASCIO_ASSICURATO';
export const UPDATE_DATA_RILASCIO_ASSICURATO                    = 'UPDATE_DATA_RILASCIO_ASSICURATO';
export const UPDATE_DATA_SCADENZA_ASSICURATO                    = 'UPDATE_DATA_SCADENZA_ASSICURATO';
export const UPDATE_STATO_RILASCIO_ASSICURATO                   = 'UPDATE_STATO_RILASCIO_ASSICURATO';
export const UPDATE_PROVINCIA_RILASCIO_ASSICURATO               = 'UPDATE_PROVINCIA_RILASCIO_ASSICURATO';
export const UPDATE_COMUNE_RILASCIO_ASSICURATO                  = 'UPDATE_COMUNE_RILASCIO_ASSICURATO';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_ASS                = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_ASS';
export const UPDATE_CARICA_PEP_ASSICURATO_FISICO                = 'UPDATE_CARICA_PEP_ASSICURATO_FISICO';
export const UPDATE_FL_PEP_IN_CARICA_ASSICURATO_FISICO          = 'UPDATE_FL_PEP_IN_CARICA_ASSICURATO_FISICO';
export const UPDATE_FL_ESPOSTO_POLITICAMENTE_ASSICURATO_FISICO  = 'UPDATE_FL_ESPOSTO_POLITICAMENTE_ASSICURATO_FISICO';
export const UPDATE_ATT_PREVAL_SVOLTA_ASSICURATO_FISICO         = 'UPDATE_ATT_PREVAL_SVOLTA_ASSICURATO_FISICO';
export const UPDATE_ATTIVITA_PRECEDENTE_ASSICURATO_FISICO       = 'UPDATE_ATTIVITA_PRECEDENTE_ASSICURATO_FISICO';

/* DATI RELAZIONE ASSICURATO FISICO */
export const UPDATE_RELAZIONE_CONTRAENTE_ASSICURATO             = 'UPDATE_RELAZIONE_CONTRAENTE_ASSICURATO';
export const UPDATE_ALTRA_RELAZIONE_CONTRAENTE_ASSICURATO       = 'UPDATE_ALTRA_RELAZIONE_CONTRAENTE_ASSICURATO';
export const UPDATE_MOTIVO_DESIGNAZIONE_ASSICURATO              = 'UPDATE_MOTIVO_DESIGNAZIONE_ASSICURATO'; 

//export const UPDATE_DATI_GENERALI_COPIA_FROM_CONTRAENTE = 'UPDATE_DATI_GENERALI_FROM_CONTRAENTE';
//export const UPDATE_DATI_GENERALI_COPIA_NUOVO_ASSICURATO = 'UPDATE_DATI_GENERALI_NUOVO_ASSICURATO';
export const UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE = 'UPDATE_DATI_GENERALI_ASSICURATO_UGUALE_CONTRAENTE';