import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import TitolareEffAss from '../../anagrafica/figure/TitolareEffAss.js'; 

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const mapStateToProps = state => {
  return {	 
  };
};

const mapDispatchToProps = dispatch => {
  console.log('#RTCFA TitolareEffPanel.mapDispatchToProps.dispatch', dispatch);
  return {
  };
};

class TitolareEffPanel extends React.Component {
  constructor(props) {
    super(props);	    
  }; 

  render () {
    // console.log('#RTCFA TitolareEffPanel this.props', this.props);
    const { classes, isValida } = this.props;
    return (
      <div className={classes.root}> 
        <ExpansionPanelComp titolo={'titolareEffettivo'} componente={TitolareEffAss} isValida={isValida} />
      </div>
    );
  };
};

TitolareEffPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TitolareEffPanel));
