import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import StampaProposal from './StampaProposal';

import { getIsValidaProposal } from '../../../utility/storeUtility';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function StampaProposalPanel(props) {
	
  const { classes } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'convalidaStampaDefinitiva'} componente={StampaProposal} senzaLensIcon={true} isDisabled={props.isDisabled}/> 
    </div>
  );
}

StampaProposalPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StampaProposalPanel);
