import * as actionType from '../actionType/titolareEff';

/* DATI TITOLARE EFFETTIVO */
export const updateDatiTitolareEff = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI GENERALI TITOLARE EFFETTIVO */
export const updateDatiGeneraliTitolareEff = (unDato, unActionType, isFiduciaria, figura) => {
  return {
    type: unActionType,
    unDato,
    isFiduciaria,
    figura
  }
};

/* DATI RESIDENZA TITOLARE EFFETTIVO */
export const updateDatiResidenzaTitolareEff = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI DOMICILIO TITOLARE EFFETTIVO */
export const updateDatiDomicilioTitolareEff = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato
  }
}

/* DATI RECAPITO TITOLARE EFFETTIVO */
export const updateDatiRecapitoTitolareEffettivo = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
}

/* DATI CORRISPONDENZA TITOLARE EFF */
export const updateDatiCorrispondenzaTitolareEff = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI CORRISPONDENZA INTESTATARIO TITOLARE EFF */
export const updateDatiCorrispondenzaIntestatarioTitolareEff = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

/* DATI GENERALI MINIMI TITOLARE EFFETTIVO */
export const updateDatiGeneraliMinimiTitolareEffBenefMorte = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const updateDatiGeneraliMinimiTitolareEffBenefVita = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const updateDatiAttEconomicaTitolareEff = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
}

/* DATI ANTIRICICLAGGIO TITOLARE EFFETTIVO */
export const updateDatiAntiriciclaggioTitolareEff = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

/* DATI FISCALI TITOLARE EFFETTIVO */
export const updateDatiFiscaliTitolareEff = (unDato, unActionType, figura) => {
  return {
    type: unActionType,
    unDato,
    figura
  }
};

export const updateTitolareEff = (currTitolareEff) => {
  return {
    type: actionType.UPDATE_TITOLARE_EFF,
    titolareEffettivo: currTitolareEff,
  }
};




/*********************** GESTIONE TABELLA RIEPILOGO ***************************/
export const confermaAggiungiTitolareEff = (currTitolareEff) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF,
    titolareEffettivo: currTitolareEff,
  }
};

//export const nuovoTitolareEff = (currTitolareEff) => {
export const nuovoTitolareEff = () => {
  return {
    type: actionType.NUOVO_TITOLARE_EFF,
  //  titolareEffettivo: currTitolareEff,
  }
};

export const caricaDettaglioTitolareEff = (figuraSelezIndex)  => {
  return {
    type: actionType.CARICA_TITOLARE_EFF,
    figuraSelezIndex,
  }
};

export const deleteTitolareEff = (currTitolareEff, figura)  => {
  return {
    type: actionType.DELETE_TITOLARE_EFF,
    titolareEffettivo: currTitolareEff,
    figura: figura,
  }
};

/*********************** GESTIONE TABELLA TITOLARE EFFETTIVO BENEFICIARIO MORTE RIEPILOGO  ***************************/

export const confermaAggiungiTitolareEffBenefMorte = (currTitolareEff) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_MORTE,
    titolareEffettivoBenefMorte: currTitolareEff,
  }
};

export const nuovoTitolareEffBenefMorte = () => {
  return {
    type: actionType.NUOVO_TITOLARE_EFF_BENEF_MORTE,
  }
};

export const updateTitolareEffBenefMorte = (currTitolareEff) => {
  return {
    type: actionType.UPDATE_TITOLARE_EFF_BENEF_MORTE,
    titolareEffettivoBenefMorte: currTitolareEff,
  } 
};

export const updateDatiRelazContrTitolareEffBenefMorte = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
}

export const updateDatiTitolareEffBenefMorte = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const caricaDettaglioTitolareEffBenefMorte = (figuraSelezIndex)  => {
  return {
    type: actionType.CARICA_TITOLARE_EFF_BENEF_MORTE,
    figuraSelezIndex,
  }
};

export const deleteTitolareEffBenefMorte = (currTitolareEff, figura)  => {
  return {
    type: actionType.DELETE_TITOLARE_EFF_BENEF_MORTE,
    titolareEffettivo: currTitolareEff,
    figura: figura,
  }
};

/*********************** GESTIONE TABELLA TITOLARE EFFETTIVO BENEFICIARIO VITA RIEPILOGO  ***************************/

export const confermaAggiungiTitolareEffBenefVita = (currTitolareEff) => {
  return {
    type: actionType.CONFERMA_AGGIUNGI_TITOLARE_EFF_BENEF_VITA,
    titolareEffettivoBenefVita: currTitolareEff,
  }
};

export const nuovoTitolareEffBenefVita = () => {
  return {
    type: actionType.NUOVO_TITOLARE_EFF_BENEF_VITA,
  }
};

export const updateTitolareEffBenefVita = (currTitolareEff) => {
  return {
    type: actionType.UPDATE_TITOLARE_EFF_BENEF_VITA,
    titolareEffettivoBenefVita: currTitolareEff,
  } 
};

export const updateDatiRelazContrTitolareEffBenefVita = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
}
export const updateDatiTitolareEffBenefVita = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};

export const caricaDettaglioTitolareEffBenefVita = (figuraSelezIndex)  => {
  return {
    type: actionType.CARICA_TITOLARE_EFF_BENEF_VITA,
    figuraSelezIndex,
  }
};

export const deleteTitolareEffBenefVita = (currTitolareEff, figura)  => {
  return {
    type: actionType.DELETE_TITOLARE_EFF_BENEF_VITA,
    titolareEffettivo: currTitolareEff,
    figura: figura,
  }
};
