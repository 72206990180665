import { generic } from './info/generic';
import { areaProdotto, areaLogin, loginUser } from './info/areaProdotto';
import { area } from './info/areaSelezionata';
import { prodotto } from './info/prodottoSelezionato';
import { contraenteFisico } from './info/contraenteFisico';
import { contraenteGiuridico } from './info/contraenteGiuridico';
import { assicuratoFisico } from './info/assicuratoFisico';
import { benefVitaFisico, benefVitaFisicoList  } from './info/beneficiarioVitaFisico';
import { benefVitaGiuridico, benefVitaGiuridicoList } from './info/beneficiarioVitaGiuridico';
import { benefVitaGenerico, benefVitaGenericoList } from './info/beneficiarioVitaGenerico';
import { benefMorteFisico, benefMorteFisicoList } from './info/beneficiarioMorteFisico';
import { benefMorteGiuridico, benefMorteGiuridicoList } from './info/beneficiarioMorteGiuridico';
import { benefMorteGenerico, benefMorteGenericoList } from './info/beneficiarioMorteGenerico';
import { referenteTerzoFisico } from './info/referenteTerzoFisico';
import { titolareEff, titolareEffList } from './info/titolareEff';
import { dichiarazioni } from './info/dichiarazioni';
import { caratteristicheContratto } from './info/caratteristicheContratto';
import { dominio } from './info/dominio';
import { questionario } from './info/questionario';
import { gestioneUtenza } from './info/gestioneUtenza';
import { stampaProposal } from './info/stampaProposal';

const initialState = {
  generic: generic,
  areaLogin: areaLogin,
  areaProdotto: areaProdotto,
  loginUser: loginUser,
  area: area,
  prodotto: prodotto,
  contraenteFisico: contraenteFisico,
  contraenteGiuridico: contraenteGiuridico,
  assicuratoFisico: assicuratoFisico,  
  benefVitaFisico: benefVitaFisico,  
  benefVitaGiuridico: benefVitaGiuridico,
  benefVitaFisicoList: benefVitaFisicoList,
  benefVitaGiuridicoList: benefVitaGiuridicoList,
  benefVitaGenerico: benefVitaGenerico,  
  benefVitaGenericoList: benefVitaGenericoList,
  benefMorteFisico: benefMorteFisico,
  benefMorteGiuridico: benefMorteGiuridico,
  benefMorteFisicoList: benefMorteFisicoList,
  benefMorteGiuridicoList: benefMorteGiuridicoList,
  benefMorteGenerico: benefMorteGenerico,
  benefMorteGenericoList: benefMorteGenericoList,
  referenteTerzoFisico: referenteTerzoFisico,
  titolareEff: titolareEff,
  titolareEffList: titolareEffList,
  dichiarazioni: dichiarazioni,
  caratteristicheContratto: caratteristicheContratto,
  dominio: dominio,
  questionario: questionario,
  gestioneUtenza: gestioneUtenza,
  stampaProposal: stampaProposal,
  scopeAmleto: false,
};

export default initialState;