import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { uris, fetchParam, isLocal } from '../../../../uris';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import TextFieldComp from '../../../common/TextFieldComp';
import SelectComp from '../../../common/SelectComp';
import CheckboxComp from '../../../common/CheckboxComp';
import ButtonComp from '../../../common/ButtonComp';
import Paper from '@material-ui/core/Paper';

import { CODICE_ALTRA_RELAZIONE } from '../../../../utility/jsConstants';

import * as actions from './action/datiPianoErogazioniPeriodiche';
import * as actionType from './actionType/datiPianoErogazioniPeriodiche';

import { textTrim, checkBoxValue } from '../../../../utility/genericUtility';

import moment from 'moment';
import 'moment/locale/it';

const styles = theme => ({
  paperStyle: theme.paperStyle,	
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  fieldSet: theme.fieldSet,
  divider: {
    width: theme.spacing(1),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
    prodotto: state.areaProdottoReducer.prodotto,
    dominio: state.areaDatiDominioReducer.dominio,
    datiPianoErogazioniPeriodiche: state.areaDatiPianoErogazioniPeriodicheReducer.caratteristicheContratto.datiPianoErogazioniPeriodiche,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDatiPianoErogazioniPeriodiche: (unDato, unActionType) => dispatch(actions.updateDatiPianoErogazioniPeriodiche(unDato, unActionType)),
  };
};
	
class DatiPianoErogazioniPeriodiche extends React.Component {
	
  constructor(props) {
    super(props);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    this.state = {
	  freqPrestazione: datiPianoErogazioniPeriodiche.freqPrestazione,
	  descFreqPrestazione: datiPianoErogazioniPeriodiche.descFreqPrestazione,
	  durata: datiPianoErogazioniPeriodiche.durata,
	  descDurata: datiPianoErogazioniPeriodiche.descDurata,
	  percentuale: datiPianoErogazioniPeriodiche.percentuale,
	  descPercentuale: datiPianoErogazioniPeriodiche.descPercentuale,
	  iban: datiPianoErogazioniPeriodiche.iban,
	  controlloIban: datiPianoErogazioniPeriodiche.controlloIban,
	  contoCointestato: datiPianoErogazioniPeriodiche.contoCointestato,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,	  
	};
  };

  componentDidMount() {	  
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    this.setPercentualeErogazioniPeriodiche(datiPianoErogazioniPeriodiche ? datiPianoErogazioniPeriodiche.freqPrestazione : '');
  }  
  
  handleChangeConfermaDatiPianoErogazioniPeriodiche = event => {
    this.props.updateDatiPianoErogazioniPeriodiche(true, actionType.SALVA_DATI_PIANO_EROGAZIONI_PERIODICHE);
    this.props.updateDatiPianoErogazioniPeriodiche('CHECK_IBAN', actionType.UPDATE_CONTROLLO_IBAN_PIANO_EROGAZIONI_PERIODICHE);
    this.setState({ 
      controlloIban: 'CHECK_IBAN',
  	});
  } 
  
  handleChangeIban = event => {
    const nuovoIban = textTrim(event.target.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchioIban = textTrim(datiPianoErogazioniPeriodiche.iban, true);
    if (nuovoIban !== vecchioIban) {	  
  	  this.props.updateDatiPianoErogazioniPeriodiche(nuovoIban, actionType.UPDATE_IBAN_PIANO_EROGAZIONI_PERIODICHE);   
	  this.setState({ 
	    iban: nuovoIban,
	  });
    }  
  };
	
  handleChangeFrequenza = event => {
    const nuovaFrequenza = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchiaFrequenza = textTrim(datiPianoErogazioniPeriodiche.freqPrestazione, true);
    if (nuovaFrequenza !== vecchiaFrequenza) {  	  
      this.props.updateDatiPianoErogazioniPeriodiche(event, actionType.UPDATE_FREQ_PRESTAZIONE_PIANO_EROGAZIONI_PERIODICHE); 	
      this.setPercentualeErogazioniPeriodiche(nuovaFrequenza);
      this.setState({
        freqPrestazione: nuovaFrequenza,
        descrFreqPrestazione: (event === null) ? '' : event.label,
      });
    }  
  }  
  
  handleChangeDurata = event => {
    const nuovaDurata = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchiaDurata = textTrim(datiPianoErogazioniPeriodiche.durata, true);
    if (nuovaDurata !== vecchiaDurata) {      
      this.props.updateDatiPianoErogazioniPeriodiche(event, actionType.UPDATE_DURATA_PIANO_EROGAZIONI_PERIODICHE); 	
      this.setState({
        durata: nuovaDurata,
        descrDurata: (event === null) ? '' : event.label,
      });
    }  
  }  
  
  handleChangePercentuale = event => {
    const nuovaPercentuale = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchiaPercentuale = textTrim(datiPianoErogazioniPeriodiche.percentuale, true);
    if (nuovaPercentuale !== vecchiaPercentuale) {  	  
      this.props.updateDatiPianoErogazioniPeriodiche(event, actionType.UPDATE_PERCENTUALE_PIANO_EROGAZIONI_PERIODICHE); 	
      this.setState({
        percentuale: nuovaPercentuale,
        descPercentuale: (event === null) ? '' : event.label,
//        controlloIbanPianoErogazioniPeriodiche: '',
      });
    }
  }  
  
  handleChangeContoCointestato = event => {
    const nuovoCoint = checkBoxValue(event.target.checked, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchioCoint = datiPianoErogazioniPeriodiche.contoCointestato;
    if(nuovoCoint !== vecchioCoint) {
      this.props.updateDatiPianoErogazioniPeriodiche(nuovoCoint, actionType.UPDATE_CONTO_COINTESTATO_PIANO_EROGAZIONI_PERIODICHE);
      this.setState({
        contoCointestato: nuovoCoint,
      })
    }
  }
	  
  handleChangeNomeCognomeCointestatario = event => {
    const nuovo = textTrim(event.target.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchio = datiPianoErogazioniPeriodiche.nomeCognomeCointestatario;
    if(nuovo !== vecchio ) {
      this.props.updateDatiPianoErogazioniPeriodiche(nuovo, actionType.UPDATE_NOME_COGNOME_COINTESTATARIO_PIANO_EROGAZIONI_PERIODICHE); 
      this.setState({
        nomeCognomeCointestatario: nuovo,
      })
    }
  }


  handleChangeRelazione = event => {
    const nuovaRelaz = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchiaRelaz = datiPianoErogazioniPeriodiche.relazContrCoint;
    if(nuovaRelaz !== vecchiaRelaz) {
      this.props.updateDatiPianoErogazioniPeriodiche(event, actionType.UPDATE_RELAZIONE_CONTR_PIANO_EROGAZIONI_PERIODICHE);
      this.setState({
        relazContrCoint: nuovaRelaz,
        descrRelazContrCoint: (event === null) ? '' : event.label,
      })
    }
  }

  handleChangeAltraRelaz = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    const vecchioAltro = datiPianoErogazioniPeriodiche.altraRelazione;
    if(nuovoAltro !== vecchioAltro) {
      this.props.updateDatiPianoErogazioniPeriodiche(nuovoAltro, actionType.UPDATE_ALTRA_RELAZIONE_PIANO_EROGAZIONI_PERIODICHE);
      this.setState({
        altraRelazione: nuovoAltro,
      })
    }
  }
  
  setPercentualeErogazioniPeriodiche = (codiceFrequenza) => {
    const codFrequenza = textTrim(codiceFrequenza, true);
    if(codFrequenza !== '') {
	  const opts = {
        siglaVersione: 'ZURI',
        codiceProdotto: this.props.prodotto.codice,
        codiceOpzione: '1',
        codiceFrequenza: codFrequenza,
        dataRichiesta: moment().format('DD/MM/YYYY'),
      };
      fetch(uris.percentualeErogazioniPeriodicheItems, fetchParam(opts))
        .then (
          response => response.json(),
          error => console.error('An error occurred.', error),
        )
        .then((result) => {
          this.setState({
            percentualeErogazioniPeriodicheItems: result,
          });
        });
    } else {
      this.setState({
        percentualeErogazioniPeriodicheItems: [],
      });
    }
  }
  
  verificaIban = () => {
	let controlloIban = '';
	const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
	const iban = textTrim(datiPianoErogazioniPeriodiche.iban, true);
	if (iban !== '') {
      if (isLocal === true) {	
    	controlloIban = 'KO';
        this.props.updateDatiPianoErogazioniPeriodiche(controlloIban, actionType.UPDATE_CONTROLLO_IBAN_PIANO_EROGAZIONI_PERIODICHE);
//        if (this.props.onChangeControlloIban){
//          this.props.onChangeControlloIban(controlloIban);
//        }
      }	else {	
        const opts = {
          iban: iban,
        };
        fetch(uris.controlloIban, fetchParam(opts))
        .then (
	      response => response.json(),
  	      error => console.error('An error occurred.', error),
	    )
	    .then((result) => {
	      controlloIban = (result === true) ? 'OK': 'KO';
          this.props.updateDatiPianoErogazioniPeriodiche(controlloIban, actionType.UPDATE_CONTROLLO_IBAN_PIANO_EROGAZIONI_PERIODICHE);
          this.setState({ 
            controlloIban: controlloIban,
          });
//	    	if (this.props.onChangeControlloIban){
//	    		this.props.onChangeControlloIban(controlloIban);
//	    	}
	    });
      }
	}
	return controlloIban;
  }
    
  controlloIban = (controlloDati) => {
    let controlloIban = this.props.datiPianoErogazioniPeriodiche.controlloIban;   
    if (controlloDati===true) {
      if (this.props.datiPianoErogazioniPeriodiche.controlloIban==='CHECK_IBAN') {
        // service call
        controlloIban = this.verificaIban();
      }
    }    
    return controlloIban;
  }
  
  isRequiredFrequenza = () => {
    return true;
  }
  
  isRequiredDurata = () => {
    return true;
  }
  
  isRequiredPercentuale = () => {
    return true;
  }
  
  isRequiredIban = () => {
    return true;
  }
  
  isRequiredContoCointestato = () => {
    return false;
  }

  isRequiredNomeCointestatario = () => {
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    return datiPianoErogazioniPeriodiche.contoCointestato;
  }

  isRequiredRelazione = () => {
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    return datiPianoErogazioniPeriodiche.contoCointestato;
  }

  isRequiredAltraRelazione = () => {
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    return datiPianoErogazioniPeriodiche.relazContrCoint === CODICE_ALTRA_RELAZIONE;
  }
	  
  initStatoCampi = () => {
    return [ {id: 'frequenza', isError: false},
             {id: 'durata', isError: false}, 
             {id: 'percentuale', isError: false}, 
             {id: 'iban', isError: false},
             {id: 'contoCointestato', isError: false},
             {id: 'nomeCognomeCointestatario', isError: false},
             {id: 'relazContrCoint', isError: false},
             {id: 'altraRelazione', isError: false},             
    	   ];
  }
  
  handleError = (id, isError) => {
    console.log('########DatiPianoErogazioniPeriodiche handleError id', id);	  
    console.log('########DatiPianoErogazioniPeriodiche handleError isError', isError);	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
  
  isDisabledPercentuale = () => {
    const freqPrestazione = textTrim(this.props.datiPianoErogazioniPeriodiche.freqPrestazione, true); 
	return (freqPrestazione === '');
  }
  
  isDisabledContoCointestato = () => {
    return false;
  }

  isDisabledNomeCognomeCointestatario = () => {
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    return !datiPianoErogazioniPeriodiche.contoCointestato;
  }

  isDisabledRelazione = () => {
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    return !datiPianoErogazioniPeriodiche.contoCointestato;
  }

  isDisabledAltraRelazione = () => {
    const datiPianoErogazioniPeriodiche = this.props.datiPianoErogazioniPeriodiche;
    return datiPianoErogazioniPeriodiche.relazContrCoint !== CODICE_ALTRA_RELAZIONE;
  }
	  
  render() {
    const { classes, datiPianoErogazioniPeriodiche, dominio, prodotto } = this.props;
    const { t } = this.props; //hoc    
    const controlloDati = datiPianoErogazioniPeriodiche.controlloDati;
    const controlloIban = this.controlloIban(controlloDati);
    const prodottoTipo = prodotto.tipo;
    return (
      <Fragment>
      {/*<fieldset className={classes.fieldSet}>
        <legend><Typography variant="subtitle1">{t('pianoErogazioniPeriodiche')}</Typography></legend>
        <DividerComp titolo='pianoErogazioniPeriodiche' variant="subtitle1" />*/}
        <Grid container direction="row" justify="center" alignItems="flex-start" >
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeFrequenza} options={dominio.frequenzaErogazioniPeriodicheItems} ricerca='comboRicerca' etichetta='freqPrestazione' 
                        value={{value: datiPianoErogazioniPeriodiche.freqPrestazione, label: datiPianoErogazioniPeriodiche.descFreqPrestazione}} 
                        required={this.isRequiredFrequenza()} controlloDati={controlloDati} id='frequenza' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeDurata} options={dominio.durataItems} ricerca='comboRicerca' etichetta='durata' 
                        value={{value: datiPianoErogazioniPeriodiche.durata, label: datiPianoErogazioniPeriodiche.descDurata}} required={this.isRequiredDurata()} 
                        controlloDati={controlloDati} id='durata' onError={this.handleError} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangePercentuale} options={this.state.percentualeErogazioniPeriodicheItems} ricerca='comboRicerca' etichetta='percentuale' 
                        value={{value: datiPianoErogazioniPeriodiche.percentuale, label: datiPianoErogazioniPeriodiche.descPercentuale}} 
                        required={this.isRequiredPercentuale()} disabled={this.isDisabledPercentuale()} controlloDati={controlloDati} id='percentuale' onError={this.handleError} />
          </Grid>
          <Grid item xs={12}>
            <TextFieldComp onBlur={this.handleChangeIban} aStyle={classes.textField} id='iban' label='iban' value={datiPianoErogazioniPeriodiche.iban} 
                           required={this.isRequiredIban()} controlloDati={controlloDati} onError={this.handleError} />
          </Grid>
          
          <Grid container direction="row" justify="center" alignItems="center">
	          <Grid item md={4} xs={12}>
	            <CheckboxComp onChange={this.handleChangeContoCointestato} required={this.isRequiredContoCointestato()} controlloDati={controlloDati}
	                        aLabel='contoCointestato' checked={datiPianoErogazioniPeriodiche.contoCointestato} id='contoCointestato' onError={this.handleError} 
	                        disabled={this.isDisabledContoCointestato()} />
	          </Grid>
	
	          <Grid item md={8} xs={12}>
	            <TextFieldComp onBlur={this.handleChangeNomeCognomeCointestatario} required={this.isRequiredNomeCointestatario()} controlloDati={controlloDati} id='nomeCognomeCointestatario'
	                        label='nomeCognomeCointestatario' value={datiPianoErogazioniPeriodiche.nomeCognomeCointestatario} onError={this.handleError} 
	                        aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledNomeCognomeCointestatario()} aStyleError={classes.textFieldWidthPercError}  />
	          </Grid>
	      </Grid>
                    
          <Grid item md={4} xs={12}>
          	<SelectComp onChange={this.handleChangeRelazione} options={dominio.relazioneItems} etichetta='relazContrCoint' id='relazContrCoint'
                      value={{value: datiPianoErogazioniPeriodiche.relazContrCoint, label: datiPianoErogazioniPeriodiche.descrRelazContrCoint}} onError={this.handleError} 
                      controlloDati={controlloDati} disabled={false} required={this.isRequiredRelazione()} disabled={this.isDisabledRelazione()} />
	      </Grid>
	      <Grid item md={8} xs={12}>
	          <TextFieldComp onBlur={this.handleChangeAltraRelaz} required={this.isRequiredAltraRelazione()} controlloDati={controlloDati}
	                         label='general.altro' value={datiPianoErogazioniPeriodiche.altraRelazione} onError={this.handleError} id='altraRelazione'
	                         aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledAltraRelazione()} aStyleError={classes.textFieldWidthPercError}  />
	      </Grid>
          
          <Grid item xs={12}>  
            <Paper elevation={0} className={classes.paperStyle}>
              <Typography variant="caption">{t('legendaPianoErogazioniPeriodiche')}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
              <Grid container direction='row' justify='center' alignItems='center'>
                <Grid item>
                {(this.state.isPresenteErrore === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(controlloDati === true) && (this.state.isPresenteErrore === false) && (controlloIban === 'OK') &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }                
                {(controlloDati === true) && (controlloIban === 'KO') &&
                  <Typography className={classes.textErrorMessage}>{'CODICE IBAN ERRATO'}</Typography>
                }
                </Grid>
              </Grid>
              <Grid item>
	                <ButtonComp
	                  aClassName={classes.button}
	                  buttonLabel={t('conferma')}
	                  onClick={this.handleChangeConfermaDatiPianoErogazioniPeriodiche}
	                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      {/*</fieldset>*/}
      </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiPianoErogazioniPeriodiche componentDidUpdate this.state.statoCampi', this.state.statoCampi);
    console.log('########DatiPianoErogazioniPeriodiche componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore); 
//	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
	if ((this.state.isPresenteErrore !== isPresenteCampiConErrore) && (this.props.datiPianoErogazioniPeriodiche.controlloDati === true)) {		
/*      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }  */    
      this.props.updateDatiPianoErogazioniPeriodiche(!isPresenteCampiConErrore, actionType.UPDATE_DATI_PIANO_EROGAZIONI_PERIODICHE_VALIDA);
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }      
}

DatiPianoErogazioniPeriodiche.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiPianoErogazioniPeriodiche)));

