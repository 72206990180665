import * as actionType from '../actionType/login';

export const login = (user, password) => {
  return {
    type: actionType.LOGIN,
    user,
    password,
  }
};

export const updateLogin = (unDato, unActionType) => {
  return {
    type: unActionType,
    unDato,
  }
};
