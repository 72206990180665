import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { fetchParam, uris } from '../../../../uris'
import Typography from '@material-ui/core/Typography'
import * as dominioActions from '../../../common/action/dominio';


const mapStateToProps = state => {
    return {
        professioniQas: state.areaDatiDominioReducer.dominio.professioniQas,
        sportQas: state.areaDatiDominioReducer.dominio.sportQas
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dominioProfessioniQas: (unaLista) => dispatch(dominioActions.dominioProfessioniQas(unaLista))
    }
}

class LegendaQuestionarioComponent extends Component {
    constructor(props) {
        super(props);

    }

      
    render() {
        const { numDidascalia, tipoAttivita } = this.props;
        const attivita = (tipoAttivita === 'Attività Professionale') ? this.props.professioniQas : this.props.sportQas;

        return (
            <div>
                {attivita && <Typography style={{selfAlign: 'center'}} variant="caption">{attivita[numDidascalia]}</Typography>}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LegendaQuestionarioComponent)
