import * as actionType from '../actionType/datiModPagAltro';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiModPagAltroInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiModPagAltroReducer = (state = initialState, action) => {
	
  console.log('#RT2 Reducer areaDatiModPagReimpiegoReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA: 
	  const caratteristicheContratto_INI = inizializzaModPagAltro(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.UPDATE_ALTRO:
      const caratteristicheContratto = {...state}.caratteristicheContratto;
      const datiModPagAltro = {...caratteristicheContratto}.datiModPagAltro;
      datiModPagAltro.altro = action.unDato;
      caratteristicheContratto.datiModPagAltro = {...datiModPagAltro};
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContratto,
      };   
    default:
      return state;
  }
}

export const inizializzaModPagAltro = (state) => {
  const caratteristicheContratto_INI = {...state}.caratteristicheContratto;
  caratteristicheContratto_INI.datiModPagAltro = jsonCopy(datiModPagAltroInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiModPagAltroReducer;