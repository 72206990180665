import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiInvioEmail from './DatiInvioEmail';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function DatiInvioEmailPanel(props) {
	
  const { classes } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'invioEmail'} componente={DatiInvioEmail} senzaLensIcon={true} isDisabled={props.isDisabled}/> 
    </div>
  );
}

DatiInvioEmailPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatiInvioEmailPanel);
