/* DATI REFERENTE TERZO */
export const SALVA_REFERENTE_TERZO_FISICO                          = 'SALVA_REFERENTE_TERZO_FISICO';
export const UPDATE_ANAGRAFICA_VALIDA_REFERENTE_TERZO_FISICO       = 'UPDATE_ANAGRAFICA_VALIDA_REFERENTE_TERZO_FISICO';

/* DATI GENERALI */
export const UPDATE_FL_REFERENTE_TERZO_FISICO                      = 'UPDATE_FL_REFERENTE_TERZO_FISICO';
export const UPDATE_CODICE_FISCALE_REFERENTE_TERZO_FISICO          = 'UPDATE_CODICE_FISCALE_REFERENTE_TERZO_FISICO';
export const UPDATE_CODICE_FISCALE_VALIDO_REFERENTE_TERZO_FISICO   = 'UPDATE_CODICE_FISCALE_VALIDO_REFERENTE_TERZO_FISICO';
export const UPDATE_NOME_REFERENTE_TERZO_FISICO                    = 'UPDATE_NOME_REFERENTE_TERZO_FISICO';
export const UPDATE_COGNOME_REFERENTE_TERZO_FISICO                 = 'UPDATE_COGNOME_REFERENTE_TERZO_FISICO';
export const UPDATE_SESSO_REFERENTE_TERZO_FISICO                   = 'UPDATE_SESSO_REFERENTE_TERZO_FISICO';
export const UPDATE_DATA_NASCITA_REFERENTE_TERZO_FISICO            = 'UPDATE_DATA_NASCITA_REFERENTE_TERZO_FISICO';
export const UPDATE_STATO_NASCITA_REFERENTE_TERZO_FISICO           = 'UPDATE_STATO_NASCITA_REFERENTE_TERZO_FISICO';
export const UPDATE_PROVINCIA_NASCITA_REFERENTE_TERZO_FISICO       = 'UPDATE_PROVINCIA_NASCITA_REFERENTE_TERZO_FISICO';
export const UPDATE_COMUNE_NASCITA_REFERENTE_TERZO_FISICO          = 'UPDATE_COMUNE_NASCITA_REFERENTE_TERZO_FISICO';

/* DATI RESIDENZA */
export const UPDATE_STATO_RESIDENZA_REFERENTE_TERZO_FISICO         = 'UPDATE_STATO_RESIDENZA_REFERENTE_TERZO_FISICO';
export const UPDATE_PROVINCIA_RESIDENZA_REFERENTE_TERZO_FISICO     = 'UPDATE_PROVINCIA_RESIDENZA_REFERENTE_TERZO_FISICO';
export const UPDATE_COMUNE_RESIDENZA_REFERENTE_TERZO_FISICO        = 'UPDATE_COMUNE_RESIDENZA_REFERENTE_TERZO_FISICO';
export const UPDATE_TOPONIMO_RESIDENZA_REFERENTE_TERZO_FISICO      = 'UPDATE_TOPONIMO_RESIDENZA_REFERENTE_TERZO_FISICO';
export const UPDATE_INDIRIZZO_RESIDENZA_REFERENTE_TERZO_FISICO     = 'UPDATE_INDIRIZZO_RESIDENZA_REFERENTE_TERZO_FISICO';
export const UPDATE_NUMERO_CIVICO_RESIDENZA_REFERENTE_TERZO_FISICO = 'UPDATE_NUMERO_CIVICO_RESIDENZA_REFERENTE_TERZO_FISICO';
export const UPDATE_CAP_RESIDENZA_REFERENTE_TERZO_FISICO           = 'UPDATE_CAP_RESIDENZA_REFERENTE_TERZO_FISICO';

export const UPDATE_EMAIL_REFERENTE_TERZO_FISICO                   = 'UPDATE_EMAIL_REFERENTE_TERZO_FISICO';
export const RESET_DATI_REFERENTE_TERZO 						   = 'RESET_DATI_REFERENTE_TERZO';