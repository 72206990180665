import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
//import { uris, fetchParam } from '../../../../uris';
import Grid from '@material-ui/core/Grid';

import TextFieldComp from '../../../common/TextFieldComp';
import SelectComp from '../../../common/SelectComp';
import CheckboxComp from '../../../common/CheckboxComp';

import * as actions from './action/datiModPagAssegno';
import * as actionType from './actionType/datiModPagAssegno';
import { textTrim, checkBoxValue } from '../../../../utility/genericUtility';

import 'moment/locale/it';
import { CODICE_ALTRA_RELAZIONE, locale_it } from '../../../../utility/jsConstants';

const styles = theme => ({
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  textFieldWidthPercError: theme.textFieldWidthPercError,
  button: theme.button,
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiModPagAssegno.mapStateToProps.state', state);
  return {
    dominio: state.areaDatiDominioReducer.dominio,
    datiModPagAssegno: state.areaDatiModPagAssegnoReducer.caratteristicheContratto.datiModPagAssegno,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModPagAssegno.mapDispatchToProps.dispatch', dispatch);
  return {
    updateDatiModPagAssegno: (unDato, unActionType) => dispatch(actions.updateDatiModPagAssegno(unDato, unActionType)),
  };
};
	
class DatiModPagAssegno extends React.Component {
	
  constructor(props) {
	super(props);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    this.state = {
      numeroAssegno1: datiModPagAssegno.numeroAssegno1,
      numeroAssegno2: datiModPagAssegno.numeroAssegno2,
      banca1: datiModPagAssegno.banca1,
      descBanca1: datiModPagAssegno.descBanca1,
      banca2: datiModPagAssegno.banca2,
      descBanca2: datiModPagAssegno.descBanca2,
      agenzia1: datiModPagAssegno.agenzia1,
      descAgenzia1: datiModPagAssegno.descAgenzia1,
      agenzia2: datiModPagAssegno.agenzia2,
      descAgenzia2: datiModPagAssegno.descAgenzia2,
      contoCointestato: datiModPagAssegno.contoCointestato,
      nomeCognomeCointestatario: datiModPagAssegno.nomeCognomeCointestatario,
      altraRelazione: datiModPagAssegno.altraRelazione,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: false,	  
	};
  };
  
  /*
  componentDidMount() {	     
    this.setFiliale1(this.props.datiModPagAssegno ? this.props.datiModPagAssegno.banca1 : '');
    this.setFiliale2(this.props.datiModPagAssegno ? this.props.datiModPagAssegno.banca2 : '');
  }*/

  handleChangeNumeroAssegno1 = event => {
    //console.log('#RTCFA DatiModPagAssegno numeroAssegno1 new value:' + event.target.value);
    const nuovoNumeroAssegno1 = textTrim(event.target.value, true);
//    const vecchioNumeroAssegno1 = textTrim(this.state.numeroAssegno1, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchioNumeroAssegno1 = datiModPagAssegno.numeroAssegno1;
    if (nuovoNumeroAssegno1 !== vecchioNumeroAssegno1) {
      this.props.updateDatiModPagAssegno(nuovoNumeroAssegno1, actionType.UPDATE_NUMERO_ASSEGNO_1);   
      this.setState({ 
        numeroAssegno1: nuovoNumeroAssegno1, 
      });
    }  
  };
  
  /*
  handleChangeNumeroAssegno2 = event => {
    //console.log('#RTCFA DatiModPagAssegno numeroAssegno2 new value:' + event.target.value);
    this.props.updateDatiModPagAssegno(event.target.value, actionType.UPDATE_NUMERO_ASSEGNO_2);   
    this.setState({ 
      numeroAssegno2: event.target.value 
    });
  };*/
	
  handleChangeBanca1 = event => {
    //console.log('#RTCFA DatiModPagAssegno banca1 new value:' + event); 
    const nuovaBanca1 = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
//    const vecchiaBanca1 = textTrim(this.state.banca1, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchiaBanca1 = datiModPagAssegno.banca1;
    if (nuovaBanca1 !== vecchiaBanca1) {
      this.props.updateDatiModPagAssegno(event, actionType.UPDATE_BANCA_1);
      //this.setFiliale1((event === null) ? '' : event.value);
      this.setState({
        banca1: (event === null) ? '' : event.value,
        descBanca1: (event === null) ? '' : event.label,
      });
    }  
  }  
  
  /*
  handleChangeBanca2 = event => {
    //console.log('#RTCFA DatiModPagAssegno banca2 new value:' + event); 
    this.props.updateDatiModPagAssegno(event, actionType.UPDATE_BANCA_2); 	
    this.setFiliale2((event === null) ? '' : event.value);
    this.setState({
      banca2: (event === null) ? '' : event.value,
      descBanca2: (event === null) ? '' : event.label,
    });	
  } 
  
  handleChangeAgenzia1 = event => {
    //console.log('#RTCFA DatiModPagAssegno agenzia1 new value:' + event); 
    this.props.updateDatiModPagAssegno(event, actionType.UPDATE_AGENZIA_1); 	
    this.setState({
      agenzia1: (event === null) ? '' : event.value,
      descAgenzia1: (event === null) ? '' : event.label,
    });	
  }*/  
  
  handleChangeAgenzia1 = event => {
    const nuovaAgenzia1 = textTrim(event.target.value, true);
//    const vecchiaAgenzia1 = textTrim(this.state.descAgenzia1, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchiaAgenzia1 = datiModPagAssegno.descAgenzia1;
    if (nuovaAgenzia1 !== vecchiaAgenzia1) {	  
      this.props.updateDatiModPagAssegno(nuovaAgenzia1, actionType.UPDATE_AGENZIA_1);   
      this.setState({ 
        descAgenzia1: nuovaAgenzia1, 
      });
    }  
  };

  /*
  handleChangeAgenzia2 = event => {
    //console.log('#RTCFA DatiModPagAssegno agenzia2 new value:' + event); 
    this.props.updateDatiModPagAssegno(event, actionType.UPDATE_AGENZIA_2); 	
    this.setState({
      agenzia2: (event === null) ? '' : event.value,
      descAgenzia2: (event === null) ? '' : event.label,
    });	
  }*/  
  
  /*
  setFiliale1 = (codiceAbi) => {
    if(textTrim(codiceAbi, true)!==''){
	  const opts = {
        codiceAbi: codiceAbi,
        dataRichiesta: moment().format('DD/MM/YYYY'),
      };
      fetch(uris.filialeItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          filiale1Items: result,
        });
      });
    }else{
      this.setState({
        filiale1Items: [],
      });
    }
  }
  
  setFiliale2 = (codiceAbi) => {
    if(textTrim(codiceAbi, true)!==''){
	  const opts = {
        codiceAbi: codiceAbi,
        dataRichiesta: moment().format('DD/MM/YYYY'),
      };
      fetch(uris.filialeItems, fetchParam(opts))
      .then (
        response => response.json(),
        error => console.error('An error occurred.', error),
      )
      .then((result) => {
        // If request is good update state with fetched data
        this.setState({
          filiale2Items: result,
        });
      });
    }else{
      this.setState({
        filiale2Items: [],
      });
    }
  }*/
    
  handleChangeContoCointestato = event => {
    const nuovoCoint = checkBoxValue(event.target.checked, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchioCoint = datiModPagAssegno.contoCointestato;
    if(nuovoCoint !== vecchioCoint) {
      this.props.updateDatiModPagAssegno(nuovoCoint, actionType.UPDATE_COINTESTATO);
      this.setState({
        contoCointestato: nuovoCoint
      })
    }
  }

  handleChangeNomeCointestatario = event => {
    const nuovoCoint = textTrim(event.target.value, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchioCoint = datiModPagAssegno.nomeCognomeCointestatario;
    if(nuovoCoint !== vecchioCoint) {
      this.props.updateDatiModPagAssegno(nuovoCoint, actionType.UPDATE_NOME_COGNOME_COINTESTATARIO);
      this.setState({
        nomeCognomeCointestatario: nuovoCoint
      })
    }
  }

  handleChangeRelazione = event => {
    const nuovaRelaz = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchiaRelaz = datiModPagAssegno.relazContrCoint;
    if(nuovaRelaz !== vecchiaRelaz) {
      this.props.updateDatiModPagAssegno(event, actionType.UPDATE_RELAZ_PAGAT_CONTR);
      this.setState({
        relazContrCoint: nuovaRelaz,
        descrRelazContrCoint: (event === null) ? '' : event.label,
      })
    }
  }

  handleChangeAltraRelaz = event => {
    const nuovoAltro = textTrim(event.target.value, true);
    const datiModPagAssegno = this.props.datiModPagAssegno;
    const vecchioAltro = datiModPagAssegno.altraRelazione;
    if(nuovoAltro !== vecchioAltro) {
      this.props.updateDatiModPagAssegno(nuovoAltro, actionType.UPDATE_ALTRA_RELAZ);
      this.setState({
        altraRelazione: nuovoAltro
      })
    }
  }

  isRequiredAssegno1 = () => {
    return (this.props.datiModPagAssegno.numeroAssegno2 === '');
  }
  
  isRequiredBanca1 = () => {
    return (this.props.datiModPagAssegno.numeroAssegno2 === '');
  }
  
  isRequiredAgenzia1 = () => {
    return (this.props.datiModPagAssegno.numeroAssegno2 === '');
  }
  
  isRequiredContoCointestato = () => {
    return false;
  }

  isRequiredNomeCointestatario = () => {
	//2021-07-01 PFD: TCM_INDIV
	//const datiModPagAssegno = this.props.datiModPagAssegno;
	//return datiModPagAssegno.contoCointestato;
	const datiModPagAssegno = this.props.datiModPagAssegno;
	let required = datiModPagAssegno.contoCointestato;
	//se scopeTcmEmissione, non obbligatorio anche se contoCointestato
	if (this.props.scopeTcmEmissione) {
	  const isTcmIndividValida = this.props.tcmIndividualeValida;
	  if (isTcmIndividValida) {
		  required = false;
	  }
	}
    return required;
  }

  isRequiredRelazione = () => {
	//2021-07-01 PFD: TCM_INDIV
	//const datiModPagAssegno = this.props.datiModPagAssegno;
	//return datiModPagAssegno.contoCointestato;
    const datiModPagAssegno = this.props.datiModPagAssegno;
	let required = datiModPagAssegno.contoCointestato;
	//se scopeTcmEmissione, non obbligatorio anche se contoCointestato
	if (this.props.scopeTcmEmissione) {
	  const isTcmIndividValida = this.props.tcmIndividualeValida;
	  if (isTcmIndividValida) {
		  required = false;
	  }
	}
    return required;
  }

  isRequiredAltraRelazione = () => {
    const datiModPagAssegno = this.props.datiModPagAssegno;
    return datiModPagAssegno.relazContrCoint === CODICE_ALTRA_RELAZIONE;
  }

  isDisabledRelazione = () => {
    const datiModPagAssegno = this.props.datiModPagAssegno;
    return !datiModPagAssegno.contoCointestato;
  }

  isDisabledAltraRelazione = () => {
    const datiModPagAssegno = this.props.datiModPagAssegno;
    return datiModPagAssegno.relazContrCoint !== CODICE_ALTRA_RELAZIONE;
  }

  isDisabledContoCointestato = () => {
    return false;
  }

  isDisabledNomeCognomeCointestatario = () => {
    const datiModPagAssegno = this.props.datiModPagAssegno;
    return !datiModPagAssegno.contoCointestato;
  }

  /*
  isRequiredAssegno2 = () => {
    if(this.props.datiModPagAssegno.numeroAssegno1 === ''){
      return true;
	}else{
	  return false;
	}   
  }
	  
  isRequiredBanca2 = () => {
    if(this.props.datiModPagAssegno.numeroAssegno1 === ''){
      return true;
	}else{
	  return false;
	}   
  }
	  
  isRequiredAgenzia2 = () => {
    if(this.props.datiModPagAssegno.numeroAssegno1 === ''){
      return true;
	}else{
	  return false;
	}   
  }*/
	  
  initStatoCampi = () => {
    return [ {id: 'numeroAssegno1', isError: false},
             {id: 'banca1', isError: false}, 
             {id: 'agenzia1', isError: false},
    	   ];
  }
  
  handleError = (id, isError) => {	    
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }
    
  render() {
    const { classes, datiModPagAssegno, controlloDati, dominio } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item md={4} xs={12}>
          <TextFieldComp onBlur={this.handleChangeNumeroAssegno1} aStyle={classes.textField} id='numeroAssegno1' label='numeroAssegno1' value={datiModPagAssegno.numeroAssegno1}
	                     required={this.isRequiredAssegno1()} controlloDati={controlloDati} onError={this.handleError} />
        </Grid>	
        <Grid item md={4} xs={12}>
          <SelectComp onChange={this.handleChangeBanca1} options={dominio.bancaItems} ricerca='comboRicerca' etichetta='banca' value={{value: datiModPagAssegno.banca1, label: datiModPagAssegno.descBanca1}} 
	            required={this.isRequiredBanca1()} controlloDati={controlloDati} id='banca1' onError={this.handleError} />
        </Grid>
        <Grid item md={4} xs={12}>
        {/*   <SelectComp onChange={this.handleChangeAgenzia1} options={this.state.filiale1Items} ricerca='comboRicerca' etichetta='agenzia1' value={{value: datiModPagAssegno.agenzia1, label: datiModPagAssegno.descAgenzia1}} 
            required={this.isRequiredAgenzia1()} controlloDati={controlloDati} />*/}
          <TextFieldComp onBlur={this.handleChangeAgenzia1} aStyle={classes.textFieldWidthPerc} id='agenzia1' label='piazza' value={datiModPagAssegno.descAgenzia1}
                         required={this.isRequiredAgenzia1()} controlloDati={controlloDati} onError={this.handleError} aStyleError={classes.textFieldWidthPercError}  />
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={4} xs={12}>
              <CheckboxComp onChange={this.handleChangeContoCointestato} required={this.isRequiredContoCointestato()} controlloDati={controlloDati}
                            aLabel='contoCointestato' checked={datiModPagAssegno.contoCointestato} id='contoCointestato' onError={this.handleError} 
                            disabled={this.isDisabledContoCointestato()} />
          </Grid>
          <Grid item md={8} xs={12}>
              <TextFieldComp onBlur={this.handleChangeNomeCointestatario} required={this.isRequiredNomeCointestatario()} controlloDati={controlloDati} id='nomeCognomeCointestatario'
                            label='nomeCognomeCointestatario' value={datiModPagAssegno.nomeCognomeCointestatario} onError={this.handleError} 
                            aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledNomeCognomeCointestatario()} aStyleError={classes.textFieldWidthPercError}  />
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
            <SelectComp onChange={this.handleChangeRelazione} options={dominio.relazioneItems} etichetta='relazContrCoint' id='relazContrCoint'
                        value={{value: datiModPagAssegno.relazContrCoint, label: datiModPagAssegno.descrRelazContrCoint}} onError={this.handleError} 
                        controlloDati={controlloDati} disabled={false} required={this.isRequiredRelazione()} disabled={this.isDisabledRelazione()} />
        </Grid>
        <Grid item md={8} xs={12}>
            <TextFieldComp onBlur={this.handleChangeAltraRelaz} required={this.isRequiredAltraRelazione()} controlloDati={controlloDati}
                           label='general.altro' value={datiModPagAssegno.altraRelazione} onError={this.handleError} 
                           aStyle={classes.textFieldWidthPerc} disabled={this.isDisabledAltraRelazione()} aStyleError={classes.textFieldWidthPercError}  />
        </Grid>
          {/*   
	        <Grid item md={4} xs={12}>
	        <TextFieldComp onBlur={this.handleChangeNumeroAssegno2} aStyle={classes.textField} id='numeroAssegno2' label='numeroAssegno2' value={datiModPagAssegno.numeroAssegno2} 
	          required={this.isRequiredAssegno2()} controlloDati={controlloDati} />
	        </Grid>	
	        <Grid item md={4} xs={12}>
	          <SelectComp onChange={this.handleChangeBanca2} options={dominio.bancaItems} ricerca='comboRicerca' etichetta='banca2' value={{value: datiModPagAssegno.banca2, label: datiModPagAssegno.descBanca2}} 
	            required={this.isRequiredBanca2()} controlloDati={controlloDati} />
	        </Grid>
	        <Grid item md={4} xs={12}>
	          <SelectComp onChange={this.handleChangeAgenzia2} options={this.state.filiale2Items} ricerca='comboRicerca' etichetta='agenzia2' value={{value: datiModPagAssegno.agenzia2, label: datiModPagAssegno.descAgenzia2}} 
	            required={this.isRequiredAgenzia2()} controlloDati={controlloDati} />
	        </Grid>*/}
      </Grid>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	});
    console.log('########DatiModPagAssegno componentDidUpdate this.state.statoCampi', this.state.statoCampi);
	if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
      if (this.props.onError) {
        this.props.onError(this.props.id, isPresenteCampiConErrore);
      }
      if (this.props.onChildrenError) {
        this.props.onChildrenError(this.props.id, isPresenteCampiConErrore);
      }      
      this.setState({	
        isPresenteErrore: isPresenteCampiConErrore,      
      });      
	}
  }
    
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
  }    
}

DatiModPagAssegno.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiModPagAssegno));
