export const UPDATE_COMPORTAMENTO_CLIENTE     = 'UPDATE_COMPORTAMENTO_CLIENTE';
export const UPDATE_COLLOCATORE               = 'UPDATE_COLLOCATORE';
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario export const UPDATE_COGNOME_COLLOCATORE       = 'UPDATE_COGNOME_COLLOCATORE';
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario export const UPDATE_NOME_COLLOCATORE          = 'UPDATE_NOME_COLLOCATORE';
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario export const UPDATE_REGIONE_ATT_COLLOCATORE   = 'UPDATE_REGIONE_ATT_COLLOCATORE';
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario export const UPDATE_COD_INTERMEDIARIO         = 'UPDATE_COD_INTERMEDIARIO';
//2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario export const UPDATE_COD_RUI                   = 'UPDATE_COD_RUI';
export const UPDATE_COD_COGNOME_NOME_COLLOCATORE	= 'UPDATE_COD_COGNOME_NOME_COLLOCATORE';
export const UPDATE_COD_RUI                   		= 'UPDATE_COD_RUI';
export const UPDATE_LUOGO_COLLOCATORE      		 	= 'UPDATE_LUOGO_COLLOCATORE';
export const UPDATE_DATA_COLLOCATORE       			= 'UPDATE_DATA_COLLOCATORE';
export const SALVA_DATI_INTERMEDIARIO         		= 'SALVA_DATI_INTERMEDIARIO';
export const UPDATE_DATI_INTERMEDIARIO_VALIDA 		= 'UPDATE_DATI_INTERMEDIARIO_VALIDA';
export const UPDATE_DURATA_RAPPORTO_CONTR_INT 		= 'UPDATE_DURATA_RAPPORTO_CONTR_INT';
export const UPDATE_OPERAZIONE_COERENTE       		= 'UPDATE_OPERAZIONE_COERENTE';
export const UPDATE_PROFILO_RISCHIO           		= 'UPDATE_PROFILO_RISCHIO';
