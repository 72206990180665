import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { withTranslation } from 'react-i18next';
import { numberFormat } from '../../utility/genericUtility';
import { locale_it } from '../../utility/jsConstants';

const styles = theme => ({
//  numberField: theme.textField,
  textFieldError: theme.textFieldError,	
});

class NumberFieldComp extends React.Component {
		
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      typing: false,
      isError: false,
      acceptDecimals: ((props.acceptDecimals!==undefined) ? props.acceptDecimals : true),
      locale: (props.locale!==undefined ? props.locale : locale_it), 
      decimalSep: (props.locale===undefined||props.locale===locale_it?',':'.'),
    }	
  };
  
  handleChange = event => {
	const value = event.target.value;
    console.log('#RT2 NumberFieldComp old value this.state.value: ', this.state.value);
    console.log('#RT2 NumberFieldComp new value event.target.value: ', value);
    const lastChar = value.substr(event.target.value.length-1);
    console.log('#RT2 NumberFieldComp this.state.acceptDecimals: ', this.state.acceptDecimals);
    console.log('#RT2 NumberFieldComp this.state.locale: ', this.state.locale);
    console.log('#RT2 NumberFieldComp this.state.decimalSep: ', this.state.decimalSep);
//    if(!isNaN(lastChar) || (this.state.acceptDecimals === true && lastChar === this.state.decimalSep)) {
  
//    if(this.state.keyCode === true) {
    	const decimal = value.indexOf(this.state.decimalSep);
    	const numDec = decimal > 0 ? value.substr(decimal+1).length: 0;
    	if (this.props.maximumFractionDigits===undefined || 
    			this.props.maximumFractionDigits>0&&numDec<=this.props.maximumFractionDigits ||
    			this.props.maximumFractionDigits===0&&decimal===-1 ) {
		    // se numerico oppure separatore decimale
	    	const num = numberFormat (value, this.state.locale);
	    	if (num!==undefined) {
		    this.setState({
		      value: num,
		      typing: true,
		    });
	    	}
		    if(this.props.onChange) {
		      this.props.onChange(event);
		    }
    	}
  //  }	
  };
	
  handleOnBlur = event => {
//    console.log('#RT2 NumberFieldComp handleOnBlur value this.state.value:', this.state.value);
//    console.log('#RT2 NumberFieldComp handleOnBlur value event.target.value:', event.target.value);
    const num = numberFormat (event.target.value, this.state.locale);
    if (num!==undefined) {
	    this.setState({
	      value: num,
	      typing: false,
	    });
    }
    if(this.props.onBlur) {
      this.props.onBlur(event);
    }
  };
  
  handleError = (isError, id) => {
	    console.log('#######TextFieldComp handleError', id + ' ' + isError);
	    this.setState({
	      isError: isError,
	    });    
	    if(this.props.onError) {
	      this.props.onError(id, isError);
	    }
	  };  

  handleOnKeyDown = event => {
	  var charCode = event.which;
	  if (charCode>=48&&charCode<=57) {
		  this.setState({
			  keyCode: true,
		  });
		  return true;
	  } else {
		  if (this.state.locale===locale_it){
			  if (this.state.acceptDecimals === true && String.fromCharCode(charCode) === this.state.decimalSep) { // virgola
				  this.setState({
					  keyCode: true,
				  });
				  return true;
			  }
		  }
	  }
	  this.setState({
		  keyCode: false,
	  });
	  return false;
  }
	
  valueText = () => {
   // const aValue = (this.state.typing === true) ? numberFormat(this.state.value, this.state.locale) : numberFormat(this.props.value, this.state.locale);
	  const aValue = (this.state.typing === true) ? this.state.value : this.props.value;
	  return aValue;
  };
	  
  render () {
	const { classes, onChange, aStyle, aStyleError, id, label, value, format, required, maxLength, controlloDati, disabled, locale, acceptDecimals, isControlError, onError, maximumFractionDigits, fullWidth } = this.props;
    const { t } = this.props; //hoc
//    console.log('###RT2 NumberFieldComp render props.value', value);
    const aValue = this.valueText();
//    console.log('###RT2 NumberFieldComp render aValue', aValue);    
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const isError = ((controlloDati === true) && isRequired && !isDisabled && (aValue === '')) || isControlError === true;
    const aStyleToApply = (isError) ? (((aStyleError === null) || (aStyleError === undefined)) ? classes.textFieldError : aStyleError) : aStyle;      
    return (
      <TextField
          error={isError}
          required={isRequired}
          disabled={isDisabled}
          id={id}
          label={t(label)} 
          className={classNames(aStyleToApply)}
          value={aValue}      
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
//          onKeyDown={this.handleOnKeyDown}
          margin='normal'
          variant='outlined'
          fullWidth={fullWidth}
          inputProps={
            {
              maxLength:this.props.maxLength,
            }
          }
      /> 
    )
  }; 
  
  componentDidUpdate(prevProps, prevState) {
	const { id, required, controlloDati, disabled } = this.props;	  
	const prevIsError = prevState.isError;
	const isRequired = required === true;
	const isDisabled = disabled === true;
	const aValue = isDisabled ? '' : this.valueText(controlloDati);
	const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === '');
	  
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
//	      console.log('#######prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
//	      console.log('#######prevIsError !== isError, isError = ' + isError + ', id: ' + id);
//	      console.log('#######prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);
    }
  }; 
};

NumberFieldComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(NumberFieldComp));
