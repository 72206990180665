import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../common/ExpansionPanelComp';
import DatiNuovaUtenza from './sezione/DatiNuovaUtenza';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function NuovaUtenzaPanel(props) {
	
  const { classes } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'nuovaUtenza'} componente={DatiNuovaUtenza} senzaLensIcon={true} /> 
    </div>
  );
}

NuovaUtenzaPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NuovaUtenzaPanel);
