import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiRipartizionePremio from './sezione/DatiRipartizionePremio';

const styles = theme => ({
  root: {
	width: '100%',
  },
});

function RipartizionePremioPanel(props) {
	
  const { classes, isValida } = props;
  return (
   <div className={classes.root}> 
	  <ExpansionPanelComp titolo={'ripartizionePremio'} componente={DatiRipartizionePremio} isValida={isValida} /> 
   </div>
  );
}

RipartizionePremioPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RipartizionePremioPanel);
