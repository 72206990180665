import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uris, fetchParam } from '../../../uris';

import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DividerComp from '../../common/DividerComp';

import * as actionsContraente from '../figure/action/contraente';
import * as actionTypeContraente from '../figure/actionType/contraente';
import * as actionsTitolareEff from '../figure/action/titolareEff';
import * as actionTypeTitolareEff from '../figure/actionType/titolareEff';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';

import { textTrim } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';

import { ESECUTORE_FISICO, PROVINCIA_ESTERA, TITOLARE_EFFETTIVO, 
		 CONTRAENTE_FISICO, TITOLARE_EFF_BENEF_VITA, TITOLARE_EFF_BENEF_MORTE, 
		 RAPPRESENTANTE_LEGALE, locale_it } from '../../../utility/jsConstants';

import moment from 'moment';
import 'moment/locale/it';
import RadioButtonComp from '../../common/RadioButtonComp';
import { radioButtonDomicilioDiversoResidenza } from '../../store/radioButtonStore';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: theme.textField,
    textFieldWidthPerc: theme.textFieldWidthPerc,
    textFieldWidthPercError: theme.textFieldWidthPercError,
    dateField: theme.dateField,
    fieldSet: theme.fieldSet,
  });

const mapStateToProps = state => {
    console.log('#RTCFA DatiDomicilio.mapStateToProps.state', state);
    return {
        dominio: state.areaDatiDominioReducer.dominio,
        datiDomicilioEsecutore: state.areaContraenteReducer.contraenteFisico.datiEsecutore.datiDomicilio,
        controlloDatiEsecutore: state.areaContraenteReducer.contraenteFisico.controlloDati,
        datiDomicilioTitolareEff: state.areaTitolareEffReducer.titolareEff.datiTitolareEff.datiDomicilio,
        controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
        datiDomicilioContrFis: state.areaContraenteReducer.contraenteFisico.datiDomicilio,
        controlloDatiContrFis: state.areaContraenteReducer.contraenteFisico.controlloDati,
        datiDomicilioTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.datiTitolareBenefMorteEff.datiDomicilio,
        controlloDatiTitEffMorte: state.areaBenefMorteReducer.benefMorteGiuridico.titolareEffBenefMorte.controlloDati,
        datiDomicilioTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.datiTitolareBenefVitaEff.datiDomicilio,
        controlloDatiTitEffVita: state.areaBenefVitaReducer.benefVitaGiuridico.titolareEffBenefVita.controlloDati,
        datiDomicilioRappLegale: state.areaContraenteReducer.contraenteGiuridico.datiRappLegale.datiDomicilio,
        controlloDatiRappLegale: state.areaContraenteReducer.contraenteGiuridico.controlloDati,
        scopeAmleto: state.genericReducer.generic.proposalInfo.scopeAmleto,
        scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
        tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
    };
};

const mapDispatchToProps = dispatch => {
    console.log('#RTCFA DatiDomicilio.mapDispatchToProps.dispatch', dispatch);
    return {
        updateDatiDomicilioEsecutore: (unDato, unActionType) => dispatch(actionsContraente.updateDatiDomicilioEsecutore(unDato, unActionType)),
        updateDatiDomicilioTitolareEff: (unDato, unActionType) => dispatch(actionsTitolareEff.updateDatiDomicilioTitolareEff(unDato, unActionType)),
        updateDatiDomicilioContraenteFisico: (unDato, unActionType) => dispatch(actionsContraente.updateDatiDomicilioContraenteFisico(unDato, unActionType)),       
        updateDatiDomicilioRappLegale: (unDato, unActionType) => dispatch(actionsContraente.updateDatiDomicilioRappLegale(unDato, unActionType)),
        setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
    };
};

class DatiDomicilio extends React.Component {
    constructor(props) {
        super(props);
        console.log('#RTCFA DatiDomicilio props', props);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        this.state = {
            statoDomicilio: datiDomicilio.statoDomicilio,
            descrStatoDomicilio: datiDomicilio.descrStatoDomicilio,
            provinciaDomicilio: datiDomicilio.provinciaDomicilio,
            descrProvinciaDomicilio: datiDomicilio.descrProvinciaDomicilio,
            comuneDomicilio: datiDomicilio.comuneDomicilio,
            descrComuneDomicilio: datiDomicilio.descrComuneDomicilio,
            toponimoDomicilio: datiDomicilio.toponimoDomicilio,
            descrToponimoDomicilio: datiDomicilio.descrToponimoDomicilio,
            indirizzoDomicilio: datiDomicilio.indirizzoDomicilio,
            numCivicoDomicilio: datiDomicilio.numCivicoDomicilio,
            capDomicilio: datiDomicilio.capDomicilio,
            provinciaItems: [],
            comuneItems: [],
            toponimoItems: [],
            stradarioItems: [],
            statoCampi: this.initStatoCampi(),
            isPresenteErrore: false,
        };
    };

    componentDidMount() {
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        this.setProvince(datiDomicilio ? datiDomicilio.statoDomicilio : '');
        this.setComuni(datiDomicilio ? datiDomicilio.provinciaDomicilio : '', moment().format('DD/MM/YYYY'));
        this.setToponimo(datiDomicilio ? datiDomicilio.comuneDomicilio : '');
        //this.setStradario(datiDomicilio ? datiDomicilio.comuneDomicilio : '', datiDomicilio ? datiDomicilio.toponimoDomicilio : '', datiDomicilio ? datiDomicilio.descrIndirizzoDomicilio : '');
    }

    datiDomicilioFrom = figura => {
        let datiDomicilio = null;
        if (figura === ESECUTORE_FISICO) {
            datiDomicilio = this.props.datiDomicilioEsecutore;
        } else if(figura === TITOLARE_EFFETTIVO) {
            datiDomicilio = this.props.datiDomicilioTitolareEff;
        } else if(figura === CONTRAENTE_FISICO) {
            datiDomicilio = this.props.datiDomicilioContrFis;
        } else if(figura === TITOLARE_EFF_BENEF_MORTE) {
            datiDomicilio = this.props.datiDomicilioTitEffMorte;
        } else if(figura === TITOLARE_EFF_BENEF_VITA) {
            datiDomicilio = this.props.datiDomicilioTitEffVita;
        } else if(figura === RAPPRESENTANTE_LEGALE) {
            datiDomicilio = this.props.datiDomicilioRappLegale;
        }
        return datiDomicilio;
    }

    controlloDatiFrom = figura => {
        let controlloDati = null;
        if (figura === ESECUTORE_FISICO) {
            controlloDati = this.props.controlloDatiEsecutore;
        } else if(figura === TITOLARE_EFFETTIVO) {
            controlloDati = this.props.controlloDatiTitolareEff;
        } else if(figura === CONTRAENTE_FISICO) {
            controlloDati = this.props.controlloDatiContrFis;
        } else if(figura === TITOLARE_EFF_BENEF_MORTE) {
            controlloDati = this.props.controlloDatiTitEffMorte;
        } else if(figura === TITOLARE_EFF_BENEF_VITA) {
            controlloDati = this.props.controlloDatiTitEffVita;
        } else if(figura === RAPPRESENTANTE_LEGALE) {
            controlloDati = this.props.controlloDatiRappLegale;
        }
        return controlloDati;
    }

    handleChangeDomicilioDiversoResidenza = event => {
        const nuovoDiverso = textTrim(event.target.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioDiverso = textTrim(datiDomicilio.domicilioDiversoResidenza);
        if (nuovoDiverso !== vecchioDiverso) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(nuovoDiverso, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(nuovoDiverso, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(nuovoDiverso, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(nuovoDiverso, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(nuovoDiverso, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_DOMICILIO_DIVERSO_RESIDENZA_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(nuovoDiverso, anActionType);
            }
            
            
            // Sono costretto a ricalcolare TcmIndividualeValida perchè potrebbe esseere rimasta selezionata una nazione in black list al momento di selezione = 'N'
            //2021-07-01 PFD: TCM_INDIV
            this.handleTcmIndividualeValida();
        }
    }

    handleChangeStatoDomicilio = event => {
        //    console.log('#RTCFA DatiDomicilio statoDomicilio new value:' + event);
        const nuovoStatoDomicilio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioStatoDomicilio = textTrim(datiDomicilio.statoDomicilio, true);
        if (nuovoStatoDomicilio !== vecchioStatoDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_STATO_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(event, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_STATO_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_STATO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            }
            this.setProvince(nuovoStatoDomicilio);
            this.setComuni('', moment().format('DD/MM/YYYY'));
            this.setToponimo('');
            this.setStradario('', '', '');
            this.setState({
                statoDomicilio: nuovoStatoDomicilio,
                descrStatoDomicilio: (event === null) ? '' : event.label,
                provinciaDomicilio: '',
                descrProvinciaDomicilio: '',
            });
        }
        
        //2021-07-01 PFD: TCM_INDIV
        this.handleTcmIndividualeValida();
    }
    
    //2021-07-01 PFD: TCM_INDIV
    //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
    //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
    handleTcmIndividualeValida = () => {
		if (this.props.scopeTcmEmissione) {
			const isTcmIndValida = isTcmIndividualeValida(locale_it);
			// se cambiata 
			if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
			 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
			}
		}  
	}

    handleChangeProvinciaDomicilio = event => {
        //    console.log('#RTCFA DatiDomicilio provinciaDomicilio new value:' + event);
        const nuovaProvinciaDomicilio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchiaProvinciaDomicilio = textTrim(datiDomicilio.provinciaDomicilio, true);
        if (nuovaProvinciaDomicilio !== vecchiaProvinciaDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_PROVINCIA_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(event, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_PROVINCIA_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_PROVINCIA_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            }
            this.setComuni(nuovaProvinciaDomicilio, moment().format('DD/MM/YYYY'));
            this.setToponimo('');
            this.setStradario('', '', '');
            this.setState({
                provinciaDomicilio: nuovaProvinciaDomicilio,
                descrProvinciaDomicilio: (event === null) ? '' : event.label,
                comuneDomicilio: '',
                descrComuneDomicilio: '',
            });
        }
    }

    handleChangeComuneDomicilio = event => {
        //    console.log('#RTCFA DatiDomicilio ComuneNascita new value:' + event);
        const nuovoComuneDomicilio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioComuneDomicilio = textTrim(datiDomicilio.comuneDomicilio, true);
        if (nuovoComuneDomicilio !== vecchioComuneDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(event, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_COMUNE_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            }
            this.setToponimo(nuovoComuneDomicilio);
            this.setStradario(nuovoComuneDomicilio, '', '');
            this.setState({
                comuneDomicilio: nuovoComuneDomicilio,
                descrComuneDomicilio: (event === null) ? '' : event.label,
                toponimoDomicilio: '',
                descrToponimoDomicilio: '',
            });
        }
    }

    //usato per PROVINCIA ESTERA
    handleChangeComuneDomicilioTextField = event => {
        //    console.log('#RTCFA DatiDomicilio ComuneNascita new value:' + event);
        const nuovoComuneDomicilio = textTrim(event.target.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioComuneDomicilio = textTrim(datiDomicilio.descrComuneDomicilio, true);
        if (nuovoComuneDomicilio !== vecchioComuneDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(nuovoComuneDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(nuovoComuneDomicilio, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_COMUNE_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(nuovoComuneDomicilio, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_COMUNE_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(nuovoComuneDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(nuovoComuneDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_COMUNE_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(nuovoComuneDomicilio, anActionType);
            }
            this.setToponimo('');
            this.setStradario('', '', '');
            this.setState({
                comuneDomicilio: '',
                descrComuneDomicilio: nuovoComuneDomicilio,
            });
            // need error Reset if previously had an error
            this.handleError('toponimoDomicilio', false);
        }
    }

    handleChangeToponimoDomicilio = event => {
        //    console.log('#RTCFA DatiDomicilio toponimoDomicilio new value:' + event.value);
        const nuovoToponimoDomicilio = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioToponimoDomicilio = textTrim(datiDomicilio.toponimoDomicilio, true);
        if (nuovoToponimoDomicilio !== vecchioToponimoDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(event, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_TOPONIMO_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(event, anActionType);
            }
            //this.setStradario(this.state.comuneDomicilio, (event === null) ? '' : event.value, this.state.descrIndirizzoDomicilio);
            this.setState({
                toponimoDomicilio: nuovoToponimoDomicilio,
                descrToponimoDomicilio: (event === null) ? '' : event.label,
            });
        }
    };

    //usato per PROVINCIA ESTERA
    handleChangeToponimoDomicilioTextField = event => {
        //    console.log('#RTCFA DatiDomicilio toponimoDomicilio new value:' + event.value);
        const nuovoToponimoDomicilio = textTrim(event.target.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioToponimoDomicilio = textTrim(datiDomicilio.descrToponimoDomicilio, true);
        if (nuovoToponimoDomicilio !== vecchioToponimoDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(nuovoToponimoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(nuovoToponimoDomicilio, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_TOPONIMO_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(nuovoToponimoDomicilio, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_TOPONIMO_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(nuovoToponimoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(nuovoToponimoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_TOPONIMO_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(nuovoToponimoDomicilio, anActionType);
            }
            //this.setStradario(this.state.comuneDomicilio, (event === null) ? '' : event.value, this.state.descrIndirizzoDomicilio);
            this.setState({
                toponimoDomicilio: '',
                descrToponimoDomicilio: nuovoToponimoDomicilio,
            });
        }
    };

    handleChangeIndirizzoDomicilio = event => {
        const nuovoIndirizzoDomicilio = textTrim(event.target.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioIndirizzoDomicilio = textTrim(datiDomicilio.indirizzoDomicilio, true);
        if (nuovoIndirizzoDomicilio !== vecchioIndirizzoDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(nuovoIndirizzoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(nuovoIndirizzoDomicilio, anActionType)
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_INDIRIZZO_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(nuovoIndirizzoDomicilio, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_INDIRIZZO_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(nuovoIndirizzoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(nuovoIndirizzoDomicilio, anActionType)
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_INDIRIZZO_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(nuovoIndirizzoDomicilio, anActionType)
            }
            this.setState({
                indirizzoDomicilio: nuovoIndirizzoDomicilio,
            });
        }
    }

    handleChangeNumCivicoDomicilio = event => {
        //    console.log('#RTCFA DatiDomicilio numCivicoDomicilio new value:' + event.target.value);
        const nuovoNumCivicoDomicilio = textTrim(event.target.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioNumCivicoDomicilio = textTrim(datiDomicilio.numCivicoDomicilio, true);
        if (nuovoNumCivicoDomicilio !== vecchioNumCivicoDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(nuovoNumCivicoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_DOMICLIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(nuovoNumCivicoDomicilio, anActionType);
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(nuovoNumCivicoDomicilio, anActionType)
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_NUMERO_CIVICO_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(nuovoNumCivicoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(nuovoNumCivicoDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_NUMERO_CIVICO_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(nuovoNumCivicoDomicilio, anActionType);
            }
            this.setState({
                numCivicoDomicilio: nuovoNumCivicoDomicilio,
            });
        }
    };

    handleChangeCapDomicilio = event => {
        //    console.log('#RTCFA DatiDomicilio capDomicilio new value:' + event.target.value);
        const nuovoCapDomicilio = textTrim(event.target.value, true);
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const vecchioCapDomicilio = textTrim(datiDomicilio.capDomicilio, true);
        if (nuovoCapDomicilio !== vecchioCapDomicilio) {
            let anActionType = null;
            if (this.props.figura === ESECUTORE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO_ESECUTORE;
                this.props.updateDatiDomicilioEsecutore(nuovoCapDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                anActionType = actionTypeTitolareEff.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO;
                this.props.updateDatiDomicilioTitolareEff(nuovoCapDomicilio, anActionType)
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                anActionType = actionTypeContraente.UPDATE_CAP_DOMICILIO_CONTRAENTE_FISICO;
                this.props.updateDatiDomicilioContraenteFisico(nuovoCapDomicilio, anActionType);
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                anActionType = actionTypeContraente.UPDATE_CAP_DOMICILIO_RAPP_LEGALE;
                this.props.updateDatiDomicilioRappLegale(nuovoCapDomicilio, anActionType);
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                anActionType = actionTypeTitolareEff.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_MORTE;
                this.props.updateDatiDomicilioTitolareEff(nuovoCapDomicilio, anActionType)
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                anActionType = actionTypeTitolareEff.UPDATE_CAP_DOMICILIO_TITOLARE_EFFETTIVO_VITA;
                this.props.updateDatiDomicilioTitolareEff(nuovoCapDomicilio, anActionType)
            }
            this.setState({
                capDomicilio: nuovoCapDomicilio,
            });
        }
    };

    setProvince = (nazione) => {
        if (textTrim(nazione, true) !== '') {
            const opts = {
                nazione: nazione,
            };
            fetch(uris.provinciaItems, fetchParam(opts))
                .then(
                    response => response.json(),
                    error => console.error('An error occurred.', error),
                )
                .then((result) => {
                    // If request is good update state with fetched data
                    this.setState({
                        provinciaItems: result,
                    });
                });
        } else {
            this.setState({
                provinciaItems: [],
            });
        }
    }

    setComuni = (codProvincia, dataNascita) => {
        if (textTrim(codProvincia, true) !== '' && textTrim(dataNascita, true) !== '') {
            const opts = {
                codiceProvincia: codProvincia,
                dataRiferimentoComune: dataNascita,
            };
            fetch(uris.comuneItems, fetchParam(opts))
                .then(
                    response => response.json(),
                    error => console.error('An error occurred.', error),
                )
                .then((result) => {
                    // If request is good update state with fetched data
                    this.setState({
                        comuneItems: result,
                    });
                });
        } else {
            this.setState({
                comuneItems: [],
            });
        }
    }

    setToponimo = (codiceIstat) => {
        if (textTrim(codiceIstat, true) !== '') {
            const opts = {
                codiceIstat: codiceIstat,
            };
            fetch(uris.toponimoItems, fetchParam(opts))
                .then(
                    response => response.json(),
                    error => console.error('An error occurred.', error),
                )
                .then((result) => {
                    // If request is good update state with fetched data
                    this.setState({
                        toponimoItems: result,
                    });
                });
        } else {
            this.setState({
                toponimoItems: [],
            });
        }
    }

    setStradario = (codiceIstat, toponimo, descrStrada) => {
        if (textTrim(codiceIstat, true) !== '' && textTrim(toponimo, true) !== '') {
            const opts = {
                codiceIstat: codiceIstat,
                descrToponimo: toponimo,
                descrStrada: descrStrada,
            };
            fetch(uris.stradarioItems, fetchParam(opts))
                .then(
                    response => response.json(),
                    error => console.error('An error occurred.', error),
                )
                .then((result) => {
                    // If request is good update state with fetched data
                    this.setState({
                        stradarioItems: result,
                    });
                });
        } else {
            this.setState({
                stradarioItems: [],
            });
        }
    }

    isPartialFilled() {
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const controlloDati = this.controlloDatiFrom(this.props.figura);
        if (controlloDati) {
            return true;
        }
        return false;
    }

    isRequiredDomicilioDiversoResidenza = () => {
        let required = false;
        if (this.props.scopeAmleto) {
            if (this.props.figura === ESECUTORE_FISICO) {
                required = true;
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                required = true;
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                required = true;
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                required = true;
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                required = true;
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                required = true;
            }
        }
        return required;
    }

    isRequiredStatoDomicilio = () => {
        let required = false;
        const domicilioDiversoResidenza = this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza;
        if (domicilioDiversoResidenza === 'S') {
            required = true;
        }
        return required;
    }

    isRequiredProvinciaDomicilio = () => {
        let required = false;
        const domicilioDiversoResidenza = this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza;
        if (domicilioDiversoResidenza === 'S') {
            required = true;
        }
        return required;
    }

    isRequiredComuneDomicilio = () => {
        let required = false;
        const domicilioDiversoResidenza = this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza;
        if (domicilioDiversoResidenza === 'S') {
            required = true;
        }
        return required;
    }

    isRequiredToponimoDomicilio = () => {
        let required = false;
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        if (datiDomicilio.domicilioDiversoResidenza === 'S') {
            required = true;
        }
        if(datiDomicilio.provinciaDomicilio === PROVINCIA_ESTERA) {
            required = false;
        }
        return required;
    }

    isRequiredIndirizzoDomicilio = () => {
        let required = false;
        const domicilioDiversoResidenza = this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza;
        if (domicilioDiversoResidenza === 'S') {
            required = true;
        }
        return required;
    }

    isRequiredNumCivicoDomicilio = () => {
        let required = false;
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        if (datiDomicilio.domicilioDiversoResidenza === 'S') {
            required = true;
        }
        if(datiDomicilio.provinciaDomicilio === PROVINCIA_ESTERA) {
            required = false;
        }
        return required;
    }

    isRequiredCapDomicilio = () => {
        let required = false;
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        if (datiDomicilio.domicilioDiversoResidenza === 'S') {
            required = true;
        }
        if(datiDomicilio.provinciaDomicilio === PROVINCIA_ESTERA) {
            required = false;
        }
        return required;
    }

    initStatoCampi = () => {
        return [{ id: 'statoDomicilio', isError: false },
        { id: 'provinciaDomicilio', isError: false },
        { id: 'comuneDomicilio', isError: false },
        { id: 'toponimoDomicilio', isError: false },
        { id: 'indirizzoDomicilio', isError: false },
        { id: 'numCivicoDomicilio', isError: false },
        { id: 'capDomicilio', isError: false },
        ];
    }

    handleError = (id, isError) => {
        let unErrore = null;
        let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
        let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
        if (campoOnId.length > 0) {
            unErrore = campoOnId[0];
            unErrore.isError = isError;
        } else {
            unErrore = { id: id, isError: isError };
        }
        this.setState({
            statoCampi: [...campoWithoutId, unErrore],
        });
    }

    isDisabledDomicilioDiversoResidenza = () => {
        let disabled = true;
        if (this.props.scopeAmleto) {
            if (this.props.figura === ESECUTORE_FISICO) {
                disabled = false;
            } else if(this.props.figura === TITOLARE_EFFETTIVO) {
                disabled = false;
            } else if(this.props.figura === CONTRAENTE_FISICO) {
                disabled = false;
            } else if(this.props.figura === RAPPRESENTANTE_LEGALE) {
                disabled = false;
            } else if(this.props.figura === TITOLARE_EFF_BENEF_MORTE) {
                disabled = false;
            } else if(this.props.figura === TITOLARE_EFF_BENEF_VITA) {
                disabled = false;
            }
        }
        return disabled;
    }

    isDisabledStatoDomicilio = () => {
        let disabled = true;
        if (this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza === 'S') {
            disabled = false;
        }
        return disabled;
    }

    isDisabledIndirizzo = () => {
        let disabled = true;
        if (this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza === 'S') {
            disabled = false;
        }
        return disabled;    }

    isDisabledCivico = () => {
        let disabled = true;
        if (this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza === 'S') {
            disabled = false;
        }
        return disabled;    }

    isDisabledCap = () => {
        let disabled = true;
        if (this.datiDomicilioFrom(this.props.figura).domicilioDiversoResidenza === 'S') {
            disabled = false;
        }
        return disabled;    }

    isDisabledProvincia = () => {
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const statoDomicilio = textTrim(datiDomicilio.statoDomicilio, true);
        return (statoDomicilio === '');
    }

    isDisabledComune = () => {
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const provinciaDomicilio = textTrim(datiDomicilio.provinciaDomicilio, true);
        return (this.isDisabledProvincia() === true) || (provinciaDomicilio === '');
    }

    isDisabledToponimo = () => {
        const datiDomicilio = this.datiDomicilioFrom(this.props.figura);
        const comuneDomicilio = textTrim(datiDomicilio.comuneDomicilio, true);
        return (this.isDisabledComune() === true) || (comuneDomicilio === '');
    }

    render() {
        console.log('#RT2 DatiDomicilio.this.props', this.props);
        const { classes, figura, labelFieldSet, dominio } = this.props;
        const datiDomicilio = this.datiDomicilioFrom(figura);
        const controlloDati = this.controlloDatiFrom(figura);
        //    const { t } = this.props; //hoc
        return (
            <Fragment>
                {/*<fieldset className={classes.fieldSet}>
       <legend><Typography variant='h6'>{t('datiDiDomicilio')}</Typography></legend>*/}
                {labelFieldSet &&
                    <DividerComp titolo={labelFieldSet} variant='subtitle1' />
                }
                <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                    <Grid item md={6} xs={12}>
                        <RadioButtonComp onChange={this.handleChangeDomicilioDiversoResidenza} radioComp={radioButtonDomicilioDiversoResidenza}
                            selectedValue={datiDomicilio.domicilioDiversoResidenza} required={this.isRequiredDomicilioDiversoResidenza()}
                            disabled={this.isDisabledDomicilioDiversoResidenza()} controlloDati={controlloDati} id='domicilioDiversoResidenza'
                            onError={this.handleError} />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='center' alignItems='flex-start'>
                    <Grid item md={4} xs={12}>
                        <SelectComp onChange={this.handleChangeStatoDomicilio} options={dominio.nazioneItems} ricerca='comboRicerca' etichetta='stato'
                            value={{ value: datiDomicilio.statoDomicilio, label: datiDomicilio.descrStatoDomicilio }} required={this.isRequiredStatoDomicilio()}
                            id='statoDomicilio' onError={this.handleError} controlloDati={controlloDati} disabled={this.isDisabledStatoDomicilio()} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SelectComp onChange={this.handleChangeProvinciaDomicilio} options={this.state.provinciaItems} ricerca='comboRicerca' etichetta='provincia'
                            value={{ value: datiDomicilio.provinciaDomicilio, label: datiDomicilio.descrProvinciaDomicilio }} disabled={this.isDisabledProvincia()}
                            required={this.isRequiredProvinciaDomicilio()} controlloDati={controlloDati} id='provinciaDomicilio' onError={this.handleError} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {datiDomicilio.provinciaDomicilio !== PROVINCIA_ESTERA &&
                            <SelectComp onChange={this.handleChangeComuneDomicilio} options={this.state.comuneItems} ricerca='comboRicerca' etichetta='comune'
                                value={{ value: datiDomicilio.comuneDomicilio, label: datiDomicilio.descrComuneDomicilio }} disabled={this.isDisabledComune()}
                                required={this.isRequiredComuneDomicilio()} id='comuneDomicilio' onError={this.handleError} controlloDati={controlloDati} />
                        }
                        {datiDomicilio.provinciaDomicilio === PROVINCIA_ESTERA &&
                            <TextFieldComp onBlur={this.handleChangeComuneDomicilioTextField} aStyle={classes.textFieldWidthPerc} id='comuneDomicilio'
                                label='comune' value={datiDomicilio.descrComuneDomicilio} required={this.isRequiredComuneDomicilio()}
                                onError={this.handleError} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                        }
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {datiDomicilio.provinciaDomicilio !== PROVINCIA_ESTERA &&
                            <SelectComp onChange={this.handleChangeToponimoDomicilio} options={this.state.toponimoItems} ricerca='comboRicerca' etichetta='toponimo'
                                value={{ value: datiDomicilio.toponimoDomicilio, label: datiDomicilio.descrToponimoDomicilio }} disabled={this.isDisabledToponimo()}
                                required={this.isRequiredToponimoDomicilio()} id='toponimoDomicilio' onError={this.handleError} controlloDati={controlloDati} />
                        }
                        {datiDomicilio.provinciaDomicilio === PROVINCIA_ESTERA &&
                            <TextFieldComp onBlur={this.handleChangeToponimoDomicilioTextField} aStyle={classes.textFieldWidthPerc} id='toponimoDomicilio'
                                label='toponimo' value={datiDomicilio.descrToponimoDomicilio} required={this.isRequiredToponimoDomicilio()}
                                onError={this.handleError} controlloDati={controlloDati} />
                        }
                    </Grid>
                    <Grid item md={5} xs={12}>
                        {/*<SelectComp onChange={this.handleChangeIndirizzoDomicilio} options={this.state.stradarioItems} ricerca='comboRicerca' etichetta='indirizzo'
        	           value={{value: datiDomicilio.indirizzoDomicilio, label: datiDomicilio.descrIndirizzoDomicilio}}
                       required={this.isRequiredIndirizzoDomicilio()} id='indirizzoDomicilio' onError={this.handleError} controlloDati={controlloDati} />*/}
                        <TextFieldComp onBlur={this.handleChangeIndirizzoDomicilio} aStyle={classes.textFieldWidthPerc} id='indirizzoDomicilio' disabled={this.isDisabledIndirizzo()}
                            label='indirizzo' value={datiDomicilio.indirizzoDomicilio} required={this.isRequiredIndirizzoDomicilio()}
                            onError={this.handleError} controlloDati={controlloDati} aStyleError={classes.textFieldWidthPercError} />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <TextFieldComp onBlur={this.handleChangeNumCivicoDomicilio} aStyle={classes.textField} label='numCivico' disabled={this.isDisabledCivico()}
                            value={datiDomicilio.numCivicoDomicilio} required={this.isRequiredNumCivicoDomicilio()}
                            id='numCivicoDomicilio' onError={this.handleError} controlloDati={controlloDati} />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <TextFieldComp onBlur={this.handleChangeCapDomicilio} aStyle={classes.textField} label='cap' disabled={this.isDisabledCap()}
                            value={datiDomicilio.capDomicilio} required={this.isRequiredCapDomicilio()}
                            id='capDomicilio' onError={this.handleError} controlloDati={controlloDati} />
                    </Grid>
                </Grid>
                {/*</fieldset>*/}
            </Fragment>
        );
    }

    isInError(prevProps, prevState) {
        let isPresenteCampiConErrore = false;
        this.state.statoCampi.map(unoStatoCampo => {
            return (
                isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
            );
        });
        console.log('########DatiDomicilio componentDidUpdate this.state.statoCampi', this.state.statoCampi);
        //    console.log('########DatiDomicilio componentDidUpdate isPresenteCampiConErrore', isPresenteCampiConErrore);
        //    console.log('########DatiDomicilio componentDidUpdate this.state.isPresenteErrore', this.state.isPresenteErrore);
        if (this.state.isPresenteErrore !== isPresenteCampiConErrore) {
            if (this.props.onError) {
                this.props.onError(this.props.id, isPresenteCampiConErrore);
            }
            this.setState({
                isPresenteErrore: isPresenteCampiConErrore,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.isInError(prevProps, prevState);
    }
}

DatiDomicilio.propTypes = {
    classes: PropTypes.object.isRequired,
    figura: PropTypes.string.isRequired,
    dominio: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatiDomicilio));
