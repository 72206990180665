import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import ButtonComp from '../../common/ButtonComp';
import TextFieldAdornComp from '../../common/TextFieldAdornComp';
import TextFieldComp from '../../common/TextFieldComp';
import SelectComp from '../../common/SelectComp';
import DatePickerComp from '../../common/DatePickerComp';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/it';
import { uris, fetchParam } from '../../../uris';

import * as actions from './action/datiNuovaUtenza';
import * as actionType from './actionType/datiNuovaUtenza';

import { ADMIN, MAX_DATE } from '../../../utility/jsConstants';
import { textTrim } from '../../../utility/genericUtility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: theme.textField,
  textFieldWidthPerc: theme.textFieldWidthPerc,
  dateField: theme.dateField,
  fieldSet: theme.fieldSet,
  button: theme.button,
  dateFieldWidth: {
    width: 270,
  },
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
  return {
	dominio: state.areaDatiDominioReducer.dominio,
	loginUser: state.areaProdottoReducer.loginUser,
	datiNuovaUtenza: state.areaDatiNuovaUtenzaReducer.gestioneUtenza.datiNuovaUtenza,
  };
};
	
//Inietta le azioni dello store nella props
const mapDispatchToProps = dispatch => {
  return {
	updateDatiNuovaUtenza: (unDato, unActionType) => dispatch(actions.updateDatiNuovaUtenza(unDato, unActionType)),
	salvaDatiNuovaUtenza: (controlloDati) => dispatch(actions.salvaDatiNuovaUtenza(controlloDati)),
	inserimentoDatiNuovaUtenza: (utente, dataScadenza) => dispatch(actions.inserimentoDatiNuovaUtenza(utente, dataScadenza)),
  };
};

class DatiNuovaUtenza extends React.Component {
  constructor(props) {
    super(props);
    const datiNuovaUtenza = this.props.datiNuovaUtenza;
    this.state = {
      mandato: datiNuovaUtenza.mandato,
      descMandato:  datiNuovaUtenza.descMandato,
      cognome: datiNuovaUtenza.cognome,
	  nome: datiNuovaUtenza.nome,
	  email: datiNuovaUtenza.email,
	  codiceConsulente: datiNuovaUtenza.codiceConsulente,
	  utente: datiNuovaUtenza.utente,
	  profilo: datiNuovaUtenza.profilo,
      descProfilo: datiNuovaUtenza.descProfilo,
      dataCreazione: datiNuovaUtenza.dataCreazione, 
      dataScadenza: datiNuovaUtenza.dataScadenza,
      codiceErrore: '',
    };
  };
  
  componentDidMount() {
	if (this.state.dataCreazione === null) {
	  const dataCorrente = moment();
	  const dataScadenza = moment(MAX_DATE, "DD/MM/YYYY");
       this.props.updateDatiNuovaUtenza(dataCorrente, actionType.UPDATE_DATA_CREAZIONE_UTENTE);
       this.props.updateDatiNuovaUtenza(dataScadenza, actionType.UPDATE_DATA_SCADENZA_UTENTE);
       this.setState({
    	 dataCreazione: dataCorrente,
    	 dataScadenza: dataScadenza,
       });
	} 
	let mand = {
	  value: this.props.loginUser.mandato.trim(),
	  label: this.props.loginUser.descrMandato.trim(),
	};
	this.props.updateDatiNuovaUtenza(mand, actionType.UPDATE_MANDATO_UTENTE);
	this.setState({
	  mandato: this.props.loginUser.mandato.trim(),
	  descMandato: this.props.loginUser.descrMandato.trim(),
    });
  }
	  
  handleChangeConfermaDatiNuovaUtenza = event => {
    this.props.salvaDatiNuovaUtenza(true, actionType.SALVA_DATI_NUOVA_UTENZA);
    if(this.state.cognome !== '' && this.state.nome !== '' && this.state.email !== '' && this.state.profilo !== ''){
        this.setInserimentoUtente();
    }
  }  
  
  setInserimentoUtente = () => {
    const opts = {
	  codiceMandato: this.state.mandato,
	  cognome: this.state.cognome,
	  nome: this.state.nome,
	  email: this.state.email,
	  codiceProfilo: this.state.profilo,
	};
	fetch(uris.inserimentoUtenteItems, fetchParam(opts))
	.then (
	  response => response.json(),
	  error => console.error('An error occurred.', error),
	)
	.then((result) => {
	  // If request is good update state with fetched data
	  const codiceErrore = (result.codiceErrore===undefined || result.codiceErrore===null) ? 'OK' : result.codiceErrore.trim();  
	  if(codiceErrore==='OK'){
	    const dataScadenza = moment(result.dataScadenzaPwd, "YYYY-MM-DD");
	    this.props.inserimentoDatiNuovaUtenza(result.user, dataScadenza);
	    this.setState({
	      utente: result.user,
	      dataScadenza: dataScadenza,
	      codiceErrore: codiceErrore,
	    });
	  }else{
		this.setState({
		  codiceErrore: codiceErrore,
		}); 
	  }
	});
  }
  
  handleChangeMandato = event => {
	const nuovoMandato = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
	const vecchioMandato = textTrim(this.state.mandato, true);
	if (nuovoMandato !== vecchioMandato) {
      this.props.updateDatiNuovaUtenza(event, actionType.UPDATE_MANDATO_UTENTE);   
      this.setState({ 
        mandato: (event === null) ? '' : event.value,
        descMandato: (event === null) ? '' : event.label,
      });
	}
  };
	  
  handleChangeCognome = event => {
	const nuovoCognome = textTrim(event.target.value, true);
	const vecchioCognome = textTrim(this.state.cognome, true);
	if (nuovoCognome !== vecchioCognome) {
      this.props.updateDatiNuovaUtenza(event.target.value, actionType.UPDATE_COGNOME_UTENTE);
      this.setState({ 
        cognome: event.target.value, 
      });
	}
  };
  
  handleChangeNome = event => {
	const nuovoNome = textTrim(event.target.value, true);
	const vecchioNome = textTrim(this.state.nome, true);
	if (nuovoNome !== vecchioNome) {
      this.props.updateDatiNuovaUtenza(event.target.value, actionType.UPDATE_NOME_UTENTE);   
      this.setState({ 
        nome: event.target.value, 
      });
	}
  };
  
  handleChangeEmail = event => {
	const nuovoEmail = textTrim(event.target.value, true);
	const vecchioEmail = textTrim(this.state.email, true);
	if (nuovoEmail !== vecchioEmail) {
      this.props.updateDatiNuovaUtenza(event.target.value, actionType.UPDATE_EMAIL_UTENTE);   
      this.setState({ 
        email: event.target.value, 
      });
	}
  };
  
  handleChangeCodiceConsulente = event => {
	const nuovoCodiceConsulente = textTrim(event.target.value, true);
	const vecchioCodiceConsulente = textTrim(this.state.codiceConsulente, true);
	if (nuovoCodiceConsulente !== vecchioCodiceConsulente) {
      this.props.updateDatiNuovaUtenza(event.target.value, actionType.UPDATE_CODICE_CONSULENTE_UTENTE);   
      this.setState({ 
        codiceConsulente: event.target.value, 
      });
	}
  };
  
  handleChangeProfilo = event => {
	const nuovoProfilo = ((event === null) || (event === undefined)) ? '' : textTrim(event.value, true);
	const vecchioProfilo = textTrim(this.state.profilo, true);
	if (nuovoProfilo !== vecchioProfilo) {
      this.props.updateDatiNuovaUtenza(event, actionType.UPDATE_PROFILO_UTENTE);   
      this.setState({ 
        profilo: (event === null) ? '' : event.value,
        descProfilo: (event === null) ? '' : event.label,
      });
	}
  };
   
  isRequiredMandato = () => {
    return true;
  }
   
  isRequiredCognome = () => {
    return true;
  }
  
  isRequiredNome = () => {
    return true;
  }
  
  isRequiredEmail = () => {
    return true;
  }
  
  isRequiredCodiceConsulente = () => {
    return false;
  }
  
  isRequiredProfilo = () => {
    return true;
  }
    
  render() {
    const { classes, dominio, datiNuovaUtenza } = this.props;  
    const controlloDati = datiNuovaUtenza.controlloDati;
    const { t } = this.props; //hoc
    
    return (
     <Fragment>
       <Grid container direction='row' justify='center' alignItems='flex-start'>
         <Grid item xs={2}></Grid>
         <Grid item xs={8}>
	       <Grid container direction='row' justify='center' alignItems='flex-start'>
	         <Grid item xs={12}>
		       {this.props.loginUser.profilo.trim()===ADMIN &&
		         <TextFieldComp aStyle={classes.textFieldWidthPerc} id='mandato' label='mandato' value={datiNuovaUtenza.descMandato} readonly={true} /> 
		       }
		       {this.props.loginUser.profilo.trim()!==ADMIN &&
		         <SelectComp onChange={this.handleChangeMandato} options={dominio.mandatoItems} ricerca='comboRicerca' etichetta='mandato' id='mandato' 
			       value={{value: datiNuovaUtenza.mandato, label: datiNuovaUtenza.descMandato}} required={this.isRequiredMandato()} controlloDati={controlloDati} />
		       }
	         </Grid>  
	         <Grid item md={6} xs={12}>
	           <TextFieldComp onBlur={this.handleChangeCognome} aStyle={classes.textFieldWidthPerc} id='cognome' label='general.cognome' value={datiNuovaUtenza.cognome} 
	             required={this.isRequiredCognome()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp onBlur={this.handleChangeNome} aStyle={classes.textFieldWidthPerc} id='nome' label='general.nome' value={datiNuovaUtenza.nome} 
	             required={this.isRequiredNome()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldAdornComp onBlur={this.handleChangeEmail} id='email' label='indEmail' value={datiNuovaUtenza.email} aStyle={classes.textFieldWidthPerc} margin='normal' variant='outlined' 
	             adornComp={ <AlternateEmail />} adornPosit='end' required={this.isRequiredEmail()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp onBlur={this.handleChangeCodiceConsulente} aStyle={classes.textFieldWidthPerc} id='codiceConsulente' label='codiceConsulente' value={datiNuovaUtenza.codiceConsulente} 
	             required={this.isRequiredCodiceConsulente()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <TextFieldComp aStyle={classes.textFieldWidthPerc} id='utente' label='utente' value={datiNuovaUtenza.utente} readonly={true}/>
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <SelectComp onChange={this.handleChangeProfilo} options={dominio.profiloItems} ricerca='comboRicerca' etichetta='profilo' id='profilo'
	        	 value={{value: datiNuovaUtenza.profilo, label: datiNuovaUtenza.descProfilo}} required={this.isRequiredProfilo()} controlloDati={controlloDati} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	           <DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} id='dataCreazione' label='dataCreazione' 
	             value={datiNuovaUtenza.dataCreazione} readonly={true} />
	         </Grid>
	         <Grid item md={6} xs={12}>	
	         {/*<DatePickerComp aStyle={classNames(classes.dateField, classes.dateFieldWidth)} aStyleError={classNames(classes.dateField, classes.dateFieldWidth)} id='dataScadenza' label='dataScadenza' 
	           value={datiNuovaUtenza.dataScadenza} readonly={true} />*/}
	         </Grid>
	         <Grid item xs={12}>
		       <Grid container direction="row" justify="center" alignItems="center">	
		         <Grid item>
		           {this.state.codiceErrore==='OK' &&
		    	     <Typography className={classes.textErrorMessage}>{t('salvaOk')}</Typography>
		           }
		           {(this.state.codiceErrore!=='' && this.state.codiceErrore!=='OK') &&
		    	     <Typography className={classes.textErrorMessage}>{t(this.state.codiceErrore)}</Typography>
		           }
		          </Grid>
		        </Grid>
		      </Grid>
	         <Grid item xs={12}>
		       <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
		         <Grid item>
		           <ButtonComp
		             aClassName={classes.button}
		             buttonLabel={'salva'}
		             onClick={this.handleChangeConfermaDatiNuovaUtenza}
		            />
		          </Grid>
		        </Grid>
		      </Grid>
	        </Grid>
	      </Grid>
	      <Grid item xs={2}></Grid>
		</Grid>
      </Fragment>	  
    );
  }
}

DatiNuovaUtenza.propTypes = {
  classes: PropTypes.object.isRequired, 
  dominio: PropTypes.object.isRequired,  
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiNuovaUtenza)));
