import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiQuestionarioSanitarioSemplificato from './sezione/DatiQuestionarioSanitarioSemplificato';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function QuestionarioSanitarioSemplificatoPanel(props) {
	
  const { classes, isValida } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'questionarioSanitarioSemplificato'} componente={DatiQuestionarioSanitarioSemplificato} isValida={isValida} /> 
    </div>
  );
}

QuestionarioSanitarioSemplificatoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuestionarioSanitarioSemplificatoPanel);
