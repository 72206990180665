//import { datiTitolareEff, } from './sezioni/sezioniAnagrafica';
import { datiTitolareEff, } from './sezioni/sezioniAnagraficaTitolareEff';

export const titolareEff = {
  anagraficaValida: null,
  controlloDati: '',  
  datiTitolareEff: datiTitolareEff,
};


export const titolareEffList = [

];