import store from '../store';
import moment from 'moment';
import { locale_it,  
	     RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, 
	     PERSONA_FISICA, PERSONA_GIURIDICA } from './jsConstants';

export const textTrim = (str, trim) => {
//  console.log('###textTrim trim', trim);
  const aValue = (trim === true) ? (((str === undefined) || (str === null)) ? '' : str.trim()) : (((str === undefined) || (str === null)) ? '' : str);
  return aValue;
};
  
export const comboValue = aComboValue => {
  const aComboElement = (((aComboValue !== undefined) && (aComboValue !== null)) && 
                         (textTrim(aComboValue.value, true) !== '') && 
                         (textTrim(aComboValue.label, true) !== '')) ? aComboValue : null;
  return aComboElement;
};

export const checkBoxValue = aValue => {
  const checkValue = ((aValue === undefined) || (aValue === null)) ? false : aValue;
  return checkValue;
}

//export const numberFormat = (str, locale) => {
//  let aValue = textTrim(str, true);
//  let num = (locale === locale_it) ? aValue.replace(/[.]+/gi, '') : aValue.replace(/[,]+/gi, '');
//  return num.toLocaleString(locale);
//};

export const getNumber = (str, locale) => {
  const numClean = cleanNumber(str, locale);
  if (numClean!=='') {
    try {
      const isOk = numClean.match(/^\d+(,\d*)?$/g);
      if (!isOk) {
        return undefined;	 
      }
    } catch(err) {
    }		  
  }
  return str;
}

export const numberFormat = (str, locale, options) => { 
  const number = getNumber(str, locale);
  if (number!==undefined){
    return number.toLocaleString(locale, options);		
  }
  return undefined;
};

export const cleanNumber = (str, locale, options) => {
  let aValue = textTrim(str, true);
  const isCurrency = options!==undefined&&options!==null&&options.currency!==null&&options.currency!==undefined;
  let num = null;
  if (locale === locale_it){
//	  num = aValue.replace(/[.]+/gi, ''); // sep migliaia
//	  num = num.replace(/[,]+/gi, '.'); //sep decimale	  
	  num = aValue;
  } else { 
	  num = aValue.replace(/[,]+/gi, '');
  }  
  num = isCurrency?num.replace(/[€]+/gi, ''):num;
  return textTrim(num, true);
};

export const isDataValid = (aDateMoment) => {
  if(aDateMoment) {
    aDateMoment = moment(aDateMoment);
  }
  return !((aDateMoment !== null) && (aDateMoment !== undefined) && (!aDateMoment.isValid()));
};

export const dataFormat = (aDateMoment, formatPattern) => {
  const aValue = ((aDateMoment === null) || (aDateMoment === undefined)) ? null : moment(aDateMoment).format(formatPattern);  
  return aValue;
};

export const concatena = (dato1, separatore, dato2, maiuscolo) => {
  const unDato = textTrim(dato1) + separatore + textTrim(dato2);
  return (maiuscolo) ? unDato.toUpperCase() : unDato;
};

// NOT Currency format
export const toNumber = (str, locale) => {
  let num = undefined;
  let aValue = textTrim(str, true);
  if (locale === locale_it) {
    if (aValue.indexOf(',')!==-1) {
      num = parseFloat(aValue.replace(/[,]+/gi, '.'));
    } else {
      num = parseInt(aValue);
    }
  }
  return num;
};
	
export const showSezione = (valueItems, campo) => {
  let check = false;
  if(valueItems) {
    valueItems.forEach(function(item, index, array) {
//	  if(item.value === campo && check===false) {
  	  if(item === campo && check === false) {    	
	    check=true;
	  }
    });
  }
  return check;
}



export const isContraenteGiuridico = (contraenteGiuridico) => {	
	return contraenteGiuridico !== undefined && 
	       contraenteGiuridico !== null &&
		   contraenteGiuridico.controlloDati === true &&
		   contraenteGiuridico.anagraficaValida !== null;
};

export const isTabSelezionatoContraenteGiuridico = () => {	
	const state = store.getState();
	const tabSelezionatoContraente = state.genericReducer.generic.tabSelezionatoContraente;
	
	return tabSelezionatoContraente === 1; //  0 - CONTRANTE FISIO / 1 - CONTRANTE GIURIDICO
};

export const isContraenteFisico = (contraenteFisico) => {	
	return  contraenteFisico !== undefined &&
	        contraenteFisico !== null &&
			contraenteFisico.controlloDati === true &&
			contraenteFisico.anagraficaValida !== null;
};

export const jsonCopy = (jsonObj) => {
  return JSON.parse(JSON.stringify(jsonObj));
};

export const calcolaTotalePercentualeFondi = (fondiList) => {
	let tot = 0;
	
	if (fondiList !== null && fondiList !==  undefined ) {
		fondiList.forEach(function(item, index, array) {
			if(!isNaN(item.fondo) && item.fondo !== '') {
				tot = tot + parseInt(item.fondo)
			}
		});
	}
    return tot;
};

export const checkEtaMassima = (dataRiferimento, dataNascita, anniMassimi, mesiMassimi) => {
	let natoDopoDataRif = false;
    const dataNascitaMassima = dataRiferimento.subtract(anniMassimi, 'years').subtract(mesiMassimi ,'month');
    if(dataNascita) {
		dataNascitaMassima.set({
        'hour': 0,
        'minute': 0,
        'second': 0,
        'millisecond': 0
      });
      dataNascita.set({
        'hour': 0,
        'minute': 0,
        'second': 0,
        'millisecond': 0
      });
      if(dataNascita.isAfter(dataNascitaMassima)){
			natoDopoDataRif = true;
        }
	}
	return natoDopoDataRif;
};

/**
 * Rounding mode: Round half up
 * L'arrotondamento dei numeri negativi e' differente da quello positivo
 * TODO: migliorare per verificare se il numero e' gia in forma esponenziale 
 */
Number.prototype.round = function (decimalPlaces = 0) {
    var num = Math.round(this + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
};