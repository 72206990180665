import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabDatiRiepilogoTitolareEff from '../sezione/TabDatiRiepilogoTitolareEff';
import DatiTitolareEff from '../sezione/DatiTitolareEff';

import ButtonComp from '../../common/ButtonComp';

import { trovaDatiTitolareEffInList, toNumber } from '../../../utility/genericUtility';
import { isTcmIndividualeValida } from '../../../utility/tcmIndividualeUtility';
import { locale_it, TITOLARE_EFFETTIVO, 
	     CODICE_QUALIFICA_FIDUCIANTE, 
	     CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE, 
	     CODICE_SAE_FIDUCIARIA_GESTIONE } from '../../../utility/jsConstants';
import { creaTitolareEff } from '../../../utility/objectUtility';
import { errorFiducianteContraente } from '../../../utility/storeUtility';

	     
import * as actions from './action/titolareEff';
import * as actionType from './actionType/titolareEff';
import * as actionGeneric from '../../generic/action/generic';
import * as actionTypeGeneric from '../../generic/actionType/generic';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: theme.button,
  textField: theme.textField,
  textErrorMessage: theme.textErrorMessage,
});

const mapStateToProps = state => {
//  console.log('#RTCFA TitolareEffAss.mapStateToProps.state', state);
  return {	  
    titolareEff: state.areaTitolareEffReducer.titolareEff,
    titolareEffList: state.areaTitolareEffReducer.titolareEffList,
    salvaTitolareEffSuccess: state.areaTitolareEffReducer.salvaTitolareEffSuccess,
    controlloDatiTitolareEff: state.areaTitolareEffReducer.titolareEff.controlloDati,
    titolareEffAnagraficaValida: state.areaTitolareEffReducer.titolareEff.anagraficaValida,
    contrGiuSae: state.areaContraenteReducer.contraenteGiuridico.datiAttEconomicaGiuridico.codSAE,
    scopeTcmEmissione: state.genericReducer.generic.proposalInfo.scopeTcmEmissione,
    tcmIndividualeValida: state.genericReducer.generic.proposalInfo.tcmIndividualeValida,
  };
};

const mapDispatchToProps = dispatch => {
//  console.log('#RTCFA TitolareEffAss.mapDispatchToProps.dispatch', dispatch);
  return {
	updateDatiTitolareEff: (unDato, unActionType) => dispatch(actions.updateDatiTitolareEff(unDato, unActionType)),
	confermaAggiungiTitolareEff: (currTitolareEff) => dispatch(actions.confermaAggiungiTitolareEff(currTitolareEff)),
//	nuovoTitolareEff: (currTitolareEff) => dispatch(actions.nuovoTitolareEff(currTitolareEff)),
	nuovoTitolareEff: () => dispatch(actions.nuovoTitolareEff()),
    updateTitolareEff: (currTitolareEff) => dispatch(actions.updateTitolareEff(currTitolareEff)),
    setGeneric: (unDato, unaLabel, unActionType) => dispatch(actionGeneric.setGeneric(unDato, unaLabel, unActionType)),
  };
};

class TitolareEffAss extends React.Component {
  constructor(props) {
    super(props);
//    console.log('#RTCFA TitolareEffAss constructor props', this.props);
    this.state = {
      percentualeTotale: 0,
      isConfermaTitEff: false,
      isNuovoTitEff: false,
      statoCampi: this.initStatoCampi(),
      isPresenteErrore: null,
    }
  };
  
  handleClickNuovoTitolareEff = event => {
//	  console.log('#RTCFA TitolareEffAss:handleClickNuovoTitolareEff new value:', event.target.value);
//    const emptyTitolareEff = creaTitolareEff();
//    this.props.nuovoTitolareEff(emptyTitolareEff);
    this.props.nuovoTitolareEff();
    this.props.updateDatiTitolareEff(false, actionType.SALVA_TITOLARE_EFF_SUCCESS);
    this.setState({
      isConfermaTitEff : false,
      isNuovoTitEff: true,
      isPresenteErrore: null,
      statoCampi: this.initStatoCampi()
    });
  }
  
  handleClickConfermaTitolareEff = (event) => {
//	console.log('#RTCFA TitolareEffAss:handleClickConfermaTitolareEff new value:', event.target.value);
	this.props.updateDatiTitolareEff(true, actionType.SALVA_TITOLARE_EFF);
	this.props.updateDatiTitolareEff(false, actionType.SALVA_TITOLARE_EFF_SUCCESS);
	this.setState({
      isConfermaTitEff: true,
      isNuovoTitEff: false,
	});
  }
  
  
  confermaTitolareEff = () => {

    this.props.updateDatiTitolareEff(errorFiducianteContraente(
      this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_GESTIONE
    ), actionType.UPDATE_ERRORE_FIDUCIANTE_TE_CONTRAENTE);

    const currTitEff = this.props.titolareEff;
    const anagraficaValidaTitEff = this.props.titolareEffAnagraficaValida;
    if (anagraficaValidaTitEff === true) {
      let isNuovo = false;

      let datiTitEffInLista = null;
      this.props.titolareEffList.forEach((item, index, array) => {
        const codiceFiscaleTitEff = currTitEff.datiTitolareEff.datiGenerali.codiceFiscale;
        if (item.datiTitolareEff.datiGenerali.codiceFiscale === codiceFiscaleTitEff) {
          datiTitEffInLista = item;
        }
      });
      // Edit figura corrente
      if (!datiTitEffInLista) {
        isNuovo = true;
      }
      // INSERIMENTO NUOVO TITOLARE EFFETTIVO
      if (isNuovo === true) {
        if (this.props.titolareEffList.length < 4) {
          this.props.confermaAggiungiTitolareEff(currTitEff);
        }
      } else {
        // MODIFICA TITOLARE EFFETTIVO
        this.props.updateTitolareEff(currTitEff);
      }
      
      this.props.updateDatiTitolareEff(true, actionType.SALVA_TITOLARE_EFF_SUCCESS);
      //2021-07-01 PFD: TCM_INDIV
      this.handleTcmIndividualeValida();
    }
    this.setState({
      isConfermaTitEff: false,
    });
  }

  //2021-07-01 PFD: TCM_INDIV
  //Logica introdotto per i Prodotti in scope TCM_EMISSIONE (TZSEV988) 
  //Se alcune condizioni su Premio/PEP Anagrafiche/Relazioni Paesi Black List sono verificate non sono obbligatori alcuni campi rif.: TCM individuali_v.1.8.doc
  handleTcmIndividualeValida = () => {	
	if (this.props.scopeTcmEmissione) {
		const isTcmIndValida = isTcmIndividualeValida(locale_it);
		// se cambiata 
		if ( isTcmIndValida !== this.props.tcmIndividualeValida ){
		 this.props.setGeneric(isTcmIndValida, "", actionTypeGeneric.TCM_INDIVIDUALE_VALIDA);
		}
	}  
  }
  
  initStatoCampi = () => {
    return [ {id: 'datiTitolareEff', isError: false},
           ];
  }
  
  handleError = (id, isError) => {
    let unErrore = null;
    let campoOnId = this.state.statoCampi.filter(unCampo => unCampo.id === id);
    let campoWithoutId = this.state.statoCampi.filter(unCampo => unCampo.id !== id);
    if (campoOnId.length > 0) {
      unErrore = campoOnId[0];
      unErrore.isError = isError;
    } else {
      unErrore = { id: id, isError: isError };
    }
    this.setState({	
      statoCampi: [...campoWithoutId, unErrore],      
    });
  }

  /* errorFiduciante = () => {
    const currentTit = this.props.titolareEff;
    const titList = this.props.titolareEffList;
    const isFiduciaria = this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_GESTIONE;
    let isFiducianteCurrent = false;
    let isPresenteFiducianteInList = false;
    if(currentTit.datiTitolareEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
      isFiducianteCurrent = true;
    }
    if(titList.length > 0 && titList[0].datiTitolareEff) {
      titList.map((el, i) => {
        if(el.datiTitolareEff.datiAntiriciclaggio.qualifica === CODICE_QUALIFICA_FIDUCIANTE) {
          isPresenteFiducianteInList = true;
        }
      });
    }
    let presente = (isFiducianteCurrent === true || isPresenteFiducianteInList === true) ? true : false;
    if(isFiduciaria) {
        return !presente;
    } else {
        return null;
    }
  } */
	  
  render() {
    const { classes, titolareEff, salvaTitolareEffSuccess, titolareEffList} = this.props;
    const { t } = this.props; //hoc
    const percentualeTotaleError = this.state.percentualeTotale!==0 && parseFloat(this.state.percentualeTotale) > 100.0;
    const datiTitolareEff = titolareEff.datiTitolareEff;
    const controlloDati = this.props.controlloDatiTitolareEff;
    const isNuovo = this.state.isNuovoTitEff;
      return (
        <Fragment>      	
          <TabDatiRiepilogoTitolareEff figura={TITOLARE_EFFETTIVO} tabHeader={'tabHeaderTitEff'} />	
          <DatiTitolareEff id={'datiTitolareEff'} datiTitolareEff={datiTitolareEff} onError={this.handleError} controlloDati={controlloDati} />
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Grid container direction='row' justify='center' alignItems='center'>
                <Grid item>
                {(controlloDati === true) && (this.state.isPresenteErrore === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiNonValidi')}</Typography>
                }
                {(controlloDati === true) && !this.state.isPresenteErrore && percentualeTotaleError && !isNuovo && 
                  <Typography className={classes.textErrorMessage}>{t('errTotPercTitEff')}</Typography>
                }
                {(controlloDati === true) && (this.state.isPresenteErrore === false) && (salvaTitolareEffSuccess === true) &&
                  <Typography className={classes.textErrorMessage}>{t('datiValidi')}</Typography>
                }
                {(controlloDati === true) && (errorFiducianteContraente(
                  this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_AMMINISTRAZIONE || this.props.contrGiuSae === CODICE_SAE_FIDUCIARIA_GESTIONE
                )) &&
                  <Typography className={classes.textErrorMessage}>{t('erroreFiduciante')}</Typography>
                }
                {(controlloDati === true) && (titolareEffList.length === 4) &&
                  <Typography className={classes.textErrorMessage}>{t('massimoTitEff')}</Typography>
                }
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ButtonComp
                /* to={linkTo} */
                /* component={Link} */
                aClassName={classes.button}
                buttonLabel={t('conferma')}
                onClick={this.handleClickConfermaTitolareEff}
              />
            </Grid>
            <Grid item>
              <ButtonComp
                /* to={linkTo} */
                /* component={Link} */
                aClassName={classes.button}
                buttonLabel={t('nuovoTitEff')}
                onClick={this.handleClickNuovoTitolareEff}
              />
            </Grid>
          </Grid>
        </Fragment>
    );
  }
  
  isInError(prevProps, prevState) {   
    let isPresenteCampiConErrore = false;
	  this.state.statoCampi.map(unoStatoCampo => {
      return (		
        isPresenteCampiConErrore = isPresenteCampiConErrore || unoStatoCampo.isError
      );
	  });
    if (this.state.isPresenteErrore !== isPresenteCampiConErrore && (this.props.controlloDatiTitolareEff !== '' ) && !this.state.isNuovoTitEff ) {
      this.props.updateDatiTitolareEff(!isPresenteCampiConErrore, actionType.UPDATE_ANAGRAFICA_VALIDA_TITOLARE_EFF);
      this.setState({
        isPresenteErrore: isPresenteCampiConErrore,
      });
	  }
  }
  
  isConfermaTitolareEff = (prevProps, prevState) => {  
    const isConfermaCurr = this.state.isConfermaTitEff;
    if (isConfermaCurr && this.props.titolareEffAnagraficaValida) {
      this.confermaTitolareEff();
    }
  }
	  
  componentDidUpdate(prevProps, prevState) {
    this.isInError(prevProps, prevState);
    this.isConfermaTitolareEff(prevProps, prevState);
  }
}

TitolareEffAss.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TitolareEffAss)));