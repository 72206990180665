import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import classNames from 'classnames';
//import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
//import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { textTrim } from '../../utility/genericUtility';

const styles = theme => ({
  root: {
//	padding: '0px  0px 0px 8px', 
    padding: '0px 8px',	  
    margin: '0px',
    color: theme.palette.color.windowsBlue,
    '&$checked': {
      color: theme.palette.color.windowsBlue,
    },
  },
  rootReadonly: {
//	padding: '0px  0px 0px 8px', 
    padding: '0px 8px',	  
    margin: '0px',    
	color: theme.palette.color.greyLight,
	'&$checked': {
      color: theme.palette.color.greyLight,
    },
  },
  controlLabel: {  
    margin: '0px',
  },
  checked: {
  },
  
  rootError: {
//	padding: '0px  0px 9px 8px',
    padding: '0px 8px',
    margin: '0px',    
    color: theme.palette.color.red,
  },
  
  typography: theme.typography,
  typographyError: {
    color: theme.palette.color.red,
  },  
  radioLabel: {
	fontSize: '1.15rem',  
  },
  fieldSet: {
	  marginLeft: theme.spacing(1),
	  marginBottom: theme.spacing(1),
//	  padding: '0px  0px 0px 8px', 
	  padding: '0px',	  
	  borderWidth: 1,
	  borderRadius:6,
	  borderStyle: 'solid',
	  borderColor: theme.palette.color.greyLight,
	  float:'left',
	  width: '97%',
  },
  
  fieldSetError: {
	  marginLeft: theme.spacing(1),
	  marginBottom: theme.spacing(1),
//	  padding: '0px  0px 0px 8px', 
	  padding: '0px',	  
	  borderWidth: 1,
	  borderRadius:6,
	  borderStyle: 'solid',
	  borderColor: theme.palette.color.red,
	  float:'left',
      '$notchedOutline': { 
          borderWidth: '2px',  	  
          borderColor: `${theme.palette.color.red} !important`,
      },	
      width: '97%',
  },
  
  legend: {
    color: theme.palette.color.greyLight,
 	  fontSize: 15, 
/*RT    fontSize: '1.21rem',*/
  },
  
  divider: {
	  width: theme.spacing(10),
  },
});

class RadioButtonComp extends React.Component {
	
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.selectedValue,
      typing: false,			
      isError: false,
    };
  };

  handleClick = event => {
//    console.log('#####RadioButtonComp handleClick event.target.value', event.target.value);	 	  
    this.setState({
      selectedValue: event.target.value,
      typing: true,			
    });
    if(this.props.onClick){
      this.props.onClick(event);
    }
  };
	  
  handleChange = event => {
//    console.log('#####Button handleChange event.target.value', event.target.value);		  
    this.setState({
      selectedValue: event.target.value,
//      typing: true,
      typing: false,
    });
    if(this.props.onChange){
      this.props.onChange(event);
    }
  };
	
  handleOnBlur = event => {
//    console.log('RadioButtonComp handleOnBlur value this.state.value: ', this.state.value);
//    console.log('RadioButtonComp handleOnBlur value event.target.value: ', event.target.value);
    this.setState({
      value: event.target.value,
      typing: false,
    });
    if(this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleError = (isError, id) => {
    console.log('#######RadioButtonComp handleError', id + ' ' + isError);
    this.setState({
      isError: isError,
    });    
    if(this.props.onError) {
      this.props.onError(id, isError);
    }
  }; 
	  
  valueText = (trim) => {
    const aValue = (this.state.typing === true) ? textTrim(this.state.selectedValue, trim) : textTrim(this.props.selectedValue, trim);
    return aValue;
  };		  
		  
  calcolaRequired = required => {
    const aValue = (required === true) ? ' *' : '';
    return aValue;
  }
  
  render() {	 
	const { classes, radioComp, onChange, selectedValue, id, required, controlloDati, disabled, readonly/*, radioCompDb */ } = this.props;
    
	//20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio
	// Vedi esempio example DatiPianoSpostamentoAutomatico
	if(!radioComp || !radioComp.elementi) {
		return null;
	} 
		
	//20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio
	//const elementi = (radioCompDb) ? radioCompDb : radioComp.elementi;
	const elementi = radioComp.elementi;	
		
    const { t } = this.props; //hoc    
//    console.log('#RT2 RadioButtonComp render props.selectedValue', selectedValue);
//    console.log('#RT2 RadioButtonComp render this.state.selectedValue', this.state.selectedValue); 
    
    const aValue = this.valueText(controlloDati);
//    console.log('###RT2 RadioButtonComp render aValue', aValue);    
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const isReadonly = readonly === true;
    const isDisabledOrReadonly = (disabled === true || readonly === true);
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === ''); 
    const aTitle = textTrim(radioComp.titolo, true);
    const aLabel = (aTitle !== '') ? (t(radioComp.titolo) + this.calcolaRequired(required)) : this.calcolaRequired(required);
    const classNameRadio = (isError === true) ? { root: classes.rootError } : (isReadonly === true ) ? {root: classes.rootReadonly, checked: classes.checked} : { root: classes.root, checked: classes.checked };
//    const classNameRadioLabel = (isError === true) ? { root: classes.typographyError } : { root: classes.typography };
    const classNameRadioLabel = (isError === true) ? { root: classes.typographyError } : { root: classes.typography };
//    const classNameRadioLabel = (isError === true) ? classNames(classes.typographyError, classes.radioLabel) : classNames(classes.typography, classes.radioLabel);
    const directionRB = ('vertical' === radioComp.align) ? 'column' : 'row';
    const justifyRB = ('vertical' === radioComp.align) ? 'flex-start' : 'flex-start';
    const alignItemsRB ='flex-start';
    return (
     <div style={{ marginTop: '0px'}}>
     {/*<div style={{ marginTop: '5px'}}>*/}
      <fieldset className={(isError === true) ? classes.fieldSetError : classes.fieldSet} id={id}>
  {/*<fieldset className={classes.fieldSet} id={id}>*/}
      {radioComp.titolo && <legend><Typography variant='caption' className={classes.legend}>{aLabel}</Typography></legend>} 
{/*        <Grid container direction={'vertical' === radioComp.align ? 'column' : 'row'} justify='flex-start' alignItems={'vertical' === radioComp.align ? 'flex-start' : 'center'}> */}
        <Grid container direction={directionRB} justify={justifyRB} alignItems={alignItemsRB}>        
          {elementi.map((radioItem, index) => {
            const isChecked = isDisabled ? false : (aValue === radioItem.value);
            return(
              <Grid key={index}>
                <FormControlLabel className={classes.controlLabel}
                  key={index}
                  control={<Radio
/*                           checked={aValue === radioItem.value} */
                             checked={isChecked}
                             onChange={this.handleChange}
                             onBlur={this.handleOnBlur}
                             onClick={this.handleClick}
                             value={radioItem.value}
                             name={radioComp.name}
                             aria-label={radioItem.ariaLabel ? radioItem.ariaLabel : radioItem.value}
/*                           classes={{ root: classes.root, checked: classes.checked }} */
                             classes={classNameRadio}
                             disabled={isDisabledOrReadonly}
                           />}
/*                  label={<Typography classes={classNameRadioLabel} variant='caption'>{t(radioItem.label)}</Typography>} */		
                  label={<Typography classes={classNameRadioLabel} variant='body2'>{t(radioItem.label)}</Typography>}                  
               />
              </Grid>
            )
          })} 
        </Grid>
{/*      <div className={classes.divider} /> */}  
      </fieldset>
     </div>
	);
  }
          
  componentDidUpdate(prevProps, prevState) {
    const { id, required, controlloDati, disabled } = this.props;	  
    const prevIsError = prevState.isError;
    const isRequired = required === true;
    const isDisabled = disabled === true;
    const aValue = isDisabled ? '' : this.valueText(controlloDati);
    const isError = (controlloDati === true) && isRequired && !isDisabled && (aValue === '');
    if ((prevIsError !== isError) && (this.state.isError !== isError)) {
//      console.log('#######prevIsError !== isError, prevIsError = ' + prevIsError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, isError = ' + isError + ', id: ' + id);
//      console.log('#######prevIsError !== isError, this.state.isError = ' + this.state.isError + ', id: ' + id);
      this.handleError(isError, id);      
    }
  }; 
}

RadioButtonComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(RadioButtonComp));
