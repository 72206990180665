import * as actionType from '../actionType/datiPremio';
import * as actionTypeGeneric from '../../../../generic/actionType/generic';
import initialState from '../../../../../session/InitialState';

import { jsonCopy } from '../../../../../utility/genericUtility';
import { datiPremioInizializzato } from '../../../../../session/info/componentiBase/sezioniCaratteristicheContratto';

const areaDatiPremioReducer = (state = initialState, action) => {

  //DA UTILIZZARE SOLO PER LE COMBO
  const unValore = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.value;
  const unaLabel = (action.unDato === null || action.unDato === undefined) ? '' : action.unDato.label;

  console.log('#RT2 Reducer areaDatiPremioReducer.action.type', action.type);
  switch (action.type) {
    case actionTypeGeneric.INIZIALIZZA:
      const caratteristicheContratto_INI = inizializzaPremio(state);
      return {
        ...state,
        caratteristicheContratto: caratteristicheContratto_INI,
      };
    case actionType.SALVA_DATI_PREMIO:
      console.log('#RT2 Reducer areaDatiPremioReducer SALVA_DATI_PREMIO', actionType.SALVA_DATI_PREMIO);
      console.log('#RT2 Reducer areaDatiPremioReducer.action', action);
      const caratteristicheContrattoDatiPremioSalva = { ...state }.caratteristicheContratto;
      const datiPremioSalva = { ...caratteristicheContrattoDatiPremioSalva }.datiPremio;
      datiPremioSalva.controlloDati = action.unDato;
      caratteristicheContrattoDatiPremioSalva.datiPremio = { ...datiPremioSalva };
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoDatiPremioSalva,
      };
    case actionType.UPDATE_PREMIO_UNICO:
      const caratteristicheContrattoPremioUnico = { ...state }.caratteristicheContratto;
      const datiPremioPremioUnico = { ...caratteristicheContrattoPremioUnico }.datiPremio;
      datiPremioPremioUnico.premioUnico = action.unDato;
      caratteristicheContrattoPremioUnico.datiPremio = { ...datiPremioPremioUnico };
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoPremioUnico,
      };
    case actionType.UPDATE_DATI_PREMIO_VALIDA:
      const caratteristicheContrattoValida = { ...state }.caratteristicheContratto;
      const datiPremioPremioUnicoValida = { ...caratteristicheContrattoValida }.datiPremio;
      datiPremioPremioUnicoValida.datiPremioValida = action.unDato;
      caratteristicheContrattoValida.datiPremio = { ...datiPremioPremioUnicoValida };
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoValida,
      };
    case actionType.UPDATE_CLASSE_PREMIO_POTENZIALE:
      const caratteristicheContrattoClassePremioPotenziale = { ...state }.caratteristicheContratto;
      const datiPremioClassePremioPotenziale = { ...caratteristicheContrattoClassePremioPotenziale }.datiPremio;
      datiPremioClassePremioPotenziale.classePremioPotenziale = action.unDato;
      caratteristicheContrattoClassePremioPotenziale.datiPremio = { ...datiPremioClassePremioPotenziale };
      return {
      	...state,
      	caratteristicheContratto: caratteristicheContrattoClassePremioPotenziale,
      };  
    case actionType.UPDATE_APPENDICE_COSTI:
      const caratteristicheContrattoAppendice = {...state}.caratteristicheContratto;
      const datiPremioUnicoAppendice = {...caratteristicheContrattoAppendice}.datiPremio;
      datiPremioUnicoAppendice.appendiceCosti = action.unDato;
      caratteristicheContrattoAppendice.datiPremio = {...datiPremioUnicoAppendice}
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoAppendice,
      };  
    case actionType.UPDATE_TARIFFA:
      const caratteristicheContrattoTariffa = {...state}.caratteristicheContratto;
      const datiPremioPremioTariffa = {...caratteristicheContrattoTariffa}.datiPremio;
      datiPremioPremioTariffa.tariffa = unValore;
      datiPremioPremioTariffa.descTariffa = unaLabel;
      caratteristicheContrattoTariffa.datiPremio = {...datiPremioPremioTariffa}
      return {
        ...state,
        caratteristicheContratto: caratteristicheContrattoTariffa
      }
    default:
      return state;
  }
}

export const inizializzaPremio = (state) => {
  const caratteristicheContratto_INI = { ...state }.caratteristicheContratto;
  caratteristicheContratto_INI.datiPremio = jsonCopy(datiPremioInizializzato);
  return caratteristicheContratto_INI;
}

export default areaDatiPremioReducer;