import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import ButtonComp from './ButtonComp';

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const styles = theme => ({
  textField: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1), 
	marginBottom: theme.spacing(2),
	height: 48,
  },
  textFieldError: theme.textFieldError,
  textErrorMessage: theme.textErrorMessage,
  button: theme.button,
});

function ModalBasicInfoComp(props) {
//  const [open, setOpen] = React.useState(false);
//  const handleOpen = () => setOpen(true);
//  const handleClose = () => setOpen(false);
	

  const onClose = (event) => {
	  if (handleClose) {
		  handleClose(event);
	  }
  };

  const { classes, componente, open } = props;
  const { boxStyle } = props;  
  const { handleClose } = props;  //callback function 
  
  const { t } = props;   //hoc 
  
  const UnComponente = componente; 
  let style = boxStyle !== undefined ? boxStyle : defaultStyle;   // vedo se arriva tra le props
  
  return (
    <div>
    
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <UnComponente />
          <Grid container direction="row" justify="center" alignItems="flex-start">
	          <Grid item xs={12}>
		        <Grid container direction="row" justify="center" alignItems="center">	
		          <Grid item>
		      	  	<ButtonComp
	                  aClassName={classes.button}
	                  buttonLabel={'Chiudi'}
	                  onClick={onClose}
		      	    />
		          </Grid>
		        </Grid>
		      </Grid>  
		  </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default withTranslation()(withStyles(styles)(ModalBasicInfoComp));