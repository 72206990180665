import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanelComp from '../../common/ExpansionPanelComp';
import DatiPremio from './sezione/DatiPremio';

const styles = theme => ({
  root: {
    width: '100%',
  },
});
			
function PremioPanel(props) {
	
  const { classes, isValida } = props;
  return (
    <div className={classes.root}> 
      <ExpansionPanelComp titolo={'premio'} componente={DatiPremio} isValida={isValida} /> 
    </div>
  );
}

PremioPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PremioPanel);
