import { jsonCopy } from './genericUtility';
import { datiGeneraliBenefMorteInizializzato, datiResidenzaInizializzato, 
	     datiRelazContrInizializzato, datiCopiaDaAltraFiguraInizializzato,
	     datiGeneraliBenefGiuridicoInizializzato,
	     datiGeneraliBenefVitaInizializzato,
	     datiTitolareEffInizializzato,
	     datiGeneraliGenericoInizializzato, 
         datiAttEconomicaGiuridicoInizializzato,
         datiAntiriciclaggioInizializzato,
         datiSocietariGiuridicoInizializzato,
         datiMandatoFiduciarioInizializzato,
         datiTitolareEffettivoBenefMorteInizializzato,
         titEffBenefMorteGiuridicoInizializzato,
         titEffBenefVitaGiuridicoInizializzato} from '../session/info/componentiBase/sezioniAnagrafica';
import { inizializzaTitolareEffBenefMorte } from '../components/anagrafica/figure/reducer/titolareEff';
import { RUOLO_BENEF_MORTE, RUOLO_BENEF_VITA, PERSONA_FISICA, PERSONA_GIURIDICA } from './jsConstants';
import store from '../store';
import { titolareEffBenefMorte } from '../session/info/titolareEffBenefMorte';

export const creaBeneficiarioMorteFisico = () => {
  const benefMorteFisico = {};
  benefMorteFisico.anagraficaValida = null;
  benefMorteFisico.controlloDati = '';
  benefMorteFisico.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_MORTE,
    tipoPers: PERSONA_FISICA,
  };
  benefMorteFisico.datiGeneraliBenefMorte = jsonCopy(datiGeneraliBenefMorteInizializzato);
  benefMorteFisico.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefMorteFisico.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefMorteFisico.datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
  benefMorteFisico.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);    
  return benefMorteFisico;
}

export const creaBeneficiarioMorteGiuridico = (list, fl) => {
  const benefMorteGiuridico = {};
  benefMorteGiuridico.anagraficaValida = null;
  benefMorteGiuridico.controlloDati = '';
  benefMorteGiuridico.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_MORTE,
    tipoPers: PERSONA_GIURIDICA,
  };
  benefMorteGiuridico.datiGeneraliBenefGiuridico = jsonCopy(datiGeneraliBenefGiuridicoInizializzato);
  benefMorteGiuridico.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefMorteGiuridico.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefMorteGiuridico.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  benefMorteGiuridico.datiAttEconomica = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  benefMorteGiuridico.datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
  benefMorteGiuridico.datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
  benefMorteGiuridico.titolareEffBenefMorte = jsonCopy(titEffBenefMorteGiuridicoInizializzato);//jsonCopy(datiTitolareEffettivoBenefMorteInizializzato);
  benefMorteGiuridico.titolareEffBenefMorteList = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  benefMorteGiuridico.flTitolareEffBenef = 'S';
  return benefMorteGiuridico;
}

export const creaBeneficiarioVitaFisico = () => {
  const benefVitaFisico = {};
  benefVitaFisico.anagraficaValida = null;
  benefVitaFisico.controlloDati = '';
  benefVitaFisico.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_VITA,
    tipoPers: PERSONA_FISICA,
  };
  benefVitaFisico.datiGeneraliBenefVita = jsonCopy(datiGeneraliBenefVitaInizializzato);
  benefVitaFisico.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefVitaFisico.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefVitaFisico.datiAntiriciclaggio = jsonCopy(datiAntiriciclaggioInizializzato);
  benefVitaFisico.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  return benefVitaFisico;
}

export const creaBeneficiarioVitaGiuridico = () => {
  const benefVitaGiuridico = {};
  benefVitaGiuridico.anagraficaValida = null;
  benefVitaGiuridico.controlloDati = '';
  benefVitaGiuridico.datiFigureAnagrBase = {
    ruolo: RUOLO_BENEF_VITA,
    tipoPers: PERSONA_GIURIDICA,
  };
  benefVitaGiuridico.datiGeneraliBenefGiuridico = jsonCopy(datiGeneraliBenefGiuridicoInizializzato);
  benefVitaGiuridico.datiResidenza = jsonCopy(datiResidenzaInizializzato);
  benefVitaGiuridico.datiRelazContr = jsonCopy(datiRelazContrInizializzato);
  benefVitaGiuridico.datiCopiaDaAltraFigura = jsonCopy(datiCopiaDaAltraFiguraInizializzato);
  benefVitaGiuridico.datiAttEconomica = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  benefVitaGiuridico.datiSocietari = jsonCopy(datiSocietariGiuridicoInizializzato);
  benefVitaGiuridico.datiMandatoFiduciario = jsonCopy(datiMandatoFiduciarioInizializzato);
  benefVitaGiuridico.titolareEffBenefVita = jsonCopy(titEffBenefVitaGiuridicoInizializzato);
  benefVitaGiuridico.titolareEffBenefVitaList = jsonCopy(datiAttEconomicaGiuridicoInizializzato);
  benefVitaGiuridico.flTitolareEffBenef = 'S';
  return benefVitaGiuridico;
}

export const creaTitolareEff = () => {
  const titolareEff = {};
  titolareEff.anagraficaValida = null;
  titolareEff.controlloDati = '';
  titolareEff.datiTitolareEff = jsonCopy(datiTitolareEffInizializzato);
  return titolareEff;
}

export const creaBeneficiarioMorteGenerico = () => {
  const benefMorteGenerico = {};
  benefMorteGenerico.anagraficaValida = null;
  benefMorteGenerico.controlloDati = '';
  benefMorteGenerico.datiGenerali = jsonCopy(datiGeneraliGenericoInizializzato);
  return benefMorteGenerico;
}

export const creaBeneficiarioVitaGenerico = () => {
  const benefVitaGenerico = {};
  benefVitaGenerico.anagraficaValida = null;
  benefVitaGenerico.controlloDati = '';
  benefVitaGenerico.datiGenerali = jsonCopy(datiGeneraliGenericoInizializzato);
  return benefVitaGenerico;
}