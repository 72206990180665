import { jsonCopy } from '../../../utility/genericUtility';

export const datiGeneraliMinimiInizializzato = {
  codiceFiscaleValido: null,
  sesso: '',
  codiceFiscale: '',
  cognome: '',
  nome: '',
  dataNascita: null,
  dataNascitaStampa: '',  
  statoNascita: '',
  descrStatoNascita: '',
  provinciaNascita: '',
  descrProvinciaNascita: '',
  comuneNascita: '',  
  descrComuneNascita: '',  
};

export const datiGeneraliInizializzato = {
  codiceFiscaleValido: null,		
  sesso: '',
  codiceFiscale: '',
  cognome: '',
  nome: '',      
  dataNascita: null,
  dataNascitaStampa: '',  
  statoNascita: '',
  descrStatoNascita: '',
  provinciaNascita: '',
  descrProvinciaNascita: '',
  comuneNascita: '',
  descrComuneNascita: '',
  cittadinanza1: '',
  descrCittadinanza1: '',
  cittadinanza2: '',
  descrCittadinanza2: '',
  numTelefono: '',
  titoloStudio: '',
  descrTitoloStudio: '',
  altroTitoloStudio:'',
};

export const datiResidenzaInizializzato = {
  statoResidenza: '',
  descrStatoResidenza: '',
  provinciaResidenza: '',
  descrProvinciaResidenza: '',
  comuneResidenza: '',
  descrComuneResidenza: '',
  toponimoResidenza: '',
  descrToponimoResidenza: '',
  indirizzoResidenza: '',
  descrIndirizzoResidenza: '',
  numCivicoResidenza: '',
  capResidenza: '',
};

export const datiGeneraliGiuridicoInizializzato = {
  ragioneSociale: '',
  codiceFiscale: '',
  dataNascita: null,  
  dataNascitaStampa: '',   
};

export const datiGeneraliBenefGiuridicoInizializzato = {
  datiGenerali: jsonCopy(datiGeneraliGiuridicoInizializzato),
  percBeneficiario: '',
};

export const datiRecapitoInizializzato = {
  corrispondenzaDiversoResidenza: '',
  corrispondenzaIntestatoContraente: '',
  statoRecapito: '',
  descrStatoRecapito: '',
  provinciaRecapito: '',
  descrProvinciaRecapito: '',
  comuneRecapito: '',
  descrComuneRecapito: '',
  toponimoRecapito: '',
  descrToponimoRecapito: '',
  indirizzoRecapito: '',
  descrIndirizzoRecapito: '',
  numCivicoRecapito: '',
  capRecapito: '',
  presso: '',
};

export const datiRipartizioneInvestimentiFinanziariInizializzato = {
  strumentiMercatoMonetario: '',
  strumentiObbligazionari: '',
  strumentiAzionari: '',
  strumentiAssicurativi: '',
  strumentiComplessi: ''
}

export const datiDomicilioInizializzato = {
  domicilioDiversoResidenza: '',
  statoDomicilio: '',
  descrStatoDomicilio: '',
  provinciaDomicilio: '',
  descrProvinciaDomicilio: '',
  comuneDomicilio: '',
  descrComuneDomicilio: '',
  toponimoDomicilio: '',
  descrToponimoDomicilio: '',
  indirizzoDomicilio: '',
  numCivicoDomicilio: '',
  capDomicilio: '',
};

export const datiFinanziariInizializzato = {
  codFonteReddito: '',
  descFonteReddito: '',
  altro: '',
  codRedditoAnnuo: '',
  descRedditoAnnuo: '',
  primaCasa: '',
  altriImmobili: '',
  codPatrimonioFinanziario: '',
  descPatrimonioFinanziario: '',
  codImpegnoFinanziario: '',
  descImpegnoFinanziario: '',
  ripartizioneInvestimentiFinanziari: jsonCopy(datiRipartizioneInvestimentiFinanziariInizializzato)
}

export const datiFiscaliInizializzato = {
  tasseStatiUniti: '',
  tinGiin: '',
  statoResidenzaFiscaleEstera: '',
  statoResFiscale1: '', 
  descrStatoResFiscale1: '',  
  nifResFiscale1: '',      
  statoResFiscale2: '', 
  descrStatoResFiscale2: '',  
  nifResFiscale2: '',  
};

export const datiGeneraliBenefVitaInizializzato = {
  percBeneficiario: '',
  datiGeneraliMinimi: jsonCopy(datiGeneraliMinimiInizializzato),
};

export const datiGeneraliBenefMorteInizializzato = {
  percBeneficiario: '',
  datiGeneraliMinimi: jsonCopy(datiGeneraliMinimiInizializzato),
};

export const datiGeneraliReferenteTerzoInizializzato = {
  flReferenteTerzo: 'N',
  sesso: '',
  codiceFiscale: '',
  cognome: '',
  nome: '',      
  dataNascita: null,
  dataNascitaStampa: '',  
  statoNascita: '',
  descrStatoNascita: '',
  provinciaNascita: '',
  descrProvinciaNascita: '',
  comuneNascita: '',
  descrComuneNascita: '',
};

export const datiRelazContrInizializzato = {
  email: '',
  relazContraente: '',
  descrRelazContraente: '',
  altro: '',
  motivoDesignazione: '',
  esclusioneComunicaz: 'N',
  benDiversoImp: ''
};

export const datiConsensoComElettrInizializzato = {
  flConsensoComElettr: 'S', //2022-04-06 PFD: impostato default = 'S' per tutte le figure (usato in ContraenteFisicoAss)
  emailConsensoComElettr: '',
};

export const datiAttEconomicaInizializzato = {
  tipoSocieta: '',
  descrTipoSocieta: '',
  codAttPrevalSvolta: '', 
  descrAttPrevalSvolta: '',
  provinciaAttPrevalSvolta: '',
  descrProvinciaAttPrevalSvolta: '',
  comuneAttPrevalSvolta: '',
  descrComuneAttPrevalSvolta: '',
  capAttPrevalSvolta: '',
  statoAttPrevalSvolta: '',
  descrStatoAttPrevalSvolta: '',
  codSAE: '',
  descrSAE: '',
  codATECO: '',
  descrATECO: '',
};


export const datiMandatoFiduciarioInizializzato = {
  numeroMandatoFid: '',
  intestatarioMandato: '',
}

export const datiAntiriciclaggioInizializzato = {
  tipoDocumento: '', 
  descrTipoDocumento: '',
  numeroDocumento: '',
  enteRilascio: '',
  descrEnteRilascio: '',
  dataRilascio: null,
  dataRilascioStampa: '',  
  dataScadenza: null,
  dataScadenzaStampa: '',  
  statoRilascio: '',
  descrStatoRilascio: '',
  provinciaRilascio: '',
  descrProvinciaRilascio: '',
  comuneRilascio: '',
  descrComuneRilascio: '',
  espostaPoliticamente: '',
  isInCarica: '',
  caricaPersonaEsposta: '',
  caricaPubblicaNoPEP: '',
  codAttivitaPrecedente: '',
  descrAttivitaPrecedente: '',
  codAmbitoAttivita: '',
  descrAmbitoAttivita: '',
  descrQualifica:'',
  qualifica: '',
  altro:'',  
};

export const datiNaturaRapportoInizializzato = {
  scopoNaturaRapporto: '', 
  descrScopoNaturaRapporto: '',
  origineFondi: '',
  descrOrigineFondi: '',
  altro: '',	
  stipulatoPerConto: '',
  /* codScopoNaturaContratto: '',
  descScopoNaturaContratto: '', */
  codSoggettoDaTutelare: '',
  descSoggettoDaTutelare: '',
  altriSoggetti: '',
  codTempoProvvista:'',
  descTempoProvvista:'',
  origineFondiList:[],
  statoProvenienzaSomme:'',
  descrStatoProvenienzaSomme:'',
};

export const relazPaesiListInizializzato = []; 
for(var i=0;i<3;i++){ // Al massimo 3 Paesi
	relazPaesiListInizializzato.push({value:'',  label:'',});
}

export const datiAttEconomicaGiuridicoInizializzato = {
  tipoSocieta: '', 
  descrTipoSocieta: '',
  codAttPrevalSvolta: '', 
  descrAttPrevalSvolta: '',
  altroAttPrevAtt: '',
  provinciaAttPrevalSvolta: '',
  descrProvinciaAttPrevalSvolta: '',
  comuneAttPrevalSvolta: '', 
  descrComuneAttPrevalSvolta: '',
  capAttPrevalSvolta: '',
  descrStatoAttPrevalSvolta: '',
  statoAttPrevalSvolta: '',
  codSAE: '',
  descrSAE: '',
  codATECO: '',
  descrATECO: '',
  flAllegati: '',
  altro: '',
  flPaesiBlackList: '',
//2022-05-27 PFD: SRS_GLIT_2021_101 - Paesi Black List  listaPaesiBlackList:[],
  relazPaesiList:  [...relazPaesiListInizializzato], // Al massimo 3 Paesi
  flEntitaFinanzPassiva: '',
  societaAzFiduciario: '',
  motivoRelazione: '',
  classeBeneficiari: '',
  societaQuotata: '',
  codStatoAttivPrevalSvolta: '',
  descrStatoAttivPrevalSvolta: '',
};

export const datiSocietariGiuridicoInizializzato = {
  numeroRegistro: '',
  nomeAlbo: '',
  dataIscrizioneAlbo: null,
  dataIscrizioneAlboStampa: '',
  numeroIscrizioneAlbo: '',
  codTipoIscrRegistro: '',
  descTipoIscrRegistro: '',
  codTipoIscrAlbo: '',
  descTipoIscrAlbo: '', 
};

export const datiFinanziariGiuridicoInizializzato = {
  codFatturato: '',
  descFatturato: '',
  sediSocieta: '',
  codPatrimonioFinanziario: '',
  descPatrimonioFinanziario: '',
  altriImmobili: '',
  ripartizioneInvestimentiFinanziari: jsonCopy(datiRipartizioneInvestimentiFinanziariInizializzato)
};

export const datiGeneraliGenericoInizializzato = {
  percBeneficiario: '100,0',
  benefGenerico: '',
  descrBenefGenerico: '',
  descrBenefEsteso: '',      
};

export const datiFigureAnagrBaseInizializzato = {
  tipoPers: '',
  ruolo: '',
};

export const datiCopiaDaAltraFiguraInizializzato = {
  flCopiaDaAltraFigura: 'N',
  figuraFrom: '',
  descrFiguraFrom: '',	
};

export const datiEsecutoreInizializzato = {
  flEsecutore: '', 
  relazContrEsecut: '',
  descrRelazContrEsecut: '',
  flDelega: '',
  tipoRappresentanza: '',
  descrTipoRappresentanza: '',
  altraRappresentanza: '',
  datiGenerali: jsonCopy(datiGeneraliInizializzato),
  datiResidenza: jsonCopy(datiResidenzaInizializzato),
  datiAttEconomica: jsonCopy(datiAttEconomicaInizializzato),
  datiAntiriciclaggio: jsonCopy(datiAntiriciclaggioInizializzato),
  datiDomicilio: jsonCopy(datiDomicilioInizializzato),
  origineFondi: '',
  descrOrigineFondi: '',
  altro: '',
};

export const datiRappLegaleInizializzato = {
  datiGeneraliMinimi: jsonCopy(datiGeneraliMinimiInizializzato),
  datiResidenza: jsonCopy(datiResidenzaInizializzato),
  datiDomicilio: jsonCopy(datiDomicilioInizializzato),
  datiAntiriciclaggio: jsonCopy(datiAntiriciclaggioInizializzato),
  datiAttEconomica: jsonCopy(datiAttEconomicaInizializzato)
};

export const datiReferenteTerzoInizializzato = {
  datiGenerali: jsonCopy(datiGeneraliReferenteTerzoInizializzato),
  datiResidenza: jsonCopy(datiResidenzaInizializzato),
  email: '',
};

export const datiTitolareEffInizializzato = {
  percOwnerShip: '',
  datiGenerali: jsonCopy(datiGeneraliInizializzato),
  datiResidenza: jsonCopy(datiResidenzaInizializzato),
  datiDomicilio: jsonCopy(datiDomicilioInizializzato),
  datiAntiriciclaggio: jsonCopy(datiAntiriciclaggioInizializzato),
  datiFiscali: jsonCopy(datiFiscaliInizializzato),
  datiRelazContr: jsonCopy(datiRelazContrInizializzato),
  datiRecapito: jsonCopy(datiRecapitoInizializzato),
  datiAttEconomica: jsonCopy(datiAttEconomicaInizializzato)
};

export const datiTitolareEffettivoBenefMorteInizializzato = {
  datiRelazContr: jsonCopy(datiRelazContrInizializzato),
  datiGeneraliMinimi: jsonCopy(datiGeneraliMinimiInizializzato),
  percOwnerShip: '',
  datiGenerali: jsonCopy(datiGeneraliInizializzato),
  datiResidenza: jsonCopy(datiResidenzaInizializzato),
  datiDomicilio: jsonCopy(datiDomicilioInizializzato),
  datiAntiriciclaggio: jsonCopy(datiAntiriciclaggioInizializzato),
  datiFiscali: jsonCopy(datiFiscaliInizializzato),
  datiRecapito: jsonCopy(datiRecapitoInizializzato),
  datiAttEconomica: jsonCopy(datiAttEconomicaInizializzato)

};

export const datiTitolareEffettivoBenefVitaInizializzato = {
  datiGeneraliMinimi: jsonCopy(datiGeneraliMinimiInizializzato),
  percOwnerShip: '',
  datiGenerali: jsonCopy(datiGeneraliInizializzato),
  datiResidenza: jsonCopy(datiResidenzaInizializzato),
  datiDomicilio: jsonCopy(datiDomicilioInizializzato),
  datiAntiriciclaggio: jsonCopy(datiAntiriciclaggioInizializzato),
  datiFiscali: jsonCopy(datiFiscaliInizializzato),
  datiRelazContr: jsonCopy(datiRelazContrInizializzato),
  datiRecapito: jsonCopy(datiRecapitoInizializzato),
  datiAttEconomica: jsonCopy(datiAttEconomicaInizializzato)
};

export const titEffBenefMorteGiuridicoInizializzato = {
  anagraficaValida: '',
  controlloDati: '',
  datiTitolareBenefMorteEff: jsonCopy(datiTitolareEffettivoBenefMorteInizializzato)
}

export const titEffBenefVitaGiuridicoInizializzato = {
  anagraficaValida: '',
  controlloDati: '',
  datiTitolareBenefVitaEff: jsonCopy(datiTitolareEffettivoBenefVitaInizializzato)
}