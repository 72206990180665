import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
//import MenuItem from '@material-ui/core/MenuItem';
//import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';

import MenuAppBar from '../../components/header/MenuAppBar';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';

//import DatiGeneraliFisico from '../../components/anagrafica/sezione/DatiGeneraliFisico';
import BenefMortePanel from '../../components/proposal/anagraph/BenefMortePanel';
import ContractorPanel from '../../components/proposal/anagraph/ContractorPanel';
import AssicuratoPanel from '../../components/proposal/anagraph/AssicuratoPanel';
import BenefVitaPanel from '../../components/proposal/anagraph/BenefVitaPanel';
import TitolareEffPanel from '../../components/proposal/anagraph/TitolareEffPanel';

import PremioCapitaleAssicuratoPanel from '../../components/proposal/caratteristicheContratto/PremioCapitaleAssicuratoPanel';
import ModalitaPagamentoPanel from '../../components/proposal/caratteristicheContratto/ModalitaPagamentoPanel';
import PremioPanel from '../../components/proposal/caratteristicheContratto/PremioPanel';
import RipartizionePremioPanel from '../../components/proposal/caratteristicheContratto/RipartizionePremioPanel';
import OpzioniContrattualiPanel from '../../components/proposal/caratteristicheContratto/OpzioniContrattualiPanel';
import CaratteristicheContrattoProposal from '../../components/proposal/caratteristicheContratto/CaratteristicheContrattoProposal';
import ReferenteTerzoPanel from '../../components/proposal/anagraph/ReferenteTerzoPanel';

import AnagraphProposal from '../../components/proposal/anagraph/AnagraphProposal';
import AltreFigureProposal from '../../components/proposal/anagraph/AltreFigureProposal';

import QuestionarioSanitarioPanel from '../../components/proposal/questionario/QuestionarioSanitarioPanel';
import QuestionarioSanitarioSemplificatoPanel from '../../components/proposal/questionario/QuestionarioSanitarioSemplificatoPanel';

import DichIntermediarioPanel from '../../components/proposal/dichiarazioni/DichIntermediarioPanel';

import InviaNuoveCredenzialiPanel from '../../components/gestioneUtenze/InviaNuoveCredenzialiPanel';

import StampaProposalPanel from '../../components/proposal/stampa/StampaProposalPanel';

import { menuAppBarItems } from '../../components/store/menuAppBarItems';
//import {categoriaProdottoItems} from '../../components/store/categoriaProdotto';
//import {initPropostaItems} from '../../components/store/initPropostaItems';

import * as actions from '../../components/common/action/dominio';
import * as actionsProdotto from '../../components/prodotto/action/prodotto';

import { uris, fetchParam } from '../../uris';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/it';
import ContraenteFisicoAss from '../../components/anagrafica/figure/ContraenteFisicoAss';
import ContraenteGiuridicoAss from '../../components/anagrafica/figure/ContraenteGiuridicoAss';
import DatiAttivitaEconomica from '../../components/anagrafica/sezione/DatiAttivitaEconomica';
import DatiRappLegale from '../../components/anagrafica/sezione/DatiRappLegale';
import DatiGeneraliFisico from '../../components/anagrafica/sezione/DatiGeneraliFisico';
import DatiTitolareEff from '../../components/anagrafica/sezione/DatiTitolareEff';
import AssicuratoFisicoAss from '../../components/anagrafica/figure/AssicuratoFisicoAss';
import { datiAttEconomicaGiuridicoInizializzato, datiNaturaRapportoInizializzato } from '../../session/info/componentiBase/sezioniAnagrafica';
import DatiAttivitaEconomicaGiuridico from '../../components/anagrafica/sezione/DatiAttivitaEconomicaGiuridico';
import DatiNaturaRapporto from '../../components/anagrafica/sezione/DatiNaturaRapporto';

/*const styles = theme => ({
  root: {
//    display: 'flex',
//    flexWrap: 'wrap',
	  marginTop: 56,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});*/

const styles = theme => ({
	root: {
		backgroundColor: 'white',
		height: '100vh',
		marginTop: 56,
	},
	central: {
		height: '80vh',
	},
});

const mapStateToProps = state => {
	  return {
		dominio: state.areaDatiDominioReducer.dominio,
		prodotto: state.areaProdottoReducer.prodotto, 
	  };
};
	
const mapDispatchToProps = dispatch => {
	return {
		dominioNazioni: (nazioneItems) => dispatch(actions.dominioNazioni(nazioneItems)),
		dominioProvince: (provinciaItems) => dispatch(actions.dominioProvince(provinciaItems)),
		dominioTipoDocumento: (tipoDocumentoItems) => dispatch(actions.dominioTipoDocumento(tipoDocumentoItems)),
		dominioEnteRilascio: (enteRilascioItems) => dispatch(actions.dominioEnteRilascio(enteRilascioItems)),
		dominioScopoNaturaRapporto: (scopoNaturaRapportoItems) => dispatch(actions.dominioScopoNaturaRapporto(scopoNaturaRapportoItems)),
		dominioTipoSocieta: (tipoSocietaItems) => dispatch(actions.dominioTipoSocieta(tipoSocietaItems)),
		dominioBeneficiarioGenericoVita: (beneficiarioGenericoVitaItems) => dispatch(actions.dominioBeneficiarioGenericoVita(beneficiarioGenericoVitaItems)),
		dominioBeneficiarioGenericoMorte: (beneficiarioGenericoMorteItems) => dispatch(actions.dominioBeneficiarioGenericoMorte(beneficiarioGenericoMorteItems)),
		dominioPrevalenteAttivitaSvolta: (prevalenteAttivitaSvoltaItems) => dispatch(actions.dominioPrevalenteAttivitaSvolta(prevalenteAttivitaSvoltaItems)),
		dominioPrevalenteAttivitaSvoltaGiuridico: (prevalenteAttivitaSvoltaGiuridicoItems) => dispatch(actions.dominioPrevalenteAttivitaSvoltaGiuridico(prevalenteAttivitaSvoltaGiuridicoItems)),
		dominioOrigineFondi: (origineFondiItems) => dispatch(actions.dominioOrigineFondi(origineFondiItems)),
		dominioAllocazioni: (allocazioneItems) => dispatch(actions.dominioAllocazioni(allocazioneItems)),
		dominioFondi: (fondiItems) => dispatch(actions.dominioFondi(fondiItems)),
		dominioPercTakeProfit: (percTakeProfitItems) => dispatch(actions.dominioPercTakeProfit(percTakeProfitItems)),		
		dominioFrequenzaErogazioniPeriodiche: (frequenzaErogazioniPeriodicheItems) => dispatch(actions.dominioFrequenzaErogazioniPeriodiche(frequenzaErogazioniPeriodicheItems)),
		dominioModalitaPagamento: (modalitaPagamentoItems) => dispatch(actions.dominioModalitaPagamento(modalitaPagamentoItems)),		
		dominioDurataErogazioniPeriodiche: (durataItems) => dispatch(actions.dominioDurataErogazioniPeriodiche(durataItems)),		
		dominioTariffaPerProdotto: (tariffaItems) => dispatch(actions.dominioTariffaPerProdotto(tariffaItems)),		
		dominioCapitaleAssicuratoPerProdotto: (capitaleAssicuratoMalattiaGraveItems) => dispatch(actions.dominioCapitaleAssicuratoPerProdotto(capitaleAssicuratoMalattiaGraveItems)),
		dominioFrequenzaCapitaleAssicurato: (frequenzaCapitaleAssicuatoItems) => dispatch(actions.dominioFrequenzaCapitaleAssicurato(frequenzaCapitaleAssicuatoItems)),
		selezionaProdotto: (area, prodotto) => dispatch(actionsProdotto.selezionaProdotto(area, prodotto)),
	};
};

class AppTest extends React.Component {
	state = {
		open: false,
		prosotto: null,
		area: null,
	};

	constructor(props) {
		super(props);
		// Don't call this.setState() here!
		this.state.store = {
			menuAppBarItems: menuAppBarItems,
			//	    	categoriaProdottoItems: categoriaProdottoItems,
			//	    	initPropostaItems: initPropostaItems,
		};
		this.state.shrinkAppBar = false;
		this.state.initializationComplete = false;
	};

	loadNazioni = () => {
		const dataOggiFormatta = moment().format('DD/MM/YYYY');
		const opts = {
			//      flagCapoluogo: 'Z',
			//RT      dataRichiesta: new Date(),

			dataRiferimentoComune: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.nazioneItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					nazioneItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioNazioni(result);
			});
	}

	loadNazioniDomini = () => {
		const dataOggiFormatta = moment().format('DD/MM/YYYY');
		const opts = {
			//	      flagCapoluogo: 'Z',
			//RT      dataRichiesta: new Date(),

			dataRiferimentoComune: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.nazioneItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					nazioneItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioNazioni(result);
			});
	}

	loadProvince = () => {
		const opts = {
			flagCapoluogo: 'C',
			//RT      dataRichiesta: new Date(),
			dataRiferimentoProv: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.provinciaItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					provinciaItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioProvince(result);
			});
	}

	loadTipoDocumento = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'A003',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.tipoDocumentoItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					tipoDocumentoItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioTipoDocumento(result);
			});
	}

	loadEnteRilascio = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'A009',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.enteRilascioItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					enteRilascioItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioEnteRilascio(result);
			});
	}

	loadScopoNaturaRapporto = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'CLSP',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.scopoNaturaRapportoItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					scopoNaturaRapportoItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioScopoNaturaRapporto(result);
			});
	}

	loadTipoSocieta = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'A020',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.tipoSocietaItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					tipoSocietaItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioTipoSocieta(result);
			});
	}

	loadBeneficiarioGenericoVita = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'BENV',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.beneficiarioGenericoVitaItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					beneficiarioGenericoVitaItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioBeneficiarioGenericoVita(result);
			});
	}

	loadBeneficiarioGenericoMorte = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'BENM',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.beneficiarioGenericoMorteItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					beneficiarioGenericoMorteItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioBeneficiarioGenericoMorte(result);
			});
	}

	loadPrevalenteAttivitaSvolta = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'ATPS',
			//RT      dataRichiesta: new Date(),
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.prevalenteAttivitaSvoltaItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					prevalenteAttivitaSvoltaItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioPrevalenteAttivitaSvolta(result);
			});
	}

	loadOrigineFondi = () => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'ORFN',
			dataRichiesta: '28/03/2019',
		};
		this.setState({
			loaded: false
		});
		fetch(uris.origineFondiItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					origineFondiItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioOrigineFondi(result);
			});
	}

	loadTariffaPerProdotto = (dataOggiFormatta, codiceProdotto) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceRegola: 'PROD_X_TARIFFA',
			codiceProdotto: codiceProdotto,
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.tariffaItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					tariffaItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioTariffaPerProdotto(result);
			});
	}

	loadCapitaleAssicuratoPerProdotto = (dataOggiFormatta, codiceProdotto) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceRegola: 'CAPGAR_X_PROD',
			codiceProdotto: codiceProdotto,
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.capitaleAssicuratoMalattiaGraveItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					capitaleAssicuratoMalattiaGraveItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioCapitaleAssicuratoPerProdotto(result);
			});
	}

	loadFrequenzaCapitaleAssicurato = (dataOggiFormatta) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'FRPP',
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.frequenzaCapitaleAssicuratoItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					frequenzaCapitaleAssicuatoItems: result,
					loaded: true
				});
				this.props.dominioFrequenzaCapitaleAssicurato(result);
			});
	}

	loadModalitaPagamento = (dataOggiFormatta) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'E035',
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.modalitaPagamentoItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					modalitaPagamentoItems: result,
					loaded: true
				});
				this.props.dominioModalitaPagamento(result);
			});
	}

	loadAllocazioni = (dataOggiFormatta, codiceProdotto) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceProdotto: codiceProdotto,
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		let lineaLibera = null;
		fetch(uris.allocazioneItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					allocazioneItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				lineaLibera = result.lineaLibera.codice;
				this.props.dominioAllocazioni(result);
				this.loadFondi(dataOggiFormatta, lineaLibera);
			});
	}

	loadFondi = (dataOggiFormatta, codiceProdottoInvestimento) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceProdottoInvestimento: codiceProdottoInvestimento,
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.fondiItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					fondiItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioFondi(result);
			});
	}

	loadFrequenzaErogazioniPeriodiche = (dataOggiFormatta, codiceProdotto) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceProdotto: codiceProdotto,
			codiceOpzione: '1',
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.frequenzaErogazioniPeriodicheItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					frequenzaErogazioniPeriodicheItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioFrequenzaErogazioniPeriodiche(result);
			});
	}

	loadDurataErogazioniPeriodiche = (dataOggiFormatta) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceCampo: 'DRPP',
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.durataItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					durataItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioDurataErogazioniPeriodiche(result);
			});
	}

	loadPercTakeProfit = (dataOggiFormatta, codiceProdotto) => {
		const opts = {
			siglaVersione: 'ZURI',
			codiceProdotto: codiceProdotto,
			codiceOpzione: '2',
			dataRichiesta: dataOggiFormatta,
		};
		this.setState({
			loaded: false
		});
		fetch(uris.percTakeProfitItems, fetchParam(opts))
			.then(
				response => response.json(),
				error => console.error('An error occurred.', error),
			)
			.then((result) => {
				// If request is good update state with fetched data
				this.setState({
					percTakeProfitItems: result,
					loaded: true
				});
				console.log('[ZSE] componentDidMount result ... ', result);
				this.props.dominioPercTakeProfit(result);
			});
	}
	
    loadPrevalenteAttivitaSvoltaGiuridico = (dataOggiFormatta) => {
	    const opts = {
	      siglaVersione: 'ZURI',
	      codiceCampo: 'A021',
	      dataRichiesta: dataOggiFormatta,
	    };
	    this.setState({
	      loaded: false
	    });
	    fetch(uris.prevalenteAttivitaSvoltaGiuridicoItems, fetchParam(opts))
	    .then (
	      response => response.json(),
	      error => console.error('An error occurred.', error),
	     )
	     .then((result) => {
	       // If request is good update state with fetched data
	       this.setState({
//		    	   prevalenteAttivitaSvoltaGiuridicoItems: result,
	         loaded: true
	       });
	       console.log('[ZSE] componentDidMount result ... ', result);
	       this.props.dominioPrevalenteAttivitaSvoltaGiuridico(result);
	    });
	}  

	componentDidMount() {
		const prodotto = {
			codice: 'M118',
			dataInizioVal: '0001-01-01T00:00:00+01:00',
			dataFineVal: '9999-12-31T00:00:00+01:00',
			title: 'Progetto',
			dataInserimento: '2019-02-08T00:00:00+01:00',
			dataVariazione: '2019-02-08T00:00:00+01:00',
			tipo: 'M',
		};
		
		const area = {
			codice: '4',
			dataInizioVal: '0001-01-01T00:00:00+01:00',
			dataFineVal: '9999-12-31T00:00:00+01:00',
			title: 'INVESTIMENTO',
			titleExt: 'INVESTIMENTO',
			codiceLingua: 'IT',
			//image: '/images/generic/risparmio.svg',
		};

		this.loadNazioni();
		this.loadProvince();
		this.loadTipoDocumento();
		this.loadEnteRilascio();
		this.loadScopoNaturaRapporto();
		this.loadTipoSocieta();
		this.loadBeneficiarioGenericoVita();
		this.loadBeneficiarioGenericoMorte();
		this.loadPrevalenteAttivitaSvolta();
		this.loadPrevalenteAttivitaSvoltaGiuridico('28/03/2019');
		this.loadOrigineFondi();
		this.loadAllocazioni('28/03/2019', 'UL04');
		this.loadPercTakeProfit('28/03/2019', prodotto.codice);
		this.loadFrequenzaCapitaleAssicurato('28/03/2019');
		this.loadModalitaPagamento('28/03/2019');
		this.loadDurataErogazioniPeriodiche('28/03/2019');		
		this.loadTariffaPerProdotto('28/03/2019', prodotto.codice);
		this.loadCapitaleAssicuratoPerProdotto('28/03/2019', prodotto.codice);
		this.loadFrequenzaErogazioniPeriodiche('28/03/2019', prodotto.codice);
		this.props.selezionaProdotto(area, prodotto);
	}

	 componentDidUpdate(prevProps, prevState) {
		 //20230313 PFD - MISTEP-IFA: Per garantire che i componenti di TEST trovino nello store i domini attesi.
		 this.checkInitializationComplete();
	 }    

	 checkInitializationComplete() {
		 if (this.props.dominio.nazioneItems &&
			 this.props.dominio.provinciaItems	&&
			 this.props.dominio.tipoDocumentoItems	&&	 
			 this.props.dominio.enteRilascioItems	&&	 
			 this.props.dominio.scopoNaturaRapportoItems	&&	 
			 this.props.dominio.tipoSocietaItems	&&	 
			 this.props.dominio.beneficiarioGenericoVitaItems	&&
			 this.props.dominio.beneficiarioGenericoMorteItems	&&	 	 
			 this.props.dominio.prevalenteAttivitaSvoltaItems	&&	 
			 this.props.dominio.prevalenteAttivitaSvoltaGiuridicoItems	&&	 
			 this.props.dominio.origineFondiItems	&&
			 this.props.dominio.allocazioneItems	&&	 
			 this.props.dominio.percTakeProfitItems	&&	 
			 this.props.dominio.frequenzaErogazioniPeriodicheItems	&&	 
			 this.props.dominio.modalitaPagamentoItems	&&	 
			 this.props.dominio.durataItems	&&	 
			 this.props.dominio.tariffaItems	&&	 
			 this.props.dominio.capitaleAssicuratoMalattiaGraveItems	&&	 
			 this.props.dominio.frequenzaCapitaleAssicuatoItems	&&	 
			 this.props.prodotto) {
			 
			 if (this.state.initializationComplete == false)  {
				 // L'inizializzazion complete
				 this.setState({
					 initializationComplete: true,
				 });
			 }
		 }
		 
	 }
		
	render() {
		const { classes } = this.props;
		const { store, shrinkAppBar, initializationComplete } = this.state;
		/*const prodotto = {
			codice: 'PRG',
			dataInizioVal: '0001-01-01T00:00:00+01:00',
			dataFineVal: '9999-12-31T00:00:00+01:00',
			title: 'Progetto',
			dataInserimento: '2019-02-08T00:00:00+01:00',
			dataVariazione: '2019-02-08T00:00:00+01:00',
			tipo: 'A',
		};*/
		return (			
				<Grid container className={classes.root}>
				  <CssBaseline />
				  {(initializationComplete) &&
					<Grid item xs={12}>
						<Grid container>
							<Grid item>
								<MenuAppBar menuItems={store.menuAppBarItems} shrinkAppBar={shrinkAppBar} />
							</Grid>
							<Grid item xs={12}>
								{/* Anagraph Proposal */}
								{/* <AnagraphProposal /> */}
								
								{/* CONTRAENTE FISICO - CONTRAENTE GIURIDICO, VEDI navTabProposalItems.js */}
								{/* <ContractorPanel />*/}							
								{/* <ContraenteFisicoAss /> */}
								{/* <ContraenteGiuridicoAss /> */}
								
								{/* ASSICURATO */}
								{/* <AssicuratoPanel /> */}
								{/* <AssicuratoFisicoAss /> */}							
								
								{/* TITOLARE EFFETTIVO 
	              				{/* <TitolareEffPanel /> */}
								
								{/* Single Component TESTING*/}
								{/* <DatiNaturaRapporto id={'datiNaturaRapportoContraenteGiuridico'} figura={'contraenteGiuridico'} datiNaturaRapporto={datiNaturaRapportoInizializzato                     } 
				                labelFieldSet='scopoNaturaRapportoOrigineFondi' onError={this.handleError} /> */}
	
								{/* Altre Figure Proposal */}
								{/* <AltreFigureProposal /> */}
								{/* BENEFICIARIO MORTE */}
								{/* <BenefMortePanel /> */}
								{/* BENEFICIARIO VITA */}
								{/* <BenefVitaPanel /> */}
								{/* REFERENTE TERZO */}
								{/* <ReferenteTerzoPanel /> */}
	
								{/* CARATTERISTICHE CONTRATTO */}
								{/* <CaratteristicheContrattoProposal prodotto={this.state.prodotto} /> */} 
								{/* PREMIO CAPITALE ASSICURATO ALTRO */}               
								{/* <PremioCapitaleAssicuratoPanel /> */}
								{/* <ModalitaPagamentoPanel /> */}
	
								{/* PREMIO CAPITALE ASSICURATO MI */}
								{/* <PremioPanel /> */}
								{/* <RipartizionePremioPanel /> */}
								{/* <OpzioniContrattualiPanel /> */}
								{/* <ModalitaPagamentoPanel /> */}
								
								{/*<DichIntermediarioPanel />*/}
								
								{/* QUESTIONARIO PANEL */}
								<QuestionarioSanitarioPanel />
								
								<QuestionarioSanitarioSemplificatoPanel />
								
								{/* <InviaNuoveCredenzialiPanel /> */}
	    	      
								{/* <StampaProposalPanel /> */}
							</Grid>
						</Grid>
					</Grid>
			      }
				</Grid>
		);
	}
}

AppTest.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppTest));
