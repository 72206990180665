export const UPDATE_MANDATO_MODIFICA            	= 'UPDATE_MANDATO_MODIFICA';
export const UPDATE_UTENTE_MODIFICA 	  			= 'UPDATE_UTENTE_MODIFICA';
export const UPDATE_EMAIL_MODIFICA 	  				= 'UPDATE_EMAIL_MODIFICA';
export const UPDATE_PROFILO_MODIFICA 	  			= 'UPDATE_PROFILO_MODIFICA';
export const UPDATE_DATA_CREAZIONE_MODIFICA 	  	= 'UPDATE_DATA_CREAZIONE_MODIFICA';
export const UPDATE_DATA_SCADENZA_MODIFICA 	    	= 'UPDATE_DATA_SCADENZA_MODIFICA';
export const UPDATE_DATA_BLOCCO_MODIFICA 	  		= 'UPDATE_DATA_BLOCCO_MODIFICA';
export const UPDATE_DATA_REVOCA_MODIFICA 	  	    = 'UPDATE_DATA_REVOCA_MODIFICA';
export const UPDATE_DATA_ULTIMA_VARIAZIONE_MODIFICA = 'UPDATE_DATA_ULTIMA_VARIAZIONE_MODIFICA';
export const UPDATE_STATO_UTENZA_MODIFICA 	  		= 'UPDATE_STATO_UTENZA_MODIFICA';
export const SALVA_DATI_MODIFICA_UTENZA  		    = 'SALVA_DATI_MODIFICA_UTENZA';
export const RIATTIVA_DATI_MODIFICA_UTENZA  		= 'RIATTIVA_DATI_MODIFICA_UTENZA';
export const REVOCA_DATI_MODIFICA_UTENZA  		    = 'REVOCA_DATI_MODIFICA_UTENZA';
export const SBLOCCA_DATI_MODIFICA_UTENZA  		    = 'SBLOCCA_DATI_MODIFICA_UTENZA';
export const MODIFICA_DATI_MODIFICA_UTENZA  		= 'MODIFICA_DATI_MODIFICA_UTENZA';

