import { jsonCopy } from '../../../utility/genericUtility';
import { datiPremioInizializzato, datiRipartizionePremioInizializzato, datiRipartizionePremioFondiInizializzato, datiPianoErogazioniPeriodicheInizializzato, datiTakeProfitInizializzato, 
	datiLifeCycleInizializzato, datiOpzioniContrattualiInizializzato, datiModPagAssegnoInizializzato, datiModPagBonificoInizializzato, datiModPagReimpiegoInizializzato, datiModalitaPagamentoInizializzato, 
	datiPremioCapitaleAssicuratoInizializzato, datiModPagCartaInizializzato, datiModPagAltroInizializzato,
	datiModPagBonificoEsteroInizializzato} from '../componentiBase/sezioniCaratteristicheContratto';

export const datiPremio = jsonCopy(datiPremioInizializzato);
export const datiRipartizionePremio = jsonCopy(datiRipartizionePremioInizializzato);
export const datiRipartizionePremioFondi = jsonCopy(datiRipartizionePremioFondiInizializzato);
export const datiPianoErogazioniPeriodiche = jsonCopy(datiPianoErogazioniPeriodicheInizializzato);
export const datiTakeProfit = jsonCopy(datiTakeProfitInizializzato);
export const datiLifeCycle = jsonCopy(datiLifeCycleInizializzato);
export const datiOpzioniContrattuali = jsonCopy(datiOpzioniContrattualiInizializzato);
export const datiModPagAssegno = jsonCopy(datiModPagAssegnoInizializzato);
export const datiModPagBonifico = jsonCopy(datiModPagBonificoInizializzato);
export const datiModPagBonificoEstero = jsonCopy(datiModPagBonificoEsteroInizializzato);
export const datiModPagReimpiego = jsonCopy(datiModPagReimpiegoInizializzato);
export const datiModPagCarta = jsonCopy(datiModPagCartaInizializzato);
export const datiModalitaPagamento = jsonCopy(datiModalitaPagamentoInizializzato);
export const datiPremioCapitaleAssicurato = jsonCopy(datiPremioCapitaleAssicuratoInizializzato);
export const datiModPagAltro = jsonCopy(datiModPagAltroInizializzato);